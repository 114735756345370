import { API } from "@network";
import {
  API_STOCK_ADJUST_ACTIVITIES_ADD,
  API_STOCK_ADJUST_ACTIVITIES_LIST,
  API_STOCK_ADJUST_CONFIRM,
  API_STOCK_ADJUST_CREATE,
  API_STOCK_ADJUST_DETAIL,
  API_STOCK_ADJUST_LIST,
  API_STOCK_ADJUST_SUMMARY,
} from "@network/URL";

export const getStockAdjustmentSummary = () => {
  return API.post(API_STOCK_ADJUST_SUMMARY);
};

export const getStockAdjustmentList = (body: any, paging: any) => {
  return API.post(
    `${API_STOCK_ADJUST_LIST}?page=${paging.pageIndex}&per_page=${paging.pageSize}`,
    body
  );
};

export const createStockAdjustment = (body: any) => {
  return API.post(API_STOCK_ADJUST_CREATE, body);
};

export const getStockAdjustmentDetail = (id: string) => {
  return API.post(API_STOCK_ADJUST_DETAIL, { id });
};

export const confirmStockAdjustment = (body: any) => {
  return API.post(API_STOCK_ADJUST_CONFIRM, { ...body, status: "approved" });
};

export const rejectStockAdjustment = (body: any) => {
  return API.post(API_STOCK_ADJUST_CONFIRM, { ...body, status: "rejected" });
};

export const getStockAdjustmentActivity = (body: any, currentPage: number) => {
  return API.post(
    `${API_STOCK_ADJUST_ACTIVITIES_LIST}?page=${currentPage}`,
    body
  );
};

export const addStockAdjustmentActivity = (body: any) => {
  return API.post(API_STOCK_ADJUST_ACTIVITIES_ADD, body);
};
