import { IFieldSelect } from "@interfaces/select";
import { getStockRequestList } from "@network/api/stock-request";
import { ObjectUtils, Select } from "d-react-components";
import { IPaging } from "d-react-components/dist/components/list/awesomeList/AwesomeListComponent";
import { isArray, map, uniqBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export interface IStockRequestSelect<T> extends IFieldSelect<T> {
  [key: string]: any;
  chainIds?: string[];
  sourceConstraint?: any[];
  source?: (body: any, paging: IPaging) => Promise<any>;
}

const StockRequestSelect = ({
  value = [],
  multiple,
  className,
  chainIds = [],
  sourceConstraint = [],
  onChange,
  source = getStockRequestList,
  ...selectProps
}: IStockRequestSelect<any>) => {
  const [requestList, setRequestList] = useState<any[]>(
    isArray(value) ? value : [value]
  );

  const { t } = useTranslation();

  useEffect(() => {
    onLoadRequest();
  }, sourceConstraint);

  const onChangeValue = (id: any) => {
    if (multiple) {
      onChange(
        map(id, (item) => ObjectUtils.findItemFromId(requestList, item))
      );
      return;
    }
    onChange(ObjectUtils.findItemFromId(requestList, id));
  };

  const requestValue = useMemo(() => {
    if (multiple) {
      return map(value, (item: any) => item.id);
    }
    return (value as any)?.id;
  }, [value]);

  const onLoadRequest = () => {
    const body = {};
    source(body, { pageIndex: 1, pageSize: 100 }).then((res: any) => {
      const listRequest = res.data?.data?.request ?? [];

      const selectedValue = isArray(value) ? value : [value];
      const filterRequest = map([...selectedValue, ...listRequest], (item) => ({
        ...item,
        id: item.id,
        ref: item.ref,
      }));
      const uniqRequest = uniqBy(filterRequest, (item) => item.id);
      setRequestList(uniqRequest);
    });
  };

  return (
    <Select
      // onSearch={onChangeTextSearch}
      className={className}
      label={t("stockRequest")}
      dataSource={requestList}
      getLabel={(item) => item?.ref}
      value={requestValue}
      onChange={onChangeValue}
      multiple={multiple}
      placeholder={t("pleaseSelect")}
      showSearch
      // filterOption={false}
      {...selectProps}
    />
  );
};

export default StockRequestSelect;
