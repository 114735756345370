import { API } from "@network";
import {
  API_GP_EVENT_ACTIVITIES,
  API_GP_EVENT_ADD_ACTIVITY,
  API_GP_EVENT_CHANNEL_LIST,
  API_GP_EVENT_CHANNEL_UPDATE,
  API_GP_EVENT_CREATE,
  API_GP_EVENT_DETAIL,
  API_GP_EVENT_LIST,
  API_GP_EVENT_PRODUCT_IMPORT,
  API_GP_EVENT_PRODUCT_LIST,
  API_GP_EVENT_PRODUCT_UPDATE,
  API_GP_EVENT_UPDATE,
  API_GP_EVENT_WAREHOUSE_LIST,
  API_GP_EVENT_WAREHOUSE_UPDATE,
} from "@network/URL";

export const getListGpEvent = (body: any, paging: any) =>
  API.post(
    `${API_GP_EVENT_LIST}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
    body
  );

export const createGpEvent = (body: any) => {
  return API.post(API_GP_EVENT_CREATE, body);
};

export const getDetailGpEvent = (id: string) => {
  return API.post(API_GP_EVENT_DETAIL, { id });
};

export const updateGPEvent = (body: any) => {
  return API.post(API_GP_EVENT_UPDATE, body);
};

export const getGPEventChannelList = (body: any, paging?: any) => {
  return API.post(
    `${API_GP_EVENT_CHANNEL_LIST}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
    body
  );
};

export const updateGPEventChannel = (body: any) => {
  return API.post(API_GP_EVENT_CHANNEL_UPDATE, body);
};

export const getGPEventWarehouseList = (body: any, paging: any) => {
  return API.post(
    `${API_GP_EVENT_WAREHOUSE_LIST}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
    body
  );
};

export const updateGPEventWarehouse = (body: any) => {
  return API.post(API_GP_EVENT_WAREHOUSE_UPDATE, body);
};

export const getGPEventProductList = (body: any, paging?: any) => {
  return API.post(
    `${API_GP_EVENT_PRODUCT_LIST}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
    body
  );
};

export const updateGPEventProduct = (body: any) => {
  return API.post(API_GP_EVENT_PRODUCT_UPDATE, body);
};

export const importGPEventProduct = (body: any) => {
  return API.post(API_GP_EVENT_PRODUCT_IMPORT, body);
};

export const getGPEventActivityList = (paging: any, body: any) => {
  return API.post(
    `${API_GP_EVENT_ACTIVITIES}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
    body
  );
};

export const addGPEventActivity = (body: any) => {
  return API.post(API_GP_EVENT_ADD_ACTIVITY, body);
};
