import React, { useMemo, useRef } from "react";
import Drawer from "@components/shared/Drawer";
import { AwesomeTableComponent, IColumnsProps } from "d-react-components";
import { useTranslation } from "react-i18next";

const ChannelList = ({ open, onClose, channelList }: any) => {
  const { t } = useTranslation();
  const tableRef = useRef<any>(null);
  const columns: IColumnsProps = useMemo(
    () => [
      {
        title: t("name"),
        dataIndex: "name",
        render: (name: string) => name,
      },
      {
        title: t("code"),
        dataIndex: "code",
        render: (code: string) => code,
      },
    ],
    [t]
  );
  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("channels")}
      hideFooter={true}
      size="auto"
      width={600}
    >
      <AwesomeTableComponent
        dataSource={channelList}
        columns={columns}
        ref={tableRef}
      />
    </Drawer>
  );
};

export default ChannelList;
