import InputTextForm from "@common/input/InputTextForm";
import ChannelSelect from "@components/channel/components/ChannelSelect";
import { CART_RULE_STATUS_LIST } from "@constants/cart-rule";
import classNames from "classnames";
import {
  DateInput,
  InputText,
  Notifications,
  Select,
} from "d-react-components";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

interface ICartRuleGeneralForm {
  form: any;
}

const CartRuleGeneralForm = ({ form }: ICartRuleGeneralForm) => {
  const formValues = form?.values ?? {};
  const inputClassName = classNames("mt-2 col-span-1");
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-2 gap-4">
      <InputText
        className={classNames(inputClassName, "col-span-2")}
        label={t("ruleTitle")}
        onChange={(event) => form.setFieldValue("title", event.target.value)}
        value={formValues.title}
        placeholder={t("enterTitle")}
      />
      <ChannelSelect
        className={inputClassName}
        value={formValues?.selectedChannel}
        onChange={(value) => {
          form.setFieldValue("selectedChannel", value);
        }}
      />
      <Select
        className={inputClassName}
        label={t("status")}
        dataSource={CART_RULE_STATUS_LIST}
        getLabel={(option) => t(option.label)}
        value={formValues.status}
        onChange={(value) => {
          form.setFieldValue("status", value);
        }}
      />
      <div className="mt-2 grid grid-cols-2 gap-4 col-span-2">
        <DateInput
          showTime
          className={inputClassName}
          label={t("startingDate")}
          onChange={(date) => {
            let value: any = moment(date).valueOf();
            if (!value) {
              // value = new Date();
              value = null;
            }
            if (value && value - formValues.period_to > 0) {
              Notifications.showError(t("startingDateCannotGreater"));
              return;
            }
            form.setFieldValue("period_from", value);
            form.setFieldValue("firstTime", false);
          }}
          value={formValues.period_from ? moment(formValues.period_from) : null}
          format="DD/MM/YYYY HH:mm"
        />

        <DateInput
          showTime
          className={inputClassName}
          label={t("endDate")}
          onChange={(date) => {
            let value: any = moment(date).valueOf();
            if (!value) {
              // value = new Date();
              value = null;
            }
            if (value && value - formValues.period_from < 0) {
              Notifications.showError(t("endDateCannotBeLess"));
              return;
            }
            form.setFieldValue("period_to", value);
            form.setFieldValue("firstTime", false);
          }}
          value={formValues.period_to ? moment(formValues.period_to) : null}
          format="DD/MM/YYYY HH:mm"
        />
      </div>

      <InputText
        label={t("description")}
        className={classNames(inputClassName, "col-span-2")}
        onChange={(event) =>
          form.setFieldValue("description", event.target.value)
        }
        multiple
        value={formValues.description}
      />
    </div>
  );
};

export default CartRuleGeneralForm;
