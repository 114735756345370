/* eslint-disable jsx-a11y/alt-text */
import ProductName from "@components/product/components/ProductName";
import Drawer from "@components/shared/Drawer";
import UserAvatarName from "@components/shared/UserAvatarName";
import { IAdmin } from "@interfaces/admin";
import { IProduct } from "@interfaces/product";
import classNames from "classnames";
import { AwesomeTableComponent, Button, Summary } from "d-react-components";
import { map, reduce } from "lodash";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Carousel, { Modal, ModalGateway } from "react-images";

interface IDeliveryConfirmedView {
  userTitle?: string;
  user?: IAdmin;
  className?: any;
  userFullName?: string;
  signature?: string;
  signAt?: string;
  content?: any;
  products?: IProduct[];
  offListProducts?: IProduct[];
  note?: string;
  attachments?: string[];
  reason?: string;
  destination?: string;
}

interface IProductViewDrawer {
  open: boolean;
  products: IProduct[];
  offListProducts: IProduct[];
  onClose: () => void;
}

const ProductViewDrawer = ({
  products,
  open,
  onClose,
  offListProducts,
}: IProductViewDrawer) => {
  const { t } = useTranslation();

  const columns: any[] = [
    {
      title: t("name"),
      dataIndex: "",
      render: (product: any) => <ProductName item={{ product }} />,
    },
    {
      title: t("quantity"),
      dataIndex: "quantity",
      align: "center",
      width: 100,
      render: (quantity: any) => quantity || "0",
    },
  ];

  const totalPickedUp = useMemo(() => {
    return reduce(products, (sum, item) => (sum += item?.quantity ?? 0), 0);
  }, [products]);

  const totalOffListItem = useMemo(() => {
    return reduce(
      offListProducts,
      (sum, item) => (sum += item?.quantity ?? 0),
      0
    );
  }, [products]);

  const renderSummaryRow = (total: number) => {
    return (
      <Summary.Row>
        <Summary.Cell index={0} className="text-right font-weight-bold">
          {t("total")}
        </Summary.Cell>
        <Summary.Cell index={1} className="text-center font-weight-bold">
          {total}
        </Summary.Cell>
      </Summary.Row>
    );
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("products")}
      onSave={() => {}}
      size="x-large"
    >
      <AwesomeTableComponent
        columns={columns}
        dataSource={products}
        pagination={false}
        summary={() => renderSummaryRow(totalPickedUp)}
      />
      <div className="label my-3" hidden={!totalOffListItem}>
        {t("offListItems")}
      </div>
      {!!totalOffListItem && (
        <AwesomeTableComponent
          columns={columns}
          dataSource={offListProducts}
          pagination={false}
          summary={() => renderSummaryRow(totalOffListItem)}
        />
      )}
    </Drawer>
  );
};

const DeliveryConfirmedView = ({
  userTitle,
  user,
  userFullName = "",
  signature,
  signAt,
  content,
  className,
  products = [],
  note,
  attachments,
  destination,
  reason,
  offListProducts = [],
}: IDeliveryConfirmedView) => {
  const { t } = useTranslation();
  const [indexImageView, setIndexImageView] = useState<any>(-1);
  const [openViewProduct, setOpenViewProduct] = useState<boolean>(false);
  const totalProductQuantity = reduce(
    products,
    (sum, item) => (sum += item.quantity),
    0
  );
  const totalOffListQuantity = reduce(
    offListProducts,
    (sum, item) => (sum += item.quantity),
    0
  );
  return (
    <div className={classNames("border p-3", className)}>
      <div className="mt-3" hidden={!reason}>
        <div className="label">{t("reason")}</div>
        <div>{reason}</div>
      </div>
      <div className="mt-3" hidden={!destination}>
        <div className="label">{t("destination")}</div>
        <div>{destination}</div>
      </div>
      <div className="d-flex mt-3" hidden={!user}>
        <div className="font-weight-bold mr-1">{`${userTitle}: `}</div>
        {user && <UserAvatarName user={user} />}
        {userFullName}
      </div>

      {signature && (
        <img
          src={signature}
          className="mt-3 image-reg-large object-fit-contain my-2"
        />
      )}

      {signature && (
        <div className={classNames("small")}>
          {`${t("signedBy")} `}
          {user && <UserAvatarName user={user} />}
          {userFullName}
          {` ${t("at")} ${signAt}`}
        </div>
      )}
      {content && <div className="mt-1">{content}</div>}
      {!!totalProductQuantity && (
        <Button
          onClick={() => setOpenViewProduct(true)}
          className="mt-3"
          variant="outline"
        >
          {`${t("view")} (${totalProductQuantity + totalOffListQuantity})`}
        </Button>
      )}
      <div className={classNames("bg-primary-20 p-3 mt-3")} hidden={!note}>
        {`"${note}"`}
      </div>
      {attachments?.length && <div className="mt-3 label">{t("proof")}</div>}

      {attachments?.length && (
        <div className="d-flex mt-1" style={{ overflowX: "scroll" }}>
          {map(attachments, (item, index) => (
            <img
              className="image-reg-large object-fit-contain my-2 mr-2"
              src={item}
              onClick={() => setIndexImageView(index)}
            />
          ))}
        </div>
      )}

      <ModalGateway>
        {indexImageView !== -1 && (
          <Modal onClose={() => setIndexImageView(-1)}>
            <Carousel
              currentIndex={indexImageView}
              views={
                map(attachments, (file) => ({
                  src: file,
                  caption: "",
                })) as any
              }
            />
          </Modal>
        )}
      </ModalGateway>

      {openViewProduct && (
        <ProductViewDrawer
          products={products}
          offListProducts={offListProducts}
          open={openViewProduct}
          onClose={() => setOpenViewProduct(false)}
        />
      )}
    </div>
  );
};

export default DeliveryConfirmedView;
