import ButtonFileUpload from "@components/shared/ButtonFileUpload";
import Drawer, { DrawerProps } from "@components/shared/Drawer";
import AppContext from "@helpers/context";
import {
  confirmRentalInvoiceProofDocuments,
  uploadRentalInvoiceProofDocuments,
} from "@network/api/rental";
import {
  Button,
  Icon,
  InputText,
  Progress,
  ViewLabelStatus,
} from "d-react-components";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FILE_FORMAT, IMAGE_FORMAT } from "@constants/file";
import { getImageDataPreview } from "@helpers/file";
import classNames from "classnames";
import {
  CONTRACT_PAYMENT_PROOF_STATUS,
  ContractPaymentProofStatus,
} from "@constants/contract";
import DocumentDropZone from "@components/shared/DocumentDropZone";

enum DocumentType {
  "PROOF_OF_IDENTIFICATION" = "PROOF_OF_IDENTIFICATION",
  "PROOF_OF_BANK_STATEMENT" = "PROOF_OF_BANK_STATEMENT",
  "PROOF_OF_SELFIE" = "PROOF_OF_SELFIE",
}

enum DocumentProofStatus {
  "UPLOADED" = "UPLOADED",
  "REJECTED" = "REJECTED",
  "APPROVED" = "APPROVED",
  "PENDING" = "PENDING",
}

interface Document {
  id: string;
  type_of_proof: DocumentType;
  attachment: string;
  extension: string;
  created: number;
  updated: number;
}

interface DocumentUploadProps {
  type: DocumentType;
  status: string;
  confirmation?: any;
  documents: Document[];
  remark?: string;
  label?: string;
  index: number;
  enableUpload?: boolean;
  hidden?: boolean;
}

const SubscriptionContractDocuments = () => {
  const { t } = useTranslation();
  const { rentalInfo } = useContext(AppContext);
  const {
    proof,
    confirmation_bank_statement_by,
    confirmation_identification_by,
    confirmation_selfie_by,
    status_payment,
    status_proof_bank_statement,
    status_proof_bank_statement_remark,
    status_proof_identification,
    status_proof_identification_remark,
    status_proof_selfie,
    status_proof_selfie_remark,
  } = rentalInfo;

  const documentUploadBlocks = useMemo(
    () => [
      {
        id: 1,
        type: DocumentType.PROOF_OF_IDENTIFICATION,
        label: t("uploadProofOfIdentification"),
        title: t("identification"),
        confirmation: confirmation_identification_by,
        status: status_proof_identification,
        remark: status_proof_identification_remark,
        documents: proof?.filter(
          (p: Document) =>
            p.type_of_proof === DocumentType.PROOF_OF_IDENTIFICATION
        ),
      },
      // {
      //   id: 2,
      //   type: DocumentType.PROOF_OF_BANK_STATEMENT,
      //   label: t("uploadProofOfBankStatement"),
      //   title: t("bankStatement"),
      //   confirmation: confirmation_bank_statement_by,
      //   status: status_proof_bank_statement,
      //   remark: status_proof_bank_statement_remark,
      //   documents: proof?.filter(
      //     (p: Document) =>
      //       p.type_of_proof === DocumentType.PROOF_OF_BANK_STATEMENT
      //   ),
      // },
      {
        id: 3,
        type: DocumentType.PROOF_OF_SELFIE,
        label: t("uploadProofOfSelfie"),
        title: t("selfiePhoto"),
        confirmation: confirmation_selfie_by,
        status: status_proof_selfie,
        remark: status_proof_selfie_remark,
        documents: proof?.filter(
          (p: Document) => p.type_of_proof === DocumentType.PROOF_OF_SELFIE
        ),
      },
    ],
    [rentalInfo]
  );
  const [selectedBlock, setSelectedBlock] = useState(documentUploadBlocks[0]);

  return (
    <div className="bg-white p-3">
      <label className="block mb-2 text-sm">{t("additionalDocuments")}</label>
      <div className="flex mt-3">
        {documentUploadBlocks.map((block, index) => (
          <div
            className={classNames(
              "px-3 py-2 mr-2 cursor-pointer flex items-center",
              {
                "bg-primary border-primary text-white":
                  selectedBlock.id === block.id,
                "bg-white border-[1px] !border-gray-400":
                  selectedBlock.id !== block.id,
              }
            )}
            key={index}
            onClick={() => setSelectedBlock(block)}
          >
            {block.title}
            {block.status === ContractPaymentProofStatus.APPROVED && (
              <Icon name="check_circle" className="text-green-500 ml-1" />
            )}
            {block.status === ContractPaymentProofStatus.REJECTED && (
              <Icon name="info" className="text-red-500 ml-1" />
            )}
          </div>
        ))}
      </div>
      {documentUploadBlocks.map((block, index) => (
        <DocumentUpload
          {...block}
          key={index}
          index={index}
          hidden={selectedBlock.id !== block.id}
          enableUpload={block.status !== ContractPaymentProofStatus.APPROVED}
        />
      ))}
    </div>
  );
};

const DocumentUpload = ({
  type,
  status,
  label,
  index,
  documents,
  remark,
  enableUpload = true,
  confirmation,
  hidden,
}: DocumentUploadProps) => {
  const buttonUploadRef = useRef<any>(null);
  const { t } = useTranslation();
  const [openConfirmModal, setOpenConfirmModal] = useState({
    open: false,
    status: "",
    type,
  });
  const { rentalInfo } = useContext(AppContext);
  const [attachments, setAttachments] = useState<any[]>(
    documents?.map((a: any) => ({
      ...a,
      imageData: getImageDataPreview(a),
    }))
  );

  const onChangeAttachments = (files: any) => {
    // get the new uploaded files
    const newFiles = files.filter(
      (f: any) => !attachments.some((a: any) => a.id === f.id)
    );
    if (newFiles.length) {
      uploadRentalInvoiceProofDocuments({
        id: rentalInfo.id,
        attachment: newFiles?.map((f: any) => f.key),
        type_of_proof: type,
      }).then((res) => {
        setAttachments(files);
      });
    }
  };
  const proofStatus = CONTRACT_PAYMENT_PROOF_STATUS.find(
    (s) => s.value === status
  );

  return (
    <div className="my-4 pb-3" hidden={hidden}>
      <div className="mb-4">
        <div className="flex">
          <div>
            <span className="bg-primary text-white block w-4 h-4 text-xs items-center justify-center flex rounded mr-2">
              {index + 1}
            </span>
          </div>
          <div className="flex-1">{label}</div>
        </div>
      </div>
      <ButtonFileUpload
        onChange={onChangeAttachments}
        containerClassName="d-flex flex-column"
        ref={buttonUploadRef}
        inputParam={{ accept: `${IMAGE_FORMAT},${FILE_FORMAT}` }}
        classNameItem="!w-24 !h-24 bg-black"
        classNameImage="!object-contain"
        defaultFiles={attachments}
        isView
        renderButton={(
          getRootProps: any,
          getInputProps: any,
          inputParam: any
        ) =>
          enableUpload ? (
            <DocumentDropZone
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              inputParam={inputParam}
            />
          ) : null
        }
      />

      <div className="mt-3">
        <div className="flex items-center">
          <span className="font-semibold text-xs mr-2">{t("status")}</span>
          <ViewLabelStatus
            content={t(`${proofStatus?.label}`)}
            color={proofStatus?.color}
          />
        </div>
        {confirmation && (
          <div className="mt-2 text-xs">
            <span className="font-semibold">
              {status === DocumentProofStatus.APPROVED
                ? t("approvedBy")
                : t("rejectBy")}
            </span>
            : {confirmation?.name}
          </div>
        )}
        {remark && (
          <div className="shippingItemNote relative break-words bg-blue-100 arrow-up p-2 mb-2 mt-3 text-xs">
            {remark}
          </div>
        )}
      </div>

      {status === DocumentProofStatus.UPLOADED && (
        <div className="bg-blue-100 p-3 mt-3">
          <span className="text-bold mb-3 block">{t("documentApproval")}</span>
          <div className="flex">
            <Button
              className="bg-primary mr-2"
              onClick={() =>
                setOpenConfirmModal({
                  open: true,
                  status: DocumentProofStatus.APPROVED,
                  type,
                })
              }
            >
              {t("approve")}
            </Button>
            <Button
              variant="outline"
              onClick={() =>
                setOpenConfirmModal({
                  open: true,
                  status: DocumentProofStatus.REJECTED,
                  type,
                })
              }
            >
              {t("reject")}
            </Button>
          </div>
        </div>
      )}
      {openConfirmModal.open && (
        <ConfirmDocumentModal
          {...openConfirmModal}
          onClose={() =>
            setOpenConfirmModal({ ...openConfirmModal, open: false })
          }
        />
      )}
    </div>
  );
};

const ConfirmDocumentModal = ({
  open,
  onClose,
  status,
  type,
}: DrawerProps & { [key: string]: any; onClose: any }) => {
  const { t } = useTranslation();
  const { rentalInfo, onReloadOrderData } = useContext(AppContext);
  const confirmDocument = (values: any) => {
    Progress.show(
      {
        method: confirmRentalInvoiceProofDocuments,
        params: [
          {
            id: rentalInfo.id,
            status,
            remark: values.remark,
            type_of_proof: type,
          },
        ],
      },
      (res: any) => {
        onReloadOrderData && onReloadOrderData();
        onClose && onClose();
      }
    );
  };
  const form = useFormik({
    initialValues: {
      remark: "",
    } as any,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      remark: Yup.string().required(t("fieldRequired")),
    }),
    onSubmit: (values: any) => {
      confirmDocument(values);
    },
  });

  const formValues = form?.values;
  return (
    <Drawer
      open={open}
      onClose={onClose}
      onSave={() => form.handleSubmit()}
      title={t("documentApproval")}
    >
      <InputText
        className="mt-3"
        label={t("remark")}
        multiple
        value={formValues.remark}
        error={form?.errors?.remark as string}
        onChange={(event) => form.setFieldValue("remark", event.target.value)}
        placeholder={t("pleaseInput")}
      />
    </Drawer>
  );
};

export default SubscriptionContractDocuments;
