import AppContext from "@helpers/context";
import { Button, ObjectUtils } from "d-react-components";
import { filter, map, max, now, sortBy } from "lodash";
import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import GiftContainer from "./GiftContainer";

const BundleConditionProduct = ({ className }: any) => {
  const { cartRuleForm } = useContext(AppContext);
  const { t } = useTranslation();
  const formValues = cartRuleForm.values ?? {};

  const currentBundleList = useMemo(() => {
    return formValues?.actions?.free_gift?.bundle ?? [];
  }, [formValues?.actions]);

  const getPriorityMax = () => {
    return max(map(currentBundleList, (gift) => gift.priority)) ?? 0;
  };

  const onClickAddBundleHandle = () => {
    const clone = [
      ...currentBundleList,
      {
        id: now(),
        name: `${t("bundle")} ${currentBundleList.length + 1}`,
        product: [],
        priority: getPriorityMax() + 1,
      },
    ];
    setChangeBundle(clone);
  };

  const setChangeBundle = (newBundle: any) => {
    const cloneAction = {
      ...formValues?.actions,
      free_gift: {
        ...formValues?.actions.free_gift,
        bundle: newBundle,
      },
    };
    cartRuleForm.setFieldValue("actions", cloneAction);
  };

  const handleChangeGift = (payload: any) => {
    const result = map(currentBundleList, (item) => {
      if (item?.id === payload?.id) {
        return payload;
      }
      return item;
    });
    const sortResult = sortBy(result, [
      function (item) {
        return item.priority;
      },
    ]);
    setChangeBundle(sortResult);
  };

  const removeGiftHandle = (item: any) => {
    const cloneGift = filter(currentBundleList, (gif) => gif?.id !== item?.id);
    setChangeBundle(cloneGift);
  };

  const onClickBundleUp = (currentIndex: any) => {
    if (currentIndex === 0) {
      return;
    }
    const swappedPriorityList = swapPriorityBetweenBundles(
      currentBundleList,
      currentIndex,
      currentIndex - 1
    );
    const results = ObjectUtils.arrayMove(
      swappedPriorityList as any,
      currentIndex,
      currentIndex - 1
    );
    setChangeBundle(results);
  };
  const onClickBundleDown = (currentIndex: any) => {
    if (currentIndex === currentBundleList.length - 1) {
      return;
    }
    const swappedPriorityList = swapPriorityBetweenBundles(
      currentBundleList,
      currentIndex,
      currentIndex + 1
    );
    const results = ObjectUtils.arrayMove(
      swappedPriorityList as any,
      currentIndex,
      currentIndex + 1
    );
    setChangeBundle(results);
  };

  const swapPriorityBetweenBundles = (
    bundleList: any,
    indexFirst: any,
    indexSecond: any
  ) => {
    const priorityFirst = bundleList?.[indexFirst]?.priority;
    const prioritySecond = bundleList?.[indexSecond]?.priority;
    const bundleResult = [...bundleList];
    bundleResult[indexSecond].priority = priorityFirst;
    bundleResult[indexFirst].priority = prioritySecond;
    return bundleResult;
  };

  return (
    <div className={className}>
      <div className="my-2">{t("ifPurchasingOneOfTheFollowingBundles")}</div>
      <div>
        {map(currentBundleList, (bundle, index) => (
          <GiftContainer
            payload={bundle}
            key={`${index}${bundle?.id}`}
            setChangeBundle={(payload: any) => handleChangeGift(payload)}
            deleteBundle={(payload: any) => removeGiftHandle(payload)}
            hasPriority
            isMovePosition
            otherPriorityList={currentBundleList
              .filter((item: any) => item.id !== bundle?.id)
              .map((item: any) => item?.priority)}
            onClickUp={() => onClickBundleUp(index)}
            onClickDown={() => onClickBundleDown(index)}
          />
        ))}
      </div>
      <Button onClick={onClickAddBundleHandle} iconName="add" className="mt-2">
        {t("morebundle")}
      </Button>
    </div>
  );
};

export default BundleConditionProduct;
