/* eslint-disable no-continue */
import { PRODUCT_TYPES } from "@constants/product";
import AppContext from "@helpers/context";
import { exportToCSV } from "@helpers/file";
import { find, forEach, split, replace, map, includes, isEmpty } from "lodash";
import React, { useContext, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

const splitRemoveSpaceString = (stringData = "") => {
  const breakArray = includes(stringData, ";")
    ? split(stringData, ";")
    : split(stringData, ",");
  return map(breakArray, (item) => replace(item, "\r", ""));
};

function csvJSON(csv: any) {
  const lines = split(csv, "\n");
  const result = [];
  const headers = splitRemoveSpaceString(lines[0]);

  for (let i = 1; i < lines.length; i++) {
    if (!lines[i]) continue;
    const obj: any = {};
    const currentLine = splitRemoveSpaceString(lines[i]);

    for (let j = 0; j < headers.length; j++) {
      const currentHeader = headers[j];
      let value: any = currentLine[j];
      if (value === "TRUE") {
        value = true;
      }
      if (value === "FALSE") {
        value = false;
      }
      if (!isEmpty(currentHeader)) {
        obj[currentHeader] = value;
      }
    }
    result.push(obj);
  }
  return result;
}

const SelectCSVFile = () => {
  const { formImport } = useContext(AppContext);
  const { t } = useTranslation();

  const { importFields, productType, fileImport } = formImport.values;
  const productTypeVal = useMemo(
    () => find(PRODUCT_TYPES, (item) => item.id === productType),
    [productType]
  );

  const onDrop = ([file]: any) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = function () {
      const data = csvJSON(reader.result);
      console.log("Raw date from file", reader.result);
      console.log("Data converted", data);
      formImport.setFieldValue("fileImport", {
        ...fileImport,
        name: file?.name,
        data,
      });

      //   if (!validateSkuList(skuList)) {
      //     setValidateLoading(false);
      //     Notifications.showError(Messages.fileImportIsNotRightFormat);
      //     return;
      //   }
    };
  };

  const onClickDownloadTemplate = () => {
    const importColumn: any = {
      sku: "",
    };
    forEach(importFields, (fieldItem) => {
      const { fields = [], name } = fieldItem;
      if (fields?.length > 0) {
        forEach(fields, (fieldChild) => {
          importColumn[`${name}_${fieldChild.name}`] = "";
        });
      } else {
        importColumn[fieldItem.name] = "";
      }
    });
    exportToCSV([importColumn], "Import_product_file");
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="p-4">
      <div>
        <div
          className="flex-center border-dashed p-5 flex-column"
          {...getRootProps()}
        >
          <img
            src={"/images/files/csv.png"}
            id="csvIconImage"
            className="mt-5"
          />
          <input {...getInputProps()} />
          <text className="font-weight-bold my-3">
            {t("dragAndDropYourCSVHere")}
          </text>
          <small>{t("maximumFileSizeIs5MB")}</small>
          <small className="mb-5 text-primary text-underline">
            {t("orSelectFromYourComputer")}
          </small>
          <text className="mb-5 text-primary">{fileImport?.name}</text>
        </div>
        <div className="flex-column d-flex">
          <text className="mt-3">{t("notSureHowToStart")}</text>
          <text>- {t("downloadAsvFile")}</text>
          <div className="d-flex text">
            {`- ${t("download")}`}
            <div
              onClick={onClickDownloadTemplate}
              className="mx-3 text-primary cursor-pointer"
            >
              {t("blankCSVTemplate")}
            </div>
            {` for ${t(productTypeVal?.label ?? "")}`}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectCSVFile;
