import { API } from "@network";
import { API_ROLE_LIST } from "@network/URL";


const RoleAPI = {
    list: (body?: any) => {
        return API.post(API_ROLE_LIST, body);
    },
};

export default RoleAPI;
