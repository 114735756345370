import { useTranslation } from "react-i18next";
import React, { useContext, useMemo, useState } from "react";
import Drawer, { DrawerProps } from "@components/shared/Drawer";
import AppContext from "@helpers/context";
import { InputText, Notifications, Progress, Select } from "d-react-components";
import styled from "@emotion/styled";
import { isEmpty } from "lodash";
import { resendContract } from "@network/api/rental";

export const VerifiedButton = () => {
  const { t } = useTranslation();
  return (
    <span className="text-green-500 bg-green-200 p-1 relative top-[2px] text-xs">
      {t("verified")}
    </span>
  );
};

export const VerifyButton = () => {
  const { t } = useTranslation();
  return (
    <span className="text-white bg-primary p-1 relative top-[2px] text-xs">
      {t("verify")}
    </span>
  );
};

export enum SendMethod {
  Email = "email",
  Sms = "sms",
  Both = "both",
}

export const METHODS = [
  { value: SendMethod.Email, label: "email" },
  { value: SendMethod.Sms, label: "sms" },
  { value: SendMethod.Both, label: "both" },
];

const ResendContractModal = ({
  open,
  onClose,
}: DrawerProps & { onClose: any }) => {
  const { t } = useTranslation();
  const [method, setMethod] = useState(METHODS[0].value);
  const { rentalInfo } = useContext(AppContext);
  const { information } = rentalInfo ?? {};
  const { is_verify_email, is_verify_phone } = information ?? {};

  const onResend = () => {
    Progress.show(
      {
        method: resendContract,
        params: [
          {
            id: rentalInfo.id,
            method:
              method === "both" ? [SendMethod.Sms, SendMethod.Email] : [method],
          },
        ],
      },
      (res: any) => {
        Notifications.showSuccess(t("notification:resendContractSuccess"));
        onClose && onClose();
      }
    );
  };

  const disableSave = useMemo(() => {
    if (method === SendMethod.Sms) {
      return isEmpty(information?.phone);
    }
    if (method === SendMethod.Email) {
      return isEmpty(information?.email);
    }
  }, [method, information]);

  return (
    <Drawer
      title={t("resendContractURL")}
      open={open}
      onClose={onClose}
      destroyOnClose
      onSave={onResend}
      disableSave={disableSave}
    >
      <StyledForm>
        <Select
          dataSource={METHODS}
          value={method}
          label={t("method")}
          placeholder={t("method")}
          onChange={(value) => setMethod(value)}
          getLabel={(item) => t(item.label)}
          getValue={(item) => item.value}
          className="mb-3"
          allowClear={false}
        />
        <InputText
          value={information?.email}
          placeholder={t("email")}
          label={t("email")}
          readOnly
          suffix={is_verify_email ? <VerifiedButton /> : <VerifyButton />}
          className="mb-3"
          hidden={method === SendMethod.Sms}
        />
        <InputText
          value={information?.phone}
          placeholder={t("phone")}
          label={t("phone")}
          readOnly
          suffix={is_verify_phone ? <VerifiedButton /> : <VerifyButton />}
          className="mb-3"
          hidden={method === SendMethod.Email}
        />
      </StyledForm>
    </Drawer>
  );
};

const StyledForm = styled.div`
  .d-input-text__suffix-container {
    background: #fff !important;
    border: none !important;
  }
`;

export default ResendContractModal;
