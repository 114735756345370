export const PAYMENT_CONFIRM_STATUS = {
  REJECTED: "rejected",
  APPROVE: "approved",
  PENDING: "pending",
};

export const REFUND_STATUS = {
  pending: {
    color: "text-amber-500",
    content: "pending",
    key: "pending",
  },
  rejected: {
    color: "text-red-500",
    content: "rejected",
    key: "rejected",
  },
  approved: {
    color: "text-green-500",
    content: "approved",
    key: "approved",
  },
  refunded: {
    color: "text-blue-500",
    content: "refunded",
    key: "refunded",
  },
  cancelled: {
    color: "text-red-500",
    content: "cancelled",
    key: "cancelled",
  },
};

export const PAYMENT_CONFIRM_STATUSES = [
  {
    value: PAYMENT_CONFIRM_STATUS.REJECTED,
    color: "#D30F0F",
    bgColor: "red",
  },
  {
    value: PAYMENT_CONFIRM_STATUS.APPROVE,
    color: "#4AA256",
    bgColor: "green",
  },
  {
    value: PAYMENT_CONFIRM_STATUS.PENDING,
    color: "#FAC256",
    bgColor: "amber",
  },
  {
    value: "default",
    color: "#2196F3",
    bgColor: "blue",
  },
];

export const PAYMENTS_METHOD = {
  BANK: "bank-transfer",
  C2P: "2c2p",
  CASH: "cash",
};

export const PAYMENTS_METHODS = [
  {
    id: "bank-transfer",
    name: "payment",
    value: "bank-transfer",
    label: "bankTransfer",
  },
  {
    id: "2c2p",
    name: "payment",
    value: "2c2p",
    label: "c2pName",
  },
  {
    id: "cash",
    name: "payment",
    value: "cash",
    label: "cashName",
  },
];

export const NUMBER_INSTALMENT = [
  { id: 1, label: "1" },
  { id: 2, label: "2" },
  { id: 3, label: "3" },
  { id: 4, label: "4" },
  { id: 5, label: "5" },
  { id: 6, label: "6" },
  { id: 7, label: "7" },
  { id: 8, label: "8" },
  { id: 9, label: "9" },
  { id: 10, label: "10" },
  { id: 11, label: "11" },
  { id: 12, label: "12" },
];
export const PAYMENT_MODERN_TRADE = "instalment_by_modern_trade";
export const PAYMENT_2C2P = "2c2p";
export const PAYMENT_DEFAULT = { id: "default", label: "Default" };
export const PAYMENT_COD = "cod";
export const PAYMENT_QR_PROMPT_PAY = "qr-prompt-pay-by-edc";
export const PAYMENT_BANK_TRANSFER = "bank-transfer";
export const PAYMENT_CREDIT_CARD_EDC = "credit-card-pos";
export const PAYMENT_CASH = "cash";

export const PAYMENT_METHOD_OBJECT: { [key: string]: string } = {
  "bank-transfer": "bankTransfer",
  "2c2p": "2C2P",
  cash: "cashName",
  "credit-card-pos": "creditCardPOS",
};

export const OFFLINE_PAYMENT_METHOD = ["cash", "viz-coin", "credit-card-pos"];
