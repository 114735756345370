import ProductName from "@components/product/components/ProductName";
import QuantityInputField from "@components/product/components/QuantityInputField";
import Drawer from "@components/shared/Drawer";
import { SelectInfinity } from "@components/shared/SelectInfinity";
import AppContext from "@helpers/context";
import { getBothLangsText } from "@helpers/locales";
import { getStaticImageMap } from "@helpers/map";
import { calculateDefaultExpandedRowKeys } from "@helpers/product";
import { getFullName } from "@helpers/string";
import { listAllStore } from "@network/api/store";
import {
  AwesomeTableComponent,
  InputText,
  Notifications,
  DateInput,
  Button,
} from "d-react-components";
import { isEmpty, reduce } from "lodash";
import moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useFirstMountState } from "react-use";

const PickupDrawer = ({
  open,
  onClose,
  pickup,
  disabled = false,
  productOrderList = [],
  deliveryList = [],
  pickupList,
  store,
  customer,
  onSave,
  showSaveButton,
  isPrint = false,
  disablePrint = false,
  isReadOnly,
}: any) => {
  const { onClickPickUpPrint } = useContext(AppContext);
  const isEdit = !!pickup;
  const [pickupData, setPickupData] = useState(getInitPickupData(pickup));
  const textSearch = useRef("");
  const productList = useRef<any>(null);
  const isFirstTime = useFirstMountState();
  const { t } = useTranslation();

  useEffect(() => {
    setPickupData(getInitPickupData(pickup));
    productList.current && productList.current.refresh();
  }, [pickup]);

  function getDeliveredNumber(product: any) {
    if (!product || !product.id) {
      return 0;
    }
    return reduce(
      deliveryList,
      (sum, deliveryItem) => {
        const productsDelivery = deliveryItem.items ?? [];
        const proDelivery = productsDelivery.find(
          (itemDe: any) =>
            itemDe.order_product_id === product.id ||
            itemDe.order_product_group_id === product.id
        );
        return sum + (proDelivery?.stock ?? 0);
      },
      0
    );
  }

  function getPickedNumber(product: any) {
    if (!product || !product.id) {
      return 0;
    }

    return reduce(
      pickupList,
      (sum, pickupItem) => {
        const listItem = pickupItem.products ?? [];
        const proPickup = listItem.find(
          (itemDe: any) =>
            itemDe.order_product_id === product.id ||
            itemDe.order_product_group_id === product.id
        );
        return sum + (proPickup?.stock ?? 0);
      },
      0
    );
  }
  function getInitPickupData(pickup: any) {
    // console.log("pickuplist create edit pickup", JSON.stringify(pickupList));
    const pickupParentItems = getInitProductPickup(productOrderList);
    const pickupItems = pickupParentItems?.map((parentItem: any) => {
      const { groups } = parentItem;
      const groupsResult = getInitProductPickup(groups);
      return {
        ...parentItem,
        groups: groupsResult,
      };
    });

    if (isEdit) {
      return {
        ...pickup,
        pickupTime: moment(pickup.pickupTime),
        products: pickupItems,
      };
    }
    return {
      store,
      pickupTime: moment(),
      personName: getFullName(customer),
      personPhone: customer?.phone ?? "",
      products: pickupItems,
      notes: "",
    };
  }

  function getInitProductPickup(productList: any) {
    const pickupItems = productList?.map((item: any) => {
      const delivered = getDeliveredNumber(item);

      let proPickup = {};
      if (isEdit) {
        proPickup = pickup.products.find(
          (pickPro: any) =>
            pickPro.order_product_id === item.id ||
            pickPro.id === item.id ||
            pickPro.order_product_group_id === item.id
        );
      } else {
        proPickup = {
          ...item,
          stock: 0,
        };
      }

      const pickedNumber = getPickedNumber(item);
      const leftItemCount = item.quantity - delivered - pickedNumber;
      return { ...proPickup, ...item, delivered, pickedNumber, leftItemCount };
    });
    return pickupItems;
  }

  useEffect(() => {
    if (isFirstTime) {
      return;
    }
    productList.current && productList.current.refresh();
  }, [pickupData.products]);

  const onChangeDataPickUp = (key: any, value: any) => {
    setPickupData({ ...pickupData, [key]: value });
  };

  const sourcePickupLocation = (paging: any) => {
    return listAllStore({ search: textSearch.current }, paging);
  };

  const renderPickUpAddress = () => {
    const store = pickupData?.store ?? null;
    if (isEmpty(store)) {
      return <div />;
    }
    const {
      name,
      code,
      latitude,
      longitude,
      city,
      province,
      postcode,
      phone,
      address,
      subdistrict,
    } = store;
    const latLng = `${latitude},${longitude}`;
    return (
      <div className="pickupLocationInfoContainer flex mt-3">
        <div className="flex-1">
          <span className="subTitle1 font-bold mb-2">{name}</span>
          <div className="mainInfoContainer">
            <div className="mainInfo">
              <div className="mb-1">{address ?? ""}</div>
              <div className="mb-1">{subdistrict?.name ?? ""}</div>
              <div className="mb-1">{city?.name ?? ""}</div>
              <div className="mb-1">{province?.name ?? ""}</div>
              <div className="mb-1">{postcode ?? ""}</div>
              <div className="mb-1">{`${t("tel")}: ${phone ?? "N/A"}`}</div>
              <div className="mb-1">{`${t("storeCode")}: ${
                code ?? "N/A"
              }`}</div>
            </div>
          </div>
        </div>
        <img src={getStaticImageMap(latLng)} className="w-32 h-24 mb-3" />
      </div>
    );
  };

  const renderPickUpLocation = () => {
    return (
      <div className="mb-3">
        {!isReadOnly && (
          <SelectInfinity
            placeholder={t("search")}
            fetchFn={(body: any, paging: any) => {
              return listAllStore(
                { search: body.search },
                {
                  pageIndex: paging.page,
                  pageSize: paging.per_page,
                }
              );
            }}
            dataPath="stores"
            onChange={(item: any) =>
              onChangeDataPickUp("store", item?.[0] ?? {})
            }
            getLabel={(item) => item?.name}
            className="mb-3"
            label={t("pickUpLocation")}
          />
        )}

        {renderPickUpAddress()}
      </div>
    );
  };
  const columnTitle = useCallback(
    (title: string, isShowBothLangs: boolean) => {
      return (
        <span style={{ whiteSpace: "pre-line" }}>
          {isShowBothLangs ? getBothLangsText(title) : t(title)}
        </span>
      );
    },
    [t]
  );

  const onChangeQuantitySubPro = (
    parentPro: any,
    childPro: any,
    quantity: any
  ) => {
    if (disabled) {
      return;
    }
    if (quantity > childPro.quantity || quantity < 0) {
      return;
    }
    if (quantity > childPro.stock && childPro.leftItemCount === 0) {
      Notifications.showError(t("notification:noLeftItem"));
      return;
    }
    const productResult = pickupData.products?.map((itemParam: any) => {
      if (parentPro.id === itemParam.id) {
        const { groups } = itemParam;
        const groupResult = setChangeQuantityInProducts(
          groups,
          childPro,
          quantity
        );

        return {
          ...itemParam,
          groups: groupResult,
        };
      }
      return itemParam;
    });
    onChangeDataPickUp("products", productResult);
  };

  const onChangeQuantityPickup = (quantity: any, item: any) => {
    if (disabled) {
      return;
    }
    if (quantity > item.quantity || quantity < 0) {
      return;
    }
    if (quantity > item.stock && item.leftItemCount === 0) {
      Notifications.showError(t("noLeftItem"));
      return;
    }
    const productResult = setChangeQuantityInProducts(
      pickupData.products,
      item,
      quantity
    );
    onChangeDataPickUp("products", productResult);
  };

  const setChangeQuantityInProducts = (
    products: any,
    productItem: any,
    quantity: any
  ) => {
    const list = products?.map((itemParam: any) => {
      if (productItem.id === itemParam.id) {
        return {
          ...itemParam,
          stock: quantity,
          leftItemCount: itemParam.leftItemCount + itemParam.stock - quantity,
        };
      }
      return itemParam;
    });
    return list;
  };

  const columns = useCallback(
    () => [
      {
        title: isPrint
          ? columnTitle("itemNameTable", true)
          : t("itemNameTable"),
        dataIndex: "product",
        // ellipsis: true,
        // align: "left",
        width: 400,
        render: (product: any, item: any) => {
          const isNoneItem = item.leftItemCount === 0;
          let subBody = <div />;
          if (!item.groups || item.groups.length === 0) {
            subBody = (
              <div
                className={
                  isNoneItem
                    ? "text-green-500 text-xs"
                    : "text-amber-500 text-xs"
                }
              >
                {item.leftItemCount + t("willBeLeft")}
              </div>
            );
          }
          return <ProductName item={item} subBody={isPrint ? null : subBody} />;
        },
      },
      {
        title: isPrint ? columnTitle("totalQty", true) : t("totalQty"),
        dataIndex: "quantity",
        width: isPrint ? 160 : 100,
        // align: "center",
        render: (quantity: any) => quantity,
      },
      {
        title: isPrint ? columnTitle("picked", true) : t("picked"),
        dataIndex: "pickedNumber",
        width: isPrint ? 160 : 100,
        // align: "center",
        render: (pickedNumber: any) => pickedNumber,
      },
      ...(isReadOnly
        ? []
        : [
            {
              title: isPrint ? columnTitle("shipQty", true) : t("shipQty"),
              dataIndex: "delivered",
              width: isPrint ? 160 : 100,
              // align: "center",
              render: (delivered: any) => delivered,
            },
          ]),
      ...(isReadOnly
        ? []
        : [
            {
              title: isPrint ? columnTitle("pickupQty", true) : t("pickupQty"),
              dataIndex: "stock",
              width: isPrint ? 160 : 100,
              // align: "center",
              render: (stock: any, item: any) => {
                const isGroupProduct = item?.groups?.length > 0;
                const parentPro = item?.parentPro;
                const isSubProductTable = !!parentPro;
                // eslint-disable-next-line no-nested-ternary
                return isGroupProduct ? (
                  <div />
                ) : isPrint ? (
                  <div className="subTitle1">{stock}</div>
                ) : (
                  <QuantityInputField
                    quantity={stock}
                    onChangeQuantity={(quantity: number) =>
                      isSubProductTable
                        ? onChangeQuantitySubPro(parentPro, item, quantity)
                        : onChangeQuantityPickup(quantity, item)
                    }
                  />
                );
              },
            },
          ]),
    ],
    [t, onChangeQuantitySubPro, onChangeQuantityPickup]
  );

  const dataSource = (productList: any) => {
    return productList?.map((product: any) => {
      const { groups = [] } = product;
      const childrenGroup = groups?.map((item: any) => ({
        ...item,
        parentPro: product,
      }));
      if (childrenGroup.length > 0) {
        return {
          ...product,
          children: childrenGroup,
        };
      }
      return product;
    });
  };

  const transformer = (productList: any) => {
    return productList?.map((product: any) => {
      const { groups = [] } = product;
      const childrenGroup = groups?.map((item: any) => ({
        ...item,
        parentPro: product,
      }));
      if (childrenGroup.length > 0) {
        return {
          ...product,
          children: childrenGroup,
        };
      }
      return product;
    });
  };
  const renderPickUpItem = () => {
    const { products } = pickupData;
    const renderAwesomeTable = () => (
      <AwesomeTableComponent
        ref={(ref) => (productList.current = ref)}
        source={() => Promise.resolve(products)}
        transformer={transformer}
        columns={columns()}
        isScroll={false}
        isPagination={false}
        className="tableItemPickup"
        size="small"
        classNameTable="mb-3"
        expandable={{
          rowExpandable: (product) => product?.groups?.length > 0,
          expandedRowKeys: calculateDefaultExpandedRowKeys(products, {}),
          // defaultExpandedRowKeys: true,
        }}
        rowClassName={(record, index) => {
          let rowClassName = "rowAwesomeTable";
          if (record.isGift) {
            rowClassName += " freeGiftProductRow";
          }
          return rowClassName;
        }}
      />
    );
    const renderAntTable = () => {
      const printColumn = columns();
      printColumn.splice(2, 1);
      printColumn.splice(1, 1);
      return (
        <AwesomeTableComponent
          dataSource={dataSource(products)}
          columns={printColumn}
          isScroll={false}
          pagination={false}
          className="tableItemPickup"
          size="small"
          expandable={{
            rowExpandable: (product: any) => product?.groups?.length > 0,
            expandedRowKeys: calculateDefaultExpandedRowKeys(products, {}),
            // defaultExpandedRowKeys: true,
          }}
          rowClassName={(record: any, index: number) => {
            let rowClassName = "rowAwesomeTable";
            if (record.isGift) {
              rowClassName += " freeGiftProductRow";
            }
            if (index === dataSource(products)?.length - 1) {
              rowClassName += " lastRowClassName";
            }
            return rowClassName;
          }}
        />
      );
    };
    return (
      <div className="contentItemContainer w-full mb-3">
        <label className="text-label">{t("pickUpItem")}</label>
        <div>{isPrint ? renderAntTable() : renderAwesomeTable()}</div>
      </div>
    );
  };

  const renderPickUpTime = () => {
    return (
      <div className="contentItemContainer mb-3">
        <label className="text-label mb-3" style={{ fontSize: "14px" }}>
          {t("pickUpTime")}
        </label>
        <DateInput
          classNameLabel="subTitle2 titleDateInput"
          onChange={(date) => onChangeDataPickUp("pickupTime", date)}
          showTime
          label={t("estimatePickupTime")}
          value={pickupData?.pickupTime ?? null}
          format="DD/MM/YYYY HH:mm"
          disabled={isReadOnly}
        />
      </div>
    );
  };

  const renderPickUpPerson = () => {
    const { personName = null, personPhone = null, notes } = pickupData;
    return (
      <div className="contentItemContainer mb-3">
        <label className="text-label mb-3" style={{ fontSize: "14px" }}>
          {t("pickUpPerson")}
        </label>
        <div className="personInfoContainer">
          <InputText
            label={t("name")}
            // variant="outlined"
            value={personName}
            onChange={(event) =>
              onChangeDataPickUp("personName", event.target.value)
            }
            className="personInput mb-3"
            disabled={isReadOnly}
          />
          <div style={{ width: "2rem" }} />
          <InputText
            label={t("phone")}
            // variant="outlined"
            value={personPhone}
            onChange={(event) =>
              onChangeDataPickUp("personPhone", event.target.value)
            }
            className="personInput mb-3"
            disabled={isReadOnly}
          />
        </div>
        <InputText
          label={t("note")}
          multiple
          rows={5}
          // variant="outlined"
          value={notes}
          onChange={(event) => onChangeDataPickUp("notes", event.target.value)}
          className="personInputNote mb-3"
          disabled={isReadOnly}
        />
      </div>
    );
  };
  const renderSecontTitle = () => {
    return (
      <Button
        iconName="print"
        onClick={() => {
          onClose && onClose();
          onClickPickUpPrint(pickup);
        }}
      >
        {t("print")}
      </Button>
    );
  };
  return (
    <>
      {isPrint ? (
        renderPickUpItem()
      ) : (
        <Drawer
          title={t("storePickup")}
          open={open}
          onClose={onClose}
          width={900}
          size="auto"
          onSave={() => onSave(pickupData)}
          hideFooter={isReadOnly}
        >
          <div className="PickupDrawerContainer">
            {renderPickUpLocation()}
            {renderPickUpItem()}
            {renderPickUpTime()}
            {renderPickUpPerson()}
          </div>
        </Drawer>
      )}
    </>
  );
};
export default PickupDrawer;
