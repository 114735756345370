import Drawer from "@components/shared/Drawer";
import { OrderType } from "@constants/common";
import { DELIVERY_STATUS_LIST } from "@constants/order";
import { getDeliveryStatusList } from "@helpers/account";
import AppContext from "@helpers/context";
import {
  updateStatusContractDelivery,
  updateStatusDelivery,
} from "@network/api/delivery";
import {
  Notifications,
  DateInput,
  InputText,
  RadioGroup,
} from "d-react-components";
import { isEmpty } from "lodash";
import moment, { Moment } from "moment";
import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const UpdateDeliveryStatusModal = ({
  openModal,
  onClose,
  deliveryTracking,
  orderType = OrderType.NORMAL,
}: any) => {
  const { onReloadOrderData, onReloadOrderDelivery } = useContext(AppContext);
  const [deliveryStatus, setDeliveryStatus] = useState(
    deliveryTracking?.status ?? null
  );
  const [arrived, setArrived] = useState<Moment | null>(moment());
  const deliveryStatusList = useRef(getDeliveryStatusList());
  const remark = useRef("");
  const { t } = useTranslation();

  const onClickSaveUpdateDeliveryHanlde = async () => {
    if (isEmpty(remark.current)) {
      Notifications.showError(t("notification:needAllRequiredInfo"));
      return Promise.reject();
    }
    const arrivedTime = isShowDateInput() && arrived ? arrived.valueOf() : null;
    const body = {
      id: deliveryTracking.id,
      status: deliveryStatus,
      remark: remark.current,
      arrived: arrivedTime,
    };
    const api =
      orderType === OrderType.NORMAL
        ? updateStatusDelivery
        : updateStatusContractDelivery;
    return api(body).then(
      (res) => {
        onReloadOrderData && onReloadOrderData();
        onReloadOrderDelivery && onReloadOrderDelivery();
        Notifications.showSuccess(
          t("notification:updateStatusDeliverySuccessed")
        );
        onClose();
      },
      (err) => {
        Notifications.showError(err);
      }
    );
  };

  const isShowDateInput = () => {
    return deliveryStatus === "completed" || deliveryStatus === "return";
  };

  return (
    <Drawer
      title={t("updateDeliveryStatus")}
      open={openModal}
      onClose={() => onClose()}
      onSave={() => onClickSaveUpdateDeliveryHanlde()}
    >
      <RadioGroup
        value={deliveryStatus}
        onChange={(value) => setDeliveryStatus(value)}
        dataSource={deliveryStatusList.current}
        getLabel={(status) => {
          const deliveryContent = DELIVERY_STATUS_LIST.find(
            (item: any) => item.key === status
          )?.content;
          return t(deliveryContent as string);
        }}
        getValue={(item) => item}
        numberOfColumns="1"
        label={t("deliveryStatus")}
        classNameItem="mt-0 mb-3"
      />

      {isShowDateInput() && (
        <div className="dateInputDeliveryStatus">
          <DateInput
            showTime
            value={arrived}
            onChange={(date) => setArrived(date)}
            label={t("actualDateArrival")}
            className="mb-3"
          />
        </div>
      )}
      <div className="dateInputDeliveryStatus">
        <InputText
          placeholder={t("pleaseInputTheNoteForThisUpdate")}
          onChange={(event) => (remark.current = event.target.value)}
          className="mb-3"
          multiple
          rows={5}
          label={t("remark")}
        />
      </div>
    </Drawer>
  );
};

export default UpdateDeliveryStatusModal;
