import Drawer, { DrawerProps } from "@components/shared/Drawer";
import { SelectInfinity } from "@components/shared/SelectInfinity";
import WarehouseInfo from "@components/shared/WarehouseInfo";
import { listUserStore } from "@network/api/store";
import UserAPI from "@network/api/user";
import { useTranslation } from "react-i18next";

interface IInventoryTableQuickFilter extends DrawerProps {
  values: any;
  onChange: any;
}

const MyIssuedDiscountOTPFilter = ({
  values = {},
  onChange,
  open,
  onClose,
  onSave,
}: IInventoryTableQuickFilter) => {
  const { t } = useTranslation();

  const onChangeValue = (newValues = {}) => {
    const result = { ...values, ...newValues };

    onChange(result);
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("filter")}
      onSave={onSave}
      size="auto"
      width={"600px"}
    >
      <div className="">
        <SelectInfinity
          mode="tags"
          multiple
          fetchFn={listUserStore}
          key="selectWarehouse"
          dataPath="stores"
          value={values?.warehouse}
          label={t("warehouse")}
          customQuery={(search) => ({
            search,
          })}
          getLabel={(item) => item?.name}
          dropdownHeight={540}
          getLabelDropdownItem={(item) => <WarehouseInfo warehouse={item} />}
          onChange={(val) => {
            onChangeValue({ warehouse: val });
          }}
          placeholder={t("pleaseSelect")}
          className="mb-3"
        />
        <SelectInfinity
          multiple
          mode="tags"
          fetchFn={UserAPI.list}
          key="selectStaff"
          dataPath="user"
          value={values?.issuedFor}
          label={t("issuedFor")}
          customQuery={(search) => ({
            search,
          })}
          getLabel={(item) =>
            item ? `${item.fullname} (${item?.employee_id})` : null
          }
          getLabelDropdownItem={(item) => (
            <div className="flex items-center">
              {item.avatar && (
                <img
                  src={item.avatar}
                  className="mr-2 w-[20px] h-[20px]"
                  alt="avatar"
                />
              )}
              {`${item.fullname} (${item?.employee_id})`}
            </div>
          )}
          onChange={(val) => {
            onChangeValue({ issuedFor: val });
          }}
          placeholder={t("pleaseSelect")}
          className="mb-3"
        />
      </div>
    </Drawer>
  );
};

export default MyIssuedDiscountOTPFilter;
