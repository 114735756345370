import FilterButtonGroup from "@common/FilterButtonGroup";
import AppContext from "@helpers/context";
import { checkProductBySKU } from "@network/api/product";
import { compact, isEmpty, split } from "lodash";
import React, { useRef, useState } from "react";
import CheckSKUUploadView from "./CheckSKUUploadView";
import CheckSKUCopyPaste from "./CheckSkuCopyPaste";
import { Notifications } from "d-react-components";
import { useTranslation } from "react-i18next";
import CheckSKUTable from "./CheckSKUTable";

export enum CheckSkuTab {
  COPY_PASTE = "checkViaCopyPaste",
  UPLOAD = "checkViaUpload",
}
export const CHECK_SKU_TABS = [
  {
    id: CheckSkuTab.COPY_PASTE,
    label: "checkViaCopyPaste",
  },
  {
    id: CheckSkuTab.UPLOAD,
    label: "checkViaUpload",
  },
];

const CheckSKU = () => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(CHECK_SKU_TABS[0].id);
  const [fileResults, setFileResults] = useState<any[]>([]);
  const [copyPasteData, setCopyPasteData] = useState();
  const [uploadSkuData, setUploadSkuData] = useState<any[]>([]);
  const [onProgress, setOnProgress] = useState(false);
  const [pasteDataResult, setPasteDataResult] = useState<any[]>([]);
  const [uploadDataResult, setUploadDataResult] = useState<any[]>([]);
  const [numberOfTypeItem, setNumberOfTypeItem] = useState({
    existed: 0,
    noExisted: 0,
    duplicated: 0,
    existed_1: 0,
    noExisted_1: 0,
    duplicated_1: 0,
  });
  const checkSKUResultTable = useRef<any>();

  const getResutlData = (skuList: any[]) => {
    checkProductBySKU({ sku: skuList })
      .then((res) => {
        const checkedPro = res?.data?.data?.products ?? [];
        const displayList = skuList.map((skuItem) => {
          const findPro = checkedPro.find((item: any) => item.sku === skuItem);
          const isDuplicate =
            skuList.filter((item) => item === skuItem)?.length > 1;
          let result = { sku: skuItem, status: "notExisted" };
          if (findPro) {
            result = { ...findPro, status: "existed" };
          }
          if (isDuplicate) {
            result = { ...result, status: "duplicated" };
          }
          return result;
        });
        let numberExisted = 0;
        let numberNoExisted = 0;
        let numberDuplicated = 0;
        displayList.forEach((item) => {
          switch (item.status) {
            case "existed":
              numberExisted++;
              break;
            case "notExisted":
              numberNoExisted++;
              break;
            case "duplicated":
              numberDuplicated++;
              break;
            default:
          }
        });
        selectedTab === CheckSkuTab.COPY_PASTE
          ? setNumberOfTypeItem({
              ...numberOfTypeItem,
              existed: numberExisted,
              noExisted: numberNoExisted,
              duplicated: numberDuplicated,
            })
          : setNumberOfTypeItem({
              ...numberOfTypeItem,
              existed_1: numberExisted,
              noExisted_1: numberNoExisted,
              duplicated_1: numberDuplicated,
            });
        selectedTab === CheckSkuTab.COPY_PASTE
          ? setPasteDataResult(displayList)
          : setUploadDataResult(displayList);
        checkSKUResultTable?.current && checkSKUResultTable.current.refresh();
        setOnProgress(false);
      })
      .catch((error) => {
        console.log({ error });
        setOnProgress(false);
      });
  };

  const handleOnClickCheckUploadData = () => {
    const skuList = uploadSkuData.map((item) => item?.sku);
    const sku = compact(skuList);
    setOnProgress(true);
    getResutlData(sku);
  };

  const handleOnClickCheckPasteData = () => {
    const result = split(copyPasteData, "\n");
    const skuListPaste = compact(result);
    if (skuListPaste?.length > 1000) {
      Notifications.showError(t("skuListIsNotMoreThan"));
      return;
    }
    if (!isEmpty(skuListPaste)) {
      getResutlData(skuListPaste);
      setOnProgress(true);
    }
  };

  return (
    <AppContext.Provider
      value={{
        selectedTab,
        fileResults,
        setFileResults,
        uploadSkuData,
        setUploadSkuData,
        onProgress,
        handleOnClickCheckUploadData,
        handleOnClickCheckPasteData,
        numberOfTypeItem,
        pasteDataResult,
        uploadDataResult,
        copyPasteData,
        setCopyPasteData,
      }}
    >
      <div className="bg-white">
        <div className="flex px-4 py-3 border-b">
          <FilterButtonGroup
            options={CHECK_SKU_TABS}
            className="border-bottom"
            value={selectedTab}
            showAll={false}
            onChange={(tab: any) => setSelectedTab(tab)}
            valueKey="id"
          />
        </div>
        <div className="py-3 px-4">
          {selectedTab === CheckSkuTab.COPY_PASTE && <CheckSKUCopyPaste />}
          {selectedTab === CheckSkuTab.UPLOAD && <CheckSKUUploadView />}
          <CheckSKUTable />
        </div>
      </div>
    </AppContext.Provider>
  );
};

export default CheckSKU;
