import Drawer from "@components/shared/Drawer";
import { DrawerProps } from "@interfaces/common";
import KpiAPI from "@network/api/kpi";
import { AwesomeTableComponent, TimeUtils } from "d-react-components";
import moment from "moment";
import React, { useMemo, useRef } from "react";
import CurrencyFormat from "react-currency-format";
import { useTranslation } from "react-i18next";

const KPIHistory = ({
  open,
  onClose,
  kpi,
  user,
}: DrawerProps & { kpi: any; user: any }) => {
  const { t } = useTranslation();
  const tableRef = useRef<any>();
  const transformer = (res: any) => {
    return res?.data?.data?.kpi ?? [];
  };
  const source = (paging: any) => {
    const body = {
      admin_id: user?.id,
      date: kpi.date,
    };
    return KpiAPI.detailKpiStaff(paging, body);
  };

  const columns = useMemo(
    () => [
      {
        title: t("year"),
        dataIndex: "date",
        width: "80px",
        render: (data: any) => moment(data, "YYYY-MM-DD").format("YYYY"),
      },
      {
        title: t("month"),
        dataIndex: "date",
        width: "80px",
        render: (data: any) => moment(data, "YYYY-MM-DD").format("MM"),
      },
      {
        title: t("branchWarehouse"),
        dataIndex: "warehouse",
        render: (data: any) => data?.name,
      },
      {
        title: t("targetKPI"),
        dataIndex: "kpi_target",
        width: "150px",
        render: (data: string) => (
          <div className="flex items-center">
            <CurrencyFormat
              value={data}
              displayType="text"
              thousandSeparator={true}
            />
          </div>
        ),
      },
      {
        id: "updated",
        dataIndex: "updated",
        width: "160px",
        title: t("updatedAt"),
        render: (data: any, item: any) => TimeUtils.convertMiliToDateTime(data),
      },
    ],
    [t]
  );

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("kpiHistory")}
      size="auto"
      width={800}
    >
      <AwesomeTableComponent
        ref={(ref) => {
          tableRef.current = ref;
        }}
        transformer={transformer}
        source={source}
        columns={columns}
        tableLayout="fixed"
        className="mt-3"
      />
    </Drawer>
  );
};

export default KPIHistory;
