import { API } from "@network";
import {
  API_MANAGER_CODE_USAGE_LIST,
  API_MANAGER_CODE_USAGE_REPORT,
} from "@network/URL";

const DiscountCodeAPI = {
  codeUsageList: (body: any, paging: any) => {
    return API.post(
      `${API_MANAGER_CODE_USAGE_LIST}?page=${paging.pageIndex}&per_page=${paging.pageSize}`,
      body
    );
  },

  codeUsageReport: (body: any, paging: any) => {
    return API.post(
      `${API_MANAGER_CODE_USAGE_REPORT}?page=${paging.pageIndex}&per_page=${paging.pageSize}`,
      body
    );
  },
};

export default DiscountCodeAPI;
