import {
  SERIAL_NUMBER_STATUS,
  SERIAL_NUMBER_STATUSES,
} from "@constants/serial-number";
import AppContext from "@helpers/context";
import {
  AvatarName,
  TimeUtils,
  ViewLabelStatus,
  ViewRowInterchange,
} from "d-react-components";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

const SerialNumberConfirm = () => {
  const { serialDetail } = useContext(AppContext);
  const { id, info, product, serial_number } = serialDetail ?? {};
  const { status, by, created } = info ?? {};
  const { t } = useTranslation();

  const SERIAL_NUMBER_CONFIRM_KEY = [
    {
      id: "info",
      label: t("status"),
      renderContent: ({ data }: any) => {
        const { status } = data || {};
        return (
          <div className="flex-row justify-content-end">
            <ViewLabelStatus
              status={status}
              listStatus={SERIAL_NUMBER_STATUSES}
              getLabel={(item) => t(item.label)}
            />
          </div>
        );
      },
    },
    {
      id: "info",
      label: t("updatedAt"),
      renderContent: ({ data }: any) => {
        const { status_at } = data || {};
        return status_at ? TimeUtils.toDateTime(status_at) : "N/A";
      },
    },
  ];

  if (
    status === SERIAL_NUMBER_STATUS.ACTIVATED ||
    status === SERIAL_NUMBER_STATUS.CANCELLED
  ) {
    SERIAL_NUMBER_CONFIRM_KEY.push({
      id: "info",
      label:
        status === SERIAL_NUMBER_STATUS.ACTIVATED
          ? t("activatedBy")
          : t("cancelledBy"),
      renderContent: ({ data }) => {
        const { status_by } = data || {};
        return (
          <div className="flex-row justify-content-end">
            {status_by ? (
              <AvatarName
                user={{ avatar: status_by?.avatar, fullName: status_by?.name }}
              />
            ) : (
              <div>N/A</div>
            )}
          </div>
        );
      },
    });
  }

  return (
    <div className="bg-white p-4 mt-2">
      <h5 className="mb-3">{t("status")}</h5>
      <ViewRowInterchange
        keyList={SERIAL_NUMBER_CONFIRM_KEY}
        dataSource={serialDetail}
        variant="border"
        classNameLabel="font-weight-bold "
        classNameContent="text-end "
        className="w-100"
      />
    </div>
  );
};

export default SerialNumberConfirm;
