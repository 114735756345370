/* eslint-disable jsx-a11y/alt-text */
import InputSelectKey from "@common/input/InputSelectKey";
import ChannelSelect from "@components/channel/components/ChannelSelect";
import { Path } from "@components/layout/Path";
import ChannelList from "@components/my-assets/requests/ChannelList";
import UserAvatarName from "@components/shared/UserAvatarName";
import {
  PROMOTION_FILTER_STATUSES,
  PROMOTION_STATUS,
  PROMOTION_STATUSES,
} from "@constants/promotion";
import AppContext from "@helpers/context";
import PromotionAPI from "@network/api/promotion";

import {
  AwesomeTableComponent,
  Button,
  IColumnsProps,
  TimeUtils,
  ViewLabelStatus,
  useFirstTime,
} from "d-react-components";

import { map, round } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, generatePath, useNavigate } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import { getInuseStatus } from "../detail/PromotionDetail";
import SelectFilter from "@common/dropdown/SelectFilter";
import { IChannel } from "@interfaces/channel";
import { getListChannel } from "@network/api/channel";
import moment from "moment";

const PromotionTable = () => {
  const [channelList, setChannelList] = useState<IChannel[]>([]);
  const tableRef = useRef<any>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showChannels, setShowChannels] = useState({
    open: false,
    channels: [],
  });

  const PROMOTIONS_SEARCH_KEYS = [
    { id: "name", label: t("title") },
    { id: "code", label: t("code") },
    { id: "sku", label: t("sku") },
  ];

  const [
    filterBody = {
      searchType: PROMOTIONS_SEARCH_KEYS[0].id,
    },
    setFilterBody,
  ] = useQueryParam<any>("filter", JsonParam);

  const isFirstTime = useFirstTime();

  useEffect(() => {
    loadChannelList();
  }, []);

  const loadChannelList = () => {
    getListChannel().then((res) => {
      const channelList = res?.data?.data?.channel ?? [];
      setChannelList(channelList);
    });
  };

  useEffect(() => {
    if (isFirstTime) return;
    tableRef.current?.refresh();
  }, [JSON.stringify(filterBody)]);

  const onChangeFilterBody = (newValue: any) => {
    setFilterBody({ ...(filterBody ?? {}), ...newValue });
  };

  const source = (paging: any) => {
    let body = {};
    if (filterBody) {
      const { textSearch, status, searchType, channel } = filterBody;
      body = {
        ...(status && status !== "all" ? { status } : {}),
        channel: channel,
        search_type: searchType,
        search_value: textSearch,
      };
    }

    return PromotionAPI.listPromotion(body, paging);
  };

  const columns: IColumnsProps = [
    {
      title: t("title"),
      dataIndex: "name",
      render: (name, promotion) => (
        <div>
          <Link
            to={generatePath(Path.PROMOTION_DETAIL, {
              promotionId: promotion.id,
            })}
          >
            {name}
          </Link>
          <div>{promotion?.code}</div>
        </div>
      ),
    },

    {
      title: t("channel"),
      dataIndex: "channels",
      render: (channels) => (
        <div
          className="border border-primary p-1 text-small d-flex text-primary flex-center text-small cursor-pointer"
          onClick={() => {
            setShowChannels({ open: true, channels });
          }}
        >
          {t("view")}
          <div
            className="bg-primary rounded-3 p-1 text-white text-xx-small flex-center ml-1"
            style={{ height: 15, width: 15 }}
          >
            {channels?.length}
          </div>
        </div>
      ),
    },
    {
      title: t("status"),
      dataIndex: "status",
      render: (status, promotion) => {
        const realStatus = getInuseStatus(promotion);
        return (
          <ViewLabelStatus
            status={realStatus}
            listStatus={PROMOTION_STATUSES}
            getLabel={(item) => t(item.label)}
          />
        );
      },
    },
    {
      title: t("period"),
      dataIndex: "start",
      render: (start, promotion) => {
        const numberOfDay = round(
          (promotion.end - start) / (1000 * 60 * 60 * 24)
        );
        const status = getInuseStatus(promotion);
        const dayLeft =
          (promotion.end - moment().valueOf()) / (1000 * 60 * 60 * 24);
        console.log(dayLeft);
        return (
          <div>
            {TimeUtils.toDate(start) + " - " + TimeUtils.toDate(promotion.end)}
            <div className="text-gray-400 text-xs flex items-center">
              {t("total")} {`${numberOfDay} ${t("days")}`}
              {status === PROMOTION_STATUS.IN_USE && (
                <>
                  <div className="mx-1 bg-gray-400 rounded w-[5px] h-[5px]"></div>
                  <span>
                    {Math.ceil(dayLeft)} {t("daysLeft")}
                  </span>
                </>
              )}
            </div>
          </div>
        );
      },
    },

    {
      title: t("createdAt"),
      dataIndex: "created",
      render: (created, promotion) => (
        <div className="d-flex flex-column">
          {TimeUtils.toDateTime(created)}
          <UserAvatarName user={promotion?.by} />
        </div>
      ),
    },
    {
      title: t("action"),
      dataIndex: "",
      render: (promotion) => (
        <div className="flex-row align-items-center text-primary cursor-default">
          <Link
            to={generatePath(Path.PROMOTION_DETAIL, {
              promotionId: promotion.id,
            })}
          >
            {t("view")}
          </Link>
          <div
            className="mx-2 bg-primary rounded"
            style={{ height: 5, width: 5 }}
          />
          <Link
            to={generatePath(Path.PROMOTION_CREATE_DUPLICATE, {
              promotionId: promotion.id,
            })}
          >
            {t("duplicate")}
          </Link>
        </div>
      ),
    },
  ];

  const renderHeader = useMemo(() => {
    return (
      <div className="w-full border px-3">
        <div className="flex-center-y py-3 border-b justify-between">
          <div className="flex-row align-items-center">
            <div className="flex-center-y gap-3">
              <h5 className="m-0">{t("status")}</h5>
              {map(PROMOTION_FILTER_STATUSES, (status) => {
                const isSelect =
                  filterBody?.status === status?.id ||
                  (status?.id === "all" && !filterBody?.status);
                return (
                  <Button
                    variant={isSelect ? "standard" : "outline"}
                    onClick={() => {
                      onChangeFilterBody({ status: status?.id });
                    }}
                  >
                    {t(status?.label)}
                  </Button>
                );
              })}
            </div>
          </div>
          <div className="flex-center-y">
            <Button
              iconName="add"
              onClick={() => {
                navigate(Path.PROMOTION_CREATE);
              }}
            >
              {t("new")}
            </Button>
          </div>
        </div>
        <div className="d-flex flex-center-y gap-3 w-100 py-3">
          {/* <ChannelSelect
            showLabel={false}
            onChange={(v) => {
              console.log("v", v);
              onChangeFilterBody({ channel: v });
            }}
            value={filterBody?.channel}
            style={{ width: "300px" }}
            placeholder={t("channel")}
            multiple
          /> */}
          <SelectFilter
            key="selectChannel"
            value={filterBody.channel}
            getLabel={(item) => item?.name}
            onChange={(val) => {
              console.log("val", val);
              onChangeFilterBody({ channel: val });
            }}
            placeholder={t("channel")}
            tagTitle={t("channel")}
            valueKey="name"
            dataSource={channelList}
            showSearch
            multiple
            style={{ width: "300px" }}
            onDropdownVisibleChange={(open) => {
              if (!open) {
                // onClickFilter();
              }
            }}
            // onClear={() => onClickFilter()}
          />
          <InputSelectKey
            className="w-100"
            placeholder="Search..."
            selectProps={{ placeholder: t("select") }}
            selectKey={filterBody.searchType}
            dataSource={PROMOTIONS_SEARCH_KEYS}
            getLabel={(i) => t(i?.label)}
            onChangeSelectKey={(v) => {
              onChangeFilterBody({ searchType: v });
            }}
            onSearch={(search: any) => {
              onChangeFilterBody({ textSearch: search });
            }}
            showSearch={false}
            inputProps={{}}
          />
        </div>
      </div>
    );
  }, [filterBody]);

  return (
    <AppContext.Provider value={{}}>
      <div className="bg-white">
        {renderHeader}
        <AwesomeTableComponent
          source={source}
          transformer={(res) => res?.data?.data?.promotion ?? []}
          isScroll={false}
          columns={columns}
          ref={(ref) => (tableRef.current = ref)}
        />
        {showChannels.open && (
          <ChannelList
            open={showChannels.open}
            channelList={showChannels.channels}
            onClose={() => setShowChannels({ open: false, channels: [] })}
          />
        )}
      </div>
    </AppContext.Provider>
  );
};

export default PromotionTable;
