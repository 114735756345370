import ButtonFileUpload from "@components/shared/ButtonFileUpload";
import Drawer from "@components/shared/Drawer";
import {
  ContractPaymentProofStatus,
  ContractPaymentStatus,
  ContractProofStatus,
} from "@constants/contract";
import { IMAGE_FORMAT } from "@constants/file";
import { PAYMENT_CONFIRM_STATUS } from "@constants/payment";
import AppContext from "@helpers/context";
import { isGrantURLPermission } from "@helpers/permissions";
import { uploadRentalInvoiceProofPaymentConfirmation } from "@network/api/rental";
import { API_UPLOAD_CONTRACT_PROOF_PAYMENT_CONFIRMATION } from "@network/URL";
import { Button, InputText, Notifications, Progress } from "d-react-components";
import { isEmpty } from "lodash";
import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const ConfirmProofButtons = ({ payload, invoice, onUploaded }: any) => {
  const { t } = useTranslation();
  const buttonUploadRef = useRef<any>(null);

  const CONTENT_PAYMENT = {
    [PAYMENT_CONFIRM_STATUS.APPROVE]: {
      title: t("confirmFullPay"),
      description: t("areCertainPaymentPaid"),
      saveText: t("confirm"),
    },
    [PAYMENT_CONFIRM_STATUS.REJECTED]: {
      title: t("confirmRejectPayment"),
      description: t("areCertainPaymentReject"),
      saveText: t("reject"),
    },
  };

  const { onReloadOrderData } = useContext(AppContext);
  const [openModal, setOpenModal] = useState<any>({
    type: undefined,
    isVisible: false,
  });
  const [attachments, setAttachments] = useState<any[]>([]);
  const remarkContent = useRef("");
  const { status_of_proof, id, method, proof_of_payment = [] } = payload;
  const isReject = status_of_proof === ContractPaymentProofStatus.REJECTED;
  const isApprove = status_of_proof === ContractPaymentProofStatus.APPROVED;

  const onClickSave = async () => {
    // if (
    //   openModal.type === PAYMENT_CONFIRM_STATUS.APPROVE &&
    //   !actualDatePayment
    // ) {
    //   Notifications.showError(t("notification:actualDatePaymentIsRequired"));
    //   return;
    // }

    // if (
    //   method === "bank-transfer" &&
    //   openModal.type === PAYMENT_CONFIRM_STATUS.APPROVE &&
    //   isEmpty(actualBankPayment)
    // ) {
    //   Notifications.showError(t("notification:actualBankPaymentIsRequired"));
    //   return;
    // }

    if (isEmpty(remarkContent.current)) {
      Notifications.showError(t("notification:remarkIsRequired"));
      return;
    }
    if (attachments.length === 0) {
      Notifications.showError(t("notification:attachmentIsRequire"));
      return;
    }

    let body: any = {
      id,
      remark: remarkContent.current,
      status:
        openModal?.type === PAYMENT_CONFIRM_STATUS.APPROVE
          ? ContractProofStatus.APPROVE
          : ContractProofStatus.REJECTED,
      attachment: attachments?.map((att: any) => att.key),
    };
    // if (openModal.type === PAYMENT_CONFIRM_STATUS.APPROVE) {
    //   body = {
    //     ...body,
    //     actualDatePayment: TimeUtils.convertDateTimeToMili(actualDatePayment),
    //   };

    //   if (method === "bank-transfer") {
    //     body = {
    //       ...body,
    //       actualBankPayment,
    //     };
    //   }
    // }
    const APIList = [
      { method: uploadRentalInvoiceProofPaymentConfirmation, params: body },
    ];

    Progress.show(APIList, (res) => {
      openModal?.type === PAYMENT_CONFIRM_STATUS.APPROVE
        ? Notifications.showSuccess(t("notification:paymentConfirmed"))
        : Notifications.showSuccess(t("notification:paymentRejected"));
      setOpenModal({ type: null, isVisible: false });
      onReloadOrderData && onReloadOrderData();
      onUploaded && onUploaded();
    });
  };

  // if (method === "2c2p") {
  //   return <></>;
  // }
  if (isApprove || isReject) {
    return <></>;
  }

  if (
    invoice.status &&
    invoice.status !== ContractPaymentStatus.PENDING &&
    invoice.status !== ContractPaymentStatus.PARTIALLY_PAID
  ) {
    return <div />;
  }

  if (proof_of_payment.length === 0) {
    return <div />;
  }

  if (!isGrantURLPermission(API_UPLOAD_CONTRACT_PROOF_PAYMENT_CONFIRMATION)) {
    return <div />;
  }
  // if (method === "2c2p") return <div />;

  const onClickConfirm = () =>
    setOpenModal({
      isVisible: true,
      type: PAYMENT_CONFIRM_STATUS.APPROVE,
    });

  const onClickReject = () => {
    setOpenModal({
      isVisible: true,
      type: PAYMENT_CONFIRM_STATUS.REJECTED,
    });
  };

  return (
    <div className="d-flex">
      <Button onClick={onClickConfirm} className="mr-3">
        {t("confirm")}
      </Button>
      <Button onClick={onClickReject} color="error">
        {t("reject")}
      </Button>

      <Drawer
        open={openModal.isVisible}
        onClose={() => setOpenModal({ isVisible: false })}
        onSave={onClickSave}
        saveText={CONTENT_PAYMENT?.[openModal.type]?.saveText}
        title={CONTENT_PAYMENT?.[openModal.type]?.title}
        size="auto"
        width="500"
      >
        <div className="flex-column pb-12">
          <div className="text-x-small">
            {CONTENT_PAYMENT?.[openModal.type]?.description}
          </div>
          <ButtonFileUpload
            onChange={(file: any) => setAttachments(file)}
            containerClassName="d-flex flex-column"
            ref={buttonUploadRef}
            inputParam={{ accept: IMAGE_FORMAT }}
            classNameItem="!w-24 !h-24"
          />

          <InputText
            className="mt-3"
            label={t("remark")}
            onChange={(event) => (remarkContent.current = event.target.value)}
            multiple
            placeholder={t("pleaseInputTheNote")}
          />
        </div>
      </Drawer>
    </div>
  );
};

export default ConfirmProofButtons;
