import Drawer from "@components/shared/Drawer";
import AppContext from "@helpers/context";
import { DrawerProps } from "@interfaces/common";
import { DateInput } from "d-react-components";
import moment from "moment";
import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const ScheduledDeliveryDate = ({ open, onClose }: DrawerProps) => {
  const { t } = useTranslation();
  const { values, errors, setFieldValue, shippingProfile } =
    useContext(AppContext);
  const { scheduled_delivery_date } = values;
  const shipping = shippingProfile?.[0];
  const { province } = shipping || {};
  const { scheduled_delivery_min, scheduled_delivery_max } = province || {};
  const [scheduledDate, setScheduledDate] = useState(scheduled_delivery_date);

  const minDate = useMemo(() => {
    return moment().add(scheduled_delivery_min, "days");
  }, [scheduled_delivery_min]);
  const maxDate = useMemo(() => {
    return moment(minDate).add(scheduled_delivery_max, "days");
  }, [minDate, scheduled_delivery_max]);

  const handleSubmit = () => {
    setFieldValue("scheduled_delivery_date", scheduledDate);
    onClose();
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("scheduledDeliveryDate")}
      destroyOnClose
      onSave={() => {
        handleSubmit();
      }}
    >
      <DateInput
        value={scheduledDate}
        onChange={(value) => setScheduledDate(value)}
        label={t("scheduledDeliveryDate")}
        error={errors?.scheduled_delivery_date as any}
        required
        format="DD/MM/YYYY"
        disabledDate={(d) => (d && d.isBefore(minDate)) || d.isAfter(maxDate)}
      />
    </Drawer>
  );
};

export default ScheduledDeliveryDate;
