import { Path } from "@components/layout/Path";
import Drawer from "@components/shared/Drawer";
import ChannelAPI from "@network/api/channel";
import { AwesomeTableComponent, InputTextSearch } from "d-react-components";
import { debounce, map } from "lodash";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, generatePath } from "react-router-dom";

function ChannelDetailWarehouseUpdateDrawer({
  open,
  onClose,
  title,
  channelId,
  type,
  onSave,
}: any) {
  const { t } = useTranslation();

  const tableRef = useRef<any>();
  const textSearch = useRef("");
  const [warehouseSelect, setWarehouseSelect] = useState<any>([]);

  const columns = [
    {
      title: t("name"),
      dataIndex: "name",
      render: (name: string, warehouse: any) => (
        <Link
          to={generatePath(Path.WAREHOUSE_DETAIL, {
            warehouseId: warehouse.id,
          })}
        >
          {name}
        </Link>
      ),
    },
    {
      dataIndex: "warehouse_system_code",
      title: t("systemCode"),
      width: 130,
    },
    {
      dataIndex: "code",
      title: t("navArCode"),
      width: 130,
    },
    {
      dataIndex: "pickup_code",
      title: t("navPickupCode"),
      width: 130,
    },
  ];

  const source = (paging: any) => {
    const body = { action: type, id: channelId, search: textSearch.current };
    return ChannelAPI.warehouseList(body, paging);
  };

  const transformer = (res: any) => {
    return res?.data?.data?.warehouse ?? [];
  };

  const onChangeTextSearch = debounce((text) => {
    textSearch.current = text;
    tableRef.current.refresh();
  }, 300);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={title}
      onSave={() => onSave(warehouseSelect)}
      size="auto"
      width="50vw"
    >
      <InputTextSearch
        className="mt-3"
        placeholder={t("search")}
        onChange={(e: any) => onChangeTextSearch(e.target.value)}
      />
      <AwesomeTableComponent
        ref={(ref) => {
          tableRef.current = ref;
        }}
        columns={columns}
        source={source}
        transformer={transformer}
        className="mt-3"
        rowSelection={{
          selectedRowKeys: map(warehouseSelect, (item) => item.id),
          onChange: (selectedRowKeys, selectRows) => {
            setWarehouseSelect(selectRows);
          },
          preserveSelectedRowKeys: true,
        }}
      />
    </Drawer>
  );
}

export default ChannelDetailWarehouseUpdateDrawer;
