import AppContext from "@helpers/context";
import RawMaterialAPI from "@network/api/raw-material";
import { Progress, TabBar } from "d-react-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import RawMaterialDetailGeneral from "./RawMaterialDetailGeneral";
import RawMaterialDetailHeader from "./RawMaterialDetailHeader";
import RawMaterialDetailInfo from "./RawMaterialDetailInfo";
import RawMaterialDetailMedia from "./RawMaterialDetailMedia";
import RawMaterialDetailPricing from "./RawMaterialDetailPricing";
import RawMaterialDetailProductAssign from "./RawMaterialDetailProductAssign";
import RawMaterialDetailSku from "./RawMaterialDetailSku";
import RawMaterialDetailActivity from "./RawMaterialDetailActivity";

const TABS = [
  {
    id: "general",
    label: "general",
    component: <RawMaterialDetailGeneral />,
  },

  {
    id: "media",
    label: "media",
    component: <RawMaterialDetailMedia />,
  },
  {
    id: "pricing",
    label: "pricing",
    component: <RawMaterialDetailPricing />,
  },
  {
    id: "sku",
    label: "sku",
    component: <RawMaterialDetailSku />,
  },
  {
    id: "productAssignTo",
    label: "productAssignTo",
    component: <RawMaterialDetailProductAssign />,
  },
  {
    id: "activity",
    label: "activity",
    component: <RawMaterialDetailActivity />,
  },
];

const RawMaterialDetail = () => {
  const [rawMaterialDetail, setRawMaterialDetail] = useState(null);

  const [tab, setTab] = useState<any>(TABS[0]);
  const { rawMaterialId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    loadRawMaterialDetail();
  }, []);

  const loadRawMaterialDetail = () => {
    Progress.show(
      { method: RawMaterialAPI.detail, params: [rawMaterialId] },
      (res: any) => {
        const request = res?.data?.data?.material;
        setRawMaterialDetail(request);
      }
    );
  };

  return (
    <AppContext.Provider
      value={{
        rawMaterialDetail,
        loadRawMaterialDetail,
      }}
    >
      <div className="flex-column-container bg-trans">
        <RawMaterialDetailHeader />
        <div className="grid grid-cols-12 gap-4 p-3 bg-white">
          <div className="col-span-4">{<RawMaterialDetailInfo />}</div>
          <div className="col-span-8 px-3">
            <TabBar
              dataSource={TABS}
              onChange={setTab}
              value={tab}
              getLabel={(item: any) => t(item.label)}
            />
            <div className="mt-3">{tab.component}</div>
          </div>
        </div>
      </div>
    </AppContext.Provider>
  );
};

export default RawMaterialDetail;
