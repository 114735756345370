import {
  AwesomeTableComponent,
  HeaderTable,
  Icon,
  Notifications,
  TimeUtils,
} from "d-react-components";
import { debounce, isEmpty } from "lodash";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import IssueDiscountOTPDrawer from "./IssueDiscountOTPDrawer";
import DiscountOTPDrawer from "./DiscountOTPDrawer";
import { listIssuedDiscountCode } from "@network/api/discount";
import UserAvatarName from "@components/shared/UserAvatarName";
import { Popover } from "antd";
import WarehouseInfo from "@components/shared/WarehouseInfo";
import MyIssuedDiscountOTPFilter from "./MyIssuedDiscountOTPFilter";
import { DiscountType } from "@interfaces/common";
import { Link } from "react-router-dom";

interface FilterBodyProps {
  warehouse?: any[];
  issuedFor?: any[];
}

const MyIssuedDiscountOTP = () => {
  const [openFilter, setOpenFilter] = useState(false);
  const [filterBody = {}, setFilterBody] = useState<FilterBodyProps>({});
  const tableRef = useRef<any>(null);
  const textSearchRef = useRef("");
  const [openIssueDiscountOTPDrawer, setOpenIssueDiscountOTPDrawer] =
    useState(false);
  const [openDiscountOTPDrawer, setOpenDiscountOTPDrawer] = useState(false);
  const [currentOTPCode, setCurrentOTPCode] = useState<any[]>();
  const { t } = useTranslation();

  const handleChangeTextSearch = debounce((event) => {
    textSearchRef.current = event.target.value;
    tableRef.current.refresh();
  }, 500);

  const dataSource = (paging: any) => {
    return listIssuedDiscountCode({
      search: textSearchRef.current,
      page: paging.pageIndex,
      size: paging.pageSize,
      ...(filterBody.warehouse && {
        warehouse: filterBody.warehouse.map((w) => w.id),
      }),
      ...(filterBody.issuedFor && {
        issued_for: filterBody.issuedFor.map((u) => u.id),
      }),
    });
  };

  const columns = useMemo(
    () => [
      {
        title: t("code"),
        dataIndex: "code",
        width: 150,
        render: (code: any, item: any) => (
          <div className="flex">
            <span
              className="py-1 px-2 !border-dashed !border-[1px] !border-gray-500 rounded cursor-pointer"
              onClick={() => {
                setCurrentOTPCode(item);
                setOpenDiscountOTPDrawer(true);
              }}
            >
              {code}
            </span>
            <div className="flex items-center justify-center ml-1">
              <Icon
                name="content_copy"
                className="bg-primary text-white p-[7px] rounded cursor-pointer"
                onClick={() => {
                  navigator.clipboard.writeText(code ?? "");
                  Notifications.showSuccess(t("notification:copySuccess"));
                }}
              />
            </div>
          </div>
        ),
      },
      {
        title: t("discount"),
        dataIndex: "discount_value",
        width: 150,
        render: (discountValue: any, code: any) => (
          <span className="bg-primary px-2 py-1 ml-2 text-white">
            {code.discount_value}
            {code.discount_type === DiscountType.Percentage ? "%" : "THB"}
          </span>
        ),
      },
      {
        title: t("warehouse"),
        dataIndex: "warehouse",
        render: (warehouse: any) => (
          <div className="flex items-center">
            {warehouse.name}{" "}
            <Popover
              content={
                <WarehouseInfo warehouse={warehouse} showSelect={false} />
              }
              trigger="click"
            >
              <span className="cursor-pointer">
                <Icon name="info" className="text-primary" />
              </span>
            </Popover>
          </div>
        ),
      },
      {
        title: t("issuedFor"),
        dataIndex: "issued_for",
        render: (issuedFor: any) => (
          <>
            <UserAvatarName user={issuedFor} />
          </>
        ),
      },
      {
        title: t("createdAt"),
        dataIndex: "created",
        render: (created: any) => <>{TimeUtils.toDateTime(created)}</>,
      },
      {
        title: t("expiresAt"),
        dataIndex: "expired_at",
        render: (expired: any) => <>{TimeUtils.toDateTime(expired)}</>,
      },
      {
        title: t("order"),
        dataIndex: "order",
        render: (order: any) =>
          order ? (
            <Link to={`/order/${order.id}`}>{order?.orderid}</Link>
          ) : (
            "N/A"
          ),
      },
    ],
    [t]
  );

  return (
    <div className="bg-white">
      <HeaderTable
        label={t("myIssuedDiscountOTP")}
        onClickNew={() => setOpenIssueDiscountOTPDrawer(true)}
        onChangeText={handleChangeTextSearch}
        className="app-layout__page-header"
        onClickFilter={() => setOpenFilter(true)}
        isFiltered={
          !isEmpty(filterBody.issuedFor) && !isEmpty(filterBody.warehouse)
        }
      />
      <AwesomeTableComponent
        classNameTable="order-table"
        source={dataSource}
        transformer={(response) => {
          return response?.data?.data?.discount ?? [];
        }}
        columns={columns}
        ref={tableRef}
        tableLayout="fixed"
        className="mt-3"
      />
      {openIssueDiscountOTPDrawer && (
        <IssueDiscountOTPDrawer
          open={openIssueDiscountOTPDrawer}
          onClose={() => setOpenIssueDiscountOTPDrawer(false)}
          onSave={() => {
            setOpenIssueDiscountOTPDrawer(false);
            tableRef.current.refresh();
          }}
        />
      )}
      {openDiscountOTPDrawer && (
        <DiscountOTPDrawer
          open={openDiscountOTPDrawer}
          onClose={() => setOpenDiscountOTPDrawer(false)}
          code={currentOTPCode}
        />
      )}
      {openFilter && (
        <MyIssuedDiscountOTPFilter
          values={filterBody}
          onChange={setFilterBody}
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          onSave={() => {
            setOpenFilter(false);
            tableRef.current.refresh();
          }}
        />
      )}
    </div>
  );
};
export default MyIssuedDiscountOTP;
