import UserAuthQrCode from "@common/UserAuthQrCode";
import { PDFExport } from "@progress/kendo-react-pdf";
import { Modal, TimeUtils } from "d-react-components";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

const UserDetailAuthenticationExportModal = ({ open, onClose, user }: any) => {
  const { fullname, name, employee_id, authentication_code } = user;
  const { t } = useTranslation();
  const pdfUserAuthRef = useRef<any>();

  const beforePDFPrinting = () => {
    const cc = document.getElementsByClassName("user-detail__auth-code");
    for (let i = 0; i < cc.length; i++) {
      const svg = cc[i];
      const rect = svg.getBoundingClientRect();

      const img = document.createElement("img");
      img.src = `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svg.outerHTML)))}`;
      // img.style = `height:100px;width:100px;margin-top:16px`;
      img.className = "remove-after-print";
      if (svg.parentNode) {
      svg.parentNode.insertBefore(img, svg);
      }
    }
  };

  const onClickExport = async () => {
    beforePDFPrinting();
    pdfUserAuthRef.current.save();
    afterPDFPrinting();
    return Promise.resolve();
  };

  const afterPDFPrinting = () => {
    setTimeout(() => {
      const imagePrintE = document.querySelector(".remove-after-print");
      if (imagePrintE && imagePrintE.parentElement){
      imagePrintE.parentElement.removeChild(imagePrintE);
      }
    }, 300);
  };

  const renderInfoRow = (title: any, content: any) => (
    <div className="d-flex border-bottom mt-3 py-3">
      <text className="text-bold w-50">{title}</text>
      <text className="w-50">{content}</text>
    </div>
  );

  if (!user) return <div />;

  return (
    <Modal
      open={open}
      onClose={onClose}
      onSave={onClickExport}
      title={t("exportPdf")}
      saveText={t("export")}
    >
      <PDFExport
        paperSize="auto"
        ref={(ref) => (pdfUserAuthRef.current = ref)}
        margin={20}
        fileName={`${employee_id}_${fullname}_${TimeUtils.convertMiliToDate(
          new Date().getTime()
        )}`}
        author="Lotus Team"
      >
        <div className="d-flex flex-column">
          <h4 style={{ fontWeight: "bold" }}>{t("saleInfinityXAuth")}</h4>
          <div className="mt-3">{t("pleaseGotoAppStore")}</div>
          {renderInfoRow(t("fullName"), fullname)}
          {renderInfoRow(t("nickName"), name)}
          {renderInfoRow(t("employeeID"), employee_id)}
          <div className="position-relative mt-4 mb-4">
            <UserAuthQrCode
              secretCode={authentication_code}
              name={name}
              className="user-detail__auth-code"
            />
          </div>
          <text className="mt-3">{t("forYourOwnAccountSecurity")}</text>
        </div>
      </PDFExport>
    </Modal>
  );
};

export default UserDetailAuthenticationExportModal;
