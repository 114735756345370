import Drawer from "@components/shared/Drawer";
import UserSelect from "@components/user/components/UserSelect";
import { ORDER_STATUS_ARRAY } from "@constants/order";
import classNames from "classnames";
import { DateInput, Select } from "d-react-components";
import { useFormik } from "formik";
import moment from "moment";
import { useTranslation } from "react-i18next";

const INIT_FILTER = {
  from: undefined,
  to: undefined,
  channels: [],
  status: [],
  warehouse: [],
  createdBy: [],
};
function CashTransactionFilterDrawer({
  onClose,
  openModal,
  onFilter,
  filterBodyDefault = INIT_FILTER,
}: any) {
  const { t } = useTranslation();
  const filterForm = useFormik<any>({
    initialValues: filterBodyDefault as any,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: schema,
    onSubmit: (values: any) => {},
  });
  const formValues = filterForm?.values;
  const formErrors = filterForm?.errors;

  //**************************************************RENDER UI************************************** */
  const inputClassName = classNames("mt-2 col-span-1");

  const renderFilterContent = () => {
    return (
      <div className="grid grid-cols-2 gap-4">
        <DateInput
          label={t("from")}
          className={inputClassName}
          onChange={(date) => {
            let result = date;
            if (!result) {
              result = moment();
            }
            filterForm.setFieldValue("from", result);
          }}
          value={formValues?.from}
        />

        <DateInput
          label={t("to")}
          className={inputClassName}
          onChange={(date) => {
            let result = date;
            if (!result) {
              result = moment();
            }
            filterForm.setFieldValue("to", result);
          }}
          value={formValues?.to}
        />

        <Select
          label={t("status")}
          dataSource={ORDER_STATUS_ARRAY}
          getLabel={(option) => t(option.label)}
          onChange={(value) => {
            filterForm.setFieldValue("status", value);
          }}
          className={"mt-2 col-span-2"}
          value={formValues?.status ?? []}
          multiple
        />

        <UserSelect
          label={t("createdBy")}
          onChange={(value) => {
            filterForm.setFieldValue("createdBy", value);
          }}
          className={"mt-2 col-span-2"}
          value={formValues?.createdBy ?? []}
          multiple
        />
      </div>
    );
  };

  const onClickFilterHandle = () => {
    onFilter(formValues);
    onClose();
  };

  return (
    <Drawer
      saveText={t("filter")}
      open={openModal}
      onClose={onClose}
      title={t("filter")}
      onSave={onClickFilterHandle}
    >
      {renderFilterContent()}
    </Drawer>
  );
}
export default CashTransactionFilterDrawer;
