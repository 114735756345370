/* eslint-disable react/jsx-no-duplicate-props */
import { getLocationFromAddress } from "@network/api/address";
import { Icon, InputText } from "d-react-components";
import GoogleMapReact from "google-map-react";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PlacesAutocomplete from "react-places-autocomplete";

const LONGITUDE_DEFAULT = 100.4930264;
const LATITUDE_DEFAULT = 13.7248936;
const DEFAULT_ZOOM = 17;

const SelectLocationMapModal = ({ location, onChange }: any) => {
  const [selectedLocation, setSelectedLocation] = useState({
    ...location,
    latitude: location?.latitude || LATITUDE_DEFAULT,
    longitude: location?.longitude || LONGITUDE_DEFAULT,
    zoom: DEFAULT_ZOOM,
  });
  const [addressSearch, setAddressSearch] = useState("");
  const mapsRef = useRef<any>(null);
  const mapRef = useRef<any>(null);
  const markerCenter = useRef<any>(null);
  const isChangeLocationOutside = useRef<any>(true);
  const { t } = useTranslation();

  useEffect(() => {
    isChangeLocationOutside.current = true;
    setCenterMap(location);
  }, [location]);

  const onChangeLocation = (locationParam: any) => {
    if (isChangeLocationOutside.current) {
      isChangeLocationOutside.current = false;
      return;
    }
    if (
      selectedLocation?.zoom &&
      selectedLocation.zoom !== locationParam.zoom
    ) {
      setSelectedLocation(locationParam);
      return;
    }
    const result = {
      latitude: (locationParam?.center?.lat ?? 0) % 90,
      longitude: (locationParam?.center?.lng ?? 0) % 180,
      zoom: locationParam.zoom,
    };

    setSelectedLocation(locationParam);
    onChange(result);
  };

  const handleApiLoaded = (map: any, maps: any) => {
    mapRef.current = map;
    mapsRef.current = maps;
    setCenterMap(location);
  };

  const setCenterMap = (location: any) => {
    if (!mapRef.current) {
      return;
    }
    mapRef.current.setOptions({
      disableDoubleClickZoom: true,
      fullscreenControlOptions: false,
      fullscreenControl: false,
      zoom: selectedLocation?.zoom ?? DEFAULT_ZOOM,
      center: {
        lat: location?.latitude || LATITUDE_DEFAULT,
        lng: location?.longitude || LONGITUDE_DEFAULT,
      },
      gestureHandling: "cooperative",
      scrollwheel: false,
    });
  };

  const renderCenterMarker = () => {
    const heightMap = selectedLocation?.size?.height;
    const widthMap = selectedLocation?.size?.width;
    const marginTop = heightMap ? heightMap / 2 - 40 : "41%";
    const marginRight = widthMap ? widthMap / 2 - 20 : "48%";
    return (
      <div className="absolute" style={{ top: marginTop, right: marginRight }}>
        <Icon
          name="location_on"
          className="text-red-500 h-[40px] w-[40px]"
          size="xxx-large"
        />
      </div>
    );
  };

  const addMarkerCenter = (location: any) => {
    if (!mapsRef.current || !mapRef.current) {
      return;
    }
    if (markerCenter.current) {
      markerCenter.current.setMap(null);
    }
    markerCenter.current = new mapsRef.current.Marker({
      position: {
        lat: location?.latitude ? location.latitude : LATITUDE_DEFAULT,
        lng: location?.longitude ? location.longitude : LONGITUDE_DEFAULT,
      },
      map: mapRef.current,
    });
  };

  const onLoadLocation = (address: string) => {
    if (_.isEmpty(address)) {
      return;
    }
    getLocationFromAddress(address).then((res) => {
      const locationResult = res?.data?.results?.[0]?.geometry?.location;
      if (locationResult) {
        onChange({
          latitude: locationResult.lat,
          longitude: locationResult.lng,
          zoom: selectedLocation?.zoom ?? DEFAULT_ZOOM,
        });
      }
    });
  };

  return (
    <div style={{ height: 350, width: "100%", position: "relative" }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_API_GOOGLE_KEY as string,
        }}
        defaultCenter={{
          lat: location?.latitude ? location.latitude : LATITUDE_DEFAULT,
          lng: location?.longitude ? location.longitude : LONGITUDE_DEFAULT,
        }}
        defaultZoom={DEFAULT_ZOOM}
        onChange={onChangeLocation}
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        yesIWantToUseGoogleMapApiInternals
      />

      {renderCenterMarker()}

      <PlacesAutocomplete
        value={addressSearch}
        onChange={(address) => setAddressSearch(address)}
        onSelect={(address) => {
          setAddressSearch(address);
          onLoadLocation(address);
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="searchLocationInput absolute top-2 left-2">
            <InputText
              {...getInputProps()}
              style={{ width: "100%", backgroundColor: "white" }}
              placeholder={t("searchGoogleLocationMap")}
              suffix={<Icon name="search" />}
              className="min-w-[200px]"
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions?.map((suggestion) => {
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className:
                        "subTitle2 addressSuggestionRow bg-white p-2 cursor-pointer",
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

export default SelectLocationMapModal;
// const styles = {
//   iconMarker: {
//     height: 40,
//     width: 40,
//     color: AppColors.pink,
//     position: "absolute",
//   },
// };
