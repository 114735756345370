import { Button, TimeUtils } from "d-react-components";
import React, { useContext, useState, useEffect, useMemo } from "react";
import UserDetailSpecialAccessModal from "./UserDetailSpecialAccessModal";
import AppContext from "@helpers/context";
import UserAPI from "@network/api/user";
import { isGrantURLPermission } from "@helpers/permissions";
import { API_USER_LOGIN_BY_CODE_UPDATE } from "@network/URL";
import { useTranslation } from "react-i18next";
import ImageIcon from "@common/Icon";

const UserDetailSpecialAccess = () => {
  const { user, loadUserDetail } = useContext(AppContext);
  const [openEdit, setOpenEdit] = useState(false);
  const { t } = useTranslation();
  const [specialAccess, setSpecialAccess] = useState<any>({});

  const loadSpecialAccess = () => {
    const body = { id: user?.id };
    return UserAPI.getSpecialAccess(body).then((res) => {
      setSpecialAccess(res?.data?.data?.admin ?? {});
    });
  };
  const isGrantPermission = useMemo(() => {
    return isGrantURLPermission(API_USER_LOGIN_BY_CODE_UPDATE);
  }, []);

  useEffect(() => {
    loadSpecialAccess();
  }, []);

  return (
    <div className="border mt-3 p-4">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h5>{t("specialAccess")}</h5>
        <span onClick={() => setOpenEdit(true)} hidden={!isGrantPermission}>
          <ImageIcon
            className="w-[15px] cursor-pointer"
            src="/images/icons/edit.svg"
          />
        </span>
      </div>
      <text>{t("specialAccessDescription")}</text>
      <div className="flex-column mt-3">
        <span className="font-semibold">{t("status")}: </span>
        <text>
          {specialAccess?.login_code_status ? t("enabled") : t("disabled")}
        </text>
      </div>
      <div
        className="flex-column mt-3"
        hidden={!specialAccess?.login_code_number}
      >
        <label>{t("specialAccessCode")}</label>
        <text>{specialAccess?.login_code_number}</text>
      </div>
      <div
        className="flex-column mt-3"
        hidden={!specialAccess?.login_code_expired}
      >
        <label>{t("specialAccessExpiredAt")}</label>
        <text>
          {TimeUtils.convertMiliToDateTime(specialAccess?.login_code_expired)}{" "}
        </text>
      </div>
      {openEdit && (
        <UserDetailSpecialAccessModal
          open={openEdit}
          onClose={() => setOpenEdit(false)}
          defaultValue={{
            status: specialAccess?.login_code_status,
            code: specialAccess?.login_code_number,
            expired: specialAccess?.login_code_expired,
          }}
          userId={user?.id}
          onSuccess={loadSpecialAccess}
        />
      )}
    </div>
  );
};

export default UserDetailSpecialAccess;
