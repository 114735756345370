import {
  CART_RULE_ACTION_CONDITION_OPTION,
  CART_RULE_CONDITION_DEFAULT,
} from "@constants/cart-rule";
import AppContext from "@helpers/context";
import { ObjectUtils } from "d-react-components";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import ConditionApplyType from "../component/condition/ConditionApplyType";
import ConditionList from "../component/condition/ConditionList";

const CONDITIONS_TYPE = ObjectUtils.mapObjectToArray(
  CART_RULE_ACTION_CONDITION_OPTION
);
const CartRuleActionCondition = () => {
  const { cartRuleForm } = useContext(AppContext);
  const formValues = cartRuleForm?.values ?? {};
  const { t } = useTranslation();
  const addConditionHandle = (option: any) => {
    const rawCondition = {
      ...(CART_RULE_CONDITION_DEFAULT as any)[option.key],
      id: Math.random(),
    };
    const conditions = formValues?.actions?.conditions ?? [];
    const results = [...conditions, rawCondition];
    const actionResult = {
      ...formValues?.actions,
      conditions: results,
    };
    cartRuleForm.setFieldValue("actions", actionResult);
  };

  const setChangeCondition = (conditions: any) => {
    const actionResult = {
      ...formValues.actions,
      conditions,
    };
    cartRuleForm.setFieldValue("actions", actionResult);
  };

  const onChangeRuleCondition = (value: any) => {
    const actionResult = {
      ...formValues.actions,
      condition_type: value,
    };
    cartRuleForm.setFieldValue("actions", actionResult);
  };

  return (
    <div className="mt-3">
      <div className="mb-3">{t("applyTheRuleOnlyToItemMatching")}</div>
      <ConditionApplyType
        value={formValues?.actions?.condition_type}
        onChangeApplyType={onChangeRuleCondition}
      />
      <ConditionList
        conditionOptions={CONDITIONS_TYPE}
        conditions={formValues?.actions?.conditions ?? []}
        setChangeCondition={setChangeCondition}
        addConditionHandle={addConditionHandle}
      />
    </div>
  );
};
export default CartRuleActionCondition;
