import ButtonFileUpload from "@components/shared/ButtonFileUpload";
import Drawer from "@components/shared/Drawer";
import { updateVehicleMedia } from "@network/api/vehicle";
import { Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import { filter, includes, map } from "lodash";
import { useTranslation } from "react-i18next";

const VehicleDetailGalleryEditDrawer = ({
  open,
  onClose,
  defaultValue,
  onUpdateSuccess,
}: any) => {
  const { t } = useTranslation();

  const vehicleForm = useFormik({
    initialValues: {
      attachments:
        map(defaultValue?.media, (item: any) => ({
          imageData: item?.attachment,
          id: item?.id,
        })) ?? [],
    },
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      const newMedia = filter(values?.attachments, (item: any) => item?.key);
      const oldMedia = filter(values?.attachments, (item: any) => !!item?.id);

      const existedMediaIds = map(defaultValue?.media, (item) => item.id);

      const deleteMedia = filter(
        existedMediaIds,
        (item) =>
          !includes(
            map(oldMedia, (oldItem: any) => oldItem?.id),
            item
          )
      );
      const input = {
        id: defaultValue?.id,
        upload: map(newMedia, (item: any) => item.key),
        delete: deleteMedia,
      };
      Progress.show({ method: updateVehicleMedia, params: [input] }, () => {
        Notifications.showSuccess(t("updateSuccess"));
        onUpdateSuccess();
        onClose();
      });
    },
  });

  const formValues = vehicleForm?.values;
  const formErrors = vehicleForm?.errors;

  const classNameInput = "mt-3 col-span-2";

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("general")}
      size="auto"
      width="50%"
      onSave={() => vehicleForm.handleSubmit()}
    >
      <div className="">
        <ButtonFileUpload
          defaultFiles={formValues?.attachments}
          onChange={(values: any) =>
            vehicleForm.setFieldValue("attachments", values)
          }
        />
      </div>
    </Drawer>
  );
};

export default VehicleDetailGalleryEditDrawer;
