import { API } from "@network";
import {
  API_LIST_ALL_USER,
  API_PROMOTION_STATUS_LIST,
  API_TRANSFER_STOCK_LIST_DRIVER,
  API_USER_ACTIVITIES,
  API_USER_ADD_ACTIVITY,
  API_USER_AUTHENTICATION_UPDATE,
  API_USER_CHANNEL_LIST,
  API_USER_CHANNEL_UPDATE,
  API_USER_CREATE,
  API_USER_DETAIL,
  API_USER_DUPLICATE,
  API_USER_LIST,
  API_USER_ORDER_MANA_LIST,
  API_USER_ORDER_MANA_UPDATE,
  API_USER_ROLE_LIST,
  API_USER_ROLE_UPDATE,
  API_USER_SECRET_CODE,
  API_USER_SO_COLLABORATION_UPDATE,
  API_USER_UPDATE,
  API_USER_UPDATE_PASSWORD,
  API_USER_WAREHOUSE_CHANNEL_CHECK,
  API_USER_SO_COLLABORATION_LIST,
  API_USER_LOGIN_BY_CODE_DETAIL,
  API_USER_LOGIN_BY_CODE_UPDATE,
  API_USER_WAREHOUSE_LIST,
  API_USER_WAREHOUSE_UPDATE,
  API_USER_WAREHOUSE_CHECK_ASSET_WAREHOUSE,
  API_USER_WAREHOUSE_CHECK_ASSET_CHANNEL,
  API_USER_IMPORT_FIELDS,
  API_USER_IMPORT_VALIDATE,
  API_USER_IMPORT,
} from "@network/URL";

export const listAllUser = (body: any, paging?: any) => {
  return API.post(
    `${API_LIST_ALL_USER}${
      paging ? `?page=${paging.pageIndex}&per_page=${paging.pageSize}` : ""
    }`,
    body
  );
};

export const getDriverList = (body: any) => {
  return API.post(API_TRANSFER_STOCK_LIST_DRIVER, body);
};

const UserAPI = {
  list: (body: any, paging: any) => {
    return API.post(
      `${API_USER_LIST}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
      body
    );
  },
  // export: (paging: any, body: any) => {
  //   return API.post(
  //     `${API_WAREHOUSE_EXPORT}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
  //     body
  //   );
  // },

  detail: (body: any) => {
    return API.post(API_USER_DETAIL, body);
  },

  update: (body: any) => {
    return API.post(API_USER_UPDATE, body);
  },

  create: (body: any) => {
    return API.post(API_USER_CREATE, body);
  },

  getSecretCode: (body: any) => {
    return API.post(API_USER_SECRET_CODE, body);
  },

  promotionStatusList: () => {
    return API.post(API_PROMOTION_STATUS_LIST);
  },

  duplicate: (body: any) => {
    return API.post(API_USER_DUPLICATE, body);
  },

  authenticationUpdate: (body: any) => {
    return API.post(API_USER_AUTHENTICATION_UPDATE, body);
  },

  channelList: (body: any, paging: any) => {
    return API.post(
      `${API_USER_CHANNEL_LIST}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
      body
    );
  },

  channelUpdate: (body: any) => {
    return API.post(API_USER_CHANNEL_UPDATE, body);
  },

  warehouseChannelCheck: (body: any) => {
    return API.post(API_USER_WAREHOUSE_CHANNEL_CHECK, body);
  },

  checkAssetWarehouse: (body: any = {}) => {
    return API.post(`${API_USER_WAREHOUSE_CHECK_ASSET_WAREHOUSE}`, body);
  },

  checkAssetChannel: (body: any = {}) => {
    return API.post(`${API_USER_WAREHOUSE_CHECK_ASSET_CHANNEL}`, body);
  },

  activityList: (paging: any, body: any) => {
    return API.post(
      `${API_USER_ACTIVITIES}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
      body
    );
  },

  activityAdd: (body: any) => {
    return API.post(API_USER_ADD_ACTIVITY, body);
  },

  orderManagerUpdate: (body: any) => {
    return API.post(API_USER_ORDER_MANA_UPDATE, body);
  },

  orderManagerList: (body: any, paging: any) => {
    return API.post(
      `${API_USER_ORDER_MANA_LIST}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
      body
    );
  },

  updatePassword: (body: any) => {
    return API.post(API_USER_UPDATE_PASSWORD, body);
  },

  roleUpdate: (body: any) => {
    return API.post(API_USER_ROLE_UPDATE, body);
  },

  roleList: (body: any, paging: any) => {
    return API.post(
      `${API_USER_ROLE_LIST}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
      body
    );
  },

  soCollaborationUpdate: (body: any) => {
    return API.post(API_USER_SO_COLLABORATION_UPDATE, body);
  },

  soCollaborationList: (body: any, paging: any) => {
    return API.post(
      `${API_USER_SO_COLLABORATION_LIST}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
      body
    );
  },

  getSpecialAccess: (body: any) => {
    return API.post(API_USER_LOGIN_BY_CODE_DETAIL, body);
  },

  updateSpecialAccess: (body: any) => {
    return API.post(API_USER_LOGIN_BY_CODE_UPDATE, body);
  },

  warehouseList: (body: any, paging: any) => {
    return API.post(
      `${API_USER_WAREHOUSE_LIST}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
      body
    );
  },

  warehouseUpdate: (body: any) => {
    return API.post(API_USER_WAREHOUSE_UPDATE, body);
  },

  getImportFields: (method = "create") => {
    return API.post(API_USER_IMPORT_FIELDS, { method });
  },

  validateImport: (body: any) => {
    return API.post(API_USER_IMPORT_VALIDATE, body);
  },

  importUser: (body: any) => {
    return API.post(API_USER_IMPORT, body);
  },
};

export default UserAPI;
