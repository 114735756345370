import PickupDrawer from "@components/order/components/pickup/PickupDrawer";
import InfoItemLangs from "@components/shared/InfoItemLangs";
import AppContext from "@helpers/context";
import { getBothLangsText } from "@helpers/locales";
import { TimeUtils } from "d-react-components";
import _ from "lodash";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

const Barcode = require("react-barcode");

const CONDITION_TEXT = (
  <>
    1. ผู้ซื้อต้องชําระค่าสินค้าทั้งหมดก่อนการส่งมอบสินค้า
    หากการชำระเงินมีเหตุขัดข้อง หรือบริษัทฯ ไม่ได้รับเงินบริษัทฯ
    ขอสงวนสิทธิ์ที่จะยังไม่ส่งสินค้า จนกว่าการชำระเงินจะเสร็จสมบูรณ์ / all items
    must be paid in full prior to delivery, and subjecting to a successful
    payment transaction. The company holds the rights to withhold product
    delivery in all cases of incomplete payment. <br />
    2. เราไม่มีนโยบายรับคืนสินค้าในทุกกรณี
    กรุณาตรวจสอบสินค้าให้เรียบร้อยก่อนเซ็นรับ
    กรณีพบปัญหาหรือความบกพร่องของสินค้า
    คุณสามารถเปลี่ยนสินค้าตัวใหม่ของรุ่นสินค้าเดียวกัน
    โดยจะต้องแจ้งมายังบริษัทภายใน 7 วันหลังวันที่ที่ซื้อสินค้า / We do not offer
    any refund on purchased products. We encourage our customers to thoroughly
    check their purchased products before signing the product receipt document.
    You are eligible for an exchange on the same product model at no cost within
    seven calendar days of your purchase.
    <br />
    3. เอกสารฉบับนี้ไม่ใช่ใบกำกับภาษี หากต้องการใบกำกับภาษี
    โปรดติดต่อบริษัทภายใน 7 วัน / This document is not a tax invoice. If you
    need a tax invoice, please contact the company within 7 days.
  </>
);

const PickUpPerson = ({ shipping, isBilling = false }: any) => {
  const { t } = useTranslation();
  const { selectedPickUp } = useContext(AppContext);
  return (
    <div style={{ textAlign: "start" }}>
      <div className="contentShippingBillingText">
        {selectedPickUp?.person_name ?? "N/A"}
      </div>
      <div className="contentShippingBillingText">
        {selectedPickUp?.person_phone ?? "N/A"}
      </div>
      {selectedPickUp?.notes && (
        <div className="contentShippingBillingText">
          {`${t("note")}:"${selectedPickUp?.notes}"`}
        </div>
      )}
    </div>
  );
};

const PickUpLocation = () => {
  const { order, shipping, billing, selectedPickUp } = useContext(AppContext);
  return (
    <div style={{ textAlign: "end" }}>
      <div className="contentShippingBillingText">
        {selectedPickUp?.store?.name ?? "N/A"}
      </div>
      <div className="contentShippingBillingText">
        {selectedPickUp?.store?.address ?? "N/A"}
      </div>
      <div className="contentShippingBillingText">
        {selectedPickUp?.store?.phone ?? "N/A"}
      </div>
      <div className="contentShippingBillingText">
        {selectedPickUp?.store?.pickup_code ?? "N/A"}
      </div>
    </div>
  );
};

const CompanyInfo = () => {
  const { channel, channel_address } = useContext(AppContext);

  if (!channel) {
    return <div />;
  }
  return (
    <div className="company-container">
      <div>
        <img src={channel_address.print_logo} className="company-logo" />
      </div>
      <div className="companyNameText-ReceiptPrint">{channel.company}</div>

      <InfoItemLangs content={channel.address} />
      <InfoItemLangs content={channel.phone} />

      {!_.isEmpty(channel.website) && (
        <InfoItemLangs content={channel.website} />
      )}
      <InfoItemLangs title="taxId" content={channel.tax} />
    </div>
  );
};

const ReceiptPickUpInfo = () => {
  const { order, selectedPickUp } = useContext(AppContext);
  return (
    <div className="receiptInfo-receiptOrderPrint">
      <Barcode value={order?.orderid} width={1.2} height={55} fontSize={10} />
      <div className="receiptText-receptOrderPrint">การยืนยันการรับสินค้า</div>
      <div
        className="receiptText-receptOrderPrint"
        style={{ marginBottom: "1rem" }}
      >
        Pick-up Confirmation
      </div>
      <InfoItemLangs title="pickUpRef" content={`#${selectedPickUp?.code}`} />
      <InfoItemLangs
        title="pickUpDate"
        content={TimeUtils.convertMiliToDateTime(selectedPickUp?.pickup_time)}
      />
      <InfoItemLangs title="order" content={order.orderid} />
      <InfoItemLangs title="storeCode" content={selectedPickUp?.store?.code} />
    </div>
  );
};

const PrintOrderPickUp = ({
  isShowSignature,
  isShowActivity,
  isPickup,
}: any) => {
  const {
    order,
    pickup = [],
    products,
    delivery,
    store,
    customer,
    selectedPickUp,
  } = useContext(AppContext);
  const { t } = useTranslation();

  const pickupList = pickup?.map((pickupItem: any) => {
    const productResult = pickupItem.pickup?.map((pro: any) => {
      let proResult = {};
      // check is not prouduct in group
      if (_.isEmpty(pro.order_product_group_id)) {
        proResult = products.find(
          (item: any) => item.id === pro.order_product_id
        );
      } else {
        products.forEach((productItem: any) => {
          const { groups = [] } = productItem;
          proResult = groups.find(
            (item: any) =>
              item.id === pro.order_product_group_id &&
              pro.order_product_id === productItem.id
          );
        });
      }

      return {
        ...pro,
        ...proResult,
      };
    });
    return {
      ...pickupItem,
      personPhone: pickupItem.person_phone,
      personName: pickupItem.person_name,
      pickupTime: pickupItem.pickup_time,
      products: productResult,
    };
  });

  if (!order) {
    return <div />;
  }

  const renderConditionsReceipt = () => {
    return (
      <div className="print-order-condition-header-container">
        {/* <div
          className="conditionContent-printOrderReceipt"
          dangerouslySetInnerHTML={{ __html: channel?.terms }}
        /> */}
        <div className="conditionTitle-printOrderReceipt">
          หมายเหตุ / Terms and Conditions
        </div>
        <span className="conditionContent-printOrderReceipt">
          {CONDITION_TEXT}
        </span>
      </div>
    );
  };

  const renderSignature = () => {
    return (
      <div className="print-order-signature-header-container">
        <div>
          <InfoItemLangs
            containerClass="print-order-signature-paidByContainer"
            title="pickedUpBy"
            content={
              <img
                src={isPickup ? selectedPickUp?.signature : order?.signature}
                className="paidSignatureImage"
              />
            }
          />
          <InfoItemLangs
            containerClass="print-order-signature-paidByContainer"
            title="date"
            content={TimeUtils.convertMiliToDate(order?.created)}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <InfoItemLangs
            containerClass="print-order-signature-paidByContainer"
            title="confirmedBy"
            content={<div className="signatureInput-print-order-signature" />}
          />
          <InfoItemLangs
            containerClass="print-order-signature-paidByContainer"
            title="date"
            content={<div className="signatureInput-print-order-signature" />}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="print-container print-order-pickup-container">
      <div className="print-order-pickup-header-container">
        <CompanyInfo />
        {order && <ReceiptPickUpInfo />}
      </div>
      <div className="print-order-pickup-header-container">
        <div className="print-shipping-container  w-100">
          <div className="titleShippingBillingText-printOrderPickUp">
            {getBothLangsText("pickUpPerson")}
          </div>
          <PickUpPerson />
        </div>
        <div style={{ width: "30%" }} />
        <div className="print-billing-container  w-100">
          <div
            className="titleShippingBillingText-printOrderPickUp"
            style={{ textAlign: "right" }}
          >
            {getBothLangsText("pickUpLocation")}
          </div>

          <div
            style={{
              alignItems: "flex-end",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <PickUpLocation />
          </div>
        </div>
      </div>
      <div className="print-order-pickup-header-container orderDetailTablePrintOrderPickupContainer">
        <PickupDrawer
          isPrint
          deliveryList={delivery}
          store={store}
          customer={customer}
          pickup={pickupList.find(
            (item: any) => item?.id === selectedPickUp?.id
          )}
          pickupList={pickupList}
          productOrderList={products}
        />
      </div>
      {renderConditionsReceipt()}
      {isShowSignature && renderSignature()}
      {!_.isEmpty(order?.note) && isShowActivity && (
        <div className="print-order-receipt-header-container">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="conditionTitle-printOrderReceipt">{`${t(
              "orderNote"
            )}(${t("internal")})`}</div>
            <div className="contentShippingBillingText">{order?.note}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PrintOrderPickUp;
