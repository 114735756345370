/* eslint-disable jsx-a11y/alt-text */
import Icon from "@common/Icon";
import Drawer from "@components/shared/Drawer";
import AppContext from "@helpers/context";
import { IProduct } from "@interfaces/product";
import {
  IRowsKey,
  Notifications,
  Progress,
  ViewRowInterchange,
} from "d-react-components";
import { useFormik } from "formik";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ProductInputShipping from "../input/ProductInputShipping";
import { editProduct } from "@network/api/product";
import { useParams } from "react-router-dom";
import { isEmpty, join } from "lodash";

interface IProductDetailShipping {
  open: boolean;
  defaultValue?: any;
  onUpdated: any;
  onClose: () => void;
}

const ProductDetailShippingDrawer = ({
  open,
  defaultValue,
  onUpdated,
  onClose,
}: IProductDetailShipping) => {
  const { t } = useTranslation();
  const { productId } = useParams();

  const productForm = useFormik<any>({
    initialValues: defaultValue as any,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: schema,
    onSubmit: (values: any) => {
      onUpdateProduct(values);
    },
  });
  const formValues = productForm?.values;
  const formErrors = productForm?.errors;

  const onUpdateProduct = (values: any) => {
    const {
      weight_option,

      weight_value,
      dimension_option,
      dimension_value,
    } = values;

    const optionList = [
      { weight_option },
      { weight_value },
      { dimension_option },
      {
        dimension_value,
      },
    ];
    const options = optionList
      .map((option: any) => {
        const key = Object.keys(option).toString();
        return { option: key, value: option[key] };
      })
      .filter((item) => !isEmpty(item.value));

    const body = {
      productid: productId,
      options,
    };

    Progress.show(
      {
        method: editProduct,
        params: [body],
      },
      (res: any) => {
        Notifications.showSuccess(t("updateSuccess"));
        onUpdated();
        onClose();
      }
    );
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("shipping")}
      size="auto"
      width="80vw"
      onSave={() => {
        productForm.handleSubmit();
      }}
    >
      <div>
        <ProductInputShipping productForm={productForm} />
      </div>
    </Drawer>
  );
};

const ProductDetailShipping = () => {
  const { productDetail, loadProductDetail } = useContext(AppContext);
  const [openEdit, setOpenEdit] = useState(false);
  const { t } = useTranslation();

  console.log("productDetail", productDetail);

  const DETAILS_KEY = useMemo<IRowsKey<IProduct>[]>(
    () => [
      {
        id: "weight_option",
        label: t("weightUnit"),
      },
      {
        id: "weight_value",
        label: t("weightValue"),
      },
      {
        id: "dimension_option",
        label: t("dimensionUnit"),
      },
      {
        id: "dimension_value",
        label: t("dimensionValue"),
      },
    ],
    [t]
  );

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mt-3">
        <h5>{t("shipping")}</h5>
        <span onClick={() => setOpenEdit(true)}>
          <Icon
            className="w-[15px] cursor-pointer"
            src="/images/icons/edit.svg"
          />
        </span>
      </div>
      <div className="mt-3 border p-4 ">
        <ViewRowInterchange
          dataSource={productDetail}
          keyList={DETAILS_KEY as any}
          variant="border"
          classNameLabel="text-hint "
          classNameContent="text-end "
        />
        {openEdit && (
          <ProductDetailShippingDrawer
            open={openEdit}
            onClose={() => setOpenEdit(false)}
            defaultValue={productDetail}
            onUpdated={loadProductDetail}
          />
        )}
      </div>
    </div>
  );
};

export default ProductDetailShipping;
