import AppContext from "@helpers/context";
import SerialNumberAPI from "@network/api/serial-number";
import { Progress } from "d-react-components";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SerialNumberHeader from "./SerialNumberHeader";
import SerialNumberInformation from "./SerialNumberInformation";
import SerialNumberConfirm from "./SerialNumberConfirm";
import { SERIAL_NUMBER_STATUS } from "@constants/serial-number";
import SerialNumberActivity from "./SerialNumberActivity";

const SerialNumberDetail = () => {
  const [serialDetail, setSerialDetail] = useState(null);
  const { serialNumberId } = useParams();
  useEffect(() => {
    loadSerialNumberDetail();
  }, []);

  const loadSerialNumberDetail = () => {
    Progress.show(
      {
        method: SerialNumberAPI.detail,
        params: [serialNumberId ?? null],
      },
      (res: any) => {
        const data = res?.data?.data?.serial_number;
        setSerialDetail(data);
      }
    );
  };

  if (!serialDetail) return <div />;

  const { id, info, product, serial_number } = serialDetail ?? {};
  const { status, by, created } = info ?? {};

  return (
    <AppContext.Provider value={{ serialDetail, loadSerialNumberDetail }}>
      <div className="flex-column-container bg-trans">
        <SerialNumberHeader />
        <SerialNumberInformation />
        {status && status !== SERIAL_NUMBER_STATUS.PENDING && (
          <SerialNumberConfirm />
        )}
        <SerialNumberActivity />
      </div>
    </AppContext.Provider>
  );
};

export default SerialNumberDetail;
