import Icon from "@common/Icon";
import ChannelSelect from "@components/channel/components/ChannelSelect";
import ChannelList from "@components/my-assets/requests/ChannelList";
import Drawer, { DrawerProps } from "@components/shared/Drawer";
import { PROMOTION_STATUS } from "@constants/promotion";
import AppContext from "@helpers/context";
import { updatePromotion } from "@network/api/promotion";
import {
  Avatar,
  Badge,
  Button,
  Notifications,
  Progress,
} from "d-react-components";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const ChannelItem = ({ channel }: any) => {
  return (
    <div className="flex items-center mb-2">
      <Avatar
        src={channel.logo}
        className="rounded-sm overflow-hidden"
        variant="square"
        size="small"
      />
      <div className="block ml-2">{channel.name}</div>
    </div>
  );
};

const PromotionDetailChannels = () => {
  const { t } = useTranslation();
  const [showListChannelDrawer, setShowListChannelDrawer] = useState(false);
  // const [showEditChannelDrawer, setShowEditChannelDrawer] = useState(false);
  const { promotionDetail, status } = React.useContext(AppContext);
  const itemToDisplay = useMemo(() => {
    //return first channel
    return promotionDetail?.channels?.slice(0, 1) ?? [];
  }, [promotionDetail]);
  const editable = useMemo(
    () => status === PROMOTION_STATUS.SCHEDULED,
    [status]
  );

  return (
    <div className="bg-white p-3 mb-3 shadow-sm">
      <div className="items-center flex mb-2">
        <label className="block text-sm flex-1 mb-0">{t("channels")}</label>
        {/* <div>
          {editable && (
            <Icon
              onClick={() => setShowEditChannelDrawer(true)}
              className="w-[15px] cursor-pointer"
              src="/images/icons/edit.svg"
            />
          )}
        </div> */}
      </div>
      {itemToDisplay.map((channel: any) => {
        return <ChannelItem key={channel.id} channel={channel} />;
      })}
      {promotionDetail?.channels?.length > 1 && (
        <Button
          variant="outline"
          className="w-100 mt-3"
          onClick={() => setShowListChannelDrawer(true)}
        >
          {t("listChannel")}
          <Badge
            index={promotionDetail?.channels?.length}
            variant="index"
            size="x-large"
            color="red"
          />
        </Button>
      )}
      {showListChannelDrawer && (
        <ChannelList
          open={showListChannelDrawer}
          onClose={() => setShowListChannelDrawer(false)}
          channelList={promotionDetail?.channels}
        />
      )}
      {/* {showEditChannelDrawer && (
        <ChannelsEditDrawer
          open={showEditChannelDrawer}
          onClose={() => setShowEditChannelDrawer(false)}
        />
      )} */}
    </div>
  );
};

const ChannelsEditDrawer = ({ open, onClose }: DrawerProps) => {
  const { t } = useTranslation();
  const { promotionDetail, reloadPromotion } = React.useContext(AppContext);
  const [channels, setChannels] = useState(promotionDetail?.channels);

  const onSave = () => {
    Progress.show(
      {
        method: updatePromotion,
        params: {
          channelids: channels?.map((c: any) => c.id),
          promotionid: promotionDetail?.id,
        },
      },
      () => {
        reloadPromotion && reloadPromotion();
        Notifications.showSuccess(t("notification:updatePromotionSuccess"));
        onClose();
      },
      (err: string) => {
        Notifications.showError(err);
      }
    );
  };

  return (
    <Drawer
      title={t("channels")}
      open={open}
      onClose={() => onClose(false)}
      size="auto"
      width="450"
      onSave={onSave}
    >
      <ChannelSelect
        value={channels}
        onChange={(value) => setChannels(value)}
        className="mb-3"
        multiple
      />
    </Drawer>
  );
};

export default PromotionDetailChannels;
