import Drawer from "@components/shared/Drawer";
import AppContext from "@helpers/context";
import { useFormik } from "formik";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ProductionPrintingImportBrowseFile from "./ProductionPrintingImportBrowseFile";
import ProductionPrintingImportFooter from "./ProductionPrintingImportFooter";
import ProductionPrintingImportResult from "./ProductionPrintingImportResult";
import ProductionPrintingImportValidation from "./ProductionPrintingImportValidation";

interface IPrintImportDrawer {
  onImported: () => void;
  open: boolean;
  onClose: () => void;
}

export const IMPORT_PRODUCTION_PRINTING_STEPS = [
  { id: "browseFile", title: "browseFile" },
  { id: "validation", title: "fileValidation" },
  { id: "import", title: "importResult" },
];

export const INIT_IMPORT_FORM = {
  fileImport: null,
};

function ProductionPrintingImportDrawer({
  open,
  onClose,
  onImported,
}: IPrintImportDrawer) {
  const { t } = useTranslation();
  const [stepIndex, setStepIndex] = useState(0);

  const formImport = useFormik({
    initialValues: INIT_IMPORT_FORM,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {},
  });

  const step = useMemo(
    () => IMPORT_PRODUCTION_PRINTING_STEPS[stepIndex],
    [stepIndex]
  );

  const classNameHeader =
    "d-flex justify-content-between align-items-center p-4 border-bottom";

  const renderContent = () => {
    switch (stepIndex) {
      case 0:
        return <ProductionPrintingImportBrowseFile />;
      case 1:
        return <ProductionPrintingImportValidation />;
      case 2:
        return <ProductionPrintingImportResult />;

      default:
        return <div />;
    }
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("importProductionPrinting")}
      size="auto"
      hideFooter
      width={"80%"}
    >
      <AppContext.Provider
        value={{
          step,
          formImport,
          stepIndex,
          setStepIndex,
          onImported,
          onClose,
        }}
      >
        <div>
          <div className={classNameHeader}>
            <h5>{t(step?.title)}</h5>
            <div className="bg-dark px-3 py-1">
              <small className="text-white">{`Step ${stepIndex + 1} of ${
                IMPORT_PRODUCTION_PRINTING_STEPS.length
              }`}</small>
            </div>
          </div>
          {renderContent()}
          <ProductionPrintingImportFooter />
        </div>
      </AppContext.Provider>
    </Drawer>
  );
}

export default ProductionPrintingImportDrawer;
