/* eslint-disable jsx-a11y/alt-text */
import {
  DELIVERY_RETURN_STATUS,
  DELIVERY_STATUS,
  DELIVERY_STOCK_STATUS,
  DELIVERY_STOCK_STATUS_LIST,
} from "@constants/delivery";
import AppContext from "@helpers/context";
import {
  AwesomeTableComponent,
  Button,
  InputText,
  Notifications,
  ObjectUtils,
  Progress,
  TimeUtils,
  ViewLabelStatus,
} from "d-react-components";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import DeliveryConfirmedView from "../components/DeliveryConfirmedView";
import * as Yup from "yup";
import { useLocalStorage } from "react-use";
import { ADMIN_AUTH_KEY } from "@constants";
import ProductName from "@components/product/components/ProductName";
import classNames from "classnames";
import QuantityInputField from "@components/product/components/QuantityInputField";
import { useFormik } from "formik";
import { filter, map, replace } from "lodash";
import {
  deliveryStockConfirmDestination,
  deliveryStockCreateReturn,
} from "@network/api/delivery-stock";
import Drawer from "@components/shared/Drawer";
import SignatureField from "@components/order/components/pickup/SignatureField";
import ButtonFileUpload from "@components/shared/ButtonFileUpload";
import SearchGroupProducts from "@components/product/components/SearchGroupProducts";
import { isGrantURLPermission } from "@helpers/permissions";
import { API_DELIVERY_STOCK_RETURN_CREATE } from "@network/URL";
import ReasonRefuseSelect from "../components/ReasonRefuseSelect";

const ReceiveStockSchema = Yup.object().shape({
  driverSignature: Yup.string().required("Driver signature is required!"),
  supervisorSignature: Yup.string().required("Receiver signature is required!"),
  attachments: Yup.array()
    .required("Attachments is required!")
    .min(1, "Minimum 1 attachment"),
});

const RefuseStockSchema = Yup.object().shape({
  driverSignature: Yup.string().required("Driver signature is required!"),
  supervisorSignature: Yup.string().required("Receiver signature is required!"),
  reason: Yup.object().required("Reason is required!"),
  attachments: Yup.array()
    .required("Attachments is required!")
    .min(1, "Minimum 1 attachment"),
});

const DeliveryStockAgreeReceiveDrawer = ({ open, onClose }: any) => {
  const { deliveryDetail, loadDeliveryDetail } = useContext(AppContext);
  const { products, driver, driver_name } = deliveryDetail;
  const { t } = useTranslation();
  const [admin] = useLocalStorage<any>(ADMIN_AUTH_KEY, {});

  const columns = [
    {
      title: t("itemName"),
      dataIndex: "",
      render: (product: any) => {
        const leftQty = product?.quantity - product?.receiveQty;
        return (
          <ProductName
            item={{ product }}
            subBody={
              <div
                className={classNames("small", {
                  "text-warning": leftQty !== 0,
                  "text-success": leftQty === 0,
                })}
              >
                {t("leftQty") + ": " + leftQty}
              </div>
            }
          />
        );
      },
    },
    {
      title: t("toReceive"),
      dataIndex: "receiveQty",
      render: (receiveQty: any, product: any) => (
        <QuantityInputField
          quantity={receiveQty}
          onChangeQuantity={(pickupQtyParam: any) => {
            if (pickupQtyParam < 0) return;
            onChangeQuantity({ ...product, receiveQty: pickupQtyParam });
          }}
        />
      ),
    },
  ];

  const columnAdditionalProduct = [
    {
      title: t("itemName"),
      dataIndex: "",
      render: (product: any) => <ProductName item={{ product }} />,
    },
    {
      title: t("toReceive"),
      dataIndex: "receiveQty",
      render: (receiveQty: any, product: any) => (
        <QuantityInputField
          quantity={receiveQty}
          onChangeQuantity={(pickupQtyParam: any) => {
            if (pickupQtyParam < 0) return;
            onChangeOffListQuantity({ ...product, receiveQty: pickupQtyParam });
          }}
        />
      ),
    },
  ];

  const form = useFormik({
    initialValues: {
      products: map(products, (item) => ({
        ...item,
        receiveQty: 0,
      })),
    } as any,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: ReceiveStockSchema,
    onSubmit: (values: any) => {
      const input = {
        id: deliveryDetail?.id,
        driver_signature_at_store: values?.driverSignature,
        supervisor_signature_at_store: values?.supervisorSignature,
        supervisor_fullname_at_store: admin?.fullname,
        remark_at_store: values?.supervisorRemark,
        driver_fullname_at_store: driver?.fullname,
        proof_at_store: map(values.attachments, (item) => item.key),
        receive: map(
          filter(values.products, (item) => !!item.receiveQty),
          (item) => ({
            transfer_item_id: item.transfer_stock_product_id,
            quantity: item.receiveQty,
          })
        ),
        additional_receive: map(values.offListStock, (item) => ({
          product_id: item.id,
          quantity: item.receiveQty,
        })),
      };

      onAgreeReceive(input);
    },
  });

  const formValues = form?.values;
  const formErrors = form?.errors;

  const onAgreeReceive = (input: any) => {
    Progress.show(
      { method: deliveryStockConfirmDestination, params: [input] },
      () => {
        onClose && onClose();
        loadDeliveryDetail();
        Notifications.showSuccess(
          replace(
            t("stockSuccessfulReceived"),
            "%time",
            TimeUtils.toDateTime(new Date())
          )
        );
      }
    );
  };

  const onChangeQuantity = (newProduct: any) => {
    const result = ObjectUtils.updateArrayById(
      formValues?.products as any,
      newProduct
    );
    form.setFieldValue("products", result);
  };

  const onChangeOffListQuantity = (newProduct: any) => {
    const result = ObjectUtils.updateArrayById(
      formValues?.offListProducts as any,
      newProduct
    );
    form.setFieldValue("offListProducts", result);
  };

  const onAddAdditionalProduct = (products: any) => {
    const result = products
      .filter((item: any) => {
        const isOutListItem = filter(
          formValues.offListProducts,
          (transferItem: any) =>
            transferItem.productid === item.id &&
            transferItem.receive !== transferItem.quantity
        );
        return isOutListItem.length === 0;
      })
      .map((item: any) => ({ ...item, receive: item?.receive ?? 1 }));
    form.setFieldValue("offListProducts", result);
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      onSave={() => form.handleSubmit()}
      saveText={t("confirm")}
      size="auto"
      width="50%"
      title={t("receiveStock")}
    >
      <div>
        <div className="mt-3 label">{t("items")}</div>

        <AwesomeTableComponent
          dataSource={formValues?.products}
          columns={columns}
          pagination={false}
        />

        {/* <div className="mt-3">
          <label className="mr-2"> {t("haveOtherProductDelivered")}</label>
          <SearchGroupProducts
            onChange={onAddAdditionalProduct}
            selectedProducts={formValues.offListProducts}
            stockConsider={false}
            buttonText={t("clickHere")}
            buttonProps={{ variant: "trans", iconName: "", size: "small" }}
          />
        </div> */}

        {/* {formValues?.offListProducts?.length > 0 && (
          <AwesomeTableComponent
            className="mt-3"
            dataSource={formValues.offListProducts}
            columns={columnAdditionalProduct}
            // ref={(ref) => (additionalProTable.current = ref)}
            pagination={false}
          />
        )} */}

        <div className="mt-3 label">{t("receiverSignature")}</div>

        {formValues.supervisorSignature && (
          <img
            className="w-100"
            src={formValues.supervisorSignature}
            style={{ height: 250, objectFit: "contain" }}
          />
        )}
        <SignatureField
          className="w-min-content mt-2"
          onSave={(signature: any) =>
            form.setFieldValue("supervisorSignature", signature)
          }
        />

        <InputText
          className="mt-3"
          label={t("receiverFullName")}
          value={admin?.fullname}
          disabled
        />

        <div className="mt-3 label">{t("driverSignature")}</div>

        {formValues.driverSignature && (
          <img
            className="w-100"
            src={formValues.driverSignature}
            style={{ height: 250, objectFit: "contain" }}
          />
        )}
        <SignatureField
          className="w-min-content mt-2"
          onSave={(signature: any) =>
            form.setFieldValue("driverSignature", signature)
          }
        />

        <InputText
          className="mt-3"
          label={t("driverFullName")}
          value={driver?.fullname ?? driver_name}
          disabled
        />

        <div className="mt-3 label">{t("proof")}</div>
        <ButtonFileUpload
          containerClassName="mt-3"
          onChange={(value: any) => form.setFieldValue("attachments", value)}
        />

        <InputText
          className="mt-3"
          label={t("remark")}
          multiple
          value={formValues.remark}
          onChange={(event) => form.setFieldValue("remark", event.target.value)}
          placeholder={t("pleaseInputTheNoteForThisUpdate")}
        />
      </div>
    </Drawer>
  );
};

const DeliveryStockRefuseReceiveDrawer = ({ open, onClose }: any) => {
  const { deliveryDetail, loadDeliveryDetail } = useContext(AppContext);
  const { products, driver, driver_name } = deliveryDetail;
  const { t } = useTranslation();
  const [admin] = useLocalStorage<any>(ADMIN_AUTH_KEY, {});

  const columns = [
    {
      title: t("itemName"),
      dataIndex: "",
      render: (product: any) => {
        const leftQty = product?.quantity - product?.receiveQty;
        return (
          <ProductName
            item={{ product }}
            subBody={
              <div
                className={classNames("small", {
                  "text-warning": leftQty !== 0,
                  "text-success": leftQty === 0,
                })}
              >
                {t("leftQty") + ": " + leftQty}
              </div>
            }
          />
        );
      },
    },
    {
      title: t("toReceive"),
      dataIndex: "receiveQty",
      render: (receiveQty: any, product: any) => (
        <QuantityInputField
          quantity={receiveQty}
          onChangeQuantity={(pickupQtyParam: any) => {
            if (pickupQtyParam < 0) return;
            onChangeQuantity({ ...product, receiveQty: pickupQtyParam });
          }}
        />
      ),
    },
  ];

  const form = useFormik({
    initialValues: {
      products: map(products, (item) => ({
        ...item,
        receiveQty: 0,
      })),
    } as any,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: RefuseStockSchema,
    onSubmit: (values: any) => {
      const input = {
        id: deliveryDetail?.id,
        driver_signature_at_store: values?.driverSignature,
        supervisor_signature_at_store: values?.supervisorSignature,
        remark_at_store: values?.supervisorRemark,
        driver_fullname_at_store: driver?.fullname,
        proof_at_store: map(values.attachments, (item) => item.key),
        reason: values?.reason?.slug,
        remark: values?.remark,
      };

      onRefuse(input);
    },
  });

  const formValues = form?.values;
  const formErrors = form?.errors;
  console.log("formErrors", formValues);
  console.log("formErrors", formErrors);

  const onRefuse = (input: any) => {
    Progress.show(
      { method: deliveryStockConfirmDestination, params: [input] },
      () => {
        onClose && onClose();
        loadDeliveryDetail();
      }
    );
  };

  const onChangeQuantity = (newProduct: any) => {
    const result = ObjectUtils.updateArrayById(
      formValues?.products as any,
      newProduct
    );
    form.setFieldValue("products", result);
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      onSave={() => form.handleSubmit()}
      saveText={t("confirm")}
      size="auto"
      width="50%"
      title={t("receiveStock")}
    >
      <div>
        <ReasonRefuseSelect
          className="mt-3"
          value={formValues?.reason}
          onChange={(value) => form.setFieldValue("reason", value)}
        />

        <div className="mt-3 label">{t("receiverSignature")}</div>

        {formValues.supervisorSignature && (
          <img
            className="w-100"
            src={formValues.supervisorSignature}
            style={{ height: 250, objectFit: "contain" }}
          />
        )}
        <SignatureField
          className="w-min-content mt-2"
          onSave={(signature: any) =>
            form.setFieldValue("supervisorSignature", signature)
          }
        />

        <InputText
          className="mt-3"
          label={t("receiverFullName")}
          value={admin?.fullname}
          disabled
        />

        <div className="mt-3 label">{t("driverSignature")}</div>

        {formValues.driverSignature && (
          <img
            className="w-100"
            src={formValues.driverSignature}
            style={{ height: 250, objectFit: "contain" }}
          />
        )}
        <SignatureField
          className="w-min-content mt-2"
          onSave={(signature: any) =>
            form.setFieldValue("driverSignature", signature)
          }
        />

        <InputText
          className="mt-3"
          label={t("driverFullName")}
          value={driver?.fullname ?? driver_name}
          disabled
        />

        <div className="mt-3 label">{t("proof")}</div>
        <ButtonFileUpload
          containerClassName="mt-3"
          onChange={(value: any) => form.setFieldValue("attachments", value)}
        />

        <InputText
          className="mt-3"
          label={t("remark")}
          multiple
          value={formValues.remark}
          onChange={(event) => form.setFieldValue("remark", event.target.value)}
          placeholder={t("pleaseInputTheNoteForThisUpdate")}
        />
      </div>
    </Drawer>
  );
};

const DeliveryStockCreateReturnDrawer = ({ open, onClose }: any) => {
  const { deliveryDetail, loadDeliveryDetail } = useContext(AppContext);
  const { products, driver, driver_name } = deliveryDetail;
  const { t } = useTranslation();
  const [admin] = useLocalStorage<any>(ADMIN_AUTH_KEY, {});

  const columns = [
    {
      title: t("itemName"),
      dataIndex: "",
      render: (product: any) => {
        const leftQty = product?.quantity - product?.receiveQty;
        return (
          <ProductName
            item={{ product }}
            subBody={
              <div
                className={classNames("small", {
                  "text-warning": leftQty !== 0,
                  "text-success": leftQty === 0,
                })}
              >
                {t("leftQty") + ": " + leftQty}
              </div>
            }
          />
        );
      },
    },
    {
      title: t("toReceive"),
      dataIndex: "receiveQty",
      render: (receiveQty: any, product: any) => (
        <QuantityInputField
          quantity={receiveQty}
          onChangeQuantity={(pickupQtyParam: any) => {
            if (pickupQtyParam < 0) return;
            onChangeQuantity({ ...product, receiveQty: pickupQtyParam });
          }}
        />
      ),
    },
  ];

  const columnAdditionalProduct = [
    {
      title: t("itemName"),
      dataIndex: "",
      render: (product: any) => <ProductName item={{ product }} />,
    },
    {
      title: t("toReceive"),
      dataIndex: "receiveQty",
      render: (receiveQty: any, product: any) => (
        <QuantityInputField
          quantity={receiveQty}
          onChangeQuantity={(pickupQtyParam: any) => {
            if (pickupQtyParam < 0) return;
            onChangeOffListQuantity({ ...product, receiveQty: pickupQtyParam });
          }}
        />
      ),
    },
  ];

  const form = useFormik({
    initialValues: {
      products: map(
        filter(products, (item) => !!item.quantity),
        (item) => ({
          ...item,
          receiveQty: 0,
        })
      ),
    } as any,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: ReceiveStockSchema,
    onSubmit: (values: any) => {
      const input = {
        id: deliveryDetail?.id,
        driver_signature_at_store: values?.driverSignature,
        supervisor_signature_at_store: values?.supervisorSignature,
        remark_at_store: values?.supervisorRemark,
        driver_fullname_at_store: driver?.fullname ?? driver_name,
        proof_at_store: map(values.attachments, (item) => item.key),
        return: map(values.products, (item) => ({
          transfer_item_id: item.transfer_stock_product_id,
          quantity: item.receiveQty,
        })),
        additional_return: map(values.offListStock, (item) => ({
          product_id: item.id,
          quantity: item.receiveQty,
        })),
      };

      onCreateReturn(input);
    },
  });

  const formValues = form?.values;
  const formErrors = form?.errors;

  const onCreateReturn = (input: any) => {
    Progress.show(
      { method: deliveryStockCreateReturn, params: [input] },
      () => {
        onClose && onClose();
        loadDeliveryDetail();
        Notifications.showSuccess(
          replace(
            t("stockSuccessfulReceived"),
            "%time",
            TimeUtils.toDateTime(new Date())
          )
        );
      }
    );
  };

  const onChangeQuantity = (newProduct: any) => {
    const result = ObjectUtils.updateArrayById(
      formValues?.products as any,
      newProduct
    );
    form.setFieldValue("products", result);
  };

  const onChangeOffListQuantity = (newProduct: any) => {
    const result = ObjectUtils.updateArrayById(
      formValues?.offListProducts as any,
      newProduct
    );
    form.setFieldValue("offListProducts", result);
  };

  const onAddAdditionalProduct = (products: any) => {
    const result = products
      .filter((item: any) => {
        const isOutListItem = filter(
          formValues.offListProducts,
          (transferItem: any) =>
            transferItem.productid === item.id &&
            transferItem.receive !== transferItem.quantity
        );
        return isOutListItem.length === 0;
      })
      .map((item: any) => ({ ...item, receive: item?.receive ?? 1 }));
    form.setFieldValue("offListProducts", result);
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      onSave={() => form.handleSubmit()}
      saveText={t("confirm")}
      size="auto"
      width="50%"
      title={t("receiveStock")}
    >
      <div>
        <div className="mt-3 label">{t("items")}</div>

        <AwesomeTableComponent
          dataSource={formValues?.products}
          columns={columns}
          pagination={false}
        />

        <div className="mt-3">
          <label className="mr-2"> {t("haveOtherProductDelivered")}</label>
          <SearchGroupProducts
            onChange={onAddAdditionalProduct}
            selectedProducts={formValues.offListProducts}
            stockConsider={false}
            buttonText={t("clickHere")}
            buttonProps={{ variant: "trans", iconName: "", size: "small" }}
          />
        </div>

        {formValues?.offListProducts?.length > 0 && (
          <AwesomeTableComponent
            className="mt-3"
            dataSource={formValues.offListProducts}
            columns={columnAdditionalProduct}
            // ref={(ref) => (additionalProTable.current = ref)}
            pagination={false}
          />
        )}

        <div className="mt-3">{t("sureCompleteReturn")}</div>

        <div className="mt-3 label">{t("driverSignature")}</div>

        {formValues.driverSignature && (
          <img
            className="w-100"
            src={formValues.driverSignature}
            style={{ height: 250, objectFit: "contain" }}
          />
        )}
        <SignatureField
          className="w-min-content mt-2"
          onSave={(signature: any) =>
            form.setFieldValue("driverSignature", signature)
          }
        />

        <InputText
          className="mt-3"
          label={t("driverFullName")}
          value={driver?.fullname ?? driver_name}
          disabled
        />

        <div className="mt-3 label">{t("supervisorSignature")}</div>

        {formValues.supervisorSignature && (
          <img
            className="w-100"
            src={formValues.supervisorSignature}
            style={{ height: 250, objectFit: "contain" }}
          />
        )}
        <SignatureField
          className="w-min-content mt-2"
          onSave={(signature: any) =>
            form.setFieldValue("supervisorSignature", signature)
          }
        />

        <InputText
          className="mt-3"
          label={t("supervisorFullName")}
          value={admin?.fullname}
          disabled
        />

        <div className="mt-3 label">{t("proof")}</div>
        <ButtonFileUpload
          containerClassName="mt-3"
          onChange={(value: any) => form.setFieldValue("attachments", value)}
        />

        <InputText
          className="mt-3"
          label={t("remark")}
          multiple
          value={formValues.remark}
          onChange={(event) => form.setFieldValue("remark", event.target.value)}
          placeholder={t("pleaseInputTheNoteForThisUpdate")}
        />
      </div>
    </Drawer>
  );
};

const DeliveryDetailDestination = () => {
  const { deliveryDetail } = useContext(AppContext);

  const { status, confirmationDesc, products } =
    deliveryDetail;
  const [openAgree, setOpenAgree] = useState(false);
  const [openRefuse, setOpenRefuse] = useState(false);
  const [openCreateReturn, setOpenCreateReturn] = useState(false);

  const { t } = useTranslation();

  const renderAgreeRefuseButtons = () => {
    if (status !== DELIVERY_STATUS.IN_DELIVERY) return <div />;
    return (
      <div className="flex-row mt-3">
        <Button onClick={() => setOpenAgree(true)} className="">
          {t("agreeToReceive")}
        </Button>
        <Button
          onClick={() => setOpenRefuse(true)}
          className="ml-3"
          variant="outline"
        >
          {t("refuseToReceive")}
        </Button>
      </div>
    );
  };

  const renderCompletedView = () => {
    const {
      driver_fullname_at_warehouse,
      signatureDriver,
      signAt,
      supervisorName,
      signatureSupervisor,
      remark,
    } = confirmationDesc ?? {};

    if (
      status === DELIVERY_STOCK_STATUS.COMPLETED ||
      status === DELIVERY_STOCK_STATUS.PARTIALLY
    ) {
      const productsAtDest = map(products, (item) => ({
        ...item,
        quantity: item.receive,
      }));
      return (
        <div>
          <DeliveryConfirmedView
            className="mt-3"
            userTitle={t("driver")}
            userFullName={driver_fullname_at_warehouse}
            signature={signatureDriver}
            signAt={signAt}
            content={t("confirmedReceivedFollowingItems")}
            products={productsAtDest}
          />
          <DeliveryConfirmedView
            className="mt-3"
            userTitle={t("receiver")}
            userFullName={supervisorName}
            signature={signatureSupervisor}
            signAt={signAt}
            content={t("confirmedSentToDriver")}
            products={productsAtDest}
            note={remark}
          />
        </div>
      );
    }

    if (status === DELIVERY_STOCK_STATUS.FAILED) {
      return (
        <div>
          <DeliveryConfirmedView
            className="mt-3"
            userTitle={t("driver")}
            userFullName={driver_fullname_at_warehouse}
            signature={signatureDriver}
            signAt={signAt}
          />
          <DeliveryConfirmedView
            className="mt-3"
            userTitle={t("receiver")}
            userFullName={supervisorName}
            signature={signatureSupervisor}
            signAt={signAt}
            note={remark}
          />
        </div>
      );
    }
    return <div />;
  };

  return (
    <div className="card-container p-3 mt-4">
      <div className="d-flex justify-content-between align-items-center">
        <div className="label">{t("destinationPickupConfirm")}</div>
      </div>
      <ViewLabelStatus
        status={status}
        listStatus={DELIVERY_STOCK_STATUS_LIST}
        getLabel={(item) => t(item.label)}
        className="mt-3"
      />
      {renderAgreeRefuseButtons()}
      {renderCompletedView()}
      {openAgree && (
        <DeliveryStockAgreeReceiveDrawer
          open={openAgree}
          onClose={() => setOpenAgree(false)}
        />
      )}
      {openRefuse && (
        <DeliveryStockRefuseReceiveDrawer
          open={openRefuse}
          onClose={() => setOpenRefuse(false)}
        />
      )}
      {openCreateReturn && (
        <DeliveryStockCreateReturnDrawer
          open={openCreateReturn}
          onClose={() => setOpenCreateReturn(false)}
        />
      )}
    </div>
  );
};
export default DeliveryDetailDestination;
