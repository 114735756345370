import React from "react";
import { ORDER_STATUS } from "@constants/order";
import { StringUtils } from "d-react-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ItemInfo = ({ title, content, style }: any) => {
  return (
    <p className="itemRefundOrderInfoContainer mb-1" style={style || {}}>
      <span className="titleText mr-1">
        {title}
      </span>
      {content}
    </p>
  );
};

const OrderCreateOrigin = ({ order }: any) => {
  const orderStatus = ORDER_STATUS?.[order?.status];
  const { t } = useTranslation();

  return (
    <div className="bg-white mb-3 p-3 shadow-sm">
      <label className="block text-sm">{t("originOrder")}</label>
      <ItemInfo
        title={`${t("orderid")}: `}
        content={
          <Link to={`/order/${order.id}`} target="_blank" rel="noreferrer">
            {order?.orderid}
          </Link>
        }
      />
      <ItemInfo title={`${t("total")}: `} content={StringUtils.moneyThaiFormat(order?.total)} />
      <ItemInfo title={`${t("status")}: `} content={t(orderStatus?.content)} style={{ color: orderStatus?.color }} />
    </div>
  );
};

export default OrderCreateOrigin;
