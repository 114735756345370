import InputTextForm from "@common/input/InputTextForm";
import Drawer from "@components/shared/Drawer";
import UserAPI from "@network/api/user";
import { Modal, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

function UserDetailPasswordUpdateModal({ open, onClose, userId, defaultValue, onUpdateSuccess }: any) {
  const { t } = useTranslation();
  const passwordForm = useFormik({
    initialValues: defaultValue ?? {},
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      if (values.password !== values.reTypePassword) {
        Notifications.showError(t("passwordNotMatch"));
        return;
      }
      const body = { id: userId, password: values.password };
      Progress.show({ method: UserAPI.updatePassword, params: [body] }, () => {
        Notifications.showSuccess(t("updateUserSuccess"));
        onUpdateSuccess();
        onClose();
      });
    },
  });

  const classNameInput = "col-12 mb-3";

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("updatePassword")}
      onSave={() => passwordForm.handleSubmit()}
    >
      <div className="row ">
        <InputTextForm
          keyData="password"
          form={passwordForm}
          required
          className={classNameInput}
          type="password"
        />
        <InputTextForm
          keyData="reTypePassword"
          form={passwordForm}
          required
          className={classNameInput}
          type="password"
        />
      </div>
    </Drawer>
  );
}

export default UserDetailPasswordUpdateModal;
