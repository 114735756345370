import { ADMIN_AUTH_KEY, ADMIN_TOKEN_KEY, CALL_CENTER_TOKEN_KEY } from "@constants";

export const getAdminProfile = () => {
  return JSON.parse(localStorage.getItem(ADMIN_AUTH_KEY) || "{}");
};

export const getAdminPermission = () => {
  return JSON.parse(localStorage.getItem(ADMIN_AUTH_KEY) || "{}")?.permissions;
};

export const getAdminToken = () => {
  return localStorage.getItem(ADMIN_TOKEN_KEY) || "";
};

export const getCallCenterToken = () => {
  return localStorage.getItem(CALL_CENTER_TOKEN_KEY) || "";
};

export const getPaymentMethods = () => {
  return (
    JSON.parse(localStorage.getItem(ADMIN_AUTH_KEY) || "{}")?.payment_method ??
    []
  );
};

export const getDeliveryStatusList = () => {
  return (
    JSON.parse(localStorage.getItem(ADMIN_AUTH_KEY) || "{}")?.delivery_status ??
    []
  );
};

export const getAdminAvatar = () => {
  return JSON.parse(localStorage.getItem(ADMIN_AUTH_KEY) || "{}")?.avatar;
};
