import AppContext from "@helpers/context";
import { IRowsKey, ViewRowInterchange } from "d-react-components";
import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
export const SERIAL_NUMBER_INFO_KEY = [];

const SerialNumberInformation = () => {
  const { serialDetail } = useContext(AppContext);
  const { id, info, product, serial_number, label_number } = serialDetail ?? {};
  const { status, by, created } = info ?? {};
  const { t } = useTranslation();

  const DETAILS_KEY = useMemo<IRowsKey<any>[]>(
    () => [
      { id: "serial_number", label: t("serialNo") },
      {
        id: "product",
        label: t("category"),
        renderContent: ({ data }) => {
          const { category } = data || {};
          return category?.name ?? "N/A";
        },
      },
      {
        id: "product",
        label: t("brand"),
        renderContent: ({ data }) => {
          const { brand } = data || {};
          return brand?.name ?? "N/A";
        },
      },
      {
        id: "product",
        label: t("productName"),
        renderContent: ({ data }) => {
          return data?.name ?? "N/A";
        },
      },
      {
        id: "product",
        label: t("size"),
        renderContent: ({ data }: any) => {
          const productSize = data?.size?.display_name?.en;
          return productSize ?? "N/A";
        },
      },
      {
        id: "product",
        label: t("sku"),
        renderContent: ({ data }) => {
          return data?.sku ?? "N/A";
        },
      },
      {
        id: "label_number",
        label: t("labelNo"),
        renderContent: ({ data }) => {
          return data ?? "N/A";
        },
      },
      {
        id: "info",
        label: t("warrantyPeriod"),
        renderContent: ({ data, item }) => {
          return data?.warranty_period ?? "N/A";
        },
      },
    ],
    [t]
  );

  return (
    <div className="bg-white p-3 border-top mt-2">
      <h5 className="mb-3">{t("information")}</h5>
      <ViewRowInterchange
        dataSource={serialDetail}
        keyList={DETAILS_KEY as any}
        variant="border"
        classNameLabel="font-weight-bold "
        classNameContent="text-end "
        className="w-100"
      />
    </div>
  );
};

export default SerialNumberInformation;
