import ProductName from "@components/product/components/ProductName";
import AppContext from "@helpers/context";
import { AwesomeTableComponent, Summary } from "d-react-components";
import { reduce } from "lodash";
import { useContext, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

const DeliverySODetailProducts = () => {
  const { deliveryDetail } = useContext(AppContext);
  const { t } = useTranslation();

  const tableRef = useRef<any>();

  const columns: any[] = [
    {
      title: t("name"),
      dataIndex: "",
      render: (product: any) => <ProductName item={{ product }} />,
    },
    {
      title: t("quantity"),
      dataIndex: "quantity",
      align: "center",
      render: (quantity: any) => quantity || "N/A",
    },
  ];

  const totalPickedUp = useMemo(() => {
    return reduce(
      deliveryDetail?.transfer_stock_product,
      (sum, item) => (sum += item?.quantity ?? 0),
      0
    );
  }, [deliveryDetail?.transfer_stock_product]);

  const renderSummaryRow = () => {
    return (
      <Summary.Row>
        <Summary.Cell index={0} className="text-right font-weight-bold">
          {t("total")}
        </Summary.Cell>
        <Summary.Cell index={1} className="text-center font-weight-bold">
          {totalPickedUp}
        </Summary.Cell>
      </Summary.Row>
    );
  };

  return (
    <div className="card-container p-4 ">
      <div className="d-flex justify-content-between align-items-center">
        <label>{t("itemList")}</label>
      </div>
      <AwesomeTableComponent
        ref={(ref) => {
          tableRef.current = ref;
        }}
        columns={columns}
        dataSource={deliveryDetail?.transfer_stock_product ?? []}
        className="mt-3 height-fit-content"
        pagination={false}
        summary={() => renderSummaryRow()}
      />
    </div>
  );
};

export default DeliverySODetailProducts;
