import React, { useCallback, useEffect, useMemo } from "react";
import CurrencyFormat from "react-currency-format";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { Path } from "@components/layout/Path";
import { Link, generatePath } from "react-router-dom";

const ProductName = ({
  item,
  isHideSku = false,
  subBody,
  isHideImage = false,
  isOldPro = false,
  renderSubName,
  imgClass = "w-10 h-10",
  showPrice = false,
  addOneAfterName = null,
  showRentPrice = false,
}: any) => {
  const { t } = useTranslation();
  const { product } = item;
  const { imageurl, name, sku, imageurls } = product || {};

  const getNameProduct = () => {
    if (isEmpty(product?.detailurl) && isEmpty(product?.editurl)) {
      return name;
    }
    return (
      <Link to={generatePath(Path.PRODUCT_DETAIL, { productId: product?.id })}>
        {name}
      </Link>
    );
  };

  return (
    <div className="order-products__item-name__details flex md:flex-row flex-col items-start z-50 relative">
      <div className="relative shrink-0">
        <ProductImage
          {...{
            imageurl,
            imageurls,
            imgClass,
            isHideImage,
          }}
        />
        {item.quantity && (
          <span className="rounded-full bg-primary leading-none text-tiny text-white p-1 text-center absolute -top-1 -right-1 block w-[16px] h-[16px]">
            {item.quantity}
          </span>
        )}
      </div>
      <div className="item-details md:ml-2 flex flex-col w-full">
        <div className="flex">
          <span
            className={classNames(
              "text-sm whitespace-normal mb-1 flex flex-col md:flex-row items-start md:items-center break-all md:break-words",
              {
                "line-through": isOldPro,
              }
            )}
          >
            <span>{getNameProduct()}</span>
            {renderSubName && renderSubName()}
          </span>
          {addOneAfterName && addOneAfterName()}
        </div>
        <div className="flex items-center mb-1">
          {!isHideSku && (
            <span
              className={classNames("text-xs text-gray-500 flex-1", {
                "line-through": isOldPro,
              })}
              style={{ textAlign: "start" }}
            >
              {t("sku")}:{sku}
            </span>
          )}
          {showPrice && (
            <div className="mr-[-14px]">
              <span className="text-xs line-through text-gray-500">
                <CurrencyFormat
                  value={
                    showRentPrice
                      ? item?.product?.regular_price_rent
                      : item.regular_price
                  }
                  displayType={"text"}
                  prefix={showRentPrice ? "" : "฿"}
                  thousandSeparator={true}
                />
              </span>
              <span className="text-xs ml-2">
                <CurrencyFormat
                  value={
                    showRentPrice
                      ? item?.product?.sale_price_rent
                      : item.sale_price
                  }
                  displayType={"text"}
                  prefix={showRentPrice ? "" : "฿"}
                  thousandSeparator={true}
                />
              </span>
            </div>
          )}
        </div>
        {subBody}
      </div>
    </div>
  );
};
const ProductImage = React.memo(
  ({ isHideImage, imageurl, imageurls, imgClass }: any) => {
    const imgUrl = useMemo<string>(() => {
      let url = "/images/img-default.png";
      if (imageurl && imageurl?.[0]) {
        url = imageurl?.[0];
      }
      if (imageurls && imageurls?.[0]) {
        url = imageurls?.[0];
      }
      return url;
    }, [imageurl, imageurls]);

    return isHideImage ? null : (
      <img
        src={imgUrl}
        className={`${imgClass} object-cover mt-1 flex-1`}
        alt=""
      />
    );
  }
);

export default ProductName;
