import {
  AwesomeTableComponent,
  Button,
  Notifications,
  Progress,
} from "d-react-components";
import { map } from "lodash";
import React, { useContext, useRef, useState } from "react";
import UserDetailRoleUpdateModal from "./UserDetailRoleUpdateModal";
import AppContext from "@helpers/context";
import { useTranslation } from "react-i18next";
import UserAPI from "@network/api/user";

const UserDetailRole = ({ updatable = true }: { updatable?: boolean }) => {
  const { user } = useContext(AppContext);
  const { t } = useTranslation();
  const tableRef = useRef<any>();
  const [openAdd, setOpenAdd] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);

  const columns = [
    {
      title: t("NO"),
      dataIndex: "id",
    },
    {
      title: t("name"),
      dataIndex: "name",
    },
    {
      title: t("code"),
      dataIndex: "code",
    },
  ];

  const source = (paging: any) => {
    const body = { id: user?.id, action: "in" };
    return UserAPI.roleList(body, paging);
  };

  const transformer = (res: any) => {
    return res?.data?.data?.roles ?? [];
  };

  const onAddRole = (roles: any) => {
    const body = {
      id: user?.id,
      action: "add",
      role: map(roles, (item) => item.id),
    };
    Progress.show({ method: UserAPI.roleUpdate, params: [body] }, () => {
      Notifications.showSuccess(t("updateUserRoleSuccess"));
      tableRef.current.refresh();
      setOpenAdd(false);
    });
  };

  const onRemoveRole = (roles: any) => {
    const body = {
      id: user?.id,
      action: "remove",
      role: map(roles, (item) => item.id),
    };
    Progress.show({ method: UserAPI.roleUpdate, params: [body] }, () => {
      Notifications.showSuccess(t("updateUserRoleSuccess"));
      tableRef.current.refresh();
      setOpenRemove(false);
    });
  };

  return (
    <div className="border mt-3 p-4">
      <div className="justify-content-between d-flex align-items-center mb-3">
        <h5>{t("role")}</h5>
        {updatable && (
          <div className="d-flex">
            <Button className="ml-3" onClick={() => setOpenRemove(true)}>
              {t("remove")}
            </Button>
            <Button className="ml-3" onClick={() => setOpenAdd(true)}>
              {t("add")}
            </Button>
          </div>
        )}
      </div>
      <AwesomeTableComponent
        ref={(ref) => {
          tableRef.current = ref;
        }}
        columns={columns}
        source={source}
        transformer={transformer}
        className="mt-3"
      />
      {openAdd && (
        <UserDetailRoleUpdateModal
          open={openAdd}
          onClose={() => setOpenAdd(false)}
          userId={user?.id}
          onSave={onAddRole}
          type="not_in"
          title={t("addRole")}
        />
      )}
      {openRemove && (
        <UserDetailRoleUpdateModal
          open={openRemove}
          onClose={() => setOpenRemove(false)}
          userId={user?.id}
          onSave={onRemoveRole}
          type="in"
          title={t("removeRole")}
        />
      )}
    </div>
  );
};

export default UserDetailRole;
