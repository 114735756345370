import ProductName from "@components/product/components/ProductName";
import AppContext from "@helpers/context";
import { AwesomeTableComponent, IColumnsProps } from "d-react-components";
import { find, forEach, map, reduce } from "lodash";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const TransferStockCrudProduct = () => {
  const { transferForm } = useContext(AppContext);
  const { t } = useTranslation();

  const [openBrowse, setOpenBrowse] = useState(false);
  const requestEvents = transferForm?.values?.requestEvents ?? [];

  const productData: any[] = reduce(
    requestEvents,
    (listPro: any[], requestItem) => {
      const products = requestItem?.products ?? [];
      console.log("requestEvents", requestItem);

      let result: any[] = [...listPro];
      products.forEach((iPro: any) => {
        const foundPro = find(listPro, (item: any) => item?.id === iPro?.id);
        // console.log("requestEvents", foundPro);

        if (foundPro) {
          result = map(result, (item: any) =>
            item?.id === foundPro?.id
              ? {
                  ...(foundPro ?? {}),
                  quantity: foundPro?.quantity ?? 0 + iPro?.quantity ?? 0,
                }
              : item
          );
        } else {
          result.push(iPro);
        }
      });

      return result;
    },
    []
  );

  const columns: IColumnsProps = [
    {
      title: t("item"),
      dataIndex: "",
      render: (product) => <ProductName item={{ product }} />,
    },
    {
      title: t("currentQuantity"),
      dataIndex: "quantity",
    },
  ];
  return (
    <div className="mt-3">
      <div className="flex-row-between-center">
        <label>{t("items")}</label>
        {/* <ProductSearchButton onChange={() => {}} /> */}
      </div>
      <AwesomeTableComponent
        // ref={(ref) => {
        //     tableRef.current = ref;
        // }}
        columns={columns}
        dataSource={productData}
        className=""
        pagination={false}
      />
    </div>
  );
};

export default TransferStockCrudProduct;
