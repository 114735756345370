import { PAYMENTS_METHOD, PAYMENT_CONFIRM_STATUSES } from "@constants/payment";
import AppContext from "@helpers/context";
import ImageIcon from "@common/Icon";
import { find, sortBy } from "lodash";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { C2PConfirmText } from "./C2PConfirmButtons";
import { TimeUtils } from "d-react-components";
import { getBankFullInfo } from "@helpers/bank";

export default function PaymentContainer({
  title,
  subTitle1,
  subTitle2,
  subTitle3,
  renderButtons,
  amount,
  method,
  transactionId = null,
  onClickChangeMethod,
  isShowChangeMethod,
  numberOfInstalment,
  bankName,
  payment,
  status,
  ...props
}: any) {
  const { t } = useTranslation();
  const { editable } = useContext(AppContext);
  const orderStatus = status
    ? status
    : find(PAYMENT_CONFIRM_STATUSES, {
        value: payment?.status,
      });

  const { payment_slip = [] } = payment || {};
  const latestSlip = sortBy(payment_slip, "created").reverse()[0];

  return (
    <div
      className="border-dashed p-3 my-3 w-100"
      style={{
        borderColor: orderStatus?.color,
        borderLeft: `8px solid ${orderStatus?.color}`,
      }}
    >
      <div className="flex flex-col md:flex-row justify-content-between">
        <div>
          {title && (
            <div className="text-primary font-weight-bold">{title}</div>
          )}
          {amount && (
            <div className="my-1">
              <span className="font-weight-bold">{`${t("amount")}: `}</span>
              {amount}
            </div>
          )}
          {transactionId && (
            <div className="my-1">
              <span className="font-weight-bold">{`${t("paymentRef")}: `}</span>
              {transactionId}
            </div>
          )}
          {numberOfInstalment > 0 && (
            <div className="my-1">
              <span className="font-weight-bold">{`${t(
                "numberOfInstalment"
              )}: `}</span>
              {numberOfInstalment}
            </div>
          )}
          {bankName && (
            <div className="my-1 flex items-center">
              <span className="font-weight-bold">{`${t("bankName")}: `}</span>
              <img src={bankName.logo} className="w-4 h-4 ml-2 mr-1" alt="" />
              <span>{bankName?.code}</span>
            </div>
          )}
          {method && (
            <div className="my-1 flex items-center">
              <span className="font-weight-bold mr-1">{`${t(
                "method"
              )}: `}</span>
              {method}
              {editable && (
                <span
                  className="text-primary cursor-pointer ml-2"
                  onClick={onClickChangeMethod}
                  hidden={!onClickChangeMethod || !isShowChangeMethod}
                >
                  <ImageIcon
                    className="w-[15px] cursor-pointer"
                    src="/images/icons/edit.svg"
                  />
                </span>
              )}
            </div>
          )}
          {latestSlip?.transfer_bank && (
            <div className="my-1 flex items-start transfer-to-bank">
              <span className="font-weight-bold whitespace-nowrap">{`${t(
                "bankOfTransfer"
              )}: `}</span>
              {latestSlip?.transfer_bank?.logo && (
                <img
                  src={latestSlip?.transfer_bank?.logo}
                  className="w-4 h-4 ml-2"
                  alt=""
                />
              )}
              <span className="ml-1">
                {getBankFullInfo(latestSlip?.transfer_bank)}
              </span>
            </div>
          )}
          {latestSlip?.transfer_date && (
            <div className="my-1 flex items-center transfer-date">
              <span className="font-weight-bold">{`${t(
                "dateOfPayment"
              )}: `}</span>
              <span className="ml-1">
                {TimeUtils.toDateTime(latestSlip?.transfer_date)}
              </span>
            </div>
          )}
          {latestSlip?.transfer_name && (
            <div className="my-1 flex items-center transfer-name">
              <span className="font-weight-bold">{`${t(
                "nameOfPayee"
              )}: `}</span>
              <span className="ml-1">{latestSlip?.transfer_name}</span>
            </div>
          )}
          {latestSlip?.credit_card_number && (
            <div className="my-1 flex items-center transfer-card">
              <span className="font-weight-bold">{`${t(
                "cardLast4Digit"
              )}: `}</span>
              <span className="ml-1">{latestSlip?.credit_card_number}</span>
            </div>
          )}
          {subTitle1 && <div className=" my-1">{subTitle1}</div>}
          {subTitle2 && <div className="text-success my-1">{subTitle2}</div>}
          {subTitle3 && <div className="text-success my-1">{subTitle3}</div>}
          {payment && payment?.method === PAYMENTS_METHOD.C2P && (
            <C2PConfirmText payment={payment} />
          )}
        </div>
        <div>{renderButtons && renderButtons}</div>
      </div>
      {props.children}
    </div>
  );
}
