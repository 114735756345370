import { PRODUCT_WARRANTIES, PRODUCT_WARRANTY } from "@constants/product";
import classNames from "classnames";
import { InputText, Select } from "d-react-components";
import { useTranslation } from "react-i18next";

interface IProductInput {
  productForm: any;
  className?: any;
}

const ProductInputWarranty = ({ productForm, className }: IProductInput) => {
  const formValues = productForm?.values;
  const { t } = useTranslation();

  const classNameField = "col-span-2";

  return (
    <div className={classNames("grid grid-cols-4 gap-4 ", className)}>
      <Select
        className={classNameField}
        onChange={(id) => {
          productForm.setFieldValue("warranty", id);
        }}
        value={formValues?.warranty}
        label={t("warranty")}
        getLabel={(item) => t(item.label)}
        dataSource={PRODUCT_WARRANTIES}
      />
      {formValues?.warranty === PRODUCT_WARRANTY.AVAILABLE && (
        <div className={classNameField}>
          <InputText
            label={t("warrantyPeriod")}
            onChange={(event) => {
              const { value } = event.target;
              const result = value ? value.replace(/\D/g, "") : 0;
              const period = parseInt(result);
              productForm.setFieldValue("warrantyPeriod", period);
            }}
            value={formValues?.warrantyPeriod?.toLocaleString()}
          />
          <div className="text-warning small mt-2">{`(equal to ${
            formValues?.warrantyPeriod / 12
          } year)`}</div>
        </div>
      )}
    </div>
  );
};

export default ProductInputWarranty;
