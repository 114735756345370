import UserAvatarName from "@components/shared/UserAvatarName";
import { DELIVERY_STOCK_STATUS_LIST } from "@constants/delivery";
import { DELIVERY_STATUS_LIST } from "@constants/order";
import AppContext from "@helpers/context";
import { IDelivery } from "@interfaces/delivery";
import {
  AwesomeTableComponent,
  DialogManager,
  Dropdown,
  HeaderTable,
  IColumnsProps,
  Notifications,
  TimeUtils,
  ViewLabelStatus,
} from "d-react-components";
import { filter, map, reduce } from "lodash";
import { useContext, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import TransferStockAddDeliveryDrawer from "./TransferStockAddDeliveryDrawer";
import TransferStockDetailDeliveryDrawer from "./TransferStockDetailDeliveryDrawer";
import TransferStockEditDeliveryDrawer from "./TransferStockEditDeliveryDrawer";
import { transferStockDeleteDelivery } from "@network/api/transfer-stock";

const getProductFromList = (list = [], pro: any) => {
  if (!(list?.length > 0)) {
    return [];
  }
  return filter(list, (item: any) => item?.product_id === pro?.productid);
};

const getDriverReceivedQty = (deliverList = [], product: any) => {
  const deliverItemList = reduce(
    deliverList,
    (list = [], item: any) => list.concat(item?.transfer_stock_product),
    []
  );
  const itemDeliver = getProductFromList(deliverItemList, product);
  return reduce(
    itemDeliver,
    (sum, item: any) => (sum += item.total_driver_receive),
    0
  );
};

const getStaffReceivedQty = (deliverList = [], product: any) => {
  const deliverItemList = reduce(
    deliverList,
    (list = [], item: any) => list.concat(item?.transfer_stock_product),
    []
  );
  const itemDeliver = getProductFromList(deliverItemList, product);
  return reduce(itemDeliver, (sum, item: any) => (sum += item.receive), 0);
};

const TransferStockDetailDeliveries = () => {
  const { transferDetail, loadTransferDetail } = useContext(AppContext);
  const { t } = useTranslation();
  const [openAdd, setOpenAdd] = useState<boolean>(false);
  const [deliveryToEdit, setDeliveryToEdit] = useState<any>();
  const [openDetail, setOpenDetail] = useState<IDelivery | null>(null);

  const tableRef = useRef<any>();

  const columns: IColumnsProps = [
    {
      title: t("ref"),
      dataIndex: "ref",
      // render: (ref: string, delivery: any) => <div></div>,
      render: (ref: string, delivery) => (
        <div
          className="text-primary text-underline cursor-default"
          onClick={() => setOpenDetail(delivery)}
        >
          {ref}
        </div>
      ),
    },
    {
      title: t("expectedDateArrival"),
      dataIndex: "estimated_arrival",
      render: TimeUtils.toDate,
    },
    {
      title: t("status"),
      dataIndex: "status",
      render: (status: any) => {
        console.log("status", status);
        return (
          <ViewLabelStatus
            status={status}
            listStatus={DELIVERY_STOCK_STATUS_LIST}
            getLabel={(item) => {
              return t(item.label);
            }}
          />
        );
      },
    },
    {
      title: t("vehicle"),
      dataIndex: "vehicle",
      render: (vehicle, item) =>
        vehicle ? vehicle?.registration : item?.vehicle_no,
    },
    {
      title: t("driver"),
      dataIndex: "driver",
      render: (driver, item) =>
        driver ? <UserAvatarName user={driver} /> : item?.driver_name,
    },
    {
      title: t("createdAt"),
      dataIndex: "created",
      render: TimeUtils.toDateTime,
    },
    {
      title: t("action"),
      dataIndex: "",
      render: (delivery) => {
        let CONTROL_ACTIONS = [
          { id: "edit", label: t("edit") },
          { id: "detail", label: t("detail") },
          { id: "cancel", label: t("cancel") },
        ];
        if (delivery.status !== "pending" && delivery.status !== "booking") {
          CONTROL_ACTIONS = filter(
            CONTROL_ACTIONS,
            (item) => item.id !== "edit" && item.id !== "cancel"
          );
        }

        return (
          <Dropdown
            iconName="more_vert"
            dataSource={CONTROL_ACTIONS}
            onClick={(item: any) => onClickAction(item?.id, delivery)}
          />
        );
      },
    },
  ];

  const onClickAction = (action: any, delivery: IDelivery) => {
    switch (action) {
      case "edit":
        setDeliveryToEdit(delivery);
        break;

      case "detail":
        setOpenDetail(delivery);
        break;

      case "cancel":
        onCancelDelivery(delivery);
        break;

      default:
        break;
    }
  };

  const onCancelDelivery = (delivery: IDelivery) => {
    DialogManager.showConfirm(
      t("removeDelivery"),
      <div className="body2">
        <div className="font-weight-bold">
          {t("areYouSureWantTODeleteThisDelivery")}
        </div>
        <div> * {t("pleaseNoteThatThisActionCannotBeUndone")}</div>
        <div> * {t("incaseYourStockTransferHas")}</div>
      </div>,
      async () => {
        await transferStockDeleteDelivery(delivery?.id);
        loadTransferDetail();
        Notifications.showSuccess(t("successfullyRemovedDelivery"));
      }
    );
  };

  const productSource = useMemo(() => {
    let result = [];
    if (transferDetail?.items?.length > 0) {
      result = map(transferDetail?.items, (iPro) => {
        const deliveryReceiveQty = getDriverReceivedQty(
          transferDetail?.delivery,
          iPro
        );
        const staffReceiveQty = getStaffReceivedQty(
          transferDetail?.delivery,
          iPro
        );
        return {
          ...iPro,
          deliveryReceiveQty,
          staffReceiveQty,
        };
      });
    }
    return result;
  }, [transferDetail?.items]);

  return (
    <div className="card-container p-4 ">
      <HeaderTable
        label={t("delivery")}
        onClickNew={() => setOpenAdd(true)}
        onChangeText={() => {}}
      />
      <AwesomeTableComponent
        ref={(ref) => {
          tableRef.current = ref;
        }}
        columns={columns}
        dataSource={transferDetail?.delivery ?? []}
        className="mt-3 height-fit-content"
        pagination={false}
      />
      {openAdd && (
        <TransferStockAddDeliveryDrawer
          open={openAdd}
          onClose={() => setOpenAdd(false)}
        />
      )}
      {deliveryToEdit && (
        <TransferStockEditDeliveryDrawer
          delivery={deliveryToEdit}
          open={!!deliveryToEdit}
          onClose={() => setDeliveryToEdit(null)}
        />
      )}
      {openDetail && (
        <TransferStockDetailDeliveryDrawer
          delivery={openDetail}
          open={!!openDetail}
          onClose={() => setOpenDetail(null)}
        />
      )}
    </div>
  );
};

export default TransferStockDetailDeliveries;
