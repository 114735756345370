import Drawer from "@components/shared/Drawer";
import classNames from "classnames";
import React, { useState, useImperativeHandle, forwardRef } from "react";
import { useTranslation } from "react-i18next";

const EditInfoDetailRef = (
  {
    title,
    isEdit,
    renderContent,
    content,
    onClickSave,
    renderContentModal,
    onClose,
    className = "mt-3",
  }: any,
  ref: any
) => {
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    close: () => {
      setOpenModal(false);
    },
  }));
  return (
    <div className={classNames("card-container p-3", className)}>
      <div className="flex-row-between-center">
        <div className="label">{title}</div>
        {isEdit && (
          <div
            className="text-small text-primary cursor-pointer"
            onClick={() => setOpenModal(true)}
          >
            {t("edit")}
          </div>
        )}
      </div>
      {renderContent && renderContent()}
      {content && (
        <div className="depositDetailInfoItem-content">{content}</div>
      )}
      <Drawer
        open={openModal}
        onClose={() => {
          onClose && onClose();
          setOpenModal(false);
        }}
        onSave={() => onClickSave && onClickSave()}
        title={title}
      >
        {renderContentModal && renderContentModal()}
      </Drawer>
    </div>
  );
};
const EditInfoDetail = forwardRef(EditInfoDetailRef);
export default EditInfoDetail;
