/* eslint-disable prefer-promise-reject-errors */
import { ADMIN_AUTH_KEY, ADMIN_LANG_KEY, ADMIN_TOKEN_KEY } from "@constants";
import { V1_AUTH_URL } from "@constants/common";
import { getAdminToken, getCallCenterToken } from "@helpers/account";
import { isGrantURLPermission } from "@helpers/permissions";
import { addUrlParam } from "@helpers/url";
import { Notifications } from "d-react-components";
import { isEmpty } from "lodash";
// import PermissionUtils from "../utils/PermissionUtils";

const UNAUTHORIZE = 401;
const SERVER_ERROR = 502;
const DATA_ERROR = 400;

export const AccessTokenInterceptor = {
  addAccessToken: (config: any) => {
    const headers = { ...config.headers, Accept: "application/json" };
    config.headers = headers;
    return config;
  },

  onRejected: (error: any) => {
    return Promise.reject(error);
  },
};

export const AddTokenToURL = {
  addAccessToken: (config: any) => {
    const token = getAdminToken();
    if (!isEmpty(token)) {
      const addedParamUrl = addUrlParam(config?.url, "api_token", token);
      config.url = addedParamUrl;
    }

    return config;
  },
  onRejected: (error: any) => {
    return Promise.reject(error);
  },
};

export const AddCallCenterToken = {
  addAccessToken: (config: any) => {
    const token = getCallCenterToken();
    if (!isEmpty(token)) {
      const addedParamUrl = addUrlParam(
        config?.url,
        "token",
        JSON.parse(token)
      );
      config.url = addedParamUrl;
    }

    return config;
  },
  onRejected: (error: any) => {
    return Promise.reject(error);
  },
};

export const LanguageInterceptor = {
  addLanguage: (config: any) => {
    if (config?.headers?.language) {
      return config;
    }
    const savedLang = localStorage.getItem(ADMIN_LANG_KEY);
    const language = savedLang ? JSON.parse(savedLang) : "en";
    const headers = { ...config.headers, language };
    config.headers = headers;

    return config;
  },
};

export const PermissionInterceptor = {
  checkPermission: (config: any) => {
    if (isGrantURLPermission(config.url, config.method)) {
      return config;
    }
    const urlSlash = config.url.split("/");
    const urlTail = urlSlash[urlSlash.length - 1];
    if (!config.notShowError) {
      Notifications.showError(`No permission ${urlTail}`);
    }
    return Promise.reject({
      message: `No permission ${urlTail}`,
    });
  },
};

export const UnauthorizeInterceptor = {
  onFullfilled: (response: any) => {
    return Promise.resolve(response);
  },

  // eslint-disable-next-line consistent-return
  onRejected: (error: any) => {
    const originalRequest = error.config;
    if (error) {
      const status = error?.response?.data?.status;
      if (status === UNAUTHORIZE) {
        Notifications.showError(error?.response?.data?.message ?? "Error data");
        localStorage.removeItem(ADMIN_TOKEN_KEY);
        localStorage.removeItem(ADMIN_AUTH_KEY);
        window.location.replace(
          process.env.REACT_APP_ENV === "development" ? "/login" : V1_AUTH_URL
        );
        return Promise.reject(error);
      }
      if (status === DATA_ERROR) {
        // Notifications.showError(error?.response?.data?.message ?? "Error data");
        if (originalRequest.keepServerErrorFormat) {
          return Promise.reject(error?.response?.data);
        }
        return Promise.reject(error?.response?.data?.message ?? "Error data");
      }
      return Promise.reject(error);
    }
  },
};
