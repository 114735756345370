import { CUSTOMER_INFO_MAP_STEPS } from "@constants/customer";
import * as Yup from "yup";

import {
  getDistrictList,
  getProvinceList,
  getSubDistrictList,
} from "@network/api/address";
import {
  createShopeAddressShipping,
  shopeAddressShippingUpload,
} from "@network/api/customer";
import { checkOrderShope } from "@network/api/order";
import {
  Button,
  Icon,
  InputText,
  Progress,
  Select,
  ViewTextError,
} from "d-react-components";
import { useFormik } from "formik";
import { filter, isEmpty, split } from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import Carousel, { Modal, ModalGateway } from "react-images";
import { Link } from "react-router-dom";
import { t } from "i18next";
import AppContext from "@helpers/context";

const { createWorker } = require("tesseract.js");

export const INIT_IMPORT_FORM = {
  importFields: [],
  productType: null,
  importType: null,
  fileImport: null,
};

const CustomerInfoMappingFooter = () => {
  const { stepIndex, setStepIndex, customerForm } = useContext(AppContext);
  const { t } = useTranslation("customer");

  const stepNextValue = useMemo(
    () => CUSTOMER_INFO_MAP_STEPS[stepIndex + 1],
    [stepIndex]
  );
  const classNameFooter =
    "d-flex justify-content-end align-items-center p-4 bg-gray-light";

  const getDisabledNextStep = () => {
    const { shopeOrderId, fileInfo, customerInfo, errors } =
      customerForm.values;

    switch (stepIndex) {
      case 0:
        return !customerForm?.values?.order;
      case 1:
        return !fileInfo || !customerInfo;
      case 2:
        return (
          !customerInfo ||
          shopeOrderId !== customerInfo?.shopeOrderId ||
          !customerInfo?.firstName ||
          !customerInfo?.lastName ||
          !customerInfo?.province ||
          !customerInfo?.subdistrict ||
          !customerInfo?.postcode
        );
      // return false;
      case 3:
        return errors?.length !== 0;
      default:
        return false;
    }
  };

  const onClickResetDefault = () => {
    setStepIndex(0);
    customerForm.setValues(INIT_IMPORT_FORM);
  };

  if (stepIndex === CUSTOMER_INFO_MAP_STEPS?.length - 1) {
    return (
      <div className={classNameFooter}>
        <Button onClick={onClickResetDefault}>{t("newMapping")}</Button>
      </div>
    );
  }

  return (
    <div className={classNameFooter}>
      {stepIndex !== 0 && (
        <Button
          variant="outline"
          iconName="chevron_left"
          className="mr-3"
          onClick={() => setStepIndex(stepIndex - 1)}
        >
          {t("previousStep")}
        </Button>
      )}
      <Button
        disabled={getDisabledNextStep()}
        onClick={() => setStepIndex(stepIndex + 1)}
      >{`${t(stepNextValue?.title)}`}</Button>
    </div>
  );
};

const OrderView = ({ order }: any) => {
  const { t } = useTranslation();

  return (
    <div className="w-100 mt-3">
      <label>{t("order:systemOrderId")}</label>
      <div className="bg-gray-200 px-3 py-2">
        <Link to={`/order/${order?.id}`}>{order?.orderid}</Link>
      </div>
    </div>
  );
};

const CommerceOrderValidate = () => {
  const { t } = useTranslation();

  const { customerForm } = useContext(AppContext);
  const formValues = customerForm?.values;

  const checkingOrderShope = () => {
    Progress.show(
      { method: checkOrderShope, params: [customerForm?.values?.shopeOrderId] },
      (res: any) => {
        const order = res?.data?.data?.order;
        customerForm.setFieldValue("order", order);
        customerForm.setFieldError("shopeOrderId", null);
      },
      (error: any) => {
        customerForm.setFieldValue("order", null);
        customerForm.setFieldError("shopeOrderId", error);
      }
    );
  };

  return (
    <div>
      <div className="d-flex align-items-end">
        <InputText
          label={t("order:shopeOrderId")}
          className="w-100"
          onChange={(event) =>
            customerForm.setFieldValue("shopeOrderId", event?.target?.value)
          }
          placeholder={t("pleaseInput")}
        />
        <Button
          onClick={checkingOrderShope}
          className="ml-3"
          disabled={isEmpty(formValues?.shopeOrderId)}
        >
          {t("validate")}
        </Button>
      </div>
      <ViewTextError error={customerForm?.errors?.shopeOrderId} />
      {customerForm?.values?.order && (
        <OrderView order={customerForm?.values?.order} />
      )}
    </div>
  );
};

const InputOrderSlip = () => {
  const { customerForm } = useContext(AppContext);
  const { t } = useTranslation();

  const onDrop = ([file]: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      customerForm.setFieldValue("fileInfo", {
        imageData: reader.result,
        fileData: file,
      });
    };

    const data = new FormData();
    data.append("image", file);

    Progress.show(
      { method: shopeAddressShippingUpload, params: [data] },
      (res: any) => {
        const extractedData = res?.data?.data ?? {};
        customerForm.setFieldValue("customerInfo", {
          firstName: split(extractedData?.name, " ")?.[0] ?? "",
          lastName: split(extractedData?.name, " ")?.[1] ?? "",
          address: extractedData?.address,
          province: extractedData?.province_id,
          city: extractedData?.city_id,
          subdistrict: extractedData?.subdistrict_id,
          postcode: extractedData?.postcode,
          refId: extractedData?.id,
          shopeOrderId: extractedData?.order_number,
        });
      }
    );
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  if (!customerForm?.values?.order) return <div />;

  return (
    <div>
      <InputText
        label={t("order:shopeOrderId")}
        className="w-100 mt-3"
        value={customerForm?.values?.shopeOrderId}
        disabled
      />
      <OrderView order={customerForm?.values?.order} />

      <div
        className="flex-center border-dashed p-5 flex-column mt-3"
        {...getRootProps()}
      >
        <img
          src={"/images/insert-file.svg"}
          id="csvIconImage"
          className="mt-5"
        />
        <input {...getInputProps()} />
        <text className="font-weight-bold my-3">{t("dragDropImageHere")}</text>
        <small>{t("maximumFile5MB")}</small>
        <small className="mb-5 text-primary text-underline">
          {t("orSelectFromYourComputer")}
        </small>
        <text className="mb-5 text-primary">
          {customerForm?.values?.fileInfo?.fileData?.name ?? ""}
        </text>
      </div>
    </div>
  );
};

const RecheckCustomerData = () => {
  const { customerForm } = useContext(AppContext);

  const [postcodeList, setPostCodeList] = useState<any[]>([]);
  const [cityList, setCityList] = useState<any[]>([]);
  const [provinceList, setProvinceList] = useState<any[]>([]);
  const [subdistrictList, setSubdistrictList] = useState<any[]>([]);
  const [modalImageView, setModalImageView] = useState(null);

  const { t } = useTranslation();
  const formValues = customerForm?.values;
  const customerInfo = formValues?.customerInfo ?? {};
  const formErrors = customerForm?.errors;

  useEffect(() => {
    getProvinceList().then((res) => {
      const provinceList = res?.data?.data?.province ?? [];
      setProvinceList(provinceList);
    });
  }, []);

  const loadPostCodeData = (subdistrictId: string) => {
    if (!subdistrictId) {
      return;
    }
    const postcodeResult =
      filter(subdistrictList, (pro) => pro.id === subdistrictId)?.[0]?.code ??
      [];
    setPostCodeList(postcodeResult);
  };

  const loadCityData = (province: any) => {
    getDistrictList(province).then((res) => {
      const cityListRes = res?.data?.data?.city ?? [];
      setCityList(cityListRes);
    });
  };

  const loadSubdistrictData = (city: any) => {
    getSubDistrictList(city).then((res) => {
      const subdistrict = res?.data?.data?.subdistrict ?? [];
      setSubdistrictList(subdistrict);
    });
  };

  useEffect(() => {
    loadCityData(customerInfo?.province);
  }, [customerInfo?.province]);

  useEffect(() => {
    loadSubdistrictData(customerInfo?.city);
  }, [customerInfo?.city]);

  useEffect(() => {
    loadPostCodeData(customerInfo?.subdistrict);
  }, [customerInfo?.subdistrict]);

  const classNameInput = "col-6 mt-3";
  return (
    <div className="d-flex">
      <div className="flex-center mr-3">
        <div
          className="position-relative image-show-wrapper"
          onClick={() => setModalImageView(formValues?.fileInfo?.imageData)}
        >
          <img
            src={formValues?.fileInfo?.imageData}
            className="h-[300px] w-auto "
          />
          <div className="overlay flex-center position-absolute">
            <Icon name="open_with" color="white" />
          </div>
        </div>

        <ModalGateway>
          {modalImageView ? (
            //  @ts-ignore
            <Modal onClose={() => setModalImageView(null)}>
              {/* @ts-ignore  */}
              <Carousel
                currentIndex={0}
                views={[
                  {
                    source: modalImageView as string,
                  },
                ]}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>

      <div className="row">
        <InputText
          label={t("order:shopeOrderId")}
          className={"col-12"}
          // disabled
          onChange={(event) => {
            customerForm.setFieldValue(
              "customerInfo.shopeOrderId",
              event?.target?.value
            );
          }}
          value={customerInfo?.shopeOrderId}
          error={
            customerInfo?.shopeOrderId !== customerForm?.values?.shopeOrderId
              ? t("customer:sorryExtractedShopeDiff")
                  .replace("%extractedNo", customerInfo?.shopeOrderId)
                  .replace("%inputNo", customerForm?.values?.shopeOrderId)
              : ""
          }
        />
        <InputText
          label={t("firstName")}
          className={classNameInput}
          onChange={(event) =>
            customerForm.setFieldValue(
              "customerInfo.firstName",
              event?.target?.value
            )
          }
          placeholder={t("pleaseInput")}
          value={customerInfo?.firstName}
        />
        <InputText
          label={t("lastName")}
          className={classNameInput}
          onChange={(event) =>
            customerForm.setFieldValue(
              "customerInfo.lastName",
              event?.target?.value
            )
          }
          placeholder={t("pleaseInput")}
          value={customerInfo?.lastName}
        />
        <InputText
          label={t("address")}
          className="col-12 mt-3"
          onChange={(event) =>
            customerForm.setFieldValue(
              "customerInfo.address",
              event?.target?.value
            )
          }
          placeholder={t("pleaseInput")}
          value={customerInfo?.address}
        />

        <Select
          className={classNameInput}
          label={t("province")}
          dataSource={provinceList}
          value={customerInfo?.province}
          onChange={(val) => {
            customerForm?.setFieldValue("customerInfo.province", val);
            customerForm?.setFieldValue("customerInfo.city", null);
            customerForm?.setFieldValue("customerInfo.subdistrict", null);
            customerForm?.setFieldValue("customerInfo.postcode", null);
          }}
          getLabel={(item) => item?.name}
          showSearch
          error={formErrors?.province}
          placeholder={t("pleaseSelect")}
        />
        <Select
          className={classNameInput}
          label={t("city")}
          dataSource={cityList}
          value={customerInfo?.city}
          onChange={(val) => {
            customerForm?.setFieldValue("customerInfo.city", val);
            customerForm?.setFieldValue("customerInfo.subdistrict", null);
            customerForm?.setFieldValue("customerInfo.postcode", null);
          }}
          getLabel={(item) => item?.name}
          showSearch
          disabled={!customerInfo.province}
          error={formErrors?.city}
          placeholder={t("pleaseSelect")}
        />
        <Select
          label={t("subdistrict")}
          className={classNameInput}
          dataSource={subdistrictList}
          value={customerInfo?.subdistrict}
          onChange={(val) => {
            customerForm?.setFieldValue("customerInfo.subdistrict", val);
            customerForm?.setFieldValue("customerInfo.postcode", null);
          }}
          getLabel={(item) => item?.name}
          showSearch
          disabled={!customerInfo.city}
          error={formErrors?.subdistrict}
          placeholder={t("pleaseSelect")}
        />
        <Select
          className={classNameInput}
          label={t("postcode")}
          dataSource={postcodeList}
          value={customerInfo?.postcode}
          onChange={(val) => {
            customerForm?.setFieldValue("customerInfo.postcode", val);
          }}
          getLabel={(item) => item?.postcode}
          getValue={(item) => item?.postcode}
          showSearch
          disabled={!customerInfo.subdistrict}
          error={formErrors?.postcode}
          placeholder={t("pleaseSelect")}
        />
      </div>
    </div>
  );
};

const CustomerMapInfo = () => {
  const { t } = useTranslation("customer");
  const { customerForm } = useContext(AppContext);
  const formValues = customerForm?.values ?? {};
  const [shippingAddressRef, setShippingAddressRef] = useState("");

  useEffect(() => {
    onCreateShippingAddress();
  }, []);

  const onCreateShippingAddress = () => {
    const body = {
      order_shopee_id: formValues?.shopeOrderId,
      first_name: formValues?.customerInfo?.firstName,
      last_name: formValues?.customerInfo?.lastName,
      address: formValues?.customerInfo?.address,
      province_id: formValues?.customerInfo?.province,
      city_id: formValues?.customerInfo?.city,
      subdistrict_id: formValues?.customerInfo?.subdistrict,
      post_code: formValues?.customerInfo?.postcode,
    };
    Progress.show(
      {
        method: createShopeAddressShipping,
        params: [formValues?.customerInfo?.refId, body],
      },
      (res: any) => {
        const shippingAddressRef =
          res?.data?.data?.address?.shipping_address_no ?? "";

        setShippingAddressRef(shippingAddressRef);
      }
    );
  };

  return (
    <div className="flex-center flex-column">
      <img className="image-square-lagre" src="/images/icons/approve.svg" />
      <div className="mt-3">
        {t("mappedSuccessfullyText").replace(
          "%shopeOrderId",
          formValues?.shopeOrderId
        )}
        <Link to={`/order/${formValues?.order?.id}`} className="ml-1">
          {formValues?.order?.orderid}
        </Link>
      </div>
      <div className="mt-3">{`Ref. ID: ${shippingAddressRef}`}</div>
    </div>
  );
};

const CustomerInfoSchema = Yup.object().shape({
  firstName: Yup.string().required(t("fieldRequired")),
  lastName: Yup.string().required(t("fieldRequired")),
  address: Yup.string().required(t("fieldRequired")),
  province: Yup.mixed().required(t("fieldRequired")),
  city: Yup.mixed().required(t("fieldRequired")),
  subdistrict: Yup.mixed().required(t("fieldRequired")),
  postcode: Yup.string().required(t("fieldRequired")),
});

const CustomerInfoMappingCreate = () => {
  const [stepIndex, setStepIndex] = useState<number>(0);

  const { t } = useTranslation("customer");

  const step = useMemo(() => CUSTOMER_INFO_MAP_STEPS[stepIndex], [stepIndex]);

  const customerForm = useFormik<any>({
    initialValues: INIT_IMPORT_FORM,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({ customerInfo: CustomerInfoSchema }),
    onSubmit: (values) => {},
  });

  const classNameHeader =
    "d-flex justify-content-between align-items-center p-4 border-bottom";

  const renderContent = () => {
    switch (stepIndex) {
      case 0:
        return <CommerceOrderValidate />;
      case 1:
        return <InputOrderSlip />;
      case 2:
        return <RecheckCustomerData />;
      case 3:
        return <CustomerMapInfo />;
      default:
        return <div />;
    }
  };

  return (
    <AppContext.Provider value={{ customerForm, stepIndex, setStepIndex }}>
      <div className="p-3">
        <h4>{t("newDataMapping")}</h4>
        <div className="card-container mt-3">
          <div className={classNameHeader}>
            <h5>{t(step?.title as string)}</h5>
            <div className="bg-dark px-3 py-1">
              <small className="text-white">{`Step ${stepIndex + 1} of ${
                CUSTOMER_INFO_MAP_STEPS.length
              }`}</small>
            </div>
          </div>
          <div className="p-4">{renderContent()}</div>
          <CustomerInfoMappingFooter />
        </div>
      </div>
    </AppContext.Provider>
  );
};

export default CustomerInfoMappingCreate;
