import classNames from "classnames";
import {
  SelectInfinity as DSelectInfinity,
  SelectInfinityProps as DSelectInfinityProps,
} from "d-react-components";
import { debounce, isEmpty } from "lodash";
import React, {
  ElementRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

export interface SelectInfinityProps {
  fetchFn: (body: any, params: any) => Promise<any>;
  customQuery?: (search?: string) => any;
  customParams?: any;
  dataPath: string;
  lazyLoad?: boolean;
  inputStyle?: "default" | "filled";
  additionalOptions?: any[];
}

export interface SelectInfinityMethods {
  onSearch: (value: any) => void;
  onFocus: (value?: any) => void;
}

const SelectInfinityRef: React.ForwardRefRenderFunction<
  SelectInfinityMethods,
  Partial<DSelectInfinityProps> & SelectInfinityProps
> = (
  {
    classNameSelect,
    fetchFn,
    dataPath,
    customQuery,
    customParams,
    lazyLoad,
    inputStyle = "default",
    additionalOptions = [],
    ...props
  },
  ref
) => {
  const [textSearch, setTextSearch] = useState<string>();
  const listRef = useRef<ElementRef<typeof DSelectInfinity>>(null);
  const defaultSelectClass = classNames(
    {
      "border-x border-b border-t-0 pt-0 pb-0 px-1 text-xs outline-0 shadow-none":
        inputStyle === "filled",
      "bg-gray-100": props.disabled,
    },
    classNameSelect
  );

  const defaultLabelClass = classNames(
    {
      "block border-x border-t pt-2 pl-3 font-normal text-[0.625rem] text-gray-400":
        inputStyle === "filled",
      "bg-gray-100": inputStyle === "filled" && props.disabled,
    },
    classNameSelect
  );

  const refreshList = () => {
    return listRef.current && listRef.current.onRefresh();
  };
  const handleChangeTextSearch = debounce((text: string) => {
    setTextSearch(text);
    refreshList();
  }, 300);

  useImperativeHandle(ref, () => ({
    onSearch: handleChangeTextSearch,
    onFocus: () => listRef.current && listRef.current.onFocus(),
  }));

  useEffect(() => {
    if (customParams) {
      refreshList();
    }
  }, [customParams]);

  return (
    <DSelectInfinity
      onSearch={handleChangeTextSearch}
      ref={listRef}
      source={(params, paging) => {
        if (lazyLoad && (!textSearch || !isEmpty(customQuery))) {
          return Promise.resolve();
        }
        const payload: any = {};
        const pagination: any = {
          page: paging.pageIndex,
          per_page: paging.pageSize,
        };
        if (customQuery) {
          Object.assign(payload, customQuery(textSearch));
        } else {
          if (textSearch) {
            payload.search = textSearch;
          }
        }
        return fetchFn(payload, pagination);
      }}
      transformer={(res) => {
        const data = res?.data?.data?.[dataPath] ?? [];
        let transform = [];
        if (data?.length > 0) {
          transform = data?.map((item: any) => ({
            ...item,
            value: item?.id,
          }));
        }
        const addedItems =
          res?.data?.data?.pagination?.currentPage === 1
            ? additionalOptions
            : [];
        return [...addedItems, ...transform];
      }}
      classNameSelect={`border outline-slate-400 text-xs w-full ${defaultSelectClass}`}
      classNameLabel={defaultLabelClass}
      className={`${inputStyle}`}
      {...props}
    />
  );
};

export const SelectInfinity = React.forwardRef(SelectInfinityRef);
