import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Spin } from "antd";
import { Icon } from "d-react-components";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

export default function UploadAvatarModule({
  container = "uploadImagesModule",
  containerSize = { width: "125px", height: "125px" },
  iconSize = "2.5rem",
  avatar = "",
  setAvatar,
}: any) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [previewAvatar, setPreviewAvatar] = useState<any>();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  function onDrop(fileUpload: any[] = []) {
    const reader = new FileReader();
    const url = reader.readAsDataURL(fileUpload[0]);
    reader.onloadend = function (e) {
      setPreviewAvatar(reader.result);
      setAvatar(fileUpload[0]);
    };
  }

  return (
    <StyledUploadAvatar
      className={container}
      style={containerSize}
      {...getRootProps()}
    >
      <input {...getInputProps()} multiple={false} />
      {previewAvatar ? (
        <img className="avatarPhoto" src={previewAvatar} />
      ) : (
        <>
          <Icon name="camera_alt" style={{ fontSize: iconSize }} />
          {loading ? <Spin size="default" /> : <div>{t("upload")}</div>}
        </>
      )}
    </StyledUploadAvatar>
  );
}

const StyledUploadAvatar = styled.div`
  &.uploadImagesModule {
    background: #ebebeb;
    border-radius: 999px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem 0rem;

    &:hover {
      cursor: pointer;
    }

    .avatarPhoto {
      height: 100%;
      width: 100%;
      border-radius: 999px;
    }
  }
`;