import { Path } from "@components/layout/Path";
import UserAvatarName from "@components/shared/UserAvatarName";
import {
  EVENT_VISIBILITY_STATUSES,
  INVENTORY_EVENT_STATUSES,
} from "@constants/inventory-control-event";
import { getListInventoryControlEvent } from "@network/api/inventory-control-event";
import {
  AwesomeTableComponent,
  HeaderTable,
  TimeUtils,
  useFirstTime,
  ViewLabelStatus,
} from "d-react-components";
import { debounce, map } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import InventoryEventFilterDrawer from "./InventoryEventFilterDrawer";

const InventoryControlEventTable = () => {
  const [openFilter, setOpenFilter] = useState(false);
  const [filterBody = {}, setFilterBody] = useQueryParam("filter", JsonParam);
  const tableRef = useRef<any>();
  const searchRef = useRef();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isFirstTime = useFirstTime();

  const isFiltered = useMemo(
    () => Object.keys(filterBody).length > 0,
    [filterBody]
  );

  useEffect(() => {
    if (isFirstTime) return;
    tableRef?.current?.refresh();
  }, [JSON.stringify(filterBody)]);

  const columns = [
    {
      title: t("title"),
      dataIndex: "title",
      render: (name: string, event: any) => (
        <Link
          to={generatePath(Path.INVENTORY_CONTROL_EVENT_DETAIL, {
            eventId: event.id,
          })}
        >
          {name}
        </Link>
      ),
    },
    {
      title: t("chain"),
      dataIndex: "chain",
      render: (chain: any) => chain?.name,
    },
    {
      title: t("warehouse"),
      dataIndex: "warehouse",
      render: (warehouse: any) => warehouse?.name,
    },
    {
      title: t("code"),
      dataIndex: "code",
    },
    {
      title: t("period"),
      dataIndex: "",
      render: (event: any) =>
        `${TimeUtils.toDateTime(event?.from)} - ${TimeUtils.toDateTime(
          event?.to
        )}`,
    },
    {
      title: t("status"),
      dataIndex: "status",
      render: (status: any) => (
        <ViewLabelStatus
          status={status}
          listStatus={INVENTORY_EVENT_STATUSES}
          getLabel={(item) => t(item.label)}
        />
      ),
    },
    {
      title: t("visibility"),
      dataIndex: "visibility",
      render: (visibility: boolean) => (
        <ViewLabelStatus
          listStatus={EVENT_VISIBILITY_STATUSES}
          status={visibility ? "enabled" : "disabled"}
        />
      ),
    },

    {
      title: t("createdBy"),
      dataIndex: "by",
      render: (by: any) => <UserAvatarName user={by} />,
    },
    {
      title: t("createdAt"),
      dataIndex: "created",
      render: TimeUtils.toDateTime,
    },
  ];

  const source = (paging: any) => {
    const { channels, warehouses, status, visibility, createdBy } = filterBody;
    const body = {
      channels: map(channels, (item) => item.id),
      warehouses: map(warehouses, (item) => item.id),
      status,
      visibility,
      created_by: map(createdBy, (item) => item.id),
      search: searchRef.current,
    };
    return getListInventoryControlEvent(body, paging);
  };

  const transformer = (res: any) => {
    return res?.data?.data?.control ?? [];
  };

  const onChangeSearch = debounce((text) => {
    searchRef.current = text;
    tableRef.current.refresh();
  }, 1000);

  const onFilter = (values: any) => {
    setFilterBody(values);
    tableRef.current.refresh();
  };

  return (
    <div className="bg-white">
      <HeaderTable
        label={t("storeEventInventoryControl")}
        onChangeText={(event: any) => onChangeSearch(event.target.value)}
        onClickNew={() => navigate(Path.INVENTORY_CONTROL_EVENT_CREATE)}
        onClickFilter={() => setOpenFilter(true)}
        isFiltered={isFiltered}
        className="app-layout__page-header"
      />
      <AwesomeTableComponent
        ref={(ref) => {
          tableRef.current = ref;
        }}
        columns={columns}
        source={source}
        transformer={transformer}
      />
      {openFilter && (
        <InventoryEventFilterDrawer
          defaultValue={filterBody}
          open={openFilter}
          onClose={() => {
            setOpenFilter(false);
          }}
          onSave={setFilterBody}
        />
      )}
    </div>
  );
};

export default InventoryControlEventTable;
