import InputSelectForm from "@common/input/InputSelectForm";
import InputTextForm from "@common/input/InputTextForm";
import Drawer from "@components/shared/Drawer";
import { GP_EVENT_VISIBILITIES } from "@constants/gp-event";
import { exportToCSV } from "@helpers/file";
import { updateGPEvent } from "@network/api/gp-event";
import {
  DateInput,
  DialogManager,
  Icon,
  Modal,
  Notifications,
  Progress,
} from "d-react-components";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

export const GPEventGeneralSchema = Yup.object().shape({
  name: Yup.string().required("Required field!"),
  visibility: Yup.string().required("Required field!"),
  remark: Yup.string().required("Required field!"),
  period: Yup.array().required("Required field!"),
});

const GPEventDetailGeneralEditDrawer = ({
  open,
  onClose,
  defaultValue,
  onUpdateSuccess,
}: any) => {
  const { t } = useTranslation();

  const generalForm = useFormik({
    initialValues: defaultValue,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: GPEventGeneralSchema,
    onSubmit: (values) => {
      const body = {
        ...values,
        from: values?.period?.[0]?.valueOf(),
        to: values?.period?.[1]?.valueOf(),
      };
      Progress.show(
        { method: updateGPEvent, params: [body] },
        () => {
          Notifications.showSuccess(t("updateGPEventSuccess"));
          onUpdateSuccess();
          onClose();
        },
        onErrorEditGeneralInfo
      );
    },
  });
  const formValues = generalForm?.values;
  const formErrors = generalForm?.errors;

  const onErrorEditGeneralInfo = (error: any) => {
    const errorList = error?.response?.data?.data ?? [];
    DialogManager.showConfirm(
      t("error"),
      <div className="d-flex align-items-center flex-wrap">
        <Icon name="error_outline" className="text-error" />
        <div className="ml-2">{`${errorList?.length} items currently exist in another on-going GP Events`}</div>
        <div
          className="text-primary cursor-pointer ml-1"
          onClick={() => exportToCSV(errorList, "Import Error Log")}
        >
          {t("downloadLog")}
        </div>
      </div>
    );
    return true;
  };

  const classNameField = "col-12 mt-3";
  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("general")}
      onSave={() => generalForm.handleSubmit()}
    >
      <div className="row">
        <InputTextForm
          keyData="name"
          form={generalForm}
          className={classNameField}
          required
        />
        <InputSelectForm
          keyData="visibility"
          form={generalForm}
          className={classNameField}
          dataSource={GP_EVENT_VISIBILITIES}
          getLabel={(item: any) => t(item.label)}
          required
        />
        <DateInput
          isRangePicker
          value={formValues?.period}
          onChange={(value) => generalForm.setFieldValue("period", value)}
          label={t("period")}
          className={classNameField}
          error={formErrors?.period as any}
          required
          showTime
          format="DD/MM/YYYY HH:mm"
        />
        <InputTextForm
          keyData="remark"
          form={generalForm}
          className={classNameField}
          multiple
          required
        />
      </div>
    </Drawer>
  );
};

export default GPEventDetailGeneralEditDrawer;
