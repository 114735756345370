import HoverableButton from "@components/shared/HoverableButton";
import { CART_RULE_ACTION_KEY, ORDER_STATUS } from "@constants/order";
import AppContext from "@helpers/context";
import { Button, Icon, Notifications } from "d-react-components";
import { isEmpty, isUndefined } from "lodash";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, Menu } from "antd";
import AddCustomItemDrawer from "../components/AddCustomItemDrawer";
import BillingAddressDrawer from "../components/BillingAddressDrawer";
import ChainDiscountDrawer from "../components/ChainDiscountDrawer";
import DiscountDrawer from "../components/DiscountDrawer";
import LoadOrderDrawer from "../components/LoadOrderDrawer";
import OrderNoteDrawer from "../components/OrderNoteDrawer";
import ShippingAddressDrawer from "../components/ShippingAddressDrawer";
import AddAdditionalFee from "../components/AddAdditionalFee";
import { OrderType } from "@constants/common";
import { isGrantURLPermission } from "@helpers/permissions";
import { API_CREATE_ORDER } from "@network/URL";
import ScheduledDeliveryDate from "../components/ScheduledDeliveryDate";
import ModalChangePaymentMethod from "../components/payment/ModalChangePaymentMethod";

const OrderProductActions = React.memo(({ applyCoupon, submitOrder }: any) => {
  const { t } = useTranslation();
  const {
    productList,
    productCustomList,
    setProductCustomList,
    values,
    freeGiftList,
    shippingProfile,
    billingProfile,
    discountChainList,
    type,
    setFieldValue,
    totalState,
    storeSelected,
  } = useContext(AppContext);
  const [openDiscountModal, setOpenDiscountModal] = useState(false);
  const [openChainDiscount, setOpenChainDiscount] = useState(false);
  const [openLoadOrder, setOpenLoadOrder] = useState(false);
  const [openAddCustomItem, setOpenAddCustomItem] = useState(false);
  const [openShippingAddress, setOpenShippingAddress] = useState(false);
  const [openBillingAddress, setOpenBillingAddress] = useState(false);
  const [openOrderNote, setOpenOrderNote] = useState(false);
  const [openAddAdditionalFee, setOpenAddAdditionalFee] = useState(false);
  const [openScheduledDelivery, setOpenScheduledDelivery] = useState(false);
  const [openPaymentMethod, setOpenPaymentMethod] = useState(false);
  const { isChainExternal, channel, note } = values;

  const onApplyCouponHandle = (payload: any) => {
    applyCoupon(payload);
  };

  const handleOnClickAddDiscount = () => {
    if (isEmpty(channel)) {
      Notifications.showError(
        t("notification:pleaseSelectProductChannelFirst")
      );
      return;
    }
    if (productList?.length === 0 && productCustomList.length === 0) {
      Notifications.showError(t("notification:pleaseAddProductToCartFirst"));
      return;
    }
    if (isChainExternal) {
      setOpenChainDiscount(true);
    } else {
      setOpenDiscountModal(true);
    }
  };

  const onSaveCustomItem = (customItem: any) => {
    setProductCustomList([...productCustomList, customItem]);
    setOpenAddCustomItem(false);
  };

  const onClickMoreActionItem = (item: any) => {
    switch (item?.id) {
      case "addAdditionalFee":
        setOpenAddAdditionalFee(true);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="">
        <div className="fixed bottom-[50%] translate-y-2/4 right-0 flex items-end flex-col">
          <HoverableButton
            className="text-white inline-block px-2 mb-2"
            icon={<Icon name="local_shipping" />}
            content={t("shippingAddress")}
            onClick={(item) => {
              if (!values.selectedCustomer) {
                Notifications.showError(t("notification:pleaseSelectCustomer"));
                return;
              }
              setOpenShippingAddress(true);
            }}
            hasData={shippingProfile?.length > 0}
            dataNumber={shippingProfile.length}
          />
          <HoverableButton
            className="text-white inline-block px-2 mb-2"
            icon={<img alt="" src="/images/icons/bill.svg" />}
            content={t("billingAddress")}
            onClick={(item) => {
              if (!values.selectedCustomer) {
                Notifications.showError(t("notification:pleaseSelectCustomer"));
                return;
              }
              setOpenBillingAddress(true);
            }}
            hasData={!isEmpty(billingProfile)}
            dataNumber={""}
          />
          {type === OrderType.NORMAL && (
            <HoverableButton
              className="text-white inline-block px-2 mb-2"
              icon={<Icon name="payment" />}
              content={t("paymentMethod")}
              hasData={!isEmpty(values.payment)}
              onClick={() => {
                if (!values.storeSelected) {
                  Notifications.showError(t("notification:pleaseSelectStore"));
                  return;
                }
                setOpenPaymentMethod(true);
              }}
            />
          )}
          <HoverableButton
            className="text-white inline-block px-2 mb-2"
            icon={<img alt="" src="/images/icons/notes.svg" />}
            content={t("orderNote")}
            onClick={() => setOpenOrderNote(true)}
            hasData={!isEmpty(note)}
          />
          <HoverableButton
            onClick={() => handleOnClickAddDiscount()}
            className="text-white inline-block px-2 mb-2"
            icon={<img alt="" src="/images/icons/tag.svg" />}
            content={t("discount")}
            hasData={!isEmpty(discountChainList)}
          />
          {type === OrderType.NORMAL && (
            <HoverableButton
              className="text-white inline-block px-2 mb-2"
              icon={<Icon name="settings" />}
              content={t("customProduct")}
              onClick={(item) => setOpenAddCustomItem(true)}
            />
          )}
          {type === OrderType.NORMAL && (
            <HoverableButton
              className="text-white inline-block px-2 mb-2"
              icon={<img alt="" src="/images/icons/change.svg" />}
              content={t("loadOrder")}
              onClick={() => setOpenLoadOrder(true)}
            />
          )}
          {type === OrderType.NORMAL && (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item onClick={() => setOpenAddAdditionalFee(true)}>
                    {t("addAdditionalFee")}
                  </Menu.Item>
                </Menu>
              }
              placement="topCenter"
              trigger={["click"]}
            >
              <HoverableButton
                className="text-white inline-block px-2 mb-2"
                icon={<img alt="" src="/images/icons/more-action.svg" />}
                content={t("moreActions")}
              />
            </Dropdown>
          )}
          {type === OrderType.RENTAL && (
            <HoverableButton
              className="text-white inline-block px-2 mb-2"
              icon={<Icon name="departure_board" />}
              content={t("scheduledDeliveryDate")}
              hasData={!!values?.scheduled_delivery_date}
              onClick={(item) => {
                if (isEmpty(shippingProfile)) {
                  Notifications.showError(
                    t("notification:selectShippingAddressPlease")
                  );
                  return;
                }
                setOpenScheduledDelivery(true);
              }}
            />
          )}
        </div>
        <Button
          color="primary"
          className="w-full"
          style={{ width: "100%" }}
          onClick={() => submitOrder()}
          disabled={!isGrantURLPermission(API_CREATE_ORDER)}
        >
          {t("placeOrder")}
        </Button>
      </div>
      {openDiscountModal && (
        <DiscountDrawer
          open={openDiscountModal}
          onClose={() => setOpenDiscountModal(false)}
          numberDiscount={
            freeGiftList.filter(
              (item: any) =>
                item?.action?.specific ===
                  CART_RULE_ACTION_KEY.discountPercentage.id ||
                item?.action?.specific === CART_RULE_ACTION_KEY.discountFix.id
            )?.length
          }
          applyCoupon={onApplyCouponHandle}
        />
      )}
      {openChainDiscount && (
        <ChainDiscountDrawer
          open={openChainDiscount}
          onClose={() => setOpenChainDiscount(false)}
        />
      )}
      {openLoadOrder && (
        <LoadOrderDrawer
          open={openLoadOrder}
          onClose={() => setOpenLoadOrder(false)}
        />
      )}
      {openAddCustomItem && (
        <AddCustomItemDrawer
          open={openAddCustomItem}
          onClose={() => setOpenAddCustomItem(false)}
          onSave={(item: any) => onSaveCustomItem(item)}
        />
      )}
      {openShippingAddress && (
        <ShippingAddressDrawer
          open={openShippingAddress}
          onClose={() => setOpenShippingAddress(false)}
        />
      )}
      {openBillingAddress && (
        <BillingAddressDrawer
          open={openBillingAddress}
          onClose={() => setOpenBillingAddress(false)}
        />
      )}
      {openAddAdditionalFee && (
        <AddAdditionalFee
          open={openAddAdditionalFee}
          onClose={() => setOpenAddAdditionalFee(false)}
        />
      )}
      {openScheduledDelivery && (
        <ScheduledDeliveryDate
          open={openScheduledDelivery}
          onClose={() => setOpenScheduledDelivery(false)}
        />
      )}
      {openOrderNote && (
        <OrderNoteDrawer
          open={openOrderNote}
          onClose={() => setOpenOrderNote(false)}
        />
      )}
      {openPaymentMethod && (
        <ModalChangePaymentMethod
          open={openPaymentMethod}
          onClose={() => setOpenPaymentMethod(false)}
          onUpdated={(paymentMethod: any) => {
            setOpenPaymentMethod(false);
            setFieldValue("payment", paymentMethod);
          }}
          orderValue={totalState}
          isCreateOrder={true}
          payment={
            !isEmpty(values.payment)
              ? {
                  ...values.payment,
                  payment_option: {
                    id: values?.payment?.payment_option,
                  },
                }
              : {}
          }
          store={storeSelected}
        />
      )}
    </>
  );
});

export default OrderProductActions;
