import AppContext from "@helpers/context";
import { Progress, TabBar } from "d-react-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { getCustomerDetail } from "@network/api/customer";
import classNames from "classnames";
import CustomerDetailHeader from "./CustomerDetailHeader";
import CustomerDetailInfo from "./CustomerDetailInfo";
import CustomerDetailGeneral from "./CustomerDetailGeneral";
import CustomerDetailShipping from "./CustomerDetailShipping";
import CustomerDetailBilling from "./CustomerDetailBilling";
import CustomerDetailOrders from "./CustomerDetailOrders";

const TABS = [
  {
    id: "general",
    label: "general",
    component: <CustomerDetailGeneral />,
  },
  {
    id: "shipping",
    label: "shipping",
    component: <CustomerDetailShipping />,
  },
  {
    id: "billing",
    label: "billing",
    component: <CustomerDetailBilling />,
  },
  {
    id: "order",
    label: "order",
    component: <CustomerDetailOrders />,
  },
];

const CustomerDetail = () => {
  const [customerDetail, setCustomerDetail] = useState({});

  const [tab, setTab] = useState<any>(TABS[0]);
  const { customerId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    loadCustomerDetail();
  }, []);

  const loadCustomerDetail = () => {
    Progress.show(
      { method: getCustomerDetail, params: [customerId] },
      (res: any) => {
        const customerInfo = res?.data?.data?.customers;
        setCustomerDetail(customerInfo);
      }
    );
  };

  return (
    <AppContext.Provider
      value={{
        customerDetail,
        setCustomerDetail,
        loadCustomerDetail,
      }}
    >
      <div className=" flex-column-container bg-trans">
        <CustomerDetailHeader />
        <div className="grid grid-cols-12 gap-4 p-3 bg-white">
          <div className="col-span-4">
            <CustomerDetailInfo />
          </div>
          <div className="col-span-8 px-3">
            <TabBar
              dataSource={TABS}
              onChange={setTab}
              value={tab}
              getLabel={(item: any) => t(item.label)}
              classNameItem={classNames(
                "bg-white border-right-0 border-left-0 border-top-0"
              )}
            />
            {tab.component}
          </div>
        </div>
      </div>
    </AppContext.Provider>
  );
};

export default CustomerDetail;
