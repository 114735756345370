import Drawer from "@components/shared/Drawer";
import UserSelect from "@components/user/components/UserSelect";
import WarehouseSelect from "@components/warehouse/components/WarehouseSelect";
import { DISCOUNT_STAFF_STATUS } from "@constants/discount";
import AppContext from "@helpers/context";
import DiscountStaffAPI from "@network/api/discount-staff";
import { Switch } from "antd";
import { InputText, Notifications } from "d-react-components";
import { useFormik } from "formik";
import { isNaN, map } from "lodash";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

const AssignFormSchema = Yup.object().shape({
  store: Yup.array().min(1, "Minimum 1 store").required("Field required!"),
  staff: Yup.array().min(1, "Minimum 1 staff").required("Field required!"),
});

const enable = "enable";
const disable = "disable";

const initialState = {
  store: [],
  staff: [],
  percentage: 0,
  amount: 0,
  status: enable,
};

const DiscountStaffAssignDrawer = ({ open, onClose, payload, isEdit }: any) => {
  const { onReloadData } = useContext(AppContext);
  const { t } = useTranslation();
  const assignForm = useFormik<any>({
    initialValues: isEdit ? payload : (initialState as any),
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: AssignFormSchema,
    onSubmit: (values: any) => {
      console.log("values", values);
      onAssign(values);
    },
  });
  const formValues = assignForm?.values;
  const formErrors = assignForm?.errors;

  const onAssign = async (values: any) => {
    const body = {
      ...values,
      store: map(values?.store, (item) => item.id),
      staff: map(values?.staff, (item) => item.id),
    };
    return DiscountStaffAPI.discountStaffAssign(body)
      .then(() => {
        Notifications.showSuccess(t("assignStaffDiscountSuccess"));
        onClose && onClose();
        onReloadData && onReloadData();
      })
      .catch((error) => {
        Notifications.showError(error.message);
        onClose && onClose();
      });
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      onSave={() => assignForm.handleSubmit()}
      title={isEdit ? t("updateStaffDiscount") : t("assignStaffDiscount")}
      saveText={isEdit ? t("update") : t("create")}
      size="large"
    >
      <div>
        <div>
          <label>{t("status")}</label>
          <Switch
            className="ml-3"
            checked={formValues.status === DISCOUNT_STAFF_STATUS.ENABLE}
            onChange={() => {
              if (formValues.status === enable) {
                assignForm.setFieldValue("status", disable);
              } else {
                assignForm.setFieldValue("status", enable);
              }
            }}
          />
        </div>

        <WarehouseSelect
          onChange={(valueList) => {
            assignForm.setFieldValue("store", valueList);
          }}
          value={formValues?.store ?? []}
          className="mt-3"
          multiple
          error={formErrors?.store}
        />

        <UserSelect
          label={t("staff")}
          value={formValues?.staff ?? []}
          onChange={(valueList) => {
            assignForm.setFieldValue("staff", valueList);
          }}
          className="mt-3"
          multiple
        />
        <div className="d-flex mt-3 ">
          <InputText
            label={t("maxPercentage")}
            suffix="%"
            value={formValues?.percentage}
            onChange={(e) => {
              let value = e.target.value.replace(/\D+/g, "");
              value = parseInt(value);
              if (isNaN(value)) {
                value = 0;
              }
              if (value > 100) {
                return;
              }
              assignForm.setFieldValue("percentage", value);
            }}
            className="w-50"
          />
          <InputText
            label={t("maxFixAmount")}
            suffix="฿"
            value={formValues?.amount?.toLocaleString()}
            onChange={(e) => {
              let value = e.target.value.replace(/\D+/g, "");
              value = parseInt(value);
              if (isNaN(value)) {
                value = 0;
              }
              assignForm.setFieldValue("amount", value);
            }}
            className="w-50 ml-3"
          />
        </div>
      </div>
    </Drawer>
  );
};

export default DiscountStaffAssignDrawer;
