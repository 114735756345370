import { API } from "@network";
import {
  API_CANCEL_DEPOSIT,
  API_DEPOSIT_COMMENT,
  API_CONFIRM_DEPOSIT,
  API_DEPOSIT_CREATE,
  API_DEPOSIT_ACTIVITIES,
  API_DEPOSIT_DETAIL,
  API_DEPOSIT_UPDATE,
  API_DEPOSIT_LIST,
  API_UPLOAD_CONFIRM_DEPOSIT,
  API_UPLOAD_DOCUMENT_DEPOSIT,
  API_UPLOAD_PAYMENT_INFO_DEPOSIT,
  API_LIST_PAYMENT,
  API_LOG_PRINT,
} from "@network/URL";

const CashDepositAPI = {
  list: (body: any, paging: any) => {
    return API.post(
      `${API_DEPOSIT_LIST}?page=${paging.pageIndex}&per_page=${paging.pageSize}`,
      body
    );
  },

  create: (body: any) => {
    return API.post(API_DEPOSIT_CREATE, body);
  },

  detail: (body: any) => {
    return API.post(API_DEPOSIT_DETAIL, body);
  },

  uploadDocument: (body: any) => {
    return API.post(API_UPLOAD_DOCUMENT_DEPOSIT, body);
  },

  activityList: (body: any, currentPage: any) => {
    return API.post(`${API_DEPOSIT_ACTIVITIES}?page=${currentPage}`, body);
  },

  comment: (body: any) => {
    return API.post(API_DEPOSIT_COMMENT, body);
  },

  update: (body: any) => {
    return API.post(API_DEPOSIT_UPDATE, body);
  },

  uploadConfirm: (body: any) => {
    return API.post(API_UPLOAD_CONFIRM_DEPOSIT, body);
  },

  uploadPaymentInfo: (body: any) => {
    return API.post(API_UPLOAD_PAYMENT_INFO_DEPOSIT, body);
  },

  confirm: (body: any) => {
    return API.post(API_CONFIRM_DEPOSIT, body);
  },

  cancel: (body: any) => {
    return API.post(API_CANCEL_DEPOSIT, body);
  },

  paymentList: (body: any, paging: any) => {
    return API.post(
      `${API_LIST_PAYMENT}?page=${paging.pageIndex}&per_page=${paging.pageSize}`,
      body
    );
  },

  logPrint: (body: any) => {
    return API.post(API_LOG_PRINT, body);
  },
};

export default CashDepositAPI;
