import Drawer from "@components/shared/Drawer";
import {
  CallStatus,
  CallType,
  CALL_STATUSES,
  CALL_TYPES,
  CALL_TYPES_OPTIONS,
} from "@constants/call";
import {
  assignCall,
  getLogCalls,
  getOrderCallHistory,
  getOrderCallList,
  searchCalls,
} from "@network/api/callcenter";
import { Popover, Spin } from "antd";
import {
  Avatar,
  AwesomeTableComponent,
  Dropdown,
  HeaderTable,
  IColumnsProps,
  Icon,
  InputText,
  Notifications,
  Select,
  TimeUtils,
  ViewLabelStatus,
} from "d-react-components";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledTable } from "./CallingPopup";
import { isUndefined } from "lodash";
import { V1_URL } from "@constants/common";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputTextForm from "@common/input/InputTextForm";
import AppContext from "@helpers/context";

type HistoryType = "all" | "byOrder";

const CallHistory = ({
  historyType = "all",
  orderId,
}: {
  historyType?: HistoryType;
  orderId?: string;
}) => {
  const { t } = useTranslation();
  const [showAssignCall, setShowAssignCall] = useState<any>({});
  const [showFilter, setShowFilter] = useState(false);
  const [filterBody, setFilterBody] = useState<any>({});
  const [expandedRowKeys, setExpandedRowKeys] = useState<any[]>([]);
  const { callCenterTokenReady } = useContext(AppContext);
  const tableRef = useRef<any>(null);
  const searchRef = useRef<string>("");
  const onChangeSearch = (text: string) => {
    searchRef.current = text;
  };
  const onSubmitSearch = () => {
    tableRef?.current?.refresh();
  };

  const source = async ({ pageSize, pageIndex }: any) => {
    if (historyType === "all") {
      return searchCalls({
        limit: pageSize,
        page: pageIndex,
        search: searchRef.current || "",
        ...(filterBody?.type ? { callType: filterBody?.type } : {}),
      });
    }
    if (historyType === "byOrder" && orderId) {
      return getOrderCallList({
        order_id: orderId,
        limit: pageSize,
        page: pageIndex,
        ...(filterBody?.type ? { callType: filterBody?.type } : {}),
      });
    }
    return [];
  };

  useEffect(() => {
    tableRef?.current?.refresh();
  }, [filterBody]);

  const ACTIONS = [{ id: "assignCall", label: t("assignCall") }];

  const onClickAction = (action: any, item: any) => {
    switch (action.id) {
      case "assignCall":
        setShowAssignCall({ open: true, item });
        break;

      default:
        break;
    }
  };

  const columns: IColumnsProps = useMemo(() => {
    return historyType === "all"
      ? [
          {
            title: t("ref"),
            dataIndex: "callNo",
          },
          {
            title: t("type"),
            dataIndex: "type",
            render: (data) => (
              <span style={{ color: CALL_TYPES[data]?.color }}>
                {t(CALL_TYPES[data]?.content)}
              </span>
            ),
          },
          {
            title: t("startTime"),
            dataIndex: "startTime",
            render: (data) => {
              return !data
                ? "N/A"
                : TimeUtils.convertMiliToDateTime(data / 1000);
            },
          },
          {
            title: t("caller"),
            dataIndex: "",
            render: (data, item) => {
              return item.type === CallType.INCOMING_CALL
                ? item.fromNumber
                : `${item.fromKey || ""} - ${item.fromLabel || ""}`;
            },
          },
          {
            title: t("orderRef"),
            dataIndex: "refId",
            render: (data) => (
              <a
                target="_blank"
                rel="noreferrer"
                href={`${V1_URL}/short-url/order/${data}`}
              >
                {data}
              </a>
            ),
          },
          {
            title: t("talkTotalDuration"),
            dataIndex: "duration",
            render: (data) =>
              moment(moment().startOf("d").add(data, "s")).format("m[m]s[s]"),
          },
          {
            title: t("action"),
            dataIndex: "",
            render: (data, call) => (
              <Dropdown
                iconName="more_vert"
                dataSource={ACTIONS}
                onClick={(item: any) => onClickAction(item, call)}
              />
            ),
          },
        ]
      : [
          {
            title: t("ref"),
            dataIndex: "ref",
          },
          {
            title: t("type"),
            dataIndex: "call_type",
            render: (data) => (
              <span style={{ color: CALL_TYPES[data]?.color }}>
                {t(CALL_TYPES[data]?.content)}
              </span>
            ),
          },
          {
            title: t("startTime"),
            dataIndex: "start",
            render: (data: any) => {
              return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
            },
          },
          {
            title: t("caller"),
            dataIndex: "destination",
            render: (data, item) => {
              return `${item?.destination || "N/A"}`;
            },
          },
          {
            title: t("talkTotalDuration"),
            dataIndex: "duration",
            render: (data, item) => {
              return moment(moment().startOf("d").add(data, "s")).format(
                "m[m]s[s]"
              );
            },
          },
        ];
  }, [t]);

  return (
    <div className="p-3">
      <HeaderTable
        label={""}
        onChangeText={(event: any) => onChangeSearch(event.target.value)}
        onSubmitSearch={onSubmitSearch}
        className="app-layout__page-header mb-3"
        onClickFilter={() => {
          setShowFilter(true);
        }}
        isFiltered={!isUndefined(filterBody.type)}
      />
      {callCenterTokenReady && (
        <AwesomeTableComponent
          source={source}
          ref={(ref) => {
            tableRef.current = ref;
          }}
          transformer={(response) => {
            return historyType === "all"
              ? response?.data?.data?.call ?? []
              : response?.data?.data?.order_call ?? [];
          }}
          expandable={{
            expandedRowRender: (record) => (
              <ExpandedTable
                call={record}
                orderId={orderId}
                historyType={historyType}
              />
            ),
            rowExpandable: () => true,
            expandedRowKeys,
            onExpandedRowsChange: (items: any) => setExpandedRowKeys(items),
          }}
          columns={columns}
          rowKey={(item) => (historyType === "all" ? item.id : item.ref)}
          defaultPagination={{
            pageIndex: 1,
            pageSize: 10,
            pageSizeOptions: ["10", "20", "50", "100"],
            showQuickJumper: true,
            showTotal: (total: any) => (
              <div className="captionText">{`Total ${total} items`}</div>
            ),
          }}
        />
      )}
      {showFilter && (
        <FilterModal
          open={showFilter}
          onClose={() => {
            setShowFilter(false);
          }}
          filterBody={filterBody}
          setFilterBody={setFilterBody}
        />
      )}
      {showAssignCall.open && (
        <AssignCallDrawer
          open={showAssignCall.open}
          item={showAssignCall.item}
          onClose={() => setShowAssignCall({ open: false, item: null })}
          onSave={() => {
            setShowAssignCall({ open: false, item: null });
            tableRef?.current?.refresh();
          }}
        />
      )}
    </div>
  );
};

const FilterModal = ({ open, onClose, filterBody, setFilterBody }: any) => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState(filterBody);

  const onCloseModal = () => {
    setFilterBody(filters);
    onClose();
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("filterCallHistory")}
      saveText={"OK"}
      onSave={onCloseModal}
    >
      <Select
        label={t("type")}
        value={filters?.type}
        onChange={(value) => setFilters({ ...filters, type: value })}
        dataSource={CALL_TYPES_OPTIONS}
        getLabel={(item) => t(item.label)}
        getValue={(item) => item.value}
        className="mb-3"
      />
    </Drawer>
  );
};

const ExpandedTable = ({ call, orderId, historyType }: any) => {
  const { t } = useTranslation();
  const tableRef = useRef<any>(null);
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const columns: IColumnsProps = useMemo(() => {
    return historyType === "all"
      ? [
          {
            title: t("loguuid"),
            dataIndex: "txnUuid",
          },
          {
            title: t("startTime"),
            dataIndex: "startTime",
            width: 160,
            render: (data) => {
              return !data
                ? "N/A"
                : TimeUtils.convertMiliToDateTime(data / 1000);
            },
          },
          {
            title: t("endTime"),
            dataIndex: "endTime",
            width: 160,
            render: (data) => {
              return !data
                ? "N/A"
                : TimeUtils.convertMiliToDateTime(data / 1000);
            },
          },
          {
            title: t("participant"),
            dataIndex: "",
            render: (data, item) => (
              <div className="flex items-center">
                {item.type === CallType.INCOMING_CALL
                  ? `${item.fromNumber || ""}, ${item.toNumber || ""} (${
                      item.toKey || ""
                    } - ${item.toLabel || ""})`
                  : `${item.toNumber || ""}, ${item.fromNumber || ""} (${
                      item.fromKey || ""
                    } - ${item.fromLabel || ""})`}
              </div>
            ),
          },
          {
            title: t("status"),
            dataIndex: "status",
            render: (data) => {
              const status = CALL_STATUSES[data];
              return (
                <ViewLabelStatus
                  content={t(`${status?.content}`)}
                  color={status?.color}
                />
              );
            },
          },
          {
            title: t("recording"),
            dataIndex: "recordingUrl",
            width: "100px",
            render: (data) => {
              return data ? (
                <div className="flex items-center">
                  <Popover
                    content={
                      <>
                        <audio controls>
                          <source src={data}></source>
                        </audio>
                      </>
                    }
                    trigger="click"
                  >
                    <a>
                      <Icon name="play_arrow" />
                    </a>
                  </Popover>
                  <a href={data} target="_blank" rel="noreferrer">
                    <Icon name="download" />
                  </a>
                </div>
              ) : null;
            },
          },
        ]
      : [
          {
            title: t("loguuid"),
            dataIndex: "ref",
            width: "150px",
          },
          {
            title: t("startTime"),
            dataIndex: "start",
            render: (data) => {
              return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
            },
          },
          {
            title: t("endTime"),
            dataIndex: "end",
            render: (data) => {
              return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
            },
          },
          {
            title: t("participant"),
            dataIndex: "from_label",
            width: "100px",
            render: (data) => <div className="flex items-center">{data}</div>,
          },
          {
            title: t("status"),
            dataIndex: "status",
            render: (data) => {
              const status = CALL_STATUSES[data];
              return (
                <ViewLabelStatus
                  content={t(`${status?.content}`)}
                  color={status?.color}
                />
              );
            },
          },
          {
            title: t("recording"),
            dataIndex: "url_recording",
            render: (data) => {
              return data ? (
                <div className="flex items-center">
                  <Popover
                    content={
                      <>
                        <audio controls>
                          <source src={data}></source>
                        </audio>
                      </>
                    }
                    trigger="click"
                  >
                    <a>
                      <Icon name="play_arrow" />
                    </a>
                  </Popover>
                  <a href={data} target="_blank" rel="noreferrer">
                    <Icon name="download" />
                  </a>
                </div>
              ) : null;
            },
          },
        ];
  }, [t]);

  useEffect(() => {
    if (historyType === "all") {
      getLogCalls(call.id)
        .then((resp) => {
          setHistory(resp?.data?.data?.logs ?? []);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      getOrderCallHistory({
        order_id: orderId,
        order_call_id: call.id,
      })
        .then((resp) => {
          setHistory(resp?.data?.data?.history ?? []);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, []);

  return (
    <StyledTable historyType={historyType}>
      <div className="relative">
        {loading && (
          <div className="absolute flex items-center justify-content-center w-full z-10 pt-6">
            <Spin />
          </div>
        )}
        <AwesomeTableComponent
          dataSource={history}
          columns={columns}
          ref={tableRef}
          pagination={false}
        />
      </div>
    </StyledTable>
  );
};

const AssignCallDrawer = ({ open, onClose, item, onSave }: any) => {
  const { t } = useTranslation();
  const assignForm = useFormik<any>({
    initialValues: {},
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      orderNo: Yup.string().required(t("fieldRequired")),
    }),
    onSubmit: (values) => {
      try {
        assignCall(item.id, values.orderNo).then(
          () => {
            Notifications.showSuccess(t("notification:assignCallSuccessfully"));
            onSave && onSave();
          },
          (err) => {
            Notifications.showError(err);
          }
        );
      } catch (err) {
        Notifications.showError(err);
      }
    },
  });

  const { handleSubmit } = assignForm;

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("assignCall")}
      onSave={handleSubmit}
    >
      <p>{t("notification:pleaseInputOrderNo")}</p>
      <InputTextForm form={assignForm} keyData="orderNo" />
    </Drawer>
  );
};

export default CallHistory;
