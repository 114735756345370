import InputTextForm from "@common/input/InputTextForm";
import Drawer from "@components/shared/Drawer";
import {
  STOCK_REQUEST_STATUS,
  STOCK_REQUEST_STATUS_LIST,
} from "@constants/stock-request";
import AppContext from "@helpers/context";
import {
  cancelStockRequest,
  confirmStockRequest,
} from "@network/api/stock-request";
import classNames from "classnames";
import {
  Button,
  Icon,
  Notifications,
  TimeUtils,
  ViewLabelStatus,
} from "d-react-components";
import { useFormik } from "formik";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

interface IApproveRequestDrawer {
  open: boolean;
  onClose: () => void;
}

const ApproveRequestDrawer = ({ open, onClose }: IApproveRequestDrawer) => {
  const { t } = useTranslation();
  const { requestDetail, loadRequestDetail } = useContext(AppContext);

  const requestForm = useFormik<any>({
    initialValues: {} as any,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: schema,
    onSubmit: (values: any) => {
      onConfirmRequest(values);
    },
  });

  const onConfirmRequest = async (input: any) => {
    const body = {
      confirm_request_stock_note: input?.remark,
      id: requestDetail?.id,
      status: "confirm",
    };
    return confirmStockRequest(body).then((res) => {
      Notifications.showSuccess(t("confirmStockSuccess"));
      onClose();
      loadRequestDetail();
    });
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("approve")}
      onSave={() => requestForm.handleSubmit()}
    >
      <div>
        <text>{t("approveStockRequestDesc")}</text>
        <InputTextForm
          form={requestForm}
          keyData="remark"
          multiple
          className="mt-3"
        />
      </div>
    </Drawer>
  );
};

const RejectRequestDrawer = ({ open, onClose }: IApproveRequestDrawer) => {
  const { t } = useTranslation();
  const { requestDetail, loadRequestDetail } = useContext(AppContext);

  const requestForm = useFormik<any>({
    initialValues: {} as any,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: schema,
    onSubmit: (values: any) => {
      onConfirmRequest(values);
    },
  });

  const onConfirmRequest = async (input: any) => {
    const body = {
      confirm_request_stock_note: input?.remark,
      status: "reject",
      id: requestDetail?.id,
    };
    return cancelStockRequest(body).then((res) => {
      Notifications.showSuccess(t("rejectRequestSuccess"));
      onClose();
      loadRequestDetail();
    });
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("reject")}
      onSave={() => requestForm.handleSubmit()}
    >
      <InputTextForm form={requestForm} keyData="remark" multiple />
    </Drawer>
  );
};

const StockRequestDetailHeader = () => {
  const { requestDetail } = useContext(AppContext);
  const { ref, status, by, created } = requestDetail ?? {};
  const { t } = useTranslation();
  const [openApprove, setOpenApprove] = useState(false);
  const [openReject, setOpenReject] = useState(false);

  return (
    <div className="card-container p-3 flex-center justify-content-between ">
      <div className="flex-column">
        <div className="d-flex">
          <h4 className={classNames("text-[14px] text-primary")}>{ref}</h4>
          <ViewLabelStatus
            listStatus={STOCK_REQUEST_STATUS_LIST}
            status={status}
            className="ml-3"
            getLabel={(item) => t(item.label)}
          />
        </div>
        {/* <div className="mt-3 d-flex align-items-center cursor-pointer">
          <Icon name="print" />
          <div className="ml-3"> {t("print")}</div>
        </div> */}
      </div>
      {/* <div className="flex-column">
        <div className="d-flex">{`${t("by")} ${by?.name}`}</div>
        <small>{`${t("createdOn")} ${TimeUtils.toDateTime(created)}`}</small>
      </div> */}

      <div className="flex-row align-items-center">
        <Button
          onClick={() => setOpenApprove(true)}
          className="mr-3"
          hidden={status !== STOCK_REQUEST_STATUS?.PENDING}
        >
          {t("approve")}
        </Button>
        <Button
          onClick={() => setOpenReject(true)}
          className="mr-3"
          color="error"
          hidden={status !== STOCK_REQUEST_STATUS?.PENDING}
        >
          {t("cancel")}
        </Button>
      </div>

      {openApprove && (
        <ApproveRequestDrawer
          open={openApprove}
          onClose={() => setOpenApprove(false)}
        />
      )}
      {openReject && (
        <RejectRequestDrawer
          open={openReject}
          onClose={() => setOpenReject(false)}
        />
      )}
    </div>
  );
};

export default StockRequestDetailHeader;
