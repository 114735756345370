import Icon from "@common/Icon";
import Drawer from "@components/shared/Drawer";
import AppContext from "@helpers/context";
import { CustomerType } from "@interfaces/customer";
import { Notifications } from "d-react-components";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomerInputInfo from "../input/CustomerInputInfo";
import { CustomerInfoRow } from "./CustomerDetailInfo";
import { isEmpty } from "lodash";
import { updateCustomer, uploadCustomerAvatar } from "@network/api/customer";

interface ICustomerGeneralEditDrawer {
  open: boolean;
  defaultValue?: any;
  onUpdated: any;
  onClose: () => void;
}

const CustomerGeneralEditDrawer = ({
  open,
  defaultValue,
  onUpdated,
  onClose,
}: ICustomerGeneralEditDrawer) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const uploadAvatarCustomer = (respone: any) => {
    const { avatar } = formValues;
    const id = respone?.data?.data?.customers?.id ?? null;
    const data = new FormData();
    data.append("customerid", id);
    data.append("file", avatar);
    return uploadCustomerAvatar(data);
  };

  const CustomerSchema = Yup.lazy((value) => {
    let schema = Yup.object().shape({
      customer_type: Yup.string().required(t("fieldRequired")),
      channel: Yup.mixed().required(t("fieldRequired")),
      phone: Yup.number().required(t("fieldRequired")),
    });
    if (value.customer_type === CustomerType.BUSINESS) {
      schema = schema.concat(
        Yup.object().shape({
          representative_first_name: Yup.string().required(t("fieldRequired")),
          representative_last_name: Yup.string().required(t("fieldRequired")),
          email: Yup.string().required(t("fieldRequired")),
          company: Yup.string().required(t("fieldRequired")),
          tax_id: Yup.string().required(t("fieldRequired")),
        })
      );
    }
    if (value.customer_type === CustomerType.INDIVIDUAL) {
      schema = schema.concat(
        Yup.object().shape({
          firstname: Yup.string().required(t("fieldRequired")),
          lastname: Yup.string().required(t("fieldRequired")),
          nickname: Yup.string().required(t("fieldRequired")),
          citizen_id: Yup.string().test("len", t("citizenIdMustBe13"), (val) =>
            !isEmpty(val) ? val?.length === 13 : true
          ),
        })
      );
    }
    return schema;
  });

  const customerForm = useFormik<any>({
    initialValues: defaultValue as any,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: CustomerSchema,
    onSubmit: async (values: any) => {
      const {
        firstname,
        lastname,
        nickname,
        phone,
        email,
        birthday,
        company,
        gender,
        customer_type,
        note,
        channel,
        avatar,
        alternative_phone,
        representative_first_name,
        representative_last_name,
        representative_email,
        representative_phone,
        tax_id,
        citizen_id,
      } = values;
      const body = {
        customer_type: customer_type,
        channel_id: channel?.id,
        id: defaultValue?.id,
        note,
        ...(values?.customer_type === CustomerType.INDIVIDUAL
          ? {
              firstname,
              lastname,
              phone,
              alternative_phone,
              email,
              nickname: isEmpty(nickname) ? firstname : nickname,
              gender,
              citizen_id,
              birthday: birthday ? birthday.valueOf() : null,
            }
          : {
              company,
              tax_id,
              phone,
              email,
              representative_first_name,
              representative_last_name,
              representative_email,
              representative_phone,
            }),
      };
      try {
        const respone = await updateCustomer(body);
        const customer = respone?.data?.data?.customers ?? {};
        const url = respone?.data?.data?.editurl ?? "";
        if (isEmpty(customer)) {
          const message = respone?.data?.message ?? "Error";
          Notifications.showError(message);
          setLoading(false);
          return;
        }
        if (avatar) {
          await uploadAvatarCustomer(respone);
        }
        Notifications.showSuccess(t("updateCustomerSuccess"));
        setLoading(false);
        onUpdated();
      } catch (error) {
        setLoading(false);
        Notifications.showError(JSON.stringify(error));
      }
    },
  });
  const formValues = customerForm?.values;
  const formErrors = customerForm?.errors;

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("general")}
      size="large"
      onSave={() => {
        customerForm.handleSubmit();
      }}
    >
      <div>
        <CustomerInputInfo
          customerForm={customerForm}
          defaultValue={defaultValue}
        />
      </div>
    </Drawer>
  );
};

const CustomerDetailGeneral = () => {
  const { customerDetail, loadCustomerDetail } = useContext(AppContext);
  const [openEdit, setOpenEdit] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="mt-3 border p-4 ">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h5>{t("general")}</h5>
        <span onClick={() => setOpenEdit(true)}>
          <Icon
            className="w-[15px] cursor-pointer"
            src="/images/icons/edit.svg"
          />
        </span>
      </div>
      <CustomerInfoRow />
      {openEdit && (
        <CustomerGeneralEditDrawer
          open={openEdit}
          onClose={() => setOpenEdit(false)}
          defaultValue={customerDetail}
          onUpdated={() => {
            loadCustomerDetail();
            setOpenEdit(false);
          }}
        />
      )}
    </div>
  );
};

export default CustomerDetailGeneral;
