import HeaderDetail from "@common/HeaderDetail";
import { DELIVERY_STOCK_STATUS_LIST } from "@constants/delivery";
import AppContext from "@helpers/context";
import { ViewLabelStatus } from "d-react-components";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

const DeliveryTransferStockHeader = () => {
  const { deliveryDetail } = useContext(AppContext);
  const { ref, status, by, created } = deliveryDetail ?? {};
  const { t } = useTranslation();
  return (
    <HeaderDetail
      title={ref}
      titleRightSide={
        <ViewLabelStatus
          listStatus={DELIVERY_STOCK_STATUS_LIST}
          status={status}
          className="ml-3"
          getLabel={(item) => t(item.label)}
        />
      }
    />
  );
};

export default DeliveryTransferStockHeader;
