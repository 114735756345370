import { CHANNEL_STATUSES } from "@constants/channel";
import classNames from "classnames";
import { Select } from "d-react-components";
import { useTranslation } from "react-i18next";

const ChannelFilterForm = ({ filterBody, onChangeData }: any) => {
  const classNameInput = classNames("col-span-1 mt-4");
  const { t } = useTranslation();
  return (
    <div className="grid grid-cols-1 gap4">
      <Select
        dataSource={CHANNEL_STATUSES}
        label={t("status")}
        className={classNameInput}
        value={filterBody?.status}
        onChange={(value) => {
          onChangeData({ status: value });
        }}
        getLabel={(item) => t(item?.label)}
        // multiple
      />
    </div>
  );
};

export default ChannelFilterForm;
