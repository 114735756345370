import Icon from "@common/Icon";
import { WAREHOUSE_STATUSES } from "@constants/warehouse";
import AppContext from "@helpers/context";
import { IWarehouse } from "@interfaces/warehouse";
import {
  IRowsKey,
  ViewLabelStatus,
  ViewRowInterchange,
} from "d-react-components";
import { toUpper } from "lodash";
import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

const WarehouseDetailInfo = () => {
  const { warehouse } = useContext(AppContext);
  const { t } = useTranslation();

  const { name, code, warehouse_system_code } = warehouse;

  const DETAILS_KEY = useMemo<IRowsKey<IWarehouse>[]>(
    () => [
      {
        id: "status",
        label: t("status"),
        renderContent: ({ data, item }) => (
          <div className="d-flex justify-content-end">
            <ViewLabelStatus
              status={data}
              listStatus={WAREHOUSE_STATUSES}
              getLabel={(item) => toUpper(item.label)}
            />
          </div>
        ),
      },
      {
        id: "code",
        label: t("navArCode"),
      },
      {
        id: "pickup_code",
        label: t("navPickupCode"),
      },
      {
        id: "chain",
        label: t("chain"),
        renderContent: ({ data, item }) => data?.name,
      },
      {
        id: "branch_tax_id",
        label: t("brandIdTax"),
      },
      {
        id: "store_type",
        label: t("storeType"),
      },
      {
        id: "phone",
        label: t("phoneNumber"),
      },
    ],
    [t]
  );
  const STATIC_KEY = useMemo<IRowsKey<IWarehouse>[]>(
    () => [
      {
        id: "admin_count",
        label: t("users"),
      },
      {
        id: "channel_count",
        label: t("channels"),
      },
      {
        id: "delivery_count",
        label: t("warehouseDelivery"),
      },
    ],
    [t]
  );

  return (
    <div>
      <div className="border flex-center flex-column pt-5 w-100 bg-white p-4">
        <Icon
          className="w-[100px] cursor-pointer"
          src="/images/icons/warehouse.svg"
        />

        <h4 className="mt-3 text-center">{name}</h4>
        <text>{warehouse_system_code}</text>
        <ViewRowInterchange
          className="w-100"
          dataSource={warehouse}
          keyList={DETAILS_KEY as any}
          variant="border"
          classNameLabel="font-weight-bold "
          classNameContent="text-end "
        />
      </div>
      <div className="border mt-3 p-4">
        <label>{t("statistic")}</label>
        <ViewRowInterchange
          className="w-100"
          dataSource={warehouse}
          keyList={STATIC_KEY as any}
          variant="border"
          classNameLabel="font-weight-bold "
          classNameContent="text-end "
        />
      </div>
    </div>
  );
};

export default WarehouseDetailInfo;
