import {
  Button,
  IRowsKey,
  ObjectUtils,
  ViewLabelStatus,
  ViewRowInterchange,
} from "d-react-components";
import { toUpper } from "lodash";
import React, { useContext, useMemo, useState } from "react";
import WarehouseDetailGeneralUpdateDrawer from "./WarehouseDetailGeneralUpdateDrawer";
import AppContext from "@helpers/context";
import { useTranslation } from "react-i18next";
import {
  WAREHOUSE_GENERAL_KEYS,
  WAREHOUSE_STATUSES,
} from "@constants/warehouse";
import { IWarehouse } from "@interfaces/warehouse";
import ImageIcon from "@common/Icon";

const WarehouseDetailGeneral = () => {
  const { warehouse, loadWarehouseDetail } = useContext(AppContext);
  const [openUpdate, setOpenUpdate] = useState(false);
  const { t } = useTranslation();

  const { id } = warehouse;

  const defaultWarehouse = ObjectUtils.mapFieldsLangsSTC(
    warehouse,
    WAREHOUSE_GENERAL_KEYS as any
  );

  const DETAILS_KEY = useMemo<IRowsKey<IWarehouse>[]>(
    () => [
      {
        id: "name",
        label: t("name"),
      },
      {
        id: "warehouse_system_code",
        label: t("systemCode"),
      },
      {
        id: "code",
        label: t("navArCode"),
      },
      {
        id: "pickup_code",
        label: t("navPickupCode"),
      },
      {
        id: "branch_tax_id",
        label: t("brandIdTax"),
      },

      {
        id: "pickup_code",
        label: t("pickupCode"),
      },
      {
        id: "is_store",
        label: t("isThisAStore"),
        renderContent: ({ data, item }) => (data ? t("yes") : t("no")),
      },
      {
        id: "nav",
        label: t("navConfig"),
        renderContent: ({ data, item }) => data?.name ?? "N/A",
      },
      {
        id: "store_type",
        label: t("storeType"),
      },
      {
        id: "is_warehouse_delivery",
        label: t("isWarehouseDelivery"),
        renderContent: ({ data, item }) => (data ? t("yes") : t("no")),
      },
      {
        id: "phone",
        label: t("phoneNumber"),
      },

      {
        id: "is_stock_adjustment",
        label: t("stockAdjust"),
        renderContent: ({ data, item }) => (data ? t("yes") : t("no")),
      },
      {
        id: "chain",
        label: t("chain"),
        renderContent: ({ data, item }) => data?.name,
      },
      {
        id: "status",
        label: t("status"),
        renderContent: ({ data, item }) => (
          <div className="d-flex justify-content-end">
            <ViewLabelStatus
              status={data}
              listStatus={WAREHOUSE_STATUSES}
              getLabel={(item) => toUpper(item.label)}
            />
          </div>
        ),
      },
    ],
    [t]
  );

  return (
    <div className="border mt-3 p-4">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h5>{t("general")}</h5>
        <span onClick={() => setOpenUpdate(true)}>
          <ImageIcon
            className="w-[15px] cursor-pointer"
            src="/images/icons/edit.svg"
          />
        </span>
      </div>
      <ViewRowInterchange
        className="w-100"
        dataSource={warehouse}
        keyList={DETAILS_KEY as any}
        variant="border"
        classNameLabel="font-weight-bold "
        classNameContent="text-end "
      />
      {openUpdate && (
        <WarehouseDetailGeneralUpdateDrawer
          open={openUpdate}
          onClose={() => setOpenUpdate(false)}
          warehouseId={id}
          defaultValue={{ ...defaultWarehouse, chain: warehouse?.chain?.id }}
          onUpdateSuccess={loadWarehouseDetail}
        />
      )}
    </div>
  );
};

export default WarehouseDetailGeneral;
