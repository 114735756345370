import AppContext from "@helpers/context";
import { validateProductFollowSku } from "@network/api/product";
import RawMaterialAPI from "@network/api/raw-material";
import { Tabs } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import { ObjectUtils, Progress } from "d-react-components";
import { find, map, reduce } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import RawMaterialForecastCheckCopy from "./RawMaterialForecastCheckCopy";
import RawMaterialForecastUpload from "./RawMaterialForecastUpload";
import RawMaterialForecastTable from "./RawMaterialForecastTable";

const CHECK_TABS = [
  { label: "checkViaCopyPaste", id: "checkViaCopyPaste" },
  { label: "checkViaUpload", id: "checkViaUpload" },
];

const RawMaterialForecast = () => {
  const [tabSelected, setTabSelected] = useState<string>("checkViaCopyPaste");
  const [rawMaterialList, setRawMaterialList] = useState<any[]>([]);
  const [saleForecastList, setSaleForecastList] = useState<any[]>([]);
  const { t } = useTranslation();

  const onChangeRawMaterialList = (rawList: any, dataInput: any) => {
    const rawMaterialList: any = map(rawList, (rawItem) => {
      const { products = [] } = rawItem;
      const productsInNeed = map(products, (productItem) => {
        const itemInNeed = find(
          dataInput,
          (dataItem) => dataItem.sku === productItem?.product?.sku
        );
        return {
          ...productItem,
          quantityInNeed: itemInNeed.quantity * productItem.quantity,
        };
      });

      const quantityNeed = reduce(
        productsInNeed,
        (sum, item) => (sum += item.quantityInNeed),
        0
      );
      return {
        ...rawItem,
        productsInNeed,
        quantityNeed,
      };
    });

    setRawMaterialList(rawMaterialList);
  };

  const onChangeSaleForecastList = (productList: any[], dataInput: any) => {
    const saleForecastList = map(productList, (proItem) => {
      const inputItem = find(dataInput, (item) => item.sku === proItem.sku);
      return {
        ...proItem,
        unitPrice: inputItem.price,
        quantityNeed: inputItem.quantity,
        total: inputItem.price * inputItem.quantity,
      };
    });

    setSaleForecastList(saleForecastList);
  };

  const getDisplay = (key: any) => {
    return tabSelected === key ? "flex" : "none";
  };

  const onCheckRawMaterial = (dataCheck: any) => {
    if (dataCheck.length === 0) return;
    //must split to multiple array
    const slicedArray = ObjectUtils.sliceArrayToMui(dataCheck, 100);
    const APICheckRawMat: any = [];
    const APICheckSale: any = [];
    slicedArray.forEach((dataList: any) => {
      const body = { sku: map(dataList, (item) => item.sku) };
      APICheckRawMat.push({
        method: RawMaterialAPI.checkRawMaterial,
        params: [body],
      });
      APICheckSale.push(validateProductFollowSku(body));
    });

    Progress.show(APICheckRawMat, (resList: any) => {
      let materialList: any[] = [];
      resList.forEach((resItem: any) => {
        const { material = [] } = resItem?.data?.data;
        materialList = materialList.concat(material);
      });
      onChangeRawMaterialList(materialList, dataCheck);
    });
    Promise.all(APICheckSale).then((resList) => {
      let productList: any = [];
      resList.forEach((resItem) => {
        const { products = [] } = resItem?.data?.data;
        productList = productList.concat(products);
        onChangeSaleForecastList(productList, dataCheck);
      });
    });
  };

  const renderContent = () => {
    return (
      <div className="p-3">
        <div
          style={{
            display: getDisplay("checkViaCopyPaste"),
          }}
        >
          <RawMaterialForecastCheckCopy />
        </div>

        <div
          style={{
            display: getDisplay("checkViaUpload"),
          }}
        >
          <RawMaterialForecastUpload />
        </div>
      </div>
    );
  };

  return (
    <AppContext.Provider
      value={{ onCheckRawMaterial, rawMaterialList, saleForecastList }}
    >
      <div className="fluid-container bg-white p-3">
        <Tabs
          onChange={(activeId) => {
            const foundData = find(CHECK_TABS, (item) => item?.id === activeId);
            setTabSelected(foundData?.id ?? "");
          }}
          activeKey={tabSelected}
        >
          {map(CHECK_TABS, (tabItem) => {
            return <TabPane key={tabItem?.id} tab={t(tabItem.label)} />;
          })}
        </Tabs>

        {renderContent()}
        <RawMaterialForecastTable />
      </div>
    </AppContext.Provider>
  );
};

export default RawMaterialForecast;
