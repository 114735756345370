import { getFullAddressFromShipping } from "@helpers/string";
import { IStore } from "@interfaces/store";
import { isEmpty, map } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import StoreSelect, { IStoreSelect } from "./StoreSelect";

interface IStoreSolidItem {
  store: IStore;
}

export const StoreSolidItem = ({ store }: IStoreSolidItem) => {
  const { name, code } = store;
  const { t } = useTranslation();

  return (
    <div className="w-100 mt-3 bg-primary d-flex">
      <div className="p-2 flex-column">
        <span className="block text-white font-weight-bold">{name}</span>
        <span className="block text-white opacity-75">{`${t(
          "code"
        )}: ${code}`}</span>
        <span className="block text-white opacity-75">{`${t(
          "address"
        )}: ${getFullAddressFromShipping(store)}`}</span>
      </div>
    </div>
  );
};

const StoreSolidSelect = ({
  className,
  ...selectProps
}: IStoreSelect<IStore>) => {
  const renderStoreSelected = () => {
    if (!selectProps?.value || isEmpty(selectProps?.value)) return <div />;
    if (selectProps?.multiple) {
      return map(selectProps?.value, (store) => (
        <StoreSolidItem store={store} />
      ));
    }
    return <StoreSolidItem store={selectProps?.value as IStore} />;
  };

  return (
    <div className={className}>
      <StoreSelect {...selectProps} />
      {renderStoreSelected()}
    </div>
  );
};

export default StoreSolidSelect;
