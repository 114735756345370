import Drawer from "@components/shared/Drawer";
import { getFullAddress } from "@helpers/address";
import AppContext from "@helpers/context";
import { searchBillingCustomer } from "@network/api/address";
import {
  createCustomerBilling,
  removeCustomerBilling,
  setCustomerBillingDefault,
  updateCustomerBilling,
} from "@network/api/customer";
import {
  Button,
  DialogManager,
  Notifications,
  Progress,
  StringUtils,
} from "d-react-components";
import { useFormik } from "formik";
import { isEmpty, map } from "lodash";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AddressItemHeader from "../component/AddressItemHeader";
import CustomerInputBilling from "../input/CustomerInputBilling";

interface ICustomerAddBillingDrawer {
  open: boolean;
  defaultValue?: any;
  onUpdated: any;
  customer: any;
  onClose: () => void;
}

interface IAddressItem {
  address: any;
  index: number;
  showAction?: boolean;
  customer: any;
  onUpdated: any;
}

export const AddressItem = ({
  address,
  index,
  showAction,
  customer,
  onUpdated,
}: IAddressItem) => {
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const { id, phone, nameOfPlace, default: isDefault } = address;
  const { t } = useTranslation();

  const onSetDefaultAddress = () => {
    Progress.show(
      {
        method: setCustomerBillingDefault,
        params: [customer?.id, address?.id],
      },
      (res: any) => {
        Notifications.showSuccess(t("updateCustomerAddressSuccess"));
        onUpdated();
      }
    );
  };

  const onRemoveAddress = () => {
    DialogManager.showConfirm(t("confirm"), t("areYouSureWantRemove"), () =>
      Progress.show(
        {
          method: removeCustomerBilling,
          params: [customer?.id, address?.id],
        },
        (res: any) => {
          Notifications.showSuccess(t("removeSuccess"));
          onUpdated();
        }
      )
    );
  };

  return (
    <div className="mt-3">
      <AddressItemHeader
        isDefault={isDefault}
        label={`${t("address")} ${index}`}
        onClickEdit={() => setOpenEdit(true)}
        onClickDelete={onRemoveAddress}
        onClickSetDefault={onSetDefaultAddress}
        showAction={showAction}
      />
      <div className="flex-column">
        <text>{`${address?.firstname} ${address?.lastname}`}</text>
        <text>{phone}</text>
        <text>{getFullAddress(address)}</text>
        <text>{nameOfPlace}</text>
      </div>
      {openEdit && (
        <CustomerEditBillingDrawer
          open={openEdit}
          onClose={() => setOpenEdit(false)}
          defaultValue={address}
          onUpdated={onUpdated}
          customer={customer}
        />
      )}
    </div>
  );
};

const CustomerEditBillingDrawer = ({
  open,
  customer,
  onUpdated,
  onClose,
  defaultValue,
}: ICustomerAddBillingDrawer) => {
  const { t } = useTranslation();
  const customerForm = useFormik<any>({
    initialValues: {
      ...defaultValue,
      district: defaultValue?.city,
      subDistrict: defaultValue?.subdistrict,
      postCode: defaultValue?.postcode,
      typeOfPlace: defaultValue?.place,
    } as any,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: BillingSchema,
    onSubmit: (values: any) => {
      onUpdateBilling(values);
    },
  });
  const formValues = customerForm?.values;
  const formErrors = customerForm?.errors;

  const onUpdateBilling = (values: any) => {
    const {
      firstname,
      lastname,
      nickname,
      province,
      district,
      postCode,
      address,
      subDistrict,
      phone,
      typeOfPlace,
      citizenid,
      taxid,
      email,
      type,
    } = values;
    const bodyBilling = {
      billingid: defaultValue.id,
      customerid: customer.id,
      default: defaultValue.default,

      firstname,
      lastname,
      nickname: isEmpty(nickname) ? firstname : nickname,
      phone,
      province: province?.id ?? "",
      city: district?.id ?? "",
      subdistrict: subDistrict?.id ?? "",
      postcode: postCode,
      address,
      place: typeOfPlace,
      citizenid: StringUtils.removeAllSpace(citizenid),
      taxid: StringUtils.removeAllSpace(taxid),
      email,
      type,
    };

    Progress.show(
      { method: updateCustomerBilling, params: [bodyBilling] },
      (res: any) => {
        onClose();
        Notifications.showSuccess(t("updateSuccess"));
        onUpdated();
      }
    );
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("updateBilling")}
      size="large"
      onSave={() => {
        customerForm.handleSubmit();
      }}
    >
      <div>
        <CustomerInputBilling customerForm={customerForm} />
      </div>
    </Drawer>
  );
};

const CustomerAddBillingDrawer = ({
  open,
  customer,
  onUpdated,
  onClose,
}: ICustomerAddBillingDrawer) => {
  const { t } = useTranslation();
  const customerForm = useFormik<any>({
    initialValues: {} as any,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: BillingSchema,
    onSubmit: (values: any) => {
      onCreateBilling(values);
    },
  });
  const formValues = customerForm?.values;
  const formErrors = customerForm?.errors;

  const onCreateBilling = (values: any) => {
    const {
      firstname,
      lastname,
      nickname,
      province,
      district,
      postCode,
      address,
      subDistrict,
      phone,
      typeOfPlace,
      citizenid,
      taxid,
      email,
      type,
    } = values;
    const bodyBilling = {
      firstname,
      lastname,
      nickname: isEmpty(nickname) ? firstname : nickname,
      phone,
      province: province?.id ?? "",
      city: district?.id ?? "",
      subdistrict: subDistrict?.id ?? "",
      postcode: postCode,
      address,
      customerid: customer?.id,
      default: true,
      place: typeOfPlace,
      citizenid: StringUtils.removeAllSpace(citizenid),
      taxid: StringUtils.removeAllSpace(taxid),
      email,
      type,
    };

    Progress.show(
      { method: createCustomerBilling, params: [bodyBilling] },
      (res: any) => {
        onClose();
        Notifications.showSuccess(t("addBillingSuccess"));
        onUpdated();
      }
    );
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("addBilling")}
      size="large"
      onSave={() => {
        customerForm.handleSubmit();
      }}
    >
      <div>
        <CustomerInputBilling customerForm={customerForm} />
      </div>
    </Drawer>
  );
};

const CustomerDetailBilling = () => {
  const { customerDetail, loadCustomerDetail } = useContext(AppContext);
  const [openAdd, setOpenAdd] = useState(false);
  const [billingList, setBillingList] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    loadBillingList();
  }, []);

  const loadBillingList = async () => {
    const res = await searchBillingCustomer(customerDetail?.id);
    const billingUserList = res?.data?.data?.billing;
    setBillingList(billingUserList);
  };

  return (
    <div className="mt-3 border p-4 ">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h5>{t("billing")}</h5>

        <Button onClick={() => setOpenAdd(true)} className="" iconName="add">
          {t("add")}
        </Button>
      </div>
      {map(billingList, (billing, index) => (
        <AddressItem
          address={billing}
          index={index + 1}
          customer={customerDetail}
          onUpdated={loadBillingList}
        />
      ))}
      {openAdd && (
        <CustomerAddBillingDrawer
          open={openAdd}
          onClose={() => setOpenAdd(false)}
          customer={customerDetail}
          onUpdated={loadBillingList}
        />
      )}
    </div>
  );
};

export default CustomerDetailBilling;
