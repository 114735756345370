import AddressFormInput from "@components/address/components/AddressFormInput";
import AppContext from "@helpers/context";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

const WarehouseCreateAddress = () => {
  const { warehouseForm } = useContext(AppContext);
  const { t } = useTranslation();
  return (
    <div className="card-container p-4">
      <label>{t("address")}</label>
      <div className="row">
        <AddressFormInput
          formDataValue={warehouseForm.values}
          formDataError={warehouseForm.errors}
          onChange={(newValue: any) => warehouseForm.setValues(newValue)}
          show={{ typeOfPlace: false, map: true }}
        />
      </div>
    </div>
  );
};

export default WarehouseCreateAddress;
