import { Button, Drawer as DDrawer, IDrawerProps } from "d-react-components";
import { useTranslation } from "react-i18next";

export interface DrawerProps extends IDrawerProps {
  saveText?: string;
  cancelText?: string;
  onSave?: () => void;
  disableSave?: boolean;
  hideFooter?: boolean;
  showCancel?: boolean;
  showSave?: boolean;
  customSideButton?: any;
  onClose?: any;
}

export const Drawer = (props: DrawerProps) => {
  const {
    saveText,
    cancelText,
    onSave,
    onClose,
    disableSave = false,
    hideFooter = false,
    showCancel = true,
    customSideButton = null,
    showSave = true,
  } = props;
  const { t } = useTranslation();
  return (
    <DDrawer {...props} onClose={onClose} destroyOnClose>
      <div className="pb-12">{props.children}</div>
      {!hideFooter && (
        <div className="position-absolute z-50 bottom-0 w-100 end-0 start-0 flex-center-y justify-content-between py-3 px-3 bg-white">
          {onClose && showCancel && (
            <Button
              onClick={(event) => {
                onClose && onClose(event);
              }}
              variant="outline"
            >
              {cancelText || t("cancel")}
            </Button>
          )}
          <div className="flex flex-1 justify-content-end">
            {customSideButton}
            {showSave && (
              <Button
                onClick={() => {
                  onSave && onSave();
                }}
                disabled={disableSave}
              >
                {saveText || t("save")}
              </Button>
            )}
          </div>
        </div>
      )}
    </DDrawer>
  );
};

export default Drawer;
