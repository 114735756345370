import AppContext from "@helpers/context";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

const OrderSignature = () => {
  const { order } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <div className="bg-white mb-3 p-3 shadow-sm">
      <label className="block text-sm">{t("customerSignature")}</label>
      <div className="signaturePhotoContainer">
        <img alt="" src={order?.signature} className="w-full" />
      </div>
    </div>
  );
};

export default OrderSignature;
