import ChannelSelect from "@components/channel/components/ChannelSelect";
import Drawer from "@components/shared/Drawer";
import { useDropzone } from "react-dropzone";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Progress } from "antd";
import {
  Button,
  Icon,
  Notifications,
  ObjectUtils,
  StringUtils,
} from "d-react-components";
import { every, filter, isEmpty, map, uniqBy } from "lodash";
import { productionCostImport, validateSkuProduct } from "@network/api/product";
import { CSVLink } from "react-csv";
import MemoForm from "./MemoForm";
import { convertCsvJSON } from "@helpers/string";
import { runPromisesSequentially } from "@helpers";

const ImportDrawer = ({ open, onClose, onSuccessImport }: any) => {
  const { t } = useTranslation();
  const [channel, setChannel] = useState<any>([]);
  const [validateLoading, setValidateLoading] = useState(false);
  const [importing, setImporting] = useState(false);
  const [percentValidation, setPercentValidation] = useState<any>();
  const [importSuccess, setImportSuccess] = useState(false);
  const [skuList, setSkuList] = useState<any>([]);
  const [memo, setMemo] = useState("");
  const [memoAttach, setMemoAttach] = useState([]);

  const validateSkuList = (skuListConverted: any[]) => {
    return every(skuListConverted, (sku) => {
      if (
        isEmpty(sku.production_cost) ||
        isEmpty(sku.sku) ||
        isEmpty(sku.sale_expense) ||
        isEmpty(sku.admin_expense)
      ) {
        return false;
      }

      const validStockNumber = sku.production_cost
        .replace("+", "")
        .replace("-", "");
      if (!StringUtils.isAllDigit(validStockNumber.replace(".", ""))) {
        return false;
      }
      return true;
    });
  };

  const getValidateSkuApi = (skuItemList: any[]) => {
    const body = {
      sku: skuItemList.map((sku) => sku.sku),
    };
    // if (customValidate) {
    //   return customValidate(body, config);
    // }
    return validateSkuProduct(body, {});
  };

  const onDrop = (files: any[]) => {
    const reader = new FileReader();
    reader.readAsText(files[0]);
    reader.onload = function (e) {
      const skuList: any = convertCsvJSON(reader.result);
      if (!validateSkuList(skuList)) {
        setValidateLoading(false);
        Notifications.showError(
          t("production_cost:fileImportIsNotRightFormat")
        );
        return;
      }

    setValidateLoading(true);

      const mulSkuList = ObjectUtils.sliceArrayToMui(skuList, 50);
      const skuListResult: any[] = [];
      let completedIndex = 1;
      runPromisesSequentially(
        mulSkuList.map((skuItemList) => {
          return () =>
            new Promise(async (resolve) => {
              const response = await getValidateSkuApi(skuItemList);
              setPercentValidation(
                Number((completedIndex / mulSkuList.length) * 100).toFixed(0)
              );
              completedIndex++;
              const resSkuItems = response?.data?.data?.validate ?? {};
              const skuRestObject = {
                ...resSkuItems,
              };
              Object.keys(skuRestObject).forEach((key) => {
                const sku = skuRestObject[key];
                const skuFromImport = skuList.find(
                  (skuParam: any) => skuParam.sku === key
                );

                skuListResult.push({
                  ...skuFromImport,
                  ...sku,
                  productionCost: Number(skuFromImport.production_cost),
                  saleExpense: Number(skuFromImport.sale_expense),
                  adminExpense: Number(skuFromImport.admin_expense),
                });
              });
              resolve(response);
            });
        })
      )
        .then(() => {
          setSkuList(uniqBy(skuListResult, "sku"));
          setValidateLoading(false);
          setImportSuccess(true);
        })
        .catch((err) => {
          setValidateLoading(false);
          Notifications.showError("error");
        });
    };
  };

  const getSkuUnValidNumber = () => {
    return getSkuUnValids().length;
  };

  const getSkuValidNumber = () => {
    return skuList.length - getSkuUnValidNumber();
  };

  const getSkuUnValids = () => {
    return filter(skuList, (sku: any) => {
      return !sku.status;
    });
  };

  const getErrorItemsData = () => {
    const skuUnValid = getSkuUnValids();

    const header = [t("sku"), t("productionCost")];
    const dataResult = [header];
    skuUnValid.forEach((sku) => {
      dataResult.push([sku?.sku, sku.productionCost]);
    });
    return dataResult;
  };

  const sampleCSVData = () => {
    const sampleData = [
      [8832248540211, 2000, 10, 10],
      [8832248540411, 3000, 10, 10],
      [8832248540211, 3100, 10, 10],
      [8832248540211, 1100, 10, 10],
    ];
    const header = ["sku", "production_cost", "sale_expense", "admin_expense"];
    const dataResult = [header, ...sampleData];
    return dataResult;
  };

  const getApiUpdateStockInventory = (skuListImport: any) => {
    const body = {
      // channel_id: channel?.id,
      channel_id: map(channel, (item) => item.id),
      memo,
      products: skuListImport.map((sku: any) => ({
        product_id: sku.id,
        production_cost: sku.productionCost,
        admin_expense: sku.adminExpense,
        sale_expense: sku.saleExpense,
      })),
      attachment: memoAttach.map((item: any) => item.key),
    };

    return productionCostImport(body);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const renderButtonAttachFile = () => {
    if (validateLoading) {
      return <Progress percent={percentValidation} />;
    }
    return (
      <Button
        iconName="attach_file"
        {...getRootProps({
          onClick: (event) => {
            if (!channel?.length) {
              Notifications.showError(t("selectChannelFirst"));
              event.stopPropagation();
            }
          },
        })}
        /* @ts-ignore */
        content={
          <div>
            <input
              {...getInputProps()}
              type="file"
              accept=".csv, text/csv"
              multiple={false}
            />
            {t("production_cost:attachCSV")}
          </div>
        }
      />
    );
  };

  const onClickSaveProduct = async () => {
    if (!channel?.length) {
      Notifications.showError(t("production_cost:channelFieldIsRequired"));
      return Promise.reject();
    }

    if (!importSuccess) {
      Notifications.showError(t("production_cost:attachFileBeforeImport"));
      return Promise.reject();
    }
    if (getSkuUnValidNumber() > 0) {
      Notifications.showError(t("production_cost:fileIsNotRightFormat"));
      return Promise.reject();
    }
    if (isEmpty(memo)) {
      Notifications.showError(t("production_cost:memoRequire"));
      return Promise.reject();
    }
    if (isEmpty(memoAttach)) {
      Notifications.showError(t("production_cost:memoAttachRequire"));
      return Promise.reject();
    }
    const skuListSliced = ObjectUtils.sliceArrayToMui(skuList, 50);
    let completedIndex = 1;
    setImporting(true);
    runPromisesSequentially(
      skuListSliced.map((skuListItem) => {
        return () =>
          new Promise(async (resolve) => {
            const response = await getApiUpdateStockInventory(skuListItem);
            setPercentValidation(
              Number((completedIndex / skuListSliced.length) * 100).toFixed(0)
            );
            completedIndex++;
            resolve(response);
          });
      })
    )
      .then(
        () => {
          onClose();
          setImportSuccess(false);
          setImporting(false);
          Notifications.showSuccess(
            t("production_cost:importProductionCosrSuccess")
          );
          onSuccessImport && onSuccessImport();
        },
        (err) => {
          Notifications.showError(err);
          setImporting(false);
          setValidateLoading(false);
        }
      )
      .catch((err) => {
        setValidateLoading(false);
        Notifications.showError(err);
      });
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      onSave={onClickSaveProduct}
      title={t("production_cost:productionCostImport")}
      saveText={t("import")}
      size="auto"
      width="500px"
      disableSave={validateLoading || importing}
    >
      {importSuccess ? (
        <>
          <Progress percent={percentValidation} />
          <div className="itemImportResult">
            <Icon name="check_circle" className="text-primary mr-1" />
            <span>
              {getSkuValidNumber()} {t("production_cost:itemValidToImport")}
            </span>
          </div>
          <div className="itemImportResult">
            <Icon name="error" className="text-danger mr-1" />

            <span>
              {getSkuUnValidNumber()} {t("production_cost:itemHaveErrorImport")}
            </span>
            <CSVLink data={getErrorItemsData()}>
              <text className=" text-primary ml-1">{t("downloadLog")}</text>
            </CSVLink>
          </div>
          <MemoForm
            memoContent={memo}
            attachments={memoAttach}
            onChangeContent={setMemo}
            onChangeAttach={setMemoAttach}
          />
        </>
      ) : (
        <>
          <p className="mb-1">{t("production_cost:updateBulkSingleFile")}</p>
          <p>
            <strong>{t("common:requiredField")}</strong>: sku, production_cost,
            sale_expense, admin_expense
          </p>
          <ChannelSelect
            multiple
            value={channel}
            onChange={(value) => setChannel(value)}
            className="mb-3"
          />
          <div className="mb-2">{renderButtonAttachFile()}</div>
          <p className="mb-1">{t("production_cost:notSureHowStart")}</p>
          <p className="mb-0">- {t("production_cost:downloadCSV")}</p>
          <p className="mb-0">
            - {t("common:download")}{" "}
            <CSVLink
              data={sampleCSVData()}
              enclosingCharacter={``}
              filename={"import-production-cost-template.csv"}
            >
              <span className=" text-primary ml-1">
                {t("production_cost:blankCSVTemplate")}
              </span>
            </CSVLink>
          </p>
        </>
      )}
    </Drawer>
  );
};

export default ImportDrawer;
