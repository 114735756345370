import {
  AwesomeTableComponent,
  Icon,
  Notifications,
  StringUtils,
} from "d-react-components";
import { isEmpty, map } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { PRODUCT_TYPE } from "@constants/product";
import ProductName from "@components/product/components/ProductName";
import { useTranslation } from "react-i18next";
import AppContext from "@helpers/context";
import ProductCard from "@components/product/components/ProductCard";
import { Pagination, Spin } from "antd";
import {
  getSizeDefault,
  isCustomSizeProduct,
  transferQuantityOfProduct,
  transformerProduct,
} from "@helpers/product";
import styled from "@emotion/styled";
import { useMedia } from "react-use";
import classNames from "classnames";
import { STORE_TYPE } from "@constants/store";
import { OrderCreateType } from "./OrderDetail";
import CurrencyFormat from "react-currency-format";

const SIZE_OPTIONS = [18, 36, 72];

const OrderDetailProductList = ({
  isStockConsider = true,
  isExpandable = false,
  source = () => Promise.resolve([]),
  navBarHeight,
}: any) => {
  const { t } = useTranslation();

  const [pagination, setPagination] = useState<any>({
    pageIndex: 1,
    pageSize: SIZE_OPTIONS[0],
  });
  const productItemTable = useRef<any>(null);
  const [currentProductList, setCurrentProductList] = useState([]);

  const {
    values,
    productList,
    setProductList,
    filterBody,
    viewMode,
    inputSearch,
    productCustomList,
    setProductCustomList,
    isEventSaleSelected,
    type,
  } = useContext(AppContext);
  const { isChainExternal, mtOrderType, mtOrderDiscount } = values;

  useEffect(() => {
    productItemTable.current && productItemTable.current.refresh();
  }, [inputSearch, filterBody, isChainExternal, mtOrderType, mtOrderDiscount]);

  const dataSource = (paging: any) => {
    if (
      isEmpty(values.channel) ||
      isEmpty(values.warehouse) ||
      (isChainExternal && !mtOrderType)
    ) {
      return Promise.resolve([]);
    }
    return source(inputSearch, filterBody, paging);
  };

  const transformExpandableProduct = (item: any) => {
    const isGroup = item?.product?.is_group ?? false;
    if (isGroup) {
      const editedGroups = item?.product?.groups?.map((subPro: any) => ({
        ...subPro,
        productid: subPro?.product?.id,
        product: {
          ...subPro.product,
          stock: subPro?.quantity * item?.product?.stock,
          total_stock: subPro?.quantity * item?.product?.total_stock,
        },
      }));
      const cloneItem = {
        ...item,
        children: [...editedGroups],
        id: item?.productid ?? Math.random(),
      };
      return cloneItem;
    }
    return { ...item, id: item?.productid ?? Math.random() };
  };

  const transformer = (response: any) => {
    let productList = response?.data?.data?.products ?? [];

    if (isExpandable) {
      productList = map(productList, transformExpandableProduct);
    }

    setCurrentProductList(productList);

    return productList;
  };

  const onChangeQuantity = useCallback(
    (product: any, quantity: number) => {
      if (quantity < 1) {
        return;
      }

      if (product?.isCustomProduct) {
        const newProCustomList =
          productCustomList &&
          productCustomList?.map((item: any) => {
            if (item.productid === product?.productid) {
              return transformerProduct({ ...product, quantity });
            }
            return item;
          });

        setProductCustomList(newProCustomList);
      } else {
        const newProductList =
          productList &&
          productList?.map((item: any) => {
            if (item.product.id === product?.product?.id) {
              return transferQuantityOfProduct(product, quantity);
            }
            return item;
          });
        setProductList([...newProductList]);
      }
    },
    [productCustomList, productList, setProductCustomList, setProductList]
  );

  const addNewProduct = useCallback(
    (product: any) => {
      const notAvailable = isEventSaleSelected
        ? false
        : product?.product?.delivery === 0 &&
          !product?.product?.selling_out_of_stock;
      if (isStockConsider && notAvailable) {
        Notifications.showError(t("notification:thisItemOutOfStock"));
        return;
      }
      let isProductExisted = false;
      let itemQuantity = 0;
      const productsResult = productList?.map((item: any) => {
        if (product.productid === item.productid) {
          isProductExisted = true;
          itemQuantity = item.quantity + 1;
          return {
            ...item,
            quantity: item.quantity + 1,
          };
        }
        return item;
      });
      if (!isProductExisted) {
        const newProduct = {
          ...product,
          quantity: 1,
          meta: isCustomSizeProduct(product) ? [getSizeDefault()] : [],
        };
        if (type === OrderCreateType.NORMAL) {
          productsResult.push(newProduct);
        }
        if (type === OrderCreateType.RENTAL) {
          productsResult.pop();
          productsResult.push(newProduct);
        }
        itemQuantity = 1;
        setProductList(productsResult);
      } else {
        if (isEventSaleSelected && itemQuantity > product?.product?.stock) {
          Notifications.showError(t("notification:thereIsNotEnoughStock"));
          return;
        }
        onChangeQuantity(product, itemQuantity);
      }
      Notifications.showSuccess(t("notification:itemAddedSuccessfully"));
    },
    [
      productList,
      setProductList,
      t,
      isStockConsider,
      onChangeQuantity,
      isEventSaleSelected,
    ]
  );

  return (
    <div className="">
      {viewMode === "list" && (
        <ProductList
          {...{
            productItemTable,
            dataSource,
            transformer,
            setPagination,
            addNewProduct,
            navBarHeight,
          }}
          showRentPrice={type === OrderCreateType.RENTAL}
        />
      )}
      {viewMode === "grid" && (
        <ProductGrid
          {...{
            inputSearch,
            filterBody,
            pagination,
            setPagination,
            dataSource,
            transformer,
            addNewProduct,
            navBarHeight,
          }}
          showRentPrice={type === OrderCreateType.RENTAL}
        />
      )}
    </div>
  );
};

const ProductGrid = React.memo(
  ({
    inputSearch,
    filterBody,
    pagination,
    setPagination,
    dataSource,
    transformer,
    addNewProduct,
    navBarHeight,
    showRentPrice,
  }: any) => {
    const [productList, setProductList] = useState<any>([]);
    const [total, setTotal] = useState<number>();
    const [loadingList, setLoadingList] = useState(false);
    const { values, headerBlockHeight } = useContext(AppContext);
    const isWide = useMedia("(min-width: 1280px)");
    const isMobile = useMedia("(max-width: 767px)");
    const { isChainExternal, mtOrderType, mtOrderDiscount } = values;

    const getProduct = async () => {
      try {
        setLoadingList(true);
        const productResp = await dataSource(pagination);
        setProductList(transformer(productResp));
        setTotal(productResp?.data?.data?.pagination?.items);
        setLoadingList(false);
      } catch (err) {
        Notifications.showError(err);
        setLoadingList(false);
      }
    };

    useEffect(() => {
      getProduct();
    }, [
      pagination,
      filterBody,
      inputSearch,
      values.channel,
      values.warehouse,
      mtOrderType,
      mtOrderDiscount,
    ]);

    return (
      <>
        {loadingList && (
          <div className="w-full flex items-center p-3 justify-content-center">
            <Spin />
          </div>
        )}
        <div
          className={classNames(
            {
              "grid-cols-6 gap-3": isWide,
              "grid-cols-4 gap-3": !isWide && !isMobile,
              "grid-cols-3 gap-2": isMobile,
            },
            "grid mt-3 overflow-auto relative height-mobile-auto"
          )}
          style={{
            height: `calc(100vh - ${
              headerBlockHeight + navBarHeight + 96 ?? 0
            }px)`,
          }}
        >
          {productList?.map((product: any, index: number) => (
            <ProductCard
              item={product}
              key={index}
              addNewProduct={addNewProduct}
              showRentPrice={showRentPrice}
            />
          ))}
          {/* {loadingList && (
            <div className="absolute w-full pt-10 flex justify-content-center">
              <Spin />
            </div>
          )} */}
        </div>
        <div className="d-table-awesome-component bg-white flex align-items-end pr-2">
          <Pagination
            showTotal={(total: any) => (
              <div className="captionText">{`Total ${total} items`}</div>
            )}
            current={pagination.pageIndex}
            total={total}
            className="ant-table-pagination ant-table-pagination-right"
            pageSizeOptions={SIZE_OPTIONS}
            onChange={(pageIndex, pageSize) =>
              setPagination({ pageIndex, pageSize })
            }
            pageSize={pagination.pageSize}
          />
        </div>
      </>
    );
  }
);

const ProductList = React.memo(
  ({
    productItemTable,
    dataSource,
    transformer,
    setPagination,
    navBarHeight,
    addNewProduct,
    showRentPrice,
  }: any) => {
    const { values } = useContext(AppContext);
    const { storeSelected } = values;
    const isHideAvaQtyCurrentStore = useMemo(
      () => storeSelected?.store_type === STORE_TYPE.ONLINE,
      [storeSelected]
    );
    const isHideOnHoldQtyCurrentStore = useMemo(
      () => storeSelected?.store_type === STORE_TYPE.ONLINE,
      [storeSelected]
    );
    const { headerBlockHeight } = useContext(AppContext);
    const { t } = useTranslation();

    const renderSubProductNameView = useCallback(
      (product: any) => {
        const subView = [];
        if (product.product_type === PRODUCT_TYPE.GROUP_CUSTOM_SIZE) {
          subView.push(
            <span className="small bg-dark width-fit-content ml-2 px-2 py-1 text-white">
              {t("specialSize")}
            </span>
          );
        }
        return subView;
      },
      [t]
    );

    const columns = useMemo(
      () => [
        {
          title: t("product:itemNameTable"),
          dataIndex: "product",
          width: 300,
          ellipsis: true,
          render: (product: any, item: any) => {
            return (
              <ProductName
                item={item}
                renderSubName={() => renderSubProductNameView(product)}
              />
            );
          },
        },
        ...(isHideAvaQtyCurrentStore
          ? []
          : [
              {
                isHide: isHideAvaQtyCurrentStore,
                title: t("product:availableQtyCurrentStore"),
                titleTooltip: t("product:availableQtyCurrentStoreTooltip"),
                dataIndex: "product",
                width: 120,
                render: (product: any) => product?.stock - product?.stock_hold,
              },
            ]),
        ...(isHideOnHoldQtyCurrentStore
          ? []
          : [
              {
                title: t("product:onHoldQtyCurrentStore"),
                titleTooltip: t("product:onHoldQtyCurrentStoreTooltip"),
                dataIndex: "product",
                width: 120,
                render: (product: any) => product?.stock_hold,
              },
            ]),
        {
          title: t("product:availableQtyDelivery"),
          titleTooltip: t("product:availableQtyDeliveryTooltip"),
          dataIndex: "product",
          width: 120,
          render: (product: any) => product?.delivery - product?.delivery_hold,
        },
        {
          title: t("product:onHoldQtyDelivery"),
          titleTooltip: t("product:onHoldQtyDeliveryTooltip"),
          dataIndex: "product",
          width: 120,
          render: (product: any) => product?.delivery_hold,
        },

        {
          title: t("product:regularPrice"),
          dataIndex: "regular_price",
          width: 120,
          render: (price: any, item: any) => (
            <CurrencyFormat
              value={showRentPrice ? item?.product?.regular_price_rent : price}
              displayType="text"
              thousandSeparator
            />
          ),
        },
        {
          title: t("product:salePrice"),
          dataIndex: "sale_price",
          width: 120,
          render: (price: any, item: any) => (
            <CurrencyFormat
              value={showRentPrice ? item?.product?.sale_price_rent : price}
              displayType="text"
              thousandSeparator
            />
          ),
        },
        {
          title: t("action"),
          dataIndex: "",
          width: 30,
          render: (productItem: any, item: any) => {
            return (
              <span
                className="cursor-pointer text-primary"
                onClick={() => addNewProduct(item)}
              >
                <Icon name="add_shopping_cart_icon" />
              </span>
            );
          },
        },
      ],
      [
        t,
        addNewProduct,
        isHideAvaQtyCurrentStore,
        isHideOnHoldQtyCurrentStore,
        renderSubProductNameView,
      ]
    );

    return (
      <ProductTableWrapper {...{ headerBlockHeight, navBarHeight }}>
        <AwesomeTableComponent
          ref={productItemTable}
          source={(currentPage) => {
            setPagination(currentPage);
            return dataSource(currentPage);
          }}
          transformer={(res) => transformer(res)}
          columns={columns}
          isScroll={false}
          // className="height-max-content mt-3 overflow-auto"
          defaultPagination={{
            pageSize: SIZE_OPTIONS[0],
            pageIndex: 1,
            showQuickJumper: false,
            pageSizeOptions: SIZE_OPTIONS,
          }}
        />
      </ProductTableWrapper>
    );
  }
);

const ProductTableWrapper = styled.div`
  .ant-table {
    overflow: auto;
    @media (min-width: 768px) {
      height: ${(props: any) =>
        `calc(100vh - ${
          props.headerBlockHeight + props.navBarHeight + 96 ?? 0
        }px)`};
    }
    .ant-table-thead {
      th {
        i.material-icons {
          display: none;
        }
      }
    }
  }
`;

export default OrderDetailProductList;
