import { FILE_TYPE, IMAGE } from "@constants/file";
import { getFileNameFromUrl, getImageDataPreview } from "@helpers/file";
import { uploadFile } from "@network/api/common";
import classNames from "classnames";
import {
  Button,
  Icon,
  Notifications,
  ObjectUtils,
  StringUtils,
  useFirstTime,
} from "d-react-components";
import { find, includes, map, some, split } from "lodash";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useDropzone } from "react-dropzone";
import SimpleReactLightbox, {
  SRLWrapper,
  useLightbox,
} from "simple-react-lightbox";
import heic2any from "heic2any";

const UPLOAD_MODE = {
  PENDING: "pending",
  SUCCESS: "success",
  ERROR: "error",
};

const FileUploadItem = ({
  file,
  onRemove,
  onChange,
  classNameItem,
  classNameImage = "",
  isView,
  onClick,
  uploadAfterSelect,
  uploadFunction,
}: any) => {
  const isPending = file.status === UPLOAD_MODE.PENDING;
  const isError = file.status === UPLOAD_MODE.ERROR;
  const isSuccess = file.status === UPLOAD_MODE.SUCCESS;

  useEffect(() => {
    isPending && uploadAfterSelect && onUploadFile();
  }, [file.status]);

  const onRetry = () => {
    onChange({ ...file, status: UPLOAD_MODE.PENDING });
  };

  const onUploadFile = () => {
    const body = new FormData();
    body.append("file", file.fileData);
    const uploadFileFunction = uploadFunction || uploadFile;

    uploadFileFunction(body, file)
      .then((res: any) => {
        const fileKey = res?.data?.data?.id;
        onChange({ ...file, key: fileKey, status: UPLOAD_MODE.SUCCESS });
      })
      .catch((err: any) => {
        onChange({ ...file, status: UPLOAD_MODE.ERROR });
      });
  };

  const classLoading = classNames("file-upload__item-loading", {});

  const classIconSuccess = classNames(
    "btn btn-trans file-upload__item-icon text-success"
  );
  const classIconError = classNames(
    "btn btn-trans file-upload__item-icon text-danger"
  );
  const classIconFooter = classNames(
    "btn btn-trans text-white p-1 flex-center"
  );

  const renderItemFooter = () => {
    if (isView && typeof isView === "function") {
      if (isView(file)) {
        return <div />;
      }
    } else if (isPending || isView) return <div />;
    return (
      <div className="file-upload__item-footer">
        <button className={classIconFooter} onClick={() => onRemove(file)}>
          <Icon name="delete" size="x-small" />
        </button>
        {isError && (
          <button className={classIconFooter} onClick={onRetry}>
            <Icon name="rotate_left" size="x-small" />
          </button>
        )}
      </div>
    );
  };

  return (
    <div className={`file-upload__item ${classNameItem}`}>
      {isSuccess && (
        <button className={classIconSuccess}>
          <Icon name="check_circle" />
        </button>
      )}
      {isError && (
        <button className={classIconError}>
          <Icon name="info" />
        </button>
      )}
      {renderItemFooter()}
      <img
        src={file.imageData}
        className={`file-upload__item-image ${classNameImage}`}
        onClick={onClick}
      />

      {isPending && (
        <div className={classLoading}>
          <div className="spinner-border text-white" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </div>
  );
};
/**
 *
 * @param {
 * buttonText: customize text in main button
 * disabled: true => disabled button upload
 * onChange: only invoke when file uploaded
 * defaultFiles: Default files before upload, must follow object's format:
 * {
 *    id: 91208391823,
 *    imageData: "uri"
 * }
 * } param0
 * @returns
 */

const ButtonFileUploadRef: any = (
  {
    buttonText = "Browse files",
    disabled = false,
    onChange,
    inputParam = {},
    containerClassName = "",
    maxFiles = 0,
    buttonClassName = "",
    defaultFiles = [],
    classNameItem = "",
    renderButton = null,
    isView = false,
    classNameImage = "",
    uploadAfterSelect = true,
    uploadFunction = null,
  }: any,
  ref: any
) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  } as any);
  const listDefaultFile = map(defaultFiles, (item) => ({
    ...item,
    status: UPLOAD_MODE.SUCCESS,
  }));
  const [listFile, setListFile] = useState<any[]>(listDefaultFile);
  const firstTime = useFirstTime();

  useImperativeHandle(ref, () => ({
    refresh: () => {
      setListFile([]);
    },
    listFile,
  }));

  useEffect(() => {
    if (firstTime) return;
    const uploadedFiles = uploadAfterSelect
      ? listFile.filter((item) => item.status === UPLOAD_MODE.SUCCESS)
      : listFile;
    onChange(uploadedFiles);
  }, [listFile]);

  const validateFileLimitNumber = (fileUpload: any) => {
    if (!!maxFiles && listFile.length + fileUpload.length > maxFiles) {
      Notifications.showError(`Only max ${maxFiles} files!`);
      return true;
    }
    return false;
  };

  /**
   * validate format of file follow inputParam
   * normally, its prevented on mode select but not in drag and drop mode
   * @param {*} fileUpload
   * @returns
   */
  const validateFileType = (fileUpload = []) => {
    if (inputParam?.accept) {
      const acceptTypes = split(inputParam?.accept, ",");
      const isUnValidType = some(
        fileUpload,
        (item: any) => !includes(acceptTypes, item.type)
      );
      if (isUnValidType) {
        Notifications.showError(`Only accept ${inputParam?.accept}`);
        return true;
      }
    }
    return false;
  };

  const validateFileInput = (fileUpload: any) => {
    if (validateFileLimitNumber(fileUpload)) {
      return true;
    }
    if (validateFileType(fileUpload)) {
      return true;
    }
    return false;
  };

  /**
   * depend on extension that get from fileName =>
   * if file is an image => return fileData to display in preview
   * if file is an pdf/doc/excel => return local image file to display in preview
   * @param {*} fileName
   * @param {*} fileData
   * @returns
   */
  const getImageDataPreview = (fileName = "", fileData: any) => {
    const extension = StringUtils.getExtensionFromFilename(fileName);
    const isImage = IMAGE.extension.includes(extension);
    if (isImage) return fileData;
    const fileFormat = find(FILE_TYPE, (fileTypeItem) =>
      fileTypeItem.extension.includes(extension)
    );
    if (fileFormat) {
      return fileFormat.iconFile;
    }
    return "";
  };

  function onDrop(fileUpload = []) {
    if (validateFileInput(fileUpload)) {
      return;
    }

    const fileResult: any[] = [];
    fileUpload.forEach((file: any, index) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        //convert to jpg
        if (file.type === "image/heic") {
          heic2any({
            blob: file,
            toType: "image/jpeg",
            quality: 0.7,
          }).then(
            (blob: any) => {
              let newFile = new File([blob], `${file.name}.jpg`, {
                type: "image/jpeg",
              });
              var url = URL.createObjectURL(blob);
              fileResult.push({
                id: StringUtils.getUniqueID(),
                fileData: newFile,
                imageData: getImageDataPreview(newFile.name, url),
                status: uploadAfterSelect ? UPLOAD_MODE.PENDING : null,
              });
              if (index === fileUpload.length - 1) {
                const clone = [...fileResult];
                setListFile([...listFile, ...clone]);
              }
            },
            (error) => {
              Notifications.showError("Error converting HEIC to JPEG");
            }
          );
        } else {
          fileResult.push({
            id: StringUtils.getUniqueID(),
            fileData: file,
            imageData: getImageDataPreview(file.name, reader.result),
            status: uploadAfterSelect ? UPLOAD_MODE.PENDING : null,
          });
          if (index === fileUpload.length - 1) {
            const clone = [...fileResult];
            setListFile([...listFile, ...clone]);
          }
        }
      };
    });
  }

  const onClickRemoveFile = (item: any) => {
    const listFileResult = ObjectUtils.removeArrayById(listFile, item.id);
    setListFile(listFileResult);
  };

  const onChangeFile = (file: any) => {
    const listFileResult = ObjectUtils.updateArrayById(listFile as any, file);
    setListFile(listFileResult);
  };

  return (
    <div className={containerClassName}>
      <div className="d-flex flex-wrap">
        <SimpleReactLightbox>
          <FileUploadGallery
            listFile={listFile}
            onChangeFile={onChangeFile}
            onClickRemoveFile={onClickRemoveFile}
            classNameItem={classNameItem}
            classNameImage={classNameImage}
            isView={isView}
            uploadAfterSelect={uploadAfterSelect}
            uploadFunction={uploadFunction}
          />
        </SimpleReactLightbox>
      </div>
      {renderButton ? (
        renderButton(getRootProps, getInputProps, inputParam)
      ) : (
        <Button
          className={classNames(buttonClassName)}
          disabled={disabled}
          {...getRootProps()}
          iconName="cloud_upload"
        >
          <div>
            <input {...getInputProps(inputParam)} />
            {buttonText}
          </div>
        </Button>
      )}
    </div>
  );
};

const FileUploadGallery = ({
  listFile,
  onChangeFile,
  onClickRemoveFile,
  classNameItem,
  classNameImage,
  isView,
  uploadAfterSelect,
  uploadFunction,
}: any) => {
  const imageToView = listFile?.map((a: any) => ({
    src: getImageDataPreview(a),
    caption: getFileNameFromUrl(
      a?.fileData ? a?.fileData?.path : a.attachment || a.imageData
    ),
    download: a.attachment || a.url,
  }));
  const { openLightbox, closeLightbox } = useLightbox();
  return (
    <>
      {listFile.map((fileItem: any, index: number) => (
        <FileUploadItem
          key={index}
          file={fileItem}
          onChange={onChangeFile}
          onRemove={onClickRemoveFile}
          classNameItem={classNameItem}
          classNameImage={classNameImage}
          isView={isView}
          uploadAfterSelect={uploadAfterSelect}
          uploadFunction={uploadFunction}
          onClick={() => {
            openLightbox(index);
          }}
        />
      ))}
      {/* @ts-ignore */}
      <SRLWrapper
        elements={imageToView}
        options={{ thumbnails: { showThumbnails: false } }}
      ></SRLWrapper>
    </>
  );
};

const ButtonFileUpload: any = forwardRef(ButtonFileUploadRef);
export default ButtonFileUpload;
