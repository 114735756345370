export enum STOCK_REQUEST_TYPE {
  TO_STORE = "request_stock_to_store",
  TO_DEMON = "request_stock_to_demon",
  BACK_STORE = "request_stock_back_store",
  BACK_DEMON = "request_stock_back_demon",
}
export const STOCK_REQUEST_TYPES = [
  { id: STOCK_REQUEST_TYPE.TO_STORE, label: "requestStockToStore" },
  { id: STOCK_REQUEST_TYPE.TO_DEMON, label: "requestStockToDemon" },
  { id: STOCK_REQUEST_TYPE.BACK_STORE, label: "requestStockBackStore" },
  { id: STOCK_REQUEST_TYPE.BACK_DEMON, label: "requestStockBackDemon" },
];

export enum STOCK_REQUEST_STATUS {
  PENDING = "pending",
  REJECT = "reject",
  CONFIRM = "confirm",
  CANCELLED = "cancelled",
}

export const STOCK_REQUEST_STATUS_LIST = [
  {
    id: "pending",
    value: "pending",
    label: "pending",
    color: "#FFBD59 ",
  },

  { id: "reject", value: "reject", label: "rejected", color: "#EB5757" },

  {
    id: "confirm",
    value: "confirm",
    label: "confirmed",
    color: "#33B950",
  },
  {
    id: "cancelled",
    value: "cancelled",
    label: "cancelled",
    color: "#8D8D8D",
  },
];
