import {
  Select,
  SelectInfinity,
  SelectInfinityProps,
  SelectProps,
} from "d-react-components";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  SelectInfinity as CustomSelectInfinity,
  SelectInfinityProps as CustomSelectInfinityProps,
} from "../../components/shared/SelectInfinity";

export interface SelectFilterProps {
  tagTitle?: string;
  fetchFn?: (params: any, paging: any) => Promise<any>;
  dataKey?: string;
  customQuery?: (search?: string) => any;
  customTagRender?: (props: any, dropdownOpen: boolean) => any;
  valueKey?: string;
}

export const SelectFilter: React.FC<
  Partial<SelectProps> & SelectFilterProps
> = (props) => {
  const {
    value,
    tagTitle,
    onDropdownVisibleChange,
    customTagRender,
    dataSource,
  } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { t } = useTranslation();
  const options = useMemo(() => {
    return dataSource?.map((item) => ({
      ...item,
      label: t(item.label),
    }));
  }, [t, dataSource]);

  return (
    <Select
      // transformer={props.transformer as any}
      selectAll
      tagRender={
        customTagRender
          ? (props) => customTagRender(props, dropdownOpen)
          : (props) => {
              return props?.value === value?.[0] && !dropdownOpen ? (
                <div className="flex items-center">
                  {tagTitle}{" "}
                  <span className="text-white bg-primary ml-1 w-4 h-4 rounded-full inline-block text-center text-xs">
                    {value.length}
                  </span>
                </div>
              ) : (
                <></>
              );
            }
      }
      onDropdownVisibleChange={(open) => {
        setDropdownOpen(open);
        onDropdownVisibleChange && onDropdownVisibleChange(open);
      }}
      {...props}
      dataSource={options}
    />
  );
};

export const SelectInfinityFilter: React.FC<
  Partial<SelectInfinityProps> & SelectFilterProps
> = (props) => {
  const {
    value,
    tagTitle,
    onDropdownVisibleChange,
    fetchFn,
    dataKey,
    customQuery,
    customTagRender,
    valueKey = "refId",
  } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <SelectInfinity
      mode="multiple"
      getLabel={(item) => item?.name || item?.label || "N/A"}
      tagRender={
        customTagRender
          ? (props) => customTagRender(props, dropdownOpen)
          : (props) => {
              return props?.value === value?.[0]?.[valueKey] &&
                !dropdownOpen ? (
                <div className="flex items-center">
                  {tagTitle}{" "}
                  <span className="text-white bg-primary ml-1 w-4 h-4 rounded-full inline-block text-center text-xs">
                    {value.length}
                  </span>
                </div>
              ) : (
                <></>
              );
            }
      }
      onDropdownVisibleChange={(open) => {
        setDropdownOpen(open);
        onDropdownVisibleChange && onDropdownVisibleChange(open);
      }}
      source={(params, paging) => {
        return fetchFn
          ? fetchFn(
              {
                search: params?.search,
                ...(customQuery ? { ...customQuery() } : {}),
              },
              paging
            )
          : Promise.resolve([]);
      }}
      transformer={(res) => {
        const data = dataKey ? res?.data?.data?.[dataKey] : [];
        let transform = [];
        if (data?.length > 0) {
          transform = data.map((item: any) => ({
            ...item,
            value: item?.id,
          }));
        }
        return transform;
      }}
      {...props}
    />
  );
};

export const SelectInfinityFilterMultiple: React.FC<
  Partial<SelectInfinityProps> & CustomSelectInfinityProps & SelectFilterProps
> = (props) => {
  const { value, tagTitle, onDropdownVisibleChange, valueKey } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <CustomSelectInfinity
      {...props}
      tagRender={(props) => {
        return props.value === value?.[0]?.[valueKey || "value"] &&
          !dropdownOpen ? (
          <div className="flex items-center">
            {tagTitle}{" "}
            <span className="text-white bg-primary ml-1 w-4 h-4 rounded-full inline-block text-center text-xs">
              {value.length}
            </span>
          </div>
        ) : (
          <></>
        );
      }}
      onDropdownVisibleChange={(open) => {
        setDropdownOpen(open);
        onDropdownVisibleChange && onDropdownVisibleChange(open);
      }}
    />
  );
};

export default SelectFilter;
