import AppContext from "@helpers/context";
import { getBothLangsText } from "@helpers/locales";
import { Table } from "antd";
import {
  AwesomeTableComponent,
  Button,
  Notifications,
  StringUtils,
  TimeUtils,
} from "d-react-components";
import _, { find, reduce } from "lodash";
import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CashTransactionSelectDrawer from "../component/CashTransactionSelectDrawer";
import { ORDER_STATUS_ARRAY } from "@constants/order";
import { Link, generatePath } from "react-router-dom";
import { Path } from "@components/layout/Path";
import UserAvatarName from "@components/shared/UserAvatarName";
import { ColumnTitle } from "@components/order/detail/content/OrderDetailTable";

const CashDepositDetailTransaction = ({ isPrintView, isShowBothLang }: any) => {
  const { cashDepositDetail, onUpdateCashDeposit } = useContext(AppContext);
  const { t } = useTranslation();
  const { payments = [], channel, store, status } = cashDepositDetail;

  const [openSelectTransaction, setOpenSelectTransaction] = useState(false);

  const columns = (
    isBothLangs: boolean,
    isPrintView: boolean,
    isBrowse?: boolean
  ) => {
    const columnsResult = [
      {
        title: <ColumnTitle title="paymentRef" isShowBothLangs={isBothLangs} />,
        dataIndex: "code",
        render: (code: string) => code,
      },
      {
        title: <ColumnTitle title="amount" isShowBothLangs={isBothLangs} />,
        dataIndex: "total",
        render: StringUtils.moneyThaiFormat,
      },
      {
        title: <ColumnTitle title="status" isShowBothLangs={isBothLangs} />,
        dataIndex: "status",
        render: t,
      },

      {
        title: <ColumnTitle title="createdBy" isShowBothLangs={isBothLangs} />,
        dataIndex: "by",
        render: (by: any) => <UserAvatarName user={by} />,
      },
      {
        title: <ColumnTitle title="createdAt" isShowBothLangs={isBothLangs} />,
        dataIndex: "created",
        render: TimeUtils.toDateTime,
      },
      {
        title: <ColumnTitle title="orderRef" isShowBothLangs={isBothLangs} />,
        dataIndex: "order",
        render: (order: any) => (
          <Link to={generatePath(Path.ORDER_DETAIL, { id: order?.id ?? "" })}>
            {order?.orderid}
          </Link>
        ),
      },
      {
        title: (
          <ColumnTitle title="orderAmount" isShowBothLangs={isBothLangs} />
        ),

        dataIndex: "order",
        render: (order: any) => StringUtils.moneyThaiFormat(order?.total),
      },
      {
        title: (
          <ColumnTitle title="orderStatus" isShowBothLangs={isBothLangs} />
        ),
        dataIndex: "order",
        render: (order: any) => {
          const status =
            find(ORDER_STATUS_ARRAY, (item) => item.id === order?.status)
              ?.label ?? "N/A";
          return t(status);
        },
      },
      {
        title: (
          <ColumnTitle title="orderSalesperson" isShowBothLangs={isBothLangs} />
        ),
        dataIndex: "order",
        render: (order: any) => order?.salesPerson?.name,
      },
      {
        title: (
          <ColumnTitle title="orderCreatedAt" isShowBothLangs={isBothLangs} />
        ),
        dataIndex: "order",
        render: (order: any) => TimeUtils.toDateTime(order?.created),
      },
    ];

    if (isPrintView) {
      columnsResult.splice(2, 1);
      columnsResult.splice(2, 1);
      columnsResult.splice(5, 1);
    }
    if (isBrowse) {
      columnsResult.push({
        title: t("cashDepositRequests"),
        dataIndex: "deposit",
        render: (deposit: any) => deposit?.ref,
      });
    }
    return columnsResult;
  };

  const totalAmount = useMemo(() => {
    return reduce(payments, (sum, item) => sum + item.total, 0);
  }, [payments]);

  const renderRightHeader = () => {
    if (isPrintView) {
      return <div />;
    }
    if (status === "pending-deposit" || status === "pending-confirmation") {
      return (
        <Button variant="trans" onClick={() => setOpenSelectTransaction(true)}>
          {t("edit")}
        </Button>
      );
    }
    return <div />;
  };

  const renderHeader = () => {
    return (
      <div className="flex-row-between-center">
        <text className="label">
          {isShowBothLang ? getBothLangsText("transaction") : t("transaction")}
        </text>
        {renderRightHeader()}
      </div>
    );
  };

  const renderContent = () => {
    if (payments.length === 0) {
      return (
        <div className="text-placeholder emptyText">
          {t("noAnyPaymentAdded")}
        </div>
      );
    }
    return (
      <div className="browsePaymentContainer-content">
        <Table
          columns={columns(isShowBothLang, isPrintView) as any}
          dataSource={payments}
          scroll={{ x: 1300 }}
          tableLayout={"auto"}
          pagination={false}
          className="browsePaymentTable mt-3"
        />
        <div className="flex-row-between-center mt-3">
          <text className="label">
            {isShowBothLang ? getBothLangsText("total") : t("total")}
          </text>
          <text className="label">
            {StringUtils.moneyThaiFormat(totalAmount)}
          </text>
        </div>
      </div>
    );
  };
  return (
    <div className="card-container p-3">
      {renderHeader()}
      {renderContent()}

      <CashTransactionSelectDrawer
        open={openSelectTransaction}
        onClose={() => setOpenSelectTransaction(false)}
        onSave={(transactionSelected: any) => {
          onUpdateCashDeposit({ transactionSelected });
          setOpenSelectTransaction(false);
          return Promise.resolve();
        }}
        channel={channel}
        store={store}
      />
    </div>
  );
};

export default CashDepositDetailTransaction;
