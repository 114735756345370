import React, { useEffect, useMemo, useState } from "react";
import Drawer, { DrawerProps } from "./Drawer";
import { isEmpty, isUndefined } from "lodash";
import { useRootStore } from "../../App";
import UserAPI from "@network/api/user";
import { useTranslation } from "react-i18next";
import UserDetailChannel from "../user/detail/UserDetailChannel";
import UserDetailPersonal from "../user/detail/UserDetailPersonal";
import UserDetailRole from "../user/detail/UserDetailRole";
import UserDetailWarehouse from "../user/detail/UserDetailWarehouse";
import AppContext from "@helpers/context";
import { TabBar } from "d-react-components";
import classNames from "classnames";
import { Spin } from "antd";

const UserInfoModal = () => {
  const userModalId = useRootStore((state) => state.userModalId);
  const setUserModalId = useRootStore((state) => state.setUserModalId);
  const [user, setUser] = useState<any>();
  const [loadingUser, setLoadingUser] = useState(false);
  const { t } = useTranslation();

  const TABS = useMemo(
    () => [
      {
        id: "personal",
        label: t("personal"),
        component: <UserDetailPersonal updatable={false} />,
      },

      {
        id: "role",
        label: t("role"),
        component: <UserDetailRole updatable={false} />,
      },

      {
        id: "channel",
        label: t("channel"),
        component: <UserDetailChannel updatable={false} />,
      },
      {
        id: "warehouse",
        label: t("warehouse"),
        component: <UserDetailWarehouse updatable={false} />,
      },
    ],
    [t]
  );
  const [tab, setTab] = useState<any>(TABS[0]);

  useEffect(() => {
    if (userModalId) {
      loadUserDetail();
    }
  }, [userModalId]);

  const loadUserDetail = () => {
    const body = {
      id: userModalId ?? null,
    };
    setLoadingUser(true);
    UserAPI.detail(body)
      .then((res: any) => {
        const user = res?.data?.data?.user;
        setUser(user);
      })
      .finally(() => {
        setLoadingUser(false);
      });
  };
  const { fullname, avatar } = user ?? {};

  return (
    <Drawer
      open={!isUndefined(userModalId)}
      onClose={() => setUserModalId(undefined)}
      hideFooter
      width={700}
      size="auto"
      destroyOnClose
    >
      <AppContext.Provider value={{ user, loadUserDetail }}>
        {loadingUser && (
          <div className="text-center">
            <Spin />
          </div>
        )}
        <div className="">
          {!isEmpty(user) && !loadingUser && (
            <div className="flex items-center flex-col">
              <img
                src={avatar}
                className="object-fit-cover w-[60px] h-[60px]"
              />
              <h4 className="mb-3">{fullname}</h4>
            </div>
          )}
          <TabBar
            dataSource={TABS}
            onChange={setTab}
            value={tab}
            classNameItem={classNames(
              "bg-white border-right-0 border-left-0 border-top-0"
            )}
          />
          {!isEmpty(user) && !loadingUser && tab.component}
        </div>
      </AppContext.Provider>
    </Drawer>
  );
};

export default UserInfoModal;
