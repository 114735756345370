import { API } from "@network";
import {
  API_CREATE_NEW_CUSTOMER,
  API_CREATE_SHOPE_ADDRESS_SHIPPING,
  API_DELETE_CUSTOMER_ORDER,
  API_EDIT_CUSTOMER_CONTACT,
  API_SEARCH_CUSTOMER,
  API_SHOPE_ADDRESS_SHIPPING_LIST,
  API_SHOPE_ADDRESS_SHIPPING_UPLOAD,
  API_DETAIL_CUSTOMER,
  API_UPLOAD_AVATAR,
  API_SET_DEFAULT_SHIPPING_CUSTOMER,
  API_UPDATE_SHIPPING_CUSTOMER,
  API_DELETE_SHIPPING_CUSTOMER,
  API_SET_DEFAULT_BILLING_CUSTOMER,
  API_UPDATE_BILLING_CUSTOMER,
  API_DELETE_BILLING_CUSTOMER,
  API_CREATE_BILLING_CUSTOMER,
  API_ORDER_HISTORY_CUSTOMER,
  API_CUSTOMER_LIST,
  API_UPDATE_CUSTOMER,
  API_REQUEST_OTP,
  API_VERIFY_OTP,
  API_VERIFY_CUSTOMER_EMAIL,
  API_VERIFY_CUSTOMER_PHONE,
} from "@network/URL";

export const listAllCustomer = (body: any, paging: any) => {
  return API.post(
    `${API_CUSTOMER_LIST}?page=${paging.pageIndex}&per_page=${paging.pageSize}`,
    body
  );
};

export const searchCustomer = (body = {}, paging: any = { page: 1 }) => {
  return API.post(`${API_SEARCH_CUSTOMER}?page=${paging.page}`, body);
};

export const deleteCustomerOrder = (body: any) => {
  return API.post(API_DELETE_CUSTOMER_ORDER, body);
};

export const createNewCustomer = (body: any) => {
  return API.post(API_CREATE_NEW_CUSTOMER, body);
};

export const updateCustomer = (body: any) => {
  return API.post(API_UPDATE_CUSTOMER, body);
};

export const editCustomerContact = (body: any) => {
  return API.post(API_EDIT_CUSTOMER_CONTACT, body);
};

export const createShopeAddressShipping = (refId: string, body: any) => {
  return API.post(`${API_CREATE_SHOPE_ADDRESS_SHIPPING}/${refId}`, body);
};

export const shopeAddressShippingList = (
  body: any,
  paging = { pageIndex: 1, pageSize: 20 }
) => {
  return API.post(
    `${API_SHOPE_ADDRESS_SHIPPING_LIST}?page=${paging.pageIndex}&per_page=${paging?.pageSize}`,
    body
  );
};

export const shopeAddressShippingUpload = (body: any) => {
  return API.post(API_SHOPE_ADDRESS_SHIPPING_UPLOAD, body);
};

export const detailCustomer = (customerid: string) => {
  return API.post(API_DETAIL_CUSTOMER, { customerid });
};

export const uploadCustomerAvatar = (body: any) => {
  return API.post(API_UPLOAD_AVATAR, body);
};

export const getCustomerDetail = (customerId: string) => {
  return API.post(API_DETAIL_CUSTOMER, { customerid: customerId });
};

export const setCustomerShippingDefault = (
  customerid: string,
  shippingid: string
) => {
  const body = { customerid, shippingid };
  return API.post(API_SET_DEFAULT_SHIPPING_CUSTOMER, body);
};

export const updateCustomerShipping = (body: any) => {
  return API.post(API_UPDATE_SHIPPING_CUSTOMER, body);
};

export const removeCustomerShipping = (customerid: any, shippingid: any) => {
  const body = { customerid, shippingid };
  return API.post(API_DELETE_SHIPPING_CUSTOMER, body);
};

export const createCustomerBilling = (body: any) => {
  return API.post(API_CREATE_BILLING_CUSTOMER, body);
};

export const setCustomerBillingDefault = (
  customerid: string,
  billingid: string
) => {
  const body = { customerid, billingid };
  return API.post(API_SET_DEFAULT_BILLING_CUSTOMER, body);
};

export const updateCustomerBilling = (body: any) => {
  return API.post(API_UPDATE_BILLING_CUSTOMER, body);
};

export const removeCustomerBilling = (customerid: any, billingid: any) => {
  const body = { customerid, billingid };
  return API.post(API_DELETE_BILLING_CUSTOMER, body);
};

export const getCustomerOrderHistory = (customerid: string, paging: any) => {
  return API.post(
    `${API_ORDER_HISTORY_CUSTOMER}?page=${paging.pageIndex}&per_page=${paging.pageSize}`,
    {
      customerid,
    }
  );
};

export const requestOTP = (body: any) => {
  return API.post(API_REQUEST_OTP, body);
};

export const verifyOTP = (body: any) => {
  return API.post(API_VERIFY_OTP, body);
};

export const verifyCustomerEmail = (body: any) => {
  return API.post(API_VERIFY_CUSTOMER_EMAIL, body);
};

export const verifyCustomerPhone = (body: any) => {
  return API.post(API_VERIFY_CUSTOMER_PHONE, body);
};
