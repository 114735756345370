import ButtonFileUpload from "@components/shared/ButtonFileUpload";
import DocumentDropZone from "@components/shared/DocumentDropZone";
import { FILE_FORMAT_PROMOTION, IMAGE_FORMAT } from "@constants/file";
import AppContext from "@helpers/context";
import React, { useContext, useRef } from "react";

const PromotionCreateDocuments = () => {
  const { promotionForm } = useContext(AppContext);
  const buttonUploadRef = useRef<any>(null);
  const { values, setFieldValue } = promotionForm;
  const enableUpload = true;
  return (
    <div className="bg-white p-3">
      <ButtonFileUpload
        onChange={(value: any) => setFieldValue("attachments", value)}
        containerClassName="d-flex flex-column"
        ref={buttonUploadRef}
        inputParam={{ accept: `${IMAGE_FORMAT},${FILE_FORMAT_PROMOTION}` }}
        classNameItem="!w-24 !h-24 bg-black"
        classNameImage="!object-contain"
        defaultFiles={values.attachments}
        // uploadAfterSelect={false}
        renderButton={(
          getRootProps: any,
          getInputProps: any,
          inputParam: any
        ) =>
          enableUpload ? (
            <DocumentDropZone
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              inputParam={inputParam}
            />
          ) : null
        }
      />
    </div>
  );
};

export default PromotionCreateDocuments;
