/* eslint-disable jsx-a11y/alt-text */
import DeliveryDetailConfirmedView from "@components/delivery-tstock/detail/DeliveryStockConfirmedView";
import SignatureField from "@components/order/components/pickup/SignatureField";
import ProductName from "@components/product/components/ProductName";
import QuantityInputField from "@components/product/components/QuantityInputField";
import ButtonFileUpload from "@components/shared/ButtonFileUpload";
import Drawer from "@components/shared/Drawer";
import { DELIVERY_STATUS } from "@constants/delivery";
import AppContext from "@helpers/context";
import { Tabs } from "antd";
import {
  AwesomeTableComponent,
  Button,
  InputText,
  Notifications,
  ObjectUtils,
} from "d-react-components";
import { every, isEmpty, map, reduce } from "lodash";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs;

const DeliveryDriverConfirmModal = ({ open, onClose }: any) => {
  const { deliveryDetail, loadDeliveryDetail } = useContext(AppContext);
  const { transfer_stock_product } = deliveryDetail;

  const totalItems = reduce(
    transfer_stock_product,
    (sum, item) => sum + item.quantity,
    0
  );

  const INIT_DRIVER_INFO = {
    signature: null,
    attachments: [],
    remark: "",
  };
  const INIT_SUPERVISOR_INFO = {
    signature: null,
    fullName: "",
  };

  const { t } = useTranslation();

  const columns = [
    {
      title: t("itemName"),
      dataIndex: "",
      render: (product: any) => <ProductName item={{ product }} />,
    },
    {
      title: t("toReceive"),
      dataIndex: "pickupQty",
      render: (pickupQty: any, product: any) => (
        <QuantityInputField
          quantity={pickupQty}
          onChangeQuantity={(pickupQtyParam: any) => {
            if (pickupQtyParam < 0) return;
            onChangeQuantity({ ...product, pickupQty: pickupQtyParam });
          }}
        />
      ),
    },
  ];

  const CONFIG_RECEIVE_STOCK = {
    id: "receive",
    title: t("receiveStocks"),
    okText: t("next"),
  };

  const CONFIG_COMPLETE_STOCK = {
    id: "complete",
    title: t("completeStockReceiving"),
    okText: t("confirm"),
  };

  const [CONFIG, setConfig] = useState(CONFIG_RECEIVE_STOCK);
  const [driverInfo, setDriverInfo] = useState(INIT_DRIVER_INFO);
  const [supervisorInfo, setSupervisorInfo] = useState(INIT_SUPERVISOR_INFO);
  const [productsPickup, setProducts] = useState<any[]>(
    map(transfer_stock_product, (item) => ({ ...item, pickupQty: 0 }))
  );

  const totalPickupQty = useMemo(
    () => reduce(productsPickup, (sum, item) => sum + item.pickupQty, 0),
    [productsPickup]
  );

  const onChangeDriveInfo = (newData: any) => {
    setDriverInfo({ ...driverInfo, ...newData });
  };

  const onChangeSupervisorInfo = (newData: any) => {
    setSupervisorInfo({ ...supervisorInfo, ...newData });
  };

  const onChangeQuantity = (newProduct: any) => {
    const result = ObjectUtils.updateArrayById(
      productsPickup as any,
      newProduct
    );
    setProducts(result);
  };

  const unValidate = () => {
    const { attachments, remark } = driverInfo;
    const { fullName } = supervisorInfo;
    if (!driverInfo.signature || !supervisorInfo.signature) {
      Notifications.showError(t("needBothPartiesSignature"));
      return true;
    }
    if (attachments.length === 0) {
      Notifications.showError(t("attachmentIsRequire"));
      return true;
    }
    if (isEmpty(remark) || isEmpty(fullName)) {
      Notifications.showError(t("needAllRequiredInfo"));
      return true;
    }
    return false;
  };

  const onClickNextReceiveStock = () => {
    const isMapQuantity = every(
      productsPickup,
      (proItem) => proItem.quantity === proItem.pickupQty
    );

    if (totalPickupQty !== totalItems || !isMapQuantity) {
      Notifications.showError(t("totalNumberItemsNotRight"));
      return;
    }

    setConfig(CONFIG_COMPLETE_STOCK);
  };

  const onClickCompleteStock = () => {
    const { attachments, remark } = driverInfo;
    const { fullName } = supervisorInfo;
    if (unValidate()) {
      return;
    }
    const body = {
      id: deliveryDetail?.id,
      driver_signature_at_warehouse: driverInfo.signature,
      supervisor_signature_at_warehouse: supervisorInfo.signature,
      supervisor_fullname_at_warehouse: fullName,
      remark_at_warehouse: remark,
      proof_at_warehouse: map(attachments, (item: any) => item.key),
    };
    // const APIConfirm = [{ method: driverConfirmDelivery, params: [body] }];
    // Progress.show(APIConfirm, () => {
    //   Notifications.showSuccess(t("confirmDeliverySuccess"));
    //   onClose();
    //   loadDeliveryDetail && loadDeliveryDetail();
    // });
  };

  const onClickConfirm = () => {
    switch (CONFIG.id) {
      case "receive":
        return onClickNextReceiveStock();
      default:
        return onClickCompleteStock();
    }
  };

  const renderConfigCompleteStock = () => {
    return (
      <Tabs defaultActiveKey="1">
        <TabPane tab={t("driver")} key="1">
          {driverInfo.signature && (
            <img
              className="w-100"
              src={driverInfo.signature}
              style={{ height: 250, objectFit: "contain" }}
            />
          )}
          <SignatureField
            className="w-min-content"
            onSave={(signature: any) => onChangeDriveInfo({ signature })}
          />

          <div className="mt-3 label">{t("proof")}</div>
          <ButtonFileUpload
            containerClassName="mt-3"
            onChange={(attachments: any) => onChangeDriveInfo({ attachments })}
          />

          <InputText
            className="mt-3"
            label={t("remark")}
            multiple
            value={driverInfo.remark}
            onChange={(event) =>
              onChangeDriveInfo({ remark: event.target.value })
            }
            placeholder={t("pleaseInputTheNoteForThisUpdate")}
          />
        </TabPane>
        <TabPane tab={t("supervisor")} key="2">
          {supervisorInfo.signature && (
            <img
              className="w-100"
              src={supervisorInfo.signature}
              style={{ height: 250, objectFit: "contain" }}
            />
          )}
          <SignatureField
            className="w-min-content"
            onSave={(image: any) =>
              onChangeSupervisorInfo({ signature: image })
            }
          />
          <InputText
            className="mt-3"
            label={t("fullName")}
            onChange={(event) =>
              onChangeSupervisorInfo({ fullName: event.target.value })
            }
            value={supervisorInfo.fullName}
          />
        </TabPane>
      </Tabs>
    );
  };

  const renderConfigReceiveStock = () => (
    <AwesomeTableComponent
      dataSource={productsPickup}
      columns={columns}
      pagination={false}
    />
  );

  const renderContent = () => {
    switch (CONFIG.id) {
      case "receive":
        return renderConfigReceiveStock();
      default:
        return renderConfigCompleteStock();
    }
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      onSave={onClickConfirm}
      saveText={CONFIG.okText}
      size="x-large"
      title={CONFIG.title}
    >
      <div>{renderContent()}</div>
    </Drawer>
  );
};

const DeliverySODetailOrigin = () => {
  const { deliveryDetail } = useContext(AppContext);
  const { status, confirmationOrigin } = deliveryDetail;

  const [openLoadProduct, setOpenLoadProduct] = useState(false);

  const isDeliveryConfirmed =
    status === DELIVERY_STATUS.IN_DELIVERY ||
    status === DELIVERY_STATUS.COMPLETED;

  const isCancelled = status === DELIVERY_STATUS.CANCELLED;
  const isPending = status === DELIVERY_STATUS.PENDING;

  const { t } = useTranslation();

  const renderMainContent = () => {
    switch (status) {
      case DELIVERY_STATUS.BOOKING:
        return renderConfirmPending();
      case DELIVERY_STATUS.IN_DELIVERY:
      case DELIVERY_STATUS.COMPLETED:
        return (
          <DeliveryDetailConfirmedView confirmation={confirmationOrigin} />
        );
      default:
        return <div />;
    }
  };
  const renderConfirmPending = () => {
    return (
      <div>
        <div className="small mt-3">{t("originPickupConfirmation")}</div>
        <Button className="mt-3" onClick={() => setOpenLoadProduct(true)}>
          {t("startLoading")}
        </Button>
      </div>
    );
  };

  if (isCancelled || isPending) return <div />;

  return (
    <div className="card-container mt-4 p-4">
      <div className="d-flex justify-content-between align-items-center">
        <label>{t("originPickupConfirm")}</label>
        {isDeliveryConfirmed && (
          <div className="text-white bg-success px-3 py-2">
            {t("confirmed")}
          </div>
        )}
      </div>
      {renderMainContent()}
      {openLoadProduct && (
        <DeliveryDriverConfirmModal
          open={openLoadProduct}
          onClose={() => setOpenLoadProduct(false)}
        />
      )}
    </div>
  );
};

export default DeliverySODetailOrigin;
