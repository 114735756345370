import { API } from "@network";
import {
  API_PRINT_PRODUCT_DETAIL,
  API_PRINT_PRODUCT_LIST,
  API_PRINT_PRODUCT_LOGS,
  API_PRODUCT_LABEL_IMPORT,
  API_PRODUCT_LABEL_IMPORT_FIELDS,
  API_PRODUCT_LABEL_IMPORT_VALIDATE,
} from "../URL";

const ProductLabelAPI = {
  getPrintProductLabelList: (body: any, paging: any) => {
    return API.post(
      `${API_PRINT_PRODUCT_LIST}?page=${paging.pageIndex}&per_page=${paging.pageSize}`,
      body
    );
  },
  getPrintProductLabelDetail: (id: any) => {
    return API.post(API_PRINT_PRODUCT_DETAIL, { id });
  },
  getPrintProductLabelLogs: (id: any) => {
    return API.post(API_PRINT_PRODUCT_LOGS, { id });
  },

  getImportFields: (body: any) => {
    return API.post(API_PRODUCT_LABEL_IMPORT_FIELDS, body);
  },

  validateImport: (body: any) => {
    return API.post(API_PRODUCT_LABEL_IMPORT_VALIDATE, body);
  },

  importProductLabel: (body: any) => {
    return API.post(API_PRODUCT_LABEL_IMPORT, body);
  },
};

export default ProductLabelAPI;
