import { API } from "@network";
import { API_LIST_REFUND, API_RAW_MATERIAL_LIST } from "../URL";

const RefundRequestAPI = {
  list: (body: any, paging: any) => {
    return API.post(`${API_LIST_REFUND}?page=${paging?.pageIndex}`, body);
  },
};

export default RefundRequestAPI;
