import { Path } from "@components/layout/Path";
import { CART_RULE_ACTION } from "@constants/cart-rule";
import AppContext from "@helpers/context";
import CartRuleAPI from "@network/api/cart-rule";
import { Button, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import { forEach, isEmpty, map, now, some } from "lodash";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import CartRuleCreateAction from "./CartRuleCreateAction";
import CartRuleCreateCondition from "./CartRuleCreateCondition";
import CartRuleCreateConfiguration from "./CartRuleCreateConfiguration";
import CartRuleCreateInfo from "./CartRuleCreateInfo";

const CartRuleSchema = Yup.lazy((value) => {
  let schema = Yup.object().shape({
    title: Yup.string().required("Title required!"),
    description: Yup.string().required("Description required!"),
  });

  return schema;
});

export const transferCondition: any = (conditionList: any[]) => {
  if (isEmpty(conditionList)) {
    return conditionList;
  }
  const cloneList = map(conditionList, (condition: any) => {
    const conditionType = condition.condition;
    const { rule } = condition;
    switch (conditionType) {
      case "product":
        const productClone = map(condition[conditionType], (item) => ({
          id: item?.productid,
          quantity: item.quantity,
        }));
        return {
          condition: conditionType,
          rule,
          [conditionType]: productClone,
        };
      case "category":
      case "brand":
        const clone = map(condition[conditionType], (item) =>
          item?.id ? item.id : item?.productid
        );
        return { condition: conditionType, rule, [conditionType]: clone };
      case "total":
      case "subtotal":
        return {
          condition: conditionType,
          rule,
          [conditionType]: condition[conditionType],
        };
      case "customer":
        const cloneCustomer = map(
          condition?.[conditionType],
          (item) => item.id
        );
        return {
          condition: conditionType,
          rule,
          [conditionType]: cloneCustomer,
        };
      case "store":
        const cloneStore = map(condition?.[conditionType], (item) => item.id);
        return {
          condition: conditionType,
          rule,
          [conditionType]: cloneStore,
        };
      case "combination":
        const cloneList = transferCondition(condition.conditions);
        return { condition: conditionType, rule, conditions: cloneList };
      default:
        return {};
    }
  });
  return cloneList;
};

const validateFreeGiftActions = (actions: any) => {
  const { gift = [], recursive, buy, free, range, bundle } = actions?.free_gift;

  const isProductBundleEmpty = some(
    bundle,
    (bundleItem) => !bundleItem.product || bundleItem.product.length === 0
  );
  if (!bundle || bundle.length === 0 || isProductBundleEmpty) {
    return {
      validate: false,
      message: "You must select bundle",
    };
  }
  const isProductGiftEmpty = some(
    gift,
    (giftItem) => !giftItem.product || giftItem.product.length === 0
  );
  if (!gift || gift.length === 0 || isProductGiftEmpty) {
    return {
      validate: false,
      message: "youMustSelectItemsToBeFreeGift",
    };
  }

  if (recursive && buy === 0 && free === 0) {
    return {
      validate: false,
      message: "buyOrFreeCanNotBeZero",
    };
  }

  if (!recursive && range.length === 0) {
    return {
      validate: false,
      message: "rangeCanNotBeEmpty",
    };
  }
  return {
    validate: true,
  };
};

export const transferValidateActions: any = (actions: any) => {
  if (!actions?.specific) {
    return { validate: false, message: "actionsFieldIsRequired" };
  }
  const resultActions = { ...actions }; //clone an action
  const {
    specific,
    discount_fix_amount,
    discount_percentage,
    buy_x_get_y,
    conditions,
    free_gift,
    buy_x_get_x,
    discount_percentage_cart,
    discount_fix_amount_cart,
  } = actions;
  const { amount, buy, minQuantity, maxQuantity, product } = actions[specific];
  /**
   * for actions:
   *  buy_x_get_discount_percentage,
      buy_x_get_discount_fix_amount,
      buy_x_get_discount_percentage_y,
   */
  if (
    specific === CART_RULE_ACTION.BUY_X_GET_DISCOUNT_PERCENTAGE ||
    specific === CART_RULE_ACTION.BUY_X_GET_DISCOUNT_FIX_AMOUNT ||
    specific === CART_RULE_ACTION.BUY_X_GET_DISCOUNT_FIX_AMOUNT_Y ||
    specific === CART_RULE_ACTION.BUY_X_GET_DISCOUNT_PERCENTAGE_Y
  ) {
    if (buy === 0 || amount === 0) {
      return {
        validate: false,
        message: "buyOrAmountCanNotBeZero",
      };
    }

    if (minQuantity > maxQuantity) {
      return {
        validate: false,
        message: "maxQuantityMustGreaterThanMin",
      };
    }
  }
  if (
    specific === CART_RULE_ACTION.BUY_X_GET_DISCOUNT_PERCENTAGE ||
    specific === CART_RULE_ACTION.BUY_X_GET_DISCOUNT_PERCENTAGE_Y
  ) {
    if (amount > 100) {
      return {
        validate: false,
        message: "amountCanNotGreaterThan100",
      };
    }
  }
  if (
    specific === CART_RULE_ACTION.BUY_X_GET_DISCOUNT_FIX_AMOUNT_Y ||
    specific === CART_RULE_ACTION.BUY_X_GET_DISCOUNT_PERCENTAGE_Y
  ) {
    if (!product || product.length === 0) {
      return {
        validate: false,
        message: "youMustSelectItemsToBeFreeGift",
      };
    }
  }

  /**
   * for other action
   */
  switch (specific) {
    case CART_RULE_ACTION.FREE_GIFT:
      const validateFreeGift = validateFreeGiftActions(actions);
      if (!validateFreeGift.validate) {
        return validateFreeGift;
      }

      break;
    case CART_RULE_ACTION.BUY_X_GET_X:
      const { recursive, buy, free, range } = buy_x_get_x;
      if (recursive && (buy === 0 || free === 0)) {
        return {
          validate: false,
          message: "buyOrFreeCanNotBeZero",
        };
      }

      if (!recursive && range.length === 0) {
        return {
          validate: false,
          message: "rangeCanNotBeEmpty",
        };
      }
      break;

    //   break;
    case CART_RULE_ACTION.DISCOUNT_FIX_AMOUNT:
      if (discount_fix_amount.amount === 0) {
        return {
          validate: false,
          message: "discountAmountMustNotBeZero",
        };
      }
      break;
    case CART_RULE_ACTION.DISCOUNT_FIX_AMOUNT_CART:
      if (discount_fix_amount_cart.amount === 0) {
        return {
          validate: false,
          message: "discountAmountMustNotBeZero",
        };
      }
      break;
    case CART_RULE_ACTION.DISCOUNT_PERCENTAGE:
      if (discount_percentage.percentage === 0) {
        return {
          validate: false,
          message: "discountAmountMustNotBeZero",
        };
      }
      break;

    case CART_RULE_ACTION.DISCOUNT_PERCENTAGE_CART:
      if (discount_percentage_cart.percentage === 0) {
        return {
          validate: false,
          message: "discountPerMustNotBeZero",
        };
      }

      break;
    default:
  }

  resultActions.conditions = transferCondition(conditions);

  return { resultActions, validate: true };
};

const CartRuleCreate = (props: any) => {
  const FORM_INIT_VALUE = {
    title: "",
    description: "",
    status: null,

    selectedChannel: "",
    period_from: now(),
    period_to: null,
    coupon: "specific_coupon",
    priority: 999,
    usage_limit: 0,
    usage_customer: 0,
    condition_rule: "all",
    conjunctional: false,

    code: [],
    existingCode: [],

    conditions: [],

    actions: {
      specific: null,
      gift: [],
      condition_type: "all",
    },

    configQuantityType: "single",
  };

  const { t } = useTranslation();
  const navigate = useNavigate();

  const location = useLocation();
  const { cartRuleDefault } = location.state ?? {};

  const defaultValueForm = () => {
    if (!cartRuleDefault) {
      return FORM_INIT_VALUE;
    }
    return cartRuleDefault;
  };

  const cartRuleForm = useFormik<any>({
    initialValues: defaultValueForm(),
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: CartRuleSchema,
    onSubmit: (values: any) => {
      onCreate(values);
    },
  });
  const formValues = cartRuleForm?.values;
  const formErrors = cartRuleForm?.errors;

  useEffect(() => {
    forEach(Object.keys(cartRuleForm.errors), (key) =>
      Notifications.showError(`${(cartRuleForm.errors as any)?.[key]}`)
    );
  }, [cartRuleForm.errors]);

  const onCreate = async (values: any) => {
    const {
      actions,
      conditions,
      code,
      conjunctional,
      condition_rule,
      usage_customer,
      usage_limit,
      priority,
      coupon,
      period_to,
      period_from,
      description,
      title,
      selectedChannel,
      existingCode,
      status,
      setChangeData,
      setChangeDatas,
      configQuantityType,
    } = values;
    const rule = {
      channel: selectedChannel?.id,
      period_from,
      period_to,
      coupon,
      priority,
      usage_limit,
      usage_customer,
      conjunctional,
      code,
      conditions: condition_rule,
    };

    const { validate, message, resultActions } =
      transferValidateActions(actions);

    if (!validate) {
      Notifications.showError(message);
      return;
    }

    if (coupon !== "no_coupon" && !usage_limit && usage_limit != 0) {
      Notifications.showError(t("pleaseFillAllRequireField"));
      return;
    }

    if (isEmpty(rule?.channel)) {
      Notifications.showError(t("channelFieldIsRequired"));
      return;
    }

    if (
      (rule.coupon === "auto_generated_coupons" ||
        rule.coupon === "specific_coupon") &&
      isEmpty(code)
    ) {
      // if ((rule.coupon === autoGenerateCoupon || rule.coupon === specificCoupon) && _.isEmpty(code)) {
      Notifications.showError(t("pleaseFillAllRequireField"));
      return;
    }

    const cloneConditions = transferCondition(conditions);
    const body = {
      title,
      description,
      rule,
      conditions: cloneConditions,
      actions: resultActions,
      status,
      quantity: configQuantityType,
    };

    Progress.show(
      { method: CartRuleAPI.create, params: [body] },
      (res: any) => {
        Notifications.showSuccess(t("createCartRuleSuccess"));
        const cartRuleId = res?.data?.data?.rule;
        navigate(generatePath(Path.CART_RULE_DETAIL, { cartRuleId }));
      }
    );
  };

  return (
    <AppContext.Provider
      value={{
        cartRuleForm,
      }}
    >
      <div className="p-3 bg-white flex-center justify-content-between border-bottom">
        <h4 className="text-primary">{t("newCartRule")}</h4>
        <Button
          onClick={() => {
            cartRuleForm.handleSubmit();
          }}
          className="ml-3"
        >
          {t("publish")}
        </Button>
      </div>
      <div className="p-3 bg-white">
        <CartRuleCreateInfo />
        <CartRuleCreateConfiguration />
        <CartRuleCreateCondition />
        <CartRuleCreateAction />
      </div>
    </AppContext.Provider>
  );
};

export default CartRuleCreate;
