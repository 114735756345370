import { Switch } from "antd";
import classNames from "classnames";
import {
  Avatar,
  AwesomeTableComponent,
  Button,
  Icon,
  InputText,
  Notifications,
  Progress,
  Select,
} from "d-react-components";
import { SelectInfinity } from "@components/shared/SelectInfinity";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ADMIN_AUTH_KEY } from "@constants";
import { useLocalStorage } from "react-use";
import NewRequestUpdateChannel from "./NewRequestUpdateChannel";
import NewRequestUpdateWarehouse from "./NewRequestUpdateWarehouse";
import moment from "moment";
import MyAssetsAPI from "@network/api/my-assets";
import { Path } from "@components/layout/Path";
import ChannelList from "./ChannelList";
import WarehouseList from "./WarehouseList";
import UserAPI, { listAllUser } from "@network/api/user";
import AppContext from "@helpers/context";
import ButtonFileUpload from "@components/shared/ButtonFileUpload";
import { FILE_FORMAT, IMAGE_FORMAT } from "@constants/file";
import UserNameDropdown from "@components/shared/UserNameDropdown";

const NewRequest = () => {
  const [admin, setAdminInfo] = useLocalStorage<any>(ADMIN_AUTH_KEY, {});
  const { loadSummaryData } = useContext(AppContext);
  const { t } = useTranslation();
  const { type } = useParams();
  const navigate = useNavigate();
  const [currentUpdateItem, setCurrentUpdateItem] = useState();
  const [updateItemType, setUpdateItemType] = useState();
  const [itemToAdd, setItemToAdd] = useState<any[]>([]);
  const [itemToRemove, setItemToRemove] = useState<any[]>([]);
  const [itemRespectiveToAdd, setItemRespectiveToAdd] = useState<any[]>([]);
  const [itemRespectiveToRemove, setItemRespectiveToRemove] = useState<any[]>(
    []
  );

  const TYPE_OF_CHANGE_REQUEST = [
    {
      id: "warehouse",
      label: t("updateStore"),
    },
    {
      id: "channel",
      label: t("updateChannel"),
    },
    // {
    //   id: "updateRole",
    //   label: "Update role",
    // },
  ];

  const requestForm = useFormik<any>({
    initialValues: {
      type_of_request: type,
      request_for_id: [admin],
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      type_of_request: Yup.string().required(t("fieldRequired")),
    }),
    onSubmit: (values) => {
      let body = {};
      if (isEmpty(itemToAdd) && isEmpty(itemToRemove)) {
        Notifications.showError(
          values.type_of_request === "warehouse"
            ? t("notification:youMustSelectAtLeastOneStore")
            : t("notification:youMustSelectAtLeastOneChannel")
        );
        return;
      }
      if (values.type_of_request === "warehouse") {
        body = {
          type_of_request: "store",
          remark: values.remark,
          ...(values.request_for_id
            ? {
                request_for_id: values.request_for_id?.[0]?.id,
              }
            : {}),
          store: [
            ...itemToAdd?.map((item) => ({
              store_id: item.id,
              // start: moment(item.start).format("YYYY-MM-DD"),
              // end: "",
              action: "add",
            })),
            ...itemToRemove?.map((item) => ({
              store_id: item.id,
              // start: "",
              // end: moment(item.end).format("YYYY-MM-DD"),
              action: "remove",
            })),
          ],
          channel: [
            ...itemRespectiveToAdd?.map((item) => ({
              channel_id: item.id,
              action: "add",
            })),
            ...itemRespectiveToRemove?.map((item) => ({
              channel_id: item.id,
              action: "remove",
            })),
          ],
          attachment: values?.attachment?.map((a: any) => a.key) ?? [],
        };
      }
      if (values.type_of_request === "channel") {
        body = {
          type_of_request: "channel",
          remark: values.remark,
          ...(values.request_for_id
            ? {
                request_for_id: values.request_for_id?.[0]?.id,
              }
            : {}),
          channel: [
            ...itemToAdd?.map((item) => ({
              channel_id: item.id,
              action: "add",
            })),
            ...itemToRemove?.map((item) => ({
              channel_id: item.id,
              action: "remove",
            })),
          ],
          attachment: values?.attachment?.map((a: any) => a.key) ?? [],
        };
      }
      Progress.show(
        { method: MyAssetsAPI.createRequest, params: [body] },
        (resp: any) => {
          Notifications.showSuccess(t("createRequestSuccessfully"));
          navigate(
            generatePath(Path.MY_REQUEST_DETAIL, {
              id: resp?.data?.data?.asset?.id,
            })
          );
          loadSummaryData();
        }
      );
    },
  });

  const dataSource: any[] = [
    {
      id: 1,
      selected: false,
      type: {
        id: "add",
        label: "add",
      },
    },
    {
      id: 2,
      selected: false,
      type: {
        id: "remove",
        label: "remove",
      },
    },
  ];
  const [listChanges, setListChanges] = useState(dataSource);
  const [showListEdit, setShowListEdit] = useState<any>({ open: false });
  const { values, errors, setFieldValue, handleSubmit } = requestForm;

  const columns = useMemo(
    () => [
      {
        title: t("display"),
        dataIndex: "selected",
        width: 100,
        render: (data: boolean, item: any) => (
          <Switch
            checked={data}
            onClick={() =>
              setListChanges(
                listChanges.map((change) =>
                  change.id === item.id
                    ? { ...item, selected: !item.selected }
                    : change
                )
              )
            }
          />
        ),
      },
      {
        title: t("typeOfChange"),
        dataIndex: "type",
        render: (data: any) =>
          `${t(data.label)} ${t(values.type_of_request as string)}`,
      },
      {
        title: "Detail of change",
        dataIndex: "channels",
        render: (data: any[], item: any) => {
          const listData = item?.type?.id === "add" ? itemToAdd : itemToRemove;
          return (
            <>
              <div
                className="flex items-center text-primary cursor-pointer"
                onClick={() => setShowListEdit({ open: true, item })}
              >
                {t(values.type_of_request)}{" "}
                <span className="ml-1 bg-red-500 text-white text-[10px] p-1 rounded-full inline-block w-[16px] h-[16px] flex items-center justify-center">
                  {listData?.length}
                </span>
              </div>
              {showListEdit.open &&
                values.type_of_request === "channel" &&
                item?.type?.id === showListEdit?.item?.type?.id && (
                  <ChannelList
                    open={showListEdit.open}
                    onClose={() => setShowListEdit({ open: false })}
                    type={item?.type?.id}
                    channelList={listData}
                  />
                )}
              {showListEdit.open &&
                values.type_of_request === "warehouse" &&
                item?.type?.id === showListEdit?.item?.type?.id && (
                  <WarehouseList
                    open={showListEdit.open}
                    onClose={() => setShowListEdit({ open: false })}
                    type={item?.type?.id}
                    warehouseList={listData}
                  />
                )}
            </>
          );
        },
      },
      {
        title: "Action",
        dataIndex: "",
        width: 100,
        render: (data: any[], item: any) => (
          <Icon
            className={classNames(
              { "text-primary cursor-pointer": item.selected },
              { "text-blue-100": !item.selected }
            )}
            name="edit"
            onClick={() => {
              if (item.selected) {
                setCurrentUpdateItem(item);
                setUpdateItemType(item.type.id);
              }
            }}
          />
        ),
      },
    ],
    [
      t,
      listChanges,
      itemToAdd,
      itemToRemove,
      values.type_of_request,
      showListEdit,
    ]
  );

  useEffect(() => {
    setItemToAdd([]);
    setItemToRemove([]);
  }, [values.type_of_request, values?.request_for_id]);

  return (
    <div className="bg-white p-3">
      <span
        className="mb-3 inline-block cursor-pointer flex items-center"
        onClick={() => navigate(-1)}
      >
        <Icon name="chevron_left" /> {t("back")}
      </span>
      <h5 className="text-primary">{t("requestChange")}</h5>

      <div className="mt-3">
        <SelectInfinity
          // multiple
          // mode="tags"
          additionalOptions={[admin]}
          value={values?.request_for_id}
          placeholder={t("search")}
          fetchFn={(body: any, paging: any) => {
            // return UserAPI.listAllUser(
            //   { ...body, id: admin.id, action: "in" },
            //   paging
            // );
            return listAllUser(body, {
              pageIndex: paging.page,
              pageSize: paging.per_page,
            });
          }}
          dataPath="users"
          onChange={(val) => {
            setFieldValue("request_for_id", val);
          }}
          getLabel={(item) =>
            `${item?.name}${
              item?.employee_id || item?.companyId
                ? ` (ID: ${item?.employee_id || item?.companyId})`
                : ""
            }`
          }
          getLabelDropdownItem={(item) => <UserNameDropdown item={item} />}
          className="mb-3"
          label={t("requestFor")}
        />
        <Select
          label={t("yourCurrentRole")}
          value={admin?.roles?.[0]?.id}
          className="mb-3"
          disabled
          getLabel={(item) => item.name}
          getValue={(item) => item.id}
          dataSource={admin?.roles}
        />
        <Select
          label={t("typeOfChangeRequest")}
          className="mb-3"
          dataSource={TYPE_OF_CHANGE_REQUEST}
          value={values.type_of_request}
          error={errors.type_of_request}
          onChange={(val) => setFieldValue("type_of_request", val)}
        />
        {values.type_of_request && (
          <>
            <label className="text-label">{t("detailOfChange")}</label>
            <AwesomeTableComponent
              dataSource={listChanges}
              columns={columns}
              tableLayout="fixed"
              className="mb-3"
              pagination={false}
            />
          </>
        )}
        <div className="mb-3">
          <label>{t("documentsOptional")}</label>
          <ButtonFileUpload
            //   uploadedFiles={formValues?.attachments}
            onChange={(values: any) => setFieldValue("attachment", values)}
            maxFiles={5}
            inputParam={{
              accept: `${IMAGE_FORMAT},${FILE_FORMAT}`,
            }}
          />
        </div>
        <InputText
          multiple
          label={t("remark")}
          className="mb-3"
          value={values.remark}
          onChange={(e) => setFieldValue("remark", e?.target?.value)}
        />
        <Button onClick={() => handleSubmit()}>{t("submitRequest")}</Button>
      </div>
      {values.type_of_request === "channel" && !isEmpty(currentUpdateItem) && (
        <NewRequestUpdateChannel
          item={values?.request_for_id?.[0]}
          itemToAdd={itemToAdd}
          itemToRemove={itemToRemove}
          open={!isEmpty(currentUpdateItem)}
          onClose={() => setCurrentUpdateItem(undefined)}
          action={updateItemType as any}
          onSave={(items: any) => {
            if (updateItemType === "add") {
              setItemToAdd(items);
            } else {
              setItemToRemove(items);
            }
            setCurrentUpdateItem(undefined);
          }}
        />
      )}
      {values.type_of_request === "warehouse" &&
        !isEmpty(currentUpdateItem) && (
          <NewRequestUpdateWarehouse
            item={values?.request_for_id?.[0]}
            itemToAdd={itemToAdd}
            itemToRemove={itemToRemove}
            open={!isEmpty(currentUpdateItem)}
            onClose={() => setCurrentUpdateItem(undefined)}
            action={updateItemType as any}
            onSave={(items: any) => {
              if (updateItemType === "add") {
                setItemToAdd(items.stores);
                setItemRespectiveToAdd(items.channels);
              } else {
                setItemToRemove(items.stores);
                setItemRespectiveToRemove(items.channels);
              }
              setCurrentUpdateItem(undefined);
            }}
          />
        )}
    </div>
  );
};

export default NewRequest;
