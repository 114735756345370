import AppContext from "@helpers/context";
import { Progress, StringUtils, TabBar } from "d-react-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { PRODUCT_WARRANTY } from "@constants/product";
import { getProductDetail, getProductPrice } from "@network/api/product";
import { map, split } from "lodash";
import ProductDetailHeader from "./ProductDetailHeader";
import ProductDetailGeneral from "./ProductDetailGeneral";
import ProductDetailInfo from "./ProductDetailInfo";
import classNames from "classnames";
import ProductDetailGallery from "./ProductDetailGallery";
import ProductDetailConfiguration from "./ProductDetailConfiguration";
import ProductDetailPricing from "./ProductDetailPricing";
import ProductDetailSku from "./ProductDetailSku";
import ProductDetailWarranty from "./ProductDetailWarranty";
import ProductDetailShipping from "./ProductDetailShipping";
import ProductDetailInventory from "./ProductDetailInventory";
import ProductDetailAttribute from "./ProductDetailAttribute";
import ProductDetailRental from "./ProductDetailRental";
import ProductDetailSpecImage from "./ProductDetailSpecImage";
import ProductDetailFactoryPrinting from "./ProductDetailFactoryPrinting";

const TABS = [
  {
    id: "general",
    label: "general",
    component: <ProductDetailGeneral />,
  },

  {
    id: "gallery",
    label: "gallery",
    component: <ProductDetailGallery />,
  },
  {
    id: "spec",
    label: "specImage",
    component: <ProductDetailSpecImage />,
  },
  {
    id: "configuration",
    label: "configuration",
    component: <ProductDetailConfiguration />,
  },
  {
    id: "pricing",
    label: "pricing",
    component: <ProductDetailPricing />,
  },
  {
    id: "sku",
    label: "sku",
    component: <ProductDetailSku />,
  },
  {
    id: "warranty",
    label: "warranty",
    component: <ProductDetailWarranty />,
  },
  {
    id: "shipping",
    label: "shipping",
    component: <ProductDetailShipping />,
  },
  {
    id: "inventory",
    label: "inventory",
    component: <ProductDetailInventory />,
  },
  {
    id: "attribute",
    label: "attribute",
    component: <ProductDetailAttribute />,
  },
  {
    id: "subscription",
    label: "subscription",
    component: <ProductDetailRental />,
  },
  {
    id: "factoryPrintingSpec",
    label: "factoryPrintSpec",
    component: <ProductDetailFactoryPrinting />,
  },
];

const ProductDetail = () => {
  const [productDetail, setProductDetail] = useState({});

  const [tab, setTab] = useState<any>(TABS[0]);
  const { productId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    loadProductDetail();
    loadProductPrice();
  }, []);

  const loadProductPrice = () => {
    getProductPrice(productId ?? "").then((res) => {
      const price = res?.data?.data?.price;
      setProductDetail((preValues) => ({
        ...preValues,
        regularPrice: price.regular_price,
        salePrice: price.sale_price,
      }));
    });
  };

  const loadProductDetail = () => {
    Progress.show(
      { method: getProductDetail, params: [productId] },
      (res: any) => {
        const product = res?.data?.data?.product;
        const {
          options,
          launch_date,
          product_type,
          attribute_values,
          alias_sku,
          warranty_period,
          has_warranty_period,

          is_rent,
          regular_price_rent,
          sale_price_rent,
          option_of_month_rent,
          gift_rent,
        } = product;

        let productDetail: any = {};

        options &&
          options.forEach((item: any) => {
            productDetail[item?.option] = item?.value;
          });

        productDetail = {
          ...productDetail,
          ...product,
          launchDate: launch_date,
          warrantyPeriod: warranty_period,
          warranty: has_warranty_period
            ? PRODUCT_WARRANTY.AVAILABLE
            : PRODUCT_WARRANTY.NOT_AVAILABLE,
          sellingWhenOutStock: product.selling_out_of_stock,
          productionCost: product?.cost ?? 0,
          productType: product_type,
          attributeList: map(attribute_values, (item) => ({
            id: item.id,
            label: item.name,
            value: item.values?.id,
            valueName: item.values,
            rowId: StringUtils.getUniqueID(),
          })),
          aliasSkuList: map(alias_sku, (item) => ({
            id: StringUtils.getUniqueID(),
            value: item,
          })),

          rentalStatus: is_rent,
          rentalRegularPrice: regular_price_rent,
          rentalSalePrice: sale_price_rent,
          rentalOptions: map(option_of_month_rent, (item) => ({
            id: StringUtils.getUniqueID(),
            value: item,
          })),
          rentalFreeGifts: gift_rent,
        };

        setProductDetail((preValues) => ({
          ...preValues,
          ...productDetail,
        }));
      }
    );
  };

  return (
    <AppContext.Provider
      value={{
        productDetail,
        loadProductDetail,
      }}
    >
      <div className=" flex-column-container bg-trans">
        <ProductDetailHeader />
        <div className="grid grid-cols-12 gap-4 p-3 bg-white">
          <div className="col-span-4">
            <ProductDetailInfo />
          </div>
          <div className="col-span-8 px-3">
            <TabBar
              dataSource={TABS}
              onChange={setTab}
              value={tab}
              getLabel={(item: any) => t(item.label)}
              classNameItem={classNames(
                "bg-white border-right-0 border-left-0 border-top-0"
              )}
            />
            {tab.component}
          </div>
        </div>
      </div>
    </AppContext.Provider>
  );
};

export default ProductDetail;
