import BrandSelect from "@components/brand/components/BrandSelect";
import ChannelSelect from "@components/channel/components/ChannelSelect";
import InventoryEventSelect from "@components/invenotry-control-event/components/InventoryEventSelect";
import ProductCategorySelect from "@components/product-category/components/ProductCategorySelect";
import StoreSelect from "@components/store/components/StoreSelect";
import StoreSelectAll from "@components/store/components/StoreSelectAll";
import { INVENTORY_STATUS_LIST } from "@constants/inventory";
import { PRODUCT_TYPES } from "@constants/product";
import { Select } from "d-react-components";
import { map } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

interface IInventoryFilterForm {
  filterForm: any;
}

const InventoryFilterForm = ({ filterForm }: IInventoryFilterForm) => {
  const classNameField = "col-span-12 mt-3";
  const formValues = filterForm?.values;
  const formErrors = filterForm?.errors;
  const { t } = useTranslation();

  return (
    <div>
      <div className="grid grid-cols-4 gap-4">
        <ChannelSelect
          value={formValues.channels}
          className={classNameField}
          onChange={(value) =>
            filterForm.setValues({
              ...formValues,
              channels: value,
              warehouses: [],
              events: [],
            })
          }
          multiple
        />
        {/* <StoreSelect
          className={classNameField}
          value={formValues.warehouses}
          onChange={(value: any) => {
            filterForm.setValues({
              ...formValues,
              warehouses: value,
              events: [],
            });
          }}
          channelId={map(formValues.channels, (item) => item.id)}
          multiple
        /> */}
        <StoreSelectAll
          className={classNameField}
          value={formValues.warehouses}
          onChange={(value: any) => {
            filterForm.setValues({
              ...formValues,
              warehouses: value,
              events: [],
            });
          }}
          channelId={map(formValues.channels, (item) => item.id)}
          multiple
        />
        <InventoryEventSelect
          warehouseIds={map(formValues?.warehouses, (item) => item.id)}
          value={formValues?.events ?? []}
          onChange={(event: any) => {
            filterForm.setFieldValue("events", event);
          }}
          className={classNameField}
          multiple
        />
        <Select
          label={t("status")}
          className={classNameField}
          value={formValues?.status}
          onChange={(value) => {
            filterForm.setFieldValue("status", value);
          }}
          getLabel={(item) => t(item?.label)}
          dataSource={INVENTORY_STATUS_LIST}
        />
        <Select
          label={t("productType")}
          className={classNameField}
          value={formValues?.productType}
          onChange={(value) => {
            filterForm.setFieldValue("productType", value);
          }}
          getLabel={(item) => t(item?.label)}
          dataSource={PRODUCT_TYPES}
          multiple
        />
        <BrandSelect
          className={classNameField}
          value={formValues?.brand}
          onChange={(value) => filterForm.setFieldValue("brand", value)}
          multiple
        />
        <ProductCategorySelect
          className={classNameField}
          value={formValues?.category}
          onChange={(value) => filterForm.setFieldValue("category", value)}
          multiple
        />
      </div>
    </div>
  );
};

export default InventoryFilterForm;
