import CallHistory from "@components/callcenter/CallHistory";
import { CallLogPopup } from "@components/callcenter/components/RecentCalls";
import Drawer from "@components/shared/Drawer";
import {
  CallStatus,
  CallType,
  CALL_STATUSES,
  CALL_TYPES,
} from "@constants/call";
import AppContext from "@helpers/context";
import { CallHistory as ICallHistory } from "@interfaces/common";
import { getOrderCallList } from "@network/api/callcenter";
import { Badge, Button, TimeUtils } from "d-react-components";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const mapCallObject = (call: any) => {
  return {
    ...call,
    callNo: call.ref,
    type: call.call_type,
    startTime: call.start,
    endTime: call.end,
  };
};

const CallHistoryLog = () => {
  const { t } = useTranslation();
  const { order } = useContext(AppContext);
  const [showAllLog, setShowAllLog] = useState(false);
  const [showLogPopup, setShowLogPopup] = useState<any>();
  const [callHistory, setCallHistory] = useState([]);
  const [totalCall, setTotalCall] = useState(0);

  useEffect(() => {
    if (order?.id) {
      getOrderCallList({
        order_id: order.id,
        limit: 5,
      })
        .then((resp) => {
          setCallHistory(resp?.data?.data?.order_call);
          setTotalCall(resp?.data?.data?.pagination?.items);
        })
        .catch((resp) => {
          console.log(resp);
        });
    }
  }, [order?.id]);

  return (
    <div className="bg-white p-3 mb-3 shadow-sm">
      <label className="block mb-3 text-sm">{t("callHistory")}</label>
      {totalCall > 0 && (
        <>
          {callHistory.slice(0, 5).map((call: ICallHistory, index) => (
            <div key={index} className="border-b py-3">
              <div className="text-primary flex items-center">
                {t("ref")}: {call.ref}
                <span
                  className="border-primary border px-2 ml-2 py-[2px] text-xs text-primary cursor-pointer"
                  onClick={() => setShowLogPopup(mapCallObject(call))}
                >
                  {t("log")}
                </span>
              </div>
              <div className="text-gray-500">
                {t(CALL_TYPES[call.call_type]?.content)} (
                {`${moment(
                  moment().startOf("d").add(call.duration, "s")
                ).format("m[m]s[s]")}`}
                )
              </div>
              <div
                className=""
                style={{ color: CALL_STATUSES[call.status]?.color }}
              >
                {t(`${CALL_STATUSES[call.status]?.content}`)}
              </div>
              <div className="text-gray-500">
                {t("createdAt")} {TimeUtils.convertMiliToDateTime(call.created)}
              </div>
            </div>
          ))}
          <Button className="w-100 mt-3" onClick={() => setShowAllLog(true)}>
            <span className="pr-3">{t("allCalls")}</span>
            <Badge
              index={totalCall}
              variant="index"
              size="x-large"
              color="red"
            />
          </Button>
        </>
      )}
      {showAllLog && (
        <Drawer
          open={showAllLog}
          onClose={() => setShowAllLog(false)}
          title={t("callLog")}
          saveText={t("ok")}
          size="auto"
          width="1200px"
        >
          <CallHistory historyType="byOrder" orderId={order.id} />
        </Drawer>
      )}
      {showLogPopup && (
        <CallLogPopup
          open={showLogPopup}
          onClose={() => setShowLogPopup(undefined)}
          call={showLogPopup}
          orderId={order.id}
        />
      )}
    </div>
  );
};

export default CallHistoryLog;
