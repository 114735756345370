import { months2years } from "@helpers/string";
import i18next from "i18next";
import { find } from "lodash";
import moment from "moment";
import { useMemo } from "react";
import Barcode from "react-barcode";

const ProductionPrintingDoc = ({
  product = {},
  language,
  listSizes,
  ...printData
}: any) => {
  // const { t } = useTranslation();
  const t = i18next.getFixedT(language);

  const productionDate = {
    day: printData.production_date
      ? moment(printData.production_date).format("DD")
      : "",
    month: printData.production_date
      ? moment(printData.production_date).format("MM")
      : "",
    year: printData.production_date
      ? moment(printData.production_date).format("YYYY")
      : "",
  };

  const warranty = product.warranty_period
    ? months2years(product.warranty_period)
    : 0;

  const sizes = useMemo(() => {
    const catId = product?.category?.id;
    return listSizes[catId];
  }, [listSizes]);

  const isTickSize = (size: any) => {
    return size?.id === product?.size?.id;
  };

  const isSpecialSize = (size: any) => {
    return size?.is_other_size;
  };

  return (
    <div className={`bg-white p-8`}>
      <div className="px-8">
        <div className="text-center mb-4">
          <img
            src={product?.brand?.logo_print ?? "/images/img-default.png"}
            className="label-print__channel-image m-auto"
          />
        </div>
        <div className="flex mb-2 items-center">
          <p className="pr-2 mb-0 text-lg">
            {t("productName")}: {t("mattressModel")}
          </p>
          <div className="flex-1 print-placeholder has-text">
            <p className="text-lg  mb-0">{product?.name}</p>
          </div>
        </div>
        <div className="flex items-center mb-2">
          <p className="pr-2 mb-0 text-lg">{t("productType")}:</p>
          {product.product_type_of_production ? (
            <div className="mb-0 text-lg flex-1 print-placeholder has-text">
              {product.product_type_of_production}
            </div>
          ) : (
            <div className="print-placeholder flex-1"></div>
          )}
        </div>
        <div className="flex mb-2">
          <p className="pr-2 text-lg mb-0">{t("size")}:</p>
          <div>
            <div className="flex flex-wrap">
              {sizes?.map((s: any) =>
                isSpecialSize(s) ? null : (
                  <p
                    className="flex shrink-0 items-center mx-4 text-lg mb-1"
                    key={s?.id}
                  >
                    <span className="mr-1 border-[1px] rounded-full inline-block w-[16px] h-[16px] border-black">
                      {isTickSize(s) && (
                        <span className="print-size-checked w-[16px] h-[16px] invert inline-block"></span>
                      )}
                    </span>
                    {s?.display_name || s?.name}
                  </p>
                )
              )}
            </div>
            <div className="flex mx-4 items-center">
              <span className="mr-1 border-[1px] rounded-full inline-block w-[16px] h-[16px] border-black">
                {isSpecialSize(product?.size) && (
                  <span className="print-size-checked w-[16px] h-[16px] invert inline-block"></span>
                )}
              </span>
              <p className="pr-2 mb-0 text-lg">{t("otherSizes")}:</p>
              <div className="flex-1">
                <p className="text-lg mb-0 print-placeholder"></p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="flex items-center mb-2">
          <p className="pr-2 mb-0 text-lg">{t("height")}:</p>
          {product.thickness_height ? (
            <div className="mb-0 text-lg flex-1 print-placeholder has-text">
              {product.thickness_height}{" "}
              {Number(product.thickness_height) === 1 ? t("inch") : t("inches")}
            </div>
          ) : (
            <div className="print-placeholder flex-1"></div>
          )}
        </div> */}
        <div className="flex items-center mb-2">
          <p className="pr-2 mb-0 text-lg">{t("materialUsed")}:</p>
          {/* <div className="print-placeholder flex-1"></div> */}
          {product?.material_label ? (
            <div className="flex-1 print-placeholder has-text">
              <p className="text-lg mb-0">{product?.material_label}</p>
            </div>
          ) : (
            <div className="print-placeholder flex-1"></div>
          )}
        </div>
      </div>
      <div className="h-[350px] my-1">
        {product?.spec_image && (
          <img src={product?.spec_image} className="max-h-[100%] mx-auto" />
        )}
      </div>
      <div className="flex">
        <div className="flex-1 pr-2">
          <div className="flex">
            <p className="shrink-0 font-bold mr-2 mb-0 pt-[2px] text-base">
              {t("usageMethod")}
            </p>
            <div>
              <p className="mb-0 text-base pt-1">{t("supportWeight")}</p>
              <img
                src="/images/printing/sleep.png"
                className="w-[160px] mt-2"
              />
            </div>
          </div>
          <div>
            <p className="mb-1 font-bold text-lg">{t("caution")}</p>
            <p className="mb-1 font-bold underline underline-offset-2 text-lg">
              {t("noJumping")}
            </p>
            <p className="mb-1 font-bold text-base">
              {t("suggestionToExtend")}
            </p>
            <p className="mb-1 text-base">{t("shouldAirDry")}</p>
            <p className="mb-1 text-base">{t("shouldNotRotate")}</p>
            {/* <p className="mb-1 text-base font-bold">{t("remarks")}</p>{" "}
            <p className="mb-1 text-base">{t("mattressNotTreatAsMedical")}</p>
            <p className="mb-1 text-base">{t("softnessDepend")}</p> */}
          </div>
        </div>
        <div>
          <div className="border-[3px] border-black w-[280px] p-3">
            <div className="text-center mb-3">
              <p className="text-md text-base">
                {t("qualityInspectionPassed")}
              </p>
              <div className="print-placeholder flex-1 w-full overflow-hidden mt-10 text-base"></div>
            </div>
            <div className="text-center mb-3">
              <p className="text-md mb-1 text-base">
                {t("day")} / {t("month")} / {t("year")}
              </p>
              <div className="flex">
                <div className=" w-full overflow-hidden has-text print-placeholder text-base">
                  {productionDate.day}
                </div>
                /
                <div className="w-full overflow-hidden has-text print-placeholder text-base">
                  {productionDate.month}
                </div>
                /
                <div className="w-full overflow-hidden has-text print-placeholder text-base">
                  {productionDate.year}
                </div>
              </div>
            </div>
            <div className="text-center mb-3">
              <p className="text-md mb-1 text-base">
                {t("manufacturerCountry")}
              </p>
              {printData.country ? (
                <div className="w-full has-text print-placeholder overflow-hidden text-base">
                  {printData.country}
                </div>
              ) : (
                <div className="print-placeholder w-full overflow-hidden text-base"></div>
              )}
            </div>
            <div className="text-center mb-3">
              <p className="text-md mb-1 text-base">
                {t("numberOfYearGuaranteed")}
              </p>
              {warranty ? (
                <div className="print-placeholder has-text w-full overflow-hidden text-base">
                  {warranty.y} {t("years")}
                  {warranty.m ? `${warranty.m} ${t("months")}` : ``}
                </div>
              ) : (
                <div className="print-placeholder w-full overflow-hidden text-base"></div>
              )}
            </div>
            <div>
              <p className="mb-0 text-base">{t("priceInThai")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end mt-3 mb-2">
        {/* <div className="flex-1">
          <Barcode
            value={printData.nav_rp}
            width={1.1}
            height={60}
            fontSize={16}
            format="CODE39"
          />
        </div> */}
        <div className="ml-2">
          <Barcode value={product.sku} width={1.5} height={60} fontSize={16} />
        </div>
        {/* <div className="ml-3 min-w-[280px]">
          <div className="flex items-center mb-1">
            <p className="text-lg mr-2 mb-0">{t("so")}:</p>
            <div className="flex-1 w-full overflow-hidden has-text print-placeholder">
              <p className="text-lg mb-0">{printData.nav_so}</p>
            </div>
          </div>
          <div className="flex items-center mb-1">
            <p className="text-lg mr-2 mb-0">{t("rp")}:</p>
            <div className="flex-1 w-full overflow-hidden has-text print-placeholder">
              <p className="text-lg mb-0">{printData.nav_rp}</p>
            </div>
          </div>
          <div className="flex items-center mb-1">
            <p className="text-lg mr-2 mb-0">{t("sku")}: </p>
            <div className="flex-1 w-full overflow-hidden has-text print-placeholder">
              <p className="text-lg mb-0">{product.sku}</p>
            </div>
          </div>
        </div> */}
      </div>
      <div className="border-t border-black text-center pt-3">
        <p className="mb-1">
          {/* {t("importedFrom")} ...................  */}
          {t("manufacturedBy")} {printData.manufactured_by} {t("and")}{" "}
          {t("distributedBy")} {printData.distributed_by}
        </p>
        <p className="mb-1">{t("companyAddress")}</p>
      </div>
    </div>
  );
};
export default ProductionPrintingDoc;
