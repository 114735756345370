/* eslint-disable react/jsx-no-duplicate-props */
import { PRODUCT_TYPE } from "@constants/product";
import classNames from "classnames";
import { InputText } from "d-react-components";
import { reduce } from "lodash";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface IProductInput {
  productForm: any;
  className?: any;
}

const ProductInputPricing = ({ productForm, className }: IProductInput) => {
  const { regularPrice, salePrice, groups, productType } = productForm?.values;
  const { t } = useTranslation();

  const isGroupProduct = productType === PRODUCT_TYPE.GROUP;
  const isCustomSizePro = productType === PRODUCT_TYPE.CUSTOM_SIZE;

  useEffect(() => {
    if (!isGroupProduct) {
      return;
    }
    const regularPriceGroup = reduce(
      groups,
      (sum, item) => (sum += item.quantity * item.regular_price),
      0
    );
    const salePriceGroup = reduce(
      groups,
      (sum, item) => (sum += item.quantity * item.sale_price),
      0
    );
    productForm.setValues({
      ...productForm.values,
      regularPrice: regularPriceGroup,
      salePrice: salePriceGroup,
      isChangePrice: false,
    });
  }, [groups, isGroupProduct]);

  const onChangePrice = (key: string, value: string) => {
    const result = value ? value.replace(/\D/g, "") : "0";
    productForm.setFieldValue(key, parseInt(result));
  };

  const isDisablePriceInput = isGroupProduct || isCustomSizePro;

  return (
    <div className={classNames("grid grid-cols-4 gap-4", className)}>
      <InputText
        className="col-span-2"
        label={t("regularPrice")}
        prefix="฿"
        value={regularPrice.toLocaleString()}
        onChange={(event) => onChangePrice("regularPrice", event.target.value)}
        disabled={isDisablePriceInput}
      />
      <InputText
        className="col-span-2"
        label={t("defaultSalePrice")}
        prefix="฿"
        value={salePrice.toLocaleString()}
        onChange={(event) => onChangePrice("salePrice", event.target.value)}
        disabled={isDisablePriceInput}
      />
    </div>
  );
};

export default ProductInputPricing;
