import InputTextForm from "@common/input/InputTextForm";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const ChannelFormEmailConfig = ({ form, fieldClassName = "" }: any) => {
  const { t } = useTranslation();

  const classNameField = classNames("col-6 mt-3", fieldClassName);
  return (
    <div className="row">
      <InputTextForm
        keyData="emailSender"
        form={form}
        className={classNameField}
      />
      <InputTextForm
        keyData="emailSupport"
        form={form}
        className={classNameField}
        label={t("replyTo")}
      />
    </div>
  );
};

export default ChannelFormEmailConfig;
