import InputSelectForm from "@common/input/InputSelectForm";
import InputTextForm from "@common/input/InputTextForm";
import ChainSelect from "@components/chain/components/ChainSelect";
import { GP_EVENT_VISIBILITIES } from "@constants/gp-event";
import AppContext from "@helpers/context";
import { DateInput } from "d-react-components";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

const GPEventCreateGeneral = () => {
  const { formValues, eventForm, formErrors } = useContext(AppContext);

  const { t } = useTranslation();

  const classNameField = "col-span-12 mt-3";
  return (
    <div className="card-container mt-3 grid p-4">
      <InputTextForm
        keyData="name"
        form={eventForm}
        className={classNameField}
        required
      />
      <ChainSelect
        value={formValues?.chain}
        className={classNameField}
        onChange={(value) => eventForm?.setFieldValue("chain", value)}
      />
      <InputSelectForm
        keyData="visibility"
        form={eventForm}
        className={classNameField}
        dataSource={GP_EVENT_VISIBILITIES}
        getLabel={(item: any) => t(item.label)}
        required
      />
      <DateInput
        isRangePicker
        value={formValues?.period}
        onChange={(value) => eventForm.setFieldValue("period", value)}
        label={t("period")}
        className={classNameField}
        error={formErrors?.period}
        required
        showTime
        format="DD/MM/YYYY HH:mm"
      />
      <InputTextForm
        keyData="remark"
        form={eventForm}
        className={classNameField}
        multiple
        required
      />
    </div>
  );
};

export default GPEventCreateGeneral;
