import InputTextForm from "@common/input/InputTextForm";
import ChannelSelect from "@components/channel/components/ChannelSelect";
import InventoryEventSelect from "@components/invenotry-control-event/components/InventoryEventSelect";
import { Path } from "@components/layout/Path";
import ButtonFileUpload from "@components/shared/ButtonFileUpload";
import StockRequestProductsErrorDrawer from "@components/stock-request/detail/StockRequestProductsErrorDrawer";
import StoreSolidSelect from "@components/store/components/StoreSolidSelect";
import AppContext from "@helpers/context";
import { mapStockAdjustmentToServer } from "@interfaces/stock-adjustment";
import { createStockAdjustment } from "@network/api/stock-adjustment";
import { Header, Progress } from "d-react-components";
import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import StockAdjustmentCreateProduct from "./StockAdjustmentCreateProduct";

const StockAdjustmentCreate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openStockError, setOpenStockError] = useState(false);

  const stockForm = useFormik<any>({
    initialValues: {} as any,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: schema,
    onSubmit: (values: any) => {
      const body = mapStockAdjustmentToServer(values);
      onCreate(body);
    },
  });

  const formValues = stockForm?.values;
  const formErrors = stockForm?.errors;

  const classNameInput = "mt-3";

  const onCreate = (input: any) => {
    Progress.show(
      { method: createStockAdjustment, params: [input] },
      (res: any) => {
        const adjustmentId = res?.data?.data?.adjustment?.id;
        navigate(generatePath(Path.STOCK_ADJUSTMENT_DETAIL, { adjustmentId }));
      },
      (err: any) => {
        if (err === "Products invalid") {
          setOpenStockError(true);
          return true;
        }
      }
    );
  };

  return (
    <AppContext.Provider value={{ stockForm }}>
      <Header
        title={t("newStockAdjustment")}
        onSave={() => stockForm.handleSubmit()}
      />
      <div className="p-3">
        <div className="card-container p-3">
          <ChannelSelect
            value={stockForm?.values.channel}
            className={classNameInput}
            onChange={(value) => stockForm.setFieldValue("channel", value)}
          />
          <StoreSolidSelect
            channelId={stockForm?.values.channel?.id}
            label={t("branchWarehouse")}
            value={formValues?.store}
            onChange={(value) => stockForm.setFieldValue("store", value)}
            className={classNameInput}
          />

          <InventoryEventSelect
            warehouseIds={formValues?.store?.id ? [formValues?.store?.id] : []}
            value={formValues?.event}
            onChange={(value) => stockForm.setFieldValue("event", value)}
            className={classNameInput}
          />

          <StockAdjustmentCreateProduct
            form={stockForm}
            className="mt-3"
            warehouseId={formValues?.store?.id}
          />

          <div className={classNameInput}>
            <label>{t("attachments")}</label>
            <ButtonFileUpload
              //   uploadedFiles={formValues?.attachments}
              onChange={(values: any) =>
                stockForm.setFieldValue("documents", values)
              }
              maxFiles={5}
            />
          </div>

          <InputTextForm
            keyData="remark"
            form={stockForm}
            multiple
            className={classNameInput}
          />

          {openStockError && (
            <StockRequestProductsErrorDrawer
              description="quantityToAdjustOver"
              productSubmitted={formValues?.products}
              inventoryEventControl={formValues?.event}
              open={openStockError}
              onClose={() => setOpenStockError(false)}
              isErrorDetail
            />
          )}
        </div>
      </div>
    </AppContext.Provider>
  );
};

export default StockAdjustmentCreate;
