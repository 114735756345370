import { IAdmin } from "./admin";
import { ICustomer } from "./customer";

export interface Pagination {
  pageSize: string | number;
  pageIndex: string | number;
}

export interface Channel {
  id: string;
  [key: string]: any;
}

export interface IMenu {
  icon: string;
  order: number;
  parent_id: number;
  title: string;
  uri: string;
  children: IMenu[];
  label: string;
  subMenu?: IMenu[];
}

export interface INotification {
  id: string;
  screen: string;
  screenid: string;
  tab: string;
  filter: string;
  title: string;
  description: string;
  receiver: Receiver;
  created: number;
  url: string;
}

export interface Receiver {
  id: string;
  receiver_by: ReceiverBy;
  read: any;
}

export interface ReceiverBy {
  id: number;
  name: string;
  fullname: string;
  avatar: string;
  companyId: string;
}

export interface CallCenterInfo {
  call_center_email: string;
  call_center_id: number;
  call_center_token: string;
  expired: number;
  has_call_center: boolean;
  status: boolean;
}

export interface CallEvent {
  destination: string;
  phone: string;
}

export interface Ticket {
  id: string;
  created: string;
  channel: Channel;
  customer: ICustomer;
  status: string;
  subject: string;
}

export interface CallHistory {
  admin: IAdmin;
  call_center_email: string;
  call_center_id: number;
  call_type: string;
  created: number | string;
  end: string;
  id: string;
  ref: string;
  start: string;
  status: string;
  label: string;
  duration: string;
}

export interface CallLog {
  id: string;
  callNo: string;
  type: string;
  txnUuid: string;
  status: string;
  startTime: number;
  endTime: number;
  duration: number;
  refId?: any;
  label?: any;
  ext?: any;
  destination: string;
  created: number;
  updated: number;
}

export interface SalesPerson {
  id: number;
  name: string;
  fullname: string;
  avatar: string;
  companyId: string;
}
export interface DrawerProps {
  open: boolean;
  onClose: () => void;
}

export enum DiscountType {
  Percentage = "discount_percentage",
  FixAmount = "discount_fix_amount",
}
