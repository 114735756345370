import React from "react";
import { Avatar } from "d-react-components";
import classNames from "classnames";
import { useRootStore } from "../../App";

const UserAvatarName = ({
  user,
  placeholder = "N/A",
  size = "x-small",
  className = "",
  variant = "rounded",
  text = "",
  showPopup = true,
}: any) => {
  const setUserModalId = useRootStore((state) => state.setUserModalId);
  if (!user || !user?.id) return placeholder;
  return (
    <div
      className={classNames("flex-center-y cursor-pointer", className)}
      onClick={() => {
        if (showPopup) setUserModalId(user?.id);
      }}
    >
      <Avatar src={user?.avatar} size={size} variant={variant} />
      <div className="ml-2 text-primary">
        {text || `${user.companyId ? `${user.companyId}_` : ""}${user?.name}`}
      </div>
    </div>
  );
};

export default UserAvatarName;
