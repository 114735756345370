import { API } from "@network";
import {
  API_KPI_WAREHOUSE,
  API_KPI_WAREHOUSE_DETAIL,
  API_KPI_WAREHOUSE_DETAIL_HISTORY,
  API_KPI_WAREHOUSE_DETAIL_UPDATE,
  API_KPI_WAREHOUSE_UPDATE,
  API_MY_ASSETS_WAREHOUSE,
  API_WAREHOUSE_ACTIVITIES,
  API_WAREHOUSE_ADD_ACTIVITY,
  API_WAREHOUSE_CHANNEL_LIST,
  API_WAREHOUSE_CHANNEL_UPDATE,
  API_WAREHOUSE_CREATE,
  API_WAREHOUSE_DELIVERY_LIST,
  API_WAREHOUSE_DELIVERY_UPDATE,
  API_WAREHOUSE_DETAIL,
  API_WAREHOUSE_DUPLICATE,
  API_WAREHOUSE_EXPORT,
  API_WAREHOUSE_IMPORT,
  API_WAREHOUSE_IMPORT_FIELDS,
  API_WAREHOUSE_IMPORT_VALIDATE,
  API_WAREHOUSE_LIST,
  API_WAREHOUSE_UPDATE,
  API_WAREHOUSE_USER_LIST,
  API_WAREHOUSE_USER_LIST_LOAD,
  API_WAREHOUSE_USER_UPDATE,
  API_WAREHOUSE_USER_WAREHOUSE_SUMMARY,
} from "@network/URL";

const WarehouseAPI = {
  list: (paging: any, body: any) => {
    return API.post(
      `${API_WAREHOUSE_LIST}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
      body
    );
  },
  export: (paging: any, body: any) => {
    return API.post(
      `${API_WAREHOUSE_EXPORT}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
      body
    );
  },

  detail: (body: any) => {
    return API.post(API_WAREHOUSE_DETAIL, body);
  },

  update: (body: any) => {
    return API.post(API_WAREHOUSE_UPDATE, body);
  },

  create: (body: any) => {
    return API.post(API_WAREHOUSE_CREATE, body);
  },

  activityList: (paging: any, body: any) => {
    return API.post(
      `${API_WAREHOUSE_ACTIVITIES}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
      body
    );
  },

  activityAdd: (body: any) => {
    return API.post(API_WAREHOUSE_ADD_ACTIVITY, body);
  },

  channelList: (body: any, paging?: any) => {
    return API.post(
      `${API_WAREHOUSE_CHANNEL_LIST}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
      body
    );
  },

  channelUpdate: (body: any) => {
    return API.post(API_WAREHOUSE_CHANNEL_UPDATE, body);
  },

  deliveryList: (body: any, paging?: any) => {
    return API.post(
      `${API_WAREHOUSE_DELIVERY_LIST}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
      body
    );
  },

  deliveryUpdate: (body: any) => {
    return API.post(API_WAREHOUSE_DELIVERY_UPDATE, body);
  },

  userList: (body: any, paging: any) => {
    return API.post(
      `${API_WAREHOUSE_USER_LIST}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
      body
    );
  },

  userListLoad: (body: any, paging: any) => {
    return API.post(
      `${API_WAREHOUSE_USER_LIST_LOAD}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
      body
    );
  },

  userUpdate: (body: any) => {
    return API.post(API_WAREHOUSE_USER_UPDATE, body);
  },

  userWarehouseSummary: (body: any) => {
    return API.post(API_WAREHOUSE_USER_WAREHOUSE_SUMMARY, body);
  },

  duplicate: (body: any) => {
    return API.post(API_WAREHOUSE_DUPLICATE, body);
  },

  getImportFields: (method = "create") => {
    return API.post(API_WAREHOUSE_IMPORT_FIELDS, { method });
  },

  validateImport: (body: any) => {
    return API.post(API_WAREHOUSE_IMPORT_VALIDATE, body);
  },

  importWarehouse: (body: any) => {
    return API.post(API_WAREHOUSE_IMPORT, body);
  },

  kpiWarehouse: (body: any) => {
    return API.post(API_KPI_WAREHOUSE, body);
  },

  updateKpiWarehouse: (body: any) => {
    return API.post(API_KPI_WAREHOUSE_UPDATE, body);
  },

  kpiWarehouseDetail: (body: any) => {
    return API.post(API_KPI_WAREHOUSE_DETAIL, body);
  },

  kpiWarehouseUpdateDetail: (body: any) => {
    return API.post(API_KPI_WAREHOUSE_DETAIL_UPDATE, body);
  },

  kpiWarehouseDetailHistory: (body: any) => {
    return API.post(API_KPI_WAREHOUSE_DETAIL_HISTORY, body);
  },

  listMyAssetsWarehouse: (body: any, paging?: any) => {
    return API.post(
      `${API_MY_ASSETS_WAREHOUSE}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
      body
    );
  },
};

export default WarehouseAPI;
