import { PRODUCT_TYPE } from "@constants/product";
import { getAttributeDefault } from "@helpers/product";
import { getAttributeFromCategory } from "@network/api/order";
import { getBrandList, getCategoryList } from "@network/api/product";
import {
  InputText,
  Modal,
  Notifications,
  Select,
  StringUtils,
  ObjectUtils,
  Drawer,
  Button,
} from "d-react-components";
import { map, isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePrevious } from "react-use";
import AttributeInputItem from "./AttributeInputItem";

const AddCustomItemDrawer = ({ open, onClose, onSave, product }: any) => {
  const [attributeList, setAttributeList] = useState(
    product?.attributeList ?? []
  );
  const [attributeDataList, setAttributeDataList] = useState([]);
  const [productInfo, setProductInfo] = useState(product?.productInfo ?? {});
  const [category, setCategory] = useState(product?.category ?? undefined);
  const [brand, setBrand] = useState(product?.brand ?? undefined);
  const [categoryList, setCategoryList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const preCategory = usePrevious(category);
  const { t } = useTranslation();

  useEffect(() => {
    loadCategoryList();
    loadBrandList();
  }, []);

  useEffect(() => {
    if (preCategory && preCategory.id !== category?.id) {
      setAttributeList([]);
    }
    onLoadAttributes();
  }, [category]);

  const loadCategoryList = () => {
    getCategoryList().then((respone) => {
      const result = respone?.data?.data?.category ?? [];
      setCategoryList(result);
    });
  };

  function loadBrandList() {
    getBrandList().then((res) => {
      const brandList = res?.data?.data?.brand ?? [];
      setBrandList(brandList);
    });
  }

  const onLoadAttributes = () => {
    if (!category) return;
    const body = { category: category?.id };

    getAttributeFromCategory(body).then((res) => {
      const attributeDataList = res?.data?.data?.attribute ?? [];
      setAttributeDataList(attributeDataList);
    });
  };

  const onClickAddAttribute = () => {
    setAttributeList([...attributeList, getAttributeDefault()]);
  };

  const skuInfoField = () => {
    return (
      <div className="">
        <div className="mb-3">
          <InputText
            label={t("itemName")}
            value={productInfo?.itemName ?? ""}
            onChange={(event) =>
              setProductInfo({ ...productInfo, itemName: event.target.value })
            }
          />
        </div>
        <div className="mb-3">
          <InputText
            label={t("sku")}
            value={productInfo?.sku ?? ""}
            onChange={(event) =>
              setProductInfo({ ...productInfo, sku: event.target.value })
            }
          />
        </div>
        <div className="mb-3">
          <InputText
            label={t("salePrice")}
            value={productInfo?.salePrice ?? ""}
            onChange={(event) =>
              setProductInfo({
                ...productInfo,
                salePrice: parseInt(event.target.value),
              })
            }
            type="number"
          />
        </div>
      </div>
    );
  };

  const sizeInputField = () => {
    return (
      <div className="">
        <InputText
          className="mb-3"
          label={t("widthCM")}
          value={productInfo?.width ?? ""}
          type="number"
          onChange={(event) =>
            setProductInfo({
              ...productInfo,
              width: parseInt(event.target.value),
            })
          }
        />
        <InputText
          className="mb-3"
          label={t("lengthCM")}
          value={productInfo?.length ?? ""}
          onChange={(event) =>
            setProductInfo({
              ...productInfo,
              length: parseInt(event.target.value),
            })
          }
          type="number"
        />
      </div>
    );
  };

  const isShowAddMore = useMemo(() => {
    return attributeList.length !== attributeDataList.length;
  }, [attributeList, attributeDataList]);

  const onRemoveAttribute = (attributeItem: any) => {
    const resultList = attributeList.filter(
      (item: any) => item.rowId !== attributeItem.rowId
    );
    setAttributeList(resultList);
  };

  const onChangeAttribute = (attribute: any) => {
    const resultList = attributeList?.map((item: any) => {
      if (item.rowId === attribute.rowId) {
        return attribute;
      }

      return item;
    });
    setAttributeList(resultList);
  };

  const attributeInputField = () => {
    return (
      <div className="mb-3">
        <label>{t("attributes")}</label>
        {map(attributeList, (item, key) => (
          <AttributeInputItem
            attribute={item}
            onRemove={onRemoveAttribute}
            onChange={onChangeAttribute}
            attributeDataList={attributeDataList}
            attributeList={attributeList}
            index={key}
          />
        ))}
        {isShowAddMore && (
          <div
            className="cursor-pointer mt-3 text-primary"
            onClick={onClickAddAttribute}
          >
            {t("addAttribute")}
          </div>
        )}
      </div>
    );
  };

  const isValidate = () => {
    const { itemName, sku, salePrice } = productInfo;
    if (isEmpty(itemName)) {
      Notifications.showError(t("notification:itemNameIsRequire"));
      return false;
    }
    if (!brand) {
      Notifications.showError(t("notification:brandIsRequired"));
      return false;
    }
    if (!category) {
      Notifications.showError(t("notification:categoryIsRequired"));
      return false;
    }

    if (isEmpty(sku)) {
      Notifications.showError(t("notification:skuIsRequire"));
      return false;
    }
    if (isEmpty(`${salePrice}`)) {
      Notifications.showError(t("notification:salePriceIsRequire"));
      return false;
    }

    return true;
  };

  const onClickSave = async () => {
    if (!isValidate()) {
      Notifications.showError(t("notification:itemIsNotValid"));
      return;
    }
    const productId = product ? product?.id : StringUtils.getUniqueID();

    const attributeListResult = attributeList?.map((item: any) => {
      const attributeData: any = attributeDataList.find(
        (dataItem: any) => dataItem.id === item.id
      );

      const { values } = attributeData || {};
      const valueData = values.find(
        (dataItem: any) => dataItem.id === item.value
      );
      return {
        ...item,
        attributeName: attributeData?.name,
        valueName: valueData?.name,
      };
    });
    const productResult = {
      productInfo,
      category,
      brand,
      attributeList: attributeListResult,

      productid: productId,
      id: productId,
      sale_price: productInfo.salePrice,
      product: {
        ...productInfo,
        id: productId,
        name: productInfo?.itemName,
        sku: productInfo?.sku,
        imageurl: [],
        product_type: PRODUCT_TYPE.CUSTOM,
      },
      quantity: 1,
      isCustomProduct: true,
      price: productInfo.salePrice,
    };
    onSave(productResult);
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("updateCustomItem")}
      destroyOnClose
    >
      <div className="pb-10">
        <Select
          label={t("category")}
          onChange={(id) => {
            const selectedCategory = ObjectUtils.findItemFromId(
              categoryList,
              id
            );
            setCategory(selectedCategory);
          }}
          value={category?.id ?? undefined}
          dataSource={categoryList}
          getLabel={(item) => item.name}
          className="mb-3"
          placeholder={t("pleaseSelect")}
        />
        <Select
          className="mb-3"
          label={t("brand")}
          onChange={(id) => {
            const selectedBrand = ObjectUtils.findItemFromId(brandList, id);
            setBrand(selectedBrand);
          }}
          value={brand?.id ?? undefined}
          dataSource={brandList}
          getLabel={(item) => item.name}
          placeholder={t("pleaseSelect")}
        />
        {skuInfoField()}
        {sizeInputField()}
        {attributeInputField()}
      </div>

      <div className="position-absolute bottom-0 w-100 end-0 start-0 flex-center-y justify-content-between py-3 px-3 bg-white">
        <Button
          onClick={() => {
            onClose && onClose({});
          }}
          variant="outline"
        >
          {t("cancel")}
        </Button>
        <Button
          onClick={() => {
            onClickSave();
          }}
        >
          {t("save")}
        </Button>
      </div>
    </Drawer>
  );
};

export default AddCustomItemDrawer;
