import { API } from "@network";
import {
  API_LIST_NOTIFICATION,
  API_READ_ALL_NOTIFICATION,
  READ_NOTIFICATION,
} from "@network/URL";

export const getAllNotifications = (payload?: any) => {
  return API.post(API_LIST_NOTIFICATION, payload);
};

export const readNotifications = (payload?: any) => {
  return API.post(READ_NOTIFICATION, payload);
};

export const readAllNotifications = () => {
  return API.post(API_READ_ALL_NOTIFICATION);
};