import AppContext from "@helpers/context";
import React, { useContext } from "react";

const MainContent: React.FC<any> = ({ children }) => {
  const { navBarHeight } = useContext(AppContext);
  return (
    <div
      className="main-content overflow-auto height-mobile-auto"
      // className="main-content overflow-auto height-mobile-auto"
      style={{ height: `calc(100vh - ${navBarHeight ?? 0}px)` }}
    >
      {children}
    </div>
  );
};

export default MainContent;
