import Drawer from "@components/shared/Drawer";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import CustomerFilterForm from "../component/CustomerFilterForm";


function CustomerTableFilterDrawer({
  open,
  onClose,
  defaultValue,
  onFilter,
}: any) {
  const { t } = useTranslation();

  const filterForm = useFormik({
    initialValues: defaultValue,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      onFilter && onFilter(values);
      onClose();
    },
  });

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("filter")}
      onSave={() => filterForm.handleSubmit()}
      size="large"
    >
      <CustomerFilterForm filterForm={filterForm} />
    </Drawer>
  );
}

export default CustomerTableFilterDrawer;
