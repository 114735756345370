import InventoryEventSelect from "@components/invenotry-control-event/components/InventoryEventSelect";
import { MT_ORDER_TYPE, MT_ORDER_TYPES } from "@constants/order";
import AppContext from "@helpers/context";
import { mtDiscountEventList, mtDiscountManualList } from "@network/api/order";
import { Select } from "d-react-components";
import { debounce, isEmpty, map } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const OrderCreateModernTradeDiscount = () => {
  const { setFieldValue, values } = useContext(AppContext);
  const [gpDiscountList, setGpDiscountList] = useState([]);
  const [gpDiscountEvents, setGpDiscountEvents] = useState([]);
  const { t } = useTranslation();
  const {
    mtOrderType,
    mtOrderDiscount,
    channel,
    storeSelected,
    isChainExternal,
  } = values;

  useEffect(() => {
    loadMTDiscountManualList();
  }, [storeSelected, mtOrderType]);

  const loadMTDiscountManualList = () => {
    if (!storeSelected || mtOrderType !== MT_ORDER_TYPE?.NORMAL) return;
    const body = {
      channel_id: channel,
      store_id: storeSelected?.id,
      chain_id: storeSelected?.chain?.id,
    };

    mtDiscountManualList(body).then((res) => {
      const discounts = res?.data?.data?.chain_discount_manual ?? [];
      setGpDiscountList(discounts);
    });
  };

  const loadMTDiscountEventList = debounce((search) => {
    if (!storeSelected || isEmpty(search)) return;
    const body = {
      channel_id: channel,
      store_id: storeSelected?.id,
      chain_id: storeSelected?.chain?.id,
      search,
    };
    mtDiscountEventList(body).then((res) => {
      const events = res?.data?.data?.chain_discount_manual_event ?? [];
      setGpDiscountEvents(events);
    });
  }, 500);

  const onChangeMtType = (id: string) => {
    // setChangeValues({ mtOrderType: id, mtOrderDiscount: null });
    setFieldValue("mtOrderType", id);
    setFieldValue("mtOrderDiscount", null);
  };

  const onChangeEvent = (event: string) => {
    // setChangeValues({ mtOrderDiscount: event, productList: [] });
    setFieldValue("mtOrderDiscount", event);
  };

  const warehouseIds = useMemo(() => {
    return map(values?.warehouse, (item) => item.id) ?? [];
  }, [values]);

  const renderValue = () => {
    switch (mtOrderType) {
      case MT_ORDER_TYPE.EVENT:
        return (
          // <Select
          //   label={t("event")}
          //   onChange={onChangeEvent}
          //   dataSource={gpDiscountEvents}
          //   getLabel={(item) => t(item.name)}
          //   value={mtOrderDiscount}
          //   onSearch={(text) => loadMTDiscountEventList(text)}
          //   showSearch
          //   disabled={!isChainExternal}
          //   placeholder={t("pleaseSelect")}
          // />
          <InventoryEventSelect
            warehouseIds={warehouseIds}
            value={mtOrderDiscount ?? []}
            onChange={onChangeEvent}
          />
        );
      default:
        return (
          <Select
            label={t("gpDiscount")}
            onChange={(value) => setFieldValue("mtOrderDiscount", value)}
            dataSource={gpDiscountList}
            getLabel={(item) => `${item?.discount}%`}
            value={mtOrderDiscount}
            disabled={!isChainExternal}
            placeholder={t("pleaseSelect")}
          />
        );
    }
  };

  return (
    <>
      <div className="col-span-2 md:col-span-1 mb-2">
        <Select
          label={t("mtOrderType")}
          dataSource={MT_ORDER_TYPES?.map((item) => ({
            ...item,
            label: t(item.label),
          }))}
          getLabel={(item) => item.label}
          onChange={onChangeMtType}
          value={mtOrderType}
          disabled={!isChainExternal}
          placeholder={t("pleaseSelect")}
        />
      </div>
      <div className="col-span-2 md:col-span-1 mb-2">{renderValue()}</div>
    </>
  );
};

export default OrderCreateModernTradeDiscount;
