import { Path } from "@components/layout/Path";
import {
  CUSTOMER_SEARCH_OPTIONS,
  CUSTOMER_SEARCH_TYPE_DEFAULT,
} from "@constants/customer";
import AppContext from "@helpers/context";
import { listAllCustomer } from "@network/api/customer";
import RoleAPI from "@network/api/role";
import { getWarehouseList } from "@network/api/store";
import { Dropdown } from "antd";
import {
  AwesomeTableComponent,
  Button,
  HeaderTable,
  IColumnsProps,
  Select,
  TimeUtils,
} from "d-react-components";
import { compact, debounce, isNil, map, omitBy } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import { JsonParam, StringParam, useQueryParam } from "use-query-params";
import CustomerTableFilterDrawer from "./CustomerTableFilterDrawer";
import moment from "moment";

const CustomerTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openFilter, setOpenFilter] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [warehouseList, setWarehouseList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  // const [filterBody, setFilterBody] = useState({});
  const [filterBody = {}, setFilterBody] = useQueryParam(
    "filterBody",
    JsonParam
  );

  const [searchType = CUSTOMER_SEARCH_TYPE_DEFAULT, setSearchType] =
    useQueryParam("searchType", StringParam);

  const tableRef = useRef<any>();
  const searchRef = useRef<any>();

  useEffect(() => {
    loadWarehouse();
    loadRoleList();
  }, []);

  const isFiltered = useMemo(
    () => Object.keys(filterBody).length > 0,
    [filterBody]
  );

  const loadWarehouse = () => {
    getWarehouseList().then((res) => {
      const warehouseList = res?.data?.data?.warehouse;
      setWarehouseList(warehouseList);
    });
  };

  const loadRoleList = () => {
    RoleAPI.list().then((res) => {
      const roleList = res?.data?.data?.roles ?? [];
      setRoleList(roleList);
    });
  };

  const actions = useMemo(() => {
    return [
      {
        key: "edit",
        label: t("edit"),
      },
    ];
  }, [t]);

  const onHandleAction = (item: any) => {
    if (item.id === "edit") {
      navigate(generatePath(Path.CUSTOMER_DETAIL, { customerId: item.id }));
    }
  };

  const columns: IColumnsProps = [
    {
      title: t("channel"),
      dataIndex: "channel",
      render: (channel) => channel?.name ?? "N/A",
      width: 110,
    },
    {
      title: t("customerNo"),
      dataIndex: "",
      render: (customer) => (
        <a
          href={generatePath(Path.CUSTOMER_DETAIL, { customerId: customer.id })}
        >
          {customer?.customerid ?? "N/A"}
        </a>
      ),
    },
    {
      title: t("name"),
      dataIndex: "",
      render: (customer) => (
        <a
          href={generatePath(Path.CUSTOMER_DETAIL, { customerId: customer.id })}
        >
          {`${customer?.firstname ?? "N/A"} ${customer?.lastname ?? "N/A"}`}
        </a>
      ),
    },
    // {
    //   title: t("provinceName"),
    //   dataIndex: "province",
    // },
    {
      title: t("email"),
      dataIndex: "email",
    },
    {
      title: t("phone"),
      dataIndex: "phone",
    },
    {
      title: t("citizenId"),
      dataIndex: "citizen_id",
      width: 100,
    },
    {
      title: t("totalOrderQty"),
      dataIndex: "total_order",
      width: 150,
    },
    {
      title: t("lifeSpending"),
      dataIndex: "total_value_order",
    },
    {
      title: t("createdAt"),
      dataIndex: "created",
      render: TimeUtils.toDateTime,
    },

    // {
    //   title: t("action"),
    //   dataIndex: "",
    //   render: (item) => (
    //     <Dropdown
    //       trigger={["click"]}
    //       menu={{ items: actions, onClick: onHandleAction }}
    //     >
    //       <Button
    //         variant="trans"
    //         content={""}
    //         suffixIcon="more_vert"
    //         size="small"
    //         className="ml-2"
    //       />
    //     </Dropdown>
    //   ),
    // },
  ];

  const source = (paging: any) => {
    const {
      channels,
      totalOrderQty,
      totalOrderValue,
      from,
      age,
      gender,
      customerType,
    } = filterBody;

    const body = omitBy(
      {
        search: searchRef.current,
        search_type: searchType,
        channel_id: channels?.map((item: any) => item.id),
        total_order_start: totalOrderQty?.[0],
        total_order_end: totalOrderQty?.[1],
        total_value_order_start: totalOrderValue?.[0],
        total_value_order_end: totalOrderValue?.[1],
        created_start: from?.[0] ? moment(from?.[0]).valueOf() : null,
        created_end: from?.[1] ? moment(from?.[1]).valueOf() : null,
        age_start: age?.[0],
        age_end: age?.[1],
        gender,
        customer_type: customerType,
      },
      isNil
    );
    return listAllCustomer(body, paging);
  };

  const transformer = (res: any) => {
    return res?.data?.data?.customers ?? [];
  };

  const onChangeSearch = debounce((text) => {
    searchRef.current = text;
    tableRef?.current?.refresh();
  }, 1000);

  const onFilter = (values: any) => {
    setFilterBody(values);
    tableRef?.current?.refresh();
  };

  return (
    <AppContext.Provider value={{ warehouseList, roleList }}>
      <div className="p-4 ">
        <HeaderTable
          label={t("allCustomers")}
          onChangeText={(event: any) => onChangeSearch(event.target.value)}
          onClickNew={() => navigate(Path.CUSTOMER_CREATE)}
          onClickFilter={() => setOpenFilter(true)}
          //   onClickExport={() => setOpenExport(true)}
          isFiltered={isFiltered}
          customView={() => (
            <Select
              value={searchType}
              dataSource={map(CUSTOMER_SEARCH_OPTIONS, (item) => ({
                ...item,
                label: t(item.label),
              }))}
              onChange={(valueId) => setSearchType(valueId)}
              getLabel={(item) => item.label}
              classNameSelect="height-[37px]"
              placeholder={t("pleaseSelect")}
            />
          )}
        />
        <AwesomeTableComponent
          ref={(ref) => {
            tableRef.current = ref;
          }}
          columns={columns}
          source={source}
          transformer={transformer}
          className="mt-3"
        />
        {openFilter && (
          <CustomerTableFilterDrawer
            open={openFilter}
            onClose={() => setOpenFilter(false)}
            onFilter={onFilter}
            defaultValue={filterBody}
          />
        )}
        {/* {openExport && (
          <CustomerTableExportModal
            open={openExport}
            onClose={() => setOpenExport(false)}
          />
        )} */}
      </div>
    </AppContext.Provider>
  );
};

export default CustomerTable;
