import AppIcon from "@common/Icon";
import UserAvatarName from "@components/shared/UserAvatarName";
import { DEPOSIT_STATUS_LIST } from "@constants/cash-deposit";
import AppContext from "@helpers/context";
import { API } from "@network";
import CashDepositAPI from "@network/api/cash-deposit";
import { Dropdown } from "antd";
import {
  Button,
  DialogManager,
  Icon,
  Progress,
  TimeUtils,
  ViewLabelStatus,
} from "d-react-components";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Sticky from "react-sticky-el";

const CashDepositDetailHeader = () => {
  const { cashDepositDetail, loadCashDepositDetail } =
    React.useContext(AppContext);
  const { t } = useTranslation();

  const OPTION = useMemo(() => {
    return [
      {
        label: t("print"),
        key: "print",
      },
      {
        label: t("cancel"),
        key: "cancel",
      },
    ];
  }, [t]);

  const handleChooseAction = (item: any) => {
    if (item.key === "print") {
      CashDepositAPI.logPrint({
        depositid: cashDepositDetail?.id,
        print: "deposit",
      });
      window.print();
    }
    if (item.key === "cancel") {
      DialogManager.showConfirm(t("confirm"), t("sureToCancel"), () => {
        onCancelDeposit();
      });
    }
  };

  const onCancelDeposit = () => {
    Progress.show(
      { method: CashDepositAPI.cancel, params: [{ id: cashDepositDetail.id }] },
      (res: any) => {
        loadCashDepositDetail();
      }
    );
  };

  return (
    <div className="bg-white relative z-[60] shadow-sm">
      <Sticky
        boundaryElement=".main-content"
        scrollElement=".main-content"
        stickyClassName="header-sticky"
        wrapperClassName="group w-full"
      >
        <div className="flex flex-col lg:flex-row border-b p-3 bg-white">
          <div className="flex-1 flex flex-col md:flex-row md:items-center mb-[0.5rem] lg:mb-0  group-[.header-sticky]:mb-0">
            <div className="flex items-center mb-[0.5rem] md:mb-0  group-[.header-sticky]:mb-0">
              <div id="orderIdContainer" className="mr-3 flex items-center">
                <span className="font-semibold text-primary">
                  {cashDepositDetail.ref}
                </span>
                {/* <AppIcon
                  onClick={() => setShowUpdateName(true)}
                  className="w-[15px] cursor-pointer ml-2"
                  src="/images/icons/edit.svg"
                /> */}
              </div>
            </div>
            <div className="flex items-center group-[.header-sticky]:hidden md:group-[.header-sticky]:flex flex-wrap">
              <ViewLabelStatus
                status={cashDepositDetail?.status}
                listStatus={DEPOSIT_STATUS_LIST}
                className="mr-2 mb-1 whitespace-nowrap"
                getLabel={(item) => t(item.label)}
              />
            </div>
          </div>
          <div className="flex items-center group-[.header-sticky]:hidden md:group-[.header-sticky]:flex">
            <Dropdown
              trigger={["click"]}
              menu={{ items: OPTION, onClick: handleChooseAction }}
            >
              <Button
                variant="outline"
                content={t("action")}
                suffixIcon="arrow_drop_down"
                size="small"
                className="ml-2"
              />
            </Dropdown>
          </div>
        </div>
      </Sticky>
      <div className="flex p-3 flex-col md:flex-row items-start md:items-center">
        {/* <div className="d-flex mr-3 mb-[0.5rem] md:mb-0">
          <Icon name="article" />
          <UserAvatarName user={cashDepositDetail?.by} />
        </div> */}
        <div className="d-flex mr-3 mb-[0.5rem] md:mb-0">
          <Icon name="date_range" />
          <div className="text-xs ml-1 text-gray-500">{`${t(
            "order:createdOn"
          )} ${TimeUtils.toDateTime(cashDepositDetail?.created)}`}</div>
        </div>
      </div>
    </div>
  );
};

export default CashDepositDetailHeader;
