/* eslint-disable guard-for-in */

import Drawer from "@components/shared/Drawer";
import { PRODUCT_TYPE } from "@constants/product";
import { IProduct } from "@interfaces/product";
import {
  AwesomeTableComponent,
  Button,
  Checkbox,
  InputTextSearch,
  Modal,
  Notifications,
  StringUtils,
} from "d-react-components";
import {
  debounce,
  every,
  filter,
  find,
  isEmpty,
  join,
  map,
  uniqBy,
} from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import ProductFilterInSearchButton from "./ProductFilterInSearchButton";
import ProductName from "./ProductName";

const INIT_FILTER = {
  brand: [],
  category: [],
};

interface IProductSearchModal {
  open: boolean;
  onClose: () => void;
  onChange?: (values: any) => void;
  defaultValue?: IProduct[];
  isStockConsider?: boolean;
  isShowSelectAll?: boolean;
  isExpandable?: boolean;
  isSingleSelect?: boolean;
  isHideAvaQtyCurrentStore?: boolean;
  isHideOnHoldQtyCurrentStore?: boolean;
  isDisableSelect?: (param: any) => boolean;
  source: any;
  dataKey?: string;
}

interface IProductSearchButton
  extends Omit<IProductSearchModal, "open" | "onClose"> {
  buttonText?: any;
  isInvalidateOpenSearch?: () => boolean;
  className?: any;
}

const ProductSearchModal = ({
  open,
  onClose,
  onChange,
  defaultValue = [],
  isStockConsider = true,
  isShowSelectAll = true,
  isExpandable = false,
  isSingleSelect = false,
  isHideAvaQtyCurrentStore,
  isHideOnHoldQtyCurrentStore,
  isDisableSelect = () => false,
  source = () => Promise.resolve([]),
  dataKey = "product",
}: IProductSearchModal) => {
  const [inputSearch, setInputSearch] = useState("");
  const productItemTable = useRef<any>(null);
  const [selectingProduct, setSelectingProduct] = useState(defaultValue);
  const [currentProductList, setCurrentProductList] = useState([]);
  const [filterBody, setFilterBody] = useState<any>(INIT_FILTER);
  const { t } = useTranslation();

  const getContentFilterItem = (valueFilter: any) => {
    return join(
      map(valueFilter, (item) => item.name),
      ", "
    );
  };

  const filterListData = useMemo(() => {
    const filterListResult: any = [];
    Object.keys(filterBody).forEach((key) => {
      const valueFilter = filterBody[key];
      if (valueFilter && valueFilter.length > 0) {
        filterListResult.push({
          id: key,
          label: t(key),
          content: getContentFilterItem(valueFilter),
        });
      }
    });
    return filterListResult;
  }, [filterBody]);

  const onSaveFilter = (filterBody: any) => {
    setFilterBody(filterBody);
  };

  const onRemoveFilter = (filterItem: any) => {
    const result = { ...filterBody };
    result[filterItem.id] = (INIT_FILTER as any)[filterItem.id];
    setFilterBody(result);
  };

  const isCheckAll = useMemo(() => {
    if (currentProductList.length === 0 || selectingProduct.length === 0) {
      return false;
    }
    return every(currentProductList, (productItem: any) => {
      const selectingItem = selectingProduct.find(
        (item) => item.product.id === productItem?.product?.id
      );
      return !!selectingItem;
    });
  }, [currentProductList, selectingProduct]);

  const onSetSelectAll = () => {
    if (!isCheckAll) {
      const results = [...selectingProduct, ...currentProductList];
      const uniqResults = uniqBy(results, (item) => item.product.id);
      setSelectingProduct(uniqResults);
    } else {
      const filteredResults = selectingProduct.filter((productItem) => {
        const currentPro = find(
          currentProductList,
          (item: any) => item?.product?.id === productItem.product.id
        );
        return !currentPro;
      });
      setSelectingProduct(filteredResults);
    }
  };

  const renderSubProductNameView = (product: any) => {
    const subView = [];
    if (product.product_type === PRODUCT_TYPE.GROUP_CUSTOM_SIZE) {
      subView.push(
        <div className="small bg-dark width-fit-content ml-2 px-2 py-1 text-white">
          {t("specialSize")}
        </div>
      );
    }
    return subView;
  };

  const columns = [
    {
      title: t("name"),
      dataIndex: "",
      width: 550,
      ellipsis: true,
      align: "left",
      render: (product: IProduct) => {
        return (
          <ProductName
            item={product}
            renderSubName={() => renderSubProductNameView(product)}
          />
        );
      },
    },

    {
      isHide: isHideAvaQtyCurrentStore,
      title: t("availableQtyCurrentStore"),
      titleTooltip: t("availableQtyCurrentStoreTooltip"),
      dataIndex: "product",
      width: 200,
      render: (product: any) => product?.stock - product?.stock_hold,
    },

    {
      isHide: isHideOnHoldQtyCurrentStore,
      title: t("onHoldQtyCurrentStore"),
      titleTooltip: t("onHoldQtyCurrentStoreTooltip"),
      dataIndex: "product",
      width: 200,
      render: (product: any) => product?.stock_hold,
    },
    {
      title: t("availableQtyDelivery"),
      titleTooltip: t("availableQtyDeliveryTooltip"),
      dataIndex: "product",
      width: 200,
      render: (product: any) => product?.delivery - product?.delivery_hold,
    },
    {
      title: t("onHoldQtyDelivery"),
      titleTooltip: t("onHoldQtyDeliveryTooltip"),
      dataIndex: "product",
      width: 200,
      render: (product: any) => product?.delivery_hold,
    },

    {
      title: t("regularPrice"),
      dataIndex: "regular_price",
      width: 200,
      render: (price: any) => StringUtils.moneyThaiFormat(price),
    },
    {
      title: t("salePrice"),
      dataIndex: "sale_price",
      width: 200,
      render: (price: any) => StringUtils.moneyThaiFormat(price),
    },
    {
      title: isShowSelectAll && (
        <Checkbox onClick={onSetSelectAll} checked={isCheckAll} />
      ),
      dataIndex: "",
      width: 50,
      render: (productItem: any) => {
        const isSelected =
          selectingProduct &&
          selectingProduct.filter(
            (product) => product.productid === productItem.productid
          ).length > 0;
        return (
          <Checkbox
            checked={isSelected}
            onClick={() => onClickSelectProduct(productItem, isSelected)}
            disabled={isDisableSelect(productItem)}
          />
        );
      },
    },
  ];

  useEffect(() => {
    productItemTable.current && productItemTable.current.refresh();
  }, [inputSearch, filterBody]);

  function onClickSelectProduct(product: IProduct, isSelected: boolean) {
    if (isSingleSelect) {
      setSelectingProduct([product]);
      return;
    }

    if (isSelected) {
      handleRemoveProductFromCart(product);
    } else {
      handleAddProductToCart(product);
    }
  }

  function handleAddProductToCart(product: IProduct) {
    const notAvailable =
      product?.product?.delivery === 0 &&
      !product?.product?.selling_out_of_stock;
    if (isStockConsider && notAvailable) {
      Notifications.showError(t("thisItemOutOfStock"));
      return;
    }
    setSelectingProduct([...selectingProduct, product]);
  }

  function handleRemoveProductFromCart(product: IProduct) {
    const clone =
      selectingProduct &&
      selectingProduct?.filter(
        (item) => item?.productid !== product?.productid
      );
    setSelectingProduct(clone);
  }

  const onChangeTextSearch = debounce((text) => {
    setInputSearch(text);
  }, 1000);

  const dataSource = (paging: any) => {
    if (isEmpty(inputSearch) && filterListData.length === 0) {
      return Promise.resolve([]);
    }
    return source(inputSearch, filterBody, paging);
  };

  const transformExpandableProduct = (item: any) => {
    const isGroup = item?.product?.is_group ?? false;
    if (isGroup) {
      const editedGroups = item?.product?.groups.map((subPro: any) => ({
        ...subPro,
        productid: subPro?.product?.id,
        product: {
          ...subPro.product,
          stock: subPro?.quantity * item?.product?.stock,
          total_stock: subPro?.quantity * item?.product?.total_stock,
        },
      }));
      const cloneItem = {
        ...item,
        children: [...editedGroups],
        id: item?.productid ?? Math.random(),
      };
      return cloneItem;
    }
    return { ...item, id: item?.productid ?? Math.random() };
  };

  const transformer = (response: any) => {
    let productList = response?.data?.data?.[dataKey] ?? [];

    if (isExpandable) {
      productList = map(productList, transformExpandableProduct);
    }

    setCurrentProductList(productList);

    return productList;
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("browseItems")}
      onSave={() => {
        onChange && onChange(selectingProduct);
        onClose();
      }}
      size="auto"
      width={"80%"}
    >
      <div>
        <div className="row">
          <div className="col-12 col-sm-10">
            <InputTextSearch
              placeholder={t("search")}
              onChange={(e: any) => onChangeTextSearch(e.target.value)}
            />
          </div>

          <div className="col-12 col-sm-2 justify-content-end d-flex">
            <ProductFilterInSearchButton
              onSaveFilter={onSaveFilter}
              classNameButton="ml-0"
            />
          </div>
          {/* <div className="col-12 mt-3">
            <FilterView
              filterList={filterListData}
              onRemoveFilter={onRemoveFilter}
              onRemoveAllFilter={() => setFilterBody(INIT_FILTER)}
            />
          </div> */}
          <div
            className="d-flex justify-content-end col-12 mt-3"
            hidden={selectingProduct.length === 0}
          >
            <text className="text-primary">{`${selectingProduct.length} ${t(
              "productsSelected"
            )}`}</text>
          </div>
          <AwesomeTableComponent
            ref={(ref) => (productItemTable.current = ref)}
            source={(currentPage) => dataSource(currentPage)}
            transformer={(res) => transformer(res)}
            columns={filter(columns, (item) => !item.isHide) as any}
            isScroll={false}
            className="height-max-content col-12 mt-3 overflow-auto"
          />
        </div>
      </div>
    </Drawer>
  );
};

const ProductSearchButton = ({
  buttonText = "Search",
  isInvalidateOpenSearch = () => false,
  className = "",
  dataKey = "product",
  ...searchModalProps
}: IProductSearchButton) => {
  const [openSearch, setOpenSearch] = useState(false);

  const handleOnClickSearchProduct = () => {
    if (isInvalidateOpenSearch()) {
      return;
    }
    setOpenSearch(true);
  };

  return (
    <div className={className}>
      <Button iconName="search" onClick={handleOnClickSearchProduct}>
        {buttonText}
      </Button>
      {openSearch && (
        <ProductSearchModal
          open={openSearch}
          onClose={() => setOpenSearch(false)}
          dataKey={dataKey}
          {...searchModalProps}
        />
      )}
    </div>
  );
};

export default ProductSearchButton;
