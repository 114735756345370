import React, { useContext } from "react";
import { Button, InputText } from "d-react-components";
import { useTranslation } from "react-i18next";
import AppContext from "@helpers/context";
import { isEmpty, join, split } from "lodash";
import { Spin } from "antd";

const CheckSKUCopyPaste = () => {
  const { t } = useTranslation();
  const {
    copyPasteData,
    setCopyPasteData,
    handleOnClickCheckPasteData,
    onProgress,
  } = useContext(AppContext);

  return (
    <div className="checkSKUPageContainer-copyPaste">
      <div className="mb-3">
        <div>{t("checkByCopyingAndPasting")}</div>
        <div>
          <span style={{ fontWeight: "bold" }}>{t("remark")}:</span>{" "}
          {t("only1SkuPerRow")}
        </div>
      </div>
      <InputText
        multiple
        placeholder={
          "8888888888880\n8888888888881\n8888888888882\n8888888888883\n..."
        }
        rows={10}
        value={copyPasteData}
        onChange={(event) => {
          const text = event?.target?.value;
          const convertArray = split(text, "\n");
          const filterArray = convertArray.map((string) =>
            string.replace(/\s/g, "")
          );
          const finalResult = join(filterArray, "\n");
          setCopyPasteData(finalResult);
        }}
        className="mb-3"
      />

      <Button
        onClick={handleOnClickCheckPasteData}
        disabled={isEmpty(copyPasteData)}
      >
        {onProgress ? <Spin /> : t("check")}
      </Button>
    </div>
  );
};

export default CheckSKUCopyPaste;
