;


export const INVENTORY_EVENT_STATUSES = [
  { id: "in-use", label: "inUse", color: "#33B950" },
  { id: "expired", label: "expired", color: "#F44336" },
];

export const INVENTORY_EVENT_STATUS = {
  IN_USE: "in-use",
  EXPIRED: "expired",
};

export const EVENT_VISIBILITY_STATUSES = [
  { id: "enabled", label: "enabled", color: "#33B950" },
  { id: "disabled", label: "disabled", color: "#F44336" },
];
