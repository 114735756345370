import { IChain } from "@interfaces/chain";
import { IFieldSelect } from "@interfaces/select";
import { getListChain } from "@network/api/chain";
import { ObjectUtils, Select } from "d-react-components";
import { isArray, map } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export interface IChainSelect<T> extends IFieldSelect<T> {
  [key: string]: any;
}

const ChainSelect = ({
  value = [],
  multiple,
  className,
  onChange,
  ...selectProps
}: IChainSelect<IChain>) => {
  const [chainList, setChainList] = useState<any[]>(
    isArray(value) ? value : [value]
  );

  const { t } = useTranslation();

  const onChangeValue = (id: any) => {
    if (multiple) {
      onChange(map(id, (item) => ObjectUtils.findItemFromId(chainList, item)));
      return;
    }
    onChange(ObjectUtils.findItemFromId(chainList, id));
  };

  const chainValue = useMemo(() => {
    if (multiple) {
      return map(value, (item: any) => item.id);
    }
    return (value as any)?.id;
  }, [value]);

  useEffect(() => {
    loadChainList();
  }, []);

  const loadChainList = () => {
    getListChain().then((res) => {
      const chainList = res?.data?.data?.chains ?? [];
      setChainList(chainList);
    });
  };

  return (
    <Select
      className={className}
      label={t("chain")}
      dataSource={chainList}
      getLabel={(item) => item?.name}
      value={chainValue}
      onChange={onChangeValue}
      multiple={multiple}
      placeholder={t("pleaseSelect")}
      showSearch
      {...selectProps}
    />
  );
};

export default ChainSelect;
