import { SelectInfinity } from "@components/shared/SelectInfinity";
import { PICKUP_TYPES } from "@constants/order";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import AppContext from "@helpers/context";
import { listUserStore } from "@network/api/store";
import { Button, InputText, InputTextSearch, Select } from "d-react-components";
import { debounce, isEmpty, join, map } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import OrderCreateModernTradeDiscount from "../components/MTDiscount";
import { StyledForm } from "../components/StyledForm";
import OrderDetailProductFilter from "./OrderDetailProductFilter";
import { useLocalStorage, useMedia } from "react-use";
import { searchSalePerson } from "@network/api/order";
import UserAvatarName from "@components/shared/UserAvatarName";
import { ADMIN_AUTH_KEY } from "@constants";
import { IAdmin } from "@interfaces/admin";
import classNames from "classnames";
import { OrderCreateType } from "./OrderDetail";

const OrderDetailHeader = () => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<boolean>(true);
  const [adminProfile] = useLocalStorage<IAdmin>(ADMIN_AUTH_KEY);
  const isMobile = useMedia("(max-width: 767px)");
  const {
    values,
    setValues,
    setFieldValue,
    listChannel,
    sourceSaleOrderList,
    onSelectStore,
    setProductList,
    setProductCustomList,
    setDiscountChainList,
    setOriginOrder,
    setExternalDiscountList,
    setFreeGiftList,
    filterBody,
    setFilterBody,
    viewMode,
    setViewMode,
    setInputSearch,
    originOrder,
    type,
    productList,
  } = useContext(AppContext);
  const {
    channel,
    warehouse,
    sourceSaleOrder,
    pickupType,
    receiptRef,
    salesPerson,
    isChainExternal,
  } = values;

  const isEventSale = useMemo(() => {
    return values.mtOrderType === "event_sale";
  }, [values.mtOrderType]);

  const onSaveFilter = (filterBody: any) => {
    setFilterBody(filterBody);
  };

  const onChangeChannel = (channel: any) => {
    setValues({
      ...values,
      channel: channel,
      warehouse: undefined,
      selectedCustomer: null,
      shippingProfile: [],
      billingProfile: {},
      pickupType: null,
      storeSelected: null,
      isChainExternal: false,
      mtOrderType: undefined,
      gpDiscount: undefined,
      receiptRef: "",
      sourceSaleOrder: null,
    });
    setProductList([]);
    setProductCustomList([]);
    setDiscountChainList([]);
    setOriginOrder(null);
    setExternalDiscountList([]);
    setFreeGiftList([]);
  };

  const onChangeTextSearch = debounce((text) => {
    setInputSearch(text);
  }, 1000);

  const getContentFilterItem = (valueFilter: any) => {
    return join(
      map(valueFilter, (item) => item?.name),
      ", "
    );
  };

  const filterListData = useMemo(() => {
    const filterListResult: any[] = [];
    Object.keys(filterBody).forEach((key) => {
      const valueFilter = filterBody[key];
      if (valueFilter && valueFilter.length > 0) {
        filterListResult.push({
          id: key,
          label: t(key),
          content: getContentFilterItem(valueFilter),
        });
      }
    });
    return filterListResult;
  }, [filterBody]);

  const currentPerson = useMemo(
    () => ({
      id: adminProfile?.id,
      value: adminProfile?.id,
      name: adminProfile?.fullname,
      avatar: adminProfile?.avatar,
      employee_id: adminProfile?.companyId,
    }),
    [adminProfile]
  );

  useEffect(() => {
    //check for sales person count
    try {
      searchSalePerson({}, { page: 1, per_page: 1 }).then((resp) => {
        const count = resp?.data?.data?.pagination?.items;
        if (count === 0) {
          setFieldValue("salesPerson", [currentPerson]);
        }
      });
    } catch (err) {}
  }, []);

  const customParams = useMemo(() => ({ channel }), [channel]);

  return (
    <div
      className="relative mb-2 overflow-hidden transition-all"
      {...(!isMobile
        ? {
            style: {
              height: expanded ? (isEventSale ? "360px" : "300px") : "64px",
            },
          }
        : {})}
    >
      <StyledForm className="grid grid-cols-3 gap-3">
        <div className="col-span-3 md:col-span-1 md:mb-2">
          <Select
            dataSource={listChannel}
            value={channel}
            onChange={(val) => {
              onChangeChannel(val);
              setFieldValue(
                "selectedChannel",
                listChannel.find((item: any) => item.id === val)
              );
            }}
            getLabel={(item) => item.name}
            label={t("channel")}
            placeholder={t("pleaseSelect")}
            disabled={!isEmpty(originOrder)}
            showSearch
          />
        </div>
        <div className="col-span-3 md:col-span-1 mb-2">
          <SelectInfinity
            fetchFn={listUserStore}
            key="selectWarehouse"
            dataPath="stores"
            value={warehouse}
            label={t("warehouse")}
            customQuery={(search) => ({
              search,
              channelid: channel,
            })}
            customParams={customParams}
            getLabel={(item) => item?.name}
            dropdownHeight={540}
            getLabelDropdownItem={(item) => (
              <div className="border-b pb-3">
                {item?.name}
                <div className="text-xs bg-blue-100 px-3 py-2 my-2 arrow-up border-b-blue-100 relative w-full">
                  <div className="flex border-b border-gray-300 pb-1">
                    <div className="flex-1">{t("systemCode")}</div>
                    <div>{item?.warehouse_system_code}</div>
                  </div>
                  <div className="flex border-b border-gray-300 py-1">
                    <div className="flex-1">{t("navArCode")}</div>
                    <div>{item?.code}</div>
                  </div>
                  <div className="flex pt-1">
                    <div className="flex-1">{t("navPickupCode")}</div>
                    <div>{item?.pickup_code}</div>
                  </div>
                </div>
                <Button
                  variant="outline"
                  className="w-100 pointer-events-none"
                  size="small"
                >
                  {t("select")}
                </Button>
              </div>
            )}
            disabled={!channel || !isEmpty(originOrder)}
            onChange={(val) => {
              setFieldValue("warehouse", val);
              onSelectStore(val?.[0]);
            }}
            placeholder={t("pleaseSelect")}
          />
        </div>
        <div className="col-span-3 md:col-span-1 mb-2">
          <SelectInfinity
            fetchFn={searchSalePerson}
            key="selectSalesPerson"
            dataPath="admin"
            value={salesPerson}
            label={t("salesPerson")}
            customQuery={(search) => ({
              search,
            })}
            additionalOptions={[currentPerson]}
            getLabel={(item) =>
              item
                ? `
                ${item?.name} 
                ${item?.employee_id ? ` (ID: ${item?.employee_id})` : ""}
              `
                : undefined
            }
            getLabelDropdownItem={(item: any) => (
              <div className="flex align-items-center">
                <UserAvatarName user={item} size="xx-small" showPopup={false} />{" "}
                {item?.employee_id ? ` (ID: ${item?.employee_id})` : ""}
              </div>
            )}
            disabled={!channel}
            onChange={(val) => {
              setFieldValue("salesPerson", val);
            }}
            placeholder={t("pleaseSelect")}
          />
        </div>
      </StyledForm>
      <StyledForm
        className={classNames("grid grid-cols-2 gap-3 md:gap-y-0", {
          "md:grid-cols-3": isEventSale,
          "md:grid-cols-5": !isEventSale,
        })}
      >
        <div className="col-span-2 md:col-span-1 mb-2">
          <Select
            dataSource={sourceSaleOrderList}
            value={sourceSaleOrder}
            getLabel={(item) => item.name}
            label={t("sourceSaleOrder")}
            placeholder={t("pleaseSelect")}
            onChange={(val) => {
              setFieldValue("sourceSaleOrder", val);
            }}
          />
        </div>
        <div className="col-span-2 md:col-span-1 mb-2">
          <Select
            dataSource={PICKUP_TYPES?.map((item) => ({
              ...item,
              label: t(item.label),
            }))}
            value={pickupType}
            getLabel={(item) => item.label}
            label={t("pickupType")}
            placeholder={t("pleaseSelect")}
            onChange={(val) => {
              setFieldValue("pickupType", val);
            }}
          />
        </div>
        {type === OrderCreateType.NORMAL && <OrderCreateModernTradeDiscount />}
        {type === OrderCreateType.NORMAL && (
          <div className="col-span-2 md:col-span-1 mb-2">
            <InputText
              label={t("receiptRef")}
              value={receiptRef}
              onChange={(event) =>
                setFieldValue("receiptRef", event.target.value)
              }
              disabled={!isChainExternal}
              placeholder={t("pleaseInput")}
            />
          </div>
        )}
      </StyledForm>
      <div className="pt-3 border-t">
        <div className="mb-2">
          <InputTextSearch
            className="bg-white"
            placeholder={t("search")}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              onChangeTextSearch(e?.currentTarget?.value)
            }
            disabled={!warehouse}
          />
        </div>

        <StyledForm className="grid grid-cols-3 gap-4">
          <div className="col-span-3 md:col-span-2">
            <OrderDetailProductFilter
              onSaveFilter={onSaveFilter}
              setFilterBody={setFilterBody}
              filterBody={filterBody}
              filterListData={filterListData}
            />
          </div>
          {!isMobile && (
            <div className="col-span-1 flex justify-content-end pt-4">
              <Button
                onClick={() => setViewMode("grid")}
                iconName="apps"
                color={viewMode === "grid" ? "primary" : "light"}
                className="mr-2"
              ></Button>
              <Button
                onClick={() => setViewMode("list")}
                iconName="table_rows"
                color={viewMode === "list" ? "primary" : "light"}
              ></Button>
            </div>
          )}
        </StyledForm>
      </div>
      {!isMobile && (
        <StyledExpandBlock
          expanded={expanded}
          className="absolute py-2 text-center w-full bottom-0 left-0 flex items-end justify-content-center cursor-pointer"
          onClick={() => setExpanded(!expanded)}
        >
          <div>
            <span className="m_scroll_arrows one"></span>
            <span className="m_scroll_arrows two"></span>
            {/* <span className="m_scroll_arrows three"></span> */}
          </div>
        </StyledExpandBlock>
      )}
    </div>
  );
};

const mouseScroll = keyframes`
  0%   { opacity: 0; }
  20%   { opacity: 0.5; }
  40%   { opacity: 1; }
  100% { opacity: 1; }
`;

const StyledExpandBlock: any = styled.div`
  ${(props: any) =>
    !props.expanded &&
    `
  height: 80px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  `}
  ${(props: any) =>
    props.expanded &&
    `
    height: auto;
    position: relative;
  `}

  .m_scroll_arrows {
    display: block;
    -ms-transform: rotate(${(props: any) => (props.expanded ? 225 : 45)}deg);
    -webkit-transform: rotate(
      ${(props: any) => (props.expanded ? 225 : 45)}deg
    );
    transform: rotate(${(props: any) => (props.expanded ? 225 : 45)}deg);
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    margin: 0 0 4px 4px;
    width: 6px;
    height: 6px;
  }
  .one,
  .two,
  .three {
    -webkit-animation: ${mouseScroll} 3s infinite;
    -moz-animation: ${mouseScroll} 3s infinite;
    animation: ${mouseScroll} 3s infinite;
  }

  .one {
    -webkit-animation-delay: 0.1s;
    -moz-animation-delay: 0.1s;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    animation-delay: alternate;
  }

  .two {
    -webkit-animation-delay: 0.4s;
    -moz-animation-delay: 0.4s;
    -webkit-animation-direction: alternate;
    animation-delay: 0.2s;
    animation-direction: alternate;
    margin-top: -6px;
  }

  .three {
    -webkit-animation-delay: 0.7s;
    -moz-animation-delay: 0.7s;
    -webkit-animation-direction: alternate;
    animation-delay: 0.3s;
    animation-direction: alternate;
    margin-top: -6px;
  }
`;

export default OrderDetailHeader;
