import AppContext from "@helpers/context";
import { Header, Notifications, Progress, TabBar } from "d-react-components";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PromotionCreateGeneralInformation from "./PromotionCreateGeneralInformation";
import PromotionCreateListOfItems from "./PromotionCreateListOfItems";
import PromotionCreateDocuments from "./PromotionCreateDocuments";
import { isEmpty } from "lodash";
import * as Yup from "yup";
import {
  createPromotion,
  getPromotionDetail,
  uploadPromotionDocument,
} from "@network/api/promotion";
import { useNavigate, useParams } from "react-router-dom";
import { Path } from "@components/layout/Path";
import moment from "moment";
import { PromotionPublishStatus } from "@constants/promotion";

const TAB_LIST = [
  {
    id: "general",
    label: "generalInformation",
    component: <PromotionCreateGeneralInformation />,
  },
  {
    id: "listOfItems",
    label: "listOfItems",
    component: <PromotionCreateListOfItems />,
  },
  {
    id: "documents",
    label: "documents",
    component: <PromotionCreateDocuments />,
  },
];

const PromotionCreate = () => {
  const [selectedTab, setSelectedTab] = useState<any>(TAB_LIST[0]);
  const [promotionProducts, setPromotionProducts] = useState<any[]>([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { promotionId } = useParams();
  const isEdit = false;
  const promotionForm = useFormik<any>({
    initialValues: {},
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      title: Yup.string().required(t("fieldRequired")),
      memo: Yup.string().required(t("fieldRequired")),
      start: Yup.mixed().required(t("fieldRequired")),
      end: Yup.mixed().required(t("fieldRequired")),
      channel: Yup.mixed().required(t("fieldRequired")),
    }),
    onSubmit: (values: any) => {
      const { title, memo, start, end, channel, status, attachments } = values;
      const body = {
        name: title,
        description: memo,
        start: start.format("x"),
        end: end.format("x"),
        channelids: channel && channel.map((c: any) => c.id),
        products: promotionProducts,
        status: PromotionPublishStatus.PUBLISHED,
        documents: attachments?.map((a: any) => a?.key),
      };
      const { validate, message } = validateInput(body);
      if (!validate) {
        Progress.show(
          { method: createPromotion, params: [body] },
          (res) => {
            // if (!isEmpty(attachments)) {
            //   uploadFileHandle(res);
            // } else {
            Notifications.showSuccess(t("createPromotionSuccess"));
            navigate(Path.PROMOTION);
            // }
          },
          (error: any) => {
            Notifications.showError(error?.message);
          }
        );
      } else {
        Notifications.showError(message);
      }
    },
  });

  const uploadFileHandle = (respone: any) => {
    const { attachments } = promotionForm?.values;
    const APIList: any = [];
    const id = respone?.data?.data?.promotion?.id ?? null;
    attachments.forEach((file: any) => {
      const data = new FormData();
      data.append("file", file?.fileData);
      data.append("promotionid", id);
      APIList.push(uploadPromotionDocument(data));
    });
    return Promise.all(APIList)
      .then((resList) => {
        Notifications.showSuccess(
          isEdit ? t("editPromotionSuccess") : t("createPromotionSuccess")
        );
        navigate(Path.PROMOTION);
      })
      .catch((err) => {
        Notifications.showError(err);
      });
  };

  const validateInput = (body: any) => {
    const { name, description, start, end, channelids, products } = body;
    const empty =
      isEmpty(name) ||
      isEmpty(description) ||
      isEmpty(channelids) ||
      isEmpty(products);
    if (empty) {
      return { validate: true, message: t("pleaseFillAllRequireField") };
    }
    if (
      moment(end, "x") <= moment(start, "x") ||
      moment(end, "x") === moment(start, "x")
    ) {
      return { validate: true, message: t("endtimehastobegreater") };
    }
    if (moment(start, "x").isBefore(moment())) {
      return { validate: true, message: t("starttimehastobe") };
    }
    return { validate: false, message: "" };
  };

  useEffect(() => {
    if (promotionId) {
      getPromotionDetail({ id: promotionId }).then((res) => {
        const detail = res?.data?.data?.promotion;
        promotionForm.setValues({
          title: detail?.name,
          memo: detail?.description,
          start: moment(detail?.start),
          end: moment(detail?.end),
          channel: detail?.channels,
        });
        setPromotionProducts(detail?.products);
      });
    }
  }, []);

  return (
    <AppContext.Provider
      value={{ promotionForm, promotionProducts, setPromotionProducts }}
    >
      <div>
        <Header
          title={t("newPromotion")}
          onSave={() => promotionForm.handleSubmit()}
          onCancel={() => navigate(Path.PROMOTION)}
        />
        <div className="p-3">
          <div className="row">
            <div className="col-md-3">
              <TabBar
                dataSource={TAB_LIST}
                variant="vertical"
                onChange={(tab) => setSelectedTab(tab)}
                value={selectedTab}
                getLabel={(item) => t(item.label as any)}
              />
            </div>
            <div className="col-md-9">{selectedTab.component}</div>
          </div>
        </div>
      </div>
    </AppContext.Provider>
  );
};

export default PromotionCreate;
