import React, { useContext, useState } from "react";
import OrderRefundItem from "./OrderRefundItem";
import AppContext from "@helpers/context";
import { useTranslation } from "react-i18next";

const OrderRefundView = () => {
  const { refund } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <div className="my-3 p-3 bg-white ">
      <label className="mb-3">{t("refundRequest")}</label>
      {refund &&
        refund?.map((item: any) => {
          return <OrderRefundItem refund={item} />;
        })}
    </div>
  );
};

export default OrderRefundView;
