import { Avatar, Icon, InputText } from "d-react-components";
import React, { useContext, useMemo, useState } from "react";
import MAIN_MENU, { MAP_V1_MENUS } from "../MainMenu";
import MenuItem from "./MenuItem";
import { useLocalStorage } from "react-use";
import { IAdmin } from "@interfaces/admin";
import { ADMIN_AUTH_KEY } from "@constants";
import AppContext from "@helpers/context";
import Drawer from "@components/shared/Drawer";
import { useTranslation } from "react-i18next";
import DiscountAuthOTP from "./DiscountAuthOTP";
import { Scrollbars } from "react-custom-scrollbars-2";
import { filterMenu, mapServerMenu } from "@helpers/menu";
import { find } from "lodash";
import { isGrantURLPermission } from "@helpers/permissions";
import { API_REFRESH_DISCOUNT_ID } from "@network/URL";

export interface IMenuBarProps {
  [key: string]: any;
}

const MenuBar: React.FC<IMenuBarProps> = ({ id }) => {
  const [searchText, setSearchText] = useState("");
  const [adminProfile] = useLocalStorage<IAdmin>(ADMIN_AUTH_KEY);
  const { fullScreen, callCenterInfo, menus } = useContext(AppContext);
  const [openOTPDrawer, setOpenOTPDrawer] = useState(false);
  const { t } = useTranslation();

  const mainMenus = useMemo(() => {
    const v1Menus = Object.values(MAP_V1_MENUS);
    return filterMenu(
      [
        ...MAIN_MENU.filter((menu) => {
          if (find(menus, { uri: menu.v1Id })) {
            return true;
          }
          if (menu.id === "callHistory" && callCenterInfo?.has_call_center) {
            return true;
          }
          if (menu.forceShow) {
            return true;
          }
          return false;
        }),
        ...(menus
          ?.filter((i: any) => !Object.values(v1Menus).includes(i.uri))
          ?.map((i: any) => mapServerMenu(i)) ?? []),
      ],
      searchText
    );
  }, [menus, searchText, callCenterInfo]);

  return (
    <nav
      id="app-layout__sidebar"
      className={`active ${fullScreen && "full-screen"}`}
      onMouseEnter={() => {
        document
          .getElementById("app-layout__content")
          ?.classList.add("mouse-enter");
        document
          .getElementById("app-layout__sidebar")
          ?.classList.add("mouse-enter");
      }}
      onMouseLeave={() => {
        document
          .getElementById("app-layout__content")
          ?.classList.remove("mouse-enter");
        document
          .getElementById("app-layout__sidebar")
          ?.classList.remove("mouse-enter");
      }}
      style={{
        width: fullScreen ? "0px" : "auto",
        minWidth: fullScreen ? "0px" : "inherit",
        overflow: fullScreen ? "hidden" : "inherit",
      }}
    >
      <div className="">
        <div className="app-layout__sidebar-header">
          {/* <img src={"/images/logos/logo.png"} alt="" /> */}
          <div className="app-layout__logo" />
        </div>
        <div className="app-layout__sidebar-user-info relative">
          <Avatar
            src={adminProfile?.avatar}
            text={`${adminProfile?.name}`}
            alt=""
            variant="rounded"
            className="user-info-avatar"
          />
          <div className="user-info">
            <span className="text-large-bold text-light">
              {`${adminProfile?.name}`} (ID: {adminProfile?.companyId})
            </span>
            {adminProfile?.email && (
              <span className="text-small text-light mt-1">
                {`${adminProfile?.email}`}
              </span>
            )}
          </div>
        </div>
        <div className="search-menu px-3 py-2 w-full">
          <InputText
            value={searchText}
            onChange={(e) => setSearchText(e?.currentTarget?.value)}
            placeholder={t("search")}
            suffix={<Icon name="search" />}
          />
        </div>
        <Scrollbars
          className="app-layout__menu-list custom-scrollbar"
          style={{ height: `calc(100vh - 220px)` }}
          autoHide
        >
          {mainMenus.map((menuItem, index) => {
            // const allowAccess = menuItem?.permission
            //   ? isGrantMenuPermission(
            //       menuItem?.permission?.url,
            //       menuItem?.permission?.method
            //     )
            //   : true;
            // if (!allowAccess) {
            //   return <></>;
            // }
            if (
              menuItem.id === "callHistory" &&
              !callCenterInfo?.has_call_center
            ) {
              return <></>;
            }
            return <MenuItem menu={menuItem} key={index} />;
          })}
        </Scrollbars>
        {/* <div className="app-layout__menu-list fixed bottom-[15px] h-auto switch-menu">
          <div className="app-layout__menu-list-item">
            <a
              className="app-layout__menu-list-item-inner text-white user-otp-opener"
              href={getV1URL()}
            >
              <Icon name="cached" color="white" />
              <span className="text-medium app-layout__menu-list-title text-white">
                {t("switchToV1")}
              </span>
            </a>
          </div>
        </div> */}

        {/* <div
                    onClick={() => {
                        document
                            .getElementById("app-layout__sidebar")
                            ?.classList.toggle("active");
                        document
                            .getElementById("app-layout__content")
                            ?.classList.toggle("active");
                    }}
                    className="app-layout__sidebar-button-toggle border-top border-1 flex-center-y hover-pointer justify-content-center"
                    style={{ height: "50px" }}
                >
                    <Icon
                        name="keyboard_double_arrow_left"
                        color="white"
                        className="d-block"
                    />
                    <div className="text text-white app-layout__sidebar-button-toggle-text">
                        {Messages.collapseMenu}
                    </div>
                </div> */}
        {openOTPDrawer && (
          <Drawer
            title={t("discountAuthOTP")}
            open={openOTPDrawer}
            onClose={() => setOpenOTPDrawer(false)}
            hideFooter={true}
            size="auto"
            width={400}
          >
            <DiscountAuthOTP />
          </Drawer>
        )}
      </div>
    </nav>
  );
};

export default MenuBar;
