import InputSelectForm from "@common/input/InputSelectForm";
import InputTextForm from "@common/input/InputTextForm";
import CompanySelect from "@components/company/components/CompanySelect";
import { Path } from "@components/layout/Path";
import ButtonFileUpload from "@components/shared/ButtonFileUpload";
import UserSelect from "@components/user/components/UserSelect";
import {
  VEHICLE_OWNERSHIPS,
  VEHICLE_STATUS_LIST,
  VEHICLE_TYPES,
} from "@constants/vehicle";
import AppContext from "@helpers/context";
import { mapVehicleToServer } from "@interfaces/vehicle";
import { createVehicle } from "@network/api/vehicle";
import { DateInput, Header, Progress } from "d-react-components";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import * as Yup from "yup";

export const VehicleSchema = Yup.object().shape({
  type: Yup.string().required("Required field!"),
  registrationNo: Yup.string().required("Required field!"),
  brand: Yup.string().required("Required field!"),
  model: Yup.string().required("Required field!"),
  color: Yup.string().required("Required field!"),
  year: Yup.object().required("Required field!"),
  internalCode: Yup.string().required("Required field!"),
  vinNo: Yup.string().required("Required field!"),
  engineNo: Yup.string().required("Required field!"),
  terminalSerial: Yup.string().required("Required field!"),
//   remark: Yup.string().required("Required field!"),
  status: Yup.string().required("Required field!"),
});

const VehicleCreate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const vehicleForm = useFormik<any>({
    initialValues: {} as any,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: VehicleSchema,
    onSubmit: (values: any) => {
      const body = mapVehicleToServer(values);
      onCreateVehicle(body);
    },
  });

  const formValues = vehicleForm?.values;
  const formErrors = vehicleForm?.errors;

  const classNameInput = "mt-3 col-span-1";

  const onCreateVehicle = (input: any) => {
    Progress.show({ method: createVehicle, params: [input] }, (res: any) => {
      const vehicleId = res?.data?.data?.vehicle?.id;
      navigate(generatePath(Path.VEHICLE_DETAIL, { vehicleId }));
    });
  };

  return (
    <AppContext.Provider value={{ vehicleForm }}>
      <Header
        title={t("newVehicle")}
        onSave={() => vehicleForm.handleSubmit()}
      />
      <div className="p-3">
        <div className="card-container p-3 grid grid-cols-2 gap-4">
          <InputSelectForm
            keyData="type"
            dataSource={VEHICLE_TYPES}
            form={vehicleForm}
            className={classNameInput}
          />
          <InputSelectForm
            keyData="status"
            dataSource={VEHICLE_STATUS_LIST}
            form={vehicleForm}
            className={classNameInput}
          />
          <InputTextForm
            keyData="registrationNo"
            form={vehicleForm}
            className={classNameInput}
          />
          <InputTextForm
            keyData="brand"
            form={vehicleForm}
            className={classNameInput}
          />
          <InputTextForm
            keyData="model"
            form={vehicleForm}
            className={classNameInput}
          />
          <DateInput
            value={formValues?.year}
            onChange={(value) => vehicleForm.setFieldValue("year", value)}
            label={t("year")}
            className={classNameInput}
            error={formErrors?.year as any}
            required
            format="YYYY"
            type="year"
          />
          <InputTextForm
            keyData="color"
            form={vehicleForm}
            className={classNameInput}
          />
          <InputTextForm
            keyData="internalCode"
            form={vehicleForm}
            className={classNameInput}
          />
          <InputTextForm
            keyData="vinNo"
            form={vehicleForm}
            className={classNameInput}
          />
          <InputTextForm
            keyData="engineNo"
            form={vehicleForm}
            className={classNameInput}
          />
          <InputTextForm
            keyData="terminalSerial"
            form={vehicleForm}
            className={classNameInput}
          />

          <InputSelectForm
            keyData="ownership"
            form={vehicleForm}
            dataSource={VEHICLE_OWNERSHIPS}
            className={classNameInput}
          />
          <CompanySelect
            className={classNameInput}
            value={formValues?.company}
            onChange={(value) => {
              vehicleForm.setFieldValue("company", value);
            }}
          />
          <UserSelect
            label={t("defaultDriver")}
            className={classNameInput}
            value={formValues?.defaultDriver}
            onChange={(value) => {
              vehicleForm.setFieldValue("defaultDriver", value);
            }}
          />

          <div className="mt-3 col-span-2">
            <label>{t("attachments")}</label>
            <ButtonFileUpload
              //   uploadedFiles={formValues?.attachments}
              onChange={(values: any) =>
                vehicleForm.setFieldValue("documents", values)
              }
              maxFiles={5}
            />
          </div>

          <InputTextForm
            keyData="remark"
            form={vehicleForm}
            className="mt-3 col-span-2"
            multiple
          />
        </div>
      </div>
    </AppContext.Provider>
  );
};

export default VehicleCreate;
