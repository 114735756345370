import AppContext from "@helpers/context";
import { StringUtils } from "d-react-components";
import React, { useContext } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

const RawMaterialForecastUpload = () => {
  const { onCheckRawMaterial } = useContext(AppContext);
  const { t } = useTranslation();

  function onSelectFile(files: any) {
    const readerNew = new FileReader();
    readerNew.readAsText(files[0]);
    readerNew.onload = function (e) {
      const skuList = StringUtils.convertCsvJSON(readerNew.result);
      onCheckRawMaterial(skuList);
    };
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onSelectFile,
  });

  return (
    <div className="d-flex flex-column w-100">
      <text>{t("checkByUploading")}</text>
      <text>
        <text className="font-weight-bold">{t("requiredField")}: </text>
        {t("skuUnitPrice")}
      </text>

      <div
        className="border border-dotted flex-center p-5 flex-column my-5"
        {...getRootProps()}
      >
        <img src={"/images/icons/csv.png"} id="csvIconImage" />
        <input {...getInputProps()} />
        <text className="font-weight-bold">{t("dragAndDropYourCSVHere")}</text>
        <text className="text-placeholder">{t("maximumFileSizeIs5MB")}</text>
        <text className="text-primary">{t("orSelectFromYourComputer")}</text>
      </div>

      <text>{t("notSureHowToStart")}</text>
      <text>{t("downloadAsvFile")}</text>

      <text>
        {t("download")}
        <text
          className="text-primary"
          onClick={() =>
            window.open(
              `https://onlinesale.lotusbeddinggroup.com/storage/exemple/import-raw-material.csv?v=${new Date().getTime()}`
            )
          }
        >
          {t("blankCSVTemplate")}
        </text>
      </text>
      {/* <div className="mt-5">
        <CustomizedButton>{Messages.check}</CustomizedButton>
      </div> */}
      <div className="divider mt-5" />
    </div>
  );
};

export default RawMaterialForecastUpload;
