import Drawer from "@components/shared/Drawer";
import { getInventoryHoldStockList } from "@network/api/inventory";
import Modal from "antd/lib/modal/Modal";
import { AwesomeTableComponent, Button, TimeUtils } from "d-react-components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const HoldStockProductModal = ({
  open,
  onClose,
  productId,
  warehouseId,
}: any) => {
  const { t } = useTranslation();
  const source = (paging: any) => {
    return getInventoryHoldStockList(
      { product_id: productId, warehouse_id: warehouseId },
      paging
    );
  };

  const transformer = (res: any) => {
    const holdList = res?.data?.data?.hold ?? [];
    return holdList;
  };

  const columns = [
    {
      title: t("storeWarehouse"),
      dataIndex: "warehouse",
      render: (store: any) => store?.name,
    },
    {
      title: t("storeCode"),
      dataIndex: "warehouse",
      render: (store: any) => store?.code,
    },
    {
      title: t("onHoldQty"),
      dataIndex: "hold",
      width: 150,
    },
    {
      title: t("createAt"),
      dataIndex: "created",
      render: TimeUtils.toDateTime,
    },
    {
      title: t("ref"),
      dataIndex: "ref",
      width: 150,
    },
  ];

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("onHoldAllocation")}
      size="auto"
      width="50%"
    >
      <AwesomeTableComponent
        columns={columns}
        source={source}
        transformer={transformer}
      />
    </Drawer>
  );
};

const InventoryOnHoldInfo = ({ product, stockInfo }: any) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div>
      <Button variant="trans" onClick={() => setOpenModal(true)}>
        {stockInfo?.hold}
      </Button>

      {openModal && (
        <HoldStockProductModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          productId={product?.id}
          warehouseId={stockInfo?.warehouse?.id}
        />
      )}
    </div>
  );
};

export default InventoryOnHoldInfo;
