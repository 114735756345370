import { API } from "@network";
import {
  API_ADD_COMMENT,
  API_LIST_COMMENT,
  API_LIST_MENU,
  API_UPDATE_FIREBASE_TOKEN,
  API_UPLOAD_FILE,
} from "@network/URL";

export const addComment = (body: any) => {
  return API.post(API_ADD_COMMENT, body);
};

export const getListComment = (orderid: string, label: any, pageIndex: any) => {
  return API.post(`${API_LIST_COMMENT}?page=${pageIndex}`, {
    orderid,
    label,
  });
};

export const uploadFile = (body: any) => {
  return API.post(API_UPLOAD_FILE, body);
};

export const getListMenu = (params = {}) => {
  return API.get(API_LIST_MENU, params);
};

export const updateFirebaseToken = (token: string) => {
  return API.post(API_UPDATE_FIREBASE_TOKEN, { token }, {
    notShowError: true,
  } as any);
};
