import { PRODUCT_TYPES } from "@constants/product";
import AppContext from "@helpers/context";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { INIT_IMPORT_FORM } from "./ProductImport";
import { TranslatedSelect } from "@common/TranslatedSelect";

const SelectProductType = () => {
  const { step, formImport } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <div className="p-4">
      <text>{t(step?.desc)}</text>
      <TranslatedSelect
        className="mt-3"
        placeholder={t("selectProductTypeHint")}
        dataSource={PRODUCT_TYPES}
        value={formImport?.values?.productType}
        onChange={(value) =>
          formImport.setValues({ ...INIT_IMPORT_FORM, productType: value })
        }
      />
    </div>
  );
};

export default SelectProductType;
