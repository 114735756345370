import { ORDER_STATUS, ORDER_STATUS_ARRAY } from "@constants/order";
import AppContext from "@helpers/context";
import { getCustomerOrderHistory } from "@network/api/customer";
import {
  AwesomeTableComponent,
  IColumnsProps,
  StringUtils,
  TimeUtils,
  ViewLabelStatus,
} from "d-react-components";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const CustomerDetailOrders = () => {
  const { customerDetail, loadCustomerDetail } = useContext(AppContext);
  const [openEdit, setOpenEdit] = useState(false);
  const { t } = useTranslation();

  const columns: IColumnsProps = [
    {
      title: t("invoiceNo"),
      dataIndex: "orderid",
      render: (id: string, item: any) => (
        <Link to={`/order/${item.id}`}>{id}</Link>
      ),
    },
    {
      title: t("itemQty"),
      dataIndex: "quantity",
    },
    {
      title: t("spending"),
      dataIndex: "amount",
      render: StringUtils.moneyThaiFormat,
    },
    {
      title: t("status"),
      dataIndex: "status",
      width: 200,

      render: (status) => {
        return (
          <ViewLabelStatus status={status} listStatus={ORDER_STATUS_ARRAY} />
        );
      },
    },
    {
      title: t("date"),
      dataIndex: "created",
      width: 200,
      render: TimeUtils.toDate,
    },
  ];

  const source = (paging: any) => {
    const { id } = customerDetail;
    return getCustomerOrderHistory(id, paging);
  };

  return (
    <div className="mt-3 border p-4 ">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h5>{t("order")}</h5>
      </div>
      <AwesomeTableComponent
        columns={columns}
        source={source}
        transformer={(res) => res?.data?.data?.order}
        className=""
      />
    </div>
  );
};

export default CustomerDetailOrders;
