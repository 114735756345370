import InputTextForm from "@common/input/InputTextForm";
import SignatureField from "@components/order/components/pickup/SignatureField";
import ButtonFileUpload from "@components/shared/ButtonFileUpload";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

const ChannelFormSignature = ({
  form,
  defaultValue,
  fieldClassName = "",
}: any) => {
  const { t } = useTranslation();

  const classNameField = classNames("col-6 mt-3", fieldClassName);
  const formValues = form.values ?? {};

  const getDefaultFileUpload = (url: string) => {
    if (isEmpty(url)) {
      return [];
    }
    return [{ imageData: url, id: url }];
  };

  return (
    <div className="row">
      {/* <div className={classNameField}>
        <label>{t("lessorSignature")}</label>
        {formValues.lessorSignature && (
          <img
            className="w-100"
            src={formValues.lessorSignature}
            style={{ height: 250, objectFit: "contain" }}
          />
        )}
        <SignatureField
          values
          onSave={(image: any) => form.setFieldValue("lessorSignature", image)}
        />
      </div> */}
      <div className={classNameField}>
        <label>{t("lessorSignature")}</label>
        <ButtonFileUpload
          defaultFiles={getDefaultFileUpload(defaultValue?.lessorSignature)}
          onChange={([value]: any) => {
            form.setFieldValue("lessorSignature", value?.key);
          }}
          maxFiles={1}
        />
      </div>

      <InputTextForm
        keyData="lessorName"
        form={form}
        className={classNameField}
      />
      {/* <div className={classNameField}>
        <label>{t("witnessSignature")}</label>
        {formValues.witnessSignature && (
          <img
            className="w-100"
            src={formValues.witnessSignature}
            style={{ height: 250, objectFit: "contain" }}
          />
        )}
        <SignatureField
          onSave={(image: any) => form.setFieldValue("witnessSignature", image)}
        />
      </div> */}
      <div className={classNameField}>
        <label>{t("witnessSignature")}</label>
        <ButtonFileUpload
          defaultFiles={getDefaultFileUpload(defaultValue?.witnessSignature)}
          onChange={([value]: any) => {
            form.setFieldValue("witnessSignature", value?.key);
          }}
          maxFiles={1}
        />
      </div>

      <InputTextForm
        keyData="witnessName"
        form={form}
        className={classNameField}
      />
    </div>
  );
};

export default ChannelFormSignature;
