import FilterDropdown, {
  FilterDropdownProps,
} from "@common/dropdown/FilterDropdown";
import { IInventoryEvent } from "@interfaces/inventory-event";
import { getListInventoryControlEvent } from "@network/api/inventory-control-event";
import { filter, includes, isArray, map, uniqBy } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface IInventoryEventDropdownSelect {
  value: any[];
  className?: any;
  storeIds?: string[];
  status?: string | null;
  hideWhenNoEvent?: boolean;
  selectProps?: FilterDropdownProps["selectProps"];
  onChange: (values: any[]) => void;
}

const InventoryEventDropDownSelect = ({
  value = [],
  className,
  storeIds = [],
  selectProps,
  status = "in-use",
  onChange,
  hideWhenNoEvent = false,
}: IInventoryEventDropdownSelect) => {
  const [eventList, setEventList] = useState<IInventoryEvent[]>([]);

  useEffect(() => {
    onLoadEvents();
  }, []);

  const onLoadEvents = () => {
    if (!storeIds?.length) {
      setEventList([]);
      return;
    }
    const body = {
      status,
      warehouses: storeIds ?? "",
    };
    getListInventoryControlEvent(body, { pageIndex: 1, pageSize: 100 }).then(
      (res: any) => {
        const listEvent = res.data?.data?.control ?? [];

        // const selectedValue = isArray(value) ? value : [value];
        // const filterEvent = map([...selectedValue, ...listEvent], (item) => ({
        //   ...item,
        //   id: item.id,
        // }));
        // const uniqEvent = uniqBy(filterEvent, (item) => item.id);
        setEventList(listEvent);
      }
    );
  };

  const onChangeEvent = (values: any[]) => {
    // const eventSelect = filter(eventList, (item) => includes(values, item.id));
    const eventSelectMapped = map(values, (item) => ({
      id: item.id,
      title: item.title,
      code: item.code,
    }));
    onChange(eventSelectMapped);
  };

  const { t } = useTranslation();

  if (hideWhenNoEvent && eventList?.length === 0) {
    return <div />;
  }

  return (
    <FilterDropdown
      selectProps={{
        placeholder: t("search"),
        mode: "multiple",
        value,
        getLabel: (item) => item?.title ?? "N/A",
        onChange: onChangeEvent,
        className: "mb-3",
        tagTitle: t("event"),
        getValue: (item) => item.id,
        fetchFn: getListInventoryControlEvent as any,
        dataKey: "control",
        customQuery: () => ({
          status,
          warehouses: storeIds ?? "",
        }),
        ...selectProps,
      }}
      className={className}
      type="infinity"
    />
  );
};

export default InventoryEventDropDownSelect;
