/* eslint-disable import/prefer-default-export */
import { filter, includes, map, uniqBy } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const TABLE_SELECT_OPTION = {
  SELECT_ALL_THIS_PAGE: "selectAllThisPage",
  DESELECT_ALL_THIS_PAGE: "deSelectAllThisPage",
  SELECT_ALL_OF_ALL_PAGE: "selectAllOfAllPage",
  DESELECT_ALL_OF_ALL_PAGE: "deSelectAllOfAllPage",
};

export function useTableSelectionOption(
  onChange: any,
  onSelectAllOfAllPage: any
) {
  const { t } = useTranslation();
  const [tableMetaData, setTableMetaData] = useState<any>({
    totalDataLength: 0,
    listSelected: [],
    currentPageData: [],
  });
  const { listSelected, totalDataLength, currentPageData } = tableMetaData;

  const currentPageSelected = filter(currentPageData, (item: any) =>
    includes(
      map(listSelected, (itemSelected: any) => itemSelected.id),
      item.id
    )
  );

  const TableSelectionOptions = [
    {
      key: TABLE_SELECT_OPTION.DESELECT_ALL_THIS_PAGE,
      text: t("deSelectAllOnThisPage"),
      onSelect: (rowKeys: any) =>
        onChangeOption(TABLE_SELECT_OPTION.DESELECT_ALL_THIS_PAGE, rowKeys),
      getVisible: () => currentPageSelected?.length > 0,
    },
    {
      key: TABLE_SELECT_OPTION.DESELECT_ALL_OF_ALL_PAGE,
      text: t("deselectAllOfAllPage"),
      onSelect: (rowKeys: any) =>
        onChangeOption(TABLE_SELECT_OPTION.DESELECT_ALL_OF_ALL_PAGE, rowKeys),
      getVisible: () => listSelected?.length > 0,
    },
    {
      key: TABLE_SELECT_OPTION.SELECT_ALL_THIS_PAGE,
      text: t("selectAllOnThisPage"),
      onSelect: (rowKeys: any) => {
        onChangeOption(TABLE_SELECT_OPTION.SELECT_ALL_THIS_PAGE, rowKeys);
      },
      getVisible: () => currentPageData?.length > currentPageSelected?.length,
    },
    {
      key: TABLE_SELECT_OPTION.SELECT_ALL_OF_ALL_PAGE,
      text: t("selectAllOfAllPage"),
      onSelect: (rowKeys: any) =>
        onChangeOption(TABLE_SELECT_OPTION.SELECT_ALL_OF_ALL_PAGE, rowKeys),
      getVisible: () => totalDataLength > listSelected?.length,
    },
  ];

  const [selectionOptions, setSelectionOptions] = useState(
    TableSelectionOptions
  );

  useEffect(() => {
    onChangeOptionSelected();
  }, [tableMetaData]);

  const onUpdateTableMetaData = (newValue: any) => {
    setTableMetaData({ ...tableMetaData, ...newValue });
  };

  const onChangeOptionSelected = () => {
    const optionList = map(TableSelectionOptions, (item) => ({
      ...item,
      isVisible: item.getVisible(),
    }));
    const result = filter(optionList, (option) => option.isVisible);
    setSelectionOptions(result);
  };

  const onChangeOption = (optionSelect: any, rowsKey: any) => {
    if (optionSelect === TABLE_SELECT_OPTION.SELECT_ALL_OF_ALL_PAGE) {
      onSelectAllOfAllPage && onSelectAllOfAllPage();
      return;
    }

    let selectedResult: any = [];
    switch (optionSelect) {
      case TABLE_SELECT_OPTION.DESELECT_ALL_THIS_PAGE:
        selectedResult = filter(
          listSelected,
          (item: any) => !includes(rowsKey, item.id)
        );
        break;

      case TABLE_SELECT_OPTION.SELECT_ALL_THIS_PAGE:
        selectedResult = uniqBy(
          [...listSelected, ...currentPageData],
          (item: any) => item.id
        );
        break;

      default:
        break;
    }
    onUpdateTableMetaData({ listSelected: selectedResult });
    onChange(selectedResult);
  };

  return [selectionOptions, tableMetaData, onUpdateTableMetaData];
}
