import React, { useContext } from "react";
import AppContext from "@helpers/context";
import { useTranslation } from "react-i18next";
import WarehouseFormGeneral from "../components/WarehouseFormGeneral";

const WarehouseCreateGeneral = () => {
  const { warehouseForm } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <div className="card-container p-4">
      <label>{t("general")}</label>
      <WarehouseFormGeneral form={warehouseForm} />
    </div>
  );
};

export default WarehouseCreateGeneral;
