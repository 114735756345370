import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import Drawer from "@components/shared/Drawer";
import classNames from "classnames";
import { Checkbox, Icon, Notifications, Select } from "d-react-components";
import { DELIVERY_STOCK_TYPE, DELIVERY_STOCK_TYPES } from "@constants/delivery";
import DriverSelect from "@components/user/components/DriverSelect";
import InputTextForm from "@common/input/InputTextForm";
import VehicleSelect from "@components/vehicle/components/VehicleSelect";
import ButtonFileUpload from "@components/shared/ButtonFileUpload";
import { forEach, isEmpty } from "lodash";

interface IDeliveryStockAssignDriverDrawer {
  open: boolean;
  showWarning?: boolean;
  showAttachment?: boolean;
  defaultValue?: any;
  onClose: () => void;
  onSubmit: (values: any) => void;
}

const DeliveryStockAssignDriverDrawer = ({
  open,
  showWarning,
  showAttachment,
  defaultValue,
  onClose,
  onSubmit,
}: IDeliveryStockAssignDriverDrawer) => {
  const { t } = useTranslation();
  const form = useFormik<any>({
    initialValues: defaultValue ?? ({} as any),
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: schema,
    validate: (value) => {
      const error: any = {};
      if (!value?.deliveryType) {
        error.deliveryType = "Delivery type is required!";
      }

      if (!value?.isUnmanagedDriver && !value.driver) {
        error.driver = "Driver is required!";
      }

      if (!value?.isUnmanagedVehicle && !value.vehicle) {
        error.vehicle = "Vehicle is required!";
      }

      if (
        value?.isUnmanagedDriver &&
        value?.deliveryType === DELIVERY_STOCK_TYPE.OUTSOURCE &&
        isEmpty(value.unmanagedDriver)
      ) {
        error.unmanagedDriver = "Driver name  is required!";
      }

      if (
        value?.isUnmanagedVehicle &&
        value?.deliveryType === DELIVERY_STOCK_TYPE.OUTSOURCE &&
        isEmpty(value.unmanagedVehicle)
      ) {
        error.unmanagedVehicle = "Vehicle No. is required!";
      }

      if (showAttachment && isEmpty(value.attachment)) {
        error.unmanagedVehicle = "Attachment is required!";
      }

      return error;
    },
    onSubmit: (values: any) => {
      onSubmit(values);
    },
  });
  const formValues = form?.values;
  const formErrors = form?.errors;

  useEffect(() => {
    forEach(Object.keys(form.errors), (key) =>
      Notifications.showError(`${form.errors[key]}`)
    );
  }, [form.errors]);

  const classNameInput = "col-span-1 mt-3";

  console.log("formValues?.driver", formValues?.driver);
  return (
    <Drawer
      open={open}
      onClose={onClose}
      onSave={() => form.handleSubmit()}
      size="x-large"
      title={t("assignDriverVehicle")}
    >
      <div className="grid grid-cols-1 gap-4">
        {showWarning && (
          <div
            className={classNames("bg-warning flex-row p-3 align-items-center")}
          >
            <Icon name="warning" color="white" />
            <div className={classNames("text-white ml-3")}>
              {t("pleaseAssignDriverVehicleFirst")}
            </div>
          </div>
        )}
        <Select
          label={t("deliveryType")}
          getLabel={(item) => t(item.label)}
          className={classNameInput}
          dataSource={DELIVERY_STOCK_TYPES}
          value={formValues?.deliveryType}
          onChange={(value) => form.setFieldValue("deliveryType", value)}
        />
        <DriverSelect
          className={classNameInput}
          value={formValues?.driver}
          onChange={(value) => form.setFieldValue("driver", value)}
          disabled={formValues.isUnmanagedDriver}
        />
        {formValues.deliveryType === "outsourced-truck" && (
          <Checkbox
            label={t("unmanagedDriver")}
            checked={formValues.isUnmanagedDriver}
            onChange={() =>
              form.setFieldValue(
                "isUnmanagedDriver",
                !formValues.isUnmanagedDriver
              )
            }
          />
        )}

        {formValues.isUnmanagedDriver && (
          <InputTextForm
            label={t("driverFullName")}
            className={classNameInput}
            value={formValues?.unmanagedDriver}
            keyData="unmanagedDriver"
            form={form}
          />
        )}

        <VehicleSelect
          className={classNameInput}
          onChange={(value) => form.setFieldValue("vehicle", value)}
          value={formValues?.vehicle}
          disabled={!formValues?.deliveryType || formValues.isUnmanagedVehicle}
        />

        {formValues.deliveryType === "outsourced-truck" && (
          <Checkbox
            label={t("unmanagedVehicle")}
            checked={formValues.isUnmanagedVehicle}
            onChange={() =>
              form.setFieldValue(
                "isUnmanagedVehicle",
                !formValues.isUnmanagedVehicle
              )
            }
          />
        )}

        {formValues.isUnmanagedVehicle && (
          <InputTextForm
            label={t("vehicleNo")}
            keyData="unmanagedVehicle"
            form={form}
            className={classNameInput}
          />
        )}

        {showAttachment && (
          <ButtonFileUpload
            onChange={(value: any) => form.setFieldValue("attachment", value)}
            defaultFiles={[]}
            style={classNames("mt-3")}
          />
        )}
      </div>
    </Drawer>
  );
};

export default DeliveryStockAssignDriverDrawer;
