import UserAvatarName from "@components/shared/UserAvatarName";
import AppContext from "@helpers/context";
import { IStockAdjustment } from "@interfaces/stock-adjustment";

import { IRowsKey, TimeUtils, ViewRowInterchange } from "d-react-components";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

const StockAdjustmentDetailGeneral = () => {
  const { adjustmentDetail } = useContext(AppContext);

  const { t } = useTranslation();

  const DETAILS_KEY = useMemo<IRowsKey<IStockAdjustment>[]>(() => {
    let detailKeyList: any[] = [
      {
        id: "ref",
        label: t("id"),
      },
      {
        id: "channel",
        label: t("channel"),
        renderContent: ({ data, item }: any) => data?.name,
      },

      {
        id: "warehouse",
        label: t("branchWarehouse"),
        renderContent: ({ data, item }: any) => data?.name,
      },
      {
        id: "warehouse",
        label: t("branchWarehouseCode"),
        renderContent: ({ data, item }: any) => data?.code,
      },
      {
        id: "stock_inventory_control",
        label: t("event"),
        renderContent: ({ data, item }: any) => data?.title,
      },
      {
        id: "stock_inventory_control",
        label: t("eventCode"),
        renderContent: ({ data, item }: any) => data?.code,
      },
      {
        id: "remark",
        label: t("remark"),
      },
      {
        id: "status",
        label: t("status"),
      },
      {
        id: "by",
        label: t("createdAt"),
        renderContent: ({ data, item }: any) => <UserAvatarName user={data} />,
      },
      {
        id: "created",
        label: t("createdAt"),
        renderContent: ({ data, item }: any) => TimeUtils.toDateTime(data),
      },
    ];

    if (!!adjustmentDetail?.confirm_by) {
      detailKeyList = detailKeyList.concat([
        {
          id: "confirm_by",
          label: t("approvedBy"),
          renderContent: ({ data, item }: any) => (
            <UserAvatarName user={data} />
          ),
        },
        {
          id: "confirm_at",
          label: t("approvedAt"),
          renderContent: ({ data, item }: any) => TimeUtils.toDateTime(data),
        },
        {
          id: "confirm_note",
          label: t("approvedRemark"),
        },
      ]);
    }

    return detailKeyList;
  }, [t, adjustmentDetail]);

  return (
    <div className="card-container p-4 ">
      <div className="d-flex justify-content-between align-items-center">
        <label>{t("generalInformation")}</label>
      </div>

      <ViewRowInterchange
        className="w-100"
        dataSource={adjustmentDetail}
        keyList={DETAILS_KEY as any}
        variant="border"
      />
    </div>
  );
};

export default StockAdjustmentDetailGeneral;
