import { API } from "@network";
import { API_KPI_STAFF_DETAIL, API_KPI_STAFF_LIST } from "@network/URL";


const KpiAPI = {
  listKpiStaff: (paging: any, body: any = {}) => {
    return API.post(
      `${API_KPI_STAFF_LIST}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
      body
    );
  },

  detailKpiStaff: (paging: any, body: any) => {
    return API.post(
      `${API_KPI_STAFF_DETAIL}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
      body
    );
    // return API.post(API_KPI_STAFF_DETAIL, body);
  },
};

export default KpiAPI;