import { Modal } from "d-react-components";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Progress } from "antd";
import { join, map } from "lodash";
import WarehouseAPI from "@network/api/warehouse";
import { exportToCSV } from "@helpers/file";
import { useTranslation } from "react-i18next";
import WarehouseFilterForm from "./WarehouseFilterForm";
import Drawer from "@components/shared/Drawer";

function WarehouseTableExportDrawer({ open, onClose }: any) {
  const [isVisibleProgress, setVisibleProgress] = useState(false);
  const [percentageProgress, setPercentageProgress] = useState(0);
  const { t } = useTranslation();
  const exportForm = useFormik({
    initialValues: {},
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values: any) => {
      setVisibleProgress(true);
      setPercentageProgress(0);
      const {
        channel,
        storeType,
        stockAdjust,
        status,
        chain,
        warehouseDelivery,
        nav,
      } = values;
      const body = {
        channel: channel?.length ? map(channel, (item) => item.id) : null,
        store_type: storeType,
        stock_adjustment: stockAdjust,
        status,
        chain,
        delivery: warehouseDelivery?.length
          ? map(warehouseDelivery, (item) => item.id)
          : null,
        nav: map(nav, (item) => item.id),
      };

      onExport(body);
    },
  });

  const onExport = async (body: any) => {
    let remainPage = true;
    let warehouseListResult: any[] = [];
    let pageIndex = 1;
    while (remainPage) {
      const response = await WarehouseAPI.export(
        {
          pageIndex,
          pageSize: 100,
        },
        body
      );
      const warehouseList = response?.data?.data?.warehouse ?? [];
      const paginationData = response?.data?.data?.pagination ?? {};
      const { currentPage, total } = paginationData;
      warehouseListResult = warehouseListResult.concat(warehouseList);
      if (currentPage === total) {
        remainPage = false;
        setPercentageProgress(100);
      } else {
        pageIndex++;
        setPercentageProgress(Math.round((currentPage / total) * 100));
      }
    }

    const fileData = convertToExportData(warehouseListResult);

    exportToCSV(fileData, "WarehouseListExport");
    setVisibleProgress(false);
    onClose();
  };

  const convertToExportData = (warehouseList: any[] = []) => {
    return map(warehouseList, (item) => {
      const warehouseDelivery = map(item?.delivery, (item) => item.name);
      const warehouseChannel = map(item?.channel, (item) => item.name);

      return {
        [t("id")]: item?.id ?? "",
        [t("chain")]: item?.chain?.name ?? "",
        [t("name")]: item?.name ?? "",
        [t("systemCode")]: item?.warehouse_system_code ?? "",
        [t("navArCode")]: item?.code ?? "",
        [t("navPickupCode")]: item?.pickup_code ?? "",
        [t("brandIdTax")]: item?.branch_tax_id ?? "",
        [t("phoneNumber")]: item?.phone ?? "",
        [t("address")]: item?.address,
        [t("province")]: item?.province?.name,
        [t("city")]: item?.city?.name,
        [t("subdistrict")]: item?.subdistrict?.name,
        [t("postcode")]: item?.postcode,
        [t("latitude")]: item?.latitude,
        [t("longitude")]: item?.longitude,
        [t("warehouseDelivery")]: join(warehouseDelivery, ", "),
        [t("status")]: item?.status,
        [t("channel")]: join(warehouseChannel, ", "),
        [t("storeType")]: item?.store_type ?? "",
        [t("isThisAStore")]: item?.is_store ? "yes" : "no",
        [t("stockAdjust")]: item?.is_stock_adjustment ? "yes" : "no",
        [t("navConfig")]: item?.nav?.name,
      };
    });
  };

  const renderContent = () => {
    if (isVisibleProgress) {
      return (
        <div className="progressExportContainer">
          <Progress className="progressExport" percent={percentageProgress} />
        </div>
      );
    }
    return <WarehouseFilterForm form={exportForm} />;
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("export")}
      onSave={() => exportForm.handleSubmit()}
      size="large"
    >
      {renderContent()}
    </Drawer>
  );
}

export default WarehouseTableExportDrawer;
