import AppContext from "@helpers/context";
import classNames from "classnames";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const RawMaterialDetailHeader = () => {
  const { rawMaterialDetail } = useContext(AppContext);
  const { title } = rawMaterialDetail ?? {};
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="card-container p-3 flex-center justify-content-between border-bottom">
      <div className="flex-column">
        <div className="d-flex">
          <h4 className={classNames("text-[14px] text-primary")}>{title}</h4>
        </div>
      </div>
    </div>
  );
};

export default RawMaterialDetailHeader;
