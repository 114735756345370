import { Pagination } from "@interfaces/common";
import { API } from "@network";
import {
  API_CHECK_PRODUCT_BY_SKU,
  API_CREATE_PRODUCT,
  API_DELETE_PRODUCT,
  API_EDIT_PRODUCT,
  API_GET_BRAND_LIST,
  API_GET_CATEGORY_LIST,
  API_GET_PRICE_PRODUCT,
  API_IMPORT_PRICE_PRODUCT,
  API_LIST_MODEL,
  API_LIST_PRODUCT,
  API_MAP_DUMMY_PRODUCT,
  API_ORDER_STOCK_HOLD,
  API_PRINT_PRODUCT_LIST,
  API_PRODUCTION_COST_IMPORT,
  API_PRODUCTION_COST_TABLE,
  API_PRODUCTION_PRINTING,
  API_PRODUCT_DELETE_MEDIA,
  API_PRODUCT_DETAIL,
  API_PRODUCT_IMPORT,
  API_PRODUCT_IMPORT_FIELDS,
  API_PRODUCT_LIST_STOCK,
  API_PRODUCT_PRINT_INFO,
  API_PRODUCT_PRINT_INFO_ACTIVITY,
  API_PRODUCT_PRINT_INFO_ACTIVITY_CREATE,
  API_PRODUCT_PRINT_INFO_UPDATE,
  API_PRODUCT_SEARCH_IN_WAREHOUSE,
  API_PRODUCT_SIZE_CATEGORY,
  API_PRODUCT_VALIDATE_IMPORT,
  API_SEARCH_ALL_PRODUCT,
  API_SEARCH_PRODUCT_BY_CHANNEL,
  API_SEARCH_PRODUCT_IN_PROMOTION,
  API_STOCK_PRODUCT_WAREHOUSE,
  API_UPDATE_PRICE_PRODUCT,
  API_UPDATE_STOCK_PRODUCT,
  API_UPLOAD_MEDIA_PRODUCT,
  API_VALIDATE_SKU_PRODUCT,
  API_VALIDATION_PRODUCT_SKU,
} from "@network/URL";

export const getBrandList = () => {
  return API.post(API_GET_BRAND_LIST);
};

export const getCategoryList = () => {
  return API.post(API_GET_CATEGORY_LIST);
};

export const browseProduct = (body: any, paging: any) =>
  API.post(
    `${API_SEARCH_PRODUCT_BY_CHANNEL}?page=${paging.pageIndex}&per_page=${paging.pageSize}`,
    body
  );

export const getStockProductWarehouse = (body: any) => {
  return API.post(API_STOCK_PRODUCT_WAREHOUSE, body);
};

export const mapDummyProduct = (body: any) => {
  return API.post(API_MAP_DUMMY_PRODUCT, body);
};

export const listProduct = (body: any, paging: Pagination) => {
  return API.post(
    `${API_LIST_PRODUCT}?page=${paging.pageIndex}&per_page=${paging.pageSize}`,
    body
  );
};

export const getOrderHoldStock = (body: any) => {
  return API.post(API_ORDER_STOCK_HOLD, body);
};

export const searchAllProduct = (body: any) => {
  return API.post(API_SEARCH_ALL_PRODUCT, body);
};

export const getProductPrintList = (body: any, paging: any) => {
  return API.post(
    `${API_PRINT_PRODUCT_LIST}?page=${paging.pageIndex}&per_page=${paging.pageSize}`,
    body
  );
};

export const getProductionPrintingList = (body: any, paging: any) => {
  return API.post(
    `${API_PRODUCTION_PRINTING}?page=${paging.pageIndex}&per_page=${paging.pageSize}`,
    body
  );
};

export const searchProductInWarehouse = (body: any, paging: any) => {
  return API.post(
    `${API_PRODUCT_SEARCH_IN_WAREHOUSE}?page=${paging?.pageIndex}&per_page=${paging.pageSize}`,
    body
  );
};

export const validateSkuProduct = (body: any, config: any) => {
  return API.post(API_VALIDATE_SKU_PRODUCT, body, config);
};

export const productionCostImport = (body: any) => {
  return API.post(API_PRODUCTION_COST_IMPORT, body);
};

export const productionCostTable = (body: any, paging: any) => {
  return API.post(
    `${API_PRODUCTION_COST_TABLE}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
    body
  );
};

export const productSizeByCategory = (category: any, lang?: string) => {
  return API.post(
    API_PRODUCT_SIZE_CATEGORY,
    { category },
    {
      headers: {
        language: lang || "en",
      },
    }
  );
};

export const getProductDetail = (productId: string) => {
  return API.post(API_PRODUCT_DETAIL, { productid: productId });
};

export const getProductPrice = (productId: string) => {
  return API.post(API_GET_PRICE_PRODUCT, { productid: productId });
};

export const getProductListStock = (productId: string) => {
  return API.post(API_PRODUCT_LIST_STOCK, { productid: productId });
};

export const editProduct = (body: any) => {
  return API.post(API_EDIT_PRODUCT, body);
};

export const deleteProduct = (productId: string) => {
  return API.post(API_DELETE_PRODUCT, { productid: productId });
};

export const createProduct = (body: any) => {
  return API.post(API_CREATE_PRODUCT, body);
};

export const uploadMediaProduct = (body: any) => {
  return API.post(API_UPLOAD_MEDIA_PRODUCT, body);
};

export const updateStockProduct = (productid: string, stock = []) => {
  const body = { productid, stock };
  return API.post(API_UPDATE_STOCK_PRODUCT, body);
};

export const updatePriceProduct = (
  productid: string,
  regular_price: any,
  sale_price: any
) => {
  const body = { productid, regular_price, sale_price };
  return API.post(API_UPDATE_PRICE_PRODUCT, body);
};

export const productDeleteMedia = (body: any) => {
  return API.post(API_PRODUCT_DELETE_MEDIA, body);
};

export const getModelList = (body: any) => {
  return API.post(API_LIST_MODEL, body);
};

export const importPriceProduct = (body: any) => {
  return API.post(API_IMPORT_PRICE_PRODUCT, body);
};

export const productPrintInfo = (productId: string) => {
  return API.post(API_PRODUCT_PRINT_INFO, { product_id: productId });
};

export const productPrintInfoUpdate = (body: any) => {
  return API.post(API_PRODUCT_PRINT_INFO_UPDATE, body);
};

export const productPrintInfoActivity = (id: string, paging: any) => {
  return API.post(
    `${API_PRODUCT_PRINT_INFO_ACTIVITY}?page=${paging.pageIndex}&per_page=${paging.pageSize}`,
    {
      id,
    }
  );
};

export const productPrintInfoActivityCreate = (body: any) => {
  return API.post(API_PRODUCT_PRINT_INFO_ACTIVITY_CREATE, body);
};

export const searchProductInPromotion = (
  channel: any,
  promotion: any,
  code: any,
  filterBody: any = {},
  currentPage: any
) => {
  const body: any = { channel, promotion, code };
  const { brand, category } = filterBody;
  if (brand && brand.length > 0) {
    body.brand = brand.map((item: any) => item.id);
  }

  if (category && category.length > 0) {
    body.category = category.map((item: any) => item.id);
  }

  return API.post(
    `${API_SEARCH_PRODUCT_IN_PROMOTION}?page=${currentPage}`,
    body
  );
};

export const productImportFields = (product_type: string) => {
  return API.post(API_PRODUCT_IMPORT_FIELDS, {
    product_type,
  });
};

export const validateImportProduct = (body: any) => {
  return API.post(API_PRODUCT_VALIDATE_IMPORT, body);
};

export const importProduct = (body: any) => {
  return API.post(API_PRODUCT_IMPORT, body);
};

export const checkProductBySKU = (body: any) => {
  return API.post(API_CHECK_PRODUCT_BY_SKU, body);
};

export const validateProductFollowSku = (body: any) => {
  return API.post(API_VALIDATION_PRODUCT_SKU, body);
};
