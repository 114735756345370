import UserAvatarName from "@components/shared/UserAvatarName";
import { STOCK_REQUEST_STATUS } from "@constants/stock-request";
import AppContext from "@helpers/context";
import { IStockRequest } from "@interfaces/stock-request";
import {
  Button,
  IRowsKey,
  TimeUtils,
  ViewRowInterchange,
} from "d-react-components";
import moment from "moment";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import StockRequestDetailGeneralEditDrawer from "./StockRequestDetailGeneralEditDrawer";

const StockRequestDetailGeneral = () => {
  const { requestDetail, loadEventDetail } = useContext(AppContext);
  const [openEdit, setOpenEdit] = useState<boolean>();
  const { t } = useTranslation();

  const DETAILS_KEY = useMemo<IRowsKey<IStockRequest>[]>(() => {
    let detailKeyList: any[] = [
      {
        id: "ref",
        label: t("id"),
      },
      {
        id: "channel",
        label: t("channel"),
        renderContent: ({ data, item }: any) => data?.name,
      },
      {
        id: "chain",
        label: t("chain"),
        renderContent: ({ data, item }: any) => data?.name ?? "N/A",
      },
      {
        id: "store",
        label: t("branchWarehouse"),
        renderContent: ({ data, item }: any) => data?.name,
      },
      {
        id: "store",
        label: t("branchWarehouseCode"),
        renderContent: ({ data, item }: any) => data?.code,
      },
      {
        id: "request_type",
        label: t("type"),
      },
      {
        id: "stock_inventory_control",
        label: t("event"),
        renderContent: ({ data, item }: any) =>
          `${data?.title} (Code: ${data?.code})`,
      },
      {
        id: "expected_arrival_date",
        label: t("expectedDateOfArrival"),
        renderContent: ({ data, item }: any) => TimeUtils.toDateTime(data),
      },
      {
        id: "remark",
        label: t("remark"),
      },
      {
        id: "status",
        label: t("status"),
      },
      {
        id: "by",
        label: t("createdBy"),
        renderContent: ({ data, item }: any) => <UserAvatarName user={data} />,
      },
      {
        id: "created",
        label: t("createdAt"),
        renderContent: ({ data, item }: any) => TimeUtils.toDateTime(data),
      },
    ];

    if (!!requestDetail?.confirmBy) {
      detailKeyList = detailKeyList.concat([
        {
          id: "confirmBy",
          label: t("approvedBy"),
          renderContent: ({ data, item }: any) => (
            <UserAvatarName user={data} />
          ),
        },
        {
          id: "confirm_request_stock_at",
          label: t("approvedAt"),
          renderContent: ({ data, item }: any) => TimeUtils.toDateTime(data),
        },
        {
          id: "confirm_request_stock_note",
          label: t("approvedRemark"),
        },
      ]);
    }

    return detailKeyList;
  }, [t, requestDetail]);

  return (
    <div className="card-container p-4 ">
      <div className="d-flex justify-content-between align-items-center">
        <label>{t("generalInformation")}</label>
        <Button
          onClick={() => setOpenEdit(true)}
          variant="trans"
          hidden={requestDetail?.status !== STOCK_REQUEST_STATUS.PENDING}
        >
          {t("edit")}
        </Button>
      </div>

      <ViewRowInterchange
        className="w-100"
        dataSource={requestDetail}
        keyList={DETAILS_KEY as any}
        variant="border"
      />
      {openEdit && (
        <StockRequestDetailGeneralEditDrawer
          open={openEdit}
          onClose={() => setOpenEdit(false)}
          defaultValue={{
            ...(requestDetail ?? {}),
            period: [moment(requestDetail?.from), moment(requestDetail?.to)],
            chain: requestDetail?.chain?.id,
          }}
          onUpdateSuccess={loadEventDetail}
        />
      )}
    </div>
  );
};

export default StockRequestDetailGeneral;
