export const LANG_DEFAULT = "th";
export const LANGUAGES = [
  {
    id: "th",
    title: "Thai",
    label: "Thai",
    locale: "th",
    code: "TH",
    image: "/images/languages/language-3@2x.png",
    icon: "/images/languages/language-3.png",
    icon_srcset:
      "/images/languages/language-3.png 1x, images/languages/language-3@2x.png 2x",
    default: true,
  },
  {
    id: "en",
    title: "English",
    label: "English",
    locale: "en",
    code: "EN",
    image: "/images/languages/language-1.png",
    icon: "/images/languages/language-1.png",
    icon_srcset:
      "/images/languages/language-1.png 1x, images/languages/language-1@2x.png 2x",
  },
];
export const LANGS = LANGUAGES?.map((item) => item.locale);
