import { refreshDiscount } from "@network/api/auth";
import { Icon, Notifications, Progress } from "d-react-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Progress as ProgressAntd } from "antd";

const DiscountAuthOTP = () => {
  const { t } = useTranslation();
  const [otpCode, setOtpCode] = useState<any>();
  const [currentTime, setCurrentTime] = useState<number>(0);

  const remainingExpiryTime =
    new Date(otpCode?.discount_id_expired_after ?? "").getTime() - currentTime;

  useEffect(() => {
    onLoadOtpCode();
  }, []);

  useEffect(() => {
    if (!remainingExpiryTime || remainingExpiryTime > 0) return;
    onLoadOtpCode();
  }, [remainingExpiryTime]);

  useEffect(() => {
    if (!otpCode?.discount_id_expired_after) return () => {};

    const interval = setInterval(() => {
      const now = new Date().getTime();
      setCurrentTime(now);
    }, 1000);

    return () => clearInterval(interval);
  }, [otpCode?.discount_id_expired_after]);

  const onLoadOtpCode = () => {
    Progress.show({ method: refreshDiscount, params: [] }, (res: any) => {
      const otpCodeRes = res?.data?.data?.profile;
      console.log("otpCodeRes", otpCodeRes);
      setOtpCode(otpCodeRes);
    });
  };

  return (
    <div className="text-center p-4">
      <img
        alt=""
        src="/images/icons/password.svg"
        className="w-8 h-8 mb-4 inline-block"
      />
      <h5 className="mb-4">{t("yourDiscountAuthOTPToken")}</h5>
      <div className="relative p-4 border-[1px] border-sky-300 mb-4 text-blue-500">
        <input
          className="w-full h-full absolute top-0 left-0 text-center text-bold"
          value={otpCode?.discountid}
          readOnly
          style={{ fontSize: "18px" }}
        />
        <Icon
          name="content_copy"
          className="absolute cursor-pointer right-8 top-[15px] z-10"
          onClick={() => {
            navigator.clipboard.writeText(otpCode?.discountid ?? "");
            Notifications.showSuccess(t("notification:copySuccess"));
          }}
        />
        <Icon
          name="refresh"
          className="absolute cursor-pointer right-2 top-[15px] z-10"
          onClick={() => {
            onLoadOtpCode();
          }}
        />
        <ProgressAntd
          percent={remainingExpiryTime / 600}
          showInfo={false}
          className="auth-otp-progress"
        />
      </div>
      <div className="bg-red-100 text-red-500 p-3 text-xs">
        {t("warningTokenOTP")}
      </div>
    </div>
  );
};

export default DiscountAuthOTP;
