/* eslint-disable react/jsx-no-duplicate-props */
import { PRODUCT_TYPE } from "@constants/product";
import { listProduct } from "@network/api/product";
import {
  AwesomeTableComponent,
  Button,
  InputText,
  StringUtils,
} from "d-react-components";
import { filter, isEmpty, map } from "lodash";
import { useTranslation } from "react-i18next";
import ProductName from "../components/ProductName";
import QuantityInputField from "../components/QuantityInputField";
import SearchGroupProducts from "../components/SearchGroupProducts";

const ProductInputTypeGroup = ({ visible, productForm }: any) => {
  const { groups } = productForm?.values;
  const { t } = useTranslation();

  const onAddProductToCart = (products: any) => {
    productForm.setFieldValue(
      "groups",
      map(products, (item) => {
        const result = { ...item };
        if (!result?.quantity) {
          result.quantity = 1;
        }
        return result;
      })
    );
  };
  console.log("groups", groups);

  const columns = [
    {
      title: t("itemName"),
      dataIndex: "",
      ellipsis: true,
      render: (product: any) => <ProductName item={{ product }} />,
    },
    {
      title: t("quantity"),
      dataIndex: "quantity",
      render: (quantity: any, item: any) => (
        <QuantityInputField
          quantity={quantity}
          onChangeQuantity={(value: any) => onChangeQuantity(value, item)}
        />
      ),
    },
    {
      title: t("regularPrice"),
      dataIndex: "regular_price",
      render: (price: any) => (
        <div className="subTitle1">{StringUtils.moneyThaiFormat(price)}</div>
      ),
    },
    {
      title: t("salePrice"),
      dataIndex: "sale_price",
      width: 200,
      render: (price: any, item: any) => (
        <InputText
          onChange={(event) => {
            const rawValue = event.target.value.replace(/\D/g, "");
            const value = isEmpty(rawValue) ? 0 : parseInt(rawValue);
            if (value < 0) {
              return;
            }
            onChangeSalePrice(value, item);
          }}
          value={price?.toLocaleString() ?? "0"}
        />
      ),
    },
    {
      title: "",
      dataIndex: "",
      render: (product: any) => (
        <Button
          iconName="delete"
          variant="trans"
          onClick={() => onRemoveGroupProductItem(product)}
        />
      ),
    },
  ];
  const onChangeSalePrice = (salePrice: any, product: any) => {
    const groupResult = map(groups, (groupItem) => {
      if (groupItem.id === product.id) {
        return {
          ...groupItem,
          sale_price: salePrice,
        };
      }
      return groupItem;
    });
    productForm.setFieldValue("groups", groupResult);
  };

  const onRemoveGroupProductItem = (productRemove: any) => {
    const results = filter(groups, (item) => item.id !== productRemove.id);
    productForm.setFieldValue("groups", results);
  };

  const onChangeQuantity = (quantity: any, product: any) => {
    if (quantity < 1) {
      return;
    }
    const result = map(groups, (item) => {
      if (item?.id === product?.id) {
        return {
          ...item,
          quantity,
        };
      }
      return item;
    });
    productForm.setFieldValue("groups", result);
  };

  if (!visible) {
    return <div />;
  }

  const source = (currentPage: any, inputSearch: any, filterBodyParam: any) => {
    const body = {
      ...filterBodyParam,
      search: inputSearch,
      isCustomSize: true,
      product_type: [PRODUCT_TYPE.DEFAULT],
    };
    return listProduct(body, {
      pageIndex: currentPage,
      pageSize: 20,
    });
  };

  return (
    <div className="mt-3 ">
      <div className="flex-center justify-content-between">
        <label>{`${t("groupSkuItems")} (${groups.length})`}</label>
        <SearchGroupProducts
          onChange={onAddProductToCart}
          selectedProducts={groups}
          stockConsider={false}
          customSource={source}
        />
      </div>

      <AwesomeTableComponent
        dataSource={groups}
        columns={columns}
        pagination={false}
        className="mt-3 height-fit-content"
      />
    </div>
  );
};

export default ProductInputTypeGroup;
