import React, { useState } from "react";
import Draggable from "react-draggable";
import { toggleFullScreen } from "@helpers";
import { Icon } from "d-react-components";

const FullScreenButton = ({
  fullScreen,
  setFullScreen,
}: {
  fullScreen: boolean;
  setFullScreen: (state: boolean) => void;
}) => {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [buttonOpacity, setButtonOpacity] = useState(75);
  const DraggableButton: any = Draggable;

  const toggleScreen = () => {
    toggleFullScreen();
    setFullScreen(!fullScreen);
  };

  const eventControl = (event: { type: any }, info: any) => {
    if (event.type === "mousemove" || event.type === "touchmove") {
      setIsDragging(true);
      setButtonOpacity(100);
    }

    if (event.type === "mouseup" || event.type === "touchend") {
      setTimeout(() => {
        setIsDragging(false);
      }, 100);
      setTimeout(() => {
        setButtonOpacity(75);
      }, 3000);
    }
  };
  return (
    <DraggableButton onDrag={eventControl} onStop={eventControl}>
      <div
        className={`fullscreen-button transition-all p-2 cursor-pointer opacity-${buttonOpacity} bg-primary fixed bottom-16 right-0 z-40 text-white leading-none`}
        onClick={() => {
          if (!isDragging) {
            toggleScreen();
          }
        }}
      >
        <Icon
          size="x-large"
          name={fullScreen ? "zoom_in_map" : "zoom_out_map"}
        />
      </div>
    </DraggableButton>
  );
};

export default FullScreenButton;
