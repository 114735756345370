import { Icon, ObjectUtils, StringUtils } from "d-react-components";
import { filter, map } from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CART_RULE_ACTION_KEY, FREE_GIFT_ACTION_KEY } from "@constants/order";
import AppContext from "@helpers/context";
import {
  ADDITIONAL_DISCOUNT,
  GP_DISCOUNT,
  INTERNAL_DISCOUNT,
  MEMBER_DISCOUNT,
  MT_CREDIT_DISCOUNT,
} from "@constants/discount";
import { getPaidFromPayments } from "@helpers/payment";
import { OrderType } from "@constants/common";
import CurrencyFormat from "react-currency-format";
import styled from "@emotion/styled";

const convertMoney = StringUtils.moneyThaiFormat;
const fixed = CART_RULE_ACTION_KEY.discountFix.id;
const percentage = CART_RULE_ACTION_KEY.discountPercentage.id;

const getDisplayCode = (item: any) => {
  if (item?.code?.code) {
    return `(${item.code.code.toUpperCase()})`;
  }
  if (item?.selfDiscount) {
    switch (item?.action?.specific) {
      case fixed:
        return `(${StringUtils.moneyThaiFormat(item?.action?.amount)})`;
      case percentage:
        return `(${item?.action?.percentage}%)`;
      default:
        return "";
    }
  }
  return "";
};

const showRemove = (item: any) => {
  if (item?.action.level === GP_DISCOUNT) return false;
  return (
    item?.selfDiscount ||
    !!item?.code?.code ||
    item?.isEmployeeDiscount ||
    item.isChainDiscount
  );
};

const CouponRow = ({
  item,
  removeCoupon,
  discountMoney,
  expandedTable,
}: any) => {
  const {
    ruleid,
    selfDiscount,
    approvedBy,
    isEmployeeDiscount,
    employeeDiscountCode,
  } = item;
  const { t } = useTranslation();

  const displayCode = getDisplayCode(item);
  const ruleIdText = ruleid ? `(${t("ruleid")}: ${ruleid})` : "";
  const selfRequestText = selfDiscount ? `(${t("manualDiscount")})` : "";
  const employeeDiscountText = isEmployeeDiscount
    ? `(${t("employeeDiscount")}: ${employeeDiscountCode})`
    : "";

  const getDiscountTitle = (coupon: any) => {
    switch (coupon?.action?.level) {
      case GP_DISCOUNT:
        return t("gpDiscount");
      case INTERNAL_DISCOUNT:
        return `${t("internalDiscount")} ${coupon.internalDiscountIndex + 1}`;
      case MEMBER_DISCOUNT:
        return t("mtMemberDiscount");
      case ADDITIONAL_DISCOUNT:
        return `${t("mtAdditionalDiscount")} ${
          coupon.additionalDiscountIndex + 1
        }`;
      case MT_CREDIT_DISCOUNT:
        return t("mtCreditCardDiscount");
      default:
        return t("discount");
    }
  };

  const getDiscountAmount = (coupon: any, amount: any) => {
    let discountAmount = `-${StringUtils.moneyThaiFormatFixed2(amount)}`;
    const specific = coupon?.action?.specific;
    if (
      specific === FREE_GIFT_ACTION_KEY.discountPercentage.id ||
      specific === FREE_GIFT_ACTION_KEY.discountPercentageCart.id
    ) {
      discountAmount += `(${coupon?.action?.percentage}%)`;
    } else if (
      specific === FREE_GIFT_ACTION_KEY.freegift.id ||
      specific === FREE_GIFT_ACTION_KEY.buyXGetX.id ||
      specific === FREE_GIFT_ACTION_KEY.buyXGetBundle.id
    ) {
      discountAmount = t("freeGift");
    }
    return discountAmount;
  };

  return (
    <tr hidden={!expandedTable}>
      <td colSpan={2} align="left" className="py-2 text-sm">
        <div className="flex flex-column align-items-start">
          <div className="flex items-center">
            <div>
              <span className="text-xs">
                {`${getDiscountTitle(item)}  `}
                {`${displayCode} `}
                {`${ruleIdText} `}
                {`${selfRequestText} `}
                {employeeDiscountText}
              </span>
              {approvedBy && (
                <div className="text-success text-xs flex items-center">
                  <span>{`${t("approvedBy")}  `}</span>
                  <img
                    src={approvedBy?.avatar}
                    className="w-6 h-6 object-cover rounded-full mx-1"
                    alt=""
                  />
                  <span>{`${approvedBy?.fullname}  `}</span>
                </div>
              )}
            </div>
            {showRemove(item) && (
              <span
                onClick={() => removeCoupon(item)}
                className="text-error text-xs cursor-pointer ml-1"
              >
                <Icon name="delete" />
              </span>
            )}
          </div>
        </div>
      </td>
      <td colSpan={2} align="right" className="py-2 text-sm">
        <span className="text-success text-xs">
          {getDiscountAmount(item, discountMoney)}
        </span>
      </td>
    </tr>
  );
};
const PriceTable = (props: any) => {
  const {
    freeGiftList,
    originOrder,
    discountChainList,
    setFreeGiftList,
    setDiscountChainList,
    totalState,
    subTotalState,
    values,
    type,
    productList,
  } = useContext(AppContext);
  const { isChainExternal } = values;
  const { t } = useTranslation();
  const [expandedTable, setExpandedTable] = useState(false);

  const [paidOrderAmount, setPaidOrderAmount] = useState(
    getPaidFromPayments(originOrder?.splitPayment ?? [])
  );

  useEffect(() => {
    const paidFromPayment = getPaidFromPayments(
      originOrder?.splitPayment ?? []
    );
    const paidFromOrder = originOrder?.order?.paid;
    setPaidOrderAmount(paidFromPayment + paidFromOrder);
  }, [originOrder]);

  const onRemoveFreeGiftHandle = useCallback(
    (item: any) => {
      const newFreeGiftList =
        freeGiftList &&
        freeGiftList.filter((freeGift: any) => freeGift.id !== item.id);
      setFreeGiftList([...newFreeGiftList]);
    },
    [freeGiftList, setFreeGiftList]
  );

  const onRemoveChainDiscount = useCallback(
    (item: any) => {
      let values = [];
      if (item.action.level === INTERNAL_DISCOUNT) {
        values = filter(
          discountChainList,
          (item) => item.action.level !== INTERNAL_DISCOUNT
        );
      } else if (item.action.level === ADDITIONAL_DISCOUNT) {
        values = filter(
          discountChainList,
          (item) => item.action.level !== ADDITIONAL_DISCOUNT
        );
      } else {
        values = ObjectUtils.removeArrayById(discountChainList, item?.id);
      }
      setDiscountChainList(values);
    },
    [discountChainList, setDiscountChainList]
  );

  const onRemoveCoupon = useCallback(
    (item: any) => {
      if (isChainExternal) {
        onRemoveChainDiscount(item);
      } else {
        onRemoveFreeGiftHandle(item);
      }
    },
    [isChainExternal, onRemoveChainDiscount, onRemoveFreeGiftHandle]
  );

  const rowMoney = (
    title: any,
    money: any,
    secondTitleStyle = {},
    isTotal = false
  ) => {
    return (
      <tr hidden={isTotal ? false : !expandedTable}>
        <td colSpan={2} align="left" className="py-2 text-sm">
          <span className="font-weight-bold text-xs">{title}</span>
        </td>
        <td colSpan={2} align="right" className="py-2 text-sm">
          <span
            className={`text-xs ${
              isTotal && "bg-primary px-2 py-1 text-white"
            }`}
            style={{ ...secondTitleStyle }}
          >
            {money || money === 0 ? `${convertMoney(money)}` : "_"}
          </span>
        </td>
      </tr>
    );
  };

  const renderCouponList = useCallback(() => {
    let afterDiscount = subTotalState;

    return map([...discountChainList, ...freeGiftList], (coupon) => {
      const { specific, percentage, amount, level } = coupon?.action ?? {};
      const isPercentage =
        specific === CART_RULE_ACTION_KEY.discountPercentage.id;
      let discountMoney = 0;

      if (isPercentage && level === INTERNAL_DISCOUNT) {
        discountMoney = Math.round((afterDiscount * percentage) / 100);
      } else if (
        isPercentage &&
        (coupon?.selfDiscount ||
          coupon?.isEmployeeDiscount ||
          coupon?.isChainDiscount)
      ) {
        discountMoney = (afterDiscount * percentage) / 100;
      } else {
        discountMoney = amount;
      }

      if (type === OrderType.RENTAL) {
        discountMoney = Math.ceil(
          discountMoney / (productList?.[0]?.month || 1)
        );
      }

      afterDiscount -= discountMoney;

      return (
        <CouponRow
          item={coupon}
          removeCoupon={onRemoveCoupon}
          discountMoney={discountMoney}
          expandedTable={expandedTable}
        />
      );
    });
  }, [
    discountChainList,
    freeGiftList,
    onRemoveCoupon,
    subTotalState,
    expandedTable,
  ]);

  return (
    <div className="price-table__container px-3 py-2 flex items-start border-t relative">
      <span
        className="inline-block mt-[5px] cursor-pointer flex items-center bg-gray-300 w-6 h-[25px] justify-center absolute top-[-30px] left-0 rounded-tr-sm rounded-tl-sm"
        onClick={() => setExpandedTable(!expandedTable)}
      >
        <Icon
          name={!expandedTable ? "keyboard_arrow_up" : "keyboard_arrow_down"}
        />
      </span>
      <div className="flex-1">
        <StyledPriceTable id="priceTable" className="w-full">
          {type === OrderType.NORMAL && (
            <tr hidden={!expandedTable}>
              <td colSpan={2} align="left" className="py-2 text-sm">
                <span className="font-weight-bold text-xs">
                  {t("subTotalName")}
                </span>
              </td>
              <td colSpan={2} align="right" className="py-2 text-sm">
                <span className="text-xs bg-primary px-2 py-1 text-white">
                  {subTotalState ? `${convertMoney(subTotalState)}` : "_"}
                </span>
              </td>
            </tr>
          )}
          {type === OrderType.RENTAL && (
            <tr hidden={!expandedTable}>
              <td colSpan={2} align="left" className="py-2 text-sm">
                <span className="font-weight-bold text-xs block mb-1">
                  {t("subTotalPerMonth")}
                </span>
                <span className="text-xs bg-primary px-2 py-1 text-white">
                  {productList?.[0]?.month} {t("months")}
                </span>
              </td>
              <td colSpan={2} align="right" className="py-2 text-sm">
                <span className="text-xs bg-primary px-2 py-1 text-white">
                  {subTotalState ? (
                    <CurrencyFormat
                      displayType={"text"}
                      value={(
                        subTotalState / (productList?.[0]?.month || 1)
                      ).toFixed(2)}
                      thousandSeparator={true}
                    />
                  ) : (
                    "_"
                  )}
                </span>
              </td>
            </tr>
          )}
          {renderCouponList()}

          <tr hidden={!expandedTable}>
            <td colSpan={2} align="left" className="py-2 text-sm">
              <span className="text-xs">{t("shippingCost")}</span>
            </td>
            <td colSpan={2} align="right" className="py-2 text-sm">
              <span className="text-xs">
                {convertMoney(values?.shippingFee ?? 0)}
              </span>
            </td>
          </tr>
          {type === OrderType.NORMAL &&
            rowMoney(t("total"), totalState, {}, true)}
          {type === OrderType.RENTAL && (
            <tr>
              <td colSpan={2} align="left" className="py-2 text-sm">
                <span className="font-weight-bold text-xs block mb-1">
                  {t("totalPerMonth")}
                </span>
                <span className="text-xs bg-primary px-2 py-1 text-white">
                  {productList?.[0]?.month} {t("months")}
                </span>
              </td>
              <td colSpan={2} align="right" className="py-2 text-sm">
                <span className="text-xs bg-primary px-2 py-1 text-white">
                  {totalState ? (
                    <CurrencyFormat
                      displayType={"text"}
                      value={(
                        totalState / (productList?.[0]?.month || 1)
                      ).toFixed(2)}
                      thousandSeparator={true}
                    />
                  ) : (
                    "_"
                  )}
                </span>
              </td>
            </tr>
          )}

          {originOrder &&
            rowMoney(t("paid"), paidOrderAmount, {
              color: "green",
            })}
          {originOrder &&
            rowMoney(
              t("remainingAmount"),
              Math.round(totalState) - paidOrderAmount
            )}
        </StyledPriceTable>
      </div>
    </div>
  );
};

const StyledPriceTable = styled.table`
  tr {
    &:not(:last-child) {
      td {
        border-bottom: 1px solid rgb(229, 231, 235);
      }
    }
  }
`;

export default PriceTable;
