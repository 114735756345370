import AppContext from "@helpers/context";
import { getPromotionDetail } from "@network/api/promotion";
import { Notifications } from "d-react-components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import PromotionDetailHeader from "./PromotionDetailHeader";
import PromotionDetailMemo from "./PromotionDetailMemo";
import PromotionDetailPeriod from "./PromotionDetailPeriod";
import PromotionDetailProducts from "./PromotionDetailProducts";
import PromotionDetailAttachments from "./PromotionDetailAttachments";
import PromotionDetailChannels from "./PromotionDetailChannels";
import PromotionActivities from "./PromotionActivities";
import PromotionDetailCreatedBy from "./PromotionDetailCreatedBy";
import moment from "moment";
import { PROMOTION_STATUS, PromotionPublishStatus } from "@constants/promotion";

export const getInuseStatus = (promotion: any) => {
  if (!promotion) {
    return "";
  }
  const currentTime = moment().valueOf();

  if (promotion.status === PROMOTION_STATUS.ENDED) {
    return promotion.status;
  } else if (currentTime >= promotion.start && currentTime <= promotion.end) {
    return PROMOTION_STATUS.IN_USE;
  } else if (currentTime < promotion.start) {
    return PROMOTION_STATUS.SCHEDULED;
  } else if (currentTime > promotion.end) {
    return PROMOTION_STATUS.ENDED;
  }
  return promotion.status;
};

const PromotionDetail = () => {
  const { promotionId } = useParams();
  const [promotionDetail, setPromotionDetail] = useState({} as any);

  const loadPromotionDetail = () => {
    if (!promotionId) {
      return;
    }
    // Load promotion detail here
    getPromotionDetail({ id: promotionId }).then(
      (res) => {
        setPromotionDetail(res?.data?.data?.promotion);
      },
      (err) => {
        Notifications.showError(err);
      }
    );
  };

  useEffect(() => {
    loadPromotionDetail();
  }, []);

  return (
    <AppContext.Provider
      value={{
        promotionDetail,
        reloadPromotion: loadPromotionDetail,
        status: getInuseStatus(promotionDetail),
      }}
    >
      <PromotionDetailHeader />

      <div className="grid grid-cols-3 gap-4 mt-3 px-3 pb-3 pt-0">
        <div className="col-span-3 md:col-span-2">
          <PromotionDetailProducts />
          <PromotionDetailMemo />
          <PromotionDetailAttachments />
          <PromotionActivities />
        </div>
        <div className="col-span-3 md:col-span-1">
          <PromotionDetailPeriod />
          <PromotionDetailCreatedBy />
          <PromotionDetailChannels />
        </div>
      </div>
    </AppContext.Provider>
  );
};

export default PromotionDetail;
