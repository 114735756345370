/* eslint-disable no-continue */
/* eslint-disable no-loop-func */
import Drawer from "@components/shared/Drawer";
import AppContext from "@helpers/context";
import { exportToCSV } from "@helpers/file";
import { importGPEventProduct } from "@network/api/gp-event";
import {
  Button,
  Icon,
  Modal,
  Notifications,
  Progress,
} from "d-react-components";
import {
  every,
  forEach,
  includes,
  isEmpty,
  map,
  parseInt,
  split,
} from "lodash";
import React, { useContext, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

function convertCsvJSON(csv: any) {
  const separator = includes(csv, ",") ? "," : ";";
  const lines = split(csv, "\n");
  const result = [];
  const headers = split(lines[0], separator);
  for (let i = 1; i < lines.length; i++) {
    let validObject = false;
    if (!lines[i]) continue;
    const objectItem: any = {};
    const currentLine = lines[i].split(separator);
    forEach(headers, (header, index) => {
      const currentHeader = header.replace(/\W/g, "");
      // const currentValue = currentLine[index]?.replace(/\W/g, "");
      const currentValue = currentLine[index];

      if (!isEmpty(currentValue)) {
        validObject = true;
        objectItem[currentHeader] = currentValue;
      }
    });
    if (validObject) {
      result.push(objectItem);
    }
  }
  return result;
}

const GPEventDetailConfigImportModal = ({ open, onClose, onImported }: any) => {
  const { eventDetail } = useContext(AppContext);
  const [dataImport, setDataImport] = useState<any>([]);
  const [errorsImport, setErrorsImport] = useState([]);
  const isErrorMode = useMemo(() => errorsImport?.length > 0, [errorsImport]);
  const { t } = useTranslation();

  const validateSkuList = (skuList: any[] = []) => {
    const isAllEnoughData = every(
      skuList,
      (item) => !isEmpty(item.sku) && item.discount
    );
    if (!isAllEnoughData) {
      Notifications.showError(t("fileImportIsNotRightFormat"));
      return false;
    }

    const isAllValidDiscount = every(skuList, (item) => item.discount < 100);
    if (!isAllValidDiscount) {
      Notifications.showError(t("GPValueNotValid"));
      return false;
    }
    return true;
  };

  const onDrop = ([file]: any) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      const skuList = convertCsvJSON(reader.result);
      const bodyImport: any = map(skuList, (item) => ({
        sku: item.sku,
        discount: parseInt(item.GP),
      }));

      if (!validateSkuList(bodyImport)) {
        setDataImport([]);
        return;
      }
      setDataImport(bodyImport);
    };
  };

  const onImport = () => {
    const input = {
      id: eventDetail?.id,
      product: dataImport,
    };
    Progress.show(
      { method: importGPEventProduct, params: [input] },
      () => {
        onClose();
        Notifications.showSuccess(t("importSuccess"));
        onImported();
      },
      (error: any) => {
        const errorList = error?.response?.data?.data ?? [];
        setErrorsImport(errorList);
        return true;
      }
    );
  };

  const onRetry = () => {
    setDataImport([]);
    setErrorsImport([]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const onClickDownloadErrorLog = () => {
    exportToCSV(errorsImport, "Import Error Log");
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("import")}
      onSave={isErrorMode ? onRetry : onImport}
      saveText={isErrorMode ? t("retry") : t("import")}
      disableSave={dataImport?.length === 0}
    >
      {/* Render input file and instruction*/}
      <div className="flex-column" hidden={isErrorMode}>
        <div>{t("uploadAllProductPromotion")}</div>
        <div className="d-flex">
          <div className="font-weight-bold">{t("requiredField")}</div>
          <div>: sku, GP</div>
        </div>
        <Button iconName="attachment" className="mt-3" {...getRootProps()}>
          <div>
            <input
              {...getInputProps()}
              type="file"
              accept=".csv, text/csv"
              multiple={false}
            />
            <text>{t("attachCSV")}</text>
          </div>
        </Button>
        <div
          className="text-success small mt-3 font-italic"
          hidden={dataImport?.length === 0}
        >
          {t("fileValidated")}
        </div>

        <div className="my-3">{t("notSureHowToStart")}</div>
        <div>- {t("downloadAsvFile")}</div>
        <div className="d-flex">
          - {t("download")}
          <a
            className="ml-3"
            target="_blank"
            href="https://onlinesale.lotusbeddinggroup.com/storage/exemple/template-item-gp-event.csv"
            rel="noreferrer"
          >
            {t("blankCSVTemplate")}
          </a>
        </div>
      </div>

      {/* Render error and download error logs */}
      <div hidden={!isErrorMode} className="flex-column">
        <div className="d-flex  align-items-center">
          <Icon name="check_circle" className="text-success" />
          <div className="ml-2">{`${
            dataImport?.length - errorsImport?.length
          } ${t("itemValidToImport")}`}</div>
        </div>
        <div className="d-flex align-items-center">
          <Icon name="error_outline" className="text-error" />
          <div className="ml-2">{`${errorsImport?.length} ${t(
            "itemHaveErrorImport"
          )}`}</div>
          <div
            className="text-primary cursor-pointer ml-1"
            onClick={onClickDownloadErrorLog}
          >
            {t("downloadLog")}
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default GPEventDetailConfigImportModal;
