import ChannelSelect from "@components/channel/components/ChannelSelect";
import Drawer from "@components/shared/Drawer";
import StoreSelect from "@components/store/components/StoreSelect";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

function TransferStockFilterDrawer({
  open,
  onClose,
  onFilter,
  defaultValue,
}: any) {
  const { t } = useTranslation();

  const filterForm = useFormik({
    initialValues: defaultValue ?? {},
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      onFilter && onFilter(values);
      onClose();
    },
  });

  const formValue = filterForm.values;
  const classNameInput = "mt-3 col-12";
  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("filter")}
      onSave={() => filterForm.handleSubmit()}
    >
      <div className="row">
        <ChannelSelect
          value={formValue.channel}
          className={classNameInput}
          onChange={(value) => filterForm.setFieldValue("channel", value)}
          multiple
        />
        <StoreSelect
          value={formValue.store}
          className={classNameInput}
          onChange={(value) => filterForm.setFieldValue("store", value)}
          multiple
        />
      </div>
    </Drawer>
  );
}

export default TransferStockFilterDrawer;
