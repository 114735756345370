import { PROMOTION_STATUS, PROMOTION_STATUSES } from "@constants/promotion";
import AppContext from "@helpers/context";
import { exportToCSV } from "@helpers/file";
import { Dropdown } from "antd";
import {
  Button,
  Icon,
  InputText,
  Notifications,
  Progress,
  TimeUtils,
  ViewLabelStatus,
} from "d-react-components";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Sticky from "react-sticky-el";
import PromotionEnd from "./PromotionEnd";
import { generatePath, useNavigate } from "react-router-dom";
import { Path } from "@components/layout/Path";
import Drawer, { DrawerProps } from "@components/shared/Drawer";
import { updatePromotion } from "@network/api/promotion";
import AppIcon from "@common/Icon";

const exportItems = (promotionDetail: any) => {
  const { products } = promotionDetail;
  const exportResult = products.map((proItem: any) => ({
    "Promotion Title": promotionDetail?.name ?? "N/A",
    Code: promotionDetail?.code ?? "N/A",
    Channel:
      promotionDetail?.channels
        ?.map((channel: any) => channel.name)
        .join(",") ?? "N/A",
    "Starts at":
      TimeUtils.convertMiliToDateTime(promotionDetail?.start) ?? "N/A",
    "Ends at": TimeUtils.convertMiliToDateTime(promotionDetail?.end) ?? "N/A",
    "Product Name": proItem?.product?.name ?? "N/A",
    "Product SKU": proItem?.product?.sku ?? "N/A",
    "Reg.Price": proItem?.regular_price ?? 0,
    "Default sale price": proItem?.default_sale_price ?? 0,
    "New sale price": proItem?.new_sale_price ?? 0,
    "Created at":
      TimeUtils.convertMiliToDateTime(promotionDetail?.created) ?? "N/A",
    "Created by": promotionDetail?.by
      ? `${promotionDetail?.by?.fullname}_${promotionDetail?.by?.companyId}`
      : "N/A",
    Memo: promotionDetail?.description ?? "N/A",
  }));
  const date = moment().format("DD/MM/YYYY");
  const time = moment().format("HH:mm");
  const fileName = `Promotion_${
    promotionDetail?.code
  }_${date}_${time}_${moment().valueOf()}`;
  return exportToCSV(exportResult, fileName);
};

const PromotionDetailHeader = () => {
  const { promotionDetail, status } = React.useContext(AppContext);
  const { t } = useTranslation();
  const [showEndPromotion, setShowEndPromotion] = useState(false);
  const [showUpdateName, setShowUpdateName] = useState(false);
  const navigate = useNavigate();
  const editable = useMemo(
    () => status === PROMOTION_STATUS.SCHEDULED,
    [status]
  );

  const OPTION = useMemo(() => {
    return [
      {
        label: t("export"),
        key: "export",
      },
      {
        label: t("endThisPromotion"),
        key: "endThisPromotion",
      },
      {
        label: t("duplicate"),
        key: "duplicate",
      },
    ];
  }, [t]);

  const handleChooseAction = (item: any) => {
    if (item.key === "export") {
      exportItems(promotionDetail);
    }
    if (item.key === "endThisPromotion") {
      // End promotion here
      setShowEndPromotion(true);
    }
    if (item.key === "duplicate") {
      // Duplicate promotion here
      navigate(
        generatePath(Path.PROMOTION_CREATE_DUPLICATE, {
          promotionId: promotionDetail.id,
        })
      );
    }
  };

  return (
    <div className="bg-white relative z-[60] shadow-sm">
      <Sticky
        boundaryElement=".main-content"
        scrollElement=".main-content"
        stickyClassName="header-sticky"
        wrapperClassName="group w-full"
      >
        <div className="flex flex-col lg:flex-row border-b p-3 bg-white">
          <div className="flex-1 flex flex-col md:flex-row md:items-center mb-[0.5rem] lg:mb-0  group-[.header-sticky]:mb-0">
            <div className="flex items-center mb-[0.5rem] md:mb-0  group-[.header-sticky]:mb-0">
              <div id="orderIdContainer" className="mr-3 flex items-center">
                <span className="font-semibold text-primary">
                  {promotionDetail.name}
                </span>
                {editable && (
                  <AppIcon
                    onClick={() => setShowUpdateName(true)}
                    className="w-[15px] cursor-pointer ml-2"
                    src="/images/icons/edit.svg"
                  />
                )}
              </div>
            </div>
            <div className="flex items-center group-[.header-sticky]:hidden md:group-[.header-sticky]:flex flex-wrap">
              {/* <ViewLabelStatus
                content={t(promotionStatus?.label as string)}
                color={promotionStatus?.color}
                className="mr-2 mb-1 whitespace-nowrap"
              /> */}
              <ViewLabelStatus
                status={status}
                listStatus={PROMOTION_STATUSES}
                className="mr-2 mb-1 whitespace-nowrap"
                getLabel={(item) => t(item.label)}
              />
            </div>
          </div>
          <div className="flex items-center group-[.header-sticky]:hidden md:group-[.header-sticky]:flex">
            <Dropdown
              trigger={["click"]}
              menu={{ items: OPTION, onClick: handleChooseAction }}
            >
              <Button
                variant="outline"
                content={t("action")}
                suffixIcon="arrow_drop_down"
                size="small"
                className="ml-2"
              />
            </Dropdown>
          </div>
        </div>
      </Sticky>
      <div className="flex p-3 flex-col md:flex-row items-start md:items-center">
        <div className="d-flex mr-3 mb-[0.5rem] md:mb-0">
          <Icon name="article" />
          <div className="text-xs ml-1 text-gray-500">
            {promotionDetail?.code}
          </div>
        </div>
        <div className="d-flex mr-3 mb-[0.5rem] md:mb-0">
          <Icon name="date_range" />
          <div className="text-xs ml-1 text-gray-500">{`${t(
            "order:createdOn"
          )} ${TimeUtils.convertMiliToDateTime(
            promotionDetail?.created
          )}`}</div>
        </div>
      </div>
      {showEndPromotion && (
        <PromotionEnd
          open={showEndPromotion}
          onClose={() => setShowEndPromotion(false)}
        />
      )}
      {showUpdateName && (
        <TitleDrawer
          open={showUpdateName}
          onClose={() => setShowUpdateName(false)}
        />
      )}
    </div>
  );
};

const TitleDrawer = ({ open, onClose }: DrawerProps) => {
  const { t } = useTranslation();
  const { promotionDetail, reloadPromotion } = React.useContext(AppContext);
  const [content, setContent] = useState(promotionDetail?.name);

  const onSave = () => {
    Progress.show(
      {
        method: updatePromotion,
        params: {
          name: content,
          promotionid: promotionDetail?.id,
        },
      },
      () => {
        reloadPromotion && reloadPromotion();
        Notifications.showSuccess(t("notification:updatePromotionSuccess"));
        onClose();
      },
      (err: string) => {
        Notifications.showError(err);
      }
    );
  };

  return (
    <Drawer
      title={t("name")}
      open={open}
      onClose={() => onClose(false)}
      onSave={onSave}
      size="auto"
      width="450"
    >
      <InputText
        label={t("name")}
        value={content}
        onChange={(e) => setContent(e.target.value)}
        multiple
        rows={5}
      />
    </Drawer>
  );
};

export default PromotionDetailHeader;
