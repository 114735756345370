import InputTextForm from "@common/input/InputTextForm";
import AddressFormInput from "@components/address/components/AddressFormInput";
import { useTranslation } from "react-i18next";

interface ICustomerInput {
  customerForm: any;
  className?: any;
}

const CustomerInputShipping = ({ customerForm, className }: ICustomerInput) => {
  const formValues = customerForm?.values;
  const { t } = useTranslation();

  const classNameField = "col-6 mt-3";

  return (
    <div className="row">
      <InputTextForm
        form={customerForm}
        className={classNameField}
        keyData="firstname"
      />
      <InputTextForm
        form={customerForm}
        className={classNameField}
        keyData="lastname"
      />
      <InputTextForm
        form={customerForm}
        className={classNameField}
        keyData="nickname"
      />
      <InputTextForm
        form={customerForm}
        className={classNameField}
        keyData="phone"
      />
      <AddressFormInput
        formDataValue={customerForm.values}
        formDataError={customerForm.errors}
        onChange={(newValue: any) => customerForm.setValues(newValue)}
        show={{ map: true }}
      />
    </div>
  );
};

export default CustomerInputShipping;
