/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import classNames from "classnames";
import Carousel, { Modal, ModalGateway } from "react-images";
import { useTranslation } from "react-i18next";
import { map } from "lodash";

const DeliveryDetailConfirmedView = ({ confirmation }: any) => {
  const {
    driver,
    supervisorName,
    signAt,
    remark,
    signatureDriver,
    signatureSupervisor,
    attachments = [],
  } = confirmation;
  const [indexImageView, setIndexImageView] = useState<any>(-1);
  const classNameContent = "d-flex mt-3";

  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column align-items-start">
      <div className={classNameContent}>
        <div className="font-weight-bold mr-3">{t("driver")}</div>
        <div>{driver?.fullname}</div>
      </div>
      <div className={classNameContent}>
        <div className="font-weight-bold mr-3">{t("supervisor")}</div>
        <div>{supervisorName}</div>
      </div>

      <div className={classNames(classNameContent, "text-success")}>
        {`${t("confirmedBy")} ${supervisorName} ${t(
          "at"
        )} ${signAt} "${remark}"`}
      </div>
      <div className={classNameContent} style={{ overflowX: "scroll" }}>
        {map(attachments, (item, index) => (
          <img
            className="image-reg-large object-fit-contain my-2 mr-2"
            src={item}
            onClick={() => setIndexImageView(index)}
          />
        ))}
      </div>
      <div className="my-3">
        <div className="font-weight-bold">{t("driverSignature")}</div>
        <div className="border mt-1">
          <img
            src={signatureDriver}
            className="image-reg-large object-fit-contain my-2"
          />
        </div>
      </div>
      <div className="my-3">
        <div className="font-weight-bold">{t("supervisorSignature")}</div>
        <div className="border mt-1">
          <img
            className="image-reg-large object-fit-contain my-2"
            src={signatureSupervisor}
          />
        </div>
      </div>
      <ModalGateway>
        {indexImageView !== -1 && (
          <Modal onClose={() => setIndexImageView(-1)}>
            <Carousel
              currentIndex={indexImageView}
              views={
                map(attachments, (file) => ({
                  src: file,
                  caption: "",
                })) as any
              }
            />
          </Modal>
        )}
      </ModalGateway>
    </div>
  );
};

export default DeliveryDetailConfirmedView;
