import InventoryHistoryDrawer from "@components/inventory/components/InventoryHistoryDrawer";
import ProductName from "@components/product/components/ProductName";
import AppContext from "@helpers/context";
import { getInventoryControlEventInfo } from "@network/api/inventory-control-event";
import { getStockProductWarehouse } from "@network/api/product";
import { AwesomeTableComponent, IColumnsProps, Icon } from "d-react-components";
import { find, map } from "lodash";
import { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const InventoryEventDetailInventory = () => {
  const { eventDetail } = useContext(AppContext);
  const tableRef = useRef<any>();
  const { t } = useTranslation();
  const { eventId } = useParams();
  const [inventoryHistory, setInventoryHistory] = useState<any | null>(null);

  const columns: IColumnsProps = [
    {
      title: t("item"),
      dataIndex: "product",
      render: (product) => <ProductName item={{ product }} />,
    },
    {
      title: t("brand"),
      dataIndex: "brand",
      render: (brand) => brand?.name,
    },
    {
      title: t("category"),
      dataIndex: "category",
      render: (category) => category?.name,
    },
    {
      title: t("currentWHEventQty"),
      dataIndex: "stock",
      width: 150,
    },
    {
      title: t("currentWHQty"),
      dataIndex: "stockWarehouse",
      width: 150,
    },
    {
      title: t("history"),
      dataIndex: "",
      width: 100,
      render: (inventory: any) => (
        <Icon
          name="update"
          className="cursor-default"
          onClick={() => setInventoryHistory(inventory)}
        />
      ),
    },
  ];

  const source = async (paging: any) => {
    const inventoryRes = await getInventoryControlEventInfo(paging, eventId);
    const stocks = inventoryRes?.data?.data?.inventory ?? [];

    const body = {
      product_id: map(stocks, (item: any) => item?.product?.id),
      warehouse_id: eventDetail?.warehouse?.id,
    };

    const stockInWHRes = await getStockProductWarehouse(body);
    const stockList = stockInWHRes?.data?.data?.stock ?? [];

    const productDeliveryResult = map(stocks, (productItem: any) => {
      const stockItem = find(
        stockList,
        (item) => item?.product?.id === productItem?.product?.id
      );
      if (stockItem?.product) {
        return {
          ...productItem,
          stockHold: stockItem?.hold,
          stockWarehouse: stockItem?.stock,
        };
      }
      return productItem;
    });
    return productDeliveryResult;
  };

  return (
    <div className="card-container p-4">
      <AwesomeTableComponent
        ref={(ref) => {
          tableRef.current = ref;
        }}
        columns={columns}
        source={source}
        transformer={(res) => res ?? []}
        className=""
      />
      {!!inventoryHistory && (
        <InventoryHistoryDrawer
          open={inventoryHistory}
          onClose={() => setInventoryHistory(null)}
          storeDefault={eventDetail?.warehouse ? [eventDetail?.warehouse] : []}
          productId={inventoryHistory?.product?.id}
        />
      )}
    </div>
  );
};

export default InventoryEventDetailInventory;
