import { Button, Select } from "d-react-components";
import _ from "lodash";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

const AttributeInputItem = ({
  attribute,
  onRemove,
  onChange,
  attributeDataList,
  attributeList,
  index,
}: any) => {
  const { t } = useTranslation();
  const valuesList = useMemo(() => {
    const attributeData = attributeDataList.find(
      (item: any) => item.id === attribute.id
    );
    return attributeData?.values ?? [];
  }, [attribute, attributeDataList]);

  const attributeSelectList = useMemo(() => {
    return attributeDataList.filter((dataItem: any) => {
      if (dataItem.id === attribute.id) return true;
      const isExistInAttribute = _.some(
        attributeList,
        (item) => dataItem.id === item.id
      );
      return !isExistInAttribute;
    });
  }, [attributeDataList, attributeList]);

  return (
    <div className="bg-gray-100 p-3 mb-3 relative">
      <label className="text-label">
        <span>
          {t("attribute")} {index + 1}
        </span>
      </label>
      <Button
        iconName="delete"
        variant="trans"
        onClick={() => onRemove(attribute)}
        className="text-white bg-primary cursor-pointer absolute top-2 right-2"
        size="x-small"
      />
      <Select
        className="mb-3"
        onChange={(id) =>
          onChange({
            ...attribute,
            id,
          })
        }
        value={attribute?.id}
        dataSource={attributeSelectList}
        getLabel={(item) => item.name}
        placeholder={t("pleaseSelect")}
      />
      <Select
        className="mb-3"
        onChange={(id) => {
          onChange({ ...attribute, value: id });
        }}
        value={attribute?.value}
        dataSource={valuesList}
        getLabel={(item) => item.name}
        placeholder={t("pleaseSelect")}
      />
    </div>
  );
};

export default AttributeInputItem;
