import { Path } from "@components/layout/Path";
import UserAvatarName from "@components/shared/UserAvatarName";
import { ORDER_STATUS_ARRAY } from "@constants/order";
import { PAYMENTS_METHODS } from "@constants/payment";
import { REFUND_STATUS_LIST } from "@constants/refund";
import AppContext from "@helpers/context";
import RefundRequestAPI from "@network/api/refund-request";
import {
  AwesomeTableComponent,
  HeaderTable,
  StringUtils,
  TimeUtils,
  ViewLabelStatus,
} from "d-react-components";
import { debounce, find } from "lodash";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";

const RefundRequestTable = () => {
  const { t } = useTranslation();
  const refundOrderListRef = useRef<any>();
  const searchRef = useRef<any>();

  const columns = [
    {
      title: t("dateTime"),
      dataIndex: "created",

      render: TimeUtils.toDateTime,
    },
    {
      title: t("refundStatus"),
      dataIndex: "status",
      render: (status: any) => (
        <ViewLabelStatus
          status={status}
          listStatus={REFUND_STATUS_LIST}
          getLabel={(item) => t(item.label)}
        />
      ),
    },
    {
      title: t("invoiceNo"),
      dataIndex: "order",
      render: (order: any, item: any) => (
        <Link to={generatePath(Path.ORDER_DETAIL, { id: order?.id })}>
          {order?.orderid}
        </Link>
      ),
    },
    {
      title: t("orderStatus"),
      dataIndex: "order",
      render: (order: any) => (
        <ViewLabelStatus
          status={order?.status}
          listStatus={ORDER_STATUS_ARRAY}
          getLabel={(item) => t(item.label)}
        />
      ),
    },
    {
      title: t("refundAmount"),
      dataIndex: "amount",
      render: StringUtils.moneyThaiFormat,
    },
    {
      title: t("method"),
      dataIndex: "method",
      render: (method: any) => {
        const methodName =
          find(PAYMENTS_METHODS, (item) => item.id === method)?.label ?? "N/A";
        return t(methodName);
      },
    },
    {
      title: t("requestor"),
      dataIndex: "by",
      render: (by: any) => <UserAvatarName user={by} />,
    },
  ];

  //**************************************************CONTROL UI************************************** */
  const source = (paging: any) => {
    const body = {
      search: searchRef.current,
    };
    return RefundRequestAPI.list(body, paging);
  };

  const transformer = (res: any) => {
    return res?.data?.data?.refund;
  };

  const onChangeTextSearch = debounce((text) => {
    searchRef.current = text;
    refundOrderListRef.current.refresh();
  }, 1000);

  return (
    <AppContext.Provider value={{}}>
      <div className="bg-white">
        <HeaderTable
          label={t("refundRequest")}
          className="app-layout__page-header"
          onChangeText={(event: any) => onChangeTextSearch(event.target.value)}
        />
        {/* <HeaderProductTable selectedRows={selectedProductRows} /> */}
        <AwesomeTableComponent
          ref={(ref) => (refundOrderListRef.current = ref)}
          source={source}
          transformer={transformer}
          columns={columns}
          isScroll={false}
          //   rowSelection={{
          //     onChange: (selectedRowKeys, selectedRows) => {
          //       setSelectedProductRows(selectedRows);
          //     },
          //   }}
        />
      </div>
    </AppContext.Provider>
  );
};

export default RefundRequestTable;
