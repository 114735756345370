import AppContext from "@helpers/context";
import { Rental } from "@interfaces/rent";
import { detailRental } from "@network/api/rental";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import ContractHeader from "./header/ContractHeader";
import OrderDetailTable from "@components/order/detail/content/OrderDetailTable";
import OrderNote from "@components/order/detail/content/OrderNote";
import OrderDetailWarehouse from "@components/order/detail/content/OrderDetailWarehouse";
import SalesPerson from "@components/order/components/shipping/SalesPerson";
import CustomerNameInfo from "@components/order/components/shipping/CustomerNameInfo";
import ShippingAddress from "@components/order/components/shipping/ShippingAddress";
import { OrderType } from "@constants/common";
import { getProvinceList } from "@network/api/address";
import { useUpdateEffect } from "react-use";
import { isArray, reduce } from "lodash";
import BillingInformation from "@components/order/components/shipping/BillingInfo";
import DeliveryTracking from "@components/order/components/delivery/DeliveryTracking";
import {
  getContractDeliveryList,
  getDeliveryTypeList,
} from "@network/api/delivery";
import { IChannel } from "@interfaces/channel";
import CreateInvoiceModal from "./modal/CreateInvoiceModal";
import { PAYMENTS_METHODS } from "@constants/payment";
import SubscriptionContractPayment from "./content/SubscriptionContractPayment";
import { useTranslation } from "react-i18next";
import SubscriptionContractDocuments from "./content/SubscriptionContractDocuments";
import CustomerInformation from "./content/CustomerInformation";
import SubscriptionContractSignature from "./content/SubscriptionContractSignature";
import ContractActivities from "./content/ContractActivities";

export interface SubscriptionContractContext {
  [key: string]: any;
}

const SubscriptionContractDetail = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [showCreateInvoiceModal, setShowCreateInvoiceModal] = useState(false);
  const [rentalInfo, setRentInfo] = useState<Rental>();
  const [deliveryList, setDeliveryList] = useState([]);
  const [deliveryTypes, setDeliveryTypes] = useState([]);
  const { currentLang } = useContext(AppContext);
  const [provinceList, setProvinceList] = useState();

  const paymentMethodsList = useMemo(
    () => PAYMENTS_METHODS?.map((item) => ({ ...item, label: t(item.label) })),
    [t]
  );

  const loadRentalData = () => {
    if (!id) {
      return;
    }
    detailRental({ id }).then((res) => {
      const rentInfo: Rental = res?.data?.data?.rent;
      setRentInfo(rentInfo);
      if (res) {
        const {
          channel,
          products,
          billing,
          salesPerson,
          code,
          created,
          updated,
        } = rentInfo;
        document.title = `${channel?.name}-Order${rentInfo?.code}`;
        // const manualProductsNotMap = getManualProductNotMap(
        //   products,
        //   manualProducts
        // );
        // const shippingList = shipping?.map((item: any) => ({
        //   ...item,
        //   expectedDateReceive: item.expected_to_receive,
        // }));
        // const isChainExternalSuspicious =
        //   is_suspicious &&
        //   suspicious_status === ORDER_SUSPICIOUS_STATUS.PENDING &&
        //   status !== "cancelled";
        // const hasCustomSizeProduct =
        //   is_custom_size &&
        //   custom_size_status === ORDER_CUSTOM_SIZE_STATUS.PENDING &&
        //   status !== "cancelled";
        // setManualProductsNotMap(manualProductsNotMap);
        // setOrderInfo({
        //   ...resOrderInfo,
        //   shipping: shippingList,
        //   salesPerson,
        // });
        // setIsChainExternalSuspicious(isChainExternalSuspicious);
        // setHasCustomSizeProduct(hasCustomSizeProduct);
        // setSelectedChannel(channel);
        // setPackageList(list_package ?? []);

        // preloadDataRefund(refund);
      }
    });
  };

  const loadProvinceData = () => {
    getProvinceList().then((res) => {
      const provinceList = res?.data?.data?.province;
      if (provinceList) {
        setProvinceList(provinceList);
      }
    });
  };

  const loadDeliveryList = () => {
    getContractDeliveryList(id).then((res) => {
      const deliveryTypes = res?.data?.data?.delivery ?? [];
      setDeliveryList(deliveryTypes);
    });
  };

  const loadDeliveryTypes = (channel: IChannel) => {
    const channelid = channel?.id;
    getDeliveryTypeList(channelid).then((res) => {
      const deliveryTypes = res?.data?.data?.delivery ?? [];
      setDeliveryTypes(deliveryTypes);
    });
  };

  useEffect(() => {
    loadRentalData();
    loadProvinceData();
    loadDeliveryList();
  }, []);

  useUpdateEffect(() => {
    loadRentalData();
    loadProvinceData();
  }, [currentLang]);

  const transformAddress = (address: any) => {
    if (isArray(address)) {
      return address.map((add) => ({ ...add, postcode: add.code }));
    }
    return {
      ...address,
      postcode: address.code,
    };
  };

  const remainingItems = useMemo(() => {
    if (!rentalInfo) {
      return 0;
    }
    const { products } = rentalInfo || {};
    const totalQuantity = reduce(
      products,
      (sum, product) => {
        const { groups } = product;
        if (!groups || groups.length === 0) {
          return (sum += product?.quantity);
        }
        const sumQuantity = reduce(
          groups,
          (sumGroup, proGroup) => (sumGroup += proGroup?.quantity),
          0
        );
        return (sum += sumQuantity);
      },
      0
    );

    let totalDelivered = 0;
    if (deliveryList?.length) {
      deliveryList.forEach((deliveryItem: any) => {
        if (deliveryItem.status === "cancel") {
          return;
        }
        deliveryItem?.products.forEach((skuItem: any) => {
          totalDelivered += skuItem.quantity;
        });
      });
    }

    return totalQuantity - totalDelivered;
  }, [rentalInfo, deliveryList]);

  const updateCustomerInfo = (updatedInfo: any) => {
    setRentInfo({
      ...rentalInfo,
      customer: { ...rentalInfo?.customer, ...updatedInfo },
    } as Rental);
  };

  const updateContactInformation = (updatedInfo: any) => {
    setRentInfo({
      ...rentalInfo,
      information: { ...rentalInfo?.information, ...updatedInfo },
    } as Rental);
  };

  const stateValue = useMemo<SubscriptionContractContext>(() => {
    return {
      id,
      rentalInfo,
      editable: true,
      products: rentalInfo?.products,
      order: {
        total: rentalInfo?.total,
        sub_total: rentalInfo?.sub_total,
        store: rentalInfo?.warehouse,
        status: rentalInfo?.status,
        id: rentalInfo?.id,
        has_edit_shipping: true,
        has_delete_shipping: true,
        billing: rentalInfo?.billing
          ? transformAddress(rentalInfo?.billing)
          : {},
        note: rentalInfo?.note,
        expire: rentalInfo?.expire,
        month: rentalInfo?.month,
        channel: rentalInfo?.channel,
        pdf_contract: rentalInfo?.pdf_contract,
      },
      channel: rentalInfo?.channel,
      salesPerson: rentalInfo?.salesPerson,
      by: rentalInfo?.by,
      customer: rentalInfo?.customer,
      updateCustomerInfo,
      billing: rentalInfo?.billing ? transformAddress(rentalInfo?.billing) : {},
      shipping: rentalInfo?.shipping
        ? transformAddress(rentalInfo?.shipping)
        : [],
      shippingProfile: rentalInfo?.shipping
        ? transformAddress(rentalInfo?.shipping)
        : [],
      values: {
        selectedCustomer: [rentalInfo?.customer],
      },
      onReloadOrderData: loadRentalData,
      onReloadOrderDelivery: loadDeliveryList,
      provinceList,
      loadProvinceList: loadProvinceData,
      delivery: deliveryList,
      deliveryTypes,
      remainingItems,
      paymentMethodsList,
      type: OrderType.RENTAL,
      updateContactInformation,
      coupons: rentalInfo?.discounts,
      fee_tax: rentalInfo?.fee_tax,
    };
  }, [rentalInfo, provinceList, deliveryList, deliveryTypes, remainingItems]);

  useEffect(() => {
    if (rentalInfo?.channel) {
      loadDeliveryTypes(rentalInfo.channel as IChannel);
    }
  }, [rentalInfo?.channel]);

  return (
    <AppContext.Provider value={stateValue}>
      <ContractHeader />

      <div className="grid grid-cols-3 gap-4 mt-3 px-3 pb-3 pt-0">
        <div className="col-span-3 md:col-span-2">
          {rentalInfo && (
            <>
              <OrderDetailTable />
              <OrderNote className="my-3" orderType={OrderType.RENTAL} />
              <div className="bg-white p-3 mb-3">
                <SubscriptionContractPayment />
              </div>
              <SubscriptionContractDocuments />
              <SubscriptionContractSignature />
              <DeliveryTracking orderType={OrderType.RENTAL} />
              <ContractActivities />
            </>
          )}
        </div>
        <div className="col-span-3 md:col-span-1">
          {rentalInfo?.channel && <OrderDetailWarehouse />}
          {rentalInfo?.salesPerson && <SalesPerson />}
          <div className="bg-white p-3 mb-3 shadow-sm">
            <CustomerNameInfo />
          </div>
          <div className="bg-white p-3 mb-3 shadow-sm">
            <CustomerInformation />
          </div>
          <div className="bg-white p-3 mb-3 shadow-sm">
            <ShippingAddress orderType={OrderType.RENTAL} />
          </div>
          <div className="bg-white p-3 mb-3 shadow-sm">
            <BillingInformation orderType={OrderType.RENTAL} />
          </div>
        </div>
      </div>
      {showCreateInvoiceModal && (
        <CreateInvoiceModal
          open={showCreateInvoiceModal}
          onClose={() => setShowCreateInvoiceModal(false)}
        />
      )}
    </AppContext.Provider>
  );
};

export default SubscriptionContractDetail;
