import { useFormik } from "formik";
import React, { useMemo, useState } from "react";
import ProductAliasImportCSVFile from "./ProductAliasImportCSVFile";
import ProductAliasImportFooter from "./ProductAliasImportFooter";
import ProductAliasImportMethod from "./ProductAliasImportMethod";
import ProductAliasImportResult from "./ProductAliasImportResult";
import ProductAliasImportValidation from "./ProductAliasImportValidation";
import { IMPORT_PRODUCT_ALIAS_STEPS } from "@constants/product";
import AppContext from "@helpers/context";
import { useTranslation } from "react-i18next";

export const INIT_IMPORT_FORM = {
  importType: null,
  fileImport: null,
};

const ProductAliasImport = () => {
  const [stepIndex, setStepIndex] = useState(0);
  const { t } = useTranslation();
  const step = useMemo(
    () => IMPORT_PRODUCT_ALIAS_STEPS[stepIndex],
    [stepIndex]
  );

  const formImport = useFormik({
    initialValues: INIT_IMPORT_FORM,
    validateOnBlur: false,
    validateOnChange: false,
  } as any);

  const classNameHeader =
    "d-flex justify-content-between align-items-center p-4 border-bottom";

  const renderContent = () => {
    switch (stepIndex) {
      case 0:
        return <ProductAliasImportMethod />;
      case 1:
        return <ProductAliasImportCSVFile />;
      case 2:
        return <ProductAliasImportValidation />;
      case 3:
        return <ProductAliasImportResult />;
      default:
        return <div />;
    }
  };

  return (
    <AppContext.Provider value={{ step, formImport, stepIndex, setStepIndex }}>
      <div className="bg-white">
        <div className={classNameHeader}>
          <label>{t(step?.title)}</label>
          <div className="bg-dark px-3 py-1">
            <small className="text-white">{`Step ${stepIndex + 1} of ${
              IMPORT_PRODUCT_ALIAS_STEPS.length
            }`}</small>
          </div>
        </div>
        {renderContent()}
        <ProductAliasImportFooter />
      </div>
    </AppContext.Provider>
  );
};

export default ProductAliasImport;
