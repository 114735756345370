import UserAvatarName from "@components/shared/UserAvatarName";
import AppContext from "@helpers/context";
import { ITransferStock } from "@interfaces/transfer-stock";
import {
  Button,
  IRowsKey,
  TimeUtils,
  ViewRowInterchange,
} from "d-react-components";
import moment from "moment";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const TransferStockDetailGeneral = () => {
  const { transferDetail } = useContext(AppContext);
  const { t } = useTranslation();

  const DETAILS_KEY = useMemo<IRowsKey<ITransferStock>[]>(
    () => [
      {
        id: "transfer_no",
        label: t("id"),
      },

      {
        id: "transfer_origin",
        label: t("origin"),
        renderContent: ({ data, item }) => `${data?.name} (${data?.code})`,
      },
      {
        id: "transfer_destination",
        label: t("destination"),
        renderContent: ({ data, item }) => `${data?.name} (${data?.code})`,
      },
      {
        id: "transfer_type",
        label: t("transferType"),
      },
      {
        id: "stock_inventory_control",
        label: t("event"),
        renderContent: ({ data, item }) => `${data?.title} (${data?.code})`,
      },
      {
        id: "expected_transfer",
        label: t("estTransferDate"),
        renderContent: ({ data, item }) => TimeUtils.toDateTime(data),
      },

      {
        id: "expected_arrival",
        label: t("estArrivalDate"),
        renderContent: ({ data, item }) => TimeUtils.toDateTime(data),
      },
      {
        id: "remark",
        label: t("remark"),
      },
      {
        id: "created",
        label: t("createdAt"),
        renderContent: ({ data, item }) => TimeUtils.toDateTime(data),
      },
      {
        id: "by",
        label: t("by"),
        renderContent: ({ data, item }) => <UserAvatarName user={data} />,
      },
    ],
    [t]
  );

  return (
    <div className="card-container p-4 ">
      <div className="d-flex justify-content-between align-items-center">
        <label>{t("generalInformation")}</label>
        {/* <Button onClick={() => setOpenEdit(true)} variant="trans">
          {t("edit")}
        </Button> */}
      </div>

      <ViewRowInterchange
        dataSource={transferDetail}
        keyList={DETAILS_KEY as any}
        variant="border"
        classNameLabel="font-weight-bold "
      />
    </div>
  );
};

export default TransferStockDetailGeneral;
