import Drawer from "@components/shared/Drawer";
import UserAvatarName from "@components/shared/UserAvatarName";
import { getProductionCostHistory } from "@network/api/expense";
import { AwesomeTableComponent, TimeUtils } from "d-react-components";
import React, { useRef } from "react";
import CurrencyFormat from "react-currency-format";
import { useTranslation } from "react-i18next";

const HistoryDrawer = ({ product, channel, open, onClose }: any) => {
  const { t } = useTranslation();
  const tableRef = useRef<any>();

  const transformer = (res: any) => {
    return res?.data?.data?.history ?? [];
  };

  const columns = [
    {
      title: t("date"),
      dataIndex: "created",
      render: (date: any) => TimeUtils.convertMiliToDateTime(date),
    },
    {
      title: t("productionCost"),
      dataIndex: "production_cost",
      render: (production_cost: any) => (
        <CurrencyFormat
          value={production_cost}
          displayType="text"
          thousandSeparator={true}
        />
      ),
    },
    {
      title: t("saleExpense"),
      dataIndex: "sale_expense",
      render: (sale_expense: any) => `${sale_expense}%`,
    },
    {
      title: t("adminExpense"),
      dataIndex: "admin_expense",
      render: (admin_expense: any) => `${admin_expense}%`,
    },
    {
      title: t("importedBy"),
      dataIndex: "by",
      render: (by: any) => <UserAvatarName user={by} />,
    },
  ];

  const source = (paging: any) => {
    return getProductionCostHistory({
      channel_id: channel,
      product_id: product,
      page: paging.pageIndex,
      limit: paging.pageSize,
    });
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("history")}
      width={"800px"}
      size="auto"
    >
      <AwesomeTableComponent
        ref={(ref) => {
          tableRef.current = ref;
        }}
        columns={columns}
        source={source}
        transformer={transformer}
        className="mt-3"
      />
    </Drawer>
  );
};

export default HistoryDrawer;
