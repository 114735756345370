import { Spin } from "antd";
import { Icon } from "d-react-components";
import { forEach, map } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { getImportProducts } from "./FileValidationAndImport";
import AppContext from "@helpers/context";
import { importProduct } from "@network/api/product";
import { useTranslation } from "react-i18next";

const ImportResult = () => {
  const { formImport } = useContext(AppContext);
  const { productType, importType, importFields, fileImport } =
    formImport.values;
  const [errorValidation, setErrorValidation] = useState([]);
  const [loadingImport, setLoadingImport] = useState(true);
  const { t } = useTranslation();

  useEffect(() => onImportProduct(), [fileImport.data]);

  const onImportProduct = () => {
    const body = {
      product_type: productType,
      method: importType,
      fields: map(importFields, (item) => item.name),
      products: getImportProducts(fileImport.data, importFields, productType),
    };

    importProduct(body)
      .then((res) => {
        setLoadingImport(false);
      })
      .catch((error) => {
        setLoadingImport(false);
      });
  };

  const getErrorItemsData = () => {
    const header = [t("sku"), "Message"];
    const dataResult = [header];
    forEach(errorValidation, (validation: any) => {
      forEach(validation?.validation ?? [], (item) =>
        dataResult.push([validation?.sku, item])
      );
    });
    return dataResult;
  };
  const getSuccessItemsData = () => {
    const header = [t("sku")];
    const dataResult = [header];
    forEach(fileImport.data ?? [], (data) => {
      dataResult.push([data?.sku]);
    });
    return dataResult;
  };

  if (loadingImport) {
    return (
      <div className="p-4 flex-center">
        <Spin />
      </div>
    );
  }
  return (
    <div className="p-4">
      <div className="d-flex mt-3">
        <Icon name="check_circle_outline" className="mr-3 text-success" />
        <div>
          {fileImport.data?.length - errorValidation?.length}{" "}
          {t("skuImportedSuccessfully")}
        </div>
        <CSVLink data={getSuccessItemsData()}>
          <div className="ml-1 text-primary">{t("downloadLog")}</div>
        </CSVLink>
      </div>

      <div className="d-flex mt-3">
        <Icon name="error" className="mr-3 text-error" />
        <div>
          {errorValidation?.length} {t("skuFailedToImport")}
        </div>
        <CSVLink data={getErrorItemsData()}>
          <div className="ml-1 text-primary">{t("downloadLog")}</div>
        </CSVLink>
      </div>
    </div>
  );
};

export default ImportResult;
