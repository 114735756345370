import InputSelectForm from "@common/input/InputSelectForm";
import Drawer from "@components/shared/Drawer";
import UserSelect from "@components/user/components/UserSelect";
import WarehouseSelect from "@components/warehouse/components/WarehouseSelect";
import { DISCOUNT_STAFF_STATUS_LIST } from "@constants/discount";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

function DiscountStaffFilterDrawer({
  open,
  onClose,
  defaultValue,
  onFilter,
}: any) {
  const { t } = useTranslation();

  const filterForm = useFormik({
    initialValues: defaultValue,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      onFilter && onFilter(values);
      onClose();
    },
  });
  const formValues = filterForm?.values ?? {};
  const formErrors = filterForm?.errors;

  const classNameField = "col-span-12";

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("filter")}
      onSave={() => filterForm.handleSubmit()}
      size="large"
    >
      <div className="grid grid-cols-4 gap-4">
        <InputSelectForm
          className={classNameField}
          label={t("status")}
          dataSource={DISCOUNT_STAFF_STATUS_LIST}
          keyData="status"
          form={filterForm}
          multiple
        />
        <WarehouseSelect
          className={classNameField}
          value={formValues?.store}
          onChange={(value) => filterForm.setFieldValue("store", value)}
          multiple
        />
        <UserSelect
          label={t("staff")}
          className={classNameField}
          value={formValues?.staff}
          onChange={(value) => filterForm.setFieldValue("staff", value)}
          multiple
        />
      </div>
    </Drawer>
  );
}

export default DiscountStaffFilterDrawer;
