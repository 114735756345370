import { useMemo } from "react";
import { matchRoutes, useLocation } from "react-router-dom";
import { split, join } from "lodash";
import { IMenuItemProps, MenuPropsType } from "@constants/menu";
import { menuFlatter } from "./menu";
import MAIN_MENU from "@components/layout/MainMenu";

const getMenuItemPath = (item: IMenuItemProps, isDetail?: boolean) => {
  const itemPath = item.path;
  if (!isDetail) {
    return itemPath;
  }
  const params = split(itemPath, "/");
  params.pop();
  const newPath = join(params, "/");
  return newPath;
};

export function usePathInfo(data: MenuPropsType): any {
  const location = useLocation();
  const flatMenu = menuFlatter(MAIN_MENU);
  const matched = matchRoutes(flatMenu as any, location);
  let pathName = matched?.[0]?.route?.path;
  return useMemo(() => {
    let menuSelecting;
    data.forEach((menuItem) => {
      const itemPath = getMenuItemPath(menuItem);
      if (itemPath === pathName) {
        menuSelecting = menuItem;
      }

      if (menuItem.subMenu && menuItem.subMenu?.length > 0) {
        menuItem.subMenu.forEach((subMenuItem) => {
          const subMenuItemPath = getMenuItemPath(subMenuItem);
          if (subMenuItemPath === pathName) {
            menuSelecting = subMenuItem;
          }
        });
      }
    });
    return menuSelecting;
  }, [pathName]);
}
