import { Path } from "@components/layout/Path";
import UserAvatarName from "@components/shared/UserAvatarName";
import { DELIVERY_STATUS_LIST } from "@constants/delivery";
import AppContext from "@helpers/context";

import {
  AwesomeTableComponent,
  HeaderTable,
  ITabItem,
  TimeUtils,
  useFirstTime,
  ViewLabelStatus,
} from "d-react-components";

import { debounce, map } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import DeliverySOFilterDrawer from "./DeliverySOFilterDrawer";
import DeliverySOTabs from "./DeliverySOTabs";
import { getDeliveryStockList } from "@network/api/delivery-stock";

const DeliverySOTable = () => {
  const tableRef = useRef<any>();
  const searchRef = useRef<string>();
  const [tabSelected, setTabSelected] = useState<ITabItem>({
    id: "all",
    label: "all",
  });
  const [openFilter, setOpenFilter] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [filter = {}, setFilter] = useQueryParam<any>("filter", JsonParam);

  const isFirstTime = useFirstTime();

  useEffect(() => {
    if (isFirstTime) return;
    tableRef.current?.refresh();
  }, [JSON.stringify(filter), tabSelected]);

  const TABLE_ACTIONS = [
    { id: "edit", label: t("edit") },
    { id: "detail", label: t("detail") },
  ];

  const source = (paging: any) => {
    const body: any = {
      search: searchRef?.current,
      status:
        !tabSelected || tabSelected?.id === "all" ? null : [tabSelected?.id],
    };
    if (filter) {
      const {
        status,
        channels,
        warehouseOrigin,
        warehouseDestination,
        driver,
        vehicle,
        createdBy,
      } = filter;

      body.status = status;

      body.channels = channels && map(channels, (item) => item.id);
      body.warehouse_origin =
        warehouseOrigin && map(warehouseOrigin, (item) => item.id);
      body.warehouse_destination =
        warehouseDestination && map(warehouseDestination, (item) => item.id);

      body.vehicle = vehicle && map(vehicle, (item) => item.id);
      body.created_by = createdBy && map(createdBy, (item) => item.id);
      body.driver = driver && map(driver, (item) => item.id);
    }
    return getDeliveryStockList(body, paging);
  };

  const columns: any[] = [
    {
      title: t("doNo"),
      dataIndex: "ref",
      ellipsis: true,
      render: (ref: string, delivery: any) => (
        <Link
          to={generatePath(Path.DELIVERY_SO_DETAIL, {
            deliveryId: delivery.id,
          })}
        >
          {ref}
        </Link>
      ),
    },
    {
      title: t("soNo"),
      dataIndex: "so_no",
    },
    {
      title: t("doNo"),
      dataIndex: "ref",
    },
    {
      title: t("origin"),
      dataIndex: "transfer",
      render: (transfer: any) =>
        `${transfer?.transfer_origin?.name} - ${
          transfer?.transfer_origin?.code ?? ""
        }`,
    },
    {
      title: t("destination"),
      dataIndex: "transfer",
      render: (transfer: any) =>
        `${transfer?.transfer_destination?.name} - ${
          transfer?.transfer_destination?.code ?? ""
        }`,
    },
    {
      title: t("vehicleNo"),
      dataIndex: "vehicle",
      render: (vehicle: any) => vehicle?.registration ?? "",
    },
    {
      title: t("driver"),
      dataIndex: "driver",
      align: "center",
      render: (driver: any) => <UserAvatarName user={driver} />,
    },

    {
      title: t("itemQty"),
      dataIndex: "total_item",
    },

    {
      title: t("status"),
      dataIndex: "status",
      render: (status: string) => (
        <ViewLabelStatus
          status={status}
          listStatus={DELIVERY_STATUS_LIST}
          getLabel={(item) => t(item.label)}
        />
      ),
    },
    {
      title: t("estArrival"),
      dataIndex: "estimated_arrival",
      render: TimeUtils.toDateTime,
    },

    {
      title: t("createdBy"),
      dataIndex: "by",
      render: (by: any) => <UserAvatarName user={by} />,
    },

    {
      title: t("createAt"),
      dataIndex: "created",
      render: TimeUtils.toDateTime,
    },
  ];

  const onClickAction = (action: any, transfer: any) => {
    switch (action.id) {
      case "edit":
        break;

      case "detail":
        break;

      default:
        break;
    }
  };

  const onChangeSearch = debounce((text) => {
    searchRef.current = text;
    tableRef.current.refresh();
  }, 300);

  const isFiltered = useMemo(() => Object.keys(filter).length > 0, [filter]);

  return (
    <div className="bg-white">
      <AppContext.Provider value={{ tabSelected }}>
        <HeaderTable
          label={t("deliverySO")}
          onChangeText={(event: any) => onChangeSearch(event.target.value)}
          onClickFilter={() => setOpenFilter(true)}
          isFiltered={isFiltered}
          className="app-layout__page-header"
        />

        <div className="d-flex overflow-auto">
          <DeliverySOTabs
            tabSelected={tabSelected}
            onChangeTab={setTabSelected}
          />
        </div>
        <div className="overflow-scroll">
          <AwesomeTableComponent
            source={source}
            transformer={(res) => res?.data?.data?.delivery ?? []}
            isScroll={false}
            columns={columns}
            ref={(ref) => (tableRef.current = ref)}
          />
        </div>

        {openFilter && (
          <DeliverySOFilterDrawer
            open={openFilter}
            onClose={() => setOpenFilter(false)}
            defaultValue={filter}
            onFilter={setFilter}
          />
        )}
      </AppContext.Provider>
    </div>
  );
};

export default DeliverySOTable;
