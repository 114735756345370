export const STOCK_ADJUSTMENT_STATUS_LIST = [
  {
    id: "pending",
    value: "pending",
    label: "pending",
    color: "#FFBD59 ",
  },

  {
    id: "approved",
    value: "approved",
    label: "approved",
    color: "#27AE60",
  },
  { id: "rejected", value: "rejected", label: "rejected", color: "#EB5757" },
  {
    id: "cancelled",
    value: "cancelled",
    label: "cancelled",
    color: "#EB5757",
  },
];

export const STOCK_ADJUSTMENT_STATUS = {
  PENDING: "pending",
  APPROVED: "approved",
  REJECTED: "rejected",
  CANCELLED: "cancelled",
};
