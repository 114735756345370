import { useTranslation } from "react-i18next";
import React, { useContext, useMemo, useState } from "react";
import Drawer, { DrawerProps } from "@components/shared/Drawer";
import AppContext from "@helpers/context";
import { InputText, Notifications, Progress, Select } from "d-react-components";
import styled from "@emotion/styled";
import { isEmpty } from "lodash";
import { listRentalInvoice, resendInvoice } from "@network/api/rental";
import {
  METHODS,
  SendMethod,
  VerifiedButton,
  VerifyButton,
} from "./ResendContractModal";
import { SelectInfinity } from "@components/shared/SelectInfinity";

const ResendInvoiceModal = ({
  open,
  onClose,
}: DrawerProps & { onClose: any }) => {
  const { t } = useTranslation();
  const [method, setMethod] = useState(METHODS[0].value);
  const [invoice, setInvoice] = useState<any>();
  const { rentalInfo } = useContext(AppContext);
  const { information } = rentalInfo ?? {};
  const { is_verify_email, is_verify_phone } = information ?? {};

  const onResend = () => {
    Progress.show(
      {
        method: resendInvoice,
        params: [
          {
            id: rentalInfo.id,
            invoice: invoice?.[0]?.id,
            method:
              method === "both" ? [SendMethod.Sms, SendMethod.Email] : [method],
          },
        ],
      },
      (res: any) => {
        Notifications.showSuccess(t("notification:resendInvoiceSuccess"));
        onClose && onClose();
      }
    );
  };

  const disableSave = useMemo(() => {
    if (isEmpty(invoice)) {
      return true;
    }
    if (method === SendMethod.Sms) {
      return isEmpty(information?.phone);
    }
    if (method === SendMethod.Email) {
      return isEmpty(information?.email);
    }
  }, [method, information]);

  return (
    <Drawer
      title={t("resendContractURL")}
      open={open}
      onClose={onClose}
      destroyOnClose
      onSave={onResend}
      disableSave={disableSave}
    >
      <StyledForm>
        <SelectInfinity
          showSearch={false}
          label={t("invoice")}
          className="mb-3"
          value={invoice}
          placeholder={t("search")}
          fetchFn={(body: any, paging: any) => {
            return listRentalInvoice({
              id: rentalInfo.id,
              pageIndex: paging.page,
              pageSize: paging.per_page,
            });
          }}
          dataPath="invoice"
          onChange={(value: any) => {
            setInvoice(value);
          }}
          getLabel={(item) => item?.code}
          getValue={(item) => item?.id}
        />
        <Select
          dataSource={METHODS}
          value={method}
          label={t("method")}
          placeholder={t("method")}
          onChange={(value) => setMethod(value)}
          getLabel={(item) => t(item.label)}
          getValue={(item) => item.value}
          className="mb-3"
          allowClear={false}
        />
        <InputText
          value={information?.email}
          placeholder={t("email")}
          label={t("email")}
          readOnly
          suffix={is_verify_email ? <VerifiedButton /> : <VerifyButton />}
          className="mb-3"
          hidden={method === SendMethod.Sms}
        />
        <InputText
          value={information?.phone}
          placeholder={t("phone")}
          label={t("phone")}
          readOnly
          suffix={is_verify_phone ? <VerifiedButton /> : <VerifyButton />}
          className="mb-3"
          hidden={method === SendMethod.Email}
        />
      </StyledForm>
    </Drawer>
  );
};

const StyledForm = styled.div`
  .d-input-text__suffix-container {
    background: #fff !important;
    border: none !important;
  }
`;

export default ResendInvoiceModal;
