/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */

import Drawer from "@components/shared/Drawer";
import { importPriceProduct, validateSkuProduct } from "@network/api/product";
import { Progress } from "antd";
import { Button, Icon, Notifications, StringUtils } from "d-react-components";
import _, { every, filter, isEmpty, map } from "lodash";
import React, { useState } from "react";
import { CSVLink } from "react-csv";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

const ProductTableImportDrawer = ({
  open,
  onClose,
  onSaveProduct,
  refreshProductTable,
}: any) => {
  const [importSuccess, setImportSuccess] = useState(false);
  const [validateLoading, setValidateLoading] = useState(false);
  const [percentValidation, setPercentValidation] = useState(0);
  const [skuList, setSkuList] = useState<any>([]);
  const { t } = useTranslation();

  const validateSkuList = (skuListConverted: any) => {
    return every(skuListConverted, (sku) => {
      return (
        !isEmpty(sku.sku) &&
        !isEmpty(sku.regular_price) &&
        StringUtils.isAllDigit(sku.regular_price) &&
        !_.isEmpty(sku.default_sale_price) &&
        StringUtils.isAllDigit(sku.default_sale_price)
      );
    });
  };

  const onDrop = (files: any) => {
    const reader = new FileReader();
    reader.readAsText(files[0]);
    reader.onload = function (e) {
      // const test = convertCSVToJSON(reader.result)
      const skuList = StringUtils.convertCsvJSON(reader.result);
      if (!validateSkuList(skuList)) {
        setValidateLoading(false);
        Notifications.showError(t("fileImportIsNotRightFormat"));
        return;
      }

      setValidateLoading(true);
      const config = {
        onUploadProgress: (progressEvent: any) => {
          const percentValidation = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setPercentValidation(percentValidation);
          // do whatever you like with the percentage complete
          // maybe dispatch an action that will update a progress bar or something
        },
      };

      const body = {
        sku: skuList.map((item) => item.sku),
      };
      console.log({ skuList });
      validateSkuProduct(body, config)
        .then((res) => {
          const validation = res?.data?.data?.validate;

          const skuListResult = [];
          for (const key in validation) {
            const sku = validation[key];
            const skuFromImport = skuList.find(
              (skuParam) => skuParam.sku === sku.sku
            );
            if (!skuFromImport) {
              Notifications.showError("Invalid data");
              return;
            }
            skuListResult.push({
              ...sku,
              regular_price: parseInt(skuFromImport.regular_price),
              sale_price: parseInt(skuFromImport.default_sale_price),
            });
          }
          console.log({ skuListResult });
          setSkuList(skuListResult);
          setValidateLoading(false);
          setImportSuccess(true);
        })
        .catch((error) => {
          setValidateLoading(false);
        });
    };
  };

  const getSkuUnValids = () => {
    return filter(
      map(skuList, (sku) => {
        if (sku.sale_price > sku.regular_price) {
          return {
            ...sku,
            message: t("salePriceCannotGreater"),
            status: false,
          };
        }
        return sku;
      }),
      (sku) => {
        return !sku.status;
      }
    );
  };

  const getSkuUnValidNumber = () => {
    return getSkuUnValids().length;
  };

  const getSkuValidNumber = () => {
    return skuList.length - getSkuUnValidNumber();
  };

  const getErrorItemsData = () => {
    const skuUnValid = getSkuUnValids();
    const header = ["sku", "regular_price", "default_sale_price", "messages"];
    const dataResult = [header];
    skuUnValid.forEach((sku) => {
      dataResult.push([
        sku?.sku,
        sku.regular_price,
        sku.sale_price,
        sku.message,
      ]);
    });
    return dataResult;
  };

  const onClickImport = async () => {
    const imports = map(skuList, (item) => {
      return {
        sku: item.sku,
        regular_price: item.regular_price,
        sale_price: item.sale_price,
      };
    });
    const body = { imports };

    return importPriceProduct(body)
      .then(() => {
        Notifications.showSuccess(t("changeProductPriceSuccess"));
        setImportSuccess(false);
        refreshProductTable();
        onClose();
      })
      .catch((err) => {
        setImportSuccess(false);
      });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const contentImport = () => {
    return (
      <div className="importModalContainer">
        <div className="subTitle1">{t("uploadProduct")}</div>
        <div
          className="subTitle1"
          style={{ flexDirection: "row", display: "flex" }}
        >
          <div style={{ fontWeight: "bold", color: "black" }}>
            {t("noteName")} :
          </div>
          {t("requireFieldsImportProduct")}
        </div>
        <div style={{ marginTop: "2rem" }}>
          {validateLoading ? (
            <Progress percent={percentValidation} />
          ) : (
            <Button iconName="attachment" {...getRootProps()}>
              <div>
                <input
                  {...getInputProps()}
                  type="file"
                  accept=".csv, text/csv"
                  multiple={false}
                />
                <text className="text-white">{t("attachCSV")}</text>
              </div>
            </Button>
          )}
        </div>
        <div>{t("notSureHowToStart")}</div>
        <div className="subTitle1">- {t("downloadAsvFile")}</div>
        <div className="flex-row">
          - {t("download")}
          <div
            className="text-primary"
            onClick={() =>
              window.open(
                `https://onlinesale.lotusbeddinggroup.com/storage/exemple/template-import-price.csv?v=${new Date().getTime()}`
              )
            }
          >
            {t("blankCSVTemplate")}
          </div>
        </div>
      </div>
    );
  };

  const contentImportSuccess = () => {
    return (
      <div className="importModalContainer">
        <Progress percent={100} />
        <div className="itemImportResult">
          <Icon name="check_circle" className="text-success" />
          <div className="subTitle1">
            {getSkuValidNumber()} {t("itemValidToImport")}
          </div>
        </div>

        <div className="itemImportResult">
          <Icon name="error" className="text-error" />
          <div className="subTitle1">
            {getSkuUnValidNumber()} {t("itemHaveErrorImport")}
          </div>
          <CSVLink data={getErrorItemsData()}>
            <div className="text-primary">{t("downloadLog")}</div>
          </CSVLink>
        </div>
      </div>
    );
  };

  return (
    <Drawer
      title={t("bulkPriceUpdate")}
      open={open}
      size="large"
      onSave={onClickImport}
      onClose={() => {
        setImportSuccess(false);
        onClose();
      }}

      //   disabledSaveButton={!importSuccess || getSkuUnValidNumber() > 0}
    >
      {importSuccess ? contentImportSuccess() : contentImport()}
    </Drawer>
  );
};

export default ProductTableImportDrawer;
