import Drawer from "@components/shared/Drawer";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ProductFilterForm from "./ProductFilterForm";

const ProductTableFilterDrawer = ({
  open,
  onClose,
  onFilter,
  defaultFilter,
}: any) => {
  const [filterBody, setFilterBody] = useState(defaultFilter);
  const { t } = useTranslation();

  const onChangeData = (newData: any) => {
    setFilterBody({ ...filterBody, ...newData });
  };

  const onClickFilter = () => {
    onFilter(filterBody);
    onClose();
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("filter")}
      onSave={onClickFilter}
    >
      <ProductFilterForm filterBody={filterBody} onChangeData={onChangeData} />
    </Drawer>
  );
};

export default ProductTableFilterDrawer;
