import {
  FILTER_ORDER_PICKUP,
  ORDER_STATUS,
  ORDER_STATUS_ARRAY,
  ORDER_STATUS_MENU,
  PRINT_ORDER_OPTION,
} from "@constants/order";
import styled from "@emotion/styled";
import AppContext from "@helpers/context";
import { getPaymentStatus } from "@helpers/payment";
import { isCustomSizePro } from "@helpers/product";
import { updateStatusOrder } from "@network/api/order";
import { Skeleton } from "antd";
import {
  Button,
  Icon,
  Notifications,
  ViewLabelStatus,
  TimeUtils,
  RadioGroup,
  Drawer,
} from "d-react-components";
import { Dropdown } from "antd";
import { filter, find, isEmpty, map, now, some } from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Sticky from "react-sticky-el";
import ImageIcon from "@common/Icon";
import ManageDeliveryDrawer from "../modal/ManageDeliveryDrawer";
import MoreActionButton from "../content/MoreActionButton";
import UserAvatarName from "@components/shared/UserAvatarName";
import { PAYMENT_COD, PAYMENT_CONFIRM_STATUS } from "@constants/payment";
// import MoreActionButton from "./MoreActionButton";

const PrintOrderButton = () => {
  const { onClickOrderPrint, pickup = [], products } = useContext(AppContext);
  const [printOrderOptions, setPrintOrderOptions] =
    useState(PRINT_ORDER_OPTION);
  const { t } = useTranslation();

  const hasCustomSize = useMemo(() => {
    return some(
      products,
      (item) => isCustomSizePro(item) || !isEmpty(item.manual_product_id)
    );
  }, [products]);

  useEffect(() => {
    if (pickup?.length > 0) {
      setPrintOrderOptions((prev) => [
        ...prev,
        {
          id: "printOrderPickUp",
          label: "order:printOrderPickup",
        },
      ]);
    }
  }, [pickup]);

  useEffect(() => {
    if (hasCustomSize) {
      setPrintOrderOptions((prev) => [
        ...prev,
        {
          id: "customSizeOrderPrint",
          label: "order:customSizeOrderPrint",
        },
      ]);
    }
  }, [hasCustomSize]);

  const printOptions = useMemo(() => {
    return map(printOrderOptions, (item) => ({
      ...item,
      label: t([item.label]),
      key: item.id,
    }));
  }, [printOrderOptions, t]);

  return (
    <StyledDropdownButton className="">
      <Dropdown
        trigger={["click"]}
        menu={{
          items: printOptions,
          onClick: onClickOrderPrint,
        }}
        className="order-header__print-container"
      >
        <Button
          variant="standard"
          //@ts-ignore
          content={
            <ImageIcon src="/images/icons/printer.svg" className="w-[15px]" />
          }
          color="primary"
          size="small"
          className="ml-2 h-[32px] p-2"
          classNameIcon="text-xs"
          style={{ minWidth: 0, width: "32px" }}
        />
      </Dropdown>
    </StyledDropdownButton>
  );
};

const OrderUrlButton = () => {
  const { order } = useContext(AppContext);
  const { t } = useTranslation();

  const onClickCopy = () => {
    navigator.clipboard.writeText(order.url);
    Notifications.showInfo(t("order:copytoclipboard"), t("order:orderURL"));
  };
  return (
    <Button onClick={() => onClickCopy()} variant="trans" color="gray">
      <Icon name="link" className="text-black mr-2" size="x-large" />
      <span className="font-normal text-black">{t("order:orderURL")}</span>
    </Button>
  );
};

const OrderStatusButton = () => {
  const { order, onReloadOrderData } = useContext(AppContext);
  const [orderStatus, setOrderStatus] = useState(order.status);
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();

  const onClickSaveHandle = async () => {
    const { id } = order ?? {};
    return updateStatusOrder(id, orderStatus).then((respone) => {
      setOpenModal(false);
      onReloadOrderData();
      Notifications.showSuccess(t("notification:updateOrderStatusSucess"));
    });
  };
  const renderUpdateOrderStatusModal = () => {
    return (
      <RadioGroup
        value={orderStatus}
        onChange={(value) => setOrderStatus(value)}
        dataSource={ORDER_STATUS_MENU}
        getLabel={(item) => t(item.content)}
        getValue={(item) => item.key}
        numberOfColumns="1"
      />
    );
  };
  return (
    <div>
      <Button onClick={() => setOpenModal(true)} variant="trans" color="gray">
        <Icon name="label" className="text-black mr-2" size="x-large" />
        <span className="font-normal text-black">
          {t("order:updateOrderStatus")}
        </span>
      </Button>
      <Drawer
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        title={t("order:orderStatus")}
      >
        <div className="pb-10">
          {renderUpdateOrderStatusModal()}
          <div className="position-absolute bottom-0 w-100 end-0 start-0 flex-center-y justify-content-between py-3 px-3 bg-white">
            <Button
              onClick={() => {
                setOpenModal(false);
              }}
              variant="outline"
            >
              {t("cancel")}
            </Button>
            <Button
              onClick={() => {
                onClickSaveHandle();
              }}
            >
              {t("save")}
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

const CreateDeliveryButton = ({
  onClickCreateDelivery,
}: {
  onClickCreateDelivery?: any;
}) => {
  const { t } = useTranslation();
  return (
    <div className="createDeliveryButtonHeader">
      <Button
        size="small"
        onClick={() => onClickCreateDelivery && onClickCreateDelivery()}
      >
        <ImageIcon className="w-[15px]" src="/images/icons/package-white.svg" />
        <span className="font-normal text-white ml-2">
          {t("order:createDelivery")}
        </span>
      </Button>
    </div>
  );
};

const SECOND_ROW_ORDER_HEADER = [
  { key: "orderURL", button: <OrderUrlButton /> },
  { key: "orderStatus", button: <OrderStatusButton /> },
  { key: "printOder", button: <PrintOrderButton /> },
  { key: "createDelivery", button: <CreateDeliveryButton /> },
  // { key: 'printDelivery', button: <PrintDeliveryButton /> },
  { key: "moreaction", button: <MoreActionButton /> },
];

// eslint-disable-next-line consistent-return
const getStatusOrder = (status: any) => {
  const orderStatus = find(ORDER_STATUS_ARRAY, (item) => item.value === status);
  return orderStatus;
};

const OrderHeader = () => {
  const {
    order,
    channel,
    by,
    shipping,
    oldProducts,
    manualProductsNotMap = [],
    delivery = [],
    hasCustomSizeProduct,
    editable,
    isPublic,
    orderHasCod,
    splitPayment = [],
  } = useContext(AppContext);

  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  const statusPickUp = order?.status_pickup ?? "N/A";
  const status = FILTER_ORDER_PICKUP.find((item) => item.id === statusPickUp);
  const statusLabel = status?.label ?? "";
  const statusColor = status?.color ?? "black";

  //*************************************************************CONTROL UI ******************************************** */

  const onClickCreateDelivery = () => {
    if (
      orderHasCod &&
      order.status !== ORDER_STATUS.cancelled.value &&
      order.status !== ORDER_STATUS.completed.value
    ) {
      console.log("splitPayment", splitPayment);
      const nonCODPayment = splitPayment?.filter(
        (payment: any) => payment.method !== PAYMENT_COD
      );
      const isAllPaymentConfirmed = nonCODPayment?.every(
        (payment: any) => payment.status === PAYMENT_CONFIRM_STATUS.APPROVE
      );
      if (isAllPaymentConfirmed) {
        setOpenModal(true);
        return;
      }
    }
    const deliveryList =
      filter(delivery, (item) => item?.status !== "cancel") ?? [];
    if (hasCustomSizeProduct) {
      Notifications.showError(t("order:notCreateDeliveryOrderHasCustomSize"));
      return;
    }

    if (!shipping || shipping.length === 0) {
      Notifications.showError(t("order:pleaseAddShippingFirst"));
      return;
    }

    if (
      shipping &&
      shipping?.length &&
      (isEmpty(shipping?.[0]?.city) ||
        isEmpty(shipping?.[0]?.province) ||
        isEmpty(shipping?.[0]?.subdistrict) ||
        isEmpty(shipping?.[0]?.postcode) ||
        isEmpty(shipping?.[0]?.phone) ||
        isEmpty(shipping?.[0]?.address))
    ) {
      Notifications.showError(t("order:shippingIncomplete"));
      return;
    }

    if (!order?.has_allowed_delivery) {
      Notifications.showError(t("order:notAllowAddDelivery"));
      return;
    }

    if (!order?.has_bulk_delivery && deliveryList?.length > 0) {
      Notifications.showError(t("order:notAllowAddMulDelivery"));
      return;
    }

    if (
      order.status !== ORDER_STATUS.processing.value &&
      order.status !== ORDER_STATUS.deliveryProcessing.value
    ) {
      Notifications.showError(t("order:canNotCreateDelivery"));
      return;
    }

    if (manualProductsNotMap.length > 0) {
      Notifications.showError(t("order:notCreateDeliveryManualProHasNotMap"));
      return;
    }
    setOpenModal(true);
  };
  const onClickCopy = () => {
    navigator.clipboard.writeText(order.url);
    Notifications.showInfo(t("order:copytoclipboard"), t("order:orderURL"));
  };

  //*************************************************************RENDER UI ******************************************** */
  const renderFirstRow = () => {
    const {
      orderid,
      status,
      payment_status,
      is_suspicious,
      suspicious_status,
    } = order ?? {};
    const { name } = channel ?? {};
    const orderStatus = getStatusOrder(status);
    const isClone = !isEmpty(oldProducts);
    const paymentStatus = getPaymentStatus(payment_status);
    return (
      <Sticky
        boundaryElement=".main-content"
        scrollElement=".main-content"
        stickyClassName="header-sticky"
        wrapperClassName="group w-full"
      >
        <div className="flex flex-col lg:flex-row border-b p-3 bg-white">
          <div className="flex-1 flex flex-col md:flex-row md:items-center mb-[0.5rem] lg:mb-0  group-[.header-sticky]:mb-0">
            <div className="flex items-center mb-[0.5rem] md:mb-0  group-[.header-sticky]:mb-0">
              {/* <div
              className="px-2 py-1 mr-3"
              style={{ border: "1px solid black" }}
            >
              {name && name.toUpperCase()}
            </div> */}
              <div id="orderIdContainer" className="mr-3">
                <span className="font-semibold text-primary">{`Order # ${orderid}`}</span>
              </div>
              <Button
                onClick={() => onClickCopy()}
                size="small"
                className="mr-2 px-2"
                style={{ minWidth: 0, height: "30px" }}
              >
                <Icon name="link" className="text-white" size="medium" />
              </Button>
            </div>
            <div className="flex items-center group-[.header-sticky]:hidden md:group-[.header-sticky]:flex flex-wrap">
              {isClone && (
                <ViewLabelStatus
                  content={t("order:loaded")}
                  color="#333333"
                  className="mr-2 mb-1 whitespace-nowrap"
                />
              )}
              <ViewLabelStatus
                content={t(orderStatus?.label as string)}
                color={orderStatus?.color}
                className="mr-2 mb-1 whitespace-nowrap"
              />
              {payment_status && (
                <ViewLabelStatus
                  content={t(paymentStatus?.label as string)}
                  color={paymentStatus?.color}
                  className="mr-2 mb-1 whitespace-nowrap"
                />
              )}
              {statusLabel && (
                <ViewLabelStatus
                  content={t(statusLabel)}
                  color={statusColor}
                  className="mr-2 mb-1 whitespace-nowrap"
                />
              )}
              {is_suspicious && (
                <ViewLabelStatus
                  content={t("order:suspicious")}
                  color="#EB5757"
                  className="mr-2 mb-1 whitespace-nowrap"
                />
              )}
            </div>
          </div>
          {!isPublic && (
            <div className="flex items-center group-[.header-sticky]:hidden md:group-[.header-sticky]:flex">
              <CreateDeliveryButton
                onClickCreateDelivery={() => onClickCreateDelivery()}
              />
              <PrintOrderButton />
              <MoreActionButton />
            </div>
          )}
        </div>
      </Sticky>
    );
  };

  const renderCreateAndExpiringTime = () => {
    const { expire, created } = order ?? {};
    const expired = now() - expire >= 0;
    return (
      <div className="flex p-3 flex-col md:flex-row items-start md:items-center">
        <div className="d-flex mr-3 mb-[0.5rem] md:mb-0">
          <Icon name="date_range" />
          <div className="text-xs ml-1 text-gray-500">{`${t(
            "order:createdOn"
          )} ${TimeUtils.convertMiliToDateTime(created)}`}</div>
        </div>
        {expired ? (
          <div className="d-flex mr-3 mb-[0.5rem] md:mb-0">
            <Icon name="access_alarm" />
            <div className="text-xs text-error ml-1">
              {t("order:expiredAt")} {TimeUtils.convertMiliToDate(expire)}{" "}
              {t("order:at")} {moment(expire).format("HH:MM")}
            </div>
          </div>
        ) : (
          <div className="d-flex mr-3 mb-[0.5rem] md:mb-0">
            <Icon name="access_timer" />
            <div className="text-xs -ml-3 text-gray-500">
              {t("order:expireAt")} {TimeUtils.convertMiliToDate(expire)}{" "}
              {t("order:at")} {moment(expire).format("HH:MM")}
            </div>
          </div>
        )}
        <div className="d-flex items-center mr-3 mb-[0.5rem] md:mb-0">
          <Icon name="person" />
          <div className="text-xs ml-1 text-gray-500">
            {`${t("order:soldBy")}`}{" "}
          </div>
          <UserAvatarName
            className="text-xs ml-2 text-gray-500"
            user={by}
            size="xx-small"
          />
        </div>
      </div>
    );
  };
  const renderSecondRow = () => {
    if (!editable) {
      return <></>;
    }
    return (
      <div className="flex flex-col md:flex-row">
        {map(SECOND_ROW_ORDER_HEADER, (item, index) => {
          return (
            <div key={index}>
              {React.cloneElement(item.button, {
                onClickCreateDelivery: () => onClickCreateDelivery(),
              })}
            </div>
          );
        })}
      </div>
    );
  };
  if (!order) {
    return (
      <div className="cardContainer orderContainer">
        <Skeleton active />
      </div>
    );
  }

  return (
    <div className="bg-white relative z-[60] shadow-sm">
      {renderFirstRow()}
      {renderCreateAndExpiringTime()}
      {/* {renderSecondRow()} */}
      {openModal && (
        <ManageDeliveryDrawer
          openModal={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
        />
      )}
    </div>
  );
};

const StyledDropdownButton = styled.div`
  button {
    font-weight: normal !important;
    color: white;
  }
  i {
    color: white;
    /* font-size: 1.625rem; */
  }
`;

export default OrderHeader;
