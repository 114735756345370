/* eslint-disable react/jsx-no-duplicate-props */
import Drawer from "@components/shared/Drawer";
import {
  NUMBER_INSTALMENT,
  OFFLINE_PAYMENT_METHOD,
  PAYMENTS_METHODS,
  PAYMENT_2C2P,
  PAYMENT_COD,
  PAYMENT_CONFIRM_STATUS,
  PAYMENT_DEFAULT,
  PAYMENT_MODERN_TRADE,
} from "@constants/payment";
import AppContext from "@helpers/context";
import { getPaidFromPayments } from "@helpers/payment";
import { orderCreateSplitPayment } from "@network/api/order";
import { modernTradeBankList } from "@network/api/store";
import {
  Button,
  InputText,
  Notifications,
  Select,
  StringUtils,
} from "d-react-components";
import { every, filter, find, isEmpty, map } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import PaymentCustomerUpdated from "./PaymentCustomerUpdated";
import { OrderType } from "@constants/common";

const defaultMethod = PAYMENTS_METHODS[0];
const defaultPayment = { total: "0", method: defaultMethod };
export const defaultPaymentList: any = [
  {
    ...defaultPayment,
    id: Math.random(),
    status: PAYMENT_CONFIRM_STATUS.PENDING,
  },
];

const SplitPaymentInputItem = ({
  index,
  onClickDelete,
  payment,
  onChangeValue,
  paymentMethods = [],
  mtBankList = [],
}: any) => {
  const {
    paymentOptions = [],
    loadPaymentOptions,
    orderHasCod,
  } = useContext(AppContext);
  const { status, total, method, numberInstalment, bankName, paymentOption } =
    payment;
  const disabled = status === PAYMENT_CONFIRM_STATUS.APPROVE;
  const { t } = useTranslation();
  useEffect(() => {
    if (!paymentOptions.length) {
      loadPaymentOptions && loadPaymentOptions();
    }
  }, []);

  const renderAdditionalModernTrade = () => {
    return (
      <>
        <Select
          disabled={disabled}
          className="mt-3"
          label={t("updatePaymentMethodSuccessfully")}
          dataSource={NUMBER_INSTALMENT}
          value={numberInstalment}
          onChange={(value) => {
            onChangeValue("numberInstalment", value);
          }}
          placeholder={t("pleaseSelect")}
        />
        <Select
          className="mt-3"
          dataSource={mtBankList}
          getLabel={(item) => (
            <div className="flex items-center">
              {item.logo && (
                <img src={item.logo} className="w-4 h-4 mr-2" alt="" />
              )}
              {item.code}
            </div>
          )}
          label={t("bankName")}
          value={bankName}
          onChange={(value) => onChangeValue("bankName", value)}
          placeholder={t("pleaseSelect")}
        />
      </>
    );
  };

  const renderAdditional2c2p = () => (
    <Select
      className="mt-3"
      label={t("paymentOptions")}
      dataSource={paymentOptions}
      value={paymentOption}
      onChange={(value) => {
        onChangeValue("paymentOption", value);
      }}
      placeholder={t("pleaseSelect")}
    />
  );

  const renderAdditionalInput = () => {
    switch (method.id) {
      case PAYMENT_MODERN_TRADE:
        return renderAdditionalModernTrade();
      case PAYMENT_2C2P:
        return renderAdditional2c2p();
      default:
        return <div />;
    }
  };

  return (
    <div className="flex-column mt-3">
      <div className="d-flex flex-center-y border-bottom border-primary">
        <label className="text-primary text-uppercase w-100">{`${t(
          "payment"
        )} ${index + 1}`}</label>
        <Button
          iconName="delete"
          variant="trans"
          onClick={onClickDelete}
          disabled={disabled}
        />
      </div>
      <Select
        className="mt-3"
        label={t("method")}
        dataSource={paymentMethods}
        value={method.id}
        onChange={(id) => {
          const selected = find(paymentMethods, { id });
          onChangeValue("method", selected);
        }}
        disabled={disabled}
        allowClear={false}
        placeholder={t("pleaseSelect")}
        getDisableOption={(method) => {
          return orderHasCod && method.id === PAYMENT_COD;
        }}
      />

      <InputText
        className="mt-3"
        label={t("amount")}
        value={total.toLocaleString()}
        onChange={(event) => {
          const result = event.target.value.replace(/\D/g, "");
          let value = parseInt(result);
          if (!value) {
            value = 0;
          }
          onChangeValue("total", value);
        }}
        suffix="฿"
        disabled={status === PAYMENT_CONFIRM_STATUS.APPROVE}
      />
      {renderAdditionalInput()}
    </div>
  );
};

export const SplitPaymentInput = ({
  total,
  setRemainAmount,
  listPayment,
  setListPayment,
}: any) => {
  const [paidTotal, setPaidTotal] = useState<any>();
  const [mtBankList, setMTBankList] = useState([]);
  const { paymentMethodsList, order, type } = useContext(AppContext);
  const { t } = useTranslation();

  useEffect(() => {
    let result = 0;
    const clone = [...listPayment];
    clone.forEach((item) => (result += parseInt(item.total)));
    setPaidTotal(result);
  }, [listPayment]);

  useEffect(() => {
    modernTradeBankList().then((res) => {
      const bankList = res?.data?.data?.brand_name ?? [];
      setMTBankList(bankList);
    });
  }, []);

  useEffect(() => {
    setRemainAmount(total - paidTotal);
  }, [paidTotal]);

  const handleOnClickSplitMore = () => {
    if (listPayment.length === 10) {
      return;
    }
    const clone = [...listPayment];
    clone.push({ ...defaultPayment, id: Math.random() });
    setListPayment(clone);
  };

  const setChangeValuePaymentItem = (key: string, value: any, id: string) => {
    const cloneList = [...listPayment];
    const clonePaidTotal = paidTotal;
    const oldValue = cloneList.find((item) => item.id === id).total;
    if (key === "total" && clonePaidTotal - oldValue + value > total) {
      Notifications.showError(
        t("notification:totalPaidAmountCannotGreaterThanTotalCart")
      );
      return;
    }
    cloneList?.map((item) => {
      if (item.id === id) {
        item[key] = value;
        return item;
      }
      return item;
    });
    setListPayment(cloneList);
  };

  const onClickDeletePaymentHandle = (id: string) => {
    if (listPayment.length === 1) {
      return;
    }
    const clone = listPayment.filter((item: any) => item.id !== id);
    setListPayment(clone);
  };

  const availablePaymentMethod = useMemo(() => {
    if (type === OrderType.RENTAL) {
      return paymentMethodsList;
    }
    if (order?.store?.is_store && order?.store?.store_type === "offline") {
      return paymentMethodsList;
    }
    return paymentMethodsList?.filter(
      (m: any) => !OFFLINE_PAYMENT_METHOD.includes(m.id)
    );
  }, [paymentMethodsList, order, listPayment]);

  return (
    <div className="mt-3">
      <div className="flex-column border-bottom pb-3">
        {map(listPayment, (payment, index) => (
          <SplitPaymentInputItem
            index={index}
            onClickDelete={() => onClickDeletePaymentHandle(payment.id)}
            payment={payment}
            paymentMethods={
              listPayment.length > 1
                ? filter(
                    availablePaymentMethod,
                    (item) => item.id !== "default"
                  )
                : availablePaymentMethod
            }
            onChangeValue={(key: string, value: any) =>
              setChangeValuePaymentItem(key, value, payment.id)
            }
            mtBankList={mtBankList}
          />
        ))}
      </div>
      <Button
        onClick={handleOnClickSplitMore}
        variant="outline"
        className="cursor-pointer text-primary mt-3 w-100"
      >
        {t("splitMore")}
      </Button>
    </div>
  );
};

const PaymentConfirmation = ({ className }: { className: string }) => {
  const {
    order,
    onReloadOrderData,
    splitPayment = [],
    oldPayment = [],
    isPublic,
  } = useContext(AppContext);
  const [openSplitPaymentModal, setOpenSplitPaymentModal] = useState(false);
  const [remainAmount, setRemainAmount] = useState(-200);
  const [listPayment, setListPayment] = useState(defaultPaymentList);
  const { paymentMethodsList, editable } = useContext(AppContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (isEmpty(splitPayment)) {
      setListPayment(defaultPaymentList);
    } else {
      const list = splitPayment?.map((item: any) => {
        const {
          total,
          method,
          id,
          status,
          installment_loan_tenure,
          payment_option,
        } = item;
        const setMethod =
          find(paymentMethodsList, { id: method }) ?? PAYMENT_DEFAULT;
        return {
          total,
          method: setMethod,
          id,
          status,
          numberInstalment: installment_loan_tenure,
          paymentOption: payment_option?.id,
        };
      });
      setListPayment(list);
    }
  }, [splitPayment, openSplitPaymentModal]);

  const isShowSplitPayment = () => {
    return (
      order?.status === "pending" ||
      order?.status === "pending-payment-confirmation" ||
      order?.status === "partially-paid"
    );
  };

  const setListPaymentHandle = (list: any) => {
    setListPayment(list);
  };

  const onClickSaveHandle = async () => {
    const arrayTotal = listPayment?.map((item: any) => item?.total);
    if (arrayTotal.includes(0) || arrayTotal.includes("0")) {
      Notifications.showError(t("notification:pleaseFillAllThePaymentAmount"));
      return Promise.reject();
    }
    if (remainAmount > 0) {
      Notifications.showError(t("notification:paymentIsNotEnough"));
      return Promise.reject();
    }

    if (listPayment[0].method.id === "default") {
      Notifications.showError(
        t("notification:youNeedToChooseASpecificPaymentMethod")
      );
      return Promise.reject();
    }

    const isValidModernTrade = every(
      filter(listPayment, (item) => item.method.id === PAYMENT_MODERN_TRADE),
      (item) => !!item.numberInstalment
    );
    if (!isValidModernTrade) {
      Notifications.showError(
        t("notification:needToFillInstalmentModernTrade")
      );
      return Promise.reject();
    }

    const isValid2c2p = every(
      filter(listPayment, (item) => item.method.id === PAYMENT_2C2P),
      (item) => !!item.paymentOption
    );
    if (!isValid2c2p) {
      Notifications.showError(t("notification:needToFillPaymentOption"));
      return Promise.reject();
    }

    let split: any = [];
    const splitPaymentID =
      splitPayment && splitPayment?.map((item: any) => item.id);
    listPayment.forEach((item: any) => {
      const paymentBody = {
        total: item.total,
        method: item.method.id,
        installment_loan_tenure: item.numberInstalment,
        bank_name_id: item.bankName,
        payment_option: item.paymentOption,
      };
      if (splitPaymentID.includes(item.id) && item.status !== "approved") {
        split.push({ ...paymentBody, id: item.id });
      } else if (item.status !== "approved") {
        split.push(paymentBody);
      }
    });
    split = split.filter((item: any) => item.total !== 0 && item.total !== "0");

    if (!isEmpty(split)) {
      return orderCreateSplitPayment(order.id, split).then(
        () => {
          onReloadOrderData();
          setOpenSplitPaymentModal(false);
        },
        (err) => {
          Notifications.showError(err);
        }
      );
    }
    return Promise.reject();
  };

  const getTotalRemainingOrder = () => {
    const totalAmount = order?.total ?? 0;
    const paid = getPaidFromPayments(oldPayment);
    return totalAmount - paid;
  };

  const onClickSplitPayment = () => {
    if (!order?.has_split_payment) {
      Notifications.showError(t("notification:notAllowToSplitPayment"));
      return;
    }
    setOpenSplitPaymentModal(true);
  };

  const renderSummaryAmount = () => (
    <div className="px-3 py-2 bg-muted w-100">
      <div className="flex flex-col md:flex-row justify-content-between py-2">
        <label className="text-primary">{t("totalCart")}</label>
        <span>{StringUtils.moneyThaiFormat(getTotalRemainingOrder())}</span>
      </div>
      <div className="flex flex-col md:flex-row justify-content-between py-2 border-top-dashed">
        <label className="text-primary">{t("splitAmount")}</label>
        <span>
          {StringUtils.moneyThaiFormat(getTotalRemainingOrder() - remainAmount)}
        </span>
      </div>
      <div className="flex flex-col md:flex-row justify-content-between py-2 border-top-dashed">
        <label className="text-primary">{t("remainingAmount")}</label>
        <span>{StringUtils.moneyThaiFormat(remainAmount)}</span>
      </div>
    </div>
  );

  return (
    <div
      className={`bg-white p-3 pt-4 d-flex shadow-sm flex-column w-100 ${className}`}
    >
      <div className="order-payment__title-container flex items-center">
        <label className="flex-1 text-sm mb-2">{t("customerPayment")}</label>
        <div>
          {isShowSplitPayment() && editable && !isPublic && (
            <Button iconName="call_split" onClick={onClickSplitPayment}>
              {t("splitPayment")}
            </Button>
          )}
        </div>
      </div>

      {map(oldPayment, (payment, index) => (
        <PaymentCustomerUpdated payload={payment} key={index} index={index} />
      ))}

      {getTotalRemainingOrder() >= 0 &&
        map(splitPayment, (payment, index) => (
          <PaymentCustomerUpdated payload={payment} key={index} index={index} />
        ))}

      <Drawer
        title={t("splitPayment")}
        open={openSplitPaymentModal}
        onClose={() => setOpenSplitPaymentModal(false)}
        onSave={onClickSaveHandle}
        size="auto"
        width={600}
      >
        {renderSummaryAmount()}
        <SplitPaymentInput
          total={getTotalRemainingOrder()}
          setRemainAmount={(value: any) => setRemainAmount(value)}
          listPayment={listPayment}
          setListPayment={setListPaymentHandle}
          order={order}
        />
      </Drawer>
    </div>
  );
};

export default PaymentConfirmation;
