import { API } from "@network";
import {
  API_CART_RULE_ACTIVITIES,
  API_CART_RULE_ACTIVITY_CREATE,
  API_CART_RULE_SYNC_LOGS,
  API_CREATE_CART_RULE,
  API_DETAIL_CART_RULE,
  API_EDIT_CART_RULE,
  API_LIST_CART_RULE,
  API_VALIDATE_COUPON,
} from "@network/URL";

const CartRuleAPI = {
  list: (body: any, paging: any) => {
    return API.post(
      `${API_LIST_CART_RULE}?page=${paging.pageIndex}&per_page=${paging.pageSize}`,
      body
    );
  },
  create: (body: any) => {
    return API.post(API_CREATE_CART_RULE, body);
  },

  detail: (body: any) => {
    return API.post(API_DETAIL_CART_RULE, body);
  },

  update: (body: any) => {
    return API.post(API_EDIT_CART_RULE, body);
  },

  validateCoupon: (body: any) => {
    return API.post(API_VALIDATE_COUPON, body);
  },

  activities: (body: any, paging: any) => {
    return API.post(
      `${API_CART_RULE_ACTIVITIES}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
      body
    );
  },

  activityAdd: (body: any) => {
    return API.post(API_CART_RULE_ACTIVITY_CREATE, body);
  },

  getFreeGiftSyncLog: (body: any, paging: any) => {
    return API.post(
      `${API_CART_RULE_SYNC_LOGS}?page=${paging.pageIndex}&per_page=${paging.pageSize}`,
      body
    );
  },
};

export default CartRuleAPI;
