import { MT_ORDER_TYPES } from "@constants/order";
import AppContext from "@helpers/context";
import { find } from "lodash";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

const OrderDetailMTType = () => {
  const { order } = useContext(AppContext);
  const { t } = useTranslation();
  if (!order?.mt_type_discount) return <div />;

  const mtDiscountType = find(
    MT_ORDER_TYPES,
    (item) => item.id === order?.mt_type_discount
  );
  const event = order?.chain_discount_manual_event;
  const discountManual = order?.chain_discount_manual;
  const eventInfo = event ? `${event?.name}(Code: ${event.code})` : "";
  const discountManualInfo = discountManual
    ? `${discountManual?.discount}%`
    : "";

  return (
    <div className="bg-white p-3 mb-3 shadow-sm">
      <label className="text-sm">{t("modernTradeOrder")}</label>
      <div className="d-flex flex-wrap">
        {mtDiscountType
          ? `${t(mtDiscountType?.label)} / ${eventInfo} ${discountManualInfo}`
          : ""}
      </div>
    </div>
  );
};

export default OrderDetailMTType;
