export const REFUND_STATUS_LIST = [
  {
    color: "#FFBD59",
    label: "pending",
    id: "pending",
  },
  {
    color: "#FF203B",
    label: "rejected",
    id: "rejected",
  },
  {
    color: "#27AE60",
    label: "approved",
    id: "approved",
  },
  {
    color: "#2C98F0",
    label: "refunded",
    id: "refunded",
  },
  {
    color: "#FF203B",
    label: "cancelled",
    id: "cancelled",
  },
];
