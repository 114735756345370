import Drawer from "@components/shared/Drawer";
import { ORDER_STATUS_ARRAY } from "@constants/order";
import { getFullName } from "@helpers/string";
import { searchShippingCustomer } from "@network/api/address";
import { detailCustomer } from "@network/api/customer";
import { Spin } from "antd";
import {
  AwesomeTableComponent,
  IRowsKey,
  StringUtils,
  TimeUtils,
  ViewLabelStatus,
  ViewRowInterchange,
} from "d-react-components";
import { find, isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CustomerInfoWidget } from "./CustomerInfoDrawer";
import { ShippingViewList } from "./ShippingAddressDrawer";

const CustomerDetailDrawer = ({
  customerId,
  open,
  onClose,
}: {
  customerId: string;
  open: boolean;
  onClose: any;
}) => {
  const [customer, setCustomer] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [shippingList, setShippingList] = useState<any[]>();
  const { t } = useTranslation();

  const loadShippingProfile = (customerId: any) => {
    if (!customerId) {
      return;
    }
    searchShippingCustomer(customerId).then((res) => {
      const shippings = res?.data?.data?.shipping;
      setShippingList(shippings);
    });
  };

  useEffect(() => {
    setLoading(true);
    detailCustomer(customerId).then((resp) => {
      setCustomer(resp?.data?.data?.customers);
      setLoading(false);
    });
    loadShippingProfile(customerId);
  }, []);

  const GENERAL_KEYS: IRowsKey<any>[] = [
    {
      id: "firstname",
      label: t("firstname"),
    },
    {
      id: "lastname",
      label: t("lastname"),
    },
    {
      id: "nickname",
      label: t("nickname"),
    },
    {
      id: "company",
      label: t("company"),
    },
    {
      id: "gender",
      label: t("gender"),
      renderContent: ({ data, item }) => (data ? t(data) : "N/A"),
    },

    {
      id: "birthday",
      label: t("birthday"),
      renderContent: ({ data, item }) => TimeUtils.convertMiliToDate(data),
    },
    {
      id: "phone",
      label: t("phone"),
    },
  ];

  const columns = useMemo(
    () => [
      {
        title: t("createdAt"),
        dataIndex: "created_at",
        width: "150px",
      },
      {
        title: t("orderId"),
        dataIndex: "orderid",
        width: "120px",
        render: (orderid: string, item: any) => (
          <Link to={`/order/${item.id}`} target="_blank">
            {orderid}
          </Link>
        ),
      },
      {
        title: t("orderStatus"),
        dataIndex: "status",
        ellipsis: true,
        width: 150,
        render: (status: string) => {
          const orderStatus = find(
            ORDER_STATUS_ARRAY,
            (item) => item.value === status
          );
          return (
            <ViewLabelStatus
              content={t(`order:${orderStatus?.content}`)}
              color={orderStatus?.color}
            />
          );
        },
      },
      {
        title: t("total"),
        dataIndex: "total",
        width: 75,
        render: (total: number) => StringUtils.moneyThaiFormat(total),
      },
    ],
    [t]
  );

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={customer ? getFullName(customer) : ""}
      size="auto"
      width={"700px"}
    >
      {loading && (
        <div className="flex justify-content-center">
          <Spin />
        </div>
      )}
      {!isEmpty(customer) && (
        <>
          <CustomerInfoWidget customer={customer} showAction={false} />
          <div className="my-3">
            <h5>{t("personalInformation")}</h5>
            <ViewRowInterchange
              keyList={GENERAL_KEYS}
              dataSource={customer}
              variant="background"
            />
          </div>
          <div className="my-3 pb-3">
            <h5>{t("shippingAddress")}</h5>
            {shippingList?.map(
              (item: any, index: number) =>
                item && (
                  <ShippingViewList
                    key={index}
                    {...{
                      shippingItem: item,
                      isShowEditRemove: () => false,
                      isCreateOrder: false,
                    }}
                  />
                )
            )}
          </div>
          <div className="my-3">
            <h5>{t("recentPurchasingHistory")}</h5>
            <AwesomeTableComponent
              classNameTable="order-table"
              dataSource={customer?.orderSpending}
              columns={columns}
              tableLayout="fixed"
              className="mt-3"
            />
          </div>
        </>
      )}
    </Drawer>
  );
};

export default CustomerDetailDrawer;
