import Drawer from "@components/shared/Drawer";
import UploadFile from "@components/shared/UploadFile";
import { ORDER_STATUS } from "@constants/order";
import { PAYMENTS_METHOD, PAYMENT_CONFIRM_STATUS } from "@constants/payment";
import AppContext from "@helpers/context";
import { isGrantURLPermission } from "@helpers/permissions";
import {
  orderConfirmSplitPayment,
  uploadImageOrderConfirm,
} from "@network/api/order";
import { API_ORDER_CONFIRM_SPLIT_PAYMENT } from "@network/URL";
import {
  Button,
  DateInput,
  InputText,
  Modal,
  Notifications,
  Progress,
  Select,
  TimeUtils,
} from "d-react-components";
import { useFormik } from "formik";
import moment from "moment";
import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

const ModalConfirm2C2PPayment = ({ open, onClose, payment }: any) => {
  const { banks2C2P = [], onReloadOrderData } = useContext(AppContext);
  const { t } = useTranslation();
  const [attachments, setAttachments] = useState<any[]>([]);

  const Error2C2PPaymentSchema = useMemo(
    () =>
      Yup.object().shape({
        actualBank: Yup.string().required(t("fieldRequired")),
        actualDate: Yup.number().required(t("fieldRequired")),
        transactionId: Yup.string().required(t("fieldRequired")),
        remark: Yup.string().required(t("fieldRequired")),
      }),
    [t]
  );

  const formik = useFormik({
    initialValues: {
      actualBank: undefined,
      actualDate: null,
      transactionId: "",
      remark: "",
    },
    validationSchema: Error2C2PPaymentSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      if (attachments.length === 0) {
        Notifications.showError(t("notification:attachmentIsRequire"));
        return Promise.reject();
      }
      return onConfirmRejectPayment();
    },
  });

  const renderUploadFile = () => {
    return (
      <div>
        <UploadFile
          uploadedFiles={attachments}
          getFile={(files: any[]) => setAttachments(files)}
          uploadImagesOnly
        />
      </div>
    );
  };

  const onConfirmRejectPayment = async () => {
    const APIList = attachments?.map((attach) => {
      const data = new FormData();
      data.append("file", attach.fileData);
      data.append("splitid", payment.id);

      return { method: uploadImageOrderConfirm, params: data };
    });
    const { remark, actualBank, transactionId, actualDate } = formik.values;
    Progress.show(APIList, () => {
      const body = {
        splitid: payment.id,
        note: remark,
        status: PAYMENT_CONFIRM_STATUS.APPROVE,
        actualDatePayment: actualDate,
        payment_method: PAYMENTS_METHOD.C2P,
        bank_2c2p_id: actualBank,
        payment_transaction_id: transactionId,
      };

      orderConfirmSplitPayment(body).then(() => {
        Notifications.showSuccess(t("notification:paymentConfirmed"));
        onClose();
        onReloadOrderData && onReloadOrderData();
      });
    });
  };
  return (
    <Drawer
      open={open}
      onClose={onClose}
      onSave={() => formik.handleSubmit()}
      title={t("confirmFullPay")}
    >
      <div className="flex-column">
        <span className="text-x-small ">
          {t("notification:areYouSureConfirmPayment")}
        </span>
        {renderUploadFile()}

        <Select
          className="mt-3"
          label={t("actual2C2PMerchantOfReceivingPayment")}
          dataSource={banks2C2P}
          getLabel={(item) => item.name}
          placeholder={t("selectBank")}
          name="actualBank"
          onChange={(value) => formik.setFieldValue("actualBank", value)}
          value={formik.values.actualBank}
          error={formik.errors.actualBank}
        />

        <DateInput
          className="mt-3"
          showTime
          label={t("actualDateOfReceiving2C2PPayment")}
          onChange={(date) => {
            formik.setFieldValue("actualDate", moment(date).valueOf());
          }}
          value={
            formik.values.actualDate ? moment(formik.values.actualDate) : null
          }
          name="actualDate"
          error={formik.errors.actualDate}
        />

        <InputText
          className="mt-3"
          label={t("C2PTransactionId")}
          placeholder={t("enterTransactionId")}
          name="transactionId"
          value={formik.values.transactionId}
          onChange={formik.handleChange}
          error={formik.errors.transactionId}
        />
        <InputText
          className="mt-3"
          label={t("remark")}
          multiple
          placeholder={t("pleaseInputTheNote")}
          name="remark"
          value={formik.values.remark}
          onChange={formik.handleChange}
          error={formik.errors.remark}
        />
      </div>
    </Drawer>
  );
};

const ModalReject2C2PPayment = ({ open, onClose, payment }: any) => {
  const { onReloadOrderData } = useContext(AppContext);
  const { t } = useTranslation();
  const [attachments, setAttachments] = useState<any[]>([]);

  const Error2C2PRejectSchema = Yup.object().shape({
    remark: Yup.string().required(t("fieldRequired")),
  });

  const formik = useFormik({
    initialValues: {
      remark: "",
    },
    validationSchema: Error2C2PRejectSchema,

    onSubmit: (values) => {
      if (attachments.length === 0) {
        Notifications.showError(t("notification:attachmentIsRequire"));
        return Promise.reject();
      }
      return onConfirmRejectPayment();
    },
  });

  const onConfirmRejectPayment = async () => {
    const APIList = attachments?.map((attach) => {
      const data = new FormData();
      data.append("file", attach.fileData);
      data.append("splitid", payment.id);

      return { method: uploadImageOrderConfirm, params: data };
    });

    const { remark } = formik.values;
    Progress.show(APIList, (res) => {
      const body = {
        splitid: payment.id,
        note: remark,
        status: PAYMENT_CONFIRM_STATUS.REJECTED,
        payment_method: PAYMENTS_METHOD.C2P,
      };

      return orderConfirmSplitPayment(body).then((res) => {
        Notifications.showSuccess(t("notification:paymentRejected"));
        onClose();
        onReloadOrderData && onReloadOrderData();
      });
    });
  };
  return (
    <Drawer
      open={open}
      onClose={onClose}
      onSave={() => formik.handleSubmit()}
      title={t("confirmRejectPayment")}
      saveText={t("reject")}
    >
      <div className="flex-column">
        <span className="text-x-small">
          {t("notification:areCertainPaymentReject")}
        </span>
        <UploadFile
          uploadedFiles={attachments}
          getFile={(files: any) => setAttachments(files)}
          uploadImagesOnly
        />

        <InputText
          className="mt-3"
          label={t("remark")}
          placeholder={t("pleaseInputTheNote")}
          name="remark"
          value={formik.values.remark}
          onChange={formik.handleChange}
          error={formik.errors.remark}
          multiple
        />
      </div>
    </Drawer>
  );
};

export const C2PConfirmText = ({ payment }: any) => {
  const { t } = useTranslation();
  const {
    status,
    confirm_payment_at,
    confirm_payment_by,
    confirm_payment_note,
  } = payment;

  const isReject = status === PAYMENT_CONFIRM_STATUS.REJECTED;
  const isApprove = status === PAYMENT_CONFIRM_STATUS.APPROVE;

  if (isApprove) {
    return (
      <div className="text-success text-max-rows-3">
        {`${t("confirmBy")} ${confirm_payment_by?.name} ${t(
          "at"
        )} ${TimeUtils.convertMiliToDateTime(
          confirm_payment_at
        )} "${confirm_payment_note}"`}
      </div>
    );
  }

  if (isReject) {
    return (
      <div className="text-error">
        {`${t("rejectBy")} ${confirm_payment_by?.name} ${t(
          "at"
        )} ${TimeUtils.convertMiliToDateTime(
          confirm_payment_at
        )} "${confirm_payment_note}"`}
      </div>
    );
  }

  return null;
};

const C2PConfirmButtons = ({ payment }: any) => {
  const { order } = useContext(AppContext);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const { t } = useTranslation();

  const { status } = payment;

  const isReject = status === PAYMENT_CONFIRM_STATUS.REJECTED;
  const isApprove = status === PAYMENT_CONFIRM_STATUS.APPROVE;

  if (
    order.status &&
    order.status !== ORDER_STATUS.pending.value &&
    order.status !== ORDER_STATUS.pendingPaymentConfirmation.value &&
    order.status !== ORDER_STATUS.partiallyPaid.value
  ) {
    return <div />;
  }
  if (!isGrantURLPermission(API_ORDER_CONFIRM_SPLIT_PAYMENT)) {
    return <div />;
  }

  if (
    !payment.payment_slip ||
    payment.payment_slip.length === 0 ||
    isApprove ||
    isReject
  ) {
    return <div />;
  }
  return (
    <div className="d-flex">
      <Button className="mr-3" onClick={() => setOpenConfirmModal(true)}>
        {t("confirm")}
      </Button>
      <Button color="error" onClick={() => setOpenRejectModal(true)}>
        {t("reject")}
      </Button>

      <ModalConfirm2C2PPayment
        open={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
        payment={payment}
      />

      <ModalReject2C2PPayment
        open={openRejectModal}
        onClose={() => setOpenRejectModal(false)}
        payment={payment}
      />
    </div>
  );
};

export default C2PConfirmButtons;
