import FilterView from "@components/shared/FilterView";
import SelectFilter from "@components/shared/SelectFilter";
import AppContext from "@helpers/context";
import { getBrandList, getCategoryList } from "@network/api/product";
import { Button, Drawer, Select } from "d-react-components";
import { filter, find, map } from "lodash";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { INIT_FILTER } from "./OrderDetail";

export const OrderDetailProductFilter = ({
  onSaveFilter,
  filterBody,
  setFilterBody,
  filterListData,
}: any) => {
  const [listBrand, setListBrand] = useState([]);
  const [showFilterDrawer, setShowFilterDrawer] = useState(false);
  const [listCategory, setListCategory] = useState([]);
  const { t } = useTranslation();
  const { values } = useContext(AppContext);
  const { warehouse } = values;

  useEffect(() => {
    getCategories();
    getBrands();
  }, []);

  const getCategories = () => {
    getCategoryList().then((response) => {
      const listCategory = response.data?.data?.category ?? [];
      setListCategory(listCategory);
    });
  };

  const getBrands = () => {
    getBrandList().then((response) => {
      const listBrand = response.data?.data?.brand ?? [];
      setListBrand(listBrand);
    });
  };

  return (
    <>
      <div className="flex align-items-end">
        <SelectFilter
          dataSource={listBrand}
          multiple
          getLabel={(item) => item.name}
          label={t("brand")}
          value={filterBody?.brand?.map((item: any) => item.id)}
          onChange={(values) => {
            setFilterBody({
              ...filterBody,
              brand: filter(listBrand, (item: any) => values.includes(item.id)),
            });
          }}
          className="w-40 mr-2"
          selectAll
          disabled={!warehouse}
          tagTitle={t("brands")}
          placeholder={t("pleaseSelect")}
          selectAllLabel={t("selectAll")}
          deselectAllLabel={t("deselectAll")}
        />
        <SelectFilter
          dataSource={listCategory}
          multiple
          label={t("category")}
          className="w-40 mr-3"
          value={filterBody?.category?.map((item: any) => item.id)}
          tagRender={() => <></>}
          onChange={(values) => {
            setFilterBody({
              ...filterBody,
              category: filter(listCategory, (item: any) =>
                values.includes(item.id)
              ),
            });
          }}
          getLabel={(item) => item.name}
          selectAll
          disabled={!warehouse}
          tagTitle={t("categories")}
          placeholder={t("pleaseSelect")}
          selectAllLabel={t("selectAll")}
          deselectAllLabel={t("deselectAll")}
        />
        <Button
          iconName="filter_list"
          onClick={() => setShowFilterDrawer(true)}
        ></Button>
      </div>
      {showFilterDrawer && (
        <FilterDrawer
          open={showFilterDrawer}
          filterBody={filterBody}
          setFilterBody={setFilterBody}
          filterListData={filterListData}
          onClose={() => setShowFilterDrawer(false)}
          listCategory={listCategory}
          listBrand={listBrand}
        />
      )}
    </>
  );
};

const FilterDrawer = ({
  open,
  onClose,
  filterBody,
  setFilterBody,
  filterListData,
  listBrand,
  listCategory,
}: any) => {
  const { t } = useTranslation();
  const { values } = useContext(AppContext);
  const { warehouse } = values;

  const onRemoveFilter = (filterItem: any) => {
    const result = { ...filterBody };
    result[filterItem.id] = INIT_FILTER[filterItem.id];
    setFilterBody(result);
  };

  return (
    <Drawer open={open} onClose={onClose}>
      <Select
        dataSource={listBrand}
        multiple
        getLabel={(item) => item.name}
        label={t("brand")}
        value={filterBody?.brand?.map((item: any) => item.id)}
        tagRender={() => <></>}
        onChange={(values) => {
          setFilterBody({
            ...filterBody,
            brand: filter(listBrand, (item: any) => values.includes(item.id)),
          });
        }}
        className="w-full mr-3"
        selectAll
        disabled={!warehouse}
        placeholder={t("pleaseSelect")}
        selectAllLabel={t("selectAll")}
        deselectAllLabel={t("deselectAll")}
      />
      <Select
        dataSource={listCategory}
        multiple
        label={t("category")}
        className="w-full mr-3"
        value={filterBody?.category?.map((item: any) => item.id)}
        tagRender={() => <></>}
        onChange={(values) => {
          setFilterBody({
            ...filterBody,
            category: filter(listCategory, (item: any) =>
              values.includes(item.id)
            ),
          });
        }}
        getLabel={(item) => item.name}
        selectAll
        disabled={!warehouse}
        placeholder={t("pleaseSelect")}
        selectAllLabel={t("selectAll")}
        deselectAllLabel={t("deselectAll")}
      />
      <div className="mt-3">
        <FilterView
          filterList={filterListData}
          onRemoveFilter={onRemoveFilter}
          onRemoveAllFilter={() => setFilterBody(INIT_FILTER)}
        />
      </div>

      <div className="position-absolute bottom-0 w-100 end-0 start-0 flex-center-y justify-content-between py-3 px-3 bg-white">
        <Button
          onClick={() => {
            onClose && onClose({});
          }}
          variant="outline"
        >
          {t("cancel")}
        </Button>
        <Button onClick={() => {}}>{t("save")}</Button>
      </div>
    </Drawer>
  );
};

export default OrderDetailProductFilter;
