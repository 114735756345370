import { Path } from "@components/layout/Path";
import Drawer from "@components/shared/Drawer";
import UserAvatarName from "@components/shared/UserAvatarName";
import { ORDER_PAYMENT_STATUS, ORDER_STATUS_ARRAY } from "@constants/order";
import { mapObjectToArray } from "@helpers";
import CashDepositAPI from "@network/api/cash-deposit";
import {
  AwesomeTableComponent,
  HeaderTable,
  StringUtils,
  TimeUtils,
  ViewLabelStatus,
  useFirstTime,
} from "d-react-components";
import { debounce, map } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, generatePath } from "react-router-dom";
import CashTransactionFilterDrawer from "./CashTransactionFilterDrawer";

const CashTransactionSelectDrawer: any = ({
  open,
  onClose,
  onSave,
  chanel,
  store,
}: any) => {
  const [openFilter, setOpenFilter] = useState(false);
  const [filterBody, setFilterBody] = useState<any>();
  const [transactionSelected, setTransactionSelected] = useState();
  const transactionList = useRef<any>();
  const textSearch = useRef();
  const isFirstTime = useFirstTime();

  const { t } = useTranslation();

  const columns = [
    {
      title: t("paymentRef"),
      dataIndex: "code",
      render: (code: string, item: any) => {
        return (
          <Link to={generatePath(Path.ORDER_DETAIL, { id: item?.order?.id })}>
            {code}
          </Link>
        );
      },
    },
    {
      title: t("amount"),
      dataIndex: "total",
      render: StringUtils.moneyThaiFormat,
    },
    {
      title: t("status"),
      dataIndex: "status",
      render: (status: any) => {
        const ORDER_PAYMENT_STATUS_LIST =
          mapObjectToArray(ORDER_PAYMENT_STATUS);

        return (
          <ViewLabelStatus
            status={status}
            listStatus={ORDER_PAYMENT_STATUS_LIST}
            getLabel={(item) => t(item.label)}
          />
        );
      },
    },

    {
      title: t("createdBy"),
      dataIndex: "by",
      render: (by: any) => <UserAvatarName user={by} />,
    },
    {
      title: t("createdAt"),
      dataIndex: "created",
      render: TimeUtils.toDateTime,
    },
    {
      title: t("orderRef"),
      dataIndex: "order",
      render: (order: any) => {
        return (
          <Link to={generatePath(Path.ORDER_DETAIL, { id: order?.id })}>
            {order?.orderid}
          </Link>
        );
      },
    },
    {
      title: t("orderAmount"),
      dataIndex: "order",
      render: (order: any) => StringUtils.moneyThaiFormat(order?.total),
    },
    {
      title: t("orderStatus"),
      dataIndex: "order",
      render: (order: any) => {
        return (
          <ViewLabelStatus
            status={order?.status}
            listStatus={ORDER_STATUS_ARRAY}
            getLabel={(item) => t(item.label)}
          />
        );
      },
    },
    {
      title: t("orderCreatedBy"),
      dataIndex: "order",
      render: (order: any) => <UserAvatarName user={order?.by} />,
    },
    {
      title: t("orderCreatedAt"),
      dataIndex: "order",
      render: (order: any) => TimeUtils.toDateTime(order?.created),
    },
  ];

  const isShowFilterView = useMemo(() => {
    if (!filterBody) {
      return false;
    }
    const { from, to, status, createdBy } = filterBody;
    return !!from || !!to || status?.length > 0 || createdBy?.length > 0;
  }, [filterBody]);

  useEffect(() => {
    if (isFirstTime) {
      return;
    }
    transactionList.current.refresh();
  }, [filterBody]);

  const onFilterTransaction = (filterBodyParam: any) => {
    setFilterBody(filterBodyParam);
  };

  const handleRemoveFilter = (item: any) => {
    const result = { ...filterBody };
    delete result[item.key];
    setFilterBody(result);
  };

  const source = (paging: any) => {
    const { status = [], createdBy = [], from, to } = filterBody ?? {};
    const body = {
      search: textSearch.current,
      from,
      to,
      channel: chanel?.id,
      storeid: store?.id,
      status,
      createdBy: map(createdBy, (item) => item.id),
    };
    return CashDepositAPI.paymentList(body, paging);
  };

  const onChangeTextSearch = debounce((text) => {
    textSearch.current = text;
    transactionList.current.refresh();
  }, 500);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      onSave={() => onSave(transactionSelected)}
      title={t("selectCashTransaction")}
      size="auto"
      width={"70vw"}
    >
      <HeaderTable
        onChangeText={(event: any) => onChangeTextSearch(event.target.value)}
        onClickFilter={() => setOpenFilter(true)}
        isFiltered={isShowFilterView}
      />
      <AwesomeTableComponent
        className="mt-3"
        source={source}
        transformer={(res) => {
          return res?.data?.data?.payment ?? [];
        }}
        columns={columns}
        isScroll={false}
        rowSelection={{
          type: "checkbox",
          onChange: (selectedRowKeys, selectedRows) => {
            setTransactionSelected(selectedRows as any);
          },
          getCheckboxProps: (record) => {
            return { disabled: !!record.deposit };
          },
        }}
        ref={(ref) => (transactionList.current = ref)}
      />

      {openFilter && (
        <CashTransactionFilterDrawer
          openModal={openFilter}
          onClose={() => setOpenFilter(false)}
          onFilter={onFilterTransaction}
          filterBodyDefault={filterBody}
        />
      )}
    </Drawer>
  );
};

export default CashTransactionSelectDrawer;
