import UserAvatarName from "@components/shared/UserAvatarName";
import { DELIVERY_STOCK_STATUS } from "@constants/delivery";
import AppContext from "@helpers/context";
import { isGrantURLPermission } from "@helpers/permissions";
import { IDelivery } from "@interfaces/delivery";
import { API_DELIVERY_STOCK_ASSIGN_DRIVER_VEHICLE } from "@network/URL";
import { deliveryStockAssignVehicleDriver } from "@network/api/delivery-stock";
import classNames from "classnames";
import {
  Button,
  IRowsKey,
  Notifications,
  Progress,
  TimeUtils,
  ViewRowInterchange,
} from "d-react-components";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DeliveryStockAssignDriverDrawer from "./DeliveryStockAssignDriverDrawer";

const DeliveryDetailDriverVehicle = () => {
  const { deliveryDetail, loadDeliveryDetail } = useContext(AppContext);
  const { t } = useTranslation();
  const [openAssignVehicle, setOpenAssignVehicle] = useState(false);

  const {
    vehicle_no,
    unmanaged_vehicle,
    vehicle,
    driver_name,
    driver,
    unmanaged_driver,
    delivery_type,
    status,
  } = deliveryDetail ?? {};

  const DETAILS_KEY = useMemo<IRowsKey<IDelivery>[]>(
    () => [
      {
        id: "vehicle",
        label: t("vehicle"),
        renderContent: ({ data, item }) => renderVehicleInfo(),
      },
      {
        id: "driver",
        label: t("driver"),
        renderContent: ({ data, item }) => renderDriverInfo(),
      },
      {
        id: "assigned_at",
        label: t("assignedOn"),
        renderContent: ({ data, item }) => TimeUtils.toDateTime(data),
      },
    ],
    [t, deliveryDetail]
  );

  const renderVehicleInfo = () => {
    if (!vehicle_no && !vehicle)
      return <div className={classNames("text-warning")}>{t("pending")}</div>;
    if (unmanaged_vehicle) {
      return <div>{vehicle_no}</div>;
    }

    return <div>{vehicle?.registration}</div>;
  };

  const renderDriverInfo = () => {
    if (!driver_name && !driver)
      return <div className={classNames("text-warning")}>{t("pending")}</div>;

    if (unmanaged_driver) {
      return <div>{driver_name}</div>;
    }

    return <UserAvatarName user={driver} />;
  };

  const onAssignDriverVehicle = (values: any) => {
    const {
      vehicle,
      driver,
      deliveryType,
      isUnmanagedVehicle,
      isUnmanagedDriver,
      unmanagedDriver,
      unmanagedVehicle,
    } = values;

    const input = {
      id: deliveryDetail?.id,
      delivery_type: deliveryType,
      unmanaged_vehicle: isUnmanagedVehicle,
      vehicle_no: unmanagedVehicle,
      vehicle_id: vehicle?.id,

      driver_id: driver?.id,
      unmanaged_driver: isUnmanagedDriver,
      driver_name: unmanagedDriver,
    };
    Progress.show(
      { method: deliveryStockAssignVehicleDriver, params: [input] },
      (res: any) => {
        setOpenAssignVehicle(false);
        loadDeliveryDetail();
        Notifications.showSuccess(t("assignVehicleDriverSuccessful"));
      }
    );
  };

  return (
    <div className="card-container p-4 ">
      <div className="d-flex justify-content-between align-items-center">
        <label>{t("driverVehicle")}</label>

        {isGrantURLPermission(API_DELIVERY_STOCK_ASSIGN_DRIVER_VEHICLE) &&
          !vehicle &&
          !vehicle_no &&
          (status === DELIVERY_STOCK_STATUS.PENDING ||
            status === DELIVERY_STOCK_STATUS.BOOKING) && (
            <Button
              variant="trans"
              onClick={() => setOpenAssignVehicle(true)}
              className=""
            >
              {t("assign")}
            </Button>
          )}
      </div>
      <ViewRowInterchange
        dataSource={deliveryDetail}
        keyList={DETAILS_KEY as any}
        variant="border"
        classNameLabel="font-weight-bold "
      />

      {openAssignVehicle && (
        <DeliveryStockAssignDriverDrawer
          open={openAssignVehicle}
          onClose={() => setOpenAssignVehicle(false)}
          onSubmit={onAssignDriverVehicle}
          defaultValue={{
            deliveryType: delivery_type,
            isUnmanagedVehicle: unmanaged_vehicle,
            unmanagedVehicle: vehicle_no,
            vehicle,
            driver,
            isUnmanagedDriver: unmanaged_driver,
            unmanagedDriver: driver_name,
          }}
        />
      )}
    </div>
  );
};

export default DeliveryDetailDriverVehicle;
