import InputTextForm from "@common/input/InputTextForm";
import ButtonFileUpload from "@components/shared/ButtonFileUpload";
import Drawer from "@components/shared/Drawer";
import {
  STOCK_ADJUSTMENT_STATUS,
  STOCK_ADJUSTMENT_STATUS_LIST,
} from "@constants/stock-adjustment";
import AppContext from "@helpers/context";
import {
  confirmStockAdjustment,
  rejectStockAdjustment,
} from "@network/api/stock-adjustment";
import classNames from "classnames";
import { Button, Notifications, ViewLabelStatus } from "d-react-components";
import { useFormik } from "formik";
import { map } from "lodash";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

interface IApproveAdjustmentDrawer {
  open: boolean;
  onClose: () => void;
}

const ApproveAdjustmentDrawer = ({
  open,
  onClose,
}: IApproveAdjustmentDrawer) => {
  const { t } = useTranslation();
  const { adjustmentDetail, loadAdjustmentDetail } = useContext(AppContext);

  const adjustmentForm = useFormik<any>({
    initialValues: {} as any,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: schema,
    onSubmit: (values: any) => {
      onConfirmAdjustment(values);
    },
  });

  const onConfirmAdjustment = async (input: any) => {
    const body = {
      id: adjustmentDetail?.id,
      confirm_note: input?.remark,
      attachment: map(input?.attachments, (item) => item.key),
    };
    return confirmStockAdjustment(body).then((res) => {
      Notifications.showSuccess(t("confirmStockSuccess"));
      onClose();
      loadAdjustmentDetail();
    });
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("approve")}
      onSave={() => adjustmentForm.handleSubmit()}
    >
      <div>
        <text>{t("approveStockAdjustmentDesc")}</text>
        <InputTextForm
          form={adjustmentForm}
          keyData="remark"
          multiple
          className="mt-3"
        />
        <div className="mt-3">
          <label>{t("attachments")}</label>
          <ButtonFileUpload
            //   uploadedFiles={formValues?.attachments}
            onChange={(values: any) =>
              adjustmentForm.setFieldValue("documents", values)
            }
            maxFiles={5}
          />
        </div>
      </div>
    </Drawer>
  );
};

const RejectAdjustmentDrawer = ({
  open,
  onClose,
}: IApproveAdjustmentDrawer) => {
  const { t } = useTranslation();
  const { adjustmentDetail, loadAdjustmentDetail } = useContext(AppContext);

  const adjustmentForm = useFormik<any>({
    initialValues: {} as any,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: schema,
    onSubmit: (values: any) => {
      onConfirmAdjustment(values);
    },
  });

  const onConfirmAdjustment = async (input: any) => {
    const body = {
      confirm_adjustment_stock_note: input?.remark,
      status: "reject",
      id: adjustmentDetail?.id,
    };
    return rejectStockAdjustment(body).then((res) => {
      Notifications.showSuccess(t("rejectAdjustmentSuccess"));
      onClose();
      loadAdjustmentDetail();
    });
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("reject")}
      onSave={() => adjustmentForm.handleSubmit()}
    >
      <InputTextForm form={adjustmentForm} keyData="remark" multiple />
      <div className="mt-3">
        <label>{t("attachments")}</label>
        <ButtonFileUpload
          //   uploadedFiles={formValues?.attachments}
          onChange={(values: any) =>
            adjustmentForm.setFieldValue("documents", values)
          }
          maxFiles={5}
        />
      </div>
    </Drawer>
  );
};

const StockAdjustmentDetailHeader = () => {
  const { adjustmentDetail } = useContext(AppContext);
  const { ref, status, by, created } = adjustmentDetail ?? {};
  const { t } = useTranslation();
  const [openApprove, setOpenApprove] = useState(false);
  const [openReject, setOpenReject] = useState(false);

  return (
    <div className="card-container p-3 flex-center justify-content-between ">
      <div className="flex-column">
        <div className="d-flex">
          <h4 className={classNames("text-[14px] text-primary")}>{ref}</h4>
          <ViewLabelStatus
            listStatus={STOCK_ADJUSTMENT_STATUS_LIST}
            status={status}
            className="ml-3"
            getLabel={(item) => t(item.label)}
          />
        </div>
        {/* <div className="mt-3 d-flex align-items-center cursor-pointer">
          <Icon name="print" />
          <div className="ml-3"> {t("print")}</div>
        </div> */}
      </div>
      {/* <div className="flex-column">
        <div className="d-flex">{`${t("by")} ${by?.name}`}</div>
        <small>{`${t("createdOn")} ${TimeUtils.toDateTime(created)}`}</small>
      </div> */}

      <div className="flex-row align-items-center">
        <Button
          onClick={() => setOpenApprove(true)}
          className="mr-3"
          hidden={status !== STOCK_ADJUSTMENT_STATUS?.PENDING}
        >
          {t("approve")}
        </Button>
        <Button
          onClick={() => setOpenReject(true)}
          className="mr-3"
          color="error"
          hidden={status !== STOCK_ADJUSTMENT_STATUS?.PENDING}
        >
          {t("reject")}
        </Button>
      </div>

      {openApprove && (
        <ApproveAdjustmentDrawer
          open={openApprove}
          onClose={() => setOpenApprove(false)}
        />
      )}
      {openReject && (
        <RejectAdjustmentDrawer
          open={openReject}
          onClose={() => setOpenReject(false)}
        />
      )}
    </div>
  );
};

export default StockAdjustmentDetailHeader;
