import { API } from "@network";
import {
  API_GET_WAREHOUSE_LIST,
  API_LIST_ALL_STORE,
  API_LIST_USER_STORE,
  API_LIST_USER_STORE_NO_PAGING,
  API_ORDER_MODERN_TRADE_BANKS,
} from "@network/URL";

export const listAllStore = (
  body: any,
  paging = { pageIndex: 1, pageSize: 10 }
) => {
  return API.post(
    `${API_LIST_ALL_STORE}?page=${paging.pageIndex}&per_page=${paging.pageSize}`,
    body
    // {
    //   search,
    //   is_warehouse_delivery: isWarehouseDelivery,
    // }
  );
};

export const listUserStore = (body: any, params?: any) => {
  console.log(body, params);
  return API.post(`${API_LIST_USER_STORE}`, body, {
    params,
  });
};

export const listUserStoreNoPaging = (body: any, params?: any) => {
  return API.post(`${API_LIST_USER_STORE_NO_PAGING}`, body, {
    params,
  });
};

export const getWarehouseList = () => {
  return API.post(API_GET_WAREHOUSE_LIST);
};

export const modernTradeBankList = (body: any = {}) => {
  return API.post(API_ORDER_MODERN_TRADE_BANKS, body);
};
