/* eslint-disable react-hooks/exhaustive-deps */
import UserAvatarName from "@components/shared/UserAvatarName";
import { DEFAULT_PAGING } from "@constants/common";
import { DISCOUNT_STAFF_STATUS_LIST } from "@constants/discount";
import AppContext from "@helpers/context";
import DiscountStaffAPI from "@network/api/discount-staff";
import {
  AwesomeTableComponent,
  HeaderTable,
  Icon,
  StringUtils,
  ViewLabelStatus,
} from "d-react-components";
import i18next from "i18next";
import { debounce, isEmpty, map } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { JsonParam, useQueryParam } from "use-query-params";
import DiscountStaffAssignDrawer from "./DiscountStaffAssignDrawer";
import DiscountStaffFilterDrawer from "./DiscountStaffFilterDrawer";
import DiscountStaffHistoryDrawer from "./DiscountStaffHistoryDrawer";

const DiscountStaffManagementTable = () => {
  const tableRef = useRef<any>();
  const searchRef = useRef<string>();
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const { t } = useTranslation();
  const [pageParam, setPageParam] = useQueryParam("pageParam", JsonParam);
  const [openFilter, setOpenFilter] = useState(false);
  const [discountAssignDrawer, setDiscountAssignDrawer] = useState<any>({
    open: false,
  });

  const [discountHistoryDrawer, setDiscountHistoryDrawer] = useState<any>({
    open: false,
  });
  const [filterBody = {}, setFilterBody] = useQueryParam(
    "filterBody",
    JsonParam
  );
  const isFiltered = useMemo(
    () => Object.keys(filterBody).length > 0,
    [filterBody]
  );

  const refreshTable = () => {
    return (
      tableRef.current &&
      tableRef.current.refreshKeepPaging(pageParam ?? DEFAULT_PAGING)
    );
  };

  useEffect(() => {
    refreshTable();
  }, [pageParam]);

  const columns: any[] = [
    {
      title: t("staff"),
      dataIndex: "staff",
      render: (staff: any) => <UserAvatarName user={staff} />,
    },
    {
      title: t("employeeId"),
      dataIndex: "staff",
      render: (staff: any) => staff?.companyId,
    },
    {
      title: t("store"),
      dataIndex: "store",
      render: (store: any) => store?.name + " - " + store?.code,
    },
    {
      title: t("discountRatePercentage"),
      dataIndex: "percentage",
    },
    {
      title: t("discountRateBaht"),
      dataIndex: "amount",
      render: StringUtils.moneyThaiFormat,
    },
    {
      title: t("status"),
      dataIndex: "status",
      render: (status: any) => (
        <ViewLabelStatus
          status={status}
          listStatus={DISCOUNT_STAFF_STATUS_LIST}
          getLabel={(item) => t(item.label)}
        />
      ),
    },
    {
      title: t("history"),
      dataIndex: "",
      render: (discount: any) => (
        <Icon
          name="history"
          onClick={() => setDiscountHistoryDrawer({ open: true, discount })}
        />
      ),
    },
    {
      title: t("edit"),
      dataIndex: "",
      render: (discountRaw: any) => {
        const discount = {
          ...discountRaw,
          staff: [discountRaw?.staff],
          store: [discountRaw?.store],
        };
        return (
          <Icon
            className="cursor-default"
            name="edit"
            onClick={() => setDiscountAssignDrawer({ open: true, discount })}
          />
        );
      },
    },
  ];

  const source = (paging: any) => {
    const { store, staff, status } = filterBody ?? {};
    let body: any = { search: searchRef.current };
    if (!isEmpty(store)) {
      body = { ...body, store: map(store, (item) => item?.id) };
    }
    if (!isEmpty(staff)) {
      body.staff = map(staff, (item) => item?.id);
    }
    if (!isEmpty(status)) {
      body.status = [...status];
    }
    return DiscountStaffAPI.discountStaffList(body, paging);
  };

  const onChangeSearch = debounce((text) => {
    searchRef.current = text;
    tableRef.current.refresh();
  }, 300);

  useEffect(() => {
    i18next.loadLanguages(["en", "th"]);
  }, []);

  return (
    <AppContext.Provider
      value={{
        onReloadData: () => {
          tableRef.current?.refresh();
        },
      }}
    >
      <div className="bg-white">
        <HeaderTable
          label={t("staffDiscountManagement")}
          onChangeText={(event: any) => onChangeSearch(event.target.value)}
          onClickFilter={() => setOpenFilter(true)}
          isFiltered={isFiltered}
          className="app-layout__page-header"
          onClickNew={() => setDiscountAssignDrawer({ open: true })}
        />
        <AwesomeTableComponent
          source={source}
          transformer={(res) => {
            const discount = res?.data?.data?.discount ?? [];
            return discount;
          }}
          isScroll={false}
          columns={columns}
          ref={(ref) => (tableRef.current = ref)}
        />
        {discountAssignDrawer?.open && (
          <DiscountStaffAssignDrawer
            open={discountAssignDrawer?.open}
            payload={discountAssignDrawer?.discount}
            isEdit={!!discountAssignDrawer?.discount}
            onClose={() => setDiscountAssignDrawer({ open: false })}
          />
        )}
        {discountHistoryDrawer?.open && (
          <DiscountStaffHistoryDrawer
            open={discountHistoryDrawer?.open}
            discount={discountHistoryDrawer?.discount}
            onClose={() => setDiscountHistoryDrawer({ open: false })}
          />
        )}
        {openFilter && (
          <DiscountStaffFilterDrawer
            open={openFilter}
            onClose={() => setOpenFilter(false)}
            onFilter={(values: any) => {
              setFilterBody(values);
              tableRef?.current?.refresh();
            }}
            defaultValue={filterBody}
          />
        )}
      </div>
    </AppContext.Provider>
  );
};

export default DiscountStaffManagementTable;
