import { SelectInfinityFilterMultiple } from "@common/dropdown/SelectFilter";
import { ONE_OF_CONDITION_TYPES } from "@constants/cart-rule";
import AppContext from "@helpers/context";
import { listAllStore } from "@network/api/store";
import { Table } from "antd";
import {
  AwesomeTableComponent,
  Icon,
  InputTextSearch,
  ObjectUtils,
  Select,
} from "d-react-components";
import _, { filter, includes, isEmpty, map, toLower, uniqBy } from "lodash";
import { useContext, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const CONDITION_TYPE = ObjectUtils.mapObjectToArray(ONE_OF_CONDITION_TYPES);
const StoreCondition = ({
  payload = {},
  setChangeCondition,
  conditionList,
  indexText = "",
  handleDeleteCondition,
}: any) => {
  const { cartRuleConditionDisabled } = useContext(AppContext);
  const [textSearch, setTextSearch] = useState("");

  const { t } = useTranslation();

  const storeList = useMemo(() => {
    if (isEmpty(textSearch)) {
      return payload.store;
    }
    return filter(payload.store, (customer) =>
      includes(toLower(customer?.name + customer?.code), toLower(textSearch))
    );
  }, [textSearch, payload.store]);

  //   const source = (pagingData: any) => {
  //     return API.listAllStore(textSearch.current, pagingData);
  //   };

  //   const renderStoreItem = (item: any) => {
  //     return item.name;
  //   };

  const onSelectStore = (stores: any) => {
    const { store = [] } = payload;
    const storeResult = uniqBy([...store, ...stores], (item) => item.id);
    const clone = { ...payload, store: storeResult };
    onChangeConditions(clone);
  };

  const onDeleteStore = (storeDelete: any) => {
    const { store = [] } = payload;
    const results = filter(store, (item) => item.id !== storeDelete.id);
    const clone = { ...payload, store: results };
    onChangeConditions(clone);
  };

  const onChangeConditions = (clone: any) => {
    const cloneList = map(conditionList, (item) => {
      if (item.id === payload.id) {
        return clone;
      }
      return item;
    });
    setChangeCondition(cloneList);
  };

  const columns: any[] = [
    {
      title: t("code"),
      dataIndex: "code",
    },
    {
      title: t("name"),
      dataIndex: "name",
    },
    {
      title: t("address"),
      dataIndex: "address",
    },
  ];

  if (!cartRuleConditionDisabled) {
    columns.push({
      title: t("action"),
      dataIndex: "",
      render: (store: any) => {
        return <Icon name="delete" onClick={() => onDeleteStore(store)} />;
      },
    });
  }
  const handleChangeRuleCondition = (changedPayload: any) => {
    const cloneList = map(conditionList, (item) => {
      if (item.id === changedPayload.id) {
        return changedPayload;
      }
      return item;
    });
    setChangeCondition(cloneList);
  };

  return (
    <div className="mt-2 border border-primary">
      <div className="flex-row-between-center bg-primary px-2">
        <div className="flex-row align-items-center">
          <text className="font-weight-bold text-white">
            {`${t("condition")} ${indexText}: `}
          </text>
          <div className="ml-1 text-white">{t("store")}</div>
          <Select
            className="ml-2 cart-rule__condition-select"
            dataSource={CONDITION_TYPE}
            disabled={cartRuleConditionDisabled}
            getLabel={(option) => t(option.label)}
            variant="standard"
            value={payload?.rule}
            onChange={(value) => {
              handleChangeRuleCondition({
                ...payload,
                rule: value,
              });
            }}
          />
        </div>
        {!cartRuleConditionDisabled && (
          <Icon
            name="remove_circle"
            color="white"
            onClick={handleDeleteCondition}
          />
        )}
      </div>
      <div className="p-3">
        <div className="flex-row-between-center">
          <InputTextSearch
            onChange={(event: any) => setTextSearch(event.target.value)}
          />
          {!cartRuleConditionDisabled && (
            <div className="flex-row justify-content-end w-25 ml-2">
              <SelectInfinityFilterMultiple
                className="w-100"
                fetchFn={(body: any, paging: any) => {
                  return listAllStore(
                    { search: body.search },
                    {
                      pageIndex: paging.page,
                      pageSize: paging.per_page,
                    }
                  );
                }}
                key="selectWarehouse"
                dataPath="stores"
                value={payload.store}
                customQuery={(search) => ({
                  // channel: filters.channel ?? [],
                  search,
                })}
                getLabel={(item) => item?.name}
                dropdownHeight={300}
                onChange={onSelectStore}
                placeholder={t("store")}
                multiple
                mode="multiple"
                valueKey="value"
                tagTitle={t("store")}
                //   onDropdownVisibleChange={(open) => {
                //     if (!open) {
                //       refreshTable();
                //     }
                //   }}
                //   onClear={() => refreshTable()}
              />
            </div>
          )}
        </div>

        <AwesomeTableComponent
          className="mt-2"
          dataSource={storeList}
          columns={columns}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default StoreCondition;
