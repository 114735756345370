import AppContext from "@helpers/context";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import ChannelFormGeneral from "../components/ChannelFormGeneral";

const ChannelCreateGeneral = () => {
  const { channelForm } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <div className="card-container p-4">
      <ChannelFormGeneral form={channelForm} show={{ systemCode: true }} />
    </div>
  );
};

export default ChannelCreateGeneral;
