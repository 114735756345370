import { IChannel } from "@interfaces/channel";
import { IFieldSelect } from "@interfaces/select";
import { getListChannel } from "@network/api/channel";

import { ObjectUtils, Select } from "d-react-components";
import { isArray, map } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export interface IChannelSelect<T> extends IFieldSelect<T> {
  [key: string]: any;
  chainIds?: string[];
  source?: (input: any) => any;
  transformer?: (res: any) => any;
  showLabel?: boolean;
}

const ChannelSelect = ({
  value = [],
  multiple,
  className,
  onChange,
  chainIds,
  source = getListChannel,
  transformer = (res) => res?.data?.data?.channel ?? [],
  showLabel = true,
  ...selectProps
}: IChannelSelect<IChannel>) => {
  const [channelList, setChannelList] = useState<any[]>(
    isArray(value) ? value : [value]
  );

  const { t } = useTranslation();

  const onChangeValue = (id: any) => {
    if (multiple) {
      onChange(
        map(id, (item) => ObjectUtils.findItemFromId(channelList, item))
      );
      return;
    }
    onChange(ObjectUtils.findItemFromId(channelList, id));
  };

  const channelValue = useMemo(() => {
    if (multiple) {
      return map(value, (item: any) => item.id);
    }
    return (value as any)?.id;
  }, [value]);

  useEffect(() => {
    loadChannelList();
  }, [chainIds]);

  const loadChannelList = () => {
    const body = { chain_id: chainIds };
    source(body).then((res: any) => {
      const channelList = transformer(res);
      const pureChannelList = map(channelList, (item) => ({
        id: item.id,
        name: item.name,
      }));
      setChannelList(pureChannelList);
    });
  };

  return (
    <Select
      className={className}
      label={showLabel ? t("channel") : ""}
      dataSource={channelList}
      value={channelValue}
      onChange={onChangeValue}
      multiple={multiple}
      placeholder={t("pleaseSelect")}
      showSearch
      getLabel={(channel) => channel?.name}
      {...selectProps}
    />
  );
};

export default ChannelSelect;
