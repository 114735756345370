import { API } from "@network";
import {
  API_CHECK_DISCOUNT,
  API_AUTHORIZED_DISCOUNT_CODE,
  API_ISSUED_DISCOUNT_CODE,
  API_CREATE_DISCOUNT_CODE,
} from "@network/URL";

export const checkDiscountCode = (body: any) =>
  API.post(API_CHECK_DISCOUNT, body);
export const createDiscountCode = (body: any) =>
  API.post(API_CREATE_DISCOUNT_CODE, body);
export const listIssuedDiscountCode = (body: any) =>
  API.post(API_ISSUED_DISCOUNT_CODE, body);
export const listAuthorizedDiscountCode = (body: any) =>
  API.post(API_AUTHORIZED_DISCOUNT_CODE, body);
