import AppContext from "@helpers/context";
import {
  AwesomeTableComponent,
  IColumnsProps,
  IRowsKey,
  Notifications,
  Progress,
  StringUtils,
  ViewRowInterchange,
} from "d-react-components";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import ImageIcon from "@common/Icon";
import ProductName from "@components/product/components/ProductName";
import Drawer from "@components/shared/Drawer";
import { CART_RULE_ACTION, CART_RULE_ACTIONS } from "@constants/cart-rule";
import { ICartRule, mapObjectServerToClient } from "@interfaces/cart-rule";
import { useFormik } from "formik";
import { find } from "lodash";
import CartRuleActionForm from "../component/CartRuleActionForm";
import ConditionApplyType from "../component/condition/ConditionApplyType";
import ConditionList from "../component/condition/ConditionList";
import { useParams } from "react-router-dom";
import CartRuleAPI from "@network/api/cart-rule";
import { transferValidateActions } from "../create/CartRuleCreate";

interface ICartRuleDetailActionUpdateDrawer {
  open: boolean;
  defaultValue: any;
  onUpdateSuccess: any;
  onClose: () => void;
}

const CartRuleDetailActionUpdateDrawer = ({
  open,
  defaultValue,
  onUpdateSuccess,
  onClose,
}: ICartRuleDetailActionUpdateDrawer) => {
  const { t } = useTranslation();
  const { cartRule, loadCartRuleDetail } = useContext(AppContext);
  const { cartRuleId } = useParams();

  const cartRuleForm = useFormik<any>({
    initialValues: defaultValue as any,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: schema,
    onSubmit: (values: any) => {
      const { validate, message, resultActions } = transferValidateActions(
        values?.actions
      );

      if (!validate) {
        Notifications.showError(message);
        return;
      }

      const input = {
        id: cartRuleId,
        actions: resultActions,
      };
      onUpdate(input);
    },
  });
  const formValues = cartRuleForm?.values;
  const formErrors = cartRuleForm?.errors;

  const onUpdate = (input: any) => {
    Progress.show(
      { method: CartRuleAPI.update, params: [input] },
      (res: any) => {
        onClose();
        loadCartRuleDetail();
        Notifications.showSuccess(t("updateCartRuleSuccess"));
      }
    );
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("general")}
      onSave={() => cartRuleForm.handleSubmit()}
      size="large"
    >
      <CartRuleActionForm form={cartRuleForm} disabledSpecificAction />
    </Drawer>
  );
};

const CartRuleDetailAction = () => {
  const { cartRule, loadCartRuleDetail } = useContext(AppContext);
  const [openUpdate, setOpenUpdate] = useState(false);

  const cartRuleAction = cartRule?.actions;
  const actionSpecific = cartRuleAction?.specific;
  const actionData = cartRuleAction?.[actionSpecific];

  const { t } = useTranslation();

  const { id } = cartRule;

  let DETAILS_KEY = useMemo<IRowsKey<ICartRule>[]>(
    () => [
      {
        id: "actions",
        label: t("specificAction"),
        renderContent: ({ data, item }) => {
          const cartRuleAction = find(
            CART_RULE_ACTIONS,
            (item) => item.id === data?.specific
          );
          return t(cartRuleAction?.label ?? "");
        },
      },
    ],
    [t]
  );
  switch (actionSpecific) {
    case CART_RULE_ACTION.FREE_GIFT:
    case CART_RULE_ACTION.BUY_X_GET_BUNDLE:
    case CART_RULE_ACTION.BUY_X_GET_X:
      DETAILS_KEY = DETAILS_KEY.concat([
        {
          id: "actions",
          label: t("buyQuantity"),
          renderContent: ({ data, item }) => {
            return actionData?.buy;
          },
        },
        {
          id: "actions",
          label: t("recursive"),
          renderContent: ({ data, item }) =>
            data[data.specific]?.recursive ? t("yes") : t("no"),
        },
        {
          id: "actions",
          label: t("getFreeQuantity"),
          renderContent: ({ data, item }) => {
            return actionData?.free;
          },
        },
      ]);
      break;

    case CART_RULE_ACTION.DISCOUNT_FIX_AMOUNT:
      DETAILS_KEY = DETAILS_KEY.concat([
        {
          id: "actions",
          label: t("amount"),
          renderContent: ({ data, item }) => {
            return actionData?.amount;
          },
        },
      ]);
      break;
    case CART_RULE_ACTION.DISCOUNT_PERCENTAGE:
      DETAILS_KEY = DETAILS_KEY.concat([
        {
          id: "actions",
          label: t("percentage"),
          renderContent: ({ data, item }) => {
            return `${actionData?.percentage}%`;
          },
        },
      ]);
      break;

    case CART_RULE_ACTION.DISCOUNT_FIX_AMOUNT_CART:
      DETAILS_KEY = DETAILS_KEY.concat([
        {
          id: "actions",
          label: t("amount"),
          renderContent: ({ data, item }) => {
            return StringUtils.moneyThaiFormat(actionData?.amount);
          },
        },
        {
          id: "actions",
          label: t("sequentially"),
          renderContent: ({ data, item }) => {
            return actionData?.sequentially ? t("yes") : t("no");
          },
        },
      ]);
      break;

    case CART_RULE_ACTION.DISCOUNT_PERCENTAGE_CART:
      DETAILS_KEY = DETAILS_KEY.concat([
        {
          id: "actions",
          label: t("percentage"),
          renderContent: ({ data, item }) => {
            return `${actionData?.percentage}%`;
          },
        },
        {
          id: "actions",
          label: t("sequentially"),
          renderContent: ({ data, item }) => {
            return actionData?.sequentially ? t("yes") : t("no");
          },
        },
      ]);
      break;

    case CART_RULE_ACTION.BUY_X_GET_DISCOUNT_FIX_AMOUNT:
    case CART_RULE_ACTION.BUY_X_GET_DISCOUNT_PERCENTAGE:
    case CART_RULE_ACTION.BUY_X_GET_DISCOUNT_FIX_AMOUNT_Y:
    case CART_RULE_ACTION.BUY_X_GET_DISCOUNT_PERCENTAGE_Y:
      DETAILS_KEY = DETAILS_KEY.concat([
        {
          id: "actions",
          label: t("buyQuantity"),
          renderContent: ({ data, item }) => {
            return actionData?.buy;
          },
        },
        {
          id: "actions",
          label: t("getFreeQuantity"),
          renderContent: ({ data, item }) => {
            return actionData?.amount;
          },
        },
        {
          id: "actions",
          label: t("minQuantityToGetDiscount"),
          renderContent: ({ data, item }) => {
            return actionData?.minQuantity;
          },
        },
        {
          id: "actions",
          label: t("maxQuantityToGetDiscount"),
          renderContent: ({ data, item }) => {
            return actionData?.maxQuantity;
          },
        },
      ]);
      break;

    default:
      break;
  }

  const renderFreeItemsY = () => {
    if (
      actionSpecific !== CART_RULE_ACTION.BUY_X_GET_DISCOUNT_FIX_AMOUNT_Y &&
      actionSpecific !== CART_RULE_ACTION.BUY_X_GET_DISCOUNT_PERCENTAGE_Y
    ) {
      return <div />;
    }

    const columns: IColumnsProps = [
      {
        title: t("name"),
        dataIndex: "product",
        render: (product) => <ProductName item={{ product }} />,
      },
    ];
    return (
      <div className="mt-3">
        <label>{t("freeItemsY")}</label>
        <AwesomeTableComponent
          columns={columns}
          dataSource={actionData?.product ?? []}
          className="mt-2"
          pagination={false}
        />
      </div>
    );
  };

  const renderActionCondition = () => {
    return (
      <div className="mt-2">
        <ConditionApplyType value={cartRuleAction?.condition_type} disabled />
        <ConditionList
          conditions={cartRuleAction?.conditions}
          disabled
          // setChangeCondition={(value: any) =>
          //   cartRuleForm.setFieldValue("conditions", value)
          // }
          // addConditionHandle={addConditionHandle}
        />
      </div>
    );
  };

  return (
    <div className="border mt-3 p-4">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h5>{t("cartRuleAction")}</h5>
        <span onClick={() => setOpenUpdate(true)}>
          <ImageIcon
            className="w-[15px] cursor-pointer"
            src="/images/icons/edit.svg"
          />
        </span>
      </div>
      <ViewRowInterchange
        className="w-100"
        dataSource={cartRule}
        keyList={DETAILS_KEY as any}
        variant="border"
        classNameLabel="font-weight-bold "
        classNameContent="text-end "
      />
      {renderFreeItemsY()}
      {renderActionCondition()}
      {openUpdate && (
        <CartRuleDetailActionUpdateDrawer
          open={openUpdate}
          onClose={() => setOpenUpdate(false)}
          defaultValue={mapObjectServerToClient(cartRule)}
          onUpdateSuccess={loadCartRuleDetail}
        />
      )}
    </div>
  );
};

export default CartRuleDetailAction;
