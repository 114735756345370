import AppContext from "@helpers/context";
import { getStockAdjustmentDetail } from "@network/api/stock-adjustment";
import { Progress, TabBar } from "d-react-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import StockAdjustmentDetailActivity from "./StockAdjustmentDetailActivity";
import StockAdjustmentDetailGeneral from "./StockAdjustmentDetailGeneral";
import StockAdjustmentDetailHeader from "./StockAdjustmentDetailHeader";
import StockAdjustmentDetailInfo from "./StockAdjustmentDetailInfo";
import StockAdjustmentDetailProducts from "./StockAdjustmentDetailProducts";

const TABS = [
  {
    id: "general",
    label: "general",
    component: <StockAdjustmentDetailGeneral />,
  },

  {
    id: "itemList",
    label: "itemList",
    component: <StockAdjustmentDetailProducts />,
  },

  {
    id: "activity",
    label: "activity",
    component: <StockAdjustmentDetailActivity />,
  },
];

const StockAdjustmentDetail = () => {
  const [adjustmentDetail, setAdjustmentDetail] = useState(null);

  const [tab, setTab] = useState<any>(TABS[0]);
  const { adjustmentId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    loadAdjustmentDetail();
  }, []);

  const loadAdjustmentDetail = () => {
    Progress.show(
      { method: getStockAdjustmentDetail, params: [adjustmentId] },
      (res: any) => {
        const adjustment = res?.data?.data?.adjustment;
        setAdjustmentDetail(adjustment);
      }
    );
  };

  return (
    <AppContext.Provider
      value={{
        adjustmentDetail,
        loadAdjustmentDetail,
      }}
    >
      <div className="flex-column-container bg-trans">
        <StockAdjustmentDetailHeader />
        <div className="grid p-3 grid-cols-12 gap-4">
          <div className="col-span-4">{<StockAdjustmentDetailInfo />}</div>
          <div className="col-span-8 px-3">
            <TabBar
              dataSource={TABS}
              onChange={setTab}
              value={tab}
              getLabel={(item: any) => t(item.label)}
            />
            <div className="mt-3">{tab.component}</div>
          </div>
        </div>
      </div>
    </AppContext.Provider>
  );
};

export default StockAdjustmentDetail;
