import Drawer from "@components/shared/Drawer";
import { createCustomerShipping, searchAddress } from "@network/api/address";
import { Button, Notifications } from "d-react-components";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import AddressFieldInput, { CustomerAddressForm } from "./AddressFieldInput";

const CustomerAddressDrawer = ({ open, onClose, customer }: any) => {
  const { t } = useTranslation();

  const CustomerAddressSchema = Yup.object().shape({
    firstname: Yup.string().required(t("fieldRequired")),
    lastname: Yup.string().required(t("fieldRequired")),
    nickname: Yup.string().required(t("fieldRequired")).nullable(),
    place: Yup.string().required(t("fieldRequired")),
    phone: Yup.string().required(t("fieldRequired")),
    address: Yup.string().required(t("fieldRequired")),
    province: Yup.mixed().required(t("fieldRequired")),
    city: Yup.mixed().required(t("fieldRequired")),
    subdistrict: Yup.mixed().required(t("fieldRequired")),
    postcode: Yup.string().required(t("fieldRequired")),
  });
  const {
    addressFormCard,
    provinceFromCard,
    districtFromCard,
    subDistrictFromCard,
    firstname,
    lastname,
    nickname,
    phone,
    alternative_phone,
  } = customer || {};
  const customerForm = useFormik<CustomerAddressForm>({
    initialValues: {
      firstname,
      lastname,
      nickname,
      phone,
      alternative_phone,
    },
    onSubmit: () => {
      submitForm();
    },
    validationSchema: CustomerAddressSchema,
    validateOnChange: false,
  });
  const { values, setFieldValue, errors, handleSubmit } = customerForm;

  useEffect(() => {
    if (provinceFromCard || districtFromCard || subDistrictFromCard) {
      loadAddressData();
    }
  }, [districtFromCard, provinceFromCard, subDistrictFromCard]);

  const loadAddressData = async () => {
    const payload = {};
    if (provinceFromCard) {
      Object.assign(payload, {
        province_name: provinceFromCard,
      });
    }
    if (districtFromCard) {
      Object.assign(payload, {
        city_name: districtFromCard,
      });
    }
    if (subDistrictFromCard) {
      Object.assign(payload, {
        subdistrict_name: subDistrictFromCard,
      });
    }
    const res = await searchAddress(payload);
    const { province, city, subdistrict, post_code } =
      res?.data?.data?.search ?? {};
    if (province?.length && province?.length === 1) {
      setFieldValue("province", province?.[0]);
      setFieldValue("addressFormCard", addressFormCard);
      setFieldValue("address", addressFormCard);
      if (city?.length && city?.length === 1) {
        setFieldValue("city", city?.[0]);
        if (subdistrict?.length && subdistrict?.length === 1) {
          setFieldValue("subdistrict", subdistrict?.[0]);
          if (post_code?.length && post_code?.length === 1) {
            setFieldValue("postcode", post_code?.[0]?.postcode);
          }
        }
      }
    }
  };

  const submitForm = async () => {
    const customerid = customer?.id ?? null;
    const orderContact = {
      firstname: customer?.firstname ?? "",
      lastname: customer?.lastname ?? "",
      email: customer?.email ?? "",
      phone: customer?.phone ?? "",
    };
    const {
      firstname,
      lastname,
      nickname,
      phone,
      province,
      city,
      postcode,
      address,
      place,
      // latitude,
      // longitude,
      subdistrict,
      alternative_phone,
    } = values;

    const bodyShipping = {
      firstname,
      lastname,
      nickname,
      phone,
      province: province?.id,
      city: city?.id,
      subdistrict: subdistrict?.id,
      postcode,
      address,
      place,
      customerid,
      default: true,
      alternative_phone: alternative_phone,
      // latitude,
      // longitude,
    };
    // createCustomerShipping(bodyShipping).then((respone) => {
    //   setSelectedCustomer(customer);
    //   setContactOrder(orderContact);
    //   setCreateShippingModal(false);
    // });
    try {
      const customerResp = await createCustomerShipping(bodyShipping);
      if (customerResp?.data?.error?.length) {
        Notifications.showError(customerResp?.data?.resp);
        return;
      }
      onClose && onClose();
    } catch (err) {
      Notifications.showError(err);
    }
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      width="400px"
      size="auto"
      title={t("addCustomerAddress")}
      maskClosable={false}
      destroyOnClose
      onSave={() => handleSubmit()}
    >
      <div className="grid grid-cols-1 gap-4">
        <AddressFieldInput form={customerForm} />
      </div>
    </Drawer>
  );
};

export default CustomerAddressDrawer;
