import { IMPORT_WAREHOUSE_STEPS } from "@constants/warehouse";
import AppContext from "@helpers/context";
import WarehouseAPI from "@network/api/warehouse";
import { useFormik } from "formik";
import { filter } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import WarehouseImportFooter from "./WarehouseImportFooter";
import WarehouseImportResult from "./WarehouseImportResult";
import WarehouseImportSelectCSVFile from "./WarehouseImportSelectCSVFile";
import WarehouseImportSelectMethod from "./WarehouseImportSelectMethod";
import WarehouseImportValidation from "./WarehouseImportValidation";

export const INIT_IMPORT_FORM = {
  importFields: [],
  importType: null,
  fileImport: null,
};

const WarehouseImport = () => {
  const [stepIndex, setStepIndex] = useState(0);
  const [importFieldSource, setImportFieldSource] = useState([]);
  const { t } = useTranslation();

  const step = useMemo(() => IMPORT_WAREHOUSE_STEPS[stepIndex], [stepIndex]);

  const formImport = useFormik({
    initialValues: INIT_IMPORT_FORM,
    validateOnBlur: false,
    validateOnChange: false,
  } as any);

  useEffect(() => {
    const { importType } = formImport?.values ?? {};
    WarehouseAPI.getImportFields(importType).then((res) => {
      const fieldList = res?.data?.data?.fields ?? [];
      const requiredFields = filter(fieldList, (item) => item.required);
      if (importType === "create") {
        formImport.setFieldValue("importFields", fieldList);
      } else {
        formImport.setFieldValue("importFields", requiredFields);
      }
      setImportFieldSource(fieldList);
    });

    // test();
  }, [formImport?.values?.importType]);

  const test = () => {
    const text = `name,code_code,code_pickup_code,branch_tax_id,channel_add,channel_remove,channel_replace,chain_code,is_store,store_type,is_stock_adjustment,phone,address_address,address_province,address_city,address_subdistrict,address_postcode,delivery_add,delivery_remove,delivery_replace,status,,,,,,
Big C Supercenter (Wong Sawang),  L-CLM-BC001   ,L-D001,BIGC,BIGC-LT,,,BIGC,1,offline,1,02-5872055,"888 Piboonsongkram Rd., Bang Sue, Bang Sue, Bangkok, 10800",,,,,MAIN-LOTUS,,,active,,,,,,
Big C Supercenter (Chaengwattana), L-CLM-BC002 ,L-D002,BIGC,BIGC-LT,,,BIGC,1,offline,1,02-5735800,"96 Moo 1, Toongsonghong, Laksi, Bangkok, 10210",,,,,MAIN-LOTUS,,,active,,,,,,`;
    // const textList = text.match("/,  +,/g");
    const textList = text.match(/".*,.*"/g);
    console.log("textList", textList);
  };

  const classNameHeader =
    "d-flex justify-content-between align-items-center p-4 border-bottom";

  const renderContent = () => {
    switch (stepIndex) {
      case 0:
        return <WarehouseImportSelectMethod />;
      case 1:
        return <WarehouseImportSelectCSVFile />;
      case 2:
        return <WarehouseImportValidation />;
      case 3:
        return <WarehouseImportResult />;
      default:
        return <div />;
    }
  };

  return (
    <AppContext.Provider
      value={{ step, formImport, stepIndex, importFieldSource, setStepIndex }}
    >
      <div className="bg-white">
        <div className={classNameHeader}>
          <label>{t(step?.title)}</label>
          <div className="bg-dark px-3 py-1">
            <small className="text-white">{`Step ${stepIndex + 1} of ${
              IMPORT_WAREHOUSE_STEPS.length
            }`}</small>
          </div>
        </div>
        {renderContent()}
        <WarehouseImportFooter />
      </div>
    </AppContext.Provider>
  );
};

export default WarehouseImport;
