export const MY_REQUEST_STATUS_LIST = [
  { id: "pending", label: "pending", color: "#FAC256" },
  { id: "approved", label: "approved", color: "#33B950" },
  { id: "cancelled", label: "cancelled", color: "#ED6969" },
  { id: "rejected", label: "rejected", color: "#EB5757" },
  { id: "completed", label: "completed", color: "#33B950" },
  { id: "scheduled", label: "scheduled", color: "#84C4FF" },
  { id: "executed", label: "executed", color: "#33B950" },
];

export const MY_REQUEST_EXECUTION_STATUS_LIST = [
  { id: "pending", label: "pendingExecuted", color: "#FAC256" },
  { id: "partially", label: "partiallyExecuted", color: "#FAC256" },
  { id: "completed", label: "fullyExecuted", color: "#33B950" },
  { id: "cancelled", label: "cancelled", color: "#ED6969" },
];

export const TYPE_OF_CHANGE_REQUEST = [
  {
    id: "store",
    label: "updateStore",
  },
  {
    id: "channel",
    label: "updateChannel",
  },
];
