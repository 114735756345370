import React from "react";
import { Button, ButtonProps, Icon } from "d-react-components";
import classNames from "classnames";
import styled from "@emotion/styled";
import { isUndefined } from "lodash";

interface HoverableButtonProps extends ButtonProps {
  icon: string | React.ReactNode;
  hasData?: boolean;
  dataNumber?: any;
}

const HoverableButton = ({
  className,
  content,
  icon,
  hasData,
  dataNumber,
  ...props
}: HoverableButtonProps) => {
  return (
    <StyledButton className="group inline-block relative">
      {hasData && (
        <span
          className={`${
            dataNumber ? "w-4 h-4 -top-2 -left-2" : "w-2 h-2 -top-1 -left-1"
          } text-tiny rounded-full z-10 bg-red-500 inline-block text-white absolute text-center`}
        >
          {dataNumber}
        </span>
      )}
      <Button
        {...props}
        color="gray"
        className={classNames(
          className,
          "transition min-w-[40px] group-hover:bg-primary group-hover:w-auto"
        )}
        style={{ minWidth: "40px" }}
      >
        <span className="absolute right-[40px] group-hover:ml-1 transition w-0 opacity-0 group-hover:visible group-hover:opacity-100 group-hover:w-auto">
          <div className="flex items-center">
            <span className="bg-primary px-2 py-1">{content}</span>
            <div
              className="w-0 h-0 
                border-t-[6px] border-t-transparent
                border-l-[8px] border-l-primary
                border-b-[6px] border-b-transparent"
            ></div>
          </div>
        </span>
        {icon}
      </Button>
    </StyledButton>
  );
};

const StyledButton = styled.div`
  button {
    background-color: #000 !important;
  }
  &:hover {
    button {
      background-color: var(--primary-color) !important;
    }
  }
`;

export default HoverableButton;
