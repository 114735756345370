/* eslint-disable no-restricted-globals */
import {
  ADDITIONAL_DISCOUNT,
  INTERNAL_DISCOUNT,
  MEMBER_DISCOUNT,
  MT_CREDIT_DISCOUNT,
} from "@constants/discount";
import { CART_RULE_ACTION_KEY } from "@constants/order";
import AppContext from "@helpers/context";
import { Button, Checkbox, Drawer, StringUtils } from "d-react-components";
import { filter, map, sortBy, find } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApplyTextInput } from "./DiscountDrawer";

const fixed = CART_RULE_ACTION_KEY.discountFix.id;
const percentage = CART_RULE_ACTION_KEY.discountPercentage.id;
const fixAmount = CART_RULE_ACTION_KEY.discountFix.id;

const initInternalDiscount = {
  name: "",
  key: INTERNAL_DISCOUNT,
  value: null,
  id: "",
  applied: false,
  type: percentage,
};

const initMemberDiscount = {
  name: "",
  key: MEMBER_DISCOUNT,
  value: null,
  id: "",
  applied: false,
  type: percentage,
};

const initAdditionalDiscount = {
  key: ADDITIONAL_DISCOUNT,
  name: "",
  value: 0,
  id: "",
  type: fixAmount,
};

const initCreditCardDiscount = {
  name: "",
  key: MT_CREDIT_DISCOUNT,
  value: null,
  id: "",
  applied: false,
  type: percentage,
};

const INIT_AMOUNT_LIMITATION = {
  amountMax: 0,
  percentageMax: 0,
};

const ChainDiscountDrawer = ({ open, onClose }: any) => {
  const { t } = useTranslation();
  const { discountChainList, setDiscountChainList, values, totalState } =
    useContext(AppContext);
  const { storeSelected } = values;

  const internalDiscountLength = filter(
    discountChainList,
    (item) => item.action.level === INTERNAL_DISCOUNT
  ).length;
  const additionalDiscountLength = filter(
    discountChainList,
    (item) => item?.action?.level === ADDITIONAL_DISCOUNT
  ).length;

  let EXTERNAL_DISCOUNT_TYPES = [
    {
      id: INTERNAL_DISCOUNT,
      label: t("internalDiscount"),
      initData: initInternalDiscount,
      renderContent: renderMtAdditionalDiscount,
      priority: 1,
    },
    {
      id: MEMBER_DISCOUNT,
      label: t("mtMemberDiscount"),
      initData: initMemberDiscount,
      renderContent: renderDiscountInputView,
      priority: 2,
    },
    {
      id: ADDITIONAL_DISCOUNT,
      label: t("mtAdditionalDiscount"),
      initData: initAdditionalDiscount,
      renderContent: renderMtAdditionalDiscount,
      priority: 3,
    },
  ];

  if (storeSelected?.chain?.status_mt_credit_card_discount) {
    EXTERNAL_DISCOUNT_TYPES = [
      ...EXTERNAL_DISCOUNT_TYPES,
      {
        id: MT_CREDIT_DISCOUNT,
        label: t("mtCreditCardDiscount"),
        initData: initCreditCardDiscount,
        renderContent: renderDiscountInputView,
        priority: 4,
      },
    ];
  }

  const [discount, setDiscount] = useState({
    ...initInternalDiscount,
    name: `${t("discount")}`,
    id: StringUtils.getUniqueID(),
  });
  const [amountLimitation, setAmountLimitation] = useState<any>(
    INIT_AMOUNT_LIMITATION
  );

  function getAmountLimitation() {
    const {
      limit_internal_discount,
      limit_member_discount,
      limit_mt_credit_card_discount,
    } = storeSelected?.chain ?? {};

    switch (discount?.key) {
      case INTERNAL_DISCOUNT:
        const percentageMax = limit_internal_discount[internalDiscountLength];
        const amountMax = (totalState * percentageMax) / 100;
        return {
          percentageMax,
          amountMax,
        };
      case MEMBER_DISCOUNT:
        return { percentageMax: limit_member_discount };
      case ADDITIONAL_DISCOUNT:
        return { percentageMax: 100, amountMax: null };
      case MT_CREDIT_DISCOUNT:
        return {
          percentageMax: limit_mt_credit_card_discount,
          amountMax: null,
        };
      default:
        return INIT_AMOUNT_LIMITATION;
    }
  }

  useEffect(() => {
    const amountLimitation = getAmountLimitation();
    setAmountLimitation(amountLimitation);
  }, [storeSelected, discount?.key, totalState]);

  const onAddDiscountChain = (discount: any) => {
    const chainList = sortBy(
      [...discountChainList, discount],
      (item) => item.priority
    );
    setDiscountChainList(chainList);
  };

  const handleChangeDiscountType = (discount: any, type: any) => {
    setDiscount({
      ...type.initData,
      name: discount.name,
      id: discount.id,
    });
  };

  const onApplyDiscount = (amountData: any, discountType: any) => {
    if (!amountData?.value || amountData?.value == 0) {
      return;
    }

    const giftItem = {
      action: {
        level: amountData?.key,
        amount: amountData?.value,
        specific: amountData?.type,
        percentage: amountData?.value,
      },
      id: amountData?.id,
      discountType: amountData?.type,
      isChainDiscount: true,
      internalDiscountIndex:
        amountData?.key === INTERNAL_DISCOUNT ? internalDiscountLength : null,
      additionalDiscountIndex:
        amountData?.key === ADDITIONAL_DISCOUNT
          ? additionalDiscountLength
          : null,
      priority: discountType.priority,
    };
    onAddDiscountChain(giftItem);
    onClose();
  };

  const getDisabledInput = (discountType: any) => {
    switch (discountType) {
      case INTERNAL_DISCOUNT:
        return !amountLimitation.percentageMax;
      case MEMBER_DISCOUNT:
      case MT_CREDIT_DISCOUNT:
        return !!find(
          discountChainList,
          (item) => item?.action?.level === discountType
        );
      case ADDITIONAL_DISCOUNT:
        return additionalDiscountLength === 2;
      default:
        return false;
    }
  };

  function renderDiscountInputView(couponPayload: any, discountType: any) {
    const { percentageMax } = amountLimitation;
    return (
      <ApplyTextInput
        onChange={(e: any) => {
          let value = e.target.value.replace(/\D+/g, "");
          value = parseInt(value);
          if (isNaN(value)) {
            value = 0;
          }
          if (percentageMax && value > percentageMax) {
            value = percentageMax;
          }
          const clone = { ...couponPayload, value };
          setDiscount(clone);
        }}
        value={couponPayload.value}
        disabled={getDisabledInput(discountType?.id)}
        onClickApply={() => onApplyDiscount(couponPayload, discountType)}
      />
    );
  }

  function renderMtAdditionalDiscount(amountPayload: any, discountType: any) {
    const disabled = getDisabledInput(discountType?.id);
    const { percentageMax, amountMax } = amountLimitation;

    return (
      <div className="d-flex">
        <Button
          onClick={() => {
            const clone = { ...amountPayload, type: percentage, value: 0 };
            setDiscount(clone);
          }}
          variant={amountPayload?.type === percentage ? "standard" : "outline"}
          disabled={disabled}
        >
          %
        </Button>
        <Button
          onClick={() => {
            const clone = { ...amountPayload, type: fixed, value: 0 };
            setDiscount(clone);
          }}
          variant={amountPayload?.type === fixed ? "standard" : "outline"}
          disabled={disabled}
        >
          ฿
        </Button>
        <ApplyTextInput
          className="w-100"
          onChange={(e: any) => {
            let value = e.target.value.replace(/\D+/g, "");
            value = parseInt(value);
            if (isNaN(value)) {
              value = 0;
            }
            if (amountPayload.type === percentage && value > percentageMax) {
              value = percentageMax;
            }
            if (
              amountMax &&
              amountPayload.type === fixed &&
              value > amountMax
            ) {
              value = amountMax;
            }
            const clone = { ...amountPayload, value };
            setDiscount(clone);
          }}
          value={amountPayload?.value?.toLocaleString()}
          disabled={disabled}
          onClickApply={() => onApplyDiscount(amountPayload, discountType)}
        />
      </div>
    );
  }

  const renderRadioDiscountType = (discount: any, type: any) => {
    const isChecked = discount.key === type.id;
    return (
      <div className="mt-3">
        <Checkbox
          variant="radio"
          checked={discount.key === type.id}
          onChange={() => handleChangeDiscountType(discount, type)}
          label={type.label}
        />
        <div hidden={!isChecked} className="mt-3">
          {type.renderContent(discount, type)}
        </div>
      </div>
    );
  };

  return (
    <Drawer
      title={t("addDiscount")}
      open={open}
      onClose={onClose}
      destroyOnClose
    >
      <div key={discount?.id} className="flex-column">
        <div className="flex-center-y">
          <div className="text-primary label">{discount.name}</div>
        </div>
        {map(EXTERNAL_DISCOUNT_TYPES, (type) =>
          renderRadioDiscountType(discount, type)
        )}
      </div>
      <div className="position-absolute bottom-0 w-100 end-0 start-0 flex-center-y justify-content-between py-3 px-3 bg-white">
        <Button
          onClick={() => {
            onClose && onClose({});
          }}
          variant="outline"
        >
          {t("cancel")}
        </Button>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          {t("save")}
        </Button>
      </div>
    </Drawer>
  );
};

export default ChainDiscountDrawer;
