import LabelCheckboxSelect from "@common/LabelCheckboxSelect";
import InputSelectForm from "@common/input/InputSelectForm";
import Drawer from "@components/shared/Drawer";
import { YES_NO_OPTIONS } from "@constants/common";
import { DELIVERY_STATUS_LIST, ORDER_STATUS_ARRAY } from "@constants/order";
import { STORE_TYPE_LIST } from "@constants/store";
import { POSITION_LABELS } from "@constants/user";
import UserAPI from "@network/api/user";
import { CheckboxGroup, InputText, Modal } from "d-react-components";
import { useFormik } from "formik";
import { filter, map } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function UserDetailSettingUpdateModal({
  open,
  onClose,
  onUpdate,
  defaultValue,
}: any) {
  // const [promotionStatusList, setPromotionStatusList] = useState([]);
  const { t } = useTranslation();

  const settingForm = useFormik({
    initialValues: defaultValue ?? {},
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      onUpdate(values);
    },
  });

  // useEffect(() => {
  //   loadPromotionStatus();
  // }, []);

  // const loadPromotionStatus = () => {
  //   UserAPI.promotionStatusList().then((res) => {
  //     const promotionStatusList = res?.data?.data?.status ?? [];
  //     setPromotionStatusList(promotionStatusList);
  //   });
  // };

  const classNameInput = "mb-3 col-12";

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("updateSettings")}
      onSave={() => settingForm.handleSubmit()}
      size="auto"
      width={600}
    >
      <div className="row">
        {/* <InputSelectForm
          keyData="promotion_status"
          label={t("promotionStatus")}
          form={settingForm}
          className={classNameInput}
          dataSource={promotionStatusList}
          getLabel={(item: any) => item.name}
          multiple
        /> */}

        <InputSelectForm
          keyData="is_store"
          label={t("storeType")}
          form={settingForm}
          className={classNameInput}
          dataSource={filter(STORE_TYPE_LIST, (item) => item.id !== "all")}
        />

        <InputSelectForm
          keyData="position_label"
          label={t("positionLabel")}
          form={settingForm}
          className={classNameInput}
          dataSource={POSITION_LABELS}
        />

        <InputSelectForm
          keyData="block"
          label={t("blockUser")}
          form={settingForm}
          className={classNameInput}
          dataSource={YES_NO_OPTIONS}
        />

        <InputSelectForm
          keyData="has_call_center"
          label={t("hasCallCenter")}
          form={settingForm}
          className={classNameInput}
          dataSource={YES_NO_OPTIONS}
        />

        <InputText
          label={t("callCenterId")}
          className={classNameInput}
          value={settingForm?.values?.call_center_id}
          onChange={(event) => {
            const value = parseInt(event.target.value) ?? "";
            settingForm.setFieldValue("call_center_id", value);
          }}
        />
        <InputText
          label={t("callCenterEmail")}
          className={classNameInput}
          value={settingForm?.values?.call_center_email}
          onChange={(event) => {
            settingForm.setFieldValue("call_center_email", event.target.value);
          }}
        />

        <div className={classNameInput}>
          <LabelCheckboxSelect
            label={t("orderStatusManagement")}
            onDeselectAll={() =>
              settingForm.setFieldValue("manager_order_status", [])
            }
            onSelectAll={() =>
              settingForm.setFieldValue(
                "manager_order_status",
                map(ORDER_STATUS_ARRAY, (item) => item.id)
              )
            }
          />

          <CheckboxGroup
            dataSource={ORDER_STATUS_ARRAY}
            getLabel={(item) => t(item.label)}
            value={settingForm.values.manager_order_status ?? []}
            onChange={(value) =>
              settingForm.setFieldValue("manager_order_status", value)
            }
          />
        </div>

        <div className={classNameInput}>
          <LabelCheckboxSelect
            label={t("deliveryStatusManagement")}
            onDeselectAll={() =>
              settingForm.setFieldValue("delivery_status", [])
            }
            onSelectAll={() =>
              settingForm.setFieldValue(
                "delivery_status",
                map(DELIVERY_STATUS_LIST, (item) => item.key)
              )
            }
          />

          <CheckboxGroup
            dataSource={DELIVERY_STATUS_LIST}
            value={settingForm.values.delivery_status ?? []}
            onChange={(value) =>
              settingForm.setFieldValue("delivery_status", value)
            }
            getLabel={(item) => t(item.label)}
            getValue={(item) => item.key}
            multiple
          />
        </div>
      </div>
    </Drawer>
  );
}

export default UserDetailSettingUpdateModal;
