import styled from "@emotion/styled";
import { Badge, Button, Select } from "d-react-components";
import { find, isUndefined } from "lodash";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMedia } from "react-use";
import { TranslatedSelect } from "./TranslatedSelect";
import { use } from "i18next";

interface Option {
  label?: string;
  value?: any;
  count?: number;
  [key: string]: any;
}

interface FilterButtonGroupProps {
  label?: string;
  options: Option[];
  value: Option["value"];
  labelKey?: string;
  onChange?: (option: Option) => void;
  valueKey?: string;
  showAll?: boolean;
  className?: string;
  classNameOptions?: string;
  classNameItem?: string;
}

const FilterButtonGroup = ({
  label,
  options = [],
  value,
  onChange,
  labelKey = "label",
  valueKey = "value",
  showAll = true,
  className = "",
  classNameOptions = "",
  classNameItem = "",
}: FilterButtonGroupProps) => {
  const { t } = useTranslation();
  const isMobile = useMedia("(max-width: 767px)");
  const onChangeValue = (option?: Option) => {
    if (onChange) onChange(option ? option[valueKey] : undefined);
  };
  const dataSource = useMemo(
    () =>
      showAll
        ? [{ [valueKey]: "all", [labelKey]: "all" }, ...options]
        : options,
    [options, showAll]
  );
  return isMobile ? (
    <>
      <TranslatedSelect
        dataSource={dataSource as any}
        value={value}
        onChange={(option) => {
          const otp = find(options, { [valueKey]: option });
          onChangeValue(otp);
        }}
        placeholder={t(label as string)}
        getLabel={(option) => option[labelKey] || option.label}
        getValue={(option) => option[valueKey]}
        className={className}
      />
    </>
  ) : (
    <div className={`flex items-center ${classNameOptions}`}>
      {label && <span className="whitespace-nowrap">{t(label)}: </span>}
      {showAll && (
        <Button
          className="mx-1"
          color={isUndefined(value) ? "primary" : "secondary"}
          variant={isUndefined(value) ? "standard" : "outline"}
          onClick={() => onChangeValue()}
          size="small"
        >
          {t("all")}
        </Button>
      )}
      {options?.map((option) => (
        <Button
          className={`mx-1 ${classNameItem}`}
          color={value === option[valueKey] ? "primary" : "secondary"}
          variant={value === option[valueKey] ? "standard" : "outline"}
          onClick={() => onChangeValue(option)}
          size="small"
        >
          {t(option[labelKey] || option.label)}
          {!isUndefined(option.count) && (
            <StyledBadge className="flex items-center ml-1">
              <Badge
                index={option?.count > 999 ? "999+" : option.count}
                variant="index"
                size="x-large"
                color="red"
              />
            </StyledBadge>
          )}
        </Button>
      ))}
    </div>
  );
};
const StyledBadge = styled.div`
  .d-badge__badge-wrapper div {
    font-size: 8px !important;
  }
`;

export default FilterButtonGroup;
