/* eslint-disable jsx-a11y/alt-text */
import SignatureField from "@components/order/components/pickup/SignatureField";
import ProductName from "@components/product/components/ProductName";
import QuantityInputField from "@components/product/components/QuantityInputField";
import ButtonFileUpload from "@components/shared/ButtonFileUpload";
import Drawer from "@components/shared/Drawer";
import { ADMIN_AUTH_KEY } from "@constants";
import {
  DELIVERY_ORIGIN_STATUS_LIST,
  DELIVERY_STOCK_STATUS,
  DELIVERY_STOCK_STATUS_LIST,
} from "@constants/delivery";
import AppContext from "@helpers/context";
import {
  deliveryStockConfirmOrigin,
  deliveryStockStartLoading,
} from "@network/api/delivery-stock";
import classNames from "classnames";
import {
  AwesomeTableComponent,
  Button,
  InputText,
  Notifications,
  ObjectUtils,
  Progress,
  TimeUtils,
  ViewLabelStatus,
} from "d-react-components";
import { useFormik } from "formik";
import { map, replace } from "lodash";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "react-use";
import * as Yup from "yup";
import DeliveryConfirmedView from "../components/DeliveryConfirmedView";
import DeliveryStockAssignDriverDrawer from "./DeliveryStockAssignDriverDrawer";
import { isGrantURLPermission } from "@helpers/permissions";
import { API_DELIVERY_STOCK_START_LOADING } from "@network/URL";

const GoodsLoadingSchema = Yup.object().shape({
  driverSignature: Yup.string().required("Driver signature is required!"),
  supervisorSignature: Yup.string().required(
    "Supervisor signature is required!"
  ),
  attachments: Yup.array()
    .required("Attachments is required!")
    .min(1, "Minimum 1 attachment"),
});

const DeliveryStockConfirmLoadingDrawer = ({ open, onClose }: any) => {
  const { deliveryDetail, loadDeliveryDetail } = useContext(AppContext);
  const { products, driver, driver_name } = deliveryDetail;
  const { t } = useTranslation();
  const [admin] = useLocalStorage<any>(ADMIN_AUTH_KEY, {});

  const columns = [
    {
      title: t("itemName"),
      dataIndex: "",
      render: (product: any) => {
        const leftQty = product?.quantity - product?.pickupQty;
        return (
          <ProductName
            item={{ product }}
            subBody={
              <div
                className={classNames("small", {
                  "text-warning": leftQty !== 0,
                  "text-success": leftQty === 0,
                })}
              >
                {t("leftQty") + ": " + leftQty}
              </div>
            }
          />
        );
      },
    },
    {
      title: t("toReceive"),
      dataIndex: "pickupQty",
      render: (pickupQty: any, product: any) => (
        <QuantityInputField
          quantity={pickupQty}
          onChangeQuantity={(pickupQtyParam: any) => {
            if (pickupQtyParam < 0) return;
            onChangeQuantity({ ...product, pickupQty: pickupQtyParam });
          }}
        />
      ),
    },
  ];

  const form = useFormik({
    initialValues: {
      products: map(products, (item) => ({
        ...item,
        pickupQty: 0,
      })),
    } as any,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: GoodsLoadingSchema,
    onSubmit: (values: any) => {
      const input = {
        id: deliveryDetail?.id,
        driver_signature_at_warehouse: values?.driverSignature,
        supervisor_signature_at_warehouse: values?.supervisorSignature,
        remark_at_warehouse: values?.remark,
        driver_fullname_at_warehouse: driver?.fullname ?? driver_name,
        proof_at_warehouse: map(values.attachments, (item) => item.key),
      };

      onGoodsLoading(input);
    },
  });

  const formValues = form?.values;
  const formErrors = form?.errors;

  const onGoodsLoading = (input: any) => {
    Progress.show(
      { method: deliveryStockConfirmOrigin, params: [input] },
      () => {
        onClose && onClose();
        loadDeliveryDetail();
        Notifications.showSuccess(
          replace(
            t("loadingOriginCompleteAt"),
            "%time",
            TimeUtils.toDateTime(new Date())
          )
        );
      }
    );
  };

  const onChangeQuantity = (newProduct: any) => {
    const result = ObjectUtils.updateArrayById(
      formValues?.products as any,
      newProduct
    );
    form.setFieldValue("products", result);
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      onSave={() => form.handleSubmit()}
      saveText={t("confirm")}
      size="auto"
      width="50%"
      title={t("goodsLoading")}
    >
      <div>
        <div className="mt-3 label">{t("items")}</div>

        <AwesomeTableComponent
          dataSource={formValues?.products}
          columns={columns}
          pagination={false}
        />

        <div className="mt-3 label">{t("driverSignature")}</div>

        {formValues.driverSignature && (
          <img
            className="w-100"
            src={formValues.driverSignature}
            style={{ height: 250, objectFit: "contain" }}
          />
        )}
        <SignatureField
          className="w-min-content mt-2"
          onSave={(signature: any) =>
            form.setFieldValue("driverSignature", signature)
          }
        />

        <InputText
          className="mt-3"
          label={t("driverFullName")}
          value={driver?.fullname ?? driver_name}
          disabled
        />

        <div className="mt-3 label">{t("supervisorSignature")}</div>

        {formValues.supervisorSignature && (
          <img
            className="w-100"
            src={formValues.supervisorSignature}
            style={{ height: 250, objectFit: "contain" }}
          />
        )}
        <SignatureField
          className="w-min-content mt-2"
          onSave={(signature: any) =>
            form.setFieldValue("supervisorSignature", signature)
          }
        />

        <InputText
          className="mt-3"
          label={t("supervisorFullName")}
          value={admin?.fullname}
          disabled
        />

        <div className="mt-3 label">{t("proof")}</div>
        <ButtonFileUpload
          containerClassName="mt-3"
          onChange={(value: any) => form.setFieldValue("attachments", value)}
        />

        <InputText
          className="mt-3"
          label={t("remark")}
          multiple
          value={formValues.remark}
          onChange={(event) => form.setFieldValue("remark", event.target.value)}
          placeholder={t("pleaseInputTheNoteForThisUpdate")}
        />
      </div>
    </Drawer>
  );
};

const DeliveryDetailOrigin = () => {
  const { deliveryDetail, loadDeliveryDetail } = useContext(AppContext);

  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAssignVehicle, setOpenAssignVehicle] = useState(false);

  const {
    vehicle_no,
    unmanaged_vehicle,
    vehicle,
    driver_name,
    driver,
    unmanaged_driver,
    delivery_type,
    status,
    confirmationOrigin,
    products,
    start_loading,
  } = deliveryDetail ?? {};

  const isCancelled = status === DELIVERY_STOCK_STATUS.CANCELLED;

  const { t } = useTranslation();

  const onClickStartLoading = () => {
    if (start_loading?.id && start_loading?.delivery_type) {
      setOpenConfirm(true);
      return;
    }
    setOpenAssignVehicle(true);
  };

  const renderMainContent = () => {
    switch (status) {
      case DELIVERY_STOCK_STATUS.PENDING:
      case DELIVERY_STOCK_STATUS.BOOKING:
        if (!isGrantURLPermission(API_DELIVERY_STOCK_START_LOADING)) {
          return <div />;
        }

        return (
          <Button className="mt-3" onClick={onClickStartLoading}>
            {t("startLoading")}
          </Button>
        );
      case DELIVERY_STOCK_STATUS.IN_DELIVERY:
      case DELIVERY_STOCK_STATUS.COMPLETED:
      case DELIVERY_STOCK_STATUS.PARTIALLY:
        const {
          driver_fullname_at_warehouse,
          signatureDriver,
          signAt,
          supervisorName,
          signatureSupervisor,
          remark,
        } = confirmationOrigin ?? {};
        const productsAtOrigin = map(products, (item) => ({
          ...item,
          quantity: item.total_driver_receive,
        }));
        return (
          <div>
            <DeliveryConfirmedView
              className="mt-3"
              userTitle={t("driver")}
              userFullName={driver_fullname_at_warehouse}
              signature={signatureDriver}
              signAt={signAt}
              content={t("confirmedReceivedFollowingItems")}
              products={productsAtOrigin}
            />
            <DeliveryConfirmedView
              className="mt-3"
              userTitle={t("supervisor")}
              userFullName={supervisorName}
              signature={signatureSupervisor}
              signAt={signAt}
              content={t("confirmedSentToDriver")}
              products={productsAtOrigin}
              note={remark}
            />
          </div>
        );
      default:
        return <div />;
    }
  };

  if (isCancelled) return <div />;

  const onStartLoading = (values: any) => {
    const {
      vehicle,
      driver,
      deliveryType,
      isUnmanagedVehicle,
      isUnmanagedDriver,
      unmanagedDriver,
      unmanagedVehicle,
      attachment,
    } = values;

    const body = {
      id: deliveryDetail?.id,
      delivery_type: deliveryType,
      unmanaged_vehicle: !!isUnmanagedVehicle,
      vehicle_no: unmanagedVehicle === null ? "" : unmanagedVehicle,
      vehicle_id: vehicle?.id,

      driver_id: driver?.id,
      unmanaged_driver: !!isUnmanagedDriver,
      driver_name: unmanagedDriver === null ? "" : unmanagedDriver,

      proof: map(attachment, (item) => item.key),
    };

    Progress.show(
      { method: deliveryStockStartLoading, params: [body] },
      (res: any) => {
        loadDeliveryDetail();
        setOpenAssignVehicle(false);
        setOpenConfirm(true);
      }
    );
  };

  return (
    <div className="card-container mt-4 p-4">
      <label>{t("originPickupConfirm")}</label>
      <ViewLabelStatus
        listStatus={DELIVERY_ORIGIN_STATUS_LIST}
        status={confirmationOrigin?.status_at_warehouse}
        getLabel={(item) => t(item.label)}
      />
      {renderMainContent()}
      {openConfirm && (
        <DeliveryStockConfirmLoadingDrawer
          open={openConfirm}
          onClose={() => setOpenConfirm(false)}
        />
      )}
      {openAssignVehicle && (
        <DeliveryStockAssignDriverDrawer
          showAttachment
          showWarning
          open={openAssignVehicle}
          onClose={() => setOpenAssignVehicle(false)}
          onSubmit={onStartLoading}
          defaultValue={{
            deliveryType: delivery_type,
            isUnmanagedVehicle: unmanaged_vehicle,
            unmanagedVehicle: vehicle_no,
            vehicle,
            driver,
            isUnmanagedDriver: unmanaged_driver,
            unmanagedDriver: driver_name,
          }}
        />
      )}
    </div>
  );
};

export default DeliveryDetailOrigin;
