export const GP_EVENT_STATUSES = [
  { id: "in-use", label: "inUse", color: "#33B950" },
  { id: "expired", label: "expired", color: "#F44336" },
];

export const GP_EVENT_STATUS = {
  IN_USE: "in-use",
  EXPIRED: "expired",
};

export const GP_EVENT_VISIBILITIES = [
  { id: "public", label: "public", color: "#33B950" },
  { id: "draft", label: "draft", color: "#F44336" },
];
