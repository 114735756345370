import { Path } from "@components/layout/Path";
import AppContext from "@helpers/context";
import CashDepositAPI from "@network/api/cash-deposit";
import { Header, Notifications, Progress, TabBar } from "d-react-components";
import { useFormik } from "formik";
import { forEach, isEmpty, map } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import CashDepositCreateGeneral from "./CashDepositCreateGeneral";
import CashDepositCreateTransaction from "./CashDepositCreateTransaction";
import CashDepositCreateDocument from "./CashDepositCreateDocument";

const TAB_LIST = [
  {
    id: "general",
    label: "generalInformation",
    component: <CashDepositCreateGeneral />,
  },
  {
    id: "transactions",
    label: "transactions",
    component: <CashDepositCreateTransaction />,
  },
  // {
  //   id: "documents",
  //   label: "documents",
  //   component: <CashDepositCreateDocument />,
  // },
];

const CashDepositCreate = () => {
  const [selectedTab, setSelectedTab] = useState<any>(TAB_LIST[0]);
  const [transactions, setTransactions] = useState<any[]>([]);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const cashDepositForm = useFormik<any>({
    initialValues: {},
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      channel: Yup.mixed().required(t("fieldRequired")),
      store: Yup.mixed().required(t("fieldRequired")),
      depositMethod: Yup.mixed().required(t("fieldRequired")),
      bankFee: Yup.string().required(t("fieldRequired")),
      transactions: Yup.array()
        .min(1, t("cashTransactionCanNotEmpty"))
        .required(t("cashTransactionCanNotEmpty")),
    }),
    onSubmit: (values: any) => {
      const {
        depositMethod,
        remark,
        transactions,
        channel,
        transactionClosingDate,
        store,
        expectedDepositDate,
        bankFee,
        documents,
      } = values;
      const body = {
        channel: channel?.id,
        storeid: store?.id,
        transaction_closing_date: transactionClosingDate?.valueOf(),
        expected_deposit_date: expectedDepositDate?.valueOf(),
        payments: map(transactions, (item) => item.id),
        documents: map(documents, (item) => item.key),
        remark,
        deposit_method: depositMethod?.id,
        bank_fee: bankFee,
      };

      Progress.show(
        { method: CashDepositAPI.create, params: [body] },
        (res) => {
          // if (!isEmpty(attachments)) {
          //   uploadFileHandle(res);
          // } else {
          Notifications.showSuccess(t("createCashDepositSuccess"));
          navigate(Path.CASH_DEPOSIT);
          // }
        },
        (error: any) => {
          Notifications.showError(error?.message);
        }
      );
    },
  });

  useEffect(() => {
    forEach(Object.keys(cashDepositForm.errors), (key) =>
      Notifications.showError(`${(cashDepositForm.errors as any)?.[key]}`)
    );
  }, [cashDepositForm.errors]);

  const validateInput = (body: any) => {
    const { name, description, start, end, channelids, products } = body;
    const empty =
      isEmpty(name) ||
      isEmpty(description) ||
      isEmpty(channelids) ||
      isEmpty(products);
    if (empty) {
      return { validate: true, message: t("pleaseFillAllRequireField") };
    }
    if (
      moment(end, "x") <= moment(start, "x") ||
      moment(end, "x") === moment(start, "x")
    ) {
      return { validate: true, message: t("endtimehastobegreater") };
    }
    if (moment(start, "x").isBefore(moment())) {
      return { validate: true, message: t("starttimehastobe") };
    }
    return { validate: false, message: "" };
  };

  return (
    <AppContext.Provider
      value={{
        cashDepositForm,
        transactions,
        setTransactions,
      }}
    >
      <div>
        <Header
          title={t("cashDepositCreate")}
          onSave={() => cashDepositForm.handleSubmit()}
          onCancel={() => navigate(Path.CASH_DEPOSIT)}
          saveText={t("submit")}
          cancelText={t("back")}
        />
        <div className="p-3">
          <div className="row">
            <div className="col-md-3">
              <TabBar
                dataSource={TAB_LIST}
                variant="vertical"
                onChange={(tab) => setSelectedTab(tab)}
                value={selectedTab}
                getLabel={(item) => t(item.label as any)}
              />
            </div>
            <div className="col-md-9">{selectedTab.component}</div>
          </div>
        </div>
      </div>
    </AppContext.Provider>
  );
};

export default CashDepositCreate;
