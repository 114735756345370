import {
  AwesomeTableComponent,
  Button,
  InputTextSearch,
  Notifications,
  Progress,
  TimeUtils,
} from "d-react-components";
import { debounce, find, map, uniqBy } from "lodash";
import React, { useContext, useRef, useState } from "react";
import UserDetailChannelUpdateModal from "./UserDetailChannelUpdateModal";
import AppContext from "@helpers/context";
import { useTranslation } from "react-i18next";
import UserAPI from "@network/api/user";
import { IChannel } from "@interfaces/channel";

const UserDetailChannel = ({ updatable = true }: { updatable?: boolean }) => {
  const { user } = useContext(AppContext);
  const { t } = useTranslation();
  const tableRef = useRef<any>();
  const textSearchRef = useRef();
  // const [listCheckChannel, setListCheckChannel] = useState<any[]>([]);
  const [openAdd, setOpenAdd] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);

  const columns = [
    {
      title: t("NO"),
      dataIndex: "id",
      render: (id: any, item: any, index: any) => index + 1,
    },
    {
      title: t("name"),
      dataIndex: "name",
      render: (name: any, channel: any) => (
        <a href={channel?.editurl} target="_blank">
          {name}
        </a>
      ),
    },
    {
      title: t("code"),
      dataIndex: "code",
    },
    // {
    //   title: t("startingDate"),
    //   dataIndex: "start",
    //   width: 120,
    //   render: (start: any, item: any) => {
    //     const currentItem = find(listCheckChannel, {
    //       channel_id: item.id,
    //     });
    //     return currentItem ? TimeUtils.toDate(currentItem?.start) : "";
    //   },
    // },
  ];

  const source = (paging: any) => {
    const body = { id: user?.id, action: "in", search: textSearchRef.current };
    return UserAPI.channelList(body, paging);
    // return UserAPI.channelList(body, paging).then(
    //   (resp) => {
    //     const listChannel = resp?.data?.data?.channels ?? [];
    //     UserAPI.checkAssetChannel({
    //       channel: listChannel?.map((c: IChannel) => c.id),
    //       admin_id: user.id,
    //     }).then((resp) => {
    //       setListCheckChannel(
    //         uniqBy(
    //           [...listCheckChannel, ...(resp?.data?.data?.asset ?? [])],
    //           "id"
    //         )
    //       );
    //     });
    //     return resp;
    //   },
    //   (err) => {
    //     return err;
    //   }
    // );
  };

  const transformer = (res: any) => {
    return res?.data?.data?.channels ?? [];
  };

  const onAddChannel = (channels: any) => {
    const body = {
      id: user?.id,
      action: "add",
      channel: map(channels, (item) => item.id),
    };
    Progress.show({ method: UserAPI.channelUpdate, params: [body] }, () => {
      Notifications.showSuccess(t("updateUserChannelSuccess"));
      tableRef.current.refresh();
      setOpenAdd(false);
    });
  };

  const onRemoveChannel = (channels: any) => {
    const body = {
      id: user?.id,
      action: "remove",
      channel: map(channels, (item) => item.id),
    };
    Progress.show({ method: UserAPI.channelUpdate, params: [body] }, () => {
      Notifications.showSuccess(t("updateUserChannelSuccess"));
      tableRef.current.refresh();
      setOpenRemove(false);
    });
  };

  const onChangeTextSearch = debounce((event) => {
    textSearchRef.current = event?.target?.value;
    tableRef.current.refresh();
  }, 400);

  return (
    <div className="border mt-3 p-4">
      <div className="justify-content-between d-flex align-items-center mb-3">
        <InputTextSearch
          placeholder={t("search")}
          onChange={onChangeTextSearch}
        />
        {updatable && (
          <div className="d-flex">
            <Button className="ml-3" onClick={() => setOpenRemove(true)}>
              {t("remove")}
            </Button>
            <Button className="ml-3" onClick={() => setOpenAdd(true)}>
              {t("add")}
            </Button>
          </div>
        )}
      </div>
      <AwesomeTableComponent
        ref={(ref) => {
          tableRef.current = ref;
        }}
        columns={columns}
        source={source}
        transformer={transformer}
        className="mt-3"
      />
      {openAdd && (
        <UserDetailChannelUpdateModal
          open={openAdd}
          onClose={() => setOpenAdd(false)}
          userId={user?.id}
          onSave={onAddChannel}
          type="not_in"
          title={t("addChannel")}
        />
      )}
      {openRemove && (
        <UserDetailChannelUpdateModal
          open={openRemove}
          onClose={() => setOpenRemove(false)}
          userId={user?.id}
          onSave={onRemoveChannel}
          type="in"
          title={t("removeChannel")}
        />
      )}
    </div>
  );
};

export default UserDetailChannel;
