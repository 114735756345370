import { API } from "@network";
import {
  API_CHANNEL_ACTIVITY_ADD,
  API_CHANNEL_ACTIVITY_LIST,
  API_CHANNEL_CREATE,
  API_CHANNEL_DETAIL,
  API_CHANNEL_LIST,
  API_CHANNEL_UPDATE,
  API_CHANNEL_USER,
  API_CHANNEL_USER_LOAD,
  API_CHANNEL_USER_SUMMARY,
  API_CHANNEL_USER_UPDATE,
  API_CHANNEL_WAREHOUSE,
  API_CHANNEL_WAREHOUSE_UPDATE,
  API_LIST_ALL_BANK_BY_CHANNEL,
  API_LIST_CHANNEL,
  API_LIST_CHANNEL_ALL,
  API_MY_ASSETS_CHANNEL,
} from "@network/URL";

export const getListChannel = (body?: any) => {
  return API.post(API_LIST_CHANNEL, body);
};

export const getAllChannel = (body?: any) => {
  return API.post(API_LIST_CHANNEL_ALL, body);
};

export const listMyAssetsChannel = (body: any, paging?: any) => {
  return API.post(
    `${API_MY_ASSETS_CHANNEL}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
    body
  );
};

export const getChannelList = (body?: any, paging?: any) => {
  return API.post(
    `${API_CHANNEL_LIST}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
    body
  );
};

export const getChannelDetail = (id: string) => {
  return API.post(API_CHANNEL_DETAIL, { id });
};

export const channelUpdate = (body: any) => {
  return API.post(API_CHANNEL_UPDATE, body);
};

export const channelCreate = (body: any) => {
  return API.post(API_CHANNEL_CREATE, body);
};

export const channelWarehouses = (body: any, paging: any) => {
  return API.post(
    `${API_CHANNEL_WAREHOUSE}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
    body
  );
};

export const channelWarehouseUpdate = (body: any) => {
  return API.post(API_CHANNEL_WAREHOUSE_UPDATE, body);
};

export const channelUsers = (body: any, paging: any) => {
  return API.post(
    `${API_CHANNEL_USER}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
    body
  );
};

export const channelUsersSummary = (body: any) => {
  return API.post(API_CHANNEL_USER_SUMMARY, body);
};

export const channelUserUpdate = (body: any) => {
  return API.post(API_CHANNEL_USER_UPDATE, body);
};

export const channelUserLoad = (body: any, paging: any) => {
  return API.post(
    `${API_CHANNEL_USER_LOAD}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
    body
  );
};

const channelActivityList = (id: string, pageIndex: any) => {
  return API.post(`${API_CHANNEL_ACTIVITY_LIST}?page=${pageIndex}`, {
    id,
  });
};
const channelActivityAdd = (body: any) => {
  return API.post(API_CHANNEL_ACTIVITY_ADD, body);
};

const channelBankList = (body: any) => {
  return API.post(API_LIST_ALL_BANK_BY_CHANNEL, body);
};

const ChannelAPI = {
  getListChannel,
  getAllChannel,
  listMyAssetsChannel,

  //channel management
  list: getChannelList,
  detail: getChannelDetail,
  update: channelUpdate,
  create: channelCreate,

  warehouseList: channelWarehouses,
  warehouseUpdate: channelWarehouseUpdate,
  userList: channelUsers,
  userSummary: channelUsersSummary,
  userUpdate: channelUserUpdate,
  userLoad: channelUserLoad,
  activities: channelActivityList,
  activityAdd: channelActivityAdd,
  bankList: channelBankList,
};

export default ChannelAPI;
