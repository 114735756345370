import Drawer from "@components/shared/Drawer";
import CartRuleAPI from "@network/api/cart-rule";
import classNames from "classnames";
import { AwesomeTableComponent, Button, TimeUtils } from "d-react-components";
import { map } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const CartRuleDetailSyncLog = () => {
  const [showLog, setShowLog] = useState<any>(false);
  const { t } = useTranslation();
  const { cartRuleId } = useParams();

  const columns = [
    {
      title: t("date"),
      dataIndex: "created",
      render: TimeUtils.toDateTime,
    },
    {
      title: t("toChannel"),
      dataIndex: "website",
      render: (website: any) => website?.name,
    },
    {
      title: t("syncStatus"),
      dataIndex: "status",
      render: (status: string) => (
        <text
          className={classNames("text-capitalize", {
            "text-danger": status === "failed",
            "text-success": status !== "failed",
          })}
        >
          {status}
        </text>
      ),
    },

    {
      title: t("error"),
      dataIndex: "message",
      render: (message: string) => (
        <div className="text-max-line-2">{message}</div>
      ),
    },
    {
      title: t("log"),
      dataIndex: "errors",
      width: 75,
      render: (errors: any) => (
        <Button
          iconName="visibility"
          variant="trans"
          onClick={() => setShowLog(errors)}
          disabled={errors?.length === 0}
        />
      ),
    },
  ];

  const source = (paging: any) => {
    const body = { cart_rule_id: cartRuleId };
    return CartRuleAPI.getFreeGiftSyncLog(body, paging);
  };

  const transformer = (response: any) => {
    const logList = response?.data?.data?.logs ?? [];
    return logList;
  };

  return (
    <div className="border mt-3 p-4">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h5>{t("syncLog")}</h5>
      </div>
      <AwesomeTableComponent
        source={source}
        transformer={transformer}
        columns={columns}
        isScroll={false}
        tableLayout="fixed"
        className="mt-3"
      />
      {!!showLog && (
        <Drawer
          title={t("log")}
          open={!!showLog}
          onClose={() => setShowLog(false)}
        >
          <div className="flex-column">
            {map(showLog, (item) => (
              <div className="mt-3">{item?.message}</div>
            ))}
          </div>
        </Drawer>
      )}
    </div>
  );
};

export default CartRuleDetailSyncLog;
