import { CART_RULE_ACTION, CART_RULE_ACTIONS } from "@constants/cart-rule";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import CartRuleActionCondition from "../create/CartRuleActionCondition";
import BuyXGetXActions from "./actions/BuyXGetXActions";
import BuyNXGetDiscountActions from "./actions/BuyNXGetDiscountActions";
import DiscountAmountActions from "./actions/DiscountAmountActions";
import DiscountAmountCartActions from "./actions/DiscountAmountCartActions";
import SelectSpecificAction from "./actions/SelectSpecificAction";
import AppContext from "@helpers/context";

interface ICartRuleActionForm {
  form: any;
  isHideCondition?: boolean;
  cartRuleActions?: any[];
  disabledSpecificAction?: boolean;
}

const DEFAULT_BUNDLE = {
  key: 1,
  products: [],
  title: "",
  id: null,
};

const defaultDiscountPer = {
  specific: CART_RULE_ACTION.DISCOUNT_PERCENTAGE,
  condition_type: "all",
  discount_percentage: {
    percentage: 0,
    recursive: false,
    max: 0,
  },
};
const defaultDiscountFix = {
  specific: CART_RULE_ACTION.DISCOUNT_FIX_AMOUNT,
  condition_type: "all",
  discount_fix_amount: {
    amount: 0,
    recursive: false,
    max: 0,
  },
};
const defaultDiscountFixCart = {
  specific: CART_RULE_ACTION.DISCOUNT_FIX_AMOUNT_CART,
  condition_type: "all",
  discount_fix_amount_cart: {
    sequentially: false,
    amount: 0,
  },
};

const defaultDiscountPerCart = {
  specific: CART_RULE_ACTION.DISCOUNT_PERCENTAGE_CART,
  condition_type: "all",
  discount_percentage_cart: {
    sequentially: false,
    percentage: 0,
  },
};

const defaultBuyXGetX = {
  specific: CART_RULE_ACTION.BUY_X_GET_X,
  condition_type: "all",
  buy_x_get_x: {
    recursive: true,
    buy: 1,
    free: 1,
    range: [],
  },
};
const defaultBuyXGetY = {
  // specific: buyXGetY,
  condition_type: "all",
  buy_x_get_y: {
    recursive: true,
    buy: 0,
    free: 0,
    range: [],
    product: [],
  },
};

const defaultBuyBundleGetBundle = {
  specific: CART_RULE_ACTION.FREE_GIFT,
  condition_type: "all",
  free_gift: {
    recursive: true,
    buy: 1,
    free: 1,
    range: [],
    gift: [],
  },
};
const defaultBuyXGetBundle = {
  specific: CART_RULE_ACTION.BUY_X_GET_BUNDLE,
  condition_type: "all",
  buy_x_get_bundle: {
    recursive: true,
    buy: 1,
    free: 1,
    range: [],
    gift: [],
  },
};
const defaultBuyNXGetDiscountPercentage = {
  specific: CART_RULE_ACTION.BUY_X_GET_DISCOUNT_PERCENTAGE,
  condition_type: "all",
  buy_x_get_discount_percentage: {
    recursive: true,
    buy: 1,
    amount: 0,
    minQuantity: 1,
    maxQuantity: 1,
  },
};
const defaultBuyNXGetDiscountPercentageOnY = {
  specific: CART_RULE_ACTION.BUY_X_GET_DISCOUNT_PERCENTAGE_Y,
  condition_type: "all",
  buy_x_get_discount_percentage_y: {
    recursive: true,
    buy: 1,
    amount: 0,
    minQuantity: 1,
    maxQuantity: 1,
    product: [],
  },
};
const defaultBuyNXGetDiscountFixAmount = {
  specific: CART_RULE_ACTION.BUY_X_GET_DISCOUNT_FIX_AMOUNT,
  condition_type: "all",
  buy_x_get_discount_fix_amount: {
    recursive: true,
    buy: 1,
    amount: 0,
    minQuantity: 1,
    maxQuantity: 1,
  },
};
const defaultBuyNXGetDiscountFixAmountOnY = {
  specific: CART_RULE_ACTION.BUY_X_GET_DISCOUNT_FIX_AMOUNT_Y,
  condition_type: "all",
  buy_x_get_discount_fix_amount_y: {
    recursive: true,
    buy: 1,
    amount: 0,
    minQuantity: 1,
    maxQuantity: 1,
    product: [],
  },
};

const CartRuleActionForm = ({
  form,
  isHideCondition,
  cartRuleActions = CART_RULE_ACTIONS,
  disabledSpecificAction,
}: ICartRuleActionForm) => {
  const { t } = useTranslation();
  const formValues = form?.values ?? {};

  const hideCondition = useMemo(() => {
    if (isHideCondition) {
      return true;
    }

    if (formValues?.actions?.specific === defaultBuyBundleGetBundle.specific) {
      return true;
    }
    return false;
  }, [formValues?.actions]);

  const handleSelectAction = (value: any) => {
    switch (value) {
      case CART_RULE_ACTION.FREE_GIFT:
        form.setFieldValue("actions", defaultBuyBundleGetBundle);
        break;
      case CART_RULE_ACTION.BUY_X_GET_BUNDLE:
        form.setFieldValue("actions", defaultBuyXGetBundle);
        break;
      case CART_RULE_ACTION.BUY_X_GET_DISCOUNT_PERCENTAGE:
        form.setFieldValue("actions", defaultBuyNXGetDiscountPercentage);
        break;
      case CART_RULE_ACTION.BUY_X_GET_DISCOUNT_PERCENTAGE_Y:
        form.setFieldValue("actions", defaultBuyNXGetDiscountPercentageOnY);
        break;
      case CART_RULE_ACTION.BUY_X_GET_DISCOUNT_FIX_AMOUNT:
        form.setFieldValue("actions", defaultBuyNXGetDiscountFixAmount);
        break;
      case CART_RULE_ACTION.BUY_X_GET_DISCOUNT_FIX_AMOUNT_Y:
        form.setFieldValue("actions", defaultBuyNXGetDiscountFixAmountOnY);
        break;
      case CART_RULE_ACTION.DISCOUNT_PERCENTAGE:
        form.setFieldValue("actions", defaultDiscountPer);
        break;
      case CART_RULE_ACTION.DISCOUNT_FIX_AMOUNT:
        form.setFieldValue("actions", defaultDiscountFix);
        break;
      case CART_RULE_ACTION.BUY_X_GET_X:
        form.setFieldValue("actions", defaultBuyXGetX);
        break;
      case CART_RULE_ACTION.DISCOUNT_FIX_AMOUNT_CART:
        form.setFieldValue("actions", defaultDiscountFixCart);
        break;
      case CART_RULE_ACTION.DISCOUNT_PERCENTAGE_CART:
        form.setFieldValue("actions", defaultDiscountPerCart);
        break;
      // case buyXGetY:
      //   form.setFieldValue("actions", defaultBuyXGetY);
      //   break;
      default:
    }
  };
  const renderActionContent = () => {
    const { actions } = formValues;
    const setChangeData = form.setFieldValue;
    switch (actions?.specific) {
      case CART_RULE_ACTION.FREE_GIFT:
        return (
          <BuyXGetXActions
            cartRuleActions={cartRuleActions}
            actions={actions}
            handleSelectAction={handleSelectAction}
            setChangeData={setChangeData}
            isShowBundleCondition
            isShowFreeProduct
          />
        );
      case CART_RULE_ACTION.BUY_X_GET_BUNDLE:
        return (
          <BuyXGetXActions
            cartRuleActions={cartRuleActions}
            actions={actions}
            handleSelectAction={handleSelectAction}
            setChangeData={setChangeData}
            isShowFreeProduct
          />
        );
      case CART_RULE_ACTION.BUY_X_GET_DISCOUNT_PERCENTAGE:
      case CART_RULE_ACTION.BUY_X_GET_DISCOUNT_FIX_AMOUNT:
        return (
          <BuyNXGetDiscountActions
            cartRuleActions={cartRuleActions}
            actions={actions}
            handleSelectAction={handleSelectAction}
            setChangeData={setChangeData}
          />
        );
      case CART_RULE_ACTION.BUY_X_GET_DISCOUNT_PERCENTAGE_Y:
      case CART_RULE_ACTION.BUY_X_GET_DISCOUNT_FIX_AMOUNT_Y:
        return (
          <BuyNXGetDiscountActions
            cartRuleActions={cartRuleActions}
            actions={actions}
            handleSelectAction={handleSelectAction}
            setChangeData={setChangeData}
            showFreeItemsY
          />
        );
      case CART_RULE_ACTION.DISCOUNT_FIX_AMOUNT:
      case CART_RULE_ACTION.DISCOUNT_PERCENTAGE:
        return (
          <DiscountAmountActions
            cartRuleActions={cartRuleActions}
            actions={actions}
            handleSelectAction={handleSelectAction}
            setChangeData={setChangeData}
          />
        );

      case CART_RULE_ACTION.BUY_X_GET_X:
        // case buyXGetY:
        return (
          <BuyXGetXActions
            cartRuleActions={cartRuleActions}
            actions={actions}
            handleSelectAction={handleSelectAction}
            setChangeData={setChangeData}
          />
        );
      case CART_RULE_ACTION.DISCOUNT_FIX_AMOUNT_CART:
      case CART_RULE_ACTION.DISCOUNT_PERCENTAGE_CART:
        // case buyXGetY:
        return (
          <DiscountAmountCartActions
            cartRuleActions={cartRuleActions}
            actions={actions}
            handleSelectAction={handleSelectAction}
            setChangeData={setChangeData}
          />
        );

      default:
        return (
          <SelectSpecificAction
            cartRuleActions={cartRuleActions}
            actions={actions}
            handleSelectAction={handleSelectAction}
          />
        );
    }
  };

  return (
    <AppContext.Provider value={{ cartRuleForm: form, disabledSpecificAction }}>
      {renderActionContent()}
      {!hideCondition && <CartRuleActionCondition />}
    </AppContext.Provider>
  );
};

export default CartRuleActionForm;
