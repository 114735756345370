export enum DELIVERY_STATUS {
  PENDING = "pending",
  BOOKING = "booking",
  IN_DELIVERY = "in-delivery",
  COMPLETED = "completed",
  CANCELLED = "cancel",
}

export const DELIVERY_STATUS_LIST = [
  { id: "pending", label: "deliveryPending", color: "#F2C94C " },
  { id: "booking", label: "deliveryBooked", color: "#2196F3 " },
  { id: "in-delivery", label: "inDelivery", color: "#F2994A " },
  {
    id: "completed",
    label: "deliveryCompleted",
    color: "#27AE60",
  },
  { id: "cancel", label: "deliveryCancelled", color: "#F44336" },
];

export enum DELIVERY_STOCK_STATUS {
  PENDING = "pending",
  BOOKING = "booking",
  IN_DELIVERY = "in-delivery",
  COMPLETED = "completed",
  CANCELLED = "cancelled",
  FAILED = "failed",
  PARTIALLY = "partially",
}

export const DELIVERY_STOCK_STATUS_LIST = [
  { id: "pending", label: "deliveryPending", color: "#F2C94C " },
  { id: "booking", label: "deliveryBooked", color: "#2196F3 " },
  { id: "in-delivery", label: "inDelivery", color: "#F2994A " },
  {
    id: "completed",
    label: "deliveryCompleted",
    color: "#27AE60",
  },
  { id: "cancelled", label: "deliveryCancelled", color: "#F44336" },
  { id: "failed", label: "deliveryFail", color: "#dadada" },
  { id: "partially", label: "deliveryPartly", color: "#2166F6" },
];

export const DELIVERY_STOCK_TYPES = [
  { id: "in-house-truck", label: "inHouseTruck" },
  { id: "outsourced-truck", label: "outSourceTruck" },
];

export const DELIVERY_STOCK_TYPE = {
  IN_HOUSE: "in-house-truck",
  OUTSOURCE: "outsourced-truck",
};

export const DELIVERY_RETURN_STATUS = {
  // NONE: "none",
  REQUESTED: "requested",
  REJECTED: "rejected",
  COMPLETED: "completed",
  CANCELLED: "cancelled",
  APPROVED: "approved",
};

export const DELIVERY_RETURN_STATUS_LIST = [
  // { id: "none", label: "none", color: "#F2C94C" },
  { id: "requested", label: "returnDeliveryRequested", color: "#FAC256" },
  { id: "completed", label: "returnDeliveryCompleted", color: "#33B950" },
  { id: "approved", label: "returnDeliveryApproved", color: "#33B950" },
  { id: "cancelled", label: "returnDeliveryCancelled", color: "#ED6969" },
  { id: "rejected", label: "returnDeliveryRejected", color: "#ED6969" },
];

export const DELIVERY_ORIGIN_STATUS_LIST = [
  { id: "pending", label: "pending", color: "#FAC256" },
  { id: "in-process", label: "inProcess", color: "#2166F6" },
  { id: "completed", label: "completed", color: "#33B950" },
];
