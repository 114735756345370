import { DELIVERY_STATUS } from "@constants/delivery";
import { ORDER_STATUS } from "@constants/order";
import { PAYMENT_COD, PAYMENT_CONFIRM_STATUS } from "@constants/payment";
import AppContext from "@helpers/context";
import { isGrantURLPermission } from "@helpers/permissions";
import { API_UPLOAD_PAYMENT_CUSTOMER } from "@network/URL";
import { Tooltip } from "antd";
import { Button, Icon, TimeUtils } from "d-react-components";
import { every } from "lodash";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Carousel, { Modal, ModalGateway } from "react-images";

const UploadPaymentInfoView = ({
  payment,
  paymentInfoList,
  onClickUploadButton,
  renderTooltip,
  imgClass = "w-32 h-32",
}: any) => {
  const { status, method } = payment;
  const { order, editable, isPublic, orderHasCod, delivery } =
    useContext(AppContext);
  const { t } = useTranslation();
  const [modalImageView, setModalImageView] = useState({
    isVisible: false,
    index: 0,
  });
  const isShowUploadButton = () => {
    // Quyet said: only use the key has_allowed_payment from FO
    // if (!order?.has_allowed_payment) return false;
    const isAllDeliveryCompleted =
      delivery.length > 0 &&
      every(delivery, (deliveryItem: any) => {
        return deliveryItem.status === DELIVERY_STATUS.COMPLETED;
      });

    return (
      !isPublic &&
      status !== PAYMENT_CONFIRM_STATUS.APPROVE &&
      isGrantURLPermission(API_UPLOAD_PAYMENT_CUSTOMER) &&
      order?.status !== ORDER_STATUS.cancelled.value &&
      (orderHasCod && method === PAYMENT_COD ? isAllDeliveryCompleted : true)
    );
  };
  return (
    <div>
      <div className="banktranferImageContainer mt-2 w-full flex whitespace-nowrap overflow-x-auto py-2">
        {paymentInfoList?.map((file: any, index: number) => {
          const tooltip = renderTooltip && renderTooltip(file);
          return (
            <div
              className="wrapImagePayment mr-1 relative cursor-pointer"
              onClick={() => {
                setModalImageView({ isVisible: true, index });
              }}
            >
              <img
                className={`fileUpload rounded object-contain bg-black m-1 ml-0 ${imgClass}`}
                src={file.imageurl}
                alt=""
              />

              {tooltip && (
                <Tooltip
                  title={
                    <div className="containerTooltip bg-white p-2">
                      {tooltip}
                    </div>
                  }
                  overlayClassName="containerTooltip bg-white p-2"
                >
                  <Icon name="info" className="absolute -top-1 -right-1" />
                </Tooltip>
              )}
            </div>
          );
        })}
      </div>

      {isShowUploadButton() && editable && (
        <Button
          iconName="cloud_upload"
          onClick={onClickUploadButton}
          color="dark"
          variant="outline"
          className="mb-3"
        >
          {t("upload")}
        </Button>
      )}

      {/* @ts-ignore */}
      <ModalGateway>
        {modalImageView.isVisible ? (
          //  @ts-ignore
          <Modal onClose={() => setModalImageView({ isVisible: false })}>
            {/* @ts-ignore  */}
            <Carousel
              currentIndex={modalImageView.index}
              views={paymentInfoList?.map((file: any) => ({
                caption: `${t("by")} ${file?.by?.name ?? " "}${t(
                  "at"
                )} ${TimeUtils.convertMiliToDateTime(file.created)}`,
                src: file.imageurl,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};

export default UploadPaymentInfoView;
