import {
  DialogComponent,
  DialogManager,
  Progress,
  ProgressComponent,
} from "d-react-components";
import { forEach, isArray, isEmpty } from "lodash";
import { Component } from "react";

class InitComponent extends Component<any> {
  progressRef: any;

  dialogRef: any;

  constructor(props: any) {
    super(props);
    this.progressRef = null;
    this.dialogRef = null;
  }

  componentDidMount() {
    Progress.initialProgress(this.progressRef);
    DialogManager.initialDialog(this.dialogRef);
  }

  progressTransformError = (error: any) => {
    const { graphQLErrors = [], message } = error;
    let errorView: string = "";
    if (!isEmpty(message)) {
      errorView += `${message}<br>`;
    }
    forEach(graphQLErrors, (error) => {
      if (isArray(error.states)) {
        forEach(error?.states ?? [], (item) => {
          errorView += `${item?.message}<br>`;
        });
      } else {
        errorView += `${error?.states?.message}<br>`;
      }
    });

    return errorView;
  };

  render() {
    return (
      <div>
        <ProgressComponent
          ref={(ref) => {
            this.progressRef = ref;
          }}
          //   transformError={this.progressTransformError}
        />
        <DialogComponent
          ref={(ref) => {
            this.dialogRef = ref;
          }}
        />
      </div>
    );
  }
}

export default InitComponent;
