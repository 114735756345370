import { Path } from "@components/layout/Path";
import UserAvatarName from "@components/shared/UserAvatarName";
import AppContext from "@helpers/context";
import {
  AwesomeTableComponent,
  HeaderTable,
  IColumnsProps,
  TimeUtils,
} from "d-react-components";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";

const TransferStockDetailStockRequest = () => {
  const { transferDetail } = useContext(AppContext);
  const { t } = useTranslation();

  const columns: IColumnsProps = [
    {
      title: t("ref"),
      dataIndex: "ref",
      render: (ref: string, delivery) => (
        <Link to={generatePath(Path.STOCK_REQUEST)}>{ref}</Link>
      ),
    },
    {
      title: t("createdBy"),
      dataIndex: "by",
      render: (by) => <UserAvatarName user={by} />,
    },
    {
      title: t("createdAt"),
      dataIndex: "created",
      render: TimeUtils.toDateTime,
    },
  ];

  return (
    <div className="card-container p-4 ">
      <AwesomeTableComponent
        columns={columns}
        dataSource={transferDetail?.request_stock ?? []}
        className="mt-3 height-fit-content"
        pagination={false}
      />
    </div>
  );
};

export default TransferStockDetailStockRequest;
