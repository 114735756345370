import ProductName from "@components/product/components/ProductName";
import QuantityInputField from "@components/product/components/QuantityInputField";
import { OrderType } from "@constants/common";
import { calculateDefaultExpandedRowKeys } from "@helpers/product";
import { getStockProductWarehouse } from "@network/api/product";
import { AwesomeTableComponent, Notifications } from "d-react-components";
import { find } from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

const TableItemDelivery = ({
  onChange,
  products = [],
  disabled = false,
  transferDelivery = false,
  selectedWarehouse,
  orderType = OrderType.NORMAL,
  hasCod = false,
}: any) => {
  const defaultProductDelivery = hasCod
    ? products.map((item: any) => ({
        ...item,
        stock: item.quantity,
        leftItemCount: 0,
      }))
    : products;
  const [productDelivery, setProductDelivery] = useState(
    defaultProductDelivery
  );
  const deliveryItemList = useRef<any>(null);
  const { t } = useTranslation();

  useEffect(() => {
    deliveryItemList && deliveryItemList.current.refreshData();
    onChange && onChange(productDelivery);
  }, [productDelivery]);

  useEffect(() => {
    if (!selectedWarehouse || !selectedWarehouse?.id) return;
    onLoadStockInformation();
  }, [selectedWarehouse]);

  const onLoadStockInformation = () => {
    const body = {
      product_id: products?.map((item: any) => item?.product?.id),
      warehouse_id: selectedWarehouse?.id,
    };
    getStockProductWarehouse(body).then((res) => {
      const stockList = res?.data?.data?.stock ?? [];

      const productDeliveryResult = productDelivery?.map((productItem: any) => {
        const stockItem = find(
          stockList,
          (item) => item?.product?.id === productItem?.product?.id
        );
        if (stockItem?.product) {
          return {
            ...productItem,
            stockHold: stockItem?.hold,
            stockWarehouse: stockItem?.stock,
          };
        }
        return productItem;
      });
      setProductDelivery(productDeliveryResult);
    });
  };

  const onChangeQuantitySubPro = useCallback(
    (parentPro, childPro, quantity) => {
      if (disabled) {
        return;
      }
      if (quantity > childPro.quantity || quantity < 0) {
        return;
      }
      if (quantity > childPro.stock && childPro.leftItemCount === 0) {
        Notifications.showError(t("notification:noLeftItem"));
        return;
      }
      const productResult = productDelivery?.map((itemParam: any) => {
        if (parentPro.id === itemParam.id) {
          const { groups } = itemParam;
          const groupResult = setChangeQuantityInProducts(
            groups,
            childPro,
            quantity
          );

          return {
            ...itemParam,
            groups: groupResult,
          };
        }
        return itemParam;
      });
      setProductDelivery(productResult);
    },
    [disabled, productDelivery, t]
  );

  const onChangeQuantityPickup = useCallback(
    (quantity, item) => {
      if (disabled) {
        return;
      }
      if (quantity > item.quantity || quantity < 0) {
        return;
      }
      if (quantity > item.stock && item.leftItemCount === 0) {
        Notifications.showError(t("notification:noLeftItem"));
        return;
      }
      const productResult = setChangeQuantityInProducts(
        productDelivery,
        item,
        quantity
      );

      setProductDelivery(productResult);
    },
    [disabled, productDelivery, t]
  );

  const setChangeQuantityInProducts = (
    products: any,
    productItem: any,
    quantity: any
  ) => {
    const list = products?.map((itemParam: any) => {
      if (productItem.id === itemParam.id) {
        return {
          ...itemParam,
          stock: quantity,
          leftItemCount: itemParam.leftItemCount + itemParam.stock - quantity,
        };
      }
      return itemParam;
    });
    return list;
  };

  let columns = useMemo(
    () => [
      {
        title: t("itemNameTable"),
        dataIndex: "product",
        render: (product: any, item: any) => {
          const isNoneItem = item.leftItemCount === 0;
          let subBody = <div />;
          if (!item.groups || item.groups.length === 0) {
            subBody = (
              <div
                className={
                  isNoneItem
                    ? "text-xs text-green-500"
                    : "text-xs text-orange-500"
                }
              >
                {item.leftItemCount + t("willBeLeft")}
              </div>
            );
          }
          return <ProductName item={item} subBody={subBody} />;
        },
        // ellipsis: true,
        width: 500,
      },
      {
        title: t("orderQty"),
        dataIndex: "quantity",
        render: (quantity: any) => quantity,
      },
      ...(transferDelivery
        ? []
        : [
            {
              title: t("product:availableQtyCurrentStore"),
              dataIndex: "stockWarehouse",
              titleTooltip: t("product:availableQtyCurrentStoreTooltip"),
              render: (stockWarehouse: any) => stockWarehouse,
            },
          ]),
      ...(transferDelivery
        ? []
        : [
            {
              title: t("product:onHoldQtyCurrentStore"),
              dataIndex: "stockHold",
              titleTooltip: t("product:onHoldQtyCurrentStoreTooltip"),
              render: (stockHold: any) => stockHold,
            },
          ]),
      {
        title: t("shipQty"),
        dataIndex: "stock",
        width: disabled ? 70 : 200,
        render: (stock, item) => {
          const isGroupProduct = item?.groups?.length > 0;
          const parentPro = item?.parentPro;
          const isSubProductTable = !!parentPro;
          let quantityView = (
            <QuantityInputField
              quantity={stock}
              onChangeQuantity={(quantity: any) =>
                isSubProductTable
                  ? onChangeQuantitySubPro(parentPro, item, quantity)
                  : onChangeQuantityPickup(quantity, item)
              }
            />
          );
          if (disabled) {
            quantityView = stock || item.quantity;
          }
          return isGroupProduct ? <div /> : quantityView;
        },
      },
    ],
    [
      t,
      disabled,
      onChangeQuantityPickup,
      onChangeQuantitySubPro,
      transferDelivery,
    ]
  );

  const source = () => {
    return Promise.resolve(productDelivery);
  };

  const transformer = (products: any) => {
    return products?.map((product: any = {}) => {
      let { groups = [] } = product;
      if (groups?.length > 0) {
        groups = groups?.map((item: any) => ({
          ...item,
          parentPro: product,
        }));
      }
      if (groups?.length > 0) {
        return {
          ...product,
          children: groups,
        };
      }
      return product;
    });
  };

  return (
    <AwesomeTableComponent
      className="height-auto"
      baseColumnProps={{ width: 150, align: "center" }}
      ref={(ref) => (deliveryItemList.current = ref)}
      source={() => source()}
      transformer={transformer}
      columns={columns}
      isScroll={false}
      isPagination={false}
      classNameTable="tableItemDelivery"
      expandable={{
        rowExpandable: (product) => product?.groups?.length > 0,
        expandedRowKeys: calculateDefaultExpandedRowKeys(productDelivery, {}),
        // defaultExpandedRowKeys: true,
      }}
      rowClassName={(product) => {
        let rowClassName = "rowAwesomeTable ";
        if (product.isGift) {
          rowClassName += "productGiftTableItemRow";
        }
        return rowClassName;
      }}
    />
  );
};

export default TableItemDelivery;
