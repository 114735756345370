import ChannelSelect from "@components/channel/components/ChannelSelect";
import ProductCategorySelect from "@components/product-category/components/ProductCategorySelect";
import Drawer from "@components/shared/Drawer";
import { exportToCSV } from "@helpers/file";
import { productionCostTable } from "@network/api/product";
import { Progress } from "antd";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

const ExportDrawer = ({ open, onClose }: any) => {
  const { t } = useTranslation();
  const [isVisibleProgress, setVisibleProgress] = useState(false);
  const [percentageProgress, setPercentageProgress] = useState(0);
  const filterForm = useFormik({
    initialValues: { channel: undefined, category: undefined },
    validateOnChange: false,
    validateOnBlur: false,

    validationSchema: Yup.object().shape({
      channel: Yup.mixed().required(t("fieldRequired")),
    }),
    onSubmit: (values) => {
      onClickExport();
    },
  });
  const { values, errors, setFieldValue, handleSubmit } = filterForm;

  const onClickExport = async () => {
    setVisibleProgress(true);
    setPercentageProgress(0);
    try {
      const { channel, category } = values as any;

      const body = {
        channel_id: channel?.id,
        category: category?.map((cat: any) => cat?.id),
      };
      await onExport(body);
    } catch (err) {
      setVisibleProgress(false);
      onClose();
    }
  }

  const convertToExportData = (productList = []) => {
    return productList.map((item: any) => ({
      [t("sku")]: item?.sku ?? "",
      [t("itemName")]: item?.name ?? "",
      [t("category")]: item?.category?.name ?? "",
      [t("productionCost")]: item?.production_cost ?? "",
      [t("saleExpense")]: item?.sale_expense ?? "",
      [t("adminExpense")]: item?.admin_expense ?? "",
    }));
  };

  const onExport = async (body: any) => {
    let remainPage = true;
    let productListResult: any = [];
    let pageIndex = 1;
    while (remainPage) {
      const response = await productionCostTable(body, {
        pageIndex,
        pageSize: 200,
      });
      const productList = response?.data?.data?.product ?? [];
      const paginationData = response?.data?.data?.pagination ?? {};
      const { currentPage, total } = paginationData;
      productListResult = productListResult.concat(productList);
      if (currentPage === total) {
        remainPage = false;
        setPercentageProgress(100);
      } else {
        pageIndex++;
        setPercentageProgress(Math.round((currentPage / total) * 100));
      }
    }

    const fileDataInventory = convertToExportData(productListResult);

    exportToCSV(fileDataInventory, "ProductionCostListExport");
    setVisibleProgress(false);
    onClose();
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      onSave={handleSubmit}
      title={t("export")}
      saveText={t("export")}
      size="auto"
      width="500px"
    >
      {isVisibleProgress ? (
        <div className="flex-center">
          <Progress className="w-100" percent={percentageProgress} />
        </div>
      ) : (
        <>
          <ChannelSelect
            value={values.channel}
            onChange={(value) => setFieldValue("channel", value)}
            className="mb-3"
            error={errors.channel}
          />
          <ProductCategorySelect
            value={values.category}
            onChange={(value) => setFieldValue("category", value)}
            multiple
          />
        </>
      )}
    </Drawer>
  );
};

export default ExportDrawer;
