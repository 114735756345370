import Icon from "@common/Icon";
import Drawer from "@components/shared/Drawer";
import AppContext from "@helpers/context";
import {
  IRowsKey,
  Notifications,
  Progress,
  StringUtils,
  TimeUtils,
  ViewRowInterchange,
} from "d-react-components";
import { useFormik } from "formik";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import InputSelectForm from "@common/input/InputSelectForm";
import InputTextForm from "@common/input/InputTextForm";
import RawMaterialAPI from "@network/api/raw-material";
import { Switch } from "antd";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import RichTextEditor from "react-rte";

interface IGeneralUpdateDrawer {
  open: boolean;
  defaultValue?: any;
  onUpdated: any;
  onClose: () => void;
}

const DetailGeneralUpdateDrawer = ({
  open,
  defaultValue,
  onUpdated,
  onClose,
}: IGeneralUpdateDrawer) => {
  const { t } = useTranslation();
  const { rawMaterialId } = useParams();
  const [descriptionHtml, setDescriptionHtml] = useState(
    RichTextEditor.createValueFromString(
      defaultValue?.description ?? "",
      "html"
    )
  );
  const [categoryList, setCategoryList] = useState([]);
  const [supplierList, setSupplierList] = useState([]);

  const isOnchange = useRef(false);
  useEffect(() => {
    if (!isOnchange.current) {
      setDescriptionHtml(
        RichTextEditor.createValueFromString(
          defaultValue?.description ?? "",
          "html"
        )
      );
    }
  }, [defaultValue?.description]);

  useEffect(() => {
    loadCategoryList();
    loadSupplierList();
  }, []);

  const loadCategoryList = () => {
    RawMaterialAPI.categoryList().then((res) => {
      const materialCategoryList = res?.data?.data?.categories ?? [];
      setCategoryList(materialCategoryList);
    });
  };

  const loadSupplierList = () => {
    RawMaterialAPI.supplierList().then((res) => {
      const results = res?.data?.data?.supplier ?? [];
      setSupplierList(results);
    });
  };

  const generalForm = useFormik<any>({
    initialValues: defaultValue as any,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: schema,
    onSubmit: (values: any) => {
      onUpdate(values);
    },
  });
  const formValues = generalForm?.values;
  const formErrors = generalForm?.errors;

  const onUpdate = (values: any) => {
    const {
      title,
      description,
      visibility,
      category,
      remark,
      supplier,
    } = values;

    const body: any = {
      id: rawMaterialId,
      title,
      description,
      categories_id: category,
      supplier_id: supplier,
      visibility,
      remark,
    };

    Progress.show(
      { method: RawMaterialAPI.update, params: [body] },
      (res: any) => {
        Notifications.showSuccess(t("updateSuccess"));
        onUpdated();
        onClose();
      }
    );
  };
  const classNameField = "col-span-4";

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("general")}
      onSave={() => {
        generalForm.handleSubmit();
      }}
      size="auto"
      width="600"
    >
      <div className={classNames("grid grid-cols-4 gap-4 ")}>
        <InputTextForm
          keyData="title"
          form={generalForm}
          className={classNameField}
        />
        <div className={classNameField}>
          <label className="mb-3">{`${t("description")} `}</label>
          <RichTextEditor
            value={descriptionHtml}
            onChange={(value) => {
              isOnchange.current = true;
              setDescriptionHtml(value);
              generalForm.setFieldValue("description", value.toString("html"));
            }}
            className="richTextEditor"
          />
        </div>
        <div className={classNames(classNameField)}>
          <label className="mr-3">{t("visibility")}</label>
          <Switch
            checked={formValues.visibility}
            onChange={() =>
              generalForm.setFieldValue("visibility", !formValues.visibility)
            }
          />
        </div>
        <InputSelectForm
          className={classNameField}
          form={generalForm}
          keyData="category"
          dataSource={categoryList}
          getLabel={(item: any) => item.name}
        />
        <InputSelectForm
          className={classNameField}
          form={generalForm}
          keyData="supplier"
          dataSource={supplierList}
          getLabel={(item: any) => item.name}
        />

        <InputTextForm
          keyData="remark"
          multiple
          form={generalForm}
          className={classNameField}
        />
      </div>
    </Drawer>
  );
};

const RawMaterialDetailGeneral = () => {
  const { rawMaterialDetail, loadRawMaterialDetail } = useContext(AppContext);
  const [openEdit, setOpenEdit] = useState(false);
  const { t } = useTranslation();

  const DETAILS_KEY = useMemo<IRowsKey<any>[]>(
    () => [
      {
        id: "sku",
        label: t("sku"),
      },
      {
        id: "visibility",
        label: t("visibility"),
        renderContent: ({ data, item }) => {
          return data ? t("yes") : t("no");
        },
      },
      {
        id: "description",
        label: t("description"),
      },
      {
        id: "categories",
        label: t("category"),
        renderContent: ({ data, item }) => {
          return data?.name ?? "N/A";
        },
      },
      {
        id: "supplier",
        label: t("supplier"),
        renderContent: ({ data, item }) => {
          return data?.name ?? "N/A";
        },
      },

      // {
      //   id: "price",
      //   label: t("price"),
      //   renderContent: ({ data, item }) => StringUtils.moneyThaiFormat(data),
      // },
      {
        id: "remark",
        label: t("remark"),
      },
      {
        id: "updated",
        label: t("updatedAt"),
        renderContent: ({ data, item }) =>
          data ? TimeUtils.toDateTime(data) : "N/A",
      },
      {
        id: "created",
        label: t("createdAt"),
        renderContent: ({ data, item }) =>
          data ? TimeUtils.toDateTime(data) : "N/A",
      },
    ],
    [t]
  );

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mt-3">
        <h5>{t("general")}</h5>
        <span onClick={() => setOpenEdit(true)}>
          <Icon
            className="w-[15px] cursor-pointer"
            src="/images/icons/edit.svg"
          />
        </span>
      </div>
      <div className="mt-3 border p-4 ">
        <ViewRowInterchange
          dataSource={rawMaterialDetail}
          keyList={DETAILS_KEY as any}
          variant="border"
          classNameLabel="text-hint"
          classNameContent="text-end "
        />
        {openEdit && (
          <DetailGeneralUpdateDrawer
            open={openEdit}
            onClose={() => setOpenEdit(false)}
            defaultValue={rawMaterialDetail}
            onUpdated={loadRawMaterialDetail}
          />
        )}
      </div>
    </div>
  );
};

export default RawMaterialDetailGeneral;
