import InputSelectForm from "@common/input/InputSelectForm";
import InputTextForm from "@common/input/InputTextForm";
import ChannelSelect from "@components/channel/components/ChannelSelect";
import InventoryEventSelect from "@components/invenotry-control-event/components/InventoryEventSelect";
import { Path } from "@components/layout/Path";
import StockRequestSolidSelect from "@components/stock-request/components/StockRequestSolidSelect";
import StoreSolidSelect from "@components/store/components/StoreSolidSelect";
import { STOCK_TRANSFER_TYPES } from "@constants/transfer-stock";
import AppContext from "@helpers/context";
import { mapTransferStockToServer } from "@interfaces/transfer-stock";
import {
  createTransferStock,
  getTransferStockRequestList,
} from "@network/api/transfer-stock";
import { DateInput, Header, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import TransferStockCrudProduct from "./TransferStockCrudProduct";

import * as Yup from "yup";

const TransferStockSchema = Yup.object().shape({
  channel: Yup.object().required("This field is required"),
  origin: Yup.object().required("This field is required"),
  destination: Yup.object().required("This field is required"),
  transferType: Yup.string().required("This field is required"),
  expectedDateTransfer: Yup.object().required("This field is required"),
  expectedDateArrival: Yup.object().required("This field is required"),
});

const TransferStockCrud = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const transferForm = useFormik<any>({
    initialValues: {} as any,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: TransferStockSchema,
    onSubmit: (values: any) => {
      const input = mapTransferStockToServer(values);

      Progress.show(
        { method: createTransferStock, params: [input] },
        (response: any) => {
          const transferId = response?.data?.data?.transferid;
          Notifications.showSuccess(t("createTransferStockSuccess"));
          navigate(generatePath(Path.TRANSFER_STOCK_DETAIL, { transferId }));
        }
      );
    },
  });

  const formValues = transferForm?.values;
  const formErrors = transferForm?.errors;

  const classNameInput = "mt-3";

  const sourceStockRequest = (body: any, paging: any) => {
    if (!formValues?.destination?.id || !formValues?.channel?.id)
      return Promise.resolve([]);


    const input = {
      ...body,
      channelId: formValues?.channel?.id,
      warehouseId: formValues?.destination?.id,
      stockEventId: formValues?.event?.id,
    };
    return getTransferStockRequestList(input);
  };

  return (
    <AppContext.Provider value={{ transferForm }}>
      <Header
        title={t("transferStock")}
        onSave={() => transferForm.handleSubmit()}
      />
      <div className="p-3">
        <div className="card-container p-3">
          <ChannelSelect
            label={t("channel")}
            value={formValues?.channel}
            onChange={(value) => transferForm.setFieldValue("channel", value)}
            className={classNameInput}
            error={formErrors?.channel as any}
          />
          <StoreSolidSelect
            label={t("origin")}
            value={formValues?.origin}
            onChange={(value) => transferForm.setFieldValue("origin", value)}
            className={classNameInput}
            error={formErrors?.origin as any}
          />
          <StoreSolidSelect
            channelId={formValues.channel?.id}
            label={t("destination")}
            value={formValues?.destination}
            onChange={(value) =>
              transferForm.setFieldValue("destination", value)
            }
            className={classNameInput}
            error={formErrors?.destination as any}
          />
          <InventoryEventSelect
            warehouseIds={
              formValues?.destination?.id ? [formValues?.destination?.id] : []
            }
            value={formValues?.event}
            onChange={(value) => transferForm.setFieldValue("event", value)}
            className={classNameInput}
          />

          <InputSelectForm
            dataSource={STOCK_TRANSFER_TYPES}
            keyData="transferType"
            form={transferForm}
            className={classNameInput}
          />
          <DateInput
            value={formValues?.expectedDateTransfer}
            onChange={(value) =>
              transferForm.setFieldValue("expectedDateTransfer", value)
            }
            label={t("expectedDateTransfer")}
            className={classNameInput}
            error={formErrors?.expectedDateTransfer as any}
            required
            format="DD/MM/YYYY"
          />
          <DateInput
            value={formValues?.expectedDateArrival}
            onChange={(value) =>
              transferForm.setFieldValue("expectedDateArrival", value)
            }
            label={t("expectedDateArrival")}
            className={classNameInput}
            error={formErrors?.expectedDateArrival as any}
            required
            format="DD/MM/YYYY"
          />
          <StockRequestSolidSelect
            label={t("stockRequest")}
            value={formValues?.requestEvents}
            onChange={(value) =>
              transferForm.setFieldValue("requestEvents", value)
            }
            className={classNameInput}
            source={sourceStockRequest}
            multiple
            sourceConstraint={[
              formValues?.channelId?.id,
              formValues?.destination?.id,
              formValues?.event?.id,
            ]}
          />
          <TransferStockCrudProduct />

          <InputTextForm
            multiple
            keyData="remark"
            form={transferForm}
            className={classNameInput}
          />
        </div>
      </div>
    </AppContext.Provider>
  );
};

export default TransferStockCrud;
