/* eslint-disable jsx-a11y/alt-text */
import Icon from "@common/Icon";
import ButtonFileUpload from "@components/shared/ButtonFileUpload";
import DocumentDropZone from "@components/shared/DocumentDropZone";
import Drawer from "@components/shared/Drawer";
import { IMAGE_FORMAT } from "@constants/file";
import AppContext from "@helpers/context";
import { uploadFile } from "@network/api/common";
import RawMaterialAPI from "@network/api/raw-material";
import {
  Button,
  Icon as DIcon,
  Notifications,
  Progress,
} from "d-react-components";
import { useFormik } from "formik";
import { filter, forEach, includes, isEmpty, map } from "lodash";
import { useContext, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import Carousel, { Modal, ModalGateway } from "react-images";

interface IDetailGalleryEditDrawer {
  open: boolean;
  defaultValue?: any;
  onUpdated: any;
  onClose: () => void;
}

const MediaItem = ({ media, onRemoveMedia }: any) => {
  const getSourceMedia = (media: any) => {
    if (media?.imageData) {
      return media.imageData;
    }

    if (!isEmpty(media?.url)) {
      return media?.url;
    }

    if (!isEmpty(media)) {
      return media;
    }

    return "/images/image-default.png";
  };
  return (
    <div className="mr-3 mt-3">
      <div className="position-relative width-fit-content">
        <img src={getSourceMedia(media)} className="image-reg-x-large" />
        <div
          onClick={() => onRemoveMedia(media)}
          className="position-absolute bg-danger p-1"
          style={{ right: 0, top: 0 }}
        >
          <DIcon name="delete" className="text-white" />
        </div>
      </div>
    </div>
  );
};

const DetailGalleryEditDrawer = ({
  open,
  defaultValue,
  onUpdated,
  onClose,
}: IDetailGalleryEditDrawer) => {
  const { t } = useTranslation();
  const rawMaterialForm = useFormik<any>({
    initialValues: defaultValue as any,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: schema,
    onSubmit: (values: any) => {
      onUpdate(values);
    },
  });
  const buttonUploadRef = useRef<any>(null);

  const formValues = rawMaterialForm?.values;
  const formErrors = rawMaterialForm?.errors;

  const onUpdate = (values: any) => {
    const { media } = values;

    const body: any = {
      id: defaultValue?.id,
      media: map(media, (item) => item.key),
    };

    Progress.show(
      { method: RawMaterialAPI.update, params: [body] },
      (res: any) => {
        Notifications.showSuccess(t("updateSuccess"));
        onUpdated();
        onClose();
      }
    );
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("gallery")}
      size="auto"
      width="80vw"
      onSave={() => {
        rawMaterialForm.handleSubmit();
      }}
    >
      <div>
        <div className="d-flex flex-wrap border p-3">
          <ButtonFileUpload
            onChange={(value: any) =>
              rawMaterialForm?.setFieldValue("media", value)
            }
            containerClassName="d-flex flex-column"
            ref={buttonUploadRef}
            inputParam={{ accept: `${IMAGE_FORMAT}` }}
            classNameItem="!w-24 !h-24 bg-black"
            classNameImage="!object-contain"
            defaultFiles={formValues.media}
            // uploadAfterSelect={false}
            renderButton={(
              getRootProps: any,
              getInputProps: any,
              inputParam: any
            ) => (
              <DocumentDropZone
                getRootProps={getRootProps}
                getInputProps={getInputProps}
                inputParam={inputParam}
              />
            )}
          />
        </div>
      </div>
    </Drawer>
  );
};

const RawMaterialDetailMedia = () => {
  const { rawMaterialDetail, loadRawMaterialDetail } = useContext(AppContext);
  const [openEdit, setOpenEdit] = useState(false);
  const { t } = useTranslation();

  const [viewImageModal, setViewImageModal] = useState(false);
  const [imageIndex, setImageIndex] = useState<number>();

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mt-3">
        <h5>{t("media")}</h5>
        <span onClick={() => setOpenEdit(true)}>
          <Icon
            className="w-[15px] cursor-pointer"
            src="/images/icons/edit.svg"
          />
        </span>
      </div>
      <div className="mt-3 border p-4 ">
        <div className="flex-row flex-wrap">
          {map(rawMaterialDetail?.media, (media, index: number) => (
            <div className="mr-3 mt-3">
              <div className="position-relative width-fit-content">
                <img
                  src={media}
                  className="image-reg-x-large"
                  onClick={() => {
                    setImageIndex(index as any);
                    setViewImageModal(true);
                  }}
                />
              </div>
            </div>
          ))}
        </div>
        <ModalGateway>
          {viewImageModal ? (
            <Modal onClose={() => setViewImageModal(false)}>
              <Carousel
                currentIndex={imageIndex}
                views={
                  map(rawMaterialDetail?.imageurl, (media) => {
                    return {
                      src: media,
                    };
                  }) as any
                }
              />
            </Modal>
          ) : null}
        </ModalGateway>
        {openEdit && (
          <DetailGalleryEditDrawer
            open={openEdit}
            onClose={() => setOpenEdit(false)}
            defaultValue={rawMaterialDetail}
            onUpdated={loadRawMaterialDetail}
          />
        )}
      </div>
    </div>
  );
};

export default RawMaterialDetailMedia;
