import { SelectInfinity } from "@components/shared/SelectInfinity";
import styled from "@emotion/styled";
import AppContext from "@helpers/context";
import { searchCustomer } from "@network/api/customer";
import { Avatar, Notifications } from "d-react-components";
import { isEmpty } from "lodash";
import React, { ElementRef, useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomerAddressDrawer from "./CustomerAddressDrawer";
import CustomerCreateCardReader from "./CustomerCreateCardReader";
import CustomerInfoDrawer from "./CustomerInfoDrawer";
import { CustomerType } from "@interfaces/customer";

const renderUserInfo = (user: any) => {
  const { firstname, lastname, nickname, email, imageurl, avatar, company } =
    user;
  const firstNameCharacter =
    user.customer_type === CustomerType.BUSINESS
      ? company?.[0].toUpperCase()
      : firstname?.[0]
      ? firstname?.[0].toUpperCase()
      : "";
  return (
    <div className="flex">
      <div className="mx-2">
        {imageurl || avatar ? (
          <Avatar className="w-10 h-10 object-cover" src={imageurl || avatar} />
        ) : (
          <div className="w-10 h-10 rounded-full flex bg-gray-400 justify-content-center items-center">
            <span className="subTitle1" style={{ fontSize: "1.5rem" }}>
              {firstNameCharacter}
            </span>
          </div>
        )}
      </div>
      <div className="userInfo">
        <span className="block text-xs">
          {user.customer_type === CustomerType.BUSINESS ? (
            <>{company}</>
          ) : (
            <>
              {firstname} {lastname}({nickname})
            </>
          )}
        </span>
        <span className="text-tiny block text-gray-400">{email}</span>
      </div>
    </div>
  );
};

const CustomerSelect = () => {
  const { t } = useTranslation();
  const { values, setFieldValue, loadShippingProfile, setContactOrder } =
    useContext(AppContext);
  const { selectedCustomer, channel } = values;
  const listRef = useRef<ElementRef<typeof SelectInfinity>>(null);
  const [isCustomerFound, setIsCustomerFound] = useState(false);
  const [newCustomer, setNewCustomer] = useState<any>();
  const [createNewModal, setCreateNewModal] = useState(false);
  const [createShippingModal, setCreateShippingModal] = useState(false);

  const onCloseCustomerInfo = (customer: any) => {
    setNewCustomer(customer);
    const { firstname, lastname, email, phone } = customer;
    setContactOrder({ firstname, lastname, email, phone });
    setCreateNewModal(false);
    if (!isEmpty(customer) && customer.id) {
      setCreateShippingModal(true);
      Notifications.showSuccess(t("notification:createCustomerAddress"));
    }
  };

  const onSelectCustomer = (customer: any) => {
    setCreateNewModal(false);
    setFieldValue("selectedCustomer", [customer]);
    const { firstname, lastname, email, phone } = customer;
    setContactOrder({ firstname, lastname, email, phone });
    loadShippingProfile(customer);
  };

  const onCloseCustomerAddress = () => {
    setCreateShippingModal(false);
    setFieldValue("selectedCustomer", [newCustomer]);
    const { firstname, lastname, email, phone } = newCustomer;
    setContactOrder({ firstname, lastname, email, phone });
    loadShippingProfile(newCustomer);
  };

  return (
    <>
      <CustomerSelectStyled className="flex-center-y w-full">
        <SelectInfinity
          ref={listRef}
          className="w-full"
          placeholder={t("searchYourExistingCustomer")}
          dataPath="customers"
          fetchFn={(body, paging) => {
            if (!body?.search) {
              return Promise.resolve();
            }
            return searchCustomer(body, paging).then((resp) => {
              setIsCustomerFound(resp?.data?.data?.customers?.length);
              return resp;
            });
          }}
          customQuery={(search) => ({
            search,
            channel_id: channel,
          })}
          // dropdownRender={(item) => renderUserInfo(item)}
          getLabel={(item) => (item ? renderUserInfo(item) : null)}
          onChange={(val) => {
            setFieldValue("selectedCustomer", val);
            loadShippingProfile(val?.[0]);
            const { firstname, lastname, email, phone } = val?.[0];
            setContactOrder({ firstname, lastname, email, phone });
          }}
          value={selectedCustomer}
          allowCreateNew={!isCustomerFound}
          onCreateNew={() => setCreateNewModal(true)}
          disabled={!channel}
          onDropdownVisibleChange={(open) => {
            if (open) {
              setIsCustomerFound(false);
            }
          }}
        />
        <CustomerCreateCardReader
          showName={false}
          buttonProps={{
            content: null as any,
            variant: "trans",
            size: "small",
          }}
          disabled={!channel}
          classNameImg="mx-0 h-[25px] w-[25px]"
          onChange={(v) => {
            listRef.current && listRef.current.onFocus();
            listRef.current && listRef.current.onSearch(v?.citizenId);
          }}
        />
      </CustomerSelectStyled>

      {createNewModal && (
        <CustomerInfoDrawer
          open={createNewModal}
          onClose={onCloseCustomerInfo}
          onSelectCustomer={onSelectCustomer}
          channel={values?.channel}
        />
      )}
      {createShippingModal && (
        <CustomerAddressDrawer
          open={createShippingModal}
          onClose={onCloseCustomerAddress}
          customer={newCustomer}
        />
      )}
    </>
  );
};

export default CustomerSelect;

const CustomerSelectStyled = styled.div`
  .customer-card-reader__button {
    min-width: 40px !important;
    margin-right: 0px !important;
    padding: 0px !important;
  }
`;
