import { WAREHOUSE_GENERAL_KEYS } from "@constants/warehouse";
import AppContext from "@helpers/context";
import WarehouseAPI from "@network/api/warehouse";
import {
  Header,
  Notifications,
  ObjectUtils,
  Progress,
  TabBar,
} from "d-react-components";
import { useFormik } from "formik";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import WarehouseCreateGeneral from "./WarehouseCreateGeneral";
import WarehouseCreateAddress from "./WarehouseCreateAddress";
import { getProvinceList } from "@network/api/address";
import { generatePath, useNavigate } from "react-router-dom";
import { Path } from "@components/layout/Path";

export const WarehouseGeneralSchema = Yup.object().shape({
  name: Yup.string().nullable().required("Required field!"),
  code: Yup.string().nullable().required("Required field!"),
});

export const ProDistrictSchema = Yup.object().shape({
  id: Yup.string().required("Required field!"),
});

export const WarehouseAddressSchema = Yup.object().shape({
  province: ProDistrictSchema,
  district: ProDistrictSchema,
  subDistrict: ProDistrictSchema,
  postCode: Yup.string().required("Required field!"),
  address: Yup.string().required("Required field!"),
});

export const WarehouseSchema = Yup.lazy((value) => {
  return WarehouseGeneralSchema.concat(WarehouseAddressSchema);
});

const TAB_LIST = [
  {
    id: "general",
    label: "generalInformation",
    component: <WarehouseCreateGeneral />,
  },
  {
    id: "address",
    label: "address",
    component: <WarehouseCreateAddress />,
  },
];
const WarehouseCreate = () => {
  const [selectedTab, setSelectedTab] = useState<any>(TAB_LIST[0]);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const warehouseForm = useFormik({
    initialValues: {},
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: WarehouseSchema,
    onSubmit: (values: any) => {
      const generalVal = ObjectUtils.mapFieldsLangsCTS(
        values,
        WAREHOUSE_GENERAL_KEYS as any
      );
      const body = {
        ...generalVal,
        province_id: values?.province?.id,
        city_id: values?.district?.id,
        subdistrict_id: values?.subDistrict?.id,
        postcode: values?.postCode,
        address: values?.address,
        latitude: values?.latitude,
        longitude: values?.longitude,
        nav_id: values?.nav?.id,
      };
      Progress.show(
        { method: WarehouseAPI.create, params: [body] },
        (res: any) => {
          Notifications.showSuccess(t("createWarehouseSuccessfully"));
          const warehouseId = res?.data?.data?.id;
          navigate(generatePath(Path.WAREHOUSE_DETAIL, { warehouseId }));
        }
      );
    },
  });

  return (
    <AppContext.Provider value={{ warehouseForm }}>
      <div>
        <Header
          title={t("newWarehouse")}
          onSave={() => warehouseForm.handleSubmit()}
        />
        <div className="p-3">
          <div className="row">
            <div className="col-md-3">
              <TabBar
                dataSource={TAB_LIST}
                variant="vertical"
                onChange={(tab) => setSelectedTab(tab)}
                value={selectedTab}
                getLabel={(item) => t(item.label as any)}
              />
            </div>
            <div className="col-md-9">{selectedTab.component}</div>
          </div>
        </div>
      </div>
    </AppContext.Provider>
  );
};

export default WarehouseCreate;
