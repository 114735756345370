import { TRANSFER_STOCK_STATUS_LIST } from "@constants/transfer-stock";
import AppContext from "@helpers/context";
import { ITransferStock } from "@interfaces/transfer-stock";
import {
  IRowsKey,
  TimeUtils,
  ViewLabelStatus,
  ViewRowInterchange,
} from "d-react-components";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

const TransferStockDetailInfo = () => {
  const { transferDetail } = useContext(AppContext);
  const { status, transfer_no } = transferDetail ?? {};
  const { t } = useTranslation();

  const DETAILS_KEY = useMemo<IRowsKey<ITransferStock>[]>(
    () => [
      {
        id: "transfer_no",
        label: t("id"),
      },
      {
        id: "transfer_origin",
        label: t("origin"),
        renderContent: ({ data, item }) => data?.code,
      },
      {
        id: "transfer_destination",
        label: t("destination"),
        renderContent: ({ data, item }) => data?.code,
      },
      {
        id: "transfer_type",
        label: t("transferType"),
      },
      {
        id: "expected_transfer",
        label: t("estTransferDate"),
        renderContent: ({ data, item }) => TimeUtils.toDateTime(data),
      },

      {
        id: "expected_arrival",
        label: t("estArrivalDate"),
        renderContent: ({ data, item }) => TimeUtils.toDateTime(data),
      },

      {
        id: "created",
        label: t("createdAt"),
        renderContent: ({ data, item }) => TimeUtils.toDateTime(data),
      },
    ],
    [t]
  );

  return (
    <div className="card-container p-4 flex-column flex-center pt-5">
      <img src={"/images/icons/event.svg"} className="image-reg-large" />
      <h4 className="mt-3">{transfer_no}</h4>
      <ViewLabelStatus
        status={status}
        listStatus={TRANSFER_STOCK_STATUS_LIST}
        getLabel={(item) => t(item.label)}
      />

      <ViewRowInterchange
        className="w-100"
        dataSource={transferDetail}
        keyList={DETAILS_KEY as any}
        variant="border"
        classNameLabel="font-weight-bold "
        classNameContent="text-end "
      />
    </div>
  );
};

export default TransferStockDetailInfo;
