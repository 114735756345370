import { Progress } from "d-react-components";
import React, { useContext } from "react";
// import UploadAvatarModule from "../../../components/UploadAvatarModule";
import AppContext from "@helpers/context";
import { API } from "@network";
import { uploadFile } from "@network/api/common";
import InputTextForm from "@common/input/InputTextForm";
import UploadAvatarModule from "@common/UploadAvatarModule";

const UserCreatePersonalInfo = () => {
  const { userForm } = useContext(AppContext);

  const classNameInput = "col-6 mt-3";

  const onUploadFile = (file: any) => {
    const body = new FormData();
    body.append("file", file);
    Progress.show({ method: uploadFile, params: [body] }, (res: any) => {
      const fileKey = res?.data?.data?.id;
      userForm.setFieldValue("avatar", fileKey);
    });
  };

  return (
    <div className="card-container ">
      <div className="d-flex p-4 border-bottom flex-center-y">
        <UploadAvatarModule setAvatar={onUploadFile} />
        <text className="text-bold ml-5">{userForm?.values?.fullName}</text>
      </div>
      <div className="row p-4 ">
        <InputTextForm keyData="username" form={userForm} required className={classNameInput} />
        <InputTextForm keyData="companyId" form={userForm} required className={classNameInput} />
        <InputTextForm keyData="fullName" form={userForm} required className={classNameInput} />
        <InputTextForm keyData="nickName" form={userForm} required className={classNameInput} />
      </div>
    </div>
  );
};

export default UserCreatePersonalInfo;
