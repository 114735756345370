import _ from "lodash";
import React, { useContext } from "react";

import AppContext from "@helpers/context";
import InfoItemLangs from "@components/shared/InfoItemLangs";
import { getBothLangsText } from "@helpers/locales";
import { StringUtils, TimeUtils } from "d-react-components";
import CashDepositPayment from "./CashDepositPayment";
import { useTranslation } from "react-i18next";
import CashDepositDetailTransaction from "./CashDepositDetailTransaction";

const CashDepositPrintView = () => {
  const { cashDepositDetail } = useContext(AppContext);
  const { t } = useTranslation();
  const {
    ref,
    created,
    store,
    status,
    channel,
    by,
    transaction_closing_date,
    expected_deposit_date,
    total,
    payments = [],
    method,
    remark,
    confirm_by,
    confirm_payment,
    confirm_payment_note,
    confirm_actual_date_payment,
    confirm_actual_bank_payment,
  } = cashDepositDetail;

  const renderCompanyInfo = () => {
    if (!channel) {
      return <div />;
    }
    const { logo, name, phone, address, tax, website } = channel;
    return (
      <div className="company-container">
        <div>
          <img src={logo} className="company-logo" />
        </div>
        <div className="companyNameText-ReceiptPrint">{name}</div>

        <InfoItemLangs content={address} />
        <InfoItemLangs content={phone} />

        <InfoItemLangs content={website} />
        <InfoItemLangs title="taxId" content={tax} />
      </div>
    );
  };
  const renderStoreInfo = () => {
    if (!store) {
      return <div />;
    }
    const { address, name } = store;
    return (
      <div className="company-container">
        <text className="sectionTitleText">
          {getBothLangsText("storeInformation")}
        </text>

        <InfoItemLangs content={name} />
        <InfoItemLangs content={store?.code} title="storeCode" />
        <InfoItemLangs content={address} />

        {/* <InfoItemLangs title={"tel"} content={"0987654321"} /> */}
      </div>
    );
  };
  const renderOrderInfo = () => {
    return (
      <div className="receiptInfo-receiptOrderPrint">
        <text className="cashRepositRequestText">Cash Deposit Request</text>
        <text className="cashRepositRequestText">คำขอการฝากเงินสด</text>
        <InfoItemLangs title="ref" content={ref} />
        <InfoItemLangs
          title="createdAt"
          content={TimeUtils.toDateTime(created)}
        />

        <InfoItemLangs title="storeCode" content={store?.code} />
        <InfoItemLangs title="paymentMethod" content={t(status)} />
      </div>
    );
  };
  const renderDepositInfo = () => {
    if (!by) {
      return <div />;
    }
    const { name, companyId } = by;
    const total = _.reduce(payments, (sum, item) => sum + item.total, 0);
    return (
      <div className="receiptInfo-receiptOrderPrint">
        <text className="sectionTitleText">
          {getBothLangsText("depositInformation")}
        </text>
        <InfoItemLangs title="staffName" content={name} />
        <InfoItemLangs title="staffId" content={companyId} />
        <InfoItemLangs
          title="createdAt"
          content={TimeUtils.convertMiliToDateTime(created)}
        />
        <InfoItemLangs
          title="transactionClosing"
          content={TimeUtils.convertMiliToDate(transaction_closing_date)}
        />
        <InfoItemLangs
          title="expectedDepositDate"
          content={TimeUtils.convertMiliToDate(expected_deposit_date)}
        />
        <InfoItemLangs
          title="amount"
          content={StringUtils.moneyThaiFormat(total)}
        />

        <InfoItemLangs title="depositMethod" content={t(method)} />
      </div>
    );
  };

  const renderCompanyOrderInfo = () => {
    return (
      <div className="print-deposit-container-sectionContentView companyOrderInfoContainer">
        {renderCompanyInfo()}
        {renderOrderInfo()}
      </div>
    );
  };

  const renderStoreDepositInfo = () => {
    return (
      <div className="print-deposit-container-sectionContentView companyOrderInfoContainer">
        {renderStoreInfo()}
        {renderDepositInfo()}
      </div>
    );
  };
  const renderTransactionList = () => {
    return (
      <div className="print-deposit-container-sectionContentView companyOrderInfoContainer">
        <CashDepositDetailTransaction isPrintView isShowBothLang />
      </div>
    );
  };

  const renderPaymentConfirmation = () => {
    if (!confirm_by) {
      return <div />;
    }
    const { name, companyId } = confirm_by;
    return (
      <div className="print-deposit-container-sectionContentView paymentConfirmationContainer">
        <text className="sectionTitleText">
          {getBothLangsText("paymentConfirmation")}
        </text>
        <text className="paymentContentText">
          {`${getBothLangsText(
            "confirmedBy"
          )} ${companyId} - ${name} ${TimeUtils.convertMiliToDateTime(
            confirm_payment
          )} : "${confirm_payment_note}"`}
        </text>
        {confirm_actual_date_payment && (
          <text className="paymentContentText">
            {`${getBothLangsText(
              "actualDateReceivingPayment"
            )}: ${TimeUtils.convertMiliToDateTime(
              confirm_actual_date_payment
            )}`}
          </text>
        )}
        {confirm_actual_bank_payment && (
          <text className="paymentContentText">
            {`${getBothLangsText("actualBankReceivingPayment")} ${
              confirm_actual_bank_payment?.number
            }(${confirm_actual_bank_payment?.name})`}
          </text>
        )}
      </div>
    );
  };
  const renderRemark = () => {
    return (
      <div className="print-deposit-container-sectionContentView  paymentConfirmationContainer">
        <text className="sectionTitleText">{getBothLangsText("remark")}</text>
        <text className="paymentContentText">{remark}</text>
      </div>
    );
  };
  const renderSignature = () => {
    return (
      <div className="print-deposit-container-signatureContainer">
        <div className="signature-depositBy">
          <InfoItemLangs
            title="depositBy"
            content="........................................"
          />
          <InfoItemLangs
            title="date"
            content="........../........../.........."
          />
        </div>
        <div className="signature-confirmedBy">
          <InfoItemLangs
            title="confirmedBy"
            content="........................................"
          />
          <InfoItemLangs
            title="date"
            content="........../........../.........."
          />
        </div>
      </div>
    );
  };
  return (
    <div className="print-container print-deposit-container">
      {renderCompanyOrderInfo()}
      {renderStoreDepositInfo()}

      {renderTransactionList()}
      {renderPaymentConfirmation()}
      {renderRemark()}
      {renderSignature()}
    </div>
  );
};

export default CashDepositPrintView;
