import classNames from "classnames";
import React from "react";
import QRCode from "react-qr-code";

const UserAuthQrCode = ({ secretCode, name, className }: any) => {
  if (!secretCode || !name) return <div />;
  const content = `otpauth://totp/SalesinfinityX.com-${name}?secret=${secretCode}`;
  return (
    <div className={classNames("mt-3", className)}>
      <QRCode value={content} size={100} />
    </div>
  );
};

export default UserAuthQrCode;
