/* eslint-disable react-hooks/exhaustive-deps */
import { IAdmin } from "@interfaces/admin";
import { IFieldSelect } from "@interfaces/select";

import { getDriverList } from "@network/api/user";
import { ObjectUtils, Select } from "d-react-components";
import { isArray, map } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export interface IDriverSelect<T> extends IFieldSelect<T> {
  [key: string]: any;
  channelId?: string;
  warehouseId?: string;
}

const DriverSelect = ({
  value = [],
  multiple,
  className,
  onChange,
  channelId,
  warehouseId,
  ...selectProps
}: IDriverSelect<IAdmin>) => {
  const [driverList, setDriverList] = useState<any[]>(
    isArray(value) ? value : value ? [value] : []
  );

  const { t } = useTranslation();

  const onChangeValue = (id: any) => {
    if (multiple) {
      onChange(map(id, (item) => ObjectUtils.findItemFromId(driverList, item)));
      return;
    }
    onChange(ObjectUtils.findItemFromId(driverList, id));
  };

  const driverValue = useMemo(() => {
    if (multiple) {
      return map(value, (item: any) => item?.id);
    }
    return (value as any)?.id;
  }, [value]);

  useEffect(() => {
    loadDriverList();
  }, [channelId]);

  const loadDriverList = () => {
    getDriverList({ channel_id: channelId, warehouse_id: warehouseId }).then(
      (res) => {
        const resultList = res?.data?.data?.driver ?? [];
        setDriverList(resultList);
      }
    );
  };

  return (
    <Select
      className={className}
      label={t("driver")}
      dataSource={driverList ?? []}
      value={driverValue}
      onChange={onChangeValue}
      multiple={multiple}
      placeholder={t("pleaseSelect")}
      showSearch
      getLabel={(driver) => driver?.fullname}
      {...selectProps}
    />
  );
};

export default DriverSelect;
