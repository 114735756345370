import { Pagination } from "@interfaces/common";
import { API } from "@network";
import {
  API_CREATE_CONTRACT_INVOICE,
  API_CREATE_SUBSCRIPTION,
  API_DETAIL_CONTRACT_INVOICE,
  API_DETAIL_SUBSCRIPTION,
  API_LIST_CONTRACT_INVOICE,
  API_LIST_PAYMENT_SUBSCRIPTION,
  API_LIST_SUBSCRIPTION,
  API_UPDATE_CONTRACT_INVOICE,
  API_UPLOAD_CONTRACT_CONFIRM_PROOF_DOCUMENTS,
  API_UPDATE_CONTRACT_CUSTOMER_INFORMATION,
  API_UPLOAD_CONTRACT_PROOF_DOCUMENTS,
  API_UPLOAD_CONTRACT_PROOF_PAYMENT,
  API_UPLOAD_CONTRACT_PROOF_PAYMENT_CONFIRMATION,
  API_UPDATE_CONTRACT_NOTE,
  API_CHANGE_EXPIRED_DAY_CONTRACT,
  API_TERMINATE_CONTRACT,
  API_CANCEL_CONTRACT,
  API_LIST_CONTRACT_ACTIVITIES,
  API_ADD_CONTRACT_ACTIVITY,
  API_CANCEL_CONTRACT_INVOICE,
  API_GET_CONTRACT_LINK_ACCESS_TOKEN,
  API_RESEND_CONTRACT,
  API_RESEND_CONTRACT_INVOICE,
  API_VIEW_PDF,
  API_VERIFY_RENT_CUSTOMER_EMAIL,
  API_VERIFY_RENT_CUSTOMER_PHONE,
  API_VALIDATE_CART_RULE_RENT,
} from "@network/URL";

export const listRental = (body: any, paging: Pagination, cancelToken: any) =>
  API.post(
    `${API_LIST_SUBSCRIPTION}?page=${paging.pageIndex}&per_page=${paging.pageSize}`,
    body,
    cancelToken
  );

export const createRental = (body: any) =>
  API.post(API_CREATE_SUBSCRIPTION, body);

export const detailRental = (body: any) =>
  API.post(API_DETAIL_SUBSCRIPTION, body);

export const listRentalPayment = (body: any) =>
  API.post(API_LIST_PAYMENT_SUBSCRIPTION, body);

export const listRentalInvoice = (body: any) =>
  API.post(API_LIST_CONTRACT_INVOICE, body);

export const createRentalInvoice = (body: any) =>
  API.post(API_CREATE_CONTRACT_INVOICE, body);

export const detailRentalInvoice = (body: any) =>
  API.post(API_DETAIL_CONTRACT_INVOICE, body);

export const updateRentalInvoice = (body: any) =>
  API.post(API_UPDATE_CONTRACT_INVOICE, body);

export const cancelRentalInvoice = (body: any) =>
  API.post(API_CANCEL_CONTRACT_INVOICE, body);

export const updateRentalNote = (body: any) =>
  API.post(API_UPDATE_CONTRACT_NOTE, body);

export const uploadRentalInvoiceProofPayment = (body: any) =>
  API.post(API_UPLOAD_CONTRACT_PROOF_PAYMENT, body);

export const uploadRentalInvoiceProofPaymentConfirmation = (body: any) =>
  API.post(API_UPLOAD_CONTRACT_PROOF_PAYMENT_CONFIRMATION, body);

export const uploadRentalInvoiceProofDocuments = (body: any) =>
  API.post(API_UPLOAD_CONTRACT_PROOF_DOCUMENTS, body);

export const confirmRentalInvoiceProofDocuments = (body: any) =>
  API.post(API_UPLOAD_CONTRACT_CONFIRM_PROOF_DOCUMENTS, body);

export const updateRentalCustomerInfo = (body: any) =>
  API.post(API_UPDATE_CONTRACT_CUSTOMER_INFORMATION, body);

export const changeRentalExpiredDay = (id: string, expire: string) => {
  const body = { id, expire };
  return API.post(API_CHANGE_EXPIRED_DAY_CONTRACT, body);
};

export const terminateContract = (body: any) =>
  API.post(API_TERMINATE_CONTRACT, body);

export const cancelContract = (body: any) =>
  API.post(API_CANCEL_CONTRACT, body);

export const addContractActivity = (body: any) => {
  return API.post(API_ADD_CONTRACT_ACTIVITY, body);
};

export const getListContractActivities = (id: string, pageIndex: any) => {
  return API.post(`${API_LIST_CONTRACT_ACTIVITIES}?page=${pageIndex}`, {
    id,
  });
};

export const getContractLinkAccessToken = (body: any) => {
  return API.post(API_GET_CONTRACT_LINK_ACCESS_TOKEN, body);
};

export const resendContract = (body: any) =>
  API.post(API_RESEND_CONTRACT, body);

export const resendInvoice = (body: any) =>
  API.post(API_RESEND_CONTRACT_INVOICE, body);

export const viewContractPdf = (body: any) => API.post(API_VIEW_PDF, body);

export const verifyCustomerEmailRentInfo = (body: any) => {
  return API.post(API_VERIFY_RENT_CUSTOMER_EMAIL, body);
};

export const verifyCustomerPhoneRentInfo = (body: any) => {
  return API.post(API_VERIFY_RENT_CUSTOMER_PHONE, body);
};

export const validateCartRuleContract = (body: any) => {
  return API.post(API_VALIDATE_CART_RULE_RENT, body);
};
