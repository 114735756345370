import MAIN_MENU from "@components/layout/MainMenu";
import { V1_DEV_URL, V1_URL } from "@constants/common";
import { IMenuItemProps } from "@constants/menu";
import { IMenu } from "@interfaces/common";
import { find } from "lodash";

export const menuFlatter = (
  data: Array<IMenuItemProps> = [],
  prev: Array<any> = []
) => {
  let result: Array<IMenuItemProps> = [...prev];
  if (data?.length > 0) {
    data.forEach((item) => {
      const isIn = !!result.find((i) => i?.id === item?.id);
      if (!isIn) {
        result.push(item);
      }
      const { subMenu = [] } = item;
      if (subMenu.length > 0) {
        subMenu.forEach((sub) => {
          const isSubIn = !!result.find((i) => i?.id === sub?.id);
          if (!isSubIn) {
            result.push({ ...sub, parentId: item.id });
          }
        });
        result = menuFlatter(subMenu, result);
      }
    });
  }
  return result;
};

const removeStartingSlash = (uri: string) => {
  return uri.startsWith("/") ? uri.substring(1) : uri;
};

const checkIsSubMenuReplaceable = (menu: any) => {
  const matchedSubmenu = find(
    MAIN_MENU,
    (item: any) =>
      item?.v1Id === menu?.uri ||
      removeStartingSlash(menu?.uri ?? "") === item?.v1Id
  );
  if (matchedSubmenu) {
    return matchedSubmenu;
  }
  return false;
};

export const mapServerMenu: any = (menu: IMenu) => {
  const baseUrl =
    process.env.REACT_APP_ENV === "development" ? V1_DEV_URL : V1_URL;
  return {
    id: `${menu.title}_${menu.order}`,
    label: menu.title,
    iconName: menu.icon,
    path:
      menu.uri && menu.uri !== "#"
        ? `${baseUrl}/${removeStartingSlash(menu.uri)}`
        : "",
    title: menu.title,
    isExternal: true,
    subMenu:
      menu.children?.map(
        (subMenu: any) =>
          checkIsSubMenuReplaceable(subMenu) || mapServerMenu(subMenu)
      ) ?? [],
  };
};

export const filterMenu = (menus: IMenuItemProps[], searchString: string) => {
  return menus
    .filter((item) => {
      const filterSubMenu =
        item?.subMenu?.filter((subItem) =>
          subItem?.label
            ?.toLocaleLowerCase()
            ?.includes(searchString?.toLocaleLowerCase())
        ) ?? [];
      return (
        filterSubMenu?.length ||
        item?.label
          ?.toLocaleLowerCase()
          ?.includes(searchString?.toLocaleLowerCase())
      );
    })
    ?.map((item) => ({
      ...item,
      subMenu:
        item?.subMenu?.filter((subItem) =>
          subItem?.label
            ?.toLocaleLowerCase()
            ?.includes(searchString?.toLocaleLowerCase())
        ) ?? [],
    }));
};
