import { API } from "@network";
import {
  API_DELIVERY_STOCK_ASSIGN_DRIVER_VEHICLE,
  API_DELIVERY_STOCK_DETAIL,
  API_DELIVERY_STOCK_LIST,
  API_DELIVERY_STOCK_ACTIVITIES,
  API_DELIVERY_STOCK_ACTIVITIES_ADD,
  API_DELIVERY_STOCK_LIST_SUMMARY,
  API_DELIVERY_STOCK_START_LOADING,
  API_DELIVERY_STOCK_CONFIRM_ORIGIN,
  API_DELIVERY_STOCK_DESTINATION_CONFIRM,
  API_DELIVERY_STOCK_RETURN_CREATE,
  API_DELIVERY_STOCK_RETURN_CONFIRM,
  API_DELIVERY_STOCK_REASON_REFUSE,
  API_DELIVERY_STOCK_RETURN_COMPLETE,
  API_DELIVERY_STOCK_RETURN_CANCEL,
} from "@network/URL";

export const deliveryStockAssignVehicleDriver = (body: any) => {
  return API.post(API_DELIVERY_STOCK_ASSIGN_DRIVER_VEHICLE, body);
};

export const getDeliveryStockSummary = () => {
  return API.post(API_DELIVERY_STOCK_LIST_SUMMARY);
};

export const getDeliveryStockList = (body: any, paging: any) => {
  return API.post(
    `${API_DELIVERY_STOCK_LIST}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
    body
  );
};

export const getDeliveryStockDetail = (id: string) => {
  return API.post(API_DELIVERY_STOCK_DETAIL, { id });
};

export const getDeliveryStockActivity = (body: any, paging: any) => {
  return API.post(
    `${API_DELIVERY_STOCK_ACTIVITIES}?page=${paging?.pageIndex}`,
    body
  );
};

export const addDeliveryStockActivity = (body: any) => {
  return API.post(API_DELIVERY_STOCK_ACTIVITIES_ADD, body);
};

export const deliveryStockStartLoading = (body: any) => {
  return API.post(API_DELIVERY_STOCK_START_LOADING, body);
};

export const deliveryStockConfirmOrigin = (body: any) => {
  return API.post(API_DELIVERY_STOCK_CONFIRM_ORIGIN, body);
};

export const deliveryStockConfirmDestination = (body: any) => {
  return API.post(API_DELIVERY_STOCK_DESTINATION_CONFIRM, body);
};

export const deliveryStockCreateReturn = (body: any) => {
  return API.post(API_DELIVERY_STOCK_RETURN_CREATE, body);
};

export const deliveryStockConfirmReturn = (body: any) => {
  return API.post(API_DELIVERY_STOCK_RETURN_CONFIRM, body);
};

export const deliveryStockCompleteReturn = (body: any) => {
  return API.post(API_DELIVERY_STOCK_RETURN_COMPLETE, body);
};

export const deliveryStockReasonRefuse = (body?: any) => {
  return API.post(API_DELIVERY_STOCK_REASON_REFUSE, body);
};

export const deliveryStockCancelReturn = (body: any) => {
  return API.post(API_DELIVERY_STOCK_RETURN_CANCEL, body);
};
