import React, { useContext, useState } from "react";
import AppContext from "@helpers/context";
import AddCustomItemDrawer from "./AddCustomItemDrawer";
import { Icon } from "d-react-components";

const EditCustomItemButton = ({ product }: any) => {
  const { setChangeValue, productCustomList, setProductCustomList } =
    useContext(AppContext);
  const [openModal, setOpenModal] = useState(false);

  const onSaveCustomItem = (customItem: any) => {
    const resultList = productCustomList?.map((item: any) => {
      if (customItem.id === item.id) {
        return customItem;
      }
      return item;
    });
    setProductCustomList(resultList);
    setOpenModal(false);
  };

  return (
    <div>
      <span
        className="text-white bg-black p-1 cursor-pointer ml-1 inline-block leading-none"
        onClick={() => setOpenModal(true)}
      >
        <Icon name="edit" size="xx-small" />
      </span>
      {openModal && (
        <AddCustomItemDrawer
          open={openModal}
          onClose={() => setOpenModal(false)}
          onSave={onSaveCustomItem}
          product={product}
        />
      )}
    </div>
  );
};

export default EditCustomItemButton;
