import { API } from "@network";
import {
  API_PRODUCT_ALIAS_IMPORT,
  API_PRODUCT_ALIAS_IMPORT_VALIDATE,
} from "../URL";

const ProductAliasAPI = {
  validateImport: (body: any) => {
    return API.post(API_PRODUCT_ALIAS_IMPORT_VALIDATE, body);
  },

  importProductAlias: (body: any) => {
    return API.post(API_PRODUCT_ALIAS_IMPORT, body);
  },
};

export default ProductAliasAPI;
