import AppContext from "@helpers/context";
import { IStockRequest } from "@interfaces/stock-request";
import { IRowsKey, TimeUtils, ViewRowInterchange } from "d-react-components";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

const StockRequestDetailInfo = () => {
  const { requestDetail } = useContext(AppContext);
  const { ref, code } = requestDetail ?? {};
  const { t } = useTranslation();

  const DETAILS_KEY = useMemo<IRowsKey<IStockRequest>[]>(
    () => [
      {
        id: "ref",
        label: t("id"),
      },
      {
        id: "channel",
        label: t("channel"),
        renderContent: ({ data, item }) => data?.name,
      },
      {
        id: "chain",
        label: t("chain"),
        renderContent: ({ data, item }) => data?.name ?? "N/A",
      },
      {
        id: "store",
        label: t("branchWarehouse"),
        renderContent: ({ data, item }) => data?.name,
      },
      {
        id: "store",
        label: t("branchWarehouseCode"),
        renderContent: ({ data, item }) => data?.code,
      },
      {
        id: "request_type",
        label: t("type"),
      },
      {
        id: "expected_arrival_date",
        label: t("expectedArrivalDate"),
        renderContent: ({ data, item }) => TimeUtils.toDateTime(data),
      },
      {
        id: "created",
        label: t("createdAt"),
        renderContent: ({ data, item }) => TimeUtils.toDateTime(data),
      },
      {
        id: "updated",
        label: t("updatedAt"),
        renderContent: ({ data, item }) => TimeUtils.toDateTime(data),
      },
    ],
    [t]
  );

  return (
    <div className="card-container p-4 flex-column flex-center pt-5">
      <img src={"/images/icons/event.svg"} className="image-reg-large" />
      <h4 className="mt-3">{ref}</h4>
      <small>{code}</small>

      <ViewRowInterchange
        className="w-100"
        dataSource={requestDetail}
        keyList={DETAILS_KEY as any}
        variant="border"
        classNameLabel="font-weight-bold "
        classNameContent="text-end "
      />
    </div>
  );
};

export default StockRequestDetailInfo;
