import InputTextForm from "@common/input/InputTextForm";
import ChannelSelect from "@components/channel/components/ChannelSelect";
import StoreSolidSelect from "@components/store/components/StoreSolidSelect";
import { DEPOSIT_METHODS } from "@constants/cash-deposit";
import AppContext from "@helpers/context";
import { DateInput, InputText, Select } from "d-react-components";
import moment from "moment";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

const CashDepositCreateGeneral = () => {
  const { t } = useTranslation();
  const { cashDepositForm } = useContext(AppContext);
  const { values, errors, setFieldValue } = cashDepositForm;

  return (
    <div className="bg-white p-3">
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <ChannelSelect
          value={values.channel}
          onChange={(value) => setFieldValue("channel", value)}
          className="mb-3 col-span-2"
          error={errors.channel}
        />
        <StoreSolidSelect
          value={values.store}
          onChange={(value) => setFieldValue("store", value)}
          className="mb-3 col-span-2"
          error={errors.store}
        />
        <Select
          className="mb-3 col-span-2"
          label={t("depositMethod")}
          dataSource={DEPOSIT_METHODS}
          value={values?.depositMethod}
          onChange={(value) =>
            cashDepositForm.setFieldValue("depositMethod", value)
          }
        />
        <InputTextForm
          className="mb-3 col-span-2"
          form={cashDepositForm}
          keyData="bankFee"
          placeholder={t("pleaseInput")}
          error={errors.bankFee}
        />
        {/* <div className="col-span-2">
          <ChannelSelect
            value={values.channel}
            onChange={(value) => setFieldValue("channel", value)}
            className="mb-3"
            multiple
            error={errors.channel}
          />
        </div> */}
        <div className="col-span-1">
          <DateInput
            label={t("transactionClosingDate")}
            value={values.transactionClosingDate}
            onChange={(value) => setFieldValue("transactionClosingDate", value)}
            className="mb-3"
            error={errors.transactionClosingDate}
            showTime
            format="DD/MM/YYYY HH:mm"
            placeholder={t("selectDate")}
          />
        </div>
        <div className="col-span-1">
          <DateInput
            label={t("expectedDepositDate")}
            value={values.expectedDepositDate}
            onChange={(value) => setFieldValue("expectedDepositDate", value)}
            className="mb-3"
            error={errors.expectedDepositDate}
            showTime
            format="DD/MM/YYYY HH:mm"
            placeholder={t("selectDate")}
          />
        </div>

        <div className="col-span-2">
          <InputText
            label={t("remark")}
            multiple
            value={values.remark}
            onChange={(e) => setFieldValue("remark", e.target.value)}
            className="mb-3"
            placeholder={t("pleaseInput")}
            error={errors.remark}
          />
        </div>
      </div>
    </div>
  );
};

export default CashDepositCreateGeneral;
