import ActivitiesView from "@components/order/components/activity/ActivitiesView";
import RawMaterialAPI from "@network/api/raw-material";
import { Notifications } from "d-react-components";
import { map } from "lodash";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const RawMaterialDetailActivity = () => {
  const { rawMaterialId } = useParams();

  const activityList = useRef(null);
  const { t } = useTranslation();

  const source = (paging: any) => {
    return RawMaterialAPI.activityList({ id: rawMaterialId }, paging);
  };

  const transformer = (res: any) => {
    const activityList = res?.data?.data?.activities ?? [];
    return map(activityList, (item) => ({
      ...item,
      files: map(item.attachment, (attItem) => attItem?.attachment),
    }));
  };

  const onClickSendComment = (comment: any, attachment: any) => {
    const body = {
      id: rawMaterialId,
      comment,
      attachment: map(attachment, (item) => item?.key),
    };
    return RawMaterialAPI.comment(body).then(() => {
      Notifications.showSuccess(t("addCommentSuccess"));
    });
  };

  return (
    <ActivitiesView
      hideType
      source={source}
      transformer={transformer}
      setRef={(listRefParams: any) => (activityList.current = listRefParams)}
      onClickSendComment={onClickSendComment}
      isInstantUploadAttach
    />
  );
};

export default RawMaterialDetailActivity;
