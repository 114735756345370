import { Path } from "@components/layout/Path";
import UserAvatarName from "@components/shared/UserAvatarName";
import { STOCK_ADJUSTMENT_STATUS_LIST } from "@constants/stock-adjustment";

import AppContext from "@helpers/context";
import { IStockAdjustment } from "@interfaces/stock-adjustment";

import { IStore } from "@interfaces/store";
import { getStockAdjustmentList } from "@network/api/stock-adjustment";

import {
  AwesomeTableComponent,
  HeaderTable,
  ITabItem,
  TimeUtils,
  useFirstTime,
  ViewLabelStatus,
} from "d-react-components";
import { debounce, find } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import StockAdjustTabs from "./StockAdjustTabs";

const StockAdjustmentTable = () => {
  const tableRef = useRef<any>();
  const searchRef = useRef<string>();
  const [tabSelected, setTabSelected] = useState<ITabItem>({
    id: "all",
    label: "all",
  });
  const [openFilter, setOpenFilter] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [filter = {}, setFilter] = useQueryParam<any>("filter", JsonParam);

  const isFirstTime = useFirstTime();

  useEffect(() => {
    if (isFirstTime) return;
    tableRef.current?.refresh();
  }, [filter, tabSelected]);

  const TABLE_ACTIONS = [
    { id: "edit", label: t("edit") },
    { id: "detail", label: t("detail") },
  ];

  const source = (paging: any) => {
    const { warehouse, channel } = filter;
    const body = {
      search: searchRef?.current,
      warehouse_id: warehouse === "all" ? null : warehouse,
      status: tabSelected?.id === "all" ? null : [tabSelected],
      channels: channel === "all" ? null : [channel],
    };
    return getStockAdjustmentList(body, paging);
  };

  const columns: any[] = [
    {
      title: t("id"),
      dataIndex: "ref",
      render: (ref: string, item: IStockAdjustment) => (
        <Link
          to={generatePath(Path.STOCK_ADJUSTMENT_DETAIL, {
            adjustmentId: item.id,
          })}
        >
          {ref}
        </Link>
      ),
    },
    {
      title: t("branchWarehouse"),
      dataIndex: "warehouse",
      render: (store: IStore) => `${store?.name} (${store?.code})`,
    },

    {
      title: t("status"),
      dataIndex: "status",
      render: (status: string) => {
        return (
          <ViewLabelStatus
            status={status}
            listStatus={STOCK_ADJUSTMENT_STATUS_LIST}
            getLabel={(item) => t(item.label)}
          />
        );
      },
    },

    {
      title: t("createdAt"),
      dataIndex: "created",
      render: TimeUtils.toDateTime,
    },
  ];

  const onChangeSearch = debounce((text) => {
    searchRef.current = text;
    tableRef.current.refresh();
  }, 300);

  const isFiltered = useMemo(() => Object.keys(filter).length > 0, [filter]);

  return (
    <AppContext.Provider value={{ tabSelected }}>
      <div className="bg-white">
        <HeaderTable
          label={t("stockAdjustment")}
          onChangeText={(event: any) => onChangeSearch(event.target.value)}
          onClickNew={() => navigate(Path.STOCK_ADJUSTMENT_CREATE)}
          // onClickFilter={() => setOpenFilter(true)}
          isFiltered={isFiltered}
          className="app-layout__page-header"
        />

        <div className="d-flex overflow-auto">
          <StockAdjustTabs
            tabSelected={tabSelected}
            onChangeTab={setTabSelected}
          />
        </div>
        <AwesomeTableComponent
          source={source}
          transformer={(res) => res?.data?.data?.adjustment ?? []}
          isScroll={false}
          columns={columns}
          ref={(ref) => (tableRef.current = ref)}
        />
        {/* {openFilter && (
        <StockAdjustmentFilterDrawer
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          defaultValue={filter}
          onFilter={setFilter}
        />
      )} */}
      </div>
    </AppContext.Provider>
  );
};

export default StockAdjustmentTable;
