import AppContext from "@helpers/context";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

const OrderDetailReceiptRef = () => {
  const { order } = useContext(AppContext);
  const { t } = useTranslation();
  return (
    <div className="bg-white p-3 mb-3 shadow-sm flex-column" hidden={!order?.receipt_ref}>
      <label className="block text-sm">{t("receiptRef")}</label>
      <span>{order?.receipt_ref}</span>
    </div>
  );
};

export default OrderDetailReceiptRef;
