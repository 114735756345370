import BrandSelect from "@components/brand/components/BrandSelect";
import ChannelSelect from "@components/channel/components/ChannelSelect";
import ProductCategorySelect from "@components/product-category/components/ProductCategorySelect";
import {
  FILTER_INVENT_STATUS_LIST,
  INVENTORY_VISIBILITY,
  PRODUCT_SUBSCRIPTION_STATUS,
  PRODUCT_TYPES,
} from "@constants/product";
import { Slider } from "antd";
import classNames from "classnames";
import { Select } from "d-react-components";
import { useTranslation } from "react-i18next";

const ProductFilterForm = ({ filterBody, onChangeData }: any) => {
  const classNameInput = classNames("col-span-1 mt-4");
  const { t } = useTranslation();
  console.log("filterBody?.regular_price", filterBody?.regular_price);
  return (
    <div className="grid grid-cols-1 gap4">
      <BrandSelect
        value={filterBody?.brand}
        onChange={(value) => onChangeData({ brand: value })}
        multiple
      />
      <ProductCategorySelect
        className={classNameInput}
        value={filterBody?.category}
        onChange={(value) => onChangeData({ category: value })}
        multiple
      />
      <Select
        dataSource={PRODUCT_TYPES}
        label={t("productType")}
        className={classNameInput}
        value={filterBody?.product_type}
        onChange={(value) => {
          onChangeData({ product_type: value });
        }}
        getLabel={(item) => t(item?.label)}
        multiple
      />

      {/* <Select
        dataSource={FILTER_INVENT_STATUS_LIST}
        label={t("total_stock")}
        className={classNameInput}
        value={filterBody?.productType}
        onChange={(value) => {
          onChangeData({ total_stock: value });
        }}
        getLabel={(item) => t(item?.label)}
      /> */}

      <Select
        dataSource={INVENTORY_VISIBILITY}
        label={t("visibility")}
        className={classNameInput}
        value={filterBody?.visibility}
        onChange={(value) => {
          onChangeData({ visibility: value });
        }}
        getLabel={(item) => t(item?.label)}
      />

      <div className={classNameInput}>
        <div className="text-bold">{t("regularPrice")}</div>
        <div className="flex-row">
          <div>{t("priceRange")}</div>
          <div style={{ margin: "0rem 1rem" }}>{`฿ ${
            filterBody?.regular_price?.[0] ?? "xxx"
          }`}</div>
          <div style={{ marginRight: "1rem" }}>-</div>
          <div>{`฿ ${filterBody?.regular_price?.[1] ?? "xxx"}`}</div>
        </div>
        <Slider
          onChange={(value) => onChangeData({ regular_price: value })}
          min={0}
          max={30000}
          step={5}
          range
          value={filterBody?.regular_price?.value}
        />
      </div>
      <div className={classNameInput}>
        <div className="text-bold">{t("defaultSalePrice")}</div>
        <div className="flex-row">
          <div>{t("priceRange")}</div>
          <div style={{ margin: "0rem 1rem" }}>{`฿ ${
            filterBody?.sale_price?.[0] ?? "xxx"
          }`}</div>
          <div style={{ marginRight: "1rem" }}>-</div>
          <div>{`฿ ${filterBody?.sale_price?.[1] ?? "xxx"}`}</div>
        </div>
        <Slider
          onChange={(value) => onChangeData({ sale_price: value })}
          min={0}
          max={30000}
          step={5}
          range
          value={filterBody?.sale_price?.value}
          style={{ fontSize: "32px" }}
        />
      </div>
      <Select
        dataSource={PRODUCT_SUBSCRIPTION_STATUS}
        label={t("subscriptionStatus")}
        className={classNameInput}
        value={filterBody?.is_rent}
        onChange={(value) => {
          onChangeData({ is_rent: value });
        }}
        getLabel={(item) => t(item?.label)}
      />
    </div>
  );
};

export default ProductFilterForm;
