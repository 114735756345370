import AppContext from "@helpers/context";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import CartRuleConfigurationForm from "../component/CartRuleConfigurationForm";

export default function CartRuleCreateConfiguration() {
  const { cartRuleForm } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <div className="mt-3">
      <label>{t("configuration")}</label>
      <div className="p-4 border">
        <CartRuleConfigurationForm form={cartRuleForm} />
      </div>
    </div>
  );
}
