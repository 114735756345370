import { API } from "@network";
import {
  API_DEPOSIT_ACTIVITIES,
  API_DEPOSIT_COMMENT,
  API_PRINT_PRODUCT_LIST,
  API_RAW_MATERIAL_CATEGORY,
  API_RAW_MATERIAL_CHECK,
  API_RAW_MATERIAL_DETAIL,
  API_RAW_MATERIAL_LIST,
  API_RAW_MATERIAL_LIST_PRODUCT,
  API_RAW_MATERIAL_LIST_STOCK,
  API_RAW_MATERIAL_SUPPLIER,
  API_RAW_MATERIAL_UNITS,
  API_RAW_MATERIAL_UPDATE,
} from "../URL";

const RawMaterialAPI = {
  list: (body: any, paging: any) => {
    return API.post(
      `${API_RAW_MATERIAL_LIST}?page=${paging.pageIndex}&per_page=${paging.pageSize}`,
      body
    );
  },

  detail: (id: string) => {
    return API.post(API_RAW_MATERIAL_DETAIL, { id });
  },

  update: (body: any) => {
    return API.post(API_RAW_MATERIAL_UPDATE, body);
  },

  listStock: (id: string) => {
    return API.post(API_RAW_MATERIAL_LIST_STOCK, {
      raw_material_id: id,
    });
  },

  productAssignList: (body: any, paging: any) => {
    return API.post(
      `${API_RAW_MATERIAL_LIST_PRODUCT}?page=${paging?.pageIndex}`,
      body
    );
  },

  checkRawMaterial: (body: any) => {
    return API.post(API_RAW_MATERIAL_CHECK, body);
  },

  categoryList: () => {
    return API.get(API_RAW_MATERIAL_CATEGORY);
  },

  supplierList: () => {
    return API.get(API_RAW_MATERIAL_SUPPLIER);
  },

  unitList: () => {
    return API.get(API_RAW_MATERIAL_UNITS);
  },

  activityList: (body: any, currentPage: any) => {
    return API.post(`${API_DEPOSIT_ACTIVITIES}?page=${currentPage}`, body);
  },
  comment: (body: any) => {
    return API.post(API_DEPOSIT_COMMENT, body);
  },
};

export default RawMaterialAPI;
