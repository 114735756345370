import Drawer from "@components/shared/Drawer";
import { DEFAULT_PAGING } from "@constants/common";
import { exportToCSV } from "@helpers/file";
import { listProduct } from "@network/api/product";
import { Progress } from "antd";
import { find, join, map } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ProductFilterForm from "./ProductFilterForm";
import { PRODUCT_TYPES } from "@constants/product";
import { TimeUtils } from "d-react-components";

const ProductTableExportDrawer = ({
  open,
  onClose,
  onFilter,
  defaultFilter,
}: any) => {
  const [filterBody, setFilterBody] = useState(defaultFilter);
  const { t } = useTranslation();

  const onChangeData = (newData: any) => {
    setFilterBody({ ...filterBody, ...newData });
  };
  const [loadingExport, setLoadingExport] = useState(false);
  const [percentageExport, setPercentageExport] = useState(0);

  const onClickExport = async () => {
    setLoadingExport(true);
    setPercentageExport(0);
    let sendData: any = {};
    for (const key in filterBody) {
      if (key === "brand" || key === "category") {
        const idarray = map(filterBody[key], (item: any) => item.id);
        sendData[key] = idarray;
      } else {
        sendData[key] = filterBody[key];
      }
    }
    try {
      await onExport(sendData);
    } catch (err) {
      setLoadingExport(false);
      onClose();
    }
  };
  const onExport = async (body: any) => {
    let remainPage = true;
    let productListResult: any = [];
    let pageIndex = 1;
    while (remainPage) {
      const response = await listProduct(body, {
        pageIndex,
        pageSize: DEFAULT_PAGING.pageSize,
      });
      const inventoryList = response?.data?.data?.product ?? [];
      const paginationData = response?.data?.data?.pagination ?? {};
      const { currentPage, total } = paginationData;
      productListResult = productListResult.concat(inventoryList);
      if (currentPage === total) {
        remainPage = false;
        setPercentageExport(100);
      } else {
        pageIndex++;
        setPercentageExport(Math.round((currentPage / total) * 100));
      }
    }

    const fileDataInventory = convertToExportData(productListResult);

    exportToCSV(fileDataInventory, "ProductListExport");
    setLoadingExport(false);
    onClose();
  };

  const convertToExportData = (productList: any) => {
    return map(productList, (product) => {
      const productType = find(
        PRODUCT_TYPES,
        (item) => item.id === product.product_type
      );
      return {
        [t("itemName")]: product?.name ?? "",
        [t("sku")]: product?.sku ?? "",
        [t("regularPrice")]: product?.regular_price ?? "",
        [t("defaultSalePrice")]: product?.sale_price ?? "",
        [t("totalStock")]: product?.total_stock ?? "",
        [t("brand")]: product?.brand?.name ?? "",
        [t("category")]: product?.category?.name ?? "",
        [t("model")]: product?.model?.name ?? "",
        [t("productType")]: t(productType?.label ?? ""),
        [t("size")]: product?.size?.display_name ?? "",
        [t("thicknessInches")]: product?.thickness_height ?? "",
        [t("warrantyMonth")]: product?.warranty_period ?? "",
        [t("launchDate")]: product?.launch_date
          ? TimeUtils.toDate(product?.launch_date)
          : "N/A",
        [t("visibility")]: product?.visibility ? "Enabled" : "Disabled",
        [t("aliasSku")]: join(product?.alias_sku, ", "),
        [t("subscription")]: product?.is_rent ? "Enabled" : "Disabled",
      };
    });
  };

  const renderMainContent = () => {
    if (loadingExport) {
      return (
        <div className="progressExportContainer">
          <Progress className="progressExport" percent={percentageExport} />
        </div>
      );
    }
    return (
      <ProductFilterForm filterBody={filterBody} onChangeData={onChangeData} />
    );
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("export")}
      onSave={onClickExport}
    >
      {renderMainContent()}
    </Drawer>
  );
};

export default ProductTableExportDrawer;
