import { TranslatedSelect } from "@common/TranslatedSelect";
import { PRODUCT_ALIAS_IMPORT_METHOD } from "@constants/product";
import AppContext from "@helpers/context";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

const ProductAliasImportMethod = () => {
  const { step, formImport } = useContext(AppContext);
  const { t } = useTranslation();

  const { importType } = formImport?.values;

  const onChangeType = (value: any) => {
    formImport.setValues({ ...formImport?.values, importType: value });
  };

  return (
    <div className="p-4">
      <text>{t(step?.desc)}</text>
      <TranslatedSelect
        className="mt-3"
        placeholder={t("selectImportTypeHint")}
        dataSource={PRODUCT_ALIAS_IMPORT_METHOD}
        value={importType}
        onChange={onChangeType}
      />
    </div>
  );
};

export default ProductAliasImportMethod;
