import { Icon, InputText } from "d-react-components";
import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useTranslation } from "react-i18next";

const RangeQuantityItem = ({
  rangeItem,
  onChange,
  onDelete,
  moveCard,
  index,
}: any) => {
  const { t } = useTranslation();
  const onChangeQuantityRange = (key: any, rawValue: any) => {
    const value = parseInt(rawValue);
    if (key === "min" && value > rangeItem.max) {
      return;
    }

    if (key === "max" && value < rangeItem.min) {
      return;
    }

    const result = {
      ...rangeItem,
      [key]: value,
    };
    onChange(result);
  };

  const ref = useRef<any>(null);
  const [, drop] = useDrop({
    accept: "card",
    hover(item: any, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset: any = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: "card", id: rangeItem.id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));

  const inputClassName = "flex-grow-1 mx-2";

  return (
    <div className="flex-row mt-2 align-items-center" ref={ref}>
      <Icon name="reorder" />

      <InputText
        label={t("minQuantity")}
        value={`${rangeItem.min}`}
        onChange={(event) => onChangeQuantityRange("min", event.target.value)}
        className={inputClassName}
        type="number"
      />
      <InputText
        label={t("maxQuantity")}
        value={rangeItem.max}
        onChange={(event) => onChangeQuantityRange("max", event.target.value)}
        className={inputClassName}
        type="number"
      />
      <InputText
        label={t("getFreeQuantity")}
        value={rangeItem.free}
        onChange={(event) => onChangeQuantityRange("free", event.target.value)}
        className={inputClassName}
        type="number"
      />

      <Icon name="delete" onClick={onDelete} />
    </div>
  );
};

export default RangeQuantityItem;
