import AppContext from "@helpers/context";
import { updateOrderNote } from "@network/api/order";
import {
  InputText,
  Modal,
  Notifications,
  Button,
  Drawer,
} from "d-react-components";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

const OrderNoteDrawer = ({ open, onClose }: any) => {
  const { values, setFieldValue } = useContext(AppContext);
  const [noteContent, setNoteContent] = useState(values?.note);
  const { t } = useTranslation();

  // const onSaveNote = async () => {
  //   const body = {
  //     orderid: order.id,
  //     note: noteContent,
  //   };
  //   updateOrderNote(body)
  //     .then(() => {
  //       Notifications.showSuccess(t("editNoteSuccess"));
  //       setOpenModal(false);
  //       onReloadOrderData && onReloadOrderData();
  //     })
  //     .catch(() => {
  //       Notifications.showError(t("editNoteFail"));
  //     });
  // };

  return (
    <Drawer title={t("orderNote")} open={open} onClose={onClose} destroyOnClose>
      <div className="pb-10">
        <InputText
          label={t("noteName")}
          value={noteContent}
          onChange={(e) => setNoteContent(e.target.value)}
          multiple
          rows={5}
        />
      </div>
      <div className="position-absolute bottom-0 w-100 end-0 start-0 py-3 px-3 bg-white flex justify-content-end">
        <Button
          onClick={() => {
            setFieldValue("note", noteContent);
            onClose();
          }}
        >
          {t("save")}
        </Button>
      </div>
    </Drawer>
  );
};

export default OrderNoteDrawer;
