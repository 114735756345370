import Drawer from "@components/shared/Drawer";
import { OrderType } from "@constants/common";
import AppContext from "@helpers/context";
import { cancelContractDelivery, cancelDelivery } from "@network/api/delivery";
import { Notifications, InputText } from "d-react-components";
import { isEmpty } from "lodash";
import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const CancelDeliveryModal = ({
  openModal,
  onClose,
  deliveryTracking,
  orderType = OrderType.NORMAL,
}: any) => {
  const { onReloadOrderData, onReloadOrderDelivery } = useContext(AppContext);
  const remark = useRef("");
  const { t } = useTranslation();

  const onClickSaveUpdateDeliveryHanlde = async () => {
    if (isEmpty(remark.current)) {
      Notifications.showError(t("notification:needAllRequiredInfo"));
      return Promise.reject();
    }

    const body = {
      id: deliveryTracking.id,
      remark: remark.current,
    };

    const api =
      orderType === OrderType.NORMAL ? cancelDelivery : cancelContractDelivery;
    return api(body).then((res) => {
      onReloadOrderData && onReloadOrderData();
      onReloadOrderDelivery && onReloadOrderDelivery();
      Notifications.showSuccess(t("notification:cancelDeliverySuccessed"));
      onClose();
    });
  };

  return (
    <Drawer
      title={t("cancelDelivery")}
      open={openModal}
      onClose={() => onClose()}
      onSave={() => onClickSaveUpdateDeliveryHanlde()}
    >
      <div className="dateInputDeliveryStatus">
        <InputText
          placeholder={t("pleaseInputTheNoteForThisUpdate")}
          onChange={(event) => (remark.current = event.target.value)}
          className="mb-3"
          multiple
          rows={5}
          label={t("remark")}
        />
      </div>
    </Drawer>
  );
};

export default CancelDeliveryModal;
