import { useFormik } from "formik";
import { filter } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import UserImportFooter from "./UserImportFooter";
import UserImportResult from "./UserImportResult";
import UserImportSelectCSVFile from "./UserImportSelectCSVFile";
import UserImportSelectMethod from "./UserImportSelectMethod";
import UserImportValidation from "./UserImportValidation";
import UserAPI from "@network/api/user";
import { IMPORT_USER_STEPS } from "@constants/user";
import AppContext from "@helpers/context";
import { useTranslation } from "react-i18next";

export const INIT_IMPORT_FORM = {
  importFields: [],
  importType: null,
  fileImport: null,
};

const UserImport = () => {
  const [stepIndex, setStepIndex] = useState(0);
  const [importFieldSource, setImportFieldSource] = useState([]);
  const { t } = useTranslation();

  const step = useMemo(() => IMPORT_USER_STEPS[stepIndex], [stepIndex]);

  const formImport = useFormik({
    initialValues: INIT_IMPORT_FORM,
    validateOnBlur: false,
    validateOnChange: false,
  } as any);

  useEffect(() => {
    const { importType } = formImport?.values ?? {};
    UserAPI.getImportFields(importType).then((res) => {
      const fieldList = res?.data?.data?.fields ?? [];
      const requiredFields = filter(fieldList, (item) => item.required);
      if (importType === "create") {
        formImport.setFieldValue("importFields", fieldList);
      } else {
        formImport.setFieldValue("importFields", requiredFields);
      }
      setImportFieldSource(fieldList);
    });
  }, [formImport?.values?.importType]);

  const classNameHeader =
    "d-flex justify-content-between align-items-center p-4 border-bottom";

  const renderContent = () => {
    switch (stepIndex) {
      case 0:
        return <UserImportSelectMethod />;
      case 1:
        return <UserImportSelectCSVFile />;
      case 2:
        return <UserImportValidation />;
      case 3:
        return <UserImportResult />;
      default:
        return <div />;
    }
  };

  return (
    <AppContext.Provider
      value={{ step, formImport, stepIndex, importFieldSource, setStepIndex }}
    >
      <div className="bg-white">
        <div className={classNameHeader}>
          <label>{t(step?.title)}</label>
          <div className="bg-dark px-3 py-1">
            <small className="text-white">{`Step ${stepIndex + 1} of ${
              IMPORT_USER_STEPS.length
            }`}</small>
          </div>
        </div>
        {renderContent()}
        <UserImportFooter />
      </div>
    </AppContext.Provider>
  );
};

export default UserImport;
