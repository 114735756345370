import { BILLING_TYPE, OrderType, TYPE_OF_PLACE } from "@constants/common";
import AppContext from "@helpers/context";
import { getFullName } from "@helpers/string";
import { isEmpty, orderBy } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BillingAddressDrawer from "../BillingAddressDrawer";
import ShippingItemInfo from "./ShippingItemInfo";
import { ORDER_STATUS } from "@constants/order";
import { isDeliveryAllItems, isEnableEditShipping } from "./ShippingAddress";
import { ContractStatus } from "@constants/contract";

const INIT_BILLING = {
  fullname: "",
  nickname: "",
  address: "",
  province: "",
  postcode: "",
  phone: "",
  place: "",
  firstname: "",
  lastname: "",
  type: BILLING_TYPE.personal,
};

const getIntialBillingInfo = (billing: any) => {
  if (!billing) {
    return INIT_BILLING;
  }
  if (isEmpty(billing.type)) {
    return {
      ...INIT_BILLING,
      ...billing,
      type: BILLING_TYPE.personal,
    };
  }
  return billing;
};

const BillingInformation = ({
  orderType = OrderType.NORMAL,
}: {
  orderType?: OrderType;
}) => {
  const {
    order,
    onReloadOrderData,
    billing,
    customer,
    provinceList,
    loadProvinceList,
    editable,
    isCreateOrder,
    delivery = [],
    products,
    pickup = [],
    isPublic,
  } = useContext(AppContext);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [billingInfo, setBillingInfo] = useState({});
  const { t } = useTranslation();

  //********************************************************LOAD DATA ******************************** */

  useEffect(() => {
    setBillingInfo(getIntialBillingInfo(billing));
  }, [billing, customer]);

  const getPlaceText = (place: any) => {
    return TYPE_OF_PLACE[place];
  };

  const getContentBilling = () => {
    if (order && order.billing) {
      if (isEmpty(billing)) return <div />;
      const { address, postcode, phone, place, citizenid, taxid, email } =
        billing;
      const type = billing.type || billing.billing;
      return (
        <div className="flex flex-column min-w-0">
          <div className="mb-1 break-words">{getFullName(billing)}</div>
          <div className="mb-1 break-words">
            {t("type")}: {t(type)}
          </div>
          <div className="mb-1 break-words">{phone}</div>
          {type === BILLING_TYPE.personal && citizenid && (
            <div className="mb-1 break-words">{`${t("citizenId")}: ${
              citizenid ?? "N/A"
            }`}</div>
          )}
          <div className="mb-1 break-words">{email}</div>
          {type === BILLING_TYPE.business && taxid && (
            <div className="mb-1 break-words">{`${t("taxId")}: ${taxid}`}</div>
          )}
          <div className="mb-1 break-words">{address}</div>
          <div className="mb-1 break-words">{billing?.subdistrict?.name}</div>
          <div className="mb-1 break-words">{billing?.city?.name}</div>
          <div className="mb-1 break-words">{billing?.province?.name}</div>
          <div className="mb-1 break-words">{postcode}</div>
          <div className="mb-1 break-words">{t(getPlaceText(place))}</div>
        </div>
      );
    }

    return t("sameAsShipping");
  };

  const isCustomerShowAdd = (shippingItem: any) => {
    if (!editable || isPublic) {
      return false;
    }
    if (isEmpty(customer)) {
      return false;
    }
    if (orderType === OrderType.NORMAL) {
      if (
        order &&
        (order.status === ORDER_STATUS.pending.value ||
          order.status === ORDER_STATUS.pendingPaymentConfirmation.value ||
          order.status === ContractStatus.SIGNED)
      ) {
        return true;
      }
      if (
        order &&
        (order.status === ORDER_STATUS.processing.value ||
          order.status === ORDER_STATUS.deliveryProcessing.value)
      ) {
        const currentDelivery = orderBy(delivery, "created", "desc")?.[0];
        const isDeliveryAll = isDeliveryAllItems(products, delivery, pickup);
        if (currentDelivery) {
          return currentDelivery.status === "cancel";
        } else {
          if (!isDeliveryAll) {
            return true;
          }
        }
      }
    }
    if (orderType === OrderType.RENTAL) {
      return isEnableEditShipping(delivery);
    }
    return false;
  };

  return (
    <ShippingItemInfo
      title={t("billingInformation")}
      onClickEdit={
        isEmpty(customer) || isPublic
          ? false
          : () => {
              setOpenEditModal(true);
            }
      }
      isShowAdd={!isEmpty(customer)}
      isCustomerShowAdd={isCustomerShowAdd}
    >
      <div className="subTitle1">{getContentBilling()}</div>
      <AppContext.Provider
        value={{
          order,
          onReloadOrderData,
          billingProfile: billingInfo,
          isCreateOrder: false,
          setBillingProfile: setBillingInfo,
          customer,
          values: {
            selectedCustomer: [customer],
          },
          provinceList,
          loadProvinceList,
        }}
      >
        {openEditModal && (
          <BillingAddressDrawer
            open={openEditModal}
            onClose={() => setOpenEditModal(false)}
            orderType={orderType}
          />
        )}
      </AppContext.Provider>
    </ShippingItemInfo>
  );
};

export default BillingInformation;
