import { Avatar } from "d-react-components";
import React from "react";

const UserNameDropdown = ({ item }: { item: any }) => {
  return (
    <span className="flex align-center">
      <Avatar
        variant="square"
        className="w-4 h-4 object-cover mr-1"
        src={item.avatar}
        size="xx-small"
      />
      {`${item?.name}${
        item?.employee_id || item?.companyId
          ? ` (ID: ${item?.employee_id || item?.companyId})`
          : ""
      }`}
    </span>
  );
};

export default UserNameDropdown;
