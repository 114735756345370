import UploadAvatarModule from "@common/UploadAvatarModule";
import InputSelectForm from "@common/input/InputSelectForm";
import InputTextForm from "@common/input/InputTextForm";
import InputVerifier from "@common/input/InputVerifier";
import ChannelSelect from "@components/channel/components/ChannelSelect";
import { CUSTOMER_TYPES } from "@constants/customer";
import { GENDERS } from "@constants/user";
import { CustomerType } from "@interfaces/customer";
import classNames from "classnames";
import { DateInput } from "d-react-components";
import { isEmpty } from "lodash";
import moment from "moment";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useUpdateEffect } from "react-use";

interface ICustomerInput {
  customerForm: any;
  className?: any;
  defaultValue?: any;
}

const CustomerInputInfo = ({
  customerForm,
  className,
  defaultValue = {},
}: ICustomerInput) => {
  const formValues = customerForm?.values;
  const formErrors = customerForm?.errors;
  const { t } = useTranslation();

  const classNameField = "col-span-2";

  useUpdateEffect(() => {
    if (!isEmpty(defaultValue)) {
      if (
        formValues.email === defaultValue.email &&
        defaultValue.is_verify_email
      ) {
        customerForm.setFieldValue("is_verify_email", true);
        return;
      }
    }
    customerForm.setFieldValue("is_verify_email", false);
    customerForm.setFieldValue("otp_email_id", null);
  }, [formValues.email]);

  useUpdateEffect(() => {
    if (!isEmpty(defaultValue)) {
      if (
        formValues.email === defaultValue.email &&
        defaultValue.is_verify_phone
      ) {
        customerForm.setFieldValue("is_verify_phone", true);
        return;
      }
    }
    customerForm.setFieldValue("is_verify_phone", false);
    customerForm.setFieldValue("otp_phone_id", null);
  }, [formValues.phone]);

  return (
    <div className={classNames("grid grid-cols-4 gap-4 ", className)}>
      <div className="col-span-4">
        <UploadAvatarModule
          setAvatar={(value: any) =>
            customerForm.setFieldValue("avatar", value)
          }
        />
      </div>
      <div className="col-span-4">
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-1">
            <InputSelectForm
              dataSource={CUSTOMER_TYPES}
              keyData="customer_type"
              form={customerForm}
              className={classNameField}
              label={t("customerType")}
            />
          </div>
          <div className="col-span-1">
            <ChannelSelect
              className={classNameField}
              value={formValues.channel}
              error={formErrors.channel}
              onChange={(value) => customerForm.setFieldValue("channel", value)}
            />
          </div>
        </div>
      </div>

      {formValues.customer_type === CustomerType.INDIVIDUAL && (
        <>
          <InputTextForm
            form={customerForm}
            className={classNameField}
            keyData="firstname"
          />
          <InputTextForm
            form={customerForm}
            className={classNameField}
            keyData="lastname"
          />
          <InputTextForm
            form={customerForm}
            className={classNameField}
            keyData="nickname"
          />
          <InputTextForm
            form={customerForm}
            className={classNameField}
            keyData="citizen_id"
            label={t("citizenId")}
          />
          <InputSelectForm
            dataSource={GENDERS}
            keyData="gender"
            form={customerForm}
            className={classNameField}
            placeholder={t("pleaseSelect")}
          />
          <DateInput
            className={classNameField}
            onChange={(value) => {
              customerForm.setFieldValue("birthday", value);
            }}
            value={formValues?.birthday ? moment(formValues?.birthday) : null}
            label={t("birthday")}
            error={formErrors.birthday}
          />
          <InputTextForm
            form={customerForm}
            className={classNameField}
            keyData="phone"
            suffix={
              <InputVerifier
                type="phone"
                isVerified={formValues?.is_verify_phone}
                value={formValues?.phone}
                payload={{ channel_id: formValues?.channel?.id }}
                userId={formValues?.id}
                className="top-[2px]"
                onVerified={(otp) => {
                  customerForm.setFieldValue("is_verify_phone", true);
                  customerForm.setFieldValue("otp_phone_id", otp?.id);
                }}
              />
            }
          />
          <InputTextForm
            form={customerForm}
            className={classNameField}
            keyData="alternative_phone"
            label={t("alternativePhone")}
          />
          <InputTextForm
            form={customerForm}
            className={classNameField}
            keyData="email"
            suffix={
              <InputVerifier
                type="email"
                isVerified={formValues?.is_verify_email}
                value={formValues?.email}
                payload={{ channel_id: formValues?.channel?.id }}
                userId={formValues?.id}
                className="top-[2px]"
                onVerified={(otp) => {
                  customerForm.setFieldValue("is_verify_email", true);
                  customerForm.setFieldValue("otp_email_id", otp?.id);
                }}
              />
            }
          />
        </>
      )}
      {formValues.customer_type === CustomerType.BUSINESS && (
        <>
          <InputTextForm
            form={customerForm}
            className={classNameField}
            keyData="company"
            label={t("companyName")}
          />
          <InputTextForm
            form={customerForm}
            className={classNameField}
            keyData="tax_id"
            label={t("companyTax")}
          />
          <InputTextForm
            form={customerForm}
            className={classNameField}
            keyData="email"
            label={t("companyEmail")}
            suffix={
              <InputVerifier
                type="email"
                isVerified={formValues?.is_verify_email}
                value={formValues?.email}
                payload={{ channel_id: formValues?.channel?.id }}
                userId={formValues?.id}
                className="top-[2px]"
                onVerified={(otp) => {
                  customerForm.setFieldValue("is_verify_email", true);
                  customerForm.setFieldValue("otp_email_id", otp?.id);
                }}
              />
            }
          />
          <InputTextForm
            form={customerForm}
            className={classNameField}
            keyData="phone"
            label={t("companyPhone")}
          />
          <InputTextForm
            form={customerForm}
            className={classNameField}
            keyData="representative_first_name"
            label={t("representativeFirstname")}
          />
          <InputTextForm
            form={customerForm}
            className={classNameField}
            keyData="representative_last_name"
            label={t("representativeLastname")}
          />
          <InputTextForm
            form={customerForm}
            className={classNameField}
            keyData="representative_phone"
            label={t("representativePhone")}
          />
          <InputTextForm
            form={customerForm}
            className={classNameField}
            keyData="representative_email"
            label={t("representativeEmail")}
          />
        </>
      )}
      <InputTextForm
        form={customerForm}
        className="col-span-4"
        multiple
        keyData="note"
      />
    </div>
  );
};

export default CustomerInputInfo;
