import SignatureField from "@components/order/components/pickup/SignatureField";
import ProductName from "@components/product/components/ProductName";
import QuantityInputField from "@components/product/components/QuantityInputField";
import SearchGroupProducts from "@components/product/components/SearchGroupProducts";
import ButtonFileUpload from "@components/shared/ButtonFileUpload";
import Drawer from "@components/shared/Drawer";
import { ADMIN_AUTH_KEY } from "@constants";
import {
  DELIVERY_RETURN_STATUS,
  DELIVERY_RETURN_STATUS_LIST,
  DELIVERY_STOCK_STATUS,
} from "@constants/delivery";
import AppContext from "@helpers/context";
import { isGrantURLPermission } from "@helpers/permissions";
import {
  API_DELIVERY_STOCK_RETURN_CANCEL,
  API_DELIVERY_STOCK_RETURN_COMPLETE,
  API_DELIVERY_STOCK_RETURN_CONFIRM,
  API_DELIVERY_STOCK_RETURN_CREATE,
} from "@network/URL";
import {
  deliveryStockCancelReturn,
  deliveryStockCompleteReturn,
  deliveryStockConfirmReturn,
  deliveryStockCreateReturn,
} from "@network/api/delivery-stock";
import classNames from "classnames";
import {
  AwesomeTableComponent,
  Button,
  DialogManager,
  Icon,
  InputText,
  Notifications,
  ObjectUtils,
  Progress,
  TimeUtils,
  ViewLabelStatus,
} from "d-react-components";
import { useFormik } from "formik";
import { filter, find, map, replace } from "lodash";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "react-use";
import * as Yup from "yup";
import DeliveryConfirmedView from "../components/DeliveryConfirmedView";
import ReasonRefuseSelect from "../components/ReasonRefuseSelect";
import DeliveryReturnConfirmDrawer from "./DeliveryReturnConfirmDrawer";
import UserAvatarName from "@components/shared/UserAvatarName";

const confirmStockSchema = Yup.object().shape({
  driverSignature: Yup.string().required("Driver signature is required!"),
  supervisorSignature: Yup.string().required("Receiver signature is required!"),
  attachments: Yup.array()
    .required("Attachments is required!")
    .min(1, "Minimum 1 attachment"),
});

const createReturnSchema = Yup.object().shape({
  attachments: Yup.array()
    .required("Attachments is required!")
    .min(1, "Minimum 1 attachment"),
});

const CancelSchema = Yup.object().shape({
  remark: Yup.string().required("Remark is required!"),
});

const DeliveryStockCompleteReturnDrawer = ({ open, onClose }: any) => {
  const { deliveryDetail, loadDeliveryDetail } = useContext(AppContext);
  const { products, driver, driver_name } = deliveryDetail;
  const { t } = useTranslation();
  const [admin] = useLocalStorage<any>(ADMIN_AUTH_KEY, {});

  const columns = [
    {
      title: t("itemName"),
      dataIndex: "",
      render: (product: any) => {
        const leftQty =
          product?.quantity - product?.receive - product?.receiveQty;
        return (
          <ProductName
            item={{ product }}
            subBody={
              <div
                className={classNames("small", {
                  "text-warning": leftQty !== 0,
                  "text-success": leftQty === 0,
                })}
              >
                {t("leftQty") + ": " + leftQty}
              </div>
            }
          />
        );
      },
    },
    {
      title: t("toReceive"),
      dataIndex: "receiveQty",
      render: (receiveQty: any, product: any) => (
        <QuantityInputField
          quantity={receiveQty}
          onChangeQuantity={(pickupQtyParam: any) => {
            if (pickupQtyParam < 0) return;
            onChangeQuantity({ ...product, receiveQty: pickupQtyParam });
          }}
        />
      ),
    },
  ];

  const columnAdditionalProduct = [
    {
      title: t("itemName"),
      dataIndex: "",
      render: (product: any) => <ProductName item={{ product }} />,
    },
    {
      title: t("toReceive"),
      dataIndex: "receiveQty",
      render: (receiveQty: any, product: any) => (
        <QuantityInputField
          quantity={receiveQty}
          onChangeQuantity={(pickupQtyParam: any) => {
            if (pickupQtyParam < 0) return;
            onChangeOffListQuantity({ ...product, receiveQty: pickupQtyParam });
          }}
        />
      ),
    },
  ];

  const form = useFormik({
    initialValues: {
      products: map(
        filter(products, (item) => !!(item.quantity - item.receive)),
        (item) => ({
          ...item,
          receiveQty: 0,
        })
      ),
    } as any,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: confirmStockSchema,
    onSubmit: (values: any) => {
      const input = {
        id: deliveryDetail?.id,
        driver_signature_at_warehouse: values?.driverSignature,
        supervisor_signature_at_warehouse: values?.supervisorSignature,
        driver_fullname_at_warehouse: driver?.fullname ?? driver_name,
        remark_at_warehouse: values?.remark,
        proof_at_warehouse: map(values.attachments, (item) => item.key),
        receive: map(values.products, (item) => ({
          transfer_item_id: item.transfer_stock_product_id,
          quantity: item.receiveQty,
        })),
        additional_receive: map(values.offListProducts, (item) => ({
          product_id: item.id,
          quantity: item.receiveQty,
        })),
      };

      onCreateReturn(input);
    },
  });

  const formValues = form?.values;
  const formErrors = form?.errors;

  const onCreateReturn = (input: any) => {
    Progress.show(
      { method: deliveryStockCompleteReturn, params: [input] },
      () => {
        onClose && onClose();
        loadDeliveryDetail();
        Notifications.showSuccess(t("returnDeliveryCompletionConfirm"));
      }
    );
  };

  const onChangeQuantity = (newProduct: any) => {
    const result = ObjectUtils.updateArrayById(
      formValues?.products as any,
      newProduct
    );
    form.setFieldValue("products", result);
  };

  const onChangeOffListQuantity = (newProduct: any) => {
    const result = ObjectUtils.updateArrayById(
      formValues?.offListProducts as any,
      newProduct
    );
    form.setFieldValue("offListProducts", result);
  };

  const onAddAdditionalProduct = (products: any) => {
    const result = products
      .filter((item: any) => {
        const isOutListItem = filter(
          formValues.offListProducts,
          (transferItem: any) =>
            transferItem.productid === item.id &&
            transferItem.receive !== transferItem.quantity
        );
        return isOutListItem.length === 0;
      })
      .map((item: any) => ({ ...item, receive: item?.receive ?? 1 }));
    form.setFieldValue("offListProducts", result);
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      onSave={() => form.handleSubmit()}
      saveText={t("confirm")}
      size="auto"
      width="50%"
      title={t("receiveStock")}
    >
      <div>
        <div className="mt-3 label">{t("items")}</div>

        <AwesomeTableComponent
          dataSource={formValues?.products}
          columns={columns}
          pagination={false}
        />

        <div className="mt-3">
          <label className="mr-2"> {t("haveOtherProductDelivered")}</label>
          <SearchGroupProducts
            onChange={onAddAdditionalProduct}
            selectedProducts={formValues.offListProducts}
            stockConsider={false}
            buttonText={t("clickHere")}
            buttonProps={{ variant: "trans", iconName: "", size: "small" }}
          />
        </div>

        {formValues?.offListProducts?.length > 0 && (
          <AwesomeTableComponent
            className="mt-3"
            dataSource={formValues.offListProducts}
            columns={columnAdditionalProduct}
            // ref={(ref) => (additionalProTable.current = ref)}
            pagination={false}
          />
        )}

        <div className="mt-3">{t("sureCompleteReturn")}</div>

        <div className="mt-3 label">{t("driverSignature")}</div>

        {formValues.driverSignature && (
          <img
            className="w-100"
            src={formValues.driverSignature}
            style={{ height: 250, objectFit: "contain" }}
          />
        )}
        <SignatureField
          className="w-min-content mt-2"
          onSave={(signature: any) =>
            form.setFieldValue("driverSignature", signature)
          }
        />

        <InputText
          className="mt-3"
          label={t("driverFullName")}
          value={driver?.fullname ?? driver_name}
          disabled
        />

        <div className="mt-3 label">{t("supervisorSignature")}</div>

        {formValues.supervisorSignature && (
          <img
            className="w-100"
            src={formValues.supervisorSignature}
            style={{ height: 250, objectFit: "contain" }}
          />
        )}
        <SignatureField
          className="w-min-content mt-2"
          onSave={(signature: any) =>
            form.setFieldValue("supervisorSignature", signature)
          }
        />

        <InputText
          className="mt-3"
          label={t("supervisorFullName")}
          value={admin?.fullname}
          disabled
        />

        <div className="mt-3 label">{t("proof")}</div>
        <ButtonFileUpload
          containerClassName="mt-3"
          onChange={(value: any) => form.setFieldValue("attachments", value)}
        />

        <InputText
          className="mt-3"
          label={t("remark")}
          multiple
          value={formValues.remark}
          onChange={(event) => form.setFieldValue("remark", event.target.value)}
          placeholder={t("pleaseInputTheNoteForThisUpdate")}
        />
      </div>
    </Drawer>
  );
};

const DeliveryStockCreateReturnDrawer = ({ open, onClose }: any) => {
  const { deliveryDetail, loadDeliveryDetail } = useContext(AppContext);
  const { products, driver, driver_name } = deliveryDetail;
  const { t } = useTranslation();

  const columns = [
    {
      title: t("itemName"),
      dataIndex: "",
      render: (product: any) => <ProductName item={{ product }} />,
      width: 200,
    },
    {
      title: t("toReceive"),
      dataIndex: "quantity",
      width: 100,
    },
    {
      title: t("toBeReceived"),
      dataIndex: "receive",
      width: 100,
      render: (quantity: any) => <div className="text-success">{quantity}</div>,
    },
    {
      title: t("toReturn"),
      dataIndex: "",
      width: 100,
      render: (product: any) => (
        <div className="text-error">{product?.quantity - product?.receive}</div>
      ),
    },
  ];

  const form = useFormik({
    initialValues: {
      products,
    } as any,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: createReturnSchema,
    onSubmit: (values: any) => {
      const input = {
        id: deliveryDetail?.id,
        remark_at_store: values?.remark,
        reason: values?.reason?.id,
        proof_at_store: map(values.attachments, (item) => item.key),
      };

      onCreateReturn(input);
    },
  });

  const formValues = form?.values;
  const formErrors = form?.errors;

  const onCreateReturn = (input: any) => {
    Progress.show(
      { method: deliveryStockCreateReturn, params: [input] },
      () => {
        onClose && onClose();
        loadDeliveryDetail();
        Notifications.showSuccess(t("createReturnSuccessfully"));
      }
    );
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      onSave={() => form.handleSubmit()}
      saveText={t("create")}
      size="auto"
      width="50%"
      title={t("returnDelivery")}
    >
      <div>
        <div>{t("pleaseSpecificReason")}</div>
        <div className="mt-3 label">{t("items")}</div>

        <AwesomeTableComponent
          dataSource={formValues?.products}
          columns={columns}
          pagination={false}
        />
        <ReasonRefuseSelect
          className="mt-3"
          value={formValues?.reason}
          onChange={(value) => form.setFieldValue("reason", value)}
        />

        <div className="mt-3 label">{t("proof")}</div>
        <ButtonFileUpload
          containerClassName="mt-3"
          onChange={(value: any) => form.setFieldValue("attachments", value)}
        />

        <InputText
          className="mt-3"
          label={t("remark")}
          multiple
          value={formValues.remark}
          onChange={(event) => form.setFieldValue("remark", event.target.value)}
          placeholder={t("pleaseInput")}
        />
      </div>
    </Drawer>
  );
};

const DeliveryDetailReturnDelivery = () => {
  const { deliveryDetail, loadDeliveryDetail } = useContext(AppContext);

  const [openComplete, setOpenComplete] = useState(false);
  const [openCreateReturn, setOpenCreateReturn] = useState(false);
  const { t } = useTranslation();
  const [openCancel, setOpenCancel] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);
  const [openReject, setOpenReject] = useState(false);

  const {
    status,
    products,
    delivery_return,
    return_delivery_status_current,
    transfer,
    reason,
    return_delivery_confirm_status_remark,
    return_delivery_created_at,
    return_delivery_created_by,
  } = deliveryDetail ?? {};

  const signatureStoreOrigin = delivery_return?.signature_at_store ?? {};
  const signatureStoreWarehouse = delivery_return?.signature_at_warehouse ?? {};

  const isHideReturnControl =
    status !== DELIVERY_STOCK_STATUS.FAILED &&
    status !== DELIVERY_STOCK_STATUS.PARTIALLY;

  const hiddenCreateReturn =
    !isGrantURLPermission(API_DELIVERY_STOCK_RETURN_CREATE) ||
    isHideReturnControl ||
    return_delivery_status_current === DELIVERY_RETURN_STATUS.APPROVED ||
    return_delivery_status_current === DELIVERY_RETURN_STATUS.COMPLETED ||
    return_delivery_status_current === DELIVERY_RETURN_STATUS.REQUESTED;

  const isShowWarning =
    isGrantURLPermission(API_DELIVERY_STOCK_RETURN_CONFIRM) &&
    return_delivery_status_current === DELIVERY_RETURN_STATUS.APPROVED;

  const isShowConfirmDelivery =
    (status === DELIVERY_STOCK_STATUS.FAILED ||
      status === DELIVERY_STOCK_STATUS.PARTIALLY) &&
    return_delivery_status_current === DELIVERY_RETURN_STATUS.REQUESTED &&
    isGrantURLPermission(API_DELIVERY_STOCK_RETURN_CONFIRM);

  const isShowCompleteDelivery =
    return_delivery_status_current === DELIVERY_RETURN_STATUS.APPROVED &&
    isGrantURLPermission(API_DELIVERY_STOCK_RETURN_COMPLETE);

  const isHideCancel =
    return_delivery_status_current === "none" ||
    return_delivery_status_current !== DELIVERY_RETURN_STATUS.REQUESTED ||
    !isGrantURLPermission(API_DELIVERY_STOCK_RETURN_CANCEL);

  const cancelForm = useFormik({
    initialValues: {},
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: CancelSchema,
    onSubmit: (values) => {
      onClickCancelDelivery(values);
    },
  });

  const onClickCancelDelivery = (input: any) => {
    const body = {
      id: deliveryDetail?.id,
      return_delivery_status_remark: input?.remark,
    };
    Progress.show(
      { method: deliveryStockCancelReturn, params: [body] },
      (res: any) => {
        loadDeliveryDetail();
        Notifications.showSuccess(t("cancelReturnDeliverySuccess"));
        setOpenCancel(false);
      }
    );
  };

  const approveForm = useFormik({
    initialValues: {} as any,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values: any) => {
      const body = {
        id: deliveryDetail?.id,
        status: "approved",
        return_delivery_confirm_status_remark: values?.remark,
      };
      Progress.show(
        { method: deliveryStockConfirmReturn, params: [body] },
        (res: any) => {
          loadDeliveryDetail();
          Notifications.showSuccess(t("confirmReturnDeliverySuccess"));
          setOpenApprove(false);
        }
      );
    },
  });

  const rejectForm = useFormik({
    initialValues: {} as any,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      const body = {
        id: deliveryDetail?.id,
        status: "rejected",
        return_delivery_confirm_status_remark: values?.remark,
      };
      Progress.show(
        { method: deliveryStockConfirmReturn, params: [body] },
        (res: any) => {
          loadDeliveryDetail();
          Notifications.showSuccess(t("rejectReturnDeliverySuccess"));
          setOpenReject(false);
        }
      );
    },
  });

  const renderConfirmedReturn = () => {
    const returnReceiveProducts = map(
      delivery_return?.return_receive,
      (item) => {
        const product = find(
          products,
          (product) =>
            product.transfer_stock_product_id === item.transfer_item_id
        );
        return { ...product, quantity: item.receive };
      }
    );

    const offListReturnReceiveProducts = map(
      delivery_return?.return_receive_additional ?? [],
      (item) => ({
        ...item?.product,
        quantity: item.receive,
      })
    );

    return (
      <div className="bg-primary-20 p-3 mt-3">
        <DeliveryConfirmedView
          className="mt-3"
          userTitle={t("driver")}
          userFullName={signatureStoreWarehouse?.driver_fullname_at_warehouse}
          signature={signatureStoreWarehouse?.driver_signature_at_warehouse}
          signAt={TimeUtils.toDateTime(
            signatureStoreWarehouse?.signed_at_warehouse
          )}
          content={t("allItemReturnedOrigin")}
          products={returnReceiveProducts}
          offListProducts={offListReturnReceiveProducts}
          note={signatureStoreWarehouse?.remark_at_warehouse}
        />
        <DeliveryConfirmedView
          className="mt-3"
          userTitle={t("supervisor")}
          userFullName={
            signatureStoreWarehouse?.supervisor_fullname_at_warehouse
          }
          signature={signatureStoreWarehouse?.supervisor_signature_at_warehouse}
          signAt={TimeUtils.toDateTime(
            signatureStoreWarehouse?.signed_at_warehouse
          )}
          content={t("confirmedReceivedFromDriver")}
          products={returnReceiveProducts}
          offListProducts={offListReturnReceiveProducts}
          note={signatureStoreWarehouse?.remark_at_warehouse}
        />
      </div>
    );
  };

  const renderMainContent = () => {
    switch (return_delivery_status_current) {
      case DELIVERY_RETURN_STATUS.COMPLETED:
        return renderConfirmedReturn();
      case DELIVERY_RETURN_STATUS.REQUESTED:
      case DELIVERY_RETURN_STATUS.APPROVED:
        return (
          <DeliveryConfirmedView
            className="mt-3"
            products={filter(
              map(products, (item) => ({
                ...item,
                quantity: item.quantity - item.receive,
              })),
              (item) => item.quantity > 0
            )}
            note={signatureStoreOrigin?.remark_at_store}
            destination={transfer?.transfer_destination?.name}
            reason={reason?.name}
            attachments={map(
              signatureStoreOrigin?.proof,
              (item) => item?.attachment
            )}
          />
        );
      case DELIVERY_RETURN_STATUS.CANCELLED:
      case DELIVERY_RETURN_STATUS.REJECTED:
        return (
          <div className={classNames("bg-primary-20 p-3 mt-3")}>
            {`"${return_delivery_confirm_status_remark}"`}
          </div>
        );
      default:
        break;
    }
  };

  return (
    <div className="card-container mt-4 p-4">
      <div
        className="flex-row align-items-center bg-warning p-3 my-3"
        hidden={!isShowWarning}
      >
        <Icon name="warning" size="x-large" className="text-white" />
        <div className="flex-column ml-3">
          <div className="label text-white">{t("returnDelivery")}</div>
          <div className=" text-white small">{t("onConfirmToReturn")}</div>
        </div>
      </div>
      <div
        className="d-flex flex-column bg-primary-20 p-3 my-3"
        hidden={!isShowConfirmDelivery}
      >
        <label className="text-primary">{t("returnDeliveryRequested")}</label>
        <div className="mt-1 flex-row align-items-center">
          {replace(
            t("thereReturnRequestedAt"),
            "%time",
            TimeUtils.toDateTime(return_delivery_created_at)
          )}
          <UserAvatarName
            user={return_delivery_created_by}
            className="ml-2"
            size="xx-small"
          />
        </div>
        <div className="flex-row mt-2">
          <Button onClick={() => setOpenApprove(true)} className="mr-3">
            {t("approve")}
          </Button>
          <Button onClick={() => setOpenReject(true)} className="bg-error">
            {t("reject")}
          </Button>
        </div>
      </div>

      <label>{t("returnDelivery")}</label>
      <ViewLabelStatus
        status={return_delivery_status_current}
        listStatus={DELIVERY_RETURN_STATUS_LIST}
        getLabel={(item) => t(item.label)}
      />
      {renderMainContent()}

      {isShowCompleteDelivery && (
        <Button onClick={() => setOpenComplete(true)} className="mt-3">
          {t("completeReturnDelivery")}
        </Button>
      )}

      {!isHideCancel && (
        <Button onClick={() => setOpenCancel(true)} className="mt-3">
          {t("cancelReturnDelivery")}
        </Button>
      )}
      <Button
        onClick={() => setOpenCreateReturn(true)}
        className="mt-3"
        hidden={hiddenCreateReturn}
      >
        {t("createReturnDelivery")}
      </Button>
      {openComplete && (
        <DeliveryStockCompleteReturnDrawer
          open={openComplete}
          onClose={() => setOpenComplete(false)}
        />
      )}
      {openCreateReturn && (
        <DeliveryStockCreateReturnDrawer
          open={openCreateReturn}
          onClose={() => setOpenCreateReturn(false)}
        />
      )}

      {openCancel && (
        <DeliveryReturnConfirmDrawer
          open={openCancel}
          onClose={() => setOpenCancel(false)}
          description={t("certainToCancelReturnDelivery")}
          form={cancelForm}
        />
      )}

      {openApprove && (
        <DeliveryReturnConfirmDrawer
          open={openApprove}
          onClose={() => setOpenApprove(false)}
          description={t("certainToConfirmReturnDelivery")}
          form={approveForm}
        />
      )}
      {openReject && (
        <DeliveryReturnConfirmDrawer
          open={openReject}
          onClose={() => setOpenReject(false)}
          description={t("certainToRejectReturnDelivery")}
          form={rejectForm}
        />
      )}
    </div>
  );
};

export default DeliveryDetailReturnDelivery;
