import { includes } from "lodash";

export const addUrlParam = (search: string, key: string, val: string) => {
  const newParam = `${key}=${val}`;
  let params = `&${newParam}`;
  // If the “search” string exists, then build params from it
  if (search) {
    // Try to replace an existance instance
    params = search.replace(new RegExp(`([?&])${key}[^&]*`), `$1${newParam}`);
    // If nothing was replaced, then add the new param to the end
    if (params === search) {
      if (includes(search, "?")) {
        params += `&${newParam}`;
      } else {
        params += `?${newParam}`;
      }
    }
  }
  return params;
};
