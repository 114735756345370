import { API, APICallCenter, APICRM, APICS } from "@network";
import {
  API_ASSIGN_CALL,
  API_CALL_CENTER_DETAIL_TICKET,
  API_CALL_CENTER_INFORMATION,
  API_CALL_CENTER_LOG_CALL,
  API_CALL_CENTER_MAKE_CALL,
  API_CALL_CENTER_REPLIES_TICKET,
  API_CALL_CENTER_SEARCH_CALL,
  API_CALL_CENTER_SEARCH_ORDER,
  API_CALL_CENTER_SEARCH_TICKET,
  API_CALL_ORDER,
  API_CALL_ORDER_HISTORY,
} from "@network/URL";

export const getCallCenterInformation = () => {
  return API.get(API_CALL_CENTER_INFORMATION);
};

export const makeCall = (params: any) => {
  return API.post(API_CALL_CENTER_MAKE_CALL, params);
};

export const getOrderCallList = (payload?: any) => {
  return API.post(API_CALL_ORDER, payload || {});
};

export const getOrderCallHistory = (payload?: any) => {
  return API.post(API_CALL_ORDER_HISTORY, payload || {});
};

export const searchOrder = (payload: any) => {
  return APICallCenter.post(API_CALL_CENTER_SEARCH_ORDER, payload);
};

export const searchTicket = (params: any) => {
  return APICS.post(API_CALL_CENTER_SEARCH_TICKET, {}, { params });
};

export const getTicketDetail = (params: any) => {
  return APICS.post(API_CALL_CENTER_DETAIL_TICKET, {}, { params });
};

export const getTicketReplies = (params: any) => {
  return APICS.post(API_CALL_CENTER_REPLIES_TICKET, {}, { params });
};

export const searchCalls = (params: any) => {
  return APICRM.get(API_CALL_CENTER_SEARCH_CALL, { params });
};

export const getLogCalls = (id: string) => {
  return APICRM.get(API_CALL_CENTER_LOG_CALL.replace("{id}", id));
};

export const assignCall = (callId: string, orderId: string) => {
  return APICRM.put(
    API_ASSIGN_CALL.replace("{id}", callId),
    {},
    {
      params: {
        order_id: orderId,
      },
    }
  );
};
