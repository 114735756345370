import { ICustomer } from "@interfaces/customer";
import { IRowsKey, ViewRowInterchange } from "d-react-components";
import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { CallEvent } from "@interfaces/common";
import AppContext from "@helpers/context";
import { RecentPurchasingTable } from "./components/RecentPurchasingTable";
import { RecentSupportTicketTable } from "./components/RecentSupportTicketTable";
import { RecentCalls } from "./components/RecentCalls";

const CallingPopup = ({
  callerInfo: defaultCallerInfo,
}: {
  callerInfo: CallEvent | undefined;
}) => {
  const { t } = useTranslation();
  const [callerInfo, setCallerInfo] = useState(defaultCallerInfo);
  const { currentLang } = useContext(AppContext);
  const DETAILS_KEY: IRowsKey<ICustomer>[] = useMemo(
    () => [
      {
        id: "firstname",
        label: t("firstname"),
      },
      {
        id: "lastname",
        label: t("lastname"),
      },
      {
        id: "email",
        label: t("email"),
      },
      {
        id: "phone",
        label: t("phone"),
      },
    ],
    [t]
  );

  return (
    <AppContext.Provider value={{ callerInfo, setCallerInfo, currentLang }}>
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-1">
          <div className="bg-primary p-3">
            <div className="d-flex align-items-center flex-column w-100 py-5 px-3">
              <img
                src="/images/icons/telephone.svg"
                className="inline-block w-[75px] h-[75px] mb-4"
                alt=""
              />
              <h4 className="text-white">{callerInfo?.phone}</h4>
            </div>
            <ViewRowInterchange
              dataSource={callerInfo}
              keyList={DETAILS_KEY as any}
              variant="border"
              classNameLabel="font-weight-bold text-white"
              classNameContent="text-end text-white"
            />
          </div>
        </div>
        <div className="col-span-3">
          <StyledTable className="border p-3 mb-3">
            <RecentPurchasingTable />
          </StyledTable>
          <StyledTable className="border p-3 mb-3">
            <RecentSupportTicketTable />
          </StyledTable>
          <StyledTable className="border p-3">
            <RecentCalls />
          </StyledTable>
        </div>
      </div>
    </AppContext.Provider>
  );
};

export const StyledTable: any = styled.div`
  max-width: ${(props: any) => props.historyType === "all" ? `calc(100vw - 120px)` : "1120px"};
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 8px 16px;
  }
`;

export default CallingPopup;
