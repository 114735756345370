import {
  Button,
  DialogManager,
  Notifications,
  Progress,
  TabBar,
} from "d-react-components";
import React, { useEffect, useMemo, useState } from "react";
import UserDetailAuthentication from "./UserDetailAuthentication";
import UserDetailSpecialAccess from "./UserDetailSpecialAccess";
import UserDetailChannel from "./UserDetailChannel";
import UserDetailDashboard from "./UserDetailDashboard";
import UserDetailInfo from "./UserDetailInfo";
import UserDetailOrderManagement from "./UserDetailOrderManagement";
import UserDetailPassword from "./UserDetailPassword";
import UserDetailPersonal from "./UserDetailPersonal";
import UserDetailRole from "./UserDetailRole";
import UserDetailSetting from "./UserDetailSetting";
import UserDetailWarehouse from "./UserDetailWarehouse";
import UserDetailSOCollaboration from "./UserDetailSOCollaboration";
import { isGrantURLPermission } from "@helpers/permissions";
import { API_USER_LOGIN_BY_CODE_DETAIL } from "@network/URL";
import AppContext from "@helpers/context";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import UserAPI from "@network/api/user";
import { Path } from "@components/layout/Path";
import HeaderDetail from "@common/HeaderDetail";
import classNames from "classnames";

const UserDetail = () => {
  const [user, setUser] = useState<any>(null);
  const { t } = useTranslation();
  const { userId } = useParams();
  const navigate = useNavigate();

  const TABS = useMemo(
    () => [
      {
        id: "dashboard",
        label: t("dashboard"),
        component: <UserDetailDashboard />,
      },
      {
        id: "personal",
        label: t("personal"),
        component: <UserDetailPersonal />,
      },

      {
        id: "password",
        label: t("password"),
        component: <UserDetailPassword />,
      },
      {
        id: "role",
        label: t("role"),
        component: <UserDetailRole />,
      },

      {
        id: "channel",
        label: t("channel"),
        component: <UserDetailChannel />,
      },
      {
        id: "warehouse",
        label: t("warehouse"),
        component: <UserDetailWarehouse />,
      },
      {
        id: "orderManagement",
        label: t("orderManagement"),
        component: <UserDetailOrderManagement />,
      },
      {
        id: "soCollaboration",
        label: t("soCollaboration"),
        component: <UserDetailSOCollaboration />,
      },
      {
        id: "settings",
        label: t("settings"),
        component: <UserDetailSetting />,
      },
      {
        id: "authenticate",
        label: t("authentication"),
        component: <UserDetailAuthentication />,
      },
      ...(isGrantURLPermission(API_USER_LOGIN_BY_CODE_DETAIL)
        ? [
            {
              id: "specialAccess",
              label: t("specialAccess"),
              component: <UserDetailSpecialAccess />,
            },
          ]
        : ([{}] as any)),
    ],
    [t]
  );
  const [tab, setTab] = useState<any>(TABS[0]);

  useEffect(() => {
    loadUserDetail();
  }, []);

  const loadUserDetail = () => {
    const body = {
      id: userId ?? null,
    };
    Progress.show({ method: UserAPI.detail, params: [body] }, (res: any) => {
      const user = res?.data?.data?.user;
      setUser(user);
    });
  };

  if (!user) return <div />;

  const onClickDuplicate = () => {
    DialogManager.showConfirm(
      t("duplicateConfirmation"),
      t("duplicateConfirmationDescription"),
      () => onDuplicate()
    );
  };

  const onDuplicate = () => {
    const body = { admin_id: userId };
    Progress.show({ method: UserAPI.duplicate, params: [body] }, (res: any) => {
      const id = res?.data?.data?.id;
      Notifications.showSuccess(t("duplicateUserSuccess"));
      navigate(generatePath(Path.USER_DETAIL, { id }));
    });
  };

  return (
    <AppContext.Provider value={{ user, loadUserDetail }}>
      <div className="flex-column-container bg-trans">
        <HeaderDetail
          title={user?.fullname}
          rightSide={
            <div className="d-flex">
              <Button onClick={onClickDuplicate} size="small" className="mr-3">
                {t("duplicate")}
              </Button>
              <Button
                variant="outline"
                size="small"
                onClick={() => window.history.back()}
              >
                {t("back")}
              </Button>
            </div>
          }
        />
        <div className="p-3 bg-white">
          <div className="grid grid-cols-4 gap-4">
            <div className="col-span-4 md:col-span-1">
              <UserDetailInfo />
            </div>
            <div className="col-span-4 md:col-span-3">
              <TabBar
                dataSource={TABS}
                onChange={setTab}
                value={tab}
                classNameItem={classNames(
                  "bg-white border-right-0 border-left-0 border-top-0"
                )}
              />
              {tab.component}
            </div>
          </div>
        </div>
      </div>
    </AppContext.Provider>
  );
};

export default UserDetail;
