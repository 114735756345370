import React from "react";
import { Icon } from "d-react-components";

const UploadFileItem = ({
  source,
  onClickRemove,
  onClick,
  isRemovable = true,
}: any) => {
  return (
    <div className="relative">
      {isRemovable && (
        <Icon
          name="remove_circle"
          className="text-error-500 cursor-pointer absolute -top-2 -right-2"
          onClick={() => onClickRemove()}
        />
      )}
      <img
        className="w-20 h-20 object-cover"
        src={source}
        onClick={() => onClick && onClick()}
        alt=""
      />
    </div>
  );
};

export default UploadFileItem;
