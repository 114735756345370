import Drawer from "@components/shared/Drawer";
import AppContext from "@helpers/context";
import { isGrantURLPermission } from "@helpers/permissions";
import {
  approveCustomSizeDetected,
  mtApproveSuspicious,
} from "@network/api/order";
import {
  API_CANCEL_ORDER,
  API_ORDER_APPROVE_CUSTOM_SIZE,
  API_ORDER_MT_APPROVE_SUSPICIOUS,
} from "@network/URL";
import { Button, InputText, Notifications } from "d-react-components";
import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CancelOrder from "../detail/modal/CancelOrder";

type WarningType = "suspicious" | "customSize";

const OrderDetailMTConfirm = ({
  type = "suspicious",
}: {
  type?: WarningType;
}) => {
  const { order, onReloadOrderData, editable } = useContext(AppContext);
  const [openModal, setOpenModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const remarkRef = useRef();
  const { t } = useTranslation();

  const onApproveOrder = async () => {
    const body = {
      order_id: order?.id,
      remark: remarkRef.current,
    };
    if (type === "suspicious") {
      return mtApproveSuspicious(body)
        .then(() => {
          onReloadOrderData();
          Notifications.showSuccess(
            t("notification:approvedOrderSuspiciousSuccessfully")
          );
        })
        .catch((error) => {
          Notifications.showError(error?.message);
        });
    } else {
      return approveCustomSizeDetected(body)
        .then(() => {
          onReloadOrderData();
          Notifications.showSuccess(
            t("notification:approvedOrderCustomSizeSuccessfully")
          );
        })
        .catch((error) => {
          Notifications.showError(error?.message);
        });
    }
  };

  const renderButtons = () => {
    if (!editable) {
      return <></>;
    }
    return (
      <div className="my-3 flex-center-y">
        <Button
          className="mr-3"
          color="primary"
          onClick={() => setOpenModal(true)}
        >
          {t("approve")}
        </Button>
        <Button color="secondary" onClick={() => setOpenCancelModal(true)}>
          {t("cancelOrder")}
        </Button>
        {openCancelModal && (
          <CancelOrder
            openModal={openCancelModal}
            setOpenModal={setOpenCancelModal}
          />
        )}
      </div>
    );
  };

  return (
    <>
      {type === "suspicious" && (
        <div className="p-3 mb-3 bg-amber-100">
          <label>{t("suspiciousDetected")}</label>
          <div>{t("orderModernTradeSuspiciousWarning")}</div>
          {isGrantURLPermission(API_CANCEL_ORDER) &&
            isGrantURLPermission(API_ORDER_MT_APPROVE_SUSPICIOUS) &&
            renderButtons()}
        </div>
      )}
      {type === "customSize" && (
        <div className="p-3 mb-3 bg-red-100">
          <label>{t("customSizeDetected")}</label>
          <div>{t("customSizeWarning")}</div>
          {isGrantURLPermission(API_CANCEL_ORDER) &&
            isGrantURLPermission(API_ORDER_APPROVE_CUSTOM_SIZE) &&
            renderButtons()}
        </div>
      )}
      {openModal && (
        <Drawer
          open={openModal}
          onClose={() => setOpenModal(false)}
          title={t("approve")}
          onSave={onApproveOrder}
        >
          <div className="subTitle1">
            <div className="mb-3">
              {t("notification:areYouSureWantToApproveThisOder")}
            </div>
            <InputText
              multiple
              rows={5}
              placeholder={t("pleaseInputTheNote")}
              onChange={(event) => (remarkRef.current = event?.target?.value)}
              label={t("remark")}
            />
          </div>
        </Drawer>
      )}
    </>
  );
};

export default OrderDetailMTConfirm;
