import { Select as DSelect, SelectProps } from "d-react-components";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export const TranslatedSelect: React.FC<Partial<SelectProps>> = ({
  dataSource,
  getLabel,
  ...props
}) => {
  const { t } = useTranslation();
  const options = useMemo(() => {
    return dataSource?.map((item) => ({
      ...item,
      label: t(getLabel ? getLabel(item) : item.label),
    }));
  }, [t, dataSource]);

  return <DSelect {...props} dataSource={options} />;
};
