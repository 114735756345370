import AppContext from "@helpers/context";
import {
  getGPEventWarehouseList,
  updateGPEventWarehouse,
} from "@network/api/gp-event";
import {
  AwesomeTableComponent,
  Button,
  DialogManager,
  Icon,
  Progress,
} from "d-react-components";
import { map } from "lodash";
import { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import GPEventDetailWarehouseUpdateModal from "./GPEventDetailWarehouseUpdateModal";

const GPEventDetailWarehouse = () => {
  const { eventDetail, eventChannelList } = useContext(AppContext);
  const [openAdd, setOpenAdd] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const { t } = useTranslation();

  const tableRef = useRef<any>();
  const columns = [
    {
      title: t("no"),
      dataIndex: "no",
      render: (no: any, item: any, index: any) => index + 1,
    },
    {
      title: t("name"),
      dataIndex: "name",
      render: (name: any, item: any) => <a href={item?.editurl}>{name}</a>,
    },
    {
      title: t("code"),
      dataIndex: "code",
    },
  ];

  const source = (paging: any) => {
    const body = {
      id: eventDetail.id,
      action: "in",
    };
    return getGPEventWarehouseList(body, paging);
  };

  const transformer = (res: any) => res?.data?.data?.warehouse ?? [];

  const onErrorAddWarehouse = (error: any) => {
    const errorList = error?.response?.data?.data ?? [];
    DialogManager.showConfirm(
      t("error"),
      <div className="d-flex align-items-center flex-wrap">
        <Icon name="error_outline" className="text-error" />
        <div className="ml-2">{`${errorList?.length} items currently exist in another on-going GP Events`}</div>
        <div
          className="text-primary cursor-pointer ml-1"
          // onClick={() => FileUtils.exportToCSV(errorList, "Import Error Log")}
        >
          {t("downloadLog")}
        </div>
      </div>
    );
    return true;
  };

  const onAddWarehouse = (warehouseList: any) => {
    const body = {
      id: eventDetail.id,
      action: "add",
      warehouse: map(warehouseList, (item) => item.id),
    };
    Progress.show(
      { method: updateGPEventWarehouse, params: [body] },
      () => {
        tableRef.current.refresh();
        setOpenAdd(false);
      },
      onErrorAddWarehouse
    );
  };

  const onRemoveWarehouse = (warehouses: any) => {
    const body = {
      id: eventDetail.id,
      action: "remove",
      warehouse: map(warehouses, (item) => item.id),
    };
    Progress.show({ method: updateGPEventWarehouse, params: [body] }, () => {
      tableRef.current.refresh();
      setOpenRemove(false);
    });
  };

  if (eventChannelList?.length === 0) {
    return (
      <div className="card-container flex-center p-4 py-5 small mt-3">
        {t("beforeSelectWarehouseConfig")}
      </div>
    );
  }

  return (
    <div className="card-container p-4 ">
      <div className="d-flex justify-content-between align-items-center">
        <label>{t("warehouse")}</label>
        <div className="d-flex">
          <Button
            onClick={() => setOpenAdd(true)}
            className="ml-3"
            variant="trans"
          >
            {t("add")}
          </Button>
          <Button onClick={() => setOpenRemove(true)} variant="trans">
            {t("remove")}
          </Button>
        </div>
      </div>
      <AwesomeTableComponent
        ref={(ref) => {
          tableRef.current = ref;
        }}
        columns={columns}
        source={source}
        transformer={transformer}
        className="mt-3 height-fit-content"
      />
      {openAdd && (
        <GPEventDetailWarehouseUpdateModal
          open={openAdd}
          onClose={() => setOpenAdd(false)}
          eventId={eventDetail?.id}
          onSave={onAddWarehouse}
          type="not_in"
          title={t("addWarehouse")}
        />
      )}
      {openRemove && (
        <GPEventDetailWarehouseUpdateModal
          open={openRemove}
          onClose={() => setOpenRemove(false)}
          eventId={eventDetail?.id}
          onSave={onRemoveWarehouse}
          type="in"
          title={t("removeWarehouse")}
        />
      )}
    </div>
  );
};

export default GPEventDetailWarehouse;
