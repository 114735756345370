import { Path } from "@components/layout/Path";
import Drawer from "@components/shared/Drawer";
import { IWarehouse } from "@interfaces/warehouse";
import WarehouseAPI from "@network/api/warehouse";
import { AwesomeTableComponent, InputTextSearch } from "d-react-components";
import { debounce, map } from "lodash";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, generatePath } from "react-router-dom";

function WarehouseDetailDeliveryUpdateDrawer({
  open,
  onClose,
  title,
  warehouseId,
  type,
  onSave,
}: any) {
  const tableRef = useRef<any>();
  const textSearch = useRef("");
  const [deliverySelect, setDeliverySelect] = useState<any>([]);
  const { t } = useTranslation();

  const columns = [
    {
      title: t("name"),
      dataIndex: "name",
      render: (name: string, warehouse: IWarehouse) => (
        <Link
          to={generatePath(Path.WAREHOUSE_DETAIL, {
            warehouseId: warehouse?.id,
          })}
        >
          {name}
        </Link>
      ),
    },
    {
      title: t("code"),
      dataIndex: "code",
    },
  ];

  const source = (paging: any) => {
    const body = {
      action: type,
      id: warehouseId,
      search: textSearch.current,
      is_warehouse_delivery: true,
    };
    return WarehouseAPI.deliveryList(body, paging);
  };

  const transformer = (res: any) => {
    return res?.data?.data?.delivery ?? [];
  };

  const onChangeTextSearch = debounce((text) => {
    textSearch.current = text;
    tableRef.current.refresh();
  }, 300);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={title}
      onSave={() => onSave(deliverySelect)}
      size="auto"
      width="30vw"
    >
      <InputTextSearch
        className="mt-3"
        placeholder={t("search")}
        onChange={(e: any) => onChangeTextSearch(e.target.value)}
      />
      <AwesomeTableComponent
        ref={(ref) => {
          tableRef.current = ref;
        }}
        columns={columns}
        source={source}
        transformer={transformer}
        className="mt-3"
        rowSelection={{
          selectedRowKeys: map(deliverySelect, (item) => item.id),
          onChange: (selectedRowKeys, selectRows) => {
            setDeliverySelect(selectRows);
          },
          preserveSelectedRowKeys: true,
        }}
      />
    </Drawer>
  );
}

export default WarehouseDetailDeliveryUpdateDrawer;
