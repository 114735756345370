import ModalSelectCustomer from "@components/order/detail/modal/ModalSelectCustomer";
import { ORDER_STATUS } from "@constants/order";
import AppContext from "@helpers/context";
import { isGrantURLPermission } from "@helpers/permissions";
import {
  searchShippingCustomer,
  updateBillingAddress,
  updateOrderShipping,
} from "@network/api/address";
import {
  deleteCustomerOrder,
  editCustomerContact,
} from "@network/api/customer";
import { updateCustomerOrder } from "@network/api/order";
import { API_DELETE_CUSTOMER_ORDER } from "@network/URL";
import { Spin } from "antd";
import { Notifications, DialogManager, Progress } from "d-react-components";
import { isEmpty } from "lodash";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
// import ModalSelectCustomer from "../../components/selectCustomer/ModalSelectCustomer";
import CustomerInfoComponent from "./CustomerInfoComponent";
import ShippingItemInfo from "./ShippingItemInfo";
import Order from "@components/order/OrderTable";
import { OrderType } from "@constants/common";

const CustomerNameInfo = () => {
  const {
    customer,
    updateCustomerInfo,
    order,
    onReloadOrderData,
    isCreateOrder,
    editable,
    type,
    isPublic,
  } = useContext(AppContext);
  const [openModal, setOpenModal] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const { t } = useTranslation();

  const onSaveCustomer = async (customerSelect: any) => {
    const body = {
      id: order.id,
      customerid: customerSelect.id,
    };
    Progress.show(
      { method: updateCustomerOrder, params: body },
      (res) => {
        Notifications.showSuccess(t("notification:updateCustomerSuccess"));
        onSuccessUpdateCustomer(customerSelect);
      },
      (err: any) => {
        Notifications.showError(err?.response?.data?.message || err);
      }
    );
  };

  const onSuccessUpdateCustomer = (customer: any) => {
    DialogManager.showConfirm(
      t("confirm"),
      t("notification:wantToApplyToShipping"),
      () => onApplyInfoToShippingInfo(customer),
      () => {
        setOpenModal(false);
        onReloadOrderData && onReloadOrderData();
      }
    );
  };

  const onApplyInfoToShippingInfo = async (customer: any) => {
    if (!customer?.id) {
      return Promise.reject();
    }
    return searchShippingCustomer(customer?.id).then((res) => {
      const shipping = res?.data?.data?.shipping?.[0];
      const bodyShipping = {
        ...shipping,
        city: shipping?.city?.id,
        province: shipping?.province?.id,
        subdistrict: shipping?.subdistrict?.id,
        orderid: order?.id,
      };

      const bodyContact = {
        firstname: customer?.firstname,
        lastname: customer?.lastname,
        email: customer?.email,
        phone: customer?.phone,
        orderid: order.id,
      };

      const bodyBilling = {
        orderid: order.id,
        status: false,
      };
      const APIList = isEmpty(shipping)
        ? [editCustomerContact(bodyContact), updateBillingAddress(bodyBilling)]
        : [
            editCustomerContact(bodyContact),
            updateOrderShipping(bodyShipping),
            updateBillingAddress(bodyBilling),
          ];
      Promise.all(APIList).then(
        (res) => {
          Notifications.showSuccess(t("notification:updateOrderInfoSuccess"));
          onReloadOrderData && onReloadOrderData();
          setOpenModal(false);
        },
        (err) => {
          Notifications.showError(err?.response?.data?.message || err);
        }
      );
    });
  };

  const onClickRemove = () => {
    DialogManager.showConfirm(
      t("confirm"),
      t("notification:wantToRemoveCustomer"),
      () => onRemoveCustomer()
    );
  };

  const onRemoveCustomer = () => {
    setLoadingRemove(true);
    const body = {
      id: order.id,
    };
    deleteCustomerOrder(body)
      .then((res) => {
        setLoadingRemove(false);
        onReloadOrderData && onReloadOrderData();
        Notifications.showSuccess(t("notification:removeCustomerOrderSuccess"));
      })
      .catch((err) => {
        setLoadingRemove(false);
      });
  };

  const isShowRemoveCustomer = () => {
    if (!customer || isCreateOrder || !editable || type === OrderType.RENTAL) {
      return false;
    }
    const isGrantPermission = isGrantURLPermission(API_DELETE_CUSTOMER_ORDER);
    if (!isGrantPermission) {
      return false;
    }

    if (
      !order ||
      (order.status !== ORDER_STATUS.pending.value &&
        order.status !== ORDER_STATUS.pendingPaymentConfirmation.value &&
        order.status !== ORDER_STATUS.partiallyPaid.value)
    ) {
      return false;
    }
    return true;
  };
  return (
    <>
      <ShippingItemInfo
        title={t("customer")}
        {...(type === OrderType.NORMAL &&
          !isPublic && {
            onClickEdit: () => {
              setOpenModal(true);
            },
          })}
      >
        <CustomerInfoComponent
          customer={customer}
          channel={order?.channel}
          showContactVerifier={type === OrderType.RENTAL}
          onVerifiedInfo={(info: any) =>
            updateCustomerInfo && updateCustomerInfo(info)
          }
        />
        {isShowRemoveCustomer() && !isPublic && (
          <div
            className="subTitle2 buttonRemove cursor-pointer text-red-500 m-0"
            onClick={onClickRemove}
          >
            {loadingRemove ? <Spin size="default" /> : t("remove")}
          </div>
        )}
      </ShippingItemInfo>
      {openModal && (
        <ModalSelectCustomer
          open={openModal}
          onClose={() => setOpenModal(false)}
          onSave={onSaveCustomer}
          defaultSelected={customer}
        />
      )}
    </>
  );
};

export default CustomerNameInfo;
