import Drawer from "@components/shared/Drawer";
import UserAvatarName from "@components/shared/UserAvatarName";
import { getProductionPrintingHistoryListNew } from "@network/api/product-print";
import classNames from "classnames";

import { AwesomeTableComponent, TimeUtils } from "d-react-components";
import { debounce } from "lodash";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

interface IPrintHistoryDrawer {
  open: boolean;
  id: string;
  onClose: () => void;
}
function ProductionPrintingHistoryDrawer({
  open,
  onClose,
  id,
}: IPrintHistoryDrawer) {
  const tableRef = useRef<any>();
  const textSearch = useRef();
  const [pagination, setPagination] = useState<any>();

  const { t } = useTranslation();

  const columns = [
    {
      title: t("date"),
      dataIndex: "created",
      render: (created: number, item: any, index: number) => (
        <div>{TimeUtils.toDateTime(created)}</div>
      ),
    },
    {
      title: t("activity"),
      dataIndex: "by",
      render: (by: any, item: any, index: number) => (
        <div className="flex items-center">
          <UserAvatarName
            user={by}
            size="xx-small"
            variant="square"
            className="mr-1"
          />
          {item.is_printed
            ? t("reprintedProductionPrinting")
            : t("printedProductionPrinting")}
        </div>
      ),
    },
  ];

  const onChangeText = debounce((text) => {
    textSearch.current = text;
    tableRef.current.refresh();
  }, 500);

  const source = (paging: any) => {
    return getProductionPrintingHistoryListNew(id, paging);
  };

  const transformer = (res: any) => {
    const pagination = res?.data?.data?.pagination;
    setPagination(pagination);
    return res?.data?.data?.history ?? [];
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("history")}
      size="auto"
      width={"80%"}
    >
      <AwesomeTableComponent
        ref={(ref) => {
          tableRef.current = ref;
        }}
        columns={columns}
        source={source}
        transformer={transformer}
        className="mt-3"
      />
    </Drawer>
  );
}

export default ProductionPrintingHistoryDrawer;
