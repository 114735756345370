import { InputText, Modal, Progress, Select } from "d-react-components";
import * as Yup from "yup";
import { useFormik } from "formik";
import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  NUMBER_INSTALMENT,
  OFFLINE_PAYMENT_METHOD,
  PAYMENT_2C2P,
  PAYMENT_COD,
  PAYMENT_DEFAULT,
  PAYMENT_MODERN_TRADE,
} from "@constants/payment";
import AppContext from "@helpers/context";
import { modernTradeBankList } from "@network/api/store";
import { orderPaymentUpdateMethod } from "@network/api/order";
import { useTranslation } from "react-i18next";
import Drawer from "@components/shared/Drawer";
import { isUndefined } from "lodash";
import { MAX_ORDER_VALUE_ALLOW_COD } from "@constants/order";

const ModalChangePaymentMethod = ({
  open,
  onClose,
  payment,
  onUpdated,
  orderValue,
  store,
  isCreateOrder,
}: any) => {
  const {
    paymentMethodsList,
    paymentOptions,
    loadPaymentOptions,
    order,
    loadOrderPaymentMethods,
  } = useContext(AppContext);
  const [mtBankList, setMTBankList] = useState([]);
  const { t } = useTranslation();

  const ChangeMethodSchema = useMemo(
    () =>
      Yup.lazy((value) => {
        let schema = Yup.object().shape({
          remark: Yup.string(),
        });
        if (value.method === PAYMENT_MODERN_TRADE) {
          schema = schema.concat(
            Yup.object().shape({
              numberInstalment: Yup.number().required(t("fieldRequired")),
            })
          );
        }
        return schema;
      }),
    [t]
  );

  const methodForm = useFormik<any>({
    initialValues: payment
      ? {
          ...(payment.method === PAYMENT_DEFAULT.id
            ? {}
            : { method: payment.method }),
          paymentOption: payment.payment_option?.id,
          remark: payment.remark || payment.comment,
          ...(payment.installment_loan_tenure
            ? { numberInstalment: payment.installment_loan_tenure }
            : {}),
          ...(payment.bank_name_id ? { bankName: payment.bank_name_id } : {}),
        }
      : {},
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: ChangeMethodSchema,
    onSubmit: (values: any) => {
      if (payment?.id) {
        const body = {
          split_id: payment?.id,
          method: values.method,
          installment_loan_tenure: values.numberInstalment,
          remark: values.remark,
          bank_name_id: values.bankName,
          payment_option: values.paymentOption,
        };
        Progress.show(
          { method: orderPaymentUpdateMethod, params: body },
          onUpdated
        );
      } else {
        const body = {
          method: values.method,
          remark: values.remark,
          ...(values.method === PAYMENT_MODERN_TRADE
            ? {
                bank_name_id: values.bankName,
                installment_loan_tenure: values.numberInstalment,
              }
            : {}),
          ...(values.method === PAYMENT_2C2P
            ? { payment_option: values.paymentOption }
            : {}),
        };
        onUpdated(body);
      }
    },
  });

  useEffect(() => {
    modernTradeBankList().then((res) => {
      const bankList = res?.data?.data?.brand_name ?? [];
      setMTBankList(bankList);
    });
  }, []);

  useEffect(() => {
    if (!paymentOptions?.length) {
      loadPaymentOptions && loadPaymentOptions();
    }
    if (!paymentMethodsList?.length) {
      loadOrderPaymentMethods && loadOrderPaymentMethods();
    }
  }, []);

  const orderTotalValue = useMemo(() => {
    return orderValue || order?.total;
  }, [order, orderValue]);

  const availablePaymentMethod = useMemo(() => {
    const selectedStore = store || order?.store;
    if (selectedStore?.is_store && selectedStore?.store_type === "offline") {
      return [
        ...(isCreateOrder ? [PAYMENT_DEFAULT] : []),
        ...paymentMethodsList,
      ];
    }
    if (
      !isUndefined(orderTotalValue) &&
      orderTotalValue > MAX_ORDER_VALUE_ALLOW_COD
    ) {
      return [
        ...(isCreateOrder ? [PAYMENT_DEFAULT] : []),
        ...paymentMethodsList?.filter(
          (m: any) =>
            m.id !== PAYMENT_COD && !OFFLINE_PAYMENT_METHOD.includes(m.id)
        ),
      ];
    }
    return [
      ...(isCreateOrder ? [PAYMENT_DEFAULT] : []),
      ...paymentMethodsList?.filter(
        (m: any) => !OFFLINE_PAYMENT_METHOD.includes(m.id)
      ),
    ];
  }, [paymentMethodsList, order, orderTotalValue]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("changePaymentMethod")}
      onSave={() => methodForm.handleSubmit()}
    >
      <Select
        dataSource={availablePaymentMethod}
        label={t("paymentMethod")}
        value={methodForm.values.method}
        error={methodForm.errors.method}
        onChange={(value) => methodForm.setFieldValue("method", value)}
        placeholder={t("pleaseSelect")}
      />
      <Select
        className="mt-3"
        hidden={methodForm.values.method !== PAYMENT_MODERN_TRADE}
        dataSource={mtBankList}
        getLabel={(item) => (
          <div className="flex items-center">
            {item.logo && (
              <img src={item.logo} className="w-4 h-4 mr-2" alt="" />
            )}
            {item.code}
          </div>
        )}
        label={t("bankName")}
        value={methodForm.values.bankName}
        error={methodForm.errors.bankName}
        onChange={(value) => methodForm.setFieldValue("bankName", value)}
        placeholder={t("pleaseSelect")}
      />
      <Select
        className="mt-3"
        dataSource={NUMBER_INSTALMENT}
        label={t("numberOfInstalment")}
        value={methodForm.values.numberInstalment}
        error={methodForm.errors.numberInstalment}
        onChange={(value) =>
          methodForm.setFieldValue("numberInstalment", value)
        }
        hidden={methodForm.values.method !== PAYMENT_MODERN_TRADE}
        placeholder={t("pleaseSelect")}
      />
      <Select
        className="mt-3"
        hidden={methodForm.values.method !== PAYMENT_2C2P}
        label={t("paymentOptions")}
        dataSource={paymentOptions}
        value={methodForm.values.paymentOption}
        onChange={(value) => {
          methodForm.setFieldValue("paymentOption", value);
        }}
        placeholder={t("pleaseSelect")}
      />
      <InputText
        className="mt-3"
        label={t("remark")}
        value={methodForm.values.remark}
        error={methodForm.errors.remark as string}
        name="remark"
        onChange={methodForm.handleChange}
        multiple
      />
    </Drawer>
  );
};

export default ModalChangePaymentMethod;
