import { Button, Icon } from "d-react-components";
import { isArray, isEmpty, map } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

const FilterViewItem = ({ label, content, onClick }: any) => (
  <div className="border border-red-500 px-3 py-2 flex content-center justify-items-center ml-3">
    <span>{`${label}:`}&nbsp;</span>

    {isArray(content) &&
      map(content, (item, index) => {
        let value = item;
        if (index !== 0) {
          value = `, ${item}`;
        }
        return <span className="text-secondary">{value}</span>;
      })}

    <div className="text-secondary" hidden={isArray(content)}>
      {content}
    </div>
    <div onClick={() => onClick && onClick()} className="ml-1 cursor-pointer">
      <Icon name="highlight_off" className="text-secondary" />
    </div>
  </div>
);

const FilterView = ({ filterList, onRemoveFilter, onRemoveAllFilter }: any) => {
  const { t } = useTranslation();
  return (
    <div
      className="d-flex flex-wrap w-100 align-items-center"
      hidden={!filterList || filterList.length === 0}
    >
      <span className="font-weight-bold">{`${t("activeFilter")}:`}</span>
      {!isEmpty(filterList) &&
        map(filterList, (item) => (
          <FilterViewItem
            label={item?.label}
            content={item?.content}
            onClick={() => onRemoveFilter && onRemoveFilter(item)}
          />
        ))}

      <Button
        onClick={() => onRemoveAllFilter && onRemoveAllFilter()}
        variant="trans"
      >
        {t("clearAll")}
      </Button>
    </div>
  );
};

export default FilterView;
