import { Path } from "@components/layout/Path";
import { shopeAddressShippingList } from "@network/api/customer";
import {
  AwesomeTableComponent,
  HeaderTable,
  IColumnsProps,
  TimeUtils,
  useFirstTime,
  ViewLabelStatus,
} from "d-react-components";
import { debounce, join, map } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import Carousel, { Modal, ModalGateway } from "react-images";
import UserAvatarName from "@components/shared/UserAvatarName";

const MappedFilePreview = ({ fileUrl }: any) => {
  const [modalImageView, setModalImageView] = useState(null);

  return (
    <div>
      <img
        src={fileUrl}
        className="image-square-small"
        onClick={() => setModalImageView(fileUrl)}
      />

      <ModalGateway>
        {modalImageView ? (
          //  @ts-ignore
          <Modal onClose={() => setModalImageView(null)}>
            {/* @ts-ignore  */}
            <Carousel
              currentIndex={0}
              views={[
                {
                  source: modalImageView as string,
                },
              ]}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};

const CustomerInfoMappingTable = () => {
  const tableRef = useRef<any>(null);
  const searchRef = useRef<string>("");
  const navigate = useNavigate();
  const [openFilter, setOpenFilter] = useState(false);
  const [filter, setFilter] = useQueryParam<any>("filter", JsonParam);
  const isFirstTime = useFirstTime();
  const { t } = useTranslation();

  useEffect(() => {
    if (isFirstTime) return;
    tableRef.current.refresh();
  }, [filter]);

  const columns: IColumnsProps = [
    {
      title: t("image"),
      dataIndex: "file",
      render: (image) => <MappedFilePreview fileUrl={image} />,
    },
    {
      title: t("ref"),
      dataIndex: "shipping_address_no",
    },
    {
      title: t("order:shopeOrderId"),
      dataIndex: "order_shopee_id",
    },

    {
      title: t("orderId"),
      dataIndex: "order",
      render: (order) => (
        <Link to={`/order/${order?.id}`}>{order?.orderid}</Link>
      ),
    },
    {
      title: t("firstName"),
      dataIndex: "first_name",
    },
    {
      title: t("lastName"),
      dataIndex: "last_name",
    },
    {
      title: t("address"),
      dataIndex: "address",
    },
    {
      title: t("province"),
      dataIndex: "province",
      render: (province) => province?.name,
    },
    {
      title: t("district"),
      dataIndex: "city",
      render: (city) => city?.name,
    },
    {
      title: t("subDistrict"),
      dataIndex: "subdistrict",
      render: (subdistrict) => subdistrict?.name,
    },
    {
      title: t("postcode"),
      dataIndex: "post_code",
    },
    {
      title: t("createdBy"),
      dataIndex: "admin",
      ellipsis: true,
      width: 150,
      render: (by: any) => <UserAvatarName user={by} />,
    },
    {
      title: t("createdAt"),
      dataIndex: "created_at",
      ellipsis: true,
      width: 150,
      render: (createdAt: any) => TimeUtils.toDateTime(createdAt),
    },
  ];

  const source = (pagingData: any) => {
    return shopeAddressShippingList({ search: searchRef.current }, pagingData);
  };

  const onChangeSearch = debounce((text) => {
    searchRef.current = text;
    tableRef.current.refresh();
  }, 500);

  return (
    <div className="p-3">
      <HeaderTable
        onClickNew={() => navigate(Path.CUSTOMER_INFO_MAP_CREATE)}
        // onClickFilter={() => setOpenFilter(true)}
        onChangeText={(event: any) => onChangeSearch(event.target.value)}
        className="app-layout__page-header"
      />
      <AwesomeTableComponent
        ref={(ref) => {
          tableRef.current = ref;
        }}
        source={source}
        columns={columns}
        showSelectColumn
        transformer={(res) => res?.data?.data?.address ?? []}
      />
    </div>
  );
};

export default CustomerInfoMappingTable;
