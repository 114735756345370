/* eslint-disable react/jsx-no-duplicate-props */
import React, { useContext } from "react";
import SelectSpecificAction from "./SelectSpecificAction";
import { CART_RULE_ACTION } from "@constants/cart-rule";
import { InputText, Select } from "d-react-components";
import { useTranslation } from "react-i18next";
import { YES_NO_OPTIONS } from "@constants/common";
import AppContext from "@helpers/context";

const DiscountAmountCartActions = ({
  actions,
  cartRuleActions,
  setChangeData,
  handleSelectAction,
}: any) => {
  const { t } = useTranslation();
  const { cartRuleForm, disabledSpecificAction } = useContext(AppContext);

  const actionKey =
    actions?.specific ?? CART_RULE_ACTION.DISCOUNT_FIX_AMOUNT_CART;
  const isDiscountFix = actionKey === CART_RULE_ACTION.DISCOUNT_FIX_AMOUNT_CART;

  const getDiscountValue = (key: any, defaultValue?: any) => {
    return actions?.[actionKey]?.[key] ?? defaultValue;
  };

  const onChangeDiscountValue = (newDiscount: any) => {
    const result = {
      ...actions,
      [actionKey]: {
        ...actions[actionKey],
        ...newDiscount,
      },
    };
    setChangeData("actions", result);
  };

  return (
    <div>
      <div className="mt-2 grid grid-cols-2 gap-4">
        <SelectSpecificAction
          disabled={disabledSpecificAction}
          actions={actions}
          handleSelectAction={handleSelectAction}
          cartRuleActions={cartRuleActions}
          className="col-span-1"
        />
        <InputText
          className="col-span-1"
          label={isDiscountFix ? t("amount") : t("percentage")}
          onChange={(event) => {
            const value = parseInt(event.target.value);
            // onChangeAmount(value);
            onChangeDiscountValue(
              isDiscountFix ? { amount: value } : { percentage: value }
            );
          }}
          value={`${getDiscountValue(isDiscountFix ? "amount" : "percentage")}`}
          suffix={isDiscountFix ? "฿" : "%"}
          type="number"
        />
      </div>
      <Select
        className="mt-2"
        label={t("sequentially")}
        value={getDiscountValue("sequentially", true)}
        onChange={(value) => {
          onChangeDiscountValue({ sequentially: value });
        }}
        dataSource={YES_NO_OPTIONS}
      />
    </div>
  );
};

export default DiscountAmountCartActions;
