import InputSelectForm from "@common/input/InputSelectForm";
import InputTextForm from "@common/input/InputTextForm";
import CompanySelect from "@components/company/components/CompanySelect";
import Drawer from "@components/shared/Drawer";
import {
  VEHICLE_OWNERSHIPS,
  VEHICLE_STATUS_LIST,
  VEHICLE_TYPES,
} from "@constants/vehicle";
import { IVehicle, mapVehicleGeneralToServer } from "@interfaces/vehicle";
import { updateVehicle } from "@network/api/vehicle";
import { DateInput, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import { find } from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { VehicleSchema } from "../create/VehicleCreate";
import UserSelect from "@components/user/components/UserSelect";

const mapVehicleToFrontendForm = (vehicle: IVehicle) => {
  const {
    year,
    registration,
    vehicle_type,
    internal_code,
    engine_no,
    vin_no,
    terminal_serial,
    driver,
    vehicle_ownership,
  } = vehicle;
  const ownership = find(
    VEHICLE_OWNERSHIPS,
    (item) => item.id === vehicle_ownership
  );
  return {
    ...vehicle,
    ownership: vehicle_ownership,
    defaultDriver: driver,
    year: moment(year, "YYYY"),
    registrationNo: registration,
    type: vehicle_type,
    internalCode: internal_code,
    engineNo: engine_no,
    vinNo: vin_no,
    terminalSerial: terminal_serial,
  };
};

const VehicleDetailGeneralEditDrawer = ({
  open,
  onClose,
  defaultValue,
  onUpdateSuccess,
}: any) => {
  const { t } = useTranslation();

  const vehicleForm = useFormik({
    initialValues: mapVehicleToFrontendForm(defaultValue),
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: VehicleSchema,
    onSubmit: (values) => {
      const input = mapVehicleGeneralToServer(values);
      Progress.show(
        { method: updateVehicle, params: [{ id: defaultValue?.id, ...input }] },
        () => {
          Notifications.showSuccess(t("updateSuccess"));
          onUpdateSuccess();
          onClose();
        }
      );
    },
  });
  const formValues = vehicleForm?.values;
  const formErrors = vehicleForm?.errors;

  const classNameInput = "mt-3 col-span-2";

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("general")}
      size="auto"
      width="50%"
      onSave={() => vehicleForm.handleSubmit()}
    >
      <div className=" card-container grid grid-cols-2 gap-4">
        <InputTextForm
          keyData="registrationNo"
          form={vehicleForm}
          className={classNameInput}
        />
        <InputSelectForm
          keyData="type"
          dataSource={VEHICLE_TYPES}
          form={vehicleForm}
          className={classNameInput}
        />

        <InputTextForm
          keyData="brand"
          form={vehicleForm}
          className={classNameInput}
        />
        <InputTextForm
          keyData="model"
          form={vehicleForm}
          className={classNameInput}
        />
        <DateInput
          value={formValues?.year}
          onChange={(value) => vehicleForm.setFieldValue("year", value)}
          label={t("year")}
          className={classNameInput}
          error={formErrors?.year as any}
          required
          format="YYYY"
          type="year"
        />
        <InputTextForm
          keyData="color"
          form={vehicleForm}
          className={classNameInput}
        />
        <InputTextForm
          keyData="internalCode"
          form={vehicleForm}
          className={classNameInput}
        />

        <InputTextForm
          keyData="engineNo"
          form={vehicleForm}
          className={classNameInput}
        />
        <InputTextForm
          keyData="vinNo"
          form={vehicleForm}
          className={classNameInput}
        />
        <InputTextForm
          keyData="terminalSerial"
          form={vehicleForm}
          className={classNameInput}
        />

        <InputSelectForm
          keyData="ownership"
          form={vehicleForm}
          dataSource={VEHICLE_OWNERSHIPS}
          className={classNameInput}
        />
        <CompanySelect
          className={classNameInput}
          value={formValues?.company ?? []}
          onChange={(value) => {
            vehicleForm.setFieldValue("company", value);
          }}
        />
        <UserSelect
          label={t("defaultDriver")}
          className={classNameInput}
          value={formValues?.defaultDriver}
          onChange={(value) => {
            vehicleForm.setFieldValue("defaultDriver", value);
          }}
        />

        <InputSelectForm
          keyData="status"
          dataSource={VEHICLE_STATUS_LIST}
          form={vehicleForm}
          className={classNameInput}
        />

        <InputTextForm
          keyData="remark"
          form={vehicleForm}
          className="mt-3 col-span-2"
          multiple
        />
      </div>
    </Drawer>
  );
};

export default VehicleDetailGeneralEditDrawer;
