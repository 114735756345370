export const API_SIGN_IN = `/auth/signin/`;
export const API_SIGN_OUT = "/auth/signout/";
export const API_LOGIN = "/admin/api/login";
export const API_AUTHENTICATOR = "/admin/api/authenticator";

export const API_ORDER_DETAIL = "admin/api/detail-order";
export const API_PROVINCE_LIST = "api/location/province";
export const API_UPDATE_ORDER_SHIPPING = "admin/api/update-or-create-shipping";
export const API_UPLOAD_PAYMENT_CUSTOMER = "admin/api/upload-payment-customer";
export const API_UPLOAD_PAYMENT_CONFIRM =
  "admin/api/upload-payment-confirmation";
export const API_DELIVERY_TYPE_LIST = "admin/api/list-delivery";
export const API_DELIVERY_CREATE = "admin/api/add-delivery";
export const API_DELIVERY_DELETE = "admin/api/delete-delivery";
export const API_UPDATE_STATUS_ORDER = "admin/api/update-status-order";
export const API_CHANGE_EXPIRED_DAY_ORDER = "admin/api/update-status-expire";
export const API_UPDATE_PAYMENT_CONFIRM = "admin/api/confirm-payment";
export const API_SEARCH_CUSTOMER = "admin/api/search-customer";
export const API_CUSTOMER_LIST = "admin/api/list-customer";
export const API_SHIPPING_PROFILE = "admin/api/search-shipping-customer";
export const API_DISTRICT_LIST = "api/location/city";
export const API_SUBDISTRICT_LIST = "api/location/subdistrict";
export const API_POSTCODE_LIST = "api/location/postcode";
export const API_UPDATE_BILLING = "admin/api/update-or-create-billing";
export const API_ADD_COMMENT = "admin/api/add-comment";
export const API_LIST_COMMENT = "admin/api/list-comment";
export const API_LIST_CHANNEL = "admin/api/list-channel";
export const API_LIST_CHANNEL_ALL = "admin/api/channel/all";
export const API_SEARCH_PRODUCT_BY_CHANNEL =
  "admin/api/search-product-by-channel";
export const API_APPLY_COUPON = "admin/api/apply-coupon";
export const API_CREATE_ORDER = "admin/api/create-new-order";
export const API_EDIT_CUSTOMER_CONTACT = "admin/api/update-order-contact";
export const API_CREATE_NEW_CUSTOMER = "admin/api/create-customer";
export const API_UPDATE_ORDER_INFO = "admin/api/update-information-order";
export const API_CREATE_CUSTOMER_SHIPPING =
  "admin/api/create-shipping-customer";
export const API_SEND_MAIL_ORDER_INVOICE = "admin/api/send-mail-order-invoice";
export const API_SEND_MAIL_ORDER_CONFIRM =
  "admin/api/send-mail-order-confimation";
export const API_GET_LIST_SOURCE_ADS = "admin/api/list-source";
export const API_CREAT_ADS = "admin/api/create-ads";
export const API_GET_LIST_ADS = "admin/api/list-ads";
export const API_SEARCH_PRODUCT_IN_PROMOTION =
  "admin/api/search-product-in-promotion";
export const API_CREATE_PROMOTION = "admin/api/create-promotion";
export const API_EDIT_PROMOTION = "admin/api/edit-promotion";
export const API_DETAIL_PROMOTION = "admin/api/detail-promotion";
export const API_GET_LIST_PROMOTION_BY_CHANNEL =
  "admin/api/list-promotion-in-ads";
export const API_CREATE_DETAIL_ADS = "admin/api/create-detail-ads";
export const API_GET_DETAIL_ADS = "admin/api/detail-ads";
export const API_EDIT_ADS = "admin/api/edit-ads";
export const API_UPLOAD_AVATAR = "admin/api/upload-avatar";
export const API_PROMOTION_STATUS = "admin/api/status-promotion";
export const API_DETAIL_CUSTOMER = "admin/api/detail-customer";
export const API_ADMIN_PROFILE = "admin/api/profile-admin";
export const API_SEARCH_BILLING_CUSTOMER = "admin/api/search-billing-customer";
export const API_CREATE_BILLING_CUSTOMER = "admin/api/create-billing-customer";
export const API_DELETE_BILLING_CUSTOMER = "admin/api/delete-billing-customer";
export const API_SET_DEFAULT_BILLING_CUSTOMER =
  "admin/api/default-billing-customer";
export const API_UPDATE_BILLING_CUSTOMER = "admin/api/update-billing-customer";
export const API_DELETE_SHIPPING_CUSTOMER =
  "admin/api/delete-shipping-customer";
export const API_SET_DEFAULT_SHIPPING_CUSTOMER =
  "admin/api/default-shipping-customer";
export const API_UPDATE_SHIPPING_CUSTOMER =
  "admin/api/update-shipping-customer";
export const API_SEARCH_ADDRESS = "admin/api/public-api/search-address";
export const API_DELETE_CUSTOMER = "admin/api/delete-customer";
export const API_UPDATE_CUSTOMER = "admin/api/update-customer";
export const API_ORDER_HISTORY_CUSTOMER = "admin/api/history-order-customer";
export const API_RESET_PAYMENT_STATUS = "admin/api/order-reset-split-payment";
export const API_UPLOAD_PROMOTION_DOCUMENT =
  "admin/api/upload-promotion-document";
export const API_UPDATE_CUSTOMER_ORDER = "admin/api/update-customer-order";
export const API_DELETE_CUSTOMER_ORDER = "admin/api/delete-customer-order";
export const API_LATEST_SALE_ORDER = "admin/api/dashboard/latest-sale-order";
export const API_VALIDATE_SKU_PROMOTION =
  "admin/api/validate-product-create-promotion";
export const API_LOGS_PAYMENT_ORDER = "admin/api/logs-payment-order";
export const API_GET_BRAND_LIST = "admin/api/brand/list";
export const API_GET_CATEGORY_LIST = "admin/api/category/list";
export const API_GET_WAREHOUSE_LIST = "admin/api/warehouse/list";
export const API_PRODUCT_DETAIL = "admin/api/product/detail";
export const API_PRODUCT_LIST_STOCK = "admin/api/product/list-stock";
export const API_CREATE_PRODUCT = "admin/api/product/create";
export const API_UPDATE_PRICE_PRODUCT = "admin/api/product/update-price";
export const API_UPDATE_STOCK_PRODUCT = "admin/api/product/update-stock";
export const API_UPLOAD_MEDIA_PRODUCT = "admin/api/product/upload-media";
export const API_DELETE_MEDIA_PRODUCT = "admin/api/product/delete-media";
export const API_GET_PRICE_PRODUCT = "admin/api/product/list-price";
export const API_EDIT_PRODUCT = "admin/api/product/edit";
export const API_DELETE_PRODUCT = "admin/api/product/delete";
export const API_GET_SOURCE_ORDER = "admin/api/get-source-order";
export const API_UPDATE_STATUS_DELIVERY = "admin/api/update-status-delivery";
export const API_CANCEL_DELIVERY = "admin/api/cancel-delivery";
export const API_EDIT_DELIVERY = "admin/api/edit-delivery";
export const API_LIST_PRODUCT = "admin/api/product/list-product";
export const API_LIST_INVENTORY = "admin/api/inventory/list-inventory";
export const API_VALIDATE_IMPORT_INVENTORY =
  "admin/api/inventory/validate-import-inventory";
export const API_UPDATE_STOCK_INVENTORY =
  "admin/api/inventory/import-inventory";
export const API_PRINT_DELIVERY = "admin/api/print-delivery";
export const API_DELETE_DELIVERY = "admin/api/inventory/delete-inventory";
export const API_LOG_PRINT = "admin/api/log/print";
export const API_ORDER_CREATE_SPLIT_PAYMENT =
  "admin/api/order-create-split-payment";
export const API_ORDER_DELETE_SPLIT_PAYMENT =
  "admin/api/order-delete-split-payment";
export const API_ORDER_UPDATE_SPLIT_PAYMENT =
  "admin/api/order-update-split-payment";
export const API_ORDER_CONFIRM_SPLIT_PAYMENT =
  "admin/api/order-confirmation-split-payment";
export const API_ORDER_UPLOAD_ORDER_CONFIRM =
  "admin/api/order-upload-confirmation-split-payment";
export const API_ORDER_UPLOAD_SPLIT_PAYMENT =
  "admin/api/order-upload-split-payment";
export const API_DASHBOARD_TOP_SKU_PERFORMANCE =
  "admin/api/dashboard/top-sku-performance";
export const API_DELETE_SHIPPING_FROM_ORDER = "admin/api/delete-order-shipping";
export const API_CANCEL_ORDER = "admin/api/cancel-order";
export const API_LOAD_ORDER = "admin/api/load-order";
export const API_CREATE_REFUND = "admin/api/create-refund";
export const API_EDIT_REFUND = "admin/api/edit-refund";
export const API_UPLOAD_ATTACHMENTS_REFUND =
  "admin/api/upload-attachment-refund";
export const API_LIST_REFUND = "admin/api/list-refund";
export const API_APPROVE_REJECT_REFUND = "admin/api/approval-or-reject";
export const API_CANCEL_REFUND = "admin/api/cancel-refund";
export const API_COMPLETE_REFUND = "admin/api/completed-refund";
export const API_CREATE_TRANSFER_STOCK = "admin/api/create-transfer-stock";
export const API_DETAIL_TRANSFER_STOCK = "admin/api/detail-transfer-stock";
export const API_UPLOAD_ATTACHMENT_TRANSFER_STOCK =
  "admin/api/upload-attachment-transfer-stock";
export const API_EDIT_TRANSFER_STOCK = "admin/api/edit-transfer-stock";
export const API_ADD_BANK_REFUND = "admin/api/add-bank-refund";
export const API_EDIT_BANK_REFUND = "admin/api/edit-bank-refund";
export const API_DELETE_BANK_REFUND = "admin/api/delete-bank-refund";
export const API_TRANSFER_STOCK_LIST = "admin/api/list-transfer-stock";
export const API_RECEIVE_STOCK_LIST = "admin/api/list-receive-stock";
export const API_RECEIVE_TRANSFER_STOCK = "admin/api/receive-transfer-stock";
export const API_LIST_WAREHOUSE_ALL = "admin/api/warehouse/all";
export const API_UPLOAD_ATTACHMENT_RECEIVE_STOCK =
  "admin/api/upload-attachment-receive-stock";
export const API_UPLOAD_COMPLETE_REFUND =
  "admin/api/upload-attachment-completed-refund";
export const API_LIST_ALL_ORDER = "admin/api/list-all-order";
export const API_LIST_ALL_USER = "admin/api/list-all-users";
export const API_LOAD_ORDER_EXPORT = "admin/api/list-order-export";
export const API_DELETE_ORDER = "admin/api/delete-order";
export const API_LIST_SUMMARY_QUANTITY_ORDER = "admin/api/list-summary-order";
export const API_DASHBOARD_LATEST_PAMENT_STATUS =
  "admin/api/dashboard/latest-payment-status";
export const API_DASHBOARD_LATEST_PROMOTIONS =
  "admin/api/dashboard/latest-promotions";
export const API_DASHBOARD_TOP_CATEGORY_PERFORMANCE =
  "admin/api/dashboard/top-category-performance";
export const API_DASHBOARD_TOP_SALE_STAFF =
  "admin/api/dashboard/top-sales-staff";
export const API_DASHBOARD_TOP_PAYMENT_METHOD =
  "admin/api/dashboard/top-payment-method";
export const API_DASHBOARD_TOP_PROVINCE_DELIVERY =
  "admin/api/dashboard/top-provinces-delivery";
export const API_DASHBOARD_SALE_CHANNEL_PERFORMANCE =
  "admin/api/dashboard/top-channel-performance";
export const API_DASHBOARD_TOTAL_SALE =
  "admin/api/dashboard/top-sales-performance";
export const API_DASHBOARD_REPORT_SUMMARY_TODAY_ORDER =
  "admin/api/dashboard/report-summary-today-order";
export const API_DASHBOARD_REPORT_SUMMARY_TOTAL_REVENUE =
  "admin/api/dashboard/report-summary-total-revenue";
export const API_DASHBOARD_REPORT_SUMMARY_TODAY_PURCHASED_ITEM =
  "admin/api/dashboard/report-summary-today-purchased-item";
export const API_DASHBOARD_REPORT_SUMMARY_FULLFILLED_ORDER =
  "admin/api/dashboard/report-summary-fullfilled-orders";
export const API_DASHBOARD_REPORT_SUMMARY_AVG_UNIT_PER_ORDER =
  "admin/api/dashboard/report-summary-avg-unit-per-order";
export const API_DASHBOARD_REPORT_SUMMARY_ORDER_PENDING_ACCOUNTING =
  "admin/api/dashboard/report-summary-order-pending-accounting";
export const API_DASHBOARD_REPORT_SUMMARY_ORDER_PENDING_FULLFILLMENT =
  "admin/api/dashboard/report-summary-order-pending-fullfillment";
export const API_DASHBOARD_REPORT_SUMMARY_AVG_SALES_PER_MAN =
  "admin/api/dashboard/report-summary-avg-sales-per-man";
export const API_DELIVERY_LOG = "admin/api/nav/logs-nav";

export const API_VALIDATE_COUPON = "admin/api/cart-rule/validate-coupon";
export const API_CREATE_CART_RULE = "admin/api/cart-rule/create-cart-rule";
export const API_EDIT_CART_RULE = "admin/api/cart-rule/edit-cart-rule";
export const API_DETAIL_CART_RULE = "admin/api/cart-rule/detail-cart-rule";
export const API_CHECK_COUPON = "admin/api/cart-rule/check-coupon";
export const API_LIST_CART_RULE = "admin/api/cart-rule/list-cart-rule";
export const API_LOAD_FREE_GIFT_FROM_PRODUCT =
  "admin/api/cart-rule/validate-cart-rule-order";
export const API_HISTORY_INVENTORY = "admin/api/inventory/history-inventory";
export const API_LIST_QUICK_CART_RULE =
  "admin/api/cart-rule/list-quick-cart-rule";
export const API_CREATE_QUICK_CART_RULE =
  "admin/api/cart-rule/create-quick-cart-rule";
export const API_DETAIL_QUICK_CART_RULE =
  "admin/api/cart-rule/detail-quick-cart-rule";
export const API_EDIT_QUICK_CART_RULE =
  "admin/api/cart-rule/edit-quick-cart-rule";
export const API_CART_RULE_SYNC_LOGS = "admin/api/logs-syncs-cart-rule";

export const API_RE_PUSH_NAV = "admin/api/nav/repush-nav";
export const API_NAV_LIST = "admin/api/nav/list-nav";
export const API_DELETE_COUPON = "admin/api/cart-rule/delete-coupon";
export const API_GET_SALE_SOURCE = "admin/api/public-api/list-order-source";
export const API_UPDATE_SALE_SOURCE = "admin/api/update-source-order";
export const API_UPDATE_FIREBASE_TOKEN = "admin/api/public-api/update-token";
export const API_GET_LIST_NOTIFICATION =
  "admin/api/public-api/list-notification";
export const API_LOAD_PROMOTION = "admin/api/load-promotion";
export const API_UPDATE_READ_NOTIFICATION =
  "/admin/api/public-api/read-notification";
export const API_READ_ALL_NOTIFICATION =
  "admin/api/public-api/read-all-notification";
export const API_VALIDATE_SKU_PRODUCT = "admin/api/validate-sku-product";
export const API_IMPORT_PRICE_PRODUCT = "admin/api/import/price-product";
export const API_REQUEST_DISCOUNT_LIST_MANAGER =
  "/admin/api/request-discount/manager";
export const API_REQUEST_DISCOUNT_CREATE = "admin/api/request-discount/create";
export const API_REQUEST_DISCOUNT_LIST =
  "admin/api/request-discount/list-discount";
export const API_REQUEST_DISCOUNT_DETAIL = "admin/api/request-discount/detail";
export const API_REQUEST_DISCOUNT_CONFIRM =
  "admin/api/request-discount/approval-or-reject-discount";
export const API_PRODUCT_DELETE_MEDIA = "admin/api/product/delete-media";
export const API_PRODUCT_PRINT_INFO =
  "admin/api/information-print-product/detail";
export const API_PRODUCT_PRINT_INFO_UPDATE =
  "admin/api/information-print-product/update";
export const API_PRODUCT_PRINT_INFO_ACTIVITY =
  "admin/api/information-print-product/activities-product";
export const API_PRODUCT_PRINT_INFO_ACTIVITY_CREATE =
  "admin/api/information-print-product/comment-product";
export const API_PRODUCT_IMPORT_FIELDS =
  "admin/api/import-product/fields-product";
export const API_PRODUCT_VALIDATE_IMPORT =
  "admin/api/import-product/validate-product";
export const API_PRODUCT_IMPORT = "admin/api/import-product/import-product";
export const API_REFRESH_DISCOUNT_ID = "admin/api/refresh-discount-id-admin";

export const API_LIST_ALL_STORE = "admin/api/store/all";

export const API_CREATE_PICKUP = "admin/api/pickup-order/create";
export const API_EDIT_PICKUP = "admin/api/pickup-order/edit";
export const API_DELETE_PICK_UP = "admin/api/pickup-order/delete";
export const API_SIGNATURE_PICK_UP = "admin/api/pickup-order/signature";
export const API_LIST_USER_STORE = "admin/api/store/list";
export const API_LIST_USER_STORE_NO_PAGING = "admin/api/store/no-pagination";

export const API_STAFF_MANAGEMENT_DISCOUNT_LIST =
  "admin/api/manager-discount-store/list";
export const API_STAFF_MANAGEMENT_DISCOUNT_ASSIGN =
  "admin/api/manager-discount-store/assign";

export const API_DISCOUNT_REQUEST_HISTORY =
  "admin/api/manager-discount-store/history";
export const API_OFFLINE_STORE_REPORT =
  "admin/api/dashboard/manual-offline-store-sale-report";
export const API_REQUEST_MANAGER_DISCOUNT = "admin/api/check-discount-manual";
export const API_MANAGER_CODE_USAGE_LIST = "admin/api/manager-code-usage/list";

export const API_LIST_ALL_COMPETITORS = "admin/api/competitors/list-all";
export const API_CALENDAR_MANUAL_SALE_REPORT =
  "admin/api/manual-sale-report/calendar";
export const API_ADD_MANUAL_SALE_REPORT = "admin/api/manual-sale-report/add";
export const API_LIST_MANUAL_SALE_REPORT_ADMIN =
  "admin/api/manual-sale-report-admin/list";
export const API_DETAIL_MANUAL_SALE_REPORT_ADMIN =
  "admin/api/manual-sale-report-admin/detail";
export const API_EDIT_MANUAL_SALE_REPORT_ADMIN =
  "admin/api/manual-sale-report-admin/edit";

export const API_LIST_PAYMENT = "/admin/api/cash-deposit/list-payment";

export const API_LIST_ALL_BANK_BY_CHANNEL =
  "admin/api/bank/list-all-bank-by-channel";
export const API_UPLOAD_CONFIRM_DEPOSIT =
  "/admin/api/cash-deposit/upload-confirm-deposit";
export const API_UPLOAD_PAYMENT_INFO_DEPOSIT =
  "admin/api/cash-deposit/upload-payment-deposit";
export const API_CONFIRM_DEPOSIT =
  "admin/api/cash-deposit/confirm-deposit-by-accountant";
export const API_CANCEL_DEPOSIT = "admin/api/cash-deposit/cancel-deposit";
export const API_DELIVERY_TRACKING_LIST =
  "admin/api/delivery/tracking-delivery";
export const API_SUMMARY_DELIVERY = "admin/api/delivery/summary-delivery";
export const API_SUMMARY_DEPOSIT = "admin/api/cash-deposit/summary-deposit";
export const API_MANAGER_CODE_USAGE_REPORT =
  "admin/api/manager-code-usage-report/list";
export const API_REQUEST_STOCK_CREATE = "admin/api/request-stock/create";
export const API_REQUEST_STOCK_EDIT = "admin/api/request-stock/edit";
export const API_REQUEST_STOCK_LIST = "admin/api/request-stock/list";
export const API_REQUEST_STOCK_DETAIL = "admin/api/request-stock/detail";
export const API_REQUEST_STOCK_SUMMARY = "admin/api/request-stock/summary";
export const API_REQUEST_STOCK_EDIT_PRODUCT =
  "admin/api/request-stock/edit-product";
export const API_REQUEST_STOCK_CHECK_STOCK =
  "admin/api/request-stock/control-stock";

export const API_REQUEST_STOCK_UPLOAD =
  "admin/api/request-stock/upload-document";
export const API_REQUEST_STOCK_ACTIVITIES =
  "admin/api/request-stock/activities";
export const API_COMMENT_REQUEST_STOCK = "admin/api/request-stock/comment";
export const API_CONFIRM_REQUEST_STOCK =
  "admin/api/request-stock/confirm-or-reject";
export const API_CANCEL_REQUEST_STOCK = "admin/api/request-stock/cancel";
export const API_STORE_SALE_REPORT = "admin/api/dashboard/store-sale-report";
export const API_APPLY_CUSTOM_SIZE_GROUP =
  "admin/api/search/custom-size-procut";
export const API_ATTRIBUTE_BY_CATEGORY =
  "admin/api/public-api/list-attribute-by-category";
export const API_MAP_DUMMY_PRODUCT = "admin/api/map-custom-product-order";
export const API_RECEIVE_STOCK_ACTIVITY = "admin/api/receive-stock/activities";
export const API_ADD_RECEIVE_STOCK_COMMENT = "admin/api/receive-stock/comment";
export const API_TRANSFER_STOCK_ACTIVITY =
  "admin/api/transfer-stock/list-activities";
export const API_ADD_TRANSFER_STOCK_COMMENT =
  "admin/api/transfer-stock/add-activities";
export const API_CART_RULE_REPORT = "admin/api/dashboard/cart-rule-report";
export const API_UPLOAD_PROOF_STOCK = "admin/api/transfer-stock/upload-proof";
export const API_COMPLETE_RECEIVE_STOCK = "admin/api/transfer-stock/completed";
export const API_CHECK_PRODUCT_BY_SKU = "admin/api/get-all-product-by-sku";
export const API_RAW_MATERIAL_LIST = "admin/api/raw-material/list";
export const API_RAW_MATERIAL_CATEGORY = "admin/api/raw-material-categories";
export const API_RAW_MATERIAL_SUPPLIER = "admin/api/raw-material-supplier";
export const API_RAW_MATERIAL_UNITS = "admin/api/raw-material-uom";
export const API_RAW_MATERIAL_CHECK = "admin/api/forecast-raw-material";
export const API_RAW_MATERIAL_SEARCH_PRODUCT =
  "admin/api/raw-material/search-products";
export const API_RAW_MATERIAL_LIST_PRODUCT =
  "admin/api/raw-material/list-products";
export const API_LIST_MODEL = "admin/api/product-model-by-brand";
export const API_RAW_MATERIAL_CREATE = "admin/api/raw-material/create";
export const API_RAW_MATERIAL_UPLOAD_MEDIA =
  "admin/api/raw-material/upload-media";
export const API_RAW_MATERIAL_UPDATE_STOCK =
  "admin/api/raw-material/update-stock";
export const API_RAW_MATERIAL_DELETE = "admin/api/raw-material/delete";
export const API_VALIDATION_PRODUCT_SKU = "admin/api/validation-product-by-sku";
export const API_RAW_MATERIAL_DETAIL = "admin/api/raw-material/detail";
export const API_RAW_MATERIAL_LIST_STOCK = "admin/api/raw-material/list-stock";
export const API_RAW_MATERIAL_UPDATE = "admin/api/raw-material/update";
export const API_RAW_MATERIAL_INVENTORY = "admin/api/raw-material/inventory";
export const API_UPDATE_RAW_STOCK = "admin/api/raw-material/update-stock";
export const API_RAW_MATERIAL_INVENTORY_HISTORY =
  "admin/api/raw-material/history-stock";
export const API_DELETE_RAW_MATERIAL_INVENTORY =
  "admin/api/raw-material/delete-stock";
export const API_RAW_MATERIAL_INVENTORY_VALIDATE =
  "admin/api/raw-material/validate-sku-import";
export const API_RAW_MATERIAL_INVENTORY_IMPORT =
  "admin/api/raw-material/import-stock";
export const API_DETAIL_PRODUCT_AND_MATERIAL =
  "admin/api/product/list-raw-material-and-product";
export const API_UPDATE_PRODUCT_RAW_MATERIAL =
  "admin/api/product/update-raw-material";
export const API_SEARCH_RAW_MATERIAL_IN_PRODUCT =
  "admin/api/search-raw-material";
export const API_GET_EMPLOYEE_DISCOUNT = "admin/api/get-employee-discount";
export const API_PRINT_PRODUCT_LIST = "admin/api/print-product/list-product";
export const API_PRINT_PRODUCT_DETAIL =
  "admin/api/print-product/detail-product";
export const API_PRINT_PRODUCT_LOGS = "admin/api/print-product/logs-product";
export const API_UPLOAD_FILE = "admin/api/upload/attachment";
export const API_INVENTORY_MEMO_ADD = "admin/api/inventory/add-memo-history";
export const API_INVENTORY_MEMO_LIST = "admin/api/inventory/list-memo-history";
export const API_INVENTORY_HOLD_STOCK = "admin/api/inventory/list-hold-stock";

export const API_STOCK_ADJUST_LIST = "admin/api/list-stock-adjustment";
export const API_STOCK_ADJUST_SUMMARY = "admin/api/summary-stock-adjustment";
export const API_STOCK_ADJUST_CREATE = "admin/api/create-stock-adjustment";
export const API_STOCK_ADJUST_DETAIL = "admin/api/detail-stock-adjustment";
export const API_STOCK_ADJUST_CONFIRM = "admin/api/confirm-stock-adjustment";
export const API_STOCK_ADJUST_UPDATE = "admin/api/update-stock-adjustment";
export const API_STOCK_ADJUST_CANCEL = "admin/api/cancel-stock-adjustment";
export const API_STOCK_ADJUST_ACTIVITIES_LIST =
  "admin/api/stock-adjustment/list-activities";
export const API_STOCK_ADJUST_ACTIVITIES_ADD =
  "admin/api/stock-adjustment/add-activities";

export const API_TRANSFER_STOCK_LIST_REQUEST =
  "admin/api/transfer-stock/list-request-stock";
export const API_TRANSFER_STOCK_LIST_DRIVER =
  "admin/api/transfer-stock/list-driver";
export const API_TRANSFER_STOCK_LIST_VEHICLE =
  "admin/api/transfer-stock/list-vehicle";

export const API_VEHICLE_UPDATE_MEDIA =
  "admin/api/vehicle/update-media-vehicle";
export const API_TRANSFER_STOCK_CANCEL = "admin/api/cancel-transfer-stock";
export const API_TRANSFER_STOCK_CREATE_DELIVERY =
  "admin/api/transfer-stock/create-delivery";
export const API_TRANSFER_STOCK_EDIT_DELIVERY =
  "admin/api/transfer-stock/edit-delivery";
export const API_TRANSFER_STOCK_DELETE_DELIVERY =
  "/admin/api/transfer-stock/cancel-delivery";
export const API_TRANSFER_STOCK_COMPLETE_RECEIVE_STOCK_STORE =
  "admin/api/transfer-stock/confirm-receive-at-store";
export const API_TRANSFER_STOCK_UPDATE_STATUS =
  "admin/api/transfer-stock/update-status-transfer";

export const API_DRIVER_DELIVERY_DETAIL =
  "admin/api/driver-delivery-transfer-stock/detail-delivery";
export const API_DRIVER_DELIVERY_CONFIRM =
  "admin/api/driver-delivery-transfer-stock/confirm-receive-at-warehouse";

export const API_VEHICLE_LIST = "admin/api/vehicle/list-vehicle";
export const API_VEHICLE_DETAIL = "admin/api/vehicle/detail-vehicle";
export const API_VEHICLE_CREATE = "admin/api/vehicle/create-vehicle";
export const API_VEHICLE_UPDATE = "admin/api/vehicle/edit-vehicle";
export const API_VEHICLE_ACTIVITY_LIST = "admin/api/vehicle/list-activities";
export const API_VEHICLE_ACTIVITY_ADD = "admin/api/vehicle/add-activities";

export const API_PRODUCT_SEARCH_IN_WAREHOUSE =
  "admin/api/search-product-warehouse";
export const API_COMPANY_ALL = "admin/api/all-company";
export const API_STOCK_PRODUCT_WAREHOUSE =
  "admin/api/inventory/stock-product-in-warehouse";
export const API_ORDER_STOCK_HOLD = "admin/api/list-hold-stock-order";

export const API_PRODUCTION_COST_TABLE =
  "admin/api/production-cost/list-product";
export const API_PRODUCTION_COST_UPDATE =
  "admin/api/production-cost/update-production-cost";
export const API_PRODUCTION_COST_HISTORY =
  "admin/api/production-cost/history-production-cost";
export const API_PRODUCTION_COST_IMPORT =
  "admin/api/production-cost/import-production-cost";
export const API_PRODUCTION_COST_FORECAST =
  "admin/api/production-cost/margin-forecast";
export const API_PRODUCTION_COST_HISTORY_MEMOS =
  "admin/api/production-cost/list-memo-production-cost";
export const API_PRODUCTION_COST_HISTORY_MEMO_ADD =
  "admin/api/production-cost/add-memo-production-cost";

export const API_CART_RULE_ACTIVITIES =
  "admin/api/cart-rule/activities-cart-rule";
export const API_CART_RULE_ACTIVITY_CREATE =
  "admin/api/cart-rule/comment-cart-rule";
export const API_PROMOTION_ACTIVITIES = "admin/api/activities-promotion";
export const API_PROMOTION_ACTIVITY_CREATE = "admin/api/comment-promotion";

export const API_ORDER_CHECK_DISCOUNT_CHAIN =
  "admin/api/chain/check-discount-chain";
export const API_ORDER_PAYMENT_UPDATE_METHOD =
  "/admin/api/order-update-payment-method";
export const API_ORDER_MODERN_TRADE_BANKS = "/admin/api/bank-name/list";
export const API_ORDER_PAYMENT_METHODS = "/api/check-payment-method-allows";

export const API_CHECK_INVENTORY_CONTROL_STOCK =
  "/admin/api/request-stock/inventory-control-stock";

export const API_WAREHOUSE_LIST = "/admin/api/warehouse/list-warehouse";
export const API_WAREHOUSE_EXPORT = "/admin/api/warehouse/export-warehouse";
export const API_WAREHOUSE_ACTIVITIES = "/admin/api/warehouse/list-activities";
export const API_WAREHOUSE_ADD_ACTIVITY = "/admin/api/warehouse/add-activities";
export const API_WAREHOUSE_DETAIL = "/admin/api/warehouse/detail-warehouse";
export const API_WAREHOUSE_UPDATE = "/admin/api/warehouse/update-warehouse";
export const API_WAREHOUSE_CHANNEL_LIST =
  "/admin/api/warehouse/channel-warehouse";
export const API_WAREHOUSE_CHANNEL_UPDATE =
  "/admin/api/warehouse/update-channel-warehouse";
export const API_WAREHOUSE_DELIVERY_UPDATE =
  "/admin/api/warehouse/update-delivery-warehouse";
export const API_WAREHOUSE_DELIVERY_LIST =
  "/admin/api/warehouse/delivery-warehouse";
export const API_WAREHOUSE_USER_LIST = "/admin/api/warehouse/user-warehouse";
export const API_WAREHOUSE_USER_UPDATE =
  "/admin/api/warehouse/update-user-warehouse";
export const API_WAREHOUSE_USER_LIST_LOAD =
  "/admin/api/warehouse/load-user-warehouse";
export const API_WAREHOUSE_USER_WAREHOUSE_SUMMARY =
  "/admin/api/warehouse/summary-user-warehouse";
export const API_WAREHOUSE_CREATE = "/admin/api/warehouse/create-warehouse";
export const API_WAREHOUSE_DUPLICATE =
  "admin/api/warehouse/duplicate-warehouse";
export const API_WAREHOUSE_IMPORT_FIELDS = "admin/api/import-warehouse/fields";
export const API_WAREHOUSE_IMPORT_VALIDATE =
  "admin/api/import-warehouse/validate";
export const API_WAREHOUSE_IMPORT = "admin/api/import-warehouse/import";

export const API_ROLE_LIST = "/admin/api/roles";

export const API_USER_LIST = "/admin/api/administrator/list-admin";
export const API_PROMOTION_STATUS_LIST =
  "/admin/api/promotion/status-promotion";
export const API_USER_CREATE = "/admin/api/administrator/create-admin";
export const API_USER_DETAIL = "/admin/api/administrator/detail-admin";
export const API_USER_UPDATE = "/admin/api/administrator/update-admin";
export const API_USER_UPDATE_PASSWORD =
  "admin/api/administrator/update-password-admin";
export const API_USER_ACTIVITIES =
  "/admin/api/administrator/list-activities-admin";
export const API_USER_ADD_ACTIVITY =
  "/admin/api/administrator/add-activities-admin";
export const API_USER_ROLE_LIST = "/admin/api/administrator/role-admin";
export const API_USER_ROLE_UPDATE =
  "/admin/api/administrator/update-role-admin";
export const API_USER_CHANNEL_LIST = "/admin/api/administrator/channel-admin";
export const API_USER_CHANNEL_UPDATE =
  "/admin/api/administrator/update-channel-admin";
export const API_USER_WAREHOUSE_LIST =
  "/admin/api/administrator/warehouse-admin";
export const API_USER_WAREHOUSE_UPDATE =
  "/admin/api/administrator/update-warehouse-admin";
export const API_USER_ORDER_MANA_LIST =
  "/admin/api/administrator/order-management-admin";
export const API_USER_ORDER_MANA_UPDATE =
  "/admin/api/administrator/update-user-management-order-admin";
export const API_USER_AUTHENTICATION_UPDATE =
  "admin/api/administrator/update-authentication-admin";
export const API_USER_SECRET_CODE =
  "admin/api/administrator/random-google-2fa-code";
export const API_USER_DUPLICATE = "admin/api/administrator/duplicate-admin";
export const API_USER_IMPORT_FIELDS = "admin/api/import-admin/fields";
export const API_USER_IMPORT_VALIDATE = "admin/api/import-admin/validate";
export const API_USER_IMPORT = "admin/api/import-admin/import";

export const API_CHAIN_LIST = "/admin/api/chain/list-all-chain";
export const API_GP_EVENT_LIST =
  "/admin/api/chain-discount-manual-event/list-chain-discount";
export const API_GP_EVENT_CREATE =
  "/admin/api/chain-discount-manual-event/create-chain-discount";
export const API_GP_EVENT_DETAIL =
  "/admin/api/chain-discount-manual-event/detail-chain-discount";
export const API_GP_EVENT_UPDATE =
  "/admin/api/chain-discount-manual-event/update-chain-discount";
export const API_GP_EVENT_CHANNEL_LIST =
  "/admin/api/chain-discount-manual-event/list-channel";
export const API_GP_EVENT_CHANNEL_UPDATE =
  "/admin/api/chain-discount-manual-event/update-channel";
export const API_GP_EVENT_WAREHOUSE_LIST =
  "/admin/api/chain-discount-manual-event/list-warehouse";
export const API_GP_EVENT_WAREHOUSE_UPDATE =
  "/admin/api/chain-discount-manual-event/update-warehouse";
export const API_GP_EVENT_PRODUCT_LIST =
  "/admin/api/chain-discount-manual-event/list-product";
export const API_GP_EVENT_PRODUCT_UPDATE =
  "/admin/api/chain-discount-manual-event/update-product";
export const API_GP_EVENT_PRODUCT_IMPORT =
  "/admin/api/chain-discount-manual-event/import-product";
export const API_GP_EVENT_ACTIVITIES =
  "/admin/api/chain-discount-manual-event/list-activities";
export const API_GP_EVENT_ADD_ACTIVITY =
  "/admin/api/chain-discount-manual-event/add-activities";

export const API_ORDER_MT_DISCOUNT_MANUAL =
  "/admin/api/select-chain-discount-manual-create-order";
export const API_ORDER_MT_DISCOUNT_EVENT =
  "/admin/api/select-chain-discount-manual-event-create-order";
export const API_ORDER_MT_APPROVE_SUSPICIOUS =
  "/admin/api/approve-suspicious-order";
export const API_ORDER_APPROVE_CUSTOM_SIZE =
  "/admin/api/approve-custom-size-detected";
export const API_ORDER_PAYMENT_OPTIONS = "/api/list-payment-options";

export const API_ORDER_DETAIL_PUBLIC = "/shares/order-detail";
export const API_ORDER_ACTIVITY_PUBLIC = "/shares/order-comment";

export const API_PRODUCT_SIZE_CATEGORY =
  "/admin/api/public-api/list-product-size-by-category";

export const API_PRODUCT_LABEL_IMPORT_FIELDS =
  "admin/api/import-label-product/fields";
export const API_PRODUCT_LABEL_IMPORT_VALIDATE =
  "admin/api/import-label-product/validate";
export const API_PRODUCT_LABEL_IMPORT = "admin/api/import-label-product/import";

export const API_PRODUCT_ALIAS_IMPORT_VALIDATE =
  "admin/api/import-alias-sku/validate";
export const API_PRODUCT_ALIAS_IMPORT = "admin/api/import-alias-sku/import";

export const API_GET_WORKINFINITY_LOGIN_URL =
  "admin/api/workinfinityx/get-url-login";
export const API_LOGIN_WORKINFINITY_X = "admin/api/workinfinityx/oauth-by-code";

export const API_SHOPE_ORDER_LOAD = "admin/api/load-order-shopee";
export const API_SHOPE_SHOP_LIST = "admin/api/list-shopee-shop";
export const API_CHECK_ORDER_SHOPE =
  "admin/api/shopee-address-shipping/check-order-shopee";

export const API_CREATE_SHOPE_ADDRESS_SHIPPING =
  "admin/api/shopee-address-shipping/create-shopee-address-shipping";

export const API_SHOPE_ADDRESS_SHIPPING_LIST =
  "admin/api/shopee-address-shipping/list-shopee-address-shipping";

export const API_SHOPE_ADDRESS_SHIPPING_UPLOAD =
  "admin/api/shopee-address-shipping/upload-file-address-shipping";
export const API_LAZADA_ORDER_LOAD = "admin/api/load-order-lazada";
export const API_LAZADA_SHOP_LIST = "admin/api/list-lazada-shop";

export const API_LIST_MENU = "admin/api/public-api/list-menu";

export const API_LIST_NOTIFICATION = "admin/api/public-api/list-notification";
export const READ_NOTIFICATION = "admin/api/public-api/read-notification";
export const READ_ALL_NOTIFICATION =
  "admin/api/public-api/read-all-notification";

export const API_CALL_CENTER_INFORMATION =
  "admin/api/call-center/information-call-center";
export const API_CALL_CENTER_MAKE_CALL = "admin/api/call-center/call";
export const API_CALL_ORDER = "/admin/api/order/call";
export const API_CALL_ORDER_HISTORY = "/admin/api/order/call-history";
export const API_CALL_CENTER_SEARCH_ORDER = "/api/call/search-order";
export const API_CALL_CENTER_SEARCH_TICKET = "/api/call/search-ticket";
export const API_CALL_CENTER_DETAIL_TICKET = "/api/call/detail-ticket";
export const API_CALL_CENTER_REPLIES_TICKET = "/api/call/replies-ticket";
export const API_CALL_CENTER_SEARCH_CALL = "/api/call";
export const API_CALL_CENTER_LOG_CALL = "/api/call/{id}/logs";
export const API_ASSIGN_CALL = "/api/call/{id}/assign-call";
export const API_SEARCH_ALL_PRODUCT = "admin/api/search/all-product";

export const API_INVENTORY_CONTROL_EVENT_LIST =
  "/admin/api/stock-inventory-control/list-stock-inventory-control";

export const API_INVENTORY_CONTROL_EVENT_DETAIL =
  "/admin/api/stock-inventory-control/detail-stock-inventory-control";

export const API_INVENTORY_CONTROL_EVENT_CREATE =
  "/admin/api/stock-inventory-control/create-stock-inventory-control";

export const API_INVENTORY_CONTROL_EVENT_UPDATE =
  "/admin/api/stock-inventory-control/update-stock-inventory-control";

export const API_INVENTORY_CONTROL_EVENT_ACTIVITY_LIST =
  "/admin/api/stock-inventory-control/list-activities";

export const API_INVENTORY_CONTROL_EVENT_ACTIVITY_ADD =
  "/admin/api/stock-inventory-control/add-activities";

export const API_INVENTORY_CONTROL_EVENT_STOCK_LIST =
  "/admin/api/stock-inventory-control/list-stock-inventory-control-detail";

export const API_INVENTORY_CONTROL_EVENT_STOCK_UPDATE =
  "/admin/api/stock-inventory-control/update-stock-inventory-control-detail";

export const API_INVENTORY_CONTROL_EVENT_STOCK_DELETE =
  "/admin/api/stock-inventory-control/delete-stock-inventory-control-detail";

export const API_INVENTORY_CONTROL_EVENT_GP_UPDATE =
  "/admin/api/stock-inventory-control/update-stock-inventory-control-gp-discount-event";

export const API_INVENTORY_CONTROL_EVENT_GP_DELETE =
  "/admin/api/stock-inventory-control/delete-stock-inventory-control-gp-discount-event";

export const API_INVENTORY_CONTROL_EVENT_INVENTORY =
  "/admin/api/stock-inventory-control/list-stock-inventory-control-inventory";

export const API_INVENTORY_CONTROL_STOCK =
  "admin/api/stock-inventory-control/search-stock-inventory-control";

export const API_SEARCH_SALE_PERSON =
  "admin/api/administrator/search-sales-person";

export const API_PRODUCTION_PRINTING =
  "admin/api/production/list-production-printing";

export const API_PRODUCTION_PRINTING_HISTORY =
  "admin/api/production/list-history-print-production-printing";

export const API_PRODUCTION_PRINTING_VALIDATION =
  "admin/api/production/validate-import-production-printing";

export const API_PRODUCTION_PRINTING_IMPORT =
  "admin/api/production/import-production-printing";

export const API_PRODUCTION_PRINTING_LABEL =
  "admin/api/production/print-label-production-printing";

export const API_PRODUCTION_PRINTING_TAG =
  "admin/api/production/print-serial-number-production-printing";

export const API_SERIAL_NUMBER_PRINT_TAG =
  "/admin/api/print-serial-number-product/detail";

export const API_SEARCH_STOCK_IN_EVENT =
  "/admin/api/stock-inventory-control/search-stock-product-by-stock-inventory-control";

export const API_DELIVERY_STOCK_LIST =
  "admin/api/driver-delivery-transfer-stock/list-delivery";
export const API_DELIVERY_STOCK_DETAIL =
  "admin/api/driver-delivery-transfer-stock/detail-delivery";
export const API_DELIVERY_STOCK_CONFIRM_ORIGIN =
  "admin/api/transfer-stock/confirm-receive-at-warehouse";
export const API_DELIVERY_STOCK_ASSIGN_DRIVER_VEHICLE =
  "admin/api/transfer-stock/assign-drive-and-vehicle-delivery";
export const API_DELIVERY_STOCK_START_LOADING =
  "admin/api/transfer-stock/start-loading-delivery";
export const API_DELIVERY_STOCK_REASON_REFUSE =
  "admin/api/transfer-stock/reason-can-not-complete-delivery";
export const API_DELIVERY_STOCK_DESTINATION_CONFIRM =
  "admin/api/transfer-stock/confirm-receive-at-store";
export const API_DELIVERY_STOCK_RETURN_CREATE =
  "admin/api/transfer-stock/create-return-delivery-to-warehouse";
export const API_DELIVERY_STOCK_RETURN_CANCEL =
  "/admin/api/transfer-stock/cancel-return-delivery-to-warehouse";
export const API_DELIVERY_STOCK_RETURN_CONFIRM =
  "admin/api/transfer-stock/confirm-return-delivery-to-warehouse";
export const API_DELIVERY_STOCK_RETURN_COMPLETE =
  "admin/api/transfer-stock/confirm-receive-return-stock-at-warehouse";
export const API_DELIVERY_STOCK_ACTIVITIES =
  "admin/api/driver-delivery-transfer-stock/list-activities";
export const API_DELIVERY_STOCK_ACTIVITIES_ADD =
  "admin/api/driver-delivery-transfer-stock/add-activities";
export const API_DELIVERY_STOCK_LIST_SUMMARY =
  "admin/api/driver-delivery-transfer-stock/summary-status";
export const API_ORDER_METADATA = "/admin/api/order-meta-data";

export const API_USER_LOGIN_BY_CODE_DETAIL =
  "/admin/api/administrator/login-by-code";
export const API_USER_WAREHOUSE_CHANNEL_CHECK =
  "/admin/api/administrator/check-channel";
export const API_USER_SO_COLLABORATION_LIST =
  "/admin/api/administrator/admin-management-sales-person";
export const API_USER_SO_COLLABORATION_UPDATE =
  "/admin/api/administrator/update-admin-management-sales-person";
export const API_USER_LOGIN_BY_CODE_UPDATE =
  "/admin/api/administrator/update-login-by-code";
export const API_USER_WAREHOUSE_CHECK_ASSET_CHANNEL =
  "/admin/api/admin-asset/check-asset-channel";
export const API_USER_WAREHOUSE_CHECK_ASSET_WAREHOUSE =
  "/admin/api/admin-asset/check-asset-warehouse";

export const API_KPI_WAREHOUSE = "/admin/api/warehouse/kpi-warehouse";
export const API_KPI_WAREHOUSE_UPDATE =
  "/admin/api/warehouse/update-kpi-warehouse";
export const API_KPI_WAREHOUSE_DETAIL =
  "/admin/api/warehouse/detail-kpi-warehouse";
export const API_KPI_WAREHOUSE_DETAIL_UPDATE =
  "/admin/api/warehouse/update-detail-kpi-warehouse";
export const API_KPI_WAREHOUSE_DETAIL_HISTORY =
  "/admin/api/warehouse/history-detail-kpi-warehouse";

export const API_KPI_STAFF_LIST = "/admin/api/kpi-staff/list";
export const API_KPI_STAFF_DETAIL = "/admin/api/kpi-staff/detail";

export const API_MY_ASSETS_SUMMARY = "/admin/api/my-asset/summary";
export const API_MY_ASSETS_CHANNEL = "/admin/api/my-asset/channel";
export const API_MY_ASSETS_WAREHOUSE = "/admin/api/my-asset/warehouse";
export const API_MY_ASSETS_ROLE = "/admin/api/my-asset/role";
export const API_MY_ASSETS_INFO = "/admin/api/my-asset/info";
export const API_MY_ASSETS_REQUEST_LIST =
  "/admin/api/my-asset/list-request-asset";
export const API_MY_ASSETS_REQUEST_DETAIL =
  "/admin/api/my-asset/detail-request-asset";
export const API_MY_ASSETS_REQUEST_CREATE =
  "/admin/api/my-asset/create-request-asset";
export const API_MY_ASSETS_REQUEST_CANCEL =
  "/admin/api/my-asset/cancel-request-asset";
export const API_MY_ASSETS_CHECK_REQUEST_CHANNEL =
  "/admin/api/my-asset/check-request-asset-channel";
export const API_MY_ASSETS_CHECK_REQUEST_WAREHOUSE =
  "/admin/api/my-asset/check-request-asset-warehouse";
export const API_MY_ASSETS_CHECK_REQUEST_CHANNEL_BY_WAREHOUSE =
  "/admin/api/my-asset/check-request-asset-channel-by-warehouse";

export const API_REQUEST_ASSETS_SUMMARY = "/admin/api/request-asset/summary";
export const API_REQUEST_ASSETS_LIST =
  "/admin/api/request-asset/list-request-asset";
export const API_REQUEST_ASSETS_DETAIL =
  "/admin/api/request-asset/detail-request-asset";
export const API_REQUEST_ASSETS_CONFIRM =
  "/admin/api/request-asset/confirm-request-asset";

export const API_MY_ASSETS_LIST_ACTIVITIES =
  "/admin/api/my-asset/list-activities";
export const API_MY_ASSETS_ADD_ACTIVITIES =
  "/admin/api/my-asset/add-activities";
export const API_REQUEST_ASSETS_LIST_ACTIVITIES =
  "/admin/api/request-asset/list-activities";
export const API_REQUEST_ASSETS_ADD_ACTIVITIES =
  "/admin/api/request-asset/add-activities";

export const API_LINE_CONNECT_URL = "/admin/line-connect/redirect";
export const API_LINE_CONNECT_INFO =
  "/admin/api/public-api/line-connect/information";
export const API_LINE_CONNECT_UNLINK =
  "/admin/api/public-api/line-connect/unlink";

export const API_PRODUCTION_PRINTING_NEW =
  "admin/api/production-printing/list-production-printing";

export const API_PRODUCTION_PRINTING_HISTORY_NEW =
  "admin/api/production-printing/history-production-printing";

export const API_PRODUCTION_PRINTING_VALIDATION_NEW =
  "admin/api/production-printing/validate-production-printing";

export const API_PRODUCTION_PRINTING_IMPORT_NEW =
  "admin/api/production-printing/import-production-printing";

export const API_PRODUCTION_PRINTING_DETAIL =
  "admin/api/production-printing/detail-production-printing";

export const API_PRODUCTION_PRINTING_PRINT =
  "admin/api/production-printing/print-production-printing";

export const API_LIST_SUBSCRIPTION = "admin/api/rent/list-rent";
export const API_CREATE_SUBSCRIPTION = "admin/api/rent/create-rent";
export const API_DETAIL_SUBSCRIPTION = "admin/api/rent/detail-rent";
export const API_LIST_PAYMENT_SUBSCRIPTION =
  "admin/api/rent//list-payment-rent";
export const API_CREATE_CONTRACT_SHIPPING =
  "admin/api/rent/create-shipping-rent";
export const API_UPDATE_CONTRACT_SHIPPING =
  "admin/api/rent/update-shipping-rent";
export const API_DELETE_CONTRACT_SHIPPING =
  "admin/api/rent/delete-shipping-rent";
export const API_UPDATE_CONTRACT_BILLING = "admin/api/rent/update-billing-rent";

export const API_DELIVERY_CONTRACT_LIST = "admin/api/rent/list-delivery-rent";
export const API_DELIVERY_CONTRACT_CREATE =
  "admin/api/rent/create-delivery-rent";
export const API_DELIVERY_CONTRACT_UPDATE =
  "admin/api/rent/update-delivery-rent";
export const API_DELIVERY_CONTRACT_CANCEL =
  "admin/api/rent/cancel-delivery-rent";
export const API_DELIVERY_CONTRACT_UPDATE_STATUS =
  "admin/api/rent/update-status-delivery-rent";
export const API_LIST_CONTRACT_INVOICE = "admin/api/rent/list-invoice-rent";
export const API_CREATE_CONTRACT_INVOICE = "admin/api/rent/create-invoice-rent";
export const API_UPDATE_CONTRACT_INVOICE = "admin/api/rent/update-invoice-rent";
export const API_DETAIL_CONTRACT_INVOICE = "admin/api/rent/detail-invoice-rent";
export const API_CANCEL_CONTRACT_INVOICE = "admin/api/rent/cancel-invoice-rent";
export const API_UPLOAD_CONTRACT_PROOF_PAYMENT =
  "admin/api/rent/upload-proof-payment";
export const API_UPLOAD_CONTRACT_PROOF_PAYMENT_CONFIRMATION =
  "admin/api/rent/upload-proof-confirmation";
export const API_UPLOAD_CONTRACT_PROOF_DOCUMENTS =
  "admin/api/rent/upload-proof-rent";
export const API_UPLOAD_CONTRACT_CONFIRM_PROOF_DOCUMENTS =
  "admin/api/rent/confirmation-proof-rent";
export const API_UPDATE_CONTRACT_CUSTOMER_INFORMATION =
  "admin/api/rent/update-information-rent";
export const API_UPDATE_CONTRACT_NOTE = "admin/api/rent/update-note";
export const API_CHANGE_EXPIRED_DAY_CONTRACT = "admin/api/rent/update-expire";
export const API_CANCEL_CONTRACT = "admin/api/rent/cancel";
export const API_TERMINATE_CONTRACT = "admin/api/rent/terminate";
export const API_LIST_CONTRACT_ACTIVITIES = "admin/api/rent/list-activities";
export const API_ADD_CONTRACT_ACTIVITY = "admin/api/rent/add-activities";
export const API_RESEND_CONTRACT = "admin/api/rent/resend-rent";
export const API_RESEND_CONTRACT_INVOICE = "admin/api/rent/resend-invoice";
export const API_VIEW_PDF = "admin/api/rent/pdf";
export const API_GET_CONTRACT_LINK_ACCESS_TOKEN =
  "admin/api/rent/get-access-token-view-rent-by-customer";
export const API_CREATE_DISCOUNT_CODE = "/admin/api/admin-discount-code/create";
export const API_ISSUED_DISCOUNT_CODE = "/admin/api/admin-discount-code/issued";
export const API_AUTHORIZED_DISCOUNT_CODE =
  "/admin/api/admin-discount-code/authorized";
export const API_CHECK_DISCOUNT = "/admin/api/admin-discount-code/check";
export const API_REQUEST_OTP = "admin/api/otp/request";
export const API_VERIFY_OTP = "admin/api/otp/verify";
export const API_VERIFY_CUSTOMER_EMAIL = "admin/api/customer/verify-email";
export const API_VERIFY_CUSTOMER_PHONE =
  "admin/api/customer/verify-phone-number";

export const API_PROMOTION_LIST = "admin/api/list-promotion";
export const API_PROMOTION_END = "admin/api/end-promotion";
export const API_VERIFY_RENT_CUSTOMER_EMAIL =
  "admin/api/rent/verify-email-rent-information";
export const API_VERIFY_RENT_CUSTOMER_PHONE =
  "admin/api/rent/verify-phone-number-rent-information";
export const API_VALIDATE_CART_RULE_RENT =
  "admin/api/cart-rule/validate-cart-rule-rent";

export const API_CHANNEL_LIST = "admin/api/channel/list-channel";
export const API_CHANNEL_DETAIL = "admin/api/channel/detail-channel";
export const API_CHANNEL_UPDATE = "admin/api/channel/update-channel";
export const API_CHANNEL_CREATE = "admin/api/channel/create-channel";
export const API_CHANNEL_WAREHOUSE = "admin/api/channel/warehouse-channel";
export const API_CHANNEL_WAREHOUSE_UPDATE =
  "admin/api/channel/update-warehouse-channel";
export const API_CHANNEL_USER = "admin/api/channel/user-channel";
export const API_CHANNEL_USER_SUMMARY =
  "admin/api/channel/summary-user-channel";
export const API_CHANNEL_USER_UPDATE = "admin/api/channel/update-user-channel";
export const API_CHANNEL_USER_LOAD = "admin/api/channel/load-user-channel";
export const API_CHANNEL_ACTIVITY_LIST = "admin/api/channel/list-activities";
export const API_CHANNEL_ACTIVITY_ADD = "admin/api/channel/add-activities";

export const API_SERIAL_NUMBER_LIST =
  "/admin/api/serial-number/list-serial-number";
export const API_SERIAL_NUMBER_DETAIL =
  "/admin/api/serial-number/detail-serial-number";
export const API_SERIAL_NUMBER_ACTIVITIES =
  "/admin/api/serial-number/list-activities";
export const API_SERIAL_NUMBER_ACTIVITIES_CREATE =
  "/admin/api/serial-number/add-activities";
export const API_SERIAL_NUMBER_ACTIVATE = "/admin/api/activate-serial-number";
export const API_SERIAL_NUMBER_CANCELLED = "/admin/api/cancel-serial-number";
export const API_SERIAL_NUMBER_PRINT_LOG =
  "/admin/api/print-serial-number-product/logs";

export const API_DEPOSIT_CREATE = "/admin/api/cash-deposit/create-deposit";
export const API_UPLOAD_DOCUMENT_DEPOSIT =
  "/admin/api/cash-deposit/upload-document-deposit";
export const API_DEPOSIT_LIST = "/admin/api/cash-deposit/list-deposit";
export const API_DEPOSIT_DETAIL = "/admin/api/cash-deposit/detail-deposit";
export const API_DEPOSIT_ACTIVITIES =
  "/admin/api/cash-deposit/activities-deposit";
export const API_DEPOSIT_COMMENT = "/admin/api/cash-deposit/comment-deposit";
export const API_DEPOSIT_UPDATE = "admin/api/cash-deposit/edit-deposit";
