import AppContext from "@helpers/context";
import ChannelAPI from "@network/api/channel";
import RoleAPI from "@network/api/role";
import classNames from "classnames";
import {
  AwesomeTableComponent,
  Button,
  InputTextSearch,
  Notifications,
  Progress,
  TimeUtils,
} from "d-react-components";
import {
  debounce,
  find,
  includes,
  isEmpty,
  lowerCase,
  map,
  reduce,
} from "lodash";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ChannelDetailUserSelectDrawer from "./ChannelDetailUserSelectDrawer";
import { useParams } from "react-router-dom";
import ChannelDetailUserLoadDrawer from "./ChannelDetailUserLoadDrawer";

const ROLE_ALL = {
  id: null,
  name: "All",
};
const ChannelDetailUserRole = () => {
  const { roleData, setRoleData } = useContext(AppContext);
  const { selecting, source, roleSummary } = roleData;
  const [textSearch, setTextSearch] = useState("");
  const { t } = useTranslation();

  const rollAllTotal = useMemo(
    () => reduce(roleSummary, (sum, item) => (sum += item.total), 0),
    [roleSummary]
  );

  const renderRoleRow = (role: any, total: any) => {
    const isHide =
      !isEmpty(textSearch) &&
      !includes(lowerCase(role?.name), lowerCase(textSearch));
    const className = classNames(
      " p-3 justify-content-between align-items-center cursor-pointer",
      {
        "bg-primary-50": selecting?.id === role.id,
        "text-primary": selecting?.id === role.id,
        hidden: isHide,
        "d-flex": !isHide,
      }
    );
    return (
      <div
        className={className}
        onClick={() => setRoleData({ ...roleData, selecting: role })}
      >
        <text>{role?.name}</text>
        <div className="small bg-red w-[21px] h-[21px] text-white rounded-circle flex-center">
          {total ?? 0}
        </div>
      </div>
    );
  };

  return (
    <div className="border">
      <div className="bg-primary-30">
        <label className="p-3">{t("allRole")}</label>
      </div>
      <div style={{ backgroundColor: "#F5F5F5" }} className="px-3 py-2">
        <InputTextSearch
          className="my-3 bg-white"
          onChange={(event: any) => setTextSearch(event.target.value)}
          value={textSearch}
        />
      </div>

      {renderRoleRow(ROLE_ALL, rollAllTotal)}
      {map(source, (role) => {
        const summary = find(roleSummary, (item) => item?.role === role?.id);
        return renderRoleRow(role, summary?.total);
      })}
    </div>
  );
};

const ChannelDetailUserAll = () => {
  const { channelId } = useParams();

  const { channel, roleData } = useContext(AppContext);
  const tableRef = useRef<any>();
  const textSearch = useRef("");
  const [openAdd, setOpenAdd] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const [openLoad, setOpenLoad] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!roleData?.selecting) return;
    tableRef.current.refresh();
  }, [roleData?.selecting]);

  const columns = [
    {
      title: t("NO"),
      dataIndex: "",
      render: (value: any, item: any, index: any) => index + 1,
    },
    {
      title: t("name"),
      dataIndex: "fullname",
      render: (name: string, user: any) => (
        <a href={user?.editurl} target="_blank" rel="noreferrer">
          {name}
        </a>
      ),
    },
    {
      title: t("nickName"),
      dataIndex: "name",
    },
    {
      title: t("companyId"),
      dataIndex: "companyId",
    },
    {
      title: t("lastLogin"),
      dataIndex: "last_login",
      render: TimeUtils.convertMiliToDateTime,
    },
  ];

  const source = (paging: any) => {
    const body: any = {
      id: channelId,
      search: textSearch.current,
      action: "in",
    };
    if (roleData?.selecting?.id) {
      body.role = roleData?.selecting?.id;
    }

    return ChannelAPI.userList(body, paging);
  };

  const transformer = (res: any) => {
    return res?.data?.data?.users ?? [];
  };

  const onChangeTextSearch = debounce((text) => {
    textSearch.current = text;
    tableRef.current.refresh();
  }, 1000);

  const onAddUser = (userList: any[]) => {
    const body = {
      id: channelId,
      action: "add",
      user: map(userList, (item) => item.id),
    };
    Progress.show({ method: ChannelAPI.userUpdate, params: [body] }, () => {
      Notifications.showSuccess(t("updateChannelUserSuccessfully"));
      setOpenAdd(false);
      setOpenLoad(false);
      tableRef.current.refresh();
    });
  };

  const onRemoveUser = (userList: any) => {
    const body = {
      id: channel?.id,
      action: "remove",
      user: map(userList, (item) => item.id),
    };
    Progress.show({ method: ChannelAPI.userUpdate, params: [body] }, () => {
      Notifications.showSuccess(t("updateChannelUserSuccessfully"));
      setOpenRemove(false);
      tableRef.current.refresh();
    });
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <InputTextSearch
          // className="mt-3"
          onChange={(event: any) => onChangeTextSearch(event.target.value)}
        />
        <div className="d-flex">
          {/* <Button onClick={() => setOpenRemove(true)} className="ml-2">
            {t("remove")}
          </Button> */}
          <Button onClick={() => setOpenLoad(true)} className="ml-2">
            {t("load")}
          </Button>
          {/* <Button onClick={() => setOpenAdd(true)} className="ml-2">
            {t("add")}
          </Button> */}
        </div>
      </div>

      <AwesomeTableComponent
        ref={(ref) => {
          tableRef.current = ref;
        }}
        columns={columns}
        source={source}
        transformer={transformer}
        className="mt-3"
      />
      {openAdd && (
        <ChannelDetailUserSelectDrawer
          open={openAdd}
          onClose={() => setOpenAdd(false)}
          title={t("addUser")}
          type="not_in"
          onSave={onAddUser}
        />
      )}
      {openRemove && (
        <ChannelDetailUserSelectDrawer
          open={openRemove}
          onClose={() => setOpenRemove(false)}
          title={t("removeUser")}
          type="in"
          onSave={onRemoveUser}
        />
      )}
      {openLoad && (
        <ChannelDetailUserLoadDrawer
          open={openLoad}
          onClose={() => setOpenLoad(false)}
          title={t("loadUser")}
          onSave={onAddUser}
        />
      )}
    </div>
  );
};

const ChannelDetailUser = () => {
  const { channel } = useContext(AppContext);
  const [roleData, setRoleData] = useState<any>({
    source: [],
    selecting: ROLE_ALL,
  });

  useEffect(() => {
    loadAllRole();
  }, []);

  useEffect(() => {
    const roleList = roleData?.source ?? [];

    if (roleList?.length === 0) return;
    loadUserSummary(map(roleList, (item) => item.id));
  }, [roleData?.source]);

  const loadAllRole = () => {
    RoleAPI.list().then((res) => {
      const roleList = res?.data?.data?.roles ?? [];
      setRoleData({
        ...roleData,
        source: roleList,
      });
    });
  };

  const loadUserSummary = (roles: any) => {
    ChannelAPI.userSummary({
      id: channel?.id,
      roles,
    }).then((res) => {
      setRoleData({
        ...roleData,
        roleSummary: res?.data?.data,
      });
    });
  };

  return (
    <AppContext.Provider value={{ roleData, setRoleData }}>
      <div className="row mt-3">
        <div className="col-md-4">
          <ChannelDetailUserRole />
        </div>
        <div className="col-md-8">
          <ChannelDetailUserAll />
        </div>
      </div>
    </AppContext.Provider>
  );
};

export default ChannelDetailUser;
