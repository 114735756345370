import { Button } from "d-react-components";
import { useTranslation } from "react-i18next";

const WarehouseInfo = ({
  warehouse: item,
  showSelect = true,
}: {
  warehouse: any;
  showSelect?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={showSelect ? "border-b pb-3" : ""}>
        {item?.name}
        <div className="text-xs bg-blue-100 px-3 py-2 my-2 arrow-up border-b-blue-100 relative w-full">
          <div className="flex border-b border-gray-300 pb-1">
            <div className="flex-1">{t("systemCode")}</div>
            <div>{item?.warehouse_system_code}</div>
          </div>
          <div className="flex border-b border-gray-300 py-1">
            <div className="flex-1">{t("navArCode")}</div>
            <div>{item?.code}</div>
          </div>
          <div className="flex pt-1">
            <div className="flex-1">{t("navPickupCode")}</div>
            <div>{item?.pickup_code}</div>
          </div>
        </div>
        {showSelect && (
          <Button
            variant="outline"
            className="w-100 pointer-events-none"
            size="small"
          >
            {t("select")}
          </Button>
        )}
      </div>
    </>
  );
};

export default WarehouseInfo;
