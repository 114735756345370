import ActivitiesView from "@components/order/components/activity/ActivitiesView";
import UserAvatarName from "@components/shared/UserAvatarName";
import {
  MY_REQUEST_EXECUTION_STATUS_LIST,
  MY_REQUEST_STATUS_LIST,
} from "@constants/my-assets";
import { IChannel } from "@interfaces/channel";
import { RequestAssetDetail } from "@interfaces/my-assets";
import { IStore } from "@interfaces/store";
import MyAssetsAPI from "@network/api/my-assets";
import {
  AwesomeTableComponent,
  Badge,
  Button,
  IColumnsProps,
  Icon,
  InputTextSearch,
  Notifications,
  TimeUtils,
  ViewLabelStatus,
} from "d-react-components";
import { filter, find, map, some, uniqBy } from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import NewRequestCancelDrawer from "./NewRequestCancelDrawer";
import classNames from "classnames";
import UserAPI from "@network/api/user";
import { IAdmin } from "@interfaces/admin";
import AttachmentView from "@components/order/components/AttachmentView";

const MyRequestDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const activityList = useRef(null);
  const [openCancelDrawer, setOpenCancelDrawer] = useState({ open: false });
  const [requestDetail, setRequestDetail] = useState<RequestAssetDetail | null>(
    null
  );

  const loadRequestDetail = () => {
    MyAssetsAPI.getRequestDetail({ id }).then((resp) =>
      setRequestDetail(resp?.data?.data?.asset)
    );
  };

  useEffect(() => {
    loadRequestDetail();
  }, []);

  const requestStatus = useMemo(
    () =>
      find(MY_REQUEST_STATUS_LIST, (item) => item.id === requestDetail?.status),
    [requestDetail]
  );

  const executionStatus = useMemo(
    () =>
      find(
        MY_REQUEST_EXECUTION_STATUS_LIST,
        (item) => item.id === requestDetail?.execution_status
      ),
    [requestDetail]
  );

  const cancelRequest = () => {
    setOpenCancelDrawer({
      open: true,
    });
  };

  const isCancellable = useMemo(() => {
    return (
      requestDetail?.status === "pending" ||
      (requestDetail?.status === "approved" &&
        requestDetail.execution_status === "partially")
    );
  }, [requestDetail]);

  const source = (paging: any) => {
    const body = { id };
    return MyAssetsAPI.listMyAssetActivity(body, paging);
  };

  const transformer = (res: any) => {
    const activityList = res?.data?.data?.activities ?? [];
    return map(activityList, (item) => ({
      ...item,
      files: map(item.attachment, (attItem) => attItem?.attachment),
    }));
  };

  const onClickSendComment = (comment: any, attachment: any) => {
    const body = {
      id: requestDetail?.id,
      comment,
      attachment: map(attachment, (item) => item?.key),
    };
    return MyAssetsAPI.addMyAssetActivity(body).then(() => {
      Notifications.showSuccess(t("addCommentSuccess"));
    });
  };

  return (
    <div className="">
      <span
        className="mb-2 flex items-center cursor-pointer"
        onClick={() => navigate(-1)}
      >
        <Icon name="chevron_left" />
        {t("back")}
      </span>
      <div className="bg-white p-3 mb-3">
        <span className="block mb-1">
          {t("requestId")}: #{requestDetail?.request_no}
        </span>
        <span className="block mb-1 flex items-center">
          <span className="mr-2">{t("requestedBy")}: </span>
          <UserAvatarName
            user={requestDetail?.request_by}
            size="xx-small"
            variant="square"
          />
        </span>
        <span className="block mb-1 flex items-center">
          <span className="mr-2">{t("requestedFor")}: </span>
          <UserAvatarName
            user={requestDetail?.request_for}
            size="xx-small"
            variant="square"
          />
        </span>
        <div className="flex items-center mt-2">
          {requestStatus && (
            <ViewLabelStatus
              content={t(`${requestStatus?.label}`)}
              color={requestStatus?.color}
              className="mr-2"
            />
          )}
          {executionStatus && (
            <ViewLabelStatus
              content={t(`${executionStatus?.label}`)}
              color={executionStatus?.color}
            />
          )}
        </div>

        <div
          className={classNames(
            {
              hidden: !requestDetail?.remark && !isCancellable,
              "border-t pt-3": !!requestDetail?.remark,
            },
            `mt-3`
          )}
        >
          {requestDetail?.remark}
          <AttachmentView attachments={requestDetail?.attachment} />
          {isCancellable && (
            <Button className="mt-2" onClick={() => cancelRequest()}>
              {t("cancel")}
            </Button>
          )}
        </div>
      </div>
      {requestDetail?.status === "pending" && (
        <div className="bg-amber-100 p-3 border-l-4 border-amber-400 mb-3">
          <div className="flex items-center mb-3">
            <span className="mr-2 text-xs font-semibold">{t("status")}</span>
            {requestStatus && (
              <ViewLabelStatus
                content={t(`${requestStatus?.label}`)}
                color={requestStatus?.color}
                className="mr-2"
              />
            )}
          </div>
          <p>{t("cancelRequestText")}</p>
          <Button
            className="mt-2"
            color="light"
            onClick={() => cancelRequest()}
          >
            {t("cancel")}
          </Button>
        </div>
      )}
      {requestDetail?.status === "approved" && (
        <div
          className={`p-3 mb-3 bg-green-100 border-l-4 border-green-500 text-sm`}
        >
          <div className="">
            <div className="flex items-center mb-2">
              <span className="mr-2 font-weight-bold">{t("status")}:</span>{" "}
              {requestStatus && (
                <ViewLabelStatus
                  content={t(`${requestStatus?.label}`)}
                  color={requestStatus?.color}
                  className="mr-2"
                />
              )}
            </div>
            <div className="flex items-center mb-2">
              <span className="mr-2 font-weight-bold">{t("confirmBy")}:</span>{" "}
              <UserAvatarName user={requestDetail.request_by} size="xx-small" />
            </div>
            <div className="flex items-center mb-2">
              <span className="mr-2 font-weight-bold">{t("confirmAt")}:</span>{" "}
              {TimeUtils.convertMiliToDate(requestDetail.confirmed_at)}
            </div>
          </div>
          <div className="mt-3">
            <div className="relative bg-white text-gray-800 p-2 mt-2 arrow-up-white">
              {requestDetail.confirmed_remark}
            </div>
          </div>
        </div>
      )}
      {requestDetail?.store?.length && requestDetail?.store?.length > 0 ? (
        <ListRequestStore
          listStore={requestDetail?.store ?? []}
          user={requestDetail?.request_for}
        />
      ) : null}
      {requestDetail?.channel?.length && requestDetail?.channel?.length > 0 ? (
        <ListRequestChannel listChannel={requestDetail?.channel ?? []} />
      ) : null}

      <ActivitiesView
        hideType
        source={source}
        transformer={transformer}
        setRef={(listRefParams: any) => (activityList.current = listRefParams)}
        onClickSendComment={onClickSendComment}
        isInstantUploadAttach
      />

      {openCancelDrawer?.open && (
        <NewRequestCancelDrawer
          open={openCancelDrawer.open}
          onClose={() => setOpenCancelDrawer({ open: false })}
          request={requestDetail}
          onSave={() => {
            setOpenCancelDrawer({ open: false });
            loadRequestDetail();
          }}
        />
      )}
    </div>
  );
};

export const ListRequestStore = ({
  listStore,
  user,
}: {
  listStore: any[];
  user: IAdmin;
}) => {
  const [listCheckWarehouse, setListCheckWarehouse] = useState<any[]>([]);
  const tableRef = useRef<any>();
  const tableRemoveRef = useRef<any>();
  const [searchAddStore, setSearchAddStore] = useState("");
  const [searchRemoveStore, setSearchRemoveStore] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    UserAPI.checkAssetWarehouse({
      warehouse: listStore?.map((w: any) => w.store.id),
      admin_id: user.id,
    }).then((resp) => {
      setListCheckWarehouse(uniqBy(resp?.data?.data?.asset ?? [], "id"));
    });
  }, []);

  const listAddStore = useMemo(() => {
    return filter(
      listStore,
      (item) =>
        item.action === "add" &&
        (item?.store?.name
          ?.toLowerCase()
          ?.includes(searchAddStore.toLowerCase()) ||
          item?.store?.code
            ?.toLowerCase()
            ?.includes(searchAddStore.toLowerCase()))
    );
  }, [listStore, searchAddStore]);
  const listRemoveStore = useMemo(() => {
    return filter(
      listStore,
      (item) =>
        item.action === "remove" &&
        (item?.store?.name
          ?.toLowerCase()
          ?.includes(searchRemoveStore.toLowerCase()) ||
          item?.store?.code
            ?.toLowerCase()
            ?.includes(searchRemoveStore.toLowerCase()))
    );
  }, [listStore, searchRemoveStore]);

  const columns = useCallback(
    (action: string): IColumnsProps => {
      return [
        {
          title: t("name"),
          dataIndex: "store",
          render: (store: IStore) => store?.name,
        },
        {
          dataIndex: "store",
          title: t("systemCode"),
          width: 130,
          render: (store: IStore) => store?.warehouse_system_code,
        },
        {
          dataIndex: "store",
          title: t("navArCode"),
          width: 130,
          render: (store: IStore) => store?.code,
        },
        {
          dataIndex: "store",
          title: t("navPickupCode"),
          width: 130,
          render: (store: IStore) => store?.pickup_code,
        },
        // ...(action === "remove"
        //   ? [
        //       {
        //         title: t("startingDate"),
        //         dataIndex: "start",
        //         width: 120,
        //         render: (start: any, item: any) => {
        //           const currentItem = find(listCheckWarehouse, {
        //             warehouse_id: item?.store?.id,
        //           });
        //           return currentItem
        //             ? TimeUtils.toDate(currentItem?.start)
        //             : "";
        //         },
        //       },
        //     ]
        //   : []),
        // ...(action === "add"
        //   ? [
        //       {
        //         title: t("startingDate"),
        //         dataIndex: "start",
        //         render: (start: any, item: any) => {
        //           return TimeUtils.toDate(start);
        //         },
        //       },
        //     ]
        //   : [
        //       {
        //         title: t("lastWorkingDate"),
        //         dataIndex: "end",
        //         render: (end: any, item: any) => {
        //           return TimeUtils.toDate(end);
        //         },
        //       },
        //     ]),
        {
          title: t("executionStatus"),
          dataIndex: "status",
          render: (status: string) => {
            const executionStatus = find(
              MY_REQUEST_STATUS_LIST,
              (item) => item.id === status
            );
            return (
              <ViewLabelStatus
                content={t(`${executionStatus?.label}`)}
                color={executionStatus?.color}
              />
            );
          },
        },
      ];
    },
    [t, listCheckWarehouse]
  );

  return (
    <>
      {listAddStore?.length > 0 && (
        <div className="bg-white p-3 mb-3">
          <h5 className="flex items-center mb-3">
            {t("addStores")}{" "}
            <span className="ml-1 bg-red-500 text-white min-w-[1rem] h-4 text-xs flex items-center justify-center">
              {listAddStore.length}
            </span>
          </h5>
          <InputTextSearch
            value={searchAddStore}
            onChange={(e: any) => setSearchAddStore(e?.target?.value)}
          />
          <AwesomeTableComponent
            ref={(ref) => {
              tableRef.current = ref;
            }}
            dataSource={listAddStore}
            columns={columns("add")}
            className="mt-3"
          />
        </div>
      )}
      {listRemoveStore?.length > 0 && (
        <div className="bg-white p-3 mb-3">
          <h5 className="flex items-center mb-3">
            {t("removeStores")}{" "}
            <span className="ml-1 bg-red-500 text-white min-w-[1rem] h-4 text-xs flex items-center justify-center">
              {listRemoveStore.length}
            </span>
          </h5>
          <InputTextSearch
            value={searchRemoveStore}
            onChange={(e: any) => setSearchRemoveStore(e?.target?.value)}
          />
          <AwesomeTableComponent
            ref={(ref) => {
              tableRemoveRef.current = ref;
            }}
            dataSource={listRemoveStore}
            columns={columns("remove")}
            className="mt-3"
          />
        </div>
      )}
    </>
  );
};

export const ListRequestChannel = ({ listChannel }: { listChannel: any[] }) => {
  const tableRef = useRef<any>();
  const tableRemoveRef = useRef<any>();
  const [searchAddChannel, setSearchAddChannel] = useState("");
  const [searchRemoveChannel, setSearchRemoveChannel] = useState("");
  const { t } = useTranslation();
  const listAddChannel = useMemo(() => {
    return filter(
      listChannel,
      (item) =>
        item.action === "add" &&
        some(
          [
            item?.channel?.name,
            item?.channel?.code,
            item?.channel?.warehouse_system_code,
            item?.channel?.pickup_code,
          ],
          (item) =>
            item?.toLowerCase()?.includes(searchAddChannel.toLowerCase())
        )
    );
  }, [listChannel, searchAddChannel]);
  const listRemoveChannel = useMemo(() => {
    return filter(
      listChannel,
      (item) =>
        item.action === "remove" &&
        (item?.channel?.name
          ?.toLowerCase()
          ?.includes(searchRemoveChannel.toLowerCase()) ||
          item?.channel?.code
            ?.toLowerCase()
            ?.includes(searchRemoveChannel.toLowerCase()))
    );
  }, [listChannel, searchRemoveChannel]);

  const columns = useCallback(
    (action: string): IColumnsProps => {
      return [
        {
          title: t("name"),
          dataIndex: "channel",
          render: (channel: IChannel) => channel?.name,
        },
        {
          title: t("code"),
          dataIndex: "channel",
          render: (channel: IStore) => channel?.code,
        },
        {
          title: t("executionStatus"),
          dataIndex: "status",
          render: (status: string) => {
            const executionStatus = find(
              MY_REQUEST_STATUS_LIST,
              (item) => item.id === status
            );
            return (
              <ViewLabelStatus
                content={t(`${executionStatus?.label}`)}
                color={executionStatus?.color}
              />
            );
          },
        },
      ];
    },
    [t]
  );

  return (
    <>
      {listAddChannel?.length > 0 && (
        <div className="bg-white p-3 mb-3">
          <h5 className="flex items-center mb-3">
            {t("addChannels")}{" "}
            <span className="ml-1 bg-red-500 text-white min-w-[1rem] h-4 text-xs flex items-center justify-center">
              {listAddChannel.length}
            </span>
          </h5>
          <InputTextSearch
            value={searchAddChannel}
            onChange={(e: any) => setSearchAddChannel(e?.target?.value)}
          />
          <AwesomeTableComponent
            ref={(ref) => {
              tableRef.current = ref;
            }}
            dataSource={listAddChannel}
            columns={columns("add")}
            className="mt-3"
          />
        </div>
      )}
      {listRemoveChannel?.length > 0 && (
        <div className="bg-white p-3 mb-3">
          <h5 className="flex items-center mb-3">
            {t("removeChannels")}{" "}
            <span className="ml-1 bg-red-500 text-white min-w-[1rem] h-4 text-xs flex items-center justify-center">
              {listRemoveChannel.length}
            </span>
          </h5>
          <InputTextSearch
            value={searchRemoveChannel}
            onChange={(e: any) => setSearchRemoveChannel(e?.target?.value)}
          />
          <AwesomeTableComponent
            ref={(ref) => {
              tableRemoveRef.current = ref;
            }}
            dataSource={listRemoveChannel}
            columns={columns("remove")}
            className="mt-3"
          />
        </div>
      )}
    </>
  );
};

export default MyRequestDetail;
