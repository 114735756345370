import { Select } from "d-react-components";
import { filter } from "lodash";
import React, { useContext } from "react";
import AppContext from "@helpers/context";
import { POSITION_LABELS } from "@constants/user";
import { STORE_TYPE_LIST } from "@constants/store";
import { useTranslation } from "react-i18next";
import ChannelSelect from "@components/channel/components/ChannelSelect";
import { YES_NO_OPTIONS } from "@constants/common";

const UserFilterForm = ({ form }: any) => {
  const { warehouseList, roleList } = useContext(AppContext);
  const { t } = useTranslation();
  const formValue = form.values;

  return (
    <div className="flex-column">
      <ChannelSelect
        value={formValue.channel}
        onChange={(valueId) => form.setFieldValue("channel", valueId)}
        label={t("channel")}
      />
      <Select
        dataSource={warehouseList}
        value={formValue.warehouse}
        onChange={(value) => form.setFieldValue("warehouse", value)}
        label={t("warehouse")}
        className="mt-3"
        getLabel={(item) => `${item.name} - ${item?.code}`}
        multiple
      />
      <Select
        dataSource={filter(STORE_TYPE_LIST, (item) => item.id !== "all")}
        value={formValue.storeType}
        onChange={(value) => form.setFieldValue("storeType", value)}
        label={t("storeType")}
        className="mt-3"
        getLabel={(item) => t(item.label)}
      />
      <Select
        dataSource={POSITION_LABELS}
        value={formValue.positionLabel}
        onChange={(value) => form.setFieldValue("positionLabel", value)}
        label={t("positionLabel")}
        className="mt-3"
        getLabel={(item) => t(item.label)}
        multiple
      />
      <Select
        dataSource={roleList}
        value={formValue.role}
        onChange={(value) => form.setFieldValue("role", value)}
        label={t("role")}
        className="mt-3"
        getLabel={(item) => item.name}
        multiple
      />
      <Select
        dataSource={YES_NO_OPTIONS}
        value={formValue.block}
        onChange={(value) => form.setFieldValue("block", value)}
        label={t("block")}
        className="mt-3"
      />
    </div>
  );
};

export default UserFilterForm;
