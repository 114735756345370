import { IFieldSelect } from "@interfaces/select";
import { getListGpEvent } from "@network/api/gp-event";
import { ObjectUtils, Select } from "d-react-components";
import { debounce, isArray, isEmpty, map, uniqBy } from "lodash";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export interface IGPEventSelect<T> extends IFieldSelect<T> {
  [key: string]: any;
  chainIds?: string[];
}

const GPEventSelect = ({
  value = [],
  multiple,
  className,
  chainIds = [],
  onChange,
  ...selectProps
}: IGPEventSelect<any>) => {
  const [gpEventList, setGPEventList] = useState<any[]>(
    isArray(value) ? value : [value]
  );

  const { t } = useTranslation();

  const onChangeValue = (id: any) => {
    if (multiple) {
      onChange(
        map(id, (item) => ObjectUtils.findItemFromId(gpEventList, item))
      );
      return;
    }
    onChange(ObjectUtils.findItemFromId(gpEventList, id));
  };

  const gpEventValue = useMemo(() => {
    if (multiple) {
      return map(value, (item: any) => item.id);
    }
    return (value as any)?.id;
  }, [value]);

  const onChangeTextSearch = debounce((textSearch) => {
    if (isEmpty(textSearch)) {
      setGPEventList([]);
    } else {
      const body = {
        search: textSearch,
        chains: chainIds,
      };
      getListGpEvent(body, { pageIndex: 1, pageSize: 100 }).then((res: any) => {
        const listEvent = res.data?.data?.event ?? [];

        const selectedValue = isArray(value) ? value : [value];
        const filterEvent = map([...selectedValue, ...listEvent], (item) => ({
          ...item,
          id: item.id,
          name: item.name,
        }));
        const uniqEvent = uniqBy(filterEvent, (item) => item.id);
        setGPEventList(uniqEvent);
      });
    }
  }, 500);

  return (
    <Select
      onSearch={onChangeTextSearch}
      className={className}
      label={t("gpEvent")}
      dataSource={gpEventList}
      getLabel={(item) => item?.name}
      value={gpEventValue}
      onChange={onChangeValue}
      multiple={multiple}
      placeholder={t("pleaseSelect")}
      showSearch
      filterOption={false}
      {...selectProps}
    />
  );
};

export default GPEventSelect;
