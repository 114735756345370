import InputSelectForm from "@common/input/InputSelectForm";
import InputTextForm from "@common/input/InputTextForm";
import ButtonFileUpload from "@components/shared/ButtonFileUpload";
import Drawer from "@components/shared/Drawer";
import { getBankFullInfo } from "@helpers/bank";
import AppContext from "@helpers/context";
import CashDepositAPI from "@network/api/cash-deposit";
import {
  DateInput,
  Notifications,
  Progress,
  TimeUtils,
} from "d-react-components";
import { useFormik } from "formik";
import _, { map } from "lodash";
import moment from "moment";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

const ErrorUploadBankSchema = Yup.object().shape({
  fullNameTransfer: Yup.string().required("Required"),
  bankTransfer: Yup.string().required("Required"),
  dateTransfer: Yup.object().required("Required"),
  attachments: Yup.array().min(1, "Required").required("Required"),
});

const CashDepositPaymentInfoDrawer = ({
  open,
  onClose,
  defaultInfo = {},
  isView = false,
}: any) => {
  const { bankList, cashDepositDetail, loadCashDepositDetail } =
    useContext(AppContext);
  const { t } = useTranslation();

  const paymentForm = useFormik({
    initialValues: {
      fullNameTransfer: "",
      bankTransfer: "",
      dateTransfer: undefined,
      comment: "",
      ...defaultInfo,
    },
    validationSchema: ErrorUploadBankSchema,
    // validate: validate,

    onSubmit: async (values) => {
      onUploadBankInfo(values);
    },
  });

  const onUploadBankInfo = (values: any) => {
    const {
      fullNameTransfer,
      bankTransfer,
      dateTransfer,
      comment,
      attachments,
    } = values;

    const body = {
      id: cashDepositDetail?.id,
      bank_transfer: bankTransfer,
      fullname_transfer: fullNameTransfer,
      date_transfer: moment(dateTransfer).valueOf(),
      attachment: map(attachments, (item) => item.key),
    };
    Progress.show(
      [{ method: CashDepositAPI.uploadPaymentInfo, params: [body] }],
      (res) => {
        Notifications.showSuccess(t("uploadFileSuccess"));
        onClose && onClose();
        loadCashDepositDetail();
      }
    );
  };

  const renderModalContent = () => {
    return (
      <div>
        <InputTextForm
          form={paymentForm}
          keyData="fullNameTransfer"
          label={t("nameOfPayee")}
          disabled={isView}
          className="mt-3"
        />

        <InputSelectForm
          keyData="bankTransfer"
          form={paymentForm}
          label={t("depositToBank")}
          required
          dataSource={bankList}
          getLabel={getBankFullInfo}
          className="mt-3"
        />

        <DateInput
          showTime
          disabled={isView}
          label={t("dateOfPayment")}
          name="dateTransfer"
          format={TimeUtils.DATE_TIME_FORMAT}
          value={paymentForm.values.dateTransfer}
          onChange={(date) => {
            const value = date ?? _.now();
            paymentForm.setFieldValue("dateTransfer", value);
          }}
          error={paymentForm.errors.dateTransfer as any}
          className="mt-3"
        />
        {!isView && (
          <div className="mt-3">
            <div className="label mb-2">{t("attachments")}</div>
            <ButtonFileUpload
              //   getFile={(file: any) => setAttachments(file)}
              //   uploadedFiles={attachments}
              //   uploadImagesOnly
              //   buttonText={t("upload")}

              onChange={(value: any) =>
                paymentForm.setFieldValue("attachments", value)
              }
              containerClassName="d-flex flex-column"
              defaultFiles={paymentForm.values.attachments}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {isView ? (
        renderModalContent()
      ) : (
        <Drawer
          title={t("paymentUpload")}
          open={open}
          onClose={onClose}
          onSave={() => paymentForm.handleSubmit()}
        >
          {renderModalContent()}
        </Drawer>
      )}
    </>
  );
};

export default CashDepositPaymentInfoDrawer;
