import {
  getDeliveredNumber,
  getProductFromList,
} from "@components/delivery-tstock/components/DeliveryForm";
import TableItemDelivery from "@components/order/detail/content/TableItemDelivery";
import Drawer from "@components/shared/Drawer";
import AppContext from "@helpers/context";
import { mapTransferStockDeliveryToServer } from "@interfaces/transfer-stock";
import {
  transferStockDeleteDelivery,
  updateDeliveryTransferStock,
} from "@network/api/transfer-stock";
import {
  Button,
  DialogManager,
  InputText,
  Notifications,
  Progress,
  TimeUtils,
} from "d-react-components";
import { useFormik } from "formik";
import { every } from "lodash";
import moment from "moment";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

function TransferStockDetailDeliveryDrawer({ delivery, open, onClose }: any) {
  const { transferDetail, loadTransferDetail } = useContext(AppContext);
  const { t } = useTranslation();

  const deliveryForm = useFormik<any>({
    initialValues: {
      transferId: transferDetail?.id,
      deliveryType: delivery?.delivery_type,
      driver: delivery?.driver,
      remark: delivery?.remark,
      vehicle: delivery?.vehicle?.id,
      deliveryNo: delivery?.delivery_no,
      expectedDateArrival: delivery?.estimated_arrival
        ? moment(delivery?.estimated_arrival)
        : null,
    } as any,
    validateOnChange: false,
    validateOnBlur: false,
    validate: (values) => {
      const errors: any = {};
      if (every(values?.products, (iPro) => iPro?.stock === 0 || !iPro.stock)) {
        errors.products = t("canNotCreateDelivery");
      }
      return errors;
    },
    // validationSchema: schema,
    onSubmit: (values: any) => {
      const input = mapTransferStockDeliveryToServer(values);
      onEditDelivery(input);
    },
  });

  const onEditDelivery = async (input: any) => {
    const body = { id: delivery?.id, ...input };
    return Progress.show(
      [{ method: updateDeliveryTransferStock, params: [body] }],
      (res) => {
        onClose && onClose();
        loadTransferDetail();
        return Notifications.showSuccess(t("updateDeliverySuccess"));
      }
    );
  };

  const renderDataField = (label: string, content: string) => (
    <InputText
      disabled
      label={t(label)}
      value={content}
      className="mt-3 col-span-1"
    />
  );

  const renderTableProduct = () => {
    const transferDeliveryProducts = (listProducts: any[] = []) => {
      return listProducts.map((iPro: any) => {
        const proDeli = getProductFromList(
          delivery?.transfer_stock_product ?? [],
          iPro
        );
        const productDelivery = proDeli?.[0];
        const { quantity = 0 } = iPro;
        const deliveredQty = getDeliveredNumber(
          transferDetail?.delivery ?? [],
          iPro
        );
        const leftItemCount = quantity - deliveredQty;

        return {
          ...iPro,
          pickupQty: 0,
          deliveredQty,
          leftItemCount,
          product: iPro,
          stock: productDelivery?.quantity ?? 0,
        };
      });
    };
    return (
      <div className="mb-3 mt-5 w-100 " style={{ overflowX: "scroll" }}>
        <TableItemDelivery
          products={transferDeliveryProducts(transferDetail?.items)}
          disabled
          transferDelivery
        />
      </div>
    );
  };

  const onClickCancel = () => {
    return DialogManager.showConfirm(
      t("removeDelivery"),
      <div className="body2">
        <div className="font-weight-bold">
          {t("areYouSureWantTODeleteThisDelivery")}
        </div>
        <div> * {t("pleaseNoteThatThisActionCannotBeUndone")}</div>
        <div> * {t("incaseYourStockTransferHas")}</div>
      </div>,
      async () => {
        await transferStockDeleteDelivery(delivery?.id);
        loadTransferDetail();
        onClose();
        return Notifications.showSuccess(t("successfullyRemovedDelivery"));
      }
    );
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("delivery")}
      onSave={() => deliveryForm.handleSubmit()}
      size="auto"
      width={"80%"}
    >
      <div className="grid grid-cols-1 gap-4">
        {renderDataField("deliveryType", delivery?.delivery_type)}
        {renderDataField("vehicleNo", delivery?.vehicle?.registration)}
        {renderDataField("driver", delivery?.driver?.fullname)}
        {renderDataField("deliveryNo", delivery?.delivery_no)}
        {renderDataField(
          "expectedDateArrival",
          TimeUtils.toDate(delivery?.estimated_arrival)
        )}
        {renderTableProduct()}
        <InputText
          disabled
          label={t("remark")}
          value={delivery?.remark}
          multiple
          className="mt-3 col-span-1"
        />
        <Button
          onClick={onClickCancel}
          className="mt-3"
          hidden={
            delivery.status !== "pending" && delivery.status !== "booking"
          }
        >
          {t("cancelDelivery")}
        </Button>
      </div>
    </Drawer>
  );
}

export default TransferStockDetailDeliveryDrawer;
