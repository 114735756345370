import { useTranslation } from "react-i18next";

const ConnectLineMobile = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col h-full w-full items-center justify-center p-4">
      <div className="flex items-center">
        <img src="/images/icons/saleinfinity.svg" alt="Sale Infinity X" />
        <img src="/images/icons/connect.svg" alt="connect to" />
        <img src="/images/icons/line.svg" alt="Line" />
      </div>
      <p className="max-w-md text-center mb-4">{t("connectLineMobileDesc")}</p>
    </div>
  );
};

export default ConnectLineMobile;
