import Drawer from "@components/shared/Drawer";
import { SelectInfinity } from "@components/shared/SelectInfinity";
import UserAvatarName from "@components/shared/UserAvatarName";
import { YES_NO_OPTIONS } from "@constants/common";
import {
  FILTER_ORDER_PICKUP,
  ORDER_EXPORT_OPTION,
  ORDER_FILTER_CUSTOM_SIZE,
  ORDER_FILTER_SUSPICIOUS_STATUS_LIST,
  ORDER_PAYMENT_STATUS,
  ORDER_STATUS_ARRAY,
} from "@constants/order";
import { mapObjectToArray } from "@helpers";
import { getPaymentMethods } from "@helpers/account";
import AppContext from "@helpers/context";
import { searchAllProduct } from "@network/api/product";
import { listAllStore } from "@network/api/store";
import { listAllUser } from "@network/api/user";
import { Progress } from "antd";
import { Button, DateInput, InputText, Select } from "d-react-components";
import { isObject, omit, pick, values } from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

const ORDER_PAYMENT_STATUS_LIST = mapObjectToArray(ORDER_PAYMENT_STATUS);
const EXPORT_OPTION = mapObjectToArray(ORDER_EXPORT_OPTION);

function FilterOrderModal({
  onClose,
  openModal,
  filterBody,
  setFilterBody,
  onChangeFilter,
  isVisibleProgress,
  percentageProgress,
  isExport = false,
  exportType = "",
  onChangeExportType,
  renderButton,
}: any) {
  const { channelList, deliveryTypes, loadChannels, loadDeliveryTypes } =
    useContext(AppContext);
  const { t } = useTranslation();

  const PAYMENTS_METHODS = useMemo(() => {
    const results = getPaymentMethods();
    return results.filter((item: any) => item.id !== "default");
  }, []);

  //**************************************************CONTROL UI************************************** */
  const onChangeBodyFilter = (key: string, value: any) => {
    onChangeFilter({ ...filterBody, [key]: value });
  };

  //**************************************************RENDER UI************************************** */

  const renderFilterInputText = (label: string, key: string) => {
    return (
      <InputText
        label={t(label)}
        placeholder={t(label)}
        value={filterBody?.[key]}
        onChange={(event) => onChangeBodyFilter(key, event.target.value)}
        className="mb-3"
      />
    );
  };

  const renderFilterInputDate = (key: string, style?: React.CSSProperties) => {
    const dateVal = filterBody?.[key]
      ? moment(parseInt(filterBody?.[key]))
      : null;
    return (
      <DateInput
        label={t(key)}
        placeholder={t(key)}
        value={dateVal}
        onChange={(value) => onChangeBodyFilter(key, value?.valueOf() ?? null)}
        style={style || {}}
        format="DD/MM/YYYY"
        className="mb-3"
        classNameInput="w-full"
      />
    );
  };

  const renderFilterInputSelect = (
    label: string,
    key: string,
    dataList: any = [],
    getLabel = (item: any) => item.name
  ) => {
    return (
      <Select
        label={t(label)}
        value={filterBody?.[key]}
        onChange={(value) => onChangeBodyFilter(key, value)}
        dataSource={dataList}
        mode="multiple"
        getLabel={getLabel}
        getValue={(item) => item.id}
        className="mb-3"
        selectAll={true}
        placeholder={t("pleaseSelect")}
        selectAllLabel={t("selectAll")}
        deselectAllLabel={t("deselectAll")}
      />
    );
  };

  const renderInputForm = () => {
    return (
      <div>
        {isExport && (
          <div className="row">
            <div className="col-6">
              <Select
                label={t("exportType")}
                value={exportType}
                onChange={onChangeExportType}
                dataSource={EXPORT_OPTION}
                getLabel={(item) => t(item.label)}
                className="mb-3"
                placeholder={t("pleaseSelect")}
              />
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-6">{renderFilterInputDate("from")}</div>
          <div className="col-6">{renderFilterInputDate("to")}</div>
        </div>
        {renderFilterInputSelect("channels", "channels", channelList)}
        {renderFilterInputSelect(
          "status",
          "status",
          ORDER_STATUS_ARRAY,
          (item) => t(item.label)
        )}
        {/* {renderFilterInputSelect("warehouse", "warehouse", listStore)} */}
        <div className="d-flex flex-column mb-3">
          <div className="body2 font-weight-bold text-dark text-xs mb-2">
            {t("warehouse")}
          </div>
          <SelectInfinity
            multiple
            mode="tags"
            value={filterBody?.warehouse}
            placeholder={t("search")}
            fetchFn={(body: any, paging: any) => {
              return listAllStore(
                { search: body.search, channelid: filterBody?.channels ?? [] },
                {
                  pageIndex: paging.page,
                  pageSize: paging.per_page,
                }
              );
            }}
            dataPath="stores"
            onChange={(value: any) => {
              onChangeBodyFilter(
                "warehouse",
                value.filter((item: any) => isObject(item))
              );
            }}
            getLabel={(item) => `${item?.name}`}
            transformer={(res) => {
              const data = res?.data?.data?.stores ?? [];
              let transform = [];
              if (data?.length > 0) {
                transform = data?.map((item: any) => ({
                  id: item.id,
                  name: item.name,
                }));
              }
              return transform;
            }}
          />
        </div>

        <div className="d-flex flex-column mb-3">
          <div className="body2 font-weight-bold text-dark text-xs">
            {t("createdBy")}
          </div>
          <SelectInfinity
            multiple
            mode="tags"
            placeholder={t("search")}
            value={filterBody?.createdby}
            fetchFn={(body: any, paging: any) => {
              return listAllUser(
                { search: body.search },
                {
                  pageIndex: paging.page,
                  pageSize: paging.per_page,
                }
              );
            }}
            dataPath="users"
            onChange={(value: any) => {
              onChangeBodyFilter(
                "createdby",
                value?.map((item: any) => omit(item, "avatar"))
              );
            }}
            getLabel={(item) => (
              <div className="flex items-center">
                {item.avatar && (
                  <>
                    <UserAvatarName user={item} size="xx-small" text=" " />{" "}
                  </>
                )}
                {`${item.companyId}_${item?.name}`}
              </div>
            )}
            transformer={(res) => {
              const data = res?.data?.data?.users ?? [];
              let transform = [];
              if (data?.length > 0) {
                transform = data?.map((item: any) => ({
                  id: item.id,
                  name: item.name,
                  companyId: item.companyId,
                  avatar: item.avatar,
                }));
              }
              return transform;
            }}
          />
        </div>

        <div className="d-flex flex-column mb-3">
          <div className="body2 font-weight-bold text-dark text-xs">
            {t("salesPerson")}
          </div>
          <SelectInfinity
            placeholder={t("search")}
            multiple
            mode="tags"
            value={filterBody?.salesPerson}
            fetchFn={(body: any, paging: any) => {
              return listAllUser(
                { search: body.search },
                {
                  pageIndex: paging.page,
                  pageSize: paging.per_page,
                }
              );
            }}
            dataPath="users"
            onChange={(value: any) => {
              onChangeBodyFilter(
                "salesPerson",
                value?.map((item: any) => omit(item, "avatar"))
              );
            }}
            getLabel={(item) => (
              <div className="flex items-center">
                {item.avatar && (
                  <>
                    <UserAvatarName user={item} size="xx-small" text=" " />{" "}
                  </>
                )}
                {`${item.companyId}_${item?.name}`}
              </div>
            )}
            transformer={(res) => {
              const data = res?.data?.data?.users ?? [];
              let transform = [];
              if (data?.length > 0) {
                transform = data?.map((item: any) => ({
                  id: item.id,
                  name: item.name,
                  companyId: item.companyId,
                  avatar: item.avatar,
                }));
              }
              return transform;
            }}
          />
        </div>

        <div className="d-flex flex-column mb-3">
          <div className="body2 font-weight-bold text-dark text-xs mb-2">
            {t("products")}
          </div>
          <SelectInfinity
            mode="tags"
            value={filterBody?.products}
            placeholder={t("search")}
            fetchFn={(body: any, paging: any) => {
              return searchAllProduct({
                pageIndex: paging.page,
                pageSize: paging.per_page,
                search: body.search || "",
              });
            }}
            dataPath="product"
            onChange={(value: any) => {
              onChangeBodyFilter(
                "products",
                value.filter((item: any) => isObject(item))
              );
            }}
            getLabel={(item) => (
              <div className="flex flex-column">
                <span>{`${item?.name}`}</span>
                <span className="text-xs">SKU: {`${item?.sku}`}</span>
              </div>
            )}
            transformer={(res) => {
              const data = res?.data?.data?.product ?? [];
              let transform = [];
              if (data?.length > 0) {
                transform = data?.map((item: any) => ({
                  id: item.productid,
                  name: item?.product?.name,
                  sku: item?.product?.sku,
                }));
              }
              return transform;
            }}
            styleTagItem={{ width: "auto" }}
          />
        </div>

        {renderFilterInputDate("actualDatePayment")}
        {renderFilterInputSelect(
          "paymentMethod",
          "method",
          PAYMENTS_METHODS,
          (item) => t(item.label)
        )}
        {renderFilterInputSelect(
          "paymentStatus",
          "payment",
          ORDER_PAYMENT_STATUS_LIST,
          (item) => t(item.label)
        )}
        {renderFilterInputSelect(
          "deliveryType",
          "delivery",
          deliveryTypes,
          (item) => t(item.name)
        )}
        {renderFilterInputSelect(
          "pickup",
          "isPickup",
          FILTER_ORDER_PICKUP,
          (item) => t(item.label)
        )}

        {renderFilterInputText("cartRuleId", "cartruleid")}
        {renderFilterInputText("couponCode", "coupon")}
        {renderFilterInputText("employeeDiscount", "employeeDiscount")}
        {renderFilterInputSelect(
          "utmOrders",
          "is_utm",
          YES_NO_OPTIONS,
          (item) => t(item.label)
          // (item) => `${item.value}`
        )}
        {renderFilterInputText("utmSource", "utm_source")}
        {renderFilterInputText("utmMedium", "utm_medium")}
        {renderFilterInputText("utmCampaign", "utm_campaign")}

        <div className="row">
          <div className="col-6">
            {renderFilterInputSelect(
              "isEmployeeDiscount",
              "isEmployeeDiscount",
              YES_NO_OPTIONS,
              (item) => t(item.label)
              // (item) => `${item.value}`
            )}
          </div>
          <div className="col-6">
            {renderFilterInputSelect(
              "suspicious",
              "suspicious",
              ORDER_FILTER_SUSPICIOUS_STATUS_LIST,
              (item) => t(item.label)
              // (item) => `${item.id}`
            )}
          </div>
          <div className="col-6">
            {renderFilterInputSelect(
              "customSizeApproval",
              "custom_size_detected",
              ORDER_FILTER_CUSTOM_SIZE,
              (item) => t(item.label)
              // (item) => `${item.id}`
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderMainContent = () => {
    if (isVisibleProgress) {
      return (
        <div className="progressExportContainer">
          <Progress className="progressExport" percent={percentageProgress} />
        </div>
      );
    }
    // return renderFilterContent();
    return renderInputForm();
  };

  const renderModalHeader = () => {
    return renderButton ? (
      renderButton()
    ) : (
      <div className="d-flex justify-content-between align-items-center w-100 px-2">
        <h4>{t("filter")}</h4>
        <div className="flex-center-y">
          <Button variant="outline" className="mr-3" onClick={() => onClose()}>
            {t("cancel")}
          </Button>
          <Button
            color="red"
            className="mr-3"
            onClick={() => {
              setFilterBody({});
              setTimeout(() => {
                onClose(true);
              }, 100);
            }}
          >
            {t("reset")}
          </Button>
          <Button onClick={() => onClose(true)}>{t("filter")}</Button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (!channelList?.length) {
      loadChannels();
    }
    if (!deliveryTypes?.length) {
      loadDeliveryTypes();
    }
  }, []);

  return (
    <Drawer
      open={openModal}
      onClose={() => onClose(false)}
      // title={t("filter")}
      title={renderModalHeader()}
      hideFooter
      size="auto"
      width={"600px"}
      destroyOnClose
    >
      {renderMainContent()}
    </Drawer>
  );
}
export default FilterOrderModal;
