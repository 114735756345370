import { API } from "@network";
import {
  API_CANCEL_DELIVERY,
  API_DELIVERY_CONTRACT_CANCEL,
  API_DELIVERY_CONTRACT_CREATE,
  API_DELIVERY_CONTRACT_LIST,
  API_DELIVERY_CONTRACT_UPDATE,
  API_DELIVERY_CONTRACT_UPDATE_STATUS,
  API_DELIVERY_CREATE,
  API_DELIVERY_DELETE,
  API_DELIVERY_LOG,
  API_DELIVERY_TRACKING_LIST,
  API_DELIVERY_TYPE_LIST,
  API_EDIT_DELIVERY,
  API_LOG_PRINT,
  API_NAV_LIST,
  API_PRINT_DELIVERY,
  API_RE_PUSH_NAV,
  API_SUMMARY_DELIVERY,
  API_UPDATE_STATUS_DELIVERY,
} from "@network/URL";

export const getDeliveryTypeList = (channelid?: string) => {
  return API.post(API_DELIVERY_TYPE_LIST, { channelid });
};

export const createDelivery = (body: any) => {
  return API.post(API_DELIVERY_CREATE, body);
};

export const editDelivery = (body: any) => {
  return API.post(API_EDIT_DELIVERY, body);
};

export const deleteDelivery = (deliveryid: string) => {
  return API.post(API_DELIVERY_DELETE, { deliveryid });
};

export const printDelivery = (orderid: string) => {
  return API.post(API_PRINT_DELIVERY, { orderid });
};

export const logPrint = (orderid: string, print: any) => {
  const body = { orderid, print };
  return API.post(API_LOG_PRINT, body);
};

export const updateStatusDelivery = (body: any) => {
  return API.post(API_UPDATE_STATUS_DELIVERY, body);
};

export const cancelDelivery = (body: any) => {
  return API.post(API_CANCEL_DELIVERY, body);
};

export const getDeliveryLogs = (body: any) => {
  return API.post(API_DELIVERY_LOG, body);
};

export const rePushNav = (body: any) => {
  return API.post(API_RE_PUSH_NAV, body);
};

export const getNavList = (body?: any) => {
  return API.post(API_NAV_LIST, body);
};

export const getContractDeliveryList = (id?: string) => {
  return API.post(API_DELIVERY_CONTRACT_LIST, { id });
};

export const createContractDelivery = (body: any) => {
  return API.post(API_DELIVERY_CONTRACT_CREATE, body);
};

export const updateContractDelivery = (body: any) => {
  return API.post(API_DELIVERY_CONTRACT_UPDATE, body);
};

export const updateStatusContractDelivery = (body: any) => {
  return API.post(API_DELIVERY_CONTRACT_UPDATE_STATUS, body);
};

export const cancelContractDelivery = (body: any) => {
  return API.post(API_DELIVERY_CONTRACT_CANCEL, body);
};

export const getSummaryDelivery = () => {
  return API.post(API_SUMMARY_DELIVERY);
};

export const getDeliveryTrackingList = (body:any, paging:any) => {
  return API.post(`${API_DELIVERY_TRACKING_LIST}?page=${paging.pageIndex}&per_page=${paging.pageSize}`, body);
};