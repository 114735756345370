import AppContext from "@helpers/context";
import { getFullAddressFromShipping } from "@helpers/string";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

export default function OrderDetailWarehouse() {
  const { order, channel } = useContext(AppContext);
  const { store = {} } = order;
  const { t } = useTranslation();

  return (
    <div className="bg-white p-3 mb-3 shadow-sm">
      <div className="py-2 border-b">
        <label className="text-sm mb-0">{t("channel")}</label>
        <div>
          <div className="mt-1">{channel?.name ?? "N/A"}</div>
        </div>
      </div>
      <div className="py-2">
        <label className="text-sm mb-0">{t("warehouse")}</label>
        <div>
          <div className="mt-1">{`${store?.name ?? "N/A"}`}</div>
          <div className="mt-1" hidden={!store?.address}>
            {getFullAddressFromShipping(store)}
          </div>
        </div>
        <div className="bg-blue-100 px-3 py-2 my-2 arrow-up border-b-blue-100 relative w-full">
          <div className="flex border-b border-gray-300 pb-1">
            <div className="flex-1">{t("systemCode")}</div>
            <div>{store?.warehouse_system_code}</div>
          </div>
          <div className="flex border-b border-gray-300 py-1">
            <div className="flex-1">{t("navArCode")}</div>
            <div>{store?.code}</div>
          </div>
          <div className="flex pt-1">
            <div className="flex-1">{t("navPickupCode")}</div>
            <div>{store?.pickup_code}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
