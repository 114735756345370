import Drawer from "@components/shared/Drawer";
import AppContext from "@helpers/context";
import {
  getGPEventProductList,
  updateGPEventProduct,
} from "@network/api/gp-event";
import {
  AwesomeTableComponent,
  InputTextSearch,
  Modal,
  Progress,
} from "d-react-components";
import { debounce, map } from "lodash";
import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import GPEvenProductView from "../compoents/GPEvenProductView";

function GPEventDetailConfigRemoveModal({ open, onClose, onUpdated }: any) {
  const { eventDetail } = useContext(AppContext);

  const tableRef = useRef<any>();
  const textSearch = useRef();
  const [productSelecting, setProductSelecting] = useState([]);
  const { t } = useTranslation();

  const columns = [
    {
      title: t("name"),
      dataIndex: "",
      render: (product: any) => <GPEvenProductView product={product} />,
    },
    {
      title: t("gp"),
      dataIndex: "discount",
      width: 100,
    },
  ];

  const onChangeText = debounce((text) => {
    textSearch.current = text;
    tableRef.current.refresh();
  }, 500);

  const source = (paging: any) => {
    const body = {
      id: eventDetail?.id,
      action: "in",
      search: textSearch.current,
    };
    return getGPEventProductList(body, paging);
  };

  const transformer = (res: any) => {
    return res?.data?.data?.product ?? [];
  };

  const onSaveRemove = () => {
    const body = {
      id: eventDetail?.id,
      action: "remove",
      product: map(productSelecting, (item: any) => ({
        id: item.id,
        discount: item.discount,
      })),
    };
    Progress.show({ method: updateGPEventProduct, params: [body] }, () => {
      onUpdated();
      onClose();
    });
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("remove")}
      onSave={onSaveRemove}
      size="auto"
      width={"80%"}
    >
      <InputTextSearch
        onChange={(event: any) => onChangeText(event.target.value)}
      />
      <AwesomeTableComponent
        ref={(ref) => {
          tableRef.current = ref;
        }}
        columns={columns}
        source={source}
        transformer={transformer}
        className="mt-3"
        rowSelection={{
          onChange: (selectedRowKeys, selectedRows: any) => {
            setProductSelecting(selectedRows);
          },
          preserveSelectedRowKeys: true,
        }}
      />
    </Drawer>
  );
}

export default GPEventDetailConfigRemoveModal;
