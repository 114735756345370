import InputSelectForm from "@common/input/InputSelectForm";
import InputTextForm from "@common/input/InputTextForm";
import NavSelect from "@components/delivery-so/components/NavSelect";
import { YES_NO_OPTIONS } from "@constants/common";
import { STORE_TYPE_LIST } from "@constants/store";
import { WAREHOUSE_STATUSES } from "@constants/warehouse";
import { getListChain } from "@network/api/chain";
import classNames from "classnames";
import { filter } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const WarehouseFormGeneral = ({ form, fieldClassName = "" }: any) => {
  const dataStoreTypes = filter(STORE_TYPE_LIST, (item) => item.id !== "all");
  const [chainList, setChainList] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    loadChainList();
  }, []);

  const loadChainList = () => {
    getListChain().then((res) => {
      const chainList = res?.data?.data?.chains ?? [];
      setChainList(chainList);
    });
  };

  const classNameField = classNames("col-6 mt-3", fieldClassName);
  return (
    <div className="row">
      <InputTextForm
        keyData="name"
        form={form}
        required
        className="col-12 ml-auto"
      />
      <InputTextForm
        keyData="code"
        form={form}
        required
        className={classNameField}
        label={t("navArCode")}
      />
      <InputTextForm
        keyData="pickupCode"
        form={form}
        className={classNameField}
        label={t("navPickupCode")}
      />
      <InputTextForm
        keyData="brandIdTax"
        form={form}
        className={classNameField}
      />
      <InputTextForm keyData="phone" form={form} className={classNameField} />
      <NavSelect
        className={classNameField}
        value={form?.values?.nav}
        onChange={(value) => {
          form.setFieldValue("nav", value);
        }}
      />
      <InputSelectForm
        keyData="isStore"
        label={t("isThisAStore")}
        form={form}
        className={classNameField}
        dataSource={YES_NO_OPTIONS}
      />
      <InputSelectForm
        keyData="storeType"
        form={form}
        className={classNameField}
        dataSource={dataStoreTypes}
      />
      <InputSelectForm
        keyData="isWarehouseDelivery"
        form={form}
        className={classNameField}
        dataSource={YES_NO_OPTIONS}
      />
      <InputSelectForm
        keyData="stockAdjust"
        form={form}
        className={classNameField}
        dataSource={YES_NO_OPTIONS}
      />
      <InputSelectForm
        keyData="chain"
        form={form}
        className={classNameField}
        dataSource={chainList}
        getLabel={(item: any) => item.name}
      />
      <InputSelectForm
        keyData="status"
        form={form}
        className={classNameField}
        dataSource={WAREHOUSE_STATUSES}
      />
    </div>
  );
};

export default WarehouseFormGeneral;
