import { Button } from "d-react-components";
import React, { useContext, useMemo } from "react";
import { INIT_IMPORT_FORM } from "./UserImport";
import { IMPORT_USER_STEPS } from "@constants/user";
import { useTranslation } from "react-i18next";
import AppContext from "@helpers/context";

const UserImportFooter = () => {
  const { stepIndex, setStepIndex, formImport } = useContext(AppContext);
  const stepNextValue = useMemo(
    () => IMPORT_USER_STEPS[stepIndex + 1],
    [stepIndex]
  );
  const { t } = useTranslation();

  const classNameFooter =
    "d-flex justify-content-end align-items-center p-4 bg-gray-light";

  const getDisabledNextStep = () => {
    const { importType, importFields, fileImport } = formImport.values;
    const { data, errors } = fileImport ?? {};

    switch (stepIndex) {
      case 0:
        return !importType || importFields.length === 0;
      case 1:
        return !data;
      case 2:
        return !errors || errors?.length !== 0;
      default:
        return false;
    }
  };

  const onClickResetDefault = () => {
    setStepIndex(0);
    formImport.setValues(INIT_IMPORT_FORM);
  };

  if (stepIndex === 3) {
    return (
      <div className={classNameFooter}>
        <Button onClick={onClickResetDefault}>{t("newImport")}</Button>
      </div>
    );
  }

  return (
    <div className={classNameFooter}>
      {stepIndex !== 0 && (
        <Button
          variant="outline"
          iconName="chevron_left"
          className="mr-3"
          onClick={() => setStepIndex(stepIndex - 1)}
        >
          {t("previousStep")}
        </Button>
      )}
      <Button
        disabled={getDisabledNextStep()}
        onClick={() => setStepIndex(stepIndex + 1)}
      >{`Step ${stepIndex + 2}: ${t(stepNextValue?.title)}`}</Button>
    </div>
  );
};

export default UserImportFooter;
