import CustomSizeField from "@components/product/components/CustomSizeField";
import ProductName from "@components/product/components/ProductName";
import InfoItemLangs from "@components/shared/InfoItemLangs";
import TablePrint from "@components/shared/TablePrint";
import { BILLING_TYPE, TYPE_OF_PLACE } from "@constants/common";
import AppContext from "@helpers/context";
import { isCustomSizePro } from "@helpers/product";
import styled from "@emotion/styled";
import { Checkbox, StringUtils, TimeUtils } from "d-react-components";
import _ from "lodash";
import React, { useContext } from "react";
import { getFullName } from "@helpers/string";
import { useTranslation } from "react-i18next";
import { getBothLangsText } from "@helpers/locales";
import { ColumnTitle } from "../content/OrderDetailTable";

const getPlaceText = (place: any) => {
  return TYPE_OF_PLACE[place];
};

export const isCustomSizeProduct = (product: any) => {
  return isCustomSizePro(product);
};

const ShippingItemLayout = ({ shipping }: any) => {
  const { t } = useTranslation();
  return (
    <div className="text-right">
      <div className="contentShippingBillingText" style={{ marginTop: "7px" }}>
        {`${getFullName(shipping)} (${shipping?.phone}${
          shipping?.alternative_phone ? ` / ${shipping?.alternative_phone}` : ""
        })`}
      </div>
      {shipping?.type === BILLING_TYPE.business && shipping?.taxid && (
        <div className="contentShippingBillingText">{`${t("taxId")}: ${
          shipping?.taxid
        }`}</div>
      )}
      {shipping?.type === BILLING_TYPE.personal && shipping?.citizenid && (
        <div className="contentShippingBillingText">{`${t("citizenId")}: ${
          shipping?.citizenid
        }`}</div>
      )}
      <div className="contentShippingBillingText">{shipping?.address}</div>
      <div className="contentShippingBillingText">
        {shipping?.subdistrict?.name}
      </div>
      <div className="contentShippingBillingText">{shipping?.city?.name}</div>
      <div className="contentShippingBillingText">{`${shipping?.province?.name}, ${shipping?.postcode}`}</div>
      <div className="contentShippingBillingText">
        {t(getPlaceText(shipping?.place))}
      </div>
      <div className="contentShippingBillingText">{shipping?.note}</div>
    </div>
  );
};

const CompanyLogo = () => {
  const { channel } = useContext(AppContext);

  if (!channel) {
    return <div />;
  }
  return (
    <div className="company-container">
      <div>
        <img src={channel.print_logo} className="company-logo" />
      </div>
    </div>
  );
};
const CompanyInfo = () => {
  const { channel } = useContext(AppContext);
  if (!channel) {
    return <div />;
  }
  return (
    <div className="company-container">
      <div className="companyNameText-ReceiptPrint">{channel.company}</div>
      <InfoItemLangs content={channel.address} />
      <InfoItemLangs content={channel.phone} />
      <InfoItemLangs content={channel.website} />
      <InfoItemLangs title="taxId" content={channel.tax} />
    </div>
  );
};

const OrderInfo = () => {
  const { order, contact, splitPayment = [], by } = useContext(AppContext);
  return (
    <div className="receiptInfo-customSizeOrderPrint">
      <InfoItemLangs title="orderNo" content={`#${order.orderid}`} />
      <InfoItemLangs
        title="purchasedAt"
        content={TimeUtils.convertMiliToDateTime(order.created)}
      />
      <InfoItemLangs
        title="expectedDeliveryAt"
        content="..........................................."
      />
      <InfoItemLangs
        title="consultedBy"
        content={TimeUtils.convertMiliToDateTime(order.created)}
      />
      <InfoItemLangs title="branchCode" content={order?.store?.code} />
    </div>
  );
};

const InforItem = ({ label, content, style = {} }: any) => {
  return (
    <div className="print-order-customsize-container-inforItem" style={style}>
      <InfoItemLangs title={label} />
      <div className="print-order-customsize-container-inforItem-text">
        {content && content()}
      </div>
    </div>
  );
};

const PrintCustomSizeOrder = () => {
  const {
    order,
    shipping,
    billing,
    channel,
    products = [],
    manualProducts = [],
  } = useContext(AppContext);
  const productsResult = products.filter(
    (pro: any) => isCustomSizePro(pro) || !_.isEmpty(pro.manual_product_id)
  );
  const { t } = useTranslation();
  let metaProduct: any = [];

  productsResult.forEach((proItem: any) => {
    const meta = proItem?.meta ?? [];
    meta.forEach((metaItem: any) => {
      const findIndex = _.findIndex(
        metaProduct,
        (item: any) =>
          item?.metaData?.width == metaItem?.width &&
          item?.metaData?.length == metaItem?.length
      );
      if (findIndex >= 0) {
        metaProduct[findIndex] = {
          ...metaProduct[findIndex],
          quantity: metaProduct[findIndex].quantity + 1,
        };
      } else {
        metaProduct.push({
          ...proItem,
          metaData: { ...metaItem },
          quantity: 1,
        });
      }
    });
  });
  metaProduct = metaProduct.concat(
    products
      .filter((pro: any) => !_.isEmpty(pro.manual_product_id))
      ?.map((item: any) => {
        const manualPro = manualProducts.find(
          (manualItem: any) => manualItem.id === item.manual_product_id
        );
        return { ...item, metaData: manualPro?.meta, manualPro };
      })
  );

  const totalOrder = _.reduce(
    metaProduct,
    (sum, item) => sum + item.quantity * item.sale_price,
    0
  );

  if (!order) {
    return <div />;
  }

  const getSubBodyProductView = (product: any) => {
    if (!product) {
      return "";
    }
    const { isGift } = product;
    const subBody = [];
    if (isGift) {
      subBody.push(
        <div className="productTableItemFreeGiftText">{t("freeGift")}</div>
      );
    }

    if (isCustomSizePro(product)) {
      subBody.push(<CustomSizeField product={product} isView />);
    }

    if (!_.isEmpty(product?.manual_product_id)) {
      subBody.push(
        product?.manualPro?.attribute_values?.map((attrItem: any) => (
          <div className="body1 attributeTooltipItemContainer">{`${attrItem?.name}: ${attrItem?.values?.name}`}</div>
        ))
      );
    }
    return subBody;
  };

  const columns = () => [
    {
      title: <ColumnTitle title="itemNo" isShowBothLangs />,
      dataIndex: "product",
      ellipsis: true,
      align: "center",
      render: (product: any, item: any, index: any) => {
        return {
          children: (
            <div className="subTitle1 nowrapCellTable">{index + 1}</div>
          ),
        };
      },
    },
    {
      title: <ColumnTitle title="brand" isShowBothLangs />,
      dataIndex: "brand",
      ellipsis: true,
      align: "center",
      render: (brand: any, item: any, index: any) => {
        return {
          children: (
            <div className="body1 nowrapCellTable">
              {item?.product?.brand?.name ?? "N/A"}
            </div>
          ),
        };
      },
    },
    {
      title: <ColumnTitle title="productModel" isShowBothLangs />,
      dataIndex: "product",
      width: "50%",
      ellipsis: true,
      render: (product: any, item: any) => {
        return {
          children: (
            <ProductName item={item} subBody={getSubBodyProductView(item)} />
          ),
        };
      },
    },
    // {
    //   title: (
    //     <div className="tableTitle" style={{ whiteSpace: "pre-line" }}>
    //       {t("color", "\n")}
    //     </div>
    //   ),
    //   dataIndex: "metaData",
    //   ellipsis: true,
    //   align: "center",
    //   render: (metaData, item, index) => {
    //     return {
    //       children: <div className="subTitle1" />,
    //     };
    //   },
    // },
    {
      title: <ColumnTitle title="customSize" isShowBothLangs />,
      dataIndex: "metaData",
      ellipsis: true,
      align: "center",
      render: (metaData: any, item: any, index: any) => {
        return {
          children: (
            <div className="subTitle1">
              {metaData.width} x {metaData.length}
            </div>
          ),
        };
      },
    },
    {
      title: <ColumnTitle title="quantity" isShowBothLangs />,
      dataIndex: "quantity",
      ellipsis: true,
      align: "center",
      render: (quantity: any) => {
        return {
          children: <div className="subTitle1">{quantity}</div>,
        };
      },
    },
    {
      title: <ColumnTitle title="unitPrice" isShowBothLangs />,
      dataIndex: "",
      align: "center",
      ellipsis: true,
      render: (product: any) => {
        return {
          children: (
            <div className="subTitle1 nowrapCellTable">
              {StringUtils.moneyThaiFormat(
                product?.quantity * product.sale_price
              )}
            </div>
          ),
        };
      },
    },
    // {
    //   title: (
    //     <div className="tableTitle" style={{ whiteSpace: "pre-line" }}>
    //       {t("remark", "\n")}
    //     </div>
    //   ),
    //   dataIndex: "",
    //   align: "center",
    //   ellipsis: true,
    //   render: (product) => {
    //     return {
    //       children: <div className="subTitle1 nowrapCellTable" />,
    //     };
    //   },
    // },
  ];

  const renderPlaceOfPurchase = () => {
    return (
      <div className="print-billing-container">
        <div className="titleShippingBillingText-printOrderReceipt">
          {getBothLangsText("placeOfPurchase")}
        </div>
        <div className="contentShippingBillingText">{`${
          order?.store?.name ?? "N/A"
        } (${order?.store?.code ?? "N/A"})`}</div>
        {order?.store?.address && (
          <div className="contentShippingBillingText">
            {order?.store?.address}
          </div>
        )}
        {order?.store?.province && (
          <div className="contentShippingBillingText">
            {order?.store?.province?.name}
          </div>
        )}
        {order?.store?.postcode && (
          <div className="contentShippingBillingText">
            {order?.store?.postcode}
          </div>
        )}
        {order?.store?.phone && (
          <div className="contentShippingBillingText">
            {order?.store?.phone}
          </div>
        )}
      </div>
    );
  };

  const renderDeclareOption = () => {
    return (
      <InforItem
        label="declareOption"
        content={() => {
          return (
            <div className="mt-2">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  lineHeight: "15px",
                }}
                className="mb-2"
              >
                <Checkbox checked={false} />
                <div>{t("customerDeclareTheSizeByThemselves")}</div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  lineHeight: "15px",
                }}
              >
                <Checkbox checked={false} />
                <div>{t("customerWantsTheStaffToGoHome")}</div>
              </div>
            </div>
          );
        }}
      />
    );
  };

  const renderRemark = () => {
    return (
      <InforItem
        label="remark"
        content={() => {
          return (
            <>
              <span style={{ display: "block" }}>
                1.
                <span>{t("productDimensionInWidthLengthAndHeight")}</span>
              </span>
              <span style={{ display: "block" }}>
                2.
                <span>
                  {t("theCustomSizeOfTheProductHaveBeenPredetermined")}
                </span>
              </span>
              <span style={{ display: "block" }}>
                3.
                <span>{t("customBedMadeToOrderMayHaveATolerance")}</span>
              </span>
            </>
          );
        }}
      />
    );
  };

  const renderConfirmation = () => {
    return (
      <InforItem
        label="confirmation"
        content={() => {
          return (
            <div
              className="mt-2"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Checkbox checked={false} />
              <div>{t("byCheckingThisBoxYouIndicate")}</div>
            </div>
          );
        }}
      />
    );
  };

  const renderConditionsReceipt = () => {
    return (
      <div className="print-order-condition-header-container">
        <div
          className="conditionContent-printOrderReceipt"
          dangerouslySetInnerHTML={{ __html: channel?.terms }}
        />
      </div>
    );
  };

  const renderSignature = () => {
    return (
      <div className="print-customsize-order-signature-header-container">
        <div>
          <InfoItemLangs
            containerClass="print-order-signature-paidByContainer"
            title="customer"
            content={
              <img src={order?.signature} className="paidSignatureImage" />
            }
          />
          <InfoItemLangs
            containerClass="print-order-signature-paidByContainer"
            title="productConsultant"
            content={<div className="signatureInput-print-order-signature" />}
          />
          <InfoItemLangs
            containerClass="print-order-signature-paidByContainer"
            title="shopManager"
            content={<div className="signatureInput-print-order-signature" />}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            float: "left",
          }}
        >
          <InforItem
            style={{ display: "flex" }}
            label="status"
            content={() => {
              return (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox checked={false} />
                    <div>{t("approved")}</div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox checked={false} />
                    <div>{t("unapproved")}</div>
                  </div>
                </div>
              );
            }}
          />
          <InfoItemLangs
            containerClass="print-order-signature-paidByContainer"
            title="authorizedPersonnel"
            content={<div className="signatureInput-print-order-signature" />}
          />
          <InfoItemLangs
            containerClass="print-order-signature-paidByContainer"
            title="date"
            content={<div className="signatureInput-print-order-signature" />}
          />
        </div>
      </div>
    );
  };

  const renderTablePrint = () => {
    const dataSource = () => {
      if (!products) {
        return [];
      }
      const productsResult = products.filter((pro: any) =>
        isCustomSizePro(pro)
      );
      const metaProduct: any = [];
      productsResult.forEach((proItem: any) => {
        const meta = proItem?.meta ?? [];
        meta.forEach((metaItem: any) => {
          const findIndex = _.findIndex(
            metaProduct,
            (item: any) =>
              item?.metaData?.width == metaItem?.width &&
              item?.metaData?.length == metaItem?.length
          );
          if (findIndex >= 0) {
            metaProduct[findIndex] = {
              ...metaProduct[findIndex],
              quantity: metaProduct[findIndex].quantity + 1,
            };
          } else {
            metaProduct.push({
              ...proItem,
              metaData: { ...metaItem },
              quantity: 1,
            });
          }
        });
      });
      const total = _.reduce(
        metaProduct,
        (sum, item) => sum + item.sale_price * item.quantity
      );
      return metaProduct;
    };
    return (
      <TablePrint
        tableLayout="auto"
        isScroll={false}
        tableClassName="printCustomSizeProductTable"
        columns={columns()}
        dataSource={metaProduct}
        rowClassName={(product: any, index: any) => {
          let rowClassName = "rowAwesomeTable ";
          if (product.isGift) {
            rowClassName += "productGiftTableItemRow";
          }
          if (index == dataSource?.length - 1) {
            rowClassName += "lastRowClassName";
          }
          return rowClassName;
        }}
      />
    );
  };

  return (
    <div className="print-container print-order-customsize-container">
      <div className="print-order-customsize-header-container-special">
        <CompanyLogo />
        <div className="companyAndOrderInfoContainer">
          <CompanyInfo />
          {order && <OrderInfo />}
        </div>
      </div>
      <div className="print-order-customsize-header-container">
        {renderPlaceOfPurchase()}

        <div className="print-shipping-container">
          <div className="titleShippingBillingText-printOrderReceipt text-right">
            {getBothLangsText("shippingInformation")}
          </div>
          {shipping?.length === 0 && (
            <span
              className="contentShippingBillingText"
              style={{ marginTop: "7px" }}
            >
              N/A
            </span>
          )}
          {shipping?.map((shippingItem: any, index: number) => (
            <ShippingItemLayout shipping={shippingItem} key={index} />
          ))}
        </div>
      </div>
      <div className="orderPrintCustomSizeContainer">
        <div>{renderTablePrint()}</div>
        <Table aria-label="spanning table">
          <TableBody className="tableBody">
            <TableRow className="tableFooter totalRowClass}">
              <TableCell
                colSpan={3}
                className="leftCellPriceTable hideTableCell-1"
              >
                <div className="body1"> </div>
              </TableCell>
              <TableCell colSpan={2} align="center" className="hideTableCell-1">
                <div
                  className="body1 totalCustomSizeOrderCell"
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {t("total")}
                </div>
              </TableCell>
              <TableCell align="center" colSpan={1}>
                <div
                  className="body1"
                  style={{
                    fontWeight: "bolder",
                    color: "rgba(0, 0, 0, 0.87)",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  id="totalOrderCustomsize"
                >
                  {StringUtils.moneyThaiFormat(totalOrder)}
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      {renderDeclareOption()}
      {renderConditionsReceipt()}
      {renderRemark()}
      {renderConfirmation()}
      {renderSignature()}
    </div>
  );
};

const Table = styled.table`
  width: 100%;
  display: table;
  border-spacing: 0;
  border-collapse: collapse;
`;
const TableBody = styled.tbody``;
const TableCell = styled.td`
  &.leftCellPriceTable {
    width: 20rem;
  }
  padding: 10px 5px;
  border-bottom: 1px solid #e8e8e8;
`;
const TableRow = styled.tr`
  &.totalRowClass {
    .rowText {
      font-weight: bold;
    }
  }
`;

export default PrintCustomSizeOrder;
