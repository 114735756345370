export const mapObjectToArray = (object: Record<string, any>): any[] => {
  if (!object) {
    return [];
  }
  const arrayResult: any[] = [];
  for (const key in object) {
    arrayResult.push(object[key]);
  }
  return arrayResult;
};

export function toggleFullScreen() {
  var doc: any = window.document;
  var docEl: any = doc.documentElement;

  var requestFullScreen =
    docEl.requestFullscreen ||
    docEl.mozRequestFullScreen ||
    docEl.webkitRequestFullScreen ||
    docEl.msRequestFullscreen;
  var cancelFullScreen =
    doc.exitFullscreen ||
    doc.mozCancelFullScreen ||
    doc.webkitExitFullscreen ||
    doc.msExitFullscreen;

  if (
    !doc.fullscreenElement &&
    !doc.mozFullScreenElement &&
    !doc.webkitFullscreenElement &&
    !doc.msFullscreenElement
  ) {
    requestFullScreen.call(docEl);
  } else {
    cancelFullScreen.call(doc);
  }
}

export const changeBodyFont = (lang: string) => {
  var r: any = document.querySelector(":root");
  if (lang === "en") {
    r.style.setProperty("--appFont", '"Poppins", "sans-serif"');
  } else {
    r.style.setProperty("--appFont", '"Prompt", "sans-serif"');
  }
}

export async function runPromisesSequentially<T>(functions: (() => Promise<T>)[]): Promise<T[]> {
  if (functions.length === 0) {
    return [];
  }
  const [first, ...rest] = functions;

  return [await first(), ...(await runPromisesSequentially(rest))];
}