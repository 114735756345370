import { IMPORT_PRODUCT_STEPS } from "@constants/product";
import AppContext from "@helpers/context";
import { productImportFields } from "@network/api/product";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ProductImportFooter from "./ProductImportFooter";
import SelectProductType from "./SelectProductType";
import SelectImportMethod from "./SelectImportMethod";
import SelectCSVFile from "./SelectCSVFile";
import FileValidationAndImport from "./FileValidationAndImport";
import ImportResult from "./ImportResult";
import { includes, map } from "lodash";

export const INIT_IMPORT_FORM = {
  importFields: [],
  productType: null,
  importType: null,
  fileImport: null,
};

const ProductImport = () => {
  const [stepIndex, setStepIndex] = useState(0);
  const [importFieldSource, setImportFieldSource] = useState<any[]>([]);
  const { t } = useTranslation();

  const step = useMemo(() => IMPORT_PRODUCT_STEPS[stepIndex], [stepIndex]);

  const formImport = useFormik({
    initialValues: INIT_IMPORT_FORM,
    validateOnBlur: false,
    validateOnChange: false,
  } as any);

  useEffect(() => {
    productImportFields(formImport?.values?.productType).then((res) => {
      const fieldList = res?.data?.data?.fields ?? [];
      const optionalList = res?.data?.data?.optional ?? [];
      const fieldListResult = map(fieldList, (item) => ({
        ...item,
        isRequired: !includes(optionalList, item.name),
      }));
      setImportFieldSource(fieldListResult);
    });
  }, [formImport?.values?.productType]);

  const classNameHeader =
    "d-flex justify-content-between align-items-center p-4 border-bottom";

  const renderContent = () => {
    switch (stepIndex) {
      case 0:
        return <SelectProductType />;
      case 1:
        return <SelectImportMethod />;
      case 2:
        return <SelectCSVFile />;
      case 3:
        return <FileValidationAndImport />;
      case 4:
        return <ImportResult />;
      default:
        return <div />;
    }
  };

  return (
    <AppContext.Provider
      value={{ step, formImport, stepIndex, importFieldSource, setStepIndex }}
    >
      <div className="bg-white">
        <div className={classNameHeader}>
          <label>{t(step?.title)}</label>
          <div className="bg-dark px-3 py-1">
            <small className="text-white">{`Step ${stepIndex + 1} of ${
              IMPORT_PRODUCT_STEPS.length
            }`}</small>
          </div>
        </div>
        {renderContent()}
        <ProductImportFooter />
      </div>
    </AppContext.Provider>
  );
};

export default ProductImport;
