import Drawer from "@components/shared/Drawer";
import { REFUND_STATUS } from "@constants/payment";
import AppContext from "@helpers/context";
import { addBankRefund, editBankRefund } from "@network/api/order";
import { Notifications, InputText, Progress } from "d-react-components";
import { isEmpty } from "lodash";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

export const ModalAddBank = ({ openModal, onClose, refund, bank }: any) => {
  const { onReloadOrderData } = useContext(AppContext);
  const { t } = useTranslation();

  const [bodyAddBank, setBodyAddBank] = useState({
    accountName: bank?.account_name,
    bankName: bank?.bank_name,
    accountNumber: bank?.account_number,
  });

  const onClickSaveAddBank = async () => {
    if (
      isEmpty(bodyAddBank.accountName) ||
      isEmpty(bodyAddBank.bankName) ||
      isEmpty(bodyAddBank.accountNumber)
    ) {
      Notifications.showError(t("notification:pleaseFillAllRequireField"));
      return Promise.reject();
    }
    const body: any = {
      refundid: refund.id,
      account_name: bodyAddBank.accountName,
      bank_name: bodyAddBank.bankName,
      account_number: bodyAddBank.accountNumber,
    };

    if (bank && bank.id) {
      body.bankid = bank.id;
      Progress.show({ method: editBankRefund, params: [body] }, () => {
        Notifications.showSuccess(t("notification:editBankRefundSuccess"));
        onReloadOrderData && onReloadOrderData();
        onClose();
      });
    } else {
      Progress.show({ method: addBankRefund, params: [body] }, () => {
        Notifications.showSuccess(t("notification:addBankRefundSuccess"));
        onReloadOrderData && onReloadOrderData();
        onClose();
      });
    }
  };

  const onChangeBodyAdd = (key: string, value: any) => {
    setBodyAddBank({ ...bodyAddBank, [key]: value });
  };

  return (
    <Drawer
      open={openModal}
      title={t("bankForRefund")}
      saveText={t("submit")}
      onSave={onClickSaveAddBank}
      onClose={onClose}
    >
      <div>
        <InputText
          className="inputBankRefund mb-3"
          onChange={(event) =>
            onChangeBodyAdd("accountName", event.target.value)
          }
          label={t("accountName")}
          value={bodyAddBank.accountName}
        />

        <InputText
          className="inputBankRefund mb-3"
          onChange={(event) => onChangeBodyAdd("bankName", event.target.value)}
          label={t("bankName")}
          value={bodyAddBank.bankName}
        />

        <InputText
          className="inputBankRefund mb-3"
          onChange={(event) =>
            onChangeBodyAdd("accountNumber", event.target.value)
          }
          label={t("accountNumber")}
          value={bodyAddBank.accountNumber}
        />
      </div>
    </Drawer>
  );
};

const AddBankRefundButton = ({ refund, bank }: any) => {
  const [openAddBank, setOpenAddBank] = useState(false);
  const { t } = useTranslation();
  const isEdit = !!bank;
  const isShowAddBank = () => {
    const { status } = refund;
    return (
      status !== REFUND_STATUS.refunded.key &&
      status !== REFUND_STATUS.cancelled.key
    );
  };

  if (!isShowAddBank()) {
    return <div />;
  }

  return (
    <>
      <div
        className="text-primary text-xs cursor-pointer"
        onClick={() => setOpenAddBank(true)}
      >
        {isEdit ? t("edit") : t("addbank")}
      </div>
      {openAddBank && (
        <ModalAddBank
          openModal={openAddBank}
          onClose={() => setOpenAddBank(false)}
          refund={refund}
          bank={bank}
        />
      )}
    </>
  );
};

export default AddBankRefundButton;
