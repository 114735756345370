import ActivitiesView from "@components/order/components/activity/ActivitiesView";
import AppContext from "@helpers/context";
import ChannelAPI from "@network/api/channel";
import { Notifications } from "d-react-components";
import { map } from "lodash";
import { useContext, useRef } from "react";
import { useTranslation } from "react-i18next";

const ChannelDetailActivity = () => {
  const { channel } = useContext(AppContext);
  const activityList = useRef(null);
  const { t } = useTranslation();

  const source = (paging: any) => {
    return ChannelAPI.activities(channel?.id, paging);
  };

  const transformer = (res: any) => {
    const activityList = res?.data?.data?.activities ?? [];
    return map(activityList, (item) => ({
      ...item,
      files: map(item.attachment, (attItem) => attItem?.attachment),
    }));
  };

  const onClickSendComment = (comment: any, attachment: any) => {
    const body = {
      id: channel?.id,
      comment,
      attachment: map(attachment, (item) => item?.key),
    };
    return ChannelAPI.activityAdd(body).then(() => {
      Notifications.showSuccess(t("addCommentSuccess"));
    });
  };

  return (
    <ActivitiesView
      hideType
      source={source}
      transformer={transformer}
      setRef={(listRefParams: any) => (activityList.current = listRefParams)}
      onClickSendComment={onClickSendComment}
      isInstantUploadAttach
    />
  );
};

export default ChannelDetailActivity;
