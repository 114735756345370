import { CART_RULE_ACTION } from "@constants/cart-rule";
import AppContext from "@helpers/context";
import { filter, map, now } from "lodash";
import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import GiftContainer from "./GiftContainer";
import { Button } from "d-react-components";

const BundleFreeProduct = ({ className }: any) => {
  const { cartRuleForm } = useContext(AppContext);
  const { t } = useTranslation();
  const formValues = cartRuleForm.values ?? {};

  const actionKey =
    formValues?.actions?.specific ?? CART_RULE_ACTION.BUY_X_GET_X;

  const currentGiftList = useMemo(() => {
    return formValues?.actions?.[actionKey]?.gift ?? [];
  }, [formValues?.actions]);

  const onClickAddBundleHandle = () => {
    const clone = [
      ...currentGiftList,
      {
        id: now(),
        name: `${t("freeGiftBundle")} ${currentGiftList.length + 1}`,
        product: [],
      },
    ];
    setChangeGifts(clone);
  };

  const setChangeGifts = (newGifts: any) => {
    const cloneAction = {
      ...formValues.actions,
      [actionKey]: {
        ...formValues.actions[actionKey],
        gift: newGifts,
      },
    };
    cartRuleForm.setFieldValue("actions", cloneAction);
  };

  const handleChangeGift = (payload: any) => {
    const result = map(currentGiftList, (item) => {
      if (item?.id === payload?.id) {
        return payload;
      }
      return item;
    });
    setChangeGifts(result);
  };

  const removeGiftHandle = (item: any) => {
    const cloneGift = filter(currentGiftList, (gif) => gif?.id !== item?.id);
    setChangeGifts(cloneGift);
  };

  return (
    <div className={className}>
      <div className="my-2">{t("willGetOneOfTheFollowingBundles")}</div>
      <div className="bundleListContainer">
        {map(currentGiftList, (gift, index) => (
          <GiftContainer
            payload={gift}
            key={`${index}${gift?.id}`}
            setChangeBundle={(payload: any) => handleChangeGift(payload)}
            deleteBundle={(payload: any) => removeGiftHandle(payload)}
          />
        ))}
      </div>
      <Button iconName="add" className="mt-2" onClick={onClickAddBundleHandle}>
        {t("morebundle")}
      </Button>
    </div>
  );
};

export default BundleFreeProduct;
