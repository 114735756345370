import { useTranslation } from "react-i18next";

const DocumentDropZone = ({ getRootProps, getInputProps, inputParam }: any) => {
  const { t } = useTranslation();
  return (
    <div
      className="rounded border-dashed border-primary flex w-100 h-[160px] items-center justify-center flex-col"
      {...getRootProps()}
    >
      <img src="/images/icons/upload.svg" className="w-8" />
      <span className="text-bold">{t("dragDropOrBrowse")}</span>
      <span className="text-gray-700 text-xs">
        {t("dragDropOrBrowseSupport")}
      </span>
      <input {...getInputProps(inputParam)} />
    </div>
  );
};

export default DocumentDropZone;
