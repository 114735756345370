import { Path } from "@components/layout/Path";
import AppContext from "@helpers/context";
import { createGpEvent } from "@network/api/gp-event";
import { Button, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import GPEventCreateGeneral from "./GPEventCreateGeneral";

const GPEventCreateSchema = Yup.object().shape({
  name: Yup.string().required("Required field!"),
  visibility: Yup.string().required("Required field!"),
  chain: Yup.object().required("Required field!"),
  remark: Yup.string().required("Required field!"),
  period: Yup.array().required("Required field!"),
});

const mapGPEventToServer = (event: any) => {
  const { chain, period, ...rest } = event;
  return {
    ...rest,
    chain_id: chain?.id,
    from: period?.[0]?.valueOf(),
    to: period?.[1]?.valueOf(),
  };
};

const GPEventCreate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const eventForm = useFormik<any>({
    initialValues: {},
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: GPEventCreateSchema,
    onSubmit: (values) => {
      const input = mapGPEventToServer(values);
      Progress.show({ method: createGpEvent, params: [input] }, (res: any) => {
        Notifications.showSuccess(t("createEventSuccessfully"));
        navigate(
          generatePath(Path.GP_EVENT_DETAIL, { eventId: res?.data?.data?.id })
        );
      });
    },
  });

  const formValues = eventForm?.values;
  const formErrors = eventForm?.errors;

  return (
    <AppContext.Provider value={{ formValues, eventForm, formErrors }}>
      <div className="p-3">
        <div className="flex-column-container bg-trans">
          <div className="card-container p-4 flex-center justify-content-between">
            <h4>{t("newGPEvent")}</h4>
            <Button onClick={() => eventForm.handleSubmit()} className="mr-3">
              {t("save")}
            </Button>
          </div>
        </div>
        <GPEventCreateGeneral />
      </div>
    </AppContext.Provider>
  );
};

export default GPEventCreate;
