import Drawer from "@components/shared/Drawer";
import styled from "@emotion/styled";
import AppContext from "@helpers/context";
import { AwesomeTableComponent, Button, Icon } from "d-react-components";
import React, { useContext, useEffect, useMemo } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";

const OrderMetadata = ({ open, onClose, orderId }: any) => {
  const { t } = useTranslation();
  const { orderMetadata, loadOrderMetadata } = useContext(AppContext);
  const columns = useMemo(
    () => [
      {
        title: t("key"),
        dataIndex: "name",
        width: "300px",
        render: (data: any) => <div className="break-all text-xs">{data}</div>,
      },
      {
        title: t("value"),
        dataIndex: "value",
        render: (data: any) => <div className="break-all text-xs">{data}</div>,
      },
    ],
    []
  );

  useEffect(() => {
    loadOrderMetadata();
  }, []);

  return (
    <Drawer
      title={
        <div className="flex items-center">
          <span className="flex-1">{t("3rdPartyData")}</span>
          <CSVLink
            data={orderMetadata?.map((item: any) => ({
              name: item.name,
              value: item.value,
            }))}
            filename={`3rd_party_data_${orderId}`}
          >
            <Button size="small">
              <Icon className="w-6" name="cloud_download_icon" />
              {t("download")}
            </Button>
          </CSVLink>
        </div>
      }
      open={open}
      onClose={onClose}
      destroyOnClose
      size="auto"
      width="800px"
      showCancel={false}
      hideFooter={true}
    >
      <OrderMetadataTable>
        <AwesomeTableComponent
          dataSource={orderMetadata}
          columns={columns}
          pagination={false}
        />
      </OrderMetadataTable>
    </Drawer>
  );
};

const OrderMetadataTable = styled.div`
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 8px 12px;
  }

  .ant-table-thead > tr > th {
    font-weight: bold;
  }
`;

export default OrderMetadata;
