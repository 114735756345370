import Drawer from "@components/shared/Drawer";
import AppContext from "@helpers/context";
import { editProductStockRequest } from "@network/api/stock-request";
import { Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import { map } from "lodash";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import StockRequestProductForm from "../components/StockRequestProductForm";
import StockRequestProductsErrorDrawer from "./StockRequestProductsErrorDrawer";

const StockRequestDetailProductsEditDrawer = ({ open, onClose }: any) => {
  const { requestDetail, loadRequestDetail } = useContext(AppContext);
  const [openStockError, setOpenStockError] = useState(false);
  const { t } = useTranslation();

  const productForm = useFormik({
    initialValues: {
      products: requestDetail?.products ?? [],
    },
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: schema,
    onSubmit: (values) => {
      const products = map(values?.products, (item) => ({
        product_id: item.id,
        quantity: item.quantity,
      }));
      Progress.show(
        {
          method: editProductStockRequest,
          params: [requestDetail?.id, products],
        },
        () => {
          Notifications.showSuccess(t("updateSuccess"));
          onClose();
          loadRequestDetail();
        },
        (err: any) => {
          if (err === "Products invalid") {
            setOpenStockError(true);
            return true;
          }
        }
      );
    },
  });

  const formValues = productForm?.values;

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("general")}
      onSave={() => productForm.handleSubmit()}
      size="auto"
      width="70%"
    >
      <StockRequestProductForm form={productForm} className="mt-3" />
      {openStockError && (
        <StockRequestProductsErrorDrawer
          productSubmitted={formValues?.products}
          inventoryEventControl={requestDetail?.stock_inventory_control}
          requestId={requestDetail?.id}
          open={openStockError}
          onClose={() => setOpenStockError(false)}
        />
      )}
    </Drawer>
  );
};

export default StockRequestDetailProductsEditDrawer;
