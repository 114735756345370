import { MARKETPLACE } from "@constants/order";
import { listShopeShop, listLazadaShop } from "@network/api/order";
import { Select } from "d-react-components";
import { debounce, find, includes, isEmpty, uniqBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const LIST_API_BY_SHOP_TYPE = {
  [MARKETPLACE.LAZADA]: listLazadaShop,
  [MARKETPLACE.SHOPEE]: listShopeShop,
};

export function SelectShop({ value = [], onChange, type, ...props }: any) {
  const [listShop, setListShop] = useState<any>(value);
  const { t } = useTranslation();

  const loadListShop = (search: string) => {
    const api = LIST_API_BY_SHOP_TYPE[type];
    api({ search }, { pageIndex: 1, pageSize: 50 }).then((res) => {
      const shopList = res?.data?.data?.shop ?? [];
      const values = !isEmpty(value) ? [value] : [];
      const uniqShop = uniqBy([...values, ...shopList], (item) => item.id);
      setListShop(uniqShop);
    });
  };

  // const onChangeText = debounce((search) => {
  //   loadListShop(search);
  // }, 200);

  useEffect(() => {
    loadListShop("");
  }, [type]);

  return (
    <Select
      label={t("shop")}
      value={value?.id}
      onChange={(values) => {
        const result = find(listShop, (item) => includes(values, item.id));
        onChange(result);
      }}
      dataSource={listShop}
      getLabel={(item) => item.name}
      showSearch
      {...props}
      // onSearch={(textSearch) => onChangeText(textSearch)}
      placeholder={t("pleaseSelect")}
    />
  );
}

export default SelectShop;
