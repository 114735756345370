import { useOnClickOutside } from "@helpers/useClickOutside";
import InfiniteScroll from "react-infinite-scroller";
import { INotification } from "@interfaces/common";
import {
  getAllNotifications,
  readAllNotifications,
  readNotifications,
} from "@network/api/notification";
import {
  Avatar,
  Button,
  Notifications,
  TabBar,
  TimeUtils,
} from "d-react-components";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { notification, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import Drawer from "@components/shared/Drawer";

const TABS = [
  {
    value: "all",
    id: "all",
  },
  {
    value: "unread",
    id: "unread",
  },
  {
    value: "read",
    id: "read",
  },
];

const Badge = ({ children }: any) => {
  return (
    <div className="px-[3px] py-0 text-[10px] bg-red-500 text-white rounded-full">
      {children}
    </div>
  );
};

const replaceUrl = (notification: INotification) => {
  if (notification.screen === "orderDetail") {
    return {
      ...notification,
      url: `/order/${notification.screenid}`,
    };
  }
  return notification;
};

const NotificationItem = ({
  notification,
}: {
  notification: INotification;
}) => {
  const isRead = useMemo(() => {
    return !!notification?.receiver?.read;
  }, [notification]);
  const navigate = useNavigate();

  const onClickNotificationItem = (item: INotification) => {
    if (!isRead) {
      readNotifications({ notificationid: item?.id });
    }
    window.open(item?.url, "_self");
    // if (item?.url?.startsWith("http")) {
    //   window.open(item?.url, "_self");
    // } else {
    //   navigate(item?.url, {
    //     replace: true
    //   });
    // }
  };

  return (
    <div
      className={
        isRead ? "bg-white cursor-pointer" : "bg-blue-100 cursor-pointer"
      }
      onClick={() => onClickNotificationItem(notification)}
    >
      {/* <Avatar alt="avatar" className="avaImage" src={Images.notification} /> */}
      <div className="p-2 border-b border-gray-300">
        <div className="subTitle1">{notification.description}</div>
        <div className="text-gray-500">
          {TimeUtils.convertMiliToDateTime(notification.created)}
        </div>
      </div>
    </div>
  );
};

const NotificationBox = () => {
  const [showBox, setShowBox] = useState(false);
  const [unReadNotification, setUnReadNotification] = useState(0);

  useEffect(() => {
    loadUnReadNotification();
  }, []);

  const unReadBadge = useMemo(() => {
    if (unReadNotification === 0) return "";
    if (unReadNotification < 100) return unReadNotification;
    return "99+";
  }, [unReadNotification]);

  const loadUnReadNotification = () => {
    const body = { filter: "", status: "unread" };
    getAllNotifications(body).then((res) => {
      const results = res?.data?.data?.pagination?.items ?? 0;
      setUnReadNotification(results);
    });
  };

  return (
    <div className="relative">
      <div className="relative">
        <Button
          iconName="notifications"
          variant="trans"
          onClick={() => setShowBox(true)}
        />
        {unReadBadge && (
          <div className="absolute top-[5px] right-0">
            <Badge>{unReadBadge}</Badge>
          </div>
        )}
      </div>

      {showBox && (
        <div className="absolute bg-white top-[40px] right-0 p-3 drop-shadow-lg border">
          <NotificationPopup
            unReadNotification={unReadNotification}
            setUnReadNotification={setUnReadNotification}
            open={showBox}
            onClose={() => setShowBox(false)}
          />
        </div>
      )}
    </div>
  );
};

const NotificationTab = ({
  status,
  setUnReadNotification,
}: {
  status: string;
  setUnReadNotification: any;
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [paging, setPaging] = useState<any>({ currentPage: 0 });
  const [listNotifications, setListNotifications] = useState<INotification[]>(
    []
  );

  const loadNotifications = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    getAllNotifications({
      filter: "",
      status,
      page: paging.currentPage + 1,
      limit: 20,
    })
      .then((resp) => {
        setListNotifications([
          ...listNotifications,
          ...(resp?.data?.data?.notification?.map((n: any) => replaceUrl(n)) ??
            []),
        ]);
        setPaging(resp?.data?.data?.pagination);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const readAll = () => {
    readAllNotifications()
      .then(() => {
        Notifications.showSuccess(t("notification:markAllAsReadSuccess"));
        setUnReadNotification(0);
        setListNotifications(
          listNotifications?.map((item) => ({
            ...item,
            receiver: {
              ...item.receiver,
              read: true,
            },
          }))
        );
      })
      .catch((e) => {
        Notifications.showError(e);
      });
  };

  return (
    <>
      <div className="absolute top-[68px] right-[10px] text-right">
        <span className="text-primary cursor-pointer" onClick={() => readAll()}>
          {t("markAllAsRead")}
        </span>
      </div>
      <InfiniteScroll
        pageStart={0}
        loadMore={loadNotifications}
        hasMore={paging.currentPage !== paging.total}
        useWindow={false}
        loader={
          <div
            className="py-2 w-full flex items-center justify-content-center"
            key={0}
          >
            <Spin />
          </div>
        }
      >
        {listNotifications?.map((item, index) => {
          return <NotificationItem notification={item} key={index} />;
        })}
      </InfiniteScroll>
    </>
  );
};

const NotificationPopup = ({
  setUnReadNotification,
  open,
  onClose,
  unReadNotification,
}: any) => {
  const [tabSelected, setTabSelected] = useState<any>(TABS[0]);
  const { t } = useTranslation();

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("notification")}
      bodyStyle={{ padding: 0 }}
    >
      <div className="flex items-center">
        <TabBar
          dataSource={TABS}
          onChange={(tab) => setTabSelected(tab)}
          value={tabSelected}
          getLabel={(item) => {
            if (item.value === "unread") {
              return (
                <>
                  <span className="mr-2">{t(item.value)}</span>{" "}
                  {unReadNotification > 0 && (
                    <Badge>{unReadNotification}</Badge>
                  )}
                </>
              );
            }
            return t(item.value);
          }}
        />
      </div>
      <div className="p-2">
        {TABS?.map((tab, index) =>
          tab.value === tabSelected.value ? (
            <div className="h-[calc(100vh-164px)] overflow-auto" key={index}>
              <NotificationTab
                status={tab.value}
                setUnReadNotification={setUnReadNotification}
              />
            </div>
          ) : null
        )}
      </div>
    </Drawer>
  );
};

export default NotificationBox;
