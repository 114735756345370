import { AwesomeTableComponent, Button, InputTextSearch, Notifications, Progress, TimeUtils } from "d-react-components";
import { map } from "lodash";
import React, { useContext, useRef, useState } from "react";
import UserDetailSOCollaborationModal from "./UserDetailSOCollaborationModal";
import AppContext from "@helpers/context";
import { useTranslation } from "react-i18next";
import UserAPI from "@network/api/user";

const UserDetailSOCollaboration = () => {
  const { user } = useContext(AppContext);
  const { t } = useTranslation();
  const tableRef = useRef<any>();
  const [openAdd, setOpenAdd] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const textSearch = useRef();

  const columns = [
    {
      title: t("id"),
      dataIndex: "id",
      render: (id: any, user: any) => <a href={user.editurl}>{id ?? "N/A"}</a>,
    },
    {
      title: t("avatar"),
      dataIndex: "avatar",
      render: (avatar: any) => (
        <img src={avatar} className="image-square-small" />
      ),
    },
    {
      title: t("fullName"),
      dataIndex: "fullname",
      render: (fullName: any, user: any) => (
        <a href={user.editurl}>{fullName ?? "N/A"}</a>
      ),
    },
    {
      title: t("companyId"),
      dataIndex: "employee_id",
    },
  ];

  const source = (paging: any) => {
    const body = { id: user?.id, action: "in", search: textSearch.current };
    return UserAPI.soCollaborationList(body, paging);
  };

  const transformer = (res: any) => {
    return res?.data?.data?.admin ?? [];
  };

  const onAddUser = (users: any) => {
    const body = {
      id: user?.id,
      action: "add",
      admin: map(users, (item) => item.id),
    };
    Progress.show(
      { method: UserAPI.soCollaborationUpdate, params: [body] },
      () => {
        Notifications.showSuccess(t("updateSuccessfully"));
        tableRef.current.refresh();
        setOpenAdd(false);
      }
    );
  };

  const onRemoveUser = (users: any) => {
    const body = {
      id: user?.id,
      action: "remove",
      admin: map(users, (item) => item.id),
    };
    Progress.show(
      { method: UserAPI.soCollaborationUpdate, params: [body] },
      () => {
        Notifications.showSuccess(t("removeSuccessfully"));
        tableRef.current.refresh();
        setOpenRemove(false);
      }
    );
  };

  return (
    <div className="border mt-3 p-4">
      <div className="justify-content-between d-flex align-items-center">
        <InputTextSearch
          placeholder={t("search")}
          onChange={(event: any) => {
            textSearch.current = event.target.value;
            tableRef.current.refresh();
          }}
        />
        <div className="d-flex">
          <Button className="ml-3" onClick={() => setOpenRemove(true)}>
            {t("remove")}
          </Button>
          <Button className="ml-3" onClick={() => setOpenAdd(true)}>
            {t("add")}
          </Button>
        </div>
      </div>
      <AwesomeTableComponent
        ref={(ref) => {
          tableRef.current = ref;
        }}
        columns={columns}
        source={source}
        transformer={transformer}
        className="mt-3"
      />
      {openAdd && (
        <UserDetailSOCollaborationModal
          open={openAdd}
          onClose={() => setOpenAdd(false)}
          userId={user?.id}
          onSave={onAddUser}
          type="not_in"
          title={t("addUsers")}
        />
      )}
      {openRemove && (
        <UserDetailSOCollaborationModal
          open={openRemove}
          onClose={() => setOpenRemove(false)}
          userId={user?.id}
          onSave={onRemoveUser}
          type="in"
          title={t("removeUser")}
        />
      )}
    </div>
  );
};

export default UserDetailSOCollaboration;
