import { API } from "@network";
import {
  API_INVENTORY_CONTROL_EVENT_ACTIVITY_ADD,
  API_INVENTORY_CONTROL_EVENT_ACTIVITY_LIST,
  API_INVENTORY_CONTROL_EVENT_CREATE,
  API_INVENTORY_CONTROL_EVENT_DETAIL,
  API_INVENTORY_CONTROL_EVENT_GP_DELETE,
  API_INVENTORY_CONTROL_EVENT_INVENTORY,
  API_INVENTORY_CONTROL_EVENT_GP_UPDATE,
  API_INVENTORY_CONTROL_EVENT_LIST,
  API_INVENTORY_CONTROL_EVENT_STOCK_DELETE,
  API_INVENTORY_CONTROL_EVENT_STOCK_LIST,
  API_INVENTORY_CONTROL_EVENT_STOCK_UPDATE,
  API_INVENTORY_CONTROL_EVENT_UPDATE,
  API_INVENTORY_CONTROL_STOCK,
  API_CHECK_INVENTORY_CONTROL_STOCK,
} from "@network/URL";

export const getListInventoryControlEvent = (body: any, paging: any) =>
  API.post(
    `${API_INVENTORY_CONTROL_EVENT_LIST}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
    body
  );

export const createInventoryControlEvent = (body: any, paging: any) =>
  API.post(API_INVENTORY_CONTROL_EVENT_CREATE, body);

export const getInventoryControlEvent = (id: string) =>
  API.post(API_INVENTORY_CONTROL_EVENT_DETAIL, { id });

export const updateInventoryControlEvent = (body: any & { id: string }) =>
  API.post(API_INVENTORY_CONTROL_EVENT_UPDATE, body);

export const getInventoryControlEventActivity = (paging: any, body: any) => {
  return API.post(
    `${API_INVENTORY_CONTROL_EVENT_ACTIVITY_LIST}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
    body
  );
};

export const addInventoryControlEventActivity = (body: any) => {
  return API.post(API_INVENTORY_CONTROL_EVENT_ACTIVITY_ADD, body);
};

export const getInventoryControlEventStocks = (paging: any, body: any) => {
  return API.post(
    `${API_INVENTORY_CONTROL_EVENT_STOCK_LIST}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
    body
  );
};

export const updateInventoryControlEventStock = (body: any) => {
  return API.post(API_INVENTORY_CONTROL_EVENT_STOCK_UPDATE, body);
};

export const removeInventoryControlEventStock = (body: any) => {
  return API.post(API_INVENTORY_CONTROL_EVENT_STOCK_DELETE, body);
};

export const updateInventoryControlEventGP = (body: any) => {
  return API.post(API_INVENTORY_CONTROL_EVENT_GP_UPDATE, body);
};

export const getInventoryControlStock = (body: any) => {
  return API.post(API_INVENTORY_CONTROL_STOCK, body);
};

export const removeInventoryControlEventGP = (eventId: string) => {
  return API.post(API_INVENTORY_CONTROL_EVENT_GP_DELETE, {
    stock_inventory_control_id: eventId,
  });
};

export const getInventoryControlEventInfo = (paging: any, evenId: any) => {
  return API.post(
    `${API_INVENTORY_CONTROL_EVENT_INVENTORY}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
    {
      stock_inventory_control_id: evenId,
    }
  );
};
export const checkInventoryControlStock = (body: any) => {
  return API.post(API_CHECK_INVENTORY_CONTROL_STOCK, body);
};
