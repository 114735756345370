/* eslint-disable no-continue */
import AppContext from "@helpers/context";
import { exportToCSV } from "@helpers/file";
import { forEach, includes, isEmpty, map, replace, split } from "lodash";
import React, { useContext } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

export const IMPORT_FIELDS = [
  {
    id: "sku",
    label: "sku",
    dataSample: "1234567891011",
  },
  {
    id: "nav_so",
    label: "nav_so",
    dataSample: "BED39823497",
  },
  {
    id: "nav_rp",
    label: "nav_rp",
    dataSample: "RP39823497",
  },
  {
    id: "production_date",
    label: "production_date",
    dataSample: "15-06-2024",
  },
  {
    id: "country_en",
    label: "country_en",
    dataSample: "Thailand",
  },
  {
    id: "country_th",
    label: "country_th",
    dataSample: "Thailand (TH)",
  },
  {
    id: "manufactured_by_en",
    label: "manufactured_by_en",
    dataSample: "Omazz Co., Ltd",
  },
  {
    id: "manufactured_by_th",
    label: "manufactured_by_th",
    dataSample: "Omazz Co., Ltd (TH)",
  },
  {
    id: "distributed_by_en",
    label: "distributed_by_en",
    dataSample: "Lotus Mattress Co., Ltd",
  },
  {
    id: "distributed_by_th",
    label: "distributed_by_th",
    dataSample: "Lotus Mattress Co., Ltd (TH)",
  },
];

const splitRemoveSpaceString = (stringData = "") => {
  const breakArray = includes(stringData, ";")
    ? split(stringData, ";")
    : split(stringData, ",");
  return map(breakArray, (item) => replace(item, "\r", ""));
};

function convertCsvJSON(csv: any) {
  /**
   * ,[space][space], => ''
   */
  const clearedSpaceCommaString = csv.replace(/,  +,/g, ",,");
  /**
   *  "Address, Bangkok" => "Address[space]Bangkok"
   */
  const convertCommaString = clearedSpaceCommaString?.replaceAll(
    ", ",
    "[space]"
  );
  const lines = split(convertCommaString, "\n");
  const result = [];
  const headers = splitRemoveSpaceString(lines[0]);

  for (let i = 1; i < lines.length; i++) {
    if (!lines[i]) continue;
    const obj: any = {};
    const currentLine = splitRemoveSpaceString(lines[i]);

    for (let j = 0; j < headers.length; j++) {
      const currentHeader = headers[j];
      let value: any = currentLine[j];
      if (value === "TRUE") {
        value = true;
      }
      if (value === "FALSE") {
        value = false;
      }
      if (!isEmpty(currentHeader)) {
        const valueRemoveSpace = `${value}`?.replaceAll("[space]", ", ");
        // eslint-disable-next-line no-useless-escape
        const valueRemoveMark = valueRemoveSpace?.replaceAll(`\"`, "");
        obj[currentHeader] = valueRemoveMark;
      }
    }
    result.push(obj);
  }
  return result;
}

const ProductionPrintingImportBrowseFile = () => {
  const { formImport } = useContext(AppContext);
  const { t } = useTranslation();
  const { fileImport } = formImport.values;

  const onDrop = ([file]: any[]) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = function () {
      const data: any = convertCsvJSON(reader.result);
      const dataMapped = map(data, (dataItem) => {
        const result: any = {};
        forEach(IMPORT_FIELDS, (fieldItem) => {
          result[fieldItem.id] = dataItem[fieldItem.label];
        });

        return result;
      });

      formImport.setFieldValue("fileImport", {
        ...fileImport,
        name: file?.name,
        data: dataMapped,
      });
    };
  };

  const onClickDownloadTemplate = () => {
    const importColumn: any = {};

    forEach(IMPORT_FIELDS, (fieldItem) => {
      const { label, dataSample } = fieldItem;
      importColumn[label] = dataSample;
    });

    exportToCSV([importColumn], "Import_production_printing_file");
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="p-4">
      <div>
        <div
          className="flex-center border-dashed p-5 flex-column"
          {...getRootProps()}
        >
          <img src={"/images/insert-file.svg"} className="mt-5" />
          <input {...getInputProps()} />
          <text className="font-weight-bold my-3">
            {t("dragAndDropYourCSVHere")}
          </text>
          <small>{t("maximumFileSizeIs5MB")}</small>
          <small className="mb-5 text-primary text-underline">
            {t("orSelectFromYourComputer")}
          </small>
          <text className="mb-5 text-primary">{fileImport?.name}</text>
        </div>
        <div className="flex-column d-flex">
          <text className="mt-3">{t("notSureHowToStart")}</text>
          <text>- {t("downloadAsvFile")}</text>
          <div className="d-flex text">
            {`- ${t("download")}`}
            <div
              onClick={onClickDownloadTemplate}
              className="mx-3 text-primary cursor-pointer"
            >
              {t("blankCSVTemplate")}
            </div>
            {` for product label`}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductionPrintingImportBrowseFile;
