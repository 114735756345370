import Icon from "@common/Icon";
import Drawer, { DrawerProps } from "@components/shared/Drawer";
import { PROMOTION_STATUS } from "@constants/promotion";
import AppContext from "@helpers/context";
import { updatePromotion } from "@network/api/promotion";
import {
  DateInput,
  Notifications,
  Progress,
  TimeUtils,
} from "d-react-components";
import { useFormik } from "formik";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

const PromotionDetailPeriod = () => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const { promotionDetail, status } = React.useContext(AppContext);
  const editable = useMemo(
    () => status === PROMOTION_STATUS.SCHEDULED,
    [status]
  );
  return (
    <div className="bg-white p-3 mb-3 shadow-sm">
      <div className="flex items-center mb-2">
        <label className="block text-sm flex-1 mb-0">{t("period")}</label>
        <div>
          {editable && (
            <Icon
              onClick={() => setOpenModal(true)}
              className="w-[15px] cursor-pointer"
              src="/images/icons/edit.svg"
            />
          )}
        </div>
      </div>
      <div>
        <div>
          {t("startsAt")}: {TimeUtils.toDateTime(promotionDetail?.start)}
        </div>
        <div>
          {t("endsAt")}: {TimeUtils.toDateTime(promotionDetail?.end)}
        </div>
      </div>
      {openModal && (
        <PeriodEditDrawer open={openModal} onClose={setOpenModal} />
      )}
    </div>
  );
};

const PeriodEditDrawer = ({ open, onClose }: DrawerProps) => {
  const { t } = useTranslation();
  const { promotionDetail, reloadPromotion } = React.useContext(AppContext);

  const promotionForm = useFormik<any>({
    initialValues: {
      start: moment(promotionDetail?.start),
      end: moment(promotionDetail?.end),
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      start: Yup.mixed().required(t("fieldRequired")),
      end: Yup.mixed().required(t("fieldRequired")),
    }),
    onSubmit: (values: any) => {},
  });
  const { values, errors, setFieldValue } = promotionForm;

  const onSave = () => {
    Progress.show(
      {
        method: updatePromotion,
        params: {
          start: values.start.valueOf(),
          end: values.end.valueOf(),
          promotionid: promotionDetail?.id,
        },
      },
      () => {
        reloadPromotion && reloadPromotion();
        Notifications.showSuccess(t("notification:updatePromotionSuccess"));
        onClose();
      },
      (err: string) => {
        Notifications.showError(err);
      }
    );
  };

  return (
    <Drawer
      title={t("period")}
      open={open}
      onClose={() => onClose(false)}
      size="auto"
      width="450"
      onSave={onSave}
    >
      <DateInput
        label={t("startDate")}
        value={values.start}
        onChange={(value) => setFieldValue("start", value)}
        className="mb-3"
        error={errors.start as string}
        showTime
        format="DD/MM/YYYY HH:mm"
        disabledDate={(d) => d.isBefore(moment().startOf("day"))}
      />
      <DateInput
        label={t("endDate")}
        value={values.end}
        onChange={(value) => setFieldValue("end", value)}
        className="mb-3"
        error={errors.end as string}
        showTime
        format="DD/MM/YYYY HH:mm"
        disabledDate={(d) => d.isBefore(values.start)}
      />
    </Drawer>
  );
};

export default PromotionDetailPeriod;
