import FilterActionButton from "@components/order/components/FilterActionButton";
import ActivitiesView from "@components/order/components/activity/ActivitiesView";
import { FILTER_COMMENT_TYPES } from "@constants/common";
import {
  addPromotionComment,
  getPromotionActivities,
} from "@network/api/promotion";
import { Notifications } from "d-react-components";
import { isEmpty } from "lodash";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const PromotionActivities = () => {
  const { promotionId } = useParams();
  const { t } = useTranslation();
  const activityList = useRef<any>(null);
  const filters = useRef(FILTER_COMMENT_TYPES);

  const source = (paging: any) => {
    return getPromotionActivities({ promotion_id: promotionId }, paging);
  };

  const transformer = (res: any) => {
    return res?.data?.data?.activities ?? [];
  };

  const onClickSendComment = (commentText: any, attachments: any) => {
    if (isEmpty(commentText)) {
      Notifications.showError(t("commentContentCanNotBeNull"));
      return Promise.reject();
    }

    const data = new FormData();
    data.append("promotion_id", promotionId || "");
    attachments?.[0]?.fileData &&
      data.append("file", attachments?.[0]?.fileData);
    data.append("comment", commentText);
    return addPromotionComment(data).then((res) => {
      Notifications.showSuccess(t("addCommentSuccess"));
    });
  };

  const onFilterActivity = (filterList: any) => {
    filters.current = filterList;
    activityList.current.refresh();
  };

  return (
    <ActivitiesView
      source={source}
      transformer={transformer}
      setRef={(listRefParams: any) => (activityList.current = listRefParams)}
      onClickSendComment={onClickSendComment}
      hideType
      renderSubTitle={() => (
        <FilterActionButton
          dataSource={FILTER_COMMENT_TYPES}
          onSelectFilter={onFilterActivity}
          defaultSelected={FILTER_COMMENT_TYPES}
        />
      )}
    />
  );
};

export default PromotionActivities;
