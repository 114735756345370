import { ADMIN_LANG_KEY } from "@constants";
import i18next from "i18next";
import { find } from "lodash";

const ns = ["common", "order", "menu", "product"];

export const getLangResource = (lang: string) => {
  const langResource = i18next.getFixedT(lang, ns);
  return langResource;
};

export const getBothLangsText = (
  key: string,
  isShowBothLangs: boolean = true,
  separateLine: boolean = false
) => {
  const savedLang = localStorage.getItem(ADMIN_LANG_KEY);
  const currentLang = savedLang ? JSON.parse(savedLang) : "en";
  i18next.loadLanguages(["en", "th"]);
  const enLang = i18next.getFixedT("en", ns);
  const thLang = i18next.getFixedT("th", ns);

  if (isShowBothLangs && thLang && enLang) {
    return `${thLang(key)}/${separateLine ? "\n" : ""}${enLang(key)}`;
  }
  return currentLang === "en" ? enLang(key) : thLang(key);
};

export const findTranslation = (translations: any) => {
  const savedLang = localStorage.getItem(ADMIN_LANG_KEY);
  const currentLang = savedLang ? JSON.parse(savedLang) : "en";
  return find(translations, { locale: currentLang }) ?? {};
};
