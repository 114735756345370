import { Select } from "d-react-components";
import { useTranslation } from "react-i18next";

interface IInputSelectForm {
  form: any;
  keyData: string;
  label?: any;
  className?: any;
  [key: string]: any;
}

const InputSelectForm = ({
  form,
  keyData,
  label,
  className,
  ...props
}: IInputSelectForm) => {
  const { t } = useTranslation();

  // eslint-disable-next-line no-unneeded-ternary
  const labelValue = label ? label : t(keyData);
  return (
    <Select
      className={className}
      label={labelValue}
      name={keyData}
      error={form.errors[keyData]}
      value={form.values[keyData]}
      onChange={(value) => form.setFieldValue(keyData, value)}
      placeholder={labelValue}
      getLabel={(item) => t(item.label)}
      {...props}
    />
  );
};

export default InputSelectForm;
