import {
  Notifications,
  Progress,
} from "d-react-components";
import * as Yup from "yup";
import { useFormik } from "formik";
import React from "react";
import WarehouseAPI from "@network/api/warehouse";
import { useTranslation } from "react-i18next";
import Drawer from "@components/shared/Drawer";
import InputTextForm from "@common/input/InputTextForm";
import InputCurrency from "@common/input/InputCurrency";

const WarehouseKPISchema = Yup.object().shape({
  kpi_target: Yup.string().nullable().required("Required field!"),
});

function WarehouseDetailUserKPIDetailUpdateDrawer({
  open,
  onClose,
  warehouseId,
  brandId,
  onUpdateSuccess,
}: any) {
  const { t } = useTranslation();

  const kpiForm = useFormik<any>({
    initialValues: {},
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: WarehouseKPISchema,
    onSubmit: (values) => {
      const formValue = {
        kpi_target: Number(values.kpi_target),
      };
      const body = {
        warehouse_id: warehouseId,
        brand_id: brandId,
        status: 1,
        ...formValue,
      };
      Progress.show(
        { method: WarehouseAPI.kpiWarehouseUpdateDetail, params: [body] },
        () => {
          Notifications.showSuccess(t("updateWarehouseSuccess"));
          onUpdateSuccess();
          onClose();
        }
      );
    },
  });

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("updateTargetKPI")}
      onSave={() => kpiForm.handleSubmit()}
      size="medium"
    >
      <div className="row">
        <InputCurrency
          keyData="kpi_target"
          form={kpiForm}
          className="col-12 ml-auto mb-3"
          label={t("targetKPI")}
        />
      </div>
    </Drawer>
  );
}

export default WarehouseDetailUserKPIDetailUpdateDrawer;
