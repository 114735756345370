/* eslint-disable jsx-a11y/alt-text */
import { Path } from "@components/layout/Path";
import UserAvatarName from "@components/shared/UserAvatarName";
import { VEHICLE_FILTER_KEY, VEHICLE_STATUS_LIST } from "@constants/vehicle";
import AppContext from "@helpers/context";
import { getVehicleList } from "@network/api/vehicle";

import {
  AwesomeTableComponent,
  HeaderTable,
  IColumnsProps,
  ITabItem,
  ObjectUtils,
  TimeUtils,
  useFirstTime,
  ViewLabelStatus,
} from "d-react-components";

import { debounce, find, isEmpty, map } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";

const VehicleTable = () => {
  const tableRef = useRef<any>();
  const searchRef = useRef<string>();
  const [tabSelected, setTabSelected] = useState<ITabItem>({
    id: "all",
    label: "all",
  });
  const [openFilter, setOpenFilter] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [filterBody = {}, setFilterBody] = useQueryParam<any>(
    "filter",
    JsonParam
  );

  const isFirstTime = useFirstTime();

  useEffect(() => {
    if (isFirstTime) return;
    tableRef.current?.refresh();
  }, [JSON.stringify(filterBody), tabSelected]);

  const source = (paging: any) => {
    let body = {
      search: searchRef?.current,
    };
    if (filterBody) {
      const { driver = [], status } = filterBody;
      const filterBodyToMap = {
        ...filterBody,
        driver: map(driver, (item) => item.id),
      };
      // delete data if empty
      Object.keys(filterBodyToMap).forEach((key) => {
        if (isEmpty(filterBodyToMap[key])) {
          delete filterBodyToMap[key];
        }
      });
      filterBodyToMap.status = status ? status === "active" : null;
      const filterBodyMapped = ObjectUtils.mapFieldsLangsCTS(
        filterBodyToMap,
        VEHICLE_FILTER_KEY as any
      );
      body = { ...body, ...filterBodyMapped };
    }

    return getVehicleList(body, paging);
  };

  const columns: IColumnsProps = [
    {
      title: t("image"),
      dataIndex: "media",
      render: (media) => (
        <img
          src={media?.[0]?.attachment}
          className="image-square-small object-fit-contain"
        />
      ),
    },
    {
      title: t("registrationNo"),
      dataIndex: "registration",
      render: (registration, item) => (
        <Link to={generatePath(Path.VEHICLE_DETAIL, { vehicleId: item.id })}>
          {registration}
        </Link>
      ),
    },
    {
      title: t("type"),
      dataIndex: "vehicle_type",
    },
    {
      title: t("ownerShip"),
      dataIndex: "vehicle_ownership",
    },
    {
      title: t("company"),
      dataIndex: "company",
      render: (company) => company?.name,
    },
    {
      title: t("driver"),
      dataIndex: "driver",
      render: (driver) => <UserAvatarName user={driver} />,
    },
    {
      title: t("brand"),
      dataIndex: "brand",
    },
    {
      title: t("model"),
      dataIndex: "model",
    },
    {
      title: t("year"),
      dataIndex: "year",
    },

    {
      title: t("status"),
      dataIndex: "status",
      render: (status) => {
        const statusFound = find(
          VEHICLE_STATUS_LIST,
          (item) => item.id === status
        );
        return (
          <ViewLabelStatus
            color={statusFound?.color}
            status={status}
            content={t(statusFound?.label as any)}
            getLabel={(item) => t(item.label)}
          />
        );
      },
    },

    {
      title: t("createdAt"),
      dataIndex: "created",
      render: TimeUtils.toDateTime,
    },
    {
      title: t("createdBy"),
      dataIndex: "by",
      render: (driver) => <UserAvatarName user={driver} />,
    },
  ];

  const onChangeSearch = debounce((text) => {
    searchRef.current = text;
    tableRef.current.refresh();
  }, 300);

  const isFiltered = useMemo(
    () => Object.keys(filterBody).length > 0,
    [filterBody]
  );

  return (
    <AppContext.Provider value={{ tabSelected }}>
      <div className="bg-white">
        <HeaderTable
          label={t("vehicle")}
          onChangeText={(event: any) => onChangeSearch(event.target.value)}
          onClickFilter={() => setOpenFilter(true)}
          isFiltered={isFiltered}
          onClickNew={() => navigate(Path.VEHICLE_CREATE)}
          className="app-layout__page-header"
        />

        <AwesomeTableComponent
          source={source}
          transformer={(res) => res?.data?.data?.vehicle ?? []}
          isScroll={false}
          columns={columns}
          ref={(ref) => (tableRef.current = ref)}
        />
        {/* {openFilter && (
        <DeliveryTableFilterDrawer
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          defaultValue={filter}
          onFilter={setFilter}
        />
      )} */}
      </div>
    </AppContext.Provider>
  );
};

export default VehicleTable;
