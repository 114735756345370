import { Path } from "@components/layout/Path";
import { getProductionCostList } from "@network/api/expense";
import {
  AwesomeTableComponent,
  HeaderTable,
  Icon,
  TimeUtils,
  ViewLabelStatus,
} from "d-react-components";
import { debounce } from "lodash";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import CostingExpenseFilterModal from "./CostingExpenseFilterModal";
import ProductName from "@components/product/components/ProductName";
import CurrencyFormat from "react-currency-format";
import HistoryDrawer from "./HistoryDrawer";
import ActionMenu, { ActionButton } from "@common/dropdown/ActionButton";
import ImportDrawer from "./ImportDrawer";
import ExportDrawer from "./ExportDrawer";

const CostingExpenseTable = () => {
  const [openFilter, setOpenFilter] = useState(false);
  const [openHistory, setOpenHistory] = useState({
    open: false,
    product: undefined,
    channel: undefined,
  });
  const [showImportDrawer, setShowImportDrawer] = useState(false);
  const [showExportDrawer, setShowExportDrawer] = useState(false);
  const [filterBody = {}, setFilterBody] = useQueryParam("filter", JsonParam);
  const tableRef = useRef<any>();
  const searchRef = useRef();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isFiltered = useMemo(
    () => Object.keys(filterBody).length > 0,
    [filterBody]
  );

  const columns = useMemo(
    () => [
      {
        title: t("product"),
        dataIndex: "title",
        render: (product: any, item: any) => (
          <ProductName item={{ product: item }} />
        ),
      },
      {
        title: t("brand"),
        dataIndex: "brand",
        render: (brand: any) => brand?.name,
      },
      {
        title: t("category"),
        dataIndex: "category",
        render: (category: any) => category?.name,
      },
      {
        title: t("productionCost"),
        dataIndex: "production_cost",
        width: 100,
        render: (production_cost: any) => (
          <CurrencyFormat
            value={production_cost}
            displayType="text"
            thousandSeparator={true}
          />
        ),
      },
      {
        title: t("saleExpense"),
        dataIndex: "sale_expense",
        width: 100,
        render: (sale_expense: any) => `${sale_expense}%`,
      },
      {
        title: t("adminExpense"),
        dataIndex: "admin_expense",
        width: 100,
        render: (admin_expense: any) => `${admin_expense}%`,
      },
      {
        title: t("history"),
        dataIndex: "",
        render: (data: any, item: any) => (
          <Icon
            name="history"
            className="cursor-pointer"
            onClick={() =>
              setOpenHistory({
                open: true,
                product: item?.id,
                channel: filterBody?.channel?.id,
              })
            }
          />
        ),
        width: 80,
      },
    ],
    [t, filterBody?.channel?.id]
  );

  const actions = useMemo(() => {
    return [
      {
        id: "import",
        label: t("import"),
      },
      {
        id: "export",
        label: t("export"),
      },
    ];
  }, [t]);

  const source = (paging: any) => {
    const body = {
      search: searchRef.current,
      channel_id: filterBody.channel?.id,
      category: filterBody.category?.map((cat: any) => cat.id) ?? [],
      brand: filterBody.brand?.map((brand: any) => brand.id) ?? [],
    };
    return getProductionCostList(body, paging);
  };

  const transformer = (res: any) => {
    return res?.data?.data?.product ?? [];
  };

  const onChangeSearch = debounce((text) => {
    searchRef.current = text;
    tableRef.current.refresh();
  }, 1000);

  const onFilter = (values: any) => {
    setFilterBody(values);
    tableRef.current.refresh();
  };

  const onHandleAction = (item: any) => {
    if (item.id === "import") {
      setShowImportDrawer(true);
    }
    if (item.id === "export") {
      setShowExportDrawer(true);
    }
  };

  return (
    <div className="p-3">
      <HeaderTable
        label={t("costingExpense")}
        onChangeText={(event: any) => onChangeSearch(event.target.value)}
        onClickFilter={() => setOpenFilter(true)}
        isFiltered={isFiltered}
        customImportButton={() => (
          <ActionMenu dataSource={actions} onClick={onHandleAction} />
        )}
      />
      <AwesomeTableComponent
        ref={(ref) => {
          tableRef.current = ref;
        }}
        columns={columns}
        source={source}
        transformer={transformer}
        className="mt-3"
      />
      {openFilter && (
        <CostingExpenseFilterModal
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          onFilter={onFilter}
          defaultValue={filterBody}
        />
      )}
      {openHistory.open && (
        <HistoryDrawer
          open={openHistory.open}
          onClose={() =>
            setOpenHistory({
              open: false,
              product: undefined,
              channel: undefined,
            })
          }
          product={openHistory.product}
          channel={openHistory.channel}
        />
      )}
      {showImportDrawer && (
        <ImportDrawer
          open={showImportDrawer}
          onClose={() => setShowImportDrawer(false)}
          onSuccessImport={() => tableRef.current.refresh()}
        />
      )}
      {showExportDrawer && (
        <ExportDrawer
          open={showExportDrawer}
          onClose={() => setShowExportDrawer(false)}
        />
      )}
    </div>
  );
};

export default CostingExpenseTable;
