import LabelCheckboxSelect from "@common/LabelCheckboxSelect";
import InputSelectForm from "@common/input/InputSelectForm";
import { YES_NO_OPTIONS } from "@constants/common";
import { DELIVERY_STATUS_LIST, ORDER_STATUS_ARRAY } from "@constants/order";
import { STORE_TYPE_LIST } from "@constants/store";
import { POSITION_LABELS } from "@constants/user";
import AppContext from "@helpers/context";
import UserAPI from "@network/api/user";
import { CheckboxGroup, InputText } from "d-react-components";
import { filter, map } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const UserCreateSetting = () => {
  const { userForm } = useContext(AppContext);
  // const [promotionStatusList, setPromotionStatusList] = useState([]);
  const { t } = useTranslation();
  const formValues = userForm?.values;

  const classNameInput = "mt-3";

  // useEffect(() => {
  //   loadPromotionStatus();
  // }, []);

  // const loadPromotionStatus = () => {
  //   UserAPI.promotionStatusList().then((res) => {
  //     const promotionStatusList = res?.data?.data?.status ?? [];
  //     setPromotionStatusList(promotionStatusList);
  //   });
  // };

  return (
    <div className="card-container mt-3 p-4">
      <label>{t("settings")}</label>
      {/* <InputSelectForm
        keyData="promotionStatus"
        label={t("promotionStatus")}
        form={userForm}
        className={classNameInput}
        dataSource={promotionStatusList}
        getLabel={(item: any) => item.name}
        multiple
      /> */}

      <InputSelectForm
        keyData="storeType"
        label={t("storeType")}
        form={userForm}
        className={classNameInput}
        dataSource={filter(STORE_TYPE_LIST, (item) => item.id !== "all")}
      />
      <InputSelectForm
        keyData="positionLabel"
        label={t("positionLabel")}
        form={userForm}
        className={classNameInput}
        dataSource={POSITION_LABELS}
      />

      <InputSelectForm
        keyData="blockUser"
        label={t("blockUser")}
        form={userForm}
        className={classNameInput}
        dataSource={YES_NO_OPTIONS}
      />

      <InputSelectForm
        keyData="hasCallCenter"
        label={t("hasCallCenter")}
        form={userForm}
        className={classNameInput}
        dataSource={YES_NO_OPTIONS}
      />

      <InputText
        label={t("callCenterId")}
        className={classNameInput}
        value={userForm?.values?.callCenterId}
        onChange={(event) => {
          const value = parseInt(event.target.value) ?? "";
          userForm.setFieldValue("callCenterId", value);
        }}
      />
      <InputText
        label={t("callCenterEmail")}
        className={classNameInput}
        value={userForm?.values?.callCenterEmail}
        onChange={(event) => {
          userForm.setFieldValue("callCenterEmail", event.target.value);
        }}
      />

      <LabelCheckboxSelect
        label={t("orderStatusManagement")}
        onDeselectAll={() => userForm.setFieldValue("orderStatus", [])}
        onSelectAll={() =>
          userForm.setFieldValue(
            "orderStatus",
            map(ORDER_STATUS_ARRAY, (item) => item.id)
          )
        }
      />
      <CheckboxGroup
        className={classNameInput}
        dataSource={ORDER_STATUS_ARRAY}
        value={userForm.values.orderStatus}
        onChange={(value) => userForm.setFieldValue("orderStatus", value)}
        getLabel={(item) => t(item.label)}
      />
      <LabelCheckboxSelect
        label={t("deliveryStatusManagement")}
        onDeselectAll={() => userForm.setFieldValue("deliveryStatus", [])}
        onSelectAll={() =>
          userForm.setFieldValue(
            "deliveryStatus",
            map(DELIVERY_STATUS_LIST, (item) => item.key)
          )
        }
      />
      <CheckboxGroup
        className={classNameInput}
        dataSource={DELIVERY_STATUS_LIST}
        value={userForm.values.deliveryStatus}
        onChange={(value) => userForm.setFieldValue("deliveryStatus", value)}
        getValue={(item) => item.key}
        multiple
        getLabel={(item) => t(item.label)}
      />
    </div>
  );
};

export default UserCreateSetting;
