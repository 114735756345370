/* eslint-disable react-hooks/exhaustive-deps */
import UserAvatarName from "@components/shared/UserAvatarName";
import { ORDER_STATUS_ARRAY } from "@constants/order";
import AppContext from "@helpers/context";
import DiscountCodeAPI from "@network/api/discount-code";
import {
  AwesomeTableComponent,
  HeaderTable,
  StringUtils,
  TimeUtils,
  ViewLabelStatus,
} from "d-react-components";
import i18next from "i18next";
import { debounce, isEmpty, map } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import DiscountCodeUsageFilterDrawer from "./DiscountCodeUsageFilterDrawer";

const DiscountCodeUsageReport = () => {
  const tableRef = useRef<any>();
  const searchRef = useRef<string>();
  const { t } = useTranslation();
  const [openFilter, setOpenFilter] = useState(false);

  const [filterBody = {}, setFilterBody] = useQueryParam(
    "filterBody",
    JsonParam
  );
  const isFiltered = useMemo(
    () => Object.keys(filterBody).length > 0,
    [filterBody]
  );

  const columns: any[] = [
    {
      title: t("ref"),
      dataIndex: "ref",
    },
    {
      title: t("code"),
      dataIndex: "discount_id",
      render: (discount_id: any) => discount_id ?? "N/A",
    },
    {
      title: t("channel"),
      dataIndex: "order",
      render: (order: any) => order?.channel?.name,
    },
    {
      title: t("warehouse"),
      dataIndex: "order",
      render: (order: any) => StringUtils.getFullNameStore(order?.store),
    },
    {
      title: t("discountValue"),
      dataIndex: "discount_value",
      render: (discount_value: any, item: any) => {
        let content = "N/A";
        if (item?.discount_type === "discount_percentage") {
          content = `${StringUtils.moneyThaiFormat(discount_value)} (%${
            item?.discount
          })`;
        }
        if (item?.discount_type === "discount_fix_amount") {
          content = `${StringUtils.moneyThaiFormat(discount_value)}`;
        }
        return content;
      },
    },
    {
      title: t("orderid"),
      dataIndex: "order",
      render: (order: any) => (
        <Link to={`/order/${order?.id}`}>{order?.orderid}</Link>
      ),
    },
    {
      title: t("orderValue"),
      dataIndex: "order",
      render: (order: any) => StringUtils.moneyThaiFormat(order?.total),
    },
    {
      title: t("orderStatus"),
      dataIndex: "order",
      render: (order: any) => {
        return (
          <ViewLabelStatus
            status={order?.status}
            listStatus={ORDER_STATUS_ARRAY}
            getLabel={(item) => t(item.label)}
          />
        );
      },
    },
    {
      title: t("usedBy"),
      dataIndex: "order",
      render: (order: any) => <UserAvatarName user={order?.by} />,
    },
    {
      title: t("useDate"),
      dataIndex: "use",
      render: TimeUtils.toDateTime,
    },
  ];

  const source = (paging: any) => {
    const body: any = {
      search: searchRef.current,
    };

    for (const key in filterBody) {
      if (!isEmpty(filterBody[key])) {
        body[key] = map(filterBody[key], (item) => item?.id ?? item);
      }
    }
    return DiscountCodeAPI.codeUsageReport(body, paging);
  };

  const onChangeSearch = debounce((text) => {
    searchRef.current = text;
    tableRef.current.refresh();
  }, 300);

  useEffect(() => {
    i18next.loadLanguages(["en", "th"]);
  }, []);

  return (
    <AppContext.Provider
      value={{
        onReloadData: () => {
          tableRef.current?.refresh();
        },
      }}
    >
      <div className="bg-white">
        <HeaderTable
          label={t("managerCodeUsageReport")}
          onChangeText={(event: any) => onChangeSearch(event.target.value)}
          onClickFilter={() => setOpenFilter(true)}
          isFiltered={isFiltered}
          className="app-layout__page-header"
        />
        <AwesomeTableComponent
          source={source}
          transformer={(res) => {
            const codes = res?.data?.data?.codes ?? [];
            return codes;
          }}
          columns={columns}
          ref={(ref) => (tableRef.current = ref)}
        />
        {openFilter && (
          <DiscountCodeUsageFilterDrawer
            open={openFilter}
            onClose={() => setOpenFilter(false)}
            onFilter={(values: any) => {
              setFilterBody(values);
              tableRef?.current?.refresh();
            }}
            defaultValue={filterBody}
          />
        )}
      </div>
    </AppContext.Provider>
  );
};

export default DiscountCodeUsageReport;
