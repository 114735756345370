import { isEmpty, map } from "lodash";
import { IChannel } from "./channel";

export interface ICartRuleAction {
  id: string;
  specific: string;
}

export interface ICartRuleConfiguration {
  conditions: string;
  coupon: string;
  id: string;
  conjunctional: boolean;
  created: number;
  period_from: number;
  period_to: number;
  priority: number;
  usage_customer: number;
  usage_limit: number;
  channel: IChannel;
  code: any[];
}

export interface ICartRule {
  id: string;
  title: string;
  ruleid: string;
  status: string;
  description: string;
  created: number;
  rule: ICartRuleConfiguration;
  actions: ICartRuleAction;
  updated: any;
}

export const mapObjectServerToClient = (cartRule: any) => {
  return {
    ...cartRule,
    ...(cartRule?.rule ?? {}),
    code: map(cartRule?.rule?.code, (item) => item.code),
    selectedChannel: cartRule?.rule?.channel,
    condition_rule: cartRule?.rule?.conditions,
    conditions: cartRule?.conditions,
    configQuantityType: isEmpty(cartRule?.quantity)
      ? "multiple"
      : cartRule?.quantity,
  };
};
