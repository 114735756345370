import ManageDeliveryDrawer from "@components/order/detail/modal/ManageDeliveryDrawer";
import ImageIcon from "@common/Icon";
import {
  DELIVERY_COURIER,
  DELIVERY_STATUS_LIST,
  ORDER_STATUS,
  SHIPPOP_TYPES,
} from "@constants/order";
import AppContext from "@helpers/context";
import { deleteDelivery, logPrint, printDelivery } from "@network/api/delivery";
import { Skeleton, Spin } from "antd";
import {
  Notifications,
  Button,
  Icon,
  TimeUtils,
  ObjectUtils,
  DialogManager,
  Badge,
  ViewLabelStatus,
} from "d-react-components";
import { isEmpty, orderBy, reduce } from "lodash";
import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ShippingInfoView from "../shipping/ShippingInfoView";
import CancelDeliveryModal from "./CancelDeliveryModal";
import UpdateDeliveryStatusModal from "./UpdateDeliveryStatusModal";
import styled from "@emotion/styled";
import { OrderType } from "@constants/common";
import { DELIVERY_STATUS } from "@constants/delivery";

const SHIPPOP_TYPE_ARRAY = ObjectUtils.mapObjectToArray(SHIPPOP_TYPES);

const DeliveryTrackingItem = ({
  deliveryTracking,
  onReloadOrderData,
  order,
  className,
  orderType,
  ...props
}: any) => {
  const [openUpdateStatusModal, setOpenUpdateStatusModal] = useState(false);
  const [progressPrint, setProgressPrint] = useState(false);
  const [openEditDeliveryModal, setOpenEditDeliveryModal] = useState(false);
  const [openListItemModal, setOpenListItemModal] = useState(false);
  const [openCancelDeliveryModal, setOpenCancelDeliveryModal] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const { editable, isPublic } = useContext(AppContext);
  const { t } = useTranslation();

  const isDislayEdit =
    editable &&
    !isPublic &&
    deliveryTracking?.status !== DELIVERY_STATUS.COMPLETED &&
    deliveryTracking.status !== DELIVERY_STATUS.CANCELLED;

  const onConfirmDelete = () => {
    DialogManager.showConfirm(
      t("deleteTrackingNumber"),
      t("deleteTrackingNumberDes"),
      () => onClickDelete()
    );
  };

  const onClickDelete = () => {
    if (!deliveryTracking?.id) return;
    setLoadingDelete(true);
    deleteDelivery(deliveryTracking?.id).then((res) => {
      setLoadingDelete(false);
      onReloadOrderData();
      Notifications.showSuccess(t("notification:deleteDeliverySuccess"));
    });
  };

  const isShowCancel = () => {
    return (
      !isPublic &&
      deliveryTracking.status !== DELIVERY_STATUS.COMPLETED &&
      deliveryTracking.status !== DELIVERY_STATUS.CANCELLED
    );
  };

  const onClickCopy = () => {
    navigator.clipboard.writeText(deliveryTracking.code);
    Notifications.showInfo(t("notification:copySuccess"));
  };

  const isDisplayTrackIcon = () => {
    return (
      editable &&
      deliveryTracking?.delivery?.courier === DELIVERY_COURIER.SHIPPOP
    );
  };

  const isDisplayUpdateStatus = () => {
    return (
      editable &&
      !isPublic &&
      !isDisplayTrackIcon() &&
      deliveryTracking.status !== DELIVERY_STATUS.CANCELLED &&
      deliveryTracking.status !== DELIVERY_STATUS.COMPLETED
    );
  };

  const onClickTrackPackage = () => {
    const trackingUrl = deliveryTracking?.delivery?.tracking;
    if (!trackingUrl || !deliveryTracking.code) {
      Notifications.showError(t("notification:hasNoTrackingInfo"));
      return;
    }
    const urlReplacedCode = trackingUrl.replace("#code", deliveryTracking.code);

    window.open(urlReplacedCode, "_blank");
  };

  const onClickPrintShippopHandle = () => {
    setProgressPrint(true);
    printDelivery(order?.id).then((respone) => {
      const listDelivery = respone?.data?.data?.print ?? [];
      const printResult = listDelivery.find(
        (item: any) => item.code === deliveryTracking.code
      );
      if (isEmpty(printResult) || isEmpty(printResult?.download)) {
        setProgressPrint(false);
        Notifications.showError(t("notification:unableToPrintThisShippop"));
      } else {
        setProgressPrint(false);
        window.open(printResult?.download);
      }
    });
    logPrint(order?.id, "delivery");
  };

  const codeDisplay = () => {
    if (isDisplayTrackIcon()) {
      return deliveryTracking?.courier_tracking_code ?? null;
    }
    return deliveryTracking?.code ?? null;
  };

  const getNumberOfItemDelivery = () => {
    let total = 0;
    const listItems =
      deliveryTracking?.items || deliveryTracking?.products || [];
    listItems.forEach((item: any) => {
      total += item.stock || item.quantity || 0;
    });
    return total;
  };

  const getDisplayCourier = (code: any) => {
    let label = "N/A";
    const labelCourier =
      SHIPPOP_TYPE_ARRAY.find((item) => item.id === code)?.label ?? null;
    if (labelCourier) {
      label = t(labelCourier);
    }
    return label;
  };

  const deliveryStatus = DELIVERY_STATUS_LIST.find(
    (item: any) => item.key === deliveryTracking.status
  );

  return (
    <div
      className={`deliveryTracking relative text-sm ${className}`}
      key={props.key}
    >
      <div className="absolute flex items-center top-0 right-0">
        {isDislayEdit && (
          <div
            className="subTitle2 buttonEdit text-primary cursor-pointer"
            onClick={() => setOpenEditDeliveryModal(true)}
          >
            <ImageIcon
              className="w-[15px] cursor-pointer"
              src="/images/icons/edit.svg"
            />
          </div>
        )}
        {isShowCancel() && (
          <div
            className="subTitle2 buttonEdit text-red-500 ml-2 cursor-pointer h-[18px]"
            onClick={() => setOpenCancelDeliveryModal(true)}
          >
            <Icon name="highlight_off" size="large" className="text-red-500" />
          </div>
        )}
      </div>

      {deliveryTracking.ref && (
        <div className="flex items-center my-2">
          <div>
            {t("ref")}:
            <span className="text-primary ml-1">{deliveryTracking.ref}</span>
          </div>
        </div>
      )}
      {deliveryTracking?.by && (
        <div className="mb-2 text-gray-500">
          {`${t("createdBy")} ${deliveryTracking.by.fullname} ${t(
            "at"
          )} ${TimeUtils.convertMiliToDateTime(deliveryTracking.created)}`}
        </div>
      )}
      {deliveryTracking?.courier_code && (
        <div className="subTitle1 my-2">
          {deliveryTracking?.delivery?.name}:
          {deliveryTracking?.courier_code && (
            <span className="ml-1">{`(${getDisplayCourier(
              deliveryTracking?.courier_code
            )})`}</span>
          )}
        </div>
      )}
      {deliveryTracking.code && (
        <div className="flex items-center my-2">
          {t("trackingNumber")}: <span className="ml-1">{codeDisplay()}</span>
          {codeDisplay() && (
            <Icon
              className="cursor-pointer ml-1"
              onClick={() => onClickCopy()}
              name="file_copy_outlined"
            />
          )}
        </div>
      )}
      {deliveryTracking.status && (
        <ViewLabelStatus
          content={t(deliveryStatus?.content ?? "")}
          color={deliveryStatus?.color}
          className="mb-2"
        />
      )}
      {deliveryTracking.warehouse && (
        <div className="text-gray-500 mb-2">
          {`${t("warehouse")} ${deliveryTracking?.warehouse?.name} (${
            deliveryTracking?.warehouse?.code
          }) `}
        </div>
      )}
      {deliveryTracking.estimated && (
        <div className="text-gray-500 mb-2">
          {t("extimatedOn") +
            TimeUtils.convertMiliToDate(deliveryTracking.estimated)}
        </div>
      )}
      {deliveryTracking.arrived && (
        <div className="text-gray-500 mb-2">
          {`${t("arrivedOn")} ${TimeUtils.convertMiliToDateTime(
            deliveryTracking.arrived
          )}`}
        </div>
      )}
      {(deliveryTracking.comment || deliveryTracking.remark) && (
        <div className="bg-blue-100 px-3 py-2 mt-3 mb-3 arrow-up border-b-blue-100 relative">
          {`${t("remark")}: "${
            deliveryTracking.comment || deliveryTracking.remark
          }"`}
        </div>
      )}
      <ShippingInfoView
        shipping={deliveryTracking?.shipping}
        containeClassName="p-3 bg-gray-100"
      />

      <div className="flex my-3">
        <div className="flex">
          <div className="relative mr-2">
            <span className="z-10 flex items-center justify-center px-[3px] py-0 text-[10px] bg-red-500 text-white rounded-full min-w-[20px] min-h-[20px] absolute -top-[5px] -right-[5px]">
              {getNumberOfItemDelivery()}
            </span>
            <Button
              onClick={() => setOpenListItemModal(true)}
              id="buttonTrack"
              variant="outline"
              color="primary"
              className="inline-block relative px-2"
              style={{ minWidth: 0, width: "40px" }}
            >
              <ImageIcon src="/images/icons/truck.svg" />
            </Button>
          </div>
          {isDisplayTrackIcon() && (
            <Button
              onClick={() => onClickTrackPackage()}
              id="buttonTrack"
              variant="outline"
              color="primary"
              className="mr-2 px-2"
              style={{ minWidth: 0, width: "40px" }}
            >
              <ImageIcon src="/images/icons/track-package.svg" />
            </Button>
          )}
          {isDisplayTrackIcon() &&
            deliveryTracking.status !== "completed" &&
            order.status !== ORDER_STATUS.completed.value && (
              <PrintButton
                onClick={() => onClickPrintShippopHandle()}
                id="buttonTrack"
                variant="standard"
                className="mr-1 px-2"
                style={{ minWidth: 0, width: "40px" }}
              >
                {progressPrint ? (
                  <Spin size="default" />
                ) : (
                  <ImageIcon src="/images/icons/printer.svg" />
                )}
              </PrintButton>
            )}

          <div className="col-span-1">
            {isDisplayUpdateStatus() && (
              <Button
                onClick={() => setOpenUpdateStatusModal(true)}
                id="buttonTrack"
                className="text-white w-100"
              >
                <span className="subTitle2 text-xs">
                  {t("updateStatus").toLocaleUpperCase()}
                </span>
              </Button>
            )}
          </div>
        </div>

        {/* <NavLogButton deliveryTracking={deliveryTracking} /> */}
      </div>

      {openUpdateStatusModal && (
        <UpdateDeliveryStatusModal
          openModal={openUpdateStatusModal}
          onClose={() => setOpenUpdateStatusModal(false)}
          deliveryTracking={deliveryTracking}
          orderType={orderType}
        />
      )}
      {openEditDeliveryModal && (
        <ManageDeliveryDrawer
          delivery={deliveryTracking}
          openModal={openEditDeliveryModal}
          onClose={() => setOpenEditDeliveryModal(false)}
          orderType={orderType}
        />
      )}
      {openListItemModal && (
        <ManageDeliveryDrawer
          delivery={deliveryTracking}
          openModal={openListItemModal}
          onClose={() => setOpenListItemModal(false)}
          readOnly
          orderType={orderType}
        />
      )}
      {openCancelDeliveryModal && (
        <CancelDeliveryModal
          deliveryTracking={deliveryTracking}
          openModal={openCancelDeliveryModal}
          onClose={() => setOpenCancelDeliveryModal(false)}
          orderType={orderType}
        />
      )}
    </div>
  );
};

const DeliveryTracking = ({
  orderType = OrderType.NORMAL,
}: {
  orderType?: OrderType;
}) => {
  const {
    delivery: deliveryList,
    onReloadOrderData,
    order,
    products,
    pickup,
    remainingItems,
    isPublic,
  } = useContext(AppContext);
  const { t } = useTranslation();
  const [showAllDeliveries, setShowAllDeliveries] = useState(false);

  const delivery = useMemo(() => {
    return orderBy(deliveryList, "created", "desc");
  }, [deliveryList]);

  const deliveryItemToDisplay = useMemo(() => {
    if (showAllDeliveries) {
      return delivery;
    }
    return [delivery?.[0] ?? {}];
  }, [showAllDeliveries, delivery]);

  if (!delivery) {
    return (
      <div className="cardContainer deliveryContainer">
        <Skeleton active />
      </div>
    );
  }
  if (!delivery || delivery.length === 0) {
    return <div />;
  }

  return (
    <div className="bg-white p-3 pt-4 deliveryContainer mb-3 shadow-sm">
      <label className="block mb-3 text-sm">{`${t("deliveryTracking")} (${t(
        "remaining"
      )} ${remainingItems})`}</label>
      {deliveryItemToDisplay?.map((deliveryItem: any, index: number) => (
        <DeliveryTrackingItem
          deliveryTracking={deliveryItem}
          onReloadOrderData={onReloadOrderData}
          order={order}
          orderType={orderType}
          key={index}
          className={
            delivery?.length === 1 ? "" : "border-b border-gray-200 pt-3"
          }
        />
      ))}
      {delivery.length > 1 && (
        <div className="flex justify-end border-t pt-3">
          <div className="relative">
            <span className="z-10 flex items-center justify-center px-[3px] py-0 text-[10px] bg-red-500 text-white rounded-full min-w-[20px] min-h-[20px] absolute -top-[5px] -right-[5px]">
              {delivery.length}
            </span>
            <Button
              onClick={() => setShowAllDeliveries(!showAllDeliveries)}
              id="buttonTrack"
              color="primary"
              variant="outline"
              className="text-xs px-2"
              style={{ minWidth: 0, width: "40px" }}
            >
              <span className="text-xs text-white whitespace-nowrap text-ellipsis overflow-hidden inline-block flex items-center">
                <ImageIcon src="/images/icons/truck.svg" />
              </span>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const PrintButton = styled(Button)`
  .ant-spin-dot-item {
    background-color: white;
  }
`;

export default DeliveryTracking;
