export const INVENTORY_STATUS_LIST = [
  { id: "out-of-stock", label: "Out Of Stock", value: [null, 0] },
  { id: "low-stock", label: "Low Stock (< 10pcs)", value: [0, 10] },
  { id: "in-stock", label: "In stock", value: [1, null] },
];

export const UPDATE_INV_METHOD = {
  ADD: "add",
  SET: "set",
};
