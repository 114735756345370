import { getOrderHoldStock } from "@network/api/product";
import { AwesomeTableComponent, Drawer, TimeUtils } from "d-react-components";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const HoldStockProductModal = ({ open, onClose, productId, orderId }: any) => {
  const [holdList, setHoldList] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    getOrderHoldStock({ product_id: productId, order_id: orderId }).then(
      (res) => {
        const holdList = res?.data?.data?.hold ?? [];
        setHoldList(holdList);
      }
    );
  }, []);

  const columns = useMemo(
    () => [
      {
        title: t("storeWarehouse"),
        dataIndex: "warehouse",
        render: (store: any) => store?.name,
      },
      {
        title: t("storeCode"),
        dataIndex: "warehouse",
        render: (store: any) => store?.code,
      },
      {
        title: t("onHoldQty"),
        dataIndex: "hold",
        render: (hold: any) => hold,
        width: 100,
      },
      {
        title: t("createAt"),
        dataIndex: "created",
        render: (created: any) => TimeUtils.convertMiliToDateTime(created),
      },
      {
        title: t("ref"),
        dataIndex: "ref",
        render: (ref) => ref,
        width: 150,
      },
    ],
    [t]
  );

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("onHoldAllocation")}
      size="auto"
      width={800}
    >
      <AwesomeTableComponent columns={columns} dataSource={holdList} />
    </Drawer>
  );
};

export default HoldStockProductModal;
