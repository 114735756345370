import {
  AwesomeTableComponent,
  Button,
  Notifications,
  Progress,
  TimeUtils,
} from "d-react-components";
import { map } from "lodash";
import React, { useContext, useRef, useState } from "react";
import UserDetailOrderManaUpdateModal from "./UserDetailOrderManaUpdateModal";
import AppContext from "@helpers/context";
import { useTranslation } from "react-i18next";
import UserAPI from "@network/api/user";
import UserAvatarName from "@components/shared/UserAvatarName";

const UserDetailOrderManagement = () => {
  const { user } = useContext(AppContext);
  const { t } = useTranslation();
  const tableRef = useRef<any>();
  const [openAdd, setOpenAdd] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);

  const columns = [
    {
      title: t("NO"),
      dataIndex: "id",
      render: (id: any, item: any, index: any) => index + 1,
    },
    {
      title: t("name"),
      dataIndex: "name",
      render: (name: any, user: any) => <UserAvatarName user={user} />,
    },
    {
      title: t("employeeId"),
      dataIndex: "employee_id",
    },
    {
      title: t("lastLogin"),
      dataIndex: "last_login",
      render: TimeUtils.convertMiliToDateTime,
    },
  ];

  const source = (paging: any) => {
    const body = { id: user?.id, action: "in" };
    return UserAPI.orderManagerList(body, paging);
  };

  const transformer = (res: any) => {
    return res?.data?.data?.admin ?? [];
  };

  const onAddOrder = (users: any) => {
    const body = {
      id: user?.id,
      action: "add",
      admin: map(users, (item) => item.id),
    };
    Progress.show(
      { method: UserAPI.orderManagerUpdate, params: [body] },
      () => {
        Notifications.showSuccess(t("updateUserOrderSuccess"));
        tableRef.current.refresh();
        setOpenAdd(false);
      }
    );
  };

  const onRemoveOrder = (users: any) => {
    const body = {
      id: user?.id,
      action: "remove",
      admin: map(users, (item) => item.id),
    };
    Progress.show(
      { method: UserAPI.orderManagerUpdate, params: [body] },
      () => {
        Notifications.showSuccess(t("updateUserOrderSuccess"));
        tableRef.current.refresh();
        setOpenRemove(false);
      }
    );
  };

  return (
    <div className="border mt-3 p-4">
      <div className="justify-content-between d-flex align-items-center">
        <h5>{t("order")}</h5>
        <div className="d-flex">
          <Button className="ml-3" onClick={() => setOpenRemove(true)}>
            {t("remove")}
          </Button>
          <Button className="ml-3" onClick={() => setOpenAdd(true)}>
            {t("add")}
          </Button>
        </div>
      </div>
      <AwesomeTableComponent
        ref={(ref) => {
          tableRef.current = ref;
        }}
        columns={columns}
        source={source}
        transformer={transformer}
        className="mt-3"
      />
      {openAdd && (
        <UserDetailOrderManaUpdateModal
          open={openAdd}
          onClose={() => setOpenAdd(false)}
          userId={user?.id}
          onSave={onAddOrder}
          type="not_in"
          title={t("addUsers")}
        />
      )}
      {openRemove && (
        <UserDetailOrderManaUpdateModal
          open={openRemove}
          onClose={() => setOpenRemove(false)}
          userId={user?.id}
          onSave={onRemoveOrder}
          type="in"
          title={t("removeUser")}
        />
      )}
    </div>
  );
};

export default UserDetailOrderManagement;
