import classNames from "classnames";
import React from "react";

interface IHeaderDetail {
  title: any;
  rightSide?: any;
  titleRightSide?: any;
}

const HeaderDetail = ({ title, rightSide, titleRightSide }: IHeaderDetail) => {
  return (
    <div className="bg-white p-3 d-flex justify-content-between align-items-center border-bottom">
      <div className="flex-row">
        <h4 className={classNames("text-[14px] text-primary")}>{title}</h4>
        {!!titleRightSide && titleRightSide}
      </div>
      {!!rightSide && rightSide}
    </div>
  );
};

export default HeaderDetail;
