import Icon from "@common/Icon";
import AppContext from "@helpers/context";
import { ICartRule } from "@interfaces/cart-rule";
import { IRowsKey, TimeUtils, ViewRowInterchange } from "d-react-components";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import CartRuleUsageStatus from "../component/CartRuleUsageStatus";
import { CART_RULE_ACTIONS, CART_RULE_TYPES } from "@constants/cart-rule";
import { find } from "lodash";

const CartRuleDetailInfo = () => {
  const { cartRule } = useContext(AppContext);
  const { t } = useTranslation();

  const { title, status } = cartRule;

  const DETAILS_KEY = useMemo<IRowsKey<ICartRule>[]>(
    () => [
      {
        id: "ruleid",
        label: t("id"),
      },
      {
        id: "rule",
        label: t("startAt"),
        renderContent: ({ data, item }) =>
          TimeUtils.toDateTime(data.period_from),
      },
      {
        id: "rule",
        label: t("endAt"),
        renderContent: ({ data, item }) => TimeUtils.toDateTime(data.period_to),
      },
      {
        id: "actions",
        label: t("action"),
        renderContent: ({ data, item }) => {
          const cartRuleAction = find(
            CART_RULE_ACTIONS,
            (item) => item.id === data?.specific
          );
          return t(cartRuleAction?.label ?? "");
        },
      },
      {
        id: "rule",
        label: t("type"),
        renderContent: ({ data, item }) => {
          const cartRuleAction = find(
            CART_RULE_TYPES,
            (item) => item.id === data?.coupon
          );
          return t(cartRuleAction?.label ?? "");
        },
      },
      {
        id: "created",
        label: t("createdAt"),
        renderContent: ({ data, item }) => TimeUtils.toDateTime(data),
      },
      {
        id: "updated",
        label: t("updatedAt"),
        renderContent: ({ data, item }) => TimeUtils.toDateTime(data),
      },
    ],
    [t]
  );

  return (
    <div>
      <div className="border flex-center flex-column pt-5 w-100 bg-white p-4">
        <Icon
          className="w-[100px] cursor-pointer"
          src="/images/icons/contract.svg"
        />

        <h4 className="mt-3 text-center">{title}</h4>
        {/* <ViewLabelStatus
          status={status}
          listStatus={CART_RULE_STATUS_LIST}
          getLabel={(item) => toUpper(item.label)}
        /> */}
        <CartRuleUsageStatus cartRule={cartRule} />
        <ViewRowInterchange
          className="w-100"
          dataSource={cartRule}
          keyList={DETAILS_KEY as any}
          variant="border"
          classNameLabel="font-weight-bold "
          classNameContent="text-end "
        />
      </div>
    </div>
  );
};

export default CartRuleDetailInfo;
