import { Modal } from "d-react-components";
import { useFormik } from "formik";
import React, { useContext } from "react";
import UserFilterForm from "./UserFilterForm";
import { useTranslation } from "react-i18next";
import Drawer from "@components/shared/Drawer";

function UserTableFilterModal({ open, onClose, onFilter, defaultValue }: any) {
  const { t } = useTranslation();

  const filterForm = useFormik({
    initialValues: defaultValue,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      onFilter && onFilter(values);
      onClose();
    },
  });

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("filter")}
      onSave={() => filterForm.handleSubmit()}
    >
      <UserFilterForm form={filterForm} />
    </Drawer>
  );
}

export default UserTableFilterModal;
