import { STOCK_REQUEST_TYPE } from "@constants/stock-request";
import { map } from "lodash";
import moment from "moment";
import { IChain } from "./chain";
import { IChannel } from "./channel";
import { IStore } from "./store";

export interface IStockRequest {
  id: string;
  ref: string;
  channel: IChannel;
  chain: IChain;
  store: IStore;
  request_type: STOCK_REQUEST_TYPE;
  expected_arrival_date: number;
  created: number;
  updated: number;

  products: any[];

  stock_inventory_control: any;
  remark: string;
  status: string;
  by: any;
  confirmBy: any;
  confirm_request_stock_at: number;
  confirm_request_stock_note: string;
}

export const mapStockRequestToServer = (input: any) => {
  const {
    channel,
    store,
    expectedDateArrival,
    remark,
    requestType,
    products,
    event,
    chain,
    documents,
  } = input;
  return {
    stock_inventory_control_id: event?.id,
    channel: channel?.id,
    storeid: store?.id,
    expected_arrival_date: moment(expectedDateArrival).valueOf(),
    remark,
    request_type: requestType,
    // name_of_request_stock: nameOfEvent,
    products: map(products, (item) => ({
      product_id: item.id,
      quantity: item.quantity,
    })),
    chain_id: chain?.id,
    documents: map(documents, (item) => item.key),
  };
};
