import {
  API_ADMIN_PROFILE,
  API_PROVINCE_LIST,
  API_DISTRICT_LIST,
  API_SUBDISTRICT_LIST,
  API_ORDER_PAYMENT_METHODS,
  API_ORDER_DETAIL_PUBLIC,
  API_ORDER_ACTIVITY_PUBLIC,
  API_ORDER_PAYMENT_OPTIONS,
  API_AUTHENTICATOR,
  API_LOGIN,
  API_GET_WORKINFINITY_LOGIN_URL,
  API_LOGIN_WORKINFINITY_X,
  API_LIST_MENU,
  API_CALL_CENTER_INFORMATION,
  API_POSTCODE_LIST,
} from "@network/URL";
import { filter, find, some } from "lodash";
import { getAdminPermission } from "./account";

export const API_METHOD = {
  POST: "post",
  GET: "get",
  PUT: "put",
  DELETE: "delete",
};

const GRANT_PERMISSION = true;
const DENY_PERMISSION = false;
const ALL_PATH = "*";

const API_ALWAYS_GRANTED = [
  API_ADMIN_PROFILE,
  API_PROVINCE_LIST,
  API_DISTRICT_LIST,
  API_SUBDISTRICT_LIST,
  API_POSTCODE_LIST,
  API_ORDER_PAYMENT_METHODS,
  API_ORDER_DETAIL_PUBLIC,
  API_ORDER_ACTIVITY_PUBLIC,
  API_ORDER_PAYMENT_OPTIONS,
  // AppConfig.getMetaDataUrl() + API_PROVINCE_LIST,
  // AppConfig.getMetaDataUrl() + API_CITY_LIST,
  // AppConfig.getMetaDataUrl() + API_SUBDISTRICT_LIST,
  API_LOGIN,
  API_AUTHENTICATOR,
  API_ADMIN_PROFILE,
  API_GET_WORKINFINITY_LOGIN_URL,
  API_LOGIN_WORKINFINITY_X,
  API_CALL_CENTER_INFORMATION,
  API_LIST_MENU,
];

const getAllPathItem = () => {
  const permissions = getAdminPermission();
  if (!permissions || permissions.length === 0) return null;
  const allPathItem = permissions.find((item: any) => item.path === ALL_PATH);
  return allPathItem;
};

const checkAllPathPermission = (method?: any) => {
  const allPathItem = getAllPathItem();
  return checkMethodIsGranted(allPathItem.method, method);
};

const checkMethodIsGranted = (methodList: any, method: any) => {
  if (methodList.length === 0) {
    // console.log("Permission is granted for all")
    return GRANT_PERMISSION;
  }
  // console.log("Permission is granted for some method")
  return methodList.includes(method.toUpperCase());
};

const findUrlItemPermissions = (url: string) => {
  const permissions = getAdminPermission();
  if (!permissions || permissions.length === 0) {
    // console.log("This user has all permission", url)
    return DENY_PERMISSION;
  }

  return permissions.filter((item: any) => {
    const pathRegex = item.path.split("/").join("\\/");
    const regex = new RegExp(pathRegex);
    const m = regex.exec(`/${url}`);
    // console.log("pathRegex", m, pathRegex, url)
    return !!m;
  });
};

export const isGrantURLPermission = (url: string, method = API_METHOD.POST) => {
  if (API_ALWAYS_GRANTED.includes(url)) {
    return GRANT_PERMISSION;
  }
  if (getAllPathItem()) {
    return checkAllPathPermission();
  }

  const permissions = getAdminPermission();

  if (!permissions || permissions.length === 0) {
    return DENY_PERMISSION;
  }
  const permissionUrls = findUrlItemPermissions(url);

  if (!permissionUrls || permissionUrls.length === 0) {
    return DENY_PERMISSION;
  }
  return some(permissionUrls, (permissionUrl) =>
    checkMethodIsGranted(permissionUrl.method, method)
  );
};

export const isGrantMenuPermission = (
  url: string,
  method = API_METHOD.POST
) => {
  if (API_ALWAYS_GRANTED.includes(url)) {
    return GRANT_PERMISSION;
  }
  if (getAllPathItem()) {
    return checkAllPathPermission();
  }

  const permissions = getAdminPermission();

  if (!permissions || permissions.length === 0) {
    return DENY_PERMISSION;
  }
  if (find(permissions, (item: any) => item.path === "/admin*")) {
    return GRANT_PERMISSION;
  }
  const permissionUrls = filter(permissions, (item: any) =>
    item.path.includes(url)
  );

  if (!permissionUrls || permissionUrls.length === 0) {
    return DENY_PERMISSION;
  }
  return some(permissionUrls, (permissionUrl) =>
    checkMethodIsGranted(permissionUrl.method, method)
  );
};
