import { FILE_ICON, FILE_TYPES } from "@constants/common";
import { Button, Icon, StringUtils } from "d-react-components";
import { t } from "i18next";
import { isEmpty } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import Carousel, { Modal, ModalGateway } from "react-images";
import UploadFileItem from "./UploadFileItem";

const { IMAGE } = FILE_TYPES;
const FILE_TYPE = [FILE_TYPES.DOC, FILE_TYPES.EXCEL, FILE_TYPES.PDF];

export const RenderPreviewFile = ({
  item,
  extension,
  name,
  source = null,
  isRemovable = false,
  handleViewImage,
  onClickRemoveHandle,
  showName = true,
  itemClassName = "w-20 mr-2 mb-2 inline-block",
}: any) => {
  if (IMAGE.extension.includes(extension)) {
    return (
      <div className={itemClassName}>
        <UploadFileItem
          onClickRemove={() => onClickRemoveHandle(item)}
          source={source || item.imageData}
          isRemovable={!!isRemovable}
          onClick={() => handleViewImage(item, source)}
        />
        {showName && (
          <span className="text-ellipsis overflow-hidden truncate w-20 inline-block">
            {name}
          </span>
        )}
      </div>
    );
  }
  return (
    <>
      {FILE_TYPE?.map((type) => {
        console.log("type", type);
        if (type.extension.includes(extension)) {
          return (
            <div id="filePreview" className="relative ">
              <a
                href={item?.url}
                className="group inline-block"
                target="_blank"
              >
                <UploadFileItem
                  onClickRemove={() => onClickRemoveHandle(item)}
                  source={`/images/files/${FILE_ICON?.[extension]}`}
                  isRemovable={!!isRemovable}
                />
                {!isRemovable && (
                  <div
                    id="downloadIconViewUpdate"
                    className="absolute top-[25px] left-[20px] group-hover:opacity-100 opacity-0"
                  >
                    <Icon name="get_app" size="xx-large" />
                  </div>
                )}
              </a>
              {name && (
                <span className="w-20 text-ellipsis overflow-hidden block truncate">
                  {name}
                </span>
              )}
            </div>
          );
        }
        return null;
      })}
    </>
  );
};

export default function UploadFile({
  buttonText,
  getFile,
  uploadedFiles = [],
  getViewImages = null,
  uploadImagesOnly = false,
  displayButton = true,
  getFileName = null,
  getFileSource = null,
  isJustGetFile = false,
  disabled = false,
  styleContainer,
  styleButtonContainer = {},
  styleIcon = {},
  isRemoveUploaded = false,
  onClickRemoveUploadedFile,
  showName,
}: any) {
  const [attachments, setAttachments] = useState<any[]>([]);
  const [imageIndex, setImageIndex] = useState(0);
  const [modalImageView, setModalImageView] = useState(false);
  const listImageRef = useRef<any>([]);
  const inputParam = uploadImagesOnly
    ? { accept: "image/x-png,image/jpeg,image/heic" }
    : {};

  useEffect(() => {
    listImageRef.current = [...attachments, ...uploadedFiles];
  }, [attachments, uploadedFiles, uploadedFiles?.length]);

  const onDrop: any = (fileUpload = []): any => {
    if (isJustGetFile) {
      getFile(fileUpload);
      return;
    }

    const fileResult: any[] = [];
    fileUpload.forEach((file, index) => {
      const reader = new FileReader();
      const url = reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        fileResult.push({
          fileData: file,
          imageData: reader.result,
        });
        if (index === fileUpload.length - 1) {
          const clone = [...fileResult];
          setAttachments([...attachments, ...clone]);
          getFile && getFile([...attachments, ...clone]);
        }
      };
    });
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleViewImage = (item: any) => {
    const clone = [...listImageRef.current];
    for (const key in clone) {
      if (clone[key] === item) {
        setImageIndex(parseInt(key));
        setModalImageView(true);
      }
    }
  };

  const onClickRemoveHandle = (item: any) => {
    const clone = attachments.filter(
      (attach) => attach?.imageData !== item?.imageData
    );
    setAttachments(clone);
    getFile(clone);
  };

  return (
    <div style={styleContainer} className="customizedUploadFileButtonContainer">
      <div className="attachmentPreviewContainer">
        {!isEmpty(uploadedFiles) &&
          uploadedFiles?.map((file: any) => {
            const fileName = getFileName ? getFileName(file) : file?.name;
            const fileExtension =
              StringUtils.getExtensionFromFilename(fileName)?.toLowerCase();
            const source = getFileSource ? getFileSource(file) : file?.url;
            return (
              <RenderPreviewFile
                item={file}
                extension={fileExtension}
                name={fileName}
                source={source}
                key={
                  file?.name
                    ? file?.name + file?.url
                    : getFileName + getFileSource
                }
                onClickRemoveHandle={onClickRemoveUploadedFile}
                handleViewImage={handleViewImage}
                isRemovable={isRemoveUploaded}
                showName={showName}
              />
            );
          })}
        {attachments &&
          attachments?.map((item) => {
            const fileName = item?.fileData?.name;
            const fileExtension =
              StringUtils.getExtensionFromFilename(fileName)?.toLowerCase();
            return (
              <RenderPreviewFile
                item={item}
                extension={fileExtension}
                name={fileName}
                isRemovable
                onClickRemoveHandle={onClickRemoveHandle}
                handleViewImage={handleViewImage}
                showName={showName}
              />
            );
          })}
      </div>
      {displayButton && (
        <Button iconName="cloud_upload" disabled={disabled} {...getRootProps()}>
          <input {...getInputProps(inputParam)} />
          {buttonText || t("browsefiles")}
        </Button>
      )}
      {/* @ts-ignore */}
      <ModalGateway>
        {modalImageView ? (
          //  @ts-ignore
          <Modal onClose={() => setModalImageView(false)}>
            {/* @ts-ignore  */}
            <Carousel
              currentIndex={imageIndex}
              views={
                getViewImages
                  ? getViewImages(listImageRef.current)
                  : listImageRef.current?.map((image: any) => {
                      if (!isEmpty(image?.imageData)) {
                        return { src: image?.imageData };
                      }
                      return { src: image?.attachment };
                    })
              }
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}
