import Drawer from "@components/shared/Drawer";
import UploadFile from "@components/shared/UploadFile";
import { PAYMENTS_METHODS } from "@constants/payment";
import AppContext from "@helpers/context";
import {
  getPaidFromPayments,
  getRefundedAmountFromRefunds,
} from "@helpers/payment";
import {
  createRefund,
  editRefund,
  uploadAttachmentRefund,
} from "@network/api/order";
import { Notifications, InputText, Select } from "d-react-components";
import { find, isEmpty } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const initialState = {
  amount: 0,
  method: PAYMENTS_METHODS[0],
  reason: "",
};

const getInitialState = (payload: any) => {
  if (isEmpty(payload)) {
    return initialState;
  }
  let method = {};
  for (const key in PAYMENTS_METHODS) {
    if (PAYMENTS_METHODS[key].id === payload?.method) {
      method = PAYMENTS_METHODS[key];
    }
  }
  return { ...payload, method };
};

export default function ManageRequestRefundDrawer({
  payload = {},
  modalTitle = "",
  isEdit = false,
  openModal,
  setOpenModal,
}: any) {
  const { t } = useTranslation();
  const { order, onReloadOrderData, splitPayment, refund, oldPayment } =
    useContext(AppContext);
  const [refundContent, setRefundContent] = useState<any>(initialState);
  const [uploadFile, setUploadFile] = useState([]);
  const paidAmount =
    getPaidFromPayments(splitPayment) + getPaidFromPayments(oldPayment);
  const refundedAmount = getRefundedAmountFromRefunds(refund);

  useEffect(() => {
    setRefundContent(getInitialState(payload));
  }, [openModal]);

  const onClickSaveHandle = async () => {
    if (refundContent.amount <= 0) {
      Notifications.showError(t("notification:amountMustBeGreaterThanZero"));
      return Promise.reject();
    }
    const body = {
      ...refundContent,
      orderid: order?.id,
      method: refundContent?.method?.id,
    };
    if (isEdit) {
      const editBody = {
        ...refundContent,
        refundid: payload?.id,
        method: refundContent?.method?.id,
      };
      return editRefund(editBody)
        .then(() => {
          if (isEmpty(uploadFile)) {
            onReloadOrderData();
            setOpenModal(false);
            Notifications.showSuccess(
              t("notification:editRequestRefundSuccess")
            );
          } else {
            uploadRefundAttachments(payload?.id);
          }
        })
        .catch((error: any) => {
          // Notifications.showError(error?.message)
          Notifications.showError(t("notification:theRefundRequestIsInvalid"));
        });
    }

    return createRefund(body)
      .then((respone) => {
        if (isEmpty(uploadFile)) {
          onReloadOrderData();
          setOpenModal(false);
          Notifications.showSuccess(
            t("notification:createRequestRefundSuccess")
          );
        } else {
          const refundid = respone?.data?.data?.refund?.id;
          uploadRefundAttachments(refundid);
        }
      })
      .catch((error) => {
        // Notifications.showError(error?.message)
        Notifications.showError(t("notification:theRefundRequestIsInvalid"));
      });
  };

  const uploadRefundAttachments = (refundid: string) => {
    const APIlist =
      uploadFile &&
      uploadFile?.map((item: any) => {
        const data = new FormData();
        data.append("file", item.fileData);
        data.append("refundid", refundid);
        return uploadAttachmentRefund(data);
      });
    return Promise.all(APIlist)
      .then((respone) => {
        onReloadOrderData();
        setOpenModal(false);
        Notifications.showSuccess(t("notification:createRequestRefundSuccess"));
      })
      .catch((error) => {
        onReloadOrderData();
        Notifications.showError(error?.message);
      });
  };

  const renderRequestRefundModal = () => {
    return (
      <div className="mt-3">
        <div className="mb-3">
          <InputText
            value={refundContent.amount.toLocaleString()}
            title={t("amount")}
            onChange={(event) => {
              const result = event.target.value.replace(/\D/g, "");
              let value = parseInt(result);
              if (!value) {
                value = 0;
              }
              const newRefundAmount =
                refundedAmount + value - (payload?.amount ?? 0);
              if (paidAmount - newRefundAmount < 0) {
                Notifications.showError(
                  t("notification:refundAmountCannotBeGreaterThanOrderTotal")
                );
                return;
              }
              setRefundContent({ ...refundContent, amount: value });
            }}
            suffix="฿"
          />
        </div>
        <div className="mb-3">
          <Select
            label={t("method")}
            dataSource={PAYMENTS_METHODS}
            getLabel={(option) => t(option.label)}
            getValue={(option) => option.id}
            value={refundContent.method?.id}
            onChange={(option) => {
              const selected = find(PAYMENTS_METHODS, { id: option });
              setRefundContent({ ...refundContent, method: selected });
            }}
            placeholder={t("pleaseSelect")}
          />
        </div>
        <div className="mb-3">
          <InputText
            label={t("reason")}
            value={refundContent.reason}
            onChange={(event) => {
              setRefundContent({
                ...refundContent,
                reason: event.target.value,
              });
            }}
            multiple
            rows={5}
          />
        </div>
        <div className="my-3">
          <div className="mb-2 font-bold">{t("attachments")}</div>
          <UploadFile
            getFile={(file: any) => setUploadFile(file)}
            uploadedFiles={payload?.attachment}
            getFileName={(file: any) => file?.attachment}
            getFileSource={(file: any) => file?.attachment}
          />
        </div>
      </div>
    );
  };

  const renderSecondTitle = () => {
    return (
      <div className="subTitle1" id="renderSecondTitleCreateRefund">
        <div>
          {`${t("paidAmount")}: `}
          <span style={{ fontWeight: "normal" }}>{`฿${paidAmount}`}</span>
        </div>
        <div>
          {`${t("requestedRefundedAmount")}: `}
          <span style={{ fontWeight: "normal" }}>{`฿${refundedAmount}`}</span>
        </div>
        <div>
          {`${t("remainingAmount")}: `}
          <span style={{ fontWeight: "normal" }}>{`฿${
            paidAmount - refundedAmount
          }`}</span>
        </div>
      </div>
    );
  };

  return (
    <>
      <Drawer
        title={t("refundRequest")}
        open={openModal}
        onClose={() => setOpenModal(false)}
        onSave={onClickSaveHandle}
        size="auto"
        width={600}
        saveText={t("submit")}
      >
        <div className="pb-12">
          {renderSecondTitle()}
          {renderRequestRefundModal()}
        </div>
      </Drawer>
    </>
  );
}
