/* eslint-disable no-restricted-globals */
import { CART_RULE_ACTION_KEY } from "@constants/order";
import { getAdminProfile } from "@helpers/account";
import AppContext from "@helpers/context";
import { DiscountType } from "@interfaces/common";
import {
  createDiscountRequest,
  discountRequestGetListManager,
  getEmployeeDiscount,
  requestManagerDiscount,
} from "@network/api/order";
import classNames from "classnames";
import {
  Button,
  Checkbox,
  Drawer,
  InputText,
  Notifications,
  ObjectUtils,
  Progress,
  Select,
  StringUtils,
} from "d-react-components";
import { find, isEmpty, map, set } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const couponKey = "coupon";
const employeeDiscountKey = "employeeDiscount";
const amountKey = "amount";

const fixed = CART_RULE_ACTION_KEY.discountFix.id;
const percentage = CART_RULE_ACTION_KEY.discountPercentage.id;

const initCoupon = {
  name: "",
  key: couponKey,
  value: null,
  id: "",
  applied: false,
};

const INIT_AMOUNT_LIMITATION = {
  amountMax: 0,
  percentageMax: 0,
  flexible: true,
};

const checkDisableInput = (payload: any) => {
  return !!payload?.applied;
};

export const ApplyTextInput = ({
  placeholder,
  onChange,
  disabled,
  value,
  onClickApply,
  className = "",
}: any) => {
  const { t } = useTranslation();
  return (
    <div className={classNames("d-flex", className)}>
      <InputText
        placeholder={placeholder}
        onChange={onChange}
        value={value ?? ""}
        disabled={disabled}
        className="w-100"
      />
      <Button onClick={onClickApply} disabled={disabled}>
        {t("apply")}
      </Button>
    </div>
  );
};

const DiscountDrawer = ({
  open,
  onClose,
  numberDiscount,
  applyCoupon,
}: any) => {
  const { t } = useTranslation();
  const { freeGiftList, setFreeGiftList, productList, subTotalState, values } =
    useContext(AppContext);
  const { storeSelected, selectedCustomer, selectedChannel } = values;

  const getInitialDiscount = (number: any) => {
    return [
      {
        ...initCoupon,
        name: `${t("discount")} ${number}`,
        id: StringUtils.getUniqueID(),
      },
    ];
  };

  const DISCOUNT_TYPES = [
    {
      id: "coupon",
      label: t("coupon"),
      initData: initCoupon,
      getDisabled: checkDisableInput,
      renderContent: renderCouponView,
    },
    {
      id: "amount",
      label: t("amount"),
      initData: {
        name: "",
        key: amountKey,
        value: 0,
        id: "",
        type: percentage,
        requested: false,
        reason: "",
        requestTo: null,
        applied: false,
      },
      getDisabled: checkDisableAmountInput,
      renderContent: renderAmountView,
    },
    {
      id: "employeeDiscount",
      label: t("employeeDiscount"),
      initData: {
        name: "",
        key: employeeDiscountKey,
        value: null,
        id: "",
        applied: false,
      },
      getDisabled: checkDisableEmployeeDiscount,
      renderContent: renderEmployeeDiscount,
    },
  ];
  const [discountType, setDiscountType] = useState<any>();
  const [listDiscount, setListDiscount] = useState<any[]>([]);
  const [listManager, setListManager] = useState<any[]>([]);
  const [showLockCode, setShowLockCode] = useState(false);
  const [requestManagerCode, setRequestManagerCode] = useState("");
  const [requestManagerCodeSuccess, setRequestManagerCodeSuccess] =
    useState(false);

  const [amountLimitation, setAmountLimitation] = useState<any>(
    INIT_AMOUNT_LIMITATION
  );

  // function getAmountLimitation() {
  //   const userProfile = getAdminProfile();
  //   const discountStoreConfigs = userProfile?.management_discount_store ?? [];
  //   const discountStoreConfig = find(
  //     discountStoreConfigs,
  //     (item) => item.storeid === storeSelected?.id
  //   );
  //   return {
  //     amountMax: discountStoreConfig?.amount ?? 0,
  //     percentageMax: discountStoreConfig?.percentage ?? 0,
  //   };
  // }

  // useEffect(() => {
  //   const amountLimitation = getAmountLimitation();
  //   setAmountLimitation(amountLimitation);
  // }, [storeSelected]);

  useEffect(() => {
    setListDiscount(getInitialDiscount(numberDiscount + 1));
  }, [open]);

  useEffect(() => {
    getListManager();
  }, []);

  // useEffect(() => {
  //   if (checkCoupon?.validate) {
  //     const clone = listDiscount?.map((item) =>
  //       item?.id === checkCoupon?.id ? { ...item, applied: true } : item
  //     );
  //     setListDiscount(clone);
  //   }
  // }, [checkCoupon]);

  const getListManager = () => {
    discountRequestGetListManager()
      .then((res) => {
        const manager = res?.data?.data?.manager ?? [];
        setListManager(manager);
      })
      .catch((err) => console.error(err));
  };

  const handleOnChangeData = (discount: any) => {
    const clone = listDiscount?.map((item) =>
      item?.id === discount?.id ? discount : item
    );
    setListDiscount(clone);
  };

  const handleChangeDiscountType = (discount: any, type: any) => {
    const listDiscountResult = ObjectUtils.updateArrayById(
      listDiscount as any,
      {
        ...type.initData,
        name: discount.name,
        id: discount.id,
      }
    );
    setListDiscount(listDiscountResult);
    setDiscountType(type);
  };

  const getDisableSelectDiscountType = (discountType: any) => {
    const isExistSelfDiscount = find(freeGiftList, (item) => item.selfDiscount);
    if (discountType.id === "amount" && isExistSelfDiscount) {
      return true;
    }
    return false;
  };

  const handleAddMoreDiscount = () => {
    setListDiscount([
      ...listDiscount,
      {
        ...initCoupon,
        name: `${t("discount")} ${listDiscount.length + 1 + numberDiscount}`,
        id: new Date().getTime(),
      },
    ]);
  };

  const onApplyAmountHandle = (amountData: any) => {
    if (!amountData?.value || amountData?.value == 0) {
      return;
    }

    const giftItem = {
      action: {
        amount: amountData?.value,
        specific: amountData?.type,
        percentage: amountData?.value,
      },
      id: amountData?.id,
      discountType: amountData?.type,
      selfDiscount: true,
      managerDiscountId: amountLimitation?.managerDiscountId,
      approvedBy: amountLimitation?.approvedBy,
    };
    setFreeGiftList([...freeGiftList, giftItem]);
    const clone = { ...amountData, applied: true };
    handleOnChangeData(clone);
    onClose();
  };

  const onRequestDiscountHandle = (amountData: any) => {
    if (isEmpty(amountData?.requestTo)) {
      Notifications.showError(t("notification:pleaseSelectPersonToRequest"));
      return;
    }
    if (isEmpty(amountData?.reason)) {
      Notifications.showError(t("notification:pleaseAddReasonToRequest"));
      return;
    }
    if (isEmpty(selectedCustomer)) {
      Notifications.showError(t("notification:pleaseSelectCustomer"));
      return;
    }
    if (isEmpty(productList)) {
      Notifications.showError(t("notification:pleasePickProductFirst"));
      return;
    }
    const bodyProduct = productList?.map((pro: any) => ({
      product_id: pro?.productid,
      quantity: pro?.quantity,
      regular_price: pro?.regular_price,
      sale_price: pro?.sale_price,
      gift: false,
    }));
    const body = {
      channel_id: selectedChannel?.id,
      request_to: amountData?.requestTo?.[0]?.id,
      customer_id: selectedCustomer?.id,
      total: subTotalState,
      shipping: 1,
      notes: amountData?.reason,
      products: bodyProduct,
    };
    createDiscountRequest(body)
      .then((res) => {
        const clone = { ...amountData, applied: true };
        handleOnChangeData(clone);
        Notifications.showSuccess(t("notification:sendRequestDiscountSuccess"));
      })
      .catch((err) => console.error({ err }));
  };

  const onApplyCouponHandle = (couponData: any) => {
    if (isEmpty(couponData?.value)) {
      return;
    }
    applyCoupon && applyCoupon(couponData);
    onClose();
  };

  const onApplyEmployeeDiscount = async (employeeData: any) => {
    if (isEmpty(employeeData.value)) {
      return;
    }
    const body = { code: employeeData.value };
    Progress.show({ method: getEmployeeDiscount, params: body }, (res: any) => {
      const discountData = res?.data?.data?.discount;
      const giftItem = {
        action: {
          amount: discountData?.value,
          specific: discountData?.discount,
          percentage: discountData?.value,
        },
        id: employeeData?.id,
        discountType: discountData.discount,
        isEmployeeDiscount: true,
        employeeDiscountCode: employeeData.value,
        priority: 1000,
      };

      setFreeGiftList([...freeGiftList, giftItem]);
      const clone = { ...employeeData, applied: true };
      handleOnChangeData(clone);
      onClose();
    });
  };

  const onDeleteDiscountHandle = (deleteDiscout: any) => {
    const clone = listDiscount.filter((item) => item?.id !== deleteDiscout?.id);
    setListDiscount(clone);
  };

  function renderCouponView(couponPayload: any) {
    return (
      <ApplyTextInput
        placeholder={t("insertCouponHere")}
        onChange={(e: any) => {
          const clone = { ...couponPayload, value: e.target.value };
          handleOnChangeData(clone);
        }}
        value={couponPayload.value}
        disabled={checkDisableInput(couponPayload)}
        onClickApply={() => onApplyCouponHandle(couponPayload)}
      />
    );
  }

  function renderEmployeeDiscount(couponPayload: any) {
    return (
      <ApplyTextInput
        placeholder={t("insertDiscountCode")}
        onChange={(e: any) => {
          const clone = { ...couponPayload, value: e.target.value };
          handleOnChangeData(clone);
        }}
        value={couponPayload.value}
        disabled={checkDisableEmployeeDiscount(couponPayload)}
        onClickApply={() => onApplyEmployeeDiscount(couponPayload)}
      />
    );
  }

  const setDiscountLimitation = (codeDiscount: any, amountPayload?: any) => {
    const { has_flexible, discount_type, discount_value, code, admin } =
      codeDiscount;
    if (has_flexible) {
      const amountMax = subTotalState * (discount_value / 100);
      setAmountLimitation({
        amountMax,
        percentageMax: discount_value,
        managerDiscountId: code,
        approvedBy: admin,
        flexible: true,
      });
    } else {
      setAmountLimitation({
        amountMax: discount_value,
        percentageMax: 0,
        managerDiscountId: code,
        approvedBy: admin,
        flexible: false,
      });
      const clone = {
        ...amountPayload,
        type: discount_type === DiscountType.FixAmount ? fixed : percentage,
        value: discount_value,
      };
      handleOnChangeData(clone);
    }
  };

  const disableEditDiscountAmount = useMemo(() => {
    return !amountLimitation?.flexible;
  }, [amountLimitation]);

  function renderAmountView(amountPayload: any) {
    const disabled = checkDisableInput(amountPayload);

    const renderInputAmountView = () => {
      const { percentageMax, amountMax } = amountLimitation;

      return (
        <div className="d-flex">
          <Button
            onClick={() => {
              const clone = { ...amountPayload, type: percentage, value: 0 };
              handleOnChangeData(clone);
            }}
            disabled={disabled || disableEditDiscountAmount}
            variant={
              amountPayload?.type === percentage ? "standard" : "outline"
            }
          >
            %
          </Button>
          <Button
            onClick={() => {
              const clone = { ...amountPayload, type: fixed, value: 0 };
              handleOnChangeData(clone);
            }}
            disabled={disabled || disableEditDiscountAmount}
            variant={amountPayload?.type === fixed ? "standard" : "outline"}
          >
            ฿
          </Button>
          <InputText
            className="w-100"
            onChange={(e) => {
              let value = e.target.value.replace(/\D+/g, "");
              value = parseInt(value);
              if (isNaN(value)) {
                return;
              }
              if (amountPayload.type === percentage && value > percentageMax) {
                value = percentageMax;
              }
              if (amountPayload.type === fixed && value > amountMax) {
                value = amountMax;
              }
              const clone = { ...amountPayload, value };
              handleOnChangeData(clone);
            }}
            value={amountPayload?.value?.toLocaleString()}
            disabled={disabled || disableEditDiscountAmount}
          />
          <Button
            onClick={() => onApplyAmountHandle(amountPayload)}
            disabled={disabled}
          >
            {t("apply")}
          </Button>
        </div>
      );
    };

    const renderRequestAmountView = () => {
      return (
        <div>
          <InputText
            placeholder={t("discountReason")}
            className="w-100"
            onChange={(e) => {
              const clone = { ...amountPayload, reason: e.target.value };
              handleOnChangeData(clone);
            }}
            value={amountPayload?.reason}
            disabled={disabled}
          />
          <div>
            <div>{t("requestedTo")}</div>
            <div>
              <Select
                onChange={(value: any) => {
                  const clone = { ...amountPayload, requestTo: value };
                  handleOnChangeData(clone);
                }}
                disabled={disabled}
                classNameSelect="inputRequest"
                dataSource={listManager}
                value={amountPayload?.requestTo ?? []}
                getLabel={(item: any) => item?.name}
                placeholder={t("pleaseSelect")}
              />
              {/* <LoadOnScrollList
                multiSelect={false}
                listItem={listManager}
                getLabelItem={(item: any) => item.name}
                value={amountPayload?.requestTo ?? []}
                onChange={(value: any) => {
                  const clone = { ...amountPayload, requestTo: value };
                  handleOnChangeData(clone);
                }}
                renderAvatar={(item: any) => {
                  return <Avatar src={item?.avatar} size="small" />;
                }}
                inputClassName="inputRequest"
                showUnderLine={false}
                disabled={disabled}
              /> */}
              <Button
                onClick={() => {
                  onRequestDiscountHandle(amountPayload);
                }}
                className={disabled ? "btn-default" : "btn-secondary"}
                disabled={disabled}
              >
                {disabled ? t("requested") : t("request")}
              </Button>
            </div>
          </div>
        </div>
      );
    };

    const onRequestManagerDiscount = () => {
      if (isEmpty(requestManagerCode)) {
        return;
      }

      const body = {
        code: requestManagerCode,
        warehouse_id: storeSelected?.id,
      };
      Progress.show(
        { method: requestManagerDiscount, params: [body] },
        (res: any) => {
          const discount = res?.data?.data ?? {};
          setDiscountLimitation(discount, amountPayload);
          setRequestManagerCodeSuccess(true);
        }
      );
    };

    const renderRequestManagerCode = () => {
      if (disabled || !storeSelected) {
        return <div />;
      }
      return (
        <div className="d-flex flex-column">
          {amountLimitation && amountLimitation.flexible && (
            <>
              <div className="d-flex my-3">
                <span>{t("wantSpecialStoreDiscount")}</span>
                <div
                  className="cursor-pointer"
                  onClick={() => setShowLockCode(true)}
                >
                  <span className="text-success ml-2">{t("unlockCode")}</span>
                </div>
              </div>
              {showLockCode && (
                <ApplyTextInput
                  placeholder={t("enterManagerLockCode")}
                  onChange={(e: any) => {
                    setRequestManagerCode(e.target.value);
                  }}
                  value={requestManagerCode}
                  onClickApply={onRequestManagerDiscount}
                />
              )}
            </>
          )}

          <div
            className="text-success mt-3"
            hidden={!requestManagerCodeSuccess}
          >
            {t("notification:unLockManagerCodeSuccessful")}
          </div>
        </div>
      );
    };
    return (
      <div>
        {!amountPayload?.requested && renderInputAmountView()}
        {renderRequestManagerCode()}
        {amountPayload?.requested && renderRequestAmountView()}
      </div>
    );
  }

  function checkDisableAmountInput(payload: any) {
    if (payload?.applied) {
      return true;
    }
    const isExistSelfDiscount = find(freeGiftList, (item) => item.selfDiscount);
    if (isExistSelfDiscount) {
      return true;
    }
    return false;
  }

  function checkDisableEmployeeDiscount(payload: any) {
    if (payload?.applied) {
      return true;
    }

    const isExistEmployeeDiscount = find(
      freeGiftList,
      (item) => item.isEmployeeDiscount
    );
    if (isExistEmployeeDiscount) {
      return true;
    }
    return false;
  }

  const renderRadioDiscountType = (discount: any, type: any) => {
    const isChecked = discount.key === type.id;
    return (
      <div className="mt-3">
        <Checkbox
          variant="radio"
          checked={discount.key === type.id}
          onChange={() => handleChangeDiscountType(discount, type)}
          label={type.label}
          disabled={getDisableSelectDiscountType(type)}
        />
        <div hidden={!isChecked} className="mt-3">
          {type.renderContent(discount)}
        </div>
      </div>
    );
  };

  const renderContentModal = () => {
    return (
      <div>
        {listDiscount.length > 0 &&
          listDiscount?.map((discount) => (
            <div key={discount?.id} className="flex-column">
              <div className="flex-center-y">
                <div className="text-primary label">{discount.name}</div>
                {!checkDisableInput(discount) && listDiscount?.length > 1 && (
                  <Button
                    iconName="delete"
                    onClick={() => onDeleteDiscountHandle(discount)}
                    variant="trans"
                  />
                )}
              </div>
              {map(DISCOUNT_TYPES, (type) =>
                renderRadioDiscountType(discount, type)
              )}
            </div>
          ))}
      </div>
    );
  };

  useEffect(() => {
    if (discountType && discountType.id === "amount") {
      setShowLockCode(false);
      setRequestManagerCodeSuccess(false);
      requestManagerDiscount({
        warehouse_id: storeSelected?.id,
      }).then((res) => {
        const discount = res?.data?.data ?? {};
        setDiscountLimitation(discount);
      });
    }
  }, [discountType]);

  return (
    <Drawer
      title={t("addDiscount")}
      open={open}
      onClose={onClose}
      destroyOnClose
    >
      {renderContentModal()}
    </Drawer>
  );
};

export default DiscountDrawer;
