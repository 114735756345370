import { IDistrict, IProvince } from "@interfaces/address";
import { IFieldSelect } from "@interfaces/select";
import { getDistrictList, getProvinceList } from "@network/api/address";
import { ObjectUtils, Select } from "d-react-components";
import { isArray, isEmpty, map } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export interface IDistrictSelect<T> extends IFieldSelect<T> {
  [key: string]: any;
  provinceId: string;
}

const DistrictSelect = ({
  value = [],
  multiple,
  className,
  onChange,
  provinceId,
  ...selectProps
}: IDistrictSelect<IDistrict>) => {
  const getInitialValue = () => {
    if (!value) return [];
    if (isArray(value)) return value;
    return [value];
  };
  const [districtList, setDistrictList] = useState<any[]>(getInitialValue());

  const { t } = useTranslation();

  const onChangeValue = (id: any) => {
    if (multiple) {
      onChange(
        map(id, (item) => ObjectUtils.findItemFromId(districtList, item))
      );
      return;
    }
    onChange(ObjectUtils.findItemFromId(districtList, id));
  };

  const districtValue = useMemo(() => {
    if (multiple) {
      return map(value, (item: any) => item.id);
    }
    return (value as any)?.id;
  }, [value]);

  useEffect(() => {
    if (isEmpty(provinceId)) return;
    loadDistrictList();
  }, [provinceId]);

  const loadDistrictList = () => {
    getDistrictList(provinceId).then((res) => {
      const districtListRes = res?.data?.data?.city ?? [];
      setDistrictList(districtListRes);
    });
  };

  return (
    <Select
      className={className}
      label={t("district")}
      dataSource={districtList}
      getLabel={(item) => item?.name}
      value={districtValue}
      onChange={onChangeValue}
      multiple={multiple}
      placeholder={t("pleaseSelect")}
      showSearch
      disabled={districtList.length === 0}
      {...selectProps}
    />
  );
};

export default DistrictSelect;
