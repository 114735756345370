import React, { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Button,
  DateInput,
  InputText,
  Notifications,
  Select,
} from "d-react-components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import moment, { Moment } from "moment";
import AppContext from "@helpers/context";
import { createNewCustomer } from "@network/api/customer";
import Drawer from "@components/shared/Drawer";
import { searchCustomer } from "@network/api/customer";
import { find, isEmpty, pick } from "lodash";
import { useDebounce } from "react-use";
import { getFullName } from "@helpers/string";
import CustomerDetailDrawer from "./CustomerDetailDrawer";
import { Spin } from "antd";
import CustomerCreateCardReader from "./CustomerCreateCardReader";
import { CustomerType } from "@interfaces/customer";
import InputVerifier from "@common/input/InputVerifier";
import styled from "@emotion/styled";
import { OrderType } from "@constants/common";

interface CustomerForm {
  firstname?: string;
  lastname?: string;
  nickname?: string;
  citizen_id?: string;
  birthday?: Moment;
  phone?: string;
  gender?: string;
  email?: string;
  company?: string;
  addressFormCard?: string;
  provinceFromCard?: string;
  districtFromCard?: string;
  subDistrictFromCard?: string;
  alternative_phone?: string;
  customer_type?: string;
  representative_first_name?: string;
  representative_last_name?: string;
  representative_email?: string;
  representative_phone?: string;
  tax_id?: string;
  is_verify_phone?: boolean;
  is_verify_email?: boolean;
  otp_phone_id?: string;
  otp_email_id?: string;
}

const CustomerInfoDrawer = ({
  customerInfo = {},
  onChangeValue,
  open,
  onClose,
  onSelectCustomer,
  channel,
}: any) => {
  const { t } = useTranslation();
  const { values: contextValues } = useContext(AppContext);
  const [customerPhone, setCustomerPhone] = useState<string>("");
  const [foundCustomer, setFoundCustomer] = useState();
  const [loadingCheckCustomer, setLoadingCheckCustomer] = useState(false);
  const { orderType } = contextValues;

  const CustomerSchema = Yup.lazy((value) => {
    let schema = Yup.object().shape({
      phone: Yup.number().required(t("fieldRequired")),
    });
    if (value.customer_type === CustomerType.BUSINESS) {
      schema = schema.concat(
        Yup.object().shape({
          representative_first_name: Yup.string().required(t("fieldRequired")),
          representative_last_name: Yup.string().required(t("fieldRequired")),
          email: Yup.string().required(t("fieldRequired")),
          company: Yup.string().required(t("fieldRequired")),
          tax_id: Yup.string().required(t("fieldRequired")),
        })
      );
    }
    if (value.customer_type === CustomerType.INDIVIDUAL) {
      schema = schema.concat(
        Yup.object().shape({
          firstname: Yup.string().required(t("fieldRequired")),
          lastname: Yup.string().required(t("fieldRequired")),
          nickname: Yup.string().required(t("fieldRequired")),
          email: Yup.string().test("required", t("fieldRequired"), (val) =>
            orderType && orderType === OrderType.RENTAL ? false : true
          ),
          citizen_id: Yup.string().test("len", t("citizenIdMustBe13"), (val) =>
            orderType && orderType === OrderType.RENTAL
              ? val?.length === 13
              : true
          ),
        })
      );
    }
    return schema;
  });

  const GENDER = [
    { key: 1, value: "male" },
    { key: 2, value: "female" },
    { key: 3, value: "other" },
  ];

  const customerForm = useFormik<CustomerForm>({
    initialValues: {
      customer_type: CustomerType.INDIVIDUAL,
    },
    onSubmit: () => {
      submitForm();
    },
    validationSchema: CustomerSchema,
    validateOnChange: false,
  });
  const { values, setFieldValue, errors, handleSubmit, setValues } =
    customerForm;
  const submitForm = async () => {
    const {
      firstname,
      lastname,
      nickname,
      phone,
      email,
      birthday,
      company,
      gender,
      citizen_id,
      addressFormCard,
      provinceFromCard,
      districtFromCard,
      subDistrictFromCard,
      alternative_phone,
      tax_id,
      representative_first_name,
      representative_last_name,
      representative_email,
      representative_phone,
      otp_phone_id,
      otp_email_id,
      is_verify_phone,
      is_verify_email,
    } = values;
    const body = {
      customer_type: values?.customer_type,
      channel_id: contextValues?.channel,
      ...(values?.customer_type === CustomerType.INDIVIDUAL
        ? {
            firstname,
            lastname,
            phone,
            alternative_phone,
            email,
            nickname,
            gender,
            citizen_id,
            birthday: birthday ? moment(birthday).valueOf() : null,
            ...(otp_phone_id && is_verify_phone ? { otp_phone_id } : {}),
            ...(otp_email_id && is_verify_email ? { otp_email_id } : {}),
          }
        : {
            company,
            tax_id,
            phone,
            email,
            representative_first_name,
            representative_last_name,
            representative_email,
            representative_phone,
            ...(otp_email_id && is_verify_email ? { otp_email_id } : {}),
          }),
    };
    try {
      const customerResp = await createNewCustomer(body);
      if (customerResp?.data?.error?.length) {
        Notifications.showError(customerResp?.data?.resp);
        return;
      }
      const customer = customerResp?.data?.data?.customers;
      const payload = { ...customer, addressFormCard };
      if (provinceFromCard) {
        Object.assign(payload, {
          provinceFromCard: provinceFromCard?.replace?.("จังหวัด", ""),
        });
      }
      if (districtFromCard) {
        Object.assign(payload, {
          districtFromCard: districtFromCard?.replace?.("อำเภอ", ""),
        });
      }
      if (subDistrictFromCard) {
        Object.assign(payload, {
          subDistrictFromCard: subDistrictFromCard?.replace?.("ตำบล", ""),
        });
      }
      onClose && onClose(payload);
    } catch (err) {
      Notifications.showError(err);
    }
  };

  const checkIsCustomerExisted = (phone: string) => {
    setLoadingCheckCustomer(true);
    searchCustomer({ search: phone }, { page: 1, limit: 1 })
      .then((resp) => {
        const customer = find(
          resp?.data?.data?.customers,
          (item) => item.channel?.id === channel
        );
        if (customer) {
          setFoundCustomer(resp?.data?.data?.customers?.[0]);
        } else {
          setFoundCustomer(undefined);
        }
        return resp;
      })
      .finally(() => {
        setLoadingCheckCustomer(false);
      });
  };

  const [, cancel] = useDebounce(
    () => {
      // setState("Typing stopped");
      setCustomerPhone(values?.phone as string);
    },
    500,
    [values?.phone]
  );

  useEffect(() => {
    if (customerPhone && customerPhone.length >= 9) {
      checkIsCustomerExisted(customerPhone);
    } else {
      setFoundCustomer(undefined);
    }
  }, [customerPhone]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      width="500px"
      size="auto"
      maskClosable={false}
      destroyOnClose
      onSave={() => handleSubmit()}
    >
      <div className="py-4 absolute top-0 right-0 left-0 border-b flex-center-y justify-between z-10 bg-white">
        <h4 className="pb-0 mb-0 pl-4">{t("createCustomer")}</h4>
        <CustomerCreateCardReader
          onChange={(value) => {
            setValues((prev = {}) => ({
              ...prev,
              ...(value || {}),
            }));
          }}
          // onTest={(value) => {
          //     setValues((prev = {}) => ({
          //         ...prev,
          //         ...(value || {}),
          //     }));
          // }}
        />
      </div>
      <div className="h-[100px]" />
      <div className="grid grid-cols-1 gap-4">
        <div className="col-span-1">
          <Select
            label={t("customerType")}
            name="CustomerType"
            options={[
              { label: t("individual"), value: CustomerType.INDIVIDUAL },
              { label: t("business"), value: CustomerType.BUSINESS },
            ]}
            onChange={(value) => {
              setFieldValue("customer_type", value);
            }}
            value={values.customer_type}
            error={errors.customer_type}
          />
        </div>
        {values.customer_type === CustomerType.INDIVIDUAL && (
          <>
            <div className="col-span-1">
              <InputText
                label={t("firstname")}
                value={values.firstname}
                onChange={(e) =>
                  setFieldValue("firstname", e?.currentTarget?.value)
                }
                error={errors?.firstname}
              />
            </div>
            <div className="col-span-1">
              <InputText
                label={t("lastname")}
                value={values.lastname}
                onChange={(e) =>
                  setFieldValue("lastname", e?.currentTarget?.value)
                }
                error={errors?.lastname}
              />
            </div>
            <div className="col-span-1">
              <InputText
                label={t("nickname")}
                value={values.nickname}
                onChange={(e) =>
                  setFieldValue("nickname", e?.currentTarget?.value)
                }
                error={errors?.nickname}
              />
            </div>
            <div className="col-span-1">
              <InputText
                label={t("citizenId")}
                value={values.citizen_id}
                onChange={(e) =>
                  setFieldValue("citizen_id", e?.currentTarget?.value)
                }
                error={errors?.citizen_id}
              />
            </div>
            <div className="col-span-1">
              <Select
                label={t("gender")}
                value={values.gender}
                onChange={(val) => {
                  setFieldValue("gender", val);
                }}
                error={errors?.gender}
                dataSource={GENDER}
                getLabel={(item) => t(item.value)}
                getValue={(item) => item.value}
                placeholder={t("pleaseSelect")}
              />
            </div>
            <div className="col-span-1">
              <DateInput
                label={t("birthday")}
                value={values?.birthday}
                error={errors?.birthday}
                onChange={(val) => {
                  setFieldValue && setFieldValue("birthday", val);
                }}
                type="date"
              />
            </div>
            <StyledFormInput className="col-span-1 relative">
              <InputText
                label={t("phone")}
                value={values.phone}
                onChange={(e) => {
                  setFieldValue("phone", e?.currentTarget?.value);
                }}
                error={errors?.phone}
                suffix={
                  orderType === OrderType.RENTAL ? (
                    <InputVerifier
                      type="phone"
                      isVerified={values?.is_verify_phone}
                      value={values?.phone}
                      payload={{ channel_id: contextValues?.channel }}
                      className="top-[2px]"
                      onVerified={(otp) => {
                        customerForm.setFieldValue("is_verify_phone", true);
                        customerForm.setFieldValue("otp_phone_id", otp?.id);
                      }}
                    />
                  ) : null
                }
              />
              {loadingCheckCustomer && (
                <div className="absolute right-[10px] bottom-[7px]">
                  <Spin />
                </div>
              )}
            </StyledFormInput>
            {foundCustomer && (
              <>
                <div className="text-red-500 text-xs">{t("phoneFound")}</div>
                <CustomerInfoWidget
                  customer={foundCustomer}
                  selectCustomer={(customer: any) => onSelectCustomer(customer)}
                />
              </>
            )}
            <div className="col-span-1 relative">
              <InputText
                label={t("alternativePhone")}
                value={values.alternative_phone}
                onChange={(e) => {
                  setFieldValue("alternative_phone", e?.currentTarget?.value);
                }}
                error={errors?.alternative_phone}
              />
            </div>
            <StyledFormInput className="col-span-1 mb-3">
              <InputText
                label={t("email")}
                value={values.email}
                onChange={(e) =>
                  setFieldValue("email", e?.currentTarget?.value)
                }
                error={errors?.email}
                suffix={
                  orderType === OrderType.RENTAL ? (
                    <InputVerifier
                      type="email"
                      isVerified={values?.is_verify_email}
                      value={values?.email}
                      payload={{ channel_id: contextValues?.channel }}
                      className="top-[2px]"
                      onVerified={(otp) => {
                        customerForm.setFieldValue("is_verify_email", true);
                        customerForm.setFieldValue("otp_email_id", otp?.id);
                      }}
                    />
                  ) : null
                }
              />
            </StyledFormInput>
          </>
        )}
        {values.customer_type === CustomerType.BUSINESS && (
          <>
            <div className="col-span-1">
              <InputText
                label={t("companyName")}
                value={values.company}
                onChange={(e) =>
                  setFieldValue("company", e?.currentTarget?.value)
                }
                error={errors?.company as string}
              />
            </div>
            <div className="col-span-1">
              <InputText
                label={t("companyTax")}
                value={values.tax_id}
                onChange={(e) =>
                  setFieldValue("tax_id", e?.currentTarget?.value)
                }
                error={errors?.tax_id as string}
              />
            </div>
            <div className="col-span-1 relative">
              <InputText
                label={t("companyPhone")}
                value={values.phone}
                onChange={(e) => {
                  setFieldValue("phone", e?.currentTarget?.value);
                }}
                error={errors?.phone as string}
              />
              {loadingCheckCustomer && (
                <div className="absolute right-[10px] bottom-[7px]">
                  <Spin />
                </div>
              )}
            </div>
            {foundCustomer && (
              <>
                <div className="text-red-500 text-xs">{t("phoneFound")}</div>
                <CustomerInfoWidget
                  customer={foundCustomer}
                  selectCustomer={(customer: any) => onSelectCustomer(customer)}
                />
              </>
            )}
            <StyledFormInput className="col-span-1">
              <InputText
                label={t("companyEmail")}
                value={values.email}
                onChange={(e) =>
                  setFieldValue("email", e?.currentTarget?.value)
                }
                error={errors?.email as string}
                suffix={
                  orderType === OrderType.RENTAL ? (
                    <InputVerifier
                      type="email"
                      isVerified={values?.is_verify_email}
                      value={values?.email}
                      payload={{ channel_id: contextValues?.channel }}
                      className="top-[2px]"
                      onVerified={(otp) => {
                        customerForm.setFieldValue("is_verify_email", true);
                        customerForm.setFieldValue("otp_email_id", otp?.id);
                      }}
                    />
                  ) : null
                }
              />
            </StyledFormInput>
            <div className="col-span-1">
              <InputText
                label={t("representativeFirstname")}
                value={values.representative_first_name}
                onChange={(e) =>
                  setFieldValue(
                    "representative_first_name",
                    e?.currentTarget?.value
                  )
                }
                error={errors?.representative_first_name as string}
              />
            </div>
            <div className="col-span-1">
              <InputText
                label={t("representativeLastname")}
                value={values.representative_last_name}
                onChange={(e) =>
                  setFieldValue(
                    "representative_last_name",
                    e?.currentTarget?.value
                  )
                }
                error={errors?.representative_last_name as string}
              />
            </div>
            <div className="col-span-1">
              <InputText
                label={t("representativePhone")}
                value={values.representative_phone}
                onChange={(e) => {
                  setFieldValue(
                    "representative_phone",
                    e?.currentTarget?.value
                  );
                }}
                error={errors?.representative_phone as string}
              />
            </div>
            <div className="col-span-1">
              <InputText
                label={t("representativeEmail")}
                value={values.representative_email}
                onChange={(e) =>
                  setFieldValue("representative_email", e?.currentTarget?.value)
                }
                error={errors?.representative_email as string}
              />
            </div>
          </>
        )}
      </div>
    </Drawer>
  );
};

export const CustomerInfoWidget = ({
  customer,
  showAction = true,
  selectCustomer,
}: {
  customer: any;
  showAction?: boolean;
  selectCustomer?: any;
}) => {
  const [showCustomerDrawer, setShowCustomerDrawer] = useState<boolean>();
  const { firstname } = customer ?? {};
  const firstNameCharacter = firstname?.[0] ? firstname?.[0].toUpperCase() : "";
  const { t } = useTranslation();

  return (
    <div className="bg-blue-500 flex items-center">
      {customer.avatar ? (
        <Avatar className="w-16 h-16 object-cover" src={customer.avatar} />
      ) : (
        <div className="w-16 h-16 flex bg-gray-400 justify-content-center items-center">
          <span className="text-white" style={{ fontSize: "1.5rem" }}>
            {firstNameCharacter}
          </span>
        </div>
      )}
      <div className="flex flex-column text-white text-xs flex-1 px-2">
        <span className="block">
          {getFullName(customer) +
            (customer?.nickname ? ` (${customer.nickname})` : "")}
        </span>
        <span className="block">{customer.phone}</span>
        <span className="block">{`${t("id").toUpperCase()}: ${
          customer.customerid
        }`}</span>
      </div>
      {showAction && (
        <div className="flex items-center">
          <Button
            color="light"
            size="small"
            className="text-primary text-xs mr-1"
            onClick={() => setShowCustomerDrawer(true)}
          >
            {t("view")}
          </Button>
          <Button
            color="light"
            size="small"
            className="text-primary text-xs mr-2"
            onClick={() => selectCustomer(customer)}
          >
            {t("select")}
          </Button>
        </div>
      )}
      {showCustomerDrawer && (
        <CustomerDetailDrawer
          open={showCustomerDrawer}
          onClose={() => setShowCustomerDrawer(false)}
          customerId={customer?.id}
        />
      )}
    </div>
  );
};

const StyledFormInput = styled.div`
  .d-input-text__suffix-container {
    background: #fff !important;
    border: none !important;
  }
`;

export default CustomerInfoDrawer;
