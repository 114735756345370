import { IFieldSelect } from "@interfaces/select";
import { getNavList } from "@network/api/delivery";
import { ObjectUtils, Select } from "d-react-components";
import { isArray, map } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export interface INavSelect<T> extends IFieldSelect<T> {
  [key: string]: any;
}

interface INav {
  [key: string]: any;
}

const NavSelect = ({
  value = [],
  multiple,
  className,
  onChange,
  ...selectProps
}: INavSelect<INav>) => {
  const getInitialValue = () => {
    if (!value) return [];
    if (isArray(value)) return value;
    return [value];
  };

  const [navList, setNavList] = useState<any[]>(getInitialValue());

  const { t } = useTranslation();

  const onChangeValue = (id: any) => {
    if (multiple) {
      onChange(map(id, (item) => ObjectUtils.findItemFromId(navList, item)));
      return;
    }
    onChange(ObjectUtils.findItemFromId(navList, id));
  };

  const navValue = useMemo(() => {
    if (multiple) {
      return map(value, (item: any) => item?.id);
    }
    return (value as any)?.id;
  }, [value]);

  useEffect(() => {
    loadNavList();
  }, []);

  const loadNavList = () => {
    getNavList().then((res) => {
      const listNav = res.data?.data?.nav ?? [];
      setNavList(listNav);
    });
  };
  
  return (
    <Select
      className={className}
      label={t("navConfig")}
      dataSource={navList}
      getLabel={(item) => item?.name}
      value={navValue}
      onChange={onChangeValue}
      multiple={multiple}
      placeholder={t("pleaseSelect")}
      showSearch
      {...selectProps}
    />
  );
};

export default NavSelect;
