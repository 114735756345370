import { API } from "@network";
import {
  API_PRODUCTION_COST_HISTORY,
  API_PRODUCTION_COST_HISTORY_MEMOS,
  API_PRODUCTION_COST_IMPORT,
  API_PRODUCTION_COST_TABLE,
  API_PRODUCTION_COST_UPDATE,
} from "@network/URL";

export const getProductionCostList = (body: any, paging: any) => {
  return API.post(API_PRODUCTION_COST_TABLE, {
    ...body,
    page: paging.pageIndex,
    limit: paging.pageSize,
  });
};

export const getProductionCostHistory = (body: any) => {
  return API.post(API_PRODUCTION_COST_HISTORY, body);
};

export const updateProductionCost = (body: any) => {
  return API.post(API_PRODUCTION_COST_UPDATE, body);
};

export const getMemoProductionCost = (body: any) => {
  return API.post(API_PRODUCTION_COST_HISTORY_MEMOS, body);
};

export const importProductionCost = (body: any) => {
  return API.post(API_PRODUCTION_COST_IMPORT, body);
};
