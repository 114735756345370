import React, { useContext } from "react";
import SelectSpecificAction from "./SelectSpecificAction";
import { CART_RULE_ACTION } from "@constants/cart-rule";
import AppContext from "@helpers/context";
import { useTranslation } from "react-i18next";
import { Icon, InputText, Select } from "d-react-components";
import { YES_NO_OPTIONS } from "@constants/common";
import { filter, isEmpty, map } from "lodash";
import { PRODUCT_TYPE } from "@constants/product";
import { browseProduct } from "@network/api/product";
import ProductSearchButton from "@components/product/components/ProductSearchButton";
import ProductName from "@components/product/components/ProductName";

const BuyNXGetDiscountActions = ({
  cartRuleActions = [],
  handleSelectAction,
  actions,
  setChangeData,
  showFreeItemsY,
}: any) => {
  const { cartRuleForm, disabledSpecificAction } = useContext(AppContext);
  const { t } = useTranslation();
  const formValues = cartRuleForm.values ?? {};

  const actionKey =
    actions?.specific ?? CART_RULE_ACTION.BUY_X_GET_DISCOUNT_FIX_AMOUNT;

  const getDiscountValue = (key: any, defaultValue?: any) => {
    return actions?.[actionKey]?.[key] ?? defaultValue;
  };

  const onChangeDiscountValue = (newDiscount: any) => {
    const result = {
      ...actions,
      [actionKey]: {
        ...(actions?.[actionKey] ?? {}),
        ...newDiscount,
      },
    };
    setChangeData("actions", result);
  };

  const renderOptionsFiled = () => {
    return (
      <div className="grid grid-cols-2 gap-4">
        <SelectSpecificAction
          disabled={disabledSpecificAction}
          actions={actions}
          handleSelectAction={handleSelectAction}
          cartRuleActions={cartRuleActions}
          className="col-span-1"
        />

        <Select
          label={t("recursive")}
          dataSource={YES_NO_OPTIONS}
          value={getDiscountValue("recursive") as any}
          onChange={(value) => {
            onChangeDiscountValue({ recursive: value });
          }}
          className="col-span-1"
        />
      </div>
    );
  };

  const getStartAdornmentAmountText = () => {
    if (
      actionKey === CART_RULE_ACTION.BUY_X_GET_DISCOUNT_FIX_AMOUNT ||
      actionKey === CART_RULE_ACTION.BUY_X_GET_DISCOUNT_FIX_AMOUNT_Y
    ) {
      return "฿";
    }
    return "%";
  };
  const renderQuantityInputField = () => {
    return (
      <div className="grid grid-cols-2 gap-4 mt-2">
        <InputText
          className="col-span-1"
          label={t("buyQuantity")}
          onChange={(event) => {
            const rawValue = event.target.value;
            const value = isEmpty(rawValue) ? 0 : parseInt(rawValue);
            if (value < 1) {
              return;
            }
            onChangeDiscountValue({ buy: value });
          }}
          value={`${getDiscountValue("buy")}`}
          type="number"
        />
        <InputText
          className="col-span-1"
          suffix={getStartAdornmentAmountText()}
          label={t("discountAmount")}
          onChange={(event) => {
            const rawValue = event.target.value;
            const value = isEmpty(rawValue) ? 0 : parseInt(rawValue);
            if (value < 0) {
              return;
            }
            onChangeDiscountValue({ amount: value });
          }}
          value={`${getDiscountValue("amount")}`}
          type="number"
        />
      </div>
    );
  };
  const renderMinMaxQuantity = () => {
    return (
      <div className="grid grid-cols-2 gap-4 mt-2">
        <InputText
          className="col-span-1"
          label={t("minQuantityToGetDiscount")}
          onChange={(event) => {
            const rawValue = event.target.value;
            const value = isEmpty(rawValue) ? 0 : parseInt(rawValue);
            if (value < 0) {
              return;
            }
            onChangeDiscountValue({ minQuantity: value });
          }}
          value={`${getDiscountValue("minQuantity")}`}
          type="number"
        />
        <InputText
          className="col-span-1"
          label={t("maxQuantityToGetDiscount")}
          onChange={(event) => {
            const rawValue = event.target.value;
            const value = isEmpty(rawValue) ? 0 : parseInt(rawValue);
            if (value < 0) {
              return;
            }
            onChangeDiscountValue({ maxQuantity: value });
          }}
          value={`${getDiscountValue("maxQuantity")}`}
          type="number"
        />
      </div>
    );
  };

  const onDeleteFreeItemY = (itemDelete: any) => {
    const productsY = getDiscountValue("product");
    const results = filter(
      productsY,
      (item) => item?.product?.id !== itemDelete?.product?.id
    );
    onChangeDiscountValue({ product: results });
  };

  const sourceSearchProduct = (code: any, filterBody: any, paging: any) => {
    const body: any = {
      channel: formValues?.selectedChannel?.id,
      promotion: "name",
      code,
      product_type: [
        PRODUCT_TYPE.DEFAULT,
        PRODUCT_TYPE.GROUP,
        PRODUCT_TYPE.CUSTOM_SIZE,
      ],
    };

    const { brand, category } = filterBody;
    if (brand && brand.length > 0) {
      body.brand = map(brand, (item) => item.id);
    }

    if (category && category.length > 0) {
      body.category = map(category, (item) => item.id);
    }

    return browseProduct(body, paging);
  };

  const renderFreeItemsY = () => {
    const productsY = getDiscountValue("product");
    return (
      <div className="mt-2">
        <div className="flex-row-between-center">
          <label>{t("freeItemsY")}</label>
          <ProductSearchButton
            onChange={(value) => onChangeDiscountValue({ product: value })}
            defaultValue={productsY}
            source={sourceSearchProduct}
            dataKey={"products"}
          />
        </div>
        <div className="freeItemList">
          {map(productsY, (productItem) => (
            <div className="flex-row-between-center py-2 border-bottom">
              <ProductName item={{ product: productItem.product }} />
              <Icon
                name="delete"
                onClick={() => onDeleteFreeItemY(productItem)}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div>
      {renderOptionsFiled()}
      {renderQuantityInputField()}
      {renderMinMaxQuantity()}
      {showFreeItemsY && renderFreeItemsY()}
    </div>
  );
};

export default BuyNXGetDiscountActions;
