import React, { useContext } from "react";
import AppContext from "@helpers/context";
import { PAYMENT_METHOD_OBJECT } from "@constants/payment";
import OrderDetailTable from "../content/OrderDetailTable";
import { TimeUtils, StringUtils, InputText } from "d-react-components";
import { useTranslation } from "react-i18next";

const PrintRefundLayout = () => {
  const { refundSelectPrint, channel, order } = useContext(AppContext);
  const { t } = useTranslation();

  const renderHeader = () => {
    return (
      <div className="refundPrintHeaderContainer">
        <img className="refundPrintLogo" src={channel?.logo} />
        <div className="subTitle1 refundRequestText">
          {t("refundRequestMemo").toUpperCase()}
        </div>
        <div className="subTitle2">{`${t(
          "createAt"
        )} ${TimeUtils.convertMiliToDateTime(
          refundSelectPrint?.created
        )}`}</div>
      </div>
    );
  };
  const renderProductTable = () => {
    return (
      <div className="orderTableContainer">
        <OrderDetailTable isViewPaid />
        <div>
          <div className="cardTitleText">{t("memo")}</div>
          <InputText value={order?.note} />
        </div>
      </div>
    );
  };
  const renderRefundInformation = () => {
    return (
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-1">
          <div className="cardTitleText">{t("orderid")}</div>
          <InputText value={order?.orderid} />
        </div>
        <div className="col-span-1">
          <div className="cardTitleText">{t("createdAt")}</div>
          <InputText value={TimeUtils.convertMiliToDateTime(order?.created)} />
        </div>

        <div className="col-span-1">
          <div className="cardTitleText">{t("requestor")}</div>
          <InputText value={refundSelectPrint?.by?.name} />
        </div>
        <div className="col-span-1">
          <div className="cardTitleText">{t("requestedDate")}</div>
          <InputText
            value={TimeUtils.convertMiliToDateTime(refundSelectPrint?.created)}
          />
        </div>
        <div className="col-span-1">
          <div className="cardTitleText">{t("refundAmount")}</div>
          <InputText
            value={StringUtils.moneyThaiFormat(refundSelectPrint?.amount)}
          />
        </div>
        <div className="col-span-1">
          <div className="cardTitleText">{t("refundMethod")}</div>
          <InputText
            value={PAYMENT_METHOD_OBJECT?.[refundSelectPrint?.method] ?? "N/A"}
          />
        </div>
        <div className="col-span-2">
          <div className="cardTitleText">{t("reasonOfRefund")}</div>
          <InputText value={refundSelectPrint?.reason} />
        </div>
      </div>
    );
  };
  const renderSignature = () => {
    return (
      <div className="signaturePrintContainer">
        <div className="signatureContentPrintContainer">
          {refundSelectPrint?.status_by?.signatureUrl ? (
            <img
              src={refundSelectPrint.status_by.signatureUrl}
              className="signaturePrint"
            />
          ) : (
            <div className="signaturePrint" />
          )}
          <div className="dashLine" />
          {/* <div className="subTitle2">Teepakorn Lojanagosin</div> */}
          <div className="subTitle1">{t("groupCEO")}</div>
        </div>
      </div>
    );
  };
  return (
    <div className="print-container">
      {renderHeader()}
      {renderRefundInformation()}
      {renderProductTable()}
      {renderSignature()}
    </div>
  );
};
export default PrintRefundLayout;
