import { Path } from "@components/layout/Path";
import AppContext from "@helpers/context";
import { deleteProduct } from "@network/api/product";
import classNames from "classnames";
import { Button, DialogManager, Progress } from "d-react-components";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";

const ProductDetailHeader = () => {
  const { productDetail } = useContext(AppContext);
  const { name } = productDetail ?? {};
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onDeactivateProduct = () => {
    DialogManager.showConfirm(t("deactivate"), t("areYouSureToDeactivate"), [
      {
        text: t("ok"),
        onClick: () => {
          const body = {
            id: productDetail.id,
          };
          Progress.show({ method: deleteProduct, params: [body] }, () => {
            navigate(Path.PRODUCT);
          });
        },
      },
    ]);
  };

  const onDuplicateProduct = () => {
    navigate(Path.PRODUCT_CREATE, {
      replace: true,
      state: {
        productDefault: {
          ...productDetail,
          sku: "",
          name: `${productDetail?.name}( ${t("duplicate")})`,
          imageurl: [],
        },
      },
    });
  };

  return (
    <div className="card-container p-3 flex-center justify-content-between border-bottom">
      <div className="flex-column">
        <div className="d-flex">
          <h4 className={classNames("text-[14px] text-primary")}>{name}</h4>
        </div>
      </div>
      <div className="flex-row">
        <Button onClick={onDeactivateProduct} className="">
          {t("deactivate")}
        </Button>

        <Button onClick={onDuplicateProduct} className="ml-2">
          {t("duplicate")}
        </Button>
      </div>
    </div>
  );
};

export default ProductDetailHeader;
