import React from "react";
import ConditionApplyType from "./condition/ConditionApplyType";
import ConditionList from "./condition/ConditionList";
import { useTranslation } from "react-i18next";
import { CART_RULE_CONDITION_DEFAULT } from "@constants/cart-rule";

interface ICartRuleConditionForm {
  form: any;
}

const CartRuleConditionForm = ({ form }: ICartRuleConditionForm) => {
  const formValues = form?.values ?? {};

  const { t } = useTranslation();

  const addConditionHandle = (option: any) => {
    const clone: any = [...(formValues.conditions ?? [])];

    clone.push({
      ...(CART_RULE_CONDITION_DEFAULT as any)[option.key],
      id: Math.random(),
    } as any);
    form.setFieldValue("conditions", clone);
  };

  console.log("formValues.conditions", formValues.conditions);
  return (
    <>
      <ConditionApplyType
        value={formValues.condition_rule}
        onChangeApplyType={(value: any) =>
          form.setFieldValue("condition_rule", value)
        }
      />
      <ConditionList
        conditions={formValues.conditions}
        setChangeCondition={(value: any) =>
          form.setFieldValue("conditions", value)
        }
        addConditionHandle={addConditionHandle}
      />
    </>
  );
};

export default CartRuleConditionForm;
