/* eslint-disable no-restricted-syntax */
import InputTextForm from "@common/input/InputTextForm";
import ChannelSelect from "@components/channel/components/ChannelSelect";
import ButtonFileUpload from "@components/shared/ButtonFileUpload";
import Drawer from "@components/shared/Drawer";
import StoreSelect from "@components/store/components/StoreSelect";
import { IMAGE_FORMAT } from "@constants/file";
import { UPDATE_INV_METHOD } from "@constants/inventory";

import {
  addInventoryHistory,
  updateInventory,
  validateImportInventory,
} from "@network/api/inventory";

import { Progress } from "antd";
import {
  Button,
  Icon,
  Notifications,
  ObjectUtils,
  StringUtils,
} from "d-react-components";
import { useFormik } from "formik";
import { every, filter, forEach, includes, isEmpty, map, split } from "lodash";
import { useState } from "react";
import { CSVLink } from "react-csv";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

interface IInventoryImportDrawer {
  open: boolean;
  defaultValue?: any;
  onClose: () => void;
  onImported: any;
}

const InventoryImportDrawer = ({
  open,
  onClose,
  onImported,
}: IInventoryImportDrawer) => {
  const [validateLoading, setValidateLoading] = useState(false);
  const [percentValidation, setPercentValidation] = useState(0);
  const [importSuccess, setImportSuccess] = useState(false);
  const [skuList, setSkuList] = useState<any[]>([]);

  const { t } = useTranslation();

  const importForm = useFormik<any>({
    initialValues: {} as any,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: schema,
    onSubmit: (values: any) => {},
  });

  const formValues = importForm?.values;
  const formErrors = importForm?.errors;

  const validateSkuList = (skuListConverted: any[]) => {
    return every(skuListConverted, (sku) => {
      if (isEmpty(sku.stock_update_quantity)) {
        return false;
      }
      if (isEmpty(sku.sku)) {
        return false;
      }
      if (
        isEmpty(sku.stock_update_method) ||
        (sku.stock_update_method.toLowerCase() !== "add" &&
          sku.stock_update_method.toLowerCase() !== "set")
      ) {
        return false;
      }

      const validStockNumber = sku.stock_update_quantity
        .replace("+", "")
        .replace("-", "");
      if (!StringUtils.isAllDigit(validStockNumber)) {
        return false;
      }
      return true;
    });
  };

  const getValidateInventApi = (skuItemList: any[]) => {
    const config = {
      onUploadProgress: (progressEvent: any) => {
        const percentValidation = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setPercentValidation(percentValidation);
      },
    };

    const body = {
      warehouseid: formValues?.warehouse?.id,
      sku: skuItemList.map((sku) => sku.sku),
    };

    return validateImportInventory(body);
  };

  function convertCsvJSON(csv: any) {
    const separator = includes(csv, ",") ? "," : ";";
    const lines = split(csv, "\n");
    const result = [];
    const headers = split(lines[0], separator);
    for (let i = 1; i < lines.length; i++) {
      let validObject = false;
      // eslint-disable-next-line no-continue
      if (!lines[i]) continue;
      const objectItem: any = {};
      const currentLine = lines[i].split(separator);

      forEach(headers, (header, index) => {
        const currentHeader = header.replace(/\W/g, "");
        const currentLineIndex = currentLine[index];
        let currentValue = currentLine[index];
        if (currentHeader === "stock_update_quantity") {
          currentValue = currentLine[index]?.replace(/\W/g, "");

          if (currentLineIndex.includes("-")) {
            currentValue = `-${currentValue}`;
          }
        }

        if (!isEmpty(currentValue)) {
          validObject = true;
          objectItem[currentHeader] = currentValue;
        }
      });
      if (validObject) {
        result.push(objectItem);
      }
    }
    return result;
  }

  const onDrop = (files: any[]) => {
    const reader = new FileReader();
    reader.readAsText(files[0]);
    reader.onload = function (e) {
      const skuList = convertCsvJSON(reader.result);
      if (!validateSkuList(skuList)) {
        setValidateLoading(false);
        Notifications.showError(t("fileImportIsNotRightFormat"));
        return;
      }

      setValidateLoading(true);

      const mulSkuList = ObjectUtils.sliceArrayToMui(skuList as any);
      const APIList = [];
      for (let index = 0; index < mulSkuList.length; index++) {
        const skuItemList = mulSkuList[index];
        APIList.push(getValidateInventApi(skuItemList));
      }

      Promise.all(APIList)
        .then((res) => {
          let skuRestObject: any = {};
          res.forEach((resItem) => {
            const resSkuItems = resItem?.data?.data?.validate ?? {};
            skuRestObject = {
              ...skuRestObject,
              ...resSkuItems,
            };
          });

          const skuListResult = [];
          for (const key in skuRestObject) {
            const sku = skuRestObject[key];
            const skuFromImport = skuList.find(
              (skuParam) => skuParam.sku === key
            );

            if (Array.isArray(sku) && sku.length === 0) {
              skuListResult.push({
                status: false,
                ...skuFromImport,
                stockUpdateMethod: skuFromImport.stock_update_method,
                stockUpdateQuantity: skuFromImport.stock_update_quantity,
              });
            } else {
              if (!skuFromImport) {
                Notifications.showError("Invalid data");
                return;
              }
              let newStock = 0;
              switch (skuFromImport.stock_update_method) {
                case UPDATE_INV_METHOD.ADD:
                  newStock =
                    parseInt(sku.stock) +
                    parseInt(skuFromImport.stock_update_quantity);
                  break;

                case UPDATE_INV_METHOD.SET:
                  newStock = parseInt(skuFromImport.stock_update_quantity);
                  break;
                default:
              }
              skuListResult.push({
                ...sku,
                status: true,
                stock: newStock,
                stockUpdateMethod: skuFromImport.stock_update_method,
                stockUpdateQuantity: skuFromImport.stock_update_quantity,
              });
            }
          }
          setSkuList(skuListResult);
          setValidateLoading(false);
          setImportSuccess(true);
        })
        .catch((err) => {
          setValidateLoading(false);
        });
    };
  };

  const getSkuUnValidNumber = () => {
    return getSkuUnValid().length;
  };

  const getSkuValidNumber = () => {
    return skuList.length - getSkuUnValidNumber();
  };

  const getSkuUnValid = () => {
    return filter(skuList, (sku) => {
      return !sku.status;
    });
  };
  const getErrorItemsData = () => {
    const skuUnValid = getSkuUnValid();

    const header = [
      t("skuid"),
      t("stockUpdateMethod"),
      t("stockUpdateQuantity"),
    ];
    const dataResult = [header];
    skuUnValid.forEach((sku) => {
      dataResult.push([
        sku?.sku,
        sku.stockUpdateMethod,
        sku.stockUpdateQuantity,
      ]);
    });
    return dataResult;
  };

  const getApiUpdateStockInventory = (skuListImport: any) => {
    const body = {
      warehouseid: formValues?.warehouse?.id,
      imports: map(skuListImport, (sku) => ({
        productid: sku.id,
        stock: sku.stock,
      })),
    };

    return updateInventory(body);
  };

  const onClickSaveProduct = async () => {
    if (!formValues?.warehouse?.id) {
      Notifications.showError(t("selectWarehouseBeforeImport"));
      return Promise.reject();
    }

    if (formValues?.warehouse?.is_stock_adjustment) {
      Notifications.showError(t("canNotImportInventWarehouse"));
      return Promise.reject();
    }

    if (!importSuccess) {
      Notifications.showError(t("attachFileBeforeImport"));
      return Promise.reject();
    }
    if (getSkuUnValidNumber() > 0) {
      Notifications.showError(t("fileIsNotRightFormat"));
      return Promise.reject();
    }
    if (isEmpty(formValues?.memo)) {
      Notifications.showError(t("memoRequire"));
      return Promise.reject();
    }

    const skuListSliced = ObjectUtils.sliceArrayToMui(skuList as any);
    const APIList = skuListSliced.map((skuListItem) =>
      getApiUpdateStockInventory(skuListItem)
    );
    return Promise.all(APIList).then((resList) => {
      let historyIds: any[] = [];
      resList.forEach((res) => {
        const history = res?.data?.data?.inventory_history_id ?? [];
        historyIds = historyIds.concat(history);
      });
      const bodyHistory = {
        inventory_history_id: historyIds,
        memo: formValues?.memo,
        attachment: map(formValues?.memoAttach, (item) => item.key),
      };
      addInventoryHistory(bodyHistory).then((res) => {
        onClose();
        setImportSuccess(false);
        Notifications.showSuccess(t("importInventorySuccess"));
        onImported && onImported();
      });
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const renderButtonAttachFile = () => {
    if (formValues?.warehouse?.is_stock_adjustment) {
      return (
        <div className="my-3 small">
          {"*" + t("canNotImportInventWarehouse")}
        </div>
      );
    }

    if (validateLoading) {
      return <Progress percent={percentValidation} className="my-3" />;
    }

    return (
      <Button iconName="attachment" className="my-3" {...getRootProps()}>
        <div>
          <input
            {...getInputProps()}
            type="file"
            accept=".csv, text/csv"
            multiple={false}
          />
          <text>{t("attachCSV")}</text>
        </div>
      </Button>
    );
  };

  const contentImport = () => {
    return (
      <div className="w-100">
        <div>{t("uploadAllRawMaterialInventory")}</div>
        <div>
          {t("requiredField")} : sku, stock_update_method, stock_update_quantity
        </div>
        <div className="grid grid-cols-1 mt-3">
          <ChannelSelect
            className="col-span-1"
            onChange={(channel) =>
              importForm.setValues({ ...formValues, channel, warehouse: null })
            }
            value={formValues?.channel}
          />
          <StoreSelect
            className="col-span-1 mt-3"
            onChange={(warehouse: any) =>
              importForm.setFieldValue("warehouse", warehouse)
            }
            value={formValues?.warehouse}
            multiple={false}
          />
        </div>
        {renderButtonAttachFile()}

        <div>{t("notSureHowToStart")}</div>
        <div>- {t("downloadAsvFile")}</div>
        <div>
          - {t("download")}
          <a
            target="_blank"
            href={
              "https://onlinesale.lotusbeddinggroup.com/storage/exemple/import-stock-template.csv"
            }
            rel="noreferrer"
          >
            {t("blankCSVTemplate")}
          </a>
        </div>
      </div>
    );
  };

  const contentImportSuccess = () => {
    return (
      <div>
        <Progress percent={100} />
        <div className="d-flex mt-3">
          <Icon name="check_circle" color="green" className="mr-2" />
          {getSkuValidNumber()} {t("itemValidToImport")}
        </div>

        <div className="d-flex">
          <Icon name="warning" color="red" className="mr-2" />
          {getSkuUnValidNumber()} {t("itemHaveErrorImport")}
          <CSVLink data={getErrorItemsData()}>
            <div className="text-primary ml-1">{t("downloadLog")}</div>
          </CSVLink>
        </div>

        <InputTextForm
          multiple
          keyData="memo"
          className="w-100 mt-3"
          form={importForm}
        />
        <ButtonFileUpload
          containerClassName="mt-3"
          onChange={(attachment: any) =>
            importForm.setFieldValue("memoAttach", attachment)
          }
          inputParam={{ accept: IMAGE_FORMAT }}
          maxFiles={5}
        />
      </div>
    );
  };
  return (
    <Drawer
      title={t("bulkImportItems")}
      open={open}
      onClose={() => {
        setImportSuccess(false);
        onClose();
      }}
      saveText={t("import")}
      onSave={onClickSaveProduct}
      size="auto"
      width={"30%"}
      disableSave={getSkuUnValidNumber() > 0}
    >
      {importSuccess ? contentImportSuccess() : contentImport()}
    </Drawer>
  );
};
export default InventoryImportDrawer;
