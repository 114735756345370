import { Path } from "@components/layout/Path";
import ProductSearchButton from "@components/product/components/ProductSearchButton";
import UserAvatarName from "@components/shared/UserAvatarName";
import { ORDER_PAYMENT_STATUS, ORDER_STATUS_ARRAY } from "@constants/order";
import AppContext from "@helpers/context";
import { searchProductInPromotion } from "@network/api/product";
import {
  AwesomeTableComponent,
  Button,
  DialogManager,
  Icon,
  Notifications,
  StringUtils,
  TimeUtils,
  ViewLabelStatus,
} from "d-react-components";
import { filter, map, sortBy } from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, generatePath } from "react-router-dom";
import CashTransactionSelectDrawer from "../component/CashTransactionSelectDrawer";
import { mapObjectToArray } from "@helpers";

const CashDepositCreateTransaction = ({
  isEdit = false,
}: {
  isEdit?: boolean;
}) => {
  const { t } = useTranslation();
  const { cashDepositForm } = useContext(AppContext);
  const { values, setFieldValue } = cashDepositForm;

  const transactionTable = useRef<any>(null);
  const [openAddTransaction, setOpenAddTransaction] = useState(false);

  useEffect(() => {
    transactionTable.current.refreshData();
  }, [values?.transactions]);

  const onRemoveItem = (transaction: any) => {
    const results = filter(
      values?.transactions,
      (item: any) => item.id !== transaction.id
    );
    setFieldValue("transactions", results);
  };

  const onRemoveAllItems = () => {
    setFieldValue("transactions", []);
  };

  const onClickRemoveAll = () => {
    DialogManager.showConfirm(t("confirm"), t("sureToDeleteItem"), () => {
      onRemoveAllItems();
    });
  };

  const columns: any[] = [
    {
      title: t("paymentRef"),
      dataIndex: "code",
      render: (code: string, item: any) => {
        return (
          <Link to={generatePath(Path.ORDER_DETAIL, { id: item?.order?.id })}>
            {code}
          </Link>
        );
      },
    },
    {
      title: t("amount"),
      dataIndex: "total",
      render: StringUtils.moneyThaiFormat,
    },
    {
      title: t("status"),
      dataIndex: "status",
      render: (status: any) => {
        const ORDER_PAYMENT_STATUS_LIST =
          mapObjectToArray(ORDER_PAYMENT_STATUS);

        return (
          <ViewLabelStatus
            status={status}
            listStatus={ORDER_PAYMENT_STATUS_LIST}
            getLabel={(item) => t(item.label)}
          />
        );
      },
    },
    {
      title: t("createdBy"),
      dataIndex: "by",
      render: (by: any) => <UserAvatarName user={by} />,
    },
    {
      title: t("createdAt"),
      dataIndex: "created",
      render: TimeUtils.toDateTime,
    },
    {
      title: t("orderRef"),
      dataIndex: "order",
      render: (order: any) => {
        return (
          <Link to={generatePath(Path.ORDER_DETAIL, { id: order?.id })}>
            {order?.orderid}
          </Link>
        );
      },
    },
    {
      title: t("orderAmount"),
      dataIndex: "order",
      render: (order: any) => StringUtils.moneyThaiFormat(order?.total),
    },
    {
      title: t("orderStatus"),
      dataIndex: "order",
      render: (order: any) => {
        return (
          <ViewLabelStatus
            status={order?.status}
            listStatus={ORDER_STATUS_ARRAY}
            getLabel={(item) => t(item.label)}
          />
        );
      },
    },
    {
      title: t("orderCreatedBy"),
      dataIndex: "order",
      render: (order: any) => <UserAvatarName user={order?.by} />,
    },
    {
      title: t("orderCreatedAt"),
      dataIndex: "order",
      render: (order: any) => TimeUtils.toDateTime(order?.created),
    },
    {
      title: t("action"),
      dataIndex: "",
      render: (item: any) => (
        <Icon
          name="delete"
          onClick={() => onRemoveItem(item)}
          className="cursor-pointer"
        />
      ),
    },
  ];

  const transformer = (respone: any) => respone;

  return (
    <div className={isEdit ? "" : "bg-white p-3"}>
      <div className="flex items-center">
        <h3 className="text-lg font-semibold">{t("transactions")}</h3>
        <div className="flex flex-1 items-end justify-end">
          <Button
            variant="outline"
            className="ml-2"
            onClick={() => onClickRemoveAll()}
          >
            {t("reset")}
          </Button>

          <Button className="ml-2" onClick={() => setOpenAddTransaction(true)}>
            {t("addTransaction")}
          </Button>
        </div>
      </div>
      <div className="mt-3">
        <AwesomeTableComponent
          ref={(ref) => {
            if (transactionTable) {
              transactionTable.current = ref;
            }
          }}
          dataSource={values?.transactions ?? []}
          transformer={transformer}
          columns={columns}
          rowKey={(item) => item.id}
        />
      </div>
      {openAddTransaction && (
        <CashTransactionSelectDrawer
          open={openAddTransaction}
          onClose={() => setOpenAddTransaction(false)}
          chanel={values?.channel}
          store={values?.store}
          onSave={(values: any) => {
            setFieldValue("transactions", values);
            setOpenAddTransaction(false);
          }}
        />
      )}
    </div>
  );
};

export default CashDepositCreateTransaction;
