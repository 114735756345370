import Drawer from "@components/shared/Drawer";
import MyAssetsAPI from "@network/api/my-assets";
import UserAPI from "@network/api/user";
import {
  AwesomeTableComponent,
  Button,
  InputTextSearch,
  Modal,
  Progress,
} from "d-react-components";
import {
  debounce,
  filter,
  includes,
  isEmpty,
  map,
  toLower,
  toUpper,
} from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

function UserDetailChannelRespectiveSelect({
  open,
  onClose,
  title,
  action,
  userId,
  onSave,
  warehouseIds,
  channelRespectiveIds,
}: any) {
  const tableRef = useRef<any>();
  const { t } = useTranslation();
  const [channelSelecting, setChannelSelecting] = useState<any>([]);
  const [allChannelRespective, setAllChannelRespective] = useState([]);
  const [textSearch, setTextSearch] = useState("");
  useEffect(() => {
    loadAllChannelRespective();
  }, []);

  const loadAllChannelRespective = () => {
    if (!channelRespectiveIds.length) {
      onSave([]);
      return;
    }
    const body = {
      id: userId,
      action,
      warehouse: warehouseIds,
      channel: channelRespectiveIds,
    };
    Progress.show(
      { method: MyAssetsAPI.checkAssetChannelByWarehouse, params: [body] },
      (res: any) => {
        const channels = res?.data?.data?.channel ?? [];
        if (!channels.length) {
          onSave([]);
          return;
        }
        setChannelSelecting(channels);
        setAllChannelRespective(channels);
      }
    );
  };

  const columns = [
    {
      title: t("name"),
      dataIndex: "name",
      render: (name: any, channel: any) => (
        <a href={channel?.editurl} target="_blank">
          {name}
        </a>
      ),
    },
    {
      title: t("code"),
      dataIndex: "code",
    },
  ];

  const onChangeText = debounce((text) => {
    setTextSearch(text);
  }, 500);

  const channelIdSelected = map(channelSelecting, (item) => item.id);

  const channelRespectiveSource = useMemo(() => {
    if (isEmpty(textSearch)) return allChannelRespective;
    return filter(allChannelRespective, (item: any) =>
      includes(toLower(item.name), toLower(textSearch))
    );
  }, [allChannelRespective, textSearch]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={title}
      onSave={() => onSave(channelSelecting)}
      disableSave={channelIdSelected?.length === 0}
      size="auto"
      width={600}
      customSideButton={
        <Button variant="outline" className="mr-2" onClick={() => onSave([])}>
          {t("skipAndSave")}
        </Button>
      }
      showCancel={false}
    >
      <div>
        <text>
          {t("youJustSelectedAddTheseChannel")
            .replace("$numberOfWarehouse", warehouseIds?.length)
            .replace("$action", toUpper(action))}
        </text>
        <InputTextSearch
          onChange={(event: any) => onChangeText(event.target.value)}
          className="mt-3"
        />
        <AwesomeTableComponent
          ref={(ref) => {
            tableRef.current = ref;
          }}
          columns={columns}
          dataSource={channelRespectiveSource}
          className="mt-3"
          rowSelection={{
            selectedRowKeys: channelIdSelected,
            onChange: (selectedRowKeys, selectedRows) => {
              setChannelSelecting(selectedRows);
            },
            preserveSelectedRowKeys: true,
          }}
          pagination={false}
        />
      </div>
    </Drawer>
  );
}

export default UserDetailChannelRespectiveSelect;
