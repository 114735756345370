/* eslint-disable jsx-a11y/alt-text */

import {
  AwesomeTableComponent,
  HeaderTable,
  IColumnsProps,
  StringUtils,
  useFirstTime,
} from "d-react-components";

import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";

import { Path } from "@components/layout/Path";
import RawMaterialAPI from "@network/api/raw-material";
import { debounce } from "lodash";

const RawMaterialTable = () => {
  const tableRef = useRef<any>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const searchRef = useRef();

  const SEARCH_KEYS = [{ id: "name", label: t("title") }];

  const [openFilter, setOpenFilter] = useState(false);

  const [
    filterBody = {
      searchType: SEARCH_KEYS[0].id,
    },
    setFilterBody,
  ] = useQueryParam<any>("filter", JsonParam);

  const isFirstTime = useFirstTime();

  useEffect(() => {
    if (isFirstTime) return;
    tableRef.current?.refresh();
  }, [JSON.stringify(filterBody)]);

  const source = (paging: any) => {
    let body = {};
    if (filterBody) {
      const { textSearch, searchType } = filterBody;
      body = {
        search_type: searchType,
        search_value: textSearch,
      };
    }

    return RawMaterialAPI.list(body, paging);
  };

  const columns: IColumnsProps = [
    {
      title: t("thumbnail"),
      dataIndex: "media",
      render: (media, item) => (
        <img
          src={media?.[0]?.url ?? "/images/img-default.png"}
          className="image-reg-medium"
        />
      ),
    },
    {
      title: t("name"),
      dataIndex: "title",
      render: (title, item) => {
        return (
          <Link
            to={generatePath(Path.RAW_MATERIAL_DETAIL, {
              rawMaterialId: item?.id,
            })}
          >
            {title}
          </Link>
        );
      },
    },
    {
      title: t("rmSku"),
      dataIndex: "sku",
    },

    {
      title: t("category"),
      dataIndex: "categories",
      render: (categories) => categories?.name ?? "N/A",
    },
    {
      title: t("unit"),
      dataIndex: "uom",
      render: (uom) => uom?.name,
    },
    {
      title: t("priceUnit"),
      dataIndex: "price",
      render: StringUtils.moneyThaiFormat,
    },
    {
      title: t("supplier"),
      dataIndex: "supplier",
      render: (supplier) => supplier?.name ?? "N/A",
    },
  ];

  const onChangeSearch = debounce((text) => {
    searchRef.current = text;
    tableRef.current.refresh();
  }, 1000);

  return (
    <div className="bg-white">
      <HeaderTable
        label={t("rawMaterial")}
        onChangeText={(event: any) => onChangeSearch(event.target.value)}
        onClickFilter={() => setOpenFilter(true)}
        className="app-layout__page-header"
      />
      <AwesomeTableComponent
        ref={(ref) => {
          tableRef.current = ref;
        }}
        columns={columns}
        source={source}
        transformer={(res) => {
          return res?.data?.data?.material ?? [];
        }}
      />
    </div>
  );
};

export default RawMaterialTable;
