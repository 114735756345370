import AppContext from "@helpers/context";
import {
  Button,
  IRowsKey,
  TimeUtils,
  ViewRowInterchange,
} from "d-react-components";
import moment from "moment";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import GPEventDetailGeneralEditDrawer from "./GPEventDetailGeneralEditDrawer";

const GPEventDetailGeneral = () => {
  const { eventDetail, loadEventDetail } = useContext(AppContext);
  const [openEdit, setOpenEdit] = useState(false);
  const { t } = useTranslation();

  const DETAILS_KEY = useMemo<IRowsKey<any>[]>(
    () => [
      {
        id: "title",
        label: t("title"),
      },

      {
        id: "chain",
        label: t("chain"),
        renderContent: ({ data, item }) => data?.name ?? "N/A",
      },
      {
        id: "code",
        label: t("code"),
      },
      {
        id: "visibility",
        label: t("visibility"),
        renderContent: ({ data, item }) =>
          data ? t("enabled") : t("disabled"),
      },
      {
        id: "from",
        label: t("from"),
        renderContent: ({ data, item }) => TimeUtils.toDateTime(data),
      },
      {
        id: "to",
        label: t("to"),
        renderContent: ({ data, item }) => TimeUtils.toDateTime(data),
      },
      {
        id: "remark",
        label: t("remark"),
      },
    ],
    [t]
  );

  return (
    <div className="card-container p-4 ">
      <div className="d-flex justify-content-between align-items-center">
        <label>{t("generalInformation")}</label>
        <Button onClick={() => setOpenEdit(true)} variant="trans">
          {t("edit")}
        </Button>
      </div>

      <ViewRowInterchange
        dataSource={eventDetail}
        keyList={DETAILS_KEY as any}
        variant="border"
        classNameLabel="font-weight-bold "
        classNameContent="text-end "
      />
      {openEdit && (
        <GPEventDetailGeneralEditDrawer
          open={openEdit}
          onClose={() => setOpenEdit(false)}
          defaultValue={{
            ...(eventDetail ?? {}),
            period: [moment(eventDetail?.from), moment(eventDetail?.to)],
            chain: eventDetail?.chain?.id,
          }}
          onUpdateSuccess={loadEventDetail}
        />
      )}
    </div>
  );
};

export default GPEventDetailGeneral;
