import Drawer from "@components/shared/Drawer";
import UserAvatarName from "@components/shared/UserAvatarName";
import { DISCOUNT_STAFF_STATUS_LIST } from "@constants/discount";
import DiscountStaffAPI from "@network/api/discount-staff";
import {
  AwesomeTableComponent,
  IColumnsProps,
  StringUtils,
  TimeUtils,
  ViewLabelStatus,
} from "d-react-components";
import { useTranslation } from "react-i18next";

const DiscountStaffHistoryDrawer = ({ open, onClose, discount }: any) => {
  const { t } = useTranslation();

  const columns: IColumnsProps = [
    {
      title: t("updatedDate"),
      dataIndex: "updated",
      render: TimeUtils.toDateTime,
    },
    {
      title: t("employeeID"),
      dataIndex: "staff",
      render: (staff) => staff?.companyId,
      width: 150,
    },
    {
      title: t("store"),
      dataIndex: "store",
      render: (store) => store?.name,
    },
    {
      title: t("discountRatePercentage"),
      dataIndex: "percentage",
      width: 150,
    },
    {
      title: t("discountRateBaht"),
      dataIndex: "amount",
      render: StringUtils.moneyThaiFormat,
    },
    {
      title: t("status"),
      dataIndex: "status",
      render: (status) => {
        return (
          <ViewLabelStatus
            status={status}
            listStatus={DISCOUNT_STAFF_STATUS_LIST}
            getLabel={(item) => t(item.label)}
          />
        );
      },
    },
    {
      title: t("assignedBy"),
      dataIndex: "assign",
      render: (assign) => {
        return <UserAvatarName user={assign} />;
      },
    },
  ];

  const source = (paging: any) => {
    const body = {
      store: discount?.store?.id,
      staff: discount?.staff?.id,
    };
    return DiscountStaffAPI.discountStaffHistory(body, paging?.pageIndex);
  };

  const transformer = (res: any) => {
    return res?.data?.data?.history ?? [];
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("history")}
      size="auto"
      width={"60vw"}
    >
      <AwesomeTableComponent
        columns={columns}
        source={source}
        transformer={transformer}
        className=""
      />
    </Drawer>
  );
};

export default DiscountStaffHistoryDrawer;
