export enum CART_RULE_TYPE {
  NO_COUPON = "no_coupon",
  SPECIFIC_COUPON = "specific_coupon",
  AUTO_GENERATE_COUPON = "auto_generated_coupons",
}
export const CART_RULE_TYPES = [
  { id: "no_coupon", label: "noCoupon" },
  { id: "specific_coupon", label: "specificCoupon" },
  { id: "auto_generated_coupons", label: "autoGeneratedCoupon" },
];

export enum CART_RULE_ACTION {
  FREE_GIFT = "free_gift",
  BUY_X_GET_BUNDLE = "buy_x_get_bundle",
  BUY_X_GET_X = "buy_x_get_x",
  DISCOUNT_PERCENTAGE = "discount_percentage",
  DISCOUNT_FIX_AMOUNT = "discount_fix_amount",
  DISCOUNT_PERCENTAGE_CART = "discount_percentage_cart",
  DISCOUNT_FIX_AMOUNT_CART = "discount_fix_amount_cart",

  BUY_X_GET_DISCOUNT_PERCENTAGE = "buy_x_get_discount_percentage",
  BUY_X_GET_DISCOUNT_FIX_AMOUNT = "buy_x_get_discount_fix_amount",
  BUY_X_GET_DISCOUNT_PERCENTAGE_Y = "buy_x_get_discount_percentage_y",
  BUY_X_GET_DISCOUNT_FIX_AMOUNT_Y = "buy_x_get_discount_fix_amount_y",
}

export const CART_RULE_ACTIONS = [
  { id: CART_RULE_ACTION.FREE_GIFT, label: "buyBundleGetBundleFree" },
  {
    id: CART_RULE_ACTION.BUY_X_GET_BUNDLE,
    label: "buyXGetBundleFree",
  },
  {
    id: CART_RULE_ACTION.DISCOUNT_PERCENTAGE,
    label: "applyDiscountByPercentage",
  },
  { id: CART_RULE_ACTION.BUY_X_GET_X, label: "buyXGetX" },
  {
    id: "discount_fix_amount",
    label: "applyDiscountByFixAmount",
  },
  {
    id: "discount_percentage_cart",
    label: "discountPercentageCart",
  },
  {
    id: "discount_fix_amount_cart",
    label: "discountFixAmountCart",
  },

  {
    id: "buy_x_get_discount_percentage",
    label: "buyNXGetDiscountPercentage",
  },
  {
    id: "buy_x_get_discount_fix_amount",
    label: "buyNXGetDiscountFixAmount",
  },
  {
    id: "buy_x_get_discount_percentage_y",
    label: "buyNXGetDiscountPercentageOnY",
  },
  {
    id: "buy_x_get_discount_fix_amount_y",
    label: "buyNXGetDiscountFixAmountOnY",
  },
];

export const CART_RULE_STATUS_LIST = [
  { id: "active", label: "active", color: "#33B950" },
  { id: "inactive", label: "inactive", color: "#EB5757" },
];

export const CART_RULE_CONDITION_DEFAULT = {
  customer: {
    key: 1,
    condition: "customer",
    label: "customer",
    product: [],
    rule: "is_one_of",
  },
  store: {
    key: 1,
    condition: "store",
    label: "store",
    product: [],
    rule: "is_one_of",
  },
  product: {
    key: 1,
    condition: "product",
    label: "sku",
    product: [],
    rule: "is_one_of",
  },
  category: {
    key: 2,
    condition: "category",
    label: "category",
    rule: "is_one_of",
    category: [],
  },
  brand: {
    key: 3,
    condition: "brand",
    label: "brand",
    rule: "is_one_of",
    brand: [],
  },
  total: {
    key: 4,
    condition: "total",
    label: "total",
    rule: "greater_than",
    total: 0,
  },
  subtotal: {
    key: 5,
    condition: "subtotal",
    label: "subTotalName",
    rule: "greater_than",
    subtotal: 0,
  },
  combination: {
    key: 6,
    condition: "combination",
    label: "combination",
    rule: "all",
    conditions: [],
  },
};

export const CART_RULE_CONDITION_OPTION = {
  product: { key: "product", label: "sku" },
  category: { key: "category", label: "category" },
  brand: { key: "brand", label: "brand" },
  customer: { key: "customer", label: "customer" },
  store: { key: "store", label: "store" },
  total: { key: "total", label: "total" },
  subtotal: { key: "subtotal", label: "subTotalName" },
  combination: { key: "combination", label: "combination" },
};

export const CART_RULE_ACTION_CONDITION_OPTION = {
  product: { key: "product", label: "sku" },
  category: { key: "category", label: "category" },
  brand: { key: "brand", label: "brand" },
  combination: { key: "combination", label: "combination" },
};

export const ONE_OF_CONDITION_TYPES = {
  is_one_of: { id: "is_one_of", label: "isoneof" },
  is_not_one_of: { id: "is_not_one_of", label: "isnotoneof" },
};

export const CONDITION_RULE_CHECK = {
  is: { id: "is", label: "is" },
  is_not: { id: "is_not", label: "isnot" },
  is_one_of: { id: "is_one_of", label: "isoneof" },
  is_not_one_of: { id: "is_not_one_of", label: "isnotoneof" },
};

export const CONDITION_RULE_COMPARE = {
  less_than: { id: "less_than", label: "lessthan" },
  greater_than: { id: "greater_than", label: "greaterthan" },
  equals_or_less_than: {
    id: "equals_or_less_than",
    label: "equalorlessthan",
  },
  equals_or_greater_than: {
    id: "equals_or_greater_than",
    label: "equalorgreaterthan",
  },
};

export const CART_RULE_USAGE_STATUS_LIST = [
  { id: "scheduled", label: "scheduled", color: "#FAC256" },
  { id: "in_use", label: "onGoing", color: "#33B950" },
  { id: "expired", label: "expired", color: "#EB5757" },
];

export const CART_RULE_USAGE_STATUS_FILTER_LIST = [
  { id: "all", label: "all", color: "#C4C4C4" },
  ...CART_RULE_USAGE_STATUS_LIST,
];

export const CART_RULE_CONFIGURATION_QUANTITY_TYPES = [
  { id: "single", label: "single" },
  { id: "multiple", label: "multiple" },
];
