export const WAREHOUSE_STATUSES = [
  { id: "active", label: "active", color: "#33B950" },
  { id: "in-active", label: "inactive", color: "#C4C4C4" },
];

export const WAREHOUSE_GENERAL_KEYS = [
  { keyClient: "name", keyServer: "name" },
  { keyClient: "code", keyServer: "code" },
  {
    keyClient: "pickupCode",
    keyServer: "pickup_code",
  },
  { keyClient: "brandIdTax", keyServer: "branch_tax_id" },
  { keyClient: "phone", keyServer: "phone" },
  { keyClient: "isStore", keyServer: "is_store" },
  {
    keyClient: "storeType",
    keyServer: "store_type",
  },
  {
    keyClient: "stockAdjust",
    keyServer: "is_stock_adjustment",
  },
  {
    keyClient: "status",
    keyServer: "status",
  },
  {
    keyClient: "chain",
    keyServer: "chain_id",
  },
  {
    keyClient: "isWarehouseDelivery",
    keyServer: "is_warehouse_delivery",
  },
  {
    keyClient: "nav",
    keyServer: "nav",
  },
];

export const WAREHOUSE_ADDRESS_KEYS = [
  { keyClient: "address", keyServer: "address" },
  { keyClient: "province", keyServer: "province" },
  { keyClient: "district", keyServer: "city" },
  {
    keyClient: "subDistrict",
    keyServer: "subdistrict",
  },
  { keyClient: "postCode", keyServer: "postcode" },
  { keyClient: "latitude", keyServer: "latitude" },
  { keyClient: "longitude", keyServer: "longitude" },
];

export const IMPORT_WAREHOUSE_STEPS = [
  {
    id: "selectImportMethod",
    title: "selectImportMethod",
    desc: "importNewWarehouseDesc",
  },
  { id: "selectCSVFile", title: "selectCSVFile" },
  { id: "validation", title: "fileValidationAndImport" },
  { id: "import", title: "importResult" },
];

export const IMPORT_WAREHOUSE_METHODS = [
  { id: "update", label: "updateExistingWarehouses" },
  { id: "create", label: "addNewWarehouses" },
];

export const WAREHOUSE_USER_KPI_TYPES = [
  { id: "kpi_by_store", label: "userKpiByStore" },
  { id: "kpi_by_brand", label: "userKpiByBrand" },
];

