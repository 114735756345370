import AppContext from "@helpers/context";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

export default function OrderDetailAdsTracking() {
  const { tracking } = useContext(AppContext);
  const { t } = useTranslation();
  if (!tracking) return <div />;

  const { campaign, content, medium, source, term } = tracking || {};
  return (
    <div className="bg-white mb-3 p-3 shadow-sm">
      <label className="block text-sm">{t("adsTracking")}</label>
      <div className="text contentContainer">
        {campaign && <div>{`${t("utmCampaign")}: ${campaign}` ?? ""}</div>}
        {source && <div>{`${t("utmSource")}: ${source}`}</div>}
        {medium && <div>{`${t("utmMedium")}: ${medium}`}</div>}
        {content && <div>{`${t("utmContent")}: ${content}` ?? ""}</div>}
        {term && <div>{`${t("utmTerm")}: ${term}`}</div>}
      </div>
    </div>
  );
}
