import { Path } from "@components/layout/Path";
import UserAvatarName from "@components/shared/UserAvatarName";
import { GP_EVENT_STATUSES } from "@constants/gp-event";
import { getListGpEvent } from "@network/api/gp-event";
import {
  AwesomeTableComponent,
  HeaderTable,
  TimeUtils,
  ViewLabelStatus,
} from "d-react-components";
import { debounce, map } from "lodash";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import GPEventFilterModal from "./GPEventFilterModal";

const GPEventTable = () => {
  const [openFilter, setOpenFilter] = useState(false);
  const [filterBody = {}, setFilterBody] = useQueryParam("filter", JsonParam);
  const tableRef = useRef<any>();
  const searchRef = useRef();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isFiltered = useMemo(
    () => Object.keys(filterBody).length > 0,
    [filterBody]
  );

  const columns = [
    {
      title: t("id"),
      dataIndex: "name",
      render: (name: string, event: any) => (
        <Link to={generatePath(Path.GP_EVENT_DETAIL, { eventId: event.id })}>
          {name}
        </Link>
      ),
    },
    {
      title: t("chain"),
      dataIndex: "chain",
      render: (chain: any) => chain?.name,
    },
    {
      title: t("code"),
      dataIndex: "code",
    },
    {
      title: t("period"),
      dataIndex: "",
      render: (event: any) =>
        `${TimeUtils.toDateTime(event?.from)} - ${TimeUtils.toDateTime(
          event?.to
        )}`,
    },
    {
      title: t("status"),
      dataIndex: "status",
      render: (status: any) => (
        <ViewLabelStatus
          status={status}
          listStatus={GP_EVENT_STATUSES}
          getLabel={(item) => t(item.label)}
        />
      ),
    },
    {
      title: t("createdAt"),
      dataIndex: "created",
      render: TimeUtils.toDateTime,
    },
    {
      title: t("lastUpdated"),
      dataIndex: "updated",
      render: TimeUtils.toDateTime,
    },
    {
      title: t("createdBy"),
      dataIndex: "by",
      render: (by: any) => <UserAvatarName user={by} />,
    },
  ];

  const source = (paging: any) => {
    const { from, to } = filterBody;
    const body = {
      ...filterBody,
      from: from?.valueOf(),
      to: to?.valueOf(),
      search: searchRef.current,
      chains: map(filterBody?.chains, (item) => item.id),
    };
    return getListGpEvent(body, paging);
  };

  const transformer = (res: any) => {
    return res?.data?.data?.event ?? [];
  };

  const onChangeSearch = debounce((text) => {
    searchRef.current = text;
    tableRef.current.refresh();
  }, 1000);

  const onFilter = (values: any) => {
    setFilterBody(values);
    tableRef.current.refresh();
  };

  return (
    <div className="bg-white">
      <HeaderTable
        label={t("gpEvent")}
        onChangeText={(event: any) => onChangeSearch(event.target.value)}
        onClickNew={() => navigate(Path.GP_EVENT_CREATE)}
        onClickFilter={() => setOpenFilter(true)}
        isFiltered={isFiltered}
        className="app-layout__page-header"
      />
      <AwesomeTableComponent
        ref={(ref) => {
          tableRef.current = ref;
        }}
        columns={columns}
        source={source}
        transformer={transformer}
      />
      {openFilter && (
        <GPEventFilterModal
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          onFilter={onFilter}
          defaultValue={filterBody}
        />
      )}
    </div>
  );
};

export default GPEventTable;
