import Drawer from "@components/shared/Drawer";
import AppContext from "@helpers/context";
import ChannelAPI from "@network/api/channel";
import RoleAPI from "@network/api/role";
import {
  AwesomeTableComponent,
  Checkbox,
  InputTextSearch,
  Select,
  TimeUtils,
} from "d-react-components";
import { useFormik } from "formik";
import { debounce, isEmpty, map } from "lodash";
import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

function ChannelDetailUserSelectDrawer({
  open,
  title,
  onClose,
  type,
  onSave,
}: any) {
  const { channel, roleData } = useContext(AppContext);
  const { t } = useTranslation();

  const [userSelecting, setUserSelecting] = useState<any>([]);
  const [roleList, setRoleList] = useState([]);
  const tableRef = useRef<any>();
  const textSearch = useRef<string>("");
  const columns = [
    {
      title: t("name"),
      dataIndex: "fullname",
      render: (name: any, user: any) => (
        <a href={user?.editurl} target="_blank" rel="noreferrer">
          {name}
        </a>
      ),
    },
    {
      title: t("nickName"),
      dataIndex: "name",
    },
    {
      title: t("companyId"),
      dataIndex: "companyId",
      width: 150,
    },
    {
      title: t("lastLogin"),
      dataIndex: "last_login",
      render: TimeUtils.toDateTime,
    },
  ];
  const loadUserForm = useFormik<any>({
    initialValues: {
      has_role: [],
      not_role: [],
      selectAllHasRole: false,
      selectAllNotRole: false,
    },
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values): any => {
      onSave(values?.users);
    },
  });
  const { values: formValue } = loadUserForm;

  React.useEffect(() => {
    loadRoleList();
  }, []);

  const loadRoleList = () => {
    RoleAPI.list().then((res) => {
      const roleList = res?.data?.data?.roles ?? [];
      setRoleList(roleList);
    });
  };

  const source = (paging: any) => {
    const { has_role, not_role } = formValue;
    const roleFilter = {};
    if (!isEmpty(has_role)) {
      Object.assign(roleFilter, { has_role });
    }
    if (!isEmpty(not_role)) {
      Object.assign(roleFilter, { not_role });
    }
    const body = {
      id: channel?.id,
      role: roleData?.selecting?.id,
      search: textSearch.current,
      action: type,
      ...roleFilter,
    };
    return Promise.resolve([])
    // return ChannelAPI.userList(body, paging);
  };

  const transformer = (res: any) => res?.data?.data?.users ?? [];

  const onChangeText = debounce((text) => {
    textSearch.current = text;
    tableRef.current.refresh();
  }, 1000);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={title}
      onSave={() => onSave(userSelecting)}
      size="auto"
      width="30vw"
    >
      <Select
        multiple
        label={t("hasRole")}
        className="mt-3"
        dataSource={roleList}
        getLabel={(item) => item.name}
        value={formValue?.has_role}
        onChange={(value) => {
          loadUserForm.setFieldValue("has_role", value);
          tableRef.current.refresh();
        }}
      />
      <Checkbox
        className="mt-3"
        label={t("selectAll")}
        value={formValue?.selectAllHasRole as any}
        onChange={() => {
          const value = !formValue?.selectAllHasRole;
          if (value) {
            loadUserForm.setValues({
              ...(formValue || {}),
              selectAllHasRole: value,
              has_role: map(roleList, (item: any) => item?.id),
            });
          } else {
            loadUserForm.setValues({
              ...(formValue || {}),
              selectAllHasRole: value,
              has_role: [],
            });
          }
          tableRef.current.refresh();
        }}
      />
      <Select
        multiple
        label={t("notRole")}
        className="mt-3"
        dataSource={roleList}
        getLabel={(item) => item.name}
        value={formValue?.not_role}
        onChange={(value) => {
          loadUserForm.setFieldValue("not_role", value);
          tableRef.current.refresh();
        }}
      />
      <Checkbox
        className="mt-3"
        label={t("selectAll")}
        value={formValue?.selectAllNotRole}
        onChange={() => {
          const value = !formValue?.selectAllNotRole;
          if (value) {
            loadUserForm.setValues({
              ...(formValue || {}),
              selectAllNotRole: value,
              not_role: map(roleList, (item: any) => item?.id),
            });
          } else {
            loadUserForm.setValues({
              ...(formValue || {}),
              selectAllNotRole: value,
              not_role: [],
            });
          }
          tableRef.current.refresh();
        }}
      />
      <label className="mt-3">Search</label>
      <InputTextSearch
        onChange={(event: any) => onChangeText(event.target.value)}
        placeholder={t("search")}
      />
      <AwesomeTableComponent
        ref={(ref) => {
          tableRef.current = ref;
        }}
        columns={columns}
        source={source}
        transformer={transformer}
        className="mt-3"
        rowSelection={{
          selectedRowKeys: map(userSelecting, (item) => item.id),
          onChange: (selectedRowKeys, selectRows) => {
            setUserSelecting(selectRows);
          },
          preserveSelectedRowKeys: true,
        }}
        baseColumnProps={{ with: 100 }}
      />
    </Drawer>
  );
}

export default ChannelDetailUserSelectDrawer;
