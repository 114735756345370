import {
  AwesomeTableComponent,
  Button,
  HeaderTable,
  IColumnsProps,
  Icon,
  TimeUtils,
  ViewLabelStatus,
} from "d-react-components";
import { debounce, find } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { JsonParam, useQueryParam } from "use-query-params";
import UserTableFilterModal from "./UserTableFilterModal";
import UserTableExportModal from "./UserTableExportModal";
import UserAPI from "@network/api/user";
import RoleAPI from "@network/api/role";
import { getWarehouseList } from "@network/api/store";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import { Path } from "@components/layout/Path";
import { POSITION_LABELS } from "@constants/user";
import AppContext from "@helpers/context";
import ActionMenu from "@common/dropdown/ActionButton";
import { Dropdown } from "antd";

const UserTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openFilter, setOpenFilter] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [warehouseList, setWarehouseList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  // const [filterBody, setFilterBody] = useState({});
  const [filterBody = {}, setFilterBody] = useQueryParam(
    "filterBody",
    JsonParam
  );

  const tableRef = useRef<any>();
  const searchRef = useRef<any>();

  useEffect(() => {
    loadWarehouse();
    loadRoleList();
  }, []);

  const isFiltered = useMemo(
    () => Object.keys(filterBody).length > 0,
    [filterBody]
  );

  const loadWarehouse = () => {
    getWarehouseList().then((res) => {
      const warehouseList = res?.data?.data?.warehouse;
      setWarehouseList(warehouseList);
    });
  };

  const loadRoleList = () => {
    RoleAPI.list().then((res) => {
      const roleList = res?.data?.data?.roles ?? [];
      setRoleList(roleList);
    });
  };

  const actions = useMemo(() => {
    return [
      {
        key: "edit",
        label: t("edit"),
      },
    ];
  }, [t]);

  const onHandleAction = (item: any) => {
    if (item.id === "edit") {
      navigate(generatePath(Path.USER_DETAIL, { userId: item.id }));
    }
  };

  const columns: IColumnsProps = [
    {
      title: t("id"),
      dataIndex: "id",
      render: (id, user) => (
        <a href={generatePath(Path.USER_DETAIL, { userId: id })}>
          {id ?? "N/A"}
        </a>
      ),
    },
    {
      title: t("avatar"),
      dataIndex: "avatar",
      width: "80px",
      render: (avatar) => <img src={avatar} className="image-square-small" />,
    },
    {
      title: t("fullName"),
      dataIndex: "fullname",
      render: (fullName, user) => (
        <a href={generatePath(Path.USER_DETAIL, { userId: user.id })}>
          {fullName ?? "N/A"}
        </a>
      ),
    },
    {
      title: t("companyId"),
      dataIndex: "employee_id",
    },
    {
      title: t("positionLabel"),
      dataIndex: "position_label",
      render: (label) => {
        const positionLabel = find(
          POSITION_LABELS,
          (item) => item.id === label
        );
        return positionLabel ? t(positionLabel?.label) : "N/A";
      },
    },
    {
      title: t("authentication"),
      dataIndex: "authentication_status",
      render: (authentication) => (
        <ViewLabelStatus
          content={authentication ? t("enabled") : t("disabled")}
          color={authentication ? "#33B950" : "#A39D9D"}
        />
      ),
    },
    {
      title: t("block"),
      dataIndex: "block",
      render: (block) => (
        <ViewLabelStatus
          content={block ? t("yes") : t("no")}
          color={block ? "#33B950" : "#A39D9D"}
        />
      ),
    },
    {
      title: t("createdAt"),
      dataIndex: "created",
      render: TimeUtils.convertMiliToDateTime,
    },
    {
      title: t("lastUpdated"),
      dataIndex: "updated",
      render: TimeUtils.convertMiliToDateTime,
    },
    {
      title: t("lastLogin"),
      dataIndex: "last_login",
      render: (time) => {
        if (!time) return "N/A";
        return TimeUtils.convertMiliToDateTime(time);
      },
    },
    {
      title: t("action"),
      dataIndex: "",
      render: (item) => (
        <Dropdown
          trigger={["click"]}
          menu={{ items: actions, onClick: onHandleAction }}
        >
          <Button
            variant="trans"
            content={""}
            suffixIcon="more_vert"
            size="small"
            className="ml-2"
          />
        </Dropdown>
      ),
    },
  ];

  const source = (paging: any) => {
    const { channel, storeType, warehouse, role, block, positionLabel } =
      filterBody;
    const body = {
      search: searchRef.current,
      channel: channel?.id,
      store_type: storeType,
      warehouse,
      role,
      block,
      position_label: positionLabel,
    };
    return UserAPI.list(body, paging);
  };

  const transformer = (res: any) => {
    return res?.data?.data?.user ?? [];
  };

  const onChangeSearch = debounce((text) => {
    searchRef.current = text;
    tableRef?.current?.refresh();
  }, 1000);

  const onFilter = (values: any) => {
    setFilterBody(values);
    tableRef?.current?.refresh();
  };

  return (
    <AppContext.Provider value={{ warehouseList, roleList }}>
      <div className="bg-white">
        <HeaderTable
          label={t("allUsers")}
          onChangeText={(event: any) => onChangeSearch(event.target.value)}
          onClickNew={() => navigate(Path.USER_CREATE)}
          onClickFilter={() => setOpenFilter(true)}
          onClickExport={() => setOpenExport(true)}
          isFiltered={isFiltered}
          className="app-layout__page-header"
        />
        <AwesomeTableComponent
          ref={(ref) => {
            tableRef.current = ref;
          }}
          columns={columns}
          source={source}
          transformer={transformer}
        />
        {openFilter && (
          <UserTableFilterModal
            open={openFilter}
            onClose={() => setOpenFilter(false)}
            onFilter={onFilter}
            defaultValue={filterBody}
          />
        )}
        {openExport && (
          <UserTableExportModal
            open={openExport}
            onClose={() => setOpenExport(false)}
          />
        )}
      </div>
    </AppContext.Provider>
  );
};

export default UserTable;
