import { TranslatedSelect } from "@common/TranslatedSelect";
import { IMPORT_WAREHOUSE_METHODS } from "@constants/warehouse";
import AppContext from "@helpers/context";
import { CheckboxGroup, Select } from "d-react-components";
import { map, includes, filter } from "lodash";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

const WarehouseImportSelectMethod = () => {
  const { step, formImport, importFieldSource } = useContext(AppContext);
  const { importType, importFields } = formImport?.values;
  const { t } = useTranslation();

  const onChangeType = (value: any) => {
    formImport.setValues({ ...formImport?.values, importType: value });
  };

  const onChangeImportField = (values = []) => {
    formImport.setFieldValue(
      "importFields",
      filter(
        importFieldSource,
        (item) => includes(values, item.name) || item.required
      )
    );
  };

  return (
    <div className="p-4">
      <text>{t(step?.desc)}</text>
      <TranslatedSelect
        className="mt-3"
        placeholder={t("selectImportTypeHint")}
        dataSource={IMPORT_WAREHOUSE_METHODS}
        value={importType}
        getLabel={(item) => t(item.label)}
        onChange={onChangeType}
      />

      <CheckboxGroup
        label={t("whichElementDoYouWantUpdate")}
        className="mt-3"
        dataSource={importFieldSource}
        value={map(importFields, (item) => item.name)}
        getValue={(item) => item.name}
        onChange={onChangeImportField as any}
        disabled={importType === "create"}
        getDisabledItem={(item) => item.required}
        showSelectAll
      />
    </div>
  );
};

export default WarehouseImportSelectMethod;
