import { InputText } from "d-react-components";
import React from "react";
import ButtonFileUpload from "@components/shared/ButtonFileUpload";
import { useTranslation } from "react-i18next";
import { IMAGE_FORMAT } from "@constants/file";

const MemoForm = ({
  memoContent,
  onChangeContent,
  onChangeAttach,
  disableAttachment,
}: any) => {
  const { t } = useTranslation();
  return (
    <div className="mt-3">
      <InputText
        multiple
        value={memoContent}
        className="w-100 mt-3"
        onChange={(event) => onChangeContent(event.target.value)}
        placeholder={t("pleaseInputTheNoteForThisUpdate")}
        label={t("memo")}
      />
      {!disableAttachment && (
        <div className="mt-3">
          <ButtonFileUpload
            onChange={onChangeAttach}
            inputParam={{ accept: IMAGE_FORMAT }}
            maxFiles={5}
          />
        </div>
      )}
    </div>
  );
};

export default MemoForm;
