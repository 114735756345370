export const TRANSFER_STOCK_TYPES = [
  { id: "request_stock_to_store", label: "requestStockToStore" },
  { id: "request_stock_to_demon", label: "requestStockToDemon" },
  { id: "request_stock_back_store", label: "requestStockBackStore" },
  { id: "request_stock_back_demon", label: "requestStockBackDemon" },
];

export const TRANSFER_STOCK_STATUS_LIST = [
  {
    id: "pending",
    value: "pending",
    label: "pending",
    color: "#FFBD59 ",
  },
  {
    id: "partial",
    color: "#F2994A",
    value: "partial",
    label: "partial",
  },
  {
    id: "processing",
    color: "#84C4FF",
    value: "processing",
    label: "processing",
  },
  {
    id: "transferred",
    color: "#29B3BC",
    value: "transferred",
    label: "transferred",
  },
  {
    id: "completed",
    value: "completed",
    label: "completed",
    color: "#33B950",
  },

  {
    id: "cancelled",
    value: "cancelled",
    label: "cancelled",
    color: "#8D8D8D",
  },
];

export const STOCK_TRANSFER_TYPE = {
  STOCK_IN: "stock-in",
  RETURN_GOODS: "return-goods",
};

export const STOCK_TRANSFER_TYPES = [
  { id: "stock-in", label: "stockIn" },
  { id: "return-goods", label: "returnGood" },
];

export const STOCK_TRANSFER_DELIVERY_TYPES = [
  { id: "in-house-truck", label: "inHouseTruck" },
  { id: "outsourced-truck", label: "outSourceTruck" },
];
