import AppContext from "@helpers/context";
import { IRowsKey, TimeUtils, ViewRowInterchange } from "d-react-components";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

const EventDetailInfo = () => {
  const { eventDetail } = useContext(AppContext);
  const { name, code } = eventDetail ?? {};
  const { t } = useTranslation();

  const DETAILS_KEY: IRowsKey<any>[] = useMemo(
    () => [
      {
        id: "visibility",
        label: t("visibility"),
      },
      {
        id: "code",
        label: t("code"),
      },
      {
        id: "from",
        label: t("from"),
        renderContent: ({ data, item }) => TimeUtils.toDateTime(data),
      },
      {
        id: "to",
        label: t("to"),
        renderContent: ({ data, item }) => TimeUtils.toDateTime(data),
      },
      {
        id: "highest_gp",
        label: t("highestGP"),
        renderContent: ({ data, item }) => `${data ?? "N/A"}%`,
      },
      {
        id: "lowest_gp",
        label: t("lowestGP"),
        renderContent: ({ data, item }) => `${data ?? "N/A"}%`,
      },
    ],
    [t]
  );

  return (
    <div className="card-container p-4 flex-column flex-center pt-5">
      <img src={"/images/icons/event.svg"} className="image-reg-large" />
      <h4 className="mt-3">{name}</h4>
      <small>{code}</small>

      <ViewRowInterchange
        className="w-100"
        dataSource={eventDetail}
        keyList={DETAILS_KEY as any}
        variant="border"
        classNameLabel="font-weight-bold "
        classNameContent="text-end "
      />
    </div>
  );
};

export default EventDetailInfo;
