import AppContext from "@helpers/context";
import { getDeliveryStockDetail } from "@network/api/delivery-stock";
import { Progress, TabBar, TimeUtils } from "d-react-components";
import { map } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import DeliverySODetailActivity from "./DeliverySODetailActivity";
import DeliverySODetailDestination from "./DeliverySODetailDestination";
import DeliverySODetailGeneral from "./DeliverySODetailGeneral";
import DeliverySODetailHeader from "./DeliverySODetailHeader";
import DeliverySODetailInfo from "./DeliverySODetailInfo";
import DeliverySODetailOrigin from "./DeliverySODetailOrigin";
import DeliverySODetailProducts from "./DeliverySODetailProducts";
import DeliverySODetailVehicle from "./DeliverySODetailVehicle";

const TABS = [
  {
    id: "general",
    label: "general",
    component: <DeliverySODetailGeneral />,
  },
  {
    id: "driverVehicle",
    label: "driverVehicle",
    component: <DeliverySODetailVehicle />,
  },
  {
    id: "itemList",
    label: "itemList",
    component: <DeliverySODetailProducts />,
  },
  {
    id: "origin",
    label: "origin",
    component: <DeliverySODetailOrigin />,
  },
  {
    id: "destination",
    label: "destination",
    component: <DeliverySODetailDestination />,
  },

  {
    id: "activity",
    label: "activity",
    component: <DeliverySODetailActivity />,
  },
];

const getDeliveryDriverData = (detail: any = {}) => {
  const { signature_at_warehouse = {}, signature_at_store = {} } = detail;

  //confirmation pick up
  const {
    driver_signature_at_warehouse,
    driver_signature_by_at_warehouse,
    proof = [],
    remark_at_warehouse,
    supervisor_fullname_at_warehouse,
    supervisor_signature_at_warehouse,
    signed_at_warehouse,
    ...restConfirmOrigin
  } = signature_at_warehouse;
  const confirmationOrigin = {
    createdAt: TimeUtils.toDateTime(restConfirmOrigin.created),
    signAt: TimeUtils.toDateTime(signed_at_warehouse),
    signatureDriver: driver_signature_at_warehouse,
    signatureSupervisor: supervisor_signature_at_warehouse,
    driver: driver_signature_by_at_warehouse,
    attachments: map(proof, (item) => item.attachment),
    remark: remark_at_warehouse,
    supervisorName: supervisor_fullname_at_warehouse,
    ...restConfirmOrigin,
  };

  //confirmation received
  const {
    driver_signature_at_store,
    driver_signature_by_at_store,
    remark_at_store,
    supervisor_fullname_at_store,
    supervisor_signature_at_store,
    signed_at_store,
    ...restConfirmDes
  } = signature_at_store;
  const confirmationDesc = {
    createdAt: TimeUtils.toDateTime(restConfirmDes.created),
    signAt: TimeUtils.toDateTime(signed_at_store),
    signatureDriver: driver_signature_at_store,
    signatureSupervisor: supervisor_signature_at_store,
    driver: driver_signature_by_at_store,
    attachments: map(restConfirmDes?.proof, (item) => item.attachment),
    remark: remark_at_store,
    supervisorName: supervisor_fullname_at_store,
    ...restConfirmDes,
  };

  return {
    ...detail,
    confirmationOrigin,
    confirmationDesc,
  };
};

const DeliverySODetail = () => {
  const [deliveryDetail, setDeliveryDetail] = useState(null);

  const [tab, setTab] = useState<any>(TABS[0]);
  const { deliveryId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    loadDeliveryDetail();
  }, []);

  const loadDeliveryDetail = () => {
    Progress.show(
      { method: getDeliveryStockDetail, params: [deliveryId] },
      (res: any) => {
        const delivery = res?.data?.data?.delivery;
        setDeliveryDetail(getDeliveryDriverData(delivery));
      }
    );
  };

  return (
    <AppContext.Provider
      value={{
        deliveryDetail,
        loadDeliveryDetail,
      }}
    >
      <div className="flex-column-container bg-trans">
        <DeliverySODetailHeader />
        <div className="grid p-3 grid-cols-12 gap-4">
          <div className="col-span-4">{<DeliverySODetailInfo />}</div>
          <div className="col-span-8 px-3">
            <TabBar
              dataSource={TABS}
              onChange={setTab}
              value={tab}
              getLabel={(item: any) => t(item.label)}
            />
            <div className="mt-3">{tab.component}</div>
          </div>
        </div>
      </div>
    </AppContext.Provider>
  );
};

export default DeliverySODetail;
