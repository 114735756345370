/* eslint-disable react-hooks/exhaustive-deps */
import { Path } from "@components/layout/Path";
import ProductName from "@components/product/components/ProductName";
import { DEFAULT_PAGING } from "@constants/common";
import { LANGUAGES } from "@constants/locales";
import { PRODUCT_LABEL_PRINT_TYPE } from "@constants/product";
import {
  PRODUCTION_PRINTING_SEARCH_OPTIONS,
  PRODUCTION_PRINTING_SEARCH_TYPE_DEFAULT,
} from "@constants/production-printing";
import AppContext from "@helpers/context";
import { productSizeByCategory } from "@network/api/product";
import ProductLabelAPI from "@network/api/product-label-print";
import {
  getProductionPrintingDetail,
  getProductionPrintingListNew,
  getSerialNumberPrint,
  printProductionPrinting,
} from "@network/api/product-print";
import classNames from "classnames";
import {
  AwesomeTableComponent,
  Button,
  Dropdown,
  HeaderTable,
  Notifications,
  Progress,
  Select,
  TimeUtils,
} from "d-react-components";
import i18next from "i18next";
import { debounce, isArray, isEmpty, map, now, uniq } from "lodash";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import { Link, generatePath } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import ProductionPrintingDoc from "./ProductionPrintingDoc";
import ProductionPrintingHistoryDrawer from "./ProductionPrintingHistoryDrawer";
import ProductionPrintingImportDrawer from "./ProductionPrintingImportDrawer";
import ProductionPrintingFilterDrawer from "./ProductionPrintingFilterDrawer";
import moment from "moment";
import Drawer, { DrawerProps } from "@components/shared/Drawer";
import { TranslatedSelect } from "@common/TranslatedSelect";
const Barcode = require("react-barcode");

enum PRODUCT_PRINT_TYPE {
  OCPB_LABEL = "OCPB_LABEL",
  LABEL = "LABEL",
  TAG = "TAG",
}

const PRODUCT_PRINT_TYPES = [
  {
    id: PRODUCT_PRINT_TYPE.OCPB_LABEL,
    label: "ocpbLabel",
  },
  {
    id: PRODUCT_PRINT_TYPE.LABEL,
    label: "standardLabel",
  },
  {
    id: PRODUCT_PRINT_TYPE.TAG,
    label: "tag",
  },
];

const ProductLabelPrintView = ({
  printLang,
  printData = {},
  customSizeInfo = "",
}: any) => {
  const product = printData?.products;
  const fontStyle = printLang === "en" ? "font-family-en" : "font-family-th";
  const th = i18next.getFixedT("th");
  const en = i18next.getFixedT("en");

  const getProductName = () => {
    if (isEmpty(customSizeInfo)) return product?.name?.[printLang];
    return `${product?.name?.[printLang]} - ${customSizeInfo}`;
  };

  const rowData = (title: any, content: any) => {
    return (
      <div className="label-print__row-data">
        <h4 className="font-weight-bold text-uppercase">{title}: </h4>
        <h4 className="font-weight-light ml-2">{content}</h4>
      </div>
    );
  };

  const rowDataSubContent = (title: any, content: any, className = "") => (
    <div className={classNames("d-flex", className)}>
      <h5 className="font-weight-bold text-uppercase">{title} : </h5>
      <h5 className="font-weight-light ml-1">{content}</h5>
    </div>
  );

  const rowSmallSize = (title: any, content: any) => (
    <div className="label-print__row-data-small">
      <text className={`font-weight-bold ${fontStyle}`}>
        {title} : <text className={`ml-1 ${fontStyle}`}>{content}</text>
      </text>
    </div>
  );

  const renderProductDefault = () => {
    return (
      <div className="label-print__info-main" style={{ paddingTop: "40px" }}>
        {/* <div className="label-print__info-product-name">
          <div className="mt-2">{product?.name?.en}</div>
          <div className="mt-2">{product?.name?.th}</div>
        </div> */}
        <div className="label-print__info-product-name">
          <div className={`mt-2 ${fontStyle}`}>{getProductName()}</div>
        </div>

        <div className="d-flex flex-column pt-4 label-print__content">
          {rowData("Model", product?.model?.name ?? "N/A")}
          {/* {map(product?.attribute, (attrItem) => rowData(attrItem?.name, attrItem?.value))} */}
          {product?.size?.has_print &&
            rowData("Size", product?.size?.display_name?.[printLang] ?? "N/A")}
          {rowData("Sku", product?.sku)}
          <div
            hidden={isEmpty(printData?.nav_rp) && isEmpty(printData?.nav_so)}
            className="label-print__sub-content"
          >
            {printData?.nav_so &&
              rowDataSubContent("SO No.", printData?.nav_so)}
            {printData?.nav_rp &&
              rowDataSubContent("NAV RP No.", printData?.nav_rp)}
          </div>
        </div>
      </div>
    );
  };

  const renderDataRowLang = (label: any, value: any) => {
    return (
      <div className="d-flex flex-column">
        {printLang === "th" && value?.th && rowSmallSize(th(label), value?.th)}
        {printLang === "en" && value?.en && rowSmallSize(en(label), value?.en)}
      </div>
    );
  };

  const renderProductTopper = () => {
    const { information } = printData;
    const options: any = { year: "numeric", month: "long", day: "numeric" };
    const today = new Date();

    const manufacturedName = information?.manufactured?.display_name;
    const manufacturedAddress = information?.manufactured?.address;
    const distributedName = information?.distributed?.display_name;
    const distributedAddress = information?.distributed?.address;
    return (
      <div className="label-print__info-main">
        <div className="label-print-full__info-product-name">
          <div className={`mt-2 ${fontStyle}`}>{getProductName()}</div>
        </div>

        <div className="d-flex flex-column pt-4">
          {renderDataRowLang("productName", product?.name)}
          {renderDataRowLang("brand", product?.brand?.display_name)}
          {renderDataRowLang("typeProduct", product?.category?.display_name)}
          {renderDataRowLang("color", information?.color)}
          {renderDataRowLang("height", {
            en: information?.height
              ? `${information?.height} ${
                  information?.height_unit?.en ?? "N/A"
                }`
              : null,
            th: information?.height
              ? `${information?.height} ${
                  information?.height_unit?.th ?? "N/A"
                }`
              : null,
          })}
          {renderDataRowLang("material", information?.material)}
          {renderDataRowLang("cover", information?.cover)}
          {renderDataRowLang("usage", information?.usage)}
          {renderDataRowLang("careInstruction", information?.care_instruction)}
          {renderDataRowLang("recommendation", information?.recommendation)}
          {renderDataRowLang("warning", information?.warning)}
          {renderDataRowLang("countryOfManu", information?.country?.name)}
          {renderDataRowLang("manuBy", {
            en: manufacturedName
              ? `${manufacturedName?.en} - ${manufacturedAddress?.en ?? "N/A"}`
              : null,
            th: manufacturedName
              ? `${manufacturedName?.th} - ${manufacturedAddress?.th ?? "N/A"}`
              : null,
          })}
          {renderDataRowLang("distributedBy", {
            en: distributedName?.en
              ? `${distributedName?.en} - ${distributedAddress?.en ?? "N/A"}`
              : null,
            th: distributedName?.th
              ? `${distributedName?.th} - ${distributedAddress?.th ?? "N/A"}`
              : null,
          })}
          {renderDataRowLang("manufacturingDate", {
            en: TimeUtils.convertMiliToDate(now()),
            th: today.toLocaleDateString("th-TH", options),
          })}
          {renderDataRowLang("salePriceBath", {
            en: "Refer to point of sale.",
            th: "ระบุ ณ จุดขาย",
          })}
          {printData?.nav_rp &&
            renderDataRowLang("navRPNo", {
              en: printData?.nav_rp,
              th: printData?.nav_rp,
            })}
        </div>
      </div>
    );
  };

  const renderProductBlanket = () => {
    const { information } = printData;
    const options: any = { year: "numeric", month: "long", day: "numeric" };
    const today = new Date();

    const manufacturedName = information?.manufactured?.display_name;
    const manufacturedAddress = information?.manufactured?.address;
    const distributedName = information?.distributed?.display_name;
    const distributedAddress = information?.distributed?.address;
    return (
      <div className="label-print__info-main">
        <div className="label-print-full__info-product-name">
          <div className={`mt-2 ${fontStyle}`}>{getProductName()}</div>
        </div>

        <div className="d-flex flex-column pt-4">
          {renderDataRowLang("productName", product?.name)}
          {renderDataRowLang("brand", product?.brand?.display_name)}
          {renderDataRowLang("typeProduct", product?.category?.display_name)}
          {renderDataRowLang("color", information?.color)}
          {renderDataRowLang("size", {
            en: information?.size
              ? `${information?.size} ${information?.size_unit?.en ?? "N/A"}`
              : null,
            th: information?.size
              ? `${information?.size} ${information?.size_unit?.th ?? "N/A"}`
              : null,
          })}
          {renderDataRowLang("quantity", {
            en: information?.quantity
              ? `${information?.quantity} ${
                  information?.unit_of_quantity?.en ?? "N/A"
                }`
              : null,
            th: information?.quantity
              ? `${information?.quantity} ${
                  information?.unit_of_quantity?.th ?? "N/A"
                }`
              : null,
          })}
          {renderDataRowLang("collection", information?.collection)}
          {renderDataRowLang("material", information?.material)}
          {renderDataRowLang("usage", information?.usage)}
          {renderDataRowLang("careInstruction", information?.care_instruction)}
          {renderDataRowLang("recommendation", information?.recommendation)}
          {renderDataRowLang("warning", information?.warning)}
          {renderDataRowLang("countryOfManu", information?.country?.name)}
          {renderDataRowLang("manuBy", {
            en: manufacturedName
              ? `${manufacturedName?.en} - ${manufacturedAddress?.en ?? "N/A"}`
              : null,
            th: manufacturedName
              ? `${manufacturedName?.th} - ${manufacturedAddress?.th ?? "N/A"}`
              : null,
          })}
          {renderDataRowLang("distributedBy", {
            en: distributedName?.en
              ? `${distributedName?.en} - ${distributedAddress?.en ?? "N/A"}`
              : null,
            th: distributedName?.th
              ? `${distributedName?.th} - ${distributedAddress?.th ?? "N/A"}`
              : null,
          })}
          {renderDataRowLang("manufacturingDate", {
            en: TimeUtils.convertMiliToDate(now()),
            th: today.toLocaleDateString("th-TH", options),
          })}
          {renderDataRowLang("salePriceBath", {
            en: "Refer to point of sale.",
            th: "ระบุ ณ จุดขาย",
          })}
          {printData?.nav_rp &&
            renderDataRowLang("navRPNo", {
              en: printData?.nav_rp,
              th: printData?.nav_rp,
            })}
        </div>
      </div>
    );
  };

  const renderProductInfo = () => {
    const { products } = printData;

    switch (products?.category?.print_type) {
      case PRODUCT_LABEL_PRINT_TYPE.BLANKET:
        return renderProductBlanket();
      case PRODUCT_LABEL_PRINT_TYPE.TOPPER:
        return renderProductTopper();
      default:
        return renderProductDefault();
    }
  };

  const renderFooterCenter = () => {
    const { products } = printData;
    if (
      products?.category?.print_type === PRODUCT_LABEL_PRINT_TYPE.TOPPER ||
      products?.category?.print_type === PRODUCT_LABEL_PRINT_TYPE.BLANKET
    ) {
      return <div />;
    }
    return (
      <div className="ml-4 d-flex align-items-center">
        <div>
          <h4 className="label-print_column-footer-model">
            {product?.model?.name ?? "Modal"}
          </h4>
          {/* {map(product?.attribute, (attrItem) => (
            <div className="label-print_column-footer-attr">{attrItem?.value ?? ""}</div>
          ))} */}
          {product?.size?.has_print && (
            <div className="label-print_column-footer-attr">
              {product?.size?.display_name?.[printLang] ?? ""}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="label-print__container flex-column">
      <div className="d-flex" style={{ flex: 1 }}>
        <div className="label-print__image-container">
          <img
            src={product?.brand?.logo_print ?? "/images/img-default.png"}
            className="label-print__channel-image"
          />
          <div className="label-print__image-product">
            <img src={product?.imageurl?.[0] ?? product?.brand?.thumbnail} />
          </div>
        </div>
        <div className="label-print__info-container">
          <div className="label-print__column-header">
            <div className="label-print__day-label">{printData?.label}</div>
          </div>
          {renderProductInfo()}
        </div>
      </div>
      <div className="d-flex align-items-center" style={{ padding: "10px" }}>
        {printData?.url_scan && (
          <div className="label-print__column-footer">
            <QRCode value={printData?.url_scan} size={100} />
            {renderFooterCenter()}
          </div>
        )}
        {printData?.nav_rp && (
          <div className="ml-2">
            <Barcode
              value={printData?.nav_rp}
              width={1.6}
              height={80}
              fontSize={16}
            />
          </div>
        )}
        <div
          className="label-print__column-footer justify-content-end"
          style={{ marginBottom: "10px" }}
        >
          <Barcode
            value={product?.sku}
            width={2.4}
            height={80}
            fontSize={16}
            format="EAN13"
          />
          <div className="d-flex flex-column ml-3">
            <small className="d-flex justify-content-end mb-2">
              {TimeUtils.convertMiliToDateWithFormat(
                printData?.created,
                "YYYYMMDD HH:mm"
              )}
            </small>
            <small>{printData?.code}</small>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProductTagPrintView = ({ printLang, printData }: any) => {
  const fontStyle = printLang === "en" ? "font-family-en" : "font-family-th";
  const { information, products, serial_number, url_scan } = printData || {};

  return (
    <div className="tag-print__container bg-white border d-flex justify-content-center align-items-center">
      <div className="flex-center-y border tag-print__item-container">
        <div className="d-flex flex-column align-item-center tag-print__image">
          <div className="tag-print__image-qr-container">
            {url_scan && <QRCode value={url_scan} size={200} />}
            <img
              src={products?.brand?.logo_print ?? "/images/img-default.png"}
              className="tag-print__image-brand"
            />
          </div>
          <div className="tag-print__info-product-size text-center mt-2">
            {serial_number}
          </div>
          <div className="tag-print__info">
            {products?.model?.name && (
              <div
                className="tag-print__info-product-name"
                style={{ wordBreak: "break-all" }}
              >
                {products?.model?.name?.toUpperCase() ?? "N/A"}
              </div>
            )}
            {products?.size?.display_name?.en && (
              <div className="tag-print__info-product-size">
                {products?.size?.display_name?.en}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const ProductionPrintingTable = () => {
  const tableRef = useRef<any>();
  const searchRef = useRef<string>();
  const [searchType, setSearchType] = useState(
    PRODUCTION_PRINTING_SEARCH_TYPE_DEFAULT
  );
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const { t } = useTranslation();
  const [printingHistory, setPrintingHistory] = useState<string | null>(null);
  const [openImport, setOpenImport] = useState(false);
  const [pageParam, setPageParam] = useQueryParam("pageParam", JsonParam);
  const [printLang, setPrintLang] = useState<any>([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [showPrintDrawer, setShowPrintDrawer] = useState(false);
  const [printingProducts, setPrintingProducts] = useState<any[]>([]);
  const [filterBody = {}, setFilterBody] = useQueryParam(
    "filterBody",
    JsonParam
  );
  const [printType, setPrintType] = useState<PRODUCT_PRINT_TYPE>(
    PRODUCT_PRINT_TYPE.OCPB_LABEL
  );

  const [ocpbLabelPrint, setOcpbSheetPrint] = useState<any>({
    products: [],
  });

  const [labelPrint, setLabelPrint] = useState<any>([]);
  const [tagPrint, setTagPrint] = useState<any>([]);

  const isFiltered = useMemo(
    () => Object.keys(filterBody).length > 0,
    [filterBody]
  );

  useEffect(() => {
    if (!isEmpty(ocpbLabelPrint?.products)) {
      onOcpbLabelPrint();
    }
  }, [ocpbLabelPrint]);

  useEffect(() => {
    if (!isEmpty(labelPrint)) {
      onLabelPrint();
    }
  }, [labelPrint]);

  useEffect(() => {
    if (!isEmpty(tagPrint)) {
      onTagPrint();
    }
  }, [tagPrint]);

  const refreshTable = () => {
    return (
      tableRef.current &&
      tableRef.current.refreshKeepPaging(pageParam ?? DEFAULT_PAGING)
    );
  };

  useEffect(() => {
    refreshTable();
  }, [pageParam]);

  const source = (paging: any) => {
    const { category, productionDate, createdDate } = filterBody;
    let sendData: any = {
      search: searchRef?.current,
      search_type: searchType,
    };

    if (category?.length) {
      sendData["category_id"] = map(category, (item) => item.id);
    }
    if (productionDate?.[0]) {
      sendData["production_date_start"] = moment(productionDate?.[0]).format(
        "DD-MM-YYYY"
      );
      sendData["production_date_end"] = moment(productionDate?.[1]).format(
        "DD-MM-YYYY"
      );
    }
    if (createdDate?.[0]) {
      sendData["created_date_start"] = moment(createdDate?.[0]).format(
        "DD-MM-YYYY"
      );
      sendData["created_date_end"] = moment(createdDate?.[1]).format(
        "DD-MM-YYYY"
      );
    }
    return getProductionPrintingListNew(sendData, paging);
  };

  const printMultiProduction = (langItem: any, products: any[]) => {
    const printData: any[] = [];
    const listSizes: any = {};
    const listCatId = uniq(products?.map((p) => p?.product?.category?.id));

    return Promise.all([
      ...products?.map((product) => {
        return new Promise(async (resolve, reject) => {
          try {
            const response = await getProductionPrintingDetail(
              product?.id,
              langItem.id
            );
            const printItem = response?.data?.data?.print ?? {};
            printData.push(printItem);
            resolve(printItem);
          } catch (err) {
            console.log(err);
            reject();
          }
        });
      }),
      ...listCatId?.map((id) => {
        return new Promise(async (resolve, reject) => {
          try {
            const response = await productSizeByCategory(id, langItem.id);
            const size = response?.data?.data?.size ?? [];
            listSizes[id] = [...size];
            resolve(size);
          } catch (err) {
            console.log(err);
            reject();
          }
        });
      }),
    ])
      .then(() => {
        setOcpbSheetPrint({
          products: printData,
          language: langItem.id,
          sizes: listSizes,
        });
        if (printData.length > 1) {
          document.title = `${langItem.id.toUpperCase()}-${TimeUtils.format(
            new Date().getTime(),
            "DDMMYYYY-HHmm"
          )}`;
        } else {
          document.title = `${
            printData?.[0]?.nav_rp
          }-${langItem.id.toUpperCase()}-${TimeUtils.format(
            new Date().getTime(),
            "DDMMYYYY-HHmm"
          )}`;
        }
      })
      .catch((err) => {
        Notifications.showError("error");
      });
  };

  const onPrintProduction = (langItem: any, products: any[]) => {
    setPrintLang(langItem.id);
    setPrintType(PRODUCT_PRINT_TYPE.OCPB_LABEL);
    Progress.show(
      { method: printMultiProduction, params: [langItem, products] },
      () => {}
    );
  };

  const onPrintProductLabel = (language: any, product: any) => {
    setPrintLang(language.id);
    setPrintType(PRODUCT_PRINT_TYPE.LABEL);
    const printData: any[] = [];
    const productIds = isArray(product)
      ? product.map((p) => p.id)
      : [product?.id];

    return Promise.all([
      ...productIds?.map((productId) => {
        return new Promise(async (resolve, reject) => {
          try {
            const response = await ProductLabelAPI.getPrintProductLabelDetail(
              productId
            );
            console.log("response", response);
            const printItem = response?.data?.data ?? {};
            printData.push({
              printData: {
                ...printItem,
                nav_so: product?.nav_so,
                nav_rp: product?.nav_rp,
              },

              language,
            });
            resolve(printItem);
          } catch (err) {
            reject(err);
          }
        });
      }),
    ]).then(
      () => {
        setLabelPrint(printData);
      },
      (err) => {
        Notifications.showError(err);
      }
    );
  };

  const onPrintProductTag = (language: any, product: any) => {
    setPrintLang(language.id);
    setPrintType(PRODUCT_PRINT_TYPE.TAG);
    const printData: any[] = [];
    const productIds = isArray(product)
      ? product.map((p) => p.id)
      : [product?.id];

    return Promise.all([
      ...productIds?.map((productId) => {
        return new Promise(async (resolve, reject) => {
          try {
            const response = await getSerialNumberPrint(productId);
            const printItem = response?.data?.data ?? {};
            printData.push({
              printData: {
                ...printItem,
              },
              language,
            });
            resolve(printItem);
          } catch (err) {
            reject(err);
          }
        });
      }),
    ]).then(
      () => {
        setTagPrint(printData);
      },
      (err) => {
        console.log(err);
        Notifications.showError(err);
      }
    );
  };

  const onPrintMultipleFile = (langItem: any, type: PRODUCT_PRINT_TYPE) => {
    const products = [...printingProducts];
    if (type === PRODUCT_PRINT_TYPE.OCPB_LABEL) {
      onPrintProduction(langItem, products);
    } else if (type === PRODUCT_PRINT_TYPE.LABEL) {
      onPrintProductLabel(langItem, products);
    } else if (type === PRODUCT_PRINT_TYPE.TAG) {
      onPrintProductTag(langItem, products);
    }
  };

  const columns: any[] = [
    {
      title: t("createdDate"),
      dataIndex: "created",
      render: TimeUtils.toDateTime,
    },
    {
      title: t("navRPNo"),
      dataIndex: "nav_rp",
    },
    {
      title: t("productionDate"),
      dataIndex: "production_date",
      width: 130,
      render: (data: any) => (data ? TimeUtils.toDate(data) : "N/A"),
    },
    // {
    //   title: t("sku"),
    //   dataIndex: "product",
    //   render: (product: any) => (
    //     <Link to={product?.editurl}>{product?.sku}</Link>
    //   ),
    // },
    {
      title: t("name"),
      dataIndex: "product",
      render: (product: any) => <ProductName item={{ product }} />,
    },
    {
      title: t("navSONo"),
      dataIndex: "nav_so",
    },
    {
      title: t("soNo"),
      dataIndex: "order",
      render: (order: any) => {
        if (!order) return "N/A";
        return (
          <Link to={generatePath(Path.ORDER_DETAIL, { id: order?.id })}>
            {order?.orderid}
          </Link>
        );
      },
    },
    {
      title: t("ocpbLabel"),
      dataIndex: "",
      render: (product: any) => (
        <Dropdown
          dataSource={LANGUAGES}
          onClick={(item) => onPrintProduction(item, [product])}
          variant="view"
          position="left-edge"
          className="dropdown-language"
        >
          <Button iconName="print" variant="trans" />
        </Dropdown>
      ),
    },
    {
      title: t("standardLabel"),
      dataIndex: "",
      render: (product: any) => (
        <Dropdown
          dataSource={LANGUAGES}
          onClick={(item) => onPrintProductLabel(item, product)}
          variant="view"
          position="left-edge"
          className="dropdown-language"
        >
          <Button iconName="print" variant="trans" />
        </Dropdown>
      ),
    },
    {
      title: t("tag"),
      dataIndex: "",
      render: (product: any) => (
        <Dropdown
          dataSource={LANGUAGES}
          onClick={(item) => onPrintProductTag(item, product)}
          variant="view"
          position="left-edge"
          className="dropdown-language"
        >
          <Button iconName="print" variant="trans" />
        </Dropdown>
      ),
    },
    {
      title: t("history"),
      dataIndex: "id",
      render: (id: string, product: any) => (
        <Button
          onClick={() => setPrintingHistory(id)}
          className=""
          iconName="update"
          variant="trans"
        />
      ),
    },
  ];

  const onChangeSearch = debounce((text) => {
    searchRef.current = text;
    tableRef.current.refresh();
  }, 300);

  const onOcpbLabelPrint = () => {
    Promise.all(
      ocpbLabelPrint?.products?.map((product: any) => {
        return new Promise(async (resolve) => {
          const response = await printProductionPrinting({
            id: product.id,
          });
          resolve(response);
        });
      })
    )
      .then(() => {
        triggerPrint();
      })
      .catch((err) => {
        Notifications.showError("error");
      });
  };

  const onLabelPrint = () => {
    document.title = `${
      labelPrint?.[0]?.printData?.products?.sku
    }-${TimeUtils.format(new Date().getTime(), "DDMMYYYYhhmmss")}`;
    triggerPrint();
  };

  const onTagPrint = () => {
    document.title = `${
      tagPrint?.[0]?.printData?.products?.sku
    }-${TimeUtils.format(new Date().getTime(), "DDMMYYYYhhmmss")}`;
    triggerPrint();
  };

  const triggerPrint = () => {
    setTimeout(() => window.print(), 300);
  };

  const renderProductPrinting = () => {
    switch (printType) {
      case PRODUCT_PRINT_TYPE.OCPB_LABEL:
        return ocpbLabelPrint?.products
          ? ocpbLabelPrint?.products?.map((p: any) => (
              <ProductionPrintingDoc
                {...(p ?? {})}
                language={ocpbLabelPrint.language}
                key={p.id}
                listSizes={ocpbLabelPrint.sizes}
              />
            ))
          : null;
      case PRODUCT_PRINT_TYPE.LABEL:
        return labelPrint?.map((printData: any, index: number) => (
          <Fragment key={index}>
            <ProductLabelPrintView {...printData} printLang={printLang} />
          </Fragment>
        ));
      case PRODUCT_PRINT_TYPE.TAG:
        return tagPrint?.map((printData: any, index: number) => (
          <Fragment key={index}>
            <ProductTagPrintView {...printData} printLang={printLang} />
          </Fragment>
        ));
      default:
        break;
    }
  };

  useEffect(() => {
    i18next.loadLanguages(["en", "th"]);
  }, []);

  return (
    <AppContext.Provider value={{}}>
      <div className={`printClass body-${printLang} flex-col`}>
        {renderProductPrinting()}
      </div>
      <div className="noPrintClass bg-white">
        <HeaderTable
          label={t("productionPrinting")}
          onChangeText={(event: any) => onChangeSearch(event.target.value)}
          onClickImport={() => setOpenImport(true)}
          onClickFilter={() => setOpenFilter(true)}
          isFiltered={isFiltered}
          customButtons={() =>
            selectedRows?.length ? (
              <Button
                iconName="print"
                onClick={() => {
                  setShowPrintDrawer(true);
                  setPrintingProducts(selectedRows);
                }}
              >
                {t("print")}
              </Button>
            ) : // <Dropdown
            //   dataSource={LANGUAGES}
            //   onClick={(item) => {onPrintProduction(item, selectedRows)}
            //   variant="view"
            //   position="left-edge"
            //   className="dropdown-language"
            // >
            //   <Button iconName="print">{t("print")}</Button>
            // </Dropdown>
            null
          }
          className="app-layout__page-header"
          customView={() => (
            <Select
              value={searchType}
              dataSource={PRODUCTION_PRINTING_SEARCH_OPTIONS.map((item) => ({
                ...item,
                label: t(item.label),
              }))}
              onChange={(valueId) => setSearchType(valueId)}
              getLabel={(item) => item.label}
              classNameSelect="height-[37px] ml-3"
              placeholder={t("pleaseSelect")}
              variant="standard"
            />
          )}
        />
        <AwesomeTableComponent
          source={source}
          transformer={(res) => {
            const products = res?.data?.data?.print ?? [];
            return products;
          }}
          columns={columns}
          ref={(ref) => (tableRef.current = ref)}
          setCurrentPage={setPageParam}
          rowSelection={{
            selectedRowKeys: map(selectedRows, (item) => item.id),
            onChange: (selectedRowKeys, selectRows) => {
              setSelectedRows(selectRows);
            },
            preserveSelectedRowKeys: true,
          }}
        />
        {!!printingHistory && (
          <ProductionPrintingHistoryDrawer
            id={printingHistory}
            open={!!printingHistory}
            onClose={() => setPrintingHistory(null)}
          />
        )}
        {openImport && (
          <ProductionPrintingImportDrawer
            open={openImport}
            onClose={() => setOpenImport(false)}
            onImported={() => {
              tableRef.current.refresh();
            }}
          />
        )}
        {openFilter && (
          <ProductionPrintingFilterDrawer
            open={openFilter}
            onClose={() => setOpenFilter(false)}
            defaultFilter={filterBody}
            onFilter={(filter: any) => {
              setFilterBody(filter);
              tableRef.current.refresh();
            }}
          />
        )}
      </div>
      <div
        className="font_preload opacity-0"
        style={{ height: 0, overflow: "hidden" }}
      >
        <span className="font-en">...</span>
        <span className="font-th">ไทย</span>
      </div>
      {showPrintDrawer && (
        <ProductPrintDrawer
          open={showPrintDrawer}
          onClose={() => setShowPrintDrawer(false)}
          onPrintProducts={onPrintMultipleFile}
        />
      )}
    </AppContext.Provider>
  );
};

const ProductPrintDrawer = ({
  open,
  onClose,
  onPrintProducts,
}: DrawerProps & { onPrintProducts: any }) => {
  const { t } = useTranslation();
  const [language, setLanguage] = useState(LANGUAGES[0].id);
  const [type, setType] = useState(PRODUCT_PRINT_TYPES[0].id);
  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("productionPrinting")}
      showCancel={false}
      saveText={t("print")}
      onSave={() => {
        const langItem = LANGUAGES.find((item) => item.id === language);
        onPrintProducts(langItem, type);
        onClose();
      }}
    >
      <Select
        dataSource={LANGUAGES}
        label={t("language")}
        className="mb-3"
        value={language}
        onChange={(val) => setLanguage(val)}
      />
      <TranslatedSelect
        dataSource={PRODUCT_PRINT_TYPES}
        label={t("type")}
        value={type}
        onChange={(val) => setType(val)}
      />
    </Drawer>
  );
};

export default ProductionPrintingTable;
