import BrandSelect from "@components/brand/components/BrandSelect";
import ChannelSelect from "@components/channel/components/ChannelSelect";
import ProductCategorySelect from "@components/product-category/components/ProductCategorySelect";
import Drawer from "@components/shared/Drawer";
import { DateInput, Select } from "d-react-components";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

function CostingExpenseFilterModal({
  open,
  onClose,
  onFilter,
  defaultValue,
}: any) {
  const { t } = useTranslation();

  const filterForm = useFormik({
    initialValues: defaultValue,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      onFilter && onFilter(values);
      onClose();
    },
  });
  const { values, setFieldValue } = filterForm;
  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("filter")}
      onSave={() => filterForm.handleSubmit()}
    >
      <div className="">
        <ChannelSelect
          value={values.channel}
          onChange={(value) => setFieldValue("channel", value)}
          className="mb-3"
        />
        <BrandSelect
          value={values.brand}
          onChange={(value) => setFieldValue("brand", value)}
          className="mb-3"
          multiple
        />
        <ProductCategorySelect
          value={values.category}
          onChange={(value) => setFieldValue("category", value)}
          multiple
        />
      </div>
    </Drawer>
  );
}

export default CostingExpenseFilterModal;
