import { TranslatedSelect } from "@common/TranslatedSelect";
import { PRODUCT_LABEL_PRINT_TYPES } from "@constants/product";
import AppContext from "@helpers/context";
import { CheckboxGroup, Select } from "d-react-components";
import { map, includes, filter } from "lodash";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

const ProductLabelImportTemplate = () => {
  const { step, formImport, importFieldSource } = useContext(AppContext);
  const { template, importFields } = formImport?.values;
  const { t } = useTranslation();

  const onChangeTemplate = (value: any) => {
    formImport.setValues({
      ...formImport?.values,
      template: value,
    });
  };

  const onChangeImportField = (values = []) => {
    formImport.setFieldValue(
      "importFields",
      filter(
        importFieldSource,
        (item) => includes(values, item.name) || item.required
      )
    );
  };

  return (
    <div className="p-4">
      <text>{t(step?.desc)}</text>
      <TranslatedSelect
        className="mt-3"
        dataSource={PRODUCT_LABEL_PRINT_TYPES}
        value={template}
        getLabel={(item) => t(item.label)}
        onChange={onChangeTemplate}
      />

      <CheckboxGroup
        label={t("whichElementDoYouWantUpdate")}
        className="mt-3"
        dataSource={importFieldSource}
        value={map(importFields, (item) => item.name)}
        getValue={(item) => item.name}
        onChange={onChangeImportField as any}
        disabled={template === "create"}
        getDisabledItem={(item) => item.required}
        showSelectAll
      />
    </div>
  );
};

export default ProductLabelImportTemplate;
