/* eslint-disable no-restricted-syntax */
import Drawer from "@components/shared/Drawer";
import FilterView from "@components/shared/FilterView";
import { DEFAULT_PAGING } from "@constants/common";
import { IProduct } from "@interfaces/product";
import { listProduct } from "@network/api/product";
import { Tooltip } from "antd";
import {
  AwesomeTableComponent,
  InputTextSearch,
  Button,
  StringUtils,
  Checkbox,
} from "d-react-components";
import { join, map, every, isEmpty, uniqBy, debounce } from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import ProductFilterInSearchButton from "./ProductFilterInSearchButton";
import ProductName from "./ProductName";

const INIT_FILTER: any = {
  brand: [],
  category: [],
};

const SearchGroupProducts = ({
  buttonText,
  buttonProps = {},
  onCloseSearchProduct,
  onChange,
  selectedProducts = [],
  isShowSelectAll = true,
  customSource,
  customTransformer,
  customColumns,
  customStockColumn,
  validateBeforeSearch = () => true,
  isSingleSelect = false,
  customMainContent,
  removeColumns = [],
  allowSelectGroupProduct = false,
}: any) => {
  const [openProductModal, setOpenProductModal] = useState(false);
  const [inputSearch, setInputSearch] = useState("");
  const productItemTable = useRef<any>(null);
  const [selectingProduct, setSelectingProduct] = useState<IProduct[]>([]);
  const [validSelectProductList, setValidSelectProList] = useState([]);
  const [filterBody, setFilterBody] = useState<any>(INIT_FILTER);
  const { t } = useTranslation();

  const getContentFilterItem = (valueFilter: any) => {
    return join(
      map(valueFilter, (item) => item.name),
      ", "
    );
  };

  const filterListData = useMemo(() => {
    const filterListResult = [];
    for (const key in filterBody) {
      const valueFilter = filterBody[key];
      if (valueFilter && valueFilter.length > 0) {
        filterListResult.push({
          id: key,
          label: t(key),
          content: getContentFilterItem(valueFilter),
        });
      }
    }
    return filterListResult;
  }, [filterBody]);

  const isCheckAll = useMemo(() => {
    if (validSelectProductList.length === 0 || selectingProduct.length === 0) {
      return false;
    }
    return every(validSelectProductList, (productItem: any) => {
      const selectingItem = selectingProduct.find(
        (item: any) => item.id === productItem.id
      );
      return !!selectingItem;
    });
  }, [validSelectProductList, selectingProduct]);

  const onSetSelectAll = useCallback(() => {
    if (isCheckAll) {
      const filteredResults = selectingProduct.filter((productItem: any) => {
        const currentPro = validSelectProductList.find(
          (item: any) => item.id === productItem.id
        );
        return !currentPro;
      });
      setSelectingProduct(filteredResults);
    } else {
      const results = [...selectingProduct, ...validSelectProductList];
      const uniqResults = uniqBy(results, (item: any) => item.id);
      setSelectingProduct(uniqResults);
    }
  }, [isCheckAll, selectingProduct, validSelectProductList]);

  const isGroupProduct = (pro: any) => pro.is_group;

  const handleAddProductToCart = useCallback(
    (product: IProduct) => {
      if (isSingleSelect) {
        setSelectingProduct([product]);
      } else {
        setSelectingProduct([...selectingProduct, product]);
      }
    },
    [isSingleSelect, selectingProduct]
  );

  const handleRemoveProductFromCart = useCallback(
    (product) => {
      const clone =
        selectingProduct &&
        selectingProduct?.filter((item) => item?.id !== product?.id);
      setSelectingProduct(clone);
    },
    [selectingProduct]
  );

  const columns = useMemo(() => {
    let cols = [
      {
        title: t("itemNameTable"),
        dataIndex: "",
        width: 600,
        ellipsis: true,
        render: (product: IProduct, item: any) => (
          <ProductName item={{ product: item }} />
        ),
      },

      {
        title: t("stock"),
        dataIndex: "stock_quantity",
        width: 200,
        render: (item: any) => {
          if (customStockColumn) {
            return customStockColumn(item);
          }
          return item?.total_stock;
        },
      },

      {
        title: t("regularPrice"),
        dataIndex: "regular_price",
        width: 200,
        render: (price: number) => StringUtils.moneyThaiFormat(price),
      },
      {
        title: t("salePrice"),
        dataIndex: "sale_price",
        width: 200,
        render: (price: number) => StringUtils.moneyThaiFormat(price),
      },
      {
        title: isShowSelectAll && (
          <Checkbox
            className="checkBoxSelectAllProducts"
            onClick={onSetSelectAll}
            checked={isCheckAll}
          />
        ),
        dataIndex: "",
        width: 50,
        render: (productItem: IProduct) => {
          const isSelected =
            selectingProduct &&
            selectingProduct.filter((product) => product.id === productItem.id)
              .length > 0;
          const disabled = productItem?.is_group && !allowSelectGroupProduct;

          return disabled ? (
            <Tooltip
              title={t("youCannotSelectProduct")}
              overlayClassName="containerTooltipSearchGroupProduct"
            >
              <div className="checkBoxSelectAllProducts-disable" />
            </Tooltip>
          ) : (
            <Checkbox
              className="checkBoxSelectAllProducts"
              disabled={disabled}
              checked={isSelected}
              onClick={() =>
                isSelected
                  ? handleRemoveProductFromCart(productItem)
                  : handleAddProductToCart(productItem)
              }
            />
          );
        },
      },
    ];
    if (removeColumns?.length > 0) {
      cols = cols.filter((item) => !removeColumns.includes(item?.dataIndex));
    }
    return cols;
  }, [
    t,
    allowSelectGroupProduct,
    customStockColumn,
    handleAddProductToCart,
    handleRemoveProductFromCart,
    isCheckAll,
    isShowSelectAll,
    onSetSelectAll,
    selectingProduct,
    removeColumns,
  ]);

  useEffect(() => {
    setSelectingProduct(selectedProducts);
  }, [openProductModal]);

  useEffect(() => {
    if (!productItemTable.current) {
      return;
    }
    productItemTable.current.refresh();
  }, [inputSearch, filterBody]);

  const onSaveFilter = (filterBody: any) => {
    setFilterBody(filterBody);
  };

  const onRemoveFilter = (filterItem: any) => {
    const result = { ...filterBody };
    result[filterItem.id] = INIT_FILTER[filterItem.id];
    setFilterBody(result);
  };

  const handleOnClickSearchProduct = () => {
    const isValidate = validateBeforeSearch();
    if (!isValidate) {
      return;
    }
    setOpenProductModal(true);
  };

  const onChangeTextSearch = debounce((text) => {
    setInputSearch(text);
  }, 1000);

  const renderHeaderView = () => {
    return (
      <>
        <div className="d-flex mb-3">
          <InputTextSearch
            placeholder={t("search")}
            onChange={(e: any) => onChangeTextSearch(e.target.value)}
          />
          <div className="flex items-center">
            <ProductFilterInSearchButton onSaveFilter={onSaveFilter} />
          </div>
        </div>
      </>
    );
  };

  const source = (currentPage?: any) => {
    if (isEmpty(inputSearch) && filterListData.length === 0) {
      return Promise.resolve();
    }
    const filterBodyParam = transformBodyFilterBeforeAPI(filterBody);

    if (customSource) {
      return customSource(currentPage?.pageIndex, inputSearch, filterBodyParam);
    }
    const body = { ...filterBodyParam, search: inputSearch, isGrouped: false };
    return listProduct(body, {
      pageIndex: currentPage?.pageIndex || DEFAULT_PAGING.pageIndex,
      pageSize: DEFAULT_PAGING.pageSize,
    }).then((res) => {
      return res;
    });
  };

  const transformBodyFilterBeforeAPI = (filterBodyParams: any) => {
    const result: any = {};

    if (!filterBodyParams) {
      return result;
    }
    for (const key in filterBodyParams) {
      const filterValue = filterBodyParams?.[key] ?? [];
      if (filterValue.length > 0) {
        result[key] = filterValue?.map((item: any) => item.id);
      }
    }
    return result;
  };

  const transformer = (response: any) => {
    let productResult = [];
    if (customTransformer) {
      productResult = customTransformer(response);
    } else {
      productResult = response?.data?.data?.product ?? [];
    }
    setValidSelectProList(
      productResult.filter((item: any) => !isGroupProduct(item))
    );
    return productResult;
  };

  const onGetColumns = () => {
    if (customColumns) {
      return customColumns;
    }
    return columns;
  };

  const renderProductTable = () => {
    return (
      <AwesomeTableComponent
        ref={productItemTable}
        source={source}
        transformer={(res) => transformer(res)}
        columns={onGetColumns()}
        isScroll={false}
        expandable={{}}
        className="mt-3"
      />
    );
  };

  const renderNumberOfSelecting = () => {
    if (selectingProduct.length === 0) {
      return <div />;
    }
    return (
      <div className="mt-2">
        <span className="numberOfSelectingText">{`${
          selectingProduct.length
        } ${t("productsSelected")}`}</span>
      </div>
    );
  };

  const renderMainContent = () => {
    if (customMainContent) {
      return (
        <div
          style={{ display: "inline-block" }}
          onClick={handleOnClickSearchProduct}
        >
          {customMainContent()}
        </div>
      );
    }

    return (
      <Button
        onClick={handleOnClickSearchProduct}
        iconName="search"
        {...buttonProps}
      >
        {buttonText || t("browseProduct")}
      </Button>
    );
  };

  return (
    <div className="inline-block">
      {renderMainContent()}
      <Drawer
        open={openProductModal}
        size="auto"
        width={800}
        title={t("addNewItems")}
        saveText={t("select")}
        cancelText={t("close")}
        onClose={() => {
          setInputSearch("");
          onCloseSearchProduct && onCloseSearchProduct();
          setOpenProductModal(false);
        }}
        disableSave={!selectingProduct || selectingProduct.length === 0}
        onSave={() => {
          setInputSearch("");
          onCloseSearchProduct && onCloseSearchProduct();
          onChange(selectingProduct);
          setOpenProductModal(false);
        }}
      >
        <div className="cardContainer searchProductViewUpdated">
          {renderHeaderView()}
          <FilterView
            filterList={filterListData}
            onRemoveFilter={onRemoveFilter}
            onRemoveAllFilter={() => setFilterBody(INIT_FILTER)}
          />
          {renderNumberOfSelecting()}
          {renderProductTable()}
        </div>
      </Drawer>
    </div>
  );
};

export default SearchGroupProducts;
