import { GP_EVENT_STATUSES } from "@constants/gp-event";
import AppContext from "@helpers/context";
import classNames from "classnames";
import { Icon, TimeUtils, ViewLabelStatus } from "d-react-components";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

const GPEventDetailHeader = () => {
  const { eventDetail } = useContext(AppContext);
  const { name, status, by, created } = eventDetail ?? {};
  const { t } = useTranslation();

  return (
    <div className="card-container p-3 flex-center justify-content-between ">
      <div className="flex-column">
        <div className="d-flex">
          <h4 className={classNames("text-[14px] text-primary")}>{name}</h4>
          <ViewLabelStatus
            listStatus={GP_EVENT_STATUSES}
            status={status}
            className="ml-3"
            getLabel={(item) => t(item.label)}
          />
        </div>
        {/* <div className="mt-3 d-flex align-items-center cursor-pointer">
          <Icon name="print" />
          <div className="ml-3"> {t("print")}</div>
        </div> */}
      </div>
      <div className="flex-column">
        <div className="d-flex">{`${t("by")} ${by?.name}`}</div>
        <small>{`${t("createdOn")} ${TimeUtils.toDateTime(created)}`}</small>
      </div>
    </div>
  );
};

export default GPEventDetailHeader;
