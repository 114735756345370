import {
  IMPORT_PRODUCT_METHODS,
  PRODUCT_TYPE,
  PRODUCT_TYPES,
} from "@constants/product";
import AppContext from "@helpers/context";
import { validateImportProduct } from "@network/api/product";
import { Spin } from "antd";
import { ObjectUtils } from "d-react-components";
import { find, forEach, isEmpty, map, groupBy, filter, includes } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";

export const getImportProductDefault = (
  productImportData: any = {},
  importFields: any[] = [],
  parentName = ""
) => {
  const body: any = { sku: productImportData.sku };
  forEach(importFields, (field) => {
    if (field?.fields?.length > 0) {
      body[field.name] = getImportProductDefault(
        productImportData,
        field?.fields,
        field?.name
      );
    } else {
      const keyName = isEmpty(parentName)
        ? field.name
        : `${parentName}_${field.name}`;
      body[field.name] = productImportData[keyName];
    }
  });
  return body;
};

const getImportProductGroup = (
  productImportData: any[] = [],
  importFields: any[] = [],
  groupKey: any
) => {
  const groupProductData = groupBy(productImportData, (item) => item.sku);
  const importBasicFields = filter(
    importFields,
    (item) => item.name !== groupKey
  );
  const importGroupFields = find(
    importFields,
    (item) => item.name === groupKey
  );

  const groupProducts: any = [];

  Object.keys(groupProductData).forEach((sku) => {
    const groupData = groupProductData[sku];
    const basicData = getImportProductDefault(groupData[0], importBasicFields);
    const groupProduct = {
      ...basicData,
      [groupKey]: map(groupData, (groupItem) =>
        getImportProductDefault(
          groupItem,
          importGroupFields?.fields ?? [],
          groupKey
        )
      ),
    };
    groupProducts.push(groupProduct);
  });

  return groupProducts;
};

export const getImportProducts = (
  productImportData = [],
  importFields = [],
  productType: any
) => {
  switch (productType) {
    case PRODUCT_TYPE.DEFAULT:
    case PRODUCT_TYPE.CUSTOM_SIZE:
      const productsImport = map(productImportData, (productImportItem) =>
        getImportProductDefault(productImportItem, importFields)
      );
      return productsImport;
    case PRODUCT_TYPE.GROUP:
      return getImportProductGroup(
        productImportData,
        importFields,
        "group_product"
      );
    case PRODUCT_TYPE.GROUP_CUSTOM_SIZE:
      return getImportProductGroup(
        productImportData,
        importFields,
        "group_custom_size"
      );
    default:
      return {};
  }
};

const FileValidationAndImport = () => {
  const { formImport } = useContext(AppContext);
  const [validateLoading, setValidateLoading] = useState(true);
  const { t } = useTranslation();

  const { productType, importType, importFields, fileImport } =
    formImport.values;
  const productTypeVal = useMemo(
    () => find(PRODUCT_TYPES, (item) => item.id === productType),
    [productType]
  );
  const importTypeVal = useMemo(
    () => find(IMPORT_PRODUCT_METHODS, (item) => item.id === importType),
    [importType]
  );

  useEffect(() => {
    try {
      onValidateImportDatabase(fileImport?.data);
    } catch (err) {
      setValidateLoading(false);
    }
  }, []);

  const onValidateImportDatabase = async (productImportData: any) => {
    console.log("productImportData", productImportData);
    const mulSkuList = ObjectUtils.sliceArrayToMui(productImportData, 100000);
    let errors: any[] = [];

    for (let index = 0; index < mulSkuList.length; index++) {
      const skuItemList = mulSkuList[index];
      const validationItems = await getValidateProductAPI(skuItemList);
      errors = errors.concat(validationItems);
    }
    formImport.setFieldValue("fileImport", { ...fileImport, errors });
    setValidateLoading(false);
  };

  const getErrorItemsData = () => {
    // const fieldList = map(importFields, (item) => item.name);
    try {
      const fieldList = Object.keys(fileImport?.data[0]);
      const header = [...fieldList, "Message"];
      const dataResult = [header];
      forEach(fileImport?.errors, (validation) => {
        const productError = find(
          fileImport.data,
          (item) => item.sku === validation.sku
        );
        forEach(validation?.validation ?? [], (message) =>
          dataResult.push([
            ...map(fieldList, (item) => {
              if (productError[item] === false) {
                return "FALSE";
              }
              if (productError[item] === true) {
                return "TRUE";
              }
              return productError[item];
            }),
            message,
          ])
        );
      });
      return dataResult;
    } catch (error) {
      console.log("error", error);
      return [];
    }
  };

  const getSuccessItemsData = () => {
    const fieldList = Object.keys(fileImport?.data[0]);
    const header = [...fieldList, "Message"];
    const dataResult = [header];
    forEach(fileImport.data, (product) => {
      const isErrorPro = includes(
        map(fileImport?.errors, (item) => item.sku),
        product.sku
      );
      if (!isErrorPro) {
        dataResult.push([
          ...map(fieldList, (item) => {
            if (product[item] === false) {
              return "FALSE";
            }
            if (product[item] === true) {
              return "TRUE";
            }
            return product[item];
          }),
          "success",
        ]);
      }
    });
    return dataResult;
  };

  const getValidateProductAPI = async (productImportData: any) => {
    const body = {
      product_type: productType,
      method: importType,
      fields: map(importFields, (item) => item.name),
      products: getImportProducts(productImportData, importFields, productType),
    };

    return validateImportProduct(body).then((res) => {
      const validation = res?.data?.data?.validate;
      const validationResult: any[] = [];
      Object.keys(validation).forEach((key) => {
        validationResult.push({ sku: key, validation: validation[key] });
      });
      return validationResult;
    });
  };

  const renderRowInfo = (title: string, content: any) => (
    <div className="d-flex mt-3">
      <text className="font-weight-bold">{`${title}: `}</text>
      <text className="ml-1">{content}</text>
    </div>
  );

  const renderValidateError = () => {
    if (fileImport?.errors?.length > 0) {
      return (
        <div className="d-flex ">
          <text className="text-error">
            {fileImport?.errors?.length} {t("errors")}
          </text>
          <CSVLink data={getErrorItemsData()} filename="invalid-import-sku.csv">
            <div className="ml-1 text-primary">{t("downloadLog")}</div>
          </CSVLink>
        </div>
      );
    }
    return <text className="text-success">{t("readyToImport")}</text>;
  };

  const renderValidateSuccess = () => {
    return (
      <div className="d-flex ">
        <text className="text-success">
          {fileImport?.data?.length - fileImport?.errors?.length} {t("success")}
        </text>
        <CSVLink data={getSuccessItemsData()} filename="ready-import-sku.csv">
          <div className="ml-1 text-primary">{t("downloadLog")}</div>
        </CSVLink>
      </div>
    );
  };

  if (validateLoading) {
    return (
      <div className="flex-center p-5">
        <Spin />
      </div>
    );
  }
  return (
    <div className="p-4">
      <div
        className="d-flex flex-column p-4"
        style={{ backgroundColor: "rgba(33, 150, 243, 0.1)" }}
      >
        {renderRowInfo(t("fileName"), fileImport?.name)}
        {renderRowInfo(t("productType"), t(productTypeVal?.label as any))}
        {renderRowInfo(t("importMethod"), t(importTypeVal?.label as any))}
        {renderRowInfo(t("validateStatus"), renderValidateError())}
        {renderRowInfo(t("validateStatus"), renderValidateSuccess())}
      </div>
      <div className="mt-5">
        {renderRowInfo(`*${t("lastWarning")}`, t("pleaseCheckCarefully"))}
      </div>
    </div>
  );
};

export default FileValidationAndImport;
