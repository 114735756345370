import HeaderDetail from "@common/HeaderDetail";
import AppContext from "@helpers/context";
import { IChannel } from "@interfaces/channel";
import ChannelAPI from "@network/api/channel";
import classNames from "classnames";
import { Button, DialogManager, Progress, TabBar } from "d-react-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import ChannelDetailActivity from "./ChannelDetailActivity";
import ChannelDetailEmailConfiguration from "./ChannelDetailEmailConfiguration";
import ChannelDetailGeneral from "./ChannelDetailGeneral";
import ChannelDetailInfo from "./ChannelDetailInfo";
import ChannelDetailSMS from "./ChannelDetailSMS";
import ChannelDetailSetting from "./ChannelDetailSetting";
import ChannelDetailSubscription from "./ChannelDetailSubscription";
import ChannelDetailSupport from "./ChannelDetailSupport";
import ChannelDetailTermCondition from "./ChannelDetailTermCondition";
import ChannelDetailUser from "./ChannelDetailUser";
import ChannelDetailWarehouse from "./ChannelDetailWarehouse";

const TABS = [
  {
    id: "general",
    label: "general",
    component: <ChannelDetailGeneral />,
  },

  {
    id: "support",
    label: "support",
    component: <ChannelDetailSupport />,
  },
  {
    id: "emailConfiguration",
    label: "emailConfiguration",
    component: <ChannelDetailEmailConfiguration />,
  },
  {
    id: "warehouse",
    label: "warehouse",
    component: <ChannelDetailWarehouse />,
  },
  {
    id: "users",
    label: "users",
    component: <ChannelDetailUser />,
  },
  {
    id: "setting",
    label: "settings",
    component: <ChannelDetailSetting />,
  },
  {
    id: "subscription",
    label: "subscription",
    component: <ChannelDetailSubscription />,
  },
  {
    id: "sms",
    label: "sms",
    component: <ChannelDetailSMS />,
  },
  {
    id: "termAndCondition",
    label: "termAndCondition",
    component: <ChannelDetailTermCondition />,
  },
  {
    id: "activity",
    label: "activity",
    component: <ChannelDetailActivity />,
  },
];

const ChannelDetail = () => {
  const [channel, setChannel] = useState<IChannel | null>(null);
  const [tab, setTab] = useState<any>(TABS[0]);
  const { channelId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    loadChannelDetail();
  }, []);

  const loadChannelDetail = () => {
    Progress.show(
      { method: ChannelAPI.detail, params: [channelId] },
      (res: any) => {
        const channel = res?.data?.data?.channel;
        setChannel(channel);
      }
    );
  };

  const onClickDuplicate = () => {
    DialogManager.showConfirm(
      t("duplicateConfirmation"),
      t("duplicateConfirmationDescription"),
      onDuplicate
    );
  };

  const onDuplicate = () => {
    // const body = { channel_id: channelId };
    // Progress.show(
    //   { method: ChannelAPI.duplicate, params: [body] },
    //   (res: any) => {
    //     const channelId = res?.data?.data?.id;
    //     Notifications.showSuccess(t("duplicateChannelSuccess"));
    //     navigate(generatePath(Path.WAREHOUSE_DETAIL, { channelId }));
    //   }
    // );
  };

  if (!channel) return <div />;

  return (
    <AppContext.Provider
      value={{
        channel,
        loadChannelDetail,
      }}
    >
      <div className="flex-column-container bg-trans">
        <HeaderDetail
          title={channel?.name}
          // rightSide={
          //   <div className="d-flex">
          //     <Button onClick={onClickDuplicate} size="small" className="ml-3">
          //       {t("duplicate")}
          //     </Button>
          //   </div>
          // }
        />
        <div className="p-3 bg-white">
          <div className="grid grid-cols-4 gap-4">
            <div className="col-span-4 md:col-span-1">
              <ChannelDetailInfo />
            </div>
            <div className="col-span-4 md:col-span-3">
              <div className="">
                <TabBar
                  dataSource={TABS}
                  onChange={setTab}
                  value={tab}
                  getLabel={(item: any) => t(item.label)}
                  classNameItem={classNames(
                    "bg-white border-right-0 border-left-0 border-top-0"
                  )}
                />
                {tab.component}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppContext.Provider>
  );
};

export default ChannelDetail;
