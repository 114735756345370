import { Button } from "d-react-components";
import React, { useContext, useState } from "react";
import QRCode from "react-qr-code";
import UserDetailAuthenticationUpdateModal from "./UserDetailAuthenticationUpdateModal";
import UserDetailAuthenticationExportModal from "./UserDetailAuthenticationExportModal";
import AppContext from "@helpers/context";
import UserAuthQrCode from "@common/UserAuthQrCode";
import { useTranslation } from "react-i18next";
import ImageIcon from "@common/Icon";

const UserDetailAuthentication = () => {
  const { user, loadUserDetail } = useContext(AppContext);
  const [openEdit, setOpenEdit] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="border mt-3 p-4">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h5>{t("authentication")}</h5>
        <span onClick={() => setOpenEdit(true)}>
          <ImageIcon
            className="w-[15px] cursor-pointer"
            src="/images/icons/edit.svg"
          />
        </span>
      </div>
      <text>{t("useGoogleAuthenticationToScan")}</text>
      <div className="flex-column mt-3">
        <span className="font-semibold">{t("status")}: </span>
        <text>
          {user?.authentication_status ? t("enabled") : t("disabled")}
        </text>
      </div>
      <div className="flex-column mt-3" hidden={!user?.authentication_status}>
        <span className="font-semibold">{t("secretCode")}: </span>
        <text>{user?.authentication_code}</text>
      </div>
      <div className="flex-column mt-3" hidden={!user?.authentication_status}>
        <span className="font-semibold">{t("qrCode")}</span>
        <UserAuthQrCode
          secretCode={user?.authentication_code}
          name={user?.name}
        />
      </div>
      {user?.authentication_status && (
        <Button
          className="mt-5"
          iconName="cloud_download"
          onClick={() => setOpenExport(true)}
        >
          {t("downloadPdf")}
        </Button>
      )}
      {openEdit && (
        <UserDetailAuthenticationUpdateModal
          open={openEdit}
          onClose={() => setOpenEdit(false)}
          defaultValue={{
            status: user?.authentication_status,
            code: user?.authentication_code,
          }}
          userId={user?.id}
          qrCodeName={user?.name}
          onSuccess={loadUserDetail}
        />
      )}
      <UserDetailAuthenticationExportModal
        open={openExport}
        onClose={() => setOpenExport(false)}
        user={user}
      />
    </div>
  );
};

export default UserDetailAuthentication;
