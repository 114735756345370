import AppContext from "@helpers/context";
import { Button, InputText } from "d-react-components";
import { map } from "lodash";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

const RawMaterialForecastCheckCopy = () => {
  const { onCheckRawMaterial } = useContext(AppContext);

  const [dataCheck, setDataCheck] = useState<any[]>([]);
  const { t } = useTranslation();

  const onChangeInput = (event: any) => {
    const textValue = event.target.value;
    const separateText = textValue.split("\n") || [];
    const dataList = map(separateText, (dataText) => {
      const dataItemList = dataText.split(",");
      return {
        sku: dataItemList?.[0],
        price: dataItemList?.[1],
        quantity: dataItemList?.[2],
      };
    });

    setDataCheck(dataList);
  };

  return (
    <div className="d-flex flex-column w-100">
      <text>{t("checkByCopying")}</text>
      <text>
        <text className="font-weight-bold">{t("remark")}: </text>
        {t("onlyOneSku")}
      </text>

      <InputText
        className="mt-5 text-input"
        onChange={onChangeInput}
        placeholder="8852248431496,500,10
8852248431441,400,10
8852248431694,300,10
8852248431090,200,11
8852248431540,500,12
8852248431144,500,13"
        multiple
        rows={8}
      />
      <div className="mt-5">
        <Button onClick={() => onCheckRawMaterial(dataCheck)}>
          {t("check")}
        </Button>
      </div>
      <div className="divider mt-5" />
    </div>
  );
};

export default RawMaterialForecastCheckCopy;
