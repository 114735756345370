import FilterDropdown, {
  FilterDropdownProps,
} from "@common/dropdown/FilterDropdown";
import { IChannel } from "@interfaces/channel";
import { getListChannel } from "@network/api/channel";
import { filter, includes, map } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface IChannelDropdownSelect {
  value: any[];
  className?: any;
  selectProps?: FilterDropdownProps["selectProps"];
  onChange: (values: any[]) => void;
}

const ChannelDropdownSelect = ({
  value = [],
  className,
  selectProps,
  onChange,
}: IChannelDropdownSelect) => {
  const [channelList, setChannelList] = useState<IChannel[]>([]);

  useEffect(() => {
    loadChannelList();
  }, []);

  const loadChannelList = () => {
    getListChannel().then((res) => {
      const channelList = res?.data?.data?.channel ?? [];
      setChannelList(channelList);
    });
  };

  const onChangeChannel = (values: string[]) => {
    const channelsSelect = filter(channelList, (item) =>
      includes(values, item.id)
    );
    const channelsSelectMapped = map(channelsSelect, (item) => ({
      id: item.id,
      name: item.name,
    }));

    onChange(channelsSelectMapped);
  };

  const { t } = useTranslation();
  return (
    <FilterDropdown
      selectProps={{
        placeholder: t("search"),
        multiple: true,
        mode: "multiple",
        value: map(value, (item) => item.id),
        getLabel: (item) => item?.name ?? "N/A",
        dataSource: channelList ?? [],
        onChange: onChangeChannel,
        className: "mb-3",
        tagTitle: t("channel"),
        getValue: (item) => item.id,
        allowCreateNew: false,
        ...selectProps,
      }}
      type="normal"
      className={className}
    />
  );
};

export default ChannelDropdownSelect;
