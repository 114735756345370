import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { SubscriptionContractContext } from "../SubscriptionContractDetail";
import AppContext from "@helpers/context";
import { isEmpty } from "lodash";
import { TimeUtils } from "d-react-components";

const SubscriptionContractSignature = () => {
  const { t } = useTranslation();
  const { rentalInfo } = useContext<SubscriptionContractContext>(AppContext);
  const { sign } = rentalInfo ?? {};
  const isSigned = !isEmpty(sign?.customer_sign);
  return (
    <div className="mb-3">
      <div className="bg-white p-3 mt-3">
        <h2 className="text-lg mb-2">{t("termsAndConditions")}</h2>
        <div className="max-h-[300px] overflow-auto border border-gray-300 border-solid rounded p-3">
          {parse(rentalInfo?.sign?.terms_and_conditions_of_rent || "")}
        </div>
      </div>
      {isSigned && (
        <div className="bg-white p-3 mt-3">
          <h2 className="text-lg mb-4">{t("signature")}</h2>
          <div className="flex md:flex-row items-start flex-col w-100 pr-2">
            <div className="flex-1">
              <span className="font-semibold mb-3 block">{t("lessee")}</span>
              <img className="max-h-[60px] mb-2" src={sign?.customer_sign} />
              <p className="mb-1">
                <span className="font-semibold">{t("printedName")}</span>:{" "}
                {sign?.customer_printed_name}
              </p>
              <p className="mb-1">
                <span className="font-semibold">{t("date")}</span>:{" "}
                {sign?.updated
                  ? TimeUtils.convertMiliToDate(sign?.updated)
                  : ""}
              </p>
            </div>
            <div>
              <span className="font-semibold mb-3 block">{t("lessor")}</span>
              <img className="max-h-[60px] mb-2" src={sign?.admin_sign} />
              <p className="mb-1">
                <span className="font-semibold">{t("printedName")}</span>:{" "}
                {sign?.admin_printed_name}
              </p>
              <p className="mb-1">
                <span className="font-semibold">{t("date")}</span>:{" "}
                {sign?.updated
                  ? TimeUtils.convertMiliToDate(sign?.updated)
                  : ""}
              </p>
            </div>
          </div>
          <div className="flex md:flex-row items-end flex-col w-100 pr-2 mt-8">
            {sign?.witness_one_sign && (
              <div className="flex-1">
                <span className="font-semibold mb-3 block">
                  {t("witness")} 1
                </span>
                <img
                  className="max-h-[60px] mb-2"
                  src={sign?.witness_one_sign}
                />
                <p className="mb-1">
                  <span className="font-semibold">{t("printedName")}</span>:{" "}
                  {sign?.witness_one_name}
                </p>
                <p className="mb-1">
                  <span className="font-semibold">{t("date")}</span>:{" "}
                  {sign?.updated
                    ? TimeUtils.convertMiliToDate(sign?.updated)
                    : ""}
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SubscriptionContractSignature;
