import ProductName from "@components/product/components/ProductName";
import ProductSearchButton from "@components/product/components/ProductSearchButton";
import { CONDITION_RULE_CHECK } from "@constants/cart-rule";
import AppContext from "@helpers/context";
import { browseProduct } from "@network/api/product";
import {
  AwesomeTableComponent,
  Icon,
  InputTextSearch,
  ObjectUtils,
  Select,
} from "d-react-components";
import { filter, isEmpty, map } from "lodash";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const CONDITION_TYPE = ObjectUtils.mapObjectToArray(CONDITION_RULE_CHECK);

export default function SKUCondition({
  payload = {},
  setChangeCondition,
  conditionList,
  indexText = "",
  handleDeleteCondition,
}: any) {
  const { cartRuleForm, cartRuleConditionDisabled } = useContext(AppContext);
  const { product = [] } = payload;
  const [textSearch, setTextSearch] = useState("");
  const { t } = useTranslation();
  const formValues = cartRuleForm?.values ?? {};

  const productList = useMemo(() => {
    if (isEmpty(textSearch)) {
      return product;
    }
    return filter(product, (item) => {
      const name = item.product.name.toLowerCase();
      const { sku } = item.product;
      if (name.indexOf(textSearch) !== -1 || sku.indexOf(textSearch) !== -1) {
        return true;
      }
      return false;
    });
  }, [textSearch, product]);

  const columns = [
    {
      title: t("itemName"),
      dataIndex: "product",
      render: (product: any) => {
        return product && <ProductName item={{ product }} />;
      },
    },
  ];

  if (!cartRuleConditionDisabled) {
    columns.push({
      title: t("action"),
      dataIndex: "",
      render: (product: any) => {
        return (
          <Icon
            name="delete"
            onClick={() => onClickDeleteProductHandle(product)}
          />
        );
      },
    });
  }
  //************************************************************* LOAD DATA ********************************** */

  //************************************************************* UI CONTROL ********************************** */

  const setChangeSelectedProductHandle = (items: any) => {
    const resultItems = map(items, (item) => ({
      ...item,
      quantity: item.quantity ?? 1,
    }));
    const clone = { ...payload, product: resultItems };
    const cloneList = map(conditionList, (item) => {
      if (item.id === payload.id) {
        return clone;
      }
      return item;
    });
    setChangeCondition(cloneList);
  };

  const onClickDeleteProductHandle = (pro: any) => {
    console.log("product", product);
    const deleteProduct = filter(
      product,
      (item) => item.productid !== pro.productid
    );
    const clonePayload = { ...payload, product: deleteProduct };
    const cloneList = map(conditionList, (item) => {
      if (item?.id === payload?.id) {
        return clonePayload;
      }
      return item;
    });
    console.log("cloneList", cloneList);
    setChangeCondition(cloneList);
  };

  const onChangeQuantity = (quantity: any, pro: any) => {
    if (quantity < 1) {
      return;
    }
    const result = map(product, (item) => {
      if (item.productid === pro.productid) {
        return { ...pro, quantity };
      }
      return item;
    });
    const clonePayload = { ...payload, product: result };
    const cloneList = map(conditionList, (item) => {
      if (item?.id === payload?.id) {
        return clonePayload;
      }
      return item;
    });
    setChangeCondition(cloneList);
  };

  const handleChangeRuleCondition = (changedPayload: any) => {
    const cloneList = map(conditionList, (item) => {
      if (item.id === changedPayload.id) {
        return changedPayload;
      }
      return item;
    });
    setChangeCondition(cloneList);
  };

  const handleOnChangeTextSearch = (text: any) => {
    setTextSearch(text);
  };

  const sourceSearchProduct = (code: any, filterBody: any, paging: any) => {
    const body: any = {
      channel: formValues.selectedChannel?.id,
      promotion: "name",
      code,
    };

    const { brand, category } = filterBody;
    if (brand && brand.length > 0) {
      body.brand = map(brand, (item) => item.id);
    }

    if (category && category.length > 0) {
      body.category = map(category, (item) => item.id);
    }

    return browseProduct(body, paging);
  };

  //************************************************************* UI RENDER ********************************** */

  const renderHeader = () => {
    return (
      <div className="flex-row-between-center bg-primary px-2">
        <div className="flex-row align-items-center">
          <text className="font-weight-bold text-white">
            {`${t("condition")} ${indexText}: `}
          </text>
          <div className="ml-1 text-white">{t("sku")}</div>
          <Select
            className="ml-2 cart-rule__condition-select"
            dataSource={CONDITION_TYPE}
            getLabel={(option) => t(option.label)}
            variant="standard"
            disabled={cartRuleConditionDisabled}
            value={payload?.rule}
            onChange={(value) => {
              handleChangeRuleCondition({
                ...payload,
                rule: value,
              });
            }}
          />
        </div>
        {!cartRuleConditionDisabled && (
          <Icon
            name="remove_circle"
            color="white"
            onClick={handleDeleteCondition}
          />
        )}
      </div>
    );
  };

  return (
    <div className="mt-2 border border-primary">
      {renderHeader()}
      <div className="p-3">
        <div className="flex-row">
          <InputTextSearch
            onChange={(event: any) => setTextSearch(event.target.value)}
          />
          {!cartRuleConditionDisabled && (
            <ProductSearchButton
              onChange={setChangeSelectedProductHandle}
              defaultValue={product}
              source={sourceSearchProduct}
              dataKey="products"
              buttonText={t("add")}
            />
          )}
        </div>

        <AwesomeTableComponent
          dataSource={productList}
          columns={columns}
          className="mt-2"
        />
      </div>
    </div>
  );
}
