import { Path } from "@components/layout/Path";
import UserAvatarName from "@components/shared/UserAvatarName";
import {
  FILTER_ORDER_PICKUP,
  ORDER_FILTER_CUSTOM_SIZE,
  ORDER_FILTER_SUSPICIOUS_STATUS_LIST,
  ORDER_PAYMENT_STATUS,
  ORDER_SEARCH_OPTIONS,
  ORDER_SEARCH_TYPE_DEFAULT,
  ORDER_SOURES,
  ORDER_STATUS_ARRAY,
  ORDER_TABLE_STATUS_TAB,
} from "@constants/order";
import styled from "@emotion/styled";
import { mapObjectToArray } from "@helpers";
import AppContext from "@helpers/context";
import { getListChannel } from "@network/api/channel";
import { getDeliveryTypeList } from "@network/api/delivery";
import {
  deleteOrder,
  getOrderPaymentMethods,
  listAllOrder,
  listSummaryQuantityOrder,
} from "@network/api/order";
import { Spin } from "antd";
import axios from "axios";
import classNames from "classnames";
import {
  AwesomeTableComponent,
  Button,
  DateInput,
  DialogManager,
  HeaderTable,
  Icon,
  InputText,
  Notifications,
  StringUtils,
  TimeUtils,
} from "d-react-components";
import { compact, find, isEmpty, isObject, join, omit } from "lodash";
import React, { ElementRef, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useUpdateEffect } from "react-use";
import { JsonParam, StringParam, useQueryParam } from "use-query-params";
import OrderShopeModal from "./components/LoadShopeeOrder";
import ExportOrderModal from "./OrderExport";
import FilterOrderModal from "./OrderFilter";
import FilterButtonGroup from "@common/FilterButtonGroup";
import SelectWithInput from "@components/shared/SelectWithInput";
import { TranslatedSelect } from "@common/TranslatedSelect";
import moment from "moment";
import SelectFilter, {
  SelectInfinityFilterMultiple,
} from "@common/dropdown/SelectFilter";
import { listAllStore } from "@network/api/store";
import { listAllUser } from "@network/api/user";
import { PAYMENTS_METHODS } from "@constants/payment";
import { searchAllProduct } from "@network/api/product";
import { DEFAULT_PAGING, YES_NO_OPTIONS } from "@constants/common";
import LabelStatus from "@common/LabelStatus";
import SalesPerson from "@common/SalesPerson";

export const ORDER_TABLE_LAYOUT_KEY = "orderTableLayout";

const ORDER_PAYMENT_STATUS_LIST = mapObjectToArray(ORDER_PAYMENT_STATUS)?.map(
  (item) => ({ ...item, value: item.id })
);

export const getIsEmployeeDiscountValue = (rawData = []) => {
  if (rawData.length === 0 || rawData.length === 2) {
    return "all";
  }
  if (rawData[0] === "true") {
    return "yes";
  }
  return "no";
};

const getUTMValues = (rawData = []) => {
  if (rawData.length === 0 || rawData.length === 2) {
    return undefined;
  }
  if (rawData[0] === "true") {
    return true;
  }
  return false;
};

const Order = () => {
  const { CancelToken } = axios;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedOrderRows, setSelectedOrderRows] = useState<any>([]);
  const cancelSource = useRef<any>();
  const cancelSummary = useRef<any>();
  const orderTableRef = useRef<ElementRef<typeof AwesomeTableComponent>>(null);
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [paymentMethodsList, setPaymentMethodsList] = useState([]);

  const [search, setSearch] = useQueryParam("search", StringParam);
  // const [filterBody, setFilterBody] = useState<any>({});
  const [filterBody, setFilterBody] = useQueryParam("filterParam", JsonParam);
  const [pageParam, setPageParam] = useQueryParam("pageParam", JsonParam);
  const [tab = ORDER_TABLE_STATUS_TAB.all.value, setTab] = useQueryParam(
    "tabSelected",
    StringParam
  ); //selected tab on url
  const [tabSelected, setTabSelected] = useState(
    tab
      ? find(ORDER_TABLE_STATUS_TAB, { value: tab })
      : ORDER_TABLE_STATUS_TAB.all
  );
  const [searchType = ORDER_SEARCH_TYPE_DEFAULT, setSearchType] = useQueryParam(
    "searchType",
    StringParam
  );
  const [source = ORDER_SOURES[0].id, setSource] = useQueryParam(
    "selectedSource",
    StringParam
  ); //selected tab on url
  const [selectedSource, setSelectedSource] = useState(
    source ? find(ORDER_SOURES, { id: source }) : ORDER_SOURES[0]
  );
  const [filters, setFilters] = useState<any>({
    source: selectedSource ? selectedSource?.value : ORDER_SOURES[0].value,
  });

  const [summary, setSummary] = useState<any>();
  const [showFilter, setShowFilter] = useState(false);
  const [openOrderShopeSelect, setOpenOrderShopeSelect] = useState(false);
  const [isExport, setIsExport] = useState(false);

  // CONFIG DATA
  // const [userList, setUserList] = useState([]);
  const [channelList, setChannelList] = useState([]);
  const [deliveryTypes, setDeliveryTypes] = useState([]);
  // const [listStore, setListStore] = useState([]);

  // const loadUsers = () => {
  //   listAllUser().then((res) => {
  //     const userList = res?.data?.data?.users ?? [];
  //     setUserList(userList);
  //   });
  // };

  const loadChannels = () => {
    getListChannel().then((res) => {
      const channelList = res?.data?.data?.channel ?? [];
      setChannelList(channelList);
    });
  };

  const loadDeliveryTypes = () => {
    getDeliveryTypeList().then((res) => {
      const deliveryTypes = res?.data?.data?.delivery ?? [];
      setDeliveryTypes(deliveryTypes);
    });
  };

  // const loadStore = () => {
  //   listAllStore().then((res) => {
  //     const listStore = res?.data?.data?.stores ?? [];
  //     const filterStore = listStore?.map((storeItem: any) => ({
  //       id: storeItem.id,
  //       name: storeItem.name,
  //     }));
  //     setListStore(listStore);
  //   });
  // };

  const columns = useMemo(
    () => [
      {
        title: t("orderNo"),
        dataIndex: "orderid",
        ellipsis: true,
        width: 160,
        render: (orderid: string, item: any) => (
          <div className="flex flex-col text-gray-400">
            <Link to={`/order/${item.id}`}>{orderid}</Link>
            <span className="text-gray-400 text-xs text-ellipsis overflow-hidden">
              {t("createdOn")} {TimeUtils.toDateTime(item.created)}
            </span>
          </div>
        ),
      },

      {
        title: t("pos"),
        dataIndex: "channel",
        width: 300,
        ellipsis: true,
        render: (channel: any, item: any) => {
          return (
            <>
              <div className="flex items-center mb-1">
                <span className="rounded border-[1px] border-primary p-1 text-primary text-xs">
                  {item?.store?.code}
                </span>
                <span className="ml-1 text-ellipsis overflow-hidden">
                  {item?.store?.name}
                </span>
              </div>
              <div className="text-gray-400 text-xs text-ellipsis overflow-hidden">
                {channel?.name}
              </div>
            </>
          );
        },
      },
      {
        title: t("customer"),
        dataIndex: "customer",
        width: 180,
        ellipsis: true,
        render: (customer: any) =>
          customer ? (
            <>
              <div className="text-ellipsis overflow-hidden">
                {customer?.company
                  ? customer?.company
                  : `${customer?.firstname} ${customer?.lastname}`}
              </div>
              <div>
                <span className="text-gray-400 text-xs text-ellipsis overflow-hidden">
                  {customer?.phone}
                </span>
              </div>
            </>
          ) : (
            "N/A"
          ),
      },
      {
        title: t("total"),
        dataIndex: "total",
        width: 120,
        render: (total: number, item: any) => (
          <>
            {StringUtils.moneyThaiFormat(total)}
            <div>
              <span className="text-gray-400 text-xs text-ellipsis overflow-hidden">
                {item?.total_item} {t("items")}
              </span>
            </div>
          </>
        ),
      },
      {
        title: t("orderStatus"),
        dataIndex: "status",
        ellipsis: true,
        width: 180,
        render: (status: string) => {
          const orderStatus = find(
            ORDER_STATUS_ARRAY,
            (item) => item.value === status
          );
          return (
            <LabelStatus
              content={t(`order:${orderStatus?.content}`)}
              color={orderStatus?.color}
            />
          );
        },
      },
      {
        title: t("logisticStatus"),
        dataIndex: "status_pickup",
        ellipsis: true,
        width: 125,
        render: (status_pickup: string, item: any) => {
          const pickUpStatus = FILTER_ORDER_PICKUP.find(
            (item) => item.id === status_pickup
          );
          if (!pickUpStatus) return "N/A";
          const label = t(pickUpStatus?.label);

          const deliveryName = item?.delivery?.map((item: any) => {
            const { delivery } = item;
            return delivery?.name;
          });
          const disPlayText = join(deliveryName, ", ");

          return (
            <div>
              <div>{disPlayText}</div>
              <LabelStatus content={label} color={pickUpStatus?.color} />
            </div>
          );
        },
      },
      {
        title: t("salesPerson"),
        dataIndex: "salesPerson",
        width: 200,
        ellipsis: true,
        render: (by: any) => <SalesPerson by={by} />,
      },
      {
        title: t("createdBy"),
        dataIndex: "by",
        ellipsis: true,
        width: 150,
        isDefault: false,
        render: (by: any) => <UserAvatarName user={by} />,
      },
      {
        title: t("pickupQty"),
        dataIndex: "total_pickup",
        ellipsis: true,
        width: 75,
        isDefault: false,
        render: (total_pickup: string) =>
          total_pickup === null ? "N/A" : total_pickup,
      },
      {
        title: t("expectedDateOfReceive"),
        dataIndex: "shipping",
        ellipsis: true,
        width: 100,
        isDefault: false,
        render: (shipping: any = []) => {
          const estimatedList = shipping?.map((item: any) => {
            const { expected_to_receive } = item;
            return !expected_to_receive
              ? "N/A"
              : TimeUtils.convertMiliToDateTime(expected_to_receive);
          });
          const disPlayText = join(estimatedList, ", ");

          return disPlayText;
        },
      },
      {
        title: t("estimatedDateArrival"),
        dataIndex: "delivery",
        ellipsis: true,
        width: 100,
        isDefault: false,
        render: (delivery: any = []) => {
          const estimatedList = delivery?.map((item: any) => {
            const { estimated } = item;
            return estimated === 0
              ? "N/A"
              : TimeUtils.convertMiliToDateTime(estimated);
          });
          const disPlayText = join(estimatedList, ", ");

          return disPlayText;
        },
      },
      {
        title: t("dateArrived"),
        dataIndex: "delivery",
        ellipsis: true,
        width: 100,
        isDefault: false,
        render: (delivery: any = []) => {
          const estimatedList = delivery?.map((item: any) => {
            const { arrived } = item;
            return arrived ? TimeUtils.convertMiliToDateTime(arrived) : "N/A";
          });
          const disPlayText = join(estimatedList, ", ");

          return disPlayText;
        },
      },
      {
        title: t("sourceOrder"),
        dataIndex: "source",
        width: 100,
        isDefault: false,
        render: (source: any) => source?.name,
      },
      {
        title: t("utmSource"),
        dataIndex: "tracking",
        width: 100,
        isDefault: false,
        render: (tracking: any) => tracking?.source ?? "N/A",
      },
      {
        title: t("utmMedium"),
        dataIndex: "tracking",
        width: 100,
        isDefault: false,

        render: (tracking: any) => tracking?.medium ?? "N/A",
      },
      {
        title: t("utmCampaign"),
        dataIndex: "tracking",
        width: 100,
        isDefault: false,

        render: (tracking: any) => tracking?.campaign ?? "N/A",
      },

      // {
      //   title: t("action"),
      //   dataIndex: "",
      //   width: 50,

      //   render: (item: any) => (
      //     // <CustomizedButtonOpenMenu
      //     //   startIcon={<Icon name="more_vert" />}
      //     //   options={ORDER_ACTION}
      //     //   choseOptionHandle={(option) => this.onChoseOptionProductHandle(option, item)}
      //     //   buttonStyle={{ backgroundColor: "white", boxShadow: "none" }}
      //     // />
      //     <Dropdown
      //       dataSource={ORDER_ACTION}
      //       onClick={(menu) => {
      //         if (menu.id === "view") {
      //           navigate(`/order/${item.id}`);
      //         }
      //         if (menu.id === "delete") {
      //           DialogManager.showConfirm(
      //             t("deleteOrder"),
      //             t("notification:areYouSureDeleteTheseOrders"),
      //             () => handleOnClickDeleteOrder([item.id])
      //           );
      //         }
      //       }}
      //     />
      //   ),
      // },
    ],
    [t]
  );

  const onClickDeleteRowOrder = () => {
    const ids = selectedOrderRows?.map((item: any) => item?.id);
    return DialogManager.showConfirm(
      t("deleteOrder"),
      t("notification:areYouSureDeleteTheseOrders"),
      () => handleOnClickDeleteOrder(ids)
    );
  };

  const handleOnClickDeleteOrder = (ids: string[]) => {
    return deleteOrder(ids)
      .then((respone) => {
        refreshTable();
        setSelectedOrderRows([]);
        Notifications.showSuccess(t("notification:ordersweredeleted"));
      })
      .catch((err: any) => {
        Notifications.showError(err?.message);
      });
  };

  const selectOrderModule = () => {
    return (
      <div className="border flex-center">
        <span className="px-3">{`${selectedOrderRows.length} ${t(
          "selected"
        )}`}</span>
        <Button onClick={onClickDeleteRowOrder}>{t("delete")}</Button>
      </div>
    );
  };

  const getQuantityOrder = (body: any) => {
    listSummaryQuantityOrder(body, {
      cancelToken: new CancelToken((c) => {
        // An executor function receives a cancel function as a parameter
        cancelSummary.current = c;
      }),
    }).then((response) => {
      const summary = response?.data?.data?.summary ?? {};
      setSummary(summary);
    });
  };

  const dataSource = (paging: any) => {
    setLoading(true);

    let filterData: any = {};
    if (!isEmpty(filterBody)) {
      const {
        channels = [],
        status = "",
        createdby = [],
        method = "",
        payment = "",
        warehouse = [],
        delivery = "",
        isPickup = "",
        isEmployeeDiscount = [],
        is_utm = "",
        suspicious = "",
        products = [],
        custom_size_detected = "",
      } = filterBody;
      const { salesPerson = [], ...restFilter } = filterBody;
      filterData = {
        ...restFilter,
        createdby: compact(createdby?.map((item: any) => item.id)),
        warehouse: compact(warehouse?.map((item: any) => item.id)),
        products: compact(products?.map((item: any) => item.id)),
        employee_discount_code: filterBody.employeeDiscount,
        isEmployeeDiscount: getIsEmployeeDiscountValue(isEmployeeDiscount),
        is_utm: getUTMValues(is_utm),
        sales_person_id: compact(salesPerson?.map((item: any) => item.id)),
        ...(status ? { status: [status] } : {}),
        ...(payment ? { payment: [payment] } : {}),
        ...(delivery ? { delivery: [delivery] } : {}),
        ...(isPickup ? { isPickup: [isPickup] } : {}),
        ...(method ? { method: [method] } : {}),
        ...(is_utm ? { is_utm: [is_utm] } : {}),
        ...(suspicious ? { suspicious: [suspicious] } : {}),
        ...(custom_size_detected
          ? { custom_size_detected: [custom_size_detected] }
          : {}),
      };
      if (channels.length === 0) {
        delete filterData.channels;
      }

      if (status.length === 0) {
        delete filterData.status;
      }

      if (createdby.length === 0) {
        delete filterData.createdby;
      }

      if (method.length === 0) {
        delete filterData.method;
      }

      if (payment.length === 0) {
        delete filterData.payment;
      }

      if (warehouse.length === 0) {
        delete filterData.warehouse;
      }

      if (delivery.length === 0) {
        delete filterData.delivery;
      }

      if (isPickup.length === 0) {
        delete filterData.isPickup;
      }
    }
    let body: any = {
      search,
      search_type: searchType,
      ...filterData,
      ...(selectedSource?.id === ORDER_SOURES[0]?.id
        ? {}
        : {
            order_type: selectedSource?.id,
          }),
    };

    getQuantityOrder(body);

    if (
      tabSelected &&
      !isEmpty(tabSelected) &&
      tabSelected.value !== ORDER_TABLE_STATUS_TAB["all"].value
    ) {
      if (
        tabSelected.value === ORDER_TABLE_STATUS_TAB["payment-rejected"].value
      ) {
        body = {
          ...body,
          payment: [
            ORDER_PAYMENT_STATUS.fullPaymentReject.id,
            ORDER_PAYMENT_STATUS.partiallyPaymentReject.id,
          ],
        };
      } else {
        body = { ...body, status: [tabSelected?.value] };
      }
    }

    return listAllOrder(body, paging, {
      cancelToken: new CancelToken((c) => {
        // An executor function receives a cancel function as a parameter
        cancelSource.current = c;
      }),
    }).then((resp) => {
      setLoading(false);
      return resp;
    });
  };

  const refreshTable = () => {
    cancelSummary.current && cancelSummary.current();
    cancelSource.current && cancelSource.current();
    setLoading(true);
    return (
      orderTableRef.current &&
      orderTableRef.current.refreshKeepPaging(pageParam ?? DEFAULT_PAGING)
    );
  };

  const onChangeFilter = (value: any) => {
    setFilterBody({
      ...filterBody,
      ...value,
    });
  };

  const renderFilterInputText = (label: string, key: string) => {
    return (
      <InputText
        placeholder={t(label)}
        value={filterBody?.[key]}
        onChange={(event) => onChangeFilter({ [key]: event.target.value })}
        onKeyDown={(e) => {
          if (e.code === "Enter") {
            refreshTable();
          }
        }}
      />
    );
  };

  const orderStatuses = useMemo(() => {
    return ORDER_STATUS_ARRAY.map((item) => ({
      ...item,
      count: summary?.[item.value],
    }));
  }, [ORDER_STATUS_ARRAY, summary, t]);

  useUpdateEffect(() => {
    refreshTable();
  }, [pageParam, tabSelected, selectedSource]);

  useEffect(() => {
    setTab(tabSelected?.value);
  }, [tabSelected]);

  useEffect(() => {
    setSource(selectedSource?.id);
  }, [selectedSource]);

  useEffect(() => {
    // loadUsers();
    loadChannels();
    loadDeliveryTypes();
    // loadStore();
    loadOrderPaymentMethods();
    if (!filterBody) {
      setFilterBody({});
    }
  }, []);

  const loadOrderPaymentMethods = () => {
    getOrderPaymentMethods({
      is_chain_external: false,
    }).then((res) => {
      const list = res?.data?.data?.method ?? [];
      setPaymentMethodsList(list);
    });
  };

  return (
    <AppContext.Provider
      value={{ channelList, deliveryTypes, loadChannels, loadDeliveryTypes }}
    >
      <div className="">
        <StyledHeaderTable
          label={t("order")}
          onClickNew={() => navigate(Path.NEW_ORDER)}
          onClickExport={() => {
            setShowFilter(true);
            setIsExport(true);
          }}
          className="app-layout__page-header bg-white"
          onChangeText={null}
          showFilter={false}
          customButtons={() => (
            <>
              <Button
                onClick={() => setOpenOrderShopeSelect(true)}
                className=""
              >
                {t("loadOrderMarketplace")}
              </Button>
            </>
          )}
        />
        <div className="px-3 pt-3">
          <div className="bg-white p-3">
            <div className="md:border-b mb-2 pb-[0px] md:pb-2">
              <FilterButtonGroup
                label="source"
                options={ORDER_SOURES}
                value={filters.source}
                showAll={false}
                onChange={(value) => {
                  setFilters({ ...filters, source: value });
                  const source = find(ORDER_SOURES, { id: value });
                  if (source) {
                    setPageParam(DEFAULT_PAGING);
                    setSelectedSource(source as any);
                    setSource((source as any).id);
                  }
                }}
              />
            </div>
            {filterBody && (
              <div className="md:border-b mb-2 pb-[0px] md:pb-2">
                <FilterButtonGroup
                  label={t("status")}
                  options={orderStatuses}
                  value={filterBody?.status}
                  onChange={(value) => {
                    onChangeFilter({ status: value });
                    refreshTable();
                  }}
                />
              </div>
            )}
            <div
              className={classNames("transition-all", {
                "max-h-0 overflow-hidden": !expanded,
                "max-h-[1500px] overflow-auto": expanded,
              })}
            >
              {filterBody && (
                <>
                  <div className="md:border-b mb-2 pb-[0px] md:pb-2">
                    <FilterButtonGroup
                      label={t("paymentMethod")}
                      options={paymentMethodsList}
                      value={filterBody?.method}
                      onChange={(value) => {
                        onChangeFilter({ method: value });
                        refreshTable();
                      }}
                      valueKey="id"
                      labelKey="label"
                      classNameOptions="flex-wrap"
                      classNameItem="mb-1"
                    />
                  </div>
                  <div className="md:border-b mb-2 pb-[0px] md:pb-2">
                    <FilterButtonGroup
                      label={t("paymentStatus")}
                      options={ORDER_PAYMENT_STATUS_LIST}
                      value={filterBody?.payment}
                      onChange={(value) => {
                        onChangeFilter({ payment: value });
                        refreshTable();
                      }}
                    />
                  </div>
                  <div className="flex md:border-b flex-col md:flex-row items-center md:pb-2 md:mb-2">
                    <div className="w-full mb-[0.5rem] md:mb-0 flex-1">
                      <FilterButtonGroup
                        valueKey="id"
                        label={t("deliveryType")}
                        options={deliveryTypes}
                        value={filterBody?.delivery}
                        labelKey="name"
                        onChange={(value) => {
                          onChangeFilter({ delivery: value });
                          refreshTable();
                        }}
                        className="w-full"
                      />
                    </div>
                    <div className="md:pl-4 w-full mb-[0.5rem] md:mb-0">
                      <FilterButtonGroup
                        label={t("pickup")}
                        options={FILTER_ORDER_PICKUP}
                        value={filterBody?.isPickup}
                        onChange={(value) => {
                          onChangeFilter({ isPickup: value });
                          refreshTable();
                        }}
                        className="mb-2 w-full"
                      />
                    </div>
                  </div>
                  <div className="flex md:border-b flex-col md:flex-row items-center md:pb-2 md:mb-2">
                    <div className="w-full mb-[0.5rem] md:mb-0 flex-1">
                      <FilterButtonGroup
                        valueKey="id"
                        label={t("utmOrders")}
                        options={YES_NO_OPTIONS}
                        value={filterBody?.is_utm}
                        labelKey="label"
                        onChange={(value) => {
                          onChangeFilter({ is_utm: value });
                          refreshTable();
                        }}
                      />
                    </div>
                    <div className="md:pl-4 w-full mb-[0.5rem] md:mb-0">
                      <FilterButtonGroup
                        label={t("isEmployeeDiscount")}
                        options={YES_NO_OPTIONS}
                        value={filterBody?.isEmployeeDiscount}
                        onChange={(value) => {
                          onChangeFilter({ isEmployeeDiscount: value });
                          refreshTable();
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex md:border-b flex-col md:flex-row items-center md:pb-2 md:mb-2">
                    <div className="w-full mb-[0.5rem] md:mb-0 flex-1">
                      <FilterButtonGroup
                        valueKey="id"
                        label={t("suspicious")}
                        options={ORDER_FILTER_SUSPICIOUS_STATUS_LIST}
                        value={filterBody?.suspicious}
                        labelKey="label"
                        onChange={(value) => {
                          onChangeFilter({ suspicious: value });
                          refreshTable();
                        }}
                      />
                    </div>
                    <div className="md:pl-4 w-full mb-[0.5rem] md:mb-0">
                      <FilterButtonGroup
                        valueKey="id"
                        labelKey="label"
                        label={t("customSizeApproval")}
                        options={ORDER_FILTER_CUSTOM_SIZE}
                        value={filterBody?.custom_size_detected}
                        onChange={(value) => {
                          onChangeFilter({ custom_size_detected: value });
                          refreshTable();
                        }}
                      />
                    </div>
                  </div>
                  <div className="grid grids-cols-1 md:grid-cols-4 gap-2">
                    <div className="col-span-1 md:col-span-2">
                      <SelectWithInput
                        prefix={
                          <>
                            <TranslatedSelect
                              onChange={(value) => {
                                setSearchType(value);
                              }}
                              dataSource={ORDER_SEARCH_OPTIONS}
                              value={searchType}
                              getValue={(item) => item.id}
                              getKey={(item) => item.id}
                              allowClear={false}
                              style={{ width: 200 }}
                            />
                          </>
                        }
                        placeholder={t("search")}
                        suffix={<Icon name="search" />}
                        value={search || ""}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.code === "Enter") {
                            refreshTable();
                          }
                        }}
                      />
                    </div>
                    <div className="col-span-1">
                      <DateInput
                        placeholder={t("from")}
                        value={filterBody.from ? moment(filterBody.from) : null}
                        onChange={(value) => {
                          onChangeFilter({ from: value?.valueOf() ?? null });
                          refreshTable();
                        }}
                        format="DD/MM/YYYY"
                        classNameInput="w-full"
                      />
                    </div>
                    <div className="col-span-1">
                      <DateInput
                        placeholder={t("to")}
                        value={filterBody.to ? moment(filterBody.to) : null}
                        onChange={(value) => {
                          onChangeFilter({ to: value?.valueOf() ?? null });
                          refreshTable();
                        }}
                        format="DD/MM/YYYY"
                        classNameInput="w-full"
                      />
                    </div>
                    <div className="col-span-1">
                      <SelectFilter
                        key="selectChannel"
                        value={filterBody.channels}
                        getLabel={(item) => item?.name}
                        onChange={(val) => {
                          onChangeFilter({ channels: val });
                        }}
                        placeholder={t("channel")}
                        tagTitle={t("channel")}
                        valueKey="name"
                        dataSource={channelList}
                        showSearch
                        multiple
                        onDropdownVisibleChange={(open) => {
                          if (!open) {
                            refreshTable();
                          }
                        }}
                        onClear={() => {
                          refreshTable();
                        }}
                      />
                    </div>
                    <div className="col-span-1">
                      <SelectInfinityFilterMultiple
                        fetchFn={(body: any, paging: any) => {
                          return listAllStore(
                            { search: body.search },
                            {
                              pageIndex: paging.page,
                              pageSize: paging.per_page,
                            }
                          );
                        }}
                        key="selectWarehouse"
                        dataPath="stores"
                        value={filterBody.warehouse}
                        customQuery={(search) => ({
                          // channel: filters.channel ?? [],
                          search,
                        })}
                        getLabel={(item) => item?.name}
                        dropdownHeight={300}
                        onChange={(val) => {
                          onChangeFilter({ warehouse: val });
                        }}
                        placeholder={t("warehouse")}
                        multiple
                        mode="multiple"
                        valueKey="value"
                        tagTitle={t("warehouse")}
                        onDropdownVisibleChange={(open) => {
                          if (!open) {
                            refreshTable();
                          }
                        }}
                        onClear={() => refreshTable()}
                      />
                    </div>
                    <div className="col-span-1">
                      <SelectInfinityFilterMultiple
                        fetchFn={(body: any, paging: any) => {
                          return listAllUser(
                            { search: body.search },
                            {
                              pageIndex: paging.page,
                              pageSize: paging.per_page,
                            }
                          );
                        }}
                        key="selectCreatedBy"
                        dataPath="users"
                        value={filterBody?.createdby}
                        customQuery={(search) => ({
                          search,
                        })}
                        dropdownHeight={300}
                        onChange={(val) => {
                          onChangeFilter({
                            createdby: val?.map((item: any) =>
                              omit(item, "avatar")
                            ),
                          });
                        }}
                        placeholder={t("createdBy")}
                        multiple
                        mode="multiple"
                        valueKey="id"
                        tagTitle={t("createdBy")}
                        onDropdownVisibleChange={(open) => {
                          if (!open) {
                            refreshTable();
                          }
                        }}
                        onClear={() => refreshTable()}
                        getLabel={(item) => (
                          <div className="flex items-center">
                            {item.avatar && (
                              <>
                                <UserAvatarName
                                  user={item}
                                  size="xx-small"
                                  text=" "
                                />{" "}
                              </>
                            )}
                            {`${item.companyId}_${item?.name}`}
                          </div>
                        )}
                        transformer={(res) => {
                          const data = res?.data?.data?.users ?? [];
                          let transform = [];
                          if (data?.length > 0) {
                            transform = data?.map((item: any) => ({
                              id: item.id,
                              name: item.name,
                              companyId: item.companyId,
                              avatar: item.avatar,
                            }));
                          }
                          return transform;
                        }}
                      />
                    </div>
                    <div className="col-span-1">
                      <SelectInfinityFilterMultiple
                        fetchFn={(body: any, paging: any) => {
                          return searchAllProduct({
                            pageIndex: paging.page,
                            pageSize: paging.per_page,
                            search: body.search || "",
                          });
                        }}
                        key="selectProducts"
                        dataPath="product"
                        value={filterBody?.products}
                        customQuery={(search) => ({
                          search,
                        })}
                        dropdownHeight={300}
                        onChange={(val) => {
                          onChangeFilter({
                            products: val.filter((item: any) => isObject(item)),
                          });
                        }}
                        placeholder={t("products")}
                        multiple
                        mode="multiple"
                        valueKey="id"
                        tagTitle={t("products")}
                        onDropdownVisibleChange={(open) => {
                          if (!open) {
                            refreshTable();
                          }
                        }}
                        onClear={() => refreshTable()}
                        getLabel={(item) => (
                          <div className="flex flex-column">
                            <span>{`${item?.name}`}</span>
                            <span className="text-xs">
                              SKU: {`${item?.sku}`}
                            </span>
                          </div>
                        )}
                        transformer={(res) => {
                          const data = res?.data?.data?.product ?? [];
                          let transform = [];
                          if (data?.length > 0) {
                            transform = data?.map((item: any) => ({
                              id: item.productid,
                              name: item?.product?.name,
                              sku: item?.product?.sku,
                            }));
                          }
                          return transform;
                        }}
                        styleTagItem={{ width: "auto" }}
                      />
                    </div>
                    <div className="col-span-1">
                      {renderFilterInputText("cartRuleId", "cartruleid")}
                    </div>
                    <div className="col-span-1">
                      {renderFilterInputText("couponCode", "coupon")}
                    </div>
                    <div className="col-span-1">
                      {renderFilterInputText(
                        "employeeDiscount",
                        "employeeDiscount"
                      )}
                    </div>
                    <div className="col-span-1">
                      {renderFilterInputText("utmSource", "utm_source")}
                    </div>
                    <div className="col-span-1">
                      {renderFilterInputText("utmMedium", "utm_medium")}
                    </div>
                    <div className="col-span-1">
                      {renderFilterInputText("utmCampaign", "utm_campaign")}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="flex items-center justify-center mt-2">
              <Button
                className="text-primary cursor-pointer flex items-center rounded-full"
                style={{ borderRadius: "999px" }}
                onClick={() => setExpanded(!expanded)}
                size="small"
                variant="outline"
              >
                {expanded ? t("collapse") : t("expand")}
                <Icon name={expanded ? "expand_less" : "expand_more"} />
              </Button>
              <Button
                iconName="cached"
                size="small"
                // variant="trans"
                className="rounded-full ml-2"
                style={{ borderRadius: "999px" }}
                onClick={() => {
                  setFilterBody({});
                  setSelectedSource(undefined);
                  setFilters({
                    source: ORDER_SOURES[0].value,
                  });
                  setTimeout(() => {
                    refreshTable();
                  });
                }}
              >
                <span className="font-normal">{t("resetFilter")}</span>
              </Button>
            </div>
          </div>
        </div>
        <div className="px-3 pt-0 pb-3">
          <StyledOrderTable className="relative">
            <AwesomeTableComponent
              classNameTable="order-table"
              source={dataSource}
              transformer={(response) => {
                return response?.data?.data?.orders ?? [];
              }}
              columns={columns}
              // rowSelection={{
              //   onChange: (selectedRowKeys, selectedRows) => {
              //     setSelectedOrderRows(selectedRows);
              //   },
              // }}
              ref={orderTableRef}
              setCurrentPage={setPageParam}
              showSelectColumn
              keyTableLayout={ORDER_TABLE_LAYOUT_KEY}
              // fixedSize={false}
              tableLayout="fixed"
              className="mt-3"
              // onSelectionView={selectOrderModule}
              // selectingRows={selectedOrderRows}
            />
            {loading && (
              <div className="absolute top-0 left-0 w-full h-full flex items-center justify-content-center bg-white/50">
                <Spin />
              </div>
            )}
          </StyledOrderTable>
        </div>
      </div>
      {showFilter &&
        (isExport ? (
          <ExportOrderModal
            open={showFilter}
            onClose={(applyFilter?: boolean) => {
              setShowFilter(false);
            }}
          />
        ) : (
          <FilterOrderModal
            openModal={showFilter}
            onChangeFilter={onChangeFilter}
            filterBody={filterBody}
            setFilterBody={setFilterBody}
            onClose={(applyFilter?: boolean) => {
              setShowFilter(false);
              if (applyFilter) {
                refreshTable();
              }
            }}
          />
        ))}
      {openOrderShopeSelect && (
        <OrderShopeModal
          open={openOrderShopeSelect}
          onClose={() => setOpenOrderShopeSelect(false)}
        />
      )}
    </AppContext.Provider>
  );
};

const StyledHeaderTable = styled(HeaderTable)`
  .d-input-search {
    display: none;
  }
`;

const StyledOrderTable = styled.div`
  .ant-table-thead {
    & > tr {
      & > th {
        padding: 10px 10px;
      }
    }
  }
  .ant-table-tbody {
    & > tr {
      & > td {
        padding: 10px 10px;
        @media screen and (max-width: 1000px) {
          padding-left: 40%;
        }
      }
    }
  }
`;

const StyledBadge = styled.div`
  .d-badge__badge-wrapper div {
    font-size: 8px !important;
  }
`;

export default Order;
