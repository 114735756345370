import AppContext from "@helpers/context";
import { Avatar } from "d-react-components";
import React from "react";
import { useTranslation } from "react-i18next";

const PromotionDetailCreatedBy = () => {
  const { t } = useTranslation();
  const { promotionDetail } = React.useContext(AppContext);
  const { by } = promotionDetail || {};
  return (
    <div className="bg-white p-3 mb-3 shadow-sm">
      <label className="block text-sm">{t("createdBy")}</label>
      <div className="flex items-center">
        <Avatar
          src={by?.avatar || "/images/img-default.png"}
          className="rounded-sm overflow-hidden"
          variant="square"
          size="small"
        />
        <div className="block ml-2">
          <div>
            {by?.fullname} ({by?.name})
          </div>
          <div>ID: {by?.employee_id || by?.companyId}</div>
        </div>
      </div>
    </div>
  );
};

export default PromotionDetailCreatedBy;
