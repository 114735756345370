import AppContext from "@helpers/context";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import CartRuleActionForm from "../component/CartRuleActionForm";

export default function CartRuleCreateAction() {
  const { cartRuleForm } = useContext(AppContext);
  const { t } = useTranslation();
  const formValues = cartRuleForm?.values ?? {};

  return (
    <div className="mt-3">
      <div className="label">{t("action")}</div>
      <div className="p-3 border mt-2">
        <CartRuleActionForm form={cartRuleForm} />
      </div>
    </div>
  );
}
