import AppContext from "@helpers/context";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import ChannelFormEmailConfig from "../components/ChannelFormEmailConfig";

const ChannelCreateEmailConfiguration = () => {
  const { channelForm } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <div className="card-container p-4">
      <ChannelFormEmailConfig form={channelForm} />
    </div>
  );
};

export default ChannelCreateEmailConfiguration;
