import React, { useContext } from "react";
import ConditionList from "./ConditionList";
import { map } from "lodash";
import { CART_RULE_CONDITION_DEFAULT } from "@constants/cart-rule";
import { useTranslation } from "react-i18next";
import ConditionApplyType from "./ConditionApplyType";
import { Icon } from "d-react-components";

const CombineCondition = ({
  conditionOptions,
  index,
  condition,
  setChangeCondition,
  conditionParentList,
  parentIndex,
  handleDeleteCondition,
}: any) => {
  const { t } = useTranslation();
  const onChangeConditions = (conditionChildList: any) => {
    const resultList = map(conditionParentList, (conditionItem) => {
      if (conditionItem.id === condition.id) {
        return { ...condition, conditions: conditionChildList };
      }
      return conditionItem;
    });
    setChangeCondition(resultList);
  };

  const addConditionHandle = (option: any) => {
    const clone = [...condition.conditions];
    clone.push({
      ...(CART_RULE_CONDITION_DEFAULT as any)[option.key],
      id: Math.random(),
    });

    const resultList = map(conditionParentList, (conditionItem) => {
      if (conditionItem.id === condition.id) {
        return { ...condition, conditions: clone };
      }
      return conditionItem;
    });

    setChangeCondition(resultList);
  };

  const onChangeRuleCondition = (value: any) => {
    const resultList = map(conditionParentList, (conditionItem) => {
      if (conditionItem.id === condition.id) {
        return { ...condition, rule: value };
      }
      return conditionItem;
    });
    setChangeCondition(resultList);
  };

  const getIndex = () => {
    if (parentIndex) {
      return `${parentIndex}.${index + 1}`;
    }
    return index + 1;
  };

  return (
    <div className="mt-2 border border-primary">
      <div className="flex-row-between-center bg-primary px-2 py-3 ">
        <div className="flex-row align-items-center">
          <text className="font-weight-bold text-white">
            {`${t("condition")} ${getIndex()}: `}
          </text>
        </div>
        <Icon
          name="remove_circle"
          color="white"
          onClick={handleDeleteCondition}
        />
      </div>
      <div className="p-3">
        <ConditionApplyType
          value={condition.rule}
          onChangeApplyType={onChangeRuleCondition}
        />
        <ConditionList
          conditions={condition.conditions}
          setChangeCondition={onChangeConditions}
          addConditionHandle={addConditionHandle}
          parentIndex={getIndex()}
          conditionOptions={conditionOptions}
        />
      </div>
    </div>
  );
};

export default CombineCondition;
