/* eslint-disable jsx-a11y/alt-text */
import { Path } from "@components/layout/Path";
import ChannelList from "@components/my-assets/requests/ChannelList";
import UserAvatarName from "@components/shared/UserAvatarName";
import AppContext from "@helpers/context";

import {
  AwesomeTableComponent,
  Button,
  IColumnsProps,
  StringUtils,
  TimeUtils,
  ViewLabelStatus,
  useFirstTime,
} from "d-react-components";

import SelectFilter from "@common/dropdown/SelectFilter";
import {
  DEPOSIT_FILTER_STATUSES,
  DEPOSIT_STATUS_LIST,
} from "@constants/cash-deposit";
import { IChannel } from "@interfaces/channel";
import { getListChannel } from "@network/api/channel";
import { map } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, generatePath, useNavigate } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import CashDepositAPI from "@network/api/cash-deposit";
import InputSelectKey from "@common/input/InputSelectKey";
import { IStore } from "@interfaces/store";
import { listUserStore } from "@network/api/store";
import { SelectInfinity } from "@components/shared/SelectInfinity";
import WarehouseInfo from "@components/shared/WarehouseInfo";
import StoreDropdownSelect from "@components/store/components/StoreDropdownSelect";

const CashDepositTable = () => {
  const [channelList, setChannelList] = useState<IChannel[]>([]);
  const [storeList, setStoreList] = useState<IStore[]>([]);
  const tableRef = useRef<any>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showChannels, setShowChannels] = useState({
    open: false,
    channels: [],
  });

  const CASH_SEARCH_KEYS = [{ id: "id", label: t("id") }];

  const [
    filterBody = {
      searchType: CASH_SEARCH_KEYS[0].id,
    },
    setFilterBody,
  ] = useQueryParam<any>("filter", JsonParam);

  const isFirstTime = useFirstTime();

  useEffect(() => {
    loadChannelList();
  }, []);

  useEffect(() => {
    loadStoreList();
  }, [filterBody.channel]);

  const loadStoreList = () => {
    // const channelid = channelId === ALL ? null : channelId;
    listUserStore({ channelid: filterBody.channel }).then((res: any) => {
      const result = res?.data?.data?.stores ?? [];
      setStoreList(result);
    });
  };

  const loadChannelList = () => {
    getListChannel().then((res) => {
      const channelList = res?.data?.data?.channel ?? [];
      setChannelList(channelList);
    });
  };

  useEffect(() => {
    if (isFirstTime) return;
    tableRef.current?.refresh();
  }, [JSON.stringify(filterBody)]);

  const onChangeFilterBody = (newValue: any) => {
    setFilterBody({ ...(filterBody ?? {}), ...newValue });
  };

  const source = (paging: any) => {
    const { textSearch, store, status, channel } = filterBody;
    const body = {
      search: textSearch,
      stores: store,
      status: status && status !== "all" ? [status] : null,
      channels: channel,
    };

    return CashDepositAPI.list(body, paging);
  };

  const columns: IColumnsProps = [
    {
      title: t("id"),
      dataIndex: "ref",

      render: (ref, item) => (
        <Link
          to={generatePath(Path.CASH_DEPOSIT_DETAIL, {
            cashDepositId: item.id,
          })}
        >
          {ref}
        </Link>
      ),
    },
    {
      title: t("store"),
      dataIndex: "store",

      render: (store, item) => `${store?.name} - ${store?.code ?? ""}`,
    },
    {
      title: t("transactionClosingDate"),
      dataIndex: "transaction_closing_date",
      render: (date, item) => TimeUtils.toDateTime(date),
    },

    {
      title: t("expectedDepositDate"),
      dataIndex: "expected_deposit_date",
      render: (date, item) => TimeUtils.toDateTime(date),
    },
    {
      title: t("amount"),
      dataIndex: "amount",

      render: (amount, item) => StringUtils.moneyThaiFormat(amount),
    },
    {
      title: t("status"),
      dataIndex: "status",

      render: (status, item) => {
        return (
          <ViewLabelStatus
            status={status}
            listStatus={DEPOSIT_STATUS_LIST}
            getLabel={(item) => t(item.label)}
          />
        );
      },
    },
    {
      title: t("createdBy"),
      dataIndex: "by",
      render: (by) => <UserAvatarName user={by} />,
    },
    {
      title: t("createdDate"),
      dataIndex: "created",

      render: (created, item) => TimeUtils.toDateTime(created),
    },
  ];

  const renderHeader = useMemo(() => {
    return (
      <div className="w-full border px-3">
        <div className="flex-center-y py-3 border-b justify-between">
          <div className="flex-row align-items-center">
            <div className="flex-center-y gap-3">
              <h5 className="m-0">{t("status")}</h5>
              {map(DEPOSIT_FILTER_STATUSES, (status) => {
                const isSelect =
                  filterBody?.status === status?.id ||
                  (status?.id === "all" && !filterBody?.status);
                return (
                  <Button
                    variant={isSelect ? "standard" : "outline"}
                    onClick={() => {
                      onChangeFilterBody({ status: status?.id });
                    }}
                  >
                    {t(status?.label)}
                  </Button>
                );
              })}
            </div>
          </div>
          <div className="flex-center-y">
            <Button
              iconName="add"
              onClick={() => {
                navigate(Path.CASH_DEPOSIT_CREATE);
              }}
            >
              {t("new")}
            </Button>
          </div>
        </div>
        <div className="d-flex flex-center-y gap-3 w-100 py-3">
          {/* <ChannelSelect
            showLabel={false}
            onChange={(v) => {
              console.log("v", v);
              onChangeFilterBody({ channel: v });
            }}
            value={filterBody?.channel}
            style={{ width: "300px" }}
            placeholder={t("channel")}
            multiple
          /> */}
          <SelectFilter
            key="selectChannel"
            value={filterBody.channel}
            getLabel={(item) => item?.name}
            onChange={(val) => {
              onChangeFilterBody({ channel: val });
            }}
            placeholder={t("channel")}
            tagTitle={t("channel")}
            valueKey="name"
            dataSource={channelList}
            showSearch
            multiple
            style={{ width: "300px" }}
            onDropdownVisibleChange={(open) => {
              if (!open) {
                // onClickFilter();
              }
            }}
            // onClear={() => onClickFilter()}
          />
          <StoreDropdownSelect
            channelIds={filterBody.channel}
            className="w-min-content ml-3"
            value={filterBody.store}
            onChange={(value) => {
              onChangeFilterBody({ store: value });
            }}
          />
          {/* <SelectFilter
            key="selectStore"
            value={filterBody.store}
            getLabel={(item) => item?.name}
            onChange={(val) => {
              onChangeFilterBody({ store: val });
            }}
            placeholder={t("store")}
            tagTitle={t("store")}
            valueKey="name"
            dataSource={storeList}
            showSearch
            multiple
            style={{ width: "300px" }}
            onDropdownVisibleChange={(open) => {
              if (!open) {
                // onClickFilter();
              }
            }}
            // onClear={() => onClickFilter()}
          /> */}
          {/* <SelectInfinity
            fetchFn={listUserStore}
            key="selectWarehouse"
            dataPath="stores"
            value={filterBody.store}
            // error={errors?.warehouse}
            // label={t("warehouse")}
            customQuery={(search) => ({
              search,
            })}
            getLabel={(item) => item?.name}
            dropdownHeight={540}
            getLabelDropdownItem={(item) => <WarehouseInfo warehouse={item} />}
            onChange={(val) => {
              onChangeFilterBody({ store: val });
            }}
            placeholder={t("pleaseSelect")}
            className="mb-3"
            style={{ width: "300px" }}
          /> */}
          <InputSelectKey
            className="w-100"
            placeholder="Search..."
            selectProps={{ placeholder: t("select") }}
            selectKey={filterBody.searchType}
            dataSource={CASH_SEARCH_KEYS}
            getLabel={(i) => t(i?.label)}
            onChangeSelectKey={(v) => {
              onChangeFilterBody({ searchType: v });
            }}
            onSearch={(search: any) => {
              onChangeFilterBody({ textSearch: search });
            }}
            showSearch={false}
            inputProps={{}}
          />
        </div>
      </div>
    );
  }, [filterBody]);

  return (
    <AppContext.Provider value={{}}>
      <div className="bg-white">
        {renderHeader}
        <AwesomeTableComponent
          source={source}
          transformer={(res) => res?.data?.data?.deposit ?? []}
          isScroll={false}
          columns={columns}
          ref={(ref) => (tableRef.current = ref)}
        />
        {showChannels.open && (
          <ChannelList
            open={showChannels.open}
            channelList={showChannels.channels}
            onClose={() => setShowChannels({ open: false, channels: [] })}
          />
        )}
      </div>
    </AppContext.Provider>
  );
};

export default CashDepositTable;
