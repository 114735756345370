import InputSelectForm from "@common/input/InputSelectForm";
import InputTextForm from "@common/input/InputTextForm";
import { Path } from "@components/layout/Path";

import ChannelSelect from "@components/channel/components/ChannelSelect";
import StoreSelect from "@components/store/components/StoreSelect";
import { EVENT_VISIBILITY_STATUSES } from "@constants/inventory-control-event";
import AppContext from "@helpers/context";
import { createInventoryControlEvent } from "@network/api/inventory-control-event";
import { Button, DateInput, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import * as Yup from "yup";

const EventCreateSchema = Yup.object().shape({
  title: Yup.string().required("Required field!"),
  visibility: Yup.string().required("Required field!"),
  // chain: Yup.object().required("Required field!"),
  remark: Yup.string().required("Required field!"),
  period: Yup.array().required("Required field!"),
  warehouse: Yup.object().required("Required field!"),
});

const mapEventToServer = (event: any) => {
  const { chain, period, warehouse, channel, ...rest } = event;
  return {
    ...rest,
    chain_id: chain?.id,
    from: period?.[0]?.valueOf(),
    to: period?.[1]?.valueOf(),
    warehouse_id: warehouse?.id,
    channel_id: channel?.id,
  };
};

const EventCreateGeneral = () => {
  const { formValues, eventForm, formErrors } = useContext(AppContext);

  const { t } = useTranslation();

  const classNameField = "col-span-12 mt-3";
  return (
    <div className="card-container grid p-4">
      <InputTextForm
        keyData="title"
        form={eventForm}
        className={classNameField}
        required
      />
      {/* <ChainSelect
        value={eventForm?.values.chain}
        className={classNameField}
        onChange={(value) => eventForm.setFieldValue("chain", value)}
      /> */}
      <ChannelSelect
        value={eventForm?.values.channel}
        className={classNameField}
        onChange={(value) => eventForm.setFieldValue("channel", value)}
      />
      <StoreSelect
        className={classNameField}
        value={eventForm.values.warehouse}
        onChange={(value: any) => {
          eventForm.setFieldValue("warehouse", value);
        }}
        channelId={eventForm?.values.channel?.id}
      />

      <InputSelectForm
        keyData="visibility"
        form={eventForm}
        className={classNameField}
        dataSource={EVENT_VISIBILITY_STATUSES}
        getLabel={(item: any) => t(item.label)}
        required
      />
      <DateInput
        isRangePicker
        value={formValues?.period}
        onChange={(value) => eventForm.setFieldValue("period", value)}
        label={t("period")}
        className={classNameField}
        error={formErrors?.period}
        required
        showTime
        format="DD/MM/YYYY HH:mm"
      />
      <InputTextForm
        keyData="remark"
        form={eventForm}
        className={classNameField}
        multiple
        required
      />
    </div>
  );
};

const InventoryControlEventCreate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const eventForm = useFormik({
    initialValues: {},
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: EventCreateSchema,
    onSubmit: (values) => {
      const input = mapEventToServer(values);
      Progress.show(
        { method: createInventoryControlEvent, params: [input] },
        (res: any) => {
          Notifications.showSuccess(t("createEventSuccessfully"));
          navigate(
            generatePath(Path.INVENTORY_CONTROL_EVENT_DETAIL, {
              eventId: res?.data?.data?.control?.id,
            })
          );
        }
      );
    },
  });

  const formValues = eventForm?.values;
  const formErrors = eventForm?.errors;

  return (
    <AppContext.Provider value={{ formValues, eventForm, formErrors }}>
      <div className="flex-column-container bg-trans">
        <div className="card-container p-4 flex-center justify-content-between">
          <h4>{t("newEvent")}</h4>
          <Button onClick={() => eventForm.handleSubmit()} className="mr-3">
            {t("save")}
          </Button>
        </div>
      </div>
      <div className="p-3">
        <EventCreateGeneral />
      </div>
    </AppContext.Provider>
  );
};

export default InventoryControlEventCreate;
