import { Path } from "@components/layout/Path";
import { CHANNEL_STATUSES } from "@constants/channel";
import AppContext from "@helpers/context";
import ChannelAPI from "@network/api/channel";
import RoleAPI from "@network/api/role";
import { getWarehouseList } from "@network/api/store";
import { Dropdown } from "antd";
import {
  AwesomeTableComponent,
  Button,
  HeaderTable,
  IColumnsProps,
  TimeUtils,
  ViewLabelStatus,
} from "d-react-components";
import { debounce } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import ChannelTableFilterDrawer from "./ChannelTableFilterDrawer";

const ChannelTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openFilter, setOpenFilter] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [warehouseList, setWarehouseList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  // const [filterBody, setFilterBody] = useState({});
  const [filterBody = {}, setFilterBody] = useQueryParam(
    "filterBody",
    JsonParam
  );

  const tableRef = useRef<any>();
  const searchRef = useRef<any>();

  useEffect(() => {
    loadWarehouse();
    loadRoleList();
  }, []);

  const isFiltered = useMemo(
    () => Object.keys(filterBody).length > 0,
    [filterBody]
  );

  const loadWarehouse = () => {
    getWarehouseList().then((res) => {
      const warehouseList = res?.data?.data?.warehouse;
      setWarehouseList(warehouseList);
    });
  };

  const loadRoleList = () => {
    RoleAPI.list().then((res) => {
      const roleList = res?.data?.data?.roles ?? [];
      setRoleList(roleList);
    });
  };

  const actions = useMemo(() => {
    return [
      {
        key: "edit",
        label: t("edit"),
      },
    ];
  }, [t]);

  const onHandleAction = (item: any) => {
    if (item.id === "edit") {
      navigate(generatePath(Path.CHANNEL_DETAIL, { channelId: item.id }));
    }
  };

  const columns: IColumnsProps = [
    {
      title: t("name"),
      dataIndex: "name",
      render: (name, channel) => (
        <a href={generatePath(Path.CHANNEL_DETAIL, { channelId: channel?.id })}>
          {name ?? "N/A"}
        </a>
      ),
    },
    {
      title: t("systemCode"),
      dataIndex: "code",
    },
    {
      title: t("company"),
      dataIndex: "company",
    },

    {
      title: t("status"),
      dataIndex: "status",
      render: (status) => {
        return (
          <ViewLabelStatus
            status={status}
            listStatus={CHANNEL_STATUSES}
            getLabel={(item) => t(item.label)}
          />
        );
      },
    },

    {
      title: t("lastUpdated"),
      dataIndex: "updated",
      render: TimeUtils.convertMiliToDateTime,
    },
    // {
    //   title: t("action"),
    //   dataIndex: "",
    //   render: (item) => (
    //     <Dropdown
    //       trigger={["click"]}
    //       menu={{ items: actions, onClick: onHandleAction }}
    //     >
    //       <Button
    //         variant="trans"
    //         content={""}
    //         suffixIcon="more_vert"
    //         size="small"
    //         className="ml-2"
    //       />
    //     </Dropdown>
    //   ),
    // },
  ];

  const source = (paging: any) => {
    const { status } = filterBody;
    const body = {
      search: searchRef.current,
      status,
    };
    return ChannelAPI.list(body, paging);
  };

  const transformer = (res: any) => {
    return res?.data?.data?.channels ?? [];
  };

  const onChangeSearch = debounce((text) => {
    searchRef.current = text;
    tableRef?.current?.refresh();
  }, 1000);

  const onFilter = (values: any) => {
    setFilterBody(values);
    tableRef?.current?.refresh();
  };

  return (
    <AppContext.Provider value={{ warehouseList, roleList }}>
      <div className="bg-white">
        <HeaderTable
          label={t("allChannels")}
          onChangeText={(event: any) => onChangeSearch(event.target.value)}
          onClickNew={() => navigate(Path.CHANNEL_CREATE)}
          onClickFilter={() => setOpenFilter(true)}
          // onClickExport={() => setOpenExport(true)}
          isFiltered={isFiltered}
          className="app-layout__page-header"
        />
        <AwesomeTableComponent
          ref={(ref) => {
            tableRef.current = ref;
          }}
          columns={columns}
          source={source}
          transformer={transformer}
        />
        {openFilter && (
          <ChannelTableFilterDrawer
            open={openFilter}
            onClose={() => setOpenFilter(false)}
            onFilter={onFilter}
            defaultValue={filterBody}
          />
        )}
        {/* {openExport && (
          <ChannelTableExportDrawer
            open={openExport}
            onClose={() => setOpenExport(false)}
          />
        )} */}
      </div>
    </AppContext.Provider>
  );
};

export default ChannelTable;
