import AddressFormInput from "@components/address/components/AddressFormInput";
import Drawer from "@components/shared/Drawer";
import WarehouseAPI from "@network/api/warehouse";
import { Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

function WarehouseDetailAddressUpdateDrawer({
  open,
  onClose,
  warehouseId,
  defaultValue,
  onUpdateSuccess,
}: any) {
  const { t } = useTranslation();
  const addressForm = useFormik({
    initialValues: defaultValue ?? {},
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: WarehouseGeneralSchema,
    onSubmit: (values) => {
      const body = {
        id: warehouseId,
        province_id: values?.province?.id,
        city_id: values?.district?.id,
        subdistrict_id: values?.subDistrict?.id,
        postcode: values?.postCode,
        address: values?.address,
        longitude: `${values?.longitude}`,
        latitude: `${values?.latitude}`,
      };
      Progress.show({ method: WarehouseAPI.update, params: [body] }, () => {
        Notifications.showSuccess(t("updateWarehouseSuccess"));
        onUpdateSuccess();
        onClose();
      });
    },
  });
  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("updateWarehouse")}
      onSave={() => addressForm.handleSubmit()}
      size="auto"
      width="30vw"
    >
      <div className="row">
        <AddressFormInput
          formDataValue={addressForm.values}
          formDataError={addressForm.errors}
          onChange={(newValue: any) => addressForm.setValues(newValue)}
          show={{ typeOfPlace: false, map: true }}
          fieldClassName="col-12"
        />
      </div>
    </Drawer>
  );
}

export default WarehouseDetailAddressUpdateDrawer;
