import InputTextForm from "@common/input/InputTextForm";
import Drawer from "@components/shared/Drawer";
import { useTranslation } from "react-i18next";

const DeliveryReturnConfirmDrawer = ({
  open,
  onClose,
  form,
  description,
}: any) => {
  const { t } = useTranslation();
  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("confirm")}
      showCancel={false}
      onSave={() => form.handleSubmit()}
    >
      <div>
        <div>{description}</div>
        <InputTextForm form={form} keyData="remark" className="mt-3" multiple />
      </div>
    </Drawer>
  );
};

export default DeliveryReturnConfirmDrawer;
