import Drawer from "@components/shared/Drawer";
import { searchCustomer } from "@network/api/customer";
import {
  Avatar,
  AwesomeListComponent,
  Checkbox,
  Icon,
  InputText,
  ObjectUtils,
} from "d-react-components";
import { debounce, find } from "lodash";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const CustomerSelectDrawer = ({
  open,
  onClose,
  defaultSelected,
  onSave,
  channelId,
  multiple = true,
}: any) => {
  const [customerSelected, setCustomerSelected] = useState(defaultSelected);
  const textSearchRef = useRef("");
  const customerListRef = useRef<any>();
  const { t } = useTranslation();

  const source = (pagingData: any) => {
    const body: any = { search: textSearchRef.current };
    if (channelId) {
      body["channel_id"] = channelId;
    }
    return searchCustomer(body, {
      page: pagingData.pageIndex,
    });
  };

  const transformer = (res: any) => {
    return res?.data?.data?.customers ?? [];
  };

  const isSelectedCustomer = (customer: any) => {
    if (!multiple) {
      return customerSelected && customer.id === customerSelected.id;
    }
    return !!find(customerSelected, (item) => item.id === customer.id);
  };

  const onSelectCustomer = (customer: any, isSelected: boolean) => {
    if (!multiple) {
      setCustomerSelected(customer);
      return;
    }

    if (isSelected) {
      setCustomerSelected(
        ObjectUtils.removeArrayById(customerSelected, customer)
      );
      return;
    }

    setCustomerSelected([...customerSelected, customer]);
  };

  const renderItem = (customer: any, index: number) => {
    const isSelected = isSelectedCustomer(customer);
    const { firstname, lastname, nickname, email, avatar } = customer;

    return (
      <div
        className="flex items-center mb-3"
        onClick={() => onSelectCustomer(customer, isSelected)}
      >
        <Checkbox checked={isSelected} />
        <div className="mx-2">
          <Avatar
            src={avatar}
            text={firstname}
            size="small"
            className="rounded overflow-hidden"
            variant="square"
          />
        </div>
        <div className="flex flex-column ml-1">
          <span className="subTitle1">
            {firstname} {lastname}({nickname})
          </span>
          <span className="text-xs text-gray-500">{email}</span>
        </div>
      </div>
    );
  };

  const onChangeTextSearch = debounce((text) => {
    textSearchRef.current = text;
    customerListRef.current.refresh();
  }, 1000);

  return (
    <Drawer
      open={open}
      onClose={() => onClose()}
      onSave={() => onSave(customerSelected)}
      title={t("selectCustomer")}
      size="auto"
      width={"50vw"}
    >
      <div className="modalSelectCustomerContainer">
        <div
          className="modalSelectCustomerSearchInput"
          aria-describedby="popover-list"
        >
          <InputText
            prefix={<Icon name="search" />}
            placeholder={t("searchYourExistingCustomer")}
            onChange={(event) => onChangeTextSearch(event.target.value)}
            className="mb-3"
          />
        </div>
        <AwesomeListComponent
          ref={customerListRef}
          source={source}
          renderItem={renderItem}
          transformer={transformer}
          styleContainer={{ overflowY: "auto" }}
          isPaging
          pagingProps={{
            pageIndex: 1,
            pageSize: 10,
          }}
          variant="load-more"
        />
      </div>
    </Drawer>
  );
};

export default CustomerSelectDrawer;
