import AppContext from "@helpers/context";
import { updateGPEventChannel } from "@network/api/gp-event";
import {
  AwesomeTableComponent,
  Button,
  DialogManager,
  Progress,
} from "d-react-components";
import { map } from "lodash";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import GPEventDetailChannelUpdateModal from "./GPEventDetailChannelUpdateModal";

const GPEventDetailChannel = () => {
  const { eventDetail, eventChannelList, loadEventChannels } =
    useContext(AppContext);
  const [openAdd, setOpenAdd] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const { t } = useTranslation();

  const columns = [
    {
      title: t("name"),
      dataIndex: "name",
      render: (name: string, item: any) => <a href={item?.editurl}>{name}</a>,
    },
    {
      title: t("code"),
      dataIndex: "code",
    },
  ];

  const onAddChannel = (channels: any) => {
    const body = {
      id: eventDetail.id,
      action: "add",
      channel: map(channels, (item) => item.id),
    };
    Progress.show({ method: updateGPEventChannel, params: [body] }, () => {
      loadEventChannels();
      setOpenAdd(false);
    });
  };

  const onRemoveChannel = (channels: any) => {
    setOpenRemove(false);
    DialogManager.showConfirm(t("removeChannel"), t("pleaseAdvisedGPConfig"), [
      {
        text: t("ok"),
        onClick: () => {
          const body = {
            id: eventDetail.id,
            action: "remove",
            channel: map(channels, (item) => item.id),
          };
          Progress.show(
            { method: updateGPEventChannel, params: [body] },
            () => {
              loadEventChannels();
            }
          );
        },
      },
    ]);
  };

  return (
    <div className="card-container p-4 ">
      <div className="d-flex justify-content-between align-items-center">
        <label>{t("channel")}</label>
        <div className="d-flex">
          <Button
            onClick={() => setOpenAdd(true)}
            className="ml-3"
            variant="trans"
            disabled={eventChannelList?.length > 0}
          >
            {t("add")}
          </Button>
          <Button
            onClick={() => setOpenRemove(true)}
            variant="trans"
            disabled={eventChannelList?.length === 0}
          >
            {t("remove")}
          </Button>
        </div>
      </div>
      <AwesomeTableComponent
        columns={columns}
        dataSource={eventChannelList}
        isPagination={false}
        className="mt-3 height-fit-content"
      />
      {openAdd && (
        <GPEventDetailChannelUpdateModal
          open={openAdd}
          onClose={() => setOpenAdd(false)}
          eventId={eventDetail?.id}
          onSave={onAddChannel}
          type="not_in"
          title={t("addChannel")}
        />
      )}
      {openRemove && (
        <GPEventDetailChannelUpdateModal
          open={openRemove}
          onClose={() => setOpenRemove(false)}
          eventId={eventDetail?.id}
          onSave={onRemoveChannel}
          type="in"
          title={t("removeChannel")}
          multiple
        />
      )}
    </div>
  );
};

export default GPEventDetailChannel;
