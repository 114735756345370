import Drawer from "@components/shared/Drawer";
import { DrawerProps } from "@interfaces/common";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AwesomeTableComponent,
  Badge,
  Button,
  IColumnsProps,
  InputTextSearch,
} from "d-react-components";
import { ceil, find, map, reduce, uniq, uniqBy } from "lodash";
import { IStore } from "@interfaces/store";
import UserAPI from "@network/api/user";
import UserDetailChannelRespectiveSelect from "@components/user/detail/UserDetailChannelRespectiveSelect";
import AppContext from "@helpers/context";
import AwesomeTableSelectable from "@common/AwesomeTableSelectable";
import classNames from "classnames";

interface RequestDrawerProps extends DrawerProps {
  action: "add" | "remove";
  onSave: any;
  itemToAdd?: any[];
  itemToRemove?: any[];
  type?: "update" | "request";
  item?: any;
}

const NewRequestUpdateWarehouse = ({
  open,
  onClose,
  action,
  onSave,
  itemToAdd = [],
  itemToRemove = [],
  type = "request",
  item, //current user
}: RequestDrawerProps) => {
  const { summary } = useContext(AppContext);
  const { t } = useTranslation();
  const [pagination, setPagination] = useState<any>();
  const [selectedRow, setSelectedRow] = useState<any[]>(
    action === "add" ? itemToAdd : itemToRemove
  );
  const [listCheckWarehouse, setListCheckWarehouse] = useState<any[]>([]);
  const [openSelectChannel, setOpenSelectChannel] = useState(false);

  const loadAllWarehouseList = async (totalPages: any, action = "all") => {
    const body = { id: item?.id, action };
    let userList: any[] = [];

    for (let index = 1; index <= totalPages; index++) {
      const res = await UserAPI.warehouseList(body, {
        pageIndex: index,
        pageSize: 100,
      });
      userList = userList.concat(res?.data?.data?.warehouse ?? []);
    }
    return userList;
  };

  const displaySelectedRow = useMemo(() => {
    //remove the default selected one
    return action === "add"
      ? selectedRow.filter((row) => !row.selected)
      : selectedRow;
  }, [action, selectedRow]);

  const listTab = useMemo(
    () => [
      ...(action === "add"
        ? [
            {
              id: "all",
              label: t("allWarehouse"),
              items: pagination?.items,
            },
          ]
        : []),
      {
        id: "current",
        label: t("myCurrentWarehouses"),
        items: summary?.warehouse,
      },
      {
        id: "selected",
        label: t("selectedWarehouses"),
        items: displaySelectedRow?.length,
      },
    ],
    [t, action, pagination, summary, displaySelectedRow]
  );
  const [currentTab, setCurrentTab] = useState(listTab[0]);
  const [searchValue, setSearchValue] = useState("");
  const tableRef = useRef<any>(null);
  const tableRefCurrent = useRef<any>(null);
  const source = (paging: any, filter: string) => {
    const body: any = {
      action: filter,
      search: searchValue,
    };
    // if (type === "update") {
    body.id = item?.id;
    // }
    const api = UserAPI.warehouseList;
    // type === "update"
    //   ? UserAPI.warehouseList
    //   : WarehouseAPI.listMyAssetsWarehouse;
    return api(body, paging).then(
      (resp) => {
        setPagination(resp?.data?.data?.pagination);
        const listWareHouse = resp?.data?.data?.warehouse ?? [];
        if (filter === "all" && action === "add") {
          setSelectedRow([
            ...selectedRow,
            ...listWareHouse?.filter((item: any) => item.selected),
          ]);
        }
        UserAPI.checkAssetWarehouse({
          warehouse: listWareHouse?.map((w: IStore) => w.id),
          admin_id: item.id,
        }).then((resp) => {
          setListCheckWarehouse(
            uniqBy(
              [...listCheckWarehouse, ...(resp?.data?.data?.asset ?? [])],
              "id"
            )
          );
        });
        return resp;
      },
      (err) => {
        return err;
      }
    );
  };

  const columns: IColumnsProps = useMemo(
    () => [
      {
        title: t("name"),
        dataIndex: "name",
        render: (name: string, item: any) => (
          <span
            className={classNames({
              "text-gray-300": item.selected && currentTab.id === "all",
            })}
          >
            {name}
          </span>
        ),
      },
      {
        dataIndex: "warehouse_system_code",
        title: t("systemCode"),
        width: 160,
        render: (code: string, item: any) => (
          <span
            className={classNames({
              "text-gray-300": item.selected && currentTab.id === "all",
            })}
          >
            {code}
          </span>
        ),
      },
      {
        dataIndex: "code",
        title: t("navArCode"),
        width: 130,
        render: (code: string, item: any) => (
          <span
            className={classNames({
              "text-gray-300": item.selected && currentTab.id === "all",
            })}
          >
            {code}
          </span>
        ),
      },
      {
        dataIndex: "pickup_code",
        title: t("navPickupCode"),
        width: 130,
        render: (code: string, item: any) => (
          <span
            className={classNames({
              "text-gray-300": item.selected && currentTab.id === "all",
            })}
          >
            {code}
          </span>
        ),
      },
    ],
    [t, action, selectedRow, listCheckWarehouse]
  );

  useEffect(() => {
    setCurrentTab(listTab[0]);
  }, []);

  //custom function to set selected rows because table doesn't keep start/end date info
  const setRowSelection = (newRows: any[]) => {
    const rowsToUpdate = newRows.map((row) => {
      const currentRowInfo = find(selectedRow, { id: row.id });
      return currentRowInfo
        ? {
            ...currentRowInfo,
            ...row,
          }
        : row;
    });
    setSelectedRow(rowsToUpdate);
  };

  const onSelectWarehouse = () => {
    setOpenSelectChannel(true);
  };

  const allChannelFromWarehouse: any = reduce(
    displaySelectedRow,
    (allChannels: any, warehouse: any) => [
      ...allChannels,
      ...(warehouse.channel ?? []),
    ],
    []
  );

  const onAddChannel = (channels: any[]) => {
    onSave({
      channels,
      stores: displaySelectedRow,
    });
  };

  const allChannelsUniq = uniq(map(allChannelFromWarehouse, (item) => item.id));

  return (
    <Drawer
      width={"1000px"}
      size="auto"
      open={open}
      onClose={onClose}
      title={`${t(action)} ${t("warehouse")}`}
      onSave={() => onSelectWarehouse()}
      destroyOnClose
    >
      <div className="bg-white">
        <label className="text-label">
          {t("list")} {t("warehouse")}
        </label>
        <div className="border py-3 px-3">
          <div className="flex items-center mb-3">
            {listTab?.map((tab, index) => (
              <Button
                size="small"
                className="mr-2"
                color={currentTab.id === tab.id ? "primary" : "gray"}
                variant={currentTab.id === tab.id ? "standard" : "outline"}
                onClick={() => setCurrentTab(tab)}
              >
                {tab.label}
                {tab.items > 0 && (
                  <Badge
                    index={tab.items}
                    variant="index"
                    color="red"
                    className="ml-1"
                  />
                )}
              </Button>
            ))}
          </div>
          {currentTab.id !== "selected" && (
            <InputTextSearch
              value={searchValue}
              onChange={(e: any) => setSearchValue(e?.target?.value)}
              onSubmit={() => tableRef?.current?.refresh()}
              className="mb-3"
            />
          )}
          <div>
            {currentTab.id === "all" && (
              <AwesomeTableSelectable
                setSelectedRow={setSelectedRow}
                source={(paging) => source(paging, "all")}
                transformer={(response) => response?.data?.data?.warehouse}
                columns={columns}
                ref={tableRef}
                apiSource={(totalPages: any) =>
                  loadAllWarehouseList(totalPages, "all")
                }
                rowSelection={{
                  selectedRowKeys: map(selectedRow, (item: any) => item.id),
                  onChange: (selectedRowKeys, selectedRows: any) => {
                    setRowSelection(selectedRows);
                  },
                  preserveSelectedRowKeys: true,
                  getCheckboxProps: (record: any) => ({
                    disabled: record.selected,
                  }),
                }}
              />
            )}
            {currentTab.id === "current" && (
              <AwesomeTableSelectable
                setSelectedRow={setSelectedRow}
                source={(paging) => source(paging, "in")}
                transformer={(response) => response?.data?.data?.warehouse}
                columns={columns}
                ref={tableRefCurrent}
                apiSource={(totalPages: any) =>
                  loadAllWarehouseList(totalPages, "in")
                }
                {...(action === "add"
                  ? {}
                  : {
                      rowSelection: {
                        selectedRowKeys: map(
                          selectedRow,
                          (item: any) => item.id
                        ),
                        onChange: (selectedRowKeys, selectedRows: any) => {
                          setRowSelection(selectedRows);
                        },
                        preserveSelectedRowKeys: true,
                      },
                    })}
              />
            )}
            {currentTab.id === "selected" && (
              <AwesomeTableComponent
                dataSource={displaySelectedRow}
                columns={columns}
                ref={tableRefCurrent}
                rowSelection={{
                  selectedRowKeys: map(
                    displaySelectedRow,
                    (item: any) => item.id
                  ),
                  onChange: (selectedRowKeys, selectedRows: any) => {
                    setSelectedRow([
                      ...(selectedRow.filter((row) => row.selected) ?? []),
                      ...(selectedRows ?? []),
                    ]);
                  },
                  preserveSelectedRowKeys: true,
                }}
              />
            )}
          </div>
        </div>
      </div>
      {openSelectChannel && (
        <UserDetailChannelRespectiveSelect
          open={openSelectChannel}
          onClose={() => {
            setOpenSelectChannel(false);
          }}
          onSave={onAddChannel}
          userId={item?.id}
          title={
            action === "add"
              ? t("addChannelRespectively")
              : t("removeChannelRespectively")
          }
          warehouseIds={map(displaySelectedRow, (item: any) => item.id)}
          channelRespectiveIds={allChannelsUniq}
          action={action}
        />
      )}
    </Drawer>
  );
};

export default NewRequestUpdateWarehouse;
