import { ICartRule } from "@interfaces/cart-rule";
import { ViewLabelStatus } from "d-react-components";
import { now } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface ICartRuleUsageStatus {
  cartRule: ICartRule;
}

const CartRuleUsageStatus = ({ cartRule }: ICartRuleUsageStatus) => {
  const { status, rule } = cartRule;
  const { t } = useTranslation();

  const usageStatus = useMemo(() => {
    if (now() < rule?.period_from) {
      return { label: t("scheduled"), color: "#FAC256" };
    }
    if (now() > rule?.period_to) {
      return { label: t("expired"), color: "#EB5757" };
    }
    return { label: t("onGoing"), color: "#33B950" };
  }, [rule?.period_from, rule?.period_to]);

  return (
    <ViewLabelStatus content={usageStatus?.label} color={usageStatus?.color} />
  );
};

export default CartRuleUsageStatus;
