import DeliveryForm from "@components/delivery-tstock/components/DeliveryForm";
import Drawer from "@components/shared/Drawer";
import AppContext from "@helpers/context";
import { mapTransferStockDeliveryToServer } from "@interfaces/transfer-stock";
import { updateDeliveryTransferStock } from "@network/api/transfer-stock";
import { Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import { every } from "lodash";
import moment from "moment";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

function TransferStockEditDeliveryDrawer({ delivery, open, onClose }: any) {
  const { transferDetail, loadTransferDetail } = useContext(AppContext);
  const { t } = useTranslation();

  const deliveryForm = useFormik<any>({
    initialValues: {
      transferId: transferDetail?.id,
      deliveryType: delivery?.delivery_type,
      driver: delivery?.driver,
      remark: delivery?.remark,
      vehicle: delivery?.vehicle?.id,
      deliveryNo: delivery?.delivery_no,
      expectedDateArrival: delivery?.estimated_arrival
        ? moment(delivery?.estimated_arrival)
        : null,
    } as any,
    validateOnChange: false,
    validateOnBlur: false,
    validate: (values) => {
      const errors: any = {};
      if (every(values?.products, (iPro) => iPro?.stock === 0 || !iPro.stock)) {
        errors.products = t("canNotCreateDelivery");
      }
      return errors;
    },
    // validationSchema: schema,
    onSubmit: (values: any) => {
      const input = mapTransferStockDeliveryToServer(values);
      onEditDelivery(input);
    },
  });

  const onEditDelivery = async (input: any) => {
    const body = { id: delivery?.id, ...input };
    return Progress.show(
      [{ method: updateDeliveryTransferStock, params: [body] }],
      (res) => {
        onClose && onClose();
        loadTransferDetail();
        return Notifications.showSuccess(t("updateDeliverySuccess"));
      }
    );
  };

  console.log("transferDetail?.delivery", transferDetail?.delivery);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("updateDelivery")}
      onSave={() => deliveryForm.handleSubmit()}
      size="auto"
      width={"80%"}
    >
      <div>
        <DeliveryForm
          channelId={transferDetail?.channel?.id}
          warehouseId={transferDetail?.transfer_origin?.id}
          deliveryItems={delivery?.transfer_stock_product ?? []}
          deliveryForm={deliveryForm}
          delivery={transferDetail?.delivery}
          transferProduct={transferDetail?.items}
          isEdit
        />
      </div>
    </Drawer>
  );
}

export default TransferStockEditDeliveryDrawer;
