export const INTERNAL_DISCOUNT = "internal_discount";
export const MEMBER_DISCOUNT = "member_discount";
export const ADDITIONAL_DISCOUNT = "additional_discount";
export const MT_CREDIT_DISCOUNT = "mt_credit_card_discount";
export const GP_DISCOUNT = "gp_discount";

export const DISCOUNT_PERCENTAGE = "discount_percentage";
export const DISCOUNT_FIX_AMOUNT = "discount_fix_amount";

export const MODERN_TRADE_DISCOUNTS = [
  {
    id: INTERNAL_DISCOUNT,
    label: "internalDiscount",
  },
  {
    id: MEMBER_DISCOUNT,
    label: "mtMemberDiscount",
  },
  {
    id: ADDITIONAL_DISCOUNT,
    label: "mtAdditionalDiscount",
  },
  {
    id: GP_DISCOUNT,
    label: "gpDiscount",
  },
  {
    id: MT_CREDIT_DISCOUNT,
    label: "mtCreditCardDiscount",
  },
];

export enum DISCOUNT_STAFF_STATUS {
  ENABLE = "enable",
  DISABLE = "disable",
}
export const DISCOUNT_STAFF_STATUS_LIST = [
  { id: DISCOUNT_STAFF_STATUS.ENABLE, label: "enable", color: "#33B950" },
  { id: DISCOUNT_STAFF_STATUS.DISABLE, label: "disable", color: "#ED6969" },
];
