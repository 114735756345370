import { Path } from "@components/layout/Path";
import ProductName from "@components/product/components/ProductName";
import { IInventoryEvent } from "@interfaces/inventory-event";
import { checkInventoryControlStock } from "@network/api/inventory-control-event";
import { checkStockRequestProducts } from "@network/api/stock-request";
import {
  AwesomeTableComponent,
  Drawer,
  IColumnsProps,
  Summary,
} from "d-react-components";
import { filter, find, findIndex, groupBy, map, reduce, replace } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";

interface IProductErrorDrawer {
  description?: string;
  open: boolean;
  requestId?: string;
  productSubmitted?: any[];
  isErrorDetail?: boolean;
  inventoryEventControl: IInventoryEvent;
  onClose: () => void;
}

const StockRequestProductDetailErrorDrawer = ({
  open,
  onClose,
  productErrorDetail,
  inventoryEventControl,
  requestId,
  productSubmitted,
}: any) => {
  const { t } = useTranslation();
  const { brand, category, submittedQty } = productErrorDetail;
  const [errorItems, setErrorItems] = useState([]);
  console.log("productSubmitted", productSubmitted);
  useEffect(() => {
    loadErrorDetail();
  }, []);

  const loadErrorDetail = () => {
    const body = {
      stock_inventory_control_id: inventoryEventControl?.id,
      request_stock_id: requestId,
      brand_id: brand?.id,
      category_id: category?.id,
    };
    checkInventoryControlStock(body).then((res) => {
      let result = res?.data?.data?.hold ?? [];
      const inventoryDataList = res?.data?.data?.inventory ?? [];

      for (let index = 0; index < inventoryDataList.length; index++) {
        const inventoryData = inventoryDataList[index];

        const existedIndex = findIndex(
          result,
          (item: any) => item.product?.id === inventoryData?.product?.id
        );

        if (existedIndex >= 0) {
          result[existedIndex].stock = inventoryData?.stock;
        } else {
          result.push(inventoryData);
        }
      }

      result = map(result, (item) => ({
        ...item,
        submittedQty: find(
          productSubmitted,
          (product) => product?.id === item?.product?.id
        )?.quantity,
      }));

      setErrorItems(result);
    });
  };

  const columns: IColumnsProps = [
    {
      title: t("item"),
      dataIndex: "product",
      width: 500,

      render: (product) => <ProductName item={{ product }} />,
    },
    {
      title: t("currentEventQty"),
      dataIndex: "stock",
      width: 200,

      render: (stock) => stock ?? 0,
    },
    {
      title: t("onHoldQty"),
      dataIndex: "quantity",
      width: 200,

      render: (stock) => stock ?? 0,
    },
    {
      title: t("submittedQty"),
      dataIndex: "submittedQty",
      width: 200,

      render: (stock) => stock ?? 0,
    },
    {
      title: t("maxQtyToAdjust"),
      dataIndex: "stock",
      width: 200,
      render: (stock) => stock ?? 0,
    },
  ];

  const totalCurrentEvent = useMemo(
    () => reduce(errorItems, (sum, item: any) => sum + (item?.stock ?? 0), 0),
    [errorItems]
  );

  const totalOnHold = useMemo(
    () => reduce(errorItems, (sum, item: any) => sum + item?.quantity, 0),
    [errorItems]
  );
  const totalSubmitted = useMemo(
    () => reduce(errorItems, (sum, item: any) => sum + item?.submittedQty, 0),
    [errorItems]
  );

  const renderSummaryRow = () => {
    return (
      <Summary.Row>
        <Summary.Cell index={0} className=" font-weight-bold">
          {t("summary")}
        </Summary.Cell>
        <Summary.Cell index={1} className=" font-weight-bold">
          {totalCurrentEvent}
        </Summary.Cell>
        <Summary.Cell index={2} className=" font-weight-bold">
          {totalOnHold}
        </Summary.Cell>
        <Summary.Cell index={3} className=" font-weight-bold">
          {totalSubmitted}
        </Summary.Cell>
        <Summary.Cell index={4} className=" font-weight-bold">
          {""}
        </Summary.Cell>
      </Summary.Row>
    );
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("error")}
      size="auto"
      width={"70%"}
    >
      <label>{t("controlledGroup")}</label>
      <div>{`${t("brand")}: ${brand?.name}`}</div>
      <div>{`${t("category")}: ${category?.name}`}</div>
      <div>{`${t("submittedQty")}: ${submittedQty}`}</div>

      <AwesomeTableComponent
        columns={columns}
        dataSource={errorItems}
        className="mt-3"
        pagination={false}
        summary={() => renderSummaryRow()}
      />
    </Drawer>
  );
};

const StockRequestProductsErrorDrawer = ({
  open,
  requestId,
  productSubmitted,
  inventoryEventControl,
  description = "quantityRequestingOver",
  onClose,
  isErrorDetail,
}: IProductErrorDrawer) => {
  const { t } = useTranslation();
  const [requestInfo, setRequestInfo] = useState<any[]>([]);
  const [productErrorDetail, setProductErrorDetail] = useState<any>(null);

  useEffect(() => {
    loadStockRequest();
  }, []);

  const loadStockRequest = async () => {
    const requestInfoRes = await checkStockRequestProducts(
      inventoryEventControl?.id,
      requestId
    );
    const requestInfoControl = requestInfoRes?.data?.data?.control ?? [];
    const requestQuantity = requestInfoRes?.data?.data?.request ?? [];

    const productSubmittedGroup = groupBy(
      productSubmitted,
      (item) => item?.category?.id + item?.brand?.id
    );

    const groupedProductKeys = Object.keys(productSubmittedGroup);

    const result = map(groupedProductKeys, (key) => {
      const productGroup = productSubmittedGroup[key];
      const category = productGroup[0].category;
      const brand = productGroup[0].brand;

      const submittedQty = reduce(
        productGroup,
        (sum, item) => sum + item.quantity,
        0
      );

      const requestedQuantity = find(
        requestQuantity,
        (requestedItem) =>
          requestedItem.brand_id === brand?.id &&
          requestedItem.category_id === category?.id
      );

      const requestInfo = find(
        requestInfoControl,
        (requestedItem) =>
          requestedItem.brand_id === brand?.id &&
          requestedItem.category_id === category?.id
      );

      return {
        category,
        brand,
        requestedQuantity: requestedQuantity?.quantity ?? 0,
        stock: requestInfo?.stock ?? 0,
        quantity: requestInfo?.quantity ?? 0,
        submittedQty,
      };
    });

    setRequestInfo(result);
  };

  const columns: IColumnsProps = [
    {
      title: t("category"),
      dataIndex: "category",
      render: (category) => category?.name,
    },
    {
      title: t("brand"),
      dataIndex: "brand",
      render: (brand) => brand?.name,
    },
    {
      title: t("currentEventQty"),
      dataIndex: "stock",
    },
    {
      title: t("onHoldQty"),
      dataIndex: "requestedQuantity",
    },
    {
      title: t("submittedQty"),
      dataIndex: "submittedQty",
      render: (submittedQty: any, productInfo: any) => (
        <div
          className="text-primary cursor-default"
          onClick={() => isErrorDetail && setProductErrorDetail(productInfo)}
        >
          {submittedQty}
        </div>
      ),
    },
    {
      title: t("maxQtyRequest"),
      dataIndex: "quantity",
    },
  ];

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("error")}
      size="auto"
      width={"70%"}
    >
      <div>
        <div>
          {t(description)}
          <Link
            to={generatePath(Path.INVENTORY_CONTROL_EVENT_DETAIL, {
              eventId: inventoryEventControl?.id,
            })}
          >
            {`#${inventoryEventControl?.code}`}
          </Link>
          )
        </div>
        <AwesomeTableComponent
          className="mt-3"
          columns={columns}
          dataSource={requestInfo}
          pagination={false}
        />
        {productErrorDetail && (
          <StockRequestProductDetailErrorDrawer
            open={!!productErrorDetail}
            onClose={() => setProductErrorDetail(null)}
            productErrorDetail={productErrorDetail}
            inventoryEventControl={inventoryEventControl}
            requestId={requestId}
            productSubmitted={productSubmitted}
          />
        )}
      </div>
    </Drawer>
  );
};

export default StockRequestProductsErrorDrawer;
