import {
  Button,
  IRowsKey,
  TimeUtils,
  ViewRowInterchange,
} from "d-react-components";
import React, { useContext, useMemo, useState } from "react";
import UserDetailPersonalUpdateModal from "./UserDetailPersonalUpdateModal";
import AppContext from "@helpers/context";
import UserInfoRow from "@common/UserInfoRow";
import { useTranslation } from "react-i18next";
import ImageIcon from "@common/Icon";

const WarehouseDetailGeneral = ({
  updatable = true,
}: {
  updatable?: boolean;
}) => {
  const { t } = useTranslation();
  const { user, loadUserDetail } = useContext(AppContext);
  const [openUpdate, setOpenUpdate] = useState(false);
  const { username, name, employee_id, fullname, block, id } = user;

  const DETAILS_KEY = useMemo<IRowsKey<any>[]>(
    () => [
      {
        id: "username",
        label: t("username"),
      },
      {
        id: "employee_id",
        label: t("employeeId"),
      },
      {
        id: "name",
        label: t("nickName"),
      },
      {
        id: "fullname",
        label: t("fullName"),
      },
      {
        id: "block",
        label: t("block"),
        renderContent: ({ data }: any) => (data ? t("yes") : t("no")),
      },
      ...(!updatable
        ? [
            {
              id: "app_version",
              label: t("appVersion"),
            },
          ]
        : []),
    ],
    [t]
  );

  return (
    <div className="border mt-3 p-4">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h5>{t("personalInfo")}</h5>
        {updatable && (
          <span onClick={() => setOpenUpdate(true)}>
            <ImageIcon
              className="w-[15px] cursor-pointer"
              src="/images/icons/edit.svg"
            />
          </span>
        )}
      </div>
      <div>
        <ViewRowInterchange
          className="w-100"
          dataSource={user}
          keyList={DETAILS_KEY as any}
          variant="border"
          classNameLabel="font-weight-bold "
          classNameContent="text-end "
        />
      </div>
      {openUpdate && (
        <UserDetailPersonalUpdateModal
          open={openUpdate}
          onClose={() => setOpenUpdate(false)}
          userId={id}
          defaultValue={{
            employee_id: user?.employee_id,
            username: user?.username,
            fullname: user?.fullname,
            block: user?.block,
            name: user?.name,
          }}
          onUpdateSuccess={loadUserDetail}
        />
      )}
    </div>
  );
};

export default WarehouseDetailGeneral;
