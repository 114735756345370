import { Path } from "@components/layout/Path";
import UserAvatarName from "@components/shared/UserAvatarName";
import { TRANSFER_STOCK_STATUS_LIST } from "@constants/transfer-stock";
import AppContext from "@helpers/context";
import { getTransferStockList } from "@network/api/transfer-stock";
import {
  AwesomeTableComponent,
  HeaderTable,
  ITabItem,
  TimeUtils,
  useFirstTime,
  ViewLabelStatus,
} from "d-react-components";
import { debounce, find, map } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import TransferStockFilterDrawer from "./TransferStockFilterDrawer";
import TransferStockTabs from "./TransferStockTabs";

const TransferStockTable = () => {
  const tableRef = useRef<any>();
  const searchRef = useRef<string>();
  const [tabSelected, setTabSelected] = useState<ITabItem>({
    id: "all",
    label: "all",
  });
  const [openFilter, setOpenFilter] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [filter = {}, setFilter] = useQueryParam<any>("filter", JsonParam);

  const isFirstTime = useFirstTime();

  useEffect(() => {
    if (isFirstTime) return;
    tableRef.current?.refresh();
  }, [JSON.stringify(filter), tabSelected]);

  const TABLE_ACTIONS = [
    { id: "edit", label: t("edit") },
    { id: "detail", label: t("detail") },
  ];

  const source = (paging: any) => {
    const body = {
      search: searchRef?.current ?? "",
      stores: map(filter?.store, (item) => item.id),
      status: tabSelected?.id === "all" ? null : [tabSelected?.id],
      channels: map(filter?.channel, (item) => item.id),
    };
    return getTransferStockList(body, paging);
  };

  const columns: any[] = [
    {
      title: t("transferId"),
      dataIndex: "transfer_no",
      ellipsis: true,
      render: (transferNo: string, transfer: any) => (
        <Link
          to={generatePath(Path.TRANSFER_STOCK_DETAIL, {
            transferId: transfer.id,
          })}
        >
          {transferNo}
        </Link>
      ),
    },
    {
      title: t("transferOrigin"),
      dataIndex: "transfer_origin",
      render: (transferOrigin: any) => transferOrigin?.name,
    },
    {
      title: t("transferDestination"),
      dataIndex: "transfer_destination",
      render: (transferDestination: any) => transferDestination?.name,
    },
    {
      title: t("requestDate"),
      dataIndex: "created",
      render: TimeUtils.toDate,
    },
    {
      title: t("transferQuantity"),
      dataIndex: "items",
      align: "center",
      render: (items: any) => {
        let total = 0;
        let receive = 0;
        items.forEach((item: any) => {
          total += item.quantity;
          receive += item.receive;
        });
        return `${receive}/${total}`;
      },
    },
    {
      title: t("status"),
      align: "center",
      dataIndex: "status",
      render: (status: string) => {
        return (
          <ViewLabelStatus
            status={status}
            listStatus={TRANSFER_STOCK_STATUS_LIST}
            getLabel={(item) => t(item.label)}
          />
        );
      },
    },

    {
      title: t("createdAt"),
      dataIndex: "created",
      render: TimeUtils.toDateTime,
    },
  ];

  const onClickAction = (action: any, transfer: any) => {
    switch (action.id) {
      case "edit":
        break;

      case "detail":
        break;

      default:
        break;
    }
  };

  const onChangeSearch = debounce((text) => {
    searchRef.current = text;
    tableRef.current.refresh();
  }, 300);

  const isFiltered = useMemo(() => Object.keys(filter).length > 0, [filter]);

  return (
    <AppContext.Provider value={{ tabSelected }}>
      <div className="bg-white">
        <HeaderTable
          label={t("allTransferStock")}
          onChangeText={(event: any) => onChangeSearch(event.target.value)}
          onClickNew={() => navigate(Path.TRANSFER_STOCK_CREATE)}
          onClickFilter={() => setOpenFilter(true)}
          isFiltered={isFiltered}
          className="app-layout__page-header"
        />

        <div className="d-flex overflow-auto ">
          <TransferStockTabs
            tabSelected={tabSelected}
            onChangeTab={setTabSelected}
          />
        </div>
        <AwesomeTableComponent
          source={source}
          transformer={(res) => res?.data?.data?.transfer ?? []}
          isScroll={false}
          columns={columns}
          ref={(ref) => (tableRef.current = ref)}
        />
        {openFilter && (
          <TransferStockFilterDrawer
            open={openFilter}
            onClose={() => setOpenFilter(false)}
            defaultValue={filter}
            onFilter={setFilter}
          />
        )}
      </div>
    </AppContext.Provider>
  );
};

export default TransferStockTable;
