import {
  AwesomeTableComponent,
  IRowsKey,
  Icon,
  ViewRowInterchange,
} from "d-react-components";
import { find, isEmpty, toUpper } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import AppContext from "@helpers/context";
import { useTranslation } from "react-i18next";
import { WAREHOUSE_USER_KPI_TYPES } from "@constants/warehouse";
import { IWarehouseKPI } from "@interfaces/warehouse";
import WarehouseDetailUserKPIUpdateDrawer from "./WarehouseDetailUserKPIUpdateDrawer";
import WarehouseAPI from "@network/api/warehouse";
import ImageIcon from "@common/Icon";
import { getBrandList } from "@network/api/product";
import { Switch } from "antd";
import CurrencyFormat from "react-currency-format";
import WarehouseDetailUserKPIDetailUpdateDrawer from "./WarehouseDetailUserKPIDetailUpdateDrawer";
import WarehouseDetailUserKPIDetailHistory from "./WarehouseDetailUserKPIDetailHistory";

const WarehouseDetailUserKPI = () => {
  const { warehouse } = useContext(AppContext);
  const [brandList, setBrandList] = useState<any[]>([]);
  const [warehouseKPI, setWarehouseKPI] = useState<IWarehouseKPI>();
  const [warehouseKPIDetail, setWarehouseKPIDetail] = useState<any[]>([]);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openUpdateBrandKPI, setOpenUpdateBrandKPI] = useState(false);
  const [openBrandKPIHistory, setOpenBrandKPIHistory] = useState(false);
  const [brandKPIList, setBrandKPIList] = useState<any[]>();
  const [selectedBrand, setSelectedBrand] = useState();

  const { t } = useTranslation();
  const { id } = warehouse;

  const DETAILS_KEY = useMemo<IRowsKey<IWarehouseKPI>[]>(
    () => [
      {
        id: "kpi_type",
        label: t("type"),
        renderContent: ({ data }) => {
          const type: any = find(WAREHOUSE_USER_KPI_TYPES, { id: data });
          return type ? t(type?.label) : "N/A";
        },
      },
      {
        id: "kpi_target",
        label: t("storeTargetKPI"),
        renderContent: ({ data }) =>
          data ? (
            <CurrencyFormat
              value={data || 0}
              displayType="text"
              thousandSeparator={true}
            />
          ) : (
            ""
          ),
      },
      // {
      //   id: "branch_tax_id",
      //   label: t("totalSalePerson"),
      // },

      // {
      //   id: "pickup_code",
      //   label: t("individualTargetKPI"),
      // },
    ],
    [t]
  );

  const loadWarehouseKPI = () => {
    WarehouseAPI.kpiWarehouse({ id }).then((resp) => {
      setWarehouseKPI(resp?.data?.data?.warehouse);
    });
  };

  const loadWarehouseKPIDetail = () => {
    WarehouseAPI.kpiWarehouseDetail({ id }).then((resp) => {
      setWarehouseKPIDetail(resp?.data?.data?.kpi);
    });
  };

  const loadBrandList = () => {
    getBrandList().then((res) => {
      const listBrand = res.data?.data?.brand ?? [];
      setBrandList(listBrand);
    });
  };

  const updateBrandKPIStatus = (brand: any) => {
    setBrandKPIList(
      brandKPIList?.map((item) =>
        item.id === brand.id ? { ...item, enabled: !item.enabled } : item
      )
    );
    // if (brand.enabled && brand.kpi_target) {
    //   const body = {
    //     warehouse_id: warehouse.id,
    //     brand_id: brand.id,
    //     status: brand.enabled ? 0 : 1,
    //     kpi_target: brand.kpi_target,
    //   };
    //   Progress.show(
    //     { method: WarehouseAPI.kpiWarehouseUpdateDetail, params: [body] },
    //     () => {
    //       // Notifications.showSuccess(t("updateWarehouseSuccess"));
    //     }
    //   );
    // }
  };

  useEffect(() => {
    loadWarehouseKPI();
    loadWarehouseKPIDetail();
    loadBrandList();
  }, []);

  useEffect(() => {
    const kpiList = brandList?.map((brand) => {
      const kpiDetail =
        find(warehouseKPIDetail, (item) => item?.brand?.id === brand.id) ?? {};
      return {
        ...brand,
        enabled: !isEmpty(kpiDetail),
        ...(isEmpty(kpiDetail)
          ? {}
          : {
              kpi_target: kpiDetail.kpi_target,
            }),
      };
    });
    setBrandKPIList(kpiList);
  }, [brandList, warehouseKPIDetail]);

  const columns = useMemo(
    () => [
      {
        title: t("display"),
        dataIndex: "enabled",
        ellipsis: true,
        width: 100,
        render: (data: any, item: any) => (
          <Switch checked={data} onClick={() => updateBrandKPIStatus(item)} />
        ),
      },
      {
        title: t("name"),
        dataIndex: "name",
        render: (data: any, item: any) => data,
      },
      {
        title: t("targetKPI"),
        dataIndex: "kpi_target",
        render: (data: any, item: any) =>
          data ? (
            <CurrencyFormat
              value={data || 0}
              displayType="text"
              thousandSeparator={true}
            />
          ) : (
            ""
          ),
      },
      {
        title: t("history"),
        dataIndex: "",
        render: (data: any, item: any) => (
          <span
            className="cursor-pointer"
            onClick={() => {
              setSelectedBrand(item.id);
              setOpenBrandKPIHistory(true);
            }}
          >
            <Icon className="text-primary" name="history" />
          </span>
        ),
      },
      {
        title: t("action"),
        dataIndex: "",
        render: (data: any, item: any) => (
          <>
            {item.enabled ? (
              <span
                onClick={() => {
                  setSelectedBrand(item.id);
                  setOpenUpdateBrandKPI(true);
                }}
              >
                <ImageIcon
                  className="w-[15px] cursor-pointer"
                  src="/images/icons/edit.svg"
                />
              </span>
            ) : null}
          </>
        ),
      },
    ],
    [t, brandKPIList]
  );

  return (
    <>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-sm">{t("defaultUserKpi")}</label>
        <span onClick={() => setOpenUpdate(true)}>
          <ImageIcon
            className="w-[15px] cursor-pointer"
            src="/images/icons/edit.svg"
          />
        </span>
      </div>
      <div className="border p-4 mb-3">
        <ViewRowInterchange
          className="w-100"
          dataSource={warehouseKPI}
          keyList={DETAILS_KEY as any}
          variant="border"
          classNameLabel="font-weight-bold "
          classNameContent="text-end "
        />
        {openUpdate && (
          <WarehouseDetailUserKPIUpdateDrawer
            open={openUpdate}
            onClose={() => setOpenUpdate(false)}
            warehouseId={id}
            defaultValue={warehouseKPI}
            onUpdateSuccess={loadWarehouseKPI}
          />
        )}
      </div>
      {warehouseKPI?.kpi_type === "kpi_by_brand" && (
        <>
          <AwesomeTableComponent
            dataSource={brandKPIList}
            columns={columns}
            pagination={false}
          />
        </>
      )}
      {openUpdateBrandKPI && (
        <WarehouseDetailUserKPIDetailUpdateDrawer
          open={openUpdateBrandKPI}
          onClose={() => setOpenUpdateBrandKPI(false)}
          warehouseId={id}
          brandId={selectedBrand}
          onUpdateSuccess={() => loadWarehouseKPIDetail()}
        />
      )}
      {openBrandKPIHistory && (
        <WarehouseDetailUserKPIDetailHistory
          open={openBrandKPIHistory}
          onClose={() => setOpenBrandKPIHistory(false)}
          warehouseId={id}
          brandId={selectedBrand}
        />
      )}
    </>
  );
};

export default WarehouseDetailUserKPI;
