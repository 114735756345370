import { Notifications, StringUtils } from "d-react-components";
import { isEmpty, join } from "lodash";
import moment from "moment";

export const transferShippingBeforeAPI = (shippingRaw: any) => {};

export const getFullAddress = (address: any) => {
  if (!address) {
    return "";
  }
  const arrayAddress = [
    address.address,
    address?.subdistrict?.name,
    address?.city?.name,
    address?.province?.name,
    address?.postcode,
  ];

  return join(arrayAddress, ", ");
};
