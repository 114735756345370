import Icon from "@common/Icon";
import ActivitiesView from "@components/order/components/activity/ActivitiesView";
import { DrawerProps, Drawer } from "@components/shared/Drawer";
import { PRINT_INPUT_TYPE } from "@constants/product";
import AppContext from "@helpers/context";
import { IProductPrintSpec } from "@interfaces/product";
import { API } from "@network";
import {
  productPrintInfo,
  productPrintInfoActivity,
  productPrintInfoActivityCreate,
  productPrintInfoUpdate,
} from "@network/api/product";
import { Spin } from "antd";
import {
  IRowsKey,
  InputText,
  Notifications,
  Progress,
  Select,
  ViewRowInterchange,
} from "d-react-components";
import { useFormik } from "formik";
import { forEach, isEmpty, map } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const ProductDetailFactoryPrinting = () => {
  const { productDetail } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [printInfo, setPrintInfo] = useState<IProductPrintSpec>({});
  const { t } = useTranslation();

  const DETAILS_KEY = useMemo<IRowsKey<IProductPrintSpec>[]>(
    () =>
      printInfo?.product?.category?.print_input_fields?.map((item: any) => {
        return {
          id: item.id,
          label: item.label,
          renderContent: ({ data }: any) => {
            if (item.id === "manufactured_id") {
              return printInfo?.manufactured?.display_name;
            }
            if (item.id === "country_id") {
              return printInfo?.country?.name;
            }
            if (item.id === "distributed_id") {
              return printInfo?.distributed?.display_name;
            }
            return printInfo?.[item.id] ?? "";
          },
        };
      }) ?? [],
    [t, printInfo]
  );

  useEffect(() => {
    loadPrintInfo();
  }, [t]);

  const loadPrintInfo = () => {
    setLoading(true);
    productPrintInfo(productDetail.id)
      .then((res) => {
        setPrintInfo(res?.data?.data?.print);
        // specificForm.setValues(res?.data?.data?.print);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const isEmptyField = useMemo(() => {
    return isEmpty(printInfo?.product?.category?.print_input_fields);
  }, [printInfo]);

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mt-3">
        <h5>{t("factoryPrintSpec")}</h5>
        {!isEmptyField && !loading && (
          <span onClick={() => setOpenEdit(true)}>
            <Icon
              className="w-[15px] cursor-pointer"
              src="/images/icons/edit.svg"
            />
          </span>
        )}
      </div>
      {loading && (
        <div className="mt-3 flex items-center justify-center">
          <Spin />
        </div>
      )}
      {!loading && (
        <>
          {isEmptyField ? (
            <div className="mt-3">{t("sorryThisProductPrint")}</div>
          ) : (
            <div className="mt-3 border p-4 ">
              <ViewRowInterchange
                dataSource={productDetail}
                keyList={DETAILS_KEY as any}
                variant="border"
                classNameLabel="text-hint"
                classNameContent="text-end "
              />
              {openEdit && (
                <ProductDetailFactoryPrintingDrawer
                  open={openEdit}
                  onClose={() => setOpenEdit(false)}
                  defaultValue={printInfo}
                  onUpdated={() => {
                    loadPrintInfo();
                    setOpenEdit(false);
                  }}
                />
              )}
            </div>
          )}
        </>
      )}
      <div className="mt-3">
        <FactoryPrintActivity />
      </div>
    </div>
  );
};

const SpecificInputField = ({ inputField, onChange, value }: any) => {
  const [dataSource, setDataSource] = useState<any[]>([]);
  useEffect(() => {
    loadDataSource();
  }, []);

  const loadDataSource = () => {
    if (inputField.type !== PRINT_INPUT_TYPE.select) return;
    if (inputField.values?.length > 0) {
      setDataSource(inputField.values);
      return;
    }
    if (!isEmpty(inputField.api)) {
      API.post(inputField.api).then((res) => {
        const dataList = res?.data?.data?.[inputField.res_data_key] ?? [];
        const dataSourceResult = map(dataList, (item) => {
          let label = "";
          forEach(inputField.select_display_keys, (key) => {
            label += `${item[key]} `;
          });
          return { id: item.id, label };
        });
        setDataSource(dataSourceResult);
      });
    }
  };

  const propsInput = {
    ...inputField,
    className: "mt-3",
    value,
  };

  switch (inputField.type) {
    case PRINT_INPUT_TYPE.select:
      return (
        <Select {...propsInput} onChange={onChange} dataSource={dataSource} />
      );
    default:
      return (
        <InputText
          {...propsInput}
          onChange={(event) => onChange(event.target.value)}
        />
      );
  }
};

const ProductDetailFactoryPrintingDrawer = ({
  open,
  onClose,
  defaultValue,
  onUpdated,
}: DrawerProps & { [key: string]: any }) => {
  const { t } = useTranslation();
  const printInputFields =
    defaultValue?.product?.category?.print_input_fields ?? [];

  const specificForm = useFormik({
    initialValues: { ...defaultValue },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      const body = { ...values };
      Progress.show({ method: productPrintInfoUpdate, params: [body] }, () => {
        onUpdated();
        Notifications.showSuccess(t("updateSuccessfully"));
      });
    },
  });

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("factoryPrintSpec")}
      size="auto"
      width="600"
      onSave={() => {
        specificForm.handleSubmit();
      }}
    >
      {map(printInputFields, (inputField) => (
        <SpecificInputField
          inputField={inputField}
          onChange={(value: any) =>
            specificForm.setFieldValue(inputField.name, value)
          }
          value={specificForm.values[inputField.name]}
        />
      ))}
    </Drawer>
  );
};

const FactoryPrintActivity = () => {
  const { t } = useTranslation();
  const { productDetail } = useContext(AppContext);

  const source = (paging: any) => {
    return productPrintInfoActivity(productDetail.id, paging);
  };

  const transformer = (res: any) => {
    const activities = res?.data?.data?.activities ?? [];
    return activities;
  };

  const onClickSendComment = (comment: any, attachments: any) => {
    if (isEmpty(comment)) {
      Notifications.showError(t("commentContentCanNotBeNull"));
      return Promise.reject();
    }

    return productPrintInfoActivityCreate({
      comment,
      id: productDetail.id,
      attachment: map(attachments, (item) => item.key),
    }).then(() => {
      Notifications.showSuccess(t("addCommentSuccess"));
    });
  };

  return (
    <ActivitiesView
      hideType
      source={source}
      transformer={transformer}
      onClickSendComment={onClickSendComment}
      isInstantUploadAttach
    />
  );
};

export default ProductDetailFactoryPrinting;
