import UserAuthQrCode from "@common/UserAuthQrCode";
import Drawer from "@components/shared/Drawer";
import AppContext from "@helpers/context";
import UserAPI from "@network/api/user";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import { Spin } from "antd";
import { Icon, TimeUtils } from "d-react-components";
import * as FileSaver from "file-saver";
import JSZip from "jszip";
import { forEach, map } from "lodash";
import { useContext, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { getImportUser } from "./UserImportValidation";

const UserAuthExportModal = ({ open, onClose, userList }: any) => {
  const { t } = useTranslation();

  const beforePDFPrinting = () => {
    const cc = document.getElementsByClassName("user-detail__auth-code");
    for (let i = 0; i < cc.length; i++) {
      const svg: any = cc[i];
      const rect = svg.getBoundingClientRect();

      const img: any = document.createElement("img");
      img.src = `data:image/svg+xml;base64,${btoa(
        unescape(encodeURIComponent(svg.outerHTML))
      )}`;
      img.style = `height:100px;width:100px;margin-top:16px`;
      svg.parentNode.insertBefore(img, svg);
    }
  };

  const onExport = async () => {
    const zip = new JSZip();
    const exportAuthFolder: any = zip.folder("authenticates");
    beforePDFPrinting();
    for (let index = 0; index < userList.length; index++) {
      const user = userList[index];
      const { fullname, employee_id } = user;

      const gridElement: any = document.querySelector(
        `.user-export-${employee_id}`
      );
      const dataPdf = await drawDOM(gridElement, {
        paperSize: "A4",
      }).then((group) => {
        return exportPDF(group);
      });
      const base64Data = dataPdf.split(";base64,")[1];
      exportAuthFolder.file(`${employee_id}-${fullname}.pdf`, base64Data, {
        base64: true,
      });
    }
    const content = await exportAuthFolder.generateAsync({ type: "blob" });
    FileSaver.saveAs(
      content,
      `authenticates-${TimeUtils.toDate(new Date().getTime())}.zip`
    );
    onClose();
  };

  const renderInfoRow = (title: any, content: any) => (
    <div className="d-flex border-bottom mt-3 py-3">
      <text className="text-bold w-50">{title}</text>
      <text className="w-50">{content}</text>
    </div>
  );

  const renderUserAuth = (user: any) => {
    const { fullname, name, employee_id, authentication_code } = user;

    return (
      <div
        className={`d-flex flex-column border-bottom p-3 user-export-${employee_id}`}
      >
        <h4 style={{ fontWeight: "bold" }}>{t("saleInfinityXAuth")}</h4>
        <div className="mt-3">{t("pleaseGotoAppStore")}</div>
        {renderInfoRow(t("fullName"), fullname)}
        {renderInfoRow(t("nickName"), name)}
        {renderInfoRow(t("employeeID"), employee_id)}
        <div className="position-relative mt-4 mb-4">
          <UserAuthQrCode
            secretCode={authentication_code}
            name={name}
            className="user-detail__auth-code"
          />
        </div>
        <text className="mt-3">{t("forYourOwnAccountSecurity")}</text>
      </div>
    );
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("exportPdf")}
      onSave={onExport}
      size="medium"
    >
      <div style={{ maxHeight: "75vh" }}>
        {map(userList, (user) => renderUserAuth(user))}
      </div>
    </Drawer>
  );
};

const UserImportResult = () => {
  const { formImport } = useContext(AppContext);
  const { importType, importFields, fileImport } = formImport.values;
  const [errorValidation, setErrorValidation] = useState([]);
  const [usersImported, setUsersImported] = useState([]);
  const [loadingImport, setLoadingImport] = useState(true);
  const { t } = useTranslation();
  const [modalExportAuth, setModalExportAuth] = useState(false);

  useEffect(() => onImport(), [fileImport?.data]);

  const onImport = () => {
    const body = {
      method: importType,
      fields: map(importFields, (item) => item.name),
      admin: map(fileImport?.data, (data) => getImportUser(data, importFields)),
    };

    UserAPI.importUser(body)
      .then((res) => {
        const users = res?.data?.data?.admin ?? [];
        setUsersImported(users);
        setLoadingImport(false);
      })
      .catch((error) => {
        setLoadingImport(false);
      });
  };

  const getErrorItemsData = () => {
    const header = [t("code"), "Message"];
    const dataResult = [header];
    errorValidation.forEach((validation: any) => {
      forEach(validation?.validation ?? [], (item) =>
        dataResult.push([validation?.code_code, item])
      );
    });
    return dataResult;
  };

  const getSuccessItemsData = () => {
    const header = [t("code")];
    const dataResult = [header];
    forEach(fileImport?.data ?? [], (data) => {
      dataResult.push([data?.code_code]);
    });
    return dataResult;
  };

  if (loadingImport) {
    return (
      <div className="p-4 flex-center">
        <Spin />
      </div>
    );
  }
  return (
    <div className="p-4">
      <div className="d-flex mt-3">
        <Icon name="check_circle_outline" className="mr-3 text-success" />
        <div>
          {fileImport?.data?.length - errorValidation?.length}{" "}
          {t("userImportedSuccessfully")}
        </div>
        <CSVLink data={getSuccessItemsData()}>
          <div className="ml-1 text-primary">{t("downloadLog")}</div>
        </CSVLink>
        <div
          className="text-error ml-1 cursor-pointer"
          onClick={() => setModalExportAuth(true)}
          hidden={usersImported.length === 0}
        >
          {t("generateAndDownloadAuth")}
        </div>
      </div>

      <div className="d-flex mt-3">
        <Icon name="error" className="mr-3 text-error" />
        <div>
          {errorValidation?.length} {t("userFailedToImport")}
        </div>
        <CSVLink data={getErrorItemsData()}>
          <div className="ml-1 text-primary">{t("downloadLog")}</div>
        </CSVLink>
      </div>
      {modalExportAuth && (
        <UserAuthExportModal
          open={modalExportAuth}
          onClose={() => setModalExportAuth(false)}
          userList={usersImported}
        />
      )}
    </div>
  );
};

export default UserImportResult;
