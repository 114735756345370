import AppContext from "@helpers/context";
import {
  getGPEventProductList,
  updateGPEventProduct,
} from "@network/api/gp-event";
import {
  AwesomeTableComponent,
  Button,
  InputText,
  InputTextSearch,
  Modal,
  ObjectUtils,
  Progress,
} from "d-react-components";
import { debounce, map, parseInt, uniqBy } from "lodash";
import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import GPEvenProductView from "../compoents/GPEvenProductView";

const BrowseProductModal = ({ open, onClose, onSave }: any) => {
  const { eventDetail } = useContext(AppContext);
  const [productSelecting, setProductSelecting] = useState([]);
  const tableRef = useRef<any>();
  const textSearch = useRef();

  const { t } = useTranslation();

  const columns = [
    {
      title: t("name"),
      dataIndex: "",
      render: (product: any) => <GPEvenProductView product={product} />,
    },
    {
      title: t("brand"),
      dataIndex: "brand",
      render: (brand: any) => brand?.name,
      width: 100,
    },
    {
      title: t("category"),
      dataIndex: "category",
      render: (category: any) => category?.name,
      width: 100,
    },
  ];

  const onChangeText = debounce((text) => {
    textSearch.current = text;
    tableRef.current.refresh();
  }, 500);

  const source = (paging: any) => {
    const body = {
      id: eventDetail?.id,
      action: "not_in",
      search: textSearch.current,
    };
    return getGPEventProductList(body, paging);
  };

  const transformer = (res: any) => {
    return res?.data?.data?.product ?? [];
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t("browseItems")}
      onSave={() => onSave(productSelecting)}
      size="medium"
      classNameContent="height-max-content"
    >
      <div>
        <InputTextSearch
          onChange={(event: any) => onChangeText(event.target.value)}
        />
        <AwesomeTableComponent
          ref={(ref) => {
            tableRef.current = ref;
          }}
          source={source}
          transformer={transformer}
          className="mt-3"
          rowSelection={{
            onChange: (selectedRowKeys, selectedRows: any) => {
              setProductSelecting(selectedRows);
            },
            preserveSelectedRowKeys: true,
          }}
          columns={columns}
        />
      </div>
    </Modal>
  );
};

function GPEventDetailConfigAddModal({ open, onClose, onUpdated }: any) {
  const { eventDetail } = useContext(AppContext);
  const tableRef = useRef<any>();
  const textSearch = useRef();
  const [productSelecting, setProductSelecting] = useState<any>([]);
  const [openBrowse, setOpenBrowse] = useState(false);
  const { t } = useTranslation();

  const columns = [
    {
      title: t("name"),
      dataIndex: "",
      render: (product: any) => <GPEvenProductView product={product} />,
    },
    {
      title: t("gp"),
      dataIndex: "discount",
      render: (discount: any, product: any) => (
        <InputText
          value={discount}
          onChange={(event) =>
            onChangeDiscount(product?.id, event.target.value)
          }
        />
      ),
      width: 100,
    },
    {
      title: t("action"),
      dataIndex: "",
      render: (product: any) => (
        <Button
          iconName="delete"
          variant="trans"
          onClick={() => onRemoveProduct(product?.id)}
        />
      ),
      width: 100,
    },
  ];

  const onChangeText = debounce((text) => {
    textSearch.current = text;
  }, 500);

  const onChangeDiscount = (productId: any, discount: any) => {
    const discountVal = discount ? parseInt(discount) : 0;
    if (discountVal > 100) return;

    const result = map(productSelecting, (item: any) => {
      if (item.id !== productId) return item;
      return { ...item, discount: discountVal };
    });
    setProductSelecting(result);
  };

  const onAddProduct = (products = []) => {
    const listProduct = uniqBy(
      [...products, ...productSelecting],
      (item) => item.id
    );
    setProductSelecting(listProduct);
    setOpenBrowse(false);
  };

  const onRemoveProduct = (productId: string) => {
    const result = ObjectUtils.removeArrayById(productSelecting, productId);
    setProductSelecting(result);
  };

  const onSaveProduct = () => {
    const body = {
      id: eventDetail?.id,
      action: "add",
      product: map(productSelecting, (item) => ({
        id: item.id,
        discount: item.discount,
      })),
    };
    Progress.show({ method: updateGPEventProduct, params: [body] }, () => {
      tableRef.current.refresh();
      onClose();
      onUpdated();
    });
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        title={t("add")}
        onSave={onSaveProduct}
        size="medium"
        headerSide={() => (
          <Button onClick={() => setOpenBrowse(true)}>{t("browse")}</Button>
        )}
        classNameHeader="d-flex pr-3"
        classNameContent="height-max-content"
      >
        <div>
          <InputTextSearch
            onChange={(event: any) => onChangeText(event.target.value)}
          />
          <AwesomeTableComponent
            ref={(ref) => {
              tableRef.current = ref;
            }}
            columns={columns}
            dataSource={productSelecting}
            className="mt-3"
          />
        </div>
      </Modal>
      {openBrowse && (
        <BrowseProductModal
          open={openBrowse}
          onClose={() => setOpenBrowse(false)}
          onSave={onAddProduct}
        />
      )}
    </div>
  );
}

export default GPEventDetailConfigAddModal;
