import { FILTER_COMMENT_TYPES } from "@constants/common";
import AppContext from "@helpers/context";
import { Pagination } from "@interfaces/common";
import { addComment, getListComment } from "@network/api/common";
import { Notifications, UrlUtils } from "d-react-components";
import _ from "lodash";
import React, { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useUpdateEffect } from "react-use";
// import "react-vertical-timeline-component/style.min.css";;
// import FilterActionButton from "../../components/FilterActionButton";
import ActivitiesView from "./activity/ActivitiesView";
import FilterActionButton from "./FilterActionButton";
import { publicActivity } from "@network/api/order";

const OrderActivities = () => {
  const { isPublic, order, by } = useContext(AppContext);
  const { t } = useTranslation();

  const filters = useRef(FILTER_COMMENT_TYPES);
  const activityList = useRef<any>(null);

  useEffect(() => {
    activityList.current && activityList.current.refresh();
  }, [order]);

  const source = (paging: Pagination) => {
    if (!order) {
      return Promise.resolve();
    }
    const label = filters.current?.map((type) => type.id);
    const params = UrlUtils.getQuery() ?? {};

    return isPublic
      ? publicActivity({ ...params, page: paging?.pageIndex ?? 1 })
      : getListComment(order.id, label, paging?.pageIndex ?? 1);
  };

  const transformer = (res: any) => {
    return res?.data?.data?.comments ?? [];
  };

  const onClickSendComment = (
    commentText: string,
    attachments: any,
    commentType: any
  ) => {
    if (_.isEmpty(commentText)) {
      Notifications.showError(t("notification:commentContentCanNotBeNull"));
      return Promise.reject();
    }
    if (!commentType || commentType === "placeholder") {
      Notifications.showError(t("notification:chooseCommentTypeFirst"));
      return Promise.reject();
    }
    const data = new FormData();
    data.append("orderid", order.id);
    data.append("file", attachments?.[0]?.fileData);
    data.append("comment", commentText);
    data.append("label", commentType);
    return addComment(data).then((res) => {
      Notifications.showSuccess(t("notification:addCommentSuccess"));
    });
  };

  const onFilterActivity = (filterList: any) => {
    filters.current = filterList;
    activityList.current.refresh();
  };

  useUpdateEffect(() => {
    activityList.current.refresh();
  }, [t]);

  return (
    <ActivitiesView
      source={source}
      transformer={transformer}
      setRef={(listRefParams: any) => (activityList.current = listRefParams)}
      onClickSendComment={onClickSendComment}
      isPublic={isPublic}
      renderSubTitle={() =>
        isPublic ? (
          <></>
        ) : (
          <FilterActionButton
            dataSource={FILTER_COMMENT_TYPES}
            onSelectFilter={onFilterActivity}
            defaultSelected={FILTER_COMMENT_TYPES}
          />
        )
      }
    />
  );
};
export default OrderActivities;
