import Drawer from "@components/shared/Drawer";
import { getGPEventWarehouseList } from "@network/api/gp-event";
import { AwesomeTableComponent, InputTextSearch } from "d-react-components";
import { debounce } from "lodash";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

function GPEventDetailWarehouseUpdateModal({
  open,
  onClose,
  title,
  type,
  eventId,
  onSave,
}: any) {
  const tableRef = useRef<any>();
  const textSearch = useRef();
  const [warehouseSelecting, setWarehouseSelecting] = useState([]);
  const { t } = useTranslation();

  const columns = [
    {
      title: t("name"),
      dataIndex: "name",
      render: (name: string, warehouse: any) => (
        <a href={warehouse?.editurl} target="_blank" rel="noreferrer">
          {name}
        </a>
      ),
    },
    {
      title: t("systemCode"),
      dataIndex: "warehouse_system_code",
    },
    {
      title: t("navArCode"),
      dataIndex: "code",
    },
  ];

  const onChangeText = debounce((text) => {
    textSearch.current = text;
    tableRef.current.refresh();
  }, 500);

  const source = (paging: any) => {
    const body = { id: eventId, action: type, search: textSearch.current };
    return getGPEventWarehouseList(body, paging);
  };

  const transformer = (res: any) => {
    return res?.data?.data?.warehouse ?? [];
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={title}
      onSave={() => onSave(warehouseSelecting)}
      size="auto"
      width={"80%"}
    >
      <div>
        <InputTextSearch
          onChange={(event: any) => onChangeText(event.target.value)}
        />
        <AwesomeTableComponent
          ref={(ref) => {
            tableRef.current = ref;
          }}
          columns={columns}
          source={source}
          transformer={transformer}
          className="mt-3"
          rowSelection={{
            onChange: (selectedRowKeys, selectedRows: any) => {
              setWarehouseSelecting(selectedRows);
            },
            preserveSelectedRowKeys: true,
          }}
        />
      </div>
    </Drawer>
  );
}

export default GPEventDetailWarehouseUpdateModal;
