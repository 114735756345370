import styled from "@emotion/styled";
import {
  Button,
  InputText,
  InputTextProps,
  Select,
  SelectProps,
} from "d-react-components";
import React, { CSSProperties, useState } from "react";
import { useTranslation } from "react-i18next";

export interface IInputSelectKeyProps {
  className?: string;
  dataSource?: SelectProps["dataSource"];
  selectProps?: Partial<SelectProps>;
  inputProps?: Partial<InputTextProps>;
  selectKey?: any;
  textSearch?: string;
  onChangeText?: InputTextProps["onChange"];
  onChangeSelectKey?: SelectProps["onChange"];
  getLabel?: SelectProps["getLabel"];
  getKey?: SelectProps["getKey"];
  placeholder?: InputTextProps["placeholder"];
  style?: CSSProperties;
  styleSelect?: CSSProperties;
  styleInput?: CSSProperties;
  [key: string]: any;
}

const InputSelectKey: React.FC<IInputSelectKeyProps> = ({
  className,
  dataSource,
  inputProps,
  selectProps,
  selectKey,
  placeholder,
  onChangeText,
  onChangeSelectKey,
  getKey,
  getLabel = (i) => i?.label,
  style,
  styleInput,
  styleSelect,
  onSearch,
  showSearch = true,
}) => {
  const { t } = useTranslation();
  const [textSearch, setTextSearch] = useState();
  return (
    <InputSelectKeyStyled
      className={`flex-center-y gap-3 ${className}`}
      style={style}
    >
      <div className="flex-center-y w-full">
        <Select
          className="input-select-key__select"
          style={{ minWidth: "150px", ...(styleSelect || {}) }}
          dataSource={dataSource}
          value={selectKey}
          onChange={onChangeSelectKey}
          getKey={getKey}
          getLabel={getLabel}
          {...(selectProps || {})}
        />
        <div
          className=""
          style={{
            width: "1px",
            backgroundColor: "#ccc",
            height: "30px",
          }}
        />
        <InputText
          className="w-full input-select-key__input"
          onChange={(event) => setTextSearch(event.target.value)}
          value={textSearch}
          placeholder={placeholder}
          style={styleInput}
          {...(inputProps || {})}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !showSearch) {
              onSearch(textSearch);
            }
          }}
        />
      </div>

      {showSearch && (
        <Button onClick={() => onSearch(textSearch)}>{t("search")}</Button>
      )}
    </InputSelectKeyStyled>
  );
};

export default InputSelectKey;

const InputSelectKeyStyled = styled.div`
  .input-select-key__select {
    .d-select__select {
      border-right-width: 0px !important;
    }
    .ant-select-focused {
      border-color: #ccc !important;
    }
  }
  .input-select-key__input {
    .d-input-text__input-container {
      border-left-width: 0px !important;
    }
    .d-input-text__input-container-outline:focus-within {
      border-color: #ccc !important;
    }
  }
`;
