import { FILTER_COMMENT_TYPES } from "@constants/common";
import AppContext from "@helpers/context";
import { Pagination } from "@interfaces/common";
import { Notifications } from "d-react-components";
import _, { forEach } from "lodash";
import React, { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useUpdateEffect } from "react-use";
// import "react-vertical-timeline-component/style.min.css";;
// import FilterActionButton from "../../components/FilterActionButton";
import ActivitiesView from "@components/order/components/activity/ActivitiesView";
import FilterActionButton from "@components/order/components/FilterActionButton";
import {
  addContractActivity,
  getListContractActivities,
} from "@network/api/rental";

const ContractActivities = () => {
  const { rentalInfo, by } = useContext(AppContext);
  const { t } = useTranslation();

  const filters = useRef(FILTER_COMMENT_TYPES);
  const activityList = useRef<any>(null);

  useUpdateEffect(() => {
    activityList.current && activityList.current.refresh();
  }, [rentalInfo, t]);

  const source = (paging: Pagination) => {
    if (!rentalInfo) {
      return Promise.resolve();
    }
    const label = filters.current?.map((type) => type.id);
    return getListContractActivities(rentalInfo.id, paging?.pageIndex ?? 1);
  };

  const transformer = (res: any) => {
    return res?.data?.data?.activities ?? [];
  };

  const onClickSendComment = (
    commentText: string,
    attachments: any,
    commentType: any
  ) => {
    if (_.isEmpty(commentText)) {
      Notifications.showError(t("notification:commentContentCanNotBeNull"));
      return Promise.reject();
    }
    // if (!commentType || commentType === "placeholder") {
    //   Notifications.showError(t("notification:chooseCommentTypeFirst"));
    //   return Promise.reject();
    // }
    const data = new FormData();
    data.append("id", rentalInfo.id);
    if (attachments.length) {
      forEach(attachments, (attachment) => {
        data.append("attachments[]", attachment.fileData);
      });
    }
    data.append("comment", commentText);
    // data.append("label", commentType);
    return addContractActivity(data).then((res) => {
      Notifications.showSuccess(t("notification:addCommentSuccess"));
    });
  };

  const onFilterActivity = (filterList: any) => {
    filters.current = filterList;
    activityList.current.refresh();
  };

  return (
    <ActivitiesView
      source={source}
      transformer={transformer}
      setRef={(listRefParams: any) => (activityList.current = listRefParams)}
      onClickSendComment={onClickSendComment}
      renderSubTitle={() => (
        <FilterActionButton
          dataSource={FILTER_COMMENT_TYPES}
          onSelectFilter={onFilterActivity}
          defaultSelected={FILTER_COMMENT_TYPES}
        />
      )}
    />
  );
};
export default ContractActivities;
