import ProductName from "@components/product/components/ProductName";
import AppContext from "@helpers/context";
import {
  AwesomeTableComponent,
  Button,
  Notifications,
  Progress,
  Select,
} from "d-react-components";
import React, { useMemo, useRef, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { useTranslation } from "react-i18next";
import PromotionCreateListOfItems, {
  exportItems,
} from "../create/PromotionCreateListOfItems";
import SelectWithInput from "@components/shared/SelectWithInput";
import Drawer, { DrawerProps } from "@components/shared/Drawer";
import { PROMOTION_STATUS } from "@constants/promotion";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { updatePromotion } from "@network/api/promotion";
import { pick } from "lodash";

const PromotionDetailProducts = () => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const SEARCH_TYPES = [
    { label: t("productName"), value: "name" },
    { label: t("productSKU"), value: "sku" },
  ];
  const [searchValue, setSearchValue] = useState("");
  const [filters, setFilters] = useState<any>({
    searchType: SEARCH_TYPES[0].value,
  });
  const { promotionDetail, status } = React.useContext(AppContext);
  const editable = useMemo(
    () => status === PROMOTION_STATUS.SCHEDULED,
    [status]
  );
  const promotionItemTable = useRef<any>(null);
  const columns: any[] = [
    {
      title: t("itemName"),
      dataIndex: "product",
      ellipsis: true,
      className: "productInfoName",
      render: (product: any, item: any) => {
        return item ? <ProductName item={item} /> : "";
      },
    },
    {
      title: t("regularPrice"),
      dataIndex: "regular_price",
      width: "200px",
      align: "center",
      className: "priceInfoTable",
      render: (price: string) => (
        <CurrencyFormat
          value={price}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"฿"}
        />
      ),
    },
    {
      title: t("salePrice"),
      dataIndex: "default_sale_price",
      width: "200px",
      align: "center",
      className: "priceInfoTable",
      render: (price: string) => (
        <CurrencyFormat
          value={price}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"฿"}
        />
      ),
    },
    {
      title: t("newSalePrice"),
      dataIndex: "new_sale_price",
      width: "200px",
      className: "priceInfoTable",
      render: (price: string) => {
        return (
          <CurrencyFormat
            value={price}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"฿"}
          />
        );
      },
    },
  ];
  const onClickExport = () => {
    exportItems(promotionDetail.products);
  };

  const productDisplayList = useMemo(() => {
    if (filters.searchValue) {
      return promotionDetail.products.filter((item: any) => {
        if (filters?.searchType === "name") {
          return item?.product?.name
            .toLowerCase()
            .includes(filters.searchValue.toLowerCase());
        } else if (filters?.searchType === "sku") {
          return item?.product?.sku
            .toLowerCase()
            .includes(filters.searchValue.toLowerCase());
        }
        return true;
      });
    } else {
      return promotionDetail.products;
    }
  }, [promotionDetail.products, filters]);

  return (
    <div className="bg-white mb-3 px-3 py-3">
      <div className="flex items-center mb-2">
        <span className="font-semibold mb-3 inline-block">{t("items")}</span>
        <div className="flex-1 flex items-center justify-end">
          <Button
            variant="outline"
            size="small"
            onClick={() => onClickExport()}
          >
            {t("export")}
          </Button>
          {editable && (
            <Button
              className="ml-2"
              size="small"
              onClick={() => setOpenModal(true)}
            >
              {t("edit")}
            </Button>
          )}
        </div>
      </div>
      <div className="mb-3">
        <SelectWithInput
          prefix={
            <>
              <Select
                onChange={(value) => {
                  setFilters({ ...filters, searchType: value });
                }}
                dataSource={SEARCH_TYPES}
                value={filters.searchType}
                getValue={(item) => item.value}
                getKey={(item) => item.value}
                allowClear={false}
              />
            </>
          }
          placeholder={t("search")}
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.code === "Enter") {
              // onClickFilter();
              setFilters({ ...filters, searchValue });
            }
          }}
        />
      </div>
      <AwesomeTableComponent
        ref={(ref) => {
          if (promotionItemTable) {
            promotionItemTable.current = ref;
          }
        }}
        dataSource={productDisplayList || []}
        // transformer={transformer}
        columns={columns}
        isScroll={false}
        rowKey={(item) => item.productid}
      />
      {openModal && (
        <PromotionProductsDrawer open={openModal} onClose={setOpenModal} />
      )}
    </div>
  );
};

const PromotionProductsDrawer = ({ open, onClose }: DrawerProps) => {
  const { t } = useTranslation();
  const { promotionDetail, reloadPromotion } = React.useContext(AppContext);
  const [promotionProducts, setPromotionProducts] = useState<any[]>(
    promotionDetail?.products ?? []
  );

  const promotionForm = useFormik<any>({
    initialValues: {
      channel: promotionDetail?.channels,
      start: moment(promotionDetail?.start),
      end: moment(promotionDetail?.end),
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({}),
    onSubmit: (values: any) => {},
  });

  const onSave = () => {
    Progress.show(
      {
        method: updatePromotion,
        params: {
          promotionid: promotionDetail?.id,
          products: promotionProducts?.map((p) =>
            pick(p, [
              "productid",
              "default_sale_price",
              "id",
              "regular_price",
              "new_sale_price",
            ])
          ),
        },
      },
      () => {
        reloadPromotion && reloadPromotion();
        Notifications.showSuccess(t("notification:updatePromotionSuccess"));
        onClose();
      },
      (err: string) => {
        Notifications.showError(err);
      }
    );
  };

  return (
    <AppContext.Provider
      value={{ promotionForm, promotionProducts, setPromotionProducts }}
    >
      <Drawer
        title={t("listOfItems")}
        open={open}
        onClose={() => onClose(false)}
        size="auto"
        width="800"
        onSave={onSave}
      >
        <PromotionCreateListOfItems isEdit={true} />
      </Drawer>
    </AppContext.Provider>
  );
};

export default PromotionDetailProducts;
