export const DEPOSIT_STATUS_LIST = [
  {
    id: "pending-deposit",
    value: "pending-deposit",
    label: "pending-deposit",
    color: "#FFBD59",
    bgColor: "amber",
  },
  {
    id: "pending-confirmation",
    value: "pending-confirmation",
    label: "pending-confirmation",
    color: "#FFBD59",
    bgColor: "amber",
  },
  {
    id: "confirm",
    value: "confirm",
    label: "confirmed",
    color: "#27AE60",
    bgColor: "green",
  },
  {
    id: "reject",
    value: "reject",
    label: "rejected",
    color: "#EB5757",
    bgColor: "red",
  },
  {
    id: "cancelled",
    value: "cancelled",
    label: "cancelled",
    color: "#8D8D8D",
    bgColor: "grey",
  },
];

export const DEPOSIT_FILTER_STATUSES = [
  { id: "all", label: "all", color: "#C4C4C4" },
  ...DEPOSIT_STATUS_LIST,
];

export const DEPOSIT_METHODS = [{ id: "cash-in-bank", label: "cash" }];
