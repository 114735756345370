import AppContext from "@helpers/context";
import { Button, Drawer, InputText, Notifications } from "d-react-components";
import { isEmpty } from "lodash";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

const LoadOrderDrawer = ({ open, onClose }: any) => {
  const [orderId, setOrderId] = useState("");
  const { t } = useTranslation();
  const { loadOrderFromOld } = useContext(AppContext);

  const onSave = async () => {
    if (isEmpty(orderId)) {
      Notifications.showError(t("notification:orderIdIsRequired"));
      return Promise.reject();
    }
    return loadOrderFromOld(orderId).then((res: any) => {
      onClose && onClose();
    });
  };
  return (
    <Drawer
      open={open}
      title={t("createOrderFromOld")}
      onClose={() => onClose()}
      destroyOnClose
    >
      <div >{t("pleaseInputTheOrderId")}</div>
      <InputText
        label={t("orderId")}
        onChange={(event) => setOrderId(event.target.value)}
        className="mt-3"
      />
      <div className="position-absolute bottom-0 w-100 end-0 start-0 flex-center-y justify-content-between py-3 px-3 bg-white">
        <Button
          onClick={() => {
            onClose && onClose({});
          }}
          variant="outline"
        >
          {t("cancel")}
        </Button>
        <Button
          onClick={() => {
            onSave();
          }}
        >
          {t("save")}
        </Button>
      </div>
    </Drawer>
  );
};

export default LoadOrderDrawer;
