import {
  ORDER_PICKUP_STATUS,
  ORDER_PICKUP_STATUS_LIST,
} from "@constants/order";
import AppContext from "@helpers/context";
import { deletePickupItem, signPickup } from "@network/api/order";
import {
  Button,
  Drawer,
  Icon,
  InputText,
  Notifications,
  Progress,
  TimeUtils,
  ViewLabelStatus,
} from "d-react-components";
import { isEmpty, reduce } from "lodash";
import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ImageIcon from "@common/Icon";
import SignatureField, { SignatureModal } from "./SignatureField";

const PickupItem = ({
  pickup,
  onClickEdit,
  isShowCreateEdit,
  onClickPrint,
  className,
}: any) => {
  const { t } = useTranslation();
  const { onReloadOrderData } = useContext(AppContext);
  const {
    pickupTime,
    personName,
    personPhone,
    products = [],
    store,
    code,
    status,
  } = pickup;

  const pickupStatus = ORDER_PICKUP_STATUS_LIST.find(
    (item: any) => item.id === status
  );
  const isPending = status === ORDER_PICKUP_STATUS.PENDING;
  const isCompleted = status === ORDER_PICKUP_STATUS.COMPLETED;
  const getNumberOfItemPickup = () => {
    return reduce(products, (sum, item) => sum + item.stock, 0);
  };
  const [openModal, setOpenModal] = useState(false);
  const remarkRef = useRef();

  const renderContentDeletePickUp = () => {
    return (
      <div className="subTitle1 renderDeletePickUp">
        <div style={{ marginBottom: "2rem" }}>
          {t("areYouSureToDeletePickup")}
        </div>
        <InputText
          label={t("remark")}
          multiple
          rows={3}
          onChange={(event: any) => (remarkRef.current = event.target.value)}
        />
      </div>
    );
  };

  const onCompletePickup = (data: any) => {
    const body = {
      pickupid: pickup.id,
      signature_pickup_order: data,
    };
    const APIComplete = { method: signPickup, params: [body] };
    Progress.show(APIComplete, (res) => {
      onReloadOrderData && onReloadOrderData();
    });
  };

  const onDeletePickup = () => {
    if (isEmpty(remarkRef.current)) {
      return Notifications.showError(
        t("notification:pleaseInputNoteForTheUpdate")
      );
    }
    return deletePickupItem({ pickupid: pickup.id }).then((res: any) => {
      onReloadOrderData && onReloadOrderData();
      Notifications.showSuccess(t("notification:deletePickupSuccess"));
    });
  };

  const handleOnClickSave = async () => {
    if (isEmpty(remarkRef.current)) {
      return Notifications.showError(
        t("notification:pleaseInputNoteForTheUpdate")
      );
    }
    Progress.show(
      {
        method: deletePickupItem,
        params: [
          {
            pickupid: pickup.id,
            remark: remarkRef.current,
          },
        ],
      },
      () => {
        setOpenModal(false);
        onReloadOrderData && onReloadOrderData();
        Notifications.showSuccess(t("notification:deletePickupSuccess"));
      },
      (err: any) => {
        Notifications.showError(err?.respone?.data?.message);
      }
    );
  };

  return (
    <div className={`pickupItemContainer relative text-sm mb-3 ${className}`}>
      {!isEmpty(code) && (
        <div className="titleContainer">
          <span className="subTitle1">
            {t("ref")}: <span className="text-primary">{code}</span>
          </span>
        </div>
      )}
      <div className="titleContainer mt-1">
        <span className="subTitle1">{store?.name ?? ""}</span>
      </div>
      {pickupStatus && (
        <ViewLabelStatus
          content={t(pickupStatus?.label ? `order:${pickupStatus?.label}` : "")}
          color={pickupStatus?.color}
          className="mt-2"
        />
      )}
      <div className="subTitle1 mt-2 text-sm text-gray-500">
        {t("pickUpBy")} {personName}-{personPhone} {t("on")}{" "}
        {TimeUtils.convertMiliToDateTime(pickupTime)}
      </div>
      {pickup?.signature && (
        <img
          className="my-3 w-100 max-w-[200px]"
          src={pickup?.signature}
          alt=""
        />
      )}
      <div className="mt-3 flex flex-row items-center">
        <div className="relative">
          <span className="z-10 flex items-center justify-center px-[3px] py-0 text-[10px] bg-red-500 text-white rounded-full min-w-[20px] min-h-[20px] absolute -top-[5px] -right-[5px]">
            {getNumberOfItemPickup()}
          </span>
          <Button
            onClick={() => onClickEdit(true)}
            id="buttonTrack"
            color="primary"
            variant="outline"
            className="text-xs px-2"
            style={{ minWidth: 0, width: "40px" }}
          >
            <span className="text-xs text-white whitespace-nowrap text-ellipsis overflow-hidden inline-block flex items-center">
              <ImageIcon src="/images/icons/package.svg" />
            </span>
          </Button>
        </div>
        <div className="">
          {isCompleted && (
            <Button
              id="buttonTrack"
              size="medium"
              onClick={onClickPrint}
              className="ml-2 px-2"
              style={{ minWidth: 0, width: "40px", height: "40px" }}
            >
              <ImageIcon src="/images/icons/printer.svg" />
            </Button>
          )}

          {isPending && (
            <SignatureField
              content={t("complete").toLocaleUpperCase()}
              className="w-100 ml-2"
              onSave={onCompletePickup}
            />
          )}
        </div>
      </div>
      {isShowCreateEdit && isPending && (
        <div className="absolute flex items-center top-0 right-0">
          <span
            className="text-primary mr-2 cursor-pointer"
            onClick={() => onClickEdit(false)}
          >
            <ImageIcon
              className="w-[15px] cursor-pointer"
              src="/images/icons/edit.svg"
            />
          </span>
          <span
            className="text-red-500 cursor-pointer relative"
            onClick={() => setOpenModal(true)}
          >
            <ImageIcon
              className="w-[15px] cursor-pointer"
              src="/images/icons/delete.svg"
            />
          </span>
        </div>
      )}
      <Drawer
        open={openModal}
        onClose={() => setOpenModal(false)}
        title={t("confirm")}
        size="auto"
        width={"600"}
      >
        {renderContentDeletePickUp()}
        <div className="position-absolute bottom-0 w-100 end-0 start-0 flex-center-y justify-content-between py-3 px-3 bg-white">
          <Button
            onClick={() => {
              setOpenModal(false);
            }}
            variant="outline"
          >
            {t("cancel")}
          </Button>
          <Button
            onClick={() => {
              handleOnClickSave();
            }}
          >
            {t("remove")}
          </Button>
        </div>
      </Drawer>
    </div>
  );
};

export default PickupItem;
