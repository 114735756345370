export const CUSTOMER_INFO_MAP_STEPS = [
  {
    id: "validateOrder",
    title: "validateOrder",
  },
  {
    id: "selectFile",
    title: "selectFile",
  },
  { id: "extractData", title: "extractData" },
  { id: "mapData", title: "mapData" },
];

export const CUSTOMER_TYPES = [
  { id: "individual", label: "individual" },
  { id: "business", label: "business" },
];

export const CUSTOMER_SEARCH_TYPE_DEFAULT = "firstname";
export const CUSTOMER_SEARCH_OPTIONS = [
  {
    id: "firstname",
    label: "firstName",
  },
  {
    id: "lastname",
    label: "firstName",
  },
  {
    id: "nickname",
    label: "nickName",
  },
  {
    id: "phone",
    label: "phone",
  },
  {
    id: "email",
    label: "email",
  },
  {
    id: "citizen_id",
    label: "citizenId",
  },
];
