/* eslint-disable no-nested-ternary */
import AppContext from "@helpers/context";
import styled from "@emotion/styled";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import {
  AwesomeTableComponent,
  Icon,
  StringUtils,
  ViewCollapse,
} from "d-react-components";
import { find, forEach, isEmpty, isNil, map, now } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { getPaidFromPayments } from "@helpers/payment";
import {
  isCustomProduct,
  isCustomSizePro,
  isCustomSizeProduct,
  isGroupProduct,
} from "@helpers/product";
import ProductName from "@components/product/components/ProductName";
import CustomSizeField from "@components/product/components/CustomSizeField";
import TablePrint from "@components/shared/TablePrint";
import CustomProductRemark from "@components/product/components/CustomProductRemark";
import MapDummyProduct from "./MapDummyProduct";
import HoldStockProduct from "./HoldStockProduct";
import { getBothLangsText } from "@helpers/locales";
import UserAvatarName from "@components/shared/UserAvatarName";
import OrderMetadata from "@components/order/components/OrderMetadata";
import { MARKETPLACE_TYPES } from "@constants/order";
import { isGrantURLPermission } from "@helpers/permissions";
import { API_ORDER_METADATA } from "@network/URL";
import { OrderType } from "@constants/common";
import CurrencyFormat from "react-currency-format";

export const ColumnTitle = ({
  title,
  isShowBothLangs,
  className = "",
}: {
  title: string;
  isShowBothLangs?: boolean;
  className?: string;
}) => {
  const { t } = useTranslation();
  return (
    <span
      className={`nowrapCellTable orderDetailTableTitle ${className}`}
      style={{ whiteSpace: "pre-line" }}
    >
      {isShowBothLangs ? getBothLangsText(title, true, true) : t(title)}
    </span>
  );
};

const SubBodyProductView = ({
  product,
  hideCustomProInfo,
  type = OrderType.NORMAL,
}: {
  product: any;
  hideCustomProInfo?: boolean;
  type?: OrderType;
}) => {
  const { t } = useTranslation();
  if (!product) {
    return <></>;
  }
  const { isGift } = product;
  const subBody = [];
  if (isGift) {
    subBody.push(
      <div key="free" className="text-xs text-orange-500">
        {t("freeGift")}
      </div>
    );
  }
  if (type === OrderType.RENTAL && product.amount === 0) {
    subBody.push(
      <div>
        <span
          key="free"
          className="text-xs text-white bg-primary py-1 px-2 mt-1 rounded inline-block"
        >
          {t("freeGift")}
        </span>
      </div>
    );
  }

  if (isCustomSizePro(product)) {
    subBody.push(<CustomSizeField key="custom" product={product} isView />);
  }

  if (!isEmpty(product?.manual_product_id) && !hideCustomProInfo) {
    subBody.push(
      <div key="custom-product" className="text-xs text-orange-500">
        {t("isCustomProduct")}
      </div>
    );
  }
  return <>{subBody}</>;
};

const createRow = (item: any) => {
  const imageurl = item?.product?.imageurls ?? null;
  const name = item?.product?.name ?? "";
  const sku = item?.product?.sku ?? "";
  const sale_price = item?.sale_price ?? null;
  const regular_price = item?.regular_price ?? null;
  const quantity = item?.quantity ?? null;
  const price = Number(sale_price) * Number(quantity);

  const row = {
    ...item,
    imageurl,
    name,
    sku,
    sale_price,
    quantity,
    price,
    regular_price,
  };
  return row;
};

const getAllDiscount = (data: any, type: OrderType) => {
  if (!data || data.length === 0) {
    return [];
  }
  const coupons =
    data &&
    data?.map((item: any) => {
      if (type === OrderType.RENTAL) {
        const type = item?.discount_type ?? "";
        const coupon = item?.code ?? "";
        return {
          key: "discount",
          content: "discount",
          value: item.discount_value,
          type,
          coupon,
          ruleid: item?.cart_rule?.ruleid ?? "",
          discount: item.discount,
        };
      } else {
        const discount = item?.discount ?? null;
        const type = item?.type ?? "";
        const coupon = item?.coupon ?? "";
        return {
          key: "discount",
          content: "discount",
          value: item.total,
          type,
          coupon,
          ruleid: item?.cart_rule?.ruleid ?? "",
          discount: item.discount,
        };
      }
    });
  return coupons;
  // const reOrderCoupon = _.sortBy(coupons, (obj) => obj.type);
  // return reOrderCoupon.reverse();
};

const RenderRow = ({
  row,
  hasSubGroup = false,
  isSubPro = false,
  isPrint = false,
  printSubPro = false,
}: any) => {
  const { t } = useTranslation();
  const showButton = row?.groups?.length > 0;
  const [openColumn, setOpenColumn] = useState(true);
  const subRows = showButton
    ? row.groups?.map((item: any) => createRow(item))
    : [];

  return (
    <>
      <TableRow
        key={row?.name + row?.sku}
        className={
          row.isGift
            ? "productGiftTableItemRow"
            : isSubPro
            ? "subProTableItemRow"
            : ""
        }
      >
        {hasSubGroup && !isPrint && (
          <TableCell data-title="" align="center">
            {showButton && (
              <span onClick={() => setOpenColumn(!openColumn)}>
                {openColumn ? (
                  <Icon size="large" name="arrow_drop_up" />
                ) : (
                  <Icon size="large" name="arrow_drop_down" />
                )}
              </span>
            )}
          </TableCell>
        )}
        {isSubPro && !isPrint && <TableCell colSpan={1} />}
        <TableCell
          colSpan={1.5}
          data-title={t("itemNameTable")}
          className={
            row.isGift
              ? "productGiftTableItemRow"
              : isSubPro
              ? "subProductTableItemRow"
              : ""
          }
          style={{ paddingLeft: printSubPro ? "2rem" : "0rem" }}
        >
          <div style={{ flexDirection: "row", display: "flex" }}>
            <ProductName
              subBody={
                row.isGift ? (
                  <div className="productTableItemFreeGiftText">
                    {t("freeGift")}
                  </div>
                ) : (
                  ""
                )
              }
              item={row}
            />
          </div>
        </TableCell>
        <TableCell align="right" data-title={t("regularPrice")}>
          <span className="rowText textOrderDetailTable">
            {row.regular_price
              ? `${StringUtils.moneyThaiFormat(row.regular_price)}`
              : "_"}
          </span>
        </TableCell>
        <TableCell align="right" data-title={t("salePrice")}>
          <span className="rowText textOrderDetailTable">
            {/* {StringUtils.moneyThaiFormat(row.sale_price)} */}
            {`฿ ${row.sale_price}`}
          </span>
        </TableCell>
        <TableCell align="right" data-title={t("quantityName")}>
          <span className="rowText">{row.quantity}</span>
        </TableCell>
        <TableCell
          align="right"
          style={{ minWidth: "6rem", overflow: "visible" }}
          data-title={t("price")}
        >
          <span className="rowText textOrderDetailTable">
            {/* {StringUtils.moneyThaiFormat(row.price)} */}
            {`฿ ${row.price}`}
          </span>
        </TableCell>
      </TableRow>
      {showButton && !isPrint && (
        <TableRow className="subProRowContainer">
          <TableCell colSpan={5}>
            <ViewCollapse defaultOpen={openColumn} label="">
              <Table>
                <TableBody className="tableBody">
                  {subRows?.map((subPro: any) => {
                    return <RenderRow row={subPro} isSubPro />;
                  })}
                </TableBody>
              </Table>
            </ViewCollapse>
          </TableCell>
        </TableRow>
      )}
      {isPrint && showButton && (
        <>
          {subRows?.map((subPro: any) => {
            return <RenderRow row={subPro} printSubPro />;
          })}
        </>
      )}
    </>
  );
};

const OrderDetailTable = ({
  isViewPaid,
  isShowBothLangs = false,
  hideSelfCouponBy,
  isHideProductImage,
  hideCustomProInfo,
  hideFee,
}: any) => {
  const {
    products,
    coupons,
    order,
    oldPayment,
    oldProducts,
    oldOrder,
    splitPayment,
    selfDiscount = [],
    manualProductsNotMap = [],
    manualProducts = [],
    employeeDiscount,
    hold = [],
    voucher = [],
    fee = [],
    fee_delivery = [],
    fee_tax = [],
    fee_external = [],
    discount_chain = [],
    orderMetadata,
    type = OrderType.NORMAL,
  } = useContext(AppContext);
  const { t } = useTranslation();
  const [expandedRowKeys, setExpandedRowKeys] = useState<any[]>([]);
  const [showMetadata, setShowMetadata] = useState(false);

  const subTotal = order?.sub_total;
  const isChainExternal = order?.is_chain_external;
  const discount = getAllDiscount(coupons, type);

  const total = order?.total;
  const invoiceTotal = order?.invoice_amount;
  const isClone = !isEmpty(oldOrder);

  const productTable = useRef<any>();

  useEffect(() => {
    productTable.current && productTable.current.refresh();
  }, [products]);

  const renderMappedProductInfo = useCallback(
    (pro: any) => {
      const { attribute_values = [], meta, product, sale_price } = pro;
      const { name, sku } = product;

      return (
        <div className="containerTooltip mappedProductInfoContainer">
          <div>{`${t("itemName")}: ${name}`}</div>{" "}
          <div>{`${t("sku")}: ${sku}`}</div>
          <div>{`${t("salePrice")}: ${StringUtils.moneyThaiFormatFixed2(
            sale_price
          )}`}</div>
          <div className="attributeInfoTooltipContainer">
            {meta && meta.width && meta.length && (
              <div className="rowText attributeTooltipItemContainer">
                {`${t("sizeWL")}: ${meta?.width}x${meta?.length}`}
              </div>
            )}
            {attribute_values?.map((item: any) => (
              <div className="rowText attributeTooltipItemContainer">{`${item?.name}: ${item?.values?.name}`}</div>
            ))}
          </div>
        </div>
      );
    },
    [t]
  );

  const renderSubProductName = useCallback(
    (product: any, hideCustomProInfo: boolean) => {
      const { manual_product_id, expired_manual } = product;
      const subView: any[] = [];
      if (hideCustomProInfo) {
        return subView;
      }
      if (isCustomProduct(product)) {
        subView.push(<MapDummyProduct dummyProduct={product} />);
      }

      if (!isEmpty(manual_product_id) && !isEmpty(manualProducts)) {
        const dummyPro = manualProducts.find(
          (manualItem: any) => manualItem.id === manual_product_id
        );

        subView.push(
          <Tooltip
            title={renderMappedProductInfo(dummyPro)}
            overlayClassName="containerTooltip"
          >
            <span className="cursor-pointer px-1 text-tiny ml-1 relative top-[1px] bg-gray-600 text-white whitespace-nowrap">
              {t("mapped")}
            </span>
          </Tooltip>
        );

        if (now() < expired_manual) {
          subView.push(<MapDummyProduct dummyProduct={dummyPro} isRemap />);
        }
      }

      const isHoldProduct = hold.find(
        (item: any) => item.product_id === product?.product?.id
      );
      if (isHoldProduct) {
        subView.push(
          <HoldStockProduct product={product} orderId={order?.id} />
        );
      }

      return subView;
    },
    [hold, manualProducts, renderMappedProductInfo, t]
  );

  const renderExpandableProductTable = (product: any) => {
    if (
      isCustomProduct(product) ||
      isCustomSizePro(product) ||
      !isEmpty(product.manual_product_id)
    ) {
      const { attribute_values = [], remark, meta } = product;
      const attributeList = attribute_values?.map((item: any) => ({
        attributeName: item.name,
        valueName: item.values?.name,
      }));

      return (
        <CustomProductRemark
          attributeList={attributeList}
          meta={meta}
          disabled
          hideRemark={isEmpty(remark)}
          remark={remark}
          showArrow
        />
      );
    }

    return <></>;
  };

  const isExpandableProductRow = (product: any) => {
    if (isShowBothLangs) {
      return true;
    }
    if (
      !isEmpty(product.manual_product_id) &&
      !isEmpty(product.attribute_values)
    ) {
      return true;
    }
    if (isGroupProduct(product)) {
      return true;
    }

    if (isCustomSizePro(product)) {
      return !isEmpty(product.remark);
    }

    if (isCustomProduct(product)) {
      return (
        !isEmpty(product.remark) ||
        !isEmpty(product.attribute_values) ||
        (!Array.isArray(product.meta) && product.meta?.width !== undefined)
      );
    }

    return false;
  };

  const expandableTable = {
    expandedRowRender: renderExpandableProductTable,
    rowExpandable: (record: any) => isExpandableProductRow(record),
    defaultExpandAllRows: true,
    expandedRowKeys,
    onExpandedRowsChange: (items: any) => setExpandedRowKeys(items),
  };

  useEffect(() => {
    if (!manualProductsNotMap?.length) {
      return;
    }
    let resultExpandedKey =
      manualProductsNotMap?.map((item: any) => item.id) ?? [];
    const customSizePro = (products ?? [])
      .filter((item: any) => {
        return (
          (isShowBothLangs && isGroupProduct(item)) || isCustomSizePro(item)
        );
      })
      ?.map((item: any) => item.id);
    resultExpandedKey = resultExpandedKey.concat(customSizePro);
    if (resultExpandedKey.length) {
      setExpandedRowKeys([...expandedRowKeys, ...resultExpandedKey]);
    }
  }, [isShowBothLangs, products, manualProductsNotMap]);

  const [paidOrderAmount, setPaidOrderAmount] = useState(
    getPaidFromPayments(oldPayment)
  );

  useEffect(() => {
    const paid = getPaidFromPayments(oldPayment);
    setPaidOrderAmount(paid);
  }, [oldPayment]);

  const rowMoney = (title: string, money: number, oldOrderId = null) => {
    return (
      <TableRow key={title} className="tableFooter">
        <TableCell colSpan={10} align="left" className="leftCellPriceTable">
          <div className="rowText"> </div>
        </TableCell>
        <TableCell colSpan={3} align="left">
          <div className="subTitle1" style={{ fontWeight: "bold" }}>
            {title}
          </div>
          {isClone && oldOrderId && (
            <div className="subTitle1" id="rowOldOrderId">
              {`(${t("ref")}: #${oldOrderId})`}
            </div>
          )}
        </TableCell>
        <TableCell className="text-right">
          <span
            className="subTitle1 text-green-500"
            style={{ fontWeight: "bold" }}
          >
            {money || money === 0
              ? `${StringUtils.moneyThaiFormat(money)}`
              : "_"}
          </span>
        </TableCell>
      </TableRow>
    );
  };

  const renderTableFooterRow = (
    title: any,
    value: any,
    isDiscount = false,
    isTotalRow = false,
    secondRow?: any
  ) => {
    const dataTitle = typeof title === "string" ? title : t("discount");
    return (
      <TableRow
        key={`${title} ${value}`}
        className={`tableFooter ${isTotalRow ? "totalRowClass" : ""}`}
      >
        <TableCell
          colSpan={10}
          align="left"
          className="leftCellPriceTable hideTableCell-1"
        >
          <div className="rowText"> </div>
        </TableCell>
        <TableCell colSpan={3} align="left" className="hideTableCell-1">
          <div className="rowText">{title}</div>
          {secondRow && <div>{secondRow}</div>}
        </TableCell>
        <TableCell className="text-right" data-title={dataTitle}>
          <span
            className="rowText text-right"
            style={{
              color: isDiscount ? "green" : "rgba(0, 0, 0, 0.87)",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {value}
          </span>
        </TableCell>
      </TableRow>
    );
  };

  const renderRentalRow = (
    title: string,
    month: any,
    money: any,
    isTotalRow = false
  ) => {
    return (
      <TableRow
        key={`${title} ${money}`}
        className={`tableFooter ${isTotalRow ? "totalRowClass" : ""}`}
      >
        <TableCell
          colSpan={10}
          align="left"
          className="leftCellPriceTable hideTableCell-1"
        >
          <div className="rowText"> </div>
        </TableCell>
        <TableCell colSpan={3} align="left" className="hideTableCell-1">
          <div className="rowText mb-1">{title}</div>
          <span className="text-xs bg-primary px-2 py-1 text-white">
            {month} {t("months")}
          </span>
        </TableCell>
        <TableCell className="text-right align-top" data-title={title}>
          <span
            className="rowText text-right"
            style={{
              color: "rgba(0, 0, 0, 0.87)",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {money}
          </span>
        </TableCell>
      </TableRow>
    );
  };

  const renderTablePriceView = () => {
    const paidAmount = getPaidFromPayments(splitPayment);
    return (
      <TableBody className="tableBody">
        {type === OrderType.NORMAL
          ? renderTableFooterRow(
              getBothLangsText("subTotalName", isShowBothLangs),
              StringUtils.moneyThaiFormat(subTotal)
            )
          : renderRentalRow(
              getBothLangsText("subTotalPerMonth", isShowBothLangs),
              order.month,
              <CurrencyFormat
                value={(subTotal / order.month).toFixed(2)}
                displayType="text"
                thousandSeparator
                prefix=""
                decimalScale={2}
              />,
              false
            )}
        {!hideFee &&
          map([...fee_delivery, ...fee], (voucherItem) =>
            renderTableFooterRow(
              voucherItem.name,
              `${StringUtils.moneyThaiFormatFixed2(voucherItem.total)}`,
              false
            )
          )}
        {map(discount, (discountItem) => {
          const couponName = !isEmpty(discountItem.coupon) ? (
            <div className="p-1 text-white bg-primary rounded inline-block text-xs">
              <Icon name="sell" size="xx-small" className="mr-1" />
              {discountItem.coupon.toUpperCase()}
            </div>
          ) : (
            ""
          );

          const ruleIdText = discountItem?.ruleid
            ? `(${t("ruleid")}: ${discountItem.ruleid})`
            : "";
          if (discountItem.type === "free_gift") {
            return renderTableFooterRow(
              `${getBothLangsText("discount", isShowBothLangs)} ${ruleIdText}`,
              t("freeGift"),
              true
            );
          }
          const title = `${getBothLangsText(
            "discount",
            isShowBothLangs
          )} ${ruleIdText}`;
          const percentageText =
            discountItem.type === "percentage"
              ? ` (${discountItem.discount}%)`
              : "";
          const discountValue =
            type === OrderType.NORMAL
              ? discountItem.value
              : Number(discountItem.value) / (order?.month || 1);
          const value =
            type === OrderType.NORMAL
              ? `-${StringUtils.moneyThaiFormatFixed2(
                  discountValue
                )}${percentageText}`
              : `-${discountValue.toFixed(2)}${percentageText}`;
          return renderTableFooterRow(title, value, true, false, couponName);
        })}
        {map(discount_chain, (discountChain) => {
          const percentageText =
            discountChain.type === "discount_percentage"
              ? ` (${discountChain.discount}%)`
              : "";
          return renderTableFooterRow(
            getBothLangsText(discountChain?.discount_level, isShowBothLangs),
            `-${StringUtils.moneyThaiFormatFixed2(
              discountChain.total
            )}${percentageText}`,
            true
          );
        })}
        {map(selfDiscount, (selfDiscountItem) => {
          const { by, code, discountid } = selfDiscountItem;
          const title = (
            <div className="flex-column">
              <span>{`${getBothLangsText("discount", isShowBothLangs)}(${t(
                "manualDiscount"
              )})`}</span>
              {by && !hideSelfCouponBy && (
                <>
                  <div className="text-success flex items-center">
                    <span className="mr-2 inline-block min-w-[90px]">
                      {t("approvedBy")}
                    </span>
                    <UserAvatarName user={by} size="xx-small" />{" "}
                    <span className="min-w-[115px]">
                      {discountid ? `(${t("code")}: ${discountid})` : ""}
                    </span>{" "}
                  </div>
                  <span className="text-success">{`(${t(
                    "ref"
                  )}: ${code})`}</span>
                </>
              )}
            </div>
          );
          const percentageText =
            selfDiscountItem.type === "discount_percentage"
              ? ` (${selfDiscountItem.discount}%)`
              : "";
          return renderTableFooterRow(
            title,
            `-${StringUtils.moneyThaiFormatFixed2(
              selfDiscountItem.total
            )}${percentageText}`,
            true
          );
        })}
        {employeeDiscount &&
          renderTableFooterRow(
            t("employeeDiscount") +
              (employeeDiscount.code ? ` (${employeeDiscount.code}) ` : ""),
            `-${StringUtils.moneyThaiFormatFixed2(
              employeeDiscount.discount_value
            )} (${employeeDiscount.discount}%)`,
            true
          )}
        {map(voucher, (voucherItem) =>
          renderTableFooterRow(
            voucherItem.name,
            `-${StringUtils.moneyThaiFormatFixed2(voucherItem.total)}`,
            true
          )
        )}

        {/* {renderTableFooterRow(
          getBothLangsText("shippingCost", isShowBothLangs),
          StringUtils.moneyThaiFormat(0)
        )} */}
        {/* {renderTableFooterRow(
          `${getBothLangsText("tax", isShowBothLangs)} (7%${Messages.included})`,
          StringUtils.moneyThaiFormatFixed2((total * 7) / 107)
        )} */}
        {type === OrderType.NORMAL &&
          renderTableFooterRow(
            getBothLangsText("total", isShowBothLangs),
            StringUtils.moneyThaiFormatFixed2(total),
            false,
            true
          )}
        {map(fee_external, (fee) => {
          const percentageText =
            fee.fee_type === "discount_percentage" ? ` (${fee.fee}%)` : "";
          return renderTableFooterRow(
            fee.name,
            `${StringUtils.moneyThaiFormatFixed2(fee.total)}${percentageText}`
          );
        })}
        {isChainExternal &&
          isEmpty(fee_external) &&
          renderTableFooterRow(t("gpDiscount"), `0%`)}
        {type === OrderType.NORMAL &&
          renderTableFooterRow(
            getBothLangsText("invoiceAmountExclVat", isShowBothLangs),
            StringUtils.moneyThaiFormatFixed2(order?.invoice_amount_excl_vat),
            true
          )}
        {map(fee_tax, (fee) =>
          renderTableFooterRow(
            fee.name,
            type === OrderType.NORMAL ? (
              `${StringUtils.moneyThaiFormatFixed2(fee.total)}`
            ) : (
              <CurrencyFormat
                value={Number(fee.total) / (order?.month || 1)}
                displayType="text"
                thousandSeparator
                prefix=""
                decimalScale={2}
              />
            )
          )
        )}
        {type === OrderType.NORMAL &&
          renderTableFooterRow(
            getBothLangsText("invoiceAmount", isShowBothLangs),
            StringUtils.moneyThaiFormatFixed2(invoiceTotal),
            false,
            true
          )}
        {isViewPaid &&
          renderTableFooterRow(
            getBothLangsText("paidAmount", isShowBothLangs),
            StringUtils.moneyThaiFormatFixed2(paidAmount.toString())
          )}
        {isViewPaid &&
          renderTableFooterRow(
            getBothLangsText("remainAmount", isShowBothLangs),
            StringUtils.moneyThaiFormatFixed2(
              (order?.total - paidAmount).toString()
            )
          )}
        {type === OrderType.RENTAL &&
          renderRentalRow(
            getBothLangsText("totalPerMonth", isShowBothLangs),
            order.month,
            <CurrencyFormat
              value={(total / order.month).toFixed(2)}
              displayType="text"
              thousandSeparator
              prefix=""
              decimalScale={2}
            />,
            true
          )}
      </TableBody>
    );
  };

  const source = () => {
    if (!products) {
      return Promise.resolve([]);
    }
    let allProduct = [];

    allProduct = products?.map((item: any) => {
      const itemResult = { ...item };
      if (item.groups?.length > 0) {
        itemResult.children = item.groups;
      }
      return itemResult;
    });

    if (manualProductsNotMap.length > 0) {
      allProduct = allProduct.concat(manualProductsNotMap);
    }

    return Promise.resolve(allProduct);
  };

  const renderProductsTable = () => {
    return (
      <TableDetail
        className={isShowBothLangs ? "" : "orderDetailTableContainer-table"}
      >
        <AwesomeTableComponent
          tableLayout="auto"
          // tableLayout="fixed"
          expandable={expandableTable}
          isScroll={false}
          classNameTable="orderDetailProductTable"
          columns={columns}
          source={source}
          transformer={(res) => res}
          pagination={false}
          ref={productTable}
          rowClassName={(product) => {
            let rowClassName = "rowAwesomeTable ";
            if (product.isGift) {
              rowClassName += "productGiftTableItemRow";
            }
            return rowClassName;
          }}
          bordered={false}
        />
      </TableDetail>
    );
  };

  const renderPrintTable = () => {
    const dataSource = () => {
      if (!products) {
        return [];
      }
      // const productsResult = products?.map((item) => {
      //   let itemResult = { ...item };
      //   if (item.groups?.length > 0) {
      //     itemResult.children = item.groups;
      //   }
      //   return itemResult;
      // });

      let productsResult: any[] = [];
      products.forEach((productItem: any) => {
        if (productItem.groups?.length > 0) {
          productsResult.push({ ...productItem, children: productItem.groups });
        } else if (isCustomSizeProduct(productItem)) {
          if (productItem.meta.length > 0) {
            forEach(productItem.meta, (metaItem) => {
              productsResult.push({
                ...productItem,
                meta: [metaItem],
                quantity: productItem.quantity / productItem.meta.length,
              });
            });
          } else {
            productsResult.push({
              ...productItem,
              meta: [],
              quantity: productItem.quantity,
            });
          }
        } else {
          productsResult.push(productItem);
        }
      });

      if (manualProductsNotMap.length > 0) {
        productsResult = productsResult.concat(manualProductsNotMap);
      }

      return productsResult;
    };
    return (
      <TableDetail
        className={isShowBothLangs ? "" : "orderDetailTableContainer-table"}
      >
        <TablePrint
          tableLayout="auto"
          expandable={{
            expandedRowRender: renderExpandableProductTable,
            rowExpandable: () => true,
            defaultExpandAllRows: true,
          }}
          isScroll={false}
          tableClassName="orderDetailProductTable"
          columns={columns}
          dataSource={dataSource()}
          rowClassName={(product: any, index: number) => {
            let rowClassName = "rowAwesomeTable ";
            if (product.isGift) {
              rowClassName += "productGiftTableItemRow";
            }
            if (index === dataSource?.length - 1) {
              rowClassName += "lastRowClassName";
            }
            return rowClassName;
          }}
        />
      </TableDetail>
    );
  };

  const columns = useMemo(
    () => [
      {
        title: (
          <ColumnTitle
            title="itemNameTable"
            isShowBothLangs={isShowBothLangs}
          />
        ),
        mobileTitle: t("itemNameTable"),
        dataIndex: "product",
        // ellipsis: true,
        ...(isShowBothLangs ? { width: 500 } : {}),
        render: (product: any, item: any) => (
          <div className="order-products__item-name">
            <ProductName
              isHideImage={isHideProductImage && isHideProductImage(item)}
              item={item}
              subBody={
                <SubBodyProductView
                  product={item}
                  hideCustomProInfo={hideCustomProInfo}
                  type={type}
                />
              }
              renderSubName={() =>
                renderSubProductName(item, hideCustomProInfo)
              }
            />
          </div>
        ),
      },
      {
        title: (
          <ColumnTitle title="regularPrice" isShowBothLangs={isShowBothLangs} />
        ),
        mobileTitle: t("regularPrice"),
        dataIndex: "regular_price",
        width: isShowBothLangs ? 150 : 100,
        ellipsis: true,
        render: (price: number, item: any) => (
          <CurrencyFormat
            value={(type === OrderType.NORMAL ? price : item.amount) ?? 0}
            displayType="text"
            thousandSeparator
            prefix={type === OrderType.NORMAL ? "฿" : ""}
          />
        ),
      },
      {
        title: (
          <ColumnTitle title="salePrice" isShowBothLangs={isShowBothLangs} />
        ),
        mobileTitle: t("salePrice"),
        width: isShowBothLangs ? 150 : 100,
        dataIndex: "sale_price",
        ellipsis: true,
        render: (price: number, item: any) => (
          <CurrencyFormat
            value={(type === OrderType.NORMAL ? price : item.amount) ?? 0}
            displayType="text"
            thousandSeparator
            prefix={type === OrderType.NORMAL ? "฿" : ""}
          />
        ),
      },
      {
        title: <ColumnTitle title="qnty" isShowBothLangs={isShowBothLangs} />,
        mobileTitle: t("qnty"),
        width: isShowBothLangs ? 150 : 80,
        dataIndex: "quantity",
        ellipsis: true,
        render: (quantity: number) => quantity,
      },
      {
        title: (
          <ColumnTitle
            title="price"
            className="text-right"
            isShowBothLangs={isShowBothLangs}
          />
        ),
        mobileTitle: t("price"),
        width: isShowBothLangs ? 150 : 100,
        dataIndex: "",
        ellipsis: true,
        render: (product: any) => (
          <CurrencyFormat
            value={
              type === OrderType.NORMAL
                ? product?.quantity * product.sale_price
                : product?.quantity * product.amount
            }
            displayType="text"
            thousandSeparator
            prefix={type === OrderType.NORMAL ? "฿" : ""}
          />
        ),
      },
    ],
    [
      isShowBothLangs,
      t,
      hideCustomProInfo,
      isHideProductImage,
      renderSubProductName,
    ]
  );

  const isMarketPlace = !isNil(
    find(MARKETPLACE_TYPES, {
      value: order?.referral_source,
    })
  );

  return (
    <div className={`bg-white w-full ${!isShowBothLangs && "p-3 shadow-sm"}`}>
      {isShowBothLangs ? renderPrintTable() : renderProductsTable()}
      <Table aria-label="spanning table">
        {renderTablePriceView()}
        {oldProducts &&
          oldProducts.length > 0 &&
          rowMoney(t("paid"), paidOrderAmount, oldOrder?.orderid)}
        {oldProducts &&
          oldProducts.length > 0 &&
          rowMoney(t("remainingAmount"), total - paidOrderAmount)}
        {isMarketPlace && isGrantURLPermission(API_ORDER_METADATA) && (
          <TableRow>
            <TableCell
              colSpan={10}
              align="left"
              className="leftCellPriceTable"
            ></TableCell>
            <TableCell colSpan={4} align="left">
              <span
                onClick={() => setShowMetadata(true)}
                className="noPrintClass w-100 flex items-center justify-center px-2 py-2 text-primary text-xs border border-primary cursor-pointer"
              >
                <Icon name="visibility" className="mr-1" />
                <span>{t("3rdPartyData")}</span>
              </span>
            </TableCell>
          </TableRow>
        )}
      </Table>
      {showMetadata && (
        <OrderMetadata
          open={orderMetadata}
          onClose={() => setShowMetadata(false)}
          orderId={order?.id}
        />
      )}
    </div>
  );
};

const Table = styled.table`
  width: 100%;
  display: table;
  border-spacing: 0;
  border-collapse: collapse;
`;
const TableBody = styled.tbody``;
const TableCell = styled.td`
  &.leftCellPriceTable {
    width: 20rem;
    @media (max-width: 767px) {
      display: none;
    }
  }
  padding: 10px 5px;
  border-bottom: 1px solid #e8e8e8;
`;
const TableRow = styled.tr`
  &.totalRowClass {
    .rowText {
      font-weight: bold;
    }
  }
`;
const TableDetail = styled.div`
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .ant-table-expand-icon-col {
    width: 0;
    min-width: 0;
  }
  &:not(.orderDetailTableContainer-table) {
    .ant-table-row,
    thead tr {
      .ant-table-cell {
        &:first-child {
          width: 0;
          padding: 0;
          min-width: 0;
          * {
            display: none;
          }
        }
      }
    }
  }
  &.orderDetailTableContainer-table {
    .ant-table-tbody > tr > td {
      @media (max-width: 767px) {
        padding-left: 120px;
      }
    }
  }
  tr.ant-table-expanded-row {
    background: #fff;
    padding-top: 0;
    top: -1px;
    position: relative;
    & > td {
      background: #fff;
      padding-top: 0;
    }
  }
  .customProductExpandRowContain {
    padding-left: 45px;
    position: relative;
    .block-remark {
      margin-top: 0 !important;
    }
  }
  .ant-table-row-level-1 {
    .order-products__item-name {
      padding-left: 10px;
      &:before {
        content: "";
        display: block;
        width: 20px;
        height: 1px;
        background: rgb(141, 141, 141);
        position: absolute;
        left: 0;
        top: 34px;
      }
    }
  }
`;

export default OrderDetailTable;
