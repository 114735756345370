import UserAvatarName from "@components/shared/UserAvatarName";
import { VEHICLE_STATUS_LIST } from "@constants/vehicle";
import AppContext from "@helpers/context";
import { IVehicle } from "@interfaces/vehicle";
import {
  Button,
  IRowsKey,
  TimeUtils,
  ViewLabelStatus,
  ViewRowInterchange,
} from "d-react-components";
import moment from "moment";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import VehicleDetailGeneralEditDrawer from "./VehicleDetailGeneralEditDrawer";

const VehicleDetailGeneral = () => {
  const { vehicleDetail, loadVehicleDetail } = useContext(AppContext);
  const [openEdit, setOpenEdit] = useState(false);
  const { t } = useTranslation();

  const DETAILS_KEY = useMemo<IRowsKey<IVehicle>[]>(
    () => [
      {
        id: "name",
        label: t("visibility"),
      },
      {
        id: "registration",
        label: t("registrationNo"),
      },
      {
        id: "vehicle_type",
        label: t("type"),
      },
      {
        id: "brand",
        label: t("brand"),
      },
      {
        id: "model",
        label: t("model"),
      },
      {
        id: "year",
        label: t("year"),
      },
      {
        id: "color",
        label: t("color"),
      },
      {
        id: "internal_code",
        label: t("internalCode"),
      },
      {
        id: "engine_no",
        label: t("engineNo"),
      },
      {
        id: "vin_no",
        label: t("vinNo"),
      },
      {
        id: "terminal_serial",
        label: t("terminalSerial"),
      },
      {
        id: "vehicle_ownership",
        label: t("ownership"),
        renderContent: ({ data, item }) => {
          return data ?? "N/A";
        },
      },
      {
        id: "company",
        label: t("company"),
        renderContent: ({ data, item }) => {
          return data?.name ?? "N/A";
        },
      },
      {
        id: "status",
        label: t("status"),
        renderContent: ({ data, item }) => {
          return (
            <div className="d-flex justify-content-end">
              <ViewLabelStatus
                status={data}
                listStatus={VEHICLE_STATUS_LIST}
                getLabel={(item) => t(item.label)}
              />
            </div>
          );
        },
      },
      {
        id: "remark",
        label: t("remark"),
      },
      {
        id: "created",
        label: t("createdAt"),
        renderContent: ({ data, item }) => TimeUtils.toDateTime(data),
      },
    ],
    [t]
  );

  return (
    <div className="card-container p-4 ">
      <div className="d-flex justify-content-between align-items-center">
        <label>{t("generalInformation")}</label>
        <Button onClick={() => setOpenEdit(true)} variant="trans">
          {t("edit")}
        </Button>
      </div>

      <ViewRowInterchange
        dataSource={vehicleDetail}
        keyList={DETAILS_KEY as any}
        variant="border"
        classNameLabel="font-weight-bold "
        classNameContent="text-end "
      />
      {openEdit && (
        <VehicleDetailGeneralEditDrawer
          open={openEdit}
          onClose={() => setOpenEdit(false)}
          defaultValue={vehicleDetail}
          onUpdateSuccess={loadVehicleDetail}
        />
      )}
    </div>
  );
};

export default VehicleDetailGeneral;
