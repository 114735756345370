import { IBrand } from "@interfaces/brand";
import { IFieldSelect } from "@interfaces/select";
import { getBrandList } from "@network/api/product";
import { ObjectUtils, Select } from "d-react-components";
import { isArray, map } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export interface IBrandSelect<T> extends IFieldSelect<T> {
  [key: string]: any;
}

const BrandSelect = ({
  value = [],
  multiple,
  className,
  onChange,
  ...selectProps
}: IBrandSelect<IBrand>) => {
  const [brandList, setBrandList] = useState<any[]>(
    isArray(value) ? value : [value]
  );

  const { t } = useTranslation();

  const onChangeValue = (id: any) => {
    if (multiple) {
      onChange(map(id, (item) => ObjectUtils.findItemFromId(brandList, item)));
      return;
    }
    onChange(ObjectUtils.findItemFromId(brandList, id));
  };

  const brandValue = useMemo(() => {
    if (multiple) {
      return map(value, (item: any) => item.id);
    }
    return (value as any)?.id;
  }, [value]);

  useEffect(() => {
    loadBrandList();
  }, []);

  const loadBrandList = () => {
    getBrandList().then((res) => {
      const listBrand = res.data?.data?.brand ?? [];
      setBrandList(listBrand);
    });
  };

  return (
    <Select
      className={className}
      label={t("brand")}
      dataSource={brandList}
      getLabel={(item) => item?.name}
      value={brandValue}
      onChange={onChangeValue}
      multiple={multiple}
      placeholder={t("pleaseSelect")}
      showSearch
      {...selectProps}
    />
  );
};

export default BrandSelect;
