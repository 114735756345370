import {
  AwesomeTableComponent,
  Button,
  InputTextSearch,
  Notifications,
  Progress,
} from "d-react-components";
import { debounce, map } from "lodash";
import React, { useContext, useRef, useState } from "react";
import WarehouseDetailDeliveryUpdateDrawer from "./WarehouseDetailDeliveryUpdateDrawer";
import AppContext from "@helpers/context";
import { useTranslation } from "react-i18next";
import { Link, generatePath } from "react-router-dom";
import { Path } from "@components/layout/Path";
import { IWarehouse } from "@interfaces/warehouse";
import WarehouseAPI from "@network/api/warehouse";

const WarehouseDetailDelivery = () => {
  const { warehouse } = useContext(AppContext);
  const [openAdd, setOpenAdd] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const { t } = useTranslation();
  const textSearch = useRef("");

  const tableRef = useRef<any>();

  const column = [
    {
      title: t("NO"),
      dataIndex: "",
      render: (value: any, item: any, index: any) => index + 1,
    },
    {
      title: t("name"),
      dataIndex: "name",
      render: (name: string, warehouse: IWarehouse) => (
        <Link
          to={generatePath(Path.WAREHOUSE_DETAIL, {
            warehouseId: warehouse?.id ?? "0",
          })}
        >
          {name}
        </Link>
      ),
    },
    {
      title: t("code"),
      dataIndex: "code",
    },
  ];

  const source = () => {
    const body = {
      action: "in",
      id: warehouse?.id,
      search: textSearch.current,
    };
    return WarehouseAPI.deliveryList(body);
  };

  const transformer = (res: any) => {
    return res?.data?.data?.delivery ?? [];
  };

  const onAddDelivery = (delivery: any) => {
    const body = {
      id: warehouse?.id,
      action: "add",
      delivery: map(delivery, (item) => item.id),
    };
    Progress.show(
      { method: WarehouseAPI.deliveryUpdate, params: [body] },
      () => {
        setOpenAdd(false);
        tableRef.current.refresh();
        Notifications.showSuccess(t("updateWarehouseSuccess"));
      }
    );
  };

  const onRemoveDelivery = (delivery: any) => {
    const body = {
      id: warehouse?.id,
      action: "remove",
      delivery: map(delivery, (item) => item.id),
    };
    Progress.show(
      { method: WarehouseAPI.deliveryUpdate, params: [body] },
      () => {
        setOpenRemove(false);
        tableRef.current.refresh();
        Notifications.showSuccess(t("updateWarehouseSuccess"));
      }
    );
  };

  const onChangeTextSearch = debounce((text) => {
    textSearch.current = text;
    tableRef.current.refresh();
  }, 300);

  return (
    <div className=" mt-3">
      <div className="d-flex justify-content-between align-items-center">
        <InputTextSearch
          placeholder={t("search")}
          onChange={(e: any) => onChangeTextSearch(e.target.value)}
        />
        <div className="d-flex">
          <Button onClick={() => setOpenRemove(true)} className="ml-2">
            {t("remove")}
          </Button>
          <Button onClick={() => setOpenAdd(true)} className="ml-2">
            {t("add")}
          </Button>
        </div>
      </div>
      <AwesomeTableComponent
        ref={(ref) => {
          tableRef.current = ref;
        }}
        columns={column}
        source={source}
        transformer={transformer}
        className="mt-3"
      />
      {openAdd && (
        <WarehouseDetailDeliveryUpdateDrawer
          open={openAdd}
          onClose={() => setOpenAdd(false)}
          warehouseId={warehouse?.id}
          onSave={onAddDelivery}
          title={t("addDelivery")}
          type="not_in"
        />
      )}
      {openRemove && (
        <WarehouseDetailDeliveryUpdateDrawer
          open={openRemove}
          onClose={() => setOpenRemove(false)}
          warehouseId={warehouse?.id}
          onSave={onRemoveDelivery}
          title={t("removeDelivery")}
          type="in"
        />
      )}
    </div>
  );
};

export default WarehouseDetailDelivery;
