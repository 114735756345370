import UserAvatarName from "@components/shared/UserAvatarName";
import AppContext from "@helpers/context";
import { IVehicle } from "@interfaces/vehicle";
import {
  Icon,
  IRowsKey,
  TimeUtils,
  ViewRowInterchange,
} from "d-react-components";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

const VehicleDetailInfo = () => {
  const { vehicleDetail } = useContext(AppContext);
  const { name, code } = vehicleDetail ?? {};
  const { t } = useTranslation();

  const DETAILS_KEY = useMemo<IRowsKey<IVehicle>[]>(
    () => [
      {
        id: "name",
        label: t("name"),
      },
      {
        id: "registration",
        label: t("registrationNo"),
      },
      {
        id: "vehicle_ownership",
        label: t("ownership"),
        renderContent: ({ data, item }) => {
          return data ?? "N/A";
        },
      },
      {
        id: "company",
        label: t("company"),
        renderContent: ({ data, item }) => {
          return data?.name ?? "N/A";
        },
      },
      {
        id: "driver",
        label: t("defaultDriver"),
        renderContent: ({ data, item }) => (
          <div className="d-flex justify-content-end">
            <UserAvatarName user={data} />
          </div>
        ),
      },
      {
        id: "created",
        label: t("createdAt"),
        renderContent: ({ data, item }) => TimeUtils.toDateTime(data),
      },
    ],
    [t]
  );

  return (
    <div className="card-container p-4 flex-column flex-center pt-5">
      <Icon name="commute" size="xxx-large" />
      <h4 className="mt-3">{name}</h4>
      <small>{code}</small>

      <ViewRowInterchange
        className="w-100"
        dataSource={vehicleDetail}
        keyList={DETAILS_KEY as any}
        variant="border"
        classNameLabel="font-weight-bold "
        classNameContent="text-end"
      />
    </div>
  );
};

export default VehicleDetailInfo;
