import { Path } from "@components/layout/Path";
import ProductName from "@components/product/components/ProductName";
import { INVENTORY_STATUS_LIST } from "@constants/inventory";
import { getInventoryList, searchStockInEvent } from "@network/api/inventory";
import {
  AwesomeTableComponent,
  HeaderTable,
  Icon,
  StringUtils,
  useFirstTime,
} from "d-react-components";
import { debounce, find, isArray, map, some } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import InventoryHistoryDrawer from "../components/InventoryHistoryDrawer";
import InventoryOnHoldInfo from "../components/InventoryOnHoldInfo";
import InventoryImportDrawer from "./InventoryImportDrawer";
import InventoryTableExportDrawer from "./InventoryTableExportDrawer";
import InventoryTableFilterDrawer from "./InventoryTableFilterDrawer";
import InventoryTableQuickFilter from "./InventoryTableQuickFilter";

const InventoryTable = () => {
  const [openFilter, setOpenFilter] = useState(false);
  const [openImport, setOpenImport] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [inventoryHistory, setInventoryHistory] = useState<any>(null);
  const [filterBody = {}, setFilterBody] = useQueryParam("filter", JsonParam);
  const tableRef = useRef<any>();
  const searchRef = useRef();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isFirstTime = useFirstTime();

  const isFiltered = useMemo(
    () =>
      some(Object.keys(filterBody), (key) => {
        if (isArray(filterBody[key])) {
          return !!filterBody[key]?.length;
        }
        return !!filterBody[key];
      }),
    [filterBody]
  );

  useEffect(() => {
    if (isFirstTime) return;
    tableRef?.current?.refresh();
  }, [filterBody]);

  const columns = [
    {
      title: t("itemNameTable"),
      dataIndex: "",
      render: (product: any) => {
        return <ProductName item={{ product }} isHideSku />;
      },
      width: 500,
      ellipsis: true,
    },
    {
      title: t("sku"),
      dataIndex: "sku",
    },
    // {
    //   title: t("category"),
    //   dataIndex: "category",
    //   render: (category: any) => category?.name ?? "",
    // },

    {
      title: t("currentQty"),
      dataIndex: "stock",
      render: (stock: any) => stock?.[0]?.stock,
      titleTooltip: t("currentQtyToolTip"),
    },

    {
      title: t("onHold"),
      dataIndex: "stock",
      render: (stock: any, item: any) => (
        <InventoryOnHoldInfo product={item} stockInfo={stock?.[0]} />
      ),
    },
    {
      title: t("totalStock"),
      dataIndex: "total_stock",
      titleTooltip: t("totalQtyToolTip"),
    },
    {
      title: t("stockValue"),
      dataIndex: "total_stock",
      render: (totalStock: any, item: any) =>
        StringUtils.moneyFormat(totalStock * item.sale_price),
    },
    {
      title: t("history"),
      dataIndex: "",
      render: (item: any, stock: any) => (
        <Icon name="history" onClick={() => setInventoryHistory(stock)} />
      ),
    },
  ];

  if (filterBody?.events?.length) {
    columns.splice(2, 0, {
      title: t("currentEventQty"),
      dataIndex: "currentEventQty",
    });
  }
  const source = async (paging: any) => {
    if (!filterBody?.warehouses?.length) return Promise.resolve([]);
    let sendData: any = {
      search: searchRef?.current,
      warehouseid: map(filterBody?.warehouses, (item) => item.id),
      brand: map(filterBody?.brand, (item) => item.id),
      category: map(filterBody?.category, (item) => item.id),
      product_type: filterBody?.productType,
      stock_inventory_control_id: map(filterBody?.events, (item) => item.id),
    };

    const statusValue = find(
      INVENTORY_STATUS_LIST,
      (item) => item.id === filterBody?.status
    );

    if (statusValue) {
      sendData.total_stock = statusValue?.value;
    }

    const inventoryRes = await getInventoryList(sendData, paging);
    let inventory = inventoryRes?.data?.data?.product ?? [];

    const productIds = map(inventory, (item) => item.id);

    if (filterBody?.events?.length && productIds?.length) {
      const body = {
        product_id: productIds,
        stock_inventory_control_id: map(filterBody?.events, (item) => item.id),
      };

      const eventStockRes = await searchStockInEvent(body);
      const stockList = eventStockRes?.data?.data?.stock ?? [];

      inventory = map(inventory, (inventoryItem: any) => {
        const stockItem = find(
          stockList,
          (item) => item?.product_id === inventoryItem?.id
        );
        if (stockItem) {
          return {
            ...inventoryItem,
            currentEventQty: stockItem?.stock,
          };
        }
        return inventoryItem;
      });
    }

    return {
      data: {
        data: {
          pagination: inventoryRes?.data?.data?.pagination,
          inventory,
        },
      },
    };
  };

  const transformer = (inventoryRes: any) => {
    const inventory = inventoryRes?.data?.data?.inventory ?? [];
    const convertResult = map(inventory, (item) => {
      const isGroup = item?.is_group ?? false;
      const subPro =
        map(item?.groups, (sub) => ({ ...sub, isSubProduct: true })) ?? [];
      if (isGroup) {
        return { ...item, children: subPro };
      }
      return item;
    });
    return convertResult;
  };

  const onChangeSearch = debounce((text) => {
    searchRef.current = text;
    tableRef.current.refresh();
  }, 1000);

  return (
    <div className="bg-white">
      <HeaderTable
        label={t("inventoryTable")}
        onChangeText={(event: any) => onChangeSearch(event.target.value)}
        onClickNew={() => navigate(Path.INVENTORY_CONTROL_EVENT_CREATE)}
        onClickFilter={() => setOpenFilter(true)}
        isFiltered={isFiltered}
        onClickImport={() => setOpenImport(true)}
        onClickExport={() => setOpenExport(true)}
        className="app-layout__page-header"
      />
      <InventoryTableQuickFilter
        value={filterBody}
        onChange={(values: any) => {
          setFilterBody(values);
        }}
      />
      <AwesomeTableComponent
        ref={(ref) => {
          tableRef.current = ref;
        }}
        columns={columns}
        source={source}
        transformer={transformer}
      />
      {openFilter && (
        <InventoryTableFilterDrawer
          defaultValue={filterBody}
          open={openFilter}
          onClose={() => {
            setOpenFilter(false);
          }}
          onSave={setFilterBody}
        />
      )}

      {openImport && (
        <InventoryImportDrawer
          open={openImport}
          onClose={() => setOpenImport(false)}
          onImported={() => {
            tableRef?.current?.refresh();
          }}
        />
      )}

      {openExport && (
        <InventoryTableExportDrawer
          open={openExport}
          onClose={() => setOpenExport(false)}
        />
      )}

      {!!inventoryHistory && (
        <InventoryHistoryDrawer
          open={inventoryHistory}
          onClose={() => setInventoryHistory(null)}
          storeDefault={filterBody?.warehouses}
          productId={inventoryHistory?.id}
        />
      )}
    </div>
  );
};

export default InventoryTable;
