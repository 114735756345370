export const STORE_TYPE = {
  ONLINE: "online",
  OFFLINE: "offline",
};

export const STORE_TYPE_LIST = [
  {
    id: "all",
    label: "allStore",
  },
  {
    id: "offline",
    label: "offline",
  },
  {
    id: "online",
    label: "online",
  },
];
