import Drawer from "@components/shared/Drawer";
import { ADMIN_AUTH_KEY, CALL_CENTER_TOKEN_KEY } from "@constants";
import AppContext from "@helpers/context";
import { CallEvent } from "@interfaces/common";
import { getCallCenterInformation } from "@network/api/callcenter";
import { Tooltip } from "antd";
import { Button, Icon } from "d-react-components";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "react-use";
import WebSocket from "../../WebSocket";
import CallingPopup from "./CallingPopup";

const CallCenterMain = () => {
  const [hidePopup, setHidePopup] = useState(false);
  const [callPopup, setCallPopup] = useState<CallEvent | undefined>();
  const [callCenterToken, setCallCenterToken] = useLocalStorage<any>(
    CALL_CENTER_TOKEN_KEY
  );
  const { callCenterInfo, setCallCenterInfo, setCallCenterTokenReady } =
    useContext(AppContext);
  const [admin] = useLocalStorage<any>(ADMIN_AUTH_KEY, {});
  const { t } = useTranslation();

  const getCallCenterInfo = async () => {
    if (!isEmpty(admin) && admin.has_call_center) {
      try {
        const callCenterRes: any = await getCallCenterInformation();
        setCallCenterInfo(callCenterRes?.data?.data?.call_center);
        setCallCenterToken(
          callCenterRes?.data?.data?.call_center?.call_center_token
        );
        setCallCenterTokenReady(true);
        const currentTime = moment().valueOf();
        const expiredTime =
          callCenterRes?.data?.data?.call_center?.expired * 1000;
        if (expiredTime) {
          const timer = setTimeout(() => {
            clearTimeout(timer);
            getCallCenterInfo();
          }, expiredTime - currentTime);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    getCallCenterInfo();
  }, []);

  const onCallComing = (event: CallEvent) => {
    setTimeout(() => {
      const callContainer = document.getElementsByClassName("webphone-widget");
      if (
        callContainer?.[0]?.textContent?.includes("incoming Call") ||
        callContainer?.[0]?.textContent?.includes("outgoing Call")
      ) {
        setCallPopup({
          ...event,
          phone: event?.phone.replace("+66", "0"),
        });
      }
    }, 1000);
  };

  return (
    <div>
      {!isEmpty(admin) && callCenterInfo?.has_call_center && (
        <Helmet>
          <script type="module">
            {`
                // Create element:
                const wp = document.createElement("div");
                wp.setAttribute("class", "webphone-widget");

                // Append to body:
                document.body.appendChild(wp);
                (function () {
                  var lc = document.createElement('script'); lc.type = 'text/javascript'; lc.async = true;
                  lc.src = "https://widget.b3works.com/webphone.js";
                  var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(lc, s);
                })();
              `}
          </script>
        </Helmet>
      )}
      {!isEmpty(callPopup) && (
        <>
          <Drawer
            open={!isEmpty(callPopup)}
            onClose={() => setCallPopup(undefined)}
            title={t("customerIsCalling")}
            showCancel={false}
            size="auto"
            width={"1200px"}
            placement="left"
            hideFooter={true}
            className={hidePopup ? "hidden" : ""}
          >
            <CallingPopup callerInfo={callPopup} />
            <div className="absolute left-[100%] top-0">
              <Tooltip title={t("close")} placement="right">
                <Button
                  className="mb-2"
                  iconName="close"
                  onClick={() => setCallPopup(undefined)}
                />
              </Tooltip>
              <Tooltip title={t("hide")} placement="right">
                <Button
                  color="gray"
                  iconName="call_received"
                  onClick={() => setHidePopup(true)}
                />
              </Tooltip>
            </div>
          </Drawer>
          <Tooltip title={t("showCallerInfo")}>
            <div
              className={`w-auto flex flex-col absolute z-100 left-20 bottom-20 items-end cursor-pointer ${
                hidePopup ? "" : "hidden"
              }`}
              onClick={() => setHidePopup(false)}
            >
              <div className="flex border h-14 w-14 rounded-full bg-webphone-blue items-center text-2xl hover:border-blue-500 justify-content-center">
                <Icon name="face" color="white" size="x-large" />
              </div>
            </div>
          </Tooltip>
        </>
      )}
      {!isEmpty(callCenterInfo) && <WebSocket onCallComing={onCallComing} />}
    </div>
  );
};

export default CallCenterMain;
