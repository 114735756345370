import {
  Modal,
  Notifications,
  ObjectUtils,
  Progress,
} from "d-react-components";
import { useFormik } from "formik";
import React from "react";
import { WarehouseGeneralSchema } from "../create/WarehouseCreate";
import { WAREHOUSE_GENERAL_KEYS } from "@constants/warehouse";
import WarehouseAPI from "@network/api/warehouse";
import { useTranslation } from "react-i18next";
import WarehouseFormGeneral from "../components/WarehouseFormGeneral";
import Drawer from "@components/shared/Drawer";

function WarehouseDetailGeneralUpdateDrawer({
  open,
  onClose,
  warehouseId,
  defaultValue,
  onUpdateSuccess,
}: any) {
  const { t } = useTranslation();

  const generalForm = useFormik({
    initialValues: defaultValue ?? {},
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: WarehouseGeneralSchema,
    onSubmit: (values) => {
      const formValue: any = ObjectUtils.mapFieldsLangsCTS(
        values,
        WAREHOUSE_GENERAL_KEYS as any
      );
      if (formValue?.chain_id === undefined) {
        formValue.chain_id = null;
      }
      const body = { id: warehouseId, ...formValue, nav_id: values?.nav?.id };
      Progress.show({ method: WarehouseAPI.update, params: [body] }, () => {
        Notifications.showSuccess(t("updateWarehouseSuccess"));
        onUpdateSuccess();
        onClose();
      });
    },
  });
  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("updateWarehouse")}
      onSave={() => generalForm.handleSubmit()}
      size="auto"
      width="30vw"
    >
      <WarehouseFormGeneral form={generalForm} fieldClassName="col-12" />
    </Drawer>
  );
}

export default WarehouseDetailGeneralUpdateDrawer;
