import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import HoldStockProductModal from "../modal/HoldStockProductModal";
const HoldStockProduct = ({ product, orderId } : any) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="inline-block">
      <div className="cursor-pointer px-1 text-tiny ml-1 relative top-[1px] bg-gray-600 text-white bg-primary whitespace-nowrap" onClick={() => setOpenModal(true)}>
        {t("onHold")}
      </div>

      {openModal && (
        <HoldStockProductModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          productId={product?.product?.id}
          orderId={orderId}
        />
      )}
    </div>
  );
};
export default HoldStockProduct;
