import ClassNames from "classnames";
import React, { useMemo } from "react";
import { generatePath, Link, matchRoutes, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IMenuItemProps, MenuPropsType } from "@constants/menu";
import { menuFlatter } from "@helpers/menu";
import { usePathInfo } from "@helpers/hooks";

export interface BreadCrumbProps {
  menu: MenuPropsType;
  dataSource?: MenuPropsType;
}

export const findParentNode = (
  node: IMenuItemProps,
  data: MenuPropsType,
  prev: MenuPropsType = []
) => {
  let result = [node, ...prev];
  if (node?.parentId) {
    const parentNode = data.find((i) => i?.id === node?.parentId);
    if (parentNode) {
      result = findParentNode(parentNode, data, result);
    }
  }
  return result;
};

const BreadCrumb: React.FC<BreadCrumbProps> = ({ menu, dataSource }) => {
  const flatMenu = useMemo(() => {
    return menuFlatter(menu);
  }, [menu]);
  const currentPath = usePathInfo(flatMenu);
  const { t } = useTranslation("menu");

  const breadcrumbData = useMemo(() => {
    if (dataSource) {
      return dataSource;
    }
    let data: MenuPropsType = [];
    if (currentPath) {
      data = findParentNode(currentPath, flatMenu);
    }
    return data;
  }, [currentPath, flatMenu]);

  const wrapperClass = ClassNames("d-flex align-items-center text-x-small");
  if (breadcrumbData?.length === 0) {
    return <div />;
  }

  return (
    <div className={wrapperClass}>
      {breadcrumbData?.map((item, index) => {
        const showSlash = index > 0;
        const query = item?.query;
        if (index === breadcrumbData.length - 1) {
          return (
            <div key={index}>
              {showSlash && <span className="mx-1">/</span>}
              {/* i18next-extract-disable-next-line */}
              {` ${t([item?.label]) ?? "N/A"}`}
            </div>
          );
        }
        return (
          <div className="flex-center-y" key={index}>
            {showSlash && <span className="mx-1">/</span>}
            <Link
              to={{
                pathname: generatePath(item?.path ?? ("" as any)),
                search: query || undefined,
              }}
              className="font-weight-bold mr-1"
            >
              {/* i18next-extract-disable-next-line */}
              {`${t([item?.label]) ?? "N/A"}`}
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default BreadCrumb;
