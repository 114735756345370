import SearchGroupProducts from "@components/product/components/SearchGroupProducts";
import { DEFAULT_PAGING } from "@constants/common";
import { ORDER_STATUS } from "@constants/order";
import { PRODUCT_TYPE } from "@constants/product";
import AppContext from "@helpers/context";
import { IProduct } from "@interfaces/product";
import { listProduct, mapDummyProduct } from "@network/api/product";
import { Notifications, Progress, DialogManager } from "d-react-components";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

const MapDummyProduct = ({
  dummyProduct,
  isRemap = false,
}: {
  dummyProduct: IProduct;
  isRemap?: boolean;
}) => {
  const { onReloadOrderData, order } = useContext(AppContext);
  const { t } = useTranslation();

  const renderMainContent = () => {
    return (
      <div className="cursor-pointer px-1 text-tiny ml-1 relative top-[1px] bg-gray-600 text-white whitespace-nowrap">
        {isRemap ? t("reMap") : t("map")}
      </div>
    );
  };

  const onSelectMapProduct = ([productSelect]: IProduct[]) => {
    DialogManager.showConfirm(
      t("confirm"),
      t("notification:areYouSureToMapThisProduct"),
      () => onMapProduct(productSelect)
    );
  };

  const onMapProduct = (productSelect: IProduct) => {
    const APIMap = {
      method: mapDummyProduct,
      params: [
        {
          order_custom_product_id: dummyProduct?.id,
          product_id: productSelect?.id,
        },
      ],
    };

    Progress.show(APIMap, (res) => {
      Notifications.showSuccess(t("mapDummyProductSuccess"));
      onReloadOrderData && onReloadOrderData();
    });
  };

  const source = (
    currentPage: number,
    inputSearch: string,
    filterBodyParam: any
  ) => {
    const body = {
      ...filterBodyParam,
      search: inputSearch,
      isCustomSize: true,
      product_type: [
        PRODUCT_TYPE.CUSTOM_SIZE,
        PRODUCT_TYPE.DEFAULT,
        PRODUCT_TYPE.GROUP,
      ],
    };
    return listProduct(body, {
      pageIndex: currentPage,
      pageSize: DEFAULT_PAGING.pageSize,
    });
  };

  if (
    order.status === ORDER_STATUS.refunded.value ||
    order.status === ORDER_STATUS.completed.value ||
    order.status === ORDER_STATUS.cancelled.value
  ) {
    return <div />;
  }

  return (
    <SearchGroupProducts
      onChange={onSelectMapProduct}
      selectedProducts={[]}
      stockConsider={false}
      customSource={source}
      isSingleSelect
      customMainContent={renderMainContent}
      allowSelectGroupProduct
    />
  );
};

export default MapDummyProduct;
