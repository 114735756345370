import InputVerifier, { VerifiedButton } from "@common/input/InputVerifier";
import { getFullName } from "@helpers/string";
import { CustomerType } from "@interfaces/customer";
import { Avatar, StringUtils, TimeUtils } from "d-react-components";
import React from "react";
import { useTranslation } from "react-i18next";

const CustomerInfoComponent = ({
  customer,
  showContactVerifier = false,
  channel,
  onVerifiedInfo,
}: any) => {
  const { t } = useTranslation();
  if (!customer || !customer.id) {
    return <></>;
  }
  return (
    <div className="flex-column">
      <Avatar
        src={customer.avatar || "/images/img-default.png"}
        className="w-16 h-16 rounded-full my-2"
      />
      {customer.customerid && (
        <span className="block">{`${t("id").toUpperCase()}: ${
          customer.customerid
        }`}</span>
      )}
      <span className="block">
        {customer.customer_type === CustomerType.BUSINESS ? (
          <>{customer.company}</>
        ) : (
          <>
            {getFullName(customer) +
              (customer?.nickname ? ` (${customer.nickname})` : "")}
          </>
        )}
      </span>
      <div className="flex items-center">
        <span className="block mr-2">{customer.email}</span>
        {showContactVerifier && (
          <InputVerifier
            type="email"
            value={customer?.email}
            className="ml-2"
            isVerified={customer?.is_verify_email}
            userId={customer?.id}
            payload={{ channel_id: channel?.id }}
            onVerified={(otp) => {
              if (onVerifiedInfo) {
                onVerifiedInfo({ is_verify_email: true });
              }
            }}
          />
        )}
      </div>
      <div className="flex items-center">
        <span className="block mr-2">{customer.phone}</span>
        {showContactVerifier &&
          customer.phone &&
          customer.customer_type === CustomerType.INDIVIDUAL && (
            <InputVerifier
              type="phone"
              value={customer?.phone}
              className="ml-2"
              isVerified={customer?.is_verify_phone}
              userId={customer?.id}
              payload={{ channel_id: channel?.id }}
              onVerified={(otp) => {
                if (onVerifiedInfo) {
                  onVerifiedInfo({ is_verify_phone: true });
                }
              }}
            />
          )}
      </div>
      {customer?.alternative_phone && (
        <span className="block">{customer?.alternative_phone}</span>
      )}
      <div hidden={!customer.gender}>{`${t("gender")}: ${t(
        customer.gender
      )}`}</div>
      <div hidden={!customer.birthday}>{`${t(
        "birthday"
      )}: ${TimeUtils.convertMiliToDate(customer.birthday)}`}</div>
      <div hidden={!customer.number_of_orders}>{`${t("numberOfOrder")}: ${
        customer.number_of_orders
      }`}</div>{" "}
      <div hidden={!customer.total_spending}>{`${t(
        "totalSpending"
      )}: ${StringUtils.moneyThaiFormat(customer.total_spending)}`}</div>
    </div>
  );
};

export default CustomerInfoComponent;
