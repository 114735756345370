import { API } from "@network";
import {
  API_SERIAL_NUMBER_ACTIVATE,
  API_SERIAL_NUMBER_ACTIVITIES,
  API_SERIAL_NUMBER_ACTIVITIES_CREATE,
  API_SERIAL_NUMBER_CANCELLED,
  API_SERIAL_NUMBER_DETAIL,
  API_SERIAL_NUMBER_LIST,
  API_SERIAL_NUMBER_PRINT_LOG,
  API_SERIAL_NUMBER_PRINT_TAG,
} from "../URL";

const SerialNumberAPI = {
  list: (body: any, paging: any) => {
    return API.post(
      `${API_SERIAL_NUMBER_LIST}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
      body
    );
  },

  detail: (id: any) => {
    return API.post(API_SERIAL_NUMBER_DETAIL, { id });
  },

  print: (id: any) => {
    return API.post(API_SERIAL_NUMBER_PRINT_TAG, { id });
  },

  printLog: (id: any, paging: any) => {
    return API.post(
      `${API_SERIAL_NUMBER_PRINT_LOG}?page=${paging?.pageIndex ?? 1}&per_page=${
        paging?.pageSize ?? 20
      }`,
      {
        id,
      }
    );
  },

  activate: (serial: any) => {
    return API.post(API_SERIAL_NUMBER_ACTIVATE, { serial });
  },

  cancelled: (id: any) => {
    return API.post(API_SERIAL_NUMBER_CANCELLED, { id });
  },

  activityList: (paging: any, body: any) => {
    return API.post(
      `${API_SERIAL_NUMBER_ACTIVITIES}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
      body
    );
  },

  activityAdd: (body: any) => {
    return API.post(API_SERIAL_NUMBER_ACTIVITIES_CREATE, body);
  },
};

export default SerialNumberAPI;
