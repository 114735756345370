import Drawer from "@components/shared/Drawer";
import { DrawerProps } from "@interfaces/common";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import {
  AwesomeTableComponent,
  Badge,
  Button,
  IColumnsProps,
  InputTextSearch,
} from "d-react-components";
import ChannelAPI from "@network/api/channel";
import { find, map, sum } from "lodash";
import UserAPI from "@network/api/user";
import AppContext from "@helpers/context";
import classNames from "classnames";
interface RequestDrawerProps extends DrawerProps {
  action: "add" | "remove";
  onSave: any;
  itemToAdd?: any[];
  itemToRemove?: any[];
  item?: any;
}

const NewRequestUpdateChannel = ({
  open,
  onClose,
  action,
  onSave,
  itemToAdd = [],
  itemToRemove = [],
  item,
}: RequestDrawerProps) => {
  const { summary } = useContext(AppContext);
  const { t } = useTranslation();
  const [pagination, setPagination] = useState<any>();
  const [selectedRow, setSelectedRow] = useState<any[]>(
    action === "add" ? itemToAdd : itemToRemove
  );

  const displaySelectedRow = useMemo(() => {
    //remove the default selected one
    return action === "add"
      ? selectedRow.filter((row) => !row.selected)
      : selectedRow;
  }, [action, selectedRow]);

  const listTab = useMemo(
    () => [
      ...(action === "add"
        ? [
            {
              id: "all",
              label: t("allChannels"),
              items: pagination?.items,
            },
          ]
        : []),
      {
        id: "current",
        label: t("myCurrentChannels"),
        items: summary?.channel,
      },
      {
        id: "selected",
        label: t("selectedChannels"),
        items: displaySelectedRow?.length,
      },
    ],
    [t, action, pagination, summary, displaySelectedRow]
  );
  const [currentTab, setCurrentTab] = useState(listTab[0]);
  const [searchValue, setSearchValue] = useState("");
  const tableRef = useRef<any>(null);
  const tableRefCurrent = useRef<any>(null);
  const source = (paging: any, filter: string) => {
    const body = {
      action: filter,
      search: searchValue,
      id: item.id,
    };
    return UserAPI.channelList(body, paging).then(
      (resp) => {
        setPagination(resp?.data?.data?.pagination);
        if (filter === "all" && action === "add") {
          setSelectedRow([
            ...selectedRow,
            ...resp?.data?.data?.channels?.filter((item: any) => item.selected),
          ]);
        }
        return resp;
      },
      (err) => {
        return err;
      }
    );
  };

  const columns: IColumnsProps = useMemo(
    () => [
      {
        title: t("name"),
        dataIndex: "name",
        render: (name: string, item: any) => (
          <span
            className={classNames({
              "text-gray-300": item.selected && currentTab.id === "all",
            })}
          >
            {name}
          </span>
        ),
      },
      {
        title: t("code"),
        dataIndex: "code",
        render: (code: string, item: any) => (
          <span
            className={classNames({
              "text-gray-300": item.selected && currentTab.id === "all",
            })}
          >
            {code}
          </span>
        ),
      },
    ],
    [t, currentTab]
  );

  useEffect(() => {
    setCurrentTab(listTab[0]);
  }, []);

  //custom function to set selected rows because table doesn't keep start/end date info
  const setRowSelection = (newRows: any[]) => {
    const rowsToUpdate = newRows.map((row) => {
      const currentRowInfo = find(selectedRow, { id: row.id });
      return currentRowInfo
        ? {
            ...currentRowInfo,
            ...row,
          }
        : row;
    });
    setSelectedRow(rowsToUpdate);
  };

  return (
    <Drawer
      width={"800px"}
      size="auto"
      open={open}
      onClose={onClose}
      title={`${t(action)} ${t("channel")}`}
      onSave={() => onSave(displaySelectedRow)}
      destroyOnClose
    >
      <div className="bg-white">
        <label className="text-label">
          {t("list")} {t("channel")}
        </label>
        <div className="border py-3 px-3">
          <div className="flex items-center mb-3">
            {listTab?.map((tab, index) => (
              <Button
                size="small"
                className="mr-2"
                color={currentTab.id === tab.id ? "primary" : "gray"}
                variant={currentTab.id === tab.id ? "standard" : "outline"}
                onClick={() => setCurrentTab(tab)}
              >
                {tab.label}
                {tab.items > 0 && (
                  <Badge
                    index={tab.items}
                    variant="index"
                    color="red"
                    className="ml-1"
                  />
                )}
              </Button>
            ))}
          </div>
          {currentTab.id !== "selected" && (
            <InputTextSearch
              value={searchValue}
              onChange={(e: any) => setSearchValue(e?.target?.value)}
              onSubmit={() => tableRef?.current?.refresh()}
              className="mb-3"
            />
          )}
          <div>
            {currentTab.id === "all" && (
              <AwesomeTableComponent
                source={(paging) => source(paging, "all")}
                transformer={(response) => response?.data?.data?.channels}
                columns={columns}
                ref={tableRef}
                rowSelection={{
                  selectedRowKeys: map(selectedRow, (item: any) => item.id),
                  onChange: (selectedRowKeys, selectedRows: any) => {
                    setRowSelection(selectedRows);
                  },
                  preserveSelectedRowKeys: true,
                  getCheckboxProps: (record: any) => ({
                    disabled: record.selected,
                  }),
                }}
              />
            )}
            {currentTab.id === "current" && (
              <AwesomeTableComponent
                source={(paging) => source(paging, "in")}
                transformer={(response) => response?.data?.data?.channels}
                columns={columns}
                ref={tableRefCurrent}
                {...(action === "add"
                  ? {}
                  : {
                      rowSelection: {
                        selectedRowKeys: map(
                          selectedRow,
                          (item: any) => item.id
                        ),
                        onChange: (selectedRowKeys, selectedRows: any) => {
                          setRowSelection(selectedRows);
                        },
                        preserveSelectedRowKeys: true,
                      },
                    })}
              />
            )}
            {currentTab.id === "selected" && (
              <AwesomeTableComponent
                dataSource={displaySelectedRow}
                columns={columns}
                ref={tableRefCurrent}
                rowSelection={{
                  selectedRowKeys: map(
                    displaySelectedRow,
                    (item: any) => item.id
                  ),
                  onChange: (selectedRowKeys, selectedRows: any) => {
                    setSelectedRow([
                      ...selectedRow.filter((row) => row.selected),
                      ...selectedRows,
                    ]);
                  },
                  preserveSelectedRowKeys: true,
                }}
              />
            )}
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default NewRequestUpdateChannel;
