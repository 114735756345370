import {
  Avatar,
  AwesomeTableComponent,
  Button,
  HeaderTable,
  IColumnsProps,
  Icon,
  Modal,
  Select,
  TimeUtils,
  ViewLabelStatus,
} from "d-react-components";
import { filter, find } from "lodash";
import React, {
  ElementRef,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import {
  TICKET_SEARCH_OPTIONS,
  TICKET_SEARCH_TYPE_DEFAULT,
  TICKET_STATUS_ARRAY,
} from "@constants/call";
import { ORDER_TABLE_LAYOUT_KEY } from "@components/order/OrderTable";
import AppContext from "@helpers/context";
import { getTicketDetail, searchTicket } from "@network/api/callcenter";
import { Reply, ReplyType, SupportTicket } from "@interfaces/ticket";
import { findTranslation } from "@helpers/locales";
import UserAvatarName from "@components/shared/UserAvatarName";
import styled from "@emotion/styled";

export const RecentSupportTicketTable = () => {
  const { t } = useTranslation();
  const { callerInfo } = useContext(AppContext);
  const [showTicketPopup, setShowTicketPopup] = useState();
  const [showSearchPopup, setShowSearchPopup] = useState(false);
  const tableRef = useRef<any>(null);

  const columns: IColumnsProps = useMemo(
    () => [
      {
        title: t("createdAt"),
        dataIndex: "created",
        render: (data) => {
          return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
        },
      },
      {
        title: t("channel"),
        dataIndex: "channel",
        render: (data) => {
          return data?.name;
        },
      },
      {
        title: t("id"),
        dataIndex: "id",
        render: (data) => (
          <a onClick={() => setShowTicketPopup(data)}>{data}</a>
        ),
      },
      {
        title: t("title"),
        dataIndex: "subject",
      },
      {
        title: t("status"),
        dataIndex: "status",
        render: (status: string) => {
          const ticketStatus = find(
            TICKET_STATUS_ARRAY,
            (item) => item.value === status
          );
          return (
            <ViewLabelStatus
              content={t(`${ticketStatus?.content}`)}
              color={ticketStatus?.color}
            />
          );
        },
      },
    ],
    [t]
  );
  const source = async ({ pageIndex, pageSize }: any) => {
    return searchTicket({
      search: callerInfo.phone,
      search_type: "customer_phone",
      page: pageIndex,
      limit: pageSize,
    });
  };

  return (
    <>
      <div className="flex mb-3">
        <div className="flex-1">
          <h4>{t("recentSupportTickets")}</h4>
        </div>
        <Button size="small" onClick={() => setShowSearchPopup(true)}>
          <Icon name="search" className="mr-2" /> {t("masterSearch")}
        </Button>
      </div>
      <AwesomeTableComponent
        source={source}
        transformer={(response) => {
          return response?.data?.data?.ticket ?? [];
        }}
        columns={columns}
        ref={tableRef}
      />
      {showSearchPopup && (
        <TicketMasterSearch
          open={showSearchPopup}
          onClose={() => setShowSearchPopup(false)}
        />
      )}
      {showTicketPopup && (
        <TicketPopup
          open={showTicketPopup}
          onClose={() => setShowTicketPopup(undefined)}
          ticketId={showTicketPopup}
        />
      )}
    </>
  );
};

const TicketMasterSearch = ({ open, onClose }: any) => {
  const [search, setSearch] = useState("");
  const [showTicketPopup, setShowTicketPopup] = useState();
  const { callerInfo } = useContext(AppContext);
  const [searchType, setSearchType] = useState(TICKET_SEARCH_TYPE_DEFAULT);
  const { t } = useTranslation();
  const orderTableRef = useRef<ElementRef<typeof AwesomeTableComponent>>(null);
  const refreshTable = () => {
    return orderTableRef.current && orderTableRef.current.refresh();
  };
  const source = async ({ pageIndex, pageSize }: any) => {
    return searchTicket({
      search: search || callerInfo.phone,
      search_type: searchType || "customer_phone",
      page: pageIndex,
      limit: pageSize,
    });
  };
  const columns: IColumnsProps = useMemo(
    () => [
      {
        title: t("id"),
        dataIndex: "id",
        width: "100px",
        render: (data) => (
          <a onClick={() => setShowTicketPopup(data)}>{data}</a>
        ),
      },
      {
        title: t("createdAt"),
        dataIndex: "created",
        render: (data) => {
          return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
        },
      },
      {
        title: t("channel"),
        dataIndex: "channel",
        render: (data) => {
          return data?.name;
        },
      },
      {
        title: t("customer"),
        dataIndex: "customer",
        render: (data) => {
          return `${data?.first_name} ${data?.last_name}`;
        },
      },
      {
        title: t("title"),
        dataIndex: "subject",
      },
      {
        title: t("status"),
        dataIndex: "status",
        render: (status: string) => {
          const ticketStatus = find(
            TICKET_STATUS_ARRAY,
            (item) => item.value === status
          );
          return (
            <ViewLabelStatus
              content={t(`${ticketStatus?.content}`)}
              color={ticketStatus?.color}
            />
          );
        },
      },
      {
        title: t("assignedTo"),
        dataIndex: "assigned",
        render: (data) => {
          return `${data?.first_name} ${data?.last_name}`;
        },
      },
      {
        title: t("updatedAt"),
        dataIndex: "updated",
        render: (data) => {
          return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
        },
      },
    ],
    [t]
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      width="100vw"
      title={t("allTicketsMasterSearch")}
    >
      <HeaderTable
        onChangeText={(e: React.FormEvent<HTMLInputElement>) => {
          setSearch(e?.currentTarget.value);
        }}
        searchValue={search}
        placeholder={t("search")}
        onSubmitSearch={() => refreshTable()}
        customView={() => (
          <Select
            value={searchType}
            dataSource={TICKET_SEARCH_OPTIONS.map((item) => ({
              ...item,
              label: t(item.label),
            }))}
            onChange={(valueId) => setSearchType(valueId)}
            getLabel={(item) => item.label}
            classNameSelect="height-[37px]"
            placeholder={t("pleaseSelect")}
          />
        )}
      />
      <AwesomeTableComponent
        source={source}
        transformer={(response) => {
          return response?.data?.data?.ticket ?? [];
        }}
        columns={columns}
        ref={orderTableRef}
        showSelectColumn
        keyTableLayout={ORDER_TABLE_LAYOUT_KEY}
        tableLayout="fixed"
        className="mt-3"
      />
      {showTicketPopup && (
        <TicketPopup
          open={showTicketPopup}
          onClose={() => setShowTicketPopup(undefined)}
          ticketId={showTicketPopup}
        />
      )}
    </Modal>
  );
};

const TicketPopup = ({ open, onClose, ticketId }: any) => {
  const { t } = useTranslation();
  const [ticketDetail, setTicketDetail] = useState<SupportTicket>();
  const { currentLang } = useContext(AppContext);

  useEffect(() => {
    getTicketDetail({ id: ticketId }).then((resp) => {
      setTicketDetail(resp?.data?.data?.ticket);
    });
  }, []);

  const ticketStatus = useMemo(() => {
    const status = find(ticketDetail?.tags, { type: "status" })?.name ?? "";
    const ticketStatus = find(
      TICKET_STATUS_ARRAY,
      (item) => item.value === status
    );
    return ticketStatus;
  }, [ticketDetail]);

  const tags = useMemo(() => {
    return filter(ticketDetail?.tags, (tag) => tag.type !== "status") ?? [];
  }, [ticketDetail]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      width="100vw"
      title={`${t("ticket")} #${ticketDetail?.id ?? ""}`}
      showFooter={false}
    >
      <div className="bg-white p-3">
        <div className="border p-3 mb-3">
          <div className="flex items-center">
            <span className="text-bold mr-2">{`${t("ticket")} #${
              ticketDetail?.id ?? ""
            }`}</span>
            {ticketStatus && (
              <ViewLabelStatus
                content={t(`${ticketStatus?.content}`)}
                color={ticketStatus?.color}
                className="rounded-none py-1 px-2 mr-2"
                style={{ borderRadius: "0px !important" }}
              />
            )}
            {tags?.length > 0 &&
              tags.map((tag, index) => (
                <div
                  key={index}
                  className="bg-primary text-white flex items-center h-[22px] p-1 text-xs"
                >
                  <Icon name="bookmark_border" className="mr-1" />
                  {findTranslation(tag.translations)?.display_name}
                </div>
              ))}
          </div>
          <div className="flex mt-3 items-center">
            <div className="flex mr-3 items-center">
              <Icon name="date_range" />
              <div className="text-xs ml-2">{`${t(
                "order:createdOn"
              )} ${TimeUtils.convertMiliToDateTime(
                ticketDetail?.created_at
              )}`}</div>
            </div>
            <div className="flex mr-3 items-center text-xs">
              <Icon name="person_outline" />
              <span className="mx-1">{t("createdBy")}</span>
              <UserAvatarName
                user={ticketDetail?.created_by}
                size="xx-small"
                variant="square"
                text={ticketDetail?.created_by?.display_name}
              />
            </div>
            <div className="flex mr-3 items-center text-xs">
              <Icon name="person_outline" />
              <span className="mx-1">{t("assignedTo")}</span>
              <UserAvatarName
                user={ticketDetail?.assignee}
                size="xx-small"
                variant="square"
                text={ticketDetail?.assignee?.display_name}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-4">
          <div className="col-span-3">
            {ticketDetail?.replies?.map((reply, index) => (
              <TicketReply reply={reply} key={index} />
            ))}
          </div>
          <div className="col-span-1">
            <div className="border p-3 mb-3">
              <h5 className="mb-2">{t("customer")}</h5>
              <Avatar
                src={ticketDetail?.user?.avatar}
                size="medium"
                variant="circle"
                className="mb-2"
              />
              <span className="block mb-2">
                {ticketDetail?.user?.display_name}
              </span>
              <span className="block mb-2">
                {ticketDetail?.user?.domain?.site_name} -{" "}
                {ticketDetail?.user?.source?.display_name}
              </span>
              <span className="block mb-2">{ticketDetail?.user?.phone}</span>
              <span className="block mb-2">
                <a href={`mailTo:${ticketDetail?.user?.email}`}>
                  {ticketDetail?.user?.email}
                </a>
              </span>
            </div>
            <div className="border p-3 mb-3">
              <h5 className="mb-2">{t("ticketDetail")}</h5>
              <span className="block mb-2">
                {t("id")}: {ticketDetail?.id}
              </span>
              <span className="block mb-2">
                {t("ticketType")}: {ticketDetail?.ticket_type?.display_name}
              </span>
              <span className="block mb-2">
                {t("createdAt")} {ticketDetail?.created_at_formatted}
              </span>
              <span className="block mb-2">
                {t("createdBy")} {ticketDetail?.created_by?.display_name}
              </span>
              <span className="block mb-2">
                {t("assignedTo")} {ticketDetail?.assignee?.display_name}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const TicketReply = ({ reply }: { reply: Reply }) => {
  const { t } = useTranslation();
  const getReplyText = () => {
    switch (reply.type) {
      case "spam":
        return t("markedAsSpam");
      case "closed":
        return t("closedThisTicket");
      case "open":
        return t("openedThisTicket");
      case "notes":
        return t("addedInternalNote");
      case "replies":
        return t("repliesTheConversation");
    }
  };
  return (
    <StyledTicketReply type={reply.type} className="border p-3">
      <div className="flex items-center">
        <div className="flex items-center flex-1">
          <UserAvatarName
            user={reply?.user}
            size="xx-small"
            variant="square"
            text={reply?.user?.display_name}
            className="mr-1"
          />
          <span>{getReplyText()}</span>
        </div>
        <div className="">{reply.created_at_formatted}</div>
      </div>
      {reply?.body && <div className="mt-3">{parse(reply?.body ?? "")}</div>}
    </StyledTicketReply>
  );
};

const handleType = (type: ReplyType) => {
  switch (type) {
    case "notes":
      return `
        background-color: #ffc42d21;
        border-left: 4px solid #FFD35A!important;
      `;
    case "spam":
      return `
        background-color: #22232321;
        border-left: 4px solid #3D3D3D!important;
      `;
    case "closed":
      return `
        background-color: #FFD6D6;
        border-left: 4px solid #CA1010!important;
      `;
    case "open":
      return `
        background-color: #27975426;
        border-left: 4px solid #279754!important;
      `;
    default:
      return `
        background-color: #FFF;
      `;
  }
};

export const StyledTicketReply: any = styled.div`
  ${(props: any) => handleType(props.type)}
`;
