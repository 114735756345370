/* eslint-disable jsx-a11y/alt-text */
import Icon from "@common/Icon";
import Drawer from "@components/shared/Drawer";
import AppContext from "@helpers/context";
import RawMaterialAPI from "@network/api/raw-material";
import { IRowsKey, ViewRowInterchange } from "d-react-components";
import { useFormik } from "formik";
import { isEmpty, isString, map, reduce } from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

interface IDetailSku {
  open: boolean;
  defaultValue?: any;
  onUpdated: any;
  onClose: () => void;
}

const DetailSkuDrawer = ({
  open,
  defaultValue,
  onUpdated,
  onClose,
}: IDetailSku) => {
  const { t } = useTranslation();
  const { rawMaterialId } = useParams();

  const rawMaterialForm = useFormik<any>({
    initialValues: defaultValue as any,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: schema,
    onSubmit: (values: any) => {
      onUpdate(values);
    },
  });
  const formValues = rawMaterialForm?.values;
  const formErrors = rawMaterialForm?.errors;

  const onUpdate = (values: any) => {
    const { aliasSkuList, sku } = values;

    const body = {
      id: rawMaterialId,
      sku,
      alias_sku: map(aliasSkuList, (item) => item.value),
    };

    // Progress.show(
    //   {
    //     method: RawMaterialAPI.update,
    //     params: [body],
    //   },
    //   (res: any) => {
    //     Notifications.showSuccess(t("updateSuccess"));
    //     onUpdated();
    //     onClose();
    //   }
    // );
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("sku")}
      size="auto"
      width="80vw"
      onSave={() => {
        rawMaterialForm.handleSubmit();
      }}
    >
      <div>
        <div />
      </div>
    </Drawer>
  );
};

const RawMaterialDetailSku = () => {
  const { rawMaterialDetail, loadRawMaterialDetail } = useContext(AppContext);
  const [openEdit, setOpenEdit] = useState(false);
  const { t } = useTranslation();
  const [materialInventoryList, setMaterialInventoryList] = useState<any[]>([]);
  const { rawMaterialId } = useParams();

  useEffect(() => {
    onLoadMaterialStock();
  }, []);

  const totalStock = useMemo(() => {
    const total = reduce(
      materialInventoryList,
      (sum, item) => parseInt(item?.stock) + sum,
      0
    );

    let result: any = 0;
    result = isNaN(total) ? 0 : total;
    if (isString(result)) {
      result = result.replace(/\D+/g, "");
      result = parseInt(result).toLocaleString();
    }
    return result;
  }, [materialInventoryList]);

  const DETAILS_KEY = useMemo<IRowsKey<any>[]>(
    () => [
      {
        id: "sku",
        label: t("sku"),
      },
      {
        id: "total",
        label: t("total"),
        renderContent: ({ data, item }) => {
          return totalStock;
        },
      },
    ],
    [t]
  );

  const onLoadMaterialStock = () => {
    return RawMaterialAPI.listStock(rawMaterialId ?? "")
      .then((respone) => {
        const listStock = respone?.data?.data?.stock ?? [];
        if (!isEmpty(listStock)) {
          const clone = map(listStock, (item) => ({
            ...item,
            stock: item?.stock ?? 0,
            warehouse: item?.warehouse?.id ?? "",
          }));
          setMaterialInventoryList(clone);
        }
      })
      .catch((err) => console.log({ err }));
  };

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mt-3">
        <h5>{t("sku")}</h5>
        {/* <span onClick={() => setOpenEdit(true)}>
          <Icon
            className="w-[15px] cursor-pointer"
            src="/images/icons/edit.svg"
          />
        </span> */}
      </div>
      <div className="mt-3 border p-4 ">
        <ViewRowInterchange
          dataSource={rawMaterialDetail}
          keyList={DETAILS_KEY as any}
          variant="border"
          classNameLabel="text-hint "
          classNameContent="text-end "
        />
        {openEdit && (
          <DetailSkuDrawer
            open={openEdit}
            onClose={() => setOpenEdit(false)}
            defaultValue={rawMaterialDetail}
            onUpdated={loadRawMaterialDetail}
          />
        )}
      </div>
    </div>
  );
};

export default RawMaterialDetailSku;
