import { API } from "@network";
import {
  API_VEHICLE_ACTIVITY_ADD,
  API_VEHICLE_ACTIVITY_LIST,
  API_VEHICLE_CREATE,
  API_VEHICLE_DETAIL,
  API_VEHICLE_LIST,
  API_VEHICLE_UPDATE,
  API_VEHICLE_UPDATE_MEDIA,
} from "@network/URL";

export const getVehicleList = (body: any, paging: any) => {
  return API.post(
    `${API_VEHICLE_LIST}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
    body
  );
};

export const createVehicle = (body: any) => {
  return API.post(API_VEHICLE_CREATE, body);
};

export const getVehicleDetail = (id: any) => {
  return API.post(API_VEHICLE_DETAIL, { id });
};

export const updateVehicle = (body: any) => {
  return API.post(API_VEHICLE_UPDATE, body);
};

export const getVehicleActivityList = (body: any, paging: any) => {
  return API.post(
    `${API_VEHICLE_ACTIVITY_LIST}?page=${paging?.pageIndex}`,
    body
  );
};

export const addVehicleActivity = (body: any) => {
  return API.post(API_VEHICLE_ACTIVITY_ADD, body);
};

export const updateVehicleMedia = (body: any) => {
  return API.post(API_VEHICLE_UPDATE_MEDIA, body);
};
