import { IMPORT_PRODUCT_METHODS } from "@constants/product";
import AppContext from "@helpers/context";
import { CheckboxGroup, Select } from "d-react-components";
import { map, includes, filter } from "lodash";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

const SelectImportMethod = () => {
  const { step, formImport, importFieldSource } = useContext(AppContext);
  const { t } = useTranslation();

  const { importType, importFields } = formImport?.values;

  const onChangeType = (value: string) => {
    console.log("importFieldSource", importFieldSource);
    let importFieldsNew = importFields;
    if (value === "create") {
      importFieldsNew = filter(importFieldSource, (item) => item.isRequired);
    }
    formImport.setValues({
      ...formImport?.values,
      importType: value,
      importFields: importFieldsNew,
    });
  };

  const onChangeImportField = (values = []) => {
    formImport.setFieldValue(
      "importFields",
      filter(
        importFieldSource,
        (item) => includes(values, item.name) || item.required
      )
    );
  };

  return (
    <div className="p-4">
      <text>{t(step?.desc)}</text>
      <Select
        className="mt-3"
        placeholder={t("selectImportTypeHint")}
        dataSource={IMPORT_PRODUCT_METHODS}
        value={importType}
        getLabel={(item) => t(item.label)}
        onChange={onChangeType}
      />

      <CheckboxGroup
        label={t("whichElementDoYouWantUpdate")}
        className="mt-3"
        dataSource={importFieldSource}
        value={map(importFields, (item) => item.name)}
        getValue={(item) => item.name}
        onChange={onChangeImportField as any}
        getDisabledItem={(item) => {
          if (importType === "create") {
            return item.isRequired;
          }
          return false;
        }}
        // disabled={importType === "create"}
        showSelectAll
      />
    </div>
  );
};

export default SelectImportMethod;
