import InputTextForm from "@common/input/InputTextForm";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const ChannelFormSms = ({ form, fieldClassName = "" }: any) => {
  const { t } = useTranslation();

  const classNameField = classNames("col-6 mt-3", fieldClassName);

  console.log("form?.formValues?.color", form?.formValues?.color);
  return (
    <div className="row">
      <InputTextForm
        keyData="senderNameSms"
        form={form}
        className={classNameField}
      />
      <InputTextForm
        keyData="shortDomain"
        form={form}
        className={classNameField}
      />
    </div>
  );
};

export default ChannelFormSms;
