import React, { useContext, useEffect, useMemo, useState } from "react";
import Drawer, { DrawerProps } from "@components/shared/Drawer";
import { useTranslation } from "react-i18next";
import {
  cancelRentalInvoice,
  detailRentalInvoice,
  getContractLinkAccessToken,
  updateRentalInvoice,
} from "@network/api/rental";
import { RentalInvoice, RentalPayment } from "@interfaces/rent";
import { getPaidFromPayments } from "@helpers/payment";
import ImageIcon from "@common/Icon";
import { find, flatten, isEmpty, map, reduce } from "lodash";
import { Dropdown, Spin, Tooltip } from "antd";
import {
  Button,
  DialogManager,
  Icon,
  InputText,
  Notifications,
  Progress,
  StringUtils,
  TimeUtils,
  ViewLabelStatus,
} from "d-react-components";
import CurrencyFormat from "react-currency-format";
import Carousel, { Modal, ModalGateway } from "react-images";
import {
  CONTRACT_PAYMENT_PROOF_STATUS,
  CONTRACT_PAYMENT_STATUS,
  CONTRACT_STATUS,
  ContractPaymentProofStatus,
  ContractPaymentStatus,
  ContractStatus,
  ContractTypeOfPayment,
} from "@constants/contract";
import AppContext from "@helpers/context";
import {
  PAYMENTS_METHOD,
  PAYMENTS_METHODS,
  PAYMENT_DEFAULT,
} from "@constants/payment";
import { UploadPaymentInfo } from "@components/order/components/payment/ModalUploadPaymentInfo";
import { isGrantURLPermission } from "@helpers/permissions";
import {
  API_UPDATE_CONTRACT_INVOICE,
  API_UPLOAD_CONTRACT_PROOF_PAYMENT,
} from "@network/URL";
import PaymentContainer from "@components/order/components/payment/PaymentContainer";
import UploadProofPaymentModal from "./UploadProofPaymentModal";
import ConfirmProofButtons from "./ConfirmProofButtons";
import UserAvatarName from "@components/shared/UserAvatarName";
import ChangePaymentMethodModal from "./ChangePaymentMethodModal";
import { SplitPaymentInput } from "@components/order/components/payment/PaymentInformation";
import { generateSplitPaymentPayload } from "./CreateInvoiceModal";

const InvoiceDetailModal = ({
  open,
  onClose,
  id,
}: DrawerProps & { id: string; onClose: any }) => {
  const { t } = useTranslation();
  const { rentalInfo, channel, onReloadOrderData } = useContext(AppContext);
  const [invoiceDetail, setInvoiceDetail] = useState<RentalInvoice>();
  const [openSplitPaymentModal, setOpenSplitPaymentModal] = useState(false);
  const [showEditDescription, setShowEditDescription] = useState(false);
  const [loading, setLoading] = useState(false);

  const getTotalRemainingOrder = () => {
    const totalAmount = parseFloat(invoiceDetail?.total ?? "0");
    const paid = getPaidFromPayments(invoiceDetail?.payments as any[]);
    return totalAmount - paid;
  };

  const loadContractInvoice = () => {
    if (id) {
      setLoading(true);
      detailRentalInvoice({ id })
        .then((resp) => {
          setInvoiceDetail(resp?.data?.data?.invoice);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    loadContractInvoice();
  }, [id]);

  const statusItem = useMemo(
    () => find(CONTRACT_STATUS, { value: invoiceDetail?.status }),
    [invoiceDetail]
  );

  const isShowSplitPayment = useMemo(() => {
    return (
      invoiceDetail?.type_of_payment === ContractTypeOfPayment.INVOICE &&
      (invoiceDetail?.status === ContractStatus.PENDING ||
        invoiceDetail?.status === ContractStatus.PARTIALLY_PAID)
    );
  }, [invoiceDetail]);

  const onClickSplitPayment = () => {
    setOpenSplitPaymentModal(true);
  };

  const onClickCopy = (item: any) => {
    if (item.key === "copyURLForAdmin") {
      getContractLinkAccessToken({ id: rentalInfo?.id }).then((resp) => {
        const token = resp?.data?.data?.token;
        const invoiceLink = `${channel?.domain}/invoice/${rentalInfo?.id}/${invoiceDetail?.id}?access_token=${token}`;
        navigator.clipboard.writeText(invoiceLink as string);
        Notifications.showInfo(t("order:copytoclipboard"), t("order:orderURL"));
      });
    } else {
      const invoiceLink = `${channel?.domain}/invoice/${rentalInfo?.id}/${invoiceDetail?.id}`;
      navigator.clipboard.writeText(invoiceLink as string);
      Notifications.showInfo(t("order:copytoclipboard"), t("order:orderURL"));
    }
  };

  const cancelInvoice = () => {
    DialogManager.showConfirm(
      t("cancelInvoice"),
      t("cancelInvoiceNote"),
      () => {
        const body = { id: invoiceDetail?.id };
        Progress.show({ method: cancelRentalInvoice, params: [body] }, () => {
          onReloadOrderData && onReloadOrderData();
          onClose && onClose();
          Notifications.showSuccess(t("cancelInvoiceSuccessful"));
        });
      }
    );
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={
        <div className="flex">
          <div className="flex-1">{invoiceDetail?.code ?? "N/A"}</div>
          <Dropdown
            trigger={["click"]}
            menu={{
              items: [
                {
                  label: t("copyURLForCustomer"),
                  key: "copyURLForCustomer",
                },
                {
                  label: t("copyURLForAdmin"),
                  key: "copyURLForAdmin",
                },
              ],
              onClick: onClickCopy,
            }}
            className="order-header__print-container"
          >
            <Button
              size="small"
              className="mr-2 px-2 relative top-[-2px]"
              style={{ minWidth: 0, height: "30px" }}
            >
              <Icon name="link" className="text-white" size="medium" />
            </Button>
          </Dropdown>
        </div>
      }
      destroyOnClose
      showSave={false}
      showCancel={false}
      width="600"
      size="auto"
      hideFooter={invoiceDetail?.status === ContractPaymentStatus.CANCELLED}
      customSideButton={
        <Button variant="outline" onClick={cancelInvoice}>
          {t("cancelInvoice")}
        </Button>
      }
    >
      {loading && (
        <div className="flex align-center justify-center">
          <Spin />
        </div>
      )}
      {!loading && (
        <>
          <div>
            <div className="border-b flex flex-col py-2">
              <span className="text-bold">{t("createdAt")}</span>
              <span>
                {TimeUtils.convertMiliToDateTime(invoiceDetail?.created)}
              </span>
            </div>
            <div className="border-b flex flex-col py-2">
              <div className="flex">
                <span className="text-bold flex-1">{t("description")}</span>
                {invoiceDetail?.type_of_payment ===
                  ContractTypeOfPayment.INVOICE && (
                  <span
                    className="cursor-pointer"
                    onClick={() => setShowEditDescription(true)}
                  >
                    <ImageIcon
                      src="/images/icons/edit.svg"
                      className="w-[15px] cursor-pointer"
                    />
                  </span>
                )}
              </div>
              <span>{invoiceDetail?.description}</span>
            </div>
            <div className="border-b flex flex-col py-2">
              <span className="text-bold">{t("totalAmount")}</span>
              <span>
                <CurrencyFormat
                  value={invoiceDetail?.total}
                  displayType="text"
                  thousandSeparator={true}
                  suffix="THB"
                />
              </span>
            </div>
            <div className="border-b flex flex-col py-2">
              <span className="text-bold">{t("paymentStatus")}</span>
              <span>
                <ViewLabelStatus
                  content={t(invoiceDetail?.status ?? "")}
                  color={(statusItem as any)?.color}
                />
              </span>
            </div>
          </div>
          <div className="justify-center mt-3 flex items-center">
            <label className="flex-1 text-sm mb-2">{t("payments")}</label>
            <div>
              {isShowSplitPayment && (
                <Button iconName="call_split" onClick={onClickSplitPayment}>
                  {t("splitPayment")}
                </Button>
              )}
            </div>
          </div>
          {getTotalRemainingOrder() >= 0 &&
            invoiceDetail?.payments &&
            map(invoiceDetail?.payments, (payment, index) => (
              <PaymentInvoice
                payload={payment}
                key={index}
                index={index}
                reloadInvoice={loadContractInvoice}
                invoice={invoiceDetail}
              />
            ))}
        </>
      )}
      {openSplitPaymentModal && (
        <SplitPaymentModal
          open={openSplitPaymentModal}
          onClose={() => setOpenSplitPaymentModal(false)}
          getTotalRemainingOrder={getTotalRemainingOrder}
          defaultListPayment={invoiceDetail?.payments ?? []}
        />
      )}
      {showEditDescription && (
        <DescriptionDrawer
          open={showEditDescription}
          onClose={() => setShowEditDescription(false)}
          invoice={invoiceDetail}
          onSave={() => {
            loadContractInvoice();
            setShowEditDescription(false);
          }}
        />
      )}
    </Drawer>
  );
};

export const SplitPaymentModal = ({
  open,
  onClose,
  getTotalRemainingOrder,
  defaultListPayment,
}: any) => {
  const { t } = useTranslation();
  const splitPayment = defaultListPayment?.map((payment: any) => ({
    ...payment,
    method: find(PAYMENTS_METHODS, { value: payment.method }),
    total: parseFloat(payment.total),
  }));
  const [remainAmount, setRemainAmount] = useState(0);
  const [listPayment, setListPayment] = useState(splitPayment);
  const { order, onReloadOrderData } = useContext(AppContext);
  const onClickSaveHandle = () => {
    const split = generateSplitPaymentPayload(
      listPayment,
      splitPayment,
      remainAmount,
      t
    );
    if (!isEmpty(split)) {
      Progress.show(
        {
          method: updateRentalInvoice,
          params: {
            id: order.id,
            payments: split,
          },
        },
        () => {
          onReloadOrderData && onReloadOrderData();
          onClose && onClose();
        }
      );
    }
  };

  const renderSummaryAmount = () => (
    <div className="px-3 py-2 bg-muted w-100">
      <div className="flex flex-col md:flex-row justify-content-between py-2">
        <label className="text-primary">{t("totalCart")}</label>
        <span>{StringUtils.moneyThaiFormat(getTotalRemainingOrder())}</span>
      </div>
      <div className="flex flex-col md:flex-row justify-content-between py-2 border-top-dashed">
        <label className="text-primary">{t("splitAmount")}</label>
        <span>
          {StringUtils.moneyThaiFormat(getTotalRemainingOrder() - remainAmount)}
        </span>
      </div>
      <div className="flex flex-col md:flex-row justify-content-between py-2 border-top-dashed">
        <label className="text-primary">{t("remainingAmount")}</label>
        <span>{StringUtils.moneyThaiFormat(remainAmount)}</span>
      </div>
    </div>
  );

  return (
    <Drawer
      title={t("splitPayment")}
      open={open}
      onClose={onClose}
      onSave={onClickSaveHandle}
      size="auto"
      width={500}
    >
      {renderSummaryAmount()}
      <SplitPaymentInput
        total={getTotalRemainingOrder()}
        setRemainAmount={(value: any) => setRemainAmount(value)}
        listPayment={listPayment}
        setListPayment={setListPayment}
        order={order}
      />
    </Drawer>
  );
};

export function PaymentInvoice({
  payload,
  index,
  reloadInvoice,
  invoice,
}: {
  payload: RentalPayment;
  index: number;
  reloadInvoice: any;
  invoice: RentalInvoice;
}) {
  const {
    method,
    total,
    code,
    status_of_proof,
    type_of_payment,
    proof_of_payment,
    proof_of_confirmation,
    remark,
  } = payload;
  const { t } = useTranslation();
  const { onReloadOrderData, order, banks } = useContext(AppContext);
  const [openChangeMethod, setOpenChangeMethod] = useState(false);
  const { paymentMethodsList } = useContext(AppContext);
  const isReject = status_of_proof === ContractPaymentProofStatus.REJECTED;
  const isApprove = status_of_proof === ContractPaymentProofStatus.APPROVED;

  const paymentMethod = useMemo(
    () =>
      find(paymentMethodsList, (item) => item.id === method) ?? PAYMENT_DEFAULT,
    [method, paymentMethodsList]
  );

  const [openModalUpload, setOpenModalUpload] = useState(false);

  const getTransferBank = () => {
    return ``;
  };
  const renderTooltipFileUpload = (paymentInfo: any) => {
    const comment = paymentInfo?.remark;
    return (
      <UploadPaymentInfo
        isView
        banks={[]}
        paymentMethod={method}
        defaultInfo={{
          fullNameTransfer: paymentInfo?.by?.name,
          comment,
          dateTransfer: paymentInfo?.created,
        }}
      />
    );
  };

  const isShowChangePaymentMethod = () => {
    if (
      isApprove ||
      isReject ||
      type_of_payment !== ContractTypeOfPayment.INVOICE
    ) {
      return false;
    }

    if (
      invoice.status &&
      invoice.status !== ContractPaymentStatus.PENDING &&
      invoice.status !== ContractPaymentStatus.PARTIALLY_PAID
    ) {
      return false;
    }

    if (!isGrantURLPermission(API_UPDATE_CONTRACT_INVOICE)) {
      return false;
    }

    return true;
  };

  const renderPaymentByBankTransfer = () => {
    const proofStatus = find(CONTRACT_PAYMENT_PROOF_STATUS, {
      value: payload?.status_of_proof,
    }) as any;
    const orderStatus = find(CONTRACT_PAYMENT_STATUS, {
      value: payload?.status,
    }) as any;
    const confirm = payload?.proof_of_confirmation?.[0];

    return (
      <PaymentContainer
        title={`${t("payment")} ${index ? index + 1 : ""}`}
        amount={`฿${total && total?.toLocaleString()}`}
        method={paymentMethod?.label}
        transactionId={code ?? null}
        renderButtons={
          <ConfirmProofButtons
            payload={payload}
            invoice={invoice}
            onUploaded={() => reloadInvoice()}
          />
        }
        onClickChangeMethod={() => setOpenChangeMethod(true)}
        isShowChangeMethod={isShowChangePaymentMethod()}
        numberOfInstalment={0}
        bankName={""}
        payment={payload}
        status={orderStatus}
      >
        <>
          <UploadPaymentInfoView
            paymentInfoList={proof_of_payment}
            payment={payload}
            onClickUploadButton={() => setOpenModalUpload(true)}
            renderTooltip={renderTooltipFileUpload}
            imgClass="w-24 h-24"
            invoice={invoice}
          />
          {(isApprove || isReject) && (
            <div className={`p-3 mt-2 bg-${proofStatus?.bgColor}-100 text-sm`}>
              <div className="border-b border-gray-500 mb-3 pb-2">
                <span className="text-bold">{t("financeConfirmation")}</span>
              </div>
              {isApprove && (
                <div className="">
                  <div className="flex items-center mb-1">
                    <span className="mr-2 font-weight-bold">
                      {t("status")}:
                    </span>{" "}
                    <span className="text-success">{t("approved")}</span>
                  </div>
                  <div className="flex items-center mb-1">
                    <span className="mr-2 font-weight-bold">
                      {t("confirmBy")}:
                    </span>{" "}
                    <UserAvatarName user={confirm?.by} size="xx-small" />
                  </div>
                  <div className="flex items-center mb-1">
                    <span className="mr-2 font-weight-bold">
                      {t("confirmAt")}:
                    </span>{" "}
                    {TimeUtils.convertMiliToDate(confirm?.created)}
                  </div>
                </div>
              )}

              {isReject && (
                <div className="">
                  <div className="flex items-center mb-1">
                    <span className="mr-2 font-weight-bold">
                      {t("status")}:
                    </span>{" "}
                    <span className="text-error">{t("rejected")}</span>
                  </div>
                  <div className="flex items-center mb-1">
                    <span className="mr-2 font-weight-bold">
                      {t("rejectBy")}:
                    </span>{" "}
                    <UserAvatarName user={confirm?.by} size="xx-small" />
                  </div>
                  <div className="flex items-center mb-1">
                    <span className="mr-2 font-weight-bold">
                      {t("rejectAt")}:
                    </span>{" "}
                    {TimeUtils.convertMiliToDate(confirm?.created)}
                  </div>
                </div>
              )}

              {/* {confirm_actual_date_payment && (
                <div className="my-1 text-success">
                  {`${t(
                    "actualDatePayment"
                  )}: ${TimeUtils.convertMiliToDateTime(
                    confirm_actual_date_payment
                  )}`}
                </div>
              )}

              {confirm_bank && (
                <div className="my-1 text-success">{`${t(
                  "actualBankReceivePayment"
                )}: ${getTransferBank()}`}</div>
              )} */}

              <UploadPaymentInfoView
                paymentInfoList={proof_of_confirmation}
                payment={payload}
                onClickUploadButton={() => setOpenModalUpload(true)}
                renderTooltip={renderTooltipFileUpload}
                imgClass="w-24 h-24"
                invoice={invoice}
              />

              {remark && (
                <div className="mt-2">
                  <div className="relative bg-white text-gray-800 p-2 mt-2 arrow-up-white">
                    {remark}
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      </PaymentContainer>
    );
  };

  return (
    <>
      {/* {method === PAYMENTS_METHOD.C2P ? (
        <PaymentBy2C2P
          index={index}
          payload={payload}
          onClickChangeMethod={() => setOpenChangeMethod(true)}
          onClickUpload={() => setOpenModalUpload(true)}
          isShowChangeMethod={isShowChangePaymentMethod()}
        />
      ) : ( */}
      {renderPaymentByBankTransfer()}
      {/* )} */}
      {openChangeMethod && (
        <ChangePaymentMethodModal
          open={openChangeMethod}
          onClose={() => setOpenChangeMethod(false)}
          payment={payload}
          invoice={invoice}
          onUpdated={() => {
            setOpenChangeMethod(false);
            Notifications.showSuccess(
              t("notification:updatePaymentMethodSuccessfully")
            );
            onReloadOrderData();
            reloadInvoice();
          }}
        />
      )}

      {openModalUpload && (
        <UploadProofPaymentModal
          open={openModalUpload}
          onClose={() => setOpenModalUpload(false)}
          payment={payload}
          onUploaded={() => reloadInvoice()}
        />
      )}
    </>
  );
}

const UploadPaymentInfoView = ({
  payment,
  paymentInfoList,
  onClickUploadButton,
  renderTooltip,
  imgClass = "w-32 h-32",
  invoice,
}: any) => {
  const { status } = payment;
  const { t } = useTranslation();
  const [modalImageView, setModalImageView] = useState({
    isVisible: false,
    index: 0,
  });
  const isShowUploadButton = () => {
    return (
      status !== ContractPaymentStatus.COMPLETED &&
      isGrantURLPermission(API_UPLOAD_CONTRACT_PROOF_PAYMENT) &&
      invoice?.status !== ContractPaymentStatus.CANCELLED &&
      invoice?.type_of_payment === ContractTypeOfPayment.INVOICE
    );
  };
  const attachments = reduce(
    paymentInfoList,
    (acc: any, crr) => {
      return [
        ...acc,
        ...crr.attachment?.map((att: any) => ({
          ...att,
          by: crr.by,
          created: crr.created,
        })),
      ];
    },
    []
  );

  return (
    <div>
      <div className="banktranferImageContainer mt-2 w-full flex whitespace-nowrap overflow-x-auto py-2">
        {attachments?.map((file: any, index: number) => {
          const tooltip = renderTooltip && renderTooltip(file);
          return (
            <div
              className="wrapImagePayment mr-1 relative cursor-pointer"
              onClick={() => {
                setModalImageView({ isVisible: true, index });
              }}
            >
              <img
                className={`fileUpload rounded object-contain bg-black m-1 ml-0 ${imgClass}`}
                src={file.attachment}
                alt=""
              />

              {tooltip && (
                <Tooltip
                  title={
                    <div className="containerTooltip bg-white p-2">
                      {tooltip}
                    </div>
                  }
                  overlayClassName="containerTooltip bg-white p-2"
                >
                  <Icon name="info" className="absolute -top-1 -right-1" />
                </Tooltip>
              )}
            </div>
          );
        })}
      </div>

      {isShowUploadButton() && (
        <Button
          iconName="cloud_upload"
          onClick={onClickUploadButton}
          color="dark"
          variant="outline"
          className="mb-3"
        >
          {t("upload")}
        </Button>
      )}

      {/* @ts-ignore */}
      <ModalGateway>
        {modalImageView.isVisible ? (
          //  @ts-ignore
          <Modal onClose={() => setModalImageView({ isVisible: false })}>
            {/* @ts-ignore  */}
            <Carousel
              currentIndex={modalImageView.index}
              views={attachments?.map((file: any) => ({
                caption: `${t("by")} ${file?.by?.name ?? " "}${t(
                  "at"
                )} ${TimeUtils.convertMiliToDateTime(file.created)}`,
                src: file.attachment,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};

const DescriptionDrawer = ({ open, onClose, onSave, invoice }: any) => {
  const [noteContent, setNoteContent] = useState(invoice?.description);
  const { t } = useTranslation();

  const splitPayment = invoice?.payments
    ?.filter((payment: any) => payment.status === "pending")
    ?.map((payment: any) => ({
      method: payment.method,
      total: parseFloat(payment.total),
    }));

  const onSaveDescription = () => {
    const body = {
      id: invoice.id,
      description: noteContent,
      payments: splitPayment,
    };
    Progress.show({ method: updateRentalInvoice, params: body }, () => {
      onSave();
    });
  };

  return (
    <Drawer
      title={t("description")}
      open={open}
      onClose={onClose}
      destroyOnClose
      onSave={onSaveDescription}
    >
      <div className="pb-10">
        <InputText
          label={t("description")}
          value={noteContent}
          onChange={(e) => setNoteContent(e.target.value)}
          multiple
          rows={5}
        />
      </div>
    </Drawer>
  );
};

export default InvoiceDetailModal;
