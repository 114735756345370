import UploadFile, { RenderPreviewFile } from "@components/shared/UploadFile";
import { COMMENT_TYPES, FILTER_COMMENT_TYPE } from "@constants/common";
import { getAdminAvatar } from "@helpers/account";
import TextareaAutosize from "react-textarea-autosize";
import parse from "html-react-parser";
import {
  AwesomeListComponent,
  Button,
  InputText,
  TimeUtils,
  Select,
  StringUtils,
  Avatar,
} from "d-react-components";
import ImageIcon from "@common/Icon";
import _ from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import Carousel, { Modal, ModalGateway } from "react-images";
import ButtonFileUpload from "@components/shared/ButtonFileUpload";
import UserAvatarName from "@components/shared/UserAvatarName";
// import ImageUploadItem from "./ImageUploadItem";

export const ActivityItem = ({ comment, onClickImage }: any) => {
  const { t } = useTranslation();
  const attachments = comment?.files || comment?.attachment || [];
  return (
    <div className="pb-3 pt-2 border-b border-gray-200 mb-2">
      <div className="flex flex-col">
        <div className="pr-3 flex items-center mb-2">
          <div className="flex-1 pr-2">
            <UserAvatarName user={comment?.by} size="xx-small" />
          </div>
          <div className="text-gray-500">{` ${TimeUtils.convertMiliToDateTime(
            comment.created
          )}`}</div>
        </div>

        <div className="flex flex-1">
          <div className="captionText activityContentContainer flex flex-col md:flex-row w-full">
            <div className="bg-blue-100 px-3 py-2 my-2 arrow-up border-b-blue-100 relative w-full">
              <p className="commentActivityText mb-0 whitespace-pre-line">
                {parse(comment.comment || "")}
              </p>
            </div>
          </div>
        </div>
      </div>
      {(comment?.file?.length > 0 || attachments?.length > 0) && (
        <div className="mt-2 mb-3">
          {comment.file && (
            <img
              className="w-16 h-16 object-contain bg-black cursor-pointer"
              src={comment.file}
              onClick={() => {
                onClickImage && onClickImage();
              }}
              alt=""
            />
          )}
          <div className="flex">
            {attachments &&
              attachments?.map((file: any, index: number) => (
                <img
                  className="w-16 h-16 object-contain bg-black cursor-pointer"
                  src={file}
                  alt=""
                  onClick={() => {
                    onClickImage && onClickImage(index);
                  }}
                />
              ))}
          </div>
        </div>
      )}

      {comment?.label && (
        <span className="bg-primary rounded text-white px-2 py-1 text-xs whitespace-nowrap">
          {t(FILTER_COMMENT_TYPE[comment?.label])}
        </span>
      )}
    </div>
  );
};
const ActivitiesView = ({
  onClickSendComment,
  setRef,
  renderSubTitle,
  source,
  transformer,
  hideType = false,
  isInstantUploadAttach = false,
  hideFieldInput = false,
  isPublic = false,
}: any) => {
  const [imageToView, setImageToView] = useState<any[]>([]);
  const [attachments, setAttachments] = useState<any[]>([]);
  const [commentType, setCommentType] = useState();
  const [commentText, setCommentText] = useState("");
  const [loadingSend, setLoadingSend] = useState(false);
  const activityList = useRef<any>(null);
  const buttonUploadRef = useRef<any>(null);
  const { t } = useTranslation();

  useEffect(() => {
    setRef && setRef(activityList.current);
  }, []);

  const onSelectImage = (files: any) => {
    const fileResults: any = [];
    files &&
      files.forEach((file: any, index: number) => {
        const reader = new FileReader();
        const url = reader.readAsDataURL(file);

        reader.onloadend = function (e) {
          fileResults.push({
            imageData: reader.result,
            fileData: file,
          });

          if (index === files.length - 1) {
            setAttachments(fileResults);
          }
        };
      });
  };
  const onClickRemoveImage = (removeFileItem: any) => {
    const result = attachments.filter(
      (item: any) => item.imageData !== removeFileItem.imageData
    );
    setAttachments(result);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onSelectImage,
  });
  const renderItem = (comment: any) => {
    return (
      <ActivityItem
        comment={comment}
        onClickImage={() => {
          setImageToView(comment.file ? [comment.file] : comment.files);
        }}
      />
    );
  };

  const onClickSend = _.throttle(() => {
    if (loadingSend) {
      return;
    }
    setLoadingSend(true);
    onClickSendComment &&
      onClickSendComment(commentText, attachments, commentType)
        .then(() => {
          setLoadingSend(false);
          setAttachments([]);
          setCommentText("");
          activityList.current.refresh();
          buttonUploadRef.current && buttonUploadRef.current.refresh();
        })
        .catch(() => setLoadingSend(false));
  }, 1000);

  const renderAttachButton = () => {
    if (isInstantUploadAttach) {
      return (
        <ButtonFileUpload
          onChange={setAttachments}
          maxFiles={5}
          containerClassName="d-flex"
          ref={buttonUploadRef}
        />
        // <UploadFile
        //   uploadedFiles={attachments}
        //   getFile={(files: any[]) => setAttachments(files)}
        //   uploadImagesOnly
        // />
      );
    }

    return (
      <div className="flex flex-1 items-end">
        <div className="flex-1">
          {attachments?.map((file: any) => (
            <RenderPreviewFile
              source={file.imageData}
              showName={false}
              handleViewImage={() => {
                setImageToView(
                  attachments?.map((attch: any) => attch.imageData)
                );
              }}
              onClickRemoveHandle={() => onClickRemoveImage(file)}
              extension={StringUtils.getExtensionFromFilename(
                file?.fileData?.path
              )?.toLowerCase()}
              isRemovable
              itemClassName="w-20 h-20 mr-2 mt-2 inline-block"
            />
          ))}
        </div>

        <Button
          className="inline-block relative px-2 mr-2"
          variant="trans"
          style={{ minWidth: 0, width: "40px" }}
          {...getRootProps()}
        >
          <input {...getInputProps()} multiple={false} />
          <ImageIcon
            className="w-[15px] cursor-pointer"
            src="/images/icons/attachment.svg"
          />
        </Button>
      </div>
    );
  };

  const commentTypes = useMemo(() => {
    return COMMENT_TYPES?.map((item) => ({
      ...item,
      content: t(item.content),
    }));
  }, [t]);

  return (
    <div className="bg-white p-3 pt-4 mt-3 shadow-sm">
      <div className="flex mb-3">
        <label className="flex-1 text-sm">{t("activities")}</label>
        {renderSubTitle && renderSubTitle()}
      </div>
      {!isPublic && (
        <div
          className="commentFieldContainer border p-3"
          hidden={hideFieldInput}
        >
          <div className="flex w-full flex-col md:flex-row">
            <div className="flex-1 flex pr-3">
              {/* <Avatar
              alt=""
              src={getAdminAvatar()}
              className="rounded overflow-hidden"
              variant="square"
              size="x-small"
            /> */}
              <TextareaAutosize
                className="flex-1 outline-none min-h-[50px]"
                placeholder={t("leaveAComment")}
                value={commentText}
                onChange={(event) => setCommentText(event.target.value)}
              />
            </div>
          </div>
          <div className="flex mt-3 items-end justify-content-end">
            {renderAttachButton()}
            {!hideType && (
              <Select
                dataSource={commentTypes}
                getKey={(item) => item.id}
                getLabel={(item) => item.content}
                onChange={(value) => {
                  setCommentType(value);
                }}
                value={commentType}
                placeholder={t("selectType")}
                className="w-[150px] ml-[48px] md:ml-0 md:mt-0"
              />
            )}
            <Button
              className="inline-block relative px-2 ml-2"
              style={{ minWidth: 0, width: "40px" }}
              onClick={onClickSend}
            >
              <ImageIcon
                className="w-[15px] cursor-pointer"
                src="/images/icons/send.svg"
              />
            </Button>
          </div>
        </div>
      )}
      {/* @ts-ignore */}
      <ModalGateway>
        {imageToView && imageToView.length > 0 ? (
          //  @ts-ignore
          <Modal onClose={() => setImageToView([])}>
            {/* @ts-ignore  */}
            <Carousel
              views={imageToView?.map((file) => ({
                src: file,
                source: file,
                caption: "",
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
      <AwesomeListComponent
        ref={activityList}
        source={(paging) => source(paging)}
        transformer={transformer}
        renderItem={(item) => renderItem(item)}
        styleContainer={{ overflowY: "auto" }}
        isPaging
        loadMoreText={t("loadMore")}
        pagingProps={{
          pageIndex: 1,
          pageSize: 10,
        }}
        variant="load-more"
      />
    </div>
  );
};

export default ActivitiesView;
