import FilterButtonGroup from "@common/FilterButtonGroup";
import { SelectInfinityFilterMultiple } from "@common/dropdown/SelectFilter";
import UserNameDropdown from "@components/shared/UserNameDropdown";
import {
  MY_REQUEST_EXECUTION_STATUS_LIST,
  TYPE_OF_CHANGE_REQUEST,
} from "@constants/my-assets";
import { listAllUser } from "@network/api/user";
import classNames from "classnames";
import { Button, Icon, InputText } from "d-react-components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const RequestAssetFilters = ({
  filterBody,
  onChangeFilter,
  resetFilter,
  refetch,
  searchValue,
  setSearchValue,
  showRequestBy = true,
}: {
  filterBody: any;
  onChangeFilter: (params: any) => void;
  resetFilter: () => void;
  refetch: () => void;
  searchValue: string;
  setSearchValue: (value: string) => void;
  showRequestBy?: boolean;
}) => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  return (
    <div className="mb-3">
      <div className="border-b mb-2 pb-2">
        <FilterButtonGroup
          valueKey="id"
          labelKey="label"
          label={t("typeOfChangeRequest")}
          options={TYPE_OF_CHANGE_REQUEST}
          value={filterBody?.type_of_request}
          onChange={(value) => {
            onChangeFilter({ type_of_request: value });
          }}
        />
      </div>
      <div
        className={classNames("transition-all", {
          "max-h-0 overflow-hidden": !expanded,
          "max-h-[500px] overflow-auto": expanded,
        })}
      >
        <div className="border-b mb-2 pb-2">
          <FilterButtonGroup
            valueKey="id"
            labelKey="label"
            label={t("executionStatus")}
            options={MY_REQUEST_EXECUTION_STATUS_LIST}
            value={filterBody?.execution_status}
            onChange={(value) => {
              onChangeFilter({ execution_status: value });
            }}
          />
        </div>
        <div className="grid grid-cols-3 gap-2">
          <div>
            <InputText
              placeholder={t("search")}
              suffix={<Icon name="search" />}
              value={searchValue || ""}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  refetch();
                }
              }}
            />
          </div>
          <div>
            <SelectInfinityFilterMultiple
              fetchFn={(body: any, paging: any) => {
                return listAllUser(
                  { search: body.search },
                  {
                    pageIndex: paging.page,
                    pageSize: paging.per_page,
                  }
                );
              }}
              key="selectRequestFor"
              dataPath="users"
              value={filterBody.admin_id}
              customQuery={(search) => ({
                search,
              })}
              getLabel={(item) => item?.name}
              dropdownHeight={300}
              onChange={(val) => {
                onChangeFilter({ admin_id: val });
              }}
              placeholder={t("requestFor")}
              multiple
              mode="multiple"
              valueKey="value"
              tagTitle={t("requestFor")}
              onDropdownVisibleChange={(open) => {
                if (!open) {
                  refetch();
                }
              }}
              getLabelDropdownItem={(item) => <UserNameDropdown item={item} />}
              onClear={() => refetch()}
            />
          </div>
          {showRequestBy && (
            <div>
              <SelectInfinityFilterMultiple
                fetchFn={(body: any, paging: any) => {
                  return listAllUser(
                    { search: body.search },
                    {
                      pageIndex: paging.page,
                      pageSize: paging.per_page,
                    }
                  );
                }}
                key="selectRequestBy"
                dataPath="users"
                value={filterBody.request_by_id}
                customQuery={(search) => ({
                  search,
                })}
                getLabel={(item) => item?.name}
                dropdownHeight={300}
                onChange={(val) => {
                  onChangeFilter({ request_by_id: val });
                }}
                placeholder={t("requestBy")}
                multiple
                mode="multiple"
                valueKey="value"
                tagTitle={t("requestBy")}
                onDropdownVisibleChange={(open) => {
                  if (!open) {
                    refetch();
                  }
                }}
                getLabelDropdownItem={(item) => (
                  <UserNameDropdown item={item} />
                )}
                onClear={() => refetch()}
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center justify-center mt-2">
        <Button
          className="text-primary cursor-pointer flex items-center rounded-full"
          style={{ borderRadius: "999px" }}
          onClick={() => setExpanded(!expanded)}
          size="small"
          variant="outline"
        >
          {expanded ? t("collapse") : t("expand")}
          <Icon name={expanded ? "expand_less" : "expand_more"} />
        </Button>
        <Button
          iconName="cached"
          size="small"
          // variant="trans"
          className="rounded-full ml-2"
          style={{ borderRadius: "999px" }}
          onClick={() => {
            resetFilter();
            setTimeout(() => {
              refetch();
            });
          }}
        >
          <span className="font-normal">{t("resetFilter")}</span>
        </Button>
      </div>
    </div>
  );
};

export default RequestAssetFilters;
