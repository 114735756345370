/* eslint-disable react/jsx-no-duplicate-props */
import { CART_RULE_ACTION } from "@constants/cart-rule";
import AppContext from "@helpers/context";
import { InputText } from "d-react-components";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import SelectSpecificAction from "./SelectSpecificAction";

const DiscountAmountActions = ({
  actions,
  cartRuleActions,
  setChangeData,
  handleSelectAction,
}: any) => {
  const { cartRuleForm, disabledSpecificAction } = useContext(AppContext);
  const { t } = useTranslation();
  const formValues = cartRuleForm.values ?? {};

  const isDiscountFix =
    actions.specific === CART_RULE_ACTION.DISCOUNT_FIX_AMOUNT;

  const getValueAmount = () => {
    if (isDiscountFix) {
      return actions?.discount_fix_amount?.amount ?? 0;
    }
    return actions?.discount_percentage?.percentage ?? 0;
  };
  const getValueRecursive = () => {
    if (isDiscountFix) {
      return actions?.discount_fix_amount?.recursive ?? 0;
    }
    return actions?.discount_percentage?.recursive ?? 0;
  };
  const getValueMax = () => {
    if (isDiscountFix) {
      return actions?.discount_fix_amount?.max ?? 0;
    }
    return actions?.discount_percentage?.max ?? 0;
  };

  const onChangeDiscountValue = (newDiscount: any) => {
    let result;
    if (isDiscountFix) {
      result = {
        ...actions,
        discount_fix_amount: {
          ...actions.discount_fix_amount,
          ...newDiscount,
        },
      };
    } else {
      result = {
        ...actions,
        discount_percentage: {
          ...actions.discount_percentage,
          ...newDiscount,
        },
      };
    }
    setChangeData("actions", result);
  };

  return (
    <div>
      <div className="actionModuleHeader">
        <SelectSpecificAction
          disabled={disabledSpecificAction}
          actions={actions}
          handleSelectAction={handleSelectAction}
          cartRuleActions={cartRuleActions}
          selectStyle={{ width: "100%" }}
        />
        <InputText
          className="mt-2"
          label={isDiscountFix ? t("amount") : t("percentage")}
          onChange={(event) => {
            const value = parseInt(event.target.value);
            // onChangeAmount(value);
            onChangeDiscountValue(
              isDiscountFix ? { amount: value } : { percentage: value }
            );
          }}
          value={`${getValueAmount()}`}
          suffix={isDiscountFix ? "฿" : "%"}
          type="number"
        />
      </div>
      {/* <div className="recursiveActionsContainer">
        {renderRecursiveSelect()}
        {renderMaxQuantityAllow()}
      </div> */}
    </div>
  );
};

export default DiscountAmountActions;
