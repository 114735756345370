import { ObjectUtils } from 'd-react-components';
import { isEmpty } from 'lodash';
import React, { useMemo } from "react";
import CurrencyInput from "react-currency-input-field";
import { useTranslation } from 'react-i18next';

interface IInputTextForm {
  form: any;
  keyData: string;
  label?: any;
  className?: any;
  prefix?: any;
  required?: boolean;
  [key: string]: any;
}
const InputCurrency = ({
  form,
  keyData,
  label,
  required,
  className,
  prefix,
  ...props
}: IInputTextForm) => {
  const { t } = useTranslation();
  // eslint-disable-next-line no-unneeded-ternary
  const labelValue = label ? label : t(keyData);
  const isPrefix = useMemo(() => !isEmpty(prefix), []);

  const formDataValue = isPrefix
    ? ObjectUtils.getValueFromStringKey(form.values, prefix)
    : form.values;

  const formDataError = isPrefix
    ? ObjectUtils.getValueFromStringKey(form.errors, prefix)
    : form.errors;

  const getName = (key: string) => {
    return isPrefix ? `${prefix}.${key}` : key;
  };

  return (
    <div className={`${className} d-input-text__container`}>
      <label htmlFor={keyData} className="text-label">
        <span>{labelValue}</span>
      </label>
      <div className="d-input-text__input-container d-input-text__input-container-outline">
        <CurrencyInput
          value={formDataValue?.[keyData] ?? ""}
          onValueChange={(value) => form.setFieldValue(keyData, value)}
          className="text-x-small d-input-text__input w-full"
          placeholder={labelValue}
          name={getName(keyData)}
        />
      </div>
      {formDataError?.[keyData] && (
        <div className="flex-center-y mt-1 d-input-text__error-view undefined">
          <i className="material-icons d-icon__small text-error">
            error_outline
          </i>
          <text className="text-x-small text-error ml-1">
            {formDataError?.[keyData]}
          </text>
        </div>
      )}
    </div>
  );
};

export default InputCurrency;