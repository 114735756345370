import { Switch } from "antd";
import { Button, InputTextSearch, Progress } from "d-react-components";
import React, { useContext } from "react";
import QRCode from "react-qr-code";
import AppContext from "@helpers/context";
import UserAPI from "@network/api/user";
import { useTranslation } from "react-i18next";
import UserAuthQrCode from "@common/UserAuthQrCode";

const UserCreateAuthentication = () => {
  const { userForm } = useContext(AppContext);
  const { t } = useTranslation();

  const onReGenerateCode = () => {
    Progress.show({ method: UserAPI.getSecretCode, params: [{}] }, (res: any) => {
      const { code } = res?.data?.data ?? {};
      userForm.setFieldValue("secretCode", code);
    });
  };

  const { authStatus } = userForm?.values;

  return (
    <div className="p-4 card-container flex-column">
      <label>{t("authentication")}</label>
      <div className="mt-3">
        <Switch
          checked={authStatus}
          onChange={() => userForm.setFieldValue("authStatus", !authStatus)}
        />
      </div>
      <div className="mt-3 text">{t("useGoogleAuthenticationToScan")}</div>
      <div className="mt-3 flex-column" hidden={!authStatus}>
        <div className="d-flex justify-content-between align-items-center">
          <label>{t("secretCode")}</label>
          <Button onClick={onReGenerateCode} variant="trans">
            {t("generate")}
          </Button>
        </div>
        <InputTextSearch value={userForm.values.secretCode} disabled />
      </div>
      <div className="mt-3 flex-column" hidden={!authStatus}>
        <label>{t("qrCode")}</label>
        <UserAuthQrCode
          secretCode={userForm.values.secretCode}
          name={userForm.values.nickName}
        />
      </div>
    </div>
  );
};

export default UserCreateAuthentication;
