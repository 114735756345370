import { Icon, InputText } from "d-react-components";
import parse from "html-react-parser";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const CustomProductRemark = ({
  meta,
  attributeList = [],
  remark = "",
  onChangeRemark,
  disabled = false,
  hideRemark = false,
  product = {},
  showArrow = false,
}: any) => {
  const { t } = useTranslation();
  const [showEdit, setShowEdit] = useState(false);
  const [remarkContent, setRemarkContent] = useState(remark);
  const classNameItem =
    "text-primary border-primary bg-white p-1 text-tiny mr-1 inline-block mb-1";

  const renderAttributeItemView = (key: string, value: string) => {
    return (
      <div style={{ borderWidth: "1px" }} className={classNameItem}>
        <span className="subTitle2">{`${key}: `}</span>
        <span className="subTitle2">{value}</span>
      </div>
    );
  };

  const renderRemark = () => {
    if (hideRemark) {
      return <div />;
    }

    return (
      <>
        {showArrow && (
          <div className="block-remark-arrow ml-2 h-0 w-0 border-x-8 border-x-transparent border-b-[10px] border-b-blue-400"></div>
        )}
        <div className="block-remark bg-blue-400 text-white mt-2 p-3">
          {(!showEdit || disabled) && (
            <div onDoubleClick={() => !disabled && setShowEdit(true)}>
              <div className="flex">
                <div className="text-label text-white flex-1">
                  {t("remark")}
                </div>
                {!showEdit && !disabled && (
                  <Icon
                    name="edit"
                    size="medium"
                    className="text-white text-tiny cursor-pointer"
                    onClick={() => !disabled && setShowEdit(true)}
                  />
                )}
              </div>
              {remark && (
                <div className="text-tiny whitespace-pre-line mt-2">
                  {parse(remark || "")}
                </div>
              )}
            </div>
          )}
          {showEdit && !disabled && (
            <div className="relative">
              <InputText
                multiple
                label={t("remark")}
                value={remarkContent}
                onChange={(event) => setRemarkContent(event.target.value)}
                classNameLabel="text-white"
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    onChangeRemark(remarkContent);
                    setShowEdit(false);
                  }
                }}
              />
              {showEdit && (
                <Icon
                  name="save"
                  size="medium"
                  className="text-white text-tiny cursor-pointer absolute right-0 top-0"
                  onClick={() => {
                    onChangeRemark(remarkContent);
                    setShowEdit(false);
                  }}
                />
              )}
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <div className="customProductExpandRowContain">
      <div className="attributeListContainer">
        {meta &&
          !Array.isArray(meta) &&
          meta?.width !== undefined &&
          !isEmpty(`${meta?.width}`) &&
          !isEmpty(`${meta?.length}`) &&
          renderAttributeItemView(
            t("sizeWL"),
            `${meta?.width}x${meta?.length}`
          )}
        {product.isCustomProduct && (
          <>
            <div style={{ borderWidth: "1px" }} className={classNameItem}>{`${t(
              "category"
            )}: ${product?.category?.name}`}</div>
            <div style={{ borderWidth: "1px" }} className={classNameItem}>{`${t(
              "brand"
            )}: ${product?.brand?.name}`}</div>
          </>
        )}
        {attributeList?.map((item: any) =>
          renderAttributeItemView(item.attributeName, item.valueName)
        )}
      </div>
      {renderRemark()}
    </div>
  );
};

export default CustomProductRemark;
