import ProductName from "@components/product/components/ProductName";
import AppContext from "@helpers/context";
import { AwesomeTableComponent } from "d-react-components";
import { useContext, useRef } from "react";
import { useTranslation } from "react-i18next";

const StockAdjustmentDetailProducts = () => {
  const { adjustmentDetail } = useContext(AppContext);
  const { t } = useTranslation();

  const tableRef = useRef<any>();
  const columns = [
    {
      title: t("name"),
      dataIndex: "product",
      render: (product: any) => <ProductName item={{ product }} />,
    },
    {
      title: t("adjustment"),
      dataIndex: "adjustment",
      width: 100,
    },
  ];

  return (
    <div className="card-container p-4 ">
      <div className="d-flex justify-content-between align-items-center">
        <label>{t("itemList")}</label>
        {/* <Button
          onClick={() => setOpenEdit(true)}
          variant="trans"
          hidden={requestDetail?.status !== STOCK_REQUEST_STATUS.PENDING}
        >
          {t("edit")}
        </Button> */}
      </div>
      <AwesomeTableComponent
        ref={(ref) => {
          tableRef.current = ref;
        }}
        columns={columns}
        dataSource={adjustmentDetail?.products ?? []}
        className="mt-3 height-fit-content"
        pagination={false}
      />
    </div>
  );
};

export default StockAdjustmentDetailProducts;
