import styled from "@emotion/styled";
import { InputText, InputTextProps } from "d-react-components";
import React from "react";

const SelectWithInput = (props: InputTextProps) => {
  return <StyledFilterInput {...props} />;
};

const StyledFilterInput = styled(InputText)`
  .d-input-text__suffix-container,
  .d-input-text__prefix-container {
    background-color: transparent !important;
  }
  .d-input-text__prefix-container {
    white-space: nowrap;
    align-items: center;
    display: flex;
    padding: 0;
    .d-select__select-outline {
      border: none !important;
    }
  }
`;

export default SelectWithInput;
