import ButtonFileUpload from "@components/shared/ButtonFileUpload";
import DocumentDropZone from "@components/shared/DocumentDropZone";
import { FILE_FORMAT_PROMOTION, IMAGE_FORMAT } from "@constants/file";
import { PROMOTION_STATUS } from "@constants/promotion";
import AppContext from "@helpers/context";
import { getImageDataPreview } from "@helpers/file";
import { uploadPromotionDocument } from "@network/api/promotion";
import { Notifications } from "d-react-components";
import { isUndefined } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const PromotionDetailAttachments = () => {
  const { t } = useTranslation();
  const buttonUploadRef = useRef<any>(null);
  const { promotionDetail, status } = React.useContext(AppContext);
  const [attachments, setAttachments] = useState<any>(undefined);
  const editable = useMemo(() => status !== PROMOTION_STATUS.ENDED, [status]);

  useEffect(() => {
    if (promotionDetail?.documents) {
      setAttachments(
        promotionDetail?.documents?.map((a: any) => ({
          ...a,
          imageData: getImageDataPreview(a),
        })) ?? []
      );
    }
  }, [promotionDetail]);

  const onChangeAttachments = (files: any) => {
    // get the new uploaded files
    const newFiles = files.filter(
      (f: any) => !attachments.some((a: any) => a.id === f.id)
    );
    if (newFiles.length) {
      uploadPromotionDocument({
        promotionid: promotionDetail.id,
        documents: newFiles?.map((f: any) => f.key),
      }).then((res) => {
        setAttachments(files);
      });
    }
  };

  return (
    <div className="mb-3 bg-white px-3 py-2">
      <span className="font-semibold mb-3 inline-block">
        {t("attachments")}
      </span>
      {!isUndefined(attachments) && (
        <ButtonFileUpload
          onChange={onChangeAttachments}
          inputParam={{ accept: `${IMAGE_FORMAT},${FILE_FORMAT_PROMOTION}` }}
          containerClassName="d-flex flex-column"
          ref={buttonUploadRef}
          classNameItem="!w-24 !h-24 bg-black"
          classNameImage="!object-contain"
          defaultFiles={attachments}
          isView={(file: any) => {
            return file.status === "success";
          }}
          renderButton={(
            getRootProps: any,
            getInputProps: any,
            inputParam: any
          ) =>
            editable ? (
              <DocumentDropZone
                getRootProps={getRootProps}
                getInputProps={getInputProps}
                inputParam={inputParam}
              />
            ) : null
          }
        />
      )}
    </div>
  );
};

export default PromotionDetailAttachments;
