import { useFormik } from "formik";
import { filter } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import ProductLabelImportCSVFile from "./ProductLabelImportCSVFile";
import ProductLabelImportFooter from "./ProductLabelImportFooter";
import ProductLabelImportResult from "./ProductLabelImportResult";
import ProductLabelImportTemplate from "./ProductLabelImportTemplate";
import ProductLabelImportValidation from "./ProductLabelImportValidation";
import { IMPORT_PRODUCT_LABEL_STEPS } from "@constants/product";
import ProductLabelAPI from "@network/api/product-label-print";
import AppContext from "@helpers/context";
import { useTranslation } from "react-i18next";

export const INIT_IMPORT_FORM = {
  importFields: [],
  template: "default",
  fileImport: null,
};

const ProductLabelImport = () => {
  const [stepIndex, setStepIndex] = useState(0);
  const [importFieldSource, setImportFieldSource] = useState([]);
  const { t } = useTranslation();

  const step = useMemo(
    () => IMPORT_PRODUCT_LABEL_STEPS[stepIndex],
    [stepIndex]
  );

  const formImport = useFormik({
    initialValues: INIT_IMPORT_FORM,
    validateOnBlur: false,
    validateOnChange: false,
  } as any);

  useEffect(() => {
    ProductLabelAPI.getImportFields({
      label: formImport?.values?.template,
    }).then((res: any) => {
      const fieldList = res?.data?.data?.fields ?? [];
      const requiredFields = filter(fieldList, (item) => item.required);
      formImport.setFieldValue("importFields", requiredFields);
      setImportFieldSource(fieldList);
    });
  }, [formImport?.values?.template]);

  const classNameHeader =
    "d-flex justify-content-between align-items-center p-4 border-bottom";

  const renderContent = () => {
    switch (stepIndex) {
      case 0:
        return <ProductLabelImportTemplate />;
      case 1:
        return <ProductLabelImportCSVFile />;
      case 2:
        return <ProductLabelImportValidation />;
      case 3:
        return <ProductLabelImportResult />;
      default:
        return <div />;
    }
  };

  return (
    <AppContext.Provider
      value={{ step, formImport, stepIndex, importFieldSource, setStepIndex }}
    >
      <div className="bg-white">
        <div className={classNameHeader}>
          <h5>{t(step?.title)}</h5>
          <div className="bg-dark px-3 py-1">
            <small className="text-white">{`Step ${stepIndex + 1} of ${
              IMPORT_PRODUCT_LABEL_STEPS.length
            }`}</small>
          </div>
        </div>
        {renderContent()}
        <ProductLabelImportFooter />
      </div>
    </AppContext.Provider>
  );
};

export default ProductLabelImport;
