import { OrderType } from "@constants/common";
import { API } from "@network";
import {
  API_DISTRICT_LIST,
  API_CREATE_CUSTOMER_SHIPPING,
  API_DELETE_SHIPPING_FROM_ORDER,
  API_PROVINCE_LIST,
  API_SEARCH_BILLING_CUSTOMER,
  API_SHIPPING_PROFILE,
  API_SUBDISTRICT_LIST,
  API_UPDATE_BILLING,
  API_UPDATE_ORDER_SHIPPING,
  API_SEARCH_ADDRESS,
  API_POSTCODE_LIST,
  API_CREATE_CONTRACT_SHIPPING,
  API_UPDATE_CONTRACT_SHIPPING,
  API_DELETE_CONTRACT_SHIPPING,
  API_UPDATE_CONTRACT_BILLING,
} from "@network/URL";
import axios from "axios";

export const getProvinceList = (params = {}) => {
  return API.get(API_PROVINCE_LIST, params);
};

export const getDistrictList = (provinceid: string) => {
  return API.post(API_DISTRICT_LIST, { provinceid });
};

export const getSubDistrictList = (cityId: string) => {
  return API.post(API_SUBDISTRICT_LIST, { city_id: cityId });
};

export const getPostcodeList = (id: string) => {
  return API.post(API_POSTCODE_LIST, { subdistrict_id: id });
};

export const createCustomerShipping = (body: any) => {
  return API.post(API_CREATE_CUSTOMER_SHIPPING, body);
};

export const searchAddress = (payload: any) => {
  return API.post(API_SEARCH_ADDRESS, payload);
};

export const searchShippingCustomer = (
  customerid: string,
  paging = { pageIndex: 1 }
) => {
  return API.post(`${API_SHIPPING_PROFILE}?page=${paging.pageIndex}`, {
    customerid,
  });
};

export const searchBillingCustomer = (
  customerid: string,
  paging = { pageIndex: 1 }
) => {
  return API.post(`${API_SEARCH_BILLING_CUSTOMER}?page=${paging.pageIndex}`, {
    customerid,
  });
};

export const getLocationFromAddress = (address: string) => {
  return axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.REACT_APP_API_GOOGLE_KEY}`
  );
};

export const createContractShipping = (body: any) => {
  return API.post(API_CREATE_CONTRACT_SHIPPING, body);
};

export const updateContractShipping = (body: any) => {
  return API.post(API_UPDATE_CONTRACT_SHIPPING, body);
};

export const updateContractBilling = (body: any) => {
  return API.post(API_UPDATE_CONTRACT_BILLING, body);
};

export const deleteShippingFromContract = (shippingid: any) => {
  return API.post(API_DELETE_CONTRACT_SHIPPING, { id: shippingid });
};

export const updateBillingAddress = (body: any, type = OrderType.NORMAL) => {
  if (type === OrderType.NORMAL) return API.post(API_UPDATE_BILLING, body);
  else return updateContractBilling(body);
};

export const updateOrderShipping = (body: any, type = OrderType.NORMAL) => {
  if (type === OrderType.NORMAL)
    return API.post(API_UPDATE_ORDER_SHIPPING, body);
  else {
    if (body.id) {
      return updateContractShipping(body);
    } else {
      return createContractShipping(body);
    }
  }
};

export const deleteShippingFromOrder = (
  shippingid: any,
  type = OrderType.NORMAL
) => {
  if (type === OrderType.NORMAL)
    return API.post(API_DELETE_SHIPPING_FROM_ORDER, { shippingid });
  else {
    return deleteShippingFromContract(shippingid);
  }
};
