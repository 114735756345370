import Drawer from "@components/shared/Drawer";
import AppContext from "@helpers/context";
import { validateSkuPromotion } from "@network/api/promotion";
import { Progress } from "antd";
import { Button, Icon, Notifications, StringUtils } from "d-react-components";
import { every, filter, isEmpty } from "lodash";
import { useContext, useState } from "react";
import { CSVLink } from "react-csv";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

const PromotionImport = ({ openModalImport, onClose, onSaveProduct }: any) => {
  const { promotionForm } = useContext(AppContext);
  const { start, end, channel } = promotionForm?.values;
  const { t } = useTranslation();
  const [validateLoading, setValidateLoading] = useState(false);
  const [percentValidation, setPercentValidation] = useState(0);
  const [importSuccess, setImportSuccess] = useState(false);
  const [skuList, setSkuList] = useState<any[]>([]);

  const validateSkuList = (skuListConverted: any) => {
    return every(skuListConverted, (sku) => {
      return (
        !isEmpty(sku.skuid) &&
        !isEmpty(sku.newSalePrice) &&
        StringUtils.isAllDigit(sku.newSalePrice)
      );
    });
  };

  const onDrop = (files: any[]) => {
    const reader = new FileReader();
    reader.readAsText(files[0]);
    reader.onload = function (e) {
      const skuList = StringUtils.convertCsvJSON(reader.result);
      if (!validateSkuList(skuList)) {
        setValidateLoading(false);
        Notifications.showError(t("fileImportIsNotRightFormat"));
        return;
      }

      setValidateLoading(true);
      const config = {
        onUploadProgress: (progressEvent: any) => {
          const percentValidation = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setPercentValidation(percentValidation);
          // do whatever you like with the percentage complete
          // maybe dispatch an action that will update a progress bar or something
        },
      };

      const body = {
        start: start.format("x"),
        end: end.format("x"),
        channelids: channel.map((channel: any) => channel.id),
        sku: skuList.map((sku) => sku.skuid),
      };
      validateSkuPromotion(body, config)
        .then((res) => {
          const validation = res?.data?.data?.validate;

          const skuListResult = [];
          for (const key in validation) {
            const sku = validation[key];
            const skuFromImport = skuList.find(
              (skuParam) => skuParam.skuid === sku?.product?.sku
            );
            if (!skuFromImport) {
              skuListResult.push({
                ...sku,
                product: { sku: key },
              });
            } else {
              skuListResult.push({
                ...sku,
                ...(sku.price ?? {}),
                new_sale_price: parseInt(skuFromImport?.newSalePrice ?? 0),
                productid: sku?.product?.id,
                default_sale_price: sku?.price?.sale_price,
              });
            }
          }
          setSkuList(skuListResult);
          setValidateLoading(false);
          setImportSuccess(true);
        })
        .catch((error) => {
          setValidateLoading(false);
          Notifications.showError(error);
        });
    };
  };

  const getSkuUnValidNumber = () => {
    return getSkuUnValids().length;
  };

  const getSkuValidNumber = () => {
    return skuList.length - getSkuUnValidNumber();
  };

  const getPercentage = () => {
    return ((1 - getSkuUnValidNumber() / skuList.length) * 100).toFixed(0);
  };

  const getSkuUnValids = () => {
    return filter(
      skuList.map((sku) => {
        if (sku.new_sale_price > sku.regular_price) {
          return {
            ...sku,
            message: t("salePriceCannotGreater"),
            status: false,
          };
        }
        return sku;
      }),
      (sku) => {
        if (sku.new_sale_price > sku.regular_price) {
          return true;
        }
        return !sku.status;
      }
    );
  };
  const getErrorItemsData = () => {
    const skuUnValid = getSkuUnValids();
    const header = [
      t("skuid"),
      t("newSalePrice"),
      t("reason"),
      t("promotionTitle"),
    ];
    const dataResult = [header];
    skuUnValid.forEach((sku) => {
      dataResult.push([
        sku?.product?.sku,
        sku.new_sale_price,
        sku.message,
        sku.promotion?.name,
      ]);
    });
    return dataResult;
  };

  const onClickSaveProduct = async () => {
    onSaveProduct && onSaveProduct(skuList);
    onClose();
    setImportSuccess(false);
    return Promise.resolve(true);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const contentImport = () => {
    return (
      <div className="importModalContainer">
        <div className="subTitle1">{t("uploadItemsIntoPro")}</div>
        <div
          className="subTitle1"
          style={{ flexDirection: "row", display: "flex" }}
        >
          <div style={{ fontWeight: "bold", color: "black" }}>
            {t("noteName")} :
          </div>
          {t("requireFieldsImport")}
        </div>
        <div style={{ marginTop: "2rem" }}>
          {validateLoading ? (
            <Progress percent={percentValidation} />
          ) : (
            <Button {...getRootProps()}>
              <div className="flex items-center">
                <input
                  {...getInputProps()}
                  type="file"
                  accept=".csv, text/csv"
                  multiple={false}
                />
                <Icon name="attachment" />
                <text className="subTitle2" style={{ color: "white" }}>
                  {t("attachCSV")}
                </text>
              </div>
            </Button>
          )}
        </div>
        <div
          className="subTitle1"
          style={{ marginTop: "2rem", marginBottom: "2rem" }}
        >
          {t("notSureHowToStart")}
        </div>
        <div className="subTitle1">- {t("downloadAsvFile")}</div>
        <div
          className="subTitle1"
          style={{ flexDirection: "row", display: "flex" }}
        >
          - {t("download")}
          <span
            className="text-primary cursor-pointer ml-2"
            onClick={() =>
              window.open(
                `https://onlinesale.lotusbeddinggroup.com/storage/exemple/csv-product-promotion.csv?v=${new Date().getTime()}`
              )
            }
          >
            {t("blankCSVTemplate")}
          </span>
        </div>
      </div>
    );
  };

  const contentImportSuccess = () => {
    return (
      <div className="importModalContainer">
        <Progress percent={100} />
        <div className="flex items-center my-3">
          <Icon name="check_circle" className="text-green-500 mr-2" />
          <div className="flex items-center">
            {getSkuValidNumber()} {t("itemValidToImport")}
          </div>
        </div>
        <div className="flex items-center my-3">
          <Icon name="error" className="text-red-500 mr-2" />
          <div className=" flex items-center">
            {getSkuUnValidNumber()} {t("itemHaveErrorImport")}
          </div>
        </div>
        <CSVLink data={getErrorItemsData()}>
          <div className="text-primary">{t("downloadLog")}</div>
        </CSVLink>
      </div>
    );
  };

  return (
    <Drawer
      title={t("bulkImportItems")}
      open={openModalImport}
      onClose={() => {
        setImportSuccess(false);
        onClose();
      }}
      saveText={t("import")}
      onSave={onClickSaveProduct}
      disableSave={!importSuccess || getSkuUnValidNumber() > 0}
      size="auto"
      width={500}
    >
      {importSuccess ? contentImportSuccess() : contentImport()}
    </Drawer>
  );
};

export default PromotionImport;
