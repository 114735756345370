import Icon from "@common/Icon";
import Drawer, { DrawerProps } from "@components/shared/Drawer";
import { PROMOTION_STATUS } from "@constants/promotion";
import AppContext from "@helpers/context";
import { updatePromotion } from "@network/api/promotion";
import { InputText, Notifications, Progress } from "d-react-components";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const PromotionDetailMemo = () => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const { promotionDetail, status } = React.useContext(AppContext);
  const editable = useMemo(() => status !== PROMOTION_STATUS.ENDED, [status]);
  return (
    <>
      <div className="bg-amber-100 px-3 py-2 mb-3">
        <div className="flex items-center mb-2">
          <span className="font-semibold inline-block flex-1">{t("memo")}</span>
          <div>
            {editable && (
              <Icon
                onClick={() => setOpenModal(true)}
                className="w-[15px] cursor-pointer"
                src="/images/icons/edit.svg"
              />
            )}
          </div>
        </div>
        <div className="text-xs mb-2">{promotionDetail?.description ?? ""}</div>
      </div>
      {openModal && <MemoDrawer open={openModal} onClose={setOpenModal} />}
    </>
  );
};

const MemoDrawer = ({ open, onClose }: DrawerProps) => {
  const { t } = useTranslation();
  const { promotionDetail, reloadPromotion } = React.useContext(AppContext);
  const [content, setContent] = useState(promotionDetail?.description);

  const onSave = () => {
    Progress.show(
      {
        method: updatePromotion,
        params: {
          description: content,
          promotionid: promotionDetail?.id,
        },
      },
      () => {
        reloadPromotion && reloadPromotion();
        Notifications.showSuccess(t("notification:updatePromotionSuccess"));
        onClose();
      },
      (err: string) => {
        Notifications.showError(err);
      }
    );
  };

  return (
    <Drawer
      title={t("memo")}
      open={open}
      onClose={() => onClose(false)}
      onSave={onSave}
      size="auto"
      width="450"
    >
      <InputText
        label={t("memo")}
        value={content}
        onChange={(e) => setContent(e.target.value)}
        multiple
        rows={5}
      />
    </Drawer>
  );
};

export default PromotionDetailMemo;
