import AppContext from "@helpers/context";
import { stockRequestDetail } from "@network/api/stock-request";
import { Progress, TabBar } from "d-react-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import StockRequestDetailActivity from "./StockRequestDetailActivity";
import StockRequestDetailGeneral from "./StockRequestDetailGeneral";
import StockRequestDetailProducts from "./StockRequestDetailProducts";
import StockRequestDetailHeader from "./StockRequestDetailHeader";
import StockRequestDetailInfo from "./StockRequestDetailInfo";
import StockRequestDetailTransfer from "./StockRequestDetailTransfer";

const TABS = [
  {
    id: "general",
    label: "general",
    component: <StockRequestDetailGeneral />,
  },

  {
    id: "itemList",
    label: "itemList",
    component: <StockRequestDetailProducts />,
  },
  {
    id: "transferStockRequest",
    label: "transferStockRequest",
    component: <StockRequestDetailTransfer />,
  },
  {
    id: "activity",
    label: "activity",
    component: <StockRequestDetailActivity />,
  },
];

const StockRequestDetail = () => {
  const [requestDetail, setRequestDetail] = useState(null);

  const [tab, setTab] = useState<any>(TABS[0]);
  const { requestId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    loadRequestDetail();
  }, []);

  const loadRequestDetail = () => {
    Progress.show(
      { method: stockRequestDetail, params: [requestId] },
      (res: any) => {
        const request = res?.data?.data?.request;
        setRequestDetail(request);
      }
    );
  };

  return (
    <AppContext.Provider
      value={{
        requestDetail,
        loadRequestDetail,
      }}
    >
      <div className="flex-column-container bg-trans">
        <StockRequestDetailHeader />
        <div className="grid p-3 grid-cols-12 gap-4">
          <div className="col-span-4">{<StockRequestDetailInfo />}</div>
          <div className="col-span-8 px-3">
            <TabBar
              dataSource={TABS}
              onChange={setTab}
              value={tab}
              getLabel={(item: any) => t(item.label)}
            />
            <div className="mt-3">{tab.component}</div>
          </div>
        </div>
      </div>
    </AppContext.Provider>
  );
};

export default StockRequestDetail;
