import { AwesomeTableComponent, InputTextSearch, Modal, TimeUtils } from "d-react-components";
import { debounce } from "lodash";
import React, { useRef, useState } from "react";
import Drawer from "@components/shared/Drawer";
import { useTranslation } from "react-i18next";
import UserAPI from "@network/api/user";

function UserDetailOrderManaUpdateModal({ open, onClose, title, type, userId, onSave }: any) {
  const tableRef = useRef<any>();
  const textSearch = useRef<any>();
  const { t } = useTranslation();
  const [userSelecting, setUserSelecting] = useState<any>([]);

  const columns = [
    {
      title: t("NO"),
      dataIndex: "id",
      render: (id: any, item: any, index: any) => index + 1,
    },
    {
      title: t("name"),
      dataIndex: "name",
      render: (name: any, item: any) => (
        <div className="d-flex">
          <img src={item?.avatar} className="image-circle-tiny w-[32px] h-[32px] object-cover" />
          <a
            href={item?.editurl}
            rel="noreferrer"
            target="_blank"
            className="ml-3"
          >
            {name}
          </a>
        </div>
      ),
    },
    {
      title: t("employeeId"),
      dataIndex: "employee_id",
    },
    {
      title: t("lastLogin"),
      dataIndex: "last_login",
      render: TimeUtils.convertMiliToDateTime,
    },
  ];

  const onChangeText = debounce((text) => {
    textSearch.current = text;
    tableRef.current.refresh();
  }, 500);

  const source = (paging: any) => {
    const body = { id: userId, action: type, search: textSearch.current };
    return UserAPI.orderManagerList(body, paging);
  };

  const transformer = (res: any) => {
    return res?.data?.data?.admin ?? [];
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={title}
      onSave={() => onSave(userSelecting)}
      size="auto"
      width={"800px"}
    >
      <div>
        <InputTextSearch
          onChange={(event: any) => onChangeText(event.target.value)}
        />
        <AwesomeTableComponent
          ref={(ref) => {
            tableRef.current = ref;
          }}
          columns={columns}
          source={source}
          transformer={transformer}
          className="mt-3"
          rowSelection={{
            onChange: (selectedRowKeys, selectedRows) => {
              setUserSelecting(selectedRows);
            },
            preserveSelectedRowKeys: true,
          }}
        />
      </div>
    </Drawer>
  );
}

export default UserDetailOrderManaUpdateModal;
