import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { savePDF } from "@progress/kendo-react-pdf";
import { StringUtils } from "d-react-components";
import { Attachment } from "@interfaces/my-assets";
import { FILE_TYPE, IMAGE } from "@constants/file";
import { find } from "lodash";

export const exportToCSV = (csvData: any, fileName: string) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};
/**
 *
 * @param []{sheetName: string, data: json} sheetDataList
 * @param {string} fileName
 */
export const exportToCSVMultipleSheet = (
  sheetDataList: any,
  fileName: string
) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const wb = XLSX.utils.book_new();
  sheetDataList.forEach((sheetData: any) => {
    const ws = XLSX.utils.json_to_sheet(sheetData.data);
    XLSX.utils.book_append_sheet(wb, ws, sheetData.sheetName);
  });

  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export const savePdf = (html: HTMLElement, fileName = "pdfNameFile.pdf") => {
  savePDF(html, {
    paperSize: "Letter",
    fileName,
    margin: 3,
  });
};

export const getFileNameFromUrl = (url: string) => {
  const splitted = url?.split("/") ?? [];
  return splitted[splitted?.length - 1];
};

export const getImageDataPreview = (attachment: Attachment) => {
  const fileName = getFileNameFromUrl(
    attachment?.fileData
      ? attachment?.fileData?.name
      : attachment.attachment ||
          attachment.imageData ||
          attachment.name ||
          attachment?.document_url ||
          ""
  );
  const extension = StringUtils.getExtensionFromFilename(fileName);
  const isImage = IMAGE.extension.includes(extension);
  if (isImage)
    return attachment?.fileData
      ? attachment?.imageData
      : attachment?.attachment ||
          attachment?.imageData ||
          attachment?.url ||
          attachment?.document_url ||
          "";
  const fileFormat = find(FILE_TYPE, (fileTypeItem) =>
    fileTypeItem.extension.includes(extension)
  );
  if (fileFormat) {
    return fileFormat.iconFile;
  }
  return "";
};
