import CallHistory from "@components/callcenter/CallHistory";
// import CallHistory from "@components/callcenter/CallHistory";
import CartRuleTable from "@components/cart-rule/table/CartRuleTable";
import ChannelDetail from "@components/channel/detail/ChannelDetail";
import ChannelTable from "@components/channel/table/ChannelTable";
import ConnectLine from "@components/connect-line/ConnectLine";
import ConnectLineMobile from "@components/connect-line/ConnectLineMobile";
import CostingExpenseTable from "@components/costing-expense/table/CostingExpense";
import CustomerCreate from "@components/customer/create/CustomerCreate";
import CustomerDetail from "@components/customer/detail/CustomerDetail";
import CustomerInfoMappingCreate from "@components/customer/info-mapping/CustomerInfoMappingCreate";
import CustomerInfoMappingTable from "@components/customer/info-mapping/CustomerInfoMappingTable";
import CustomerTable from "@components/customer/table/CustomerTable";
import Dashboard from "@components/dashboard/Dashboard";
import DeliverySODetail from "@components/delivery-so/detail/DeliverySODetail";
import DeliverySOTable from "@components/delivery-so/table/DeliverySOTable";
import DeliveryDetail from "@components/delivery-tstock/detail/DeliveryStockDetail";
import DeliveryTransferStockTable from "@components/delivery-tstock/table/DeliveryStockTable";
import DiscountCodeUsageReport from "@components/discount-code/table/DiscountCodeUsageReport";
import DiscountCodeUsageTable from "@components/discount-code/table/DiscountCodeUsageTable";
import MyAuthorizedDiscountOTP from "@components/discount-otp/my-authorized-discount-otp/MyAuthorizedDiscountOTP";
import MyIssuedDiscountOTP from "@components/discount-otp/my-issued-discount-otp/MyIssuedDiscountOTP";
import DiscountStaffManagementTable from "@components/discount-staff/table/DiscountStaffManagementTable";
import GPEventCreate from "@components/gpevent/create/GPEventCreate";
import GPEventDetail from "@components/gpevent/detail/GPEventDetail";
import GPEventTable from "@components/gpevent/table/GPEventTable";
import InventoryControlEventCreate from "@components/invenotry-control-event/create/InventoryControlEventCreate";
import InventoryControlEventDetail from "@components/invenotry-control-event/detail/InventoryControlEventDetail";
import InventoryControlEventTable from "@components/invenotry-control-event/table/InventoryControlEventTable";
import InventoryTable from "@components/inventory/table/InventoryTable";
import KPI from "@components/kpi/KPI";
import Login from "@components/login/Login";
import MyAssets from "@components/my-assets/MyAssets";
import MyAssetsChannel from "@components/my-assets/assets/MyAssetsChannel";
import MyAssetsRole from "@components/my-assets/assets/MyAssetsRole";
import MyAssetsWarehouse from "@components/my-assets/assets/MyAssetsWarehouse";
import RequestApproval from "@components/my-assets/request-approval/RequestApproval";
import RequestApprovalDetail from "@components/my-assets/request-approval/RequestApprovalDetail";
import MyRequestDetail from "@components/my-assets/requests/MyRequestDetail";
import MyRequests from "@components/my-assets/requests/MyRequests";
import NewRequest from "@components/my-assets/requests/NewRequest";
import Order from "@components/order/OrderTable";
import OrderCreate from "@components/order/create/OrderDetail";
import OrderDetail from "@components/order/detail/OrderDetail";
import ProductionPrintingTable from "@components/product-print/new/ProductionPrintingTable";
import ProductCreate from "@components/product/create/ProductCreate";
import ProductDetail from "@components/product/detail/ProductDetail";
import ProductTable from "@components/product/table/ProductTable";
import PromotionCreate from "@components/promotion/create/PromotionCreate";
import PromotionDetail from "@components/promotion/detail/PromotionDetail";
import PromotionTable from "@components/promotion/table/PromotionTable";
import SerialNumberDetail from "@components/serial-number/detail/SerialNumberDetail";
import SerialNumberTable from "@components/serial-number/table/SerialNumberTable";
import StockAdjustmentCreate from "@components/stock-adjustment/create/StockAdjustmentCreate";
import StockAdjustmentDetail from "@components/stock-adjustment/detail/StockAdjustmentDetail";
import StockAdjustmentTable from "@components/stock-adjustment/table/StockAdjustmentTable";
import StockRequestCreate from "@components/stock-request/create/StockRequestCreate";
import StockRequestDetail from "@components/stock-request/detail/StockRequestDetail";
import StockRequestTable from "@components/stock-request/table/StockRequestTable";
import SubscriptionContractTable from "@components/subscription-contract/SubscriptionContractTable";
import SubscriptionContractCreate from "@components/subscription-contract/create/SubscriptionContractCreate";
import SubscriptionContractDetail from "@components/subscription-contract/detail/SubscriptionContractDetail";
import TransferStockCrud from "@components/transfer-stock/create/TransferStockCrud";
import TransferStockDetail from "@components/transfer-stock/detail/TransferStockDetail";
import TransferStockTable from "@components/transfer-stock/table/TransferStockTable";
import UserCreate from "@components/user/create/UserCreate";
import UserDetail from "@components/user/detail/UserDetail";
import UserTable from "@components/user/table/UserTable";
import VehicleCreate from "@components/vehicle/create/VehicleCreate";
import VehicleDetail from "@components/vehicle/detail/VehicleDetail";
import VehicleTable from "@components/vehicle/table/VehicleTable";
import WarehouseCreate from "@components/warehouse/create/WarehouseCreate";
import WarehouseDetail from "@components/warehouse/detail/WarehouseDetail";
import WarehouseTable from "@components/warehouse/table/WarehouseTable";
import { ADMIN_AUTH_KEY } from "@constants";
import { isEmpty } from "lodash";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { useLocalStorage } from "react-use";
import { Path } from "./Path";
import { ProtectedRoute } from "./ProtectedRoute";
import ChannelCreate from "@components/channel/create/ChannelCreate";
import CartRuleCreate from "@components/cart-rule/create/CartRuleCreate";
import CartRuleDetail from "@components/cart-rule/detail/CartRuleDetail";
import ProductImport from "@components/product/import/ProductImport";
import ProductAliasImport from "@components/product-alias/import/ProductAliasImport";
import ProductLabelImport from "@components/product-label/import/ProductLabelImport";
import UserImport from "@components/user/import/UserImport";
import WarehouseImport from "@components/warehouse/import/WarehouseImport";
import CheckSKU from "@components/check-sku/CheckSKU";
import CashDepositTable from "@components/cash-deposit/table/CashDepositTable";
import CashDepositCreate from "@components/cash-deposit/create/CashDepositCreate";
import CashDepositDetail from "@components/cash-deposit/detail/CashDepositDetail";
import RawMaterialTable from "@components/raw-material/table/RawMaterialTable";
import RawMaterialDetail from "@components/raw-material/detail/RawMaterialDetail";
import RawMaterialForecast from "@components/raw-material-forecast/RawMaterialForecast";
import RefundRequestTable from "@components/order/components/refund-request/table/RefundRequestTable";
import DeliveryTrackingTable from "@components/order/components/delivery-tracking/table/DeliveryTrackingTable";

export const Layout = () => {
  return (
    <LayoutInner>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.ORDER}
          element={
            <ProtectedRoute>
              <Order />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path={Path.ORDER_DETAIL}
          element={
            <ProtectedRoute>
              <OrderDetail />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path={Path.VIEW_ORDER_DETAIL}
          element={<OrderDetail isPublic />}
        ></Route>
        <Route
          path={Path.NEW_ORDER}
          element={
            <ProtectedRoute>
              <OrderCreate />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path={Path.CALL_HISTORY}
          element={
            <ProtectedRoute>
              <CallHistory />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path={Path.CUSTOMER_INFO_MAP}
          element={
            <ProtectedRoute>
              <CustomerInfoMappingTable />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path={Path.CUSTOMER_INFO_MAP_CREATE}
          element={
            <ProtectedRoute>
              <CustomerInfoMappingCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.GP_EVENT}
          element={
            <ProtectedRoute>
              <GPEventTable />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.GP_EVENT_CREATE}
          element={
            <ProtectedRoute>
              <GPEventCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.GP_EVENT_DETAIL}
          element={
            <ProtectedRoute>
              <GPEventDetail />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path={Path.INVENTORY_CONTROL_EVENT}
          element={
            <ProtectedRoute>
              <InventoryControlEventTable />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.INVENTORY_CONTROL_EVENT_CREATE}
          element={
            <ProtectedRoute>
              <InventoryControlEventCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.INVENTORY_CONTROL_EVENT_DETAIL}
          element={
            <ProtectedRoute>
              <InventoryControlEventDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.TRANSFER_STOCK}
          element={
            <ProtectedRoute>
              <TransferStockTable />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.TRANSFER_STOCK_CREATE}
          element={
            <ProtectedRoute>
              <TransferStockCrud />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.TRANSFER_STOCK_DETAIL}
          element={
            <ProtectedRoute>
              <TransferStockDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.STOCK_REQUEST}
          element={
            <ProtectedRoute>
              <StockRequestTable />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.STOCK_REQUEST_CREATE}
          element={
            <ProtectedRoute>
              <StockRequestCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.STOCK_REQUEST_DETAIL}
          element={
            <ProtectedRoute>
              <StockRequestDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.STOCK_ADJUSTMENT}
          element={
            <ProtectedRoute>
              <StockAdjustmentTable />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.STOCK_ADJUSTMENT_CREATE}
          element={
            <ProtectedRoute>
              <StockAdjustmentCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.STOCK_ADJUSTMENT_DETAIL}
          element={
            <ProtectedRoute>
              <StockAdjustmentDetail />
            </ProtectedRoute>
          }
        />
        {/* Print product */}
        <Route
          path={Path.PRODUCTION_PRINTING}
          element={
            <ProtectedRoute>
              <ProductionPrintingTable />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.DELIVERY_STOCK}
          element={
            <ProtectedRoute>
              <DeliveryTransferStockTable />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.DELIVERY_STOCK_DETAIL}
          element={
            <ProtectedRoute>
              <DeliveryDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.INVENTORY}
          element={
            <ProtectedRoute>
              <InventoryTable />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.PRODUCTION_COST}
          element={
            <ProtectedRoute>
              <CostingExpenseTable />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.VEHICLE}
          element={
            <ProtectedRoute>
              <VehicleTable />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.VEHICLE_CREATE}
          element={
            <ProtectedRoute>
              <VehicleCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.VEHICLE_DETAIL}
          element={
            <ProtectedRoute>
              <VehicleDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.DELIVERY_SO}
          element={
            <ProtectedRoute>
              <DeliverySOTable />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.DELIVERY_SO_DETAIL}
          element={
            <ProtectedRoute>
              <DeliverySODetail />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.WAREHOUSE}
          element={
            <ProtectedRoute>
              <WarehouseTable />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.WAREHOUSE_CREATE}
          element={
            <ProtectedRoute>
              <WarehouseCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.WAREHOUSE_DETAIL}
          element={
            <ProtectedRoute>
              <WarehouseDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.USER}
          element={
            <ProtectedRoute>
              <UserTable />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.CONNECT_LINE}
          element={
            <ProtectedRoute>
              <ConnectLine />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.CONNECT_LINE_REQUIRED}
          element={<ConnectLineMobile />}
        ></Route>
        <Route
          path={Path.SUBSCRIPTION_CONTRACT}
          element={
            <ProtectedRoute>
              <SubscriptionContractTable />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.USER_CREATE}
          element={
            <ProtectedRoute>
              <UserCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.SUBSCRIPTION_CONTRACT_CREATE}
          element={
            <ProtectedRoute>
              <SubscriptionContractCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.USER_DETAIL}
          element={
            <ProtectedRoute>
              <UserDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.USER_IMPORT}
          element={
            <ProtectedRoute>
              <UserImport />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.SUBSCRIPTION_CONTRACT_DETAIL}
          element={
            <ProtectedRoute>
              <SubscriptionContractDetail />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path={Path.MY_ASSETS}
          element={
            <ProtectedRoute>
              <MyAssets />
            </ProtectedRoute>
          }
        >
          <Route path={Path.MY_ASSETS_CHANNEL} element={<MyAssetsChannel />} />
          <Route path={Path.MY_ASSETS_ROLE} element={<MyAssetsRole />} />
          <Route
            path={Path.MY_ASSETS_WAREHOUSE}
            element={<MyAssetsWarehouse />}
          />
          <Route path={Path.MY_REQUEST} element={<MyRequests />} />
          <Route path={Path.MY_REQUEST_NEW} element={<NewRequest />} />
          <Route path={Path.MY_REQUEST_DETAIL} element={<MyRequestDetail />} />
          <Route path={Path.REQUEST_APPROVAL} element={<RequestApproval />} />
          <Route
            path={Path.REQUEST_APPROVAL_DETAIL}
            element={<RequestApprovalDetail />}
          />
        </Route>
        <Route
          path={Path.KPI}
          element={
            <ProtectedRoute>
              <KPI />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.PRODUCT}
          element={
            <ProtectedRoute>
              <ProductTable />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.PRODUCT_CREATE}
          element={
            <ProtectedRoute>
              <ProductCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.PRODUCT_DETAIL}
          element={
            <ProtectedRoute>
              <ProductDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.CUSTOMER}
          element={
            <ProtectedRoute>
              <CustomerTable />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.CUSTOMER_CREATE}
          element={
            <ProtectedRoute>
              <CustomerCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.CUSTOMER_DETAIL}
          element={
            <ProtectedRoute>
              <CustomerDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.DISCOUNT_STAFF_MANAGEMENT}
          element={
            <ProtectedRoute>
              <DiscountStaffManagementTable />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.DISCOUNT_CODE_USAGE}
          element={
            <ProtectedRoute>
              <DiscountCodeUsageTable />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.DISCOUNT_CODE_USAGE_REPORT}
          element={
            <ProtectedRoute>
              <DiscountCodeUsageReport />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.MY_ISSUSED_DISCOUNT_OTP}
          element={
            <ProtectedRoute>
              <MyIssuedDiscountOTP />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.MY_AUTHORIZED_DISCOUNT_OTP}
          element={
            <ProtectedRoute>
              <MyAuthorizedDiscountOTP />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.PROMOTION_LIST}
          element={
            <ProtectedRoute>
              <PromotionTable />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.PROMOTION}
          element={
            <ProtectedRoute>
              <PromotionTable />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.PROMOTION_CREATE}
          element={
            <ProtectedRoute>
              <PromotionCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.PROMOTION_DETAIL}
          element={
            <ProtectedRoute>
              <PromotionDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.PROMOTION_CREATE_DUPLICATE}
          element={
            <ProtectedRoute>
              <PromotionCreate />
            </ProtectedRoute>
          }
        />

        <Route
          path={Path.CHANNEL}
          element={
            <ProtectedRoute>
              <ChannelTable />
            </ProtectedRoute>
          }
        />

        <Route
          path={Path.CHANNEL_DETAIL}
          element={
            <ProtectedRoute>
              <ChannelDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.CHANNEL_CREATE}
          element={
            <ProtectedRoute>
              <ChannelCreate />
            </ProtectedRoute>
          }
        />

        <Route
          path={Path.SERIAL_NUMBER}
          element={
            <ProtectedRoute>
              <SerialNumberTable />
            </ProtectedRoute>
          }
        />

        <Route
          path={Path.CART_RULE}
          element={
            <ProtectedRoute>
              <CartRuleTable />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.CART_RULE_CREATE}
          element={
            <ProtectedRoute>
              <CartRuleCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.CART_RULE_DETAIL}
          element={
            <ProtectedRoute>
              <CartRuleDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.SERIAL_NUMBER_DETAIL}
          element={
            <ProtectedRoute>
              <SerialNumberDetail />
            </ProtectedRoute>
          }
        />

        <Route
          path={Path.PRODUCT_IMPORT}
          element={
            <ProtectedRoute>
              <ProductImport />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.PRODUCT_ALIAS_IMPORT}
          element={
            <ProtectedRoute>
              <ProductAliasImport />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.PRODUCT_LABEL_IMPORT}
          element={
            <ProtectedRoute>
              <ProductLabelImport />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.CHECK_SKU}
          element={
            <ProtectedRoute>
              <CheckSKU />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.WAREHOUSE_IMPORT}
          element={
            <ProtectedRoute>
              <WarehouseImport />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.CASH_DEPOSIT}
          element={
            <ProtectedRoute>
              <CashDepositTable />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.CASH_DEPOSIT_DETAIL}
          element={
            <ProtectedRoute>
              <CashDepositDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.CASH_DEPOSIT_CREATE}
          element={
            <ProtectedRoute>
              <CashDepositCreate />
            </ProtectedRoute>
          }
        />

        <Route
          path={Path.RAW_MATERIAL}
          element={
            <ProtectedRoute>
              <RawMaterialTable />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.RAW_MATERIAL_DETAIL}
          element={
            <ProtectedRoute>
              <RawMaterialDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.RAW_MATERIAL_FORECAST}
          element={
            <ProtectedRoute>
              <RawMaterialForecast />
            </ProtectedRoute>
          }
        />

        <Route
          path={Path.REFUND_REQUEST}
          element={
            <ProtectedRoute>
              <RefundRequestTable />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.DELIVERY_TRACKING}
          element={
            <ProtectedRoute>
              <DeliveryTrackingTable />
            </ProtectedRoute>
          }
        />
        <Route path="/login" element={<Login />}></Route>
      </Routes>
    </LayoutInner>
  );
};

const LayoutInner: React.FC<any> = ({ children }) => {
  const [isAuthenticated] = useLocalStorage(ADMIN_AUTH_KEY);
  return !isEmpty(isAuthenticated) ? (
    <div className="app-layout__wrapper">{children}</div>
  ) : (
    <div className="app-layout__wrapper">
      <div className="active" id="app-layout__content">
        {children}
      </div>
    </div>
  );
};
