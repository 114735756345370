import AppContext from "@helpers/context";
import { AwesomeTableComponent, Icon } from "d-react-components";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckSkuTab } from "./CheckSKU";
import { CHECK_SKU_STATUS } from "@constants/product";
import { Link } from "react-router-dom";
import DownloadSKU from "./DownloadSKU";

const CheckSKUTable = () => {
  const { t } = useTranslation();
  const [openModalSelectDownload, setOpenModalSelectDownload] = useState(false);
  const { selectedTab, numberOfTypeItem, pasteDataResult, uploadDataResult } =
    useContext(AppContext);

  const renderDisplayNumberItem = (exist: any, no_exist: any, dup: any) => {
    return (
      <div id="renderDisplayNumberItem">
        <span className="ml-2">
          <span>{t("existedItem")}:</span>
          {` ${exist}`},
        </span>
        <span className="ml-2">
          <span>{t("notExistedItem")}:</span>
          {` ${no_exist}`},
        </span>
        <span className="ml-2">
          <span>{t("duplicatedItem")}:</span>
          {` ${dup}`}
        </span>
      </div>
    );
  };

  const columns = [
    {
      title: () => (
        <span className="tableTitle nowrapCellTable">{t("NO")}</span>
      ),
      dataIndex: "",
      width: 100,
      ellipsis: true,
      render: (i: any, item: any, index: any) => {
        return <div className="subtitle1">{index + 1}</div>;
      },
    },
    {
      title: () => (
        <span className="tableTitle nowrapCellTable">{t("sku")}</span>
      ),
      dataIndex: "sku",
      width: 200,
      ellipsis: true,
      render: (sku: any, item: any, index: any) => {
        const link = item?.editurl ?? null;
        return (
          <Link to={`/product/${item.id}`} target="_blank">
            <div
              className="subtitle1"
              style={{ color: link ? "rgb(44, 152, 240)" : "black" }}
            >
              {sku}
            </div>
          </Link>
        );
      },
    },
    {
      title: () => (
        <span className="tableTitle nowrapCellTable">{t("name")}</span>
      ),
      dataIndex: "name",
      width: 500,
      ellipsis: true,
      render: (name: any, item: any, index: any) => {
        return <div className="subtitle1">{name ?? "N/A"}</div>;
      },
    },
    {
      title: () => (
        <span className="tableTitle nowrapCellTable">{t("category")}</span>
      ),
      dataIndex: "category",
      ellipsis: true,
      render: (category: any, item: any, index: any) => {
        return <div className="subtitle1">{category?.name ?? "N/A"}</div>;
      },
    },
    {
      title: () => (
        <span className="tableTitle nowrapCellTable">{t("brand")}</span>
      ),
      dataIndex: "brand",
      ellipsis: true,
      render: (brand: any, item: any, index: any) => {
        return <div className="subtitle1">{brand?.name ?? "N/A"}</div>;
      },
    },
    {
      title: () => (
        <span className="tableTitle nowrapCellTable">{t("status")}</span>
      ),
      dataIndex: "status",
      ellipsis: true,
      render: (status: any, item: any, index: any) => {
        const statusItem = CHECK_SKU_STATUS[status];
        return (
          <div
            className="subtitle1"
            style={{ color: statusItem?.color ?? "black" }}
          >
            {t(statusItem?.label) ?? "N/A"}
          </div>
        );
      },
    },
  ];

  return (
    <div className="checkSKUPageContainer-tableResult">
      <div className="flex">
        <div className="font-semibold flex items-center flex-1 my-3">
          {t("result")}
          {selectedTab === CheckSkuTab.COPY_PASTE
            ? renderDisplayNumberItem(
                numberOfTypeItem.existed,
                numberOfTypeItem.noExisted,
                numberOfTypeItem.duplicated
              )
            : renderDisplayNumberItem(
                numberOfTypeItem.existed_1,
                numberOfTypeItem.noExisted_1,
                numberOfTypeItem.duplicated_1
              )}
        </div>
        <div
          className="text-primary flex items-center cursor-pointer"
          onClick={() => setOpenModalSelectDownload(true)}
        >
          <Icon name="cloud_download" className="mr-1" />
          <span>{t("download")}</span>
        </div>
      </div>
      <AwesomeTableComponent
        dataSource={
          selectedTab === CheckSkuTab.COPY_PASTE
            ? pasteDataResult
            : uploadDataResult
        }
        columns={columns}
        isScroll={false}
        tableLayout="fixed"
        pagination={false}
      />
      {openModalSelectDownload && (
        <DownloadSKU
          open={openModalSelectDownload}
          onClose={() => setOpenModalSelectDownload(false)}
        />
      )}
    </div>
  );
};

export default CheckSKUTable;
