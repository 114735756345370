import { IFieldSelect } from "@interfaces/select";
import { IStore } from "@interfaces/store";

import { listUserStoreNoPaging } from "@network/api/store";
import { ObjectUtils, Select } from "d-react-components";
import { map } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export interface IStoreSelect<T> extends IFieldSelect<T> {
  [key: string]: any;
  channelId?: string | string[];
}

const StoreSelectAll = ({
  value = [],
  multiple,
  className,
  onChange,
  channelId,
  disabled,
  ...selectProps
}: IStoreSelect<IStore>) => {
  // const [storeList, setStoreList] = useState<any[]>(
  const [storeList, setStoreList] = useState<IStore[]>([]);

  const { t } = useTranslation();

  useEffect(() => {
    loadStoreList();
  }, [JSON.stringify(channelId)]);

  const loadStoreList = () => {
    listUserStoreNoPaging({
      channelid: channelId,
    }).then((res) => {
      const storeList = res?.data?.data?.stores ?? [];
      setStoreList(storeList);
    });
  };

  const onChangeValue = (id: any) => {
    if (multiple) {
      onChange(map(id, (item) => ObjectUtils.findItemFromId(storeList, item)));
      return;
    }
    onChange(ObjectUtils.findItemFromId(storeList, id));
  };

  const storeValue = useMemo(() => {
    if (multiple) {
      return map(value, (item: any) => item.id);
    }
    return (value as any)?.id;
  }, [value]);

  return (
    <Select
      showSearch
      dataSource={storeList}
      value={storeValue}
      multiple={multiple}
      label={t("warehouse")}
      getLabel={(item) => item?.name}
      disabled={disabled}
      onChange={onChangeValue}
      placeholder={t("pleaseSelect")}
      className={className}
      {...selectProps}
    />
  );
};

export default StoreSelectAll;
