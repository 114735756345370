import InputTextForm from "@common/input/InputTextForm";
import ChannelSelect from "@components/channel/components/ChannelSelect";
import StoreSelect from "@components/store/components/StoreSelect";
import UserSelect from "@components/user/components/UserSelect";
import VehicleSelect from "@components/vehicle/components/VehicleSelect";
import {
  EVENT_VISIBILITY_STATUSES,
  INVENTORY_EVENT_STATUSES,
} from "@constants/inventory-control-event";
import { DELIVERY_STATUS_LIST } from "@constants/order";
import { Select } from "d-react-components";
import { map } from "lodash";
import { useTranslation } from "react-i18next";

interface IInventoryFilterForm {
  filterForm: any;
}

const DeliveryStockFilterForm = ({ filterForm }: IInventoryFilterForm) => {
  const classNameField = "col-span-12 mt-3";
  const formValues = filterForm?.values;
  const formErrors = filterForm?.errors;
  const { t } = useTranslation();

  return (
    <div>
      <div className="grid grid-cols-4 gap-4">
        <ChannelSelect
          value={formValues.channels}
          className={classNameField}
          onChange={(value) =>
            filterForm.setValues({
              ...formValues,
              channels: value,
              warehouseOrigin: [],
              warehouseDestination: [],
            })
          }
          multiple
        />
        <StoreSelect
          label={t("origin")}
          className={classNameField}
          value={formValues.warehouseOrigin}
          onChange={(value: any) => {
            filterForm.setValues({
              ...formValues,
              warehouseOrigin: value,
            });
          }}
          channelId={map(formValues.channels, (item) => item.id)}
          multiple
        />
        <StoreSelect
          label={t("destination")}
          className={classNameField}
          value={formValues.warehouseDestination}
          onChange={(value: any) => {
            filterForm.setValues({
              ...formValues,
              warehouseDestination: value,
            });
          }}
          channelId={map(formValues.channels, (item) => item.id)}
          multiple
        />
        <VehicleSelect
          className={classNameField}
          value={formValues.vehicle}
          onChange={(value: any) => {
            filterForm.setFieldValue("vehicle", value);
          }}
          multiple
        />
        <UserSelect
          label={t("driver")}
          className={classNameField}
          value={formValues?.driver}
          onChange={(value) => {
            filterForm.setFieldValue("driver", value);
          }}
          multiple
        />
        <Select
          label={t("status")}
          className={classNameField}
          value={formValues?.status}
          onChange={(value) => {
            filterForm.setFieldValue("status", value);
          }}
          getLabel={(item) => t(item?.label)}
          dataSource={DELIVERY_STATUS_LIST}
          multiple
        />
        <UserSelect
          label={t("createdBy")}
          className={classNameField}
          value={formValues?.createdBy}
          onChange={(value) => {
            filterForm.setFieldValue("createdBy", value);
          }}
          multiple
        />
      </div>
    </div>
  );
};

export default DeliveryStockFilterForm;
