import InputTextForm from "@common/input/InputTextForm";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const ChannelFormSupport = ({ form, fieldClassName = "" }: any) => {
  const { t } = useTranslation();

  const classNameField = classNames("col-6 mt-3", fieldClassName);
  return (
    <div className="row">
      <InputTextForm
        keyData="phoneSupport"
        form={form}
        className={classNameField}
        label={t("phoneNoSupport")}
      />
      <InputTextForm
        keyData="line"
        form={form}
        className={classNameField}
        label={t("lineSupportUrl")}
      />
      <InputTextForm
        keyData="messenger"
        form={form}
        className={classNameField}
        label={t("messengerSupportUrl")}
      />
      <InputTextForm
        keyData="emailSupport"
        form={form}
        className={classNameField}
        label={t("email")}
      />
    </div>
  );
};

export default ChannelFormSupport;
