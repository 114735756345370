import { IMPORT_WAREHOUSE_METHODS } from "@constants/warehouse";
import AppContext from "@helpers/context";
import WarehouseAPI from "@network/api/warehouse";
import { Spin } from "antd";
import { ObjectUtils } from "d-react-components";
import { find, forEach, includes, isEmpty, map } from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";

export const getImportWarehouse = (
  importData: any,
  importFields: any,
  parentName?: any
) => {
  const body: any = {};
  forEach(importFields, (field) => {
    if (field?.fields?.length > 0) {
      body[field.name] = getImportWarehouse(
        importData,
        field?.fields,
        field?.name
      );
    } else {
      const keyName = isEmpty(parentName)
        ? field.name
        : `${parentName}_${field.name}`;
      body[field.name] = importData[keyName];
    }
  });
  return body;
};

const WarehouseImportValidation = () => {
  const { formImport } = useContext(AppContext);
  const [validateLoading, setValidateLoading] = useState(true);
  const { t } = useTranslation();

  const { importType, importFields, fileImport } = formImport.values;
  const importTypeVal = useMemo(
    () => find(IMPORT_WAREHOUSE_METHODS, (item) => item.id === importType),
    [importType]
  );

  useEffect(() => {
    try {
      onValidateImportDatabase(fileImport?.data);
    } catch (err) {
      setValidateLoading(false);
    }
  }, []);

  const onValidateImportDatabase = async (importData: any) => {
    const mulCodeList = ObjectUtils.sliceArrayToMui(importData, 100000);
    let errors: any = [];

    for (let index = 0; index < mulCodeList.length; index++) {
      const codeItemList = mulCodeList[index];
      const validationItems = await getValidateWarehouseAPI(codeItemList);
      errors = errors.concat(validationItems);
    }
    formImport.setFieldValue("fileImport", { ...fileImport, errors });
    setValidateLoading(false);
  };

  const getErrorItemsData = () => {
    // const fieldList = map(importFields, (item) => item.name);
    const fieldList = Object.keys(fileImport?.data[0]);
    const header = [...fieldList, "Message"];
    const dataResult = [header];
    forEach(fileImport?.errors, (validation) => {
      const error = find(
        fileImport.data,
        (item) =>
          `${item.code_code}_${item.code_pickup_code}` === validation.code
      );
      forEach(validation?.validation ?? [], (message) =>
        dataResult.push([...map(fieldList, (item) => error?.[item]), message])
      );
    });
    return dataResult;
  };

  const getSuccessItemsData = () => {
    const fieldList = Object.keys(fileImport?.data[0]);
    const header = [...fieldList, "Message"];
    const dataResult = [header];
    forEach(fileImport.data, (warehouse) => {
      const isErrorPro = includes(
        map(fileImport?.errors, (item) => item.code),
        `${warehouse.code_code}_${warehouse.code_pickup_code}`
      );
      if (!isErrorPro) {
        dataResult.push([
          ...map(fieldList, (item) => warehouse[item]),
          "success",
        ]);
      }
    });
    return dataResult;
  };

  const getValidateWarehouseAPI = async (importData: any) => {
    const body = {
      method: importType,
      fields: map(importFields, (item) => item.name),
      warehouse: map(importData, (data) =>
        getImportWarehouse(data, importFields)
      ),
    };

    return WarehouseAPI.validateImport(body).then((res) => {
      const validation = res?.data?.data?.validate;
      const validationResult: any = [];
      Object.keys(validation).forEach((key) => {
        validationResult.push({ code: key, validation: validation[key] });
      });
      return validationResult;
    });
  };

  const renderRowInfo = (title: any, content: any) => (
    <div className="d-flex mt-3">
      <text className="font-weight-bold">{`${title}: `}</text>
      <text className="ml-1">{content}</text>
    </div>
  );

  const renderValidateError = () => {
    if (fileImport?.errors?.length > 0) {
      return (
        <div className="d-flex ">
          <text className="text-error">
            {fileImport?.errors?.length} {t("errors")}
          </text>
          <CSVLink data={getErrorItemsData()} filename="invalid-import-sku.csv">
            <div className="ml-1 text-primary">{t("downloadLog")}</div>
          </CSVLink>
        </div>
      );
    }
    return <text className="text-success">{t("readyToImport")}</text>;
  };

  const renderValidateSuccess = () => {
    return (
      <div className="d-flex ">
        <text className="text-success">
          {fileImport?.data?.length - fileImport?.errors?.length} {t("success")}
        </text>
        <CSVLink
          data={getSuccessItemsData()}
          filename="ready-import-warehouse.csv"
        >
          <div className="ml-1 text-primary">{t("downloadLog")}</div>
        </CSVLink>
      </div>
    );
  };

  if (validateLoading) {
    return (
      <div className="flex-center p-5">
        <Spin />
      </div>
    );
  }
  return (
    <div className="p-4">
      <div
        className="d-flex flex-column p-4"
        style={{ backgroundColor: "rgba(33, 150, 243, 0.1)" }}
      >
        {renderRowInfo(t("fileName"), fileImport?.name)}
        {renderRowInfo(t("importMethod"), t(importTypeVal?.label as any))}
        {renderRowInfo(t("validateStatus"), renderValidateError())}
        {renderRowInfo(t("validateStatus"), renderValidateSuccess())}
      </div>
      <div className="mt-5">
        {renderRowInfo(`*${t("lastWarning")}`, t("pleaseCheckCarefully"))}
      </div>
    </div>
  );
};

export default WarehouseImportValidation;
