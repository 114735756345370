export const Path = {
  ROOT: "/",
  AUTH: "/auth",
  AUTH_LOGIN: "/login",
  AUTH_FORGOT_PASS: "/auth/forgot-password",
  OAUTH: "/oauth",
  ORDER: "/order",
  ORDER_DETAIL: "/order/:id",
  NEW_ORDER: "/order/new",
  CALL_HISTORY: "/call-history",
  CUSTOMER_INFO_MAP: "/customer-info-map",
  CUSTOMER_INFO_MAP_CREATE: "/customer-info-map/create",
  VIEW_ORDER_DETAIL: "/view-detail-order",

  GP_EVENT: "/gp-event",
  GP_EVENT_CREATE: "/gp-event/create",
  GP_EVENT_DETAIL: "/gp-event/:eventId",

  INVENTORY_CONTROL_EVENT: "/inventory-control-event",
  INVENTORY_CONTROL_EVENT_CREATE: "/inventory-control-event/create",
  INVENTORY_CONTROL_EVENT_DETAIL: "/inventory-control-event/:eventId",

  TRANSFER_STOCK: "/transfer-stock",
  TRANSFER_STOCK_CREATE: "/transfer-stock/create",
  TRANSFER_STOCK_DETAIL: "/transfer-stock/:transferId",

  STOCK_REQUEST: "/stock-request",
  STOCK_REQUEST_CREATE: "/stock-request/create",
  STOCK_REQUEST_DETAIL: "/stock-request/:requestId",

  STOCK_ADJUSTMENT: "/stock-adjustment",
  STOCK_ADJUSTMENT_CREATE: "/stock-adjustment/create",
  STOCK_ADJUSTMENT_DETAIL: "/stock-adjustment/:adjustmentId",

  PRODUCTION_PRINTING: "/production-printing",

  DELIVERY_STOCK: "/delivery-stock",
  DELIVERY_STOCK_DETAIL: "/delivery-stock/:deliveryId",

  DELIVERY_SO: "/delivery-so",
  DELIVERY_SO_DETAIL: "/delivery-so/:deliveryId",

  INVENTORY: "/inventory",
  PRODUCTION_COST: "/costing-expense",

  VEHICLE: "/vehicle",
  VEHICLE_CREATE: "/vehicle/create",
  VEHICLE_DETAIL: "/vehicle/:vehicleId",

  WAREHOUSE: "/warehouse",
  WAREHOUSE_CREATE: "/warehouse/create",
  WAREHOUSE_DETAIL: "/warehouse/:warehouseId",
  WAREHOUSE_IMPORT: "/warehouse-import",

  USER: "/users",
  USER_CREATE: "/users/create",
  USER_DETAIL: "/users/:userId",
  USER_IMPORT: "/users-import",

  MY_ASSETS: "/my-assets",
  MY_ASSETS_CHANNEL: "/my-assets/channel",
  MY_ASSETS_WAREHOUSE: "/my-assets/warehouse",
  MY_ASSETS_ROLE: "/my-assets/role",
  MY_REQUEST_PARENT: "/my-assets/my-request",
  MY_REQUEST_NEW: "/my-assets/new-request/:type",
  MY_REQUEST: "/my-assets/my-request/:status",
  MY_REQUEST_APPROVAL_PARENT: "/my-assets/request-approval",
  REQUEST_APPROVAL: "/my-assets/request-approval/:status",
  REQUEST_APPROVAL_DETAIL: "/my-assets/request-approval/detail/:id",
  MY_REQUEST_DETAIL: "/my-assets/my-request/detail/:id",

  KPI: "/kpi",
  CONNECT_LINE: "/line-connect",
  CONNECT_LINE_REQUIRED: "/line-connect-required",

  SUBSCRIPTION_CONTRACT: "/subscription-contract",
  SUBSCRIPTION_CONTRACT_CREATE: "/subscription-contract/new",
  SUBSCRIPTION_CONTRACT_DETAIL: "/subscription-contract/:id",
  PRODUCT: "/products",
  PRODUCT_CREATE: "/product/create",
  PRODUCT_DETAIL: "/product/:productId",

  CUSTOMER: "/customers",
  CUSTOMER_CREATE: "/customers/create",
  CUSTOMER_DETAIL: "/customers/:customerId",

  DISCOUNT_STAFF_MANAGEMENT: "/discount-staff-management",
  DISCOUNT_CODE_USAGE: "/discount-code-usage",
  DISCOUNT_CODE_USAGE_REPORT: "/discount-code-report",
  MY_ISSUSED_DISCOUNT_OTP: "/my-issued-discount-otp",
  MY_AUTHORIZED_DISCOUNT_OTP: "/my-authorized-discount-otp",

  PROMOTION_LIST: "/promotion-list",
  PROMOTION: "/promotion",
  PROMOTION_CREATE: "/promotion/create",
  PROMOTION_CREATE_DUPLICATE: "/promotion/create/:promotionId",
  PROMOTION_DETAIL: "/promotion/:promotionId",

  CHANNEL: "/channels",
  CHANNEL_CREATE: "/channels/create",
  CHANNEL_DETAIL: "/channels/:channelId",

  CART_RULE: "/cart-rule",
  CART_RULE_CREATE: "/cart-rule/create",
  CART_RULE_DETAIL: "/cart-rule/:cartRuleId",

  SERIAL_NUMBER: "/serial-number",
  SERIAL_NUMBER_DETAIL: "/serial-number/:serialNumberId",
  SERIAL_NUMBER_CREATE: "/serial-number/create",

  PRODUCT_IMPORT: "/product-import",
  PRODUCT_ALIAS_IMPORT: "/product-alias-import",
  PRODUCT_LABEL_IMPORT: "/product-label-import",
  CHECK_SKU: "/check-sku/",

  CASH_DEPOSIT: "/cash-deposit",
  CASH_DEPOSIT_CREATE: "/cash-deposit/create",
  CASH_DEPOSIT_DETAIL: "/cash-deposit/:cashDepositId",

  RAW_MATERIAL: "/raw-material",
  RAW_MATERIAL_DETAIL: "/raw-material/:rawMaterialId",
  RAW_MATERIAL_FORECAST: "/raw-material-forecast",

  REFUND_REQUEST: "/refund-request",
  DELIVERY_TRACKING: "/delivery-tracking"
};

export const PUBLIC_PATHS = [
  Path.AUTH,
  Path.AUTH_LOGIN,
  Path.AUTH_FORGOT_PASS,
  Path.OAUTH,
  Path.CONNECT_LINE_REQUIRED,
  Path.VIEW_ORDER_DETAIL,
];

export const MAP_PATHS_TO_V1 = {
  [Path.ORDER]: "/orders",
  [Path.NEW_ORDER]: "/orders/create",
  [Path.ORDER_DETAIL]: "/orders/:id/edit",
};
