import Drawer, { DrawerProps } from "@components/shared/Drawer";
import {
  DateInput,
  IRowsKey,
  InputText,
  Progress,
  Select,
  ViewRowInterchange,
} from "d-react-components";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import ImageIcon from "@common/Icon";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { compact, filter, find, isEmpty, pick } from "lodash";
import AppContext from "@helpers/context";
import { getDistrictList, getSubDistrictList } from "@network/api/address";
import { updateRentalCustomerInfo } from "@network/api/rental";
import * as Yup from "yup";
import moment from "moment";
import styled from "@emotion/styled";
import InputVerifier, { VerifiedButton } from "@common/input/InputVerifier";

export enum InformationType {
  INDIVIDUAL = "individual",
  BUSINESS = "business",
}

const getAddresssString = (information: any) => {
  if (information?.type_of_information === InformationType.INDIVIDUAL) {
    const addressComponents = compact([
      information?.address,
      information?.subdistrict?.name,
      information?.city?.name,
      information?.province?.name,
      information?.code,
    ]);
    return !isEmpty(addressComponents) ? addressComponents.join(", ") : "N/A";
  } else {
    const addressComponents = compact([
      information?.company_address,
      information?.company_subdistrict?.name,
      information?.company_city?.name,
      information?.company_province?.name,
      information?.company_code,
    ]);
    return !isEmpty(addressComponents) ? addressComponents.join(", ") : "N/A";
  }
};

const CustomerInformation = () => {
  const { t } = useTranslation("common");
  const [showCustomerInformationModal, setShowCustomerInformationModal] =
    useState(false);
  const { rentalInfo, updateContactInformation } = useContext(AppContext);

  const DETAILS_KEY_INDIVIDUALS: IRowsKey<any>[] = useMemo(
    () => [
      {
        id: "type_of_information",
        label: t("type"),
        renderContent: ({ data }) =>
          data && data === InformationType.INDIVIDUAL ? t("individual") : "N/A",
      },
      {
        id: "first_name",
        label: t("firstname"),
      },
      {
        id: "last_name",
        label: t("lastname"),
      },
      {
        id: "citizen_id",
        label: t("citizenId"),
      },
      {
        id: "day_of_birth",
        label: t("birthday"),
        renderContent: ({ data, item }) =>
          data ? moment(data).format("DD/MM/YYYY") : "N/A",
      },
      {
        id: "phone",
        label: t("phone"),
        renderContent: ({ data, item: customer }) =>
          data ? (
            <div className="flex items-center">
              <span className="mr-2">{data}</span>
              <InputVerifier
                type="phone"
                isVerified={customer?.is_verify_phone}
                value={customer?.phone}
                payload={{ channel_id: rentalInfo?.channel?.id }}
                label="rent-information"
                onVerified={(otp) => {
                  updateContactInformation({
                    is_verify_phone: true,
                  });
                }}
                rentId={rentalInfo?.information?.id}
              />
            </div>
          ) : (
            "N/A"
          ),
      },
      {
        id: "email",
        label: t("email"),
        renderContent: ({ data, item: customer }) =>
          data ? (
            <div className="flex items-center">
              <span className="mr-2">{data}</span>
              <InputVerifier
                type="email"
                isVerified={customer?.is_verify_email}
                value={customer?.email}
                payload={{ channel_id: rentalInfo?.channel?.id }}
                label="rent-information"
                onVerified={(otp) => {
                  updateContactInformation({
                    is_verify_email: true,
                  });
                }}
                rentId={rentalInfo?.information?.id}
              />
            </div>
          ) : (
            "N/A"
          ),
      },
      {
        id: "address",
        label: t("residenceAddress"),
        renderContent: ({ data, item }) => getAddresssString(item),
      },
    ],
    [t]
  );

  const DETAILS_KEY_BUSINESS: IRowsKey<any>[] = useMemo(
    () => [
      {
        id: "type_of_information",
        label: t("type"),
        renderContent: ({ data }) =>
          data && data === InformationType.BUSINESS ? t("business") : "N/A",
      },
      {
        id: "company_name",
        label: t("companyName"),
      },
      {
        id: "tax_id",
        label: t("taxId"),
      },
      {
        id: "address",
        label: t("companyAddressLabel"),
        renderContent: ({ data, item }) => getAddresssString(item),
      },
      {
        id: "company_phone",
        label: t("companyPhone"),
      },
      {
        id: "company_email",
        label: t("companyEmail"),
        renderContent: ({ data, item: customer }) =>
          data ? (
            <div className="flex items-center">
              <span className="mr-2">{data}</span>
              <InputVerifier
                type="email"
                isVerified={customer?.is_verify_email}
                value={customer?.email}
                payload={{ channel_id: rentalInfo?.channel?.id }}
                label="rent-information"
                onVerified={(otp) => {
                  updateContactInformation({
                    is_verify_email: true,
                  });
                }}
                rentId={rentalInfo?.information?.id}
              />
            </div>
          ) : (
            "N/A"
          ),
      },
      {
        id: "first_name",
        label: t("representativeFirstname"),
      },
      {
        id: "last_name",
        label: t("representativeLastname"),
      },
      {
        id: "phone",
        label: t("representativePhone"),
      },
      {
        id: "email",
        label: t("representativeEmail"),
      },
    ],
    [t]
  );

  return (
    <>
      <div className="flex flex-row justify-center items-center">
        <label className="block mb-0 text-sm flex-1">
          {t("customerInformation")}
        </label>
        <span
          className="cursor-pointer"
          onClick={() => setShowCustomerInformationModal(true)}
        >
          <ImageIcon
            src="/images/icons/edit.svg"
            className="w-[15px] cursor-pointer"
          />
        </span>
      </div>
      {!isEmpty(rentalInfo?.information) && (
        <StyledInformationBlock className="bg-blue-100 py-2 px-3 mt-3">
          <ViewRowInterchange
            dataSource={rentalInfo?.information}
            keyList={
              rentalInfo?.information?.type_of_information ===
              InformationType.INDIVIDUAL
                ? DETAILS_KEY_INDIVIDUALS
                : (DETAILS_KEY_BUSINESS as any)
            }
            variant="border"
            classNameLabel="font-weight-bold text-xs"
            classNameContent="text-xs"
            classNameRow="flex-col information-row"
          />
        </StyledInformationBlock>
      )}
      {showCustomerInformationModal && (
        <CustomerInformationModal
          open={showCustomerInformationModal}
          onClose={() => setShowCustomerInformationModal(false)}
        />
      )}
    </>
  );
};

const CustomerInformationModal = ({
  open,
  onClose,
}: DrawerProps & { onClose: any }) => {
  const { t } = useTranslation("common");
  const { provinceList, loadProvinceList, rentalInfo, onReloadOrderData } =
    useContext(AppContext);
  const [postcodeList, setPostCodeList] = useState<any[]>([]);
  const [cityList, setCityList] = useState<any[]>([]);
  const [subdistrictList, setSubdistrictList] = useState<any[]>([]);

  const infoForm = useFormik<any>({
    initialValues: {
      type:
        rentalInfo?.information?.type_of_information ||
        InformationType.INDIVIDUAL,
      ...rentalInfo?.information,
      ...(rentalInfo?.information?.type_of_information ===
      InformationType.BUSINESS
        ? {
            province: rentalInfo?.information?.company_province,
            city: rentalInfo?.information?.company_city,
            subdistrict: rentalInfo?.information?.company_subdistrict,
            code: rentalInfo?.information?.company_code,
          }
        : {
            day_of_birth: moment(rentalInfo?.information?.day_of_birth),
          }),
    },
    validateOnChange: false,
    validateOnBlur: false,
    //yup schema based on InformationType
    validationSchema: Yup.object().shape({
      citizen_id: Yup.string()
        .nullable()
        .when("type", {
          is: InformationType.INDIVIDUAL,
          then: Yup.string().test(
            "len",
            t("citizenIdMustBe13"),
            (val) => val?.length === 13
          ),
        }),
    }),
    onSubmit: (values) => {
      const payload = {
        rent_id: rentalInfo.id,
        type_of_information: values?.type,
        ...(values?.type === InformationType.INDIVIDUAL
          ? {
              ...pick(values, [
                "first_name",
                "last_name",
                "citizen_id",
                "phone",
                "email",
                "address",
              ]),
              province_id: values?.province?.id,
              city_id: values?.city?.id,
              subdistrict_id: values?.subdistrict?.id,
              code: values?.code,
              day_of_birth: values?.day_of_birth
                ? moment(values?.day_of_birth).valueOf()
                : null,
            }
          : {
              ...pick(values, [
                "company_name",
                "tax_id",
                "company_address",
                "company_phone",
                "company_email",
                "first_name",
                "last_name",
                "phone",
                "email",
              ]),
              company_province_id: values?.province?.id,
              company_city_id: values?.city?.id,
              company_subdistrict_id: values?.subdistrict?.id,
              company_code: values?.code,
            }),
      };
      Progress.show(
        { method: updateRentalCustomerInfo, params: [payload] },
        () => {
          onClose && onClose();
          onReloadOrderData && onReloadOrderData();
        }
      );
    },
  });
  const { values, setFieldValue, errors, handleSubmit } = infoForm;

  const loadPostCodeData = (subdistrict: any) => {
    if (!subdistrict) {
      return;
    }
    const postcodeResult =
      filter(subdistrictList, (pro) => pro.id === subdistrict)?.[0]?.code ?? [];
    setPostCodeList(postcodeResult);
  };

  const loadCityData = (province: any) => {
    getDistrictList(province).then((res) => {
      const cityListRes = res?.data?.data?.city ?? [];
      setCityList(cityListRes);
    });
  };

  const loadSubdistrictData = (city: any) => {
    getSubDistrictList(city).then((res) => {
      const subdistrict = res?.data?.data?.subdistrict ?? [];
      setSubdistrictList(subdistrict);
    });
  };

  useEffect(() => {
    loadCityData(values?.province);
  }, [values?.province]);

  useEffect(() => {
    loadSubdistrictData(values?.city);
  }, [values?.city]);

  useEffect(() => {
    loadPostCodeData(values?.subdistrict?.id);
  }, [values?.subdistrict]);

  useEffect(() => {
    if (!provinceList?.length) {
      loadProvinceList && loadProvinceList();
    }
  }, []);
  //reset all form values when type changes
  useEffect(() => {
    infoForm.resetForm();
    infoForm.setFieldValue("type", values.type);
  }, [values.type]);

  return (
    <Drawer
      title={t("customerInformation")}
      onClose={onClose}
      open={open}
      onSave={() => handleSubmit()}
    >
      <Select
        label={t("type")}
        name="informationType"
        options={[
          { label: t("individual"), value: InformationType.INDIVIDUAL },
          { label: t("business"), value: InformationType.BUSINESS },
        ]}
        onChange={(value) => {
          infoForm.setFieldValue("type", value);
        }}
        value={values.type}
        error={errors.type}
        className="mb-3"
      />
      {values.type === InformationType.INDIVIDUAL && (
        <>
          <InputText
            label={t("firstname")}
            value={values.first_name}
            onChange={(e) =>
              setFieldValue("first_name", e?.currentTarget?.value)
            }
            error={errors?.first_name as string}
            className="mb-3"
          />
          <InputText
            label={t("lastname")}
            value={values.last_name}
            onChange={(e) =>
              setFieldValue("last_name", e?.currentTarget?.value)
            }
            error={errors?.last_name as string}
            className="mb-3"
          />
          <InputText
            label={t("citizenId")}
            value={values.citizen_id}
            onChange={(e) =>
              setFieldValue("citizen_id", e?.currentTarget?.value)
            }
            error={errors?.citizen_id as string}
            className="mb-3"
          />
          <DateInput
            label={t("birthday")}
            value={values?.day_of_birth}
            error={errors?.day_of_birth as string}
            onChange={(val) => {
              setFieldValue && setFieldValue("day_of_birth", val);
            }}
            type="date"
            className="mb-3"
          />
          <StyledFormInput>
            <InputText
              label={t("phone")}
              value={values.phone}
              onChange={(e) => {
                setFieldValue("phone", e?.currentTarget?.value);
              }}
              error={errors?.phone as string}
              className="mb-3"
              suffix={
                <InputVerifier
                  type="phone"
                  isVerified={values?.is_verify_phone}
                  value={values?.phone}
                  payload={{ channel_id: rentalInfo?.channel?.id }}
                  label="rent-information"
                  onVerified={(otp) => {
                    setFieldValue("is_verify_phone", true);
                    setFieldValue("otp_phone_id", otp?.id);
                  }}
                  rentId={rentalInfo?.information?.id}
                />
              }
            />
          </StyledFormInput>
          <StyledFormInput>
            <InputText
              label={t("email")}
              value={values.email}
              onChange={(e) => setFieldValue("email", e?.currentTarget?.value)}
              error={errors?.email as string}
              className="mb-3"
              suffix={
                <InputVerifier
                  type="email"
                  isVerified={values?.is_verify_email}
                  value={values?.email}
                  payload={{ channel_id: rentalInfo?.channel?.id }}
                  label="rent-information"
                  onVerified={(otp) => {
                    setFieldValue("is_verify_email", true);
                    setFieldValue("otp_email_id", otp?.id);
                  }}
                  rentId={rentalInfo?.information?.id}
                />
              }
            />
          </StyledFormInput>
          <Select
            label={t("province")}
            dataSource={provinceList}
            value={values?.province?.id}
            onChange={(val) => {
              setFieldValue(
                "province",
                find(provinceList, (item) => item.id === val)
              );
              setFieldValue("city", undefined);
              setFieldValue("subdistrict", undefined);
              setFieldValue("code", undefined);
            }}
            getLabel={(item) => item?.name}
            showSearch
            error={errors?.province}
            placeholder={t("pleaseSelect")}
            className="mb-3"
          />
          <Select
            label={t("city")}
            dataSource={cityList}
            value={values?.city?.id}
            onChange={(val) => {
              setFieldValue(
                "city",
                find(cityList, (item) => item.id === val)
              );
              setFieldValue("subdistrict", undefined);
              setFieldValue("code", undefined);
            }}
            getLabel={(item) => item?.name}
            showSearch
            disabled={!values.province}
            error={errors?.city}
            placeholder={t("pleaseSelect")}
            className="mb-3"
          />
          <Select
            label={t("subdistrict")}
            dataSource={subdistrictList}
            value={values?.subdistrict?.id}
            onChange={(val) => {
              setFieldValue(
                "subdistrict",
                find(subdistrictList, (item) => item.id === val)
              );
              setFieldValue("code", undefined);
            }}
            getLabel={(item) => item?.name}
            showSearch
            disabled={!values.city}
            error={errors?.subdistrict}
            placeholder={t("pleaseSelect")}
            className="mb-3"
          />
          <Select
            label={t("postcode")}
            dataSource={postcodeList}
            value={values?.code}
            onChange={(val) => {
              setFieldValue("code", val);
            }}
            getLabel={(item) => item?.postcode}
            getValue={(item) => item?.postcode}
            showSearch
            disabled={!values.subdistrict}
            error={errors?.code}
            placeholder={t("pleaseSelect")}
            className="mb-3"
          />
          <InputText
            label={t("address")}
            value={values.address}
            onChange={(e) => setFieldValue("address", e?.currentTarget?.value)}
            error={errors?.address as string}
            className="mb-3"
          />
        </>
      )}
      {values.type === InformationType.BUSINESS && (
        <>
          <InputText
            label={t("companyName")}
            value={values.company_name}
            onChange={(e) =>
              setFieldValue("company_name", e?.currentTarget?.value)
            }
            error={errors?.company_name as string}
            className="mb-3"
          />
          <InputText
            label={t("taxId")}
            value={values.tax_id}
            onChange={(e) => setFieldValue("tax_id", e?.currentTarget?.value)}
            error={errors?.tax_id as string}
            className="mb-3"
          />
          <Select
            label={t("province")}
            dataSource={provinceList}
            value={values?.province?.id}
            onChange={(val) => {
              setFieldValue(
                "province",
                find(provinceList, (item) => item.id === val)
              );
              setFieldValue("city", undefined);
              setFieldValue("subdistrict", undefined);
              setFieldValue("code", undefined);
            }}
            getLabel={(item) => item?.name}
            showSearch
            error={errors?.province}
            placeholder={t("pleaseSelect")}
            className="mb-3"
          />
          <Select
            label={t("city")}
            dataSource={cityList}
            value={values?.city?.id}
            onChange={(val) => {
              setFieldValue(
                "city",
                find(cityList, (item) => item.id === val)
              );
              setFieldValue("subdistrict", undefined);
              setFieldValue("code", undefined);
            }}
            getLabel={(item) => item?.name}
            showSearch
            disabled={!values.province}
            error={errors?.city}
            placeholder={t("pleaseSelect")}
            className="mb-3"
          />
          <Select
            label={t("subdistrict")}
            dataSource={subdistrictList}
            value={values?.subdistrict?.id}
            onChange={(val) => {
              setFieldValue(
                "subdistrict",
                find(subdistrictList, (item) => item.id === val)
              );
              setFieldValue("code", undefined);
            }}
            getLabel={(item) => item?.name}
            showSearch
            disabled={!values.city}
            error={errors?.subdistrict}
            placeholder={t("pleaseSelect")}
            className="mb-3"
          />
          <Select
            label={t("postcode")}
            dataSource={postcodeList}
            value={values?.code}
            onChange={(val) => {
              setFieldValue("code", val);
            }}
            getLabel={(item) => item?.postcode}
            getValue={(item) => item?.postcode}
            showSearch
            disabled={!values.subdistrict}
            error={errors?.code}
            placeholder={t("pleaseSelect")}
            className="mb-3"
          />
          <InputText
            label={t("companyAddressLabel")}
            value={values.company_address}
            onChange={(e) =>
              setFieldValue("company_address", e?.currentTarget?.value)
            }
            error={errors?.company_address as string}
            className="mb-3"
          />
          <InputText
            label={t("companyPhone")}
            value={values.company_phone}
            onChange={(e) => {
              setFieldValue("company_phone", e?.currentTarget?.value);
            }}
            error={errors?.company_phone as string}
            className="mb-3"
          />
          <StyledFormInput>
            <InputText
              label={t("companyEmail")}
              value={values.company_email}
              onChange={(e) =>
                setFieldValue("company_email", e?.currentTarget?.value)
              }
              error={errors?.company_email as string}
              className="mb-3"
              suffix={
                <InputVerifier
                  type="email"
                  isVerified={values?.is_verify_email}
                  value={values?.company_email}
                  payload={{ channel_id: rentalInfo?.channel?.id }}
                  label="rent-information"
                  onVerified={(otp) => {
                    setFieldValue("is_verify_email", true);
                    setFieldValue("otp_email_id", otp?.id);
                  }}
                  rentId={rentalInfo?.information?.id}
                />
              }
            />
          </StyledFormInput>
          <InputText
            label={t("representativeFirstname")}
            value={values.first_name}
            onChange={(e) =>
              setFieldValue("first_name", e?.currentTarget?.value)
            }
            error={errors?.first_name as string}
            className="mb-3"
          />
          <InputText
            label={t("representativeLastname")}
            value={values.last_name}
            onChange={(e) =>
              setFieldValue("last_name", e?.currentTarget?.value)
            }
            error={errors?.last_name as string}
            className="mb-3"
          />
          <InputText
            label={t("representativePhone")}
            value={values.phone}
            onChange={(e) => {
              setFieldValue("phone", e?.currentTarget?.value);
            }}
            error={errors?.phone as string}
            className="mb-3"
          />
          <InputText
            label={t("representativeEmail")}
            value={values.email}
            onChange={(e) => setFieldValue("email", e?.currentTarget?.value)}
            error={errors?.email as string}
            className="mb-3"
          />
        </>
      )}
    </Drawer>
  );
};

const StyledInformationBlock = styled.div`
  .information-row {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
`;

const StyledFormInput = styled.div`
  .d-input-text__suffix-container {
    background: #fff !important;
    border: none !important;
  }
`;

export default CustomerInformation;
