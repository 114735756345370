export const PRODUCTION_PRINTING_SEARCH_TYPE_DEFAULT = "nav_rp";
export const PRODUCTION_PRINTING_SEARCH_OPTIONS = [
  {
    id: "nav_rp",
    label: "navRPNo",
  },
  {
    id: "sku",
    label: "sku",
  },
  {
    id: "product_name",
    label: "productName",
  },
  {
    id: "nav_so",
    label: "navSONo",
  },
  {
    id: "so_no",
    label: "soNo",
  },
];
