import { API } from "@network";
import {
  API_ADD_TRANSFER_STOCK_COMMENT,
  API_CREATE_TRANSFER_STOCK,
  API_DETAIL_TRANSFER_STOCK,
  API_EDIT_TRANSFER_STOCK,
  API_RECEIVE_TRANSFER_STOCK,
  API_REQUEST_STOCK_SUMMARY,
  API_TRANSFER_STOCK_ACTIVITY,
  API_TRANSFER_STOCK_COMPLETE_RECEIVE_STOCK_STORE,
  API_TRANSFER_STOCK_CREATE_DELIVERY,
  API_TRANSFER_STOCK_DELETE_DELIVERY,
  API_TRANSFER_STOCK_EDIT_DELIVERY,
  API_TRANSFER_STOCK_LIST,
  API_TRANSFER_STOCK_LIST_REQUEST,
  API_TRANSFER_STOCK_LIST_VEHICLE,
} from "@network/URL";

export const createTransferStock = (body: any) => {
  return API.post(API_CREATE_TRANSFER_STOCK, body);
};

export const editTransferStock = (body: any) => {
  return API.post(API_EDIT_TRANSFER_STOCK, body);
};

export const getTransferStockList = (
  body: any,
  paging = { pageIndex: 1, pageSize: 100 }
) => {
  return API.post(
    `${API_TRANSFER_STOCK_LIST}?page=${paging.pageIndex}&per_page=${paging.pageSize}`,
    body
  );
};

export const transferStockDetail = (id: string) => {
  return API.post(API_DETAIL_TRANSFER_STOCK, { transferid: id });
};

export const getTransferStockSummary = () => {
  return API.post(API_REQUEST_STOCK_SUMMARY);
};

export const createDeliveryTransferStock = (body: any) => {
  return API.post(API_TRANSFER_STOCK_CREATE_DELIVERY, body);
};

export const updateDeliveryTransferStock = (body: any) => {
  return API.post(API_TRANSFER_STOCK_EDIT_DELIVERY, body);
};

export const getTransferStockRequestList = (body: {
  search: string;
  channelId: string;
  warehouseId: string;
  stockEventId: string;
}) => {
  return API.post(API_TRANSFER_STOCK_LIST_REQUEST, {
    warehouse_id: body?.warehouseId,
    search: body.search,
    channel_id: body?.channelId,
    stock_inventory_control_id: body?.stockEventId,
  });
};

export const transferStockListVehicle = (body: any) => {
  return API.post(API_TRANSFER_STOCK_LIST_VEHICLE, body);
};

export const transferStockDeleteDelivery = (id: string) => {
  return API.post(API_TRANSFER_STOCK_DELETE_DELIVERY, { id });
};

export const receiveTransferStock = (body: any) => {
  return API.post(API_RECEIVE_TRANSFER_STOCK, body);
};

export const completeReceiverStock = (body: any) => {
  return API.post(API_TRANSFER_STOCK_COMPLETE_RECEIVE_STOCK_STORE, body);
};

export const getTransferStockActivity = (body: any, currentPage: number) => {
  return API.post(`${API_TRANSFER_STOCK_ACTIVITY}?page=${currentPage}`, body);
};

export const addTransferStockComment = (body: any) => {
  return API.post(API_ADD_TRANSFER_STOCK_COMMENT, body);
};
