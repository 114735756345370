import InputSelectForm from "@common/input/InputSelectForm";
import InputTextForm from "@common/input/InputTextForm";
import ChainSelect from "@components/chain/components/ChainSelect";
import Drawer from "@components/shared/Drawer";
import StoreSelect from "@components/store/components/StoreSelect";
import {
  EVENT_VISIBILITY_STATUSES,
  INVENTORY_EVENT_STATUSES,
} from "@constants/inventory-control-event";
import AppContext from "@helpers/context";
import { updateInventoryControlEvent } from "@network/api/inventory-control-event";
import {
  Button,
  DateInput,
  IRowsKey,
  Notifications,
  Progress,
  TimeUtils,
  ViewLabelStatus,
  ViewRowInterchange,
} from "d-react-components";
import { useFormik } from "formik";
import moment from "moment";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

interface IEventDetailGeneralEditModal {
  defaultValue: any;
  open: boolean;
  onClose: () => void;
  onUpdateSuccess: () => void;
}

const EventDetailGeneralEditModal = ({
  defaultValue,
  open,
  onClose,
  onUpdateSuccess,
}: IEventDetailGeneralEditModal) => {
  const { t } = useTranslation();
  const eventForm = useFormik<any>({
    initialValues: defaultValue ?? ({} as any),
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: schema,
    onSubmit: (values: any) => {
      const { period, id, warehouse, channel, remark, title, visibility } =
        values;
      const body = {
        id,
        title,
        from: period?.[0]?.valueOf(),
        to: period?.[1]?.valueOf(),
        // warehouse_id: warehouse?.id,
        channel_id: channel?.id,
        remark,
        visibility,
      };

      Progress.show(
        { method: updateInventoryControlEvent, params: [body] },
        () => {
          Notifications.showSuccess(t("updateSuccess"));
          onUpdateSuccess();
          onClose();
        }
      );
    },
  });

  const formValues = eventForm?.values;
  const formErrors = eventForm?.errors;

  const classNameInput = "col-span-1";
  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("general")}
      onSave={() => eventForm.handleSubmit()}
    >
      <div className="grid grid-cols-1 gap-4">
        <InputTextForm form={eventForm} keyData="title" />
        {/* <ChainSelect
          value={formValues?.chain}
          className={classNameInput}
          disabled
          onChange={() => {}}
        /> */}

        <StoreSelect
          channelId={defaultValue?.channel?.id}
          className={classNameInput}
          value={eventForm.values.warehouse}
          onChange={(value: any) => {
            eventForm.setFieldValue("warehouse", value);
          }}
          disabled
        />

        {/* <InputSelectForm
          keyData="status"
          form={eventForm}
          className={classNameInput}
          dataSource={INVENTORY_EVENT_STATUSES}
          getLabel={(item: any) => t(item.label)}
          required
        /> */}

        <InputSelectForm
          keyData="visibility"
          form={eventForm}
          className={classNameInput}
          dataSource={EVENT_VISIBILITY_STATUSES}
          getLabel={(item: any) => t(item.label)}
          required
        />
        <DateInput
          isRangePicker
          value={formValues?.period}
          onChange={(value) => eventForm.setFieldValue("period", value)}
          label={t("period")}
          className={classNameInput}
          error={formErrors?.period as any}
          required
          showTime
          format="DD/MM/YYYY HH:mm"
        />
        <InputTextForm
          keyData="remark"
          form={eventForm}
          className={classNameInput}
          multiple
          required
        />
      </div>
    </Drawer>
  );
};

const InventoryEventDetailGeneral = () => {
  const { eventDetail, loadEventDetail } = useContext(AppContext);
  const [openEdit, setOpenEdit] = useState(false);
  const { t } = useTranslation();

  const DETAILS_KEY: IRowsKey<any>[] = useMemo(
    () => [
      {
        id: "title",
        label: t("title"),
      },
      {
        id: "code",
        label: t("code"),
      },
      {
        id: "chain",
        label: t("chain"),
        renderContent: ({ data, item }) => data?.name,
      },
      {
        id: "channel",
        label: t("channel"),
        renderContent: ({ data, item }) => data?.name,
      },
      {
        id: "warehouse",
        label: t("warehouse"),
        renderContent: ({ data, item }) => data?.name,
      },
      {
        id: "visibility",
        label: t("visibility"),
        renderContent: ({ data, item }) =>
          data ? t("enabled") : t("disabled"),
      },
      {
        id: "status",
        label: t("status"),
        renderContent: ({ data, item }) => (
          <div className="d-flex justify-content-end">
            <ViewLabelStatus
              listStatus={INVENTORY_EVENT_STATUSES}
              status={data}
              getLabel={(item) => t(item.label)}
            />
          </div>
        ),
      },

      {
        id: "from",
        label: t("from"),
        renderContent: ({ data, item }) => TimeUtils.toDateTime(data),
      },
      {
        id: "to",
        label: t("to"),
        renderContent: ({ data, item }) => TimeUtils.toDateTime(data),
      },
      {
        id: "remark",
        label: t("remark"),
      },
    ],
    [t]
  );

  return (
    <div className="card-container p-4 ">
      <div className="d-flex justify-content-between align-items-center">
        <label>{t("generalInformation")}</label>
        <Button onClick={() => setOpenEdit(true)} variant="trans">
          {t("edit")}
        </Button>
      </div>

      <ViewRowInterchange
        dataSource={eventDetail}
        keyList={DETAILS_KEY as any}
        variant="border"
        classNameLabel="font-weight-bold "
        classNameContent="text-end "
      />
      {openEdit && (
        <EventDetailGeneralEditModal
          open={openEdit}
          onClose={() => setOpenEdit(false)}
          defaultValue={{
            ...(eventDetail ?? {}),
            period: [moment(eventDetail?.from), moment(eventDetail?.to)],
          }}
          onUpdateSuccess={loadEventDetail}
        />
      )}
    </div>
  );
};

export default InventoryEventDetailGeneral;
