import {
  Button,
  InputText,
  ObjectUtils,
  StringUtils,
} from "d-react-components";
import { map } from "lodash";
import { useTranslation } from "react-i18next";

interface IProductInput {
  productForm: any;
  className?: any;
}

const AliasItem = ({ alias, onRemove, onChange }: any) => (
  <div className="d-flex mt-3">
    <Button
      onClick={() => onRemove(alias)}
      className="text-error"
      variant="trans"
      iconName="delete"
    />
    <InputText
      onChange={(event) => onChange({ ...alias, value: event.target.value })}
      className="w-100 ml-3"
      value={alias?.value}
    />
  </div>
);

const ProductInputSku = ({ productForm, className }: IProductInput) => {
  const { sku, aliasSkuList } = productForm?.values;
  const { t } = useTranslation();

  const onAddNew = () => {
    const newAlias = { id: StringUtils.getUniqueID(), value: "" };
    productForm.setFieldValue("aliasSkuList", [...aliasSkuList, newAlias]);
  };

  const onUpdateAlias = (alias: any) => {
    const newAlias = ObjectUtils.updateArrayById(aliasSkuList, alias);
    productForm.setFieldValue("aliasSkuList", newAlias);
  };

  const onDeleteAlias = (alias: any) => {
    const newAlias = ObjectUtils.removeArrayById(aliasSkuList, alias?.id);

    productForm.setFieldValue("aliasSkuList", newAlias);
  };

  return (
    <div className={className}>
      <InputText
        className="w-100"
        label={t("primarySku")}
        value={sku}
        onChange={(event) =>
          productForm.setFieldValue("sku", event.target.value)
        }
        required
      />

      <label className="mt-3">{t("aliasSKU")}</label>
      {map(aliasSkuList, (alias) => (
        <AliasItem
          alias={alias}
          onChange={onUpdateAlias}
          onRemove={onDeleteAlias}
        />
      ))}
      <div className="my-3 divider" />
      <div className="text-primary cursor-pointer" onClick={onAddNew}>
        {t("addMore")}
      </div>
    </div>
  );
};

export default ProductInputSku;
