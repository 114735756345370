import HeaderDetail from "@common/HeaderDetail";
import AppContext from "@helpers/context";
import { Button } from "d-react-components";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

const VehicleDetailHeader = () => {
  const { vehicleDetail } = useContext(AppContext);
  const { name } = vehicleDetail ?? {};
  const { t } = useTranslation();
  return (
    <HeaderDetail
      title={name}
      rightSide={
        <div className="d-flex">
          <Button onClick={() => {}} className="mr-3">
            {t("print")}
          </Button>
        </div>
      }
    />
  );
};

export default VehicleDetailHeader;
