import { Path } from "@components/layout/Path";
import { POSITION_LABELS } from "@constants/user";
import AppContext from "@helpers/context";
import { listProduct } from "@network/api/product";
import RoleAPI from "@network/api/role";
import { getWarehouseList } from "@network/api/store";
import UserAPI from "@network/api/user";
import { Dropdown } from "antd";
import {
  AwesomeTableComponent,
  Button,
  HeaderTable,
  IColumnsProps,
  Select,
  StringUtils,
  TimeUtils,
  ViewLabelStatus,
} from "d-react-components";
import { debounce, find, map } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import { JsonParam, StringParam, useQueryParam } from "use-query-params";
import ProductName from "../components/ProductName";
import ProductTableFilterDrawer from "./ProductTableFilterDrawer";
import ProductTableExportDrawer from "./ProductTableExportDrawer";
import ProductTableImportDrawer from "./ProductTableImportDrawer";
import {
  PRODUCT_SEARCH_OPTIONS,
  PRODUCT_SEARCH_TYPE_DEFAULT,
} from "@constants/product";

const ProductTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openFilter, setOpenFilter] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [openBulkPriceUpdate, setOpenBulkPriceUpdate] = useState(false);
  const [warehouseList, setWarehouseList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  // const [filterBody, setFilterBody] = useState({});
  const [filterBody = {}, setFilterBody] = useQueryParam(
    "filterBody",
    JsonParam
  );

  const [searchType = PRODUCT_SEARCH_TYPE_DEFAULT, setSearchType] =
    useQueryParam("searchType", StringParam);

  const tableRef = useRef<any>();
  const searchRef = useRef<any>();

  useEffect(() => {
    loadWarehouse();
    loadRoleList();
  }, []);

  const isFiltered = useMemo(
    () => Object.keys(filterBody).length > 0,
    [filterBody]
  );

  const loadWarehouse = () => {
    getWarehouseList().then((res) => {
      const warehouseList = res?.data?.data?.warehouse;
      setWarehouseList(warehouseList);
    });
  };

  const loadRoleList = () => {
    RoleAPI.list().then((res) => {
      const roleList = res?.data?.data?.roles ?? [];
      setRoleList(roleList);
    });
  };

  const actions = useMemo(() => {
    return [
      {
        key: "edit",
        label: t("edit"),
      },
    ];
  }, [t]);

  const onHandleAction = (item: any, product: any) => {
    if (item.key === "edit") {
      navigate(generatePath(Path.PRODUCT_DETAIL, { productId: product.id }));
    }
  };

  const columns: IColumnsProps = [
    {
      title: t("name"),
      dataIndex: "",
      render: (product) => <ProductName item={{ product }} />,
    },

    {
      title: t("visibility"),
      dataIndex: "visibility",
      render: (visibility) => (
        <ViewLabelStatus
          content={visibility ? t("enabled") : t("disabled")}
          color={visibility ? "#33B950" : "#A39D9D"}
        />
      ),
    },
    {
      title: t("regularPrice"),
      dataIndex: "regular_price",
      render: StringUtils.moneyThaiFormat,
    },
    {
      title: t("defaultSalePrice"),
      dataIndex: "sale_price",
      render: StringUtils.moneyThaiFormat,
    },
    {
      title: t("stock"),
      dataIndex: "total_stock",
    },
    {
      title: t("brand"),
      dataIndex: "brand",
      render: (brand) => brand?.name,
    },
    {
      title: t("category"),
      dataIndex: "category",
      render: (category) => category?.name,
    },

    {
      title: t("model"),
      dataIndex: "model",
      render: (model) => model?.name ?? "N/A",
    },
    {
      title: t("action"),
      dataIndex: "",
      render: (item, product) => (
        <Dropdown
          trigger={["click"]}
          menu={{ items: actions, onClick: (e) => onHandleAction(e, product) }}
        >
          <Button
            variant="trans"
            content={""}
            suffixIcon="more_vert"
            size="small"
            className="ml-2"
          />
        </Dropdown>
      ),
    },
  ];

  const source = (paging: any) => {
    let sendData: any = { search: searchRef.current, search_type: searchType };
    for (const key in filterBody) {
      if (key === "brand" || key === "category") {
        const idarray = map(filterBody[key], (item: any) => item.id);
        sendData[key] = idarray;
      } else {
        sendData[key] = filterBody[key];
      }
    }
    return listProduct(sendData, paging);
  };

  const transformer = (res: any) => {
    return res?.data?.data?.product ?? [];
  };

  const onChangeSearch = debounce((text) => {
    searchRef.current = text;
    tableRef?.current?.refresh();
  }, 1000);

  const onFilter = (values: any) => {
    setFilterBody(values);
    tableRef?.current?.refresh();
  };

  return (
    <AppContext.Provider value={{ warehouseList, roleList }}>
      <div className="p-4 ">
        <HeaderTable
          label={t("allProducts")}
          onChangeText={(event: any) => onChangeSearch(event.target.value)}
          onClickNew={() => navigate(Path.PRODUCT_CREATE)}
          onClickFilter={() => setOpenFilter(true)}
          onClickExport={() => setOpenExport(true)}
          isFiltered={isFiltered}
          customButtons={() => (
            <Button onClick={() => setOpenBulkPriceUpdate(true)}>
              {t("bulkPriceUpdate")}
            </Button>
          )}
          customView={() => (
            <Select
              value={searchType}
              dataSource={map(PRODUCT_SEARCH_OPTIONS, (item) => ({
                ...item,
                label: t(item.label),
              }))}
              onChange={(valueId) => setSearchType(valueId)}
              getLabel={(item) => item.label}
              classNameSelect="height-[37px]"
              placeholder={t("pleaseSelect")}
            />
          )}
        />
        <AwesomeTableComponent
          ref={(ref) => {
            tableRef.current = ref;
          }}
          columns={columns}
          source={source}
          transformer={transformer}
          className="mt-3"
        />
        {openFilter && (
          <ProductTableFilterDrawer
            open={openFilter}
            onClose={() => setOpenFilter(false)}
            onFilter={onFilter}
            defaultFilter={filterBody}
          />
        )}
        {openExport && (
          <ProductTableExportDrawer
            open={openExport}
            onClose={() => setOpenExport(false)}
          />
        )}
        {openBulkPriceUpdate && (
          <ProductTableImportDrawer
            open={openBulkPriceUpdate}
            onClose={() => setOpenBulkPriceUpdate(false)}
          />
        )}
      </div>
    </AppContext.Provider>
  );
};

export default ProductTable;
