import { API } from "@network";
import {
  API_HISTORY_INVENTORY,
  API_INVENTORY_HOLD_STOCK,
  API_INVENTORY_MEMO_ADD,
  API_INVENTORY_MEMO_LIST,
  API_LIST_INVENTORY,
  API_SEARCH_STOCK_IN_EVENT,
  API_UPDATE_STOCK_INVENTORY,
  API_VALIDATE_IMPORT_INVENTORY,
} from "@network/URL";

export const historyInventory = (body: any, paging: any) => {
  return API.post(
    `${API_HISTORY_INVENTORY}?page=${paging.pageIndex}&per_page=${paging.pageSize}`,
    body
  );
};

export const inventoryHistoryMemoList = (body: any) => {
  return API.post(API_INVENTORY_MEMO_LIST, body);
};

export const addInventoryHistory = (body: any) => {
  return API.post(API_INVENTORY_MEMO_ADD, body);
};

export const getInventoryList = (body: any, paging: any) => {
  return API.post(
    `${API_LIST_INVENTORY}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
    body
  );
};

export const getInventoryHoldStockList = (body: any, paging: any) => {
  return API.post(
    `${API_INVENTORY_HOLD_STOCK}?page=${paging.pageIndex}&per_page=${paging.pageSize}`,
    body
  );
};

export const validateImportInventory = (body: any) => {
  return API.post(API_VALIDATE_IMPORT_INVENTORY, body);
};

export const updateInventory = (body: any) => {
  return API.post(API_UPDATE_STOCK_INVENTORY, body);
};

export const searchStockInEvent = (body: any) => {
  return API.post(API_SEARCH_STOCK_IN_EVENT, body);
};
