import InputTextForm from "@common/input/InputTextForm";
import GPEventSelect from "@components/gpevent/compoents/GPEventSelect";
import Drawer from "@components/shared/Drawer";
import AppContext from "@helpers/context";
import {
  removeInventoryControlEventGP,
  updateInventoryControlEventGP,
} from "@network/api/inventory-control-event";
import {
  AwesomeTableComponent,
  Button,
  DialogManager,
  IColumnsProps,
  Notifications,
  Progress,
  TimeUtils,
} from "d-react-components";
import { useFormik } from "formik";
import { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

interface IGPEventUpdateModal {
  open: boolean;
  onClose: () => void;
}

const GPEventUpdateModal = ({ open, onClose }: IGPEventUpdateModal) => {
  const { t } = useTranslation();
  const { eventDetail, loadEventDetail } = useContext(AppContext);

  const eventForm = useFormik<any>({
    initialValues: { chain: eventDetail?.chain?.name } as any,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: schema,
    onSubmit: (values: any) => {
      const body = {
        event_id: values?.gpEvent?.id,
        stock_inventory_control_id: eventDetail?.id,
      };
      Progress.show(
        { method: updateInventoryControlEventGP, params: [body] },
        (res: any) => {
          loadEventDetail();
          Notifications.showSuccess(t("updateSuccess"));
          onClose();
        }
      );
    },
  });
  const formValues = eventForm?.values;
  const formErrors = eventForm?.errors;

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("gpEvent")}
      onSave={() => eventForm.handleSubmit()}
    >
      <div className="grid grid-cols-1">
        <InputTextForm keyData="chain" form={eventForm} disabled />
        <GPEventSelect
          value={formValues?.gpEvent}
          onChange={(value) => eventForm.setFieldValue("gpEvent", value)}
          className="mt-3"
          chainIds={[eventDetail?.chain?.id]}
        />
      </div>
    </Drawer>
  );
};

const InventoryEventDetailGP = () => {
  const { eventId } = useParams();
  const tableRef = useRef<any>();
  const { t } = useTranslation();
  const { eventDetail, loadEventDetail } = useContext(AppContext);

  const [openUpdate, setOpenUpdate] = useState(false);

  const columns: IColumnsProps = [
    {
      title: t("name"),
      dataIndex: "name",
    },
    {
      title: t("code"),
      dataIndex: "code",
    },
    {
      title: t("createdAt"),
      dataIndex: "createdAt",
      render: TimeUtils.toDateTime,
    },
    {
      title: t("action"),
      dataIndex: "",
      render: (gpEvent: any) => (
        <Button
          variant="trans"
          className=""
          iconName="delete"
          onClick={() => onClickRemoveGPEvent(gpEvent)}
        />
      ),
    },
  ];

  const onClickRemoveGPEvent = (gpEvent: any) => {
    DialogManager.showConfirm(
      t("confirm"),
      t("areYouSureRemoveThisItem"),
      () => {
        Progress.show(
          { method: removeInventoryControlEventGP, params: [eventId] },
          (res: any) => {
            Notifications.showSuccess(t("removeSuccessfully"));
            tableRef.current.refresh();
            loadEventDetail();
          }
        );
      }
    );
  };
  console.log(
    "eventDetail?.chainDiscountManualEvent",
    eventDetail?.chainDiscountManualEvent
  );
  return (
    <div className="card-container p-3">
      <div className="flex-row-between-center">
        <label>{t("gpEvent")}</label>
        <Button
          onClick={() => setOpenUpdate(true)}
          className=""
          variant="trans"
        >
          {t("update")}
        </Button>
      </div>
      <AwesomeTableComponent
        ref={(ref) => {
          tableRef.current = ref;
        }}
        columns={columns}
        dataSource={
          eventDetail?.chainDiscountManualEvent
            ? [eventDetail?.chainDiscountManualEvent]
            : []
        }
        className=""
      />
      {openUpdate && (
        <GPEventUpdateModal
          open={openUpdate}
          onClose={() => setOpenUpdate(false)}
        />
      )}
    </div>
  );
};

export default InventoryEventDetailGP;
