import { TranslatedSelect } from "@common/TranslatedSelect";
import ChannelSelect from "@components/channel/components/ChannelSelect";
import { PROMOTION_PUBLISH_STATUS } from "@constants/promotion";
import AppContext from "@helpers/context";
import { DateInput, InputText, Select } from "d-react-components";
import moment from "moment";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

const PromotionCreateGeneralInformation = () => {
  const { t } = useTranslation();
  const { promotionForm } = useContext(AppContext);
  const { values, errors, setFieldValue } = promotionForm;

  return (
    <div className="bg-white p-3">
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <div className="col-span-2">
          <ChannelSelect
            value={values.channel}
            onChange={(value) => setFieldValue("channel", value)}
            className="mb-3"
            multiple
            error={errors.channel}
          />
        </div>
        <div className="col-span-1">
          <DateInput
            label={t("startDate")}
            value={values.start}
            onChange={(value) => setFieldValue("start", value)}
            className="mb-3"
            error={errors.start}
            showTime
            format="DD/MM/YYYY HH:mm"
            disabledDate={(d) => d.isBefore(moment().startOf("d"))}
          />
        </div>
        <div className="col-span-1">
          <DateInput
            label={t("endDate")}
            value={values.end}
            onChange={(value) => setFieldValue("end", value)}
            className="mb-3"
            error={errors.end}
            showTime
            format="DD/MM/YYYY HH:mm"
            disabledDate={(d) => d.isBefore(values.start)}
          />
        </div>
        <div className="col-span-2">
          <InputText
            label={t("title")}
            value={values.title}
            onChange={(e) => setFieldValue("title", e.target.value)}
            className="mb-3"
            placeholder={t("pleaseInput")}
            error={errors.title}
          />
        </div>
        <div className="col-span-2">
          <InputText
            label={t("memo")}
            multiple
            value={values.memo}
            onChange={(e) => setFieldValue("memo", e.target.value)}
            className="mb-3"
            placeholder={t("pleaseInput")}
            error={errors.memo}
          />
        </div>
      </div>
    </div>
  );
};

export default PromotionCreateGeneralInformation;
