import NavLogButton from "@components/order/components/delivery/NavLogButton";
import AppContext from "@helpers/context";
import { Badge, Button, TimeUtils } from "d-react-components";
import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const OrderNavItem = ({ navItem }: any) => {
  const { t } = useTranslation();
  return (
    <div className="orderNavItemContainer mb-3">
      <span className="block">
        {t("navSoNo")}
        <span className="text-primary">{navItem?.ref}</span>
      </span>
      <span className="block mt-2">
        {`${t("syncedOn")} ${TimeUtils.convertMiliToDateTime(
          navItem?.created
        )}`}
      </span>
    </div>
  );
};
const OrderNav = () => {
  const { nav = [], order } = useContext(AppContext);
  const [showAllNav, setShowAllNav] = useState(false);
  const { t } = useTranslation();

  const renderLogView = () => {
    if (!order || order?.nav === null) {
      return <div />;
    }
    if (order?.nav) {
      return <NavLogButton />;
    }
    if (!order?.nav) {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <NavLogButton />
          <span className="subTitle1 errorLogText text-red-500">
            {t("errorDetectedViewLog")}
          </span>
        </div>
      );
    }
    return <div />;
  };

  const itemToDisplay = useMemo(() => {
    if (showAllNav) {
      return nav;
    }
    if (!nav?.length) {
      return [];
    }
    return [nav?.[0] ?? {}];
  }, [showAllNav, nav]);

  return (
    <div className="bg-white p-3 mb-3 shadow-sm">
      <label className="block text-sm">{t("nav")}</label>
      {itemToDisplay?.map((navItem: any, index: string) => (
        <OrderNavItem navItem={navItem} key={index} />
      ))}

      {nav.length === 0 && <span>{t("thereIsNoNav")}</span>}
      {renderLogView()}
      {nav.length > 1 && (
        <Button
          onClick={() => setShowAllNav(!showAllNav)}
          id="buttonTrack"
          color="primary"
          className="w-100 mt-3"
        >
          <span className="text-white whitespace-nowrap text-ellipsis overflow-hidden inline-block flex items-center">
            {showAllNav ? t("hide") : t("allNAVSo").toLocaleUpperCase()}
            <Badge
              index={nav.length}
              variant="index"
              size="x-large"
              color="red"
              className="ml-2"
            />
          </span>
        </Button>
      )}
    </div>
  );
};

export default OrderNav;
