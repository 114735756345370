export interface Channel {
  id: string;
  name: string;
}
export interface ICustomer {
  address: string;
  avatar?: string;
  birthday: string;
  channel: Channel;
  company: string;
  created: number;
  customer_type: string;
  customerid: string;
  email: string;
  firstname: string;
  gender: string;
  id: string;
  is_verify_email: boolean;
  is_verify_phone: boolean;
  lastname: string;
  nickname: string;
  note: string;
  phone: string;
  postcode: string;
  province: string;
  ref_code: string;
  ref_id: string;
}

export enum CustomerType {
  INDIVIDUAL = "individual",
  BUSINESS = "business",
}

export interface VerifyData {
  isVerified?: boolean;
  type: "phone" | "email";
  value?: string;
  payload?: any;
  onVerified?: (otp: any) => void;
  userId?: string;
  rentId?: string;
  className?: string;
  label?: "profile" | "rent-information";
}
