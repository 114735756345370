import { CONDITION_RULE_COMPARE } from "@constants/cart-rule";
import AppContext from "@helpers/context";
import { Icon, InputText, ObjectUtils, Select } from "d-react-components";
import { map } from "lodash";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

const CONDITION_TYPE = ObjectUtils.mapObjectToArray(CONDITION_RULE_COMPARE);

const SubtotalCondition = ({
  payload,
  conditionList,
  setChangeCondition,
  indexText,
  handleDeleteCondition,
}: any) => {
  const { t } = useTranslation();
  const { cartRuleConditionDisabled } = useContext(AppContext);

  const { label, condition } = payload;

  //*****************************************CONTROL_UI************************************************** */

  const handleChangeRuleCondition = (changedPayload: any) => {
    const cloneList = map(conditionList, (item) => {
      if (item.id === changedPayload.id) {
        return changedPayload;
      }
      return item;
    });
    setChangeCondition(cloneList);
  };

  const handleChangeValue = (value: any) => {
    const clone = { ...payload, [condition]: value };
    const cloneList = map(conditionList, (item) => {
      if (item?.id === payload?.id) {
        return clone;
      }
      return item;
    });
    setChangeCondition(cloneList);
  };

  //*****************************************RENDER_UI************************************************** */

  return (
    <div className="mt-2 border border-primary">
      <div className="flex-row-between-center bg-primary px-2">
        <div className="flex-row align-items-center py-1">
          <text className="font-weight-bold text-white">
            {`${t("condition")} ${indexText}: `}
          </text>
          <div className="ml-1 text-white">{`${`${t("cart")} ${t(
            condition
          )}`}`}</div>
          <Select
            className="ml-2 cart-rule__condition-select"
            dataSource={CONDITION_TYPE}
            getLabel={(option) => t(option.label)}
            variant="standard"
            disabled={cartRuleConditionDisabled}
            value={payload?.rule}
            onChange={(value) => {
              handleChangeRuleCondition({
                ...payload,
                rule: value,
              });
            }}
          />
          <InputText
            className="cart-rule__condition-input-text"
            size={10}
            value={payload?.[condition]?.toLocaleString()}
            onChange={(event) => {
              const result = event.target.value.replace(/\D/g, "");
              let value = parseInt(result);
              if (!value) {
                value = 0;
              }
              handleChangeValue(value);
            }}
            disabled={cartRuleConditionDisabled}
          />
        </div>
        {!cartRuleConditionDisabled && (
          <Icon
            name="remove_circle"
            color="white"
            onClick={handleDeleteCondition}
          />
        )}
      </div>
    </div>
  );
};

export default SubtotalCondition;
