import InputSelectForm from "@common/input/InputSelectForm";
import InputTextForm from "@common/input/InputTextForm";
import {
  CHANNEL_CONFIG_CREATED_ORDER_EMAIL,
  CHANNEL_REPORT_SOURCES,
} from "@constants/channel";
import classNames from "classnames";
import { InputColor } from "d-react-components";
import { useTranslation } from "react-i18next";

const ChannelFormSetting = ({ form, fieldClassName = "" }: any) => {
  const { t } = useTranslation();

  const classNameField = classNames("col-12 mt-3", fieldClassName);

  console.log("form?.formValues?.color", form?.formValues?.color);
  return (
    <div className="row">
      <InputTextForm
        keyData="domainShareOrder"
        form={form}
        className={classNameField}
      />
      <InputColor
        label={t("color")}
        value={form?.formValues?.color}
        className={classNameField}
        onChange={(event) => form.setFieldValue("color", event.target.value)}
      />
      <InputSelectForm
        keyData="isSendEmailCreatedOrder"
        form={form}
        className={classNameField}
        dataSource={CHANNEL_CONFIG_CREATED_ORDER_EMAIL}
      />
      <InputSelectForm
        keyData="isSendReportToLine"
        form={form}
        className={classNameField}
        dataSource={CHANNEL_CONFIG_CREATED_ORDER_EMAIL}
      />
      <InputSelectForm
        keyData="isThisChannelDealer"
        form={form}
        required
        className={classNameField}
        dataSource={CHANNEL_CONFIG_CREATED_ORDER_EMAIL}
      />

      <InputSelectForm
        keyData="channelOrderSource"
        form={form}
        className={classNameField}
        dataSource={CHANNEL_REPORT_SOURCES}
        multiple
      />
    </div>
  );
};

export default ChannelFormSetting;
