import { IStockRequest } from "@interfaces/stock-request";
import { TimeUtils } from "d-react-components";
import { isEmpty, map, reduce } from "lodash";
import { useTranslation } from "react-i18next";
import StockRequestSelect, { IStockRequestSelect } from "./StockRequestSelect";

interface IRequestSolidItem {
  request: IStockRequest;
}

export const StockRequestSolidItem = ({ request }: IRequestSolidItem) => {
  const { ref, store, products, expected_arrival_date } = request;
  const { t } = useTranslation();

  const totalQuantity = reduce(products, (sum, item) => item.quantity + sum, 0);

  return (
    <div className="w-100 mt-3 bg-primary d-flex">
      <div className="p-2 flex-column">
        <span className="block text-white font-weight-bold">{ref}</span>
        <span className="block text-white opacity-75">{`${
          store?.name ?? ""
        }`}</span>
        <span className="block text-white opacity-75">{`${t(
          "expectedDateOfArrival"
        )}: ${TimeUtils.toDate(expected_arrival_date)}`}</span>
        <span className="block text-white opacity-75">{`${t(
          "qtyRequestedItem"
        )}: ${totalQuantity}`}</span>
      </div>
    </div>
  );
};

const StockRequestSolidSelect = ({
  className,
  ...selectProps
}: IStockRequestSelect<IStockRequest>) => {
  const renderRequestSelected = () => {
    if (!selectProps?.value || isEmpty(selectProps?.value)) return <div />;
    if (selectProps?.multiple) {
      return map(selectProps?.value, (request: any) => (
        <StockRequestSolidItem request={request} />
      ));
    }
    return (
      <StockRequestSolidItem request={selectProps?.value as IStockRequest} />
    );
  };

  return (
    <div className={className}>
      <StockRequestSelect {...selectProps} />
      {renderRequestSelected()}
    </div>
  );
};

export default StockRequestSolidSelect;
