import { Switch } from "antd";
import { Button, DateInput, InputText, Modal, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import React from "react";
import moment from "moment";
import UserAPI from "@network/api/user";
import { useTranslation } from "react-i18next";
import Drawer from "@components/shared/Drawer";

function UserDetailSpecialAccessModal({ open, onClose, defaultValue, userId, onSuccess }: any) {
  const { t } = useTranslation();
  const specialAccess = useFormik({
    initialValues: defaultValue ?? {},
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      const body = {
        id: userId,
        status: values?.status,
        code: values?.status ? values?.code : null,
        expired: values?.status ? values?.expired : 0
      };
      Progress.show({ method: UserAPI.updateSpecialAccess, params: [body] }, (res) => {
        Notifications.showSuccess(t("updateUserSuccess"));
        onClose();
        onSuccess();
      });
    },
  });

  const { status, code, expired } = specialAccess?.values;
  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("updateSpecialAccess")}
      onSave={() => specialAccess.handleSubmit()}
      size="large"
    >
      <div className="mb-3 flex-column">
        <label>{t("status")}</label>
        <div>
          <Switch
            checked={status}
            onChange={() => specialAccess.setFieldValue("status", !status)}
          />
        </div>
      </div>
      <div className="mb-3 flex-column" hidden={!status}>
        <div className="d-flex justify-content-between align-items-center">
          <label>{t("specialAccessCode")}</label>
        </div>
        <InputText
          value={code}
          onChange={(event) =>
            specialAccess.setFieldValue("code", event?.target?.value)
          }
        />
      </div>
      <div className="mb-3 flex-column" hidden={!status}>
        <DateInput
          className="mb-3"
          label={t("specialAccessExpiredAt")}
          name="expired"
          value={expired ? moment(expired) : null}
          onChange={(date) => {
            specialAccess.setFieldValue("expired", moment(date).valueOf());
          }}
          showTime
          format="DD/MM/YYYY HH:mm"
        />
      </div>
    </Drawer>
  );
}

export default UserDetailSpecialAccessModal;
