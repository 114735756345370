export enum CallType {
  OUTGOING_CALL = "outgoing",
  INCOMING_CALL = "incoming",
}

export const CALL_TYPES: { [key: string]: any } = {
  [CallType.OUTGOING_CALL]: {
    content: "outgoingCall",
    color: "#84C4FF",
  },
  [CallType.INCOMING_CALL]: {
    content: "incomingCall",
    color: "#27AE60",
  },
};

export const CALL_TYPES_OPTIONS: any[] = [
  {
    label: "outgoingCall",
    value: CallType.OUTGOING_CALL,
  },
  {
    label: "incomingCall",
    value: CallType.INCOMING_CALL,
  },
];

export enum CallStatus {
  ANSWERED = "answered",
  UNANSWERED = "unanswered",
  CANCEL = "cancel",
}

export const CALL_STATUSES: { [key: string]: any } = {
  [CallStatus.ANSWERED]: {
    content: "answered",
    color: "#22C55E",
  },
  [CallStatus.UNANSWERED]: {
    content: "unanswered",
    color: "#8D8D8D",
  },
  [CallStatus.CANCEL]: {
    content: "cancelled",
    color: "#8D8D8D",
  },
};

export const TICKET_STATUS_ARRAY = [
  {
    content: "open",
    value: "open",
    color: "#33B950",
    label: "open",
    id: "open",
  },
  {
    content: "closed",
    value: "closed",
    color: "#C4C4C4",
    label: "closed",
    id: "closed",
  },
  {
    content: "spam",
    value: "spam",
    color: "#C51736",
    label: "spam",
    id: "spam",
  },
];

export const TICKET_SEARCH_TYPE_DEFAULT = "customer_phone";
export const TICKET_SEARCH_OPTIONS = [
  {
    id: "ticket_number",
    label: "ticketNumber",
  },
  {
    id: "customer_name",
    label: "customerName",
  },
  {
    id: "customer_phone",
    label: "customerPhone",
  },
  {
    id: "customer_email",
    label: "customerEmail",
  },
];
