import { API } from "@network";
import {
  API_MY_ASSETS_SUMMARY,
  API_MY_ASSETS_CHANNEL,
  API_MY_ASSETS_WAREHOUSE,
  API_MY_ASSETS_ROLE,
  API_MY_ASSETS_REQUEST_CREATE,
  API_MY_ASSETS_INFO,
  API_MY_ASSETS_REQUEST_LIST,
  API_MY_ASSETS_REQUEST_DETAIL,
  API_MY_ASSETS_CHECK_REQUEST_CHANNEL,
  API_MY_ASSETS_CHECK_REQUEST_WAREHOUSE,
  API_REQUEST_ASSETS_LIST,
  API_REQUEST_ASSETS_CONFIRM,
  API_MY_ASSETS_REQUEST_CANCEL,
  API_REQUEST_ASSETS_DETAIL,
  API_MY_ASSETS_LIST_ACTIVITIES,
  API_MY_ASSETS_ADD_ACTIVITIES,
  API_REQUEST_ASSETS_LIST_ACTIVITIES,
  API_REQUEST_ASSETS_ADD_ACTIVITIES,
  API_REQUEST_ASSETS_SUMMARY,
  API_MY_ASSETS_CHECK_REQUEST_CHANNEL_BY_WAREHOUSE,
} from "@network/URL";

const MyAssetsAPI = {
  info: () => {
    return API.post(`${API_MY_ASSETS_INFO}`);
  },
  summary: () => {
    return API.post(`${API_MY_ASSETS_SUMMARY}`);
  },
  adminSummary: () => {
    return API.post(`${API_REQUEST_ASSETS_SUMMARY}`);
  },
  channel: (paging: any, body: any = {}) => {
    return API.post(
      `${API_MY_ASSETS_CHANNEL}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
      body
    );
  },
  warehouse: (paging: any, body: any = {}) => {
    return API.post(
      `${API_MY_ASSETS_WAREHOUSE}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
      body
    );
  },
  role: (paging: any, body: any = {}) => {
    return API.post(
      `${API_MY_ASSETS_ROLE}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
      body
    );
  },
  createRequest: (body: any = {}) => {
    return API.post(`${API_MY_ASSETS_REQUEST_CREATE}`, body);
  },
  listRequestAccess: (paging: any, body: any = {}) => {
    return API.post(
      `${API_MY_ASSETS_REQUEST_LIST}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
      body
    );
  },
  listRequestApproval: (paging: any, body: any = {}) => {
    return API.post(
      `${API_REQUEST_ASSETS_LIST}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
      body
    );
  },
  getRequestDetail: (body: any = {}) => {
    return API.post(`${API_MY_ASSETS_REQUEST_DETAIL}`, body);
  },
  checkAssetWarehouse: (body: any = {}) => {
    return API.post(`${API_MY_ASSETS_CHECK_REQUEST_WAREHOUSE}`, body);
  },
  checkAssetChannel: (body: any = {}) => {
    return API.post(`${API_MY_ASSETS_CHECK_REQUEST_CHANNEL}`, body);
  },
  checkAssetChannelByWarehouse: (body: any = {}) => {
    return API.post(
      `${API_MY_ASSETS_CHECK_REQUEST_CHANNEL_BY_WAREHOUSE}`,
      body
    );
  },
  approveRequest: (body: any = {}) => {
    return API.post(`${API_REQUEST_ASSETS_CONFIRM}`, body);
  },
  getRequestApprovalDetail: (body: any = {}) => {
    return API.post(`${API_REQUEST_ASSETS_DETAIL}`, body);
  },
  cancelRequest: (body: any = {}) => {
    return API.post(`${API_MY_ASSETS_REQUEST_CANCEL}`, body);
  },
  listMyAssetActivity: (body: any = {}, paging: any) => {
    return API.post(
      `${API_MY_ASSETS_LIST_ACTIVITIES}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
      body
    );
  },
  addMyAssetActivity: (body: any = {}) => {
    return API.post(`${API_MY_ASSETS_ADD_ACTIVITIES}`, body);
  },
  listRequestAssetActivity: (body: any = {}, paging: any) => {
    return API.post(
      `${API_REQUEST_ASSETS_LIST_ACTIVITIES}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
      body
    );
  },
  addRequestAssetActivity: (body: any = {}) => {
    return API.post(`${API_REQUEST_ASSETS_ADD_ACTIVITIES}`, body);
  },
};

export default MyAssetsAPI;
