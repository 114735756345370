import { Path } from "@components/layout/Path";
import UserAvatarName from "@components/shared/UserAvatarName";
import AppContext from "@helpers/context";
import {
  AwesomeTableComponent,
  IColumnsProps,
  TimeUtils,
} from "d-react-components";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";

const StockRequestDetailTransfer = () => {
  const { requestDetail } = useContext(AppContext);
  const { t } = useTranslation();
  const columns: IColumnsProps = [
    {
      title: t("requestId"),
      dataIndex: "transfer_no",
      render: (transferNo, transfer) => (
        <Link
          to={generatePath(Path.TRANSFER_STOCK_DETAIL, {
            transferId: transfer?.id,
          })}
        >
          {transferNo}
        </Link>
      ),
    },
    {
      title: t("createdBy"),
      dataIndex: "by",
      render: (by) => <UserAvatarName user={by} />,
    },
    {
      title: t("createdAt"),
      dataIndex: "created",
      render: TimeUtils.toDateTime,
    },
  ];
  return (
    <AwesomeTableComponent
      columns={columns}
      dataSource={requestDetail?.transfer ?? []}
      pagination={false}
    />
  );
};

export default StockRequestDetailTransfer;
