import InputTextForm from "@common/input/InputTextForm";
import BrandSelect from "@components/brand/components/BrandSelect";
import ProductCategorySelect from "@components/product-category/components/ProductCategorySelect";
import Drawer from "@components/shared/Drawer";
import AppContext from "@helpers/context";
import {
  getInventoryControlEventStocks,
  removeInventoryControlEventStock,
  updateInventoryControlEventStock,
} from "@network/api/inventory-control-event";
import { checkStockRequestProducts } from "@network/api/stock-request";
import classNames from "classnames";
import {
  AwesomeTableComponent,
  Button,
  DialogManager,
  Dropdown,
  IColumnsProps,
  Notifications,
  Progress,
} from "d-react-components";
import { useFormik } from "formik";
import { find, map } from "lodash";
import { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

const EventControlSchema = Yup.object().shape({
  brand: Yup.object().required("This field is required"),
  category: Yup.object().required("This field is required"),
  quantity: Yup.string().required("This field is required"),
});

interface IInventoryEventDetailControlModal {
  eventControl?: any;
  open: boolean;
  onClose: () => void;
  onCrudSuccess: () => void;
}

const InventoryEventDetailControlModal = ({
  eventControl,
  open,
  onClose,
  onCrudSuccess,
}: IInventoryEventDetailControlModal) => {
  const { t } = useTranslation();
  const { eventId } = useParams();

  const isEdit = !!eventControl;

  const controlForm = useFormik<any>({
    initialValues: eventControl ?? ({} as any),
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: EventControlSchema,
    onSubmit: (values: any) => {
      const input = {
        stock_inventory_control_id: eventId,
        category_id: values?.category?.id,
        brand_id: values?.brand?.id,
        quantity: values?.quantity,
      };

      onCrudControlDetail(input);
    },
  });
  const formValues = controlForm?.values;
  const formErrors = controlForm?.errors;

  const onCrudControlDetail = (input: any) => {
    Progress.show(
      { method: updateInventoryControlEventStock, params: [input] },
      (res: any) => {
        onCrudSuccess();
        onClose();
        Notifications.showSuccess(t("updateSuccessfully"));
      }
    );
  };

  const classNameInput = classNames("col-span-1 mt-4");
  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("controlDetail")}
      onSave={() => controlForm.handleSubmit()}
    >
      <div className="grid grid-cols-1 gap4">
        <BrandSelect
          className={classNameInput}
          value={formValues?.brand}
          error={formErrors?.brand}
          onChange={(value) => controlForm.setFieldValue("brand", value)}
          disabled={isEdit}
        />
        <ProductCategorySelect
          error={formErrors?.category}
          className={classNameInput}
          value={formValues?.category}
          onChange={(value) => controlForm.setFieldValue("category", value)}
          disabled={isEdit}
        />
        <InputTextForm
          className={classNameInput}
          label={t("maxQuantity")}
          keyData="quantity"
          form={controlForm}
        />
      </div>
    </Drawer>
  );
};

const InventoryEventDetailControl = () => {
  const { t } = useTranslation();
  const { eventDetail } = useContext(AppContext);
  const [openCrudControl, setOpenCrudControl] = useState<any>({
    open: false,
    eventControl: "",
  });

  const tableRef = useRef<any>();
  const { eventId } = useParams();

  const CONTROL_ACTIONS = [
    { id: "edit", label: t("edit") },
    { id: "remove", label: t("remove") },
  ];

  const columns: IColumnsProps = [
    {
      title: t("category"),
      dataIndex: "category",
      render: (category) => category?.name,
    },
    {
      title: t("brand"),
      dataIndex: "brand",
      render: (brand) => brand?.name,
    },
    {
      title: t("maxQuantity"),
      dataIndex: "quantity",
    },
    {
      title: t("currentWHQuantity"),
      dataIndex: "stock",
    },
    // {
    //   title: t("onHoldQty"),
    //   dataIndex: "onHoldQty",
    // },
    {
      title: t("action"),
      dataIndex: "",
      render: (eventControl) => (
        <Dropdown
          iconName="more_vert"
          dataSource={CONTROL_ACTIONS}
          onClick={(item: any) => onClickAction(item, eventControl)}
        />
      ),
    },
  ];

  const onClickAction = (action: any, eventControl: any) => {
    switch (action.id) {
      case "edit":
        setOpenCrudControl({ open: true, eventControl });
        break;

      case "remove":
        onRemoveEventControl(eventControl);
        break;

      default:
        break;
    }
  };

  const onRemoveEventControl = (eventControl: any) => {
    DialogManager.showConfirm(
      t("confirm"),
      t("areYouSureRemoveThisItem"),
      () => {
        const input = {
          stock_inventory_control_id: eventId,
          category_id: eventControl?.category?.id,
          brand_id: eventControl?.brand?.id,
        };
        Progress.show(
          { method: removeInventoryControlEventStock, params: [input] },
          (res: any) => {
            Notifications.showSuccess(t("removeSuccessfully"));
            tableRef.current.refresh();
          }
        );
      }
    );
  };

  const source = async (paging: any) => {
    const eventStockRes = await getInventoryControlEventStocks(paging, {
      stock_inventory_control_id: eventId,
    });

    const eventStock = eventStockRes?.data?.data?.detail ?? [];

    const requestInfoRes = await checkStockRequestProducts(eventDetail?.id);
    const requestInfoControl = requestInfoRes?.data?.data?.control ?? [];
    const requestQuantity = requestInfoRes?.data?.data?.request ?? [];

    const eventStockMapped = map(eventStock, (item) => {
      const requestInfo = find(
        requestInfoControl,
        (requestedItem) =>
          requestedItem.brand_id === item?.brand?.id &&
          requestedItem.category_id === item?.category?.id
      );
      return { ...item, onHoldQty: requestInfo?.stock ?? 0 };
    });
    return eventStockMapped;
  };

  return (
    <div className="card-container p-3">
      <div className="flex-row-between-center">
        <label>{t("controlDetail")}</label>
        <div className="flex-row">
          <Button
            variant="trans"
            onClick={() => setOpenCrudControl({ open: true })}
            className=""
          >
            {t("add")}
          </Button>
          {/* <Button variant="trans" onClick={() => {}} className="">
            {t("remove")}
          </Button> */}
        </div>
      </div>

      <AwesomeTableComponent
        ref={(ref) => {
          tableRef.current = ref;
        }}
        columns={columns}
        source={source}
        transformer={(res) => res}
        className=""
      />

      {openCrudControl.open && (
        <InventoryEventDetailControlModal
          onCrudSuccess={() => tableRef.current?.refresh()}
          eventControl={openCrudControl.eventControl}
          open={openCrudControl}
          onClose={() => setOpenCrudControl(false)}
        />
      )}
    </div>
  );
};

export default InventoryEventDetailControl;
