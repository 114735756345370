import Icon from "@common/Icon";
import Drawer from "@components/shared/Drawer";
import AppContext from "@helpers/context";
import { ICustomer } from "@interfaces/customer";
import {
  Button,
  DialogManager,
  IRowsKey,
  Notifications,
  Progress,
  TimeUtils,
  ViewRowInterchange,
} from "d-react-components";
import { useFormik } from "formik";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomerInputInfo from "../input/CustomerInputInfo";
import {
  createCustomerShipping,
  searchShippingCustomer,
} from "@network/api/address";
import AddressItemHeader from "../component/AddressItemHeader";
import { getFullAddress } from "@helpers/address";
import { find, isEmpty, map } from "lodash";
import CustomerInputShipping from "../input/CustomerInputShipping";
import { ShippingSchema } from "../create/CustomerCreate";
import {
  removeCustomerShipping,
  setCustomerShippingDefault,
  updateCustomerShipping,
} from "@network/api/customer";
import { TYPE_OF_PLACES } from "@constants/common";

interface ICustomerAddShippingDrawer {
  open: boolean;
  defaultValue?: any;
  onUpdated: any;
  customer: any;
  onClose: () => void;
}

interface IAddressItem {
  address: any;
  index: number;
  showAction?: boolean;
  customer: any;
  onUpdated: any;
}

export const AddressItem = ({
  address,
  index,
  showAction,
  customer,
  onUpdated,
}: IAddressItem) => {
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const { id, phone, nameOfPlace, default: isDefault } = address;
  const { t } = useTranslation();

  const onSetDefaultAddress = () => {
    Progress.show(
      {
        method: setCustomerShippingDefault,
        params: [customer?.id, address?.id],
      },
      (res: any) => {
        Notifications.showSuccess(t("updateCustomerAddressSuccess"));
        onUpdated();
      }
    );
  };

  const onRemoveAddress = () => {
    DialogManager.showConfirm(t("confirm"), t("areYouSureWantRemove"), () =>
      Progress.show(
        {
          method: removeCustomerShipping,
          params: [customer?.id, address?.id],
        },
        (res: any) => {
          Notifications.showSuccess(t("removeSuccess"));
          onUpdated();
        }
      )
    );
  };

  return (
    <div className="mt-3">
      <AddressItemHeader
        isDefault={isDefault}
        label={`${t("address")} ${index}`}
        onClickEdit={() => setOpenEdit(true)}
        onClickDelete={onRemoveAddress}
        onClickSetDefault={onSetDefaultAddress}
        showAction={showAction}
      />
      <div className="flex-column">
        <text>{`${address?.firstname} ${address?.lastname}`}</text>
        <text>{phone}</text>
        <text>{getFullAddress(address)}</text>
        <text>{nameOfPlace}</text>
      </div>
      {openEdit && (
        <CustomerEditShippingDrawer
          open={openEdit}
          onClose={() => setOpenEdit(false)}
          defaultValue={address}
          onUpdated={onUpdated}
          customer={customer}
        />
      )}
    </div>
  );
};

const CustomerEditShippingDrawer = ({
  open,
  customer,
  onUpdated,
  onClose,
  defaultValue,
}: ICustomerAddShippingDrawer) => {
  const { t } = useTranslation();
  const customerForm = useFormik<any>({
    initialValues: {
      ...defaultValue,
      district: defaultValue?.city,
      subDistrict: defaultValue?.subdistrict,
      postCode: defaultValue?.postcode,
      typeOfPlace: defaultValue?.place,
    } as any,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: ShippingSchema,
    onSubmit: (values: any) => {
      onUpdateShipping(values);
    },
  });
  const formValues = customerForm?.values;
  const formErrors = customerForm?.errors;

  const onUpdateShipping = (values: any) => {
    const {
      firstname,
      lastname,
      nickname,
      province,
      district,
      postCode,
      address,
      subDistrict,
      phone,
      typeOfPlace,
    } = values;
    const bodyShipping = {
      shippingid: defaultValue?.id,
      default: defaultValue.default,

      firstname,
      lastname,
      nickname: isEmpty(nickname) ? firstname : nickname,
      phone,
      province: province?.id ?? "",
      city: district?.id ?? "",
      subdistrict: subDistrict?.id ?? "",
      postcode: postCode,
      address,
      customerid: customer?.id,
      place: typeOfPlace,
    };

    Progress.show(
      { method: updateCustomerShipping, params: [bodyShipping] },
      (res: any) => {
        onClose();
        Notifications.showSuccess(t("addShippingSuccess"));
        onUpdated();
      }
    );
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("addShipping")}
      size="large"
      onSave={() => {
        customerForm.handleSubmit();
      }}
    >
      <div>
        <CustomerInputShipping customerForm={customerForm} />
      </div>
    </Drawer>
  );
};

const CustomerAddShippingDrawer = ({
  open,
  customer,
  onUpdated,
  onClose,
}: ICustomerAddShippingDrawer) => {
  const { t } = useTranslation();
  const customerForm = useFormik<any>({
    initialValues: {} as any,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: ShippingSchema,
    onSubmit: (values: any) => {
      onCreateShipping(values);
    },
  });
  const formValues = customerForm?.values;
  const formErrors = customerForm?.errors;

  const onCreateShipping = (values: any) => {
    const {
      firstname,
      lastname,
      nickname,
      province,
      district,
      postCode,
      address,
      subDistrict,
      phone,
      typeOfPlace,
    } = values;
    const bodyShipping = {
      firstname,
      lastname,
      nickname: isEmpty(nickname) ? firstname : nickname,
      phone,
      province: province?.id ?? "",
      city: district?.id ?? "",
      subdistrict: subDistrict?.id ?? "",
      postcode: postCode,
      address,
      customerid: customer?.id,
      default: true,
      place: typeOfPlace,
    };

    Progress.show(
      { method: createCustomerShipping, params: [bodyShipping] },
      (res: any) => {
        onClose();
        Notifications.showSuccess(t("addShippingSuccess"));
        onUpdated();
      }
    );
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("addShipping")}
      size="large"
      onSave={() => {
        customerForm.handleSubmit();
      }}
    >
      <div>
        <CustomerInputShipping customerForm={customerForm} />
      </div>
    </Drawer>
  );
};

const CustomerDetailShipping = () => {
  const { customerDetail, loadCustomerDetail } = useContext(AppContext);
  const [openAdd, setOpenAdd] = useState(false);
  const [shippingList, setShippingList] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    loadShippingList();
  }, []);

  const loadShippingList = async () => {
    const res = await searchShippingCustomer(customerDetail?.id);
    const shippingUserList = res?.data?.data?.shipping;
    setShippingList(shippingUserList);
  };

  return (
    <div className="mt-3 border p-4 ">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h5>{t("shipping")}</h5>

        <Button onClick={() => setOpenAdd(true)} className="" iconName="add">
          {t("add")}
        </Button>
      </div>
      {map(shippingList, (shipping, index) => (
        <AddressItem
          address={shipping}
          index={index + 1}
          customer={customerDetail}
          onUpdated={loadShippingList}
        />
      ))}
      {openAdd && (
        <CustomerAddShippingDrawer
          open={openAdd}
          onClose={() => setOpenAdd(false)}
          customer={customerDetail}
          onUpdated={loadShippingList}
        />
      )}
    </div>
  );
};

export default CustomerDetailShipping;
