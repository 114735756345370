import { InputText, Notifications, Progress } from "d-react-components";
import * as Yup from "yup";
import { useFormik } from "formik";
import InputSelectForm from "@common/input/InputSelectForm";
import React from "react";
import { WAREHOUSE_USER_KPI_TYPES } from "@constants/warehouse";
import WarehouseAPI from "@network/api/warehouse";
import { useTranslation } from "react-i18next";
import Drawer from "@components/shared/Drawer";
import InputTextForm from "@common/input/InputTextForm";
import InputCurrency from "@common/input/InputCurrency";

export const WarehouseKPISchema = Yup.object().shape({
  type: Yup.string().nullable().required("Required field!"),
  target: Yup.string()
    .nullable()
    .when("type", {
      is: "kpi_by_store",
      then: Yup.string().nullable().required("Required field!"),
    }),
});

function WarehouseDetailUserKPIUpdateDrawer({
  open,
  onClose,
  warehouseId,
  defaultValue,
  onUpdateSuccess,
}: any) {
  const { t } = useTranslation();

  const kpiForm = useFormik({
    initialValues: defaultValue
      ? {
          type: defaultValue.kpi_type,
          target: defaultValue.kpi_target,
          totalSalespersons: defaultValue.kpi_total_salespersons,
        }
      : {},
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: WarehouseKPISchema,
    onSubmit: (values) => {
      const formValue = {
        kpi_type: values.type,
        kpi_target: Number(values.target),
      };
      const body = { id: warehouseId, ...formValue };
      Progress.show(
        { method: WarehouseAPI.updateKpiWarehouse, params: [body] },
        () => {
          Notifications.showSuccess(t("updateWarehouseSuccess"));
          onUpdateSuccess();
          onClose();
        }
      );
    },
  });

  const { values, setFieldValue } = kpiForm;

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("defaultUserKpi")}
      onSave={() => kpiForm.handleSubmit()}
      size="medium"
    >
      <div className="row">
        <InputSelectForm
          form={kpiForm}
          keyData="type"
          dataSource={WAREHOUSE_USER_KPI_TYPES}
          className="col-12 ml-auto"
        />
        {values.type === "kpi_by_store" && (
          <>
            <InputCurrency
              keyData="target"
              form={kpiForm}
              className="col-12 ml-auto mt-3"
              label={t("storeTargetKPI")}
            />
            <InputText
              disabled
              label={t("totalSalespersons")}
              className="col-12 ml-auto mt-3"
              value={values.totalSalespersons}
            />
            <InputText
              disabled
              label={t("individualTargetKPI")}
              className="col-12 ml-auto mt-3"
            />
          </>
        )}
      </div>
    </Drawer>
  );
}

export default WarehouseDetailUserKPIUpdateDrawer;
