import { Path } from "@components/layout/Path";
import AppContext from "@helpers/context";
import { isGrantURLPermission } from "@helpers/permissions";
import { IChannel } from "@interfaces/channel";
import { API_MY_ASSETS_REQUEST_CREATE } from "@network/URL";
import MyAssetsAPI from "@network/api/my-assets";
import {
  AwesomeTableComponent,
  Button,
  InputTextSearch,
  TimeUtils,
} from "d-react-components";
import { find, uniqBy } from "lodash";
import React, { useContext, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";

const MyAssetsChannel = () => {
  const [searchValue, setSearchValue] = useState();
  // const [listCheckChannel, setListCheckChannel] = useState<any[]>([]);
  const { summary } = useContext(AppContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tableRef = useRef<any>();

  const columns = useMemo(
    () => [
      {
        title: t("name"),
        dataIndex: "name",
      },
      {
        title: t("code"),
        dataIndex: "code",
      },
      // {
      //   title: t("startingDate"),
      //   dataIndex: "start",
      //   width: 120,
      //   render: (start: any, item: any) => {
      //     const currentItem = find(listCheckChannel, {
      //       channel_id: item.id,
      //     });
      //     return currentItem ? TimeUtils.toDate(currentItem?.start) : "";
      //   },
      // },
    ],
    // [t, listCheckChannel],
    [t]
  );

  const source = (paging: any) => {
    const body = {
      search: searchValue,
      action: "in",
    };
    return MyAssetsAPI.channel(paging, body);
    // return MyAssetsAPI.channel(paging, body).then(
    //   (resp) => {
    //     const listChannel = resp?.data?.data?.channels ?? [];
    //     MyAssetsAPI.checkAssetChannel({
    //       channel: listChannel?.map((c: IChannel) => c.id),
    //     }).then((resp) => {
    //       setListCheckChannel(
    //         uniqBy(
    //           [...listCheckChannel, ...(resp?.data?.data?.asset ?? [])],
    //           "id"
    //         )
    //       );
    //     });
    //     return resp;
    //   },
    //   (err) => {
    //     return err;
    //   }
    // );
  };

  const transformer = (res: any) => {
    return res?.data?.data?.channels ?? [];
  };

  const submitSearch = () => {
    tableRef?.current?.refresh();
  };

  return (
    <div className="bg-white p-3">
      <div className="flex items-center mb-3">
        <div className="flex flex-1 items-center">
          <h5 className="">{t("channel")}</h5>
          <span className="ml-1 bg-red-500 text-white min-w-[1rem] h-4 text-xs flex items-center justify-center">
            {summary?.channel}
          </span>
        </div>
        {isGrantURLPermission(API_MY_ASSETS_REQUEST_CREATE) && (
          <Button
            onClick={() =>
              navigate(generatePath(Path.MY_REQUEST_NEW, { type: "channel" }))
            }
          >
            {t("requestChange")}
          </Button>
        )}
      </div>
      <div className="mb-3">
        <InputTextSearch
          className="bg-white"
          placeholder={t("search")}
          value={searchValue}
          onChange={(e: any) => setSearchValue(e?.target?.value)}
          onSubmit={() => submitSearch()}
        />
      </div>
      <AwesomeTableComponent
        ref={(ref) => {
          tableRef.current = ref;
        }}
        transformer={transformer}
        source={source}
        columns={columns}
        className="mt-3"
      />
    </div>
  );
};

export default MyAssetsChannel;
