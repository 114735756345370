import Drawer from "@components/shared/Drawer";
import { getGPEventChannelList } from "@network/api/gp-event";
import { AwesomeTableComponent, InputTextSearch } from "d-react-components";
import { debounce } from "lodash";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

function GPEventDetailChannelUpdateModal({
  open,
  onClose,
  title,
  type,
  eventId,
  onSave,
  multiple,
}: any) {
  const tableRef = useRef<any>();
  const textSearch = useRef();
  const [channelSelecting, setChannelSelecting] = useState<any>([]);
  const { t } = useTranslation();

  const columns = [
    {
      title: t("name"),
      dataIndex: "name",
      render: (name: string, channel: any) => (
        <a href={channel?.editurl} target="_blank" rel="noreferrer">
          {name}
        </a>
      ),
    },
    {
      title: t("code"),
      dataIndex: "code",
    },
  ];

  const onChangeText = debounce((text) => {
    textSearch.current = text;
    tableRef.current.refresh();
  }, 500);

  const source = (paging: any) => {
    const body = { id: eventId, action: type, search: textSearch.current };
    return getGPEventChannelList(body, paging);
  };

  const transformer = (res: any) => {
    return res?.data?.data?.channels ?? [];
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={title}
      onSave={() => onSave(channelSelecting)}
      size="auto"
      width={"80%"}
    >
      <div>
        <InputTextSearch
          onChange={(event: any) => onChangeText(event.target.value)}
        />
        <AwesomeTableComponent
          ref={(ref) => {
            tableRef.current = ref;
          }}
          columns={columns}
          source={source}
          transformer={transformer}
          className="mt-3"
          rowSelection={{
            onChange: (selectedRowKeys, selectedRows) => {
              setChannelSelecting(selectedRows);
            },
            preserveSelectedRowKeys: true,
            type: multiple ? "checkbox" : "radio",
          }}
        />
      </div>
    </Drawer>
  );
}

export default GPEventDetailChannelUpdateModal;
