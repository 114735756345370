import { getBothLangsText } from "@helpers/locales";
import { isEmpty } from "lodash";

const InfoItemLangs = ({ title = "", content, containerClass = "" }: any) => {
  return (
    <div className={`info-item-content-print-container ${containerClass}`}>
      {title && (
        <div className="info-item-content-print-title">{`${getBothLangsText(
          title,
          true
        )}: `}</div>
      )}
      {!isEmpty(content) && (
        <div className="info-item-content-print-content">{content}</div>
      )}
    </div>
  );
};

export default InfoItemLangs;
