import { IProvince } from "@interfaces/address";
import { IFieldSelect } from "@interfaces/select";
import { getProvinceList } from "@network/api/address";
import { ObjectUtils, Select } from "d-react-components";
import { isArray, map } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export interface IProvinceSelect<T> extends IFieldSelect<T> {
  [key: string]: any;
}

const ProvinceSelect = ({
  value = [],
  multiple,
  className,
  onChange,
  ...selectProps
}: IProvinceSelect<IProvince>) => {
  const getInitialValue = () => {
    if (!value) return [];
    if (isArray(value)) return value;
    return [value];
  };

  const [provinceList, setProvinceList] = useState<any[]>(getInitialValue());

  const { t } = useTranslation();

  const onChangeValue = (id: any) => {
    if (multiple) {
      onChange(
        map(id, (item) => ObjectUtils.findItemFromId(provinceList, item))
      );
      return;
    }
    onChange(ObjectUtils.findItemFromId(provinceList, id));
  };

  const provinceValue = useMemo(() => {
    if (multiple) {
      return map(value, (item: any) => item.id);
    }
    return (value as any)?.id;
  }, [value]);

  useEffect(() => {
    loadProvinceList();
  }, []);

  const loadProvinceList = () => {
    getProvinceList().then((res) => {
      const provinceList = res?.data?.data?.province ?? [];
      setProvinceList(provinceList);
    });
  };

  return (
    <Select
      className={className}
      label={t("province")}
      dataSource={provinceList}
      getLabel={(item) => item?.name}
      value={provinceValue}
      onChange={onChangeValue}
      multiple={multiple}
      placeholder={t("pleaseSelect")}
      showSearch
      {...selectProps}
    />
  );
};

export default ProvinceSelect;
