import { searchAllProduct } from "@network/api/product";
import { Switch } from "antd";
import {
  AwesomeTableComponent,
  Button,
  IColumnsProps,
  Icon,
  InputText,
  ObjectUtils,
  StringUtils,
  ViewTextError,
} from "d-react-components";
import { filter, map } from "lodash";
import { useTranslation } from "react-i18next";
import ProductName from "../components/ProductName";
import ProductSearchButton from "../components/ProductSearchButton";
import QuantityInputField from "../components/QuantityInputField";

interface IProductInput {
  productForm: any;
  className?: any;
}

const RentalOptionItem = ({ option, onRemove, onchange }: any) => {
  const { t } = useTranslation();

  return (
    <div className="flex-row align-items-center mt-3">
      <div className="flex-column justify-content-between">
        <Icon name="keyboard_arrow_up" size="large" />
        <Icon name="keyboard_arrow_down" size="large" />
      </div>
      <InputText
        onChange={(event) =>
          onchange({ ...option, value: event?.target.value })
        }
        value={option.value}
        className="flex-1 bg-white mx-3"
        suffix={<div>months</div>}
        placeholder={t("pleaseInput")}
      />
      <Icon
        name="delete"
        color="primary"
        className="text-primary cursor-default"
        onClick={() => onRemove(option)}
      />
    </div>
  );
};

const ProductInputRentalConfig = ({
  productForm,
  className,
}: IProductInput) => {
  const {
    rentalStatus,
    rentalRegularPrice,
    rentalSalePrice,
    rentalOptions,
    rentalFreeGifts,
  } = productForm?.values ?? {};

  const formErrors = productForm?.errors;
  const { t } = useTranslation();

  const classNameField = "col-span-2 ";

  const onAddOption = () => {
    const optionList = [
      ...rentalOptions,
      { id: StringUtils.getUniqueID(), value: "" },
    ];
    productForm.setFieldValue("rentalOptions", optionList);
  };

  const onRemoveOption = (option: any) => {
    const optionList = filter(rentalOptions, (item) => item.id !== option.id);
    productForm.setFieldValue("rentalOptions", optionList);
  };

  const onUpdateOption = (newOption: any) => {
    const optionList = ObjectUtils.updateArrayById(rentalOptions, newOption);
    productForm.setFieldValue("rentalOptions", optionList);
  };

  const onUpdateGift = (newGift: any) => {
    const giftList = map(rentalFreeGifts, (giftItem) => {
      if (giftItem.product?.id === newGift?.product?.id) {
        return newGift;
      }
      return giftItem;
    });

    productForm.setFieldValue("rentalFreeGifts", giftList);
  };

  const onRemoveGift = (gift: any) => {
    const optionList = filter(
      rentalFreeGifts,
      (item) => item.product?.id !== gift?.product?.id
    );
    productForm.setFieldValue("rentalFreeGifts", optionList);
  };

  const columns: IColumnsProps = [
    {
      title: t("product"),
      dataIndex: "",
      render: (product) => <ProductName item={product} />,
    },
    {
      title: t("quantity"),
      dataIndex: "quantity",
      render: (quantity, product) => {
        console.log("quantity", quantity);
        return (
          <QuantityInputField
            quantity={quantity}
            onChangeQuantity={(quantity: any) =>
              onUpdateGift({ ...product, quantity })
            }
          />
        );
      },
    },
    {
      title: t("action"),
      dataIndex: "",
      render: (product) => (
        <Icon
          name="delete"
          color="primary"
          className="text-primary cursor-default"
          onClick={() => onRemoveGift(product)}
        />
      ),
    },
  ];

  return (
    <div className={className}>
      <div className="d-flex mt-3">
        <Switch
          checked={rentalStatus}
          onChange={() =>
            productForm.setFieldValue("rentalStatus", !rentalStatus)
          }
        />
        <div className="ml-2">{t("enable")}</div>
      </div>
      <div className="border p-3 mt-3" hidden={!rentalStatus}>
        <div className="grid grid-cols-4 gap-4 ">
          <InputText
            className={classNameField}
            label={t("subscriptionRegularPrice")}
            onChange={(event) => {
              const { value } = event.target;
              const result = value ? value.replace(/\D/g, "") : 0;
              const period = parseInt(result);
              productForm.setFieldValue("rentalRegularPrice", period);
            }}
            value={rentalRegularPrice?.toLocaleString()}
            error={formErrors.rentalRegularPrice}
          />
          <InputText
            className={classNameField}
            label={t("subscriptionSalePrice")}
            onChange={(event) => {
              const { value } = event.target;
              const result = value ? value.replace(/\D/g, "") : 0;
              const period = parseInt(result);
              productForm.setFieldValue("rentalSalePrice", period);
            }}
            value={rentalSalePrice?.toLocaleString()}
            error={formErrors.rentalSalePrice}
          />
        </div>
        <div className="bg-primary-25 p-3 mt-3">
          <div className="label text-primary">{t("subscriptionOptions")}</div>
          {map(rentalOptions, (option) => (
            <RentalOptionItem
              option={option}
              onRemove={onRemoveOption}
              onchange={onUpdateOption}
            />
          ))}
          <ViewTextError error={formErrors.rentalOptions} />
          <Button iconName="add" onClick={onAddOption} className="mt-3">
            {t("option")}
          </Button>
        </div>
        <div className="bg-primary-25 p-3  mt-3">
          <div className="label text-primary">{t("freeGift")}</div>
          {!!rentalFreeGifts?.length && (
            <AwesomeTableComponent
              columns={columns}
              dataSource={rentalFreeGifts}
              className="bg-white p-3 mt-3"
            />
          )}
          <ViewTextError error={formErrors.rentalFreeGifts} />

          <ProductSearchButton
            className="mt-3"
            buttonText={t("add")}
            source={(search: string, body: any, paging: any) => {
              return searchAllProduct({
                pageIndex: paging.page,
                pageSize: paging.per_page,
                search: search,
              });
            }}
            onChange={(productSelecting) => {
              const result = map(productSelecting, (item) => ({
                ...item,
                quantity: item.quantity ?? 1,
              }));
              productForm.setFieldValue("rentalFreeGifts", result);
            }}
            defaultValue={rentalFreeGifts}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductInputRentalConfig;
