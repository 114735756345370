import EditInfoDetail from "@common/EditInfoDetail";
import ButtonFileUpload from "@components/shared/ButtonFileUpload";
import { DEPOSIT_METHODS, DEPOSIT_STATUS_LIST } from "@constants/cash-deposit";
import AppContext from "@helpers/context";
import CashDepositAPI from "@network/api/cash-deposit";
import {
  DateInput,
  InputText,
  Notifications,
  Progress,
  Select,
  StringUtils,
  TimeUtils,
} from "d-react-components";
import _, { find, isEmpty, map } from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const pending = DEPOSIT_STATUS_LIST[0].id;
const pendingConfirm = DEPOSIT_STATUS_LIST[1].id;

const CashDepositDetailInfo = () => {
  const { cashDepositDetail, loadCashDepositDetail } = useContext(AppContext);
  const {
    channelList,
    channel,
    store,
    method,
    transaction_closing_date,
    remark,
    expected_deposit_date,
    payments,
    documents,
    status,
    bank_fee,
  } = cashDepositDetail;
  const transactionRef = useRef();
  const expectedDateRef = useRef();
  const methodRef = useRef();
  const remarkItem = useRef();
  const [depositDetailInfo, setDepositDetailInfo] = useState<any>({});
  const isEdit = status === pendingConfirm || status === pending;
  const { t } = useTranslation();

  useEffect(() => {
    setInitValue();
  }, [
    channel,
    store,
    method,
    transaction_closing_date,
    remark,
    expected_deposit_date,
  ]);

  const setInitValue = () => {
    setDepositDetailInfo({
      channel,
      store,
      method,
      transaction_closing_date,
      remark,
      expected_deposit_date,
      bank_fee,
    });
  };
  const handleOnChangeData = (key: any, value: any) => {
    setDepositDetailInfo({ ...depositDetailInfo, [key]: value });
  };

  const handleOnClickSaveEditInfo = async (ref: any, key: any) => {
    const body = {
      id: cashDepositDetail?.id,
      channel: depositDetailInfo?.channel?.id,
      storeid: depositDetailInfo?.store?.id,
      transaction_closing_date: depositDetailInfo?.transaction_closing_date,
      expected_deposit_date: depositDetailInfo?.expected_deposit_date,
      remark: depositDetailInfo?.remark,
      bank_fee: depositDetailInfo?.bank_fee,
      payments: map(payments, (item) => item?.id) ?? [],
    };
    try {
      await CashDepositAPI.update(body);
      Notifications.showSuccess(t("editDepositInfoSuccess"));
      ref.current.close();
      loadCashDepositDetail && loadCashDepositDetail();
    } catch (error) {
      console.error({ error });
      setInitValue();
      ref.current.close();
      loadCashDepositDetail && loadCashDepositDetail();
    }
  };
  const depositMethod = find(DEPOSIT_METHODS, (item) => item?.id === method);
  return (
    <>
      <EditInfoDetail
        title={t("channel")}
        content={channel?.name ?? "N/A"}
        className={"mt-0"}
        // renderContentModal={() => (
        //   <CustomizedSelect
        //     options={channelList}
        //     setOptionDisplayName={(item) => item.name}
        //     itemValue
        //     value={depositDetailInfo?.channel?.id}
        //   />
        // )}
      />
      <EditInfoDetail
        title={t("storeWarehouse")}
        content={StringUtils.getFullNameStore(store)}
      />
      <EditInfoDetail
        isEdit={isEdit}
        title={t("depositMethod")}
        content={t(depositMethod?.label ?? "")}
        renderContentModal={() => (
          <Select
            dataSource={DEPOSIT_METHODS}
            value={depositDetailInfo?.method ?? ""}
            onChange={(value) => handleOnChangeData("method", value)}
          />
        )}
        ref={methodRef}
        onClose={() => setInitValue()}
        onClickSave={() => handleOnClickSaveEditInfo(methodRef, "method")}
      />
      <EditInfoDetail
        isEdit={isEdit}
        title={t("bankFee")}
        content={StringUtils.moneyThaiFormat(bank_fee)}
        renderContentModal={() => (
          <InputText
            label={t("bankFee")}
            value={depositDetailInfo?.bank_fee ?? ""}
            onChange={(event) =>
              handleOnChangeData("bank_fee", event.target.value)
            }
          />
        )}
        ref={methodRef}
        onClose={() => setInitValue()}
        onClickSave={() => handleOnClickSaveEditInfo(methodRef, "method")}
      />
      <EditInfoDetail
        isEdit={isEdit}
        title={t("transactionClosingDate")}
        content={TimeUtils.toDateTime(transaction_closing_date)}
        renderContentModal={() => (
          <DateInput
            className="selectDepositMethod"
            value={moment(depositDetailInfo?.transaction_closing_date)}
            showTime
            format={TimeUtils.DATE_TIME_FORMAT}
            onChange={(date) => {
              let result = moment(date).valueOf();
              if (!result) {
                result = Date.now();
              }
              if (result > _.now()) {
                Notifications.showError(t("transClosingDateCanNotOverToday"));
                return;
              }
              handleOnChangeData("transaction_closing_date", result);
            }}
          />
        )}
        ref={transactionRef}
        onClose={() => setInitValue()}
        onClickSave={() =>
          handleOnClickSaveEditInfo(transactionRef, "transaction_closing_date")
        }
      />
      <EditInfoDetail
        isEdit={isEdit}
        title={t("expectedDepositDate")}
        content={TimeUtils.toDateTime(expected_deposit_date)}
        renderContentModal={() => (
          <DateInput
            className="selectDepositMethod"
            value={moment(depositDetailInfo?.expected_deposit_date)}
            showTime
            format={TimeUtils.DATE_TIME_FORMAT}
            onChange={(date) => {
              let result = moment(date).valueOf();

              if (!result) {
                result = Date.now();
              }

              if (result < transaction_closing_date) {
                Notifications.showError(t("expClosingDateNotBeforeTransDate"));
                return;
              }
              handleOnChangeData("expected_deposit_date", result);
            }}
          />
        )}
        ref={expectedDateRef}
        onClose={() => setInitValue()}
        onClickSave={() =>
          handleOnClickSaveEditInfo(expectedDateRef, "expected_deposit_date")
        }
      />
      <EditInfoDetail
        isEdit={isEdit}
        title={t("remark")}
        onClose={() => setInitValue()}
        onClickSave={() => handleOnClickSaveEditInfo(remarkItem, "remark")}
        ref={remarkItem}
        renderContent={() => <div>{remark}</div>}
        renderContentModal={() => (
          <InputText
            value={depositDetailInfo?.remark}
            onChange={(event) => {
              handleOnChangeData("remark", event?.target?.value);
            }}
            multiple
            rows={5}
          />
        )}
      />
    </>
  );
};

export default CashDepositDetailInfo;
