import { Avatar } from "d-react-components";
import React from "react";
import { useRootStore } from "../App";

const SalesPerson = ({ by }: { by: any }) => {
  const setUserModalId = useRootStore((state) => state.setUserModalId);

  return (
    <div
      className="flex items-center cursor-pointer"
      onClick={() => setUserModalId(by?.id)}
    >
      <Avatar
        src={by?.avatar || "/images/img-default.png"}
        className="rounded-sm overflow-hidden"
        variant="square"
        size="x-small"
      />
      <div className="block ml-2 text-ellipsis overflow-hidden">
        <div className="text-ellipsis overflow-hidden text-primary">
          {by?.fullname} ({by?.name})
        </div>
        <div className="text-xs text-gray-400 text-ellipsis overflow-hidden">
          ID: {by?.companyId}
        </div>
      </div>
    </div>
  );
};

export default SalesPerson;
