import {
  CART_RULE_ACTION_KEY,
  MAX_ORDER_VALUE_ALLOW_COD,
  MT_ORDER_TYPE,
  PICKUP_TYPE,
} from "@constants/order";
import { STORE_TYPE } from "@constants/store";
import AppContext from "@helpers/context";
import { getListChannel } from "@network/api/channel";
import {
  createOrder,
  getOrderPaymentMethods,
  getPaymentOptions,
  getSaleOrderSource,
  loadOrder,
} from "@network/api/order";
import { browseProduct } from "@network/api/product";
import {
  createContractShipping,
  getProvinceList,
  searchShippingCustomer,
  updateContractBilling,
} from "@network/api/address";
import { useFormik } from "formik";
import {
  every,
  filter,
  find,
  isEmpty,
  pick,
  map,
  omit,
  reduce,
  some,
  sortBy,
  throttle,
} from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Modal } from "antd";
import OrderDetailHeader from "./OrderDetailHeader";
import OrderDetailProductList from "./OrderDetailProductList";
import OrderProducts from "./OrderProductsCart";
import {
  Button,
  Notifications,
  Progress,
  StringUtils,
} from "d-react-components";
import { isCustomSizeProduct } from "@helpers/product";
import { useTranslation } from "react-i18next";
import { ADDITIONAL_DISCOUNT, INTERNAL_DISCOUNT } from "@constants/discount";
import { useMeasure, useMedia } from "react-use";
import ModalConfirmPickup from "../components/pickup/ConfirmPickupDrawer";
import { useNavigate } from "react-router-dom";
import Drawer from "@components/shared/Drawer";
import moment from "moment";
import { createRental, updateRentalCustomerInfo } from "@network/api/rental";
import { InformationType } from "@components/subscription-contract/detail/content/CustomerInformation";
import { CustomerType } from "@interfaces/customer";
import { PAYMENT_COD } from "@constants/payment";

export interface OrderFilterForm {
  selectedChannel?: any;
  storeSelected?: any;
  channel?: string;
  isChainExternal?: boolean;
  mtOrderType?: any;
  mtOrderDiscount?: any;
  selectedCustomer?: any;
  pickupType?: any;
  receiptRef?: any;
  sourceSaleOrder?: any;
  note?: string;
  warehouse?: any;
  salesPerson?: any;
  shippingFee?: any;
  month?: string;
  scheduled_delivery_date?: any;
  orderType?: any;
  payment?: any;
}

export enum OrderCreateType {
  NORMAL = "NORMAL",
  RENTAL = "RENTAL",
}

export const INIT_FILTER: any = {
  brand: [],
  category: [],
};

type VIEW_MODE = "grid" | "list";

const OrderCreate = ({
  type = OrderCreateType.NORMAL,
}: {
  type?: OrderCreateType;
}) => {
  const isMobile = useMedia("(max-width: 767px)");
  const [showProductDrawer, setShowProductDrawer] = useState(false);
  const { navBarHeight } = useContext(AppContext);
  const [listChannel, setListChannel] = useState<any[]>([]);
  const [provinceList, setProvinceList] = useState<any[]>([]);
  const [productList, setProductList] = useState<any[]>([]);
  const [freeGiftList, setFreeGiftList] = useState<any[]>([]);
  const [freeGiftListRent, setFreeGiftListRent] = useState<any[]>([]);
  const [discountChainList, setDiscountChainList] = useState<any[]>([]);
  const [externalDiscountList, setExternalDiscountList] = useState<any[]>([]);
  const [productCustomList, setProductCustomList] = useState<any[]>([]);
  const [originOrder, setOriginOrder] = useState<any>(null);
  const [sourceSaleOrderList, setSourceSaleOrderList] = useState<any[]>([]);
  const [totalState, setTotalSate] = useState<any>(null);
  const [subTotalState, setSubTotalState] = useState<any>(null);
  const [shippingProfile, setShippingProfile] = useState<any>([]);
  const [billingProfile, setBillingProfile] = useState<any>({});
  const [contactOrder, setContactOrder] = useState<any>({});
  const [checkCoupon, setCheckCoupon] = useState();
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [paymentMethodsList, setPaymentMethodsList] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [filterBody, setFilterBody] = useState<any>(INIT_FILTER);
  const [viewMode, setViewMode] = useState<VIEW_MODE>("grid");
  const [inputSearch, setInputSearch] = useState("");
  const [modalPickup, setModalPickup] = useState<any>({
    orderId: null,
    open: false,
    orderUrl: "",
  });
  const [headerBlockRef, { height: headerBlockHeight }] = useMeasure<any>();

  const orderFilterForm = useFormik<OrderFilterForm>({
    initialValues: {
      orderType: type,
    },
    onSubmit: () => {},
  });
  const { values, errors, setFieldValue, setValues } = orderFilterForm;
  const {
    storeSelected,
    channel,
    isChainExternal,
    mtOrderType,
    mtOrderDiscount,
    selectedCustomer,
    selectedChannel,
    pickupType,
    sourceSaleOrder,
    receiptRef,
    note,
    salesPerson,
    shippingFee,
  } = values;

  const getListChannels = () => {
    getListChannel().then((respone) => {
      const channels = respone?.data?.data?.channel ?? [];
      setListChannel(channels);
    });
  };

  const loadProvinceData = () => {
    getProvinceList().then((res) => {
      const provinceList = res?.data?.data?.province ?? [];
      setProvinceList(provinceList);
    });
  };

  const loadSourceSaleOrderData = () => {
    getSaleOrderSource().then((res) => {
      const sourceSaleOrderList = res?.data?.data?.sources ?? [];
      setSourceSaleOrderList(sourceSaleOrderList);
    });
  };

  const onSelectStore = (item: any) => {
    if (item) {
      let pickupType;
      if (item?.store_type === STORE_TYPE.ONLINE) {
        pickupType = PICKUP_TYPE.NONE;
      } else {
        pickupType = PICKUP_TYPE.FULL;
      }
      setFieldValue("pickupType", pickupType);
    } else {
      setFieldValue("pickupType", undefined);
    }
    setFieldValue("storeSelected", item);
    setFieldValue("isChainExternal", item?.is_chain_external);
    setFieldValue("mtOrderType", undefined);
    setFieldValue("mtOrderDiscount", undefined);
    //reset
    setProductList([]);
    setPaymentMethodsList([]);
  };

  useEffect(() => {
    getListChannels();
    loadProvinceData();
    loadSourceSaleOrderData();
  }, []);

  useEffect(() => {
    const shippingInfo = shippingProfile?.[0];
    if (!isEmpty(shippingInfo)) {
      const { scheduled_delivery_min } = shippingInfo?.province || {};
      setFieldValue(
        "scheduled_delivery_date",
        moment().add(scheduled_delivery_min, "days")
      );
    }
  }, [shippingProfile?.[0]?.id]);

  const sourceSearchProduct = (code: any, filterBody: any, paging: any) => {
    const warehouseDelivery = map(
      storeSelected?.warehouse_delivery ?? [],
      (item) => item.id
    );
    const body: any = {
      channel,
      promotion: "name",
      code,
      store_id: storeSelected?.id,
      is_rent: false,
    };

    const { brand, category } = filterBody;
    if (brand && brand.length > 0) {
      body.brand = brand?.map((item: any) => item.id);
    }

    if (category && category.length > 0) {
      body.category = category?.map((item: any) => item.id);
    }

    if (warehouseDelivery && warehouseDelivery.length > 0) {
      body.warehouse_delivery_id = warehouseDelivery;
    }

    if (isChainExternal && mtOrderType === MT_ORDER_TYPE.EVENT) {
      if (!mtOrderDiscount) {
        return Promise.resolve([]);
      } else {
        body.ref_type = "stock_inventory_control";
        body.ref_id = mtOrderDiscount?.id;
      }
    }
    if (type === OrderCreateType.RENTAL) {
      body.is_rent = true;
    }
    return browseProduct(body, paging);
  };

  const setChangeFreeGiftList = (rawList: any) => {
    const sortList = sortBy(
      rawList?.map((item: any) => ({ ...item, priority: item.priority ?? 0 })),
      (item) => item.priority
    );
    setFreeGiftList(sortList);
  };

  const onChangeSubtotal = (value: any) => {
    setSubTotalState(value);
    setDiscountChainList([]);
  };

  const loadOrderFromOld = async (orderId: string) => {
    Progress.show({ method: loadOrder, params: [orderId] }, (res: any) => {
      const orderRes = res.data.data;
      const productList = orderRes?.newProducts?.map((productItem: any) => {
        const oldProduct = orderRes.products.find(
          (item: any) => item.product.id === productItem.product.id
        );
        return {
          ...productItem,
          quantity: oldProduct.quantity,
          meta: (oldProduct?.meta ?? [])?.map((metaItem: any) => ({
            ...metaItem,
            id: StringUtils.getUniqueID(),
          })),
        };
      });
      setValues({
        ...values,
        selectedChannel: orderRes?.channel,
        selectedCustomer: [orderRes?.customer],
        pickupType: orderRes?.order?.pickup_type,
        storeSelected: orderRes?.order?.store,
        isChainExternal: orderRes?.order?.store?.is_chain_external,
        sourceSaleOrder: orderRes?.order?.source?.id,
        channel: orderRes?.channel?.id,
        warehouse: [orderRes?.order?.store],
      });
      const shippingList = orderRes?.shipping?.map((item: any) => {
        const { expected_to_receive, ...rest } = item;
        return {
          ...rest,
          expectedDateReceive: item.expected_to_receive,
        };
      });
      setShippingProfile(shippingList);
      setBillingProfile({
        ...orderRes?.billing,
        status: !!orderRes?.order?.billing,
      });
      setProductList(productList);
      setContactOrder(orderRes?.contact);
      setOriginOrder(orderRes);
    });
  };

  const loadShippingProfile = (customer: any) => {
    if (!customer?.id) {
      return;
    }
    searchShippingCustomer(customer?.id).then((res) => {
      const shippingList = res?.data?.data?.shipping;
      if (shippingList?.[0]) {
        setShippingProfile([shippingList[0]]);
      }
      setBillingProfile({});
    });
  };

  const validateSalesPerson = () => {
    if (isEmpty(salesPerson)) {
      {
        Notifications.showError(t("notification:youHaveToChooseSalesPerson"));
        return false;
      }
    }
    return true;
  };

  const validateProducts = () => {
    if (
      isEmpty(selectedChannel) ||
      (isEmpty(productList) && isEmpty(productCustomList))
    ) {
      Notifications.showError(t("notification:youHaveToChooseProductsFirst"));
      return false;
    }

    const customSizeProduct = productList.filter((item) =>
      isCustomSizeProduct(item)
    );

    const isSomeProductNotFillDimension = some(
      customSizeProduct,
      (item: any) => {
        return some(
          item.meta ?? [],
          (metaItem) => metaItem.length === 0 || metaItem.width === 0
        );
      }
    );
    if (isSomeProductNotFillDimension) {
      Notifications.showError(t("notification:youMustFillWidthAndLength"));
      return false;
    }
    return true;
  };

  const validateFreeGift = (freeGiftBody: any) => {
    const freeGiftList = freeGiftBody.filter(
      (item: any) =>
        item.specific === CART_RULE_ACTION_KEY.freegift.id ||
        item.specific === CART_RULE_ACTION_KEY.buyXGetBundle.id
    );
    if (freeGiftList.length === 0) {
      return true;
    }
    const isAllSelectedGift = every(freeGiftList, (freeGiftItem) => {
      const bundle = freeGiftItem?.bundle ?? {};
      const bundleList = bundle?.list_bundle ?? [];
      const quantitySum = reduce(
        bundleList,
        (sum, item) => sum + item.quantity,
        0
      );
      return quantitySum === bundle.quantity;
    });
    if (!isAllSelectedGift) {
      Notifications.showError(t("notification:youMustSelectAllFreeGift"));
      return false;
    }
    return true;
  };

  const validateCustomSizeCartRule = (customSizeList: any) => {
    const isAllSizeValidate = every(customSizeList, (customSizeItem) => {
      return every(
        customSizeItem.size,
        (sizeItem) => sizeItem.length !== 0 && sizeItem.width !== 0
      );
    });

    if (!isAllSizeValidate) {
      Notifications.showError(t("notification:mustSelectAllCustomSizeInGift"));
      return false;
    }
    return true;
  };

  const getDiscountChainLevel = (discount: any) => {
    const levelValue = discount?.action?.level;
    if (levelValue === INTERNAL_DISCOUNT) {
      return `${levelValue}_${discount?.internalDiscountIndex + 1}`;
    }
    if (
      levelValue === ADDITIONAL_DISCOUNT &&
      discount?.additionalDiscountIndex === 1
    ) {
      return `${levelValue}_${discount?.additionalDiscountIndex + 1}`;
    }
    return levelValue;
  };

  const getSelfRequestDiscountServer = (item: any) => ({
    discount: item?.action?.amount,
    discount_type: item?.action?.specific,
    discountid: item?.managerDiscountId,
  });

  const getDiscountChainServer = (discount: any) => {
    const level = getDiscountChainLevel(discount);
    return {
      level,
      discount: discount.action?.amount,
      discount_type: discount?.discountType,
    };
  };

  const getShippingServer = (item: any) => {
    const { expectedDateReceive, ...rest } = item;
    return {
      ...rest,
      city: item?.city?.id,
      province: item?.province?.id,
      subdistrict: item?.subdistrict?.id,
      ...(expectedDateReceive
        ? { expected_to_receive: moment(expectedDateReceive).valueOf() }
        : {}),
    };
  };

  const getBillingServer = () => ({
    ...billingProfile,
    city: billingProfile?.city?.id,
    province: billingProfile?.province?.id,
    subdistrict: billingProfile?.subdistrict?.id,
  });

  const getProductCustomerServer = (customItem: any) => ({
    name: customItem?.product?.name,
    sku: customItem?.product?.sku,
    sale_price: customItem?.sale_price,
    brand_id: customItem?.brand?.id,
    category_id: customItem?.category?.id,
    quantity: customItem?.quantity,
    meta: {
      width: customItem?.productInfo?.width,
      length: customItem?.productInfo?.length,
    },
    attribute: customItem?.attributeList?.map((item: any) => item.value),
    remark: customItem.remark,
  });

  const getProductServer = (pro: any) => ({
    adsid: pro?.adsid ?? null,
    promotionid: pro.promotionid,
    quantity: pro.quantity,
    discountid: pro.discountid,
    meta: pro.meta,
    remark: pro.remark,
  });

  const getDiscountBody = () => {
    const customSizeCartRule: any[] = [];
    const freeGiftBody = freeGiftList
      .filter((item) => !item.selfDiscount && !item.isEmployeeDiscount)
      ?.map((item) => {
        const bundle = item?.action?.bundle ?? {};
        const bundleList = bundle?.list_bundle ?? [];
        const bundleSelect: any[] = [];
        // const bundleSelect = bundleList
        //   .filter(
        //     (bundleItem) => bundleItem.quantity && bundleItem.quantity > 0
        //   )
        //   ?.map((bundleItem) => ({
        //     id: bundleItem?.id,
        //     quantity: bundleItem.quantity,
        //   }));
        bundleList
          .filter(
            (bundleItem: any) => bundleItem.quantity && bundleItem.quantity > 0
          )
          .forEach((bundleItem: any) => {
            bundleSelect.push({
              id: bundleItem?.id,
              quantity: bundleItem.quantity,
            });

            //transform meta data
            const productList = bundleItem?.product ?? [];
            const productsCustomSize = productList.filter((item: any) =>
              isCustomSizeProduct(item)
            );
            productsCustomSize.forEach((productItem: any) => {
              customSizeCartRule.push({
                cart_rule_id: item.id,
                bundle_id: bundleItem.id,
                product_id: productItem.productid,
                size: productItem?.meta ?? [],
              });
            });
          });

        const giftList = item?.action?.gift ?? [];
        giftList
          .filter((item: any) => isCustomSizeProduct(item))
          .forEach((giftItem: any) => {
            customSizeCartRule.push({
              cart_rule_id: item.id,
              product_id: giftItem.productid,
              size: giftItem?.meta ?? [],
            });
          });
        return {
          cartruleid: item?.id ?? "",
          code: item?.code?.code ?? "",
          bundle: {
            ...bundle,
            list_bundle: bundleSelect,
          },
          ...(type === OrderCreateType.NORMAL
            ? {
                specific: item?.action?.specific,
              }
            : {}),
        };
      });
    return { customSizeCartRule, freeGiftBody };
  };

  const onClickCreateOrderHandle = throttle(() => {
    if (!validateSalesPerson()) {
      return Promise.reject();
    }
    if (!validateProducts()) {
      return Promise.reject();
    }
    if (isEmpty(selectedCustomer) && !isChainExternal) {
      Notifications.showError(t("notification:pleaseChooseCustomer"));
      return Promise.reject();
    }

    if (isEmpty(receiptRef) && isChainExternal) {
      Notifications.showError(t("notification:receiptRefRequire"));
      return Promise.reject();
    }

    if (!sourceSaleOrder) {
      Notifications.showError(t("notification:pleaseSelectSourceOrder"));
      return Promise.reject();
    }
    if (!storeSelected) {
      Notifications.showError(t("notification:pleaseSelectStore"));
      return Promise.reject();
    }
    if (isEmpty(values.payment)) {
      Notifications.showError(t("notification:pleaseSelectPaymentMethod"));
      return Promise.reject();
    }
    if (isEmpty(originOrder) && totalState <= 0) {
      Notifications.showError(t("notification:orderValueCannotBeUnderZero"));
      return Promise.reject();
    }

    // if (totalState == 0) {
    //   Notifications.showError(Messages.totalSaleAmountMustGreater);
    //   return Promise.reject();
    // }

    const { customSizeCartRule, freeGiftBody } = getDiscountBody();

    const discountSelfRequest = map(
      filter(freeGiftList, (item: any) => item?.selfDiscount),
      getSelfRequestDiscountServer
    );

    const discountEmployee = find(
      freeGiftList,
      (item: any) => item.isEmployeeDiscount
    );
    if (
      !validateFreeGift(freeGiftBody) ||
      !validateCustomSizeCartRule(customSizeCartRule)
    ) {
      return Promise.reject();
    }

    const discountChain = map(discountChainList, getDiscountChainServer);
    const products = productList?.map(getProductServer);
    const shipping = map(shippingProfile, getShippingServer);
    const billing = getBillingServer();
    const contact = contactOrder;
    const manualProduct = map(productCustomList, getProductCustomerServer);

    const body = {
      channel: selectedChannel?.id ?? null,
      customerid: selectedCustomer?.[0]?.id ?? null,
      receipt_ref: receiptRef ?? null,
      products,
      shipping,
      billing,
      note,
      contact,
      old_order: originOrder?.order?.id,
      cartrule: freeGiftBody,
      sourceid: sourceSaleOrder,
      cartRuleSelfRequest: discountSelfRequest?.[0],
      pickup_type: pickupType,
      storeid: storeSelected?.id,
      customSizeCartRule,
      manualProduct,
      employee_discount_code: discountEmployee?.employeeDiscountCode ?? "",
      discountChain,
      mt_type_discount: mtOrderType,
      mt_type_discount_id: mtOrderDiscount?.id
        ? mtOrderDiscount?.id
        : mtOrderDiscount,
      ...(salesPerson ? { sales_person_id: salesPerson?.[0]?.id } : {}),
      shipping_fee: shippingFee || 0,
      payment: values.payment,
    };

    Progress.show(
      { method: createOrder, params: [body] },
      (res: any) => {
        onProcessSuccessCreateOrder(res?.data?.data);
      },
      (err: any) => {
        Notifications.showError(err?.response?.data?.message);
      }
    );
  }, 1000);

  const onClickCreateRentalContractHandle = throttle(() => {
    if (!validateSalesPerson()) {
      return Promise.reject();
    }
    if (!validateProducts()) {
      return Promise.reject();
    }
    if (isEmpty(selectedCustomer)) {
      Notifications.showError(t("notification:pleaseChooseCustomer"));
      return Promise.reject();
    }
    if (!sourceSaleOrder) {
      Notifications.showError(t("notification:pleaseSelectSourceOrder"));
      return Promise.reject();
    }
    if (!storeSelected) {
      Notifications.showError(t("notification:pleaseSelectStore"));
      return Promise.reject();
    }
    if (isEmpty(shippingProfile)) {
      Notifications.showError(t("order:pleaseAddShippingFirst"));
      return Promise.reject();
    }
    //temporary allow only Bangkok
    if (
      shippingProfile?.[0]?.province?.id !==
      "1efb827f-2765-4f34-9500-d8e266a98dab"
    ) {
      Notifications.showError(t("notification:onlySupportShippingBangkok"));
      return Promise.reject();
    }
    if (selectedCustomer) {
      const customer = selectedCustomer[0];
      if (!customer?.email) {
        Notifications.showError(
          t("notification:customerShouldHaveAValidEmail")
        );
        return Promise.reject();
      }
      if (
        customer.customer_type === CustomerType.INDIVIDUAL &&
        !customer?.phone
      ) {
        Notifications.showError(
          t("notification:customerShouldHaveAValidPhone")
        );
        return Promise.reject();
      }
      if (
        customer.customer_type === CustomerType.INDIVIDUAL &&
        !customer.is_verify_phone
      ) {
        Notifications.showError(t("notification:customerPhoneMustBeVerified"));
        return Promise.reject();
      }
      if (!customer.is_verify_email) {
        Notifications.showError(t("notification:customerEmailMustBeVerified"));
        return Promise.reject();
      }
    }
    //temporary allow only Bangkok
    if (
      shippingProfile?.[0]?.province?.id !==
      "1efb827f-2765-4f34-9500-d8e266a98dab"
    ) {
      Notifications.showError(t("notification:onlySupportShippingBangkok"));
      return Promise.reject();
    }
    const numberOfMonth = productList?.[0]?.month;
    if (!numberOfMonth) {
      Notifications.showError(t("notification:pleaseChooseContractPeriod"));
      return Promise.reject();
    }

    const { freeGiftBody } = getDiscountBody();

    const shippingInfo = shippingProfile[0];
    const shippingBody = {
      ...omit(shippingInfo, [
        "postcode",
        "subdistrict",
        "province",
        "city",
        "id",
      ]),
      city_id: shippingInfo?.city?.id,
      province_id: shippingInfo?.province?.id,
      subdistrict_id: shippingInfo?.subdistrict?.id,
      code: shippingInfo?.postcode,
      scheduled_delivery_date:
        values?.scheduled_delivery_date?.valueOf() ?? null,
    };

    const body = {
      channel_id: selectedChannel?.id ?? null,
      customer_id: selectedCustomer?.[0]?.id ?? null,
      product_id: productList?.[0]?.product?.id,
      month: productList?.[0]?.month ? parseInt(productList?.[0]?.month) : 0,
      warehouse_id: storeSelected?.id,
      sales_person_id: salesPerson?.[0]?.id,
      remark: note,
      source_order_id: sourceSaleOrder,
      pickup_type: pickupType,
      shipping: shippingBody,
      cartrule: freeGiftBody,
    };

    Progress.show(
      { method: createRental, params: [body] },
      (res: any) => {
        onProcessSuccessCreateRental(res?.data?.data);
      },
      (err: any) => {
        Notifications.showError(err?.response?.data?.message);
      }
    );
  }, 1000);

  const onProcessSuccessCreateOrder = useCallback(
    (orderResponse: any) => {
      const orderID = orderResponse?.orderid;
      const url = orderResponse.orderurl ?? "";

      if (pickupType === PICKUP_TYPE.NONE) {
        Notifications.showSuccess(t("notification:orderCreatedSuccessfully"));
        navigate(`/order/${orderResponse?.id}`);
        return;
      }
      const messagesSuccess = `${t("order")} ${orderID} ${t(
        "wasCreatedSuccess"
      )}`;

      Modal.confirm({
        title: t("success"),
        content: messagesSuccess,
        okText: t("continue"),
        cancelText: t("createPickup"),
        onOk: (close) => {
          close();
          navigate(`/order/${orderResponse?.id}`);
        },
        onCancel: (close) => {
          setModalPickup({
            open: true,
            orderId: orderResponse.id,
            orderUrl: url,
          });
          close();
        },
      });
    },
    [t, pickupType]
  );

  const onProcessSuccessCreateRental = useCallback(
    async (orderResponse: any) => {
      try {
        if (!isEmpty(billingProfile)) {
          const billingInfo = billingProfile;
          const body = {
            ...omit(billingInfo, [
              "postcode",
              "subdistrict",
              "province",
              "city",
            ]),
            rent_id: orderResponse?.rent?.id,
            city_id: billingInfo?.city?.id,
            province_id: billingInfo?.province?.id,
            subdistrict_id: billingInfo?.subdistrict?.id,
            code: billingInfo?.postcode,
          };
          await updateContractBilling(body);
        }
        if (selectedCustomer) {
          const customer = selectedCustomer[0];
          const body = {
            rent_id: orderResponse?.rent?.id,
            type: InformationType.INDIVIDUAL,
            ...pick(customer, [
              "alternative_phone",
              "citizen_id",
              "phone",
              "email",
              "gender",
            ]),
            first_name: customer.firstname,
            last_name: customer.lastname,
            day_of_birth: customer.birthday,
          };
          await updateRentalCustomerInfo(body);
        }
      } catch (error) {
      } finally {
        Notifications.showSuccess(t("notification:orderCreatedSuccessfully"));
        navigate(`/subscription-contract/${orderResponse?.rent?.id}`);
        return;
      }
    },
    [t, shippingProfile, values]
  );

  const loadPaymentOptions = (channelId: string) => {
    getPaymentOptions({ channel_id: channelId || channel }).then((res) => {
      const paymentOptions = res?.data?.data?.options ?? [];
      setPaymentOptions(paymentOptions);
    });
  };

  const loadOrderPaymentMethods = () => {
    getOrderPaymentMethods({
      is_chain_external: storeSelected.is_chain_external,
    }).then((res) => {
      const list = res?.data?.data?.method ?? [];
      setPaymentMethodsList(list);
    });
  };

  const isEventSaleSelected = useMemo(() => {
    return (
      isChainExternal && mtOrderType === MT_ORDER_TYPE.EVENT && mtOrderDiscount
    );
  }, [isChainExternal, mtOrderDiscount, mtOrderType]);

  useEffect(() => {
    if (
      totalState > MAX_ORDER_VALUE_ALLOW_COD &&
      values?.payment?.method === PAYMENT_COD
    ) {
      setFieldValue("payment", {});
    }
  }, [totalState]);
console.log(originOrder, 'originOrder')
  return (
    <AppContext.Provider
      value={{
        listChannel,
        provinceList,
        sourceSaleOrderList,
        onSelectStore,
        productList,
        setProductList,
        originOrder,
        setOriginOrder,
        freeGiftList,
        setFreeGiftList: setChangeFreeGiftList,
        freeGiftListRent,
        setFreeGiftListRent,
        productCustomList,
        setProductCustomList,
        discountChainList,
        setDiscountChainList,
        externalDiscountList,
        setExternalDiscountList,
        values,
        setValues,
        setFieldValue,
        totalState,
        setTotalSate,
        subTotalState,
        setSubTotalState: onChangeSubtotal,
        loadOrderFromOld,
        loadShippingProfile,
        isCreateOrder: true,
        shippingProfile,
        setShippingProfile,
        billingProfile,
        setBillingProfile,
        filterBody,
        setFilterBody,
        viewMode,
        setViewMode,
        inputSearch,
        setInputSearch,
        headerBlockHeight,
        setContactOrder,
        setCheckCoupon,
        isEventSaleSelected,
        type,
        paymentOptions,
        loadPaymentOptions,
        loadOrderPaymentMethods,
        paymentMethodsList,
        storeSelected,
      }}
    >
      <div className="grid grid-cols-3 gap-4 p-3">
        <div
          className="col-span-3 md:col-span-2 height-mobile-auto"
          style={{ height: `calc(100vh - ${navBarHeight + 32 ?? 0}px)` }}
        >
          <div ref={headerBlockRef}>
            <OrderDetailHeader />
          </div>
          {!isMobile && (
            <OrderDetailProductList
              source={sourceSearchProduct}
              navBarHeight={navBarHeight}
            />
          )}
          {isMobile && (
            <>
              <Button onClick={() => setShowProductDrawer(true)}>
                {t("addProduct")}
              </Button>
              <Drawer
                open={showProductDrawer}
                onClose={() => setShowProductDrawer(false)}
                title={t("addProduct")}
              >
                <div className="flex justify-content-end">
                  <Button
                    onClick={() => setViewMode("grid")}
                    iconName="apps"
                    color={viewMode === "grid" ? "primary" : "light"}
                    className="mr-2"
                  ></Button>
                  <Button
                    onClick={() => setViewMode("list")}
                    iconName="table_rows"
                    color={viewMode === "list" ? "primary" : "light"}
                  ></Button>
                </div>
                <OrderDetailProductList
                  source={sourceSearchProduct}
                  navBarHeight={navBarHeight}
                />
              </Drawer>
            </>
          )}
        </div>
        <div className="col-span-3 md:col-span-1 relative">
          <OrderProducts
            submitOrder={
              type === OrderCreateType.RENTAL
                ? onClickCreateRentalContractHandle
                : onClickCreateOrderHandle
            }
            navBarHeight={navBarHeight}
          />
        </div>
      </div>
      {modalPickup.orderId && (
        <ModalConfirmPickup
          open={modalPickup.open}
          orderId={modalPickup.orderId}
          orderUrl={modalPickup.orderUrl}
          onClose={() => setModalPickup({ open: false, orderId: null })}
        />
      )}
    </AppContext.Provider>
  );
};

export default OrderCreate;
