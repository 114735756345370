import InputSelectForm from "@common/input/InputSelectForm";
import InputTextForm from "@common/input/InputTextForm";
import AddressFormInput from "@components/address/components/AddressFormInput";
import { BILLING_TYPE, BILLING_TYPES } from "@constants/common";
import { useTranslation } from "react-i18next";

interface ICustomerInput {
  customerForm: any;
  className?: any;
}

const CustomerInputBilling = ({ customerForm, className }: ICustomerInput) => {
  const formValues = customerForm?.values;
  const { t } = useTranslation();

  const classNameField = "col-6 mt-3";

  return (
    <div className="row">
      <InputSelectForm
        form={customerForm}
        keyData="type"
        dataSource={BILLING_TYPES}
        className={classNameField}
      />
      <InputTextForm
        form={customerForm}
        className={classNameField}
        keyData="firstname"
      />
      <InputTextForm
        form={customerForm}
        className={classNameField}
        keyData="lastname"
      />

      <InputTextForm
        form={customerForm}
        className={classNameField}
        keyData="phone"
      />

      {formValues?.type === BILLING_TYPE.personal && (
        <>
          <InputTextForm
            keyData="email"
            form={customerForm}
            className={classNameField}
          />

          <InputTextForm
            keyData="citizenid"
            form={customerForm}
            className={classNameField}
          />
        </>
      )}
      {formValues?.type === BILLING_TYPE.business && (
        <>
          <InputTextForm
            keyData="company"
            form={customerForm}
            className={classNameField}
          />
          <InputTextForm
            keyData="taxId"
            form={customerForm}
            className={classNameField}
          />
        </>
      )}

      <AddressFormInput
        formDataValue={customerForm.values}
        formDataError={customerForm.errors}
        onChange={(newValue: any) => customerForm.setValues(newValue)}
        show={{ map: true }}
      />
    </div>
  );
};

export default CustomerInputBilling;
