import Drawer from "@components/shared/Drawer";
import { SelectInfinity } from "@components/shared/SelectInfinity";
import {
  DELIVERY_COURIER,
  MARKETPLACE_TYPES,
  SHIPPOP_TYPES,
} from "@constants/order";
import AppContext from "@helpers/context";
import { getFullAddressFromShipping, getFullName } from "@helpers/string";
import {
  createContractDelivery,
  createDelivery,
  editDelivery,
  updateContractDelivery,
} from "@network/api/delivery";
import { listAllStore } from "@network/api/store";
import classNames from "classnames";
import {
  DateInput,
  InputText,
  Notifications,
  Progress,
  RadioGroup,
  Select,
  ObjectUtils,
  Icon,
} from "d-react-components";
import { useFormik } from "formik";
import { filter, isEmpty, reduce, every, find, values } from "lodash";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import TableItemDelivery from "../content/TableItemDelivery";
import { OrderType } from "@constants/common";

const SHIPPOP_TYPES_ARRAY = ObjectUtils.mapObjectToArray(SHIPPOP_TYPES);

export const WarehouseSelect = ({
  onChange,
  value,
  error,
  disabled,
  className,
}: any) => {
  // const { warehouseList } = useContext(AppContext);
  const { t } = useTranslation();

  useEffect(() => {
    listAllStore(
      { is_warehouse_delivery: true },
      {
        pageIndex: 1,
        pageSize: 1,
      }
    ).then((resp) => {
      const isSingleStore = resp?.data?.data?.pagination?.items === 1;
      const stores = resp?.data?.data?.stores;
      if (isSingleStore && stores.length) {
        onChange(stores);
      }
    });
  }, []);

  return (
    <div className={classNames("col-span-1", className)}>
      {/* <Select
        label={t("fromWarehouse")}
        dataSource={warehouseList}
        onChange={onChange}
        placeholder={t("selectWarehouse")}
        value={value}
        getLabel={(option) => {
          return `${option?.name} - ${option?.code}`;
        }}
        error={error}
        showSearch
      /> */}
      <SelectInfinity
        value={value}
        error={error}
        label={t("fromWarehouse")}
        placeholder={t("selectWarehouse")}
        fetchFn={(body: any, paging: any) => {
          return listAllStore(
            { search: body.search, is_warehouse_delivery: true },
            {
              pageIndex: paging.page,
              pageSize: paging.per_page,
            }
          );
        }}
        disabled={disabled}
        dataPath="stores"
        onChange={onChange}
        getLabel={(option) => {
          return option ? `${option?.name} - ${option?.code}` : undefined;
        }}
      />
    </div>
  );
};

const SelectOrderPackage = ({ onChange, value, error }: any) => {
  const { packageList } = useContext(AppContext);
  const { t } = useTranslation();

  const dataSource = filter(
    packageList,
    (item) => !isEmpty(item.package_number)
  );
  if (isEmpty(dataSource)) return <div />;
  return (
    <div className="col-span-1">
      <Select
        label={t("package")}
        dataSource={dataSource}
        onChange={onChange}
        value={value}
        getLabel={(option) =>
          `${option.package_name} - ${option.package_number}`
        }
        getValue={(item) => item.package_number}
        error={error}
        placeholder={t("pleaseSelect")}
      />
    </div>
  );
};

const DeliveryInformationForm = ({
  formData,
  disabled,
  isEdit,
  showDeliveryOrderNo = true,
  showShipPopService = false,
  orderType,
}: any) => {
  const { t } = useTranslation();
  return (
    <div className="my-3 grid grid-cols-2 gap-4">
      <WarehouseSelect
        onChange={(value: any) => {
          formData.setFieldValue("selectedWarehouse", value);
        }}
        value={formData.values.selectedWarehouse}
        error={formData.errors.selectedWarehouse}
        disabled={disabled}
      />
      {showDeliveryOrderNo && (
        <div className="col-span-1">
          <InputText
            label={`${t("deliveryOrderNo")}(${t("optional")})`}
            placeholder={t("deliveryOrderNo")}
            onChange={formData.handleChange}
            value={formData.values?.code}
            disabled={disabled}
            name="code"
          />
        </div>
      )}
      {showShipPopService && (
        <div className="col-span-1">
          {/* <RadioGroup
            label={Messages.shippopSevices}
            onChange={(value) => {
              formData.setFieldValue("courierCode", value);
            }}
            dataSource={SHIPPOP_TYPES_ARRAY ?? []}
            value={formData.values.courierCode}
            disabled={isEdit}
          /> */}
          <Select
            label={t("shippopSevices")}
            dataSource={SHIPPOP_TYPES_ARRAY}
            onChange={(value) => {
              formData.setFieldValue("courierCode", value);
            }}
            value={formData.values.courierCode}
            disabled={isEdit}
            placeholder={t("pleaseSelect")}
            getLabel={(item) => t(item.label)}
          />
        </div>
      )}
      <div className="col-span-1">
        <DateInput
          onChange={(value) => {
            formData.setFieldValue("estimated", value);
          }}
          value={formData.values.estimated}
          disabled={disabled}
          label={t("expectedDateOfArrival")}
          error={formData.errors.estimated}
          format="DD/MM/YYYY"
          showTime={false}
        />
      </div>
      <SelectOrderPackage
        value={formData.values.package}
        onChange={(value: any) => {
          formData.setFieldValue("package", value);
        }}
      />
    </div>
  );
};

const checkDeliveryStockProduct = (productDelivery = []) => {
  let result = true;
  if (
    every(productDelivery, (deliveryItem: any) => {
      const isGroup = deliveryItem?.groups?.length > 0;
      if (isGroup) {
        const subProduct = deliveryItem?.groups ?? [];
        return !checkDeliveryStockProduct(subProduct);
      }
      return deliveryItem?.stock == 0;
    })
  ) {
    result = false;
  }
  return result;
};

const ManageDeliveryDrawer = ({
  openModal,
  onClose,
  delivery,
  readOnly,
  orderType = OrderType.NORMAL,
}: any) => {
  const { t } = useTranslation();
  const {
    deliveryTypes = [],
    order,
    shipping,
    onReloadOrderData,
    products,
    pickup,
    onReloadOrderDelivery,
    orderHasCod,
    ...appState
  } = useContext(AppContext);
  const isEdit = !!delivery;
  const isView =
    readOnly ||
    delivery?.status === "completed" ||
    delivery?.status === "cancel";

  const DeliverySchema = useMemo(
    () =>
      Yup.object().shape({
        selectedWarehouse: Yup.mixed().required(t("fieldRequired")),
        estimated:
          orderType === OrderType.NORMAL
            ? Yup.string().required(t("fieldRequired"))
            : Yup.string().nullable(),
        // comment: Yup.string().required(Messages.required),
      }),
    [t, orderType]
  );

  const onValidateDelivery = useCallback(
    (values) => {
      const errors: any = {};
      const { productDelivery, shippingId, deliveryCourier, courierCode } =
        values;
      if (!deliveryCourier && orderType === OrderType.NORMAL) {
        errors.deliveryCourier = "Required!";
      }

      if (!productDelivery || productDelivery.length === 0) {
        Notifications.showError(
          t("notification:canNotCreateDeliveryWithNoItems")
        );
        errors.productDelivery = "Invalid!";
      }

      // if (every(productDelivery, (proItem) => proItem.stock == 0)) {
      //   Notifications.showError(Messages.canNotCreateDeliveryWithNoItems);
      //   return false;
      // }

      if (!checkDeliveryStockProduct(productDelivery)) {
        Notifications.showError(
          t("notification:canNotCreateDeliveryWithNoItems")
        );
        errors.productDelivery = "Invalid!";
      }

      if (!shippingId) {
        Notifications.showError(t("notification:selectShippingAddressPlease"));
        errors.shippingId = "Required!";
      }

      if (
        deliveryCourier?.courier === DELIVERY_COURIER.SHIPPOP &&
        !courierCode
      ) {
        Notifications.showError(t("notification:pleaseSelectShippopServices"));
        errors.courierCode = "Required!";
      }

      return errors;
    },
    [t]
  );

  const getInitBodyPost = () => {
    if (delivery) {
      const { warehouse, shipping, estimated, courier_code } = delivery ?? {};
      return {
        ...delivery,
        deliveryCourier: delivery?.delivery,
        selectedWarehouse: [warehouse],
        shippingId: shipping?.id,
        estimated: estimated ? moment(estimated) : null,
        courierCode: courier_code,
      };
    }
    return {
      ...(shipping?.length > 0
        ? {
            shippingId: shipping[0]?.id,
            ...(orderType === OrderType.NORMAL
              ? {
                  estimated: shipping[0]?.expected_to_receive
                    ? moment(shipping[0]?.expected_to_receive)
                    : null,
                }
              : {}),
            ...(orderType === OrderType.RENTAL
              ? {
                  estimated: shipping[0]?.scheduled_delivery_date
                    ? moment(shipping[0]?.scheduled_delivery_date)
                    : null,
                }
              : {}),
          }
        : {}),
    };
  };
  const deliveryForm = useFormik({
    initialValues: getInitBodyPost(),
    validationSchema: DeliverySchema,
    validateOnBlur: false,
    validateOnChange: false,
    validate: onValidateDelivery,
    onSubmit: (values) => {
      if (isEdit) {
        return onEditDelivery(values);
      }
      return onCreateDelivery(values);
    },
  });

  const warehouseObject = useMemo(
    () => ({ id: deliveryForm.values.selectedWarehouse?.[0]?.id }),
    [deliveryForm.values.selectedWarehouse]
  );

  const onEditDelivery = async (values: any) => {
    const {
      selectedWarehouse,
      productDelivery,
      estimated,
      shippingId,
      courierCode,
      comment,
      code,
    } = values;
    const body = {
      //warehouse in order
      // warehouse_id: order?.store?.id,
      ...(orderType === OrderType.NORMAL
        ? {
            ...values,
            shippingid: shippingId,
            warehouseid: selectedWarehouse?.[0]?.id,
            orderid: order.id,
            comment,
            courier_code: courierCode,
            orderdeliveryid: delivery?.id,
            status: delivery?.status,
            arrived: delivery?.arrived,
            delivery: transferBodyProductDelivery(productDelivery),
            estimated: moment(estimated).valueOf(),
          }
        : {
            id: delivery.id,
            warehouse_id: selectedWarehouse?.[0]?.id,
            code,
            remark: comment,
            estimated: moment(estimated).valueOf(),
          }),
    };

    Progress.show(
      {
        method:
          orderType === OrderType.NORMAL
            ? editDelivery
            : updateContractDelivery,
        params: [body],
      },
      (res) => {
        onReloadOrderData && onReloadOrderData();
        // onReloadOrderDelivery && onReloadOrderDelivery();
        if (orderType === OrderType.RENTAL) {
          onReloadOrderDelivery && onReloadOrderDelivery();
        }
        onClose(false);
        Notifications.showSuccess(
          t("notification:updateInforDeliverySucessed")
        );
      },
      (err: any) => {
        Notifications.showError(err);
      }
    );
  };

  const onCreateDelivery = async (values: any) => {
    const {
      selectedWarehouse,
      productDelivery,
      estimated,
      deliveryCourier,
      shippingId,
      courierCode,
      comment,
      ...rest
    } = values;

    const body = {
      deliveryid: deliveryCourier?.id,
      ...rest,
      delivery: transferBodyProductDelivery(productDelivery),
      estimated: moment(estimated).valueOf(),
      courier_code: courierCode,
      warehouse_id: order?.store?.id,
      ...(orderType === OrderType.NORMAL
        ? {
            shippingid: shippingId,
            warehouseid: selectedWarehouse?.[0]?.id,
            orderid: order.id,
            comment,
          }
        : {
            shipping_id: shippingId,
            warehouse_id: selectedWarehouse?.[0]?.id,
            rent_id: order.id,
            remark: comment,
          }),
    };
    Progress.show(
      {
        method:
          orderType === OrderType.NORMAL
            ? createDelivery
            : createContractDelivery,
        params: [body],
      },
      (res) => {
        onReloadOrderData && onReloadOrderData();
        onReloadOrderDelivery && onReloadOrderDelivery();
        Notifications.showSuccess(t("notification:createDeliverySuccess"));
        onClose();
      },
      (err: any) => {
        Notifications.showError(err);
      }
    );
    return Promise.resolve();
  };

  const transferBodyProductDelivery = (products: any) => {
    let results: any = [];
    products.forEach((productItem: any) => {
      const { groups } = productItem;
      if (groups?.length > 0) {
        const subProductList = groups?.map((groupItem: any) => ({
          order_product_id: productItem?.id,
          order_product_group_id: groupItem?.id,
          stock: groupItem?.stock,
        }));
        results = results.concat(subProductList);
      } else {
        results.push({
          order_product_id: productItem?.id,
          stock: productItem?.stock,
        });
      }
    });
    return results;
  };

  const getDeliveryCourierObject = (id: any) =>
    filter(deliveryTypes, (item) => item.id === id)?.[0];

  const renderContentDelivery = () => {
    const isShipPop = deliveryCourier?.courier === DELIVERY_COURIER.SHIPPOP;
    if (!deliveryCourier && orderType === OrderType.NORMAL) return <div />;
    return (
      <DeliveryInformationForm
        formData={deliveryForm}
        disabled={isView}
        isEdit={isEdit}
        showDeliveryOrderNo={!isShipPop}
        showShipPopService={isShipPop}
        orderType={orderType}
      />
    );
  };

  /**
   * get  quantity of products remaining after subtracting delivered and picked number
   * @param {*} product
   * @param {*} parentPro
   */
  const getOnLeftItem = (product: any, parentPro: any) => {
    const deliveryList =
      appState?.delivery?.filter((item: any) => item.status !== "cancel") ?? [];
    const totalDelivered = getDeliveredNumber(deliveryList, product, parentPro);
    const totalPicked = getPickedNumber(pickup, product, parentPro);

    return product.quantity - totalDelivered - totalPicked;
  };
  /**
   * calculate number delivered of product from delivery list
   * @param {*} deliverList
   * @param {*} product
   * @param {*} parentPro
   */
  const getDeliveredNumber = (
    deliverList: any[] = [],
    product: any,
    parentPro: any
  ) => {
    const deliverItemList = reduce(
      deliverList,
      (list = [], item) => list.concat(item.items || item.products),
      []
    );
    const itemDeliver = getProsInDeliPickupList(
      deliverItemList,
      product,
      parentPro,
      orderType
    );
    return reduce(
      itemDeliver,
      (sum, item: any) => (sum += item.stock || item.quantity || 0),
      0
    );
  };
  /**
   * calculator number picked of product from pickup list
   * @param {*} pickupList
   * @param {*} product
   * @param {*} parentPro
   */
  const getPickedNumber = (
    pickupList: any[] = [],
    product: any,
    parentPro: any
  ) => {
    const pickupItemList = reduce(
      pickupList,
      (list = [], item) => list.concat(item.pickup),
      []
    );
    const itemPickups = getProsInDeliPickupList(
      pickupItemList,
      product,
      parentPro,
      orderType
    );
    return reduce(itemPickups, (sum, item: any) => (sum += item.stock), 0);
  };
  /**
   * filter products those are existed in delivery or pickup list
   * @param {*} itemList
   * @param {*} product
   * @param {*} parentPro
   */
  const getProsInDeliPickupList = (
    itemList = [],
    product: any,
    parentPro: any,
    orderType: OrderType
  ) => {
    return itemList.filter((pickupItem: any) => {
      if (parentPro) {
        return (
          pickupItem?.order_product_id === parentPro?.id &&
          pickupItem?.order_product_group_id === product?.id
        );
      }
      return orderType === OrderType.NORMAL
        ? pickupItem?.order_product_id === product?.id
        : pickupItem?.product?.id === product?.product?.id;
    });
  };

  const transferProductDelivery = (productList: any, parentPro: any) => {
    const deliveryItems = delivery?.items || delivery?.products || [];
    if (productList.length === 0) {
      return [];
    }
    const deliveryList =
      appState?.delivery?.filter((item: any) => item.status !== "cancel") ?? [];
    return productList?.map((proItem: any) => {
      const { groups = [] } = proItem;
      const proDeli = getProsInDeliPickupList(
        deliveryItems,
        proItem,
        parentPro,
        orderType
      );
      const productDelivery: any = proDeli?.[0];
      const pickupQty = getPickedNumber(pickup, proItem, parentPro);
      const deliveredQty = getDeliveredNumber(deliveryList, proItem, parentPro);

      if (productDelivery) {
        const groupProduct = transferProductDelivery(groups, proItem);
        return {
          ...productDelivery,
          ...proItem,
          groups: groupProduct,
          pickupQty,
          deliveredQty,
          leftItemCount: getOnLeftItem(proItem, parentPro),
        };
      }
      const groupProductDefault = transferProductDelivery(groups, proItem);
      return {
        ...proItem,
        groups: groupProductDefault,
        stock: 0,
        pickupQty,
        deliveredQty,
        leftItemCount: getOnLeftItem(proItem, parentPro),
      };
    });
  };

  const renderShippingInfoItem = (shipping: any) => (
    <div className="flex-column">
      <div>{getFullName(shipping)}</div>
      <div>{shipping.phone}</div>
      <div>{getFullAddressFromShipping(shipping)}</div>
    </div>
  );

  const renderShippingAddress = () => {
    return (
      <div className="createDeliveryShippingAddress">
        <RadioGroup
          label={t("shippingAddress")}
          value={deliveryForm.values.shippingId}
          onChange={(value) => {
            deliveryForm.setFieldValue("shippingId", value);
          }}
          dataSource={shipping}
          getLabel={renderShippingInfoItem}
          numberOfColumns={"1"}
        />
      </div>
    );
  };

  const { deliveryCourier } = deliveryForm.values;

  const renderTitleModal = (
    <div className="headerModalConfirmPickup flex">
      <div className="cardTitleText flex-1">
        {isEdit ? t("listItem") : t("createDelivery")}
      </div>
      {/* {renderPrintLabel()} */}
    </div>
  );

  const deliveryTypesList = useMemo(() => {
    deliveryForm.setFieldValue(
      "deliveryCourier",
      find(deliveryTypes, { courier: DELIVERY_COURIER.LOTUS_TRUCK })
    );
    return filter(deliveryTypes, (type) => {
      const currentType: any = find(MARKETPLACE_TYPES, { value: type.courier });
      if (currentType && currentType.value !== order?.referral_source) {
        return false;
      }
      if (
        orderType === OrderType.RENTAL &&
        type.courier === DELIVERY_COURIER.SHIPPOP
      ) {
        return false;
      }
      return true;
    });
  }, [deliveryTypes]);

  return (
    <Drawer
      open={openModal}
      onClose={onClose}
      title={renderTitleModal}
      size="auto"
      width={1000}
      disableSave={isView}
      onSave={() => {
        deliveryForm.handleSubmit();
        return Promise.resolve();
      }}
    >
      <div className="pb-12">
        <RadioGroup
          value={deliveryCourier?.id}
          onChange={(value) =>
            deliveryForm.setFieldValue(
              "deliveryCourier",
              getDeliveryCourierObject(value)
            )
          }
          dataSource={deliveryTypesList}
          getLabel={(item) => item.name}
          disabled={isEdit || orderType === OrderType.RENTAL}
        />
        {renderContentDelivery()}

        {orderType === OrderType.RENTAL ||
        (orderType === OrderType.NORMAL && !!deliveryCourier) ? (
          <>
            <div className="overflow-auto my-3">
              <TableItemDelivery
                onChange={(productDelivery: any) =>
                  deliveryForm.setFieldValue("productDelivery", productDelivery)
                }
                products={transferProductDelivery(products, null)}
                disabled={
                  isView ||
                  (orderType === OrderType.RENTAL && isEdit) ||
                  orderHasCod
                }
                selectedWarehouse={warehouseObject}
                orderType={orderType}
                hasCod={orderHasCod}
              />
            </div>
            {renderShippingAddress()}
            <InputText
              label={t("remark")}
              placeholder={t("pleaseInputTheNoteForThisUpdate")}
              multiple
              onChange={deliveryForm.handleChange}
              disabled={isView}
              value={deliveryForm.values.comment}
              error={deliveryForm.errors.comment as string}
              className="mt-3"
              name="comment"
            />
          </>
        ) : null}
      </div>
    </Drawer>
  );
};

export default ManageDeliveryDrawer;
