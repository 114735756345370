import UserNameView from "@components/shared/UserNameView";
import KpiAPI from "@network/api/kpi";
import UserAPI from "@network/api/user";
import { Spin } from "antd";
import classNames from "classnames";
import {
  AwesomeTableComponent,
  Icon,
  InputTextSearch,
} from "d-react-components";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import KPIHistory from "./KPIHistory";
import { useUpdateEffect } from "react-use";

const KPI = () => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState();
  const [openKPIHistory, setOpenKPIHistory] = useState(false);
  const [selectedKPI, setSelectedKPI] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>();
  const [listStaff, setListStaff] = useState<any[]>([]);
  const [paging, setPaging] = useState<any>({ currentPage: 0 });
  const tableRef = useRef<any>();

  const columns = useMemo(
    () => [
      {
        title: t("year"),
        dataIndex: "date",
        render: (data: any) => moment(data, "YYYY-MM-DD").format("YYYY"),
      },
      {
        title: t("month"),
        dataIndex: "date",
        render: (data: any) => moment(data, "YYYY-MM-DD").format("MM"),
      },
      {
        title: t("targetKPI"),
        dataIndex: "kpi",
        render: (data: string, item: any) => (
          <div className="flex items-center">
            <CurrencyFormat
              value={data}
              displayType="text"
              thousandSeparator={true}
            />
            <Icon
              name="manage_search"
              className="text-primary cursor-pointer ml-1"
              onClick={() => {
                setSelectedKPI(item);
                setOpenKPIHistory(true);
              }}
            />
          </div>
        ),
      },
    ],
    [t]
  );

  const loadUser = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    UserAPI.list(
      {
        search: searchValue,
      },
      {
        pageIndex: paging.currentPage + 1,
        pageSize: 20,
      }
    )
      .then((resp) => {
        const mapListStaff = resp?.data?.data?.user?.map((u: any) => ({
          ...u,
          companyId: u.employee_id,
        }));
        setListStaff([
          ...(paging.currentPage === 0 ? [] : listStaff),
          ...(mapListStaff ?? []),
        ]);
        setPaging(resp?.data?.data?.pagination);
        if (!selectedUser) {
          setSelectedUser(mapListStaff?.[0]);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const selectUser = (user: any) => {
    setSelectedUser(user);
  };

  const submitSearchUser = () => {
    setPaging({ currentPage: 0 });
  };

  const source = (paging: any) => {
    const body = {
      admin_id: selectedUser?.id,
    };
    return KpiAPI.listKpiStaff(paging, body);
  };

  const transformer = (res: any) => {
    return res?.data?.data?.kpi ?? [];
  };

  useEffect(() => {
    tableRef?.current?.refresh();
  }, [selectedUser]);

  useEffect(() => {
    loadUser();
  }, [paging]);

  return (
    <div className="p-3">
      <div className="grid grid-cols-5 gap-4">
        <div className="col-span-1">
          <div className="bg-blue-100 p-3">
            <h6>KPI Watch</h6>
          </div>
          <div className="bg-gray-200 px-3 py-2">
            <InputTextSearch
              className="bg-white"
              placeholder={t("search")}
              value={searchValue}
              onChange={(e: any) => setSearchValue(e?.target?.value)}
              onSubmit={() => submitSearchUser()}
            />
          </div>
          <div className="h-[calc(100vh-180px)] overflow-auto">
            <InfiniteScroll
              pageStart={0}
              loadMore={loadUser}
              hasMore={listStaff.length < paging.items}
              useWindow={false}
              loader={
                <div
                  className="py-2 w-full flex items-center justify-content-center"
                  key={0}
                >
                  <Spin />
                </div>
              }
            >
              {listStaff?.map((user, index) => (
                <div
                  className={classNames(
                    `p-2 bg-[#fff] hover:bg-blue-100 cursor-pointer border-b border-gray-200`,
                    {
                      "bg-blue-100": user?.id === selectedUser?.id,
                    }
                  )}
                  key={user.id}
                  onClick={() => selectUser(user)}
                >
                  <UserNameView className="text-gray-900" user={user} />
                </div>
              ))}
            </InfiniteScroll>
          </div>
        </div>
        <div className="col-span-4">
          <div className="bg-primary p-3">
            <UserNameView className="text-white" user={selectedUser} />
          </div>
          <div className="p-3 bg-white">
            <h4 className="mb-3">KPI Desk</h4>
            {selectedUser && (
              <AwesomeTableComponent
                ref={(ref) => {
                  tableRef.current = ref;
                }}
                transformer={transformer}
                source={source}
                columns={columns}
                tableLayout="fixed"
                className="mt-3"
              />
            )}
            {openKPIHistory && (
              <KPIHistory
                open={openKPIHistory}
                onClose={() => setOpenKPIHistory(false)}
                user={selectedUser}
                kpi={selectedKPI}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default KPI;
