import AppContext from "@helpers/context";
import { validateImportProductionPrintingNew } from "@network/api/product-print";
import { Spin } from "antd";
import { find, forEach, includes, isEmpty, join, map, replace } from "lodash";
import { useContext, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { IMPORT_FIELDS } from "./ProductionPrintingImportBrowseFile";

export const getImportProductLabel = (
  importData: any,
  importFields: any,
  parentName?: any
) => {
  const body: any = { sku: importData.sku };
  forEach(importFields, (field) => {
    if (field?.fields?.length > 0) {
      body[field.name] = getImportProductLabel(
        importData,
        field?.fields,
        field?.name
      );
    } else {
      const keyName = isEmpty(parentName)
        ? field.name
        : `${parentName}_${field.name}`;
      body[field.name] = importData[keyName];
    }
  });
  return body;
};

const ProductionPrintingImportValidation = () => {
  const { formImport } = useContext(AppContext);
  const [validateLoading, setValidateLoading] = useState(true);

  const { fileImport } = formImport.values;

  const { t } = useTranslation();

  useEffect(() => {
    try {
      onValidateImportDatabase(fileImport?.data);
    } catch (err) {
      setValidateLoading(false);
    }
  }, []);

  const onValidateImportDatabase = async (importData: any) => {
    const validation = await getValidateAPI(importData);
    formImport.setFieldValue("fileImport", {
      ...fileImport,
      errors: validation,
    });
    setValidateLoading(false);
  };

  const getErrorItemsData = () => {
    const fieldList = Object.keys(fileImport?.data[0]);
    const header = [...fieldList, "Message"];
    const dataResult = [header];

    forEach(fileImport?.data, (importDataItem) => {
      const messages: string[] = [];
      const navRp = importDataItem.nav_rp;
      const isError = !isEmpty(fileImport?.errors?.[navRp]);
      if (isError) {
        messages.push(...fileImport?.errors?.[navRp]);
      }

      if (messages?.length) {
        dataResult.push([
          ...map(fieldList, (item) => importDataItem?.[item]),
          join(messages, ", "),
        ]);
      }
    });

    dataResult[0] = [
      ...map(
        fieldList,
        (key) => (find(IMPORT_FIELDS, (item) => item.id === key) as any).label
      ),
      "Messages",
    ];

    return dataResult;
  };

  const getSuccessItemsData = () => {
    const fieldList = Object.keys(fileImport?.data[0]);

    const header = [...fieldList];
    const dataResult = [header];

    forEach(fileImport?.data, (importDataItem) => {
      const messages: string[] = [];
      const navRp = importDataItem.nav_rp;
      const isError = !isEmpty(fileImport?.errors?.[navRp]);
      if (isError) {
        messages.push(...fileImport?.errors?.[navRp]);
      }

      if (!messages?.length) {
        dataResult.push([...map(fieldList, (item) => importDataItem?.[item])]);
      }
    });
    dataResult[0] = [
      ...map(
        fieldList,
        (key) => (find(IMPORT_FIELDS, (item) => item.id === key) as any).label
      ),
      "Messages",
    ];

    return dataResult;
  };

  const getValidateAPI = async (importData: any) => {
    const body = {
      payload: importData,
    };
    return validateImportProductionPrintingNew(body).then(
      (res: any) => {
        const validation = res?.data?.data;
        return {};
      },
      (err) => {
        const validation = err?.data?.errors;
        return validation;
      }
    );
  };

  const renderRowInfo = (title: string, content: any) => (
    <div className="d-flex mt-3">
      <text className="font-weight-bold">{`${title}: `}</text>
      <text className="ml-1">{content}</text>
    </div>
  );

  const renderValidateError = () => {
    const errorItems = getErrorItemsData();
    if (errorItems?.length > 1) {
      return (
        <div className="d-flex ">
          <text className="text-error">
            {errorItems?.length - 1} {t("errors")}
          </text>
          <CSVLink
            data={errorItems}
            filename="invalid-import-production-printing.csv"
          >
            <div className="ml-1 text-primary">{t("downloadLog")}</div>
          </CSVLink>
        </div>
      );
    }
    return <text className="text-success">{t("readyToImport")}</text>;
  };

  const renderValidateSuccess = () => {
    const errorItems = getErrorItemsData();

    return (
      <div className="d-flex ">
        <text className="text-success">
          {fileImport?.data?.length + 1 - errorItems?.length} {t("success")}
        </text>
        <CSVLink
          data={getSuccessItemsData()}
          filename="ready-import-production-printing.csv"
        >
          <div className="ml-1 text-primary">{t("downloadLog")}</div>
        </CSVLink>
      </div>
    );
  };

  if (validateLoading) {
    return (
      <div className="flex-center p-5">
        <Spin />
      </div>
    );
  }

  return (
    <div className="p-4">
      <div
        className="d-flex flex-column p-4"
        style={{ backgroundColor: "rgba(33, 150, 243, 0.1)" }}
      >
        {renderRowInfo(t("fileName"), fileImport?.name)}
        {renderRowInfo(t("validateStatus"), renderValidateError())}
        {renderRowInfo(t("validateStatus"), renderValidateSuccess())}
      </div>
      <div className="mt-5">
        {renderRowInfo(`*${t("lastWarning")}`, t("pleaseCheckCarefully"))}
      </div>
    </div>
  );
};

export default ProductionPrintingImportValidation;
