import UserAvatarName from "@components/shared/UserAvatarName";
import { DELIVERY_STATUS_LIST } from "@constants/order";
import styled from "@emotion/styled";
import AppContext from "@helpers/context";
import {
  getDeliveryTrackingList,
  getSummaryDelivery,
} from "@network/api/delivery";
import {
  AwesomeTableComponent,
  Badge,
  HeaderTable,
  ITabItem,
  ObjectUtils,
  TabBar,
  TimeUtils,
  useFirstTime,
  ViewLabelStatus,
} from "d-react-components";
import { debounce, map, reduce } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { JsonParam, useQueryParam } from "use-query-params";
import DeliveryTrackingFilterDrawer from "./DeliveryTrackingFilterDrawer";
import moment from "moment";
import { generatePath, Link } from "react-router-dom";
import { Path } from "@components/layout/Path";
import DeliveryTrackingExportDrawer from "./DeliveryTrackingExportDrawer";

interface IDeliveryTabs {
  tabSelected: ITabItem;
  onChangeTab: (value: ITabItem) => void;
}

const TAB_LIST = [
  { id: "all", label: "all", value: "all" },
  ...DELIVERY_STATUS_LIST,
];

const DeliveryTrackingTabs = ({
  tabSelected,
  onChangeTab: onChangeTabSelected,
}: IDeliveryTabs) => {
  const [summary, setSummary] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    loadSummaryDelivery();
  }, []);

  const loadSummaryDelivery = () => {
    getSummaryDelivery().then((res: any) => {
      const summaryRes = res?.data?.data?.delivery ?? [];
      const allNumber = reduce(summaryRes, (sum, item) => sum + item.total, 0);
      const summary = ObjectUtils.mapArrayToObject(
        [...summaryRes, { status: "all", total: allNumber }],
        (item: any) => item.status
      );
      setSummary(summary);
    });
  };

  return (
    <TabBar
      dataSource={TAB_LIST}
      onChange={onChangeTabSelected}
      value={tabSelected}
      getLabel={(item) => {
        const summaryNumber = (summary as any)?.[item.value]?.total ?? 0;
        const badgeNumber = summaryNumber > 999 ? "999+" : summaryNumber;
        return (
          <StyledBadge className="flex items-center">
            <span>{t(item.label as string) || "N/A"}</span>
            <Badge
              index={badgeNumber}
              variant="index"
              size="x-large"
              color="red"
            />
          </StyledBadge>
        );
      }}
      isScroll
      hideScrollBar
      minWidthItem={"100%"}
      classNameItem="justify-content-start text-gray-500 bg-white shrink-0 grow-0 border-right-0 border-left-0"
      variant="horizontal"
    />
  );
};

const StyledBadge = styled.div`
  .d-badge__badge-wrapper div {
    font-size: 8px !important;
  }
`;

const DeliveryTrackingTable = () => {
  const [tabSelected, setTabSelected] = useState<any>();
  const { t } = useTranslation();
  const searchRef = useRef<any>();
  const tableRef = useRef<any>();
  const [filter = {}, setFilter] = useQueryParam<any>("filter", JsonParam);

  const [openFilter, setOpenFilter] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const isFiltered = useMemo(() => Object.keys(filter).length > 0, [filter]);
  const isFirstTime = useFirstTime();

  useEffect(() => {
    if (isFirstTime) return;
    tableRef.current?.refresh();
  }, [JSON.stringify(filter), tabSelected]);

  const columns = [
    {
      title: t("doNo"),
      dataIndex: "ref",
    },
    {
      title: t("orderNo"),
      dataIndex: "order",
      render: (order: any) => (
        <Link to={generatePath(Path.ORDER_DETAIL, { id: order?.id })}>
          {order.orderid}
        </Link>
      ),
    },
    {
      title: t("fromWarehouse"),
      dataIndex: "warehouse",
      render: (warehouse: any) =>
        `${warehouse?.name} - ${warehouse?.code ?? ""}`,
    },
    {
      title: t("logisticType"),
      dataIndex: "delivery",
      render: (delivery: any) => delivery?.name,
    },
    {
      title: t("itemQty"),
      dataIndex: "item",
      render: (qty: any) => <div className="min-w-[100px]">{qty}</div>,
    },
    {
      title: t("status"),
      dataIndex: "status",
      render: (status: any) => (
        <ViewLabelStatus
          status={status}
          listStatus={DELIVERY_STATUS_LIST}
          getLabel={(item) => t(item.label)}
        />
      ),
    },
    {
      title: t("customerPhone"),
      dataIndex: "shipping",
      render: (shipping: any) => shipping?.phone,
    },
    {
      title: t("provinceName"),
      dataIndex: "shipping",
      render: (shipping: any) => shipping?.province?.name,
    },
    {
      title: t("estArrival"),
      dataIndex: "estimated",
      render: TimeUtils.toDateTime,
    },
    {
      title: t("channel"),
      dataIndex: "order",
      render: (order: any) => order?.channel?.name,
    },
    {
      title: t("createdBy"),
      dataIndex: "by",
      render: (by: any) => <UserAvatarName user={by} />,
    },
    {
      title: t("createAt"),
      dataIndex: "created",
      render: (created: any) => TimeUtils.toDateTime(created),
    },
  ];

  const source = (paging: any) => {
    const body: any = {
      search: searchRef.current,
      status:
        !tabSelected || tabSelected?.id === "all" ? null : [tabSelected?.id],
    };
    if (filter) {
      const {
        channels,
        from,
        to,
        delivery,
        deliveryService,
        status,
        expectArrivalDate,
        warehouse,
        createdBy,
      } = filter;

      if (status && status.length > 0) {
        if (!body.status) {
          body.status = status;
        } else {
          body.status = [...body.status, ...status];
        }
      }
      body.channels = channels?.length && map(channels, (item) => item.id);
      body.from = from ? moment(from)?.valueOf() : null;
      body.to = to ? moment(to)?.valueOf() : null;
      body.delivery = delivery && delivery;
      body.service = deliveryService && deliveryService;
      body.expectedDateArrival = expectArrivalDate
        ? moment(expectArrivalDate)?.valueOf()
        : null;
      body.warehouseid = warehouse?.length && map(warehouse, (item) => item.id);
      body.createdBy = createdBy?.length && map(createdBy, (item) => item.id);
    }
    return getDeliveryTrackingList(body, paging);
  };

  const onChangeTextSearch = debounce((text) => {
    searchRef.current = text;
    tableRef.current.refresh();
  }, 500);

  return (
    <AppContext.Provider value={{}}>
      <div className="bg-white">
        <HeaderTable
          label={t("deliveryTracking")}
          onChangeText={onChangeTextSearch}
          className="app-layout__page-header"
          onClickFilter={() => setOpenFilter(true)}
          onClickExport={() => setOpenExport(true)}
          isFiltered={isFiltered}
        />
        <div className="d-flex overflow-auto">
          <DeliveryTrackingTabs
            tabSelected={tabSelected}
            onChangeTab={setTabSelected}
          />
        </div>
        <AwesomeTableComponent
          source={source}
          transformer={(response) => {
            return response?.data?.data?.delivery ?? [];
          }}
          columns={columns}
          ref={(ref) => (tableRef.current = ref)}
        />
        {openFilter && (
          <DeliveryTrackingFilterDrawer
            open={openFilter}
            onClose={() => {
              setOpenFilter(false);
            }}
            defaultValue={filter}
            onFilter={setFilter}
          />
        )}
        {openExport && (
          <DeliveryTrackingExportDrawer
            open={openExport}
            onClose={() => {
              setOpenExport(false);
            }}
          />
        )}
      </div>
    </AppContext.Provider>
  );
};
export default DeliveryTrackingTable;
