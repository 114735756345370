import { BILLING_TYPE, TYPE_OF_PLACES } from "@constants/common";
import AppContext from "@helpers/context";
import { getDistrictList, getSubDistrictList } from "@network/api/address";
import { InputText, Select } from "d-react-components";
import { filter, find } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export interface CustomerAddressForm {
  firstname?: string;
  lastname?: string;
  nickname?: string;
  address?: string;
  province?: any;
  subdistrict?: any;
  postcode?: any;
  phone?: string;
  city?: any;
  place?: string;
  addressName?: string;
  default?: boolean;
  type?: string;
  citizenid?: string;
  company?: string;
  taxid?: string;
  expectedDateReceive?: string;
  note?: string;
  district?: any;
  latitude?: string;
  longitude?: string;
  email?: string;
  addressFormCard?: string;
  alternative_phone?: string;
  scheduled_delivery_date?: string;
}

const AddressFieldInput = ({
  form,
  isBilling = false,
  billingType = "",
}: any) => {
  const { provinceList, loadProvinceList } = useContext(AppContext);
  const [postcodeList, setPostCodeList] = useState<any[]>([]);
  const [cityList, setCityList] = useState<any[]>([]);
  const [subdistrictList, setSubdistrictList] = useState<any[]>([]);
  const { values, errors, setFieldValue } = form;
  const { t } = useTranslation();

  const loadPostCodeData = (subdistrict: any) => {
    if (!subdistrict) {
      return;
    }
    const postcodeResult =
      filter(subdistrictList, (pro) => pro.id === subdistrict)?.[0]?.code ?? [];
    setPostCodeList(postcodeResult);
  };

  const loadCityData = (province: any) => {
    getDistrictList(province).then((res) => {
      const cityListRes = res?.data?.data?.city ?? [];
      setCityList(cityListRes);
    });
  };

  const loadSubdistrictData = (city: any) => {
    getSubDistrictList(city).then((res) => {
      const subdistrict = res?.data?.data?.subdistrict ?? [];
      setSubdistrictList(subdistrict);
    });
  };

  useEffect(() => {
    loadCityData(values?.province);
  }, [values?.province]);

  useEffect(() => {
    loadSubdistrictData(values?.city);
  }, [values?.city]);

  useEffect(() => {
    loadPostCodeData(values?.subdistrict?.id);
  }, [values?.subdistrict]);

  useEffect(() => {
    if (!provinceList?.length) {
      loadProvinceList && loadProvinceList();
    }
  }, []);

  return (
    <>
      <div className="grid grid-cols-1 gap-4">
        <div className="col-span-1">
          <InputText
            label={t("firstname")}
            value={values.firstname}
            onChange={(e) =>
              setFieldValue("firstname", e?.currentTarget?.value)
            }
            error={errors?.firstname}
          />
        </div>
        <div className="col-span-1">
          <InputText
            label={t("lastname")}
            value={values.lastname}
            onChange={(e) => setFieldValue("lastname", e?.currentTarget?.value)}
            error={errors?.lastname}
          />
        </div>
        {isBilling && billingType === BILLING_TYPE.personal && (
          <>
            <div className="col-span-1">
              <InputText
                label={t("email")}
                value={values.email}
                onChange={(e) =>
                  setFieldValue("email", e?.currentTarget?.value)
                }
                error={errors?.email}
              />
            </div>
            <div className="col-span-1">
              <InputText
                label={t("citizenId")}
                value={values.citizenid}
                onChange={(e) =>
                  setFieldValue("citizenid", e?.currentTarget?.value)
                }
                error={errors?.citizenid}
              />
            </div>
          </>
        )}
        {isBilling && billingType === BILLING_TYPE.business && (
          <>
            <div className="col-span-1">
              <InputText
                label={t("company")}
                value={values.company}
                onChange={(e) =>
                  setFieldValue("company", e?.currentTarget?.value)
                }
                error={errors?.company}
              />
            </div>
            <div className="col-span-1">
              <InputText
                label={t("taxId")}
                value={values.taxid}
                onChange={(e) =>
                  setFieldValue("taxid", e?.currentTarget?.value)
                }
                error={errors?.taxid}
              />
            </div>
          </>
        )}
        {!isBilling && (
          <div className="col-span-1">
            <InputText
              label={t("nickname")}
              value={values.nickname}
              onChange={(e) =>
                setFieldValue("nickname", e?.currentTarget?.value)
              }
              error={errors?.nickname}
            />
          </div>
        )}
        <div className="col-span-1">
          <InputText
            label={t("phone")}
            value={values.phone}
            onChange={(e) => setFieldValue("phone", e?.currentTarget?.value)}
            error={errors?.phone}
          />
        </div>
        {!isBilling && (
          <div className="col-span-1">
            <InputText
              label={t("alternativePhone")}
              value={values.alternative_phone}
              onChange={(e) =>
                setFieldValue("alternative_phone", e?.currentTarget?.value)
              }
              error={errors?.alternative_phone}
            />
          </div>
        )}
        {isBilling && billingType === BILLING_TYPE.business && (
          <>
            <div className="col-span-1">
              <InputText
                label={t("email")}
                value={values.email}
                onChange={(e) =>
                  setFieldValue("email", e?.currentTarget?.value)
                }
                error={errors?.email}
              />
            </div>
          </>
        )}
        <div className="col-span-1">
          <Select
            label={t("province")}
            dataSource={provinceList}
            value={values?.province?.id}
            onChange={(val) => {
              setFieldValue(
                "province",
                find(provinceList, (item) => item.id === val)
              );
              setFieldValue("city", undefined);
              setFieldValue("subdistrict", undefined);
              setFieldValue("postcode", undefined);
            }}
            getLabel={(item) => item?.name}
            showSearch
            error={errors?.province}
            placeholder={t("pleaseSelect")}
          />
        </div>
        <div className="col-span-1">
          <Select
            label={t("city")}
            dataSource={cityList}
            value={values?.city?.id}
            onChange={(val) => {
              setFieldValue(
                "city",
                find(cityList, (item) => item.id === val)
              );
              setFieldValue("subdistrict", undefined);
              setFieldValue("postcode", undefined);
            }}
            getLabel={(item) => item?.name}
            showSearch
            disabled={!values.province}
            error={errors?.city}
            placeholder={t("pleaseSelect")}
          />
        </div>
        <div className="col-span-1">
          <Select
            label={t("subdistrict")}
            dataSource={subdistrictList}
            value={values?.subdistrict?.id}
            onChange={(val) => {
              setFieldValue(
                "subdistrict",
                find(subdistrictList, (item) => item.id === val)
              );
              setFieldValue("postcode", undefined);
            }}
            getLabel={(item) => item?.name}
            showSearch
            disabled={!values.city}
            error={errors?.subdistrict}
            placeholder={t("pleaseSelect")}
          />
        </div>
        <div className="col-span-1">
          <Select
            label={t("postcode")}
            dataSource={postcodeList}
            value={values?.postcode}
            onChange={(val) => {
              setFieldValue("postcode", val);
            }}
            getLabel={(item) => item?.postcode}
            getValue={(item) => item?.postcode}
            showSearch
            disabled={!values.subdistrict}
            error={errors?.postcode}
            placeholder={t("pleaseSelect")}
          />
        </div>
        <div className="col-span-1">
          <InputText
            label={t("address")}
            value={values.address}
            onChange={(e) => setFieldValue("address", e?.currentTarget?.value)}
            error={errors?.address}
          />
        </div>
        {values?.addressFormCard && (
          <div className="col-span-1">
            <InputText
              label={t("addressFormCard")}
              value={values.addressFormCard}
              disabled
            />
          </div>
        )}
        <div className="col-span-1">
          <Select
            label={t("typeOfPlace")}
            dataSource={TYPE_OF_PLACES}
            value={values?.place}
            error={errors?.place}
            onChange={(val) => {
              setFieldValue("place", val);
            }}
            getLabel={(item) => t(item?.content)}
          />
        </div>
      </div>
    </>
  );
};

export default AddressFieldInput;
