import { map } from "lodash";
import { IAdmin } from "./admin";

export interface IVehicle {
  id: string;
  vehicle_type: string;
  registration: string;
  brand: string;
  model: string;
  year: string;
  color: string;
  internal_code: string;
  vin_no: string;
  engine_no: string;
  terminal_serial: string;
  vehicle_description: string;
  status: string;
  vehicle_ownership: string;
  company: string;
  driver: IAdmin;
  remark: string;
  name: string;
  attachment: string[];
  created: number;
}

export const mapVehicleToServer = (input: any) => {
  const { defaultDriver, attachments } = input;

  const generalInput = mapVehicleGeneralToServer(input);
  return {
    ...generalInput,

    upload_media: map(attachments, (item) => item.key),
    // vehicle: map(attachments, (item) => item.key),
  };
};

export const mapVehicleGeneralToServer = (input: any) => {
  const {
    type,
    registrationNo,
    brand,
    model,
    year,
    color,
    internalCode,
    vinNo,
    engineNo,
    terminalSerial,
    vehicleDesc,
    status,
    ownership,
    company,
    vehicleRemark,
    defaultDriver,
  } = input;
  return {
    driver_id: defaultDriver?.id,
    vehicle_type: type,
    registration: registrationNo,
    brand,
    model,
    year: year.year(),
    color,
    internal_code: internalCode,
    vin_no: vinNo,
    engine_no: engineNo,
    terminal_serial: terminalSerial,
    vehicle_description: vehicleDesc,
    status,
    vehicle_ownership: ownership,
    company_id: company?.id,
    remark: vehicleRemark,
  };
};
