import DeliveryForm from "@components/delivery-tstock/components/DeliveryForm";
import Drawer from "@components/shared/Drawer";
import AppContext from "@helpers/context";
import { mapTransferStockDeliveryToServer } from "@interfaces/transfer-stock";
import { createDeliveryTransferStock } from "@network/api/transfer-stock";
import { Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import { every } from "lodash";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

function TransferStockAddDeliveryDrawer({ open, onClose }: any) {
  const { transferDetail, loadTransferDetail } = useContext(AppContext);
  const { t } = useTranslation();

  const deliveryForm = useFormik<any>({
    initialValues: {
      transferId: transferDetail?.id,
    } as any,
    validateOnChange: false,
    validateOnBlur: false,
    validate: (values) => {
      const errors: any = {};
      if (every(values?.products, (iPro) => iPro?.stock === 0 || !iPro.stock)) {
        errors.products = t("canNotCreateDelivery");
      }
      return errors;
    },
    onSubmit: (values: any) => {
      const input = mapTransferStockDeliveryToServer(values);
      onCreateDelivery(input);
    },
  });

  const onCreateDelivery = async (input: any) => {
    const body = { ...input };
    // if (isEdit) {
    //   body.id = data?.id;
    //   return Progress.show(
    //     [{ method: API.transferStockEditDelivery, params: [body] }],
    //     (res) => {
    //       onClose && onClose();
    //       loadTransferDetail && loadTransferDetail();
    //       return Notifications.showSuccess(Messages.successfullyEditedDelivery);
    //     }
    //   );
    // }
    return Progress.show(
      [{ method: createDeliveryTransferStock, params: [body] }],
      (res) => {
        onClose && onClose();
        loadTransferDetail();
        return Notifications.showSuccess(t("createDeliverySuccess"));
      }
    );
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("addDelivery")}
      onSave={() => deliveryForm.handleSubmit()}
      size="auto"
      width={"80%"}
    >
      <div>
        <DeliveryForm
          channelId={transferDetail?.channel?.id}
          warehouseId={transferDetail?.transfer_origin?.id}
          deliveryItems={[]}
          deliveryForm={deliveryForm}
          delivery={transferDetail?.delivery}
          transferProduct={transferDetail?.items}
        />
      </div>
    </Drawer>
  );
}

export default TransferStockAddDeliveryDrawer;
