import ImageIcon from "@common/Icon";
import Drawer from "@components/shared/Drawer";
import {
  CHANNEL_GENERAL_KEYS,
  CHANNEL_REPORT_SOURCES,
} from "@constants/channel";

import AppContext from "@helpers/context";
import { IChannel } from "@interfaces/channel";
import ChannelAPI from "@network/api/channel";
import {
  IRowsKey,
  Notifications,
  ObjectUtils,
  Progress,
  ViewRowInterchange,
} from "d-react-components";
import { useFormik } from "formik";
import { find, join, map } from "lodash";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ChannelFormSetting from "../components/ChannelFormSetting";

function ChannelDetailSettingUpdateDrawer({
  open,
  onClose,
  channelId,
  defaultValue,
  onUpdateSuccess,
}: any) {
  const { t } = useTranslation();

  const generalForm = useFormik({
    initialValues: defaultValue ?? {},
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: ChannelGeneralSchema,
    onSubmit: (values) => {
      const formValue: any = ObjectUtils.mapFieldsLangsCTS(
        values,
        CHANNEL_GENERAL_KEYS as any
      );

      console.log("formValue", formValue);

      const body = { id: channelId, ...formValue };
      Progress.show(
        { method: ChannelAPI.update, params: [body] },
        () => {
          Notifications.showSuccess(t("updateChannelSuccess"));
          onUpdateSuccess();
          onClose();
        },
        (error: any) => {
          console.log("error", error);
          alert(JSON.stringify(error));
        }
      );
    },
  });
  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("updateChannel")}
      onSave={() => generalForm.handleSubmit()}
      size="auto"
      width="30vw"
    >
      <ChannelFormSetting form={generalForm} fieldClassName="col-12" />
    </Drawer>
  );
}

const ChannelDetailSetting = () => {
  const { channel, loadChannelDetail } = useContext(AppContext);
  const [openUpdate, setOpenUpdate] = useState(false);
  const { t } = useTranslation();

  const { id } = channel ?? {};

  const DETAILS_KEY = useMemo<IRowsKey<IChannel>[]>(
    () => [
      {
        id: "domain_order",
        label: t("domainShareOrder"),
      },
      {
        id: "color",
        label: t("color"),
        renderContent: ({ data }) => {
          return (
            <div className="flex-row justify-content-end align-items-center">
              <div
                style={{
                  backgroundColor: data,
                  height: 15,
                  width: 15,
                  marginRight: 3,
                }}
              />
              {data}
            </div>
          );
        },
      },
      {
        id: "is_send_mail_created_order_to_customer",
        label: t("sendEmailToCreatedOrder"),
        renderContent: ({ data }) => {
          return data === 1 ? t("enabled") : t("disabled");
        },
      },
      {
        id: "send_report_to_line",
        label: t("sendReportToLine"),
        renderContent: ({ data }) => {
          return data === 1 ? t("enabled") : t("disabled");
        },
      },
      {
        id: "is_channel_dealer",
        label: t("isChannelDealer"),
        renderContent: ({ data }) => {
          return data === 1 ? t("enabled") : t("disabled");
        },
      },
      {
        id: "channel_source",
        label: t("reportOrderSource"),
        renderContent: ({ data }) => {
          const sourceList = map(data, (item) => {
            const source = find(
              CHANNEL_REPORT_SOURCES,
              (source) => source.id === item
            );
            return t(source?.label ?? "");
          });
          return join(sourceList, ", ");
        },
      },
    ],
    [t]
  );

  return (
    <div className="border mt-3 p-4">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h5>{t("setting")}</h5>
        <span onClick={() => setOpenUpdate(true)}>
          <ImageIcon
            className="w-[15px] cursor-pointer"
            src="/images/icons/edit.svg"
          />
        </span>
      </div>
      <ViewRowInterchange
        className="w-100"
        dataSource={channel}
        keyList={DETAILS_KEY as any}
        variant="border"
        classNameLabel="font-weight-bold "
        classNameContent="text-end "
      />
      {openUpdate && (
        <ChannelDetailSettingUpdateDrawer
          open={openUpdate}
          onClose={() => setOpenUpdate(false)}
          channelId={id}
          defaultValue={ObjectUtils.mapFieldsLangsSTC(
            channel ?? {},
            CHANNEL_GENERAL_KEYS as any
          )}
          onUpdateSuccess={loadChannelDetail}
        />
      )}
    </div>
  );
};

export default ChannelDetailSetting;
