import AppContext from "@helpers/context";
import { isCustomSizePro, isGroupCustomSizeProduct } from "@helpers/product";
import { applyCustomSizeGroupProduct } from "@network/api/order";
import classNames from "classnames";
import {
  Button,
  Icon,
  InputText,
  Notifications,
  Progress,
} from "d-react-components";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomProductRemark from "./CustomProductRemark";

const CustomSizeItem = ({ size, onClick, isView, isActive }: any) => {
  const widthText = size?.width ?? "N/A";
  const lengthText = size?.length ?? "N/A";

  return (
    <div
      className={classNames(
        `cursor-pointer float-left border-primary border text-xs flex items-center w-fit p-1 inline-block mb-2 mr-2`,
        { "text-white bg-primary": isActive, "text-primary": !isActive }
      )}
      onClick={onClick}
    >
      <span className="subTitle2">{`W x L (cm): ${widthText}x${lengthText}`}</span>
      {!isView && <Icon name="edit" className="ml-1" size="small" />}
    </div>
  );
};

export const CustomSizeField = ({
  product,
  onChangeProduct,
  onReplaceProduct,
  isView = false,
  onChangeRemarkCustomSizePro,
}: any) => {
  const [modalEdit, setModalEdit] = useState<any>({
    visible: false,
    size: null,
    index: null,
  });
  const customSizeList = useMemo(() => product?.meta ?? [], [product]);
  const renderCustomSizeList = () => {
    if (isGroupCustomSizeProduct(product)) {
      return (
        <CustomSizeItem
          onClick={() =>
            !isView && setModalEdit({ visible: !modalEdit.visible, size: null })
          }
          isView={isView}
          isActive={modalEdit.visible}
        />
      );
    }
    return customSizeList?.map((item: any, index: number) => (
      <CustomSizeItem
        size={item}
        onClick={() =>
          !isView &&
          setModalEdit({
            visible: modalEdit.index === index ? false : true,
            size: item,
            index: modalEdit.index === index ? null : index,
          })
        }
        isView={isView}
        isActive={modalEdit.index === index}
      />
    ));
  };

  return (
    <div className="mt-2">
      <div className="overflow-hidden">{renderCustomSizeList()}</div>
      {modalEdit.visible && (
        <CustomSizeEdit
          open={modalEdit.visible}
          sizeData={modalEdit.size}
          onClose={() => setModalEdit({ visible: false, size: null })}
          product={product}
          onReplaceProduct={onReplaceProduct}
          onChangeProduct={onChangeProduct}
        />
      )}
    </div>
  );
};

const CustomSizeEdit = ({
  open,
  onClose,
  onReplaceProduct,
  onChangeProduct,
  product,
  sizeData,
  activeItem,
}: any) => {
  const { values } = useContext(AppContext);
  const { t } = useTranslation();

  const [length, setLength] = useState(sizeData?.length ?? 0);
  const [width, setWidth] = useState(sizeData?.width ?? 0);
  const { product_custom_size, sku } = product.product;
  const { min_width, max_width, min_length, max_length } =
    product_custom_size ?? {};

  useEffect(() => {
    setWidth(sizeData?.width);
    setLength(sizeData?.length);
  }, [sizeData]);

  const applySizeProduct = () => {
    if (
      length > max_length ||
      length < min_length ||
      width > max_width ||
      width < min_width
    ) {
      Notifications.showError(t("notification:lengthWidthIsNotInRange"));
      return;
    }
    const metaResult = product.meta?.map((item: any) => {
      if (item.id === sizeData.id) {
        return {
          ...item,
          length,
          width,
        };
      }
      return item;
    });

    onChangeProduct({ ...product, meta: metaResult });

    onClose();
  };

  const applySizeGroupProduct = () => {
    Progress.show(
      {
        method: applyCustomSizeGroupProduct,
        params: {
          productid: product?.id,
          width,
          length,
          channel: values?.channel,
        },
      },
      (res: any) => {
        const products = res?.data?.data?.products ?? [];
        if (products.length === 0) {
          Notifications.showError(
            t("notification:noCustomSizeProductWasFound")
          );
          return;
        }
        const groupProducts = products?.map((item: any) => ({
          ...item,
          customSized: true,
          isGift: product.isGift,
          meta: product.meta?.map((metaItem: any) => ({
            ...metaItem,
            width,
            length,
          })),
          quantity: product.quantity,
        }));
        onClose();
        onReplaceProduct(product, groupProducts);
      }
    );
  };

  const onClickSave = async () => {
    if (isCustomSizePro(product)) {
      applySizeProduct();
    } else {
      applySizeGroupProduct();
    }
  };

  const renderDescription = () => {
    if (isGroupCustomSizeProduct(product)) {
      return <div />;
    }
    return (
      <div className="p-3 bg-amber-100 mt-2 text-tiny">
        <span className="text-xs block">{`${t(
          "youHaveSelectCustomSize"
        )} ${sku}`}</span>
        <span className="text-xs block">{`${t(
          "allowedRangeOfWidthIS"
        )} ${min_width}-${max_width}cm`}</span>
        <span className="text-xs block">{`${t(
          "allowedRangeOfLengthIS"
        )} ${min_length}-${max_length}cm`}</span>
        <span className="text-xs block">{`${t(
          "wantDifferentWidthAndLength"
        )} `}</span>
      </div>
    );
  };

  return (
    <div className="bg-blue-100 p-3 mt-2">
      <InputCustomSizeProduct
        width={width}
        length={length}
        onChangeWidth={setWidth}
        onChangeLength={setLength}
      />
      <Button
        onClick={() => onClickSave()}
        className="w-full mt-2"
        style={{ width: "100%" }}
      >
        {t("save")}
      </Button>
      {renderDescription()}
    </div>
  );
};

const InputCustomSizeProduct = ({
  width,
  length,
  onChangeLength,
  onChangeWidth,
  onApply,
}: any) => {
  const { t } = useTranslation();
  return (
    <div className="grid grid-cols-2 gap-3">
      <div className="col-span-1">
        <InputText
          type="number"
          value={width}
          label={t("width")}
          onChange={(e) => onChangeWidth(e?.target?.value)}
        />
      </div>
      <div className="col-span-1">
        <InputText
          type="number"
          value={length}
          label={t("length")}
          onChange={(e) => onChangeLength(e?.target?.value)}
        />
      </div>
    </div>
  );
};

export default CustomSizeField;
