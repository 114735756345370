import React, { useContext } from "react";
import AppContext from "@helpers/context";
import { useTranslation } from "react-i18next";
import InputTextForm from "@common/input/InputTextForm";

const UserCreatePassword = () => {
  const { userForm } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <div className="p-4 card-container">
      <label>{t("password")}</label>
      <div className="row">
        <InputTextForm
          keyData="password"
          form={userForm}
          required
          className="col-6"
          type="password"
        />
        <InputTextForm
          keyData="reTypePassword"
          form={userForm}
          required
          className="col-6"
          type="password"
        />
      </div>
    </div>
  );
};

export default UserCreatePassword;
