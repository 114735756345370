import AppContext from "@helpers/context";
import { Button } from "d-react-components";
import { map } from "lodash";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Carousel, { Modal, ModalGateway } from "react-images";
import VehicleDetailGalleryEditDrawer from "./VehicleDetailGalleryEditDrawer";

const VehicleDetailGallery = () => {
  const { vehicleDetail, loadVehicleDetail } = useContext(AppContext);
  const [indexImageView, setIndexImageView] = useState<any>(-1);
  const { t } = useTranslation();
  const [openEdit, setOpenEdit] = useState(false);
  return (
    <div className="card-container p-3">
      <div className="flex-row-between-center">
        <label>{t("gallery")}</label>
        <Button onClick={() => setOpenEdit(true)} className="" variant="trans">
          {t("edit")}
        </Button>
      </div>
      <div className={"d-flex flex-wrap"} style={{ overflowX: "scroll" }}>
        {map(vehicleDetail?.media, (item, index) => (
          <img
            className="image-reg-large object-fit-contain my-2 mr-2"
            src={item?.attachment}
            onClick={() => setIndexImageView(index)}
          />
        ))}
      </div>
      <ModalGateway>
        {indexImageView !== -1 && (
          <Modal onClose={() => setIndexImageView(-1)}>
            <Carousel
              currentIndex={indexImageView}
              views={
                map(vehicleDetail?.media, (file) => ({
                  src: file?.attachment,
                  caption: "",
                })) as any
              }
            />
          </Modal>
        )}
      </ModalGateway>

      {openEdit && (
        <VehicleDetailGalleryEditDrawer
          open={openEdit}
          onClose={() => setOpenEdit(false)}
          defaultValue={vehicleDetail}
          onUpdateSuccess={loadVehicleDetail}
        />
      )}
    </div>
  );
};

export default VehicleDetailGallery;
