import { ORDER_STATUS, ORDER_STATUS_MENU } from "@constants/order";
import { PAYMENTS_METHOD, PAYMENT_CONFIRM_STATUS } from "@constants/payment";
import AppContext from "@helpers/context";
import {
  changeExpiredDayOrder,
  resetPaymentStatus,
  sendEmailOrderConfirm,
  sendEmailOrderInvoice,
  updateStatusOrder,
} from "@network/api/order";
import {
  Notifications,
  StringUtils,
  Button,
  Checkbox,
  RadioGroup,
  InputText,
  DateInput,
  Drawer,
  Progress,
} from "d-react-components";
import { findIndex, isEmpty } from "lodash";
import moment from "moment";
import React, { useContext, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CancelOrder from "../modal/CancelOrder";
import ManageRequestRefundDrawer from "../modal/ManageRequestRefundDrawer";
import { Dropdown } from "antd";
import { OrderType } from "@constants/common";
import CreateInvoiceModal from "@components/subscription-contract/detail/modal/CreateInvoiceModal";
import {
  cancelContract,
  changeRentalExpiredDay,
  terminateContract,
  viewContractPdf,
} from "@network/api/rental";
import { ContractStatus } from "@constants/contract";
import ResendContractModal from "@components/subscription-contract/detail/modal/ResendContractModal";
import ResendInvoiceModal from "@components/subscription-contract/detail/modal/ResendInvoiceModal";
// import CreateEditRequestRefund from "../../refund/action/CreateEditRequestRefund";

const ChangeStatusModal = ({
  changePaymentStatusModal,
  setChangePaymentStatusModal,
}: any) => {
  const { order, onReloadOrderData, splitPayment } = useContext(AppContext);
  const { t } = useTranslation();

  const [paymentSelected, setPaymentSelected] = useState(null);
  const remarkContent = useRef("");
  const onClickChangePaymentStatus = async () => {
    if (isEmpty(remarkContent.current)) {
      Notifications.showError(t("notification:remarkIsRequired"));
      return Promise.reject();
    }
    if (
      order.status !== ORDER_STATUS.pending.value &&
      order.status !== ORDER_STATUS.pendingPaymentConfirmation.value &&
      order.status !== ORDER_STATUS.partiallyPaid.value &&
      order.status !== ORDER_STATUS.processing.value
    ) {
      Notifications.showError(t("notification:orderCanNotBeReset"));
      return Promise.reject();
    }
    const body = {
      splitid: paymentSelected,
      note: remarkContent.current,
    };

    return resetPaymentStatus(body).then((res) => {
      Notifications.showSuccess(t("notification:changePaymentStatusSuccess"));
      setChangePaymentStatusModal(false);
      onReloadOrderData && onReloadOrderData();
    });
  };

  const isDisablePayment = (paymentItem: any) => {
    return (
      paymentItem.method === PAYMENTS_METHOD.C2P ||
      paymentItem.status !== PAYMENT_CONFIRM_STATUS.APPROVE
    );
  };

  return (
    <Drawer
      open={changePaymentStatusModal}
      onClose={() => {
        setChangePaymentStatusModal(false);
      }}
      title={t("resetPayment")}
      size="auto"
      width={600}
    >
      <div className="pb-12">
        <RadioGroup
          value={paymentSelected}
          dataSource={splitPayment}
          onChange={(event: any) => {
            setPaymentSelected(event);
          }}
          numberOfColumns="1"
          getDisabledItem={(paymentItem) => isDisablePayment(paymentItem)}
          getLabel={(paymentItem) => {
            const { method, total, status } = paymentItem;
            const index = findIndex(
              splitPayment,
              (item: any) => item.id === splitPayment.id
            );
            const title = `${t("payment")} ${index + 1}`;
            const disabled = isDisablePayment(paymentItem);
            return (
              <span
                className={`subTitle1 paymentStatusItem ${
                  disabled ? "text-gray-500" : ""
                }`}
              >
                {title} - {t(method)} - {StringUtils.moneyThaiFormat(total)} -{" "}
                {status}{" "}
              </span>
            );
          }}
        >
          {/* <div
            style={{ display: "flex", flexDirection: "column" }}
            onChange={(event) => {
              setPaymentSelected(event.target.value);
            }}
          >
            {splitPayment?.map((paymentItem, index) => {
              const { method, total, status } = paymentItem;
              const title = `${Messages.payment} ${index + 1}`;
              const disabled = isDisablePayment(paymentItem);
              return (
                <FormControlLabel
                  control={<Radio />}
                  value={paymentItem.id}
                  disabled={disabled}
                  label={
                    <div
                      className={`subTitle1 paymentStatusItem ${
                        disabled ? "text-gray-500" : ""
                      }`}
                    >
                      {title} - {Messages[method]} -{" "}
                      {StringUtils.moneyThaiFormat(total)} - {status}{" "}
                    </div>
                  }
                />
              );
            })}
          </div> */}
        </RadioGroup>
        <InputText
          label={t("remark")}
          variant="outline"
          onChange={(event) => (remarkContent.current = event.target.value)}
          multiple
          rows={5}
        />
      </div>
      <div className="position-absolute bottom-0 w-100 end-0 start-0 flex-center-y justify-content-between py-3 px-3 bg-white">
        <Button
          onClick={() => {
            onClickChangePaymentStatus();
          }}
        >
          {t("save")}
        </Button>
      </div>
    </Drawer>
  );
};

const OrderStatusModal = ({ openModal, setOpenModal }: any) => {
  const { order, onReloadOrderData } = useContext(AppContext);
  const [orderStatus, setOrderStatus] = useState(order.status);
  const { t } = useTranslation();

  const onClickSaveHandle = async () => {
    const { id } = order ?? {};
    return updateStatusOrder(id, orderStatus).then((respone) => {
      setOpenModal(false);
      onReloadOrderData();
      Notifications.showSuccess(t("notification:updateOrderStatusSucess"));
    });
  };
  const renderUpdateOrderStatusModal = () => {
    return (
      <RadioGroup
        value={orderStatus}
        onChange={(value) => setOrderStatus(value)}
        dataSource={ORDER_STATUS_MENU}
        getLabel={(item) => t(item.content)}
        getValue={(item) => item.key}
        numberOfColumns="1"
      />
    );
  };

  return (
    <Drawer
      open={openModal}
      onClose={() => {
        setOpenModal(false);
      }}
      title={t("order:orderStatus")}
    >
      <div className="pb-10">
        {renderUpdateOrderStatusModal()}
        <div className="position-absolute bottom-0 w-100 end-0 start-0 flex-center-y justify-content-between py-3 px-3 bg-white">
          <Button
            onClick={() => {
              setOpenModal(false);
            }}
            variant="outline"
          >
            {t("cancel")}
          </Button>
          <Button
            onClick={() => {
              onClickSaveHandle();
            }}
          >
            {t("save")}
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

const MoreActionButton = ({
  onClickCreateDelivery,
  orderType = OrderType.NORMAL,
}: any) => {
  const { order, onReloadOrderData, contact } = useContext(AppContext);
  const [expireDateModal, setExpireDateModal] = useState(false);
  const [sendEmailModal, setSendEmailModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [orderStatusModal, setOrderStatusModal] = useState(false);
  const [requestRefundModal, setRequestRefundModal] = useState(false);
  const [changePaymentStatusModal, setChangePaymentStatusModal] =
    useState(false);
  const [sendEmailInvoiceModal, setSendEmailInvoiceModal] = useState(false);
  const [createInvoiceModal, setCreateInvoiceModal] = useState(false);
  const [openContractCancelModal, setOpenContractCancelModal] = useState(false);
  const [openContractTerminateModal, setOpenContractTerminateModal] =
    useState(false);
  const [openResendContractModal, setOpenResendContractModal] = useState(false);
  const [openResendInvoiceModal, setOpenResendInvoiceModal] = useState(false);
  const [expireDate, setExpireDate] = useState(
    order?.expire ? moment(order?.expire) : null
  );
  const [check, setCheck] = useState(false);
  const emailCustom = useRef("");
  const { t } = useTranslation();

  const OPTION = useMemo(
    () =>
      orderType === OrderType.NORMAL
        ? [
            {
              label: t("updateOrderStatus"),
              key: "updateOrderStatus",
            },
            // {
            //   label: t("createDelivery"),
            //   key: "createDelivery",
            // },
            // {
            //     content: Messages.printOrder,
            // },
            {
              label: t("changeExpiringDate"),
              key: "changeExpiringDate",
            },
            // {
            //     content: Messages.printDelivery,
            // },
            {
              label: t("refundRequest"),
              key: "editRequestRefund",
            },
            {
              label: t("cancelOrder"),
              key: "cancelOrder",
            },

            {
              label: t("resetPaymentStatus"),
              key: "resetPaymentStatus",
            },
            {
              label: t("sendEmailCustomer"),
              key: "sendEmailCustomer",
            },
            {
              label: t("sendConfirmationCustomer"),
              key: "sendConfirmationCustomer",
            },
          ]
        : [
            {
              label: t("createInvoice"),
              key: "createInvoice",
            },
            {
              label: t("changeExpiringDate"),
              key: "changeExpiringDate",
            },
            ...(order.status === ContractStatus.PENDING
              ? [
                  {
                    label: t("cancelContract"),
                    key: "cancelContract",
                  },
                ]
              : []),
            ...([ContractStatus.SIGNED, ContractStatus.ONBOARD].includes(
              order.status
            )
              ? [
                  {
                    label: t("terminateContract"),
                    key: "terminateContract",
                  },
                ]
              : []),
            {
              label: t("resendContractURL"),
              key: "resendContractURL",
            },
            {
              label: t("resendInvoiceURL"),
              key: "resendInvoiceURL",
            },
            ...(order.pdf_contract
              ? [
                  {
                    label: t("downloadContractPDF"),
                    key: "downloadContractPDF",
                  },
                ]
              : []),
          ],
    [t, orderType, order]
  );

  const handleChooseAction = ({ key }: any) => {
    const action = key;
    if (action === "updateOrderStatus") {
      setOrderStatusModal(true);
      return;
    }

    if (action === "changeExpiringDate") {
      setExpireDateModal(true);
    }

    if (action === "printDelivery") {
      Notifications.showSuccess("Print");
    }
    if (action === "createDelivery") {
      onClickCreateDelivery && onClickCreateDelivery();
      return;
    }

    if (action === "resetPaymentStatus") {
      setChangePaymentStatusModal(true);
      return;
    }

    if (action === "printOrder") {
      window.print();
      return;
    }

    if (action === "sendEmailCustomer") {
      // setSendEmailModal(true)
      if (!order.has_send_mail_to_customer) {
        Notifications.showError(t("notification:cantSendMailCustomer"));
        return;
      }
      if (!contact || !contact.email) {
        // Notifications.showError(t("notification:orderContactIsNull"));
        // return;
        setCheck(true);
      }
      setSendEmailInvoiceModal(true);
    }

    if (action === "sendConfirmationCustomer") {
      if (!order.has_send_mail_to_customer) {
        Notifications.showError(t("notification:cantSendMailCustomer"));
        return;
      }
      if (
        order &&
        order.status !== ORDER_STATUS.pending.value &&
        order.status !== ORDER_STATUS.pendingPaymentConfirmation.value
      ) {
        Notifications.showError(t("notification:canOnlySendOrderConfirm"));
        return;
      }
      if (!contact || !contact.email) {
        // Notifications.showError(t("orderContactIsNull"));
        // return;
        setCheck(true);
      }
      setSendEmailModal(true);
    }

    if (action === "cancelOrder") {
      if (
        order?.status === ORDER_STATUS.cancelled.value ||
        order?.status === ORDER_STATUS.refunded.value
      ) {
        Notifications.showError(t("notification:cannotCancelOrder"));
        return;
      }
      setCancelModal(true);
    }

    if (action === "editRequestRefund") {
      if (order.status !== ORDER_STATUS.cancelled.value) {
        Notifications.showError(t("notification:youCantRequestRefundCancel"));
        return;
      }
      setRequestRefundModal(true);
    }

    if (action === "createInvoice") {
      setCreateInvoiceModal(true);
    }

    if (action === "cancelContract") {
      setOpenContractCancelModal(true);
    }

    if (action === "terminateContract") {
      setOpenContractTerminateModal(true);
    }

    if (action === "resendContractURL") {
      setOpenResendContractModal(true);
    }

    if (action === "resendInvoiceURL") {
      setOpenResendInvoiceModal(true);
    }

    if (action === "downloadContractPDF") {
      onClickDownloadContractPDF();
    }
  };

  const handleClickSaveExpireDate = async () => {
    if (!expireDate) {
      return;
    }
    const apiChangeDate =
      orderType === OrderType.NORMAL
        ? changeExpiredDayOrder
        : changeRentalExpiredDay;

    apiChangeDate(order.id, expireDate.valueOf().toString()).then((respone) => {
      onReloadOrderData();
      Notifications.showSuccess(t("notification:changeExpiringDateSucess"));
      setExpireDateModal(false);
    });
  };

  const renderMenuMoreAction = () => {
    return (
      <Dropdown
        trigger={["click"]}
        menu={{ items: OPTION, onClick: handleChooseAction }}
      >
        <Button
          variant="outline"
          content={t("action")}
          suffixIcon="arrow_drop_down"
          size="small"
          className="ml-2"
        />
      </Dropdown>
    );
  };
  const renderExpireDateModal = () => {
    return (
      <Drawer
        open={expireDateModal}
        onClose={() => setExpireDateModal(false)}
        title={t("changeExpiringDate")}
      >
        <div id="pb-12">
          <DateInput
            showTime
            value={expireDate}
            onChange={(date) => setExpireDate(date)}
            format="DD/MM/YYYY HH:mm"
            disabledDate={(d) => !d || d.isBefore(moment())}
          />
        </div>
        <div className="position-absolute bottom-0 w-100 end-0 start-0 flex-center-y justify-content-between py-3 px-3 bg-white">
          <Button
            onClick={() => {
              handleClickSaveExpireDate();
            }}
          >
            {t("save")}
          </Button>
        </div>
      </Drawer>
    );
  };

  const onClickSendEmail = () => {
    const body = {
      orderid: order?.id,
      email: "",
    };
    if (check) {
      if (isEmpty(emailCustom.current)) {
        Notifications.showError(t("notification:notValidEmail"));
        return Promise.reject();
      }
      body.email = emailCustom.current;
    }
    if (sendEmailModal) {
      return sendEmailOrderConfirm(body).then((res) => {
        Notifications.showSuccess(t("notification:sendEmailConfirmSuccess"));
        setSendEmailModal(false);
      });
    }
    return sendEmailOrderInvoice(body).then((res) => {
      Notifications.showSuccess(t("notification:sendEmailInvoiceSuccess"));
      setSendEmailInvoiceModal(false);
    });
  };

  const renderSendEmailModal = () => {
    return (
      <Drawer
        open={sendEmailModal || sendEmailInvoiceModal}
        onClose={() => {
          setSendEmailModal(false);
          setSendEmailInvoiceModal(false);
        }}
        title={
          sendEmailModal
            ? t("sendOrderConfirmationToCustomerByEmail")
            : t("sendOrderInvoiceToCustomerByEmail")
        }
      >
        <div className="pb-12">
          <InputText className="mb-3" disabled value={contact?.email} />
          <Checkbox
            className="mb-3"
            checked={check}
            onChange={() => setCheck(!check)}
            label={t("wantToSendToDifferentEmail")}
          />
          <InputText
            className="mb-3"
            placeholder={t("pleaseInputTheEmailAddress")}
            disabled={!check}
            onChange={(event) => (emailCustom.current = event.target.value)}
          />
        </div>
        <div className="position-absolute bottom-0 w-100 end-0 start-0 flex-center-y justify-content-between py-3 px-3 bg-white">
          <Button
            onClick={() => {
              onClickSendEmail();
            }}
          >
            {t("save")}
          </Button>
        </div>
      </Drawer>
    );
  };

  const onClickDownloadContractPDF = () => {
    window.open(order.pdf_contract, "_blank");
  };

  return (
    <div className="eachIcon relative">
      {renderMenuMoreAction()}
      {renderExpireDateModal()}
      {renderSendEmailModal()}
      <CancelOrder openModal={cancelModal} setOpenModal={setCancelModal} />
      <ManageRequestRefundDrawer
        openModal={requestRefundModal}
        setOpenModal={setRequestRefundModal}
      />
      <ChangeStatusModal
        changePaymentStatusModal={changePaymentStatusModal}
        setChangePaymentStatusModal={setChangePaymentStatusModal}
      />
      <OrderStatusModal
        openModal={orderStatusModal}
        setOpenModal={setOrderStatusModal}
      />
      <CreateInvoiceModal
        open={createInvoiceModal}
        onClose={() => setCreateInvoiceModal(false)}
      />
      <TerminateContractModal
        open={openContractTerminateModal}
        onClose={() => setOpenContractTerminateModal(false)}
      />
      <CancelContractModal
        open={openContractCancelModal}
        onClose={() => setOpenContractCancelModal(false)}
      />
      <ResendContractModal
        open={openResendContractModal}
        onClose={() => setOpenResendContractModal(false)}
      />
      <ResendInvoiceModal
        open={openResendInvoiceModal}
        onClose={() => setOpenResendInvoiceModal(false)}
      />
    </div>
  );
};

const CancelContractModal = ({ onClose, open }: any) => {
  const { t } = useTranslation();
  const { onReloadOrderData, id } = useContext(AppContext);
  const remarkContent = useRef("");
  const onSubmit = async () => {
    if (isEmpty(remarkContent.current)) {
      Notifications.showError(t("notification:remarkIsRequired"));
      return Promise.reject();
    }
    const body = {
      id,
      remark: remarkContent.current,
    };

    return cancelContract(body).then((res) => {
      Notifications.showSuccess(t("cancelContractSuccessful"));
      onReloadOrderData && onReloadOrderData();
      onClose && onClose();
    });
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("cancelContract")}
      size="auto"
      width={600}
    >
      <div className="pb-12">
        {t("cancelSubscriptionContractNote")}
        <InputText
          label={t("remark")}
          variant="outline"
          onChange={(event) => (remarkContent.current = event.target.value)}
          multiple
          rows={5}
          className="mt-3"
        />
      </div>
      <div className="position-absolute bottom-0 w-100 end-0 start-0 flex-center-y justify-content-between py-3 px-3 bg-white">
        <Button
          onClick={() => {
            onSubmit();
          }}
        >
          {t("save")}
        </Button>
      </div>
    </Drawer>
  );
};

const TerminateContractModal = ({ onClose, open }: any) => {
  const { t } = useTranslation();
  const { onReloadOrderData, id } = useContext(AppContext);
  const remarkContent = useRef("");
  const onSubmit = async () => {
    if (isEmpty(remarkContent.current)) {
      Notifications.showError(t("notification:remarkIsRequired"));
      return Promise.reject();
    }
    const body = {
      id,
      remark: remarkContent.current,
    };

    return terminateContract(body).then((res) => {
      Notifications.showSuccess(t("terminateContractSuccessful"));
      onReloadOrderData && onReloadOrderData();
      onClose && onClose();
    });
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("terminateContract")}
      size="auto"
      width={600}
    >
      <div className="pb-12">
        {t("terminateSubscriptionContractNote")}
        <InputText
          label={t("remark")}
          variant="outline"
          onChange={(event) => (remarkContent.current = event.target.value)}
          multiple
          rows={5}
          className="mt-3"
        />
      </div>
      <div className="position-absolute bottom-0 w-100 end-0 start-0 flex-center-y justify-content-between py-3 px-3 bg-white">
        <Button
          onClick={() => {
            onSubmit();
          }}
        >
          {t("save")}
        </Button>
      </div>
    </Drawer>
  );
};

export default MoreActionButton;
