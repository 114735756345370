import { API } from "@network";
import {
  API_PRODUCTION_PRINTING_DETAIL,
  API_PRODUCTION_PRINTING_HISTORY,
  API_PRODUCTION_PRINTING_HISTORY_NEW,
  API_PRODUCTION_PRINTING_IMPORT,
  API_PRODUCTION_PRINTING_IMPORT_NEW,
  API_PRODUCTION_PRINTING_LABEL,
  API_PRODUCTION_PRINTING_NEW,
  API_PRODUCTION_PRINTING_PRINT,
  API_PRODUCTION_PRINTING_TAG,
  API_PRODUCTION_PRINTING_VALIDATION,
  API_PRODUCTION_PRINTING_VALIDATION_NEW,
  API_SERIAL_NUMBER_PRINT_TAG,
} from "@network/URL";

export const getProductionPrintingHistoryList = (id: string, paging: any) => {
  return API.post(
    `${API_PRODUCTION_PRINTING_HISTORY}?page=${paging.pageIndex}&per_page=${paging.pageSize}`,
    { id }
  );
};

export const validateImportProductionPrinting = (body: any) => {
  return API.post(API_PRODUCTION_PRINTING_VALIDATION, body);
};

export const importProductionPrinting = (body: any) => {
  return API.post(API_PRODUCTION_PRINTING_IMPORT, body);
};

export const getProductionPrintingLabel = (id: string) => {
  return API.post(API_PRODUCTION_PRINTING_LABEL, { id });
};

export const getProductionPrintingTag = (id: string) => {
  return API.post(API_PRODUCTION_PRINTING_TAG, { id });
};

export const getSerialNumberPrint = (id: string) => {
  return API.post(API_SERIAL_NUMBER_PRINT_TAG, { id });
};

export const getProductionPrintingHistoryListNew = (
  id: string,
  paging: any
) => {
  return API.post(
    `${API_PRODUCTION_PRINTING_HISTORY_NEW}?page=${paging.pageIndex}&per_page=${paging.pageSize}`,
    { id }
  );
};

export const validateImportProductionPrintingNew = (body: any) => {
  return API.post(API_PRODUCTION_PRINTING_VALIDATION_NEW, body, {
    keepServerErrorFormat: true,
  } as any);
};

export const importProductionPrintingNew = (body: any) => {
  return API.post(API_PRODUCTION_PRINTING_IMPORT_NEW, body);
};

export const getProductionPrintingListNew = (body: any, paging: any) => {
  return API.post(
    `${API_PRODUCTION_PRINTING_NEW}?page=${paging.pageIndex}&per_page=${paging.pageSize}`,
    body
  );
};

export const getProductionPrintingDetail = (id: string, lang?: string) => {
  return API.post(
    API_PRODUCTION_PRINTING_DETAIL,
    { id },
    {
      headers: {
        language: lang || "en",
      },
    }
  );
};

export const printProductionPrinting = (body: any) => {
  return API.post(API_PRODUCTION_PRINTING_PRINT, body);
};
