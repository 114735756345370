import { PRODUCT_ALIAS_IMPORT_METHOD } from "@constants/product";
import AppContext from "@helpers/context";
import ProductAliasAPI from "@network/api/product-alias";
import { Spin } from "antd";
import { ObjectUtils } from "d-react-components";
import { find, forEach, includes, map } from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";

const ProductAliasImportValidation = () => {
  const { formImport } = useContext(AppContext);
  const { t } = useTranslation();

  const [validateLoading, setValidateLoading] = useState(true);

  const { importType, fileImport } = formImport.values;
  const methodVal = useMemo(
    () => find(PRODUCT_ALIAS_IMPORT_METHOD, (item) => item.id === importType),
    [importType]
  );

  useEffect(() => {
    try {
      onValidateImportDatabase(fileImport?.data);
    } catch (err) {
      setValidateLoading(false);
    }
  }, []);

  const onValidateImportDatabase = async (importData: any) => {
    const mulCodeList = ObjectUtils.sliceArrayToMui(importData, 100000);
    let errors: any[] = [];

    for (let index = 0; index < mulCodeList.length; index++) {
      const codeItemList = mulCodeList[index];
      const validationItems = await getValidateAPI(codeItemList);
      errors = errors.concat(validationItems);
    }
    formImport.setFieldValue("fileImport", { ...fileImport, errors });
    setValidateLoading(false);
  };

  const getErrorItemsData = () => {
    const fieldList = Object.keys(fileImport?.data[0]);
    const header = [...fieldList, "Message"];
    const dataResult = [header];
    forEach(fileImport?.errors, (validation) => {
      const error = find(
        fileImport.data,
        (item) => item.code === validation.sku
      );
      forEach(validation?.validation ?? [], (message) =>
        dataResult.push([...map(fieldList, (item) => error?.[item]), message])
      );
    });
    return dataResult;
  };

  const getSuccessItemsData = () => {
    const fieldList = Object.keys(fileImport?.data[0]);
    const header = [...fieldList, "Message"];
    const dataResult = [header];
    forEach(fileImport.data, (pro) => {
      const isErrorPro = includes(
        map(fileImport?.errors, (item) => item.sku),
        pro.sku
      );
      if (!isErrorPro) {
        dataResult.push([...map(fieldList, (item) => pro[item]), "success"]);
      }
    });
    return dataResult;
  };

  const getValidateAPI = async (importData: any) => {
    const body = {
      method: importType,
      products: map(importData, (item) => ({
        sku: item.sku,
        alias: item.sku_alias,
      })),
    };

    return ProductAliasAPI.validateImport(body).then((res) => {
      const validation = res?.data?.data?.validate;
      const validationResult: any[] = [];
      Object.keys(validation).forEach((key) => {
        validationResult.push({ code: key, validation: validation[key] });
      });
      return validationResult;
    });
  };

  const renderRowInfo = (title: string, content: any) => (
    <div className="d-flex mt-3">
      <text className="font-weight-bold">{`${title}: `}</text>
      <text className="ml-1">{content}</text>
    </div>
  );

  const renderValidateError = () => {
    if (fileImport?.errors?.length > 0) {
      return (
        <div className="d-flex ">
          <text className="text-error">
            {fileImport?.errors?.length} {t("errors")}
          </text>
          <CSVLink data={getErrorItemsData()} filename="invalid-import-sku.csv">
            <div className="ml-1 text-primary">{t("downloadLog")}</div>
          </CSVLink>
        </div>
      );
    }
    return <text className="text-success">{t("readyToImport")}</text>;
  };

  const renderValidateSuccess = () => {
    return (
      <div className="d-flex ">
        <text className="text-success">
          {fileImport?.data?.length - fileImport?.errors?.length} {t("success")}
        </text>
        <CSVLink
          data={getSuccessItemsData()}
          filename="ready-import-product-label.csv"
        >
          <div className="ml-1 text-primary">{t("downloadLog")}</div>
        </CSVLink>
      </div>
    );
  };

  if (validateLoading) {
    return (
      <div className="flex-center p-5">
        <Spin />
      </div>
    );
  }
  return (
    <div className="p-4">
      <div
        className="d-flex flex-column p-4"
        style={{ backgroundColor: "rgba(33, 150, 243, 0.1)" }}
      >
        {renderRowInfo(t("fileName"), fileImport?.name)}
        {renderRowInfo(t("importMethod"), t(methodVal?.label as any))}
        {renderRowInfo(t("validateStatus"), renderValidateError())}
        {renderRowInfo(t("validateStatus"), renderValidateSuccess())}
      </div>
      <div className="mt-5">
        {renderRowInfo(`*${t("lastWarning")}`, t("pleaseCheckCarefully"))}
      </div>
    </div>
  );
};

export default ProductAliasImportValidation;
