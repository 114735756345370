import React from "react";

const UserInfoRow = ({ label, value }: any) => {
  return (
    <div className="d-flex py-3 border-bottom">
      <div className="w-50">{label}</div>
      <div className="w-50">{value}</div>
    </div>
  );
};

export default UserInfoRow;
