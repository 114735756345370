import ProductCategorySelect from "@components/product-category/components/ProductCategorySelect";
import classNames from "classnames";
import { DateInput } from "d-react-components";
import { map } from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";

const ProductionPrintingFilterForm = ({ filterBody, onChangeData }: any) => {
  const classNameInput = classNames("col-span-1 mt-4");
  const { t } = useTranslation();
  const { productionDate = [], createdDate = [] } = filterBody ?? {};
  console.log("productionDate", productionDate);
  return (
    <div className="grid grid-cols-1 gap4">
      <ProductCategorySelect
        className={classNameInput}
        value={filterBody?.category}
        onChange={(value) => onChangeData({ category: value })}
        multiple
      />
      <DateInput
        className={classNameInput}
        isRangePicker
        label={t("productionDate")}
        value={
          productionDate?.length
            ? (map(productionDate, (item) => moment(item)) as any)
            : []
        }
        onChange={(value) => onChangeData({ productionDate: value })}
      />
      <DateInput
        className={classNameInput}
        isRangePicker
        label={t("createdDate")}
        value={
          createdDate?.length
            ? (map(createdDate, (item) => moment(item)) as any)
            : []
        }
        onChange={(value) => onChangeData({ createdDate: value })}
      />
    </div>
  );
};

export default ProductionPrintingFilterForm;
