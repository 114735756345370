import React, { useContext, useEffect } from "react";
import { Button } from "d-react-components";
import BreadCrumb from "./BreadCrumb";
import { DropdownLanguage } from "../header/DropdownLanguage";
import MAIN_MENU from "../MainMenu";
import ButtonSetting from "../header/ButtonSetting";
import { useMeasure } from "react-use";
import AppContext from "@helpers/context";
import NotificationBox from "./NotificationBox";

export interface INavBarProps {
  className?: string;
}

const NavBar: React.FC<INavBarProps> = ({ className }) => {
  const { setNavBarHeight, fullScreen } = useContext(AppContext);
  const [navBarRef, { x, y, width, height, top, right, bottom, left }] =
    useMeasure<any>();

  useEffect(() => {
    setNavBarHeight(height);
  }, [height]);

  return (
    <div
      ref={navBarRef}
      style={{
        height: fullScreen ? "0px" : "auto",
        zIndex: 99,
        ...(fullScreen ? { overflow: "hidden" } : {}),
      }}
    >
      <nav
        className={`app-layout__navbar ${className}`}
        id="app-layout__navbar"
      >
        <Button
          onClick={() => {
            document
              .getElementById("app-layout__sidebar")
              ?.classList.toggle("active");
            document
              .getElementById("app-layout__content")
              ?.classList.toggle("active");
          }}
          iconName="notes"
          variant="trans"
        />
        <div className="container-fluid app-layout__nav-bar-content">
          <BreadCrumb menu={MAIN_MENU} />
          <div className="app-layout__nav-bar-right-content">
            <DropdownLanguage />
            {/* <Button iconName="notifications" variant="trans" /> */}
            <NotificationBox />
            <ButtonSetting />
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
