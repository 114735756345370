import Drawer from "@components/shared/Drawer";
import UploadFile from "@components/shared/UploadFile";
import { PAYMENTS_METHODS, REFUND_STATUS } from "@constants/payment";
import AppContext from "@helpers/context";
import {
  approveRejectRefund,
  cancelRefund,
  completeRefund,
  deleteBankRefund,
  uploadAttachmentRefund,
  uploadCompleteRefund,
} from "@network/api/order";
import {
  Notifications,
  TimeUtils,
  StringUtils,
  InputText,
  Button,
  DialogManager,
  Progress,
} from "d-react-components";
import { t } from "i18next";
import { isEmpty } from "lodash";
import React, { useContext, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AddBankRefundButton from "./AddBankRefundButton";
import RefundActionButton from "./RefundActionButton";

const ItemInfo = ({ title, content, style }: any) => {
  return (
    <div className="flex items-center mb-2" style={style || {}}>
      <span className="font-bold">{title}</span>: {content}
    </div>
  );
};

const ModalRemark = ({
  mode,
  openModal,
  onClose,
  refundId,
  isUploadFile,
}: any) => {
  const { order, onReloadOrderData } = useContext(AppContext);
  const [files, setFiles] = useState([]);
  const remark = useRef<any>("");
  const { t } = useTranslation();

  const onClickSave = async () => {
    if (isEmpty(remark.current)) {
      Notifications.showError(t("notification:remarkIsRequired"));
      return Promise.resolve();
    }
    switch (mode.key) {
      case "reject":
        return onConfirmRejectRefund(
          "rejected",
          t("notification:rejectedRefundSuccess")
        );

      case "confirm":
        return onConfirmRejectRefund(
          "approved",
          t("notification:confirmRefundSuccess")
        );

      case "cancelRefund":
        return onCancelRefund();

      case "completeRefund":
        return onCompleteRefund();

      default:
        return Promise.resolve();
    }
  };

  const onConfirmRejectRefund = async (status: any, successMessage: string) => {
    const body = {
      status,
      note: remark.current,
      refundid: refundId,
    };

    Progress.show(
      { method: approveRejectRefund, params: [body] },
      () => {
        Notifications.showSuccess(successMessage);
        onClose && onClose();
        onReloadOrderData && onReloadOrderData();
      },
      (err: any) => {
        Notifications.showError(err?.response?.data?.message || err);
      }
    );
  };

  const onCancelRefund = () => {
    const body = {
      note: remark.current,
      refundid: refundId,
    };
    Progress.show(
      { method: cancelRefund, params: [body] },
      () => {
        Notifications.showSuccess(t("notification:cancelRefundSuccess"));
        onClose && onClose();
        onReloadOrderData && onReloadOrderData();
      },
      (err: any) => {
        Notifications.showError(err?.response?.data?.message || err);
      }
    );
  };

  const onCompleteRefund = () => {
    const body = {
      note: remark.current,
      refundid: refundId,
    };
    const APIUploadList: any = files?.map((file: any) => {
      const data = new FormData();
      data.append("file", file?.fileData);
      data.append("refundid", refundId);
      return { method: uploadCompleteRefund, params: data };
    });
    APIUploadList.push({ method: completeRefund, params: [body] });

    Progress.show(
      APIUploadList,
      () => {
        Notifications.showSuccess(t("notification:completeRefundSuccess"));
        onClose && onClose();
        onReloadOrderData && onReloadOrderData();
      },
      (err: any) => {
        Notifications.showError(err?.response?.data?.message || err);
      }
    );
  };

  return (
    <Drawer
      title={mode?.title}
      open={openModal}
      onClose={() => onClose()}
      onSave={() => onClickSave()}
      destroyOnClose={true}
    >
      {isUploadFile && <UploadFile getFile={(file: any) => setFiles(file)} />}
      <div className="mt-3">
        <InputText
          className="mt-1"
          onChange={(event) => (remark.current = event.target.value)}
          multiple
          rows={5}
          label={t("remark")}
        />
      </div>
    </Drawer>
  );
};

const AccountingConfirmation = ({
  onClickCompleteRefund,
  disabled = false,
  isRefunded,
  refund,
}: any) => {
  const { onReloadOrderData } = useContext(AppContext);
  const { t } = useTranslation();

  const onDrop = (files: any) => {
    const APIUploadList = files?.map((file: any) => {
      const data = new FormData();
      data.append("file", file);
      data.append("refundid", refund?.id);
      return { method: uploadCompleteRefund, params: data };
    });
    Progress.show(
      APIUploadList,
      () => {
        Notifications.showSuccess(t("uploadFileSuccess"));
        onReloadOrderData && onReloadOrderData();
      },
      (err: any) => {
        Notifications.showError(err?.response?.data?.message || err);
      }
    );
  };
  const renderContent = () => {
    if (isRefunded) {
      return (
        <div>
          <UploadFile
            getFile={onDrop}
            uploadedFiles={refund?.attachmentCompleted ?? []}
            getFileSource={(item: any) => item.attachment}
            getFileName={(item: any) => item.attachment}
            isJustGetFile
            getViewImages={(list: any) =>
              list?.map((item: any) => ({ src: item?.attachment }))
            }
          />
          <div className="subTitle1 text-primary mt-2">{`${t("refundedBy")} ${
            refund?.completed_by?.name
          } ${t("at")} ${TimeUtils.convertMiliToDate(refund?.completed_at)} ${
            refund.completed_note
          }`}</div>
        </div>
      );
    }

    return (
      <Button
        className="mt-2"
        disabled={disabled}
        onClick={onClickCompleteRefund}
      >
        {t("completeRefund")}
      </Button>
    );
  };
  return (
    <div>
      <ItemInfo title={t("accountingConfirm")} />
      {renderContent()}
    </div>
  );
};

const GroupConfirmRejectButtons = ({
  onClickConfirm,
  onClickReject,
  refund,
  onClickCompleteRefund,
}: any) => {
  const isApproved = refund && refund.status === "approved";
  const isReject = refund && refund.status === "rejected";
  const isRefunded = refund && refund.status === "refunded";
  const isCanceled = refund && refund.status === "cancelled";
  const content = `${refund?.status_by?.name} ${t(
    "at"
  )} ${TimeUtils.convertMiliToDate(refund?.status_at)} ${refund.status_note}`;

  if (isCanceled) {
    return (
      <div className="text-red-500 mt-2">{`${t("cancelBy")} ${
        refund?.cancelled_by?.name
      } ${t("at")} ${TimeUtils.convertMiliToDate(refund?.cancelled_at)} ${
        refund.cancelled_note
      }`}</div>
    );
  }

  if (isApproved || isRefunded) {
    return (
      <div>
        <div className="text-green-500 mb-2">{`${t(
          "approvedBy"
        )} ${content}`}</div>
        <AccountingConfirmation
          onClickCompleteRefund={onClickCompleteRefund}
          isRefunded={isRefunded}
          refund={refund}
        />
      </div>
    );
  }

  if (isReject) {
    return (
      <div>
        <div className="subTitle1 mt-2 text-red-500">
          {`${t("rejectBy")} ${content}`}
        </div>
        <AccountingConfirmation disabled refund={refund} />
      </div>
    );
  }

  return (
    <div className="flex mt-2">
      <Button onClick={onClickConfirm}>{t("approve")}</Button>
      <Button onClick={onClickReject} color="error" className="ml-1">
        {t("reject")}
      </Button>
    </div>
  );
};

const RefundBank = ({ bank, refund }: any) => {
  const { onReloadOrderData } = useContext(AppContext);
  const onClickDeleteRefundBank = () => {
    DialogManager.showConfirm(
      t("confirm"),
      t("notification:sureWantToDeleteRefundBank"),
      () => onDeleteRefundBank()
    );
  };

  const onDeleteRefundBank = async () => {
    const body = {
      bankid: bank.id,
    };
    return deleteBankRefund(body).then(
      () => {
        Notifications.showSuccess(t("notification:deleteBankRefundSuccess"));
        onReloadOrderData && onReloadOrderData();
      },
      (error) => {
        Notifications.showError(error?.response?.data?.message ?? error);
      }
    );
  };

  return (
    <div>
      <ul className="list-disc pl-3">
        <li className="subTitle1">{bank.account_name}</li>
        <li className="subTitle1">{bank.bank_name}</li>
        <li className="subTitle1">{bank.account_number}</li>
      </ul>

      {refund?.status !== "refunded" && (
        <div className="flex mb-4 items-center">
          <AddBankRefundButton refund={refund} bank={bank} />
          <div
            className="text-red-500 cursor-pointer p-1 text-xs ml-2"
            onClick={onClickDeleteRefundBank}
          >
            {t("delete")}
          </div>
        </div>
      )}
    </div>
  );
};

const OrderRefundItem = ({ refund }: any) => {
  const { order, onReloadOrderData } = useContext(AppContext);
  const { t } = useTranslation();
  const [openRemark, setOpenRemark] = useState<any>({
    isVisible: false,
    mode: undefined,
    isUploadFile: false,
  });

  const REMARK_MODE = useMemo(
    () => ({
      REJECTED: {
        key: "reject",
        title: t("reject"),
      },
      CONFIRMED: {
        key: "confirm",
        title: t("confirm"),
      },
      CANCEL: {
        key: "cancelRefund",
        title: t("cancelRefund"),
      },
      COMPLETE: {
        key: "completeRefund",
        title: t("completeRefund"),
      },
    }),
    [t]
  );

  const onDrop = (files: any) => {
    const APIList: any[] = [];
    files &&
      files.forEach((file: any, index: number) => {
        const data = new FormData();
        data.append("file", file);
        data.append("refundid", refund.id);
        APIList.push({ method: uploadAttachmentRefund, params: data });
      });

    Progress.show(
      APIList,
      () => {
        Notifications.showSuccess(
          t("notification:uploadAttachmentRefundSuccess")
        );
        onReloadOrderData && onReloadOrderData();
      },
      (err: any) => {
        Notifications.showError(err?.response?.data?.message || err);
      }
    );
  };

  const onClickCancelRefund = () => {
    DialogManager.showConfirm(
      t("confirm"),
      t("notification:sureWantToCancelRefund"),
      () => setOpenRemark({ isVisible: true, mode: REMARK_MODE.CANCEL })
    );
  };

  const { by, amount, method, status, reason, attachment, created, bank } =
    refund;

  const refundStatus = (REFUND_STATUS as any)?.[status];

  return (
    <div className="border-dashed p-3">
      <div className="mb-3">
        <div className="flex items-center">
          <div className="flex-1">
            <ItemInfo
              title={t("requestBy")}
              content={
                <div className="flex items-center">
                  {by?.name}{" "}
                  <img
                    src={by?.avatar}
                    alt=""
                    className="w-8 h-8 object-cover rounded-full ml-2"
                  />
                </div>
              }
            />
          </div>
          <RefundActionButton
            refund={refund}
            onClickCancelRefund={onClickCancelRefund}
          />
        </div>

        <ItemInfo
          title={t("amount")}
          content={StringUtils.moneyThaiFormat(amount)}
        />
        <ItemInfo
          title={t("method")}
          content={PAYMENTS_METHODS.find((item) => item.id === method)?.label}
        />

        <ItemInfo
          title={t("date")}
          content={TimeUtils.convertMiliToDateTime(created)}
        />

        <ItemInfo
          title={t("status")}
          content={
            <div className={`${refundStatus.color} ml-1`}>
              {refundStatus.content}
            </div>
          }
        />

        <ItemInfo title={t("reason")} content={reason} />

        <ItemInfo title={t("attachments")} />
        <UploadFile
          getFile={onDrop}
          uploadedFiles={attachment}
          getFileSource={(item: any) => item.attachment}
          getFileName={(item: any) => item.attachment}
          isJustGetFile
          showName={false}
          getViewImages={(list: any) =>
            list?.map((item: any) => ({ src: item?.attachment }))
          }
          disabled={
            status === REFUND_STATUS.refunded.key ||
            status === REFUND_STATUS.cancelled.key
          }
        />
      </div>

      <ItemInfo title={t("refundinfAccount")} />
      {bank?.map((item: any) => (
        <RefundBank bank={item} refund={refund} />
      ))}

      <ItemInfo title={t("directorApproval")} />
      <GroupConfirmRejectButtons
        refund={refund}
        onClickConfirm={() =>
          setOpenRemark({ isVisible: true, mode: REMARK_MODE.CONFIRMED })
        }
        onClickReject={() =>
          setOpenRemark({ isVisible: true, mode: REMARK_MODE.REJECTED })
        }
        onClickCompleteRefund={() =>
          setOpenRemark({
            isVisible: true,
            mode: REMARK_MODE.COMPLETE,
            isUploadFile: true,
          })
        }
      />

      <ModalRemark
        openModal={openRemark.isVisible}
        onClose={() => setOpenRemark(false)}
        mode={openRemark.mode}
        isUploadFile={openRemark.isUploadFile}
        refundId={refund.id}
      />
    </div>
  );
};

export default OrderRefundItem;
