import { Dropdown, Menu } from "antd";
import { Checkbox, Icon } from "d-react-components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const FilterActionButton = ({
  dataSource = [],
  defaultSelected = [],
  onSelectFilter,
  isSingleFilter,
}: any) => {
  const [filterList, setFilterList] = useState(defaultSelected);
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  const onClickItemFilter = (item: any) => {
    if (isSingleFilter) {
      setFilterList([item]);
    } else if (isItemSelected(item)) {
      const filterListResult = filterList.filter(
        (itemParam: any) => itemParam.id !== item.id
      );
      setFilterList(filterListResult);
    } else {
      const filterListResult = filterList.concat([item]);
      setFilterList(filterListResult);
    }
  };

  const isItemSelected = (item: any) => {
    return (
      filterList.filter((itemParam: any) => itemParam.id === item.id).length > 0
    );
  };

  const onClickFilter = () => {
    onSelectFilter && onSelectFilter(filterList);
  };

  const handleMenuClick = (e: any) => {
    if (e.key === "3") {
      setVisible(false);
    }
  };

  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag);
  };

  const overlay = (
    <Menu className="menuFilterContainer" onClick={handleMenuClick}>
      {dataSource?.map((item: any) => (
        <Menu.Item
          onClick={() => onClickItemFilter(item)}
          key={item.id}
          className="filterItemContainer"
        >
          <Checkbox
            checked={isItemSelected(item)}
            name={item.id}
            label={t(item.label)} 
          />
        </Menu.Item>
      ))}
      <div className="flex p-2">
        <div className="flex-1" onClick={() => handleVisibleChange(false)}>
        <span className="cursor-pointer text-red-500">{t("cancel")}</span>
        </div>
        <div className="flex-1 text-right" onClick={() => onClickFilter()}>
          <span className="cursor-pointer text-primary">{t("filter")}</span>
        </div>
      </div>
    </Menu>
  );

  return (
    <Dropdown
      overlay={overlay}
      onVisibleChange={handleVisibleChange}
      visible={visible}
    >
      <a onClick={(e) => e.preventDefault()}>
        <Icon name="filter_alt" />
      </a>
    </Dropdown>
  );
};

export default FilterActionButton;
