import { VerifyData } from "@interfaces/customer";
import {
  requestOTP,
  verifyCustomerEmail,
  verifyCustomerPhone,
  verifyOTP,
} from "@network/api/customer";
import {
  verifyCustomerEmailRentInfo,
  verifyCustomerPhoneRentInfo,
} from "@network/api/rental";
import classNames from "classnames";
import {
  Button,
  Icon,
  Modal,
  Notifications,
  Progress,
} from "d-react-components";
import React, { useEffect, useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import { useTranslation } from "react-i18next";
import VerificationInput from "react-verification-input";

export const VerifiedButton = ({ className }: { className?: string }) => {
  const { t } = useTranslation();
  return (
    <span
      className={classNames(
        className,
        "text-green-500 bg-green-200 px-1 py-[2px] relative text-xs flex items-center rounded"
      )}
    >
      <Icon name="check_circle" size="small" className="mr-1" />
      {t("verified")}
    </span>
  );
};

export const VerifyButton = ({
  onClick,
  disabled,
  className,
}: {
  onClick: any;
  disabled: boolean;
  className?: string;
}) => {
  const { t } = useTranslation();
  return (
    <span
      onClick={disabled ? null : onClick}
      className={classNames(
        className,
        { "bg-primary": !disabled, "bg-gray-300": disabled },
        "text-white px-1 py-[2px] relative text-xs cursor-pointer flex rounded"
      )}
    >
      {t("verify")}
    </span>
  );
};

const InputVerifier = ({
  isVerified,
  type,
  value,
  payload,
  onVerified,
  userId,
  rentId,
  className,
  label = "profile",
}: VerifyData) => {
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  return (
    <>
      {isVerified ? (
        <VerifiedButton />
      ) : (
        <VerifyButton
          onClick={() => setShowVerifyModal(true)}
          disabled={!value}
        />
      )}
      {showVerifyModal && (
        <ModalVerify
          open={showVerifyModal}
          onClose={() => setShowVerifyModal(false)}
          type={type}
          value={value}
          payload={payload}
          onVerified={onVerified}
          userId={userId}
          rentId={rentId}
          label={label}
        />
      )}
    </>
  );
};

const ModalVerify = ({
  open,
  onClose,
  type,
  value,
  payload = {},
  onVerified,
  userId,
  rentId,
  label = "profile",
}: Partial<VerifyData> & { open: any; onClose: any }) => {
  const { t } = useTranslation();
  const [otp, setOtp] = useState<any>();
  const [code, setCode] = useState<string>("");
  const [countdownKey, setCountdownKey] = React.useState<number>(1);
  const time = React.useMemo(() => {
    return Date.now() + 60000;
  }, [countdownKey]);

  const resendOTP = () => {
    try {
      requestOTP({
        type_of_otp: type,
        value,
        label,
        ...payload,
      }).then(
        (resp: any) => {
          setOtp(resp.data.data.otp);
        },
        (err) => {
          Notifications.showError(err);
          onClose();
        }
      );
    } catch (error) {
      Notifications.showError(error);
      onClose();
    }
  };

  const verifyCode = () => {
    Progress.show(
      {
        method: verifyOTP,
        params: [
          {
            id: otp?.id,
            code,
          },
        ],
      },
      (resp: any) => {
        if (rentId) {
          Progress.show(
            {
              method:
                type === "email"
                  ? verifyCustomerEmailRentInfo
                  : verifyCustomerPhoneRentInfo,
              params: [
                {
                  rent_information_id: rentId,
                  ...(type === "email"
                    ? { otp_email_id: otp?.id }
                    : { otp_phone_id: otp?.id }),
                },
              ],
            },
            (resp: any) => {
              Notifications.showSuccess(t("verified"));
              onVerified && onVerified(resp?.data?.data?.otp);
              onClose();
            }
          );
        } else if (userId) {
          Progress.show(
            {
              method:
                type === "email" ? verifyCustomerEmail : verifyCustomerPhone,
              params: [
                {
                  customer_id: userId,
                  ...(type === "email"
                    ? { otp_email_id: otp?.id }
                    : { otp_phone_id: otp?.id }),
                },
              ],
            },
            (resp: any) => {
              Notifications.showSuccess(t("verified"));
              onVerified && onVerified(resp?.data?.data?.otp);
              onClose();
            }
          );
        } else {
          Notifications.showSuccess(t("verified"));
          onVerified && onVerified(resp?.data?.data?.otp);
          onClose();
        }
      },
      (err: any) => {
        Notifications.showError(err);
      }
    );
  };

  useEffect(() => {
    if (code && code.length === 6) {
      verifyCode();
    }
  }, [code]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      showFooter={false}
      showHeader={false}
      className="rounded-xl overflow-hidden"
      width={400}
    >
      <Icon
        name="highlight_off"
        size="large"
        className="absolute right-[10px] top-[10px] cursor-pointer"
        onClick={onClose}
      />
      {otp ? (
        <>
          <div className="pb-4 flex justify-center text-center font-semibold">
            {t("enterAuthenticationCode")}
          </div>
          <div className="flex justify-center flex-column">
            <div className="flex flex-col mb-3">
              <div className="text-center">
                {type === "email" ? t("otpSentToEmail") : t("otpSentToSms")}
              </div>
              <div className="text-center text-primary">{value}</div>
            </div>
            <div className="flex justify-center mb-3">
              <VerificationInput
                validChars="0-9"
                inputProps={{ inputMode: "numeric" }}
                classNames={{
                  character:
                    "border-t-0 border-l-0 border-r-0 border-b-[2px] border-solid border-gray-500",
                  characterInactive: "bg-transparent",
                  characterSelected: "outline-none border-primary",
                }}
                value={code}
                onChange={(value) => setCode(value)}
              />
            </div>
            <Countdown
              date={time}
              precision={3}
              zeroPadTime={2}
              key={countdownKey}
              renderer={({ hours, minutes, seconds, completed }) => (
                <>
                  {/* {completed ? (
                    <span
                      className="block text-center text-primary cursor-pointer"
                      onClick={() => {
                        resendOTP();
                        setCountdownKey(countdownKey + 1);
                      }}
                    >
                      {t("resendNow")}
                    </span>
                  ) : (
                    <>
                      <span className="block mb-1 text-center">
                        {t("notReceiveCode")}
                      </span>
                      <span className="block text-center text-yellow-500">
                        {t("resendNow")} ({zeroPad(minutes)}:{zeroPad(seconds)})
                      </span>
                    </>
                  )} */}

                  <Button
                    className="!w-full mt-4 rounded"
                    disabled={!completed}
                    onClick={() => {
                      resendOTP();
                      setCountdownKey(countdownKey + 1);
                    }}
                  >
                    {completed
                      ? t("resendCode")
                      : `${t("resendCode")} (${zeroPad(minutes)}:${zeroPad(
                          seconds
                        )})`}
                  </Button>
                </>
              )}
            />
          </div>
        </>
      ) : (
        <>
          <div className="pb-4 flex justify-center text-center font-semibold">
            {t("sendAuthenticationCode")}
          </div>
          <div className="flex justify-center flex-column">
            <div className="flex flex-col mb-3">
              <div className="text-center">
                {type === "email"
                  ? t("otpWillSendToEmail")
                  : t("otpWillSendToSms")}
              </div>
              <div className="text-center text-primary">{value}</div>
            </div>
            <Button
              className="!w-full mt-4 rounded"
              onClick={() => {
                resendOTP();
                setCountdownKey(countdownKey + 1);
              }}
            >
              {t("sendVerification")}
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default InputVerifier;
