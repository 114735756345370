import {
  CONTRACT_PAYMENT_STATUS,
  ContractPaymentStatus,
} from "@constants/contract";
import { ORDER_PAYMENT_STATUS } from "@constants/order";
import { PAYMENT_CONFIRM_STATUS, REFUND_STATUS } from "@constants/payment";
import { find, isEmpty } from "lodash";

export const getPaidFromPayments = (payments: any[]) => {
  if (!payments || payments.length === 0) return 0;
  let paid = 0;
  payments.forEach((item) => {
    if (item.status === PAYMENT_CONFIRM_STATUS.APPROVE) {
      paid += item.total;
    }
  });
  return paid;
};

export const getRefundedAmountFromRefunds = (refunds: any[]) => {
  if (!refunds || refunds.length === 0) return 0;
  let paid = 0;
  refunds.forEach((item) => {
    if (
      item.status !== REFUND_STATUS.cancelled.key &&
      item.status !== REFUND_STATUS.rejected.key
    ) {
      paid += item?.amount;
    }
  });
  return paid;
};

export const getPaymentStatus = (paymentStatus: any) => {
  if (!isEmpty(paymentStatus)) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in ORDER_PAYMENT_STATUS) {
      if (ORDER_PAYMENT_STATUS[key].id === paymentStatus) {
        return ORDER_PAYMENT_STATUS[key];
      }
    }
    return ORDER_PAYMENT_STATUS.pendingPayment;
  }
  return ORDER_PAYMENT_STATUS.pendingPayment;
};

export const getContractPaymentStatus = (paymentStatus: any) => {
  if (!isEmpty(paymentStatus)) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in CONTRACT_PAYMENT_STATUS) {
      if (CONTRACT_PAYMENT_STATUS[key].value === paymentStatus) {
        return CONTRACT_PAYMENT_STATUS[key];
      }
    }
    return find(CONTRACT_PAYMENT_STATUS, {
      value: ContractPaymentStatus.PENDING,
    });
  }
  return find(CONTRACT_PAYMENT_STATUS, {
    value: ContractPaymentStatus.PENDING,
  });
};
