import { PRODUCT_TYPE, PRODUCT_WARRANTY } from "@constants/product";
import AppContext from "@helpers/context";
import {
  createProduct,
  getBrandList,
  getCategoryList,
  updatePriceProduct,
  updateStockProduct,
  uploadMediaProduct,
} from "@network/api/product";
import { getWarehouseList } from "@network/api/store";
import { Spin } from "antd";
import { Button, Notifications } from "d-react-components";
import { useFormik } from "formik";
import { forEach, isEmpty, join, map } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import ProductInputRentalConfig from "../input/ProductInputRentalConfig";
import ProductInputName from "../input/ProductInputName";
import ProductInputInfo from "../input/ProductInputInfo";
import ProductInputGallery from "../input/ProductInputGallery";
import ProductInputConfiguration from "../input/ProductInputConfiguration";
import ProductInputPricing from "../input/ProductInputPricing";
import ProductInputSku from "../input/ProductInputSku";
import ProductInputShipping from "../input/ProductInputShipping";
import ProductInputAttribute from "../input/ProductInputAttribute";
import ProductInputWarranty from "../input/ProductInputWarranty";
import * as Yup from "yup";
import { Path } from "@components/layout/Path";

export const ProductRentalSchema = Yup.object().shape({
  rentalRegularPrice: Yup.string().required("Rental regular price required!"),
  rentalSalePrice: Yup.string().required("Rental sale price required!"),
  rentalOptions: Yup.array()
    .min(1, "Rental options required!")
    .required("Rental options required!"),
  // rentalFreeGifts: Yup.array()
  //   .min(1, "Rental gifts required!")
  //   .required("Rental gifts required!"),
});

const ProductSchema = Yup.lazy((value) => {
  let schema = Yup.object().shape({
    name: Yup.string().required("Name required!"),
    description: Yup.string().required("Description required!"),
  });
  if (value.rentalStatus) {
    schema = schema.concat(ProductRentalSchema);
  }
  return schema;
});

const ProductCreate = (props: any) => {
  const FORM_INIT_VALUE = {
    listModel: [],
    brand: undefined,
    model: undefined,
    size: null,
    warranty: null,
    warrantyPeriod: null,

    category: undefined,

    listSelectedWarehouse: [],

    dimension_value: "",
    dimension_option: "cm",
    weight_value: "",
    weight_option: "kg",

    remark: "",
    visibility: true,

    name: "",
    description: "",
    imageurl: [],
    imageToUpload: [],

    regularPrice: 0,
    salePrice: 0,
    productionCost: 0,

    stockList: [],
    sellingWhenOutStock: true,
    sku: null,
    groups: [],
    group_custom_size: [],
    attributeList: [],
    attributeDataList: [],
    aliasSkuList: [],

    product_custom_size: null,
    productType: PRODUCT_TYPE.DEFAULT,

    loadingCreateEdit: false,

    isChangeStock: false,
    isChangeMedia: false,
    isChangePrice: false,

    withStock: props.withStock,

    rentalOptions: [],
    rentalFreeGifts: [],
  };

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [brandList, setBrandList] = useState([]);
  const [warehouseList, setWarehouseList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const location = useLocation();
  const { productDefault } = location.state ?? {};

  const defaultValueForm = () => {
    if (!productDefault) {
      return FORM_INIT_VALUE;
    }
    return productDefault;
  };

  const productForm = useFormik<any>({
    initialValues: defaultValueForm(),
    // initialValues: FORM_INIT_VALUE as any,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: ProductSchema,
    onSubmit: (values: any) => {
      onCreateProduct();
    },
  });
  const formValues = productForm?.values;
  const formErrors = productForm?.errors;

  useEffect(() => {
    loadBrandList();
    loadCategoryList();
    loadWarehouseList();
  }, []);

  useEffect(() => {
    forEach(Object.keys(productForm.errors), (key) =>
      Notifications.showError(`${(productForm.errors as any)?.[key]}`)
    );
  }, [productForm.errors]);

  const loadBrandList = () => {
    getBrandList()
      .then((respone) => {
        const listBrand = respone?.data?.data?.brand ?? [];
        setBrandList(listBrand);
      })
      .catch((err) => console.log({ err }));
  };

  const loadCategoryList = () => {
    getCategoryList()
      .then((respone) => {
        const listCategory = respone?.data?.data?.category ?? [];
        setCategoryList(listCategory);
      })
      .catch((err) => console.log({ err }));
  };

  const loadWarehouseList = () => {
    getWarehouseList()
      .then((respone) => {
        const listWarehouse = respone?.data?.data?.warehouse ?? [];
        setWarehouseList(listWarehouse);
      })
      .catch((err) => console.log({ err }));
  };

  const onCreateProduct = () => {
    const {
      name,
      description,
      sku,
      visibility,
      category,
      brand,
      model,
      size,
      weight_option,
      weight_value,
      dimension_option,
      dimension_value,
      sellingWhenOutStock,
      productionCost,
      remark,
      groups,
      productType,
      product_custom_size,
      group_custom_size,
      attributeList,
      aliasSkuList,
      warranty,
      warrantyPeriod,
      launchDate,

      rentalStatus,
      rentalRegularPrice,
      rentalSalePrice,
      rentalOptions,
      rentalFreeGifts,
    } = formValues;

    const optionList = [
      { weight_option },
      { weight_value },
      { dimension_option },
      {
        dimension_value,
      },
    ];
    const options = optionList
      .map((option: any) => {
        const key = Object.keys(option).toString();
        return { option: key, value: option[key] };
      })
      .filter((item) => !isEmpty(item.value));

    const categoryid = category?.id;
    const brandid = brand?.id;

    const isGroupProduct = productType === PRODUCT_TYPE.GROUP;
    const isGroupCustomSizeProduct =
      productType === PRODUCT_TYPE.GROUP_CUSTOM_SIZE;
    const isCustomSizeProduct = productType === PRODUCT_TYPE.CUSTOM_SIZE;

    const body: any = {
      name,
      description,
      categoryid,
      brandid,
      model_id: model?.id,
      product_size_id: size?.id ?? null,
      visibility,
      options,
      sku,
      selling_out_of_stock: sellingWhenOutStock,
      cost: productionCost,
      remark,
      is_group: isGroupProduct,
      product_type: productType,
      attribute_values: map(attributeList, (item) => item.value),
      alias_sku: map(aliasSkuList, (item) => item.value),
      has_warranty_period: warranty === PRODUCT_WARRANTY.AVAILABLE,
      warranty_period: warrantyPeriod,
      launch_date: launchDate?.valueOf(),

      is_rent: rentalStatus,
      regular_price_rent: rentalRegularPrice,
      sale_price_rent: rentalSalePrice,
      option_of_month_rent: map(rentalOptions, (item) => item.value),
      gift_rent: map(rentalFreeGifts, (item) => ({
        quantity: item.quantity,
        product_id: item?.product?.id,
      })),
    };
    console.log("body", JSON.stringify(body));

    /**
     * add body for variant product types
     */
    if (isGroupProduct) {
      if (groups.length === 0) {
        Notifications.showError(t("groupProductIsEmpty"));
        return;
      }
      body.groups = map(groups, (item) => ({
        product_id: item?.id,
        quantity: item?.quantity,
        sale_price: item?.sale_price ?? 0,
      }));
    }

    if (isGroupCustomSizeProduct) {
      if (group_custom_size.length === 0) {
        Notifications.showError(t("groupCustomSizeProductIsEmpty"));
        return;
      }
      body.group_custom_size = map(group_custom_size, (item) => item.id);
    }

    if (isCustomSizeProduct) {
      if (!product_custom_size || !product_custom_size.product) {
        Notifications.showError(t("customSizeProductIsEmpty"));
        return;
      }
      const {
        product,
        min_length,
        max_length,
        min_width,
        max_width,
        coefficient,
      } = product_custom_size;
      if (
        isEmpty(`${min_length}`) ||
        isEmpty(`${max_length}`) ||
        isEmpty(`${min_width}`) ||
        isEmpty(`${max_width}`) ||
        isEmpty(`${coefficient}`)
      ) {
        Notifications.showError(t("pleaseFillAllCustomSizeFormulaField"));
        return;
      }
      body.product_custom_size = {
        product_id: product?.id,
        min_length,
        max_length,
        max_width,
        min_width,
        coefficient,
      };
    }

    setLoading(true);

    let APICall;
    APICall = createProduct(body);

    APICall.then((res) => {
      const product = res?.data?.data?.product;
      if (product) {
        const allAfterAPI = [
          onEditPrice(product.id),
          ...onUploadMedia(product.id),
        ];
        if (props.withStock) {
          allAfterAPI.push(onEditStockList(product.id));
        }

        return Promise.all(allAfterAPI).then((resAllAPI) => {
          setLoading(false);

          Notifications.showSuccess(t("createProductSuccess"));
          // window.open(res?.data?.data?.editurl, "_self");
          navigate(
            generatePath(Path.PRODUCT_DETAIL, { productId: product.id })
          );
        });
      }
      return Promise.reject();
    }).catch((error) => {
      setLoading(false);
    });
  };

  const onEditPrice = (productId: string) => {
    const { regularPrice, salePrice, productType } = formValues;
    if (
      productType === PRODUCT_TYPE.GROUP ||
      productType === PRODUCT_TYPE.CUSTOM_SIZE
    ) {
      return Promise.resolve(true);
    }
    return updatePriceProduct(productId, regularPrice, salePrice);
  };

  const onUploadMedia = (productId: string) => {
    const { imageToUpload, isChangeMedia } = formValues;
    if (!imageToUpload || imageToUpload.length === 0 || !isChangeMedia) {
      return [Promise.resolve()];
    }
    return map(imageToUpload, (image) => {
      const data = new FormData();
      data.append("file", image.fileData);
      data.append("productid", productId ?? "");
      return uploadMediaProduct(data);
    });
  };

  const onEditStockList = (productId: string) => {
    const { stockList, isChangeStock } = formValues;
    if (!isChangeStock) {
      return Promise.resolve(true);
    }
    const stockListParam = map(stockList, (stockItem) => ({
      warehouseid: stockItem?.warehouse?.id,
      stock: stockItem?.stock ?? 0,
      action: stockItem?.method ?? "default",
      memo: stockItem.memo,
      attachment: map(stockItem?.memoAttach, (item) => item.key),
    }));

    return updateStockProduct(productId, stockListParam as any);
  };

  const renderPublicButton = () => {
    if (loading) {
      return (
        <Button className="ml-3">
          <Spin />
        </Button>
      );
    }
    return (
      <Button
        onClick={() => {
          productForm.handleSubmit();
        }}
        className="ml-3"
      >
        {t("publish")}
      </Button>
    );
  };

  const renderHeader = () => {
    const { name } = formValues;
    return (
      <div className="p-3 bg-white flex-center justify-content-between border-bottom">
        <h4 className="text-primary">{name ? name : t("addProducts")}</h4>
        {renderPublicButton()}
      </div>
    );
  };

  return (
    <AppContext.Provider
      value={{
        productForm,
        warehouseList,
        categoryList,
        brandList,
      }}
    >
      {renderHeader()}
      <div className="p-3 bg-white">
        <ProductInputName productForm={productForm} />
        <div className="mt-3">
          <div className="label">{t("additionalInformation")}</div>
          <ProductInputInfo
            productForm={productForm}
            className="border p-3 mt-3"
          />
        </div>
        <div className="mt-3">
          <div className="label">{t("subscriptionConfig")}</div>

          <ProductInputRentalConfig
            productForm={productForm}
            className=" mt-3"
          />
        </div>

        <div className="mt-3">
          <label>{t("gallery")}</label>
          <ProductInputGallery productForm={productForm} className="mt-3" />
        </div>
        <div className="mt-3">
          <label className="mt-3">{t("productConfiguration")}</label>

          <ProductInputConfiguration
            productForm={productForm}
            className="p-3 border"
          />
        </div>
        <div className="mt-3">
          <label>{t("pricing")}</label>

          <ProductInputPricing
            productForm={productForm}
            className="mt-3 border p-3"
          />
        </div>
        <div className="mt-3">
          <label className="mt-3">{t("sku")}</label>
          <div className="border p-3">
            <ProductInputSku productForm={productForm} />
          </div>
        </div>
        <div className="mt-3">
          <div className="label">{t("productWarranty")}</div>
          <ProductInputWarranty
            productForm={productForm}
            className="border p-3 mt-3"
          />
        </div>
        <div className="mt-3">
          <label>{t("shipping")}</label>

          <ProductInputShipping
            productForm={productForm}
            className="border p-3  mt-3"
          />
        </div>

        <div className="mt-3">
          <label>{t("attributeValue")}</label>

          <ProductInputAttribute
            productForm={productForm}
            className="border p-3"
          />
        </div>
      </div>
    </AppContext.Provider>
  );
};

export default ProductCreate;
