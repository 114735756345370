import InputSelectForm from "@common/input/InputSelectForm";
import BrandSelect from "@components/brand/components/BrandSelect";
import ProductCategorySelect from "@components/product-category/components/ProductCategorySelect";
import Drawer from "@components/shared/Drawer";
import { SERIAL_NUMBER_STATUSES } from "@constants/serial-number";
import { DateInput, InputText } from "d-react-components";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

function SerialNumberFilterDrawer({
  open,
  onClose,
  onFilter,
  defaultValue,
}: any) {
  const { t } = useTranslation();

  const filterForm = useFormik({
    initialValues: defaultValue,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      onFilter && onFilter(values);
      onClose();
    },
  });
  const formValue = filterForm.values;
  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("filter")}
      onSave={() => filterForm.handleSubmit()}
      width={"50vw"}
      size="auto"
    >
      <div className="row">
        <InputSelectForm
          dataSource={SERIAL_NUMBER_STATUSES}
          form={filterForm}
          keyData="status"
          className="mt-3 col-12"
        />
        <BrandSelect
          multiple
          value={formValue.brand}
          onChange={(value) => filterForm.setFieldValue("brand", value)}
          className="mt-3 col-12"
        />
        <ProductCategorySelect
          multiple
          value={formValue.category}
          onChange={(value) => filterForm.setFieldValue("category", value)}
          className="mt-3 col-12"
        />
        <DateInput
          isRangePicker
          value={formValue.createdDate}
          onChange={(value) => filterForm.setFieldValue("createdDate", value)}
          label={t("createdDate")}
          className="mt-3 col-12"
        />
        <DateInput
          isRangePicker
          value={formValue.activatedDate}
          onChange={(value) => filterForm.setFieldValue("activatedDate", value)}
          label={t("activatedDate")}
          className="mt-3 col-12"
        />
        <InputText
          value={formValue.sku}
          onChange={(e) => filterForm.setFieldValue("sku", e?.target?.value)}
          label={t("sku")}
          className="mt-3 col-12"
        />
      </div>
    </Drawer>
  );
}

export default SerialNumberFilterDrawer;
