import { IFieldSelect } from "@interfaces/select";
import { deliveryStockReasonRefuse } from "@network/api/delivery-stock";
import { ObjectUtils, Select } from "d-react-components";
import { isArray, map, uniqBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export interface IReasonRefuseSelect<T> extends IFieldSelect<T> {
  [key: string]: any;
  chainIds?: string[];
}

const ReasonRefuseSelect = ({
  value = [],
  multiple,
  className,
  chainIds = [],
  onChange,
  ...selectProps
}: IReasonRefuseSelect<any>) => {
  const [reasonList, setReasonList] = useState<any[]>(
    isArray(value) ? value : [value]
  );

  const { t } = useTranslation();

  useEffect(() => {
    onLoadReasons();
  }, []);

  const onChangeValue = (id: any) => {
    if (multiple) {
      onChange(map(id, (item) => ObjectUtils.findItemFromId(reasonList, item)));
      return;
    }
    onChange(ObjectUtils.findItemFromId(reasonList, id));
  };

  const reasonValue = useMemo(() => {
    if (multiple) {
      return map(value, (item: any) => item.id);
    }
    return (value as any)?.id;
  }, [value]);

  const onLoadReasons = () => {
    deliveryStockReasonRefuse().then((res: any) => {
      const reasonList = res?.data?.data?.reason ?? [];
      const reasonListMapped = map(reasonList, (item) => ({
        ...item,
        id: item.slug,
      }));

      const selectedValue = isArray(value) ? value : [value];
      const allReasons = [...selectedValue, ...reasonListMapped];
      const uniqReasons = uniqBy(allReasons, (item) => item.id);
      setReasonList(uniqReasons);
    });
  };

  return (
    <Select
      className={className}
      label={t("reason")}
      dataSource={reasonList}
      getLabel={(item) => item?.name}
      value={reasonValue}
      onChange={onChangeValue}
      multiple={multiple}
      placeholder={t("pleaseSelect")}
      showSearch
      filterOption={false}
      {...selectProps}
    />
  );
};

export default ReasonRefuseSelect;
