import { Path } from "@components/layout/Path";
import { WAREHOUSE_STATUSES } from "@constants/warehouse";
import WarehouseAPI from "@network/api/warehouse";
import {
  AwesomeTableComponent,
  HeaderTable,
  IColumnsProps,
  TimeUtils,
  ViewLabelStatus,
} from "d-react-components";
import { debounce, map, upperCase } from "lodash";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, generatePath, useNavigate } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import WarehouseTableFilterDrawer from "./WarehouseTableFilterDrawer";
import WarehouseTableExportDrawer from "./WarehouseTableExportDrawer";

const WarehouseTable = () => {
  const tableRef = useRef<any>(null);
  const textSearchRef = useRef("");
  const [openFilter, setOpenFilter] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [filterBody = { status: "active" }, setFilterBody] = useQueryParam(
    "filter",
    JsonParam
  );
  const navigate = useNavigate();

  const { t } = useTranslation();

  const isFiltered = useMemo(
    () => Object.keys(filterBody).length > 0,
    [filterBody]
  );

  const columns: IColumnsProps = [
    {
      title: t("name"),
      dataIndex: "name",
      render: (name: string, warehouse) => (
        <Link
          to={generatePath(Path.WAREHOUSE_DETAIL, {
            warehouseId: warehouse.id,
          })}
        >
          {name}
        </Link>
      ),
    },
    {
      title: t("systemCode"),
      dataIndex: "warehouse_system_code",
    },
    {
      title: t("navArCode"),
      dataIndex: "code",
    },
    {
      title: t("navPickupCode"),
      dataIndex: "pickup_code",
    },
    {
      title: t("province"),
      dataIndex: "province",
      render: (province) => province?.name,
    },
    {
      title: t("district"),
      dataIndex: "city",
      render: (district) => district?.name ?? "N/A",
    },
    {
      title: t("subdistrict"),
      dataIndex: "subdistrict",
      render: (subdistrict) => subdistrict?.name ?? "N/A",
    },
    {
      title: t("postcode"),
      dataIndex: "postcode",
      render: (postcode) => postcode ?? "N/A",
    },
    {
      title: t("phone"),
      dataIndex: "phone",
    },
    {
      title: t("status"),
      dataIndex: "status",
      render: (status) => (
        <ViewLabelStatus
          status={status}
          listStatus={WAREHOUSE_STATUSES}
          getLabel={(item) => upperCase(item.label)}
        />
      ),
    },
    // {
    //   title: t("createdAt"),
    //   dataIndex: "created",
    //   render: TimeUtils.toDateTime,
    // },
    // {
    //   title: t("lastUpdated"),
    //   dataIndex: "updated",
    //   render: TimeUtils.toDateTime,
    // },
  ];

  const source = (paging: any) => {
    const {
      channel,
      storeType,
      stockAdjust,
      status,
      chain,
      warehouseDelivery,
      province,
      nav,
    } = filterBody;
    const body = {
      search: textSearchRef.current,
      channel: channel?.length ? map(channel, (item) => item.id) : null,
      store_type: storeType,
      stock_adjustment: stockAdjust,
      status,
      chain,
      delivery: warehouseDelivery?.length
        ? map(warehouseDelivery, (item) => item.id)
        : null,
      province: map(province, (item) => item.id),
      nav: map(nav, (item) => item.id),
    };
    return WarehouseAPI.list(paging, body);
  };

  const transformer = (res: any) => {
    return res?.data?.data?.warehouse ?? [];
  };

  const handleChangeTextSearch = debounce((event) => {
    textSearchRef.current = event.target.value;
    tableRef.current.refresh();
  }, 500);

  const onFilter = (values: any) => {
    setFilterBody(values);
    tableRef.current.refresh();
  };

  return (
    // <div className="bg-white">
    <div className="bg-white">
      <HeaderTable
        label={t("warehouse")}
        onClickNew={() => navigate(Path.WAREHOUSE_CREATE)}
        onChangeText={handleChangeTextSearch}
        onClickFilter={() => setOpenFilter(true)}
        onClickExport={() => setOpenExport(true)}
        isFiltered={isFiltered}
        className="app-layout__page-header"
      />
      <AwesomeTableComponent
        ref={(ref) => {
          tableRef.current = ref;
        }}
        columns={columns}
        source={source}
        transformer={transformer}
      />
      {openFilter && (
        <WarehouseTableFilterDrawer
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          defaultValue={filterBody}
          onFilter={onFilter}
        />
      )}
      {openExport && (
        <WarehouseTableExportDrawer
          open={openExport}
          onClose={() => setOpenExport(false)}
        />
      )}
    </div>
  );
};

export default WarehouseTable;
