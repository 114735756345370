import { TRANSFER_STOCK_STATUS_LIST } from "@constants/transfer-stock";
import AppContext from "@helpers/context";
import classNames from "classnames";
import { Icon, TimeUtils, ViewLabelStatus } from "d-react-components";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

const TransferStockDetailHeader = () => {
  const { transferDetail } = useContext(AppContext);
  const { transfer_no, status, by, created } = transferDetail ?? {};
  const { t } = useTranslation();

  return (
    <div className="card-container p-3 flex-center justify-content-between ">
      <div className="flex-column">
        <div className="d-flex">
          <h4 className={classNames("text-[14px] text-primary")}>
            {transfer_no}
          </h4>
          <ViewLabelStatus
            listStatus={TRANSFER_STOCK_STATUS_LIST}
            status={status}
            className="ml-3"
            getLabel={(item) => t(item.label)}
          />
        </div>
        {/* <div className="mt-3 d-flex align-items-center cursor-pointer">
          <Icon name="print" />
          <div className="ml-3"> {t("print")}</div>
        </div> */}
      </div>
      <div className="flex-column">
        <div className="d-flex">{`${t("by")} ${by?.name}`}</div>
        <small>{`${t("createdOn")} ${TimeUtils.toDateTime(created)}`}</small>
      </div>
    </div>
  );
};

export default TransferStockDetailHeader;
