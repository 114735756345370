import { PAYMENTS_METHOD, PAYMENTS_METHODS } from "@constants/payment";
import { find } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

enum CreditCardType {
  VISA = "visa",
  MASTERCARD = "mastercard",
  AMEX = "amex",
  DISCOVER = "discover",
}
const CREDIT_CARD_TYPES = [
  {
    id: CreditCardType.VISA,
    name: "Visa",
    img: "/images/credit-card/visa-logo.png",
  },
  {
    id: CreditCardType.MASTERCARD,
    name: "Mastercard",
    img: "/images/credit-card/mc-logo-52.png",
  },
  {
    id: CreditCardType.AMEX,
    name: "American Express",
    img: "/images/credit-card/americanexpress-logo.png",
  },
  {
    id: CreditCardType.DISCOVER,
    name: "Discover Cards",
    img: "/images/credit-card/discover-card-logo.png",
  },
];
export const PaymentMethod: React.FC<{ method: any }> = ({ method }) => {
  const { t } = useTranslation();
  const { card_number } = method;
  if (!card_number) {
    if (!method.method) return <>{t("cardBy2c2p")}</>;
    const paymentMethod = find(PAYMENTS_METHODS, { id: method.method });
    return (
      <span className="text-xs">
        {paymentMethod?.id === PAYMENTS_METHOD.C2P
          ? t("cardBy2c2p")
          : t(paymentMethod?.label as string)}
      </span>
    );
  }
  let card = null;
  if (card_number.startsWith("4")) {
    card = CREDIT_CARD_TYPES.find((card) => card.id === CreditCardType.VISA);
  } else if (card_number.startsWith("5")) {
    card = CREDIT_CARD_TYPES.find(
      (card) => card.id === CreditCardType.MASTERCARD
    );
  } else if (card_number.startsWith("3")) {
    card = CREDIT_CARD_TYPES.find((card) => card.id === CreditCardType.AMEX);
  } else if (card_number.startsWith("6")) {
    card = CREDIT_CARD_TYPES.find(
      (card) => card.id === CreditCardType.DISCOVER
    );
  }
  if (!card) {
    return null;
  }
  return (
    <div className="text-xs flex items-center">
      <img
        alt="card-logo"
        src={card?.img}
        className="object-cover w-auto relative h-[10px] mr-1"
      />
      <div className="text-nowrap">{card_number.slice(-4)}</div>
    </div>
  );
};

export default PaymentMethod;
