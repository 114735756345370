import { SelectInfinity } from "@components/shared/SelectInfinity";
import { listAllUser } from "@network/api/user";
import classNames from "classnames";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface IUserSelect {
  value: any;
  className?: any;
  label?: any;
  multiple?: any;
  onChange: (values: any) => void;
}

const UserSelect = ({
  label,
  multiple,
  value,
  onChange,
  className,
  ...props
}: IUserSelect) => {
  const { t } = useTranslation();

  const onChangeValue = (value: any) => {
    if (multiple) {
      onChange(value);
      return;
    }
    onChange(value?.[0]);
  };

  const userValue = useMemo(() => {
    if (multiple) {
      return value;
    }
    return value ? [value] : [];
  }, [value]);

  return (
    <div className={classNames("d-flex flex-column ", className)}>
      <label>{label}</label>
      <SelectInfinity
        placeholder={t("search")}
        mode={multiple ? "multiple" : undefined}
        value={userValue}
        fetchFn={(body: any, paging: any) => {
          return listAllUser(
            { search: body.search },
            {
              pageIndex: paging.page,
              pageSize: paging.per_page,
            }
          );
        }}
        dataPath="users"
        onChange={onChangeValue}
        getLabel={(item) => (item ? `${item?.name} (${item?.companyId})` : "")}
        transformer={(res) => {
          const data = res?.data?.data?.users ?? [];
          let transform = [];
          if (data?.length > 0) {
            transform = data?.map((item: any) => ({
              id: item.id,
              name: item.name,
              companyId: item.companyId,
              avatar: item.avatar,
            }));
          }
          return transform;
        }}
        {...props}
      />
    </div>
  );
};

export default UserSelect;
