import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Icon,
  Notifications,
  TimeUtils,
  ViewLabelStatus,
} from "d-react-components";
import { useNavigate, useParams } from "react-router-dom";
import {
  ListRequestChannel,
  ListRequestStore,
} from "../requests/MyRequestDetail";
import { useTranslation } from "react-i18next";
import { RequestAssetDetail } from "@interfaces/my-assets";
import MyAssetsAPI from "@network/api/my-assets";
import {
  MY_REQUEST_EXECUTION_STATUS_LIST,
  MY_REQUEST_STATUS_LIST,
} from "@constants/my-assets";
import { find, map } from "lodash";
import UserAvatarName from "@components/shared/UserAvatarName";
import RequestApprovalDrawer from "./RequestApprovalDrawer";
import ActivitiesView from "@components/order/components/activity/ActivitiesView";
import AppContext from "@helpers/context";

const RequestApprovalDetail = () => {
  const { loadSummaryData } = useContext(AppContext);
  const navigate = useNavigate();
  const activityList = useRef(null);
  const { id } = useParams();
  const { t } = useTranslation();
  const [noPermission, setNoPermission] = useState(false);
  const [approveRequest, setApproveRequest] = useState<any>({});
  const [requestDetail, setRequestDetail] = useState<RequestAssetDetail | null>(
    null
  );

  const loadRequestDetail = () => {
    MyAssetsAPI.getRequestApprovalDetail({ id }).then(
      (resp) => setRequestDetail(resp?.data?.data?.asset),
      (err) => {
        console.log(err);
        setNoPermission(true);
      }
    );
  };

  useEffect(() => {
    loadRequestDetail();
  }, []);

  const requestStatus = useMemo(
    () =>
      find(MY_REQUEST_STATUS_LIST, (item) => item.id === requestDetail?.status),
    [requestDetail]
  );

  const executionStatus = useMemo(
    () =>
      find(
        MY_REQUEST_EXECUTION_STATUS_LIST,
        (item) => item.id === requestDetail?.execution_status
      ),
    [requestDetail]
  );

  const source = (paging: any) => {
    const body = { id };
    return MyAssetsAPI.listRequestAssetActivity(body, paging);
  };

  const transformer = (res: any) => {
    const activityList = res?.data?.data?.activities ?? [];
    return map(activityList, (item) => ({
      ...item,
      files: map(item.attachment, (attItem) => attItem?.attachment),
    }));
  };

  const onClickSendComment = (comment: any, attachment: any) => {
    const body = {
      id: requestDetail?.id,
      comment,
      attachment: map(attachment, (item) => item?.key),
    };
    return MyAssetsAPI.addRequestAssetActivity(body).then(() => {
      Notifications.showSuccess(t("addCommentSuccess"));
    });
  };

  return (
    <div>
      <div>
        <span
          className="mb-2 flex items-center cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <Icon name="chevron_left" />
          {t("back")}
        </span>
        {noPermission ? (
          <div className="text-center p-3 bg-white">
            {t("youNotHavePermission")}
          </div>
        ) : (
          <>
            {" "}
            <div className="bg-primary text-white p-3 mb-3">
              <span className="block mb-1">
                {t("requestId")}: #{requestDetail?.request_no}
              </span>
              <span className="block mb-1 flex items-center">
                <span className="mr-2">{t("requestedBy")}: </span>
                <UserAvatarName
                  user={requestDetail?.request_by}
                  size="xx-small"
                  variant="square"
                />
              </span>
              <span className="block mb-1 flex items-center">
                <span className="mr-2">{t("requestedFor")}: </span>
                <UserAvatarName
                  user={requestDetail?.request_for}
                  size="xx-small"
                  variant="square"
                />
              </span>
              <div className="border-t mt-3 pt-3">{requestDetail?.remark}</div>
            </div>
            {requestDetail?.status === "pending" && (
              <div className="bg-amber-100 p-3 border-l-4 border-amber-400 mb-3">
                <div className="flex items-center mb-3">
                  <span className="mr-2 text-xs font-semibold">
                    {t("status")}
                  </span>
                  {requestStatus && (
                    <ViewLabelStatus
                      content={t(`${requestStatus?.label}`)}
                      color={requestStatus?.color}
                      className="mr-2"
                    />
                  )}
                </div>
                <p>{t("approveRequestText")}</p>
                <div className="flex mt-2">
                  <Button
                    className="mr-2"
                    color="primary"
                    onClick={() =>
                      setApproveRequest({ open: true, type: "approve" })
                    }
                  >
                    {t("approve")}
                  </Button>
                  <Button
                    className=""
                    color="light"
                    onClick={() =>
                      setApproveRequest({ open: true, type: "reject" })
                    }
                  >
                    {t("reject")}
                  </Button>
                </div>
              </div>
            )}
            {requestDetail?.status === "approved" && (
              <div
                className={`p-3 mb-3 bg-green-100 border-l-4 border-green-500 text-sm`}
              >
                <div className="">
                  <div className="flex items-center mb-2">
                    <span className="mr-2 font-weight-bold">
                      {t("status")}:
                    </span>{" "}
                    {requestStatus && (
                      <ViewLabelStatus
                        content={t(`${requestStatus?.label}`)}
                        color={requestStatus?.color}
                        className="mr-2"
                      />
                    )}
                  </div>
                  <div className="flex items-center mb-2">
                    <span className="mr-2 font-weight-bold">
                      {t("confirmBy")}:
                    </span>{" "}
                    <UserAvatarName
                      user={requestDetail.request_by}
                      size="xx-small"
                    />
                  </div>
                  <div className="flex items-center mb-2">
                    <span className="mr-2 font-weight-bold">
                      {t("confirmAt")}:
                    </span>{" "}
                    {TimeUtils.convertMiliToDate(requestDetail.confirmed_at)}
                  </div>
                </div>
                <div className="mt-3">
                  <div className="relative bg-white text-gray-800 p-2 mt-2 arrow-up-white">
                    {requestDetail.confirmed_remark}
                  </div>
                </div>
              </div>
            )}
            {requestDetail?.store?.length &&
              requestDetail?.store?.length > 0 && (
                <ListRequestStore
                  listStore={requestDetail?.store ?? []}
                  user={requestDetail?.request_for}
                />
              )}
            {requestDetail?.channel?.length &&
              requestDetail?.channel?.length > 0 && (
                <ListRequestChannel
                  listChannel={requestDetail?.channel ?? []}
                />
              )}
            <ActivitiesView
              hideType
              source={source}
              transformer={transformer}
              setRef={(listRefParams: any) =>
                (activityList.current = listRefParams)
              }
              onClickSendComment={onClickSendComment}
              isInstantUploadAttach
            />
            {approveRequest?.open && (
              <RequestApprovalDrawer
                open={approveRequest.open}
                onClose={() => setApproveRequest({ open: false })}
                type={approveRequest.type}
                request={requestDetail}
                onSave={() => {
                  setApproveRequest({ open: false });
                  loadRequestDetail();
                  loadSummaryData();
                }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default RequestApprovalDetail;
