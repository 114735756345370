import {
  Button,
  IRowsKey,
  Icon,
  ObjectUtils,
  ViewRowInterchange,
} from "d-react-components";
import React, { useContext, useMemo, useState } from "react";
import ImageIcon from "@common/Icon";

import WarehouseDetailAddressUpdateDrawer from "./WarehouseDetailAddressUpdateDrawer";
import AppContext from "@helpers/context";
import { useTranslation } from "react-i18next";
import { IWarehouse } from "@interfaces/warehouse";
import { WAREHOUSE_ADDRESS_KEYS } from "@constants/warehouse";

const WarehouseDetailAddress = () => {
  const { warehouse, loadWarehouseDetail } = useContext(AppContext);
  const [openUpdate, setOpenUpdate] = useState(false);
  const { id } = warehouse;
  const { t } = useTranslation();

  const DETAILS_KEY = useMemo<IRowsKey<IWarehouse>[]>(
    () => [
      {
        id: "address",
        label: t("address"),
      },
      {
        id: "subdistrict",
        label: t("subdistrict"),
        renderContent: ({ data, item }) => data?.name,
      },
      {
        id: "city",
        label: t("city"),
        renderContent: ({ data, item }) => data?.name,
      },
      {
        id: "province",
        label: t("province"),
        renderContent: ({ data, item }) => data?.name,
      },
      {
        id: "latitude",
        label: t("latitude"),
      },
      {
        id: "longitude",
        label: t("longitude"),
      },
      {
        id: "postcode",
        label: t("postcode"),
      },
      {
        id: "latitude",
        label: t("viewMap"),
        renderContent: ({ data, item }) => (
          <Icon
            name="map"
            className="cursor-pointer"
            onClick={() => {
              window.open(
                `https://maps.google.com/?q=${item?.latitude},${item?.longitude}`
              );
            }}
          />
        ),
      },
    ],
    [t]
  );

  return (
    <div className="border mt-3 p-4">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h5>{t("address")}</h5>
        <span onClick={() => setOpenUpdate(true)}>
          <ImageIcon
            className="w-[15px] cursor-pointer"
            src="/images/icons/edit.svg"
          />
        </span>
      </div>
      <ViewRowInterchange
        className="w-100"
        dataSource={warehouse}
        keyList={DETAILS_KEY as any}
        variant="border"
        classNameLabel="font-weight-bold "
        classNameContent="text-end "
      />

      {openUpdate && (
        <WarehouseDetailAddressUpdateDrawer
          open={openUpdate}
          onClose={() => setOpenUpdate(false)}
          warehouseId={id}
          defaultValue={ObjectUtils.mapFieldsLangsSTC(
            warehouse,
            WAREHOUSE_ADDRESS_KEYS as any
          )}
          onUpdateSuccess={loadWarehouseDetail}
        />
      )}
    </div>
  );
};

export default WarehouseDetailAddress;
