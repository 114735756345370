import React, { useContext, useRef, useState } from "react";
import { debounce } from "lodash";
import Drawer from "@components/shared/Drawer";
import {
  InputText,
  Icon,
  AwesomeListComponent,
  Avatar,
  Checkbox,
} from "d-react-components";
import { searchCustomer } from "@network/api/customer";
import { useTranslation } from "react-i18next";
import AppContext from "@helpers/context";

const ModalSelectCustomer = ({
  open,
  onClose,
  defaultSelected,
  onSave,
}: any) => {
  const [customerSelected, setCustomerSelected] = useState(defaultSelected);
  const { channel } = useContext(AppContext);
  const textSearchRef = useRef("");
  const customerListRef = useRef<any>();
  const { t } = useTranslation();

  const source = (pagingData: any) => {
    return searchCustomer(
      { search: textSearchRef.current, channel_id: channel?.id },
      {
        page: pagingData.pageIndex,
      }
    );
  };

  const transformer = (res: any) => {
    return res?.data?.data?.customers ?? [];
  };

  const renderItem = (user: any, index: number) => {
    const isSelected = customerSelected && user.id === customerSelected.id;
    const { firstname, lastname, nickname, email, imageurl } = user;

    return (
      <div
        className="flex items-center mb-3"
        onClick={() => setCustomerSelected(user)}
      >
        <Checkbox checked={isSelected} />
        <div className="mx-2">
          <Avatar
            src={imageurl}
            text={firstname}
            size="small"
            className="rounded overflow-hidden"
            variant="square"
          />
        </div>
        <div className="flex flex-column ml-1">
          <span className="subTitle1">
            {firstname} {lastname}({nickname})
          </span>
          <span className="text-xs text-gray-500">{email}</span>
        </div>
      </div>
    );
  };

  const onChangeTextSearch = debounce((text) => {
    textSearchRef.current = text;
    customerListRef.current.refresh();
  }, 1000);

  return (
    <Drawer
      className="modalSelectCustomer"
      open={open}
      onClose={() => onClose()}
      onSave={() => onSave(customerSelected)}
      title={t("selectCustomer")}
      size="auto"
      width={500}
    >
      <div className="modalSelectCustomerContainer">
        <div
          className="modalSelectCustomerSearchInput"
          aria-describedby="popover-list"
        >
          <InputText
            prefix={<Icon name="search" />}
            placeholder={t("searchYourExistingCustomer")}
            onChange={(event) => onChangeTextSearch(event.target.value)}
            className="mb-3"
          />
        </div>
        <AwesomeListComponent
          ref={customerListRef}
          source={source}
          renderItem={renderItem}
          transformer={transformer}
          styleContainer={{ overflowY: "auto" }}
          isPaging
          pagingProps={{
            pageIndex: 1,
            pageSize: 10,
          }}
          variant="load-more"
        />
      </div>
    </Drawer>
  );
};

export default ModalSelectCustomer;
