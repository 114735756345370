import { V1_URL } from "@constants/common";
import { getAdminToken } from "@helpers/account";
import { getLineConnectInfo, unlinkLineConnect } from "@network/api/auth";
import { Button, TimeUtils } from "d-react-components";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ConnectLine = () => {
  const { t } = useTranslation();
  const [isConnected, setIsConnected] = useState(false);
  const [lineAccInfo, setLineAccInfo] = useState<any>();
  const token = getAdminToken();

  useEffect(() => {
    getLineConnectInfo({}).then((resp) => {
      const isConnectedToLine = !isEmpty(resp?.data?.data?.line_id);
      setLineAccInfo(resp?.data?.data);
      setIsConnected(isConnectedToLine);
    });
  }, []);

  const unLinkAccount = () => {
    unlinkLineConnect({}).then((resp) => {
      window.location.reload();
    });
  };

  return (
    <div className="flex flex-col bg-white h-full items-center justify-center">
      <div className="flex items-center">
        <img src="/images/icons/saleinfinity.svg" alt="Sale Infinity X" />
        <img src="/images/icons/connect.svg" alt="connect to" />
        <img src="/images/icons/line.svg" alt="Line" />
      </div>
      {!isConnected && (
        <>
          <p className="max-w-md text-center mb-4">{t("connectLineDesc")}</p>
          <a href={`${V1_URL}/line-connect/redirect?api_token=${token}`}>
            <Button>{t("connectLine")}</Button>
          </a>
        </>
      )}
      {isConnected && (
        <>
          <p className="max-w-md text-center mb-1">
            {t("conntectLineSuccess")}
          </p>
          <span className="text-gray-500 mb-4">
            ({t("authorizedAt")}{" "}
            {TimeUtils.convertMiliToDateTime(lineAccInfo?.information?.link_at)}
            )
          </span>
          <div className="flex items-center">
            <Button className="mr-2" onClick={() => unLinkAccount()}>
              {t("unlink")}
            </Button>
            <a href={`${V1_URL}/line-connect/redirect?api_token=${token}`}>
              <Button variant="outline">{t("reconnectLine")}</Button>
            </a>
          </div>
        </>
      )}
    </div>
  );
};

export default ConnectLine;
