import InputTextForm from "@common/input/InputTextForm";
import ButtonFileUpload from "@components/shared/ButtonFileUpload";
import Drawer from "@components/shared/Drawer";
import AppContext from "@helpers/context";
import { editStockRequest } from "@network/api/stock-request";
import { DateInput, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import { map } from "lodash";
import moment from "moment";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

export const GPEventGeneralSchema = Yup.object().shape({
  name: Yup.string().required("Required field!"),
  visibility: Yup.string().required("Required field!"),
  remark: Yup.string().required("Required field!"),
  period: Yup.array().required("Required field!"),
});

const StockRequestDetailGeneralEditDrawer = ({
  open,
  onClose,
  defaultValue,
  onUpdateSuccess,
}: any) => {
  const { requestDetail, loadEventDetail } = useContext(AppContext);
  const { t } = useTranslation();

  const generalForm = useFormik({
    initialValues: defaultValue,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: schema,
    onSubmit: (values) => {
      console.log("values?.documents", values?.documents);
      const body = {
        id: requestDetail?.id,
        remark: values?.remark,
        expected_arrival_date: moment(values?.expectedDateOfArrival)?.valueOf(),
        documents: map(values?.documents, (item) => item.key),
      };
      Progress.show({ method: editStockRequest, params: [body] }, () => {
        Notifications.showSuccess(t("updateSuccess"));
        onClose();
        loadEventDetail();
        onUpdateSuccess && onUpdateSuccess();
      });
    },
  });
  const formValues = generalForm?.values;
  const formErrors = generalForm?.errors;

  const classNameField = "col-span-1 mt-3";
  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("general")}
      onSave={() => generalForm.handleSubmit()}
      size="auto"
      width="50%"
    >
      <div className="grid grid-cols-1 gap-4">
        <DateInput
          value={formValues?.expectedArrivalDate}
          onChange={(value) =>
            generalForm.setFieldValue("expectedArrivalDate", value)
          }
          label={t("expectedDateOfArrival")}
          className={classNameField}
          error={formErrors?.expectedArrivalDate as any}
          required
          format="DD/MM/YYYY"
        />
        <InputTextForm
          keyData="remark"
          form={generalForm}
          className={classNameField}
          multiple
          required
        />
        <div className={classNameField}>
          <label>{t("document")}</label>
          <ButtonFileUpload
            onChange={(values: any) =>
              generalForm.setFieldValue("documents", values)
            }
            maxFiles={5}
            //   ref={buttonUploadRef}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default StockRequestDetailGeneralEditDrawer;
