import Icon from "@common/Icon";
import Drawer from "@components/shared/Drawer";
import AppContext from "@helpers/context";
import { IProduct } from "@interfaces/product";
import {
  IRowsKey,
  Notifications,
  Progress,
  TimeUtils,
  ViewRowInterchange,
} from "d-react-components";
import { useFormik } from "formik";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ProductInputName from "../input/ProductInputName";
import ProductInputInfo from "../input/ProductInputInfo";
import { editProduct } from "@network/api/product";
import { useParams } from "react-router-dom";

interface IProductDetailGeneralEditDrawer {
  open: boolean;
  defaultValue?: any;
  onUpdated: any;
  onClose: () => void;
}

const ProductDetailGeneralEditDrawer = ({
  open,
  defaultValue,
  onUpdated,
  onClose,
}: IProductDetailGeneralEditDrawer) => {
  const { t } = useTranslation();
  const { productId } = useParams();

  const productForm = useFormik<any>({
    initialValues: defaultValue as any,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: schema,
    onSubmit: (values: any) => {
      onUpdateProduct(values);
    },
  });
  const formValues = productForm?.values;
  const formErrors = productForm?.errors;

  const onUpdateProduct = (values: any) => {
    const {
      name,
      description,
      visibility,
      category,
      brand,
      model,
      size,
      remark,
      launchDate,
      selling_out_of_stock,
    } = values;

    const body: any = {
      productid: productId,
      name,
      description,
      categoryid: category?.id,
      brandid: brand?.id,
      model_id: model?.id,
      product_size_id: size?.id ?? null,
      visibility,
      launch_date: launchDate?.valueOf(),
      remark,
      selling_out_of_stock,
      // options,
      // selling_out_of_stock: sellingWhenOutStock,
      // cost: productionCost,
      // is_group: isGroupProduct,
      // product_type: productType,
      // attribute_values: map(attributeList, (item) => item.value),
      // sku,
      // alias_sku: map(aliasSkuList, (item) => item.value),
      // has_warranty_period: warranty === PRODUCT_WARRANTY.AVAILABLE,
      // warranty_period: warrantyPeriod,
    };

    Progress.show({ method: editProduct, params: [body] }, (res: any) => {
      Notifications.showSuccess(t("updateSuccess"));
      onUpdated();
      onClose();
    });
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("general")}
      onSave={() => {
        productForm.handleSubmit();
      }}
      size="auto"
      width="600"
    >
      <div>
        <ProductInputName productForm={productForm} />
        <ProductInputInfo productForm={productForm} className="mt-2" />
      </div>
    </Drawer>
  );
};

const ProductDetailGeneral = () => {
  const { productDetail, loadProductDetail } = useContext(AppContext);
  const [openEdit, setOpenEdit] = useState(false);
  const { t } = useTranslation();

  const DETAILS_KEY = useMemo<IRowsKey<IProduct>[]>(
    () => [
      {
        id: "name",
        label: t("name"),
      },
      {
        id: "description",
        label: t("description"),
      },
      {
        id: "category",
        label: t("category"),
        renderContent: ({ data, item }) => {
          return data?.name ?? "N/A";
        },
      },
      {
        id: "brand",
        label: t("brand"),
        renderContent: ({ data, item }) => {
          return data?.name ?? "N/A";
        },
      },
      {
        id: "model",
        label: t("model"),
        renderContent: ({ data, item }) => {
          return data?.name ?? "N/A";
        },
      },
      {
        id: "size",
        label: t("size"),
        renderContent: ({ data, item }) => {
          return data?.name ?? "N/A";
        },
      },
      {
        id: "thickness_height",
        label: t("thicknessInches"),
        renderContent: ({ data, item }) => {
          return data ?? "N/A";
        },
      },
      {
        id: "launchDate",
        label: t("launchDate"),
        renderContent: ({ data, item }) => {
          return data ? TimeUtils.toDateTime(data) : "N/A";
        },
      },
      {
        id: "visibility",
        label: t("visibility"),
        renderContent: ({ data, item }) => {
          return data ? t("enabled") : t("disable");
        },
      },
      {
        id: "selling_out_of_stock",
        label: t("continueSelling"),
        renderContent: ({ data, item }) => {
          return data ? t("enabled") : t("disable");
        },
      },
    ],
    [t]
  );

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mt-3">
        <h5>{t("general")}</h5>
        <span onClick={() => setOpenEdit(true)}>
          <Icon
            className="w-[15px] cursor-pointer"
            src="/images/icons/edit.svg"
          />
        </span>
      </div>
      <div className="mt-3 border p-4 ">
        <ViewRowInterchange
          dataSource={productDetail}
          keyList={DETAILS_KEY as any}
          variant="border"
          classNameLabel="text-hint"
          classNameContent="text-end "
        />
        {openEdit && (
          <ProductDetailGeneralEditDrawer
            open={openEdit}
            onClose={() => setOpenEdit(false)}
            defaultValue={productDetail}
            onUpdated={loadProductDetail}
          />
        )}
      </div>
    </div>
  );
};

export default ProductDetailGeneral;
