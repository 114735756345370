import { Select } from "d-react-components";
import { toUpper } from "lodash";
import { useTranslation } from "react-i18next";

const ConditionApplyType = ({ onChangeApplyType, value, disabled }: any) => {
  const { t } = useTranslation();

  const APPLY_TYPES = [
    { id: "all", label: "all" },
    { id: "any", label: "any" },
  ];
  return (
    <div className="flex-row align-items-center bg-black px-2">
      <text className="text-white">{t("if")}</text>
      <Select
        onChange={onChangeApplyType}
        className="mx-2 cart-rule__condition-select cart-rule__condition-apply-type"
        dataSource={APPLY_TYPES}
        getLabel={(item) => toUpper(item.label)}
        variant="standard"
        value={value}
        disabled={disabled}
      />
      <text className="text-white">{t("ofthefollowingconditionsaremet")} </text>
    </div>
  );
};

export default ConditionApplyType;
