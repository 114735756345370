import { CustomerType } from "@interfaces/customer";
import { isEmpty, join, split } from "lodash";

export const getFullName = (data: any) => {
  if (!data) {
    return "";
  }
  if (data?.customer_type == CustomerType.BUSINESS) {
    return data.company;
  }
  return `${data?.firstname ?? "N/A"} ${data?.lastname ?? "N/A"}`;
};

export function nFormatter(num: number, digits: number) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
}

export const getFullAddressFromShipping = (shipping: any) => {
  if (!shipping) {
    return "";
  }
  const arrayAddress = [
    shipping.address,
    shipping?.subdistrict?.name,
    shipping?.city?.name,
    shipping?.province?.name,
    shipping?.postcode,
  ];
  return join(arrayAddress, ", ");
};

export function convertCsvJSON(csv: any) {
  const lines = split(csv, "\n");
  const result = [];
  const headers = split(lines[0], ",");
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < lines.length; i++) {
    let validObject = false;
    // eslint-disable-next-line no-continue
    if (!lines[i]) continue;
    const objectItem: any = {};
    const currentline = lines[i].split(",");
    headers.forEach((header, index) => {
      const currentHeader = header.replace(/\W/g, "");
      const currentLineIndex = currentline[index];
      let currentValue = currentline[index]?.replace(/[^0-9.]/g, "");
      if (currentLineIndex.includes("-")) {
        currentValue = `-${currentValue}`;
      }
      if (!isEmpty(currentValue)) {
        validObject = true;
        objectItem[currentHeader] = currentValue;
      }
    });
    if (validObject) {
      result.push(objectItem);
    }
  }
  return result;
}

export function months2years(months: number) {
  const y = Math.floor(months / 12);
  const monthsLeft = months / 12 - y;
  const m = Math.floor(monthsLeft * 12);
  return { y, m };
}
