import { Path } from "@components/layout/Path";
import { SERIAL_NUMBER_STATUSES } from "@constants/serial-number";
import AppContext from "@helpers/context";
import SerialNumberAPI from "@network/api/serial-number";
import {
  AvatarName,
  AwesomeTableComponent,
  HeaderTable,
  TimeUtils,
  ViewLabelStatus,
} from "d-react-components";
import { debounce } from "lodash";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, generatePath, useNavigate } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import SerialNumberFilterDrawer from "./SerialNumberFilterDrawer";

const SerialNumberTable = () => {
  const [openFilter, setOpenFilter] = useState(false);
  const [filterBody = {}, setFilterBody] = useQueryParam("filter", JsonParam);
  const tableRef = useRef<any>();
  const searchRef = useRef<any>();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const isFiltered = useMemo(
    () => Object.keys(filterBody).length > 0,
    [filterBody]
  );

  const columns = [
    {
      title: t("serialNo"),
      dataIndex: "serial_number",
      render: (name: any, item: any) => (
        <Link
          to={generatePath(Path.SERIAL_NUMBER_DETAIL, {
            serialNumberId: item.id,
          })}
        >
          {name}
        </Link>
      ),
    },
    {
      title: t("sku"),
      dataIndex: "product",
      render: (product: any) => product?.sku ?? "N/A",
    },
    {
      title: t("itemName"),
      dataIndex: "product",
      render: (product: any) => product?.name ?? "N/A",
    },
    {
      title: t("status"),
      dataIndex: "info",
      render: (info: any) => (
        <ViewLabelStatus
          status={info?.status}
          listStatus={SERIAL_NUMBER_STATUSES}
          getLabel={(item) => t(item.label)}
        />
      ),
    },
    {
      title: t("activatedAt"),
      dataIndex: "info",
      render: (info: any) =>
        info?.status_at ? TimeUtils.toDateTime(info?.status_at) : "N/A",
    },
    {
      title: t("createdBy"),
      dataIndex: "info",
      render: (info: any) =>
        info?.by ? (
          <AvatarName
            user={{ avatar: info?.by?.avatar, fullName: info?.by?.name }}
          />
        ) : (
          "N/A"
        ),
    },
    {
      title: t("createdAt"),
      dataIndex: "info",
      render: (info: any) => TimeUtils.toDateTime(info?.created),
    },
  ];

  const source = (paging: any) => {
    const { activatedDate, createdDate, ...rest } = filterBody;
    const body = {
      ...(rest || {}),
      created_at_from: createdDate?.[0]?.valueOf(),
      created_at_to: createdDate?.[1]?.valueOf(),
      status_at_from: activatedDate?.[0]?.valueOf(),
      status_at_to: activatedDate?.[1]?.valueOf(),
      search: searchRef.current,
    };
    return SerialNumberAPI.list(body, paging);
  };

  const transformer = (res: any) => {
    return res?.data?.data?.serial_number ?? [];
  };

  const onChangeSearch = debounce((text) => {
    searchRef.current = text;
    tableRef.current.refresh();
  }, 1000);

  const onFilter = (values: any) => {
    setFilterBody(values);
    tableRef.current.refresh();
  };

  return (
    <AppContext.Provider value={{}}>
      <div className="bg-white">
        <HeaderTable
          label={t("serialNumber")}
          //   onClickNew={() => navigate(Path.SERIAL_NUMBER_CREATE)}
          onChangeText={(event: any) => onChangeSearch(event.target.value)}
          onClickFilter={() => setOpenFilter(true)}
          isFiltered={isFiltered}
          className="app-layout__page-header"
        />
        <AwesomeTableComponent
          ref={(ref) => {
            tableRef.current = ref;
          }}
          columns={columns}
          source={source}
          transformer={transformer}
        />
        {openFilter && (
          <SerialNumberFilterDrawer
            open={openFilter}
            onClose={() => setOpenFilter(false)}
            onFilter={onFilter}
            defaultValue={filterBody}
          />
        )}
      </div>
    </AppContext.Provider>
  );
};

export default SerialNumberTable;
