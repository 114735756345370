import { map } from "lodash";
import { IChain } from "./chain";
import { IChannel } from "./channel";
import { IInventoryEvent } from "./inventory-event";
import { IStore } from "./store";

export interface IStockAdjustment {
  id: string;
  ref: string;
  channel: IChannel;
  warehouse: IStore;
  stock_inventory_control: IInventoryEvent;
  created: number;
  updated: number;
}

export const mapStockAdjustmentToServer = (input: any) => {
  const { channel, store, remark, attachments, products, event } = input;
  return {
    channel_id: channel?.id,
    warehouse_id: store?.id,
    remark,
    products: map(products, (item) => ({
      product_id: item?.id,
      adjustment: item.adjustment,
    })),
    attachment: map(attachments, (item) => item.key),
    stock_inventory_control_id: event?.id,
  };
};
