import AppContext from "@helpers/context";
import { every, filter, findIndex, isEmpty } from "lodash";
import React, { useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import CustomerInfo from "../components/CustomerInfo";
import CustomerSelect from "../components/CustomerSelect";
import ProductCartItem from "../../product/components/ProductCartItem";
import { PRODUCT_TYPE } from "@constants/product";
import {
  getBundlesFollowCustomSize,
  getListSizeDefault,
  getSizeDefault,
  isCustomSizePro,
  isGroupCustomSizeProduct,
  transferQuantityOfProduct,
  transformerProduct,
} from "@helpers/product";
import CustomSizeField from "@components/product/components/CustomSizeField";
import { CART_RULE_ACTION_KEY } from "@constants/order";
import FreeGiftSelect from "../components/FreeGiftSelect";
import { Notifications } from "d-react-components";
import { validateCartRuleOrder } from "@network/api/order";
import PriceTable from "../components/PriceTable";
import OrderProductActions from "./OrderProductActions";
import CustomProductRemark from "@components/product/components/CustomProductRemark";
import { useMeasure } from "react-use";
import { OrderCreateType } from "./OrderDetail";
import { validateCartRuleContract } from "@network/api/rental";

const isCustomSizeProduct = (product: any) => {
  return isGroupCustomSizeProduct(product) || isCustomSizePro(product);
};

const isGroupProduct = (product: any) => {
  const { product_type } = product?.product;
  return product_type === PRODUCT_TYPE.GROUP;
};

const getTotal = (subtotal: any, giftList: any, fees?: number[]) => {
  let result = subtotal;
  if (!isEmpty(fees)) {
    fees?.forEach((fee) => {
      result += Number(fee);
    });
  }

  if (isEmpty(giftList)) {
    return result;
  }

  const couponResultList = filter(giftList, (item: any) => {
    const specific = item?.action?.specific;
    return (
      specific === CART_RULE_ACTION_KEY.discountPercentage.id ||
      specific === CART_RULE_ACTION_KEY.discountFix.id ||
      specific === CART_RULE_ACTION_KEY.discountPercentageCart.id ||
      specific === CART_RULE_ACTION_KEY.discountFixAmountCart.id ||
      specific === CART_RULE_ACTION_KEY.buyNXGetDiscountPercentage.id ||
      specific === CART_RULE_ACTION_KEY.buyNXGetDiscountPercentageOnY.id ||
      specific === CART_RULE_ACTION_KEY.buyNXGetDiscountFixAmountOnY.id ||
      specific === CART_RULE_ACTION_KEY.buyNXGetDiscountFixAmount.id
    );
  });

  couponResultList.forEach((coupon) => {
    const { specific, amount, percentage } = coupon?.action;
    if (
      specific === CART_RULE_ACTION_KEY.discountPercentage.id &&
      (coupon.selfDiscount ||
        coupon.isEmployeeDiscount ||
        coupon.isChainDiscount)
    ) {
      result -= (result * percentage) / 100;
    } else {
      result -= amount;
    }
    // result = result - amount;
  });

  return result;
};

const getSubTotal = (productList: any, type = OrderCreateType.NORMAL) => {
  let result = 0;
  for (const key in productList) {
    const price =
      type === OrderCreateType.RENTAL
        ? productList[key]?.product?.sale_price_rent
        : productList[key]?.sale_price;
    result += price * productList[key].quantity;
  }

  return result;
};

const OrderProducts = ({ submitOrder, navBarHeight }: any) => {
  const { t } = useTranslation();
  const {
    values,
    setFieldValue,
    productList,
    setProductList,
    originOrder,
    freeGiftList,
    setFreeGiftList,
    freeGiftListRent,
    setFreeGiftListRent,
    productCustomList,
    setProductCustomList,
    subTotalState,
    setSubTotalState,
    setCheckCoupon,
    discountChainList,
    setTotalSate,
    isEventSaleSelected,
    type,
  } = useContext(AppContext);
  const { selectedCustomer } = values;

  const [actionBlockRef, { height: actionBlockHeight }] = useMeasure<any>();
  const [customerBlockRef, { height: customerBlockHeight }] = useMeasure<any>();

  useEffect(() => {
    const subTotalValue = getSubTotal(
      [...productList, ...productCustomList],
      type
    );
    setSubTotalState(subTotalValue);
  }, [productList, productCustomList]);

  useEffect(() => {
    values.channel && onLoadFreeGift();
  }, [subTotalState, selectedCustomer]);

  useEffect(() => {
    if (type === OrderCreateType.RENTAL) {
      setFreeGiftListRent(productList?.[0]?.product?.gift_rent ?? []);
    }
  }, [productList]);

  useEffect(() => {
    const totalValue = getTotal(
      subTotalState,
      [...freeGiftList, ...discountChainList],
      [values?.shippingFee ?? 0]
    );
    setTotalSate(totalValue);
  }, [subTotalState, freeGiftList, discountChainList, values?.shippingFee]);

  const onChangeRemarkCustomSizePro = (product: any, remark: string) => {
    const resultList = productList?.map((item: any) => {
      if (item.productid === product.productid) {
        return { ...product, remark };
      }
      return item;
    });
    setProductList(resultList);
  };

  const onChangeRemarkOfCustomPro = (product: any, remark: string) => {
    const resultList = productCustomList?.map((item: any) => {
      if (item.id === product.id) {
        return { ...product, remark };
      }

      return item;
    });
    setProductCustomList(resultList);
  };

  const getSugBodyProductView = (
    product: any,
    originOrder: any,
    onChangeProduct: any,
    onReplaceProduct: any
  ) => {
    if (!product) {
      return "";
    }
    const { isGift, isOldPro } = product;
    const subBody = [];

    if (isOldPro) {
      subBody.push(
        <div className="text-xs bg-red-500 text-white py-1 px-2 inline-block w-fit line-through">
          {`${t("order")} #${originOrder?.order?.orderid ?? "N/A"}`}
        </div>
      );
    }

    if (isCustomSizeProduct(product)) {
      subBody.push(
        <>
          <CustomSizeField
            product={product}
            onChangeProduct={onChangeProduct}
            onReplaceProduct={onReplaceProduct}
            isView={isOldPro}
            onChangeRemarkCustomSizePro={onChangeRemarkCustomSizePro}
          />
          <div className="mb-2">
            <CustomProductRemark
              onChangeRemark={(remark: string) =>
                onChangeRemarkCustomSizePro(product, remark)
              }
              remark={product?.remark}
            />
          </div>
        </>
      );
    }

    if (product?.isCustomProduct) {
      subBody.push(
        <CustomProductRemark
          onChangeRemark={(remark: string) =>
            onChangeRemarkOfCustomPro(product, remark)
          }
          remark={product?.remark}
          attributeList={product?.attributeList ?? []}
          meta={product.productInfo}
          product={product}
        />
      );
    }
    return subBody;
  };

  const getProductsFromFreeGift = () => {
    let productGifts: any[] = [];
    freeGiftList.forEach((freeGiftItem: any) => {
      const productActionGift = (freeGiftItem?.action?.gift ?? [])?.map(
        (item: any) => ({
          ...item,
          isGift: true,
        })
      );
      productGifts = productGifts.concat(productActionGift);
    });
    return productGifts;
  };

  const getBundleSelected = (bundleList: any) => {
    const bundleSelectedList: any[] = [];
    freeGiftList.forEach((item: any) => {
      const bundleList = item?.action?.bundle?.list_bundle ?? [];
      bundleList.forEach((bundleItem: any) => {
        const { quantity } = bundleItem;
        if (quantity && quantity > 0) {
          bundleSelectedList.push(bundleItem);
        }
      });
    });
    return bundleSelectedList;
  };

  const getProductsFromBundleSelected = (bundleList: any) => {
    let productList: any[] = [];
    bundleList.forEach((bundleItem: any) => {
      const bundleProducts = bundleItem?.product?.map((item: any) => {
        const transformedProduct = transformerProduct(
          item,
          type === OrderCreateType.RENTAL
        );
        return {
          ...transformedProduct,
          quantity: item.quantity * bundleItem.quantity,
        };
      });
      productList = productList.concat(bundleProducts);
    });
    return productList;
  };

  const applyGroupCustomSizingProduct = (product: any, groupProducts: any) => {
    if (product.isGift && !isEmpty(product.bundleId)) {
      applySizingGroupBundlePro(product, groupProducts);
    } else if (product.isGift && isEmpty(product.bundleId)) {
      applySizingGroupGiftPro(product, groupProducts);
    } else {
      applySizingOrderGroupPro(product, groupProducts);
    }
  };

  const applySizingProduct = (product: any) => {
    if (product.isGift && !isEmpty(product.bundleId)) {
      applySizingBundlePro(product);
    } else if (product.isGift && isEmpty(product.bundleId)) {
      applySizingGiftPro(product);
    } else {
      applySizingOrderPro(product);
    }
  };

  const applySizingOrderPro = (product: any) => {
    const result = productList?.map((item: any) => {
      if (item?.product?.id === product?.product?.id) {
        return product;
      }
      return item;
    });
    setProductList(result);
  };

  const applySizingOrderGroupPro = (product: any, groupProducts: any) => {
    const result = productList
      .filter((item: any) => item.product.id !== product.product.id)
      .concat(groupProducts);

    setProductList(result);
  };

  const applySizingBundlePro = (product: any) => {
    const freeGiftResult = [...freeGiftList];
    const productIndexData = findProductInBundleFreeGift(product);
    if (!productIndexData) return;

    const { freeGiftIndex, bundleIndex, productIndex } = productIndexData;
    //set new product value to found product
    freeGiftResult[freeGiftIndex].action.bundle.list_bundle[
      bundleIndex
    ].product[productIndex] = product;

    setFreeGiftList(freeGiftResult);
  };
  const applySizingGiftPro = (product: any) => {
    const freeGiftResult = [...freeGiftList];
    const productIndexData = findProInFreeGift(product);
    if (!productIndexData) return;

    const { freeGiftIndex, productIndex } = productIndexData;
    //set new product value to found product
    freeGiftResult[freeGiftIndex].action.gift[productIndex] = product;

    setFreeGiftList(freeGiftResult);
  };

  const applySizingGroupBundlePro = (oldProduct: any, groupProducts: any) => {
    const freeGiftResult = [...freeGiftList];
    const productIndexData = findProductInBundleFreeGift(oldProduct);
    if (!productIndexData) return;
    const { freeGiftIndex, bundleIndex, productIndex } = productIndexData;
    const listProduct =
      freeGiftResult[freeGiftIndex].action.bundle.list_bundle[bundleIndex]
        .product ?? [];

    const listProResult = listProduct
      .filter((item: any) => item.productid !== oldProduct.productid)
      .concat(
        groupProducts?.map((item: any) => ({
          ...item,
          quantity: oldProduct.quantity,
          bundleId: oldProduct.bundleId,
          freeGiftId: oldProduct.freeGiftId,
        }))
      );
    freeGiftResult[freeGiftIndex].action.bundle.list_bundle[
      bundleIndex
    ].product = listProResult;
    setFreeGiftList(freeGiftResult);
  };

  const applySizingGroupGiftPro = (oldProduct: any, groupProducts: any) => {
    const freeGiftResult = [...freeGiftList];
    const productIndexData = findProInFreeGift(oldProduct);
    if (!productIndexData) return;
    const { freeGiftIndex, productIndex } = productIndexData;
    const listProduct = freeGiftResult[freeGiftIndex].action.gift ?? [];

    const listProResult = listProduct
      .filter((item: any) => item.productid !== oldProduct.productid)
      .concat(
        groupProducts?.map((item: any) => ({
          ...item,
          quantity: oldProduct.quantity,
          freeGiftId: oldProduct.freeGiftId,
        }))
      );
    freeGiftResult[freeGiftIndex].action.gift = listProResult;
    setFreeGiftList(freeGiftResult);
  };

  const findProInFreeGift = (product: any) => {
    const freeGiftIndex = findIndex(
      freeGiftList,
      (item: any) => item.id === product.freeGiftId
    );
    //Not found free gift
    if (freeGiftIndex < 0) return false;

    const giftList = freeGiftList?.[freeGiftIndex]?.action?.gift ?? [];
    const productIndex = findIndex(
      giftList,
      (item: any) => item.productid === product.productid
    );
    //Not found product
    if (productIndex < 0) return false;

    return { freeGiftIndex, productIndex };
  };

  const findProductInBundleFreeGift = (product: any) => {
    const freeGiftIndex = findIndex(
      freeGiftList,
      (item: any) => item.id === product.freeGiftId
    );
    //Not found free gift
    if (freeGiftIndex < 0) return false;

    const bundle = freeGiftList?.[freeGiftIndex]?.action?.bundle ?? {};
    const bundleList = bundle?.list_bundle ?? [];
    const bundleIndex = findIndex(
      bundleList,
      (item: any) => item.id === product.bundleId
    );
    //Not found bundle
    if (bundleIndex < 0) return false;

    const productIndex = findIndex(
      bundleList?.[bundleIndex]?.product ?? [],
      (item: any) => item.productid === product.productid
    );
    //Not found product
    if (productIndex < 0) return false;

    return { freeGiftIndex, bundleIndex, productIndex };
  };

  const renderProductSubName = (product: any) => {
    if (product.isCustomProduct) {
      // return <UpdateCustomItemButton product={product} />;
      return "";
    }

    return <div />;
  };

  const onChangeQuantity = (product: any, quantity: number) => {
    if (quantity < 1) {
      return;
    }
    if (isEventSaleSelected && quantity > product?.product?.stock) {
      Notifications.showError(t("notification:thereIsNotEnoughStock"));
      return;
    }

    if (product?.isCustomProduct) {
      const newProCustomList =
        productCustomList &&
        productCustomList?.map((item: any) => {
          if (item.productid === product?.productid) {
            return transformerProduct(
              { ...product, quantity },
              type === OrderCreateType.RENTAL
            );
          }
          return item;
        });

      setProductCustomList(newProCustomList);
    } else {
      const newProductList =
        productList &&
        productList?.map((item: any) => {
          if (item.product.id === product?.product?.id) {
            return transferQuantityOfProduct(product, quantity);
          }
          return item;
        });
      setProductList([...newProductList]);
    }
  };

  const onDeleteProductHandle = (productInfo: any) => {
    const { id } = productInfo.product;

    if (productInfo.isCustomProduct) {
      const newProCustomList =
        productCustomList &&
        productCustomList.filter((item: any) => item.id !== productInfo.id);
      setProductCustomList(newProCustomList);
    } else {
      const newProductList =
        productList &&
        productList.filter((item: any) => item.product.id !== id);
      setProductList([...newProductList]);
    }
  };

  const onSaveSelectGift = (bundleList: any, freeGiftItem: any) => {
    const freeGiftListResult = freeGiftList?.map((item: any) => {
      if (item.id === freeGiftItem.id) {
        const itemResult = { ...item };
        // itemResult.action.bundle.list_bundle = bundleList;
        itemResult.action.bundle.list_bundle = getBundlesFollowCustomSize(
          freeGiftItem,
          itemResult.action.bundle,
          bundleList
        );
        return itemResult;
      }
      return item;
    });
    setFreeGiftList(freeGiftListResult);
  };

  const onLoadFreeGift = (payload?: any) => {
    const couponCode = payload?.value;
    if (!productList || productList.length === 0) {
      setFreeGiftList([]);
      return;
    }
    const codes = freeGiftList
      .filter((item: any) => !!item?.code?.code)
      ?.map((item: any) => item?.code?.code);
    const selfDiscount = freeGiftList.filter((item: any) => item?.selfDiscount);
    if (!isEmpty(couponCode)) {
      codes.push(couponCode);
    }
    const body = {
      storeid: values?.storeSelected?.id,
      channelid: values?.channel,
      products: productList?.map((item: any) => ({
        discountid: item?.discountid,
        productid: item?.product?.id,
        quantity: item?.quantity,
      })),
      codes,
      subtotal: subTotalState,
      customerid: selectedCustomer?.id,
    };
    const api =
      type === OrderCreateType.NORMAL
        ? validateCartRuleOrder
        : validateCartRuleContract;
    api(body)
      .then((res: any) => {
        const freeGiftListRes = res?.data?.data?.cartrule ?? [];
        let freeGiftListResult = freeGiftListRes?.map((item: any) => {
          const bundle = item?.action?.bundle ?? {};
          const bundleList = bundle?.list_bundle ?? [];

          const freeGiftItemResult = { ...item };
          freeGiftItemResult.action.bundle.list_bundle =
            getBundlesFollowCustomSize(item, bundle, bundleList);

          //transform gift list
          const giftList = item?.action?.gift ?? [];
          freeGiftItemResult.action.gift = giftList?.map((giftItem: any) => ({
            ...giftItem,
            id: giftItem.productid,
            isGift: true,
            freeGiftId: item.id,
            meta: getListSizeDefault(giftItem.quantity),
          }));

          return freeGiftItemResult;
        });

        //if code is entered that not fit any cart rule => show alert error
        if (!isEmpty(couponCode)) {
          const isExistCouponCode = every(freeGiftListResult, (item) => {
            const freeGiftCode = item?.code?.code ?? "";
            return freeGiftCode.toLowerCase() !== couponCode.toLowerCase();
          });
          if (isExistCouponCode) {
            Notifications.showError(t("notification:invalidCouponCode"));
            setCheckCoupon({ validate: false, id: payload?.id });
          } else {
            setCheckCoupon({ validate: true, id: payload?.id });
          }
        }
        if (!isEmpty(selfDiscount)) {
          freeGiftListResult = [...freeGiftListResult, ...selfDiscount];
        }
        setFreeGiftList(freeGiftListResult);
      })
      .catch((err) => {
        if (!isEmpty(couponCode)) {
          Notifications.showError(t("notification:invalidCouponCode"));
          setCheckCoupon({ validate: false, id: payload?.id });
        }
      });
  };

  const productsSourceTable = useMemo(() => {
    let dataResult: any[] = [];
    dataResult = productList?.map((proItem: any) =>
      transformerProduct(proItem, type === OrderCreateType.RENTAL)
    );

    //add product from free gift
    const productGifts = getProductsFromFreeGift();
    if (!isEmpty(productGifts)) {
      dataResult = dataResult.concat(productGifts);
    }
    //add product from "Buying X get multiple bundle"  cart rule
    const bundleSelectedList = getBundleSelected(freeGiftList);
    const productBundle = getProductsFromBundleSelected(bundleSelectedList);
    if (!isEmpty(productBundle)) {
      dataResult = dataResult.concat(productBundle);
    }

    //add productList of old order
    const originProductList = originOrder?.products ?? [];
    if (!isEmpty(originProductList)) {
      originProductList.forEach((oldPro: any) => {
        const index = findIndex(
          dataResult,
          (proItem) => proItem.product.id === oldPro.product.id
        );
        dataResult.splice(index + 1, 0, { ...oldPro, isOldPro: true });
      });
    }

    //add product custom
    if (!isEmpty(productCustomList)) {
      dataResult = dataResult.concat(productCustomList);
    }

    if (!isEmpty(freeGiftListRent)) {
      const listGiftRent = freeGiftListRent?.map((item: any) => ({
        ...item,
        isGift: true,
        regular_price: 0,
        sale_price: 0,
        price: 0,
      }));
      dataResult = dataResult.concat(listGiftRent);
    }

    return dataResult;
  }, [
    productList,
    originOrder,
    freeGiftList,
    freeGiftListRent,
    productCustomList,
  ]);

  return (
    <div className="pr-8 relative">
      <div
        className="bg-white p-3"
        style={{ height: `calc(100vh - ${navBarHeight + 32 ?? 0}px)` }}
      >
        <div ref={customerBlockRef}>
          <CustomerSelect />
          {selectedCustomer && (
            <CustomerInfo customer={selectedCustomer?.[0]} />
          )}
        </div>
        <div
          className="overflow-auto"
          style={{
            height: `calc(100% - ${
              customerBlockHeight + actionBlockHeight - 16
            }px)`,
          }}
        >
          <div className="my-3">
            {productsSourceTable?.map((item, index) => (
              <ProductCartItem
                item={item}
                key={index}
                onChangeQuantity={onChangeQuantity}
                onDeleteProductHandle={onDeleteProductHandle}
                subBody={getSugBodyProductView(
                  item,
                  originOrder,
                  applySizingProduct,
                  applyGroupCustomSizingProduct
                )}
                renderSubName={() => renderProductSubName(item)}
                showRentPrice={type === OrderCreateType.RENTAL}
              />
            ))}
          </div>
          {freeGiftList
            .filter(
              (item: any) =>
                item?.action?.specific === CART_RULE_ACTION_KEY.freegift.id ||
                item?.action?.specific === CART_RULE_ACTION_KEY.buyXGetBundle.id
            )
            ?.map((freeGiftItem: any) => (
              <FreeGiftSelect
                freeGift={freeGiftItem}
                onSaveSelectGift={(bundleList: any) =>
                  onSaveSelectGift(bundleList, freeGiftItem)
                }
              />
            ))}
        </div>
      </div>
      {/* <div className="absolute bg-white bottom-0 left-0 w-full z-50"> */}
      <div className="relative">
        <div
          ref={actionBlockRef}
          className="absolute bottom-0 left-0 w-full z-[99]"
        >
          <div className="bg-white">
            <PriceTable />
          </div>
          <OrderProductActions
            applyCoupon={(coupon: any) => onLoadFreeGift(coupon)}
            submitOrder={submitOrder}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderProducts;
