import ProductName from "@components/product/components/ProductName";
import QuantityInputField from "@components/product/components/QuantityInputField";
import SearchGroupProducts from "@components/product/components/SearchGroupProducts";
import { IProduct } from "@interfaces/product";
import {
  AwesomeTableComponent,
  IColumnsProps,
  Icon,
  ObjectUtils,
} from "d-react-components";
import { filter, map } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

interface IStockRequestProductForm {
  form: any;
  className?: any;
}

const StockRequestProductForm = ({
  form,
  className,
}: IStockRequestProductForm) => {
  const { t } = useTranslation();
  const formValues = form?.values;

  const columns: IColumnsProps = [
    {
      title: t("item"),
      dataIndex: "",
      render: (product) => <ProductName item={{ product }} />,
    },
    {
      title: t("requestQty"),
      dataIndex: "quantity",
      render: (quantity, item) => (
        <QuantityInputField
          quantity={quantity}
          onChangeQuantity={(quantity: number) => {
            if (quantity < 1) return;
            onChangeQuantity(item, quantity);
          }}
        />
      ),
    },
    {
      title: t("action"),
      dataIndex: "",
      render: (item) => (
        <Icon
          name="delete"
          className="cursor-default"
          onClick={() => onRemoveProduct(item.id)}
        />
      ),
    },
  ];

  const onChangeQuantity = (product: IProduct, quantity: number) => {
    const result = ObjectUtils.updateArrayById(formValues?.products, {
      ...product,
      quantity,
    });
    form.setFieldValue("products", result);
  };

  const onRemoveProduct = (id: string) => {
    const result = filter(formValues?.products, (item) => item?.id !== id);
    form.setFieldValue("products", result);
  };

  const onChangeProduct = (list: any[] = []) => {
    const currentIdList = map(formValues?.products, (item) => item?.id);
    const addingList = map(list, (item) => {
      if (currentIdList?.includes(item?.id)) {
        return item;
      }
      return { ...item, quantity: 1 };
    });

    form.setFieldValue("products", addingList);
  };

  return (
    <div className={className}>
      <div className="flex-row-between-center mb-2">
        <label>{t("items")}</label>
        <SearchGroupProducts
          onChange={onChangeProduct}
          selectedProducts={formValues?.products ?? []}
          stockConsider={false}
          customStockColumn={(item: any) => (
            <div className="body1 tablePriceCell">
              {item?.stock?.[0]?.stock}
            </div>
          )}
          removeColumns={["stock_quantity", "regular_price", "sale_price"]}
        />
      </div>
      <AwesomeTableComponent
        pagination={false}
        columns={columns}
        dataSource={formValues?.products ?? []}
        className=""
      />
    </div>
  );
};

export default StockRequestProductForm;
