import AppContext from "@helpers/context";
import {
  Button,
  IRowsKey,
  Icon,
  Notifications,
  Progress,
  ViewRowInterchange,
} from "d-react-components";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import ImageIcon from "@common/Icon";
import Drawer from "@components/shared/Drawer";
import { CART_RULE_TYPES } from "@constants/cart-rule";
import { ICartRule, mapObjectServerToClient } from "@interfaces/cart-rule";
import { useFormik } from "formik";
import { find, join, map } from "lodash";
import CartRuleConfigurationForm from "../component/CartRuleConfigurationForm";
import CartRuleAPI from "@network/api/cart-rule";
import { useParams } from "react-router-dom";
import { exportToCSV } from "@helpers/file";

interface ICartRuleDetailConfigurationUpdateDrawer {
  open: boolean;
  defaultValue: any;
  onUpdateSuccess: any;
  onClose: () => void;
}

const CartRuleDetailConfigurationUpdateDrawer = ({
  open,
  defaultValue,
  onUpdateSuccess,
  onClose,
}: ICartRuleDetailConfigurationUpdateDrawer) => {
  const { t } = useTranslation();
  const { cartRule, loadCartRuleDetail } = useContext(AppContext);
  const { cartRuleId } = useParams();

  const cartRuleForm = useFormik<any>({
    initialValues: defaultValue as any,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: schema,
    onSubmit: (values: any) => {
      const {
        coupon,
        priority,
        usage_limit,
        usage_customer,
        conjunctional,
        code,
        condition_rule,
        configQuantityType,
      } = values;
      const input = {
        id: cartRuleId,
        quantity: configQuantityType,
        rule: {
          coupon,
          priority,
          usage_limit,
          usage_customer,
          conjunctional,
          code,
          conditions: condition_rule,
        },
      };
      onUpdate(input);
    },
  });
  const formValues = cartRuleForm?.values;
  const formErrors = cartRuleForm?.errors;

  const onUpdate = (input: any) => {
    Progress.show(
      { method: CartRuleAPI.update, params: [input] },
      (res: any) => {
        onClose();
        loadCartRuleDetail();
        Notifications.showSuccess(t("updateCartRuleSuccess"));
      }
    );
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("general")}
      onSave={() => cartRuleForm.handleSubmit()}
      size="large"
    >
      <CartRuleConfigurationForm
        form={cartRuleForm}
        formDisabled={{ configQuantityType: true, coupon: true }}
      />
    </Drawer>
  );
};

const CartRuleDetailConfiguration = () => {
  const { cartRule, loadCartRuleDetail } = useContext(AppContext);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [showCodes, setShowCodes] = useState(false);
  const { t } = useTranslation();

  const { id } = cartRule;

  const renderCartRuleCode = (codes: any[]) => {
    if (codes?.length === 1) {
      return codes?.[0]?.code;
    }
    return (
      <div className="d-flex justify-content-end">
        <Button variant="outline" onClick={() => setShowCodes(true)}>
          {t("coupon")}
          <div className="h-[15px] w-[15px] rounded-2 flex-center ml-2 bg-error text-white text-tiny">
            {codes?.length}
          </div>
        </Button>
      </div>
    );
  };

  const DETAILS_KEY = useMemo<IRowsKey<ICartRule>[]>(
    () => [
      {
        id: "rule",
        label: t("type"),
        renderContent: ({ data, item }) => {
          const cartRuleAction = find(
            CART_RULE_TYPES,
            (item) => item.id === data?.coupon
          );
          return t(cartRuleAction?.label ?? "");
        },
      },
      {
        id: "rule",
        label: t("code"),
        renderContent: ({ data, item }) => renderCartRuleCode(data?.code),
      },
      {
        id: "rule",
        label: t("usageLimitPerUser"),
        renderContent: ({ data, item }) => data?.usage_customer,
      },
      {
        id: "rule",
        label: t("usageLimit"),
        renderContent: ({ data, item }) => data?.usage_limit,
      },
      {
        id: "rule",
        label: t("conjunctionalUse"),
        renderContent: ({ data, item }) =>
          data?.conjunctional ? t("yes") : t("no"),
      },
      {
        id: "rule",
        label: t("priority"),
        renderContent: ({ data, item }) => data?.priority,
      },
    ],
    [t]
  );

  const onExportCode = () => {
    const exportData = map(cartRule?.rule?.code, (item, index) => {
      return {
        No: index + 1,
        Code: item?.code ?? "N/A",
      };
    });
    const fileName = `CART_CODE_${new Date()}`;
    exportToCSV(exportData, fileName);
    setShowCodes(false);
  };

  return (
    <div className="border mt-3 p-4">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h5>{t("cartRuleConfiguration")}</h5>
        <span onClick={() => setOpenUpdate(true)}>
          <ImageIcon
            className="w-[15px] cursor-pointer"
            src="/images/icons/edit.svg"
          />
        </span>
      </div>
      <ViewRowInterchange
        className="w-100"
        dataSource={cartRule}
        keyList={DETAILS_KEY as any}
        variant="border"
        classNameLabel="font-weight-bold "
        classNameContent="text-end "
      />
      {openUpdate && (
        <CartRuleDetailConfigurationUpdateDrawer
          open={openUpdate}
          onClose={() => setOpenUpdate(false)}
          defaultValue={mapObjectServerToClient(cartRule)}
          onUpdateSuccess={loadCartRuleDetail}
        />
      )}
      {showCodes && (
        <Drawer
          open={showCodes}
          onClose={() => setShowCodes(false)}
          title={t("code")}
          saveText={t("export")}
          onSave={onExportCode as any}
        >
          <div className="flex-column">
            {map(cartRule?.rule?.code, (code) => (
              <div className="mt-2">
                {code.code}
                <Icon
                  name="content_copy"
                  className="p-2 cursor-pointer"
                  onClick={() => {
                    navigator.clipboard.writeText(code?.code ?? "");
                    Notifications.showSuccess(t("notification:copySuccess"));
                  }}
                />
              </div>
            ))}
          </div>
        </Drawer>
      )}
    </div>
  );
};

export default CartRuleDetailConfiguration;
