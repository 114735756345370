import { Path } from "@components/layout/Path";
import AppContext from "@helpers/context";
import { IWarehouse } from "@interfaces/warehouse";
import { getProvinceList } from "@network/api/address";
import WarehouseAPI from "@network/api/warehouse";
import {
  Button,
  DialogManager,
  Notifications,
  Progress,
  TabBar,
} from "d-react-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import WarehouseDetailAddress from "./WarehouseDetailAddress";
import WarehouseDetailChannel from "./WarehouseDetailChannel";
import WarehouseDetailDashboard from "./WarehouseDetailDashboard";
import WarehouseDetailDelivery from "./WarehouseDetailDelivery";
import WarehouseDetailGeneral from "./WarehouseDetailGeneral";
import WarehouseDetailInfo from "./WarehouseDetailInfo";
import WarehouseDetailUser from "./WarehouseDetailUser";
import WarehouseDetailUserKPI from "./WarehouseDetailUserKPI";
import HeaderDetail from "@common/HeaderDetail";
import classNames from "classnames";

const TABS = [
  {
    id: "dashboard",
    label: "dashboard",
    component: <WarehouseDetailDashboard />,
  },
  {
    id: "general",
    label: "general",
    component: <WarehouseDetailGeneral />,
  },

  {
    id: "address",
    label: "address",
    component: <WarehouseDetailAddress />,
  },
  {
    id: "channel",
    label: "channel",
    component: <WarehouseDetailChannel />,
  },
  {
    id: "warehouseDelivery",
    label: "warehouseDelivery",
    component: <WarehouseDetailDelivery />,
  },
  {
    id: "users",
    label: "users",
    component: <WarehouseDetailUser />,
  },
  {
    id: "userKpi",
    label: "defaultUserKpi",
    component: <WarehouseDetailUserKPI />,
  },
];

const WarehouseDetail = () => {
  const [warehouse, setWarehouse] = useState<IWarehouse | null>(null);
  const [provinceList, setProvinceList] = useState([]);
  const [tab, setTab] = useState<any>(TABS[0]);
  const { warehouseId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    loadWarehouseDetail();
    loadProvinceData();
  }, []);

  const loadWarehouseDetail = () => {
    const body = {
      id: warehouseId,
    };
    Progress.show(
      { method: WarehouseAPI.detail, params: [body] },
      (res: any) => {
        const warehouse = res?.data?.data?.warehouse;
        setWarehouse(warehouse);
      }
    );
  };

  const loadProvinceData = () => {
    getProvinceList().then((res) => {
      const provinceList = res?.data?.data?.province;
      setProvinceList(provinceList);
    });
  };

  const onClickDuplicate = () => {
    DialogManager.showConfirm(
      t("duplicateConfirmation"),
      t("duplicateConfirmationDescription"),
      onDuplicate
    );
  };

  const onDuplicate = () => {
    const body = { warehouse_id: warehouseId };
    Progress.show(
      { method: WarehouseAPI.duplicate, params: [body] },
      (res: any) => {
        const warehouseId = res?.data?.data?.id;
        Notifications.showSuccess(t("duplicateWarehouseSuccess"));
        navigate(generatePath(Path.WAREHOUSE_DETAIL, { warehouseId }));
      }
    );
  };

  if (!warehouse) return <div />;

  return (
    <AppContext.Provider
      value={{ warehouse, loadWarehouseDetail, provinceList }}
    >
      <div className="flex-column-container bg-trans">
        <HeaderDetail
          title={warehouse?.name}
          rightSide={
            <div className="d-flex">
              <Button onClick={onClickDuplicate} size="small" className="ml-3">
                {t("duplicate")}
              </Button>
            </div>
          }
        />
        <div className="p-3 bg-white">
          <div className="grid grid-cols-4 gap-4">
            <div className="col-span-4 md:col-span-1">
              <WarehouseDetailInfo />
            </div>
            <div className="col-span-4 md:col-span-3">
              <div className="">
                <TabBar
                  dataSource={TABS}
                  onChange={setTab}
                  value={tab}
                  getLabel={(item: any) => t(item.label)}
                  classNameItem={classNames(
                    "bg-white border-right-0 border-left-0 border-top-0"
                  )}
                />
                {tab.component}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppContext.Provider>
  );
};

export default WarehouseDetail;
