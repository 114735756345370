import { Progress, Select } from "d-react-components";
import * as Yup from "yup";
import { useFormik } from "formik";
import React, { useContext, useEffect, useMemo, useState } from "react";
import AppContext from "@helpers/context";
import { orderPaymentUpdateMethod } from "@network/api/order";
import { useTranslation } from "react-i18next";
import Drawer from "@components/shared/Drawer";
import { updateRentalInvoice } from "@network/api/rental";
import { PAYMENT_DEFAULT } from "@constants/payment";

const ChangePaymentMethodModal = ({
  open,
  onClose,
  payment,
  onUpdated,
  invoice,
}: any) => {
  const { t } = useTranslation();
  const { paymentMethodsList, order } = useContext(AppContext);

  const ChangeMethodSchema = useMemo(
    () =>
      Yup.lazy((value) => {
        let schema = Yup.object().shape({
          method: Yup.string().required(t("fieldRequired")),
        });
        return schema;
      }),
    [t]
  );
  const methodForm = useFormik<any>({
    initialValues: {
      ...(payment.method === PAYMENT_DEFAULT.id
        ? {}
        : { method: payment.method }),
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: ChangeMethodSchema,
    onSubmit: (values: any) => {
      const body = {
        id: order.id,
        payments: invoice?.payments?.map((p: any) =>
          p.id === payment.id
            ? { total: parseFloat(p.total), method: values.method }
            : { total: parseFloat(p.total), method: p.method }
        ),
      };
      Progress.show({ method: updateRentalInvoice, params: body }, onUpdated);
    },
  });

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("changePaymentMethod")}
      onSave={() => methodForm.handleSubmit()}
    >
      <Select
        dataSource={paymentMethodsList}
        label={t("paymentMethod")}
        value={methodForm.values.method}
        error={methodForm.errors.method}
        onChange={(value) => methodForm.setFieldValue("method", value)}
        placeholder={t("pleaseSelect")}
      />
    </Drawer>
  );
};

export default ChangePaymentMethodModal;
