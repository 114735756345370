import React, { useState, useContext, useRef } from "react";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import { Menu, Table } from "antd";
import { find, forEach, map, reduce, sortBy } from "lodash";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import AppContext from "@helpers/context";
import {
  AwesomeTableComponent,
  Button,
  Dropdown,
  ObjectUtils,
  StringUtils,
  TabBar,
  TimeUtils,
} from "d-react-components";
import Drawer from "@components/shared/Drawer";
import { exportToCSVMultipleSheet } from "@helpers/file";
import classNames from "classnames";

const FORECAST_TABS = [
  { label: "rawMaterialForecast", id: "rawMaterialForecast" },
  { label: "saleForecast", id: "saleForecast" },
];

const DOWNLOAD_OPTIONS = [
  { id: "pdf", label: "forecastPdf" },
  { id: "excel", label: "forecastExcel" },
];

const sortObjectByKey = (object: any) => {
  if (!object) return {};
  return Object.keys(object)
    .sort()
    .reduce((obj: any, key) => {
      obj[key] = object[key];
      return obj;
    }, {});
};

const MaterialForecastTable = ({ propsTable = {} }) => {
  const { rawMaterialList } = useContext(AppContext);
  const { t } = useTranslation();

  const columnsMaterial = [
    {
      title: t("no"),
      dataIndex: "",
      render: (i: any, item: any, index: any) => index + 1,
    },
    {
      title: t("sku"),
      dataIndex: "sku",
    },
    {
      title: t("name"),
      dataIndex: "title",
    },

    {
      title: t("UOM"),
      dataIndex: "uom",
      render: (uom: any) => uom?.name,
    },
    {
      title: t("quantityNeed"),
      dataIndex: "quantityNeed",
      render: (quantityNeed: any) => (
        <text className="text-primary">{quantityNeed}</text>
      ),
    },
  ];

  return (
    <AwesomeTableComponent
      columns={columnsMaterial}
      dataSource={rawMaterialList}
      className="w-100"
      {...propsTable}
    />
  );
};

const SaleForecastTable = ({ propsTable }: any) => {
  const { saleForecastList } = useContext(AppContext);
  const { t } = useTranslation();

  const columns = [
    {
      title: t("no"),
      dataIndex: "",
      render: (i: any, item: any, index: any) => index + 1,
    },
    {
      title: t("sku"),
      dataIndex: "sku",
    },
    {
      title: t("name"),
      dataIndex: "name",
    },
    {
      title: t("unitPrice"),
      dataIndex: "unitPrice",
      width: 200,
      render: StringUtils.moneyThaiFormat,
    },
    {
      title: t("quantity"),
      dataIndex: "quantityNeed",
      width: 200,
    },
    {
      title: t("totalSales"),
      dataIndex: "total",
      width: 200,
      render: StringUtils.moneyThaiFormat,
    },
  ];

  const grandTotal = reduce(
    saleForecastList,
    (sum, item) => (sum += item.total),
    0
  );
  return (
    <Table
      columns={columns}
      dataSource={saleForecastList}
      className="w-100"
      summary={() => (
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} />
          <Table.Summary.Cell index={1} />
          <Table.Summary.Cell index={2} />
          <Table.Summary.Cell index={3} />
          <Table.Summary.Cell index={4} className="font-weight-bold">
            {t("grantTotal")}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={5} className="font-weight-bold">
            {StringUtils.moneyThaiFormat(grandTotal)}
          </Table.Summary.Cell>
        </Table.Summary.Row>
      )}
      {...propsTable}
    />
  );
};

const ModalRawMaterialExportPdf = ({ open, onClose }: any) => {
  const pdfRawExportRef = useRef<any>();
  const pdfSaleExportRef = useRef<any>();
  const { t } = useTranslation();

  const onClickExport = async () => {
    pdfRawExportRef.current.save();
    pdfSaleExportRef.current.save();
    return Promise.resolve();
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      onSave={onClickExport}
      title={t("exportPdf")}
    >
      <PDFExport
        paperSize="auto"
        ref={(ref) => (pdfRawExportRef.current = ref)}
        margin={20}
        fileName={`forecast_raw_material_${TimeUtils.toDate(
          new Date().getTime()
        )}`}
        author="Lotus Team"
      >
        <div className="my-5 d-flex flex-column">
          <h4 style={{ fontWeight: "bold" }}>{t("rawMaterialForecast")}</h4>
          <text>
            {`${t("generatedOn")} ${TimeUtils.toDateTime(
              new Date().getTime()
            )}`}
          </text>
        </div>
        <MaterialForecastTable propsTable={{ pagination: false }} />
      </PDFExport>
      <PDFExport
        ref={(ref) => (pdfSaleExportRef.current = ref)}
        paperSize="auto"
        margin={20}
        fileName={`forecast_sales_${TimeUtils.convertMiliToDate(
          new Date().getTime()
        )}`}
        author="Lotus Team"
      >
        <div className="my-5 d-flex flex-column">
          <h4 style={{ fontWeight: "bold" }}>{t("saleForecast")}</h4>
          <text>
            {`${t("generatedOn")} ${TimeUtils.toDateTime(
              new Date().getTime()
            )}`}
          </text>
        </div>
        <SaleForecastTable propsTable={{ pagination: false }} />
      </PDFExport>
    </Drawer>
  );
};

const RawMaterialForecastTable = () => {
  const { saleForecastList, rawMaterialList } = useContext(AppContext);
  const [tabSelected, setTabSelected] = useState(FORECAST_TABS[0]);
  const [openMoreAction, setOpenMoreAction] = useState<any>(null);
  const [openModalExportPdf, setOpenModalExportPdf] = useState(false);
  const open = Boolean(openMoreAction);
  const { t } = useTranslation();

  const handleChooseActionDownLoad = (action: any) => {
    setOpenMoreAction(null);
    switch (action) {
      case "pdf":
        setOpenModalExportPdf(true);
        break;
      case "excel":
        onClickDownloadExcel();
        break;
      default:
    }
  };

  const onClickDownloadExcel = () => {
    const saleForecastExportData = map(saleForecastList, (item, index) => {
      return {
        No: index + 1,
        SKU: item?.sku ?? "N/A",
        Name: item?.name ?? "N/A",
        "Unit Price": item?.unitPrice ?? "N/A",
        "Forecast Quantity": item?.quantityNeed ?? "N/A",
        "Total Sales Forecast": item?.total ?? "N/A",
      };
    });
    saleForecastExportData.push({
      No: " ",
      SKU: " ",
      Name: " ",
      "Unit Price": " ",
      "Forecast Quantity": "Grand total",
      "Total Sales Forecast": `${reduce(
        saleForecastList,
        (sum, item) => (sum += item?.total ?? 0),
        0
      )}`,
    });

    const rawMaterialExportData = map(rawMaterialList, (item, index) => ({
      No: index + 1,
      "SKU RM": item.sku,
      "NAME RM": item.title,
      UOM: item.uom?.name,
      "Grand Total RM": item.quantityNeed,
    }));

    const rawMaterialDetailExportData = map(
      rawMaterialList,
      (rawMaterial, index) => {
        const dataSource = {
          No: index + 1,
          "SKU RM": rawMaterial.sku,
          "NAME RM": rawMaterial.title,
          UOM: rawMaterial.uom?.name,
          "Grand Total RM": rawMaterial.quantityNeed,
        };
        const { productsInNeed } = rawMaterial;

        let productColumns: any = {};
        forEach(saleForecastList, (productItem) => {
          const proInNeed = find(
            productsInNeed,
            (pro) => pro.product.sku === productItem.sku
          );

          if (proInNeed && proInNeed?.product?.name) {
            productColumns[proInNeed?.product?.name] =
              proInNeed.quantityInNeed ?? "0";
          } else {
            productColumns[productItem?.name] = 0;
          }
        });
        productColumns = sortObjectByKey(productColumns);

        return { ...dataSource, ...productColumns };
      }
    );
    const fileName = `RAW_MATERIAL_FORECAST${TimeUtils.convertMiliToDateTime(
      new Date().getTime()
    )}`;
    exportToCSVMultipleSheet(
      [
        { data: saleForecastExportData, sheetName: "Sales forecast" },
        { data: rawMaterialExportData, sheetName: "Raw material forecast" },
        {
          data: rawMaterialDetailExportData,
          sheetName: "Raw material forecast detail",
        },
      ],
      fileName
    );
  };

  const getDisplay = (key: string) =>
    tabSelected?.id === key ? "flex" : "none";

  const renderTable = () => {
    return (
      <div className="mt-5">
        <div style={{ display: getDisplay("rawMaterialForecast") }}>
          <MaterialForecastTable />
        </div>
        <div style={{ display: getDisplay("saleForecast") }}>
          <SaleForecastTable />
        </div>
      </div>
    );
  };

  const renderMenuMoreAction = () => {
    return (
      <Dropdown
        iconName="more_vert"
        dataSource={map(DOWNLOAD_OPTIONS, (item) => ({
          ...item,
          label: t(item.label),
        }))}
        onClick={(item: any) => handleChooseActionDownLoad(item)}
      >
        <Button variant="trans" iconName="cloud">
          {t("download")}
        </Button>
      </Dropdown>
    );
  };

  return (
    <div>
      <div className="d-flex border-bottom justify-content-between">
        <div className="d-flex">
          <TabBar
            dataSource={FORECAST_TABS}
            onChange={setTabSelected as any}
            value={tabSelected}
            getLabel={(item: any) => t(item.label)}
            classNameItem={classNames(
              "bg-white border-right-0 border-left-0 border-top-0"
            )}
          />
        </div>

        {renderMenuMoreAction()}
      </div>

      {renderTable()}
      <ModalRawMaterialExportPdf
        open={openModalExportPdf}
        onClose={() => setOpenModalExportPdf(false)}
      />
    </div>
  );
};

export default RawMaterialForecastTable;
