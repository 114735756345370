import Drawer from "@components/shared/Drawer";
import AppContext from "@helpers/context";
import RoleAPI from "@network/api/role";
import WarehouseAPI from "@network/api/warehouse";
import {
  AwesomeTableComponent,
  InputTextSearch,
  Modal,
  Select,
  TimeUtils,
} from "d-react-components";
import { useFormik } from "formik";
import { debounce } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

function WarehouseDetailUserLoadDrawer({ open, onClose, onSave }: any) {
  const warehouseContext = useContext(AppContext);
  const { t } = useTranslation();

  const [warehouseList, setWarehouseList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const tableRef = useRef<any>();
  const textSearch = useRef<string>("");

  const loadUserForm = useFormik({
    initialValues: {
      users: [],
      warehouse: null,
      role: null,
    },
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      onSave(values?.users);
    },
  });

  const columns = [
    {
      title: t("name"),
      dataIndex: "fullname",
    },
    {
      title: t("nickName"),
      dataIndex: "name",
    },
    {
      title: t("companyId"),
      dataIndex: "companyId",
      width: 150,
    },
    {
      title: t("lastLogin"),
      dataIndex: "last_login",
      render: TimeUtils.toDateTime,
    },
  ];

  useEffect(() => {
    loadWarehouseList();
    loadRoleList();
  }, []);

  const source = (paging: any) => {
    const { warehouse, role } = loadUserForm?.values;
    if (!warehouse) return Promise.resolve([]);
    const body = {
      id: warehouse,
      role,
      search: textSearch.current,
      warehouse_id: warehouseContext?.warehouse?.id,
    };
    return WarehouseAPI.userListLoad(body, paging);
  };

  const transformer = (res: any) => res?.data?.data?.users ?? [];

  const onChangeText = debounce((text) => {
    textSearch.current = text;
    tableRef.current.refresh();
  }, 1000);

  const loadWarehouseList = (textSearch?: any) => {
    WarehouseAPI.list(
      { pageIndex: 1, pageSize: 20 },
      { search: textSearch }
    ).then((res) => {
      setWarehouseList(res?.data?.data?.warehouse ?? []);
    });
  };

  const loadRoleList = () => {
    RoleAPI.list().then((res) => {
      const roleList = res?.data?.data?.roles ?? [];
      setRoleList(roleList);
    });
  };

  const formValue = loadUserForm?.values;

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("loadUser")}
      onSave={() => loadUserForm.handleSubmit()}
      // disabledSaveButton={formValue?.users?.length === 0}
      size="auto"
      width="30vw"
    >
      <div>
        <Select
          label={t("warehouse")}
          onSearch={loadWarehouseList}
          showSearch
          dataSource={warehouseList}
          getLabel={(item) => item.name}
          value={formValue?.warehouse}
          onChange={(value) => {
            loadUserForm.setFieldValue("warehouse", value);
            tableRef.current.refresh();
          }}
        />
        <Select
          label={t("role")}
          className="mt-3"
          dataSource={roleList}
          getLabel={(item) => item.name}
          value={formValue?.role}
          onChange={(value) => {
            loadUserForm.setFieldValue("role", value);
            tableRef.current.refresh();
          }}
        />
        <label className="mt-3">{t("allUsers")}</label>
        <InputTextSearch
          onChange={(event: any) => onChangeText(event.target.value)}
        />
        <AwesomeTableComponent
          ref={(ref) => {
            tableRef.current = ref;
          }}
          columns={columns}
          source={source}
          transformer={transformer}
          className="mt-3"
          rowSelection={{
            onChange: (selectedRowKeys, selectedRows) => {
              loadUserForm.setFieldValue("users", selectedRows);
            },
          }}
          baseColumnProps={{ with: 100 }}
        />
      </div>
    </Drawer>
  );
}

export default WarehouseDetailUserLoadDrawer;
