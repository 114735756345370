import Drawer from "@components/shared/Drawer";
import AppContext from "@helpers/context";
import {
  getGPEventProductList,
  updateGPEventProduct,
} from "@network/api/gp-event";
import {
  AwesomeTableComponent,
  InputText,
  InputTextSearch,
  Modal,
  Progress,
} from "d-react-components";
import { debounce, find, map, uniqBy } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import GPEvenProductView from "../compoents/GPEvenProductView";

function GPEventDetailConfigUpdateModal({ open, onClose, onUpdated }: any) {
  const { eventDetail } = useContext(AppContext);

  const tableRef = useRef<any>();
  const textSearch = useRef();
  const [productList, setProductList] = useState<any>([]);
  const { t } = useTranslation();

  const columns = [
    {
      title: t("name"),
      dataIndex: "",
      render: (product: any) => <GPEvenProductView product={product} />,
    },
    {
      title: t("gp"),
      dataIndex: "discount",
      render: (discount: any, product: any) => {
        return (
          <InputText
            value={discount}
            onChange={(event) => onChangeDiscount(product, event.target.value)}
          />
        );
      },
      width: 100,
    },
  ];

  useEffect(() => {
    loadProductSource();
  }, []);

  const loadProductSource = () => {
    const body = {
      id: eventDetail?.id,
      action: "in",
      search: textSearch.current,
    };
    getGPEventProductList(body).then((res) => {
      const list = res?.data?.data?.product ?? [];
      setProductList(list);
    });
  };

  const onChangeText = debounce((text) => {
    textSearch.current = text;
    tableRef.current.refresh();
  }, 500);

  const onChangeDiscount = (product: any, discount: any) => {
    const discountVal = discount ? parseInt(discount) : 0;
    if (discountVal > 100) return;

    const result = map(productList, (item) => {
      if (item.id === product?.id) {
        return { ...product, discount: discountVal };
      }
      return item;
    });
    setProductList(result);
  };

  const onUpdateProduct = () => {
    const body = {
      id: eventDetail?.id,
      action: "add",
      product: map(productList, (item) => ({
        id: item.id,
        discount: item.discount,
      })),
    };
    Progress.show({ method: updateGPEventProduct, params: [body] }, () => {
      onUpdated();
      onClose();
    });
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("update")}
      onSave={onUpdateProduct}
      size="auto"
      width={"80%"}
    >
      <InputTextSearch
        onChange={(event: any) => onChangeText(event.target.value)}
      />
      <AwesomeTableComponent
        ref={(ref) => {
          tableRef.current = ref;
        }}
        columns={columns}
        dataSource={productList}
        className="mt-3"
        isPagination={false}
      />
    </Drawer>
  );
}

export default GPEventDetailConfigUpdateModal;
