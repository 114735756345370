import { Icon, Select } from "d-react-components";
import React, { useContext, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import CurrencyFormat from "react-currency-format";
import ProductName from "./ProductName";
import QuantityInputField from "./QuantityInputField";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import AppContext from "@helpers/context";
import EditCustomItemButton from "@components/order/components/EditCustomItemButton";

const createRow = (item: any, parentPro: any, showRentPrice: boolean) => {
  const imageurl = item?.product?.imageurls ?? null;
  const name = item?.product?.name ?? "";
  const sku = item?.product?.sku ?? "";
  const sale_price =
    (showRentPrice ? item?.product?.sale_price_rent : item?.sale_price) ?? null;
  const regular_price =
    (showRentPrice ? item?.product?.regular_price_rent : item?.regular_price) ??
    null;
  const quantity = item?.quantity ? item.quantity * parentPro.quantity : null;
  const price = Number(sale_price) * Number(quantity);

  const row = {
    ...item,
    imageurl,
    name,
    sku,
    sale_price,
    quantity,
    price,
    regular_price,
  };
  return row;
};

const ProductCartItem = ({
  item,
  subBody,
  renderSubName,
  onChangeQuantity,
  onDeleteProductHandle,
  isSubItem,
  showRentPrice = false,
}: any) => {
  const { t } = useTranslation();
  const { productList, setProductList } = useContext(AppContext);
  const [expanded, setExpanded] = useState(false);
  const { product } = item || {};
  const showButton = product?.groups?.length > 0;
  const { isGift = false, isOldPro = false, isCustomProduct = false } = item;
  const subRows = showButton
    ? product.groups?.map((subPro: any) =>
        createRow(subPro, item, showRentPrice)
      )
    : [];
  const rentMonthOptions = useMemo(() => {
    if (!showRentPrice) return [];
    return (
      item?.product?.option_of_month_rent?.map((item: any) => {
        return {
          id: item,
          value: item,
        };
      }) ?? []
    );
  }, [item, showRentPrice]);

  const onChangeMonth = (value: any) => {
    const newProductList =
      productList &&
      productList?.map((item: any) => {
        if (item.product.id === product?.id) {
          return {
            ...item,
            month: value,
          };
        }
        return item;
      });
    setProductList([...newProductList]);
  };

  useEffect(() => {
    if (!item.month && rentMonthOptions.length > 0) {
      onChangeMonth(rentMonthOptions[0].value);
    }
  }, [rentMonthOptions]);

  return (
    <StyledProductItem
      expanded={expanded}
      className={classNames({
        "flex items-center sub-product": isOldPro,
        "flex items-center": isSubItem,
      })}
    >
      {(isSubItem || isOldPro) && (
        <span className="border-t h-[1px] w-[45px] flex-nowrap grow-0 shrink-0"></span>
      )}
      <div
        className={classNames({
          "flex-1 sub-item": isSubItem || isOldPro,
          "border-t": !isSubItem && !isOldPro,
        })}
      >
        <div
          className={classNames("flex pt-2", {
            "bg-gray-100 p-2 mt-2": isSubItem,
            "bg-amber-100 p-2 mt-2": isGift || isCustomProduct,
            "bg-gray-100 p-2": isOldPro,
          })}
        >
          <div
            className={classNames(
              { "parent-product": !isSubItem && !isOldPro },
              "flex-1"
            )}
          >
            <ProductName
              item={item}
              isOldPro={item?.isOldPro ?? false}
              subBody={subBody}
              renderSubName={renderSubName}
              imgClass="w-10 h-10"
              showPrice
              showRentPrice={isGift ? false : showRentPrice}
              addOneAfterName={() => (
                <>
                  {showButton ? (
                    <span
                      className="bg-primary p-1 text-center cursor-pointer text-white text-tiny h-[24px] w-[52px] mx-2 grow-0 shrink-0"
                      onClick={() => setExpanded(!expanded)}
                    >
                      {expanded ? t("collapse") : t("expand")}
                    </span>
                  ) : null}
                  {isCustomProduct && <EditCustomItemButton product={item} />}
                </>
              )}
            />
          </div>
          {!isSubItem && !isGift && !isOldPro && (
            <div>
              <Icon
                name="delete"
                className="text-red-500 cursor-pointer"
                onClick={() => onDeleteProductHandle(item)}
              />
            </div>
          )}
          {isGift && (
            <div>
              <Icon name="redeem" className="text-red-500" />
            </div>
          )}
        </div>

        {subRows && expanded && (
          <div className="sub-product">
            {subRows?.map((item: any, index: number) => (
              <ProductCartItem
                item={item}
                key={index}
                isSubItem={true}
                showRentPrice={showRentPrice}
              />
            ))}
          </div>
        )}

        {showRentPrice && !isGift && (
          <div className="my-2 pl-[45px]">
            <Select
              dataSource={rentMonthOptions}
              onChange={(val: any) => onChangeMonth(val)}
              value={item?.month}
              placeholder={t("contractPeriod")}
              getLabel={(item: any) => `${item.value} ${t("months")}`}
            />
          </div>
        )}
        <div
          className={classNames("flex mb-2", {
            "bg-gray-100 px-2 pb-2 flex-1": isSubItem || isOldPro,
            "bg-amber-100 px-2 pb-2": isGift || isCustomProduct,
          })}
        >
          <div className="w-[150px] pl-[45px]">
            <QuantityInputField
              quantity={item.quantity}
              onChangeQuantity={(quantity: number) =>
                onChangeQuantity(item, quantity)
              }
              disabled={isSubItem || isGift || isOldPro}
            />
          </div>
          <div className="flex justify-content-end flex-1">
            <span
              className={classNames("text-xs ml-2 bg-primary p-2 text-white", {
                "line-through opacity-50": isOldPro,
              })}
            >
              {showRentPrice && !isGift ? (
                <CurrencyFormat
                  value={(item.price / (item.month || 1)).toFixed(2)}
                  displayType={"text"}
                  suffix={`/${t("month")}`}
                  thousandSeparator={true}
                />
              ) : (
                <CurrencyFormat
                  value={item.price || "0"}
                  displayType={"text"}
                  prefix={showRentPrice ? "" : "฿"}
                  thousandSeparator={true}
                />
              )}
            </span>
          </div>
        </div>
      </div>
    </StyledProductItem>
  );
};

const StyledProductItem: any = styled.div`
  .parent-product {
    ${(props: any) =>
      props.expanded &&
      `
    position: relative;
    &:before {
      display: block;
      content: "";
      height: 95%;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 1px;
      background-color: #e5e7eb;
    }
    `}
  }
  .sub-product {
    & > div {
      position: relative;
      &:before {
        display: block;
        content: "";
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        background-color: #e5e7eb;
      }
      &:last-child {
        &:before {
          height: 50%;
        }
      }
      &:not(:first-child) {
        .sub-item {
          border-top: 1px solid #e5e7eb;
        }
      }
    }
  }
`;

export default ProductCartItem;
