import { SelectInfinity } from "@components/shared/SelectInfinity";
import { IFieldSelect } from "@interfaces/select";
import { IStore } from "@interfaces/store";

import { listUserStore } from "@network/api/store";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export interface IStoreSelect<T> extends IFieldSelect<T> {
  [key: string]: any;
  channelId?: string | string[];
}

const StoreSelect = ({
  value = [],
  multiple,
  className,
  onChange,
  channelId,
  disabled,
  sourceAPI = listUserStore,
  ...selectProps
}: IStoreSelect<IStore>) => {
  // const [storeList, setStoreList] = useState<any[]>(

  const { t } = useTranslation();

  const onChangeValue = (value: any) => {
    if (multiple) {
      onChange(value);
      return;
    }
    onChange(value?.[0]);
  };

  const storeValue = useMemo(() => {
    if (multiple) {
      return value;
    }
    return [value];
  }, [value]);

  return (
    <SelectInfinity
      fetchFn={sourceAPI}
      dataPath="stores"
      value={storeValue}
      label={t("warehouse")}
      customQuery={(search) => ({
        search,
        channelid: channelId,
      })}
      getLabel={(item) => item?.name}
      disabled={disabled}
      onChange={onChangeValue}
      mode={multiple ? "multiple" : undefined}
      placeholder={t("pleaseSelect")}
      className={className}
      {...selectProps}
    />
  );
};

export default StoreSelect;
