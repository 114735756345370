
export const SERIAL_NUMBER_STATUS = {
    PENDING: "pending",
    ACTIVATED: "activated",
    CANCELLED: "cancelled",
};


export const SERIAL_NUMBER_STATUSES = [
    { id: SERIAL_NUMBER_STATUS.ACTIVATED, label: "activated", color: "#33B950" },
    { id: SERIAL_NUMBER_STATUS.PENDING, label: "pending", color: "#F2C94C" },
    { id: SERIAL_NUMBER_STATUS.CANCELLED, label: "cancelled", color: "#8D8D8D" },
];
