import UserAvatarName from "@components/shared/UserAvatarName";
import AppContext from "@helpers/context";
import { IDelivery } from "@interfaces/delivery";
import { IRowsKey, TimeUtils, ViewRowInterchange } from "d-react-components";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

const DeliveryTransferStockInfo = () => {
  const { deliveryDetail } = useContext(AppContext);
  const { ref, code } = deliveryDetail ?? {};
  const { t } = useTranslation();

  const DETAILS_KEY = useMemo<IRowsKey<IDelivery>[]>(
    () => [
      {
        id: "ref",
        label: t("id"),
      },
      {
        id: "transfer",
        label: t("origin"),
        renderContent: ({ data, item }) =>
          `${data?.transfer_origin?.name} (${data?.transfer_origin?.code})`,
      },
      {
        id: "transfer",
        label: t("destination"),
        renderContent: ({ data, item }) =>
          `${data?.transfer_destination?.name} (${data?.transfer_destination?.code})`,
      },
      {
        id: "stock_inventory_control",
        label: t("event"),
        renderContent: ({ data, item }) => data?.title ?? "N/A",
      },
      {
        id: "driver",
        label: t("driver"),
        renderContent: ({ data, item }) => (
          <div className="d-flex justify-content-end">
            {data ? <UserAvatarName user={data} /> : item?.driver_name}
          </div>
        ),
      },
      {
        id: "vehicle",
        label: t("vehicle"),
        renderContent: ({ data, item }) =>
          data?.registration ?? item?.vehicle_no,
      },
      {
        id: "transfer",
        label: t("transferType"),
        renderContent: ({ data, item }) => data?.transfer_type ?? "N/A",
      },
      {
        id: "transfer",
        label: t("estDateTransfer"),
        renderContent: ({ data, item }) =>
          TimeUtils.toDate(data?.expected_transfer),
      },
      {
        id: "transfer",
        label: t("estDateArrival"),
        renderContent: ({ data, item }) =>
          TimeUtils.toDate(data?.expected_arrival),
      },
      {
        id: "created",
        label: t("createdAt"),
        renderContent: ({ data, item }) => TimeUtils.toDate(data),
      },
      {
        id: "updated",
        label: t("updatedAt"),
        renderContent: ({ data, item }) => TimeUtils.toDate(data),
      },
    ],
    [t]
  );

  return (
    <div className="card-container p-4 flex-column flex-center pt-5">
      <img src={"/images/icons/event.svg"} className="image-reg-large" />
      <h4 className="mt-3">{ref}</h4>
      <small>{code}</small>

      <ViewRowInterchange
        className="w-100"
        dataSource={deliveryDetail}
        keyList={DETAILS_KEY as any}
        variant="border"
        classNameLabel="font-weight-bold "
        classNameContent="text-end "
      />
    </div>
  );
};

export default DeliveryTransferStockInfo;
