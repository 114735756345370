import {
  AwesomeTableComponent,
  Button,
  InputTextSearch,
  Notifications,
  Progress,
  TimeUtils,
} from "d-react-components";
import { debounce, find, map, uniqBy } from "lodash";
import React, { useContext, useRef, useState } from "react";
import AppContext from "@helpers/context";
import UserAPI from "@network/api/user";
import { useTranslation } from "react-i18next";
import NewRequestUpdateWarehouse from "@components/my-assets/requests/NewRequestUpdateWarehouse";
import moment from "moment";
import { Link, generatePath } from "react-router-dom";
import { Path } from "@components/layout/Path";
import { IStore } from "@interfaces/store";

const UserDetailWarehouse = ({ updatable = true }: { updatable?: boolean }) => {
  const { t } = useTranslation();
  const { user } = useContext(AppContext);
  const [updateItemType, setUpdateItemType] = useState("");
  const tableRef = useRef<any>();
  const [openAddRemove, setOpenAddRemove] = useState(false);
  const [listCheckWarehouse, setListCheckWarehouse] = useState<any[]>([]);
  const textSearchRef = useRef();

  const columns = [
    {
      title: t("NO"),
      dataIndex: "id",
      render: (id: any, item: any, index: any) => index + 1,
    },
    {
      title: t("name"),
      dataIndex: "name",
      render: (name: any, warehouse: any) => (
        <Link
          to={generatePath(Path.WAREHOUSE_DETAIL, {
            warehouseId: warehouse.id,
          })}
        >
          {name}
        </Link>
      ),
    },
    {
      title: t("systemCode"),
      dataIndex: "warehouse_system_code",
      width: 120,
    },
    {
      title: t("navArCode"),
      dataIndex: "code",
      width: 120,
    },
    {
      title: t("startingDate"),
      dataIndex: "start",
      width: 120,
      render: (start: any, item: any) => {
        const currentItem = find(listCheckWarehouse, {
          warehouse_id: item.id,
        });
        return currentItem ? TimeUtils.toDate(currentItem?.start) : "";
      },
    },
  ];

  const source = (paging: any) => {
    const body = { id: user?.id, action: "in", search: textSearchRef.current };
    return UserAPI.warehouseList(body, paging).then(
      (resp) => {
        const listWareHouse = resp?.data?.data?.warehouse ?? [];
        UserAPI.checkAssetWarehouse({
          warehouse: listWareHouse?.map((w: IStore) => w.id),
          admin_id: user.id,
        }).then((resp) => {
          setListCheckWarehouse(
            uniqBy(
              [...listCheckWarehouse, ...(resp?.data?.data?.asset ?? [])],
              "id"
            )
          );
        });
        return resp;
      },
      (err) => {
        return err;
      }
    );
  };

  const transformer = (res: any) => {
    return res?.data?.data?.warehouse ?? [];
  };

  // const onAddWarehouse = (warehouses: any) => {
  //   const body = {
  //     id: user?.id,
  //     action: "add",
  //     warehouse: map(warehouses, (item) => item.id),
  //   };
  //   Progress.show({ method: UserAPI.warehouseUpdate, params: [body] }, () => {
  //     Notifications.showSuccess(t("updateUserWarehouseSuccess"));
  //     tableRef.current.refresh();
  //     setOpenAdd(false);
  //   });
  // };

  // const onRemoveWarehouse = (warehouses: any) => {
  //   const body = {
  //     id: user?.id,
  //     action: "remove",
  //     warehouse: map(warehouses, (item) => item.id),
  //   };
  //   Progress.show({ method: UserAPI.warehouseUpdate, params: [body] }, () => {
  //     Notifications.showSuccess(t("updateUserWarehouseSuccess"));
  //     tableRef.current.refresh();
  //     setOpenRemove(false);
  //   });
  // };

  const onChangeTextSearch = debounce((event) => {
    textSearchRef.current = event?.target?.value;
    tableRef.current.refresh();
  }, 400);

  return (
    <div className="border mb-3 p-4">
      <div className="justify-content-between d-flex align-items-center mb-3">
        <InputTextSearch
          placeholder={t("search")}
          onChange={onChangeTextSearch}
        />
        {updatable && (
          <div className="d-flex">
            <Button
              className="ml-3"
              onClick={() => {
                setUpdateItemType("remove");
                setOpenAddRemove(true);
              }}
            >
              {t("remove")}
            </Button>
            <Button
              className="ml-3"
              onClick={() => {
                setUpdateItemType("add");
                setOpenAddRemove(true);
              }}
            >
              {t("add")}
            </Button>
          </div>
        )}
      </div>
      <AwesomeTableComponent
        ref={(ref) => {
          tableRef.current = ref;
        }}
        columns={columns}
        source={source}
        transformer={transformer}
        className="mt-3"
      />
      {/* {openAdd && (
        <UserDetailWHAddModal
          open={openAdd}
          onClose={() => setOpenAdd(false)}
          onUpdated={() => {
            tableRef.current.refresh();
          }}
        />
      )}
      {openRemove && (
        <UserDetailWHRemoveModal
          open={openRemove}
          onClose={() => setOpenRemove(false)}
          onUpdated={() => {
            tableRef.current.refresh();
          }}
        />
      )} */}

      {openAddRemove && (
        <NewRequestUpdateWarehouse
          itemToAdd={[]}
          itemToRemove={[]}
          open={openAddRemove}
          onClose={() => setOpenAddRemove(false)}
          action={updateItemType as any}
          type="update"
          item={user}
          onSave={(items: any) => {
            const body = {
              id: user?.id,
              action: updateItemType,
              warehouse: map(items?.stores, (item: any) => ({
                id: item.id,
                date: moment(item.start).format("YYYY-MM-DD"),
              })),
              channel: map(items?.channels, (item: any) => item.id),
            };
            Progress.show(
              { method: UserAPI.warehouseUpdate, params: [body] },
              () => {
                Notifications.showSuccess(t("updateUserWarehouseSuccess"));
                setOpenAddRemove(false);
                tableRef.current.refresh();
              }
            );
          }}
        />
      )}
    </div>
  );
};

export default UserDetailWarehouse;
