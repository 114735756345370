import ProductName from "@components/product/components/ProductName";
import ProductSearchButton from "@components/product/components/ProductSearchButton";
import AppContext from "@helpers/context";
import { exportToCSV } from "@helpers/file";
import { searchProductInPromotion } from "@network/api/product";
import {
  AwesomeTableComponent,
  Button,
  DialogManager,
  Icon,
  InputText,
  Notifications,
} from "d-react-components";
import { filter, map, sortBy, uniqBy } from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { useTranslation } from "react-i18next";
import PromotionImport from "./PromotionImport";
import CurrencyInput from "react-currency-input-field";

export const exportItems = (promotionItems: any) => {
  const exportResult = promotionItems.map((proItem: any) => ({
    "Item Name": proItem?.product?.name ?? "N/A",
    SKU: proItem?.product?.sku ?? "N/A",
    "Reg.Price": proItem?.regular_price ?? 0,
    "Default sale price": proItem?.default_sale_price ?? 0,
    "New sale price": proItem?.new_sale_price ?? 0,
  }));
  const date = moment().format("DD/MM/YYYY");
  const time = moment().format("HH:mm");
  const fileName = `PromotionItemExport_${date}_${time}_${moment().valueOf()}`;
  return exportToCSV(exportResult, fileName);
};

const PromotionCreateListOfItems = ({
  isEdit = false,
}: {
  isEdit?: boolean;
}) => {
  const { t } = useTranslation();
  const { setPromotionProducts, promotionProducts, isView, promotionForm } =
    useContext(AppContext);
  const { values, setFieldValue } = promotionForm;
  const [onExport, setOnExport] = useState(false);
  const [openModalImport, setOpenModalImport] = useState(false);
  const [promotionItems, setPromotionItems] = useState<any[]>([]);
  const promotionItemTable = useRef<any>(null);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setPromotionItems(promotionProducts);
    promotionItemTable.current.refreshData();
  }, [promotionProducts]);

  const onChangeSalePrice = (item: any, newSalePrice: any) => {
    const newPromotionItems = promotionItems.map((itemParam: any) => {
      if (item.productid === itemParam.productid) {
        return {
          ...itemParam,
          new_sale_price: newSalePrice,
        };
      }

      return itemParam;
    });
    setPromotionProducts(newPromotionItems);
  };

  const onDeleteItem = (promotionItem: any) => {
    const results = filter(
      promotionItems,
      (item: any) => promotionItem?.product.id !== item?.product.id
    );
    setPromotionProducts(results);
  };

  const onRemoveAllItems = () => {
    setPromotionProducts([]);
  };

  const onClickRemoveAll = () => {
    DialogManager.showConfirm(t("confirm"), t("sureToDeleteItem"), () => {
      onRemoveAllItems();
    });
  };

  const columns: any[] = [
    {
      title: t("itemName"),
      dataIndex: "product",
      ellipsis: true,
      className: "productInfoName",
      render: (product: any, item: any) => {
        return item ? <ProductName item={item} /> : "";
      },
    },
    {
      title: t("regularPrice"),
      dataIndex: "regular_price",
      width: "150px",
      align: "center",
      className: "priceInfoTable",
      render: (price: string) => (
        <CurrencyFormat
          value={price}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"฿"}
        />
      ),
    },
    {
      title: t("salePrice"),
      dataIndex: "default_sale_price",
      width: "150px",
      align: "center",
      className: "priceInfoTable",
      render: (price: string) => (
        <CurrencyFormat
          value={price}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"฿"}
        />
      ),
    },
    {
      title: t("newSalePrice"),
      dataIndex: "new_sale_price",
      width: "150px",
      className: "priceInfoTable",
      render: (price: string, item: any) => {
        return (
          <div className="d-input-text__input-container d-input-text__input-container-outline">
            <CurrencyInput
              value={price}
              onValueChange={(value) => {
                const result = value ? value.replace(/\D/g, "") : 0;
                if (result > item.regular_price) {
                  Notifications.showError(t("salePriceCannotGreater"));
                  return;
                }
                onChangeSalePrice(item, result);
              }}
              disabled={isView}
              className="text-x-small d-input-text__input w-full"
            />
          </div>
        );
      },
    },
    {
      dataIndex: "",
      width: "60px",
      align: "center",
      render: (promotionItem: any) => {
        return (
          <span
            className="cursor-pointer"
            onClick={() => {
              if (!isView) {
                onDeleteItem(promotionItem);
              }
            }}
          >
            <Icon name="delete" className="text-red-500" />
          </span>
        );
      },
    },
  ];

  const transformer = (respone: any) => {
    let result = respone;
    if (searchText) {
      result = respone.filter((item: any) => {
        const name = item.product.name.toLowerCase();
        const { sku } = item.product;
        const regular_price = item.regular_price.toString();
        const searchTextResult = searchText?.toLowerCase() ?? "";
        if (
          name.indexOf(searchTextResult) !== -1 ||
          sku.indexOf(searchTextResult) !== -1 ||
          regular_price.indexOf(searchTextResult) !== -1
        ) {
          return true;
        }
        return false;
      });
    }
    return sortBy(result, (item) => item?.product?.name);
  };

  const sourceSearchProduct = (code: any, filterBody: any, paging: any) => {
    const channelIds = map(values?.channel ?? [], (channel) => channel.id);
    return searchProductInPromotion(
      channelIds,
      "name",
      code,
      filterBody,
      paging?.pageIndex
    ).then(
      (response) => response,
      (err) => {
        Notifications.showError(err);
        return err;
      }
    );
  };

  const onClickExport = async () => {
    // setOnExport(true);
    exportItems(promotionItems);
    // setOnExport(false);
  };

  const onClickBulkImport = () => {
    const { channel, start, end } = promotionForm?.values;
    if (!channel || channel.length === 0) {
      Notifications.showError(t("pleaseSelectYourChannel"));
      return;
    }
    if (!start || !end || start > end) {
      Notifications.showError(t("notValidTime"));
      return;
    }
    setOpenModalImport(true);
  };

  const onSaveProductImport = (skuList: any[]) => {
    const resultItems = uniqBy(
      [...skuList, ...promotionItems],
      (item: any) => item.productid
    );
    setPromotionProducts(resultItems);
  };

  return (
    <div className={isEdit ? "" : "bg-white p-3"}>
      <div className="flex items-center">
        <h3 className="text-lg font-semibold">{t("items")}</h3>
        <div className="flex flex-1 items-end justify-end">
          <Button
            variant="outline"
            className="ml-2"
            onClick={() => onClickRemoveAll()}
          >
            {t("reset")}
          </Button>
          <Button
            variant="outline"
            className="ml-2"
            onClick={() => onClickExport()}
          >
            {t("export")}
          </Button>
          <Button
            variant="outline"
            className="ml-2"
            onClick={() => onClickBulkImport()}
          >
            {t("bulkImport")}
          </Button>
          <ProductSearchButton
            className="ml-2"
            buttonText={t("addProduct")}
            source={sourceSearchProduct}
            dataKey="products"
            onChange={(products) => {
              const productsResult = products.map((product: any) => {
                if (promotionItems.includes(product)) {
                  return product;
                }
                return {
                  ...product,
                  default_sale_price: product.sale_price,
                  new_sale_price: product.sale_price,
                };
              });
              setPromotionProducts(productsResult);
            }}
            defaultValue={promotionItems}
          />
        </div>
      </div>
      <div className="mt-3">
        <AwesomeTableComponent
          ref={(ref) => {
            if (promotionItemTable) {
              promotionItemTable.current = ref;
            }
          }}
          dataSource={promotionItems}
          transformer={transformer}
          columns={columns}
          isScroll={false}
          rowKey={(item) => item.productid}
        />
      </div>
      {openModalImport && (
        <PromotionImport
          openModalImport={openModalImport}
          onClose={() => setOpenModalImport(false)}
          onSaveProduct={(skuList: any) => onSaveProductImport(skuList)}
        />
      )}
    </div>
  );
};

export default PromotionCreateListOfItems;
