import InputVerifier from "@common/input/InputVerifier";
import { OrderType } from "@constants/common";
import { ORDER_STATUS } from "@constants/order";
import AppContext from "@helpers/context";
import { isGrantURLPermission } from "@helpers/permissions";
import { getFullName } from "@helpers/string";
import { CustomerType } from "@interfaces/customer";
import { deleteCustomerOrder } from "@network/api/customer";
import { API_DELETE_CUSTOMER_ORDER } from "@network/URL";
import {
  TimeUtils,
  StringUtils,
  Avatar,
  Icon,
  Loading,
  DialogManager,
  Notifications,
} from "d-react-components";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

const HiddenContent = ({ children, hidden }: any) => {
  return hidden ? null : children;
};

const CustomerInfo = ({ customer }: any) => {
  const [expanded, setExpanded] = useState(false);
  const {
    order,
    isCreateOrder,
    onReloadOrderData,
    values,
    setFieldValue,
    type: orderType,
  } = useContext(AppContext);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const { t } = useTranslation();
  if (!customer || !customer.id) {
    return <></>;
  }
  const { firstname } = customer ?? {};
  const firstNameCharacter =
    customer.customer_type === CustomerType.BUSINESS
      ? customer?.company?.[0]?.toUpperCase()
      : firstname?.[0]
      ? firstname?.[0].toUpperCase()
      : "";
  const isShowRemoveCustomer = () => {
    if (!customer || isCreateOrder) {
      return false;
    }
    const isGrantPermission = isGrantURLPermission(API_DELETE_CUSTOMER_ORDER);
    if (!isGrantPermission) {
      return false;
    }

    if (
      !order ||
      (order.status !== ORDER_STATUS.pending.value &&
        order.status !== ORDER_STATUS.pendingPaymentConfirmation.value &&
        order.status !== ORDER_STATUS.partiallyPaid.value)
    ) {
      return false;
    }
    return true;
  };

  const onClickRemove = () => {
    DialogManager.showConfirm(t("confirm"), t("wantToRemoveCustomer"), () =>
      onRemoveCustomer()
    );
  };

  const onRemoveCustomer = () => {
    setLoadingRemove(true);
    const body = {
      id: order.id,
    };
    deleteCustomerOrder(body)
      .then((res) => {
        setLoadingRemove(false);
        onReloadOrderData && onReloadOrderData();
        Notifications.showSuccess(t("notification:removeCustomerOrderSuccess"));
      })
      .catch((err) => {
        setLoadingRemove(false);
      });
  };

  return (
    <>
      <div className="flex bg-amber-100 p-2 my-2">
        <div className="flex-1 flex">
          {customer.avatar ? (
            <Avatar className="w-12 h-12 object-cover" src={customer.avatar} />
          ) : (
            <div className="w-12 h-12 flex bg-gray-400 justify-content-center items-center">
              <span className="text-white" style={{ fontSize: "1.5rem" }}>
                {firstNameCharacter}
              </span>
            </div>
          )}
          <div className="customer-info ml-2 pt-2 text-xs">
            <HiddenContent hidden={!expanded}>
              <span className="block">{`${t("id").toUpperCase()}: ${
                customer.customerid
              }`}</span>
            </HiddenContent>
            <span className="block">
              {customer.customer_type === CustomerType.BUSINESS
                ? customer.company
                : getFullName(customer) +
                  (customer?.nickname ? ` (${customer.nickname})` : "")}
            </span>
            {/* <HiddenContent hidden={!expanded}>
              <span className="block">{customer.company}</span>
            </HiddenContent> */}
            <div className="flex items-center mt-1">
              <span className="mr-2">{customer.phone}</span>
              {orderType === OrderType.RENTAL &&
                customer.customer_type === CustomerType.INDIVIDUAL && (
                  <InputVerifier
                    type="phone"
                    isVerified={customer?.is_verify_phone}
                    value={customer?.phone}
                    payload={{ channel_id: values?.channel }}
                    userId={customer?.id}
                    onVerified={(otp) => {
                      if (setFieldValue) {
                        const customerClone = {
                          ...customer,
                          is_verify_phone: true,
                        };
                        setFieldValue("selectedCustomer", [customerClone]);
                      }
                    }}
                  />
                )}
            </div>
            <div className="flex items-center mt-1">
              <span className="mr-2">{customer.email}</span>
              {orderType === OrderType.RENTAL && (
                <InputVerifier
                  type="email"
                  isVerified={customer?.is_verify_email}
                  value={customer?.email}
                  payload={{ channel_id: values?.channel }}
                  userId={customer?.id}
                  onVerified={(otp) => {
                    if (setFieldValue) {
                      const customerClone = {
                        ...customer,
                        is_verify_email: true,
                      };
                      setFieldValue("selectedCustomer", [customerClone]);
                    }
                  }}
                />
              )}
            </div>
            <HiddenContent hidden={!expanded}>
              <>
                <div className="block" hidden={!customer.gender}>{`${t(
                  "gender"
                )}: ${t(customer.gender)}`}</div>
                <div className="block" hidden={!customer.birthday}>{`${t(
                  "birthday"
                )}: ${TimeUtils.convertMiliToDate(customer.birthday)}`}</div>
                <div
                  className="block"
                  hidden={!customer.number_of_orders}
                >{`${t("numberOfOrder")}: ${
                  customer.number_of_orders
                }`}</div>{" "}
                <div className="block" hidden={!customer.total_spending}>{`${t(
                  "totalSpending"
                )}: ${StringUtils.moneyThaiFormat(
                  customer.total_spending
                )}`}</div>
              </>
            </HiddenContent>
          </div>
        </div>
        <Icon
          size="large"
          className="cursor-pointer pt-3"
          onClick={() => setExpanded(!expanded)}
          name={expanded ? "expand_less" : "expand_more"}
        ></Icon>
      </div>
      {isShowRemoveCustomer() && (
        <div className="text-red-500" onClick={onClickRemove}>
          {loadingRemove ? <Loading /> : t("remove")}
        </div>
      )}
    </>
  );
};

export default CustomerInfo;
