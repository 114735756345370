import Icon from "@common/Icon";
import { CHANNEL_STATUSES } from "@constants/channel";
import AppContext from "@helpers/context";
import { IChannel } from "@interfaces/channel";
import {
  IRowsKey,
  TimeUtils,
  ViewLabelStatus,
  ViewRowInterchange,
} from "d-react-components";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

const ChannelDetailInfo = () => {
  const { channel } = useContext(AppContext);
  const { t } = useTranslation();

  const { name } = channel ?? {};

  const DETAILS_KEY = useMemo<IRowsKey<IChannel>[]>(
    () => [
      {
        id: "code",
        label: t("systemCode"),
      },
      {
        id: "company",
        label: t("company"),
      },
      {
        id: "phone",
        label: t("phone"),
      },
      {
        id: "updated",
        label: t("lastUpdated"),
        renderContent: ({ data, item }) => TimeUtils.toDateTime(data),
      },
    ],
    [t]
  );

  return (
    <div>
      <div className="border flex-center flex-column pt-5 w-100 bg-white p-4">
        <Icon
          className="w-[100px] cursor-pointer"
          src="/images/icons/channel.svg"
        />

        <h4 className="mt-3 text-center">{name}</h4>
        <ViewLabelStatus
          status={channel?.status}
          listStatus={CHANNEL_STATUSES}
          getLabel={(item) => t(item.label)}
        />
        <div className="divider mt-3" />
        <ViewRowInterchange
          className="w-100 mt-3"
          dataSource={channel}
          keyList={DETAILS_KEY as any}
          variant="border"
          classNameLabel="font-weight-bold "
          classNameContent="text-end "
        />
      </div>
    </div>
  );
};

export default ChannelDetailInfo;
