import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import RichTextEditor from "react-rte";

const ChannelFormTerm = ({
  form,
  fieldClassName = "",
  keyData = "terms",
}: any) => {
  const { t } = useTranslation();
  const formData = form?.values;

  const [termHtml, setTermHtml] = useState(
    RichTextEditor.createValueFromString(formData[keyData], "html")
  );
  const isOnchange = useRef(false);

  useEffect(() => {
    if (!isOnchange.current) {
      setTermHtml(
        RichTextEditor.createValueFromString(formData[keyData], "html")
      );
    }
  }, [formData[keyData]]);

  return (
    <div>
      <label className="mt-3">{`${t("terms")}`}</label>
      <RichTextEditor
        value={termHtml}
        onChange={(value: any) => {
          isOnchange.current = true;
          setTermHtml(value);
          form.setFieldValue(keyData, value.toString("html"));
        }}
        className="rich-text__editor"
      />
    </div>
  );
};

export default ChannelFormTerm;
