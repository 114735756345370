import axios from "axios";
import Drawer from "@components/shared/Drawer";
import AppContext from "@helpers/context";
import { getFullName } from "@helpers/string";
import { Icon, InputText, Notifications, Progress } from "d-react-components";
import * as Yup from "yup";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ShippingItemInfo from "./ShippingItemInfo";
import { useFormik } from "formik";
import { editCustomerContact } from "@network/api/customer";
import { makeCall } from "@network/api/callcenter";
import { Spin } from "antd";

const ContactCaller = ({ phone }: { phone: string | number }) => {
  const { order, contact } = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  // const removeZeroFromPhone = (phone: string) => {
  //   return phone.startsWith("0") ? phone.substring(1) : phone;
  // };

  const handleCall = () => {
    setLoading(true);
    makeCall({
      id: contact.id,
      phone,
    }).finally(() => {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
  };

  return loading ? (
    <Spin className="ml-2" />
  ) : (
    <Icon
      name="phone"
      className="cursor-pointer ml-2 text-primary"
      onClick={() => handleCall()}
    />
  );
};

const OrderContact = () => {
  const { contact, order, isCreateOrder, onReloadOrderData, isPublic } =
    useContext(AppContext);
  const [openModal, setOpenModal] = useState(false);
  const { callCenterInfo } = useContext(AppContext);
  const { t } = useTranslation();

  const onClickSave = (orderContact: any) => {
    if (isCreateOrder) {
      setOpenModal(false);
      // onSaveOrderContact && onSaveOrderContact(orderContact);
      return Promise.resolve();
    }
    const body = {
      orderid: order.id,
      ...orderContact,
    };

    Progress.show(
      { method: editCustomerContact, params: [body] },
      () => {
        setOpenModal(false);
        onReloadOrderData && onReloadOrderData();
        Notifications.showSuccess(t("notification:updateOrderContactSuccess"));
      },
      (err: any) => {
        Notifications.showError(err?.response?.data?.message || err);
      }
    );
  };

  return (
    <>
      <ShippingItemInfo
        title={t("orderContact")}
        onClickEdit={
          isPublic
            ? null
            : () => {
                setOpenModal(true);
              }
        }
      >
        {(contact?.firstname || contact?.lastname) && (
          <div className="subTitle1">{getFullName(contact)}</div>
        )}
        <div className="subTitle1">{contact?.email}</div>
        <div className="subTitle1 flex items-center">
          {contact?.phone}{" "}
          {callCenterInfo?.has_call_center && contact?.phone && (
            <ContactCaller phone={contact?.phone} />
          )}
        </div>
        {contact?.alternative_phone && (
          <div className="subTitle1">
            {contact?.alternative_phone}
            {callCenterInfo?.has_call_center && contact?.alternative_phone && (
              <ContactCaller phone={contact?.alternative_phone} />
            )}
          </div>
        )}
      </ShippingItemInfo>
      {openModal && (
        <OrderContactDrawer
          openModal={openModal}
          setOpenModal={setOpenModal}
          onClickSave={onClickSave}
        />
      )}
    </>
  );
};

const OrderContactDrawer = ({ openModal, setOpenModal, onClickSave }: any) => {
  const { contact } = useContext(AppContext);

  const { t } = useTranslation();

  const ContactSchema = Yup.object().shape({
    firstname: Yup.string().required(t("fieldRequired")),
    lastname: Yup.string().required(t("fieldRequired")),
    email: Yup.string().email().nullable(),
    phone: Yup.string().required(t("fieldRequired")).nullable(),
  });
  const contactForm = useFormik<any>({
    initialValues: { ...contact },
    validationSchema: ContactSchema,
    validateOnChange: false,
    onSubmit: () => {
      onClickSave(values);
    },
  });
  const { handleSubmit, values, errors, setFieldValue } = contactForm;

  const onSubmit = () => {
    handleSubmit();
  };

  return (
    <Drawer
      open={openModal}
      onClose={() => setOpenModal(false)}
      onSave={onSubmit}
      title={t("editOrderContact")}
    >
      <div className="grid grid-cols-1 gap-4">
        <div className="col-span-1">
          <InputText
            style={{ width: "100%" }}
            label={t("firstname")}
            value={values.firstname}
            error={errors.firstname as string}
            onChange={(event) => setFieldValue("firstname", event.target.value)}
          />
        </div>

        <div className="col-span-1">
          <InputText
            style={{ width: "100%" }}
            label={t("lastname")}
            value={values.lastname}
            error={errors.lastname as string}
            onChange={(event) => setFieldValue("lastname", event.target.value)}
          />
        </div>
        <div className="col-span-1">
          <InputText
            style={{ width: "100%" }}
            label={t("email")}
            value={values.email}
            error={errors.email as string}
            onChange={(event) => setFieldValue("email", event.target.value)}
          />
        </div>
        <div className="col-span-1">
          <InputText
            style={{ width: "100%" }}
            label={t("phone")}
            value={values.phone}
            error={errors.phone as string}
            onChange={(event) => setFieldValue("phone", event.target.value)}
          />
        </div>
        <div className="col-span-1">
          <InputText
            style={{ width: "100%" }}
            label={t("alternativePhone")}
            value={values.alternative_phone}
            error={errors.alternative_phone as string}
            onChange={(event) =>
              setFieldValue("alternative_phone", event.target.value)
            }
          />
        </div>
      </div>
    </Drawer>
  );
};

export default OrderContact;
