import PrintProvider, { NoPrint, Print } from "react-easy-print";
import {
  ORDER_CUSTOM_SIZE_STATUS,
  ORDER_SUSPICIOUS_STATUS,
  PRINT_MODE,
} from "@constants/order";
import AppContext from "@helpers/context";
import { preloadImage } from "@helpers/image";
import { Channel } from "@interfaces/common";
import { getProvinceList } from "@network/api/address";
import { getDeliveryTypeList, logPrint } from "@network/api/delivery";
import {
  getOrderDetail,
  getOrderMetadata,
  getOrderPaymentMethods,
  getPaymentOptions,
  getSourceOrder,
  publicActivity,
  publicDetail,
} from "@network/api/order";
import { getWarehouseList } from "@network/api/store";
import { find, forEach, isEmpty, isUndefined, reduce, some } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import DeliveryTracking from "../components/delivery/DeliveryTracking";
import OrderActivities from "../components/OrderActivities";
import OrderCreateOrigin from "../components/OrderCreateOrigin";
import OrderDetailMTConfirm from "../components/OrderDetailMTConfirm";
import OrderSignature from "../components/OrderSignature";
import PaymentConfirmation from "../components/payment/PaymentInformation";
import OrderPickup from "../components/pickup/OrderPickup";
import OrderRefundView from "../components/refund/OrderRefundView";
import ShippingInfo from "../components/shipping/ShippingInfo";
import OrderDetailAdsTracking from "./content/OrderDetailAdsTracking";
import OrderDetailMTType from "./content/OrderDetailMTType";
import OrderDetailReceiptRef from "./content/OrderDetailReceiptRef";
import OrderDetailTable from "./content/OrderDetailTable";
import OrderDetailWarehouse from "./content/OrderDetailWarehouse";
import OrderNav from "./content/OrderNav";
import OrderNote from "./content/OrderNote";
// import OrderPackage from "./content/OrderPackage";
import SourceOfOrder from "./content/SourceOfOrder";
import OrderHeader from "./header/OrderHeader";
import PrintRefundLayout from "./print/PrintRefundLayout";
import PrintOrderReceipt from "./print/PrintOrderReceipt";
import PrintOrderPickUp from "./print/PrintOrderPickUp";
import PrintCustomSizeOrder from "./print/PrintCustomSizeOrder";
import { FILTER_COMMENT_TYPES, OrderType } from "@constants/common";
import { getListComment } from "@network/api/common";
import {
  DialogManager,
  Progress,
  RadioGroup,
  StringUtils,
  UrlUtils,
} from "d-react-components";
import { useTranslation } from "react-i18next";
import { getFullAddressFromShipping, getFullName } from "@helpers/string";
import { Spin } from "antd";
import { useUpdateEffect } from "react-use";
import CallHistory from "../components/CallHistory";
import SalesPerson from "../components/shipping/SalesPerson";
import { PAYMENT_COD } from "@constants/payment";

const OrderDetail = ({
  orderId: orderPopupId,
  isPublic = false,
}: {
  orderId?: string;
  isPublic?: boolean;
}) => {
  const { id } = useParams();
  const orderId = isUndefined(orderPopupId) ? id : orderPopupId;
  const editable = isUndefined(orderPopupId);
  const [paymentMethodsList, setPaymentMethodsList] = useState([]);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [printMode, setPrintMode] = useState(PRINT_MODE.ORDER_RECEIPT);
  const [selectedPickUp, setSelectedPickUp] = useState(null);
  const [manualProductsNotMap, setManualProductsNotMap] = useState([]);
  const [isChainExternalSuspicious, setIsChainExternalSuspicious] =
    useState(false);
  const [hasCustomSizeProduct, setHasCustomSizeProduct] = useState(false);
  const [packageList, setPackageList] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState<Channel>();
  const [provinceList, setProvinceList] = useState();
  const [deliveryTypes, setDeliveryTypes] = useState();
  const [billingShippingSelect, setBillingShippingSelect] = useState();
  const [source, setSource] = useState<any>();
  const [orderInfo, setOrderInfo] = useState<any>({});
  const [loadingAllActivity, setLoadingAllActivity] = useState(false);
  const [allOrderActivity, setAllOrderActivity] = useState<any>();
  const [orderMetadata, setOrderMetadata] = useState<any>();
  const { t, i18n } = useTranslation();
  const { currentLang, callCenterInfo, callCenterTokenReady } =
    useContext(AppContext);

  const loadOrderData = () => {
    if (!orderId && !isPublic) {
      return;
    }
    const method = isPublic ? publicDetail : getOrderDetail;
    const params = isPublic ? UrlUtils.getQuery() : orderId;
    Progress.show({ method, params: [params] }, (res: any) => {
      const resOrderInfo = res?.data?.data;
      if (res) {
        const {
          order,
          channel,
          shipping,
          products,
          refund,
          manualProducts,
          list_package,
          splitPayment,
          billing,
          salesPerson,
        } = resOrderInfo;
        const {
          suspicious_status,
          is_suspicious,
          status,
          is_custom_size,
          custom_size_status,
        } = order || {};
        document.title = `${channel?.name}-Order${order?.orderid}`;
        const manualProductsNotMap = getManualProductNotMap(
          products,
          manualProducts
        );
        const shippingList = shipping?.map((item: any) => ({
          ...item,
          expectedDateReceive: item.expected_to_receive,
        }));
        const isChainExternalSuspicious =
          is_suspicious &&
          suspicious_status === ORDER_SUSPICIOUS_STATUS.PENDING &&
          status !== "cancelled";
        const hasCustomSizeProduct =
          is_custom_size &&
          custom_size_status === ORDER_CUSTOM_SIZE_STATUS.PENDING &&
          status !== "cancelled";
        setManualProductsNotMap(manualProductsNotMap);
        setOrderInfo({
          ...resOrderInfo,
          shipping: shippingList,
          salesPerson,
        });
        setIsChainExternalSuspicious(isChainExternalSuspicious);
        setHasCustomSizeProduct(hasCustomSizeProduct);
        setSelectedChannel(channel);
        setPackageList(list_package ?? []);

        preloadDataRefund(refund);
      }
    });
  };

  const loadProvinceData = () => {
    getProvinceList().then((res) => {
      const provinceList = res?.data?.data?.province;
      if (provinceList) {
        setProvinceList(provinceList);
      }
    });
  };

  const loadDeliveryTypes = () => {
    const channelid = selectedChannel?.id;
    getDeliveryTypeList(channelid).then((res) => {
      const deliveryTypes = res?.data?.data?.delivery ?? [];
      setDeliveryTypes(deliveryTypes);
    });
  };

  const loadSourceOfOrder = () => {
    if (!orderId) {
      return;
    }
    getSourceOrder(orderId).then((respone) => {
      const source = respone?.data?.data?.source ?? {};
      setSource(source);
    });
  };

  const loadOrderPaymentMethods = () => {
    getOrderPaymentMethods({ order_id: orderId }).then((res) => {
      const list = res?.data?.data?.method ?? [];
      setPaymentMethodsList(list);
    });
  };

  const getManualProductNotMap = (productList: any, manualProducts: any) => {
    return manualProducts.filter((item: any) => {
      return !some(
        productList,
        (proItem) => proItem.manual_product_id === item.id
      );
    });
  };

  const preloadDataRefund = (refundList: any[]) => {
    const signatureImages: any[] = [];
    refundList.forEach((refund) => {
      if (refund?.status_by?.signatureUrl) {
        signatureImages.push(refund?.status_by?.signatureUrl);
      }
    });
    if (signatureImages.length > 0) {
      preloadImage(signatureImages);
    }
  };

  const loadPaymentOptions = (channelId: string) => {
    getPaymentOptions({ channel_id: channelId }).then((res) => {
      const paymentOptions = res?.data?.data?.options ?? [];
      setPaymentOptions(paymentOptions);
    });
  };

  const onClickPrintRefund = () => {};

  const onClickPrintOrder = ({ key }: any) => {
    const mode = key;
    if (mode === PRINT_MODE.ORDER_PICKUP) {
      DialogManager.showInfo(
        t("selectPickUpToPrint"),
        <SelectPrintPickUpView
          pickUpList={pickup}
          onChangePickUp={(pickupId: any) => {
            const result = pickup.find((item: any) => item?.id === pickupId);
            setSelectedPickUp(result);
          }}
        />,
        () => {
          setTimeout(() => {
            onPrintPickUp();
          }, 200);
        }
      );
      return;
    }
    if (!order.billing && !isEmpty(shipping)) {
      DialogManager.showInfo(
        t("selectBillingYouWant"),
        <SelectBillingView
          shipping={orderInfo?.shipping}
          onChangeShipping={(id: any) => {
            const billingShipping = find(
              shipping,
              (item: any) => item.id === id
            );
            onChangeBillingPrint(billingShipping);
          }}
        />,
        () => {
          setTimeout(() => {
            onPrintOrder(mode);
          }, 300);
        }
      );
      return;
    }
    onPrintOrder(mode);
  };

  const onChangeBillingPrint = (billingShippingSelect: any) => {
    setBillingShippingSelect(billingShippingSelect);
  };

  const onPrintOrder = (mode: string) => {
    if (mode === PRINT_MODE.ORDER_DETAIL_INTERNAL) {
      setLoadingAllActivity(true);
      loadAllOrderActivity();
    } else {
      setPrintMode(mode);
      setTimeout(() => {
        window.print();
      }, 500);
    }
    logPrint(orderId as string, "order");
  };

  const onPrintPickUp = (printPickUp?: any) => {
    setPrintMode(PRINT_MODE.ORDER_PICKUP);
    const pickup = printPickUp || selectedPickUp;
    if (pickup) {
      setSelectedPickUp(pickup);
    }
    setTimeout(() => {
      window.print();
    }, 500);
  };

  const loadAllOrderActivity = async () => {
    let allOrderActivity: any[] = [];
    let isLastPage = false;
    let index = 1;
    while (!isLastPage) {
      const { commentList, totalPage } = await loadPageActivity(index);
      allOrderActivity = allOrderActivity.concat(commentList);
      if (index >= totalPage) {
        isLastPage = true;
      } else {
        index++;
      }
    }
    setLoadingAllActivity(false);
    setPrintMode(PRINT_MODE.ORDER_DETAIL_INTERNAL);
    setAllOrderActivity(allOrderActivity);
    setTimeout(() => {
      window.print();
    }, 500);
  };

  const loadPageActivity = async (index: number) => {
    const labels = FILTER_COMMENT_TYPES?.map((type) => type.id);
    const params = UrlUtils.getQuery() ?? {};
    const res = isPublic
      ? await publicActivity({ ...params, page: index })
      : await getListComment(orderId as string, labels, index);
    const resData = res?.data?.data;
    const totalPage = resData?.pagination?.total ?? 0;
    const commentList = resData?.comments ?? [];
    return { totalPage, commentList };
  };

  const loadOrderMetadata = async () => {
    const res = await getOrderMetadata({ order_id: orderId });
    setOrderMetadata(res?.data?.data?.meta);
  };

  useEffect(() => {
    loadOrderData();
    loadSourceOfOrder();
    if (!isPublic) {
      loadOrderPaymentMethods();
    }
  }, []);

  useEffect(() => {
    if (selectedChannel) {
      loadDeliveryTypes();
    }
  }, [selectedChannel]);

  useUpdateEffect(() => {
    loadOrderData();
    loadProvinceData();
  }, [currentLang]);

  const {
    order,
    channel,
    by,
    shipping,
    billing = {},
    oldProducts,
    products,
    pickup,
    delivery,
    coupons,
    oldPayment,
    oldOrder,
    splitPayment,
    selfDiscount = [],
    employeeDiscount,
    hold = [],
    voucher = [],
    fee = [],
    fee_delivery = [],
    fee_tax = [],
    fee_external = [],
    discount_chain = [],
    contact,
    manualProducts,
    banks,
    banks2C2P,
    refund,
    thaiQrPayment = {},
    nav,
    tracking,
    customer,
    channel_address,
    salesPerson,
  } = orderInfo ?? {};

  const remainingItems = useMemo(() => {
    const totalQuantity = reduce(
      products,
      (sum, product) => {
        const { groups } = product;
        if (!groups || groups.length === 0) {
          return (sum += product?.quantity);
        }
        const sumQuantity = reduce(
          groups,
          (sumGroup, proGroup) => (sumGroup += proGroup?.quantity),
          0
        );
        return (sum += sumQuantity);
      },
      0
    );

    let totalDelivered = 0;
    if (delivery?.length) {
      delivery.forEach((deliveryItem: any) => {
        if (deliveryItem.status === "cancel") {
          return;
        }
        deliveryItem.items.forEach((skuItem: any) => {
          totalDelivered += skuItem.stock;
        });
      });
    }
    let pickedUp = 0;
    if (pickup?.length) {
      pickup.forEach((pickupItem: any) => {
        pickupItem.pickup.forEach((skuItem: any) => {
          pickedUp += skuItem.stock;
        });
      });
    }

    return totalQuantity - totalDelivered - pickedUp;
  }, [products, delivery, pickup]);

  const orderHasCod = useMemo(() => {
    return (
      splitPayment?.some((item: any) => item?.method === PAYMENT_COD) ?? false
    );
  }, [splitPayment]);

  return (
    <PrintProvider>
      <AppContext.Provider
        value={{
          order,
          channel,
          channel_address,
          by,
          shipping,
          oldProducts,
          products,
          pickup,
          delivery,
          deliveryTypes,
          onReloadOrderData: loadOrderData,
          onClickOrderPrint: onClickPrintOrder,
          onClickPickUpPrint: onPrintPickUp,
          manualProducts,
          manualProductsNotMap,
          isChainExternalSuspicious,
          hasCustomSizeProduct,
          coupons,
          oldPayment,
          oldOrder,
          splitPayment,
          selfDiscount,
          employeeDiscount,
          hold,
          voucher,
          fee,
          fee_delivery,
          fee_tax,
          fee_external,
          discount_chain,
          contact,
          banks,
          banks2C2P,
          paymentMethodsList,
          refund,
          thaiQrPayment,
          packageList,
          nav,
          tracking,
          customer,
          values: {
            selectedCustomer: [customer],
          },
          shippingProfile: shipping,
          billing,
          provinceList,
          loadProvinceList: loadProvinceData,
          paymentOptions,
          loadPaymentOptions: () => loadPaymentOptions(channel?.id),
          allOrderActivity,
          billingShippingSelect,
          selectedPickUp,
          callCenterInfo,
          editable,
          source,
          salesPerson,
          orderMetadata,
          loadOrderMetadata,
          remainingItems,
          callCenterTokenReady,
          type: OrderType.NORMAL,
          isPublic,
          orderHasCod,
        }}
      >
        {loadingAllActivity && <Spin />}
        <Print name="printOrder" printOnly>
          <PrintOrder printMode={printMode} />
        </Print>
        <NoPrint>
          <div className={isPublic ? "w-screen" : ""}>
            <OrderHeader />
            <div className="grid grid-cols-3 gap-4 mt-3 px-3 pb-3 pt-0">
              <div className="col-span-3 md:col-span-2">
                {order && (
                  <>
                    {isChainExternalSuspicious && <OrderDetailMTConfirm />}
                    {hasCustomSizeProduct && (
                      <OrderDetailMTConfirm type="customSize" />
                    )}
                    <OrderDetailTable />
                    <OrderNote className="my-3" />
                    <OrderPickup />
                    <DeliveryTracking />
                    <PaymentConfirmation className="mt-3" />
                  </>
                )}
                {refund && refund.length > 0 && <OrderRefundView />}
                <OrderActivities />
              </div>
              <div className="col-span-3 md:col-span-1">
                <OrderDetailMTType />
                {/* <OrderPackage /> */}
                {source?.website && <SourceOfOrder />}
                <OrderDetailAdsTracking />
                {order?.store && <OrderDetailWarehouse />}
                {salesPerson && <SalesPerson />}
                <OrderNav />
                <OrderDetailReceiptRef />
                <ShippingInfo />
                {oldOrder && <OrderCreateOrigin order={oldOrder} />}
                {order?.signature && <OrderSignature />}
                {/* {newOrder && <LoadedOrderInfo />} */}
                {callCenterInfo?.has_call_center && <CallHistory />}
              </div>
            </div>
          </div>
        </NoPrint>
      </AppContext.Provider>
    </PrintProvider>
  );
};

const PrintOrder = ({ printMode }: { printMode: string }) => {
  switch (printMode) {
    case PRINT_MODE.REFUND:
      return <PrintRefundLayout />;

    case PRINT_MODE.ORDER_DETAIL_INTERNAL:
      return <PrintOrderReceipt isShowActivity />;

    case PRINT_MODE.ORDER_RECEIPT:
      return (
        <PrintOrderReceipt isShowSignature hideSelfCouponBy hideCustomProInfo />
      );

    case PRINT_MODE.ORDER_RECEIPT_CUSTOMER:
      return (
        <PrintOrderReceipt
          isShowSignature
          hideSelfCouponBy
          hideCustomProInfo
          // eslint-disable-next-line no-useless-concat
          receiptText={
            "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ" +
            "\n" +
            "Receipt/Tax Invoice (ABB)"
          }
        />
      );

    case PRINT_MODE.ORDER_PICKUP:
      return <PrintOrderPickUp isShowSignature hideSelfCouponBy isPickup />;

    case PRINT_MODE.ORDER_CUSTOM_SIZE:
      return <PrintCustomSizeOrder />;

    default:
      return <PrintOrderReceipt hideSelfCouponBy hideCustomProInfo />;
  }
};

const SelectPrintPickUpView = ({ pickUpList = [], onChangePickUp }: any) => {
  const [pickUpSelected, setPickUpSelected] = useState(pickUpList?.[0]?.id);
  useEffect(() => {
    onChangePickUp(pickUpSelected);
  }, [pickUpSelected]);

  return (
    <RadioGroup
      value={pickUpSelected}
      dataSource={pickUpList}
      onChange={(value) => {
        setPickUpSelected(value);
      }}
      numberOfColumns={"1"}
      getLabel={(pickup) => (
        <>
          <div style={{ marginLeft: "1rem" }}>
            <div className="bodyModalText">{`#${pickup?.code ?? "N/A"}`}</div>
            <div className="bodyModalText">{`${
              pickup?.person_name ?? "N/A"
            }`}</div>
            <div className="bodyModalText">{`${
              pickup?.person_phone ?? "N/A"
            }`}</div>
            <div className="bodyModalText">{`${pickup?.store?.name}(${pickup?.store?.code})`}</div>
          </div>
        </>
      )}
    />
  );
};

const SelectBillingView = ({ shipping, onChangeShipping }: any) => {
  const [shippingSelected, setShippingSelected] = useState(shipping?.[0]?.id);
  useEffect(() => {
    onChangeShipping(shippingSelected);
  }, [shippingSelected]);
  return (
    <RadioGroup
      value={shippingSelected}
      dataSource={shipping}
      onChange={(e) => {
        setShippingSelected(e);
      }}
      numberOfColumns={"1"}
      getLabel={(shipping) => (
        <>
          <div>
            <div className="bodyModalText">{getFullName(shipping)}</div>
            <div className="bodyModalText">{shipping.phone}</div>
            <div className="bodyModalText">
              {getFullAddressFromShipping(shipping)}
            </div>
          </div>
        </>
      )}
    />
  );
};

export default OrderDetail;
