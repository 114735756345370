import InputSelectForm from "@common/input/InputSelectForm";
import InputTextForm from "@common/input/InputTextForm";
import ChainSelect from "@components/chain/components/ChainSelect";
import ChannelSelect from "@components/channel/components/ChannelSelect";
import InventoryEventSelect from "@components/invenotry-control-event/components/InventoryEventSelect";
import { Path } from "@components/layout/Path";
import ButtonFileUpload from "@components/shared/ButtonFileUpload";
import UploadFile from "@components/shared/UploadFile";
import StoreSolidSelect from "@components/store/components/StoreSolidSelect";
import { STOCK_REQUEST_TYPES } from "@constants/stock-request";
import AppContext from "@helpers/context";
import { mapStockRequestToServer } from "@interfaces/stock-request";
import { createStockRequest } from "@network/api/stock-request";
import { DateInput, Header, Progress } from "d-react-components";
import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import StockRequestProductForm from "../components/StockRequestProductForm";
import StockRequestProductsErrorDrawer from "../detail/StockRequestProductsErrorDrawer";

const StockRequestCreate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openStockError, setOpenStockError] = useState(false);

  const requestForm = useFormik<any>({
    initialValues: {} as any,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: schema,
    onSubmit: (values: any) => {
      const body = mapStockRequestToServer(values);
      onCreateRequest(body);
    },
  });

  const formValues = requestForm?.values;
  const formErrors = requestForm?.errors;

  const classNameInput = "mt-3";

  const onCreateRequest = (input: any) => {
    Progress.show(
      { method: createStockRequest, params: [input] },
      (res: any) => {
        const requestId = res?.data?.data?.request?.id;
        navigate(generatePath(Path.STOCK_REQUEST_DETAIL, { requestId }));
      },
      (err: any) => {
        if (err === "Products invalid") {
          setOpenStockError(true);
          return true;
        }
      }
    );
  };

  return (
    <AppContext.Provider value={{ requestForm }}>
      <Header
        title={t("newStockRequest")}
        onSave={() => requestForm.handleSubmit()}
      />
      <div className="p-3">
        <div className="card-container p-3">
          <ChannelSelect
            value={requestForm?.values.channel}
            className={classNameInput}
            onChange={(value) => requestForm.setFieldValue("channel", value)}
          />
          {/* <ChainSelect
          value={formValues?.chain}
          className={classNameInput}
          onChange={(value) => requestForm?.setFieldValue("chain", value)}
        /> */}
          <StoreSolidSelect
            channelId={requestForm?.values.channel?.id}
            label={t("branchWarehouse")}
            value={formValues?.store}
            onChange={(value) => requestForm.setFieldValue("store", value)}
            className={classNameInput}
          />

          <InputSelectForm
            dataSource={STOCK_REQUEST_TYPES}
            keyData="requestType"
            form={requestForm}
            className={classNameInput}
          />

          <InventoryEventSelect
            warehouseIds={formValues?.store?.id ? [formValues?.store?.id] : []}
            value={formValues?.event}
            onChange={(value) => requestForm.setFieldValue("event", value)}
            className={classNameInput}
          />

          <StockRequestProductForm form={requestForm} className="mt-3" />

          <DateInput
            value={formValues?.expectedDateArrival}
            onChange={(value) =>
              requestForm.setFieldValue("expectedDateArrival", value)
            }
            label={t("expectedDateArrival")}
            className={classNameInput}
            error={formErrors?.expectedDateArrival as any}
            required
            format="DD/MM/YYYY"
          />
          <InputTextForm
            keyData="remark"
            form={requestForm}
            multiple
            className={classNameInput}
          />
          <div className={classNameInput}>
            <label>{t("attachments")}</label>
            <ButtonFileUpload
              //   uploadedFiles={formValues?.attachments}
              onChange={(values: any) =>
                requestForm.setFieldValue("documents", values)
              }
              maxFiles={5}
            />
          </div>

          {openStockError && (
            <StockRequestProductsErrorDrawer
              productSubmitted={formValues?.products}
              inventoryEventControl={formValues?.event}
              open={openStockError}
              onClose={() => setOpenStockError(false)}
            />
          )}
        </div>
      </div>
    </AppContext.Provider>
  );
};

export default StockRequestCreate;
