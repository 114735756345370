import InputSelectForm from "@common/input/InputSelectForm";
import InputTextForm from "@common/input/InputTextForm";
import Drawer from "@components/shared/Drawer";
import { YES_NO_OPTIONS } from "@constants/common";
import UserAPI from "@network/api/user";
import { Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

function UserDetailPersonalUpdateModal({
  open,
  onClose,
  userId,
  defaultValue,
  onUpdateSuccess,
}: any) {
  const { t } = useTranslation();
  const personalForm = useFormik({
    initialValues: defaultValue ?? {},
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      const body = { id: userId, ...values };
      Progress.show({ method: UserAPI.update, params: [body] }, () => {
        Notifications.showSuccess(t("updateUserSuccess"));
        onUpdateSuccess();
        onClose();
      });
    },
  });

  const classNameInput = "col-6 mt-3";

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("updateUser")}
      onSave={() => personalForm.handleSubmit()}
      size="large"
    >
      <div className="row ">
        <InputTextForm
          keyData="username"
          form={personalForm}
          required
          className={classNameInput}
        />
        <InputTextForm
          keyData="employee_id"
          form={personalForm}
          required
          className={classNameInput}
          label={t("employeeId")}
        />
        <InputTextForm
          keyData="fullname"
          form={personalForm}
          required
          className={classNameInput}
          label={t("fullName")}
        />
        <InputTextForm
          keyData="name"
          form={personalForm}
          required
          className={classNameInput}
        />
        <InputSelectForm
          label={t("block")}
          keyData="block"
          form={personalForm}
          required
          className={classNameInput}
          dataSource={YES_NO_OPTIONS}
        />
      </div>
    </Drawer>
  );
}

export default UserDetailPersonalUpdateModal;
