import Drawer from "@components/shared/Drawer";
import {
  LOAD_ORDER_TYPE,
  LOAD_ORDER_TYPES,
  MARKETPLACE,
  MARKETPLACE_TYPES,
} from "@constants/order";
import { loadOrderShopeShop, loadOrderLazadaShop } from "@network/api/order";
import {
  Progress,
  Notifications,
  InputText,
  Select,
  DateInput,
} from "d-react-components";
import { useFormik } from "formik";
import moment from "moment";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import SelectShop from "./SelectShop";

const ORDER_API_BY_SHOP_TYPE = {
  [MARKETPLACE.LAZADA]: loadOrderLazadaShop,
  [MARKETPLACE.SHOPEE]: loadOrderShopeShop,
};

export const OrderShopeModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const OrderLoadShopeSchema = Yup.object().shape({
    type: Yup.mixed().required(t("fieldRequired")),
    shop: Yup.object().required(t("fieldRequired")),
    orderType: Yup.mixed().required(t("fieldRequired")),
    orderId: Yup.string().when(["orderType"], {
      is: (orderType: string) => orderType === LOAD_ORDER_TYPE.SINGLE,
      then: Yup.string().required(t("fieldRequired")),
    }),
    date: Yup.mixed().when(["orderType"], {
      is: (orderType: string) => orderType === LOAD_ORDER_TYPE.BULK,
      then: Yup.mixed().required(t("fieldRequired")),
    }),
  });
  const orderForm = useFormik({
    initialValues: {},
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: OrderLoadShopeSchema,
    onSubmit: (values: any) => {
      const input = {
        shop_id: values?.shop?.id,
        type: values?.orderType,
        ...(values?.orderType === LOAD_ORDER_TYPE.SINGLE
          ? {
              order_id: values?.orderId,
            }
          : {}),
        ...(values?.orderType === LOAD_ORDER_TYPE.BULK
          ? {
              start: moment(values?.date)
                .set("hour", 12)
                .set("minute", 0)
                .valueOf(),
            }
          : {}),
      };
      onLoadOrder(input);
    },
  });

  const onLoadOrder = (input: any) => {
    const api = ORDER_API_BY_SHOP_TYPE[formValues?.type];
    Progress.show({ method: api, params: [input] }, () => {
      Notifications.showSuccess(t("loadSuccessful"));
      onClose();
    });
  };

  const formValues: any = orderForm?.values;
  const formErrors: any = orderForm?.errors;
  
  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("loadOrder")}
      onSave={() => orderForm.handleSubmit()}
      saveText={t("load")}
    >
      <div>
        <Select
          dataSource={MARKETPLACE_TYPES}
          value={formValues?.type}
          onChange={(value: any) => {
            orderForm.setFieldValue("type", value);
            orderForm.setFieldValue("shop", undefined);
          }}
          className="mb-3"
          getValue={(item) => item.value}
          label={t("marketplace")}
        />
        {formValues?.type && (
          <>
            <SelectShop
              value={formValues?.shop}
              onChange={(value: any) => orderForm.setFieldValue("shop", value)}
              error={formErrors?.shop}
              type={formValues?.type}
              className="mb-3"
            />
            <Select
              dataSource={LOAD_ORDER_TYPES}
              value={formValues?.orderType}
              onChange={(value: any) =>
                orderForm.setFieldValue("orderType", value)
              }
              className="mb-3"
              getValue={(item) => item.value}
              label={t("type")}
              getLabel={(item) => t(item.label)}
              error={formErrors?.orderType}
            />
            {formValues?.orderType === LOAD_ORDER_TYPE.SINGLE && (
              <InputText
                className="mb-3"
                label={t("orderId")}
                value={formValues?.orderId}
                onChange={(event: any) =>
                  orderForm.setFieldValue("orderId", event?.target?.value)
                }
                error={formErrors?.orderId}
              />
            )}
            {formValues?.orderType === LOAD_ORDER_TYPE.BULK && (
              <div className="">
                <DateInput
                  label={t("date")}
                  value={formValues?.date as any}
                  error={formErrors?.date}
                  onChange={(val) => {
                    orderForm.setFieldValue("date", val);
                  }}
                  format="DD/MM/YYYY"
                  disabledDate={(current) => moment(current).isAfter(moment())}
                />
              </div>
            )}
          </>
        )}
      </div>
    </Drawer>
  );
};

export default OrderShopeModal;
