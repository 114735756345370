import AppContext from "@helpers/context";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import CartRuleConditionForm from "../component/CartRuleConditionForm";

export default function CartRuleCreateCondition() {
  const { cartRuleForm } = useContext(AppContext);
  const { t } = useTranslation();
  const formValues = cartRuleForm?.values ?? {};
  //************************************************************* LOAD DATA ********************************** */

  //************************************************************* UI CONTROL ********************************** */

  //************************************************************* UI RENDER ********************************** */

  return (
    <div className="mt-3">
      <label>{t("cartCondition")}</label>
      <div className="p-3 border mt-1">
        <CartRuleConditionForm form={cartRuleForm} />
      </div>
    </div>
  );
}
