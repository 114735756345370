import UserAvatarName from "@components/shared/UserAvatarName";
import AppContext from "@helpers/context";
import { IDelivery } from "@interfaces/delivery";
import { IRowsKey, TimeUtils, ViewRowInterchange } from "d-react-components";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

const DeliveryStockGeneral = () => {
  const { deliveryDetail } = useContext(AppContext);
  const { t } = useTranslation();
  const DETAILS_KEY = useMemo<IRowsKey<IDelivery>[]>(
    () => [
      {
        id: "ref",
        label: t("id"),
      },
      {
        id: "transfer",
        label: t("origin"),
        renderContent: ({ data, item }) =>
          `${data?.transfer_origin?.name} (${data?.transfer_origin?.code})`,
      },
      {
        id: "transfer",
        label: t("destination"),
        renderContent: ({ data, item }) =>
          `${data?.transfer_destination?.name} (${data?.transfer_destination?.code})`,
      },
      {
        id: "stock_inventory_control",
        label: t("event"),
        renderContent: ({ data, item }) => data?.title ?? "N/A",
      },

      {
        id: "transfer",
        label: t("transferType"),
        renderContent: ({ data, item }) => data?.transfer_type ?? "N/A",
      },
      {
        id: "transfer",
        label: t("estDateTransfer"),
        renderContent: ({ data, item }) =>
          TimeUtils.toDate(data?.expected_transfer),
      },
      {
        id: "transfer",
        label: t("estDateArrival"),
        renderContent: ({ data, item }) =>
          TimeUtils.toDate(data?.expected_arrival),
      },
      {
        id: "remark",
        label: t("remark"),
      },
      {
        id: "by",
        label: t("createdBy"),
        renderContent: ({ data, item }) =>  <UserAvatarName user={data} />,
      },
      {
        id: "created",
        label: t("createdAt"),
        renderContent: ({ data, item }) => TimeUtils.toDate(data),
      },
    ],
    [t]
  );
  return (
    <div className="card-container p-4 ">
      <div className="d-flex justify-content-between align-items-center">
        <label>{t("generalInformation")}</label>
        {/* <Button onClick={() => setOpenEdit(true)} variant="trans">
          {t("edit")}
        </Button> */}
      </div>

      <ViewRowInterchange
        dataSource={deliveryDetail}
        keyList={DETAILS_KEY as any}
        variant="border"
        classNameLabel="font-weight-bold "
      />
    </div>
  );
};

export default DeliveryStockGeneral;
