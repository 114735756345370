import Drawer, { DrawerProps } from "@components/shared/Drawer";
import AppContext from "@helpers/context";
import { RadioGroup } from "d-react-components";
import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckSkuTab } from "./CheckSKU";
import { isEmpty } from "lodash";
import { exportToCSV } from "@helpers/file";

const DownloadSKU = ({ open, onClose }: DrawerProps) => {
  const { t } = useTranslation();

  const OPTION_DOWNLOAD = useMemo(
    () => [
      { id: "all", label: t("downloadAllItem") },
      { id: "notExistDuplicate", label: t("downloadNotExisted") },
    ],
    [t]
  );
  const [optionDownload, setOptionDownload] = useState(OPTION_DOWNLOAD[0].id);

  const { selectedTab, pasteDataResult, uploadDataResult } =
    useContext(AppContext);

  const handleOnClickDownload = async () => {
    let resultData =
      selectedTab === CheckSkuTab.COPY_PASTE
        ? [...pasteDataResult]
        : [...uploadDataResult];
    if (isEmpty(resultData)) {
      return Promise.reject();
    }
    if (optionDownload === "notExistDuplicate") {
      resultData = resultData.filter(
        (item) => item.status === "existed" || item.status === "duplicated"
      );
    }
    const exportData = resultData.map((item, index) => {
      return {
        No: index + 1,
        SKU: item?.sku ?? "N/A",
        Name: item?.name ?? "N/A",
        Category: item?.category?.name ?? "N/A",
        Brand: item?.brand?.name ?? "N/A",
        Status: item?.status ?? "N/A",
      };
    });
    const fileName = `CHECK_SKU_PRODUCT${new Date()}`;
    exportToCSV(exportData, fileName);
    onClose();
    return Promise.resolve();
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("downloadOption")}
      width={400}
      size="auto"
      onSave={handleOnClickDownload}
    >
      <RadioGroup
        dataSource={OPTION_DOWNLOAD}
        getLabel={(item) => item.label}
        numberOfColumns="1"
        value={optionDownload}
        onChange={(value: any) => setOptionDownload(value)}
      />
    </Drawer>
  );
};

export default DownloadSKU;
