import AppContext from "@helpers/context";
import { Avatar } from "d-react-components";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ShippingItemInfo from "./ShippingItemInfo";
import { useRootStore } from "../../../../App";

const SalesPerson = () => {
  const { salesPerson, by } = useContext(AppContext);
  const { t } = useTranslation();
  const setUserModalId = useRootStore((state) => state.setUserModalId);

  return (
    <div className="bg-white p-3 mb-3 shadow-sm">
      <ShippingItemInfo title={t("createdBy")} className="pb-2">
        <div
          className="flex items-center cursor-pointer"
          onClick={() => setUserModalId(by?.id)}
        >
          <Avatar
            src={by?.avatar || "/images/img-default.png"}
            className="rounded-sm overflow-hidden"
            variant="square"
            size="small"
          />
          <div className="block ml-2">
            <div className="text-primary">
              {by?.fullname} ({by?.name})
            </div>
            <div>ID: {by.employee_id || by.companyId}</div>
          </div>
        </div>
      </ShippingItemInfo>
      {salesPerson && (
        <ShippingItemInfo title={t("salesPerson")} className="border-t pt-2">
          <div
            className="flex items-center cursor-pointer"
            onClick={() => setUserModalId(by?.id)}
          >
            <Avatar
              src={salesPerson?.avatar || "/images/img-default.png"}
              className="rounded-sm overflow-hidden"
              variant="square"
              size="small"
            />
            <div className="block ml-2">
              <div className="text-primary">
                {salesPerson?.fullname} ({salesPerson?.name})
              </div>
              <div>ID: {salesPerson.employee_id || by.companyId}</div>
            </div>
          </div>
        </ShippingItemInfo>
      )}
    </div>
  );
};

export default SalesPerson;
