import AppContext from "@helpers/context";
import React, { useContext, useMemo, useRef, useState } from "react";
import { SubscriptionContractContext } from "../SubscriptionContractDetail";
import { useTranslation } from "react-i18next";
import {
  AwesomeTableComponent,
  IColumnsProps,
  TimeUtils,
  ViewLabelStatus,
} from "d-react-components";
import { listRentalInvoice } from "@network/api/rental";
import InvoiceDetailModal from "../modal/InvoiceDetailModal";
import { find, pick } from "lodash";
import CurrencyFormat from "react-currency-format";
import { useUpdateEffect } from "react-use";
import { RentalPayment } from "@interfaces/rent";
import { Popover } from "antd";
import PaymentMethod from "./PaymentMethod";
import { CONTRACT_INVOICE_PAYMENT_STATUS } from "@constants/contract";

export const getStatusPaymentContract = (status: any) => {
  const orderStatus = find(
    CONTRACT_INVOICE_PAYMENT_STATUS,
    (item) => item.value === status
  );
  return orderStatus;
};

const PaymentCards = ({ payments }: { payments: RentalPayment[] }) => {
  const { t } = useTranslation();
  // const cardList = payments?.filter((item) => !isEmpty(item.card_number));
  // if (isEmpty(cardList)) {
  //   return <>{t("cardBy2c2p")}</>;
  // }
  //remove first item from cardList
  const otherCards = payments
    ?.slice(1)
    ?.map((item, index) => <PaymentMethod method={item} key={index} />);
  return payments.length === 1 ? (
    <div>
      <PaymentMethod method={payments?.[0]} />
    </div>
  ) : (
    <div>
      <PaymentMethod method={payments?.[0]} />
      <Popover placement="bottomLeft" title={""} content={otherCards}>
        <span className="cursor-pointer px-[3px] bg-primary text-white text-[10px] inline-block min-w-[18px] text-center h-[20px] rounded">
          +{payments?.length - 1}
        </span>
      </Popover>
    </div>
  );
};

const SubscriptionContractPayment = () => {
  const { id, rentalInfo } =
    useContext<SubscriptionContractContext>(AppContext);
  const [showInvoiceModal, setShowInvoiceModal] = useState({
    open: false,
    id: "",
  });
  const tableRef = useRef<any>(null);
  const tableCurrentPlanRef = useRef<any>(null);
  const { t } = useTranslation();

  const source = async ({ pageSize, pageIndex }: any) => {
    return listRentalInvoice({
      id,
      page: pageIndex,
      limit: pageSize,
    });
  };
  const currentPlan = useMemo(
    () => [
      {
        ...pick(rentalInfo, [
          "total",
          "month",
          "total_payment",
          "count_month_payment",
          "card_number",
          "next_payment",
        ]),
      },
    ],
    [rentalInfo]
  );

  const columns: IColumnsProps = useMemo(
    () => [
      {
        title: t("invoiceNo"),
        dataIndex: "code",
        width: 200,
        render: (data, item) => (
          <div className="flex flex-col">
            <span
              className="text-primary cursor-pointer"
              onClick={() =>
                setShowInvoiceModal({
                  open: true,
                  id: item.id,
                })
              }
            >
              {data}
            </span>
            <span className="text-gray-400 text-xs text-ellipsis overflow-hidden">
              {item.created
                ? TimeUtils.convertMiliToDateTime(item.created)
                : ""}
            </span>
          </div>
        ),
      },
      {
        title: t("description"),
        dataIndex: "description",
        render: (data, item) => item.remark || item.description,
      },
      {
        title: t("amount"),
        dataIndex: "total",
        width: 240,
        render: (data, item) => (
          <div className="flex flex-col">
            <span className="">{data}</span>
            <span className="text-gray-400 text-ellipsis overflow-hidden flex items-center">
              <PaymentCards payments={item?.payments} />
              {item.has_failed && (
                <span className="ml-1 cursor-pointer px-[3px] bg-red-500 text-white text-[10px] inline-block min-w-[18px] text-center h-[20px] rounded whitespace-nowrap">
                  {t("failed")}
                </span>
              )}
            </span>
          </div>
        ),
      },
      {
        title: t("status"),
        dataIndex: "status",
        width: 80,
        render: (data) => {
          const orderStatus = getStatusPaymentContract(data);
          return (
            <ViewLabelStatus
              content={t(orderStatus?.label ?? "")}
              color={orderStatus?.color}
              className="mr-2 mb-1 whitespace-nowrap"
            />
          );
        },
      },
    ],
    [t]
  );
  const columnsCurrentPlan: IColumnsProps = useMemo(
    () => [
      {
        title: t("totalAmount"),
        dataIndex: "total",
        width: 100,
        render: (data, item) => (
          <CurrencyFormat value={data} displayType="text" thousandSeparator />
        ),
      },
      {
        title: t("contractTerm"),
        dataIndex: "month",
        width: 100,
        render: (data, item) => `${item?.count_month_payment}/${data}`,
      },
      {
        title: t("paid"),
        dataIndex: "total_payment",
        width: 100,
        render: (data, item) => (
          <CurrencyFormat value={data} displayType="text" thousandSeparator />
        ),
      },
      {
        title: t("remaining"),
        dataIndex: "total_payment",
        width: 100,
        render: (data, item) => (
          <CurrencyFormat
            value={item.total - data}
            displayType="text"
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
          />
        ),
      },
      {
        title: t("nextBillingDate"),
        dataIndex: "next_payment",
        width: 130,
        render: (data, item) =>
          item.count_month_payment === item.month
            ? t("contractEnded")
            : data
            ? TimeUtils.convertMiliToDate(data)
            : "",
      },
      {
        title: t("method"),
        dataIndex: "card_number",
        width: 160,
        render: (data, item) => <PaymentMethod method={item} />,
      },
    ],
    [t]
  );

  useUpdateEffect(() => {
    tableRef?.current?.refresh();
  }, [rentalInfo]);

  return (
    <div>
      <div className="mb-3">
        <label className="block mb-2 text-sm">{t("currentPlan")}</label>
        <AwesomeTableComponent
          dataSource={currentPlan}
          columns={columnsCurrentPlan}
          ref={tableCurrentPlanRef}
          pagination={false}
        />
      </div>
      <div className="mb-3">
        <label className="block mb-2 text-sm">{t("paymentHistory")}</label>
        <AwesomeTableComponent
          source={source}
          transformer={(response) => {
            return response?.data?.data?.invoice ?? [];
          }}
          columns={columns}
          ref={tableRef}
        />
        {showInvoiceModal.open && (
          <InvoiceDetailModal
            open={showInvoiceModal.open}
            id={showInvoiceModal.id as string}
            onClose={() => {
              setShowInvoiceModal({
                id: "",
                open: false,
              });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default SubscriptionContractPayment;
