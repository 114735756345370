import Drawer from "@components/shared/Drawer";
import { INVENTORY_STATUS_LIST } from "@constants/inventory";
import { PRODUCT_TYPES } from "@constants/product";
import { exportToCSV } from "@helpers/file";
import { getInventoryList } from "@network/api/inventory";
import { Spin } from "antd";
import { useFormik } from "formik";
import { find, map } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import InventoryFilterForm from "../components/InventoryFilterForm";

interface IDrawerProps {
  open: boolean;
  onClose: () => void;
}
function InventoryTableExportDrawer({ open, onClose }: IDrawerProps) {
  const { t } = useTranslation();
  const [loadingExport, setLoadingExport] = useState(false);
  const [percentageExport, setPercentageExport] = useState(0);

  const filterForm = useFormik<any>({
    initialValues: {} as any,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values: any) => {
      onExport(values);
    },
  });

  const onExport = async (filterBody: any) => {
    setLoadingExport(true);
    setPercentageExport(0);

    let sendData: any = {
      warehouseid: map(filterBody?.warehouses, (item) => item.id),
      brand: map(filterBody?.brand, (item) => item.id),
      category: map(filterBody?.category, (item) => item.id),
      product_type: filterBody?.productType,
      stock_inventory_control_id: map(filterBody?.events, (item) => item.id),
    };

    const statusValue = find(
      INVENTORY_STATUS_LIST,
      (item) => item.id === filterBody?.status
    );

    if (statusValue) {
      sendData.total_stock = statusValue?.value;
    }

    try {
      await onExportInventory(sendData);
    } catch (err) {
      setLoadingExport(false);
    }
  };

  const onExportInventory = async (body: any) => {
    let remainPage = true;
    let inventoryListResult: any[] = [];
    let pageIndex = 1;
    while (remainPage) {
      const response = await getInventoryList(body, pageIndex);
      const inventoryList = response?.data?.data?.product ?? [];
      const paginationData = response?.data?.data?.pagination ?? {};
      const { currentPage, total } = paginationData;
      inventoryListResult = inventoryListResult.concat(inventoryList);
      if (currentPage === total) {
        remainPage = false;
        setPercentageExport(100);
      } else {
        pageIndex++;
        setPercentageExport(Math.round((currentPage / total) * 100));
      }
    }

    const fileDataInventory = convertToExportData(inventoryListResult);

    exportToCSV(fileDataInventory, "InventoryExport");
    setLoadingExport(false);
    onClose();
  };

  const convertToExportData = (inventoryList: any[]) => {
    return inventoryList.map((item) => {
      const productType = find(
        PRODUCT_TYPES,
        (typeItem) => typeItem.id === item?.product_type
      );
      return {
        [t("itemName")]: item?.name ?? "",
        [t("sku")]: item?.sku ?? "",
        [t("type")]: t(productType?.label as any) ?? "",
        [t("category")]: item?.category?.name ?? "",
        [t("totalStock")]: item?.total_stock ?? "",
        [t("stock")]: item?.stock?.[0]?.stock ?? "",
      };
    });
  };

  const renderContent = () => {
    if (loadingExport) {
      return (
        <div className="p-4 flex-center">
          <Spin />
        </div>
      );
    }
    return <InventoryFilterForm filterForm={filterForm} />;
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("export")}
      onSave={() => filterForm.handleSubmit()}
      size="auto"
      width={"80%"}
      saveText={t("export")}
    >
      {renderContent()}
    </Drawer>
  );
}

export default InventoryTableExportDrawer;
