import OrderDetail from "@components/order/detail/OrderDetail";
import { Modal } from "d-react-components";
import React from "react";
import { useTranslation } from "react-i18next";

const OrderDetailPopup = ({ open, onClose, orderId }: any) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={open}
      onClose={onClose}
      width="100vw"
      title={t("orderDetail")}
      showFooter={false}
    >
      <OrderDetail orderId={orderId} />
    </Modal>
  );
};

export default OrderDetailPopup;
