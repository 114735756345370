import ProductName from "@components/product/components/ProductName";
import { DELIVERY_STOCK_STATUS } from "@constants/delivery";
import AppContext from "@helpers/context";
import { AwesomeTableComponent, Summary } from "d-react-components";
import { filter, groupBy, map, reduce } from "lodash";
import { useContext, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

const getProductFromList = (list = [], pro: any) => {
  if (!(list?.length > 0)) {
    return [];
  }
  return filter(list, (item: any) => item?.product_id === pro?.productid);
};

const getDriverReceivedQty = (deliverList = [], product: any) => {
  const deliverListFiltered = filter(
    deliverList,
    (item: any) => item.status !== DELIVERY_STOCK_STATUS.CANCELLED
  );
  const deliverItemList = reduce(
    deliverListFiltered,
    (list = [], item: any) => list.concat(item?.transfer_stock_product ?? []),
    []
  );
  const itemDeliver = getProductFromList(deliverItemList, product);

  return reduce(
    itemDeliver,
    (sum, item: any) => (sum += item.total_driver_receive),
    0
  );
};

const getStaffReceivedQty = (deliverList = [], product: any) => {
  const deliverItemList = reduce(
    deliverList,
    (list = [], item: any) => list.concat(item?.transfer_stock_product),
    []
  );
  const itemDeliver = getProductFromList(deliverItemList, product);
  return reduce(itemDeliver, (sum, item: any) => (sum += item.receive), 0);
};

const TransferStockDetailProduct = () => {
  const { transferDetail } = useContext(AppContext);
  const { t } = useTranslation();

  const tableRef = useRef<any>();

  const offListProduct = useMemo(() => {
    const fullList = reduce(
      transferDetail?.delivery,
      (list, item) => list.concat(item?.receive_stock_additional),
      []
    );
    const result: any[] = [];
    if (fullList?.length > 1) {
      const groupList = groupBy(fullList, (item: any) => item?.productid);

      Object.keys(groupList).forEach((key) => {
        const listPro = groupList?.[key] ?? [];
        const total = reduce(
          listPro,
          (sum, item: any) => (sum += item?.receive ?? 0),
          0
        );
        const product: any = listPro?.[0] ?? {};
        result.push({ ...product, receive: total });
      });
    }
    return result;
  }, [transferDetail?.delivery]);

  const columns: any[] = [
    {
      title: t("name"),
      dataIndex: "",
      render: (product: any) => <ProductName item={{ product }} />,
    },
    {
      title: t("transferQty"),
      dataIndex: "quantity",
      align: "center",
      render: (quantity: any) => quantity || "N/A",
    },
    {
      title: t("driverReceiveQty"),
      dataIndex: "deliveryReceiveQty",
      align: "center",
      render: (deliveryReceiveQty: any) => deliveryReceiveQty || 0,
    },
    {
      title: t("staffReceiveQty"),
      dataIndex: "staffReceiveQty",
      align: "center",
      render: (quantity: any) => quantity || 0,
    },
  ];

  const productSource = useMemo(() => {
    let result = [];
    if (transferDetail?.items?.length > 0) {
      result = map(transferDetail?.items, (iPro) => {
        const deliveryReceiveQty = getDriverReceivedQty(
          transferDetail?.delivery,
          iPro
        );
        const staffReceiveQty = getStaffReceivedQty(
          transferDetail?.delivery,
          iPro
        );
        return {
          ...iPro,
          deliveryReceiveQty,
          staffReceiveQty,
        };
      });
    }
    return result;
  }, [transferDetail?.items]);

  console.log("productSource", productSource);

  const totalProduct = useMemo(() => {
    return reduce(
      transferDetail?.items,
      (sum, item) => (sum += item?.quantity),
      0
    );
  }, [transferDetail?.items]);

  const totalReceive = useMemo(() => {
    return reduce(
      productSource,
      (sum, item) => (sum += item?.staffReceiveQty ?? 0),
      0
    );
  }, [productSource]);

  const totalDriveReceive = useMemo(() => {
    return reduce(
      productSource,
      (sum, item) => (sum += item?.deliveryReceiveQty ?? 0),
      0
    );
  }, [productSource]);

  const renderSummaryRow = () => {
    return (
      <Summary.Row>
        <Summary.Cell index={0} className="text-right font-weight-bold">
          {t("total")}
        </Summary.Cell>
        <Summary.Cell index={1} className="text-center font-weight-bold">
          {totalProduct}
        </Summary.Cell>
        <Summary.Cell
          index={2}
          className="text-center font-weight-bold"
        >{`${totalDriveReceive}/${totalProduct}`}</Summary.Cell>
        <Summary.Cell
          index={3}
          className="text-center font-weight-bold"
        >{`${totalReceive}/${totalProduct}`}</Summary.Cell>
      </Summary.Row>
    );
  };

  const renderOffListTable = () => {
    const offListColumns: any[] = [
      {
        title: t("item"),
        dataIndex: "",
        render: (ref: any, item: any) => (
          <ProductName item={{ product: item }} />
        ),
      },
      {
        title: t("quantity"),
        dataIndex: "receive",
        align: "center",
        render: (quantity: any) => quantity || "N/A",
      },
    ];

    const renderOffListSummary = () => {
      return (
        <Summary.Row>
          <Summary.Cell index={0} className="text-right font-weight-bold">
            {t("total")}
          </Summary.Cell>
          <Summary.Cell index={1} className="text-center font-weight-bold">
            {reduce(offListProduct, (sum, item) => (sum += item?.receive), 0)}
          </Summary.Cell>
        </Summary.Row>
      );
    };

    return (
      <div className="car-container mt-4 mb-3">
        <label className=" my-3">{t("receiveStockOffTheList")}</label>
        <AwesomeTableComponent
          dataSource={offListProduct}
          columns={offListColumns}
          pagination={false}
          summary={() => renderOffListSummary()}
        />
      </div>
    );
  };

  return (
    <div className="card-container p-4 ">
      <div className="d-flex justify-content-between align-items-center">
        <label>{t("itemList")}</label>
      </div>
      <AwesomeTableComponent
        ref={(ref) => {
          tableRef.current = ref;
        }}
        columns={columns}
        dataSource={productSource}
        className="mt-3 height-fit-content"
        pagination={false}
        summary={() => renderSummaryRow()}
      />
      {renderOffListTable()}
    </div>
  );
};

export default TransferStockDetailProduct;
