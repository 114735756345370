import { useTableSelectionOption } from "@helpers/useTable";
import {
  AwesomeTableComponent,
  AwesomeTableComponentProps,
  Progress,
} from "d-react-components";
import { ceil, isEmpty } from "lodash";
import React, { forwardRef } from "react";

interface AwesomeTableSelectableProps extends AwesomeTableComponentProps {
  setSelectedRow: (selectedRows: any) => void;
  apiSource?: any;
}

const AwesomeTableSelectable = forwardRef(
  (props: AwesomeTableSelectableProps, ref: any) => {
    const { rowSelection, setSelectedRow, apiSource, transformer } = props;
    const [tableSelectionOptions, tableMetaData, setTableMetaData] =
      useTableSelectionOption(
        (selectedRows: any) => setSelectedRow(selectedRows),
        () => onSelectAllOfAllPage()
      );

    const onSelectAllOfAllPage = () => {
      if (!apiSource) return;
      const totalPages = ceil(tableMetaData?.totalDataLength / 100);
      Progress.show(
        { method: apiSource, params: [totalPages] },
        (wList: any) => {
          setTableMetaData({ currentPageData: wList, listSelected: wList });
          setSelectedRow(wList);
        }
      );
    };
    return (
      <AwesomeTableComponent
        {...props}
        {...(ref ? { ref } : {})}
        transformer={(response) => {
          const data = transformer?.(response);
          setTableMetaData({
            currentPageData: data,
            totalDataLength: response?.data?.data?.pagination?.items,
          });
          return data;
        }}
        {...(!isEmpty(props.rowSelection)
          ? {
              rowSelection: {
                ...rowSelection,
                selections: tableSelectionOptions,
                onChange: (selectedRowKeys, selectedRows, info) => {
                  setTableMetaData({ listSelected: selectedRows });
                  rowSelection?.onChange?.(selectedRowKeys, selectedRows, info);
                },
              },
            }
          : {})}
      />
    );
  }
);

export default AwesomeTableSelectable;
