import ChannelSelect from "@components/channel/components/ChannelSelect";
import { CUSTOMER_TYPES } from "@constants/customer";
import { GENDERS } from "@constants/user";
import { Slider } from "antd";
import { DateInput, InputText, Select } from "d-react-components";
import { useTranslation } from "react-i18next";

interface ICustomerFilterForm {
  filterForm: any;
}

const CustomerFilterForm = ({ filterForm }: ICustomerFilterForm) => {
  const classNameField = "col-span-12";
  const formValues = filterForm?.values;
  const formErrors = filterForm?.errors;
  const { t } = useTranslation();

  return (
    <div>
      <div className="grid grid-cols-4 gap-4">
        <ChannelSelect
          value={formValues.channels}
          className={classNameField}
          onChange={(value) => filterForm.setFieldValue("channels", value)}
          multiple
        />
        <DateInput
          isRangePicker
          value={formValues.createdFrom}
          onChange={(value) => filterForm.setFieldValue("from", value)}
          label={t("createdAt")}
          className="col-span-12"
        />

        <div className={classNameField}>
          <label>{t("totalOrderQty")}</label>
          <div className="flex">
            <InputText
              value={formValues?.totalOrderQty?.[0]}
              placeholder={t("from")}
              className="w-full mr-2"
              type="number"
              onChange={(e) =>
                filterForm.setFieldValue("totalOrderQty", [
                  e.target.value,
                  formValues?.totalOrderQty?.[1],
                ])
              }
            />
            <InputText
              value={formValues?.totalOrderQty?.[1]}
              placeholder={t("to")}
              className="w-full"
              type="number"
              onChange={(e) =>
                filterForm.setFieldValue("totalOrderQty", [
                  formValues?.totalOrderQty?.[0],
                  e.target.value,
                ])
              }
            />
          </div>
        </div>
        <div className={classNameField}>
          <label>{t("lifeSpending")}</label>
          <div className="flex">
            <InputText
              value={formValues?.totalOrderValue?.[0]}
              placeholder={t("from")}
              className="w-full mr-2"
              type="number"
              onChange={(e) =>
                filterForm.setFieldValue("totalOrderValue", [
                  e.target.value,
                  formValues?.totalOrderValue?.[1],
                ])
              }
            />
            <InputText
              value={formValues?.totalOrderValue?.[1]}
              placeholder={t("to")}
              className="w-full"
              type="number"
              onChange={(e) =>
                filterForm.setFieldValue("totalOrderValue", [
                  formValues?.totalOrderValue?.[0],
                  e.target.value,
                ])
              }
            />
          </div>
        </div>
        <div className={classNameField}>
          <label>{t("age")}</label>
          <div className="flex">
            <InputText
              value={formValues?.age?.[0]}
              placeholder={t("from")}
              className="w-full mr-2"
              type="number"
              onChange={(e) =>
                filterForm.setFieldValue("age", [
                  e.target.value,
                  formValues?.age?.[1],
                ])
              }
            />
            <InputText
              value={formValues?.age?.[1]}
              placeholder={t("to")}
              className="w-full"
              type="number"
              onChange={(e) =>
                filterForm.setFieldValue("age", [
                  formValues?.age?.[0],
                  e.target.value,
                ])
              }
            />
          </div>
        </div>
        <Select
          label={t("gender")}
          className={classNameField}
          value={formValues?.gender}
          onChange={(value) => {
            filterForm.setFieldValue("gender", value);
          }}
          getLabel={(item) => t(item?.label)}
          dataSource={GENDERS}
          multiple
        />
        <Select
          label={t("customerType")}
          className={classNameField}
          value={formValues?.customerType}
          onChange={(value) => {
            filterForm.setFieldValue("customerType", value);
          }}
          getLabel={(item) => t(item?.label)}
          dataSource={CUSTOMER_TYPES}
          multiple
        />
      </div>
    </div>
  );
};

export default CustomerFilterForm;
