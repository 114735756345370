import { MenuPropsType } from "@constants/menu";
import { API_METHOD } from "@helpers/permissions";
import {
  API_GP_EVENT_LIST,
  API_INVENTORY_CONTROL_EVENT_LIST,
  API_LIST_ALL_ORDER,
  API_SHOPE_ADDRESS_SHIPPING_LIST,
  API_REQUEST_STOCK_LIST,
  API_TRANSFER_STOCK_LIST,
  API_PRODUCTION_PRINTING,
  API_STOCK_ADJUST_LIST,
  API_LIST_INVENTORY,
  API_DELIVERY_STOCK_LIST,
  API_LIST_SUBSCRIPTION,
  API_ISSUED_DISCOUNT_CODE,
  API_AUTHORIZED_DISCOUNT_CODE,
  API_PROMOTION_LIST,
  API_SERIAL_NUMBER_LIST,
  API_MANAGER_CODE_USAGE_REPORT,
  API_MANAGER_CODE_USAGE_LIST,
  API_STAFF_MANAGEMENT_DISCOUNT_LIST,
  API_PRODUCT_IMPORT,
  API_PRODUCT_ALIAS_IMPORT_VALIDATE,
  API_PRODUCT_ALIAS_IMPORT,
  API_PRODUCT_LABEL_IMPORT,
  API_USER_IMPORT,
  API_WAREHOUSE_IMPORT,
  API_CHECK_PRODUCT_BY_SKU,
  API_DEPOSIT_LIST,
  API_RAW_MATERIAL_LIST,
  API_LIST_REFUND,
  API_DELIVERY_TRACKING_LIST,
} from "@network/URL";
import { Path } from "./Path";

export const COMMON_KEY = "COMMON";

export const MAP_V1_MENUS = {
  ORDER: "orders",
  CUSTOMER_MAP: "customer-info-map",
  GP_EVENT: "gp-event",
  INVENTORY_CONTROL_EVENT: "inventory-control-event",
  INVENTORY: "inventory-module",
  STOCK_REQUEST: "stock-request",
  TRANSFER_STOCK: "transfer-stock",
  STOCK_ADJUSTMENT: "stock-adjustment",
  PRODUCTION_PRINTING: "production-printing",
  DELIVERY: "delivery",
  PRODUCTION_COST: "production-cost",
  VEHICLE: "vehicle",
  WAREHOUSE: "v2/warehouse",
  KPI: "kpi",
  MY_ASSETS: "my-assets",
  USERS: "v2/auth/users",
  CUSTOMERS: "customer",
  PRODUCTS: "products",
  PROMOTION: "promotion",
  MANAGE_DISCOUNT_STORE: "manager-discount-store",
  DISCOUNT_CODE_USAGE: "manager-code-usage",
  DISCOUNT_CODE_REPORT: "manager-code-report",
  SUBSCRIPTION_CONTRACT: "subscription-contract",
  CHANNEL: "channel",
  CART_RULES: "cart-rule",
  SERIAL_NUMBER: "serial-number",
  RAW_MATERIAL: "raw-material-list",
  CHECK_SKU: "/check-sku-in-system",
  REFUND_REQUEST: "/refund/list",
  DELIVERY_TRACKING: "/delivery-tracking",

  PRODUCT_IMPORT: "/imports/products",
  PRODUCT_ALIAS_IMPORT: "/imports/alias-sku-product",
  PRODUCT_LABEL_IMPORT: "/imports/label-product",
  WAREHOUSE_IMPORT: "/imports/warehouse",
  USER_IMPORT: "/imports/admin",
  CASH_DEPOSIT: "/cash-deposit",
};

const MAIN_MENU: MenuPropsType = [
  {
    id: "discountAuthOTP",
    label: "discountAuthOTP",
    icon: "/images/icons/password.svg",
    title: "discountAuthOTP",
    forceShow: true,
    className: "user-otp-opener",
    subMenu: [
      {
        id: "myIssuedDiscountOTP",
        path: Path.MY_ISSUSED_DISCOUNT_OTP,
        label: "myIssuedDiscountOTP",
        permission: {
          url: API_ISSUED_DISCOUNT_CODE,
          method: API_METHOD.POST,
        },
      },
      {
        id: "myAuthorizedDiscountOTP",
        path: Path.MY_AUTHORIZED_DISCOUNT_OTP,
        label: "myAuthorizedDiscountOTP",
        permission: {
          url: API_AUTHORIZED_DISCOUNT_CODE,
          method: API_METHOD.POST,
        },
      },
    ],
  },
  {
    id: "channels",
    label: "channels",
    iconName: "group",
    path: Path.CHANNEL,
    title: "channel",
    v1Id: MAP_V1_MENUS.CHANNEL,
    subMenu: [
      {
        id: "channelDetail",
        path: Path.CHANNEL_DETAIL,
        notShow: true,
        label: "channelDetail",
      },
      {
        id: "newChannel",
        path: Path.CHANNEL_CREATE,
        notShow: true,
        label: "newChannel",
      },
    ],
  },
  {
    id: "order",
    label: "order",
    iconName: "store",
    path: Path.ORDER,
    title: "order",
    v1Id: MAP_V1_MENUS.ORDER,
    reloadDocument: true,
    permission: {
      url: API_LIST_ALL_ORDER,
      method: API_METHOD.POST,
    },
    subMenu: [
      {
        id: "orderDetail",
        path: Path.ORDER_DETAIL,
        notShow: true,
        label: "orderDetail",
      },
      {
        id: "newOrder",
        path: Path.NEW_ORDER,
        notShow: true,
        label: "newOrder",
      },
    ],
  },
  {
    id: "cartRules",
    label: "cartRules",
    iconName: "group",
    path: Path.CART_RULE,
    title: "cartRules",
    v1Id: MAP_V1_MENUS.CART_RULES,
    subMenu: [
      {
        id: "cartRuleDetail",
        path: Path.CART_RULE_DETAIL,
        notShow: true,
        label: "cartRuleDetail",
      },
      {
        id: "cartRuleCreate",
        path: Path.CART_RULE_CREATE,
        notShow: true,
        label: "newCartRule",
      },
    ],
  },
  {
    id: "rentalContract",
    label: "subscriptionContract",
    iconName: "store",
    path: Path.SUBSCRIPTION_CONTRACT,
    title: "subscriptionContract",
    v1Id: MAP_V1_MENUS.SUBSCRIPTION_CONTRACT,
    permission: {
      url: API_LIST_SUBSCRIPTION,
      method: API_METHOD.POST,
    },
    subMenu: [
      {
        id: "newSubscriptionContract",
        path: Path.SUBSCRIPTION_CONTRACT_CREATE,
        notShow: true,
        label: "newSubscriptionContract",
      },
      {
        id: "rentalContractDetail",
        path: Path.SUBSCRIPTION_CONTRACT_DETAIL,
        notShow: true,
        label: "rentalContractDetail",
      },
    ],
  },
  {
    id: "callHistory",
    label: "callHistory",
    iconName: "phone",
    path: Path.CALL_HISTORY,
    title: "callHistory",
  },
  {
    id: "customerInfoMapping",
    label: "customerInfoMapping",
    iconName: "schema",
    path: Path.CUSTOMER_INFO_MAP,
    title: "customerInfoMapping",
    v1Id: MAP_V1_MENUS.CUSTOMER_MAP,
    permission: {
      url: API_SHOPE_ADDRESS_SHIPPING_LIST,
      method: API_METHOD.POST,
    },
    subMenu: [
      {
        id: "newDataMapping",
        path: Path.CUSTOMER_INFO_MAP_CREATE,
        notShow: true,
        label: "newDataMapping",
      },
    ],
  },
  {
    id: "inventory",
    label: "inventory",
    iconName: "inventory",
    path: Path.INVENTORY,
    title: "inventory",
    v1Id: MAP_V1_MENUS.INVENTORY,
    permission: {
      url: API_LIST_INVENTORY,
      method: API_METHOD.POST,
    },
  },
  {
    id: "inventoryControlEvent",
    label: "inventoryControlEvent",
    iconName: "insert_invitation",
    path: Path.INVENTORY_CONTROL_EVENT,
    title: "inventoryControlEvent",
    v1Id: MAP_V1_MENUS.INVENTORY_CONTROL_EVENT,
    permission: {
      url: API_INVENTORY_CONTROL_EVENT_LIST,
      method: API_METHOD.POST,
    },
    subMenu: [
      {
        id: "createInventoryEvent",
        path: Path.INVENTORY_CONTROL_EVENT_CREATE,
        label: "createInventoryEvent",
      },
      {
        id: "inventoryEvent",
        path: Path.INVENTORY_CONTROL_EVENT_DETAIL,
        notShow: true,
        label: "inventoryEvent",
      },
    ],
  },
  {
    id: "stockRequest",
    label: "stockRequest",
    iconName: "request_page",
    path: Path.STOCK_REQUEST,
    title: "stockRequest",
    v1Id: MAP_V1_MENUS.STOCK_REQUEST,
    permission: {
      url: API_REQUEST_STOCK_LIST,
      method: API_METHOD.POST,
    },
    subMenu: [
      {
        id: "createStockRequest",
        path: Path.STOCK_REQUEST_CREATE,
        label: "createStockRequest",
      },
      {
        id: "stockRequestDetail",
        path: Path.STOCK_REQUEST_DETAIL,
        notShow: true,
        label: "stockRequestDetail",
      },
    ],
  },
  {
    id: "transferStock",
    label: "transferStock",
    iconName: "move_down",
    path: Path.TRANSFER_STOCK,
    title: "transferStock",
    v1Id: MAP_V1_MENUS.TRANSFER_STOCK,
    permission: {
      url: API_TRANSFER_STOCK_LIST,
      method: API_METHOD.POST,
    },
    subMenu: [
      {
        id: "createTransferStock",
        path: Path.TRANSFER_STOCK_CREATE,
        label: "createTransferStock",
      },
      {
        id: "transferStockDetail",
        path: Path.TRANSFER_STOCK_DETAIL,
        notShow: true,
        label: "transferStockDetail",
      },
    ],
  },
  {
    id: "stockAdjustment",
    label: "stockAdjustment",
    iconName: "adjust",
    path: Path.STOCK_ADJUSTMENT,
    title: "stockAdjustment",
    v1Id: MAP_V1_MENUS.INVENTORY,
    permission: {
      url: API_STOCK_ADJUST_LIST,
      method: API_METHOD.POST,
    },
    subMenu: [
      {
        id: "stockAdjustDetail",
        path: Path.STOCK_ADJUSTMENT_DETAIL,
        notShow: true,
        label: "stockAdjustDetail",
      },
      {
        id: "stockAdjustCreate",
        path: Path.STOCK_ADJUSTMENT_CREATE,
        notShow: true,
        label: "stockAdjustCreate",
      },
    ],
  },
  {
    id: "productionPrinting",
    label: "productionPrinting",
    iconName: "print",
    path: Path.PRODUCTION_PRINTING,
    title: "productionPrinting",
    v1Id: MAP_V1_MENUS.PRODUCTION_PRINTING,
    permission: {
      url: API_PRODUCTION_PRINTING,
      method: API_METHOD.POST,
    },
  },
  {
    id: "deliveryStock",
    label: "deliveryStock",
    iconName: "local_shipping",
    path: Path.DELIVERY_STOCK,
    title: "deliveryStock",
    v1Id: MAP_V1_MENUS.DELIVERY,
    permission: {
      url: API_DELIVERY_STOCK_LIST,
      method: API_METHOD.POST,
    },
    subMenu: [
      {
        id: "deliveryStockDetail",
        path: Path.DELIVERY_STOCK_DETAIL,
        notShow: true,
        label: "deliveryStockDetail",
      },
    ],
  },
  {
    id: "deliverySO",
    label: "deliverySO",
    iconName: "toys",
    path: Path.DELIVERY_SO,
    title: "deliverySO",
    v1Id: MAP_V1_MENUS.DELIVERY,
    permission: {
      url: API_DELIVERY_STOCK_LIST,
      method: API_METHOD.POST,
    },

    subMenu: [
      {
        id: "deliverySODetail",
        path: Path.DELIVERY_SO_DETAIL,
        notShow: true,
        label: "deliverySODetail",
      },
    ],
  },
  {
    id: "costingExpense",
    label: "costingExpense",
    iconName: "local_atm",
    path: Path.PRODUCTION_COST,
    title: "costingExpense",
    v1Id: MAP_V1_MENUS.PRODUCTION_COST,
    // permission: {
    //   url: API_PRODUCTION_COST_TABLE,
    //   method: API_METHOD.GET,
    // },
  },
  {
    id: "vehicle",
    label: "vehicle",
    iconName: "commute",
    path: Path.VEHICLE,
    title: "vehicle",
    v1Id: MAP_V1_MENUS.VEHICLE,
    subMenu: [
      {
        id: "vehicleDetail",
        path: Path.VEHICLE_DETAIL,
        notShow: true,
        label: "vehicleDetail",
      },
      {
        id: "newVehicle",
        path: Path.VEHICLE_CREATE,
        notShow: true,
        label: "newVehicle",
      },
    ],
    // permission: {
    //   url: API_PRODUCTION_COST_TABLE,
    //   method: API_METHOD.GET,
    // },
  },

  {
    id: "warehouse",
    label: "warehouse",
    iconName: "warehouse",
    path: Path.WAREHOUSE,
    title: "warehouse",
    v1Id: MAP_V1_MENUS.WAREHOUSE,
    subMenu: [
      {
        id: "warehouseDetail",
        path: Path.WAREHOUSE_DETAIL,
        notShow: true,
        label: "warehouseDetail",
      },
      {
        id: "newWarehouse",
        path: Path.WAREHOUSE_CREATE,
        notShow: true,
        label: "newWarehouse",
      },
    ],
  },
  {
    id: "users",
    label: "users",
    iconName: "group",
    path: Path.USER,
    title: "user",
    v1Id: MAP_V1_MENUS.USERS,
    subMenu: [
      {
        id: "userDetail",
        path: Path.USER_DETAIL,
        notShow: true,
        label: "userDetail",
      },
      {
        id: "newUser",
        path: Path.USER_CREATE,
        notShow: true,
        label: "newUser",
      },
    ],
  },
  {
    id: "customers",
    label: "customers",
    iconName: "group",
    path: Path.CUSTOMER,
    title: "customer",
    v1Id: MAP_V1_MENUS.CUSTOMERS,
    subMenu: [
      {
        id: "customerDetail",
        path: Path.CUSTOMER_DETAIL,
        notShow: true,
        label: "customerDetail",
      },
      {
        id: "customerCreate",
        path: Path.CUSTOMER_CREATE,
        notShow: true,
        label: "customerCreate",
      },
    ],
  },
  {
    id: "products",
    label: "products",
    iconName: "group",
    path: Path.PRODUCT,
    title: "products",
    v1Id: MAP_V1_MENUS.PRODUCTS,
    subMenu: [
      {
        id: "productDetail",
        path: Path.PRODUCT_DETAIL,
        notShow: true,
        label: "productDetail",
      },
      {
        id: "productCreate",
        path: Path.PRODUCT_CREATE,
        notShow: true,
        label: "productCreate",
      },
    ],
  },
  {
    id: "kpi",
    label: "KPI",
    iconName: "query_stats",
    path: Path.KPI,
    title: "KPI",
    v1Id: MAP_V1_MENUS.KPI,
  },
  {
    id: "myAssets",
    label: "myAssets",
    iconName: "web_asset",
    path: Path.MY_ASSETS,
    title: "myAssets",
    v1Id: MAP_V1_MENUS.MY_ASSETS,
    redirectTo: Path.MY_ASSETS_CHANNEL,
    subMenu: [
      {
        id: "myAssetsWarehouse",
        path: Path.MY_ASSETS_WAREHOUSE,
        notShow: true,
        label: "warehouse",
      },
      {
        id: "myAssetsChannel",
        path: Path.MY_ASSETS_CHANNEL,
        notShow: true,
        label: "channel",
      },
      {
        id: "myAssetsRole",
        path: Path.MY_ASSETS_ROLE,
        notShow: true,
        label: "role",
      },
      {
        id: "newRequest",
        path: Path.MY_REQUEST_NEW,
        notShow: true,
        label: "newRequest",
      },
      {
        id: "detailRequest",
        path: Path.MY_REQUEST_DETAIL,
        notShow: true,
        label: "requestDetail",
      },
      {
        id: "myAssetsRequest",
        path: Path.MY_REQUEST,
        notShow: true,
        label: "myRequest",
      },
      {
        id: "myAssetsRequestApproval",
        path: Path.REQUEST_APPROVAL,
        notShow: true,
        label: "changeRequestApproval",
      },
      {
        id: "myAssetsRequestApprovalDetail",
        path: Path.REQUEST_APPROVAL_DETAIL,
        notShow: true,
        label: "requestApprovalDetail",
      },
    ],
  },
  {
    id: "discount-staff-management",
    label: "staffDiscountManagement",
    iconName: "discount",
    path: Path.DISCOUNT_STAFF_MANAGEMENT,
    title: "staffDiscountManagement",
    v1Id: MAP_V1_MENUS.MANAGE_DISCOUNT_STORE,
    permission: {
      url: API_STAFF_MANAGEMENT_DISCOUNT_LIST,
      method: API_METHOD.POST,
    },
  },
  {
    id: "managerCodeUsage",
    label: "managerCodeUsage",
    iconName: "discount",
    path: Path.DISCOUNT_CODE_USAGE,
    title: "managerCodeUsage",
    v1Id: MAP_V1_MENUS.DISCOUNT_CODE_USAGE,
    permission: {
      url: API_MANAGER_CODE_USAGE_LIST,
      method: API_METHOD.POST,
    },
  },
  {
    id: "managerCodeUsageReport",
    label: "managerCodeUsageReport",
    iconName: "discount",
    path: Path.DISCOUNT_CODE_USAGE_REPORT,
    title: "managerCodeUsage",
    v1Id: MAP_V1_MENUS.DISCOUNT_CODE_REPORT,
    permission: {
      url: API_MANAGER_CODE_USAGE_REPORT,
      method: API_METHOD.POST,
    },
  },
  {
    id: "promotion",
    label: "promotionPricing",
    iconName: "campaign",
    path: Path.PROMOTION,
    title: "promotionPricing",
    v1Id: MAP_V1_MENUS.PROMOTION,
    permission: {
      url: API_PROMOTION_LIST,
      method: API_METHOD.POST,
    },
    subMenu: [
      {
        id: "promotionDetail",
        path: Path.PROMOTION_DETAIL,
        notShow: true,
        label: "promotionDetail",
      },
      {
        id: "promotionCreate",
        path: Path.PROMOTION_CREATE,
        notShow: true,
        label: "promotionCreate",
      },
      {
        id: "promotionCreateDup",
        path: Path.PROMOTION_CREATE_DUPLICATE,
        notShow: true,
        label: "promotionCreate",
      },
    ],
  },
  {
    id: "cash-deposit",
    label: "cashDeposit",
    iconName: "campaign",
    path: Path.CASH_DEPOSIT,
    title: "cashDeposit",
    v1Id: MAP_V1_MENUS.CASH_DEPOSIT,
    permission: {
      url: API_DEPOSIT_LIST,
      method: API_METHOD.POST,
    },
    subMenu: [
      {
        id: "cashDepositDetail",
        path: Path.CASH_DEPOSIT_DETAIL,
        notShow: true,
        label: "cashDepositDetail",
      },
      {
        id: "cashDepositCreate",
        path: Path.CASH_DEPOSIT_CREATE,
        notShow: true,
        label: "cashDepositCreate",
      },
    ],
  },
  {
    id: "serialNumber",
    label: "serialNumber",
    iconName: "campaign",
    path: Path.SERIAL_NUMBER,
    title: "serialNumber",
    v1Id: MAP_V1_MENUS.SERIAL_NUMBER,
    permission: {
      url: API_SERIAL_NUMBER_LIST,
      method: API_METHOD.POST,
    },
    subMenu: [
      {
        id: "serialNumberDetail",
        path: Path.SERIAL_NUMBER_DETAIL,
        notShow: true,
        label: "serialNumberDetail",
      },
      {
        id: "serialNumberCreate",
        path: Path.SERIAL_NUMBER_CREATE,
        notShow: true,
        label: "serialNumberCreate",
      },
    ],
  },
  {
    id: "checkSKU",
    label: "checkSKU",
    iconName: "check",
    path: Path.CHECK_SKU,
    title: "checkSKU",
    v1Id: MAP_V1_MENUS.CHECK_SKU,
    permission: {
      url: API_CHECK_PRODUCT_BY_SKU,
      method: API_METHOD.POST,
    },
  },
  {
    id: "importProduct",
    label: "importProduct",
    iconName: "campaign",
    title: "importProduct",
    // forceShow: true,
    v1Id: MAP_V1_MENUS.PRODUCT_IMPORT,
    path: Path.PRODUCT_IMPORT,
    permission: {
      url: API_PRODUCT_IMPORT,
      method: API_METHOD.POST,
    },
  },
  {
    id: "importProductAlias",
    label: "importProductAlias",
    iconName: "campaign",
    title: "importProductAlias",
    // forceShow: true,
    v1Id: MAP_V1_MENUS.PRODUCT_ALIAS_IMPORT,
    path: Path.PRODUCT_ALIAS_IMPORT,
    permission: {
      url: API_PRODUCT_ALIAS_IMPORT,
      method: API_METHOD.POST,
    },
  },
  {
    id: "importLabelProducts",
    label: "importLabelProducts",
    iconName: "campaign",
    title: "importLabelProducts",
    // forceShow: true,
    v1Id: MAP_V1_MENUS.PRODUCT_LABEL_IMPORT,
    path: Path.PRODUCT_LABEL_IMPORT,
    permission: {
      url: API_PRODUCT_LABEL_IMPORT,
      method: API_METHOD.POST,
    },
  },
  {
    id: "importUser",
    label: "importUser",
    iconName: "campaign",
    title: "importUser",
    // forceShow: true,
    v1Id: MAP_V1_MENUS.USER_IMPORT,
    path: Path.USER_IMPORT,
    permission: {
      url: API_USER_IMPORT,
      method: API_METHOD.POST,
    },
  },
  {
    id: "importWarehouse",
    label: "importWarehouse",
    iconName: "campaign",
    title: "importWarehouse",
    // forceShow: true,
    path: Path.WAREHOUSE_IMPORT,
    v1Id: MAP_V1_MENUS.WAREHOUSE_IMPORT,
    permission: {
      url: API_WAREHOUSE_IMPORT,
      method: API_METHOD.POST,
    },
  },

  {
    id: "rawMaterial",
    label: "rawMaterial",
    iconName: "campaign",
    path: Path.RAW_MATERIAL,
    title: "rawMaterial",
    v1Id: MAP_V1_MENUS.RAW_MATERIAL,
    forceShow: true,
    permission: {
      url: API_RAW_MATERIAL_LIST,
      method: API_METHOD.POST,
    },
    subMenu: [
      {
        id: "rawMateriaDetail",
        path: Path.RAW_MATERIAL_DETAIL,
        notShow: true,
        label: "rawMateriaDetail",
      },
      {
        id: "rawMateriaDetail",
        path: Path.RAW_MATERIAL_FORECAST,
        label: "rawMaterialForecast",
      },
    ],
  },

  {
    id: "refundRequest",
    label: "refundRequest",
    iconName: "check",
    path: Path.REFUND_REQUEST,
    title: "refundRequest",
    forceShow: true,
    v1Id: MAP_V1_MENUS.REFUND_REQUEST,
    permission: {
      url: API_LIST_REFUND,
      method: API_METHOD.POST,
    },
  },{
    id: "deliveryTracking",
    label: "deliveryTracking",
    iconName: "check",
    path: Path.DELIVERY_TRACKING,
    title: "deliveryTracking",
    forceShow: true,
    v1Id: MAP_V1_MENUS.DELIVERY_TRACKING,
    permission: {
      url: API_DELIVERY_TRACKING_LIST,
      method: API_METHOD.POST,
    },
  },
  {
    id: "gpEvent",
    label: "gpEvent",
    iconName: "event",
    path: Path.GP_EVENT,
    title: "gpEvent",
    forceShow: true,
    v1Id: MAP_V1_MENUS.GP_EVENT,
    permission: {
      url: API_GP_EVENT_LIST,
      method: API_METHOD.GET,
    },
    subMenu: [
      {
        id: "createGpEvent",
        path: Path.GP_EVENT_CREATE,
        label: "newCreateGpEvent",
      },
      {
        id: "gpEventDetail",
        path: Path.GP_EVENT_DETAIL,
        notShow: true,
        label: "gpEventDetail",
      },
    ],
  },
];

export default MAIN_MENU;
