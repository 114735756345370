import * as Yup from "yup";

export const POSITION_LABELS = [
  { id: "sales_admin", label: "salesAdmin" },
  { id: "sales_supervisor", label: "salesSupervisor" },
  { id: "finance", label: "finance" },
  { id: "billing", label: "billing" },
  { id: "delivery", label: "delivery" },
  { id: "marketing", label: "marketing" },
  { id: "inventory", label: "inventory" },
  { id: "manager", label: "manager" },
  { id: "administrator", label: "administrator" },
  { id: "cs", label: "cs" },
  { id: "none", label: "none" },
];

export const UserSchema = Yup.object().shape({
  companyId: Yup.string().required("Company required!"),
  fullName: Yup.string().required("Full name required!"),
  nickName: Yup.string().required("Nickname required!"),
  password: Yup.string().required("Password required!"),
  // secretCode: Yup.string().required("Secret code required!"),
  storeType: Yup.string().required("Store type required!"),
  block: Yup.boolean().required("Store type required!"),
  username: Yup.string().required("Username required!"),
});

export const USER_KEYS: any[] = [
  { keyClient: "blockUser", keyServer: "block" },
  { keyClient: "companyId", keyServer: "employee_id" },
  { keyClient: "deliveryStatus", keyServer: "delivery_status" },
  { keyClient: "fullName", keyServer: "fullname" },
  { keyClient: "nickName", keyServer: "name" },
  { keyClient: "orderStatus", keyServer: "manager_order_status" },
  { keyClient: "password", keyServer: "password" },
  { keyClient: "promotionStatus", keyServer: "promotion_status" },
  { keyClient: "secretCode", keyServer: "authentication_code" },
  { keyClient: "storeType", keyServer: "is_store" },
  { keyClient: "username", keyServer: "username" },
  { keyClient: "authStatus", keyServer: "authentication_status" },
  { keyClient: "avatar", keyServer: "avatar" },
  { keyClient: "callCenterId", keyServer: "call_center_id" },
  { keyClient: "callCenterEmail", keyServer: "call_center_email" },
  { keyClient: "hasCallCenter", keyServer: "has_call_center" },
  { keyClient: "positionLabel", keyServer: "position_label" },
];

export const GENDERS = [
  { id: "male", label: "male" },
  { id: "female", label: "female" },
  { id: "other", label: "other" },
];

export const IMPORT_USER_STEPS = [
  { id: "selectImportMethod", title: "selectImportMethod", desc: "importNewUserDesc" },
  { id: "selectCSVFile", title: "selectCSVFile" },
  { id: "validation", title: "fileValidationAndImport" },
  { id: "import", title: "importResult" },
];


export const IMPORT_USER_METHODS = [
  { id: "update", label: "updateExistingUsers" },
  { id: "create", label: "addNewUsers" },
];