import axios from "axios";
import {
  AccessTokenInterceptor,
  AddCallCenterToken,
  AddTokenToURL,
  LanguageInterceptor,
  PermissionInterceptor,
  UnauthorizeInterceptor,
} from "./Interceptors";

const getInstance = () => {
  const instance = axios.create({
    // baseURL: "https://cors-anywhere.herokuapp.com/" + window.domain,
    baseURL: process.env["REACT_APP_API_HOST_URL"],
    timeout: 300000,
    withCredentials: true,
  });

  instance.interceptors.response.use(
    UnauthorizeInterceptor.onFullfilled,
    UnauthorizeInterceptor.onRejected
  );

  instance.interceptors.request.use(
    AccessTokenInterceptor.addAccessToken,
    AccessTokenInterceptor.onRejected
  );

  instance.interceptors.request.use(AddTokenToURL.addAccessToken);

  instance.interceptors.request.use(PermissionInterceptor.checkPermission);

  instance.interceptors.request.use(LanguageInterceptor.addLanguage);

  return instance;
};

const getInstanceCallCenter = (baseURL: string) => {
  const instance = axios.create({
    baseURL,
    timeout: 300000,
  });

  instance.interceptors.response.use(
    UnauthorizeInterceptor.onFullfilled,
    UnauthorizeInterceptor.onRejected
  );

  instance.interceptors.request.use(
    AddCallCenterToken.addAccessToken,
    AddCallCenterToken.onRejected
  );

  return instance;
};

export const API = getInstance();
export const APICallCenter = getInstanceCallCenter(
  process.env["REACT_APP_API_HOST_URL"] as string
);
export const APICS = getInstanceCallCenter("https://csinfinityx.com");
export const APICRM = getInstanceCallCenter("https://crm.lotusbeddinggroup.com");