// react
import React from "react";
// application
import { useTranslation } from "react-i18next";
import Indicator from "./Indicator";
import { useLocalStorage } from "react-use";
import { ADMIN_AUTH_KEY, ADMIN_TOKEN_KEY } from "@constants";
import { IAdmin } from "@interfaces/admin";
import { V1_LOGOUT_URL } from "@constants/common";

function ButtonSetting() {
  const { t } = useTranslation("common");
  const [admin, setAdmin, remove] = useLocalStorage<IAdmin>(ADMIN_AUTH_KEY);
  const [adminToken, setAdminToken, removeToken] =
    useLocalStorage<IAdmin>(ADMIN_TOKEN_KEY);

  const onLogout = () => {
    remove();
    if (process.env.REACT_APP_ENV === "development") {
      removeToken();
      setTimeout(() => {
        window.location.reload();
      }, 200);
    } else {
      setTimeout(() => {
        window.location.replace(V1_LOGOUT_URL);
      }, 100);
    }
  };

  const buttonSetting = <i className="material-icons">settings</i>;

  const dropDownSetting = (
    <div className="app-layout__dropdown-setting">
      <button
        className="btn text-sm whitespace-nowrap"
        type="button"
        onClick={onLogout}
      >
        {t(`logout`)}
      </button>
    </div>
  );

  return <Indicator icon={buttonSetting} dropdown={dropDownSetting} />;
}

export default ButtonSetting;
