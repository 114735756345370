import ChainSelect from "@components/chain/components/ChainSelect";
import Drawer from "@components/shared/Drawer";
import { GP_EVENT_STATUSES, GP_EVENT_VISIBILITIES } from "@constants/gp-event";
import { DateInput, Select } from "d-react-components";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

function GPEventFilterModal({ open, onClose, onFilter, defaultValue }: any) {
  const { t } = useTranslation();

  const filterForm = useFormik({
    initialValues: defaultValue,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      onFilter && onFilter(values);
      onClose();
    },
  });
  const formValue = filterForm.values;
  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("filter")}
      onSave={() => filterForm.handleSubmit()}
    >
      <div className="row">
        <ChainSelect
          value={formValue.chains}
          className="mt-3 col-12"
          onChange={(value) => filterForm.setFieldValue("chains", value)}
          multiple
        />
        <Select
          dataSource={GP_EVENT_STATUSES}
          value={formValue.status}
          onChange={(value) => filterForm.setFieldValue("status", value)}
          label={t("status")}
          className="mt-3 col-12"
          getLabel={(item) => t(item.label)}
        />
        <Select
          dataSource={GP_EVENT_VISIBILITIES}
          value={formValue.visibility}
          onChange={(value) => filterForm.setFieldValue("visibility", value)}
          label={t("visibility")}
          className="mt-3 col-12"
          getLabel={(item) => t(item.label)}
        />
        <DateInput
          value={formValue.from}
          onChange={(value) => filterForm.setFieldValue("from", value)}
          label={t("from")}
          className="mt-3 col-6"
        />
        <DateInput
          value={formValue.to}
          onChange={(value) => filterForm.setFieldValue("to", value)}
          label={t("to")}
          className="mt-3 col-6"
        />
      </div>
    </Drawer>
  );
}

export default GPEventFilterModal;
