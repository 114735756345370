import { Select } from "d-react-components";
import { useTranslation } from "react-i18next";

const SelectSpecificAction = ({
  actions,
  handleSelectAction,
  cartRuleActions,
  className,
  disabled
}: any) => {
  const { t } = useTranslation();
  return (
    <Select
      label={t("specificAction")}
      value={actions?.specific}
      placeholder={t("pleaseSelectSpecificAction")}
      onChange={handleSelectAction}
      dataSource={cartRuleActions}
      getLabel={(item) => t(item.label)}
      className={className}
      disabled={disabled}
    />
  );
};

export default SelectSpecificAction;
