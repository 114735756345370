import FilterDropdown, {
  FilterDropdownProps,
} from "@common/dropdown/FilterDropdown";
import { IStore } from "@interfaces/store";
import { listUserStore, listUserStoreNoPaging } from "@network/api/store";
import { filter, includes, map } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface IStoreDropdownSelect {
  value: any[];
  className?: any;
  channelIds?: string[];
  selectProps?: FilterDropdownProps["selectProps"];
  onChange: (values: any[]) => void;
}

const StoreDropdownSelect = ({
  value = [],
  className,
  channelIds = [],
  selectProps,
  onChange,
}: IStoreDropdownSelect) => {
  const [storeList, setStoreList] = useState<IStore[]>([]);

  const onChangeStore = (values: string[]) => {
    const storeSelect = filter(storeList, (item) => includes(values, item.id));
    const storeSelectMapped = map(storeSelect, (item) => ({
      id: item.id,
      name: item.name,
    }));

    onChange(storeSelectMapped);
  };

  useEffect(() => {
    loadStoreList();
  }, [JSON.stringify(channelIds)]);

  const loadStoreList = () => {
    listUserStoreNoPaging({
      channelid: channelIds,
    }).then((res) => {
      const storeList = res?.data?.data?.stores ?? [];
      setStoreList(storeList);
    });
  };

  const { t } = useTranslation();
  return (
    <FilterDropdown
      selectProps={{
        placeholder: t("search"),
        mode: "multiple",
        getLabel: (item) => item?.name ?? "N/A",
        onChange: onChangeStore,
        className: "mb-3",
        tagTitle: t("store"),
        getValue: (item) => item.id,
        dataSource: storeList ?? [],
        multiple: true,
        value: map(value, (item) => item.id),
        allowCreateNew: false,

        // fetchFn: listUserStore as any,
        // dataKey: "stores",
        // customQuery: () => ({
        //   channelid: channelIds,
        // }),
        ...selectProps,
      }}
      className={className}
      // type="infinity"
      type="normal"
    />
  );
};

export default StoreDropdownSelect;
