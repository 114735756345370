import AppContext from "@helpers/context";
import {
  IRowsKey,
  StringUtils,
  TimeUtils,
  ViewRowInterchange,
} from "d-react-components";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

const RawMaterialDetailInfo = () => {
  const { rawMaterialDetail } = useContext(AppContext);
  const { title, code } = rawMaterialDetail ?? {};
  const { t } = useTranslation();

  const DETAILS_KEY = useMemo<IRowsKey<any>[]>(
    () => [
      {
        id: "sku",
        label: t("sku"),
      },
      {
        id: "categories",
        label: t("category"),
        renderContent: ({ data, item }) => {
          return data?.name ?? "N/A";
        },
      },
      {
        id: "supplier",
        label: t("supplier"),
        renderContent: ({ data, item }) => {
          return data?.name ?? "N/A";
        },
      },

      {
        id: "price",
        label: t("price"),
        renderContent: ({ data, item }) => StringUtils.moneyThaiFormat(data),
      },
      {
        id: "created",
        label: t("createdAt"),
        renderContent: ({ data, item }) =>
          data ? TimeUtils.toDateTime(data) : "N/A",
      },
    ],
    [t]
  );

  return (
    <div>
      <div className="border p-4 flex-column flex-center pt-5">
        <img
          src={rawMaterialDetail?.media?.[0] ?? "/images/img-default.png"}
          className="image-square-medium rounded-1"
        />
        <h4 className="mt-3 text-center text-primary">{title}</h4>
      </div>
      <div className="border p-3">
        <ViewRowInterchange
          className="w-100"
          dataSource={rawMaterialDetail}
          keyList={DETAILS_KEY as any}
          variant="border"
          classNameLabel="text-hint"
          classNameContent="text-end"
        />
      </div>
    </div>
  );
};

export default RawMaterialDetailInfo;
