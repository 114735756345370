import ProductName from "@components/product/components/ProductName";
import QuantityInputField from "@components/product/components/QuantityInputField";
import {
  AwesomeTableComponent,
  Icon,
  Modal,
  Notifications,
  StringUtils,
  Drawer,
  Button,
} from "d-react-components";
import { reduce } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const BundleSelectItem = ({ bundleItem, index, onChangeQuantity }: any) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: () => t("itemName"),
      dataIndex: "product",
      ellipsis: true,
      render: (product: any) => <ProductName item={{ product }} />,
    },

    {
      title: t("regularPrice"),
      dataIndex: "regular_price",
      ellipsis: true,
      render: (regularPrice: any) => StringUtils.moneyThaiFormat(regularPrice),
    },

    {
      title: t("qnty"),
      dataIndex: "quantity",
      ellipsis: true,
      render: (quantity: number) => quantity,
    },
  ];
  return (
    <div className="bundleSelectItemContainer">
      <div className="flex mb-2">
        <div className="flex-1">{bundleItem?.name ?? ""} </div>
        <div className="flex-1 flex justify-content-end w-[200px]">
          <QuantityInputField
            quantity={bundleItem?.quantity ?? 0}
            onChangeQuantity={onChangeQuantity}
          />
        </div>
      </div>

      <AwesomeTableComponent
        source={() => Promise.resolve(bundleItem?.product ?? [])}
        transformer={(response) => response}
        columns={columns}
        isScroll={false}
        isPagination={false}
        className="mb-3"
      />
    </div>
  );
};

const ModalFreeGiftSelect = ({
  open,
  onClose,
  onSaveFreeGift,
  bundle,
}: any) => {
  const [bundleLists, setBundleList] = useState(bundle?.list_bundle ?? []);
  const { t } = useTranslation();

  const onBundleChangeQuantity = (quantity: string, bundleItem: any) => {
    if (parseInt(quantity) < 0) {
      return;
    }
    const bundlesResult = bundleLists?.map((item: any) => {
      if (item.id === bundleItem.id) {
        return {
          ...item,
          quantity: parseInt(quantity),
        };
      }

      return item;
    });
    const quantitySum = getQuantitySum(bundlesResult);
    if (quantitySum > bundle.quantity) {
      Notifications.showError(t("notification:yourBundleQuantityCanNotGreaterThan"));
      return;
    }
    setBundleList(bundlesResult);
  };

  const renderContentSelect = () => {
    return bundleLists?.map((bundleItem: any, index: number) => {
      return (
        <BundleSelectItem
          bundleItem={bundleItem}
          index={index}
          onChangeQuantity={(quantity: string) =>
            onBundleChangeQuantity(quantity, bundleItem)
          }
        />
      );
    });
  };
  return (
    <Drawer
      title={t("freeGift")}
      open={open}
      onClose={onClose}
      size="auto"
      width={"600px"}
      destroyOnClose
    >
      <div className="flex mb-2">
        <div className="">{`${t("freeQuantity")}: ${bundle.quantity}`}</div>
        <div className="ml-3">
          {`${t("quantityLeft")}: ${
            bundle.quantity - getQuantitySum(bundleLists)
          }`}
        </div>
      </div>
      {renderContentSelect()}
      <div className="position-absolute bottom-0 w-100 end-0 start-0 flex-center-y justify-content-between py-3 px-3 bg-white">
        <Button
          onClick={() => {
            onClose && onClose({});
          }}
          variant="outline"
        >
          {t("cancel")}
        </Button>
        <Button
          onClick={() => {
            onSaveFreeGift(bundleLists);
          }}
        >
          {t("save")}
        </Button>
      </div>
    </Drawer>
  );
};

const getQuantitySum = (bundleList: any) => {
  return reduce(bundleList, (sum, item) => sum + (item.quantity ?? 0), 0);
};

const FreeGiftSelect = ({ freeGift, onSaveSelectGift }: any) => {
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  //   const [giftSelected, setGiftSelected] = useState(null);

  const bundle = freeGift?.action?.bundle ?? {};
  const bundleLists = bundle?.list_bundle ?? [];

  if (!bundleLists || bundleLists.length === 1 || bundleLists.length === 0) {
    return <div />;
  }

  const getSelectContent = () => {
    const quantitySum = getQuantitySum(bundle?.list_bundle ?? []);
    if (quantitySum === bundle.quantity) {
      return t("changeFreeGift");
    }

    return t("selectFreeGift");
  };

  const onSaveFreeGift = async (bundleListsResult: any) => {
    const quantitySum = getQuantitySum(bundleListsResult);
    if (quantitySum < bundle.quantity) {
      Notifications.showError(t("notification:pleaseSelectEnoughBundleQuantity"));
      return;
    }
    onSaveSelectGift(bundleListsResult);
    setOpenModal(false);
  };
  return (
    <div style={{ width: "100%" }}>
      <div
        className="flex p-2 text-primary border-dashed border-primary items-center justify-content-center cursor-pointer"
        onClick={() => setOpenModal(true)}
      >
        <Icon name="redeem" className="text-primary mr-2" />
        <div className="text-primary">{getSelectContent()}</div>
      </div>
      {openModal && (
        <ModalFreeGiftSelect
          open={openModal}
          onClose={() => setOpenModal(false)}
          bundle={bundle}
          onSaveFreeGift={onSaveFreeGift}
        />
      )}
    </div>
  );
};

export default FreeGiftSelect;
