import {
  AwesomeTableComponent,
  Notifications,
  Progress,
  TimeUtils,
} from "d-react-components";
import * as Yup from "yup";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useRef, useState } from "react";
import WarehouseAPI from "@network/api/warehouse";
import { useTranslation } from "react-i18next";
import Drawer from "@components/shared/Drawer";
import CurrencyFormat from "react-currency-format";
import UserAvatarName from "@components/shared/UserAvatarName";

function WarehouseDetailUserKPIDetailHistory({
  open,
  onClose,
  warehouseId,
  brandId,
}: any) {
  const { t } = useTranslation();
  const tableRef = useRef<any>(null);

  const source = async ({ pageSize, pageIndex }: any) => {
    return WarehouseAPI.kpiWarehouseDetailHistory({
      warehouse_id: warehouseId,
      brand_id: brandId,
      limit: pageSize,
      page: pageIndex,
    });
  };

    const columns = useMemo(
      () => [
        {
          title: t("updatedBy"),
          dataIndex: "admin",
          ellipsis: true,
          render: (by: any) => <UserAvatarName user={by} />,
        },
        {
          title: t("targetKPI"),
          dataIndex: "kpi_target",
          render: (data: any, item: any) =>
            data ? (
              <CurrencyFormat
                value={data || 0}
                displayType="text"
                thousandSeparator={true}
              />
            ) : (
              ""
            ),
        },
        {
          title: t("updatedAt"),
          dataIndex: "updated",
          render: (data) => {
            return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
          },
        },
      ],
      [t]
    );

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("brandKPIHistory")}
      onSave={() => onClose()}
      size="auto"
      width={800}
    >
      <AwesomeTableComponent
        source={source}
        ref={(ref) => {
          tableRef.current = ref;
        }}
        transformer={(response) => {
          return response?.data?.data?.history ?? []
        }}
        columns={columns}
      />
    </Drawer>
  );
}

export default WarehouseDetailUserKPIDetailHistory;
