import InputTextForm from "@common/input/InputTextForm";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import RichTextEditor from "react-rte";

interface IProductInput {
  productForm: any;
  className?: any;
}

const ProductInputName = ({ productForm, className }: IProductInput) => {
  const { description } = productForm?.values;
  const { t } = useTranslation();

  const [descriptionHtml, setDescriptionHtml] = useState(
    RichTextEditor.createValueFromString(description, "html")
  );
  const isOnchange = useRef(false);

  useEffect(() => {
    if (!isOnchange.current) {
      setDescriptionHtml(
        RichTextEditor.createValueFromString(description, "html")
      );
    }
  }, [description]);

  return (
    <div className={className}>
      <InputTextForm
        label={t("itemName")}
        keyData="name"
        form={productForm}
        required
      />
      <label className="mt-3">{`${t("description")} (${t("optional")})`}</label>
      <RichTextEditor
        value={descriptionHtml}
        onChange={(value: any) => {
          isOnchange.current = true;
          setDescriptionHtml(value);
          productForm.setFieldValue("description", value.toString("html"));
        }}
        className="rich-text__editor"
      />
    </div>
  );
};

export default ProductInputName;
