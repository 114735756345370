/* eslint-disable jsx-a11y/alt-text */
import { Button, DialogManager, Icon } from "d-react-components";
import { filter, forEach, isEmpty, map } from "lodash";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import Carousel, { Modal, ModalGateway } from "react-images";

interface IProductInput {
  productForm: any;
  className?: any;
}

const ProductInputGallery = ({ productForm, className }: IProductInput) => {
  const { imageurl, imageToUpload = [] } = productForm?.values;
  const { t } = useTranslation();

  const [viewImageModal, setViewImageModal] = useState(false);
  const [imageIndex, setImageIndex] = useState<number>();
  const productMediaAll = [...imageToUpload, ...imageurl];
  const onDrop = (files: any) => {
    const fileResults = imageToUpload;

    forEach(files, (file, index: number) => {
      const reader = new FileReader();
      const url = reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        fileResults.push({
          imageData: reader.result,
          fileData: file,
        });
        if (index === files.length - 1) {
          productForm.setFieldValue("imageToUpload", fileResults);
        }
      };
    });
  };

  const confirmDeleteMedia = (media: any) => {
    return DialogManager.showConfirm(
      t("confirm"),
      t("areYouSureDeleteMedia"),
      () => onClickOkDeleteMedia(media)
    );
  };

  const onClickOkDeleteMedia = (media: any) => {
    if (media?.imageData) {
      const clone = filter(
        imageToUpload,
        (item) => item?.imageData !== media?.imageData
      );
      productForm.setFieldValue("imageToUpload", clone);
      return;
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const renderEmptyDragDropField = () => {
    return (
      <div
        className="flex-column flex-center w-100 p-3 mt-3 border-dashed"
        {...getRootProps()}
      >
        <img src="/images/insert-file.svg" className="image-reg-medium" />
        <input {...getInputProps()} />
        <Button className="my-3">{t("addFiles")}</Button>
        <small>{t("orDragDropHere")}</small>
      </div>
    );
  };

  const getSourceMedia = (media: any) => {
    if (media?.imageData) {
      return media.imageData;
    }

    if (!isEmpty(media)) {
      return media;
    }

    return "/images/image-default.png";
  };

  const renderDragDropWithMedias = () => {
    return (
      <>
        <div className="d-flex flex-wrap border p-3">
          {map(productMediaAll, (media, index: number) => (
            <div className="mr-3 mt-3">
              <div className="position-relative width-fit-content">
                <img
                  src={getSourceMedia(media)}
                  className="image-reg-x-large"
                  onClick={() => {
                    setImageIndex(index as any);
                    setViewImageModal(true);
                  }}
                />
                <div
                  onClick={() => confirmDeleteMedia(media)}
                  className="position-absolute bg-danger p-1"
                  style={{ right: 0, top: 0 }}
                >
                  <Icon name="delete" className="text-white" />
                </div>
              </div>
            </div>
          ))}
          <div className="mr-3 mt-3">
            <div
              className="image-reg-x-large border-dashed flex-center flex-column p-3"
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <Button variant="trans" className="text-primary">
                {t("addFiles")}
              </Button>
              <small>{t("orDragDropHere")}</small>
            </div>
          </div>
        </div>
        <ModalGateway>
          {viewImageModal ? (
            <Modal onClose={() => setViewImageModal(false)}>
              <Carousel
                currentIndex={imageIndex}
                views={
                  map(productMediaAll, (media) => {
                    return {
                      src: media.imageData ? media.imageData : media,
                    };
                  }) as any
                }
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </>
    );
  };
  return (
    <div className={className}>
      {productMediaAll.length === 0
        ? renderEmptyDragDropField()
        : renderDragDropWithMedias()}
    </div>
  );
};

export default ProductInputGallery;
