import { API } from "@network";
import {
  API_CANCEL_REQUEST_STOCK,
  API_COMMENT_REQUEST_STOCK,
  API_CONFIRM_REQUEST_STOCK,
  API_REQUEST_STOCK_ACTIVITIES,
  API_REQUEST_STOCK_CHECK_STOCK,
  API_REQUEST_STOCK_CREATE,
  API_REQUEST_STOCK_DETAIL,
  API_REQUEST_STOCK_EDIT,
  API_REQUEST_STOCK_EDIT_PRODUCT,
  API_REQUEST_STOCK_LIST,
  API_REQUEST_STOCK_SUMMARY,
  API_REQUEST_STOCK_UPLOAD,
} from "@network/URL";

export const createStockRequest = (body: any) => {
  return API.post(API_REQUEST_STOCK_CREATE, body);
};

export const editStockRequest = (body: any) => {
  return API.post(API_REQUEST_STOCK_EDIT, body);
};

export const editProductStockRequest = (id: string, products: any[]) => {
  return API.post(API_REQUEST_STOCK_EDIT_PRODUCT, { id, products });
};

export const getStockRequestList = (body: any, paging: any) => {
  return API.post(
    `${API_REQUEST_STOCK_LIST}?page=${paging.pageIndex}&per_page=${paging.pageSize}`,
    body
  );
};

export const stockRequestDetail = (id: string) => {
  return API.post(API_REQUEST_STOCK_DETAIL, { id });
};

export const getStockRequestSummary = () => {
  return API.post(API_REQUEST_STOCK_SUMMARY);
};

export const uploadDocumentStockRequest = (body: any) => {
  return API.post(API_REQUEST_STOCK_UPLOAD, body);
};

export const confirmStockRequest = (body: any) => {
  return API.post(API_CONFIRM_REQUEST_STOCK, body);
};

export const cancelStockRequest = (body: any) => {
  return API.post(API_CANCEL_REQUEST_STOCK, body);
};

export const addCommentStockRequest = (body: any) => {
  return API.post(API_COMMENT_REQUEST_STOCK, body);
};

export const getStockRequestActivity = (body: any, currentPage: number) => {
  return API.post(`${API_REQUEST_STOCK_ACTIVITIES}?page=${currentPage}`, body);
};

export const checkStockRequestProducts = (
  eventInventoryId: string,
  requestId?: string
) => {
  return API.post(API_REQUEST_STOCK_CHECK_STOCK, {
    request_stock_id: requestId,
    stock_inventory_control_id: eventInventoryId,
  });
};
