import { Path } from "@components/layout/Path";
import { CHANNEL_GENERAL_KEYS } from "@constants/channel";
import AppContext from "@helpers/context";
import ChannelAPI from "@network/api/channel";
import {
  Header,
  Notifications,
  ObjectUtils,
  Progress,
  TabBar,
} from "d-react-components";
import { useFormik } from "formik";
import { forEach, map } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import ChannelCreateGeneral from "./ChannelCreateGeneral";
import ChannelCreateSupport from "./ChannelCreateSupport";
import ChannelCreateEmailConfiguration from "./ChannelCreateEmailConfiguration";
import ChannelCreateSetting from "./ChannelCreateSetting";

const ChannelCreate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const TAB_LIST = [
    {
      id: "general",
      label: t("generalInformation"),
      component: <ChannelCreateGeneral />,
    },
    {
      id: "support",
      label: t("support"),
      component: <ChannelCreateSupport />,
    },
    {
      id: "emailConfig",
      label: t("emailConfiguration"),
      component: <ChannelCreateEmailConfiguration />,
    },
    {
      id: "settings",
      label: t("settings"),
      component: <ChannelCreateSetting />,
    },
  ];
  const [selectedTab, setSelectedTab] = useState<any>(TAB_LIST[0]);

  const channelForm = useFormik({
    initialValues: {},
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: ChannelSchema,
    onSubmit: (values: any) => {
      if (values.password !== values.reTypePassword) {
        Notifications.showError(t("passwordNotMatch"));
        return;
      }
      const generalInfoInput = ObjectUtils.mapFieldsLangsCTS(
        values,
        CHANNEL_GENERAL_KEYS as any
      );

      Progress.show(
        { method: ChannelAPI.create, params: [generalInfoInput] },
        (res: any) => {
          Notifications.showSuccess(t("createChannelSuccessfully"));
          navigate(
            generatePath(Path.CHANNEL_DETAIL, {
              channelId: res?.data?.data?.channel?.id,
            })
          );
        }
      );
    },
  });

  useEffect(() => {
    forEach(Object.keys(channelForm.errors), (key) =>
      Notifications.showError(`${(channelForm.errors as any)?.[key]}`)
    );
  }, [channelForm.errors]);

  return (
    <AppContext.Provider value={{ channelForm }}>
      <div className="bg-muted">
        <Header
          title={t("newChannel")}
          onSave={() => channelForm.handleSubmit()}
        />
        <div className="row mt-1">
          <div className="col-4 card-container">
            <TabBar
              dataSource={TAB_LIST}
              variant="vertical"
              onChange={(tab) => setSelectedTab(tab)}
              value={selectedTab}
            />
          </div>
          <div className="col-8">
            {map(TAB_LIST, (item) => (
              <div hidden={item.id !== selectedTab?.id}>{item?.component}</div>
            ))}
          </div>
        </div>
      </div>
    </AppContext.Provider>
  );
};

export default ChannelCreate;
