import Drawer from "@components/shared/Drawer";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import DeliveryStockFilterForm from "../components/DeliveryStockFilterForm";

interface IDrawerProps {
  open: boolean;
  defaultValue?: any;
  onClose: () => void;
  onFilter: any;
}
function DeliveryStockFilterDrawer({
  defaultValue,
  open,
  onClose,
  onFilter,
}: IDrawerProps) {
  const { t } = useTranslation();

  const filterForm = useFormik<any>({
    initialValues: defaultValue ?? ({} as any),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values: any) => {
      onFilter(values);
      onClose();
    },
  });

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("filter")}
      onSave={() => filterForm.handleSubmit()}
      size="auto"
      width={"80%"}
    >
      <DeliveryStockFilterForm filterForm={filterForm} />
    </Drawer>
  );
}

export default DeliveryStockFilterDrawer;
