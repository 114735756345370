export const V1_URL = "https://salesinfinityx.com/admin";
export const V1_DEV_URL = "https://dev.salesinfinityx.com/admin";
export const V1_AUTH_URL = `${V1_URL}/auth/login?redirectTo=${window.location.href}`;
export const V1_LOGOUT_URL = `${V1_URL}/auth/logout`;

export const YES_NO_OPTIONS = [
  { id: true, label: "yes", value: true },
  { id: false, label: "no", value: false },
];

export const DEFAULT_IMAGE = "/images/img-default.png";

export const DEFAULT_PAGING = {
  pageIndex: 1,
  pageSize: 10,
};

export const TYPE_OF_PLACES = [
  { id: "private-house", content: "privateHouse" },
  { id: "condo-apartment", content: "condominium" },
  { id: "company-office", content: "companyOffice" },
];

export enum OrderType {
  NORMAL = "NORMAL",
  RENTAL = "RENTAL",
}

export const TYPE_OF_PLACE: { [key: string]: any } = {
  "private-house": "privateHouse",
  "condo-apartment": "condominium",
  "company-office": "companyOffice",
};

export const BILLING_TYPES = [
  { id: "personal", label: "personal" },
  { id: "business", label: "business" },
];

export const BILLING_TYPE = {
  personal: "personal",
  business: "business",
};

export const FILE_TYPES = {
  DOC: { extension: ["docx", "doc", "pptx", "pps"] },
  EXCEL: { extension: ["xls", "csv", "xlsx"] },
  PDF: { extension: ["pdf"] },
  IMAGE: {
    extension: [
      "jpeg",
      "bmp",
      "png",
      "jpg",
      "heic",
      "PNG",
      "HEIC",
      "JPG",
      "JPEG",
      "BMP",
    ],
  },
};

export const FILE_ICON: any = {
  csv: "csv.png",
  pdf: "pdf-file.png",
  xls: "sheets.png",
  xlsx: "sheets.png",
  doc: "doc.png",
  docx: "doc.png",
};

export const FILTER_COMMENT_TYPES = [
  { id: "activities", label: "activities" },
  { id: "chatLog", label: "chatLog" },
  { id: "orderStatus", label: "orderStatus" },
  { id: "refund", label: "refund" },
  { id: "delivery", label: "delivery" },
  { id: "payment", label: "paymentName" },
];

export const FILTER_COMMENT_TYPE: { [key: string]: any } = {
  activities: "activities",
  chatLog: "chatLog",
  orderStatus: "orderStatus",
  refund: "refund",
  delivery: "delivery",
  payment: "paymentName",
  comment: "comment",
};

export const COMMENT_TYPES = [
  { id: "chatLog", content: "chatLog" },
  { id: "orderStatus", content: "orderStatus" },
  { id: "refund", content: "refund" },
  { id: "delivery", content: "delivery" },
  { id: "payment", content: "paymentName" },
];

export const ORDER_ACTION = [
  {
    id: "view",
    label: "view",
  },
  {
    id: "delete",
    label: "delete",
  },
];

export const PRINT_MODE: { [key: string]: string } = {
  REFUND: "refund",
  ORDER_DETAIL_INTERNAL: "printOrderInternal",
  ORDER_DETAIL_CUSTOMER: "printOrderCustomer",
  ORDER_RECEIPT: "printReceipt",
  ORDER_PICKUP: "printOrderPickUp",
  ORDER_CUSTOM_SIZE: "customSizeOrderPrint",
  ORDER_RECEIPT_CUSTOMER: "printReceiptCustomer",
};
