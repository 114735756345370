/* eslint-disable react-hooks/exhaustive-deps */
import { TYPE_OF_PLACES } from "@constants/common";
import { IProvince } from "@interfaces/address";
import { getLocationFromAddress, getPostcodeList } from "@network/api/address";
import classNames from "classnames";
import { InputText as Input, Select } from "d-react-components";
import { debounce, isEmpty, join, map } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import DistrictSelect from "./DistrictSelect";
import ProvinceSelect from "./ProvinceSelect";
import SelectLocationMapModal from "./SelectLocationMapModal";
import SubdistrictSelect from "./SubdistrictSelect";

const DEFAULT_SHOW = {
  typeOfPlace: true,
  remark: false,
  map: true,
  address: true,
};

const AddressFormInput = ({
  formDataValue,
  formDataError,
  show = {},
  onChange,
  fieldClassName,
}: any) => {
  const showField = { ...DEFAULT_SHOW, ...show };
  const [listPostCode, setListPostCode] = useState<any[]>([]);

  // const [location, setLocation] = useState<any>({
  //   latitude: formDataValue.latitude,
  //   longitude: formDataValue.longitude,
  // });

  const [latitude, setLatitude] = useState(formDataValue.latitude);
  const [longitude, setLongitude] = useState(formDataValue.longitude);

  useEffect(() => {
    if (showField.map) {
      onChangeValue({
        latitude,
      });
    }
  }, [JSON.stringify(latitude)]);

  useEffect(() => {
    if (showField.map) {
      onChangeValue({
        longitude,
      });
    }
  }, [JSON.stringify(longitude)]);

  useEffect(() => {
    if (!formDataValue?.subDistrict?.id) {
      return;
    }

    loadPostcodeList(formDataValue?.subDistrict?.id);
  }, [formDataValue?.subDistrict?.id]);

  const loadPostcodeList = async (subdistrictId: string) => {
    const postCodeListRes = await getPostcodeList(subdistrictId);
    const postcodeList = map(
      postCodeListRes?.data?.data?.code ?? [],
      (item) => item.postcode
    );
    setListPostCode(postcodeList);
  };

  const isFirstTime = useRef(true);
  useEffect(() => {
    if (
      (isFirstTime.current &&
        formDataValue?.latitude &&
        formDataValue?.longitude) ||
      !showField.map
    ) {
      isFirstTime.current = false;
      return;
    }
    loadLocation();
  }, [
    formDataValue?.province?.id,
    formDataValue?.district?.id,
    formDataValue?.subDistrict?.id,
  ]);

  const loadLocation = debounce(() => {
    const addressList = [
      formDataValue?.subDistrict?.name,
      formDataValue?.district?.name,
      formDataValue?.province?.name,
    ].filter((item) => !!item);
    const fulAddress = join(addressList, ",");
    if (isEmpty(fulAddress)) {
      return;
    }
    console.log("run into loadLocation");

    getLocationFromAddress(fulAddress).then((res) => {
      const locationResult = res?.data?.results?.[0]?.geometry?.location;
      if (locationResult) {
        setLatitude(locationResult.lat);
        setLongitude(locationResult.lng);
      }
    });
  }, 1000);

  const onChangeValue = (values = {}) => {
    onChange({ ...formDataValue, ...values });
  };

  const onChangeProvince = (province: IProvince) => {
    onChangeValue({
      province: province,
      district: null,
      subDistrict: null,
      postCode: null,
    });
  };

  const onChangeDistrict = (district: any) => {
    onChangeValue({
      ...formDataValue,
      district,
      subDistrict: null,
      postCode: null,
    });
  };

  const onChangeSubDistrict = (subDistrict: any) => {
    onChangeValue({
      ...formDataValue,
      subDistrict,
      postCode: null,
    });
  };

  const renderMapSelect = () => {
    if (!latitude || !longitude) {
      return <div />;
    }
    return (
      <div className="col-12 mt-3">
        {/* <AddressMapSelect
          location={location}
          onChange={(location) => {
            setLocation(location);
          }}
        /> */}
        <SelectLocationMapModal
          location={{
            latitude: parseFloat(latitude),
            longitude: parseFloat(longitude),
          }}
          onChange={(location: any) => {
            setLatitude(location.latitude);
            setLongitude(location.longitude);
          }}
        />
      </div>
    );
  };

  const classNameInputField = classNames("col-6 mt-3", fieldClassName);
  const { t } = useTranslation();

  return (
    <>
      <ProvinceSelect
        className={classNameInputField}
        value={formDataValue?.province}
        error={formDataError?.province}
        getLabel={(item: any) => item.name}
        required
        onChange={onChangeProvince as any}
      />
      <DistrictSelect
        provinceId={formDataValue?.province?.id}
        onChange={onChangeDistrict}
        value={formDataValue?.district}
        error={formDataError?.district}
        required
        className={classNameInputField}
      />
      <SubdistrictSelect
        districtId={formDataValue?.district?.id}
        onChange={onChangeSubDistrict}
        value={formDataValue?.subDistrict}
        error={formDataError?.subDistrict}
        required
        className={classNameInputField}
      />

      <div className={classNameInputField}>
        <Select
          label={t("postcode")}
          placeholder={t("postcode")}
          dataSource={listPostCode}
          onChange={(value) => onChangeValue({ postCode: value })}
          value={formDataValue?.postCode}
          error={formDataError?.postCode}
          disabled={listPostCode.length === 0}
          getLabel={(item) => item}
          getValue={(item) => item}
          required
        />
      </div>
      <div className={classNameInputField} hidden={!showField.typeOfPlace}>
        <Select
          label={t("typeOfPlace")}
          placeholder={t("typeOfPlace")}
          dataSource={map(TYPE_OF_PLACES, (item: any) => ({
            ...item,
            label: t(item.content),
          }))}
          onChange={(value) => onChangeValue({ typeOfPlace: value })}
          value={formDataValue?.typeOfPlace}
          error={formDataError?.typeOfPlace}
          required
        />
      </div>

      <div className="col-12 mt-3" hidden={!showField.address}>
        <Input
          label={t("address")}
          placeholder={t("address")}
          onChange={(event) => onChangeValue({ address: event?.target.value })}
          value={formDataValue?.address}
          error={formDataError?.address}
          required
        />
      </div>

      <div className="col-6 mt-3" hidden={!showField.address}>
        <Input
          label={t("latitude")}
          placeholder={t("latitude")}
          onChange={(event) => setLatitude(event?.target.value)}
          value={latitude}
          error={formDataError?.latitude}
        />
      </div>
      <div className="col-6 mt-3" hidden={!showField.address}>
        <Input
          label={t("longitude")}
          placeholder={t("longitude")}
          onChange={(event) => setLongitude(event?.target.value)}
          value={longitude}
          error={formDataError?.longitude}
        />
      </div>

      {showField.map && renderMapSelect()}
      <div className="col-12" hidden={!showField.remark}>
        <Input
          label={t("remark")}
          onChange={(event) => onChangeValue({ remark: event?.target.value })}
          value={formDataValue?.remark}
          error={formDataError?.remark}
          multiple
        />
      </div>
    </>
  );
};

export default AddressFormInput;
