import { find, join, map } from "lodash";
import { STOCK_TRANSFER_DELIVERY_TYPES } from "./transfer-stock";

export const VEHICLE_TYPES = [
  {
    id: "small-truck",
    label: "smallTruck",
  },
  { id: "large-truck", label: "largeTruck" },
  { id: "motorbike", label: "motorbike" },
  { id: "van", label: "van" },
];

export const VEHICLE_FILTER_KEY = [
  {
    keyServer: "status",
    keyClient: "status",
    getFilterContent: (data: any) => data,
  },
  {
    keyServer: "vehicle_type",
    keyClient: "type",
    getFilterContent: (data = []) => {
      const vehicleTypes = data.map((id) => {
        const type = find(VEHICLE_TYPES, (item) => item.id === id);
        return type?.label;
      });
      return join(vehicleTypes, ", ");
    },
  },

  {
    keyServer: "vehicle_ownership",
    keyClient: "ownerShip",
    getFilterContent: (data = []) => {
      const ownershipType = data.map((id) => {
        const type = find(
          STOCK_TRANSFER_DELIVERY_TYPES,
          (item) => item.id === id
        );
        return type?.label;
      });
      return join(ownershipType, ", ");
    },
  },
  {
    keyServer: "company_id",
    keyClient: "company",
    getFilterContent: (data = [], dataSource = []) => {
      const companies = data.map((id) => {
        const company = find(dataSource, (item: any) => item.id === id);
        return company?.name;
      });
      return join(companies, ", ");
    },
  },
  {
    keyServer: "driver_id",
    keyClient: "driver",
    getFilterContent: (data = []) => {
      const drivers = map(data, (item: any) => item.name);
      return join(drivers, ", ");
    },
  },
];

export const VEHICLE_STATUS_LIST = [
  {
    id: true,
    label: "active",
    color: "#33B950",
  },
  { id: false, label: "inactive", color: "#8D8D8D" },
];

export const VEHICLE_OWNERSHIPS = [
  { id: "in-house-truck", label: "inHouseTruck" },
  { id: "outsourced-truck", label: "outSourceTruck" },
];
