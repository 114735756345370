import { ActivityItem } from "@components/order/components/activity/ActivitiesView";
import ButtonFileUpload from "@components/shared/ButtonFileUpload";
import Drawer from "@components/shared/Drawer";
import UserAvatarName from "@components/shared/UserAvatarName";
import StoreSelect from "@components/store/components/StoreSelect";
import StoreSelectAll from "@components/store/components/StoreSelectAll";
import { IMAGE_FORMAT } from "@constants/file";
import { IStore } from "@interfaces/store";
import {
  addInventoryHistory,
  historyInventory,
  inventoryHistoryMemoList,
} from "@network/api/inventory";
import classNames from "classnames";
import {
  AwesomeTableComponent,
  Button,
  InputText,
  Notifications,
  TimeUtils,
  useFirstTime,
  useForceUpdateConstraint,
} from "d-react-components";
import { filter, includes, isEmpty, map } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Carousel, { Modal, ModalGateway } from "react-images";

const InventoryHistoryLog = ({ history }: any) => {
  const [memo, setMemo] = useState<string>("");
  const [memoAttach, setMemoAttach] = useState([]);
  const [memoList, setMemoList] = useState<any[]>([]);
  const [imageToView, setImageToView] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    onLoadMemos();
  }, []);

  const isUpdate = useForceUpdateConstraint(memoList);

  const onLoadMemos = () => {
    const body = {
      inventory_history_id: history.id,
    };
    inventoryHistoryMemoList(body).then((res) => {
      const memoList = res?.data?.data?.memo ?? [];
      const memoMapComment = map(memoList, ({ memo, attachment, ...item }) => ({
        ...item,
        comment: memo,
        files: map(attachment, (item) => item.attachment),
      }));
      setMemoList(memoMapComment);
    });
  };

  const onClickSendComment = () => {
    if (isEmpty(memo)) {
      Notifications.showError(t("memoRequire"));
      return;
    }
    const body = {
      inventory_history_id: [history.id],
      memo,
      attachment: map(memoAttach, (item: any) => item.key),
    };
    addInventoryHistory(body).then(() => {
      Notifications.showSuccess(t("uploadMemoSuccess"));
      onLoadMemos();
      setMemoAttach([]);
      setMemo("");
    });
  };

  return (
    <div>
      <div className="mt-3">
        <InputText
          label={t("memo")}
          multiple
          placeholder={t("pleaseInputTheNoteForThisUpdate")}
          onChange={(event) => setMemo(event.target.value)}
        />
        <div className="mt-3 d-flex justify-content-end">
          {isUpdate && (
            <ButtonFileUpload
              containerClassName="d-flex mr-3"
              onChange={setMemoAttach}
              inputParam={{
                accept: IMAGE_FORMAT,
              }}
              maxFiles={5}
            />
          )}
          <Button onClick={onClickSendComment} iconName="send" className="">
            {t("send")}
          </Button>
        </div>
      </div>
      <div className="d-flex flex-column mt-3">
        {map(memoList, (memoItem) => (
          <ActivityItem
            comment={memoItem}
            onClickImage={(index: any) => {
              setImageIndex(index);
              setImageToView(memoItem.files);
            }}
          />
        ))}
      </div>
      <ModalGateway>
        {imageToView && imageToView.length > 0 ? (
          <Modal onClose={() => setImageToView([])}>
            <Carousel
              currentIndex={imageIndex}
              views={
                map(imageToView, (file) => ({
                  src: file,
                  caption: "",
                })) as any
              }
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};

interface IInventoryHistoryDrawer {
  open: boolean;
  storeDefault?: IStore[];
  productId: string;
  onClose: () => void;
  source?: any;
  transformer?: any;
}

const InventoryHistoryDrawer = ({
  open,
  onClose,
  storeDefault = [],
  productId = "",
  source = historyInventory,
  transformer = (res: any) => res?.data?.data?.history ?? [],
}: IInventoryHistoryDrawer) => {
  const [store, setStore] = useState<IStore | undefined>(storeDefault?.[0]);
  const historyTable = useRef<any>();
  const [listExpandedKey, setListExpandedKey] = useState<string[]>([]);
  const { t } = useTranslation();
  const isExpanded = (record: any) => includes(listExpandedKey, record?.id);
  const isFirstTime = useFirstTime();

  useEffect(() => {
    if (isFirstTime) return;
    historyTable?.current?.refresh();
  }, [store?.id]);

  const columns: any[] = [
    {
      title: t("date"),
      dataIndex: "created",
      render: TimeUtils.toDateTime,
    },
    {
      title: t("event"),
      dataIndex: "action",
    },
    {
      title: t("adjustment"),
      dataIndex: "adjustment",
      render: (adjustment: number) => (
        <div
          className={classNames({
            "text-success": adjustment > 0,
            "text-error": adjustment <= 0,
          })}
        >
          {adjustment > 0 ? "+" : ""} {adjustment}
        </div>
      ),
    },
    {
      title: t("quantity"),
      dataIndex: "quantity",
    },
    {
      title: t("adjustedBy"),
      dataIndex: "by",
      render: (by: any) => <UserAvatarName user={by} />,
    },
    {
      title: t("ref"),
      dataIndex: "event_ref",
      render: (ref: any, item: any) => (
        <a href={item?.event_url} onClick={() => onClose && onClose()}>
          {ref}
        </a>
      ),
    },
    {
      title: t("memo"),
      dataIndex: "",
      align: "center",
      render: (ref: string, item: any) => (
        <Button variant="outline" onClick={() => onClickShowHide(item)}>
          {isExpanded(item) ? t("hide") : t("show")}
        </Button>
      ),
    },
  ];

  function onClickShowHide(item: any) {
    if (isExpanded(item)) {
      setListExpandedKey(filter(listExpandedKey, (key) => key !== item.id));
    } else {
      setListExpandedKey([...listExpandedKey, item.id]);
    }
  }

  const dataSource = (paging: any) => {
    const body = {
      productid: productId,
      warehouseid: store?.id,
    };
    return source(body, paging);
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("inventoryHistory")}
      size="auto"
      width={"80%"}
      //   secondTitle={renderSecondTitle()}
    >
      <div>
        <StoreSelectAll
          value={store}
          onChange={setStore as any}
          multiple={false}
          dataSource={storeDefault ?? []}
        />

        <AwesomeTableComponent
          source={dataSource}
          transformer={transformer}
          columns={columns}
          isScroll={false}
          ref={(ref) => (historyTable.current = ref)}
          expandable={{
            expandedRowRender: (record, index, indent, expanded) =>
              expanded && <InventoryHistoryLog history={record} />,
            rowExpandable: () => true,
            expandIcon: () => false,
            expandedRowKeys: listExpandedKey,
          }}
          expandIconColumnIndex={-1}
          className="mt-3"
        />
      </div>
    </Drawer>
  );
};

export default InventoryHistoryDrawer;
