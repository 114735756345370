import React from "react";
import { useTranslation } from "react-i18next";

const GPEvenProductView = ({ product }: any) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex">
      <img src={product?.imageurl?.[0]} className="image-reg-small" />
      <div className="flex-column ml-3">
        <div>{product?.name}</div>
        <small>{`${t("sku")}: ${product?.sku}`}</small>
      </div>
    </div>
  );
};

export default GPEvenProductView;
