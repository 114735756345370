import { reduce, find, isEmpty, debounce, join, zip } from "lodash";
import {
  Button,
  Checkbox,
  DateInput,
  Drawer,
  Icon,
  InputText,
  Notifications,
  Progress,
  TimeUtils,
  usePrevious,
} from "d-react-components";
import * as Yup from "yup";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import AppContext from "@helpers/context";
import { OrderType, TYPE_OF_PLACE, TYPE_OF_PLACES } from "@constants/common";
import { ORDER_STATUS } from "@constants/order";
import { Spin } from "antd";
import { getFullAddressFromShipping, getFullName } from "@helpers/string";
import {
  createCustomerShipping,
  deleteShippingFromOrder,
  getLocationFromAddress,
  searchShippingCustomer,
  updateOrderShipping,
} from "@network/api/address";
import { calculateAllProductQuantity } from "@helpers/product";
import { useTranslation } from "react-i18next";
import AddressFieldInput, { CustomerAddressForm } from "./AddressFieldInput";
import { useFormik } from "formik";
import moment from "moment";
import SelectLocationMapModal from "../../address/components/SelectLocationMapModal";
import { useUpdateEffect } from "react-use";
import { isEnableEditShipping } from "./shipping/ShippingAddress";

const INIT_SHIPPING = {
  fullname: "",
  nickname: "",
  address: "",
  province: "",
  postcode: "",
  phone: "",
  place: "",
  firstname: "",
  lastname: "",
  city: "",
  note: "",
  expectedDateReceive: "",
};

const getPlaceText = (place: any) => {
  return TYPE_OF_PLACE[place];
};

const ShippingAddressDrawer = ({ open, onClose, orderType }: any) => {
  const {
    shippingProfile: shipping = [],
    order,
    // customer,
    onReloadOrderData,
    isCreateOrder,
    setShippingProfile: onSaveShipping,
    delivery = [],
    products,
    pickup = [],
    values,
  } = useContext(AppContext);
  const { selectedCustomer } = values;
  const customer = selectedCustomer?.[0] ?? {};
  const [shippingInfo, setShippingInfo] = useState<any>(
    initialShippingInfo(shipping)
  );
  const [shippingUserList, setShippingUserList] = useState<any[]>([]);
  const [openModalAddNew, setOpenModalAddNew] = useState(false);
  const [openModalAddToList, setOpenModalAddToList] = useState(false);
  const [removeProgress, setRemoveProgress] = useState(false);
  const { t } = useTranslation();

  function initialShippingInfo(shipping: any[]) {
    if (!shipping) {
      return INIT_SHIPPING;
    }
    return shipping?.[0];
  }

  useEffect(() => {
    setShippingInfo(initialShippingInfo(shipping));
  }, [shipping]);

  const onClickSave = async (shippingInfo: any) => {
    if (isCreateOrder) {
      setOpenModalAddNew(false);
      const clone = shipping?.map((item: any) => {
        if (item?.id === shippingInfo?.id) {
          return shippingInfo;
        }
        return item;
      });
      onSaveShipping && onSaveShipping(clone);
      return Promise.reject();
    }

    const {
      expectedDateReceive,
      scheduled_delivery_date,
      ...shippingInfoRest
    } = shippingInfo;
    const body = {
      ...shippingInfoRest,
      id: shippingInfo?.id,
      alternative_phone: shippingInfo?.alternative_phone,
      ...(expectedDateReceive
        ? {
            expected_to_receive: moment(expectedDateReceive).valueOf(),
          }
        : {}),

      ...(orderType === OrderType.NORMAL
        ? {
            orderid: order?.id,
            city: shippingInfo?.city?.id,
            province: shippingInfo?.province?.id,
            subdistrict: shippingInfo?.subdistrict?.id,
          }
        : {
            rent_id: order?.id,
            city_id: shippingInfo?.city?.id,
            province_id: shippingInfo?.province?.id,
            subdistrict_id: shippingInfo?.subdistrict?.id,
            code: shippingInfo?.postcode,
            ...(scheduled_delivery_date
              ? {
                  scheduled_delivery_date: moment(
                    scheduled_delivery_date
                  ).valueOf(),
                }
              : {}),
          }),
    };
    Progress.show(
      { method: updateOrderShipping, params: [body, orderType] },
      () => {
        onReloadOrderData && onReloadOrderData();
        setOpenModalAddNew(false);
        Notifications.showSuccess(
          t("notification:updateShippingAddressSuccess")
        );
      },
      (err: any) => {
        Notifications.showError(err?.response?.data?.message || err);
      }
    );
  };

  const onClickSaveAddNew = async (shippingInfoAddNew: any) => {
    if (shippingInfoAddNew.id) {
      onClickSave(shippingInfoAddNew);
      return;
    }
    const { expectedDateReceive, ...shippingInfoAddNewRest } =
      shippingInfoAddNew;
    const body = {
      ...shippingInfoAddNewRest,
      customerid: customer?.id,
      province: shippingInfoAddNew?.province?.id,
      city: shippingInfoAddNew?.city?.id,
      subdistrict: shippingInfoAddNew?.subdistrict?.id,
      alternative_phone: shippingInfoAddNew?.alternative_phone,
      ...(expectedDateReceive
        ? {
            expected_to_receive: moment(expectedDateReceive).valueOf(),
          }
        : {}),
    };
    //If shipping list of user is empty. the first shipping item will be default
    if (shippingUserList.length === 0) {
      body.default = true;
    }
    Progress.show(
      { method: createCustomerShipping, params: [body] },
      () => {
        setOpenModalAddNew(false);
        Notifications.showSuccess(
          t("notification:createNewShippingInfoSuccess")
        );
        setShippingInfo(shippingInfoAddNew);
      },
      (err: any) => {
        Notifications.showError(err?.response?.data?.message || err);
      }
    );
  };

  const onClickSaveAddToList = async () => {
    if (isCreateOrder) {
      setOpenModalAddToList(false);
      const clone = [
        ...shipping,
        {
          ...shippingInfo,
          id: (shippingInfo?.id + shipping?.length).toString(),
        },
      ];
      onSaveShipping && onSaveShipping(clone);
      return Promise.reject();
    }

    const { id, ...rest } = shippingInfo;
    const body = {
      ...rest,
      ...(orderType === OrderType.NORMAL
        ? {
            orderid: order?.id,
            city: rest?.city?.id,
            province: rest?.province?.id,
            subdistrict: rest?.subdistrict?.id,
          }
        : {
            rent_id: order?.id,
            city_id: rest?.city?.id,
            province_id: rest?.province?.id,
            subdistrict_id: rest?.subdistrict?.id,
          }),
    };
    return updateOrderShipping(body, orderType).then(
      () => {
        onReloadOrderData();
        setOpenModalAddToList(false);
        Notifications.showSuccess(
          t("notification:createNewShippingInfoSuccess")
        );
      },
      (err) => {
        Notifications.showError(err?.respone?.data?.message);
      }
    );
  };

  const onClickRemoveShipping = (shippingItem: any) => {
    setRemoveProgress(true);
    if (isCreateOrder) {
      const clone = shipping?.filter(
        (item: any) => item?.id !== shippingItem?.id
      );
      onSaveShipping && onSaveShipping(clone);
      setRemoveProgress(false);
      return;
    }
    const idShippingDelivery = delivery?.map(
      (delivery: any) => delivery?.shipping?.id
    );
    if (idShippingDelivery?.includes(shippingItem?.id)) {
      Notifications.showError(
        t("notification:youcannotremovethisshippingaddress")
      );
      setRemoveProgress(false);
      return;
    }
    if (!isEmpty(shippingItem)) {
      deleteShippingFromOrder(shippingItem?.id, orderType)
        .then(() => {
          setRemoveProgress(false);
          onReloadOrderData();
          Notifications.showSuccess(
            t("removeShippingAdressfromordersuccessfully")
          );
        })
        .catch(() => {
          setRemoveProgress(false);
        });
    }
  };

  const isDeliveryAllItems = (
    productList: any,
    deliveryList: any,
    pickupList: any
  ) => {
    const totalItems = calculateAllProductQuantity(productList);
    let itemsDelivery: any[] = [];
    deliveryList.forEach((deliveryItem: any) => {
      itemsDelivery = itemsDelivery.concat(deliveryItem?.items ?? []);
    });

    const totalDeliveryItems = reduce(
      itemsDelivery ?? [],
      (sum, item) => {
        return sum + item.stock;
      },
      0
    );

    const totalPickUpItems = reduce(
      pickupList,
      (sum, pickupItem) => {
        const subPickup = reduce(
          pickupItem.pickup,
          (subSum, subPickup) => {
            return subSum + subPickup?.stock;
          },
          0
        );
        return sum + subPickup;
      },
      0
    );

    return totalItems - totalDeliveryItems - totalPickUpItems === 0;
  };

  const isShowEditRemove = (shippingItem: any) => {
    if (isCreateOrder) {
      return true;
    }
    if (orderType === OrderType.RENTAL) {
      return isEnableEditShipping(delivery);
    }
    if (orderType === OrderType.NORMAL) {
      if (
        order &&
        (order.status === ORDER_STATUS.pending.value ||
          order.status === ORDER_STATUS.pendingPaymentConfirmation.value)
      ) {
        return true;
      }
      if (
        order &&
        (order.status === ORDER_STATUS.processing.value ||
          order.status === ORDER_STATUS.deliveryProcessing.value)
      ) {
        const isCreatedDelivery = find(
          delivery,
          (deliveryItem) => deliveryItem?.shipping?.id === shippingItem?.id
        );

        if (isCreatedDelivery) {
          return false;
        }

        const isDeliveryAll = isDeliveryAllItems(products, delivery, pickup);

        if (!isDeliveryAll) {
          return true;
        }
      }
    }
    return false;
  };

  const isCustomerShowAdd = () => {
    if (isCreateOrder) {
      return !isEmpty(customer);
    }

    if (!order?.has_create_shipping) return false;
    if (!order?.has_bulk_shipping && shipping.length > 0) return false;

    if (
      order &&
      (order.status === ORDER_STATUS.pending.value ||
        order.status === ORDER_STATUS.pendingPaymentConfirmation.value)
    ) {
      return true;
    }

    if (
      order &&
      (order.status === ORDER_STATUS.processing.value ||
        order.status === ORDER_STATUS.deliveryProcessing.value)
    ) {
      const isDeliveryAll = isDeliveryAllItems(products, delivery, pickup);

      if (!isDeliveryAll) {
        return true;
      }
    }

    return false;
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("orderShippingAddress")}
      destroyOnClose
    >
      {!isEmpty(shipping)
        ? shipping?.map(
            (item: any, index: number) =>
              item && (
                <ShippingViewList
                  key={index}
                  {...{
                    shippingItem: item,
                    isShowEditRemove,
                    isCreateOrder,
                    order,
                    setShippingInfo,
                    setOpenModalAddNew,
                    onClickRemoveShipping,
                    removeProgress,
                  }}
                />
              )
          )
        : t("noShippingInfoFound")}

      {!isEmpty(customer) && (
        <div className="border-t-1 py-2">
          <Button onClick={() => setOpenModalAddToList(true)}>
            <Icon name="add" /> {t("add")}
          </Button>
        </div>
      )}

      {openModalAddToList && (
        <AddShippingToOrderDrawer
          {...{
            openModalAddToList,
            setOpenModalAddToList,
            shippingUserList,
            setShippingUserList,
            shippingInfo,
            setShippingInfo,
            onClickSaveAddToList,
            onClose,
            setOpenModalAddNew,
            openModalAddNew,
          }}
        />
      )}
      {openModalAddNew && (
        <AddNewShippingProfileDrawer
          {...{
            openModalAddNew,
            setOpenModalAddNew,
            onClickSaveAddNew,
            shippingInfo,
            customer,
          }}
          {...(isEmpty(shippingInfo)
            ? {}
            : {
                showNote: true,
                showExpectedReceiveTime:
                  orderType === OrderType.RENTAL ? false : true,
                showMap: true,
                showSaveDefault: false,
                size: "auto",
                width: "600px",
              })}
        />
      )}
    </Drawer>
  );
};

export const ShippingViewList = ({
  shippingItem,
  isShowEditRemove,
  isCreateOrder,
  order,
  setShippingInfo,
  setOpenModalAddNew,
  onClickRemoveShipping,
  removeProgress,
}: any) => {
  const { t } = useTranslation();
  return (
    <div className="py-3 border-b">
      <div className="mainContentContainerShipping">
        <div className="flex flex-column">
          <div className="font-bold">
            {getFullName(shippingItem) +
              (shippingItem?.nickname ? ` (${shippingItem.nickname})` : "")}
          </div>
          <div className="subTitle1">{shippingItem?.phone}</div>
          {shippingItem?.alternative_phone && (
            <div className="subTitle1">{shippingItem?.alternative_phone}</div>
          )}
          <div className="subTitle1">{shippingItem?.address}</div>
          <div className="subTitle1">
            {shippingItem?.subdistrict?.name}, {shippingItem?.city?.name},{" "}
            {shippingItem?.province?.name}, {shippingItem?.postcode}
          </div>
          <div className="subTitle1">
            {t(getPlaceText(shippingItem?.place))}
          </div>
          {shippingItem?.expectedDateReceive && (
            <p className="subTitle1 shippingItemNote mb-1">
              <span style={{ fontWeight: "bold" }}>{`${t(
                "expectedDateReceive"
              )}: `}</span>
              {TimeUtils.convertMiliToDateTime(
                shippingItem?.expectedDateReceive
              )}
            </p>
          )}
          {shippingItem?.note && (
            <p className="subTitle1 shippingItemNote mb-1">
              <span style={{ fontWeight: "bold" }}>{`${t(
                "shippingNote"
              )}: `}</span>
              {shippingItem?.note}
            </p>
          )}
        </div>
      </div>

      {isShowEditRemove(shippingItem) && (
        <div id="buttonGroup" className="mt-2">
          {(isCreateOrder || order?.has_edit_shipping) && (
            <div
              className="cursor-pointer bg-primary text-white p-1 w-fit inline-block leading-none mr-1"
              onClick={() => {
                setShippingInfo(shippingItem);
                setOpenModalAddNew(true);
              }}
            >
              <Icon name="edit" size="x-small" />
            </div>
          )}
          {(isCreateOrder || order?.has_delete_shipping) && (
            <div
              className="cursor-pointer bg-primary text-white p-1 w-fit inline-block leading-none"
              onClick={() => {
                onClickRemoveShipping(shippingItem);
              }}
            >
              {removeProgress ? (
                <Spin />
              ) : (
                <Icon name="delete" size="x-small" />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const AddShippingToOrderDrawer = ({
  openModalAddToList,
  setOpenModalAddToList,
  shippingUserList,
  setShippingUserList,
  shippingInfo,
  setShippingInfo,
  onClickSaveAddToList,
  onClose,
  setOpenModalAddNew,
  openModalAddNew,
}: any) => {
  const { values } = useContext(AppContext);
  const { selectedCustomer } = values;
  const customer = selectedCustomer?.[0] ?? {};
  const { t } = useTranslation();
  const prevState = usePrevious(openModalAddNew);

  useEffect(() => {
    searchShippingCustomer(customer?.id).then((resp) => {
      transformer(resp);
    });
  }, []);

  const transformer = (res: any) => {
    const shippingUserList = res?.data?.data?.shipping;
    setShippingUserList(shippingUserList);
  };

  useEffect(() => {
    if (prevState === true && openModalAddNew === false) {
      searchShippingCustomer(customer?.id).then((resp) => {
        transformer(resp);
      });
    }
  }, [openModalAddNew]);

  const renderShippingProfileItem = (item: any, index: number) => {
    const shipping = {
      subdistrict: item?.subdistrict,
      address: item?.address,
      province: item?.province,
      city: item?.city,
      postcode: item?.postcode,
    };
    return (
      <div className="flex py-3 border-b" key={index}>
        <div className="flex-1">
          <div className="flex items-center mb-2">
            <span className="text-bold">{getFullName(item)}</span>
            {item.default && (
              <span
                style={{ borderWidth: "1px" }}
                className="inline-block ml-2 p-1 text-tiny text-primary border-primary"
              >
                {t("defaultText")}
              </span>
            )}
          </div>
          <div>{item?.phone}</div>
          <div>{getFullAddressFromShipping(shipping)}</div>
        </div>
        <div>
          <Checkbox
            variant="radio"
            checked={shippingInfo?.id === item?.id}
            onChange={(event) => {
              setShippingInfo(item);
            }}
            value={item?.id}
          />
        </div>
      </div>
    );
  };

  return (
    <Drawer
      title={
        <div className="flex">
          <span className="flex-1">{t("addShippingToOrder")}</span>
          <Button
            onClick={() => {
              setShippingInfo({});
              setOpenModalAddNew(true);
            }}
          >
            <Icon name="add" /> {t("add")}
          </Button>
        </div>
      }
      open={openModalAddToList}
      onClose={() => setOpenModalAddToList(false)}
    >
      <div className="pb-10">
        {shippingUserList?.map((item: any, index: number) =>
          renderShippingProfileItem(item, index)
        )}
      </div>
      <div className="position-absolute bottom-0 w-100 end-0 start-0 py-3 px-3 bg-white flex justify-content-end">
        <Button
          onClick={() => {
            onClickSaveAddToList();
          }}
        >
          {t("save")}
        </Button>
      </div>
    </Drawer>
  );
};

export const AddNewShippingProfileDrawer = ({
  openModalAddNew,
  setOpenModalAddNew,
  onClickSaveAddNew,
  shippingInfo,
  showNote,
  showExpectedReceiveTime,
  showScheduledDeliveryDate,
  showSaveDefault = true,
  showMap,
  customer,
  order,
  ...drawerProps
}: any) => {
  const { t } = useTranslation();
  const isFirstTime = useRef(true);
  const { latitude, longitude, province, city } = shippingInfo;
  const { firstname, lastname, nickname, phone, alternative_phone } =
    customer || {};
  const [location, setLocation] = useState({ latitude, longitude });
  const CustomerAddressSchema = Yup.object().shape({
    firstname: Yup.string().required(t("fieldRequired")),
    lastname: Yup.string().required(t("fieldRequired")),
    nickname: Yup.string().required(t("fieldRequired")).nullable(),
    // place: Yup.string().required(t("fieldRequired")),
    phone: Yup.string().required(t("fieldRequired")),
    address: Yup.string().required(t("fieldRequired")),
    province: Yup.mixed().required(t("fieldRequired")),
    city: Yup.mixed().required(t("fieldRequired")),
    subdistrict: Yup.mixed().required(t("fieldRequired")),
    postcode: Yup.string().required(t("fieldRequired")),
  });
  const customerForm = useFormik<CustomerAddressForm>({
    initialValues: isEmpty(shippingInfo)
      ? {
          ...(!isEmpty(customer)
            ? {
                firstname,
                lastname,
                nickname,
                phone,
                alternative_phone,
              }
            : {}),
        }
      : {
          ...shippingInfo,
          ...(shippingInfo.expectedDateReceive
            ? { expectedDateReceive: moment(shippingInfo.expectedDateReceive) }
            : {}),
          ...(shippingInfo.scheduled_delivery_date
            ? {
                scheduled_delivery_date: moment(
                  shippingInfo.scheduled_delivery_date
                ),
              }
            : {}),
        },
    onSubmit: (values) => {
      onClickSaveAddNew(values);
    },
    validationSchema: CustomerAddressSchema,
    validateOnChange: false,
  });

  const loadLocation = debounce(() => {
    const { city, province } = shippingInfo;
    const addressList = [city?.name, province?.name].filter((item) => !!item);
    const fulAddress = join(addressList, ",");
    if (isEmpty(fulAddress)) {
      return;
    }
    getLocationFromAddress(fulAddress).then((res) => {
      const locationResult = res?.data?.results?.[0]?.geometry?.location;
      if (locationResult) {
        setLocation({
          latitude: locationResult.lat,
          longitude: locationResult.lng,
        });
      }
    });
  }, 1000);

  useEffect(() => {
    if (isFirstTime.current && latitude && longitude) {
      isFirstTime.current = false;
      return;
    }
    loadLocation();
  }, [province?.id, city?.id]);

  useEffect(() => {
    setFieldValue("latitude", location?.latitude);
    setFieldValue("longitude", location?.longitude);
  }, [location]);

  const { handleSubmit, values, errors, setFieldValue } = customerForm;

  const { scheduled_delivery_min, scheduled_delivery_max } =
    values?.province || {};
  const minDate = useMemo(() => {
    return moment(order?.created).add(scheduled_delivery_min, "days");
  }, [scheduled_delivery_min, order?.created]);
  const maxDate = useMemo(() => {
    return moment(minDate).add(scheduled_delivery_max, "days");
  }, [minDate, scheduled_delivery_max]);

  useUpdateEffect(() => {
    const { scheduled_delivery_date } = values;
    if (
      scheduled_delivery_date &&
      (minDate.isAfter(scheduled_delivery_date) ||
        maxDate.isBefore(scheduled_delivery_date))
    ) {
      setFieldValue && setFieldValue("scheduled_delivery_date", null);
    }
  }, [values?.province?.id, minDate, maxDate]);

  return (
    <Drawer
      title={
        <div className="flex">
          <span className="flex-1">
            {isEmpty(shippingInfo) ? t("newAddress") : t("updateAddress")}
          </span>
        </div>
      }
      open={openModalAddNew}
      onClose={() => setOpenModalAddNew(false)}
      {...drawerProps}
    >
      <div className="pb-12">
        <AddressFieldInput form={customerForm} />
        {showExpectedReceiveTime && (
          <div className="col-span-1 mt-3">
            <DateInput
              label={t("expectedDateReceive")}
              value={values?.expectedDateReceive as any}
              error={errors?.expectedDateReceive}
              onChange={(val) => {
                setFieldValue && setFieldValue("expectedDateReceive", val);
              }}
              showTime
              format="DD/MM/YYYY HH:mm"
            />
          </div>
        )}
        {showScheduledDeliveryDate && (
          <div className="col-span-1 mt-3">
            <DateInput
              label={t("scheduledDeliveryDate")}
              disabled={isEmpty(values?.province)}
              value={values?.scheduled_delivery_date as any}
              error={errors?.scheduled_delivery_date}
              onChange={(val) => {
                setFieldValue && setFieldValue("scheduled_delivery_date", val);
              }}
              format="DD/MM/YYYY"
              disabledDate={(d) =>
                (d && d.isBefore(minDate)) || d.isAfter(maxDate)
              }
            />
          </div>
        )}
        {showNote && (
          <div className="col-span-1 mt-3">
            <InputText
              multiple
              label={t("shippingNote")}
              value={values?.note as any}
              error={errors?.note}
              onChange={(e) => {
                setFieldValue && setFieldValue("note", e?.currentTarget?.value);
              }}
            />
          </div>
        )}
        {showMap && (
          <div className="col-span-1 mt-3">
            <SelectLocationMapModal
              location={location}
              onChange={(location: any) => {
                setLocation(location);
              }}
            />
          </div>
        )}
        {showSaveDefault && (
          <Checkbox
            checked={values.default}
            onChange={(event) => {
              setFieldValue("default", values.default);
            }}
            label={t("setAsDefault")}
            className="mt-3"
          />
        )}
      </div>
      <div className="position-absolute bottom-0 w-100 end-0 start-0 py-3 px-3 bg-white flex justify-content-end">
        <Button
          onClick={() => {
            handleSubmit();
          }}
        >
          {t("save")}
        </Button>
      </div>
    </Drawer>
  );
};

export default ShippingAddressDrawer;
