import { map } from "lodash";
import moment from "moment";
import { IChannel } from "./channel";
import { IDelivery } from "./delivery";
import { IInventoryEvent } from "./inventory-event";
import { IProduct } from "./product";
import { IStore } from "./store";

export interface ITransferStock {
  id: string;
  transfer_no: string;
  transfer_origin: IStore;
  transfer_destination: IStore;
  transfer_type: string;
  status: string;
  expected_arrival: number;
  expected_transfer: number;
  created: number;
  remark: string;
  by: any;
  items: IProduct[];
  delivery: IDelivery[];
  channel: IChannel;
  stock_inventory_control: IInventoryEvent;
}

export const mapTransferStockToServer = (input: any) => {
  const {
    channel,
    expectedDateTransfer,
    origin,
    destination,
    remark,
    transferType,
    expectedDateArrival,
    requestEvents,
    event,
  } = input;
  return {
    stock_inventory_control_id: event?.id,

    channel_id: channel?.id,
    transfer_origin: origin?.id,
    transfer_destination: destination?.id,
    expected_transfer: moment(expectedDateTransfer).valueOf(),
    expected_arrival: moment(expectedDateArrival).valueOf(),

    remark,
    transfer_type: transferType,
    request_stock_id: map(requestEvents, (item) => item.id),
  };
};

export const mapTransferStockDeliveryToServer = (values: any) => {
  const {
    vehicle,
    driver,
    deliveryNo,
    expectedDateArrival,
    deliveryType,
    remark,
    products = [],
    transferId,
  } = values;
  console.log("mapTransferStockDeliveryToServer", vehicle);
  const input = {
    transfer_id: transferId,
    delivery_type: deliveryType,
    vehicle_id: vehicle,
    driver_id: driver?.id,
    delivery_no: deliveryNo,
    estimated_arrival: moment(expectedDateArrival).valueOf(),
    remark,
    transfer_stock_product: map(products, (iPro) => ({
      id: iPro?.transfer_stock_product_id,
      quantity: iPro?.stock,
    })),
  };

  return input;
};
