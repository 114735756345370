import AppContext from "@helpers/context";
import { exportToCSV } from "@helpers/file";
import { getGPEventProductList } from "@network/api/gp-event";
import { AwesomeTableComponent, Button } from "d-react-components";
import { map } from "lodash";
import { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import GPEvenProductView from "../compoents/GPEvenProductView";
import GPEventDetailConfigAddModal from "./GPEventDetailConfigAddModal";
import GPEventDetailConfigImportModal from "./GPEventDetailConfigImportModal";
import GPEventDetailConfigRemoveModal from "./GPEventDetailConfigRemoveModal";
import GPEventDetailConfigUpdateModal from "./GPEventDetailConfigUpdateModal";

const GPEventDetailConfig = () => {
  const { eventDetail, eventChannelList, loadEventDetail } =
    useContext(AppContext);
  const [openAdd, setOpenAdd] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const [openImport, setOpenImport] = useState(false);
  const { t } = useTranslation();

  const tableRef = useRef<any>();
  const columns = [
    {
      title: t("name"),
      dataIndex: "",
      render: (item: any) => <GPEvenProductView product={item} />,
    },
    {
      title: t("gp"),
      dataIndex: "discount",
      width: 200,
    },
  ];

  const source = (paging: any) => {
    const body = {
      id: eventDetail.id,
      action: "in",
    };
    return getGPEventProductList(body, paging);
  };

  const transformer = (res: any) => res?.data?.data?.product ?? [];

  const renderButton = (key: any, onLick: any) => (
    <Button onClick={onLick} variant="trans">
      {t(key)}
    </Button>
  );

  const onExport = async () => {
    const body = {
      id: eventDetail.id,
      action: "in",
    };

    let remainPage = true;
    let listResult: any[] = [];
    let pageIndex = 1;
    while (remainPage) {
      const response = await getGPEventProductList(body, {
        pageIndex,
        pageSize: 30,
      });
      const productList = response?.data?.data?.product ?? [];
      const paginationData = response?.data?.data?.pagination ?? {};
      const { currentPage, total } = paginationData;
      listResult = listResult.concat(productList);
      if (currentPage === total) {
        remainPage = false;
      } else {
        pageIndex++;
      }
    }

    const dataToExport = map(listResult, (item) => ({
      Name: item?.name,
      SKU: item?.sku,
      Discount: item?.discount,
    }));
    exportToCSV(dataToExport, "GP Configuration");
  };

  const onUpdatedConfig = () => {
    tableRef.current.refresh();
    loadEventDetail();
  };

  if (eventChannelList?.length === 0) {
    return (
      <div className="card-container flex-center p-4 py-5 small mt-3">
        {t("beforeSelectWarehouseConfig")}
      </div>
    );
  }

  return (
    <div className="card-container p-4 ">
      <div className="d-flex justify-content-between align-items-center">
        <label>{t("gpConfiguration")}</label>
        <div className="d-flex">
          {renderButton("import", () => setOpenImport(true))}
          {renderButton("export", onExport)}
          {renderButton("add", () => setOpenAdd(true))}
          {renderButton("update", () => setOpenUpdate(true))}
          {renderButton("remove", () => setOpenRemove(true))}
        </div>
      </div>
      <AwesomeTableComponent
        ref={(ref) => {
          tableRef.current = ref;
        }}
        columns={columns}
        source={source}
        transformer={transformer}
        className="mt-3 height-fit-content"
      />
      {openAdd && (
        <GPEventDetailConfigAddModal
          open={openAdd}
          onClose={() => setOpenAdd(false)}
          onUpdated={onUpdatedConfig}
        />
      )}
      {openRemove && (
        <GPEventDetailConfigRemoveModal
          open={openRemove}
          onClose={() => setOpenRemove(false)}
          onUpdated={onUpdatedConfig}
        />
      )}
      {openUpdate && (
        <GPEventDetailConfigUpdateModal
          open={openUpdate}
          onClose={() => setOpenUpdate(false)}
          onUpdated={onUpdatedConfig}
        />
      )}

      {openImport && (
        <GPEventDetailConfigImportModal
          open={openImport}
          onClose={() => setOpenImport(false)}
          onImported={onUpdatedConfig}
        />
      )}
    </div>
  );
};

export default GPEventDetailConfig;
