import ButtonFileUpload from "@components/shared/ButtonFileUpload";
import classNames from "classnames";
import { InputColor } from "d-react-components";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

const ChannelFormPdfContract = ({
  form,
  defaultValue,
  fieldClassName = "",
}: any) => {
  const { t } = useTranslation();

  const classNameField = classNames("col-6 mt-3", fieldClassName);
  const formValues = form.values ?? {};

  const getDefaultFileUpload = (url: string) => {
    if (isEmpty(url)) {
      return [];
    }
    return [{ imageData: url, id: url }];
  };
  return (
    <div className="row">
      <InputColor
        label={t("primaryColor")}
        value={formValues?.pdfColorPrimary}
        className={classNameField}
        onChange={(event) =>
          form.setFieldValue("pdfColorPrimary", event.target.value)
        }
      />
      <div className={classNameField}>
        <label>{t("logo")}</label>
        <ButtonFileUpload
          defaultFiles={getDefaultFileUpload(defaultValue?.pdfLogo)}
          onChange={([value]: any) => {
            form.setFieldValue("pdfLogo", value?.key);
          }}
          maxFiles={1}
        />
      </div>
      <div className={classNameField}>
        <label>{t("iconHeader")}</label>
        <ButtonFileUpload
          defaultFiles={getDefaultFileUpload(defaultValue?.pdfIconHeader)}
          onChange={([value]: any) => {
            form.setFieldValue("pdfIconHeader", value?.key);
          }}
          maxFiles={1}
        />
      </div>
      <div className={classNameField}>
        <label>{t("footerIcon")}</label>
        <ButtonFileUpload
          defaultFiles={getDefaultFileUpload(defaultValue?.pdfIconFooter)}
          onChange={([value]: any) => {
            form.setFieldValue("pdfIconFooter", value?.key);
          }}
          maxFiles={1}
        />
      </div>
      <div className={classNameField}>
        <label>{t("backgroundHeader")}</label>
        <ButtonFileUpload
          defaultFiles={getDefaultFileUpload(defaultValue?.pdfBackgroundHeader)}
          onChange={([value]: any) => {
            form.setFieldValue("pdfBackgroundHeader", value?.key);
          }}
          maxFiles={1}
        />
      </div>
    </div>
  );
};

export default ChannelFormPdfContract;
