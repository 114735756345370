import ManageRequestRefundDrawer from "@components/order/detail/modal/ManageRequestRefundDrawer";
import { REFUND_STATUS } from "@constants/payment";
import AppContext from "@helpers/context";
import { Dropdown } from "d-react-components";
import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import AddBankRefundButton, { ModalAddBank } from "./AddBankRefundButton";

const RefundActionButton = ({ refund, onClickCancelRefund }: any) => {
  const { order, onClickRefundPrint } = useContext(AppContext);
  const [openMoreAction, setOpenMoreAction] = useState(false);
  const [showAddBankRefund, setShowAddBankRefund] = useState(false);
  const [showRequestRefund, setShowRequestRefund] = useState(false);

  const { t } = useTranslation();

  const isShowEditButton = useMemo(() => {
    const { status } = refund;
    return (
      status === REFUND_STATUS.pending.key ||
      status === REFUND_STATUS.rejected.key
    );
  }, [refund]);

  const isShowAddBank = useMemo(() => {
    const { status } = refund;
    return (
      status !== REFUND_STATUS.refunded.key &&
      status !== REFUND_STATUS.cancelled.key
    );
  }, [refund]);

  const REFUND_ACTIONS = useMemo(
    () => [
      { id: "print", label: t("print") },
      ...(isShowAddBank ? [{ id: "addBank", label: t("addbank") }] : []),
      ...(isShowEditButton ? [{ id: "edit", label: t("edit") }] : []),
      ...(isShowEditButton ? [{ id: "cancel", label: t("cancel") }] : []),
    ],
    [t, isShowEditButton]
  );

  const onClickAction = (item: any) => {
    switch (item.id) {
      case "addBank":
        setShowAddBankRefund(true);
        break;
      case "print":
        onClickRefundPrint(refund);
        break;
      case "edit":
        setShowRequestRefund(true);
        break;

      case "cancel":
        onClickCancelRefund();
        break;
      default:
        return;
    }
  };
  return (
    <div>
      <Dropdown
        title={t("action")}
        open={openMoreAction}
        onClose={() => setOpenMoreAction(false)}
        onBlur={() => setTimeout(() => setOpenMoreAction(false), 200)}
        dataSource={REFUND_ACTIONS}
        onClick={(item: any) => onClickAction(item)}
      ></Dropdown>
      {showAddBankRefund && (
        <ModalAddBank
          refund={refund}
          openModal={showAddBankRefund}
          onClose={() => setShowAddBankRefund(false)}
        />
      )}
      {showRequestRefund && (
        <ManageRequestRefundDrawer
          payload={refund}
          order={order}
          isEdit
          openModal={showRequestRefund}
          setOpenModal={setShowRequestRefund}
        />
      )}
    </div>
  );
};

export default RefundActionButton;
