import { API } from "@network";
import {
  API_CREATE_PROMOTION,
  API_DETAIL_PROMOTION,
  API_EDIT_PROMOTION,
  API_UPLOAD_PROMOTION_DOCUMENT,
  API_VALIDATE_SKU_PROMOTION,
  API_PROMOTION_LIST,
  API_PROMOTION_ACTIVITIES,
  API_PROMOTION_ACTIVITY_CREATE,
  API_PROMOTION_END,
  API_LOAD_PROMOTION,
} from "@network/URL";

export const listPromotion = (body: any, paging: any) => {
  return API.post(
    `${API_PROMOTION_LIST}?page=${paging.pageIndex}&per_page=${paging.pageSize}`,
    body
  );
};

export const getPromotionDetail = (body: any) => {
  return API.post(API_DETAIL_PROMOTION, body);
};

export const createPromotion = (body: any) => {
  return API.post(API_CREATE_PROMOTION, body);
};

export const updatePromotion = (body: any) => {
  return API.post(API_EDIT_PROMOTION, body);
};

export const validateSkuPromotion = (body: any, config: any) => {
  return API.post(API_VALIDATE_SKU_PROMOTION, body, config);
};

export const uploadPromotionDocument = (body: any) => {
  return API.post(API_UPLOAD_PROMOTION_DOCUMENT, body);
};

export const getPromotionActivities = (body: any, paging: any) => {
  return API.post(
    `${API_PROMOTION_ACTIVITIES}?page=${paging?.pageIndex}&per_page=${paging?.pageSize}`,
    body
  );
};

export const addPromotionComment = (body: any) => {
  return API.post(API_PROMOTION_ACTIVITY_CREATE, body);
};

export const endPromotion = (body: any) => {
  return API.post(API_PROMOTION_END, body);
};

export const loadPromotion = (code: string) => {
  return API.post(API_LOAD_PROMOTION, { code });
};

const PromotionAPI = {
  listPromotion,
};

export default PromotionAPI;
