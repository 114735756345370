import UserAuthQrCode from "@common/UserAuthQrCode";
import Drawer from "@components/shared/Drawer";
import UserAPI from "@network/api/user";
import { Switch } from "antd";
import { Button, InputTextSearch, Modal, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

function UserDetailAuthenticationUpdateModal({ open, onClose, defaultValue, userId, qrCodeName, onSuccess } : any) {
  const { t } = useTranslation();
  const authForm = useFormik({
    initialValues: defaultValue ?? {},
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      const body = {
        id: userId,
        authentication_status: values?.status,
        authentication_code: values?.code,
      };
      Progress.show({ method: UserAPI.authenticationUpdate, params: [body] }, (res) => {
        Notifications.showSuccess(t("updateUserSuccess"));
        onClose();
        onSuccess();
      });
    },
  });

  const onReGenerateCode = () => {
    Progress.show({ method: UserAPI.getSecretCode, params: [{}] }, (res: any) => {
      const { code } = res?.data?.data ?? {};
      authForm.setFieldValue("code", code);
    });
  };

  const { status, code } = authForm?.values;
  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("updateAuthentication")}
      onSave={() => authForm.handleSubmit()}
      size="large"
    >
      <div className="mb-3 flex-column">
        <label>{t("status")}</label>
        <div>
          <Switch
            checked={status}
            onChange={() => authForm.setFieldValue("status", !status)}
          />
        </div>
      </div>
      <div className="mb-3 flex-column" hidden={!status}>
        <div className="d-flex justify-content-between align-items-center">
          <label className="mb-0">{t("secretCode")}</label>
          <Button onClick={onReGenerateCode} variant="trans">
            {t("generate")}
          </Button>
        </div>
        <InputTextSearch value={code} disabled />
      </div>
      <div className="flex-column mb-3" hidden={!status}>
        <label>{t("qrCode")}</label>
        <UserAuthQrCode secretCode={code} name={qrCodeName} />
      </div>
    </Drawer>
  );
}

export default UserDetailAuthenticationUpdateModal;
