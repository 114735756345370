import PaymentContainer from "@components/order/components/payment/PaymentContainer";
import UserAvatarName from "@components/shared/UserAvatarName";
import { DEPOSIT_METHODS, DEPOSIT_STATUS_LIST } from "@constants/cash-deposit";
import AppContext from "@helpers/context";
import { Tooltip } from "antd";
import { Button, Icon, StringUtils, TimeUtils } from "d-react-components";
import { find, map, reduce } from "lodash";
import moment from "moment";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Carousel, { Modal, ModalGateway } from "react-images";
import CashDepositPaymentConfirmButtons from "./CashDepositPaymentConfirmButtons";
import CashDepositPaymentInfoDrawer from "./CashDepositPaymentInfoDrawer";
import { getBankFullInfo } from "@helpers/bank";

const pending = DEPOSIT_STATUS_LIST[0].id;
const pendingConfirm = DEPOSIT_STATUS_LIST[1].id;
const rejectStatus = DEPOSIT_STATUS_LIST[3].id;
const confirmed = DEPOSIT_STATUS_LIST.find(
  (item) => item.value === "confirm"
)?.value;

const rejected = DEPOSIT_STATUS_LIST.find(
  (item) => item.value === "reject"
)?.value;

const CashDepositPayment = ({ isPrintView }: any) => {
  const { cashDepositDetail } = useContext(AppContext);
  const { t } = useTranslation();
  const {
    status,
    payments,
    payment_upload = [],
    accountant_upload = [],
    method,
    confirm_by,
    confirm_payment,
    confirm_payment_note,
    confirm_actual_date_payment,
    confirm_actual_bank_payment,
  } = cashDepositDetail;

  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [modalImageView, setModalImageView] = useState<any>(false);

  const total = useMemo(() => {
    return reduce(payments, (sum, item) => sum + item?.total, 0);
  }, [payments]);

  const listAllImages = useMemo(() => {
    return [...payment_upload];
  }, [payment_upload, accountant_upload]);

  const isShowUploadPaymentDeposit = useMemo(() => {
    return (
      status === pending || status === pendingConfirm || status === rejectStatus
    );
  }, [status]);

  const isConfirmed = useMemo(() => {
    return status === confirmed;
  }, [status]);

  const isRejected = useMemo(() => {
    return status === rejected;
  }, [status]);

  const renderToolTip = (payment: any) => {
    if (!payment?.bank?.id || !payment?.fullname_transfer) {
      return <div />;
    }
    return (
      <CashDepositPaymentInfoDrawer
        isView
        defaultInfo={{
          fullNameTransfer: payment?.fullname_transfer ?? "",
          bankTransfer: payment?.bank?.id ?? "",
          dateTransfer: moment(payment?.date_transfer),
        }}
      />
    );
  };

  const renderPaymentUploadFiles = () => {
    return (
      <div className="banktranferImageContainer mt-2 w-full flex whitespace-nowrap overflow-x-auto py-2">
        {map(payment_upload, (paymentItem, index) => {
          const tooltip = renderToolTip && renderToolTip(paymentItem);
          return (
            <div
              className="wrapImagePayment mr-1 relative cursor-pointer"
              onClick={() => {
                setModalImageView({ isVisible: true, index });
              }}
            >
              <img
                className="fileUpload rounded object-contain bg-black m-1 ml-0 w-32 h-32"
                src={paymentItem.imageurl}
              />

              {tooltip && (
                <Tooltip
                  title={
                    <div className="containerTooltip bg-white p-2">
                      {tooltip}
                    </div>
                  }
                  overlayClassName="containerTooltip bg-white p-2"
                >
                  <Icon name="info" className="absolute -top-1 -right-1" />
                </Tooltip>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const renderDepositConfirmedView = () => {
    return (
      <div className="depositConfirmedContainer">
        <text className="">
          {`${t("confirmedBy")} ${confirm_by?.name} `}
          {`${t("at")} ${TimeUtils.toDate(confirm_payment)}`}
          {` "${confirm_payment_note}"`}
        </text>
        <text className="">
          {`${t("actualDateOfReceivingPayment")} ${TimeUtils.toDate(
            confirm_actual_date_payment
          )}`}
        </text>
        <text className="">
          {`${t("actualBankOfReceivingPayment")} ${
            confirm_actual_bank_payment?.number
          }(${confirm_actual_bank_payment?.name})`}
        </text>
      </div>
    );
  };

  const renderPaymentFooter = () => {
    const proofStatus = find(DEPOSIT_STATUS_LIST, {
      value: status,
    });

    return (
      <>
        {renderPaymentUploadFiles()}

        {isShowUploadPaymentDeposit && (
          <Button iconName="cloud" onClick={() => setOpenPaymentModal(true)}>
            {t("upload")}
          </Button>
        )}

        {/* {isConfirmed && renderDepositConfirmedView()}
        {isRejected && renderDepositRejectedView()} */}
        {(isConfirmed || isRejected) && (
          <div className={`p-3 mt-2 bg-${proofStatus?.bgColor}-100 text-sm`}>
            <div className="border-b border-gray-500 mb-3 pb-2">
              <span className="text-bold">{t("financeConfirmation")}</span>
            </div>
            {isConfirmed && (
              <div className="">
                <div className="flex items-center mb-1">
                  <span className="mr-2 font-weight-bold">{t("status")}:</span>{" "}
                  <span className="text-success">{t("approved")}</span>
                </div>
                <div className="flex items-center mb-1">
                  <span className="mr-2 font-weight-bold">
                    {t("confirmBy")}:
                  </span>{" "}
                  <UserAvatarName user={confirm_by} size="xx-small" />
                </div>
                <div className="flex items-center mb-1">
                  <span className="mr-2 font-weight-bold">
                    {t("confirmAt")}:
                  </span>{" "}
                  {TimeUtils.toDateTime(confirm_payment)}
                </div>
              </div>
            )}

            {isRejected && (
              <div className="">
                <div className="flex items-center mb-1">
                  <span className="mr-2 font-weight-bold">{t("status")}:</span>{" "}
                  <span className="text-error">{t("rejected")}</span>
                </div>
                <div className="flex items-center mb-1">
                  <span className="mr-2 font-weight-bold">
                    {t("rejectBy")}:
                  </span>{" "}
                  <UserAvatarName user={confirm_by} size="xx-small" />
                </div>
                <div className="flex items-center mb-1">
                  <span className="mr-2 font-weight-bold">
                    {t("rejectAt")}:
                  </span>{" "}
                  {TimeUtils.toDateTime(confirm_payment)}
                </div>
              </div>
            )}

            {/* <UploadPaymentInfoView
              paymentInfoList={accountant_upload}
              payment={cashDepositDetail}
              // onClickUploadButton={() => setOpenModalUpload(true)}
              // renderTooltip={renderTooltipFileUpload}
              imgClass="w-24 h-24"
            /> */}

            {confirm_actual_date_payment && (
              <div className="my-1 text-success">
                {`${t("actualDatePayment")}: ${TimeUtils.toDateTime(
                  confirm_actual_date_payment
                )}`}
              </div>
            )}

            {confirm_actual_bank_payment && (
              <div className="my-1 text-success">{`${t(
                "actualBankReceivePayment"
              )}: ${getBankFullInfo(confirm_actual_bank_payment)}`}</div>
            )}

            {map(accountant_upload, (fileUpload, index) => (
              <div
                className="wrapImagePayment mr-1 relative cursor-pointer"
                onClick={() => {
                  setModalImageView({
                    isVisible: true,
                    index: payment_upload?.length + index,
                  });
                }}
              >
                <img
                  className={`fileUpload rounded object-contain bg-black m-1 ml-0 w-24 h-24`}
                  src={fileUpload.imageurl}
                  alt=""
                />
              </div>
            ))}

            {confirm_payment_note && (
              <div className="mt-2">
                <div className="relative bg-white text-gray-800 p-2 mt-2 arrow-up-white">
                  {confirm_payment_note}
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  const depositMethod = find(DEPOSIT_METHODS, (item) => item?.id === method);

  return (
    <div className="card-container mt-3 p-3">
      <div className="label">{t("paymentInformation")}</div>
      <PaymentContainer
        renderButtons={<CashDepositPaymentConfirmButtons />}
        method={t(depositMethod?.label ?? "")}
        amount={StringUtils.moneyThaiFormat(total)}
        payment={{
          payment_slip: map(payment_upload, (item) => ({
            ...item,
            transfer_date: item.date_transfer,
            transfer_bank: item.bank,
          })),
        }}
      >
        {renderPaymentFooter()}
      </PaymentContainer>
      <CashDepositPaymentInfoDrawer
        open={openPaymentModal}
        onClose={() => setOpenPaymentModal(false)}
      />
      <ModalGateway>
        {modalImageView.isVisible ? (
          <Modal onClose={() => setModalImageView({ isVisible: false })}>
            <Carousel
              currentIndex={modalImageView.index}
              views={
                map([...payment_upload, ...accountant_upload], (file) => ({
                  caption: `${t("by")} ${file?.by?.name ?? " "}${t(
                    "at"
                  )} ${TimeUtils.toDateTime(file.created)}`,
                  src: file.imageurl,
                })) as any
              }
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};

export default CashDepositPayment;
