import { Path } from "@components/layout/Path";
import AppContext from "@helpers/context";
import MyAssetsAPI from "@network/api/my-assets";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink as Link, Outlet } from "react-router-dom";
import { isGrantURLPermission } from "@helpers/permissions";
import {
  API_MY_ASSETS_REQUEST_LIST,
  API_REQUEST_ASSETS_LIST,
  API_REQUEST_ASSETS_SUMMARY,
} from "@network/URL";
import { Icon } from "d-react-components";
import { useMedia } from "react-use";

interface MyAssetsSummary {
  channel?: number;
  role?: number;
  warehouse?: number;
  pending?: number;
}

const MyAssets = () => {
  const isMobile = useMedia("(max-width: 767px)");
  const [menuExpanded, setMenuExpanded] = useState(isMobile ? false : true);
  const [summary, setSummary] = useState<MyAssetsSummary>({});
  const [summaryMyRequests, setSummaryMyRequests] = useState<MyAssetsSummary>(
    {}
  );
  const [summaryRequestAccess, setSummaryRequestAccess] =
    useState<MyAssetsSummary>({});
  const { t } = useTranslation();

  const loadSummaryData = () => {
    MyAssetsAPI.info().then((resp) => {
      setSummary(resp?.data?.data);
    });
    MyAssetsAPI.summary().then((resp) => {
      setSummaryMyRequests(resp?.data?.data);
    });
    if (isGrantURLPermission(API_REQUEST_ASSETS_SUMMARY)) {
      MyAssetsAPI.adminSummary().then((resp) => {
        setSummaryRequestAccess(resp?.data?.data);
      });
    }
  };

  useEffect(() => {
    loadSummaryData();
  }, []);

  const classNameItem = classNames(
    "py-2 px-3 hover:bg-blue-100 w-full block flex left-menu-item"
  );

  return (
    <AppContext.Provider value={{ summary, loadSummaryData }}>
      <div className="p-3">
        <div className="grid grid-cols-4 gap-4">
          <div className="col-span-4 md:col-span-1">
            <div
              className={classNames(
                "bg-white flex items-center md:hidden w-100 px-3 pt-3",
                {
                  "pb-0": menuExpanded,
                  "pb-3": !menuExpanded,
                }
              )}
              onClick={() => {
                setMenuExpanded(!menuExpanded);
              }}
            >
              <h6 className="flex-1">{t("menu")}</h6>
              <span className="inline-block cursor-pointer flex items-center">
                <Icon
                  name={
                    menuExpanded ? "keyboard_arrow_up" : "keyboard_arrow_down"
                  }
                />
              </span>
            </div>
            <div
              className={classNames("bg-white p-3", { hidden: !menuExpanded })}
            >
              <div className="mb-3">
                <h6 className="mb-2">{t("myAssets")}</h6>
                <div className="">
                  <Link to={Path.MY_ASSETS_CHANNEL} className={classNameItem}>
                    <span className="flex-1">{t("channel")}</span>
                    <span className="text-gray-500">{summary.channel}</span>
                  </Link>
                  <Link to={Path.MY_ASSETS_WAREHOUSE} className={classNameItem}>
                    <span className="flex-1">{t("warehouse")}</span>
                    <span className="text-gray-500">{summary.warehouse}</span>
                  </Link>
                  <Link to={Path.MY_ASSETS_ROLE} className={classNameItem}>
                    <span className="flex-1">{t("role")}</span>
                    <span className="text-gray-500">{summary.role}</span>
                  </Link>
                </div>
              </div>
              {isGrantURLPermission(API_MY_ASSETS_REQUEST_LIST) && (
                <div className="mb-3">
                  <h6 className="mb-2">{t("myRequests")}</h6>
                  <div className="">
                    <Link
                      to={`${Path.MY_REQUEST_PARENT}/all`}
                      className={classNameItem}
                    >
                      <span className="flex-1">{t("all")}</span>
                    </Link>
                    <Link
                      to={`${Path.MY_REQUEST_PARENT}/pending`}
                      className={classNameItem}
                    >
                      <span className="flex-1">{t("pending")}</span>
                      <span className="text-gray-500">
                        {summaryMyRequests.pending}
                      </span>
                    </Link>
                    <Link
                      to={`${Path.MY_REQUEST_PARENT}/approved`}
                      className={classNameItem}
                    >
                      <span className="flex-1">{t("approved")}</span>
                      <span className="text-gray-500"></span>
                    </Link>
                    <Link
                      to={`${Path.MY_REQUEST_PARENT}/completed`}
                      className={classNameItem}
                    >
                      <span className="flex-1">{t("completed")}</span>
                      <span className="text-gray-500"></span>
                    </Link>
                    <Link
                      to={`${Path.MY_REQUEST_PARENT}/rejected`}
                      className={classNameItem}
                    >
                      <span className="flex-1">{t("rejected")}</span>
                      <span className="text-gray-500"></span>
                    </Link>
                    <Link
                      to={`${Path.MY_REQUEST_PARENT}/cancelled`}
                      className={classNameItem}
                    >
                      <span className="flex-1">{t("cancelled")}</span>
                      <span className="text-gray-500"></span>
                    </Link>
                  </div>
                </div>
              )}
              {isGrantURLPermission(API_REQUEST_ASSETS_LIST) && (
                <div>
                  <h6 className="mb-2">{t("changeRequestApproval")}</h6>
                  <div className="">
                    <Link
                      to={`${Path.MY_REQUEST_APPROVAL_PARENT}/all`}
                      className={classNameItem}
                    >
                      <span className="flex-1">{t("all")}</span>
                    </Link>
                    <Link
                      to={`${Path.MY_REQUEST_APPROVAL_PARENT}/pending`}
                      className={classNameItem}
                    >
                      <span className="flex-1">{t("pending")}</span>
                      <span className="text-gray-500">
                        {summaryRequestAccess.pending}
                      </span>
                    </Link>
                    <Link
                      to={`${Path.MY_REQUEST_APPROVAL_PARENT}/approved`}
                      className={classNameItem}
                    >
                      <span className="flex-1">{t("approved")}</span>
                      {/* <span className="text-gray-500">32</span> */}
                    </Link>
                    <Link
                      to={`${Path.MY_REQUEST_APPROVAL_PARENT}/completed`}
                      className={classNameItem}
                    >
                      <span className="flex-1">{t("completed")}</span>
                    </Link>
                    <Link
                      to={`${Path.MY_REQUEST_APPROVAL_PARENT}/rejected`}
                      className={classNameItem}
                    >
                      <span className="flex-1">{t("rejected")}</span>
                      {/* <span className="text-gray-500">32</span> */}
                    </Link>
                    <Link
                      to={`${Path.MY_REQUEST_APPROVAL_PARENT}/cancelled`}
                      className={classNameItem}
                    >
                      <span className="flex-1">{t("cancelled")}</span>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-span-4 md:col-span-3">
            <Outlet />
          </div>
        </div>
      </div>
    </AppContext.Provider>
  );
};

export default MyAssets;
