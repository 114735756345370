import { DrawerProps, Drawer } from "@components/shared/Drawer";
import AppContext from "@helpers/context";
import { endPromotion } from "@network/api/promotion";
import { Notifications, InputText, DialogManager } from "d-react-components";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

export default function PromotionEnd({ open, onClose }: DrawerProps) {
  const { promotionDetail, reloadPromotion } = useContext(AppContext);
  const { t } = useTranslation();

  const form = useFormik<any>({
    initialValues: {},
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      remark: Yup.string().required(t("fieldRequired")),
    }),
    onSubmit: (values) => {
      onClickEndPromotion();
    },
  });
  const { values, errors, handleSubmit } = form;

  const onClickEndPromotion = async () => {
    DialogManager.showConfirm(
      t("confirm"),
      t("notification:confirmEndPromotion"),
      () => {
        const body = {
          id: promotionDetail?.id,
          remark: values.remark,
        };
        return endPromotion(body)
          .then(() => {
            reloadPromotion();
            onClose && onClose();
            Notifications.showSuccess(t("notification:endPromotionSuccess"));
          })
          .catch((error: any) => {
            Notifications.showError(error?.message);
          });
      }
    );
  };

  return (
    <>
      <Drawer
        open={open}
        onClose={onClose}
        title={t("endThisPromotion")}
        onSave={handleSubmit}
      >
        <div className="pb-12">
          <InputText
            label={t("remark")}
            multiple
            rows={5}
            placeholder={t("notification:pleaseInputTheNote")}
            value={values.remark}
            onChange={(event: any) => {
              form.setFieldValue("remark", event?.target?.value);
            }}
            error={errors.remark as string}
          />
        </div>
      </Drawer>
    </>
  );
}
