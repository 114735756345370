import { Pagination } from "@interfaces/common";
import { API } from "@network";
import {
  API_ADD_BANK_REFUND,
  API_APPLY_CUSTOM_SIZE_GROUP,
  API_APPROVE_REJECT_REFUND,
  API_ATTRIBUTE_BY_CATEGORY,
  API_CANCEL_ORDER,
  API_CANCEL_REFUND,
  API_CHANGE_EXPIRED_DAY_ORDER,
  API_COMPLETE_REFUND,
  API_CREATE_BILLING_CUSTOMER,
  API_CREATE_CUSTOMER_SHIPPING,
  API_CREATE_ORDER,
  API_CREATE_PICKUP,
  API_CREATE_REFUND,
  API_DELETE_BANK_REFUND,
  API_DELETE_ORDER,
  API_DELETE_PICK_UP,
  API_EDIT_BANK_REFUND,
  API_EDIT_PICKUP,
  API_EDIT_REFUND,
  API_GET_EMPLOYEE_DISCOUNT,
  API_GET_SALE_SOURCE,
  API_GET_SOURCE_ORDER,
  API_LIST_ALL_ORDER,
  API_LIST_SUMMARY_QUANTITY_ORDER,
  API_LOAD_FREE_GIFT_FROM_PRODUCT,
  API_LOAD_ORDER,
  API_LOAD_ORDER_EXPORT,
  API_LOGS_PAYMENT_ORDER,
  API_ORDER_ACTIVITY_PUBLIC,
  API_ORDER_CONFIRM_SPLIT_PAYMENT,
  API_ORDER_CREATE_SPLIT_PAYMENT,
  API_ORDER_DETAIL,
  API_ORDER_DETAIL_PUBLIC,
  API_ORDER_MT_APPROVE_SUSPICIOUS,
  API_ORDER_MT_DISCOUNT_EVENT,
  API_ORDER_MT_DISCOUNT_MANUAL,
  API_ORDER_PAYMENT_METHODS,
  API_ORDER_PAYMENT_OPTIONS,
  API_ORDER_PAYMENT_UPDATE_METHOD,
  API_ORDER_UPLOAD_ORDER_CONFIRM,
  API_ORDER_UPLOAD_SPLIT_PAYMENT,
  API_REQUEST_DISCOUNT_CREATE,
  API_REQUEST_DISCOUNT_LIST_MANAGER,
  API_REQUEST_MANAGER_DISCOUNT,
  API_RESET_PAYMENT_STATUS,
  API_SEND_MAIL_ORDER_CONFIRM,
  API_SEND_MAIL_ORDER_INVOICE,
  API_SHIPPING_PROFILE,
  API_SIGNATURE_PICK_UP,
  API_UPDATE_CUSTOMER_ORDER,
  API_UPDATE_ORDER_INFO,
  API_UPDATE_ORDER_SHIPPING,
  API_UPDATE_SALE_SOURCE,
  API_UPDATE_STATUS_ORDER,
  API_UPLOAD_ATTACHMENTS_REFUND,
  API_UPLOAD_COMPLETE_REFUND,
  API_ORDER_APPROVE_CUSTOM_SIZE,
  API_SHOPE_ORDER_LOAD,
  API_SHOPE_SHOP_LIST,
  API_CHECK_ORDER_SHOPE,
  API_LAZADA_ORDER_LOAD,
  API_LAZADA_SHOP_LIST,
  API_SEARCH_SALE_PERSON,
  API_ORDER_METADATA,
} from "@network/URL";

export const listAllOrder = (
  body: any,
  paging: Pagination,
  cancelToken: any
) => {
  return API.post(
    `${API_LIST_ALL_ORDER}?page=${paging.pageIndex}&per_page=${paging.pageSize}`,
    body,
    cancelToken
  );
};

export const listSummaryQuantityOrder = (body: any, cancelToken: any) => {
  return API.post(API_LIST_SUMMARY_QUANTITY_ORDER, body, cancelToken);
};

export const loadOrderExport = (body: any, currentPage: string | number) => {
  return API.post(
    `${API_LOAD_ORDER_EXPORT}?page=${currentPage}&per_page=100`,
    body
  );
};

export const getSaleOrderSource = () => {
  return API.get(API_GET_SALE_SOURCE);
};

export const createOrder = (body: any) => API.post(API_CREATE_ORDER, body);

export const mtDiscountManualList = (body: any) =>
  API.post(API_ORDER_MT_DISCOUNT_MANUAL, body);

export const mtDiscountEventList = (body: any) =>
  API.post(API_ORDER_MT_DISCOUNT_EVENT, body);

export const publicDetail = (params: any) =>
  API.get(API_ORDER_DETAIL_PUBLIC, { params });

export const publicActivity = (params: any) =>
  API.get(API_ORDER_ACTIVITY_PUBLIC, { params });

export const mtApproveSuspicious = (params: any) =>
  API.post(API_ORDER_MT_APPROVE_SUSPICIOUS, params);

export const approveCustomSizeDetected = (params: any) =>
  API.post(API_ORDER_APPROVE_CUSTOM_SIZE, params);

export const paymentOptions = (params: any) =>
  API.get(API_ORDER_PAYMENT_OPTIONS, { params });

export const applyCustomSizeGroupProduct = (body: any) => {
  return API.post(API_APPLY_CUSTOM_SIZE_GROUP, body);
};

export const validateCartRuleOrder = (body: any) => {
  return API.post(API_LOAD_FREE_GIFT_FROM_PRODUCT, body);
};

export const discountRequestGetListManager = (
  search?: any,
  paging = { pageIndex: 1 }
) => {
  return API.get(API_REQUEST_DISCOUNT_LIST_MANAGER);
};

export const createDiscountRequest = (body: any) => {
  return API.post(API_REQUEST_DISCOUNT_CREATE, body);
};

export const getEmployeeDiscount = (body: any) => {
  return API.post(API_GET_EMPLOYEE_DISCOUNT, body);
};

export const requestManagerDiscount = (body: any) => {
  return API.post(API_REQUEST_MANAGER_DISCOUNT, body);
};

export const loadOrder = (id: string) => {
  return API.post(API_LOAD_ORDER, { id });
};

export const getAttributeFromCategory = (body: any) => {
  return API.post(API_ATTRIBUTE_BY_CATEGORY, body);
};

export const createBillingCustomer = (body: any) => {
  return API.post(API_CREATE_BILLING_CUSTOMER, body);
};

export const updateOrderNote = (body: any) => {
  return API.post(API_UPDATE_ORDER_INFO, body);
};

export const deleteOrder = (ids: string[]) => {
  return API.post(API_DELETE_ORDER, { ids });
};

export const getOrderDetail = (id: string) => {
  return API.post(API_ORDER_DETAIL, { id });
};

export const createPickup = (body: any) => {
  return API.post(API_CREATE_PICKUP, body);
};

export const editPickup = (body: any) => {
  return API.post(API_EDIT_PICKUP, body);
};

export const signPickup = (body: any) => {
  return API.post(API_SIGNATURE_PICK_UP, body);
};

export const deletePickupItem = (body: any) => {
  return API.post(API_DELETE_PICK_UP, body);
};

export const getSourceOrder = (orderid: string) => {
  return API.post(API_GET_SOURCE_ORDER, { orderid });
};

export const getOrderPaymentMethods = (body: any) => {
  return API.post(API_ORDER_PAYMENT_METHODS, body);
};

export const getPaymentOptions = (params: any) =>
  API.get(API_ORDER_PAYMENT_OPTIONS, { params });

export const updateStatusOrder = (id: string, status: string) => {
  const body = { id, status };
  return API.post(API_UPDATE_STATUS_ORDER, body);
};

export const resetPaymentStatus = (body: any) => {
  return API.post(API_RESET_PAYMENT_STATUS, body);
};

export const changeExpiredDayOrder = (id: string, expire: string) => {
  const body = { id, expire };
  return API.post(API_CHANGE_EXPIRED_DAY_ORDER, body);
};

export const sendEmailOrderConfirm = (body: any) => {
  return API.post(API_SEND_MAIL_ORDER_CONFIRM, body);
};

export const sendEmailOrderInvoice = (body: any) => {
  return API.post(API_SEND_MAIL_ORDER_INVOICE, body);
};

export const cancelOrder = (body: any) => {
  return API.post(API_CANCEL_ORDER, body);
};

export const editRefund = (body: any) => {
  return API.post(API_EDIT_REFUND, body);
};

export const createRefund = (body: any) => {
  return API.post(API_CREATE_REFUND, body);
};

export const uploadAttachmentRefund = (body: any) => {
  return API.post(API_UPLOAD_ATTACHMENTS_REFUND, body);
};

export const orderCreateSplitPayment = (orderid: any, split = []) => {
  const body = { orderid, split };
  return API.post(API_ORDER_CREATE_SPLIT_PAYMENT, body);
};

export const getLogsPaymentOrder = (id: string, paging = { pageIndex: 1 }) => {
  return API.post(`${API_LOGS_PAYMENT_ORDER}?page=${paging.pageIndex}`, { id });
};

export const uploadImageOrderConfirm = (body: any) => {
  return API.post(API_ORDER_UPLOAD_ORDER_CONFIRM, body);
};

export const orderConfirmSplitPayment = (body: any) => {
  return API.post(API_ORDER_CONFIRM_SPLIT_PAYMENT, body);
};

export const orderPaymentUpdateMethod = (body: any) => {
  return API.post(API_ORDER_PAYMENT_UPDATE_METHOD, body);
};

export const uploadOrderSplitPayment = (body: any) => {
  return API.post(API_ORDER_UPLOAD_SPLIT_PAYMENT, body);
};

export const approveRejectRefund = (body: any) => {
  return API.post(API_APPROVE_REJECT_REFUND, body);
};

export const cancelRefund = (body: any) => {
  return API.post(API_CANCEL_REFUND, body);
};

export const completeRefund = (body: any) => {
  return API.post(API_COMPLETE_REFUND, body);
};

export const uploadCompleteRefund = (body: any) => {
  return API.post(API_UPLOAD_COMPLETE_REFUND, body);
};

export const deleteBankRefund = (body: any) => {
  return API.post(API_DELETE_BANK_REFUND, body);
};

export const editBankRefund = (body: any) => {
  return API.post(API_EDIT_BANK_REFUND, body);
};

export const addBankRefund = (body: any) => {
  return API.post(API_ADD_BANK_REFUND, body);
};

export const updateSourceSaleOrder = (body: any) => {
  return API.post(API_UPDATE_SALE_SOURCE, body);
};

export const updateCustomerOrder = (body: any) => {
  return API.post(API_UPDATE_CUSTOMER_ORDER, body);
};

export const loadOrderShopeShop = (body: any) => {
  return API.post(API_SHOPE_ORDER_LOAD, body);
};

export const listShopeShop = (body: any, paging?: any) => {
  return API.post(API_SHOPE_SHOP_LIST, body);
};

export const checkOrderShope = (orderShopeId: any) => {
  return API.post(API_CHECK_ORDER_SHOPE, { order_shopee_id: orderShopeId });
};

export const loadOrderLazadaShop = (body: any) => {
  return API.post(API_LAZADA_ORDER_LOAD, body);
};

export const listLazadaShop = (body: any, paging?: any) => {
  return API.post(API_LAZADA_SHOP_LIST, body);
};

export const searchSalePerson = (body: any, params: any) => {
  return API.post(`${API_SEARCH_SALE_PERSON}`, body, { params });
};

export const getOrderMetadata = (body: any, params?: any) => {
  return API.post(`${API_ORDER_METADATA}`, body, { params });
};
