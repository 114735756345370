import AppContext from "@helpers/context";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

export default function SourceOfOrder() {
  const { source } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <div className="bg-white mb-3 p-3 shadow-sm">
      <label className="block">{t("sourceOfOrder")}</label>
      <div className="text contentContainer">
        <div>{t("syncfromwebsite")}</div>
        <a href={source?.website?.domain ?? ""} rel="noreferrer" target="_blank" title={t("gotosourcewebsite")}>
          {source?.website?.domain ?? ""}
        </a>
        <div>{`- ${t("orderid")} :${source?.id}` ?? ""}</div>
        <div>{`- ${t("item")} :${source?.items}` ?? ""}</div>
        <div>{`- ${t("total")} :${source?.total}` ?? "฿"}</div>
      </div>
    </div>
  );
}
