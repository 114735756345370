import { filter, includes } from "lodash";

export enum ContractStatus {
  PENDING = "pending",
  SIGNED = "signed",
  PROCESSING = "processing",
  ONBOARD = "on-board",
  COMPLETED = "completed",
  CANCELLED = "cancelled",
  TERMINATED = "terminated",
  PARTIALLY_PAID = "partially-paid",
  DELIVERY_PROCESSING = "delivery-processing",
  DELIVERED = "delivered",
  REFUNDED = "refunded",
  PAID = "paid",
}

export enum ContractPaymentStatus {
  PENDING = "pending",
  PARTIALLY_PAID = "partially-paid",
  COMPLETED = "completed",
  CANCELLED = "cancelled",
}

export enum ContractPaymentProofStatus {
  PENDING = "PENDING",
  UPLOADED = "UPLOADED",
  REJECTED = "REJECTED",
  APPROVED = "APPROVED",
}

enum ContractSource {
  ONLINE = "online",
  STANDALONE = "standalone",
}

export enum ContractTypeOfPayment {
  INVOICE = "INVOICE",
  SUBSCRIPTION = "SUBSCRIPTION",
}

export enum ContractProofStatus {
  REJECTED = "REJECTED",
  APPROVE = "APPROVED",
}

export const CONTRACT_STATUS = [
  {
    value: ContractStatus.PENDING,
    label: "pending",
    color: "#FAC256",
  },
  {
    value: ContractStatus.SIGNED,
    label: "signed",
    color: "#D76C9C",
  },
  {
    value: ContractStatus.PARTIALLY_PAID,
    label: "partiallyPaid",
    color: "#33B950",
  },
  {
    value: ContractStatus.PROCESSING,
    label: "processing",
    color: "#84C4FF",
  },
  {
    value: ContractStatus.ONBOARD,
    label: "active",
    color: "#33B950",
  },
  {
    value: ContractStatus.COMPLETED,
    label: "completed",
    color: "#33B950",
  },
  {
    value: ContractStatus.CANCELLED,
    label: "cancelled",
    color: "#C4C4C4",
  },
  {
    value: ContractStatus.TERMINATED,
    label: "terminated",
    color: "#C4C4C4",
  },
  {
    value: ContractStatus.DELIVERED,
    label: "delivered",
    color: "#33B950",
  },
  {
    value: ContractStatus.DELIVERY_PROCESSING,
    label: "deliveryProcessing",
    color: "#FFBD59",
  },
  {
    value: ContractStatus.REFUNDED,
    label: "refunded",
    color: "#C4C4C4",
  },
  {
    value: ContractStatus.PAID,
    label: "paid",
    color: "#33B950",
  },
];

export const CONTRACT_STATUS_FOR_FILTER = filter(CONTRACT_STATUS, (status) => {
  return includes(
    [
      ContractStatus.PENDING,
      ContractStatus.CANCELLED,
      ContractStatus.SIGNED,
      ContractStatus.ONBOARD,
      ContractStatus.COMPLETED,
      ContractStatus.TERMINATED,
      ContractStatus.PROCESSING,
      ContractPaymentStatus.PARTIALLY_PAID,
    ],
    status.value
  );
});

export const CONTRACT_PAYMENT_STATUS = [
  {
    value: ContractPaymentStatus.PENDING,
    label: "pending",
    bgColor: "amber",
    color: "#FAC256",
  },
  {
    value: ContractPaymentStatus.PARTIALLY_PAID,
    label: "partiallyPaid",
    bgColor: "blue",
    color: "#84C4FF",
  },
  {
    value: ContractPaymentStatus.COMPLETED,
    label: "fullyPaid",
    bgColor: "green",
    color: "#33B950",
  },
  {
    value: ContractPaymentStatus.CANCELLED,
    label: "cancelled",
    bgColor: "gray",
    color: "#D30F0F",
  },
];

export const CONTRACT_INVOICE_PAYMENT_STATUS = [
  {
    value: ContractPaymentStatus.COMPLETED,
    label: "completed",
    bgColor: "green",
    color: "#33B950",
  },
  ...CONTRACT_PAYMENT_STATUS,
];

export const CONTRACT_PAYMENT_PROOF_STATUS = [
  {
    value: ContractPaymentProofStatus.PENDING,
    label: "pendingSubmission",
    bgColor: "amber",
    color: "#FAC256",
  },
  {
    value: ContractPaymentProofStatus.APPROVED,
    label: "approved",
    bgColor: "green",
    color: "#33B950",
  },
  {
    value: ContractPaymentProofStatus.REJECTED,
    label: "rejected",
    bgColor: "red",
    color: "#ED6969",
  },
  {
    value: ContractPaymentProofStatus.UPLOADED,
    label: "pendingApproval",
    bgColor: "amber",
    color: "#FAC256",
  },
];

export const CONTRACT_SOURCE = [
  {
    value: ContractSource.ONLINE,
    label: "online",
  },
  {
    value: ContractSource.STANDALONE,
    label: "standaloneStore",
  },
];
