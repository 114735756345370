import { Spin } from "antd";
import { Icon } from "d-react-components";
import { forEach, map } from "lodash";
import { useContext, useEffect, useState } from "react";
import { CSVLink } from "react-csv";

import AppContext from "@helpers/context";
import ProductLabelAPI from "@network/api/product-label-print";
import { useTranslation } from "react-i18next";
import { getImportProductLabel } from "./ProductLabelImportValidation";

const ProductLabelImportResult = () => {
  const { formImport } = useContext(AppContext);
  const { template, importFields, fileImport } = formImport.values;
  const [errorValidation, setErrorValidation] = useState([]);
  const [loadingImport, setLoadingImport] = useState(true);
  const { t } = useTranslation();

  useEffect(() => onImport(), [fileImport.data]);

  const onImport = () => {
    const body = {
      label: template,
      fields: map(importFields, (item) => item.name),
      products: map(fileImport.data, (data) =>
        getImportProductLabel(data, importFields)
      ),
    };

    ProductLabelAPI.importProductLabel(body)
      .then((res) => {
        setLoadingImport(false);
      })
      .catch((error) => {
        setLoadingImport(false);
      });
  };

  const getErrorItemsData = () => {
    const header = [t("code"), "Message"];
    const dataResult = [header];
    errorValidation.forEach((validation: any) => {
      forEach(validation?.validation ?? [], (item) =>
        dataResult.push([validation?.code_code, item])
      );
    });
    return dataResult;
  };

  const getSuccessItemsData = () => {
    const header = [t("code")];
    const dataResult = [header];
    forEach(fileImport.data ?? [], (data) => {
      dataResult.push([data?.code_code]);
    });
    return dataResult;
  };

  if (loadingImport) {
    return (
      <div className="p-4 flex-center">
        <Spin />
      </div>
    );
  }
  return (
    <div className="p-4">
      <div className="d-flex mt-3">
        <Icon name="check_circle_outline" className="mr-3 text-success" />
        <div>
          {fileImport.data?.length - errorValidation?.length}{" "}
          {t("skuImportedSuccessfully")}
        </div>
        <CSVLink data={getSuccessItemsData()}>
          <div className="ml-1 text-primary">{t("downloadLog")}</div>
        </CSVLink>
      </div>

      <div className="d-flex mt-3">
        <Icon name="error" className="mr-3 text-error" />
        <div>
          {errorValidation?.length} {t("skuFailedToImport")}
        </div>
        <CSVLink data={getErrorItemsData()}>
          <div className="ml-1 text-primary">{t("downloadLog")}</div>
        </CSVLink>
      </div>
    </div>
  );
};

export default ProductLabelImportResult;
