import classNames from "classnames";
import { Button } from "d-react-components";
import React from "react";
import { useTranslation } from "react-i18next";

const LabelCheckboxSelect = ({ label, onSelectAll, onDeselectAll, className }: any) => {
  const { t } = useTranslation();
  const classNameContainer = classNames("mt-3 d-flex justify-content-between align-items-center", className);
  return (
    <div className={classNameContainer}>
      <label>{label}</label>
      <div className="d-flex">
        <Button variant="trans" onClick={onDeselectAll} className="">
          {t("deselectAll")}
        </Button>
        <Button variant="trans" onClick={onSelectAll} className="">
          {t("selectAll")}
        </Button>
      </div>
    </div>
  );
};

export default LabelCheckboxSelect;
