import { IDistrict, ISubdistrict } from "@interfaces/address";
import { IFieldSelect } from "@interfaces/select";
import { getDistrictList, getSubDistrictList } from "@network/api/address";
import { ObjectUtils, Select } from "d-react-components";
import { isArray, isEmpty, map } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export interface ISubdistrictSelect<T> extends IFieldSelect<T> {
  [key: string]: any;
  districtId: string;
}

const SubdistrictSelect = ({
  value = [],
  multiple,
  className,
  onChange,
  districtId,
  ...selectProps
}: ISubdistrictSelect<ISubdistrict>) => {
  const getInitialValue = () => {
    if (!value) return [];
    if (isArray(value)) return value;
    return [value];
  };
  const [subdistrictList, setSubdistrictList] = useState<any[]>(
    getInitialValue()
  );

  const { t } = useTranslation();

  const onChangeValue = (id: any) => {
    if (multiple) {
      onChange(
        map(id, (item) => ObjectUtils.findItemFromId(subdistrictList, item))
      );
      return;
    }
    onChange(ObjectUtils.findItemFromId(subdistrictList, id));
  };

  const subdistrictValue = useMemo(() => {
    if (multiple) {
      return map(value, (item: any) => item.id);
    }
    return (value as any)?.id;
  }, [value]);

  useEffect(() => {
    if (isEmpty(districtId)) return;
    loadSubdistrictList();
  }, [districtId]);

  const loadSubdistrictList = () => {
    getSubDistrictList(districtId).then((res) => {
      const subdistrict = res?.data?.data?.subdistrict ?? [];
      setSubdistrictList(subdistrict);
    });
  };

  return (
    <Select
      className={className}
      label={t("subdistrict")}
      dataSource={subdistrictList}
      getLabel={(item) => item?.name}
      value={subdistrictValue}
      onChange={onChangeValue}
      multiple={multiple}
      placeholder={t("pleaseSelect")}
      showSearch
      disabled={subdistrictList.length === 0}
      {...selectProps}
    />
  );
};

export default SubdistrictSelect;
