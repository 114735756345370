import { Button, Notifications, Progress } from "d-react-components";
import { map, join, find } from "lodash";
import React, { useContext, useState } from "react";
import UserDetailSettingUpdateModal from "./UserDetailSettingUpdateModal";
import AppContext from "@helpers/context";
import { POSITION_LABELS } from "@constants/user";
import UserInfoRow from "@common/UserInfoRow";
import { useTranslation } from "react-i18next";
import UserAPI from "@network/api/user";
import ImageIcon from "@common/Icon";

const UserDetailSetting = () => {
  const { user, loadUserDetail } = useContext(AppContext);
  const { t } = useTranslation();
  const [openEdit, setOpenEdit] = useState(false);
  const {
    promotion_status = [],
    block,
    manager_order_status,
    delivery_status,
    is_store,
    has_call_center,
    call_center_id,
    call_center_email,
    position_label,
  } = user ?? {};
  // const promotionStatus = map(promotion_status, (item) => item.name);
  // const promotionStatusId = map(promotion_status, (item) => item.id);
  const positionLabel = find(
    POSITION_LABELS,
    (item) => item.id === position_label
  );

  const onUpdateSetting = (settingValue: any) => {
    const body = { id: user?.id, ...settingValue };
    Progress.show({ method: UserAPI.update, params: [body] }, () => {
      Notifications.showSuccess(t("updateUserSuccess"));
      setOpenEdit(false);
      loadUserDetail();
    });
  };

  return (
    <div className="border mt-3 p-4">
      <div className="d-flex justify-content-between align-items-center">
        <h5>{t("settings")}</h5>
        <span onClick={() => setOpenEdit(true)}>
          <ImageIcon
            className="w-[15px] cursor-pointer"
            src="/images/icons/edit.svg"
          />
        </span>
      </div>
      <div>
        {/* <UserInfoRow
          label={t("promotionStatus")}
          value={join(promotionStatus, ", ")}
        /> */}
        <UserInfoRow label={t("storeType")} value={t(is_store)} />
        <UserInfoRow
          label={t("positionLabel")}
          value={t(positionLabel?.label as string)}
        />
        <UserInfoRow label={t("blockUser")} value={block ? "Yes" : "No"} />
        <UserInfoRow
          label={t("hasCallCenter")}
          value={has_call_center ? "Yes" : "No"}
        />
        <UserInfoRow label={t("callCenterId")} value={call_center_id} />
        <UserInfoRow label={t("callCenterEmail")} value={call_center_email} />
        <UserInfoRow
          label={t("orderStatusManagement")}
          value={
            <div className="flex-column">
              {map(manager_order_status, (item) => (
                <div>{t(item)}</div>
              ))}
            </div>
          }
        />
        <UserInfoRow
          label={t("deliveryStatusManagement")}
          value={
            <div className="flex-column">
              {map(delivery_status, (item) => (
                <div>{t(item)}</div>
              ))}
            </div>
          }
        />
      </div>
      {openEdit && (
        <UserDetailSettingUpdateModal
          open={openEdit}
          onClose={() => setOpenEdit(false)}
          onUpdate={onUpdateSetting}
          defaultValue={{
            is_store: user.is_store,
            // promotion_status: promotionStatusId,
            block,
            manager_order_status,
            delivery_status,
            has_call_center,
            call_center_id,
            call_center_email,
            position_label,
          }}
        />
      )}
    </div>
  );
};

export default UserDetailSetting;
