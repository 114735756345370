import { Icon, Notifications, Button, TimeUtils } from "d-react-components";
import {
  join,
  map,
  reduce,
  find,
  maxBy,
  forEach,
  compact,
  sumBy,
} from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import FilterOrderModal from "./OrderFilter";
import {
  ADDITIONAL_DISCOUNT,
  GP_DISCOUNT,
  INTERNAL_DISCOUNT,
  MEMBER_DISCOUNT,
  MT_CREDIT_DISCOUNT,
} from "@constants/discount";
import { useTranslation } from "react-i18next";
import { getPaymentMethods } from "@helpers/account";
import { ORDER_STATUS_STRING } from "@constants/order";
import { loadOrderExport } from "@network/api/order";
import { exportToCSV } from "@helpers/file";
import { getPaymentStatus } from "@helpers/payment";
import { getFullName } from "@helpers/string";
import { getIsEmployeeDiscountValue } from "./OrderTable";

const DAYS_90 = 90 * 24 * 3600 * 1000;
const ExportOrderModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const [filterBody, setFilterBody] = useState<any>({});
  const [loadingExport, setLoadingExport] = useState(false);
  const [percentageExport, setPercentageExport] = useState(0);
  const [exportOption, setExportOption] = useState("reportNAV");
  const enableExportRef = useRef(false);

  const PAYMENTS_METHODS = getPaymentMethods();

  const validateData = () => {
    if (!filterBody.from || !filterBody.to) {
      Notifications.showError(t("notification:fromDateAndToDateIsRequired"));
      return false;
    }

    if (filterBody.to - filterBody.from > DAYS_90) {
      Notifications.showError(t("notification:dateRangeIsNot90"));
      return false;
    }

    return true;
  };

  const onClickExport = async () => {
    if (!validateData()) {
      return;
    }
    setLoadingExport(true);
    const {
      channels = [],
      status = [],
      createdby = [],
      method = [],
      payment = [],
      isEmployeeDiscount,
      products = [],
    } = filterBody;
    const { salesPerson, ...restFilter } = filterBody;
    const filterData = {
      ...restFilter,
      warehouse: map(filterBody.warehouse, (item) => item.id),
      employee_discount_code: filterBody.employeeDiscount,
      isEmployeeDiscount: getIsEmployeeDiscountValue(isEmployeeDiscount),
      products: filterBody?.products?.map((item: any) => item.id),
      sales_person_id: compact(salesPerson?.map((item: any) => item.id)),
    };
    if (channels.length === 0) {
      delete filterData.channels;
    }
    if (products.length === 0) {
      delete filterData.products;
    }

    if (status.length === 0) {
      delete filterData.status;
    }

    if (createdby.length === 0) {
      delete filterData.createdby;
    }

    if (method.length === 0) {
      delete filterData.method;
    }

    if (payment.length === 0) {
      delete filterData.payment;
    }

    if (exportOption === "reportByItemShopee") {
      filterData.referral_source = "shopee";
    }

    if (exportOption === "reportByItemLazada") {
      filterData.referral_source = "lazada";
    }

    let remainPage = true;
    let orderListResult: any[] = [];
    let pageIndex = 1;
    enableExportRef.current = true;
    while (remainPage && enableExportRef.current) {
      const response = await loadOrderExport(filterData, pageIndex);
      const orderList = response?.data?.data?.orders ?? [];
      const paginationData = response?.data?.data?.pagination ?? {};
      const { currentPage, total } = paginationData;
      orderListResult = orderListResult.concat(orderList);
      if (currentPage === total) {
        remainPage = false;
        setPercentageExport(100);
      } else {
        pageIndex++;
        setPercentageExport(Math.round((currentPage / total) * 100));
      }
    }
    // console.log("orderListResult", orderListResult)
    const fileDataOrders = convertToOrderFileData(orderListResult);
    // console.log("fileDataOrders", fileDataOrders);
    const from = TimeUtils.convertMiliToDate(filterData?.from);
    const to = TimeUtils.convertMiliToDate(filterData?.to);
    const fileName = `OrderExport_${exportOption}_${from}_${to}`;
    exportToCSV(fileDataOrders, fileName);
    setLoadingExport(false);
    enableExportRef.current = false;
    onClose();
  };

  const getCustomerFullAddress = (shipping: any) => {
    if (!shipping) {
      return "";
    }
    return `${shipping?.address} ${shipping?.subdistrict?.name ?? ""} ${
      shipping?.city?.name
    } ${shipping?.province?.name} ${shipping?.postcode}`;
  };

  const convertToOrderFileData = (orderList: any[]) => {
    switch (exportOption) {
      case "reportByPayment":
        const orderPaymentList: any[] = [];
        orderList.forEach((order) => {
          const listPayment = order?.payment ?? [];
          listPayment.forEach((payment: any) => {
            orderPaymentList.push({ ...order, payment });
          });
        });
        // console.log("ORDER PAYMENT LIST", orderPaymentList);
        return orderPaymentList?.map((item) => {
          const confirmBank = item?.payment?.confirm_bank;
          const confirmBankInfo = confirmBank
            ? `${confirmBank.bank_number}(${confirmBank.bank_name} - ${confirmBank.bank_account})`
            : "";
          return {
            "Order ID": `${item?.orderid}`,
            "3rd Party Order ID": item?.ref_order_id ?? "",
            Channel: item?.channel?.name,
            "Company Receive": item?.channel?.company,
            "Bank Account No.": confirmBank?.bank_number,
            "Created By ID": item?.by?.companyId,
            "Created By": item?.by?.name,
            "Sale person": item?.salesPerson?.fullname,
            "Order status": t(ORDER_STATUS_STRING[item.status]),
            "Order note": item?.note,
            "Customer name": getFullName(item?.contact),
            Tel: item?.contact?.phone,
            "Channel payment": item?.payment?.method,
            "Slip Uploaded Date": item?.payment?.confirm_payment_at
              ? TimeUtils.convertMiliToDate(item.payment.confirm_payment_at)
              : "0",
            "Slip Uploaded Time": item?.payment?.confirm_payment_at
              ? TimeUtils.convertMiliToTime(item.payment.confirm_payment_at)
              : "0",

            "Actual bank of receiving payment": confirmBankInfo,
            "Actual date of receiving payment": item?.payment
              ?.confirm_actual_date_payment
              ? TimeUtils.convertMiliToDate(
                  item?.payment?.confirm_actual_date_payment
                )
              : "0",
            "Actual time of receiving payment": item?.payment
              ?.confirm_actual_date_payment
              ? TimeUtils.convertMiliToTime(
                  item?.payment?.confirm_actual_date_payment
                )
              : "0",
            "Payment amount": item?.payment?.total,
            "Accountant Person": item?.payment?.confirm_payment_by?.name,
          };
        });
      case "reportByItem":
      case "reportByItemMarketing":
        const orderItemList: any[] = [];
        orderList.forEach((order) => {
          const listProduct: any[] = order?.products ?? [];
          listProduct.forEach((productItem) => {
            const deliveryProduct: any[] = [];
            const listDelivery: any[] = order?.delivery ?? [];
            listDelivery.forEach((delivery) => {
              const itemOfDelivery = delivery?.items?.map(
                (item: any) => item?.order_product?.product?.id
              );
              if (itemOfDelivery.includes(productItem?.product?.id)) {
                deliveryProduct.push(delivery);
              }
            });
            const deliveryInfo = find(
              order?.delivery,
              (delivery) => delivery.status !== "cancelled"
            );

            orderItemList.push({
              ...order,
              product: {
                ...productItem,
                discount:
                  order.discount * (productItem.total / order.sub_total),
              },
              deliveryProduct,
              deliveryInfo,
            });
          });
        });
        // console.log("ORDER ITEM LIST", orderItemList);
        const orderItemPaymentList: any[] = [];
        orderItemList.forEach((orderItem) => {
          const listPayment = orderItem?.payment ?? [];
          const listOldPayment = orderItem?.oldPayment ?? [];
          [...listPayment, ...listOldPayment].forEach((payment) => {
            orderItemPaymentList.push({ ...orderItem, payment });
          });
        });
        return orderItemPaymentList?.map((item: any) => {
          const confirmBank = item?.payment?.confirm_bank;
          const confirmBankInfo = confirmBank
            ? `${confirmBank.bank_number}(${confirmBank.bank_name} - ${confirmBank.bank_account})`
            : "";
          const skuGroupCompositions = map(
            item?.product?.groups ?? [],
            (groupItem) =>
              `${groupItem?.product?.sku} (x${groupItem?.quantity})`
          );
          const skuComposition = join(skuGroupCompositions, ", ");
          return {
            "Order ID": `${item?.orderid}`,
            "3rd Party Order ID": item?.ref_order_id ?? "",
            Channel: item?.channel?.name,
            ...(exportOption === "reportByItemMarketing"
              ? {
                  Warehouse: item?.store?.name,
                  "Warehouse System Code": item?.store?.warehouse_system_code,
                  "Warehouse NAV Code": item?.store?.code,
                }
              : {}),
            "Company receiver": item?.channel?.company,
            "Created By ID": item?.by?.companyId,
            "Created By": item?.by?.name,
            "Sale person": item?.salesPerson?.fullname,
            "Bank Account No.": confirmBank?.bank_number,
            "Channel payment": item?.payment?.method,
            "Slip Uploaded Date": item?.payment?.confirm_payment_at
              ? TimeUtils.convertMiliToDate(item.payment.confirm_payment_at)
              : "0",
            "Slip Uploaded Time": item?.payment?.confirm_payment_at
              ? TimeUtils.convertMiliToTime(item.payment.confirm_payment_at)
              : "0",
            "Actual bank of receiving payment": confirmBankInfo,
            "Actual date of receiving payment": item?.payment
              ?.confirm_actual_date_payment
              ? TimeUtils.convertMiliToDate(
                  item?.payment?.confirm_actual_date_payment
                )
              : "0",
            "Actual time of receiving payment": item?.payment
              ?.confirm_actual_date_payment
              ? TimeUtils.convertMiliToTime(
                  item?.payment?.confirm_actual_date_payment
                )
              : "0",
            "Order status": t(ORDER_STATUS_STRING[item.status]),
            "Order note": item?.note,
            "Customer name": getFullName(item?.contact),
            ...(exportOption === "reportByItemMarketing"
              ? {
                  "Customer address": item?.deliveryInfo?.shipping?.address,
                  "Customer province":
                    item?.deliveryInfo?.shipping?.province?.name,
                  "Customer district": item?.deliveryInfo?.shipping?.city?.name,
                  "Customer subdistrict":
                    item?.deliveryInfo?.shipping?.subdistrict?.name,
                  "Customer postal code":
                    item?.deliveryInfo?.shipping?.postcode,
                  "Customer Phone No.": item?.contact?.phone,
                  "Customer Email": item?.contact?.email ?? "",
                  "Customer DOB": item?.customer?.birth
                    ? TimeUtils.convertMiliToDateWithFormat(
                        item?.customer?.birth,
                        "YYYY-MM-DD"
                      )
                    : "",
                }
              : {}),
            "Total discount (THB)": item?.discount,
            "SKU/Barcode": item?.product?.product?.sku,
            "Product name": item?.product?.product?.name,
            ...(exportOption === "reportByItemMarketing"
              ? {
                  Brand: item?.product?.product?.brand?.name,
                }
              : {}),
            "Created date": getTimeToExport(item?.created),
            "Confirmed date": getTimeToExport(
              maxBy(item?.payment ?? [], (item: any) => item.confirm_payment_at)
                ?.confirm_payment_at
            ),
            "Delivery type": join(
              (item?.deliveryProduct ?? [])?.map(
                (item: any) => item?.delivery?.name
              ),
              ","
            ),
            Quantity: item?.product?.quantity,
            Unit: "PCS",
            "Unit price": item?.product?.sale_price,
            "Line amount": item?.product?.quantity * item?.product?.sale_price,
            ...(exportOption === "reportByItemMarketing"
              ? {
                  "Item Discount": item?.product?.discount?.toFixed(2),
                  "Sales Amount (Net)": (
                    item?.product?.total - item?.product?.discount
                  )?.toFixed(2),
                }
              : {}),
            "SKU Composition": skuComposition,
          };
        });
      case "reportByItemShopee":
      case "reportByItemLazada":
        const orderItemShopeeList: any[] = [];
        orderList.forEach((order) => {
          const listProduct: any[] = order?.products ?? [];
          listProduct.forEach((productItem) => {
            const groupProduct = productItem.groups ?? [];
            if (groupProduct.length > 0) {
              forEach(groupProduct, (groupProductItem) => {
                orderItemShopeeList.push({
                  ...order,
                  dummySKU: productItem?.product?.sku,
                  product: groupProductItem,
                  delivery: order?.delivery?.[0],
                });
              });
            } else {
              orderItemShopeeList.push({
                ...order,
                product: productItem,
                dummySKU: productItem?.product?.sku,
                delivery: order?.delivery?.[0],
              });
            }
          });
        });
        const itemPaymentShopeeList: any[] = [];
        orderItemShopeeList.forEach((orderItem) => {
          const listPayment = orderItem?.payment ?? [];
          const listOldPayment = orderItem?.oldPayment ?? [];
          [...listPayment, ...listOldPayment].forEach((payment) => {
            itemPaymentShopeeList.push({ ...orderItem, payment });
          });
        });
        return map(itemPaymentShopeeList, (item) => {
          const delivery = item?.delivery;
          const shipping = delivery?.shipping ?? null;

          const lineAmount =
            item?.product?.quantity * item?.product?.sale_price;
          const lineAmountTotalOrder = reduce(
            itemPaymentShopeeList.filter(
              (itemParam) => item?.orderid === itemParam.orderid
            ),
            (sum, item) =>
              sum + item?.product?.quantity * item?.product?.sale_price,
            0
          );

          const sellerVoucher = find(
            item.voucher,
            (vo) => vo.slug === "voucher_from_seller"
          );
          const sellerDiscount = find(
            item.voucher,
            (vo) => vo.slug === "seller_discount"
          );

          const voucherFromShope = find(
            item.voucher,
            (vo) => vo.slug === "voucher_from_shopee"
          );
          const voucherFromShopeTotal =
            (lineAmount * (voucherFromShope?.total ?? 0)) /
            lineAmountTotalOrder;

          const coinVoucher = find(item.voucher, (vo) => vo.slug === "coins");
          const coinVoucherTotal =
            (lineAmount * (coinVoucher?.total ?? 0)) / lineAmountTotalOrder;

          const shopeDiscount = find(
            item.voucher,
            (vo) => vo.slug === "shopee_discount"
          );
          const shopeDiscountTotal =
            (lineAmount * (shopeDiscount?.total ?? 0)) / lineAmountTotalOrder;

          const totalDiscount =
            (sellerVoucher?.total ?? 0) + (sellerDiscount?.total ?? 0);
          const totalThirdDiscount =
            (voucherFromShopeTotal ?? 0) +
            (coinVoucherTotal ?? 0) +
            (shopeDiscountTotal ?? 0);

          const totalSkuDiscount =
            (lineAmount * totalDiscount) / lineAmountTotalOrder;

          return {
            "Order ID": `${item?.orderid}`,
            "3rd Party Order ID": item?.ref_order_id ?? "",
            Channel: item?.channel?.name,
            "Company receiver": item?.channel?.company,
            "Created By ID": item?.by?.companyId,
            "Created By": item?.by?.name,
            "Sale person": item?.salesPerson?.fullname,
            "Channel payment": item?.payment?.method,
            "Slip Uploaded Date": item?.payment?.confirm_payment_at
              ? TimeUtils.convertMiliToDate(item.payment.confirm_payment_at)
              : "0",
            "Slip Uploaded Time": item?.payment?.confirm_payment_at
              ? TimeUtils.convertMiliToTime(item.payment.confirm_payment_at)
              : "0",
            "Customer name": getFullName(item?.contact),
            "Customer address": getCustomerFullAddress(shipping),
            "Customer province": shipping?.province?.name ?? "",
            "Customer city": shipping?.city?.name ?? "",
            "Customer subdistrict": shipping?.subdistrict?.name ?? "",
            "Customer postal code": shipping?.postcode ?? "",
            Tel: shipping?.phone ?? "",
            "Dummy barcode": item.dummySKU,
            "SKU/Barcode": item?.product?.product?.sku,
            "Product name": item?.product?.product?.name,
            Quantity: item?.product?.quantity,
            Unit: "PCS",
            "Unit price": item?.product?.sale_price,
            "Total discount (THB)": totalDiscount,
            "Total 3rd Parties Discount (THB)": totalThirdDiscount,
            "SKU Discount": totalSkuDiscount,
            "Final price": lineAmount - totalSkuDiscount,
          };
        });
      case "reportByPrice":
        return getDataReportByPrice(orderList);

      default:
        const orderDeliveryList: any[] = [];
        orderList.forEach((order) => {
          const deliveryList: any[] = order?.delivery ?? [];
          deliveryList.forEach((deliveryItem) => {
            deliveryItem.items.forEach((productItem: any, index: string) => {
              if (productItem.stock && productItem.stock > 0) {
                orderDeliveryList.push({
                  ...order,
                  delivery: deliveryItem,
                  product: productItem,
                  itemLineNo: index + 1,
                });
              }
            });
          });
        });
        return orderDeliveryList?.map((item) => {
          const delivery = item?.delivery;
          const shipping = delivery?.shipping ?? null;
          switch (exportOption) {
            case "reportByShipping":
              return {
                "Order ID": `${item?.orderid}`,
                "3rd Party Order ID": item?.ref_order_id ?? "",
                "DO No.": item?.delivery?.code,
                Channel: item?.channel?.name,
                "Company receiver": item?.channel?.company,
                "Created By ID": item?.by?.companyId,
                "Created By": item?.by?.name,
                "Sale person": item?.salesPerson?.fullname,
                "Order status": t(ORDER_STATUS_STRING[item.status]),
                "Order note": item?.note,
                "Customer name": getFullName(shipping),
                "Customer address": getCustomerFullAddress(shipping),
                "Customer province": shipping?.province?.name ?? "",
                "Customer city": shipping?.city?.name ?? "",
                "Customer subdistrict": shipping?.subdistrict?.name ?? "",
                "Customer postal code": shipping?.postcode ?? "",
                Tel: shipping?.phone ?? "",
                "Channel payment": join(
                  (item?.payment ?? [])?.map(
                    (paymentItem: any) => paymentItem.method
                  ),
                  ","
                ),
                "Payment status": getPaymentStatus(item?.payment_status)?.label,
                "SKU/Barcode": item?.product?.order_product?.product?.sku,
                "Product name": item?.product?.order_product?.product?.name,
                "Created date": getTimeToExport(item?.created),
                "Confirmed date": getTimeToExport(
                  maxBy(
                    item?.payment ?? [],
                    (item: any) => item.confirm_payment_at
                  )?.confirm_payment_at
                ),
                "Delivery type": item?.delivery?.delivery?.name,
                "Created shipment date": getTimeToExport(
                  item?.delivery?.created
                ),
                "Actual shipment date": getTimeToExport(
                  item?.delivery?.arrived
                ),
                Quantity: item?.product?.stock ?? 0,
                Unit: "PCS",
              };
            case "reportNAVModernTrade":
              const defaultDiscount = { total: 0, discount: 0 };
              const paymentList = item?.payment ?? [];
              const discountChains = item?.discount_chain ?? [];
              const discountExternals = item?.fee_external ?? [];
              const salePrice =
                (item?.product?.order_product?.sale_price ?? 0) *
                (item?.product?.stock ?? 0);

              const internalDiscount1 =
                find(
                  discountChains,
                  (item) => item.discount_level === `${INTERNAL_DISCOUNT}_1`
                ) ?? defaultDiscount;
              const internalDiscount2 =
                find(
                  discountChains,
                  (item) => item.discount_level === `${INTERNAL_DISCOUNT}_2`
                ) ?? defaultDiscount;
              const internalDiscount3 =
                find(
                  discountChains,
                  (item) => item.discount_level === `${INTERNAL_DISCOUNT}_3`
                ) ?? defaultDiscount;
              const memberDiscount =
                find(
                  discountChains,
                  (item) => item.discount_level === MEMBER_DISCOUNT
                ) ?? defaultDiscount;
              const additionalDiscount =
                find(
                  discountChains,
                  (item) => item.discount_level === ADDITIONAL_DISCOUNT
                ) ?? defaultDiscount;
              const mtCreditCardDiscount =
                find(
                  discountChains,
                  (item) => item.discount_level === MT_CREDIT_DISCOUNT
                ) ?? defaultDiscount;
              const GPDiscount = find(
                discountExternals,
                (item) => item.ref === GP_DISCOUNT
              ) ?? { fee: 0, total: 0 };

              const interDiscountTotal1 =
                (salePrice * internalDiscount1?.discount) / 100 ?? 0;
              const interDiscountTotal2 =
                ((salePrice - interDiscountTotal1) *
                  internalDiscount2?.discount) /
                  100 ?? 0;
              const interDiscountTotal3 =
                ((salePrice - interDiscountTotal1 - interDiscountTotal2) *
                  internalDiscount3?.discount) /
                  100 ?? 0;

              const memberDiscountTotal =
                ((salePrice -
                  interDiscountTotal1 -
                  interDiscountTotal2 -
                  interDiscountTotal3) *
                  memberDiscount?.discount) /
                  100 ?? 0;

              const totalOrderBeforeAD =
                item?.total +
                  additionalDiscount.total +
                  mtCreditCardDiscount.total ?? 0;
              const totalItemBeforeAD =
                salePrice -
                interDiscountTotal1 -
                interDiscountTotal2 -
                interDiscountTotal3 -
                memberDiscountTotal;
              const ratioDiscountTotalOrder =
                additionalDiscount.total / totalOrderBeforeAD;
              const additionalDiscountItem =
                totalItemBeforeAD * ratioDiscountTotalOrder;

              const creditDiscountTotal =
                ((salePrice -
                  interDiscountTotal1 -
                  interDiscountTotal2 -
                  interDiscountTotal3 -
                  memberDiscountTotal -
                  additionalDiscountItem) *
                  mtCreditCardDiscount?.discount) /
                  100 ?? 0;

              const GPDiscountTotal =
                ((salePrice -
                  interDiscountTotal1 -
                  interDiscountTotal2 -
                  interDiscountTotal3) *
                  GPDiscount?.fee) /
                  100 ?? 0;

              const amountIncludeVAT =
                salePrice -
                interDiscountTotal1 -
                interDiscountTotal2 -
                interDiscountTotal3 -
                GPDiscountTotal;
              const amountExcludeVAT = amountIncludeVAT / 1.07;

              return {
                "Warehouse/Branch Code": "CLS-SH009",
                "Mobile app SO Ref No.": item?.orderid,
                "Payment method": join(
                  map(
                    paymentList,
                    (paymentItem) =>
                      find(
                        PAYMENTS_METHODS,
                        (item) => item.id === paymentItem.method
                      )?.translate
                  ),
                  ","
                ),
                "Bank Of Instalment": join(
                  map(
                    paymentList,
                    (paymentItem) => paymentItem?.bank_name?.code
                  ),
                  ","
                ),
                "Instalment Term (month)": join(
                  map(
                    paymentList,
                    (paymentItem) => paymentItem?.installment_loan_tenure
                  ),
                  ","
                ),
                "Mobile app shipment Ref No.": item?.delivery?.code ?? "",
                "Order date": getTimeToExport(item?.created),
                "Requested delivery date": getTimeToExport(delivery?.created),
                "Promised delivery date": getTimeToExport(delivery?.estimated),
                "Customer name": getFullName(shipping),
                "Customer name 2": shipping?.nickname ?? "",
                "Customer address": getCustomerFullAddress(shipping),
                "Customer subdistrict": shipping?.subdistrict?.name ?? "",
                "Customer city": shipping?.city?.name ?? "",
                "Customer province": shipping?.province?.name ?? "",
                "Customer postal code": shipping?.postcode ?? "",
                "Customer contact": getFullName(shipping),
                "Customer phone No.": shipping?.phone ?? "",
                "Sale person": item?.salesPerson?.fullname,
                "Your reference": item?.orderid,
                "Logistic type": "1",
                "Item No.": item?.product?.order_product?.product?.sku ?? "",
                "Item line No.": (item?.itemLineNo ?? 0) * 100,
                "Give away":
                  item?.product?.order_product?.sale_price !== 0 ? 1 : 0,

                Qty: item?.product?.stock ?? 0,
                UOM: "PC",
                "Sale Unit Price":
                  item?.product?.order_product?.sale_price ?? 0,
                "Sale Price": salePrice,
                "Internal Discount 1 (%)": internalDiscount1?.discount ?? 0,
                "Internal Discount 1 (Amount)": interDiscountTotal1?.toFixed(2),
                "Internal Discount 2 (%)": internalDiscount2?.discount ?? 0,
                "Internal Discount 2 (Amount)": interDiscountTotal2?.toFixed(2),
                "Internal Discount 3 (%)": internalDiscount3?.discount ?? 0,
                "Internal Discount 3 (Amount)": interDiscountTotal3?.toFixed(2),
                "MT Member Discount (%)": memberDiscount?.discount ?? 0,
                "MT Member Discount (Amount)": memberDiscountTotal?.toFixed(2),
                "MT Additional Discount (Method)": additionalDiscount
                  ? "Voucher"
                  : "",
                "MT Additional Discount (Amount)":
                  additionalDiscountItem?.toFixed(2),
                "MT Credit Card Discount (%)":
                  mtCreditCardDiscount?.discount ?? 0,
                "MT Credit Card Discount (Amount)":
                  creditDiscountTotal?.toFixed(2),
                "Discount GP (%)": GPDiscount?.fee ?? 0,
                "Discount GP (Amount)": GPDiscountTotal?.toFixed(2),
                "Amount (Excl. VAT)": (amountExcludeVAT ?? 0)?.toFixed(2),
                "Amount (Incl. VAT)": (amountIncludeVAT ?? 0)?.toFixed(2),
                "Invoice discount amount": (
                  (amountIncludeVAT * item.mt_sharing_discount) / 100 ?? 0
                )?.toFixed(2),
              };
            case "reportForPowerBI":
              return {
                Channel: item?.channel?.name,
                "Warehouse/Branch Name": "CLS-SH009",
                "Mobile app SO Ref No.": item?.orderid,
                "3rd Party Order ID": item?.ref_order_id ?? "",
                "Payment method": join(
                  (item?.payment ?? [])?.map(
                    (paymentItem: any) => paymentItem.method
                  ),
                  ","
                ),
                "Mobile app shipment Ref No.": item?.delivery?.code ?? "",
                "Order date": getTimeToExport(item?.created),
                "Requested delivery date": getTimeToExport(delivery?.created),
                "Promised delivery date": getTimeToExport(delivery?.estimated),
                "Customer name": getFullName(shipping),
                "Customer name 2": shipping?.nickname ?? "",
                "Customer address": getCustomerFullAddress(shipping),
                "Customer subdistrict": shipping?.subdistrict?.name ?? "",
                "Customer city": shipping?.city?.name ?? "",
                "Customer province": shipping?.province?.name ?? "",
                "Customer postal code": shipping?.postcode ?? "",
                "Customer contact": getFullName(shipping),
                "Customer phone No.": shipping?.phone ?? "",
                "Sale person": item?.salesPerson?.fullname,
                "Your reference": item?.orderid,
                "Logistic type": "1",
                "Invoive discount amount": getInVoiceDiscountAmount(item),
                "item line No.": (item?.itemLineNo ?? 0) * 100,
                "Item No.": item?.product?.order_product?.product?.sku ?? "",
                "Item Name": item?.product?.order_product?.product?.name ?? "",
                Category:
                  item?.product?.order_product?.product?.category?.name ??
                  "N/A",
                Brand:
                  item?.product?.order_product?.product?.brand?.name ?? "N/A",
                Model:
                  item?.product?.order_product?.product?.model?.name ?? "N/A",
                Size:
                  item?.product?.order_product?.product?.size?.name ?? "N/A",
                Qty: item?.product?.stock ?? 0,
                UOM: "PC",
                "Give away":
                  item?.product?.order_product?.sale_price !== 0 ? 1 : 0,
                "sale unit price":
                  item?.product?.order_product?.sale_price ?? 0,
              };
            default:
              return {
                "Warehouse/Branch Code": "CLS-SH009",
                "Mobile app SO Ref No.": item?.orderid,
                "3rd Party Order ID": item?.ref_order_id ?? "",
                "Payment method": join(
                  (item?.payment ?? [])?.map(
                    (paymentItem: any) => paymentItem.method
                  ),
                  ","
                ),
                "Mobile app shipment Ref No.": item?.delivery?.code ?? "",
                "Order date": getTimeToExport(item?.created),
                "Requested delivery date": getTimeToExport(delivery?.created),
                "Promised delivery date": getTimeToExport(delivery?.estimated),
                "Customer name": getFullName(shipping),
                "Customer name 2": shipping?.nickname ?? "",
                "Customer address": getCustomerFullAddress(shipping),
                "Customer subdistrict": shipping?.subdistrict?.name ?? "",
                "Customer city": shipping?.city?.name ?? "",
                "Customer province": shipping?.province?.name ?? "",
                "Customer postal code": shipping?.postcode ?? "",
                "Customer contact": getFullName(shipping),
                "Customer phone No.": shipping?.phone ?? "",
                "Sale person": item?.salesPerson?.fullname,
                "Your reference": item?.orderid,
                "Logistic type": "1",
                "Invoive discount amount": getInVoiceDiscountAmount(item),
                "item line No.": (item?.itemLineNo ?? 0) * 100,
                "Item No.": item?.product?.order_product?.product?.sku ?? "",
                Qty: item?.product?.stock ?? 0,
                UOM: "PC",
                "Give away":
                  item?.product?.order_product?.sale_price !== 0 ? 1 : 0,
                "sale unit price":
                  item?.product?.order_product?.sale_price ?? 0,
              };
          }
        });
    }
  };

  const getDataReportByPrice = (orderList: any[] = []) => {
    const orderItemList: any[] = [];
    let reportByPriceData: any[] = [];
    forEach(orderList, (order: any) => {
      const listProduct: any[] = order?.products ?? [];
      listProduct.forEach((productItem) => {
        const deliveryProduct: any[] = [];
        const listDelivery: any[] = order?.delivery ?? [];
        listDelivery.forEach((delivery) => {
          const itemOfDelivery = delivery?.items?.map(
            (item: any) => item?.order_product?.product?.id
          );
          if (itemOfDelivery.includes(productItem?.product?.id)) {
            deliveryProduct.push(delivery);
          }
        });
        orderItemList.push({
          ...order,
          product: productItem,
          deliveryProduct,
        });
      });
    });
    // console.log("ORDER ITEM LIST", orderItemList);
    const orderItemPaymentList: any[] = [];
    forEach(orderItemList, (orderItem) => {
      const listPayment = orderItem?.payment ?? [];
      const listOldPayment = orderItem?.oldPayment ?? [];
      [...listPayment, ...listOldPayment].forEach((payment) => {
        orderItemPaymentList.push({ ...orderItem, payment });
      });
    });

    const lineAmountTotal = reduce(
      orderItemPaymentList,
      (sum, item) => sum + item?.product?.quantity * item?.product?.sale_price,
      0
    );

    reportByPriceData = orderItemPaymentList?.map((item) => {
      const confirmBank = item?.payment?.confirm_bank;
      const confirmBankInfo = confirmBank
        ? `${confirmBank.bank_number}(${confirmBank.bank_name} - ${confirmBank.bank_account})`
        : "";

      const lineAmount = item?.product?.quantity * item?.product?.sale_price;
      const lineAmountTotalOrder = reduce(
        orderItemPaymentList.filter(
          (itemParam) => item?.orderid === itemParam.orderid
        ),
        (sum, item) =>
          sum + item?.product?.quantity * item?.product?.sale_price,
        0
      );

      const sellerVoucher = find(
        item.voucher,
        (vo) => vo.slug === "voucher_from_seller"
      );
      const sellerVoucherTotal =
        (lineAmount * (sellerVoucher?.total ?? 0)) / lineAmountTotalOrder;

      const voucherFromShope = find(
        item.voucher,
        (vo) => vo.slug === "voucher_from_shopee"
      );
      const voucherFromShopeTotal =
        (lineAmount * (voucherFromShope?.total ?? 0)) / lineAmountTotalOrder;

      const sellerDiscount = find(
        item.voucher,
        (vo) => vo.slug === "seller_discount"
      );
      const sellerDiscountTotal =
        (lineAmount * (sellerDiscount?.total ?? 0)) / lineAmountTotalOrder;

      const coinVoucher = find(item.voucher, (vo) => vo.slug === "coins");
      const coinVoucherTotal =
        (lineAmount * (coinVoucher?.total ?? 0)) / lineAmountTotalOrder;

      const shopeDiscount = find(
        item.voucher,
        (vo) => vo.slug === "shopee_discount"
      );
      const shopeDiscountTotal =
        (lineAmount * (shopeDiscount?.total ?? 0)) / lineAmountTotalOrder;

      const totalDiscount =
        item?.referral_source === "shopee"
          ? (sellerVoucher?.total ?? 0) + (sellerDiscount?.total ?? 0)
          : item?.discount;
      const totalSkuDiscount =
        (lineAmount * totalDiscount) / lineAmountTotalOrder;

      const totalThirdDiscount =
        (voucherFromShopeTotal ?? 0) +
        (coinVoucherTotal ?? 0) +
        (shopeDiscountTotal ?? 0);

      return {
        "Order ID": `${item?.orderid}`,
        "3rd Party Order ID": item?.ref_order_id ?? "",
        Channel: item?.channel?.name,
        Warehouse: item?.store?.name,
        "Warehouse Code": item?.store?.code,
        "Company receiver": item?.channel?.company,
        "Created By ID": item?.by?.companyId,
        "Created By": item?.by?.name,
        "Sale person": item?.salesPerson?.fullname,
        "Bank Account No.": confirmBank?.bank_number,
        "Channel payment": item?.payment?.method,
        "Slip Uploaded Date": item?.payment?.confirm_payment_at
          ? TimeUtils.convertMiliToDate(item.payment.confirm_payment_at)
          : "0",
        "Slip Uploaded Time": item?.payment?.confirm_payment_at
          ? TimeUtils.convertMiliToTime(item.payment.confirm_payment_at)
          : "0",
        "Actual bank of receiving payment": confirmBankInfo,
        "Actual date of receiving payment": item?.payment
          ?.confirm_actual_date_payment
          ? TimeUtils.convertMiliToDate(
              item?.payment?.confirm_actual_date_payment
            )
          : "0",
        "Actual time of receiving payment": item?.payment
          ?.confirm_actual_date_payment
          ? TimeUtils.convertMiliToTime(
              item?.payment?.confirm_actual_date_payment
            )
          : "0",
        "Order status": t(ORDER_STATUS_STRING[item.status]),
        "Order note": item?.note,
        "Customer name": getFullName(item?.contact),

        "SKU/Barcode": item?.product?.product?.sku,
        "Product name": item?.product?.product?.name,
        Brand: item?.product?.product?.brand?.name,
        "Created date": getTimeToExport(item?.created),
        "Confirmed date": getTimeToExport(
          maxBy(item?.payment ?? [], (item: any) => item.confirm_payment_at)
            ?.confirm_payment_at
        ),
        "Delivery type": join(
          (item?.deliveryProduct ?? [])?.map(
            (item: any) => item?.delivery?.name
          ),
          ","
        ),
        Quantity: item?.product?.quantity,
        Unit: "PCS",
        "Unit price": item?.product?.sale_price,
        "Line amount": lineAmount,
        "Total discount (THB)": totalDiscount,
        "Total 3rd Parties Discount (THB)": totalThirdDiscount,
        "SKU discount": totalSkuDiscount,
        "Final price": lineAmount - totalSkuDiscount,
      };
    });
    //footer
    reportByPriceData.push({
      "Order ID": ``,
      "3rd Party Order ID": "",
      Channel: "",
      "Company receiver": "",
      "Created By ID": "",
      "Created By": "",
      "Sale person": "",
      "Bank Account No.": "",
      "Channel payment": "",
      "Slip Uploaded Date": "",
      "Slip Uploaded Time": "",
      "Actual bank of receiving payment": "",
      "Actual date of receiving payment": "",
      "Actual time of receiving payment": "",
      "Order status": "",
      "Order note": "",
      "Customer name": "",

      "SKU/Barcode": "",
      "Product name": "",
      "Created date": "",
      "Confirmed date": "",
      "Delivery type": "",
      Quantity: "",
      Unit: "",
      "Unit price": "",
      "Line amount": lineAmountTotal,
      "Total discount (THB)": "",
      "SKU discount": reduce(
        reportByPriceData,
        (sum, item) => sum + item["SKU discount"],
        0
      ),
      "Final price": reduce(
        reportByPriceData,
        (sum, item) => sum + item["Final price"],
        0
      ),
    });
    return reportByPriceData;
  };

  const getInVoiceDiscountAmount = (orderExport: any) => {
    const totalDiscount = orderExport?.discount ?? 0;

    const totalAmountDelivery = reduce(
      orderExport?.delivery?.items ?? [],
      (sum, item) => {
        return (
          sum + (item?.order_product?.sale_price ?? 0) * (item?.stock ?? 0)
        );
      },
      0
    );

    return (totalDiscount * totalAmountDelivery) / orderExport.sub_total;
  };

  const getTimeToExport = (time: string) => {
    if (!time) {
      return "";
    }

    return TimeUtils.convertMiliToDateTime(time);
  };

  const renderButton = () => {
    return (
      <div className="d-flex justify-content-between align-items-center w-100 px-3 py-3">
        <label>{t("export")}</label>
        <div className="flex-center-y">
          <Button variant="outline" className="mr-3" onClick={() => onClose()}>
            {t("cancel")}
          </Button>
          <Button onClick={onClickExport} disabled={loadingExport}>
            <Icon className="w-6" name="cloud_download_icon" />
            {t("export")}
          </Button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    return () => {
      enableExportRef.current = false;
    };
  }, []);

  return (
    <FilterOrderModal
      openModal={open}
      onClose={onClose}
      renderButton={() => renderButton()}
      filterBody={filterBody}
      onChangeFilter={(filterBody: any) => setFilterBody(filterBody)}
      isVisibleProgress={loadingExport}
      percentageProgress={percentageExport}
      isExport
      exportType={exportOption}
      onChangeExportType={(value: any) => {
        setExportOption(value);
      }}
    />
  );
};

export default ExportOrderModal;
