/* eslint-disable jsx-a11y/alt-text */
import Icon from "@common/Icon";
import Drawer from "@components/shared/Drawer";
import { PRODUCT_TYPE, PRODUCT_TYPES } from "@constants/product";
import AppContext from "@helpers/context";
import { IProduct } from "@interfaces/product";
import {
  IRowsKey,
  Notifications,
  Progress,
  ViewRowInterchange,
} from "d-react-components";
import { useFormik } from "formik";
import { find, isEmpty, map } from "lodash";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ProductInputConfiguration from "../input/ProductInputConfiguration";
import { editProduct } from "@network/api/product";

interface IProductDetailConfigurationDrawer {
  open: boolean;
  defaultValue?: any;
  onUpdated: any;
  onClose: () => void;
}

const ProductDetailConfigurationDrawer = ({
  open,
  defaultValue,
  onUpdated,
  onClose,
}: IProductDetailConfigurationDrawer) => {
  const { t } = useTranslation();
  const productForm = useFormik<any>({
    initialValues: defaultValue as any,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: schema,
    onSubmit: (values: any) => {
      onUpdateProduct(values);
    },
  });
  const formValues = productForm?.values;
  const formErrors = productForm?.errors;

  const onUpdateProduct = (values: any) => {
    const { productType, groups, product_custom_size, group_custom_size } =
      values;

    const isGroupProduct = productType === PRODUCT_TYPE.GROUP;
    const isGroupCustomSizeProduct =
      productType === PRODUCT_TYPE.GROUP_CUSTOM_SIZE;
    const isCustomSizeProduct = productType === PRODUCT_TYPE.CUSTOM_SIZE;

    const body: any = {
      productid: defaultValue?.id,

      is_group: isGroupProduct,
      product_type: productType,
    };

    if (isGroupProduct) {
      if (groups.length === 0) {
        Notifications.showError(t("groupProductIsEmpty"));
        return;
      }
      body.groups = map(groups, (item) => ({
        product_id: item?.id,
        quantity: item?.quantity,
        sale_price: item?.sale_price ?? 0,
      }));
    }

    if (isGroupCustomSizeProduct) {
      if (group_custom_size.length === 0) {
        Notifications.showError(t("groupCustomSizeProductIsEmpty"));
        return;
      }
      body.group_custom_size = map(group_custom_size, (item) => item.id);
    }

    if (isCustomSizeProduct) {
      if (!product_custom_size || !product_custom_size.product) {
        Notifications.showError(t("customSizeProductIsEmpty"));
        return;
      }
      const {
        product,
        min_length,
        max_length,
        min_width,
        max_width,
        coefficient,
      } = product_custom_size;
      if (
        isEmpty(`${min_length}`) ||
        isEmpty(`${max_length}`) ||
        isEmpty(`${min_width}`) ||
        isEmpty(`${max_width}`) ||
        isEmpty(`${coefficient}`)
      ) {
        Notifications.showError(t("pleaseFillAllCustomSizeFormulaField"));
        return;
      }
      body.product_custom_size = {
        product_id: product?.id,
        min_length,
        max_length,
        max_width,
        min_width,
        coefficient,
      };
    }

    Progress.show({ method: editProduct, params: [body] }, (res: any) => {
      Notifications.showSuccess(t("updateSuccess"));
      onUpdated();
      onClose();
    });
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("configuration")}
      size="auto"
      width="80vw"
      onSave={() => {
        productForm.handleSubmit();
      }}
    >
      <div>
        <ProductInputConfiguration productForm={productForm} />
      </div>
    </Drawer>
  );
};

const ProductDetailConfiguration = () => {
  const { productDetail, loadProductDetail } = useContext(AppContext);
  const [openEdit, setOpenEdit] = useState(false);
  const { t } = useTranslation();

  const DETAILS_KEY = useMemo<IRowsKey<IProduct>[]>(
    () => [
      {
        id: "productType",
        label: t("type"),
        renderContent: ({ data, item }) => {
          const productType = find(PRODUCT_TYPES, (item) => item.id === data);
          return t(productType?.label as any);
        },
      },
    ],
    [t]
  );

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mt-3">
        <h5>{t("productConfiguration")}</h5>
        <span onClick={() => setOpenEdit(true)}>
          <Icon
            className="w-[15px] cursor-pointer"
            src="/images/icons/edit.svg"
          />
        </span>
      </div>
      <div className="mt-3 border p-4 ">
        <ViewRowInterchange
          dataSource={productDetail}
          keyList={DETAILS_KEY as any}
          variant="border"
          classNameLabel="text-hint"
          classNameContent="text-end "
        />
        {openEdit && (
          <ProductDetailConfigurationDrawer
            open={openEdit}
            onClose={() => setOpenEdit(false)}
            defaultValue={productDetail}
            onUpdated={loadProductDetail}
          />
        )}
      </div>
    </div>
  );
};

export default ProductDetailConfiguration;
