import React, { useContext, useEffect, useMemo } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import CurrencyFormat from "react-currency-format";
import { DEFAULT_IMAGE } from "@constants/common";
import LinesEllipsis from "react-lines-ellipsis";
import { Icon } from "d-react-components";
import AppContext from "@helpers/context";

const ProductCard = React.memo(
  ({
    item,
    isHideSku = false,
    isHideImage = false,
    addNewProduct,
    showRentPrice = false,
  }: any) => {
    const { t } = useTranslation();
    const { product } = item;
    const { imageurl, name, sku, imageurls } = product;
    const { isEventSaleSelected } = useContext(AppContext);

    const getNameProduct = () => {
      if (_.isEmpty(product?.detailurl) && _.isEmpty(product?.editurl)) {
        return name;
      }
      return <a href={product?.editurl}>{name}</a>;
    };
    const renderImageProduct = () => {
      let url = DEFAULT_IMAGE;
      if (isHideImage) {
        return <div />;
      }
      if (imageurl && imageurl?.[0]) {
        url = imageurl?.[0];
      }
      if (imageurls && imageurls?.[0]) {
        url = imageurls?.[0];
      }
      return (
        <div className="aspect-w-4 aspect-h-3">
          <img
            className="w-full h-full object-center object-cover"
            alt={name}
            src={url}
          />
        </div>
      );
    };

    const stock = useMemo(() => {
      return isEventSaleSelected ? product.stock : product.total_stock;
    }, [isEventSaleSelected]);

    return (
      <div className="group mb-2 bg-white flex flex-col cursor-pointer relative max-h-[280px] 3xl:max-h-[320px] 4xl:max-h-[360px]">
        {/* {product?.total_stock > 0 && ( */}
        <div
          onClick={() => addNewProduct(item)}
          className="absolute w-full h-full top-0 left-0 bg-black bg-opacity-50 hidden group-hover:flex items-center justify-content-center z-50"
        >
          <Icon name="add" size="xxx-large" color="white" />
        </div>
        {/* )} */}
        {stock > 0 && (
          <div className="absolute bg-green-500 text-white px-2 py-1 text-xs top-0 left-0 z-40">
            {stock} {t("left")}
          </div>
        )}
        {stock <= 0 && (
          <div className="absolute bg-gray-400 text-white px-2 py-1 text-xs top-0 left-0 z-40">
            {t("outOfStock")}
          </div>
        )}
        {renderImageProduct()}

        <div className="p-2 flex-1 overflow-hidden">
          {!isHideSku && (
            <div className="text-tiny block text-gray-400">
              <LinesEllipsis
                text={sku || ""}
                maxLine="1"
                ellipsis="..."
                trimRight
                basedOn="letters"
              />
            </div>
          )}
          <span className="text-xs inline-block text-gray-500 overflow-hidden">
            <LinesEllipsis
              text={getNameProduct() || ""}
              maxLine="3"
              ellipsis="..."
              trimRight
              basedOn="letters"
            />
            {/* {renderSubName && renderSubName()} */}
          </span>
        </div>
        <div className="flex">
          <span className="flex-1 text-xs text-center bg-primary text-white py-2">
            <CurrencyFormat
              value={
                showRentPrice ? item?.product?.sale_price_rent : item.sale_price
              }
              displayType="text"
              thousandSeparator={true}
            />
          </span>
          <span className="flex-1 text-xs text-center bg-gray-400 text-white py-2 line-through">
            <CurrencyFormat
              value={
                showRentPrice
                  ? item?.product?.regular_price_rent
                  : item.regular_price
              }
              displayType="text"
              thousandSeparator={true}
            />
          </span>
        </div>
      </div>
    );
  }
);

export default ProductCard;
