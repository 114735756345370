import InputTextForm from "@common/input/InputTextForm";
import Drawer from "@components/shared/Drawer";
import MyAssetsAPI from "@network/api/my-assets";
import { Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

const RequestApprovalDrawer = ({
  open,
  onClose,
  type,
  request,
  onSave,
}: any) => {
  const { t } = useTranslation();
  const approveForm = useFormik<any>({
    initialValues: {} as any,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      // attachment: Yup.mixed().required(Messages.fieldRequired),
      remark: Yup.string().required(t("fieldRequired")),
    }),
    onSubmit: (values: any) => {
      Progress.show(
        {
          method: MyAssetsAPI.approveRequest,
          params: [
            {
              id: request?.id,
              remark: values?.remark ?? "",
              status: type === "approve" ? "approved" : "rejected",
            },
          ],
        },
        (res: any) => {
          Notifications.showSuccess(
            type === "approve"
              ? t("approveSuccessfully")
              : t("cancelSuccessfully")
          );
          onSave();
        }
      );
    },
  });

  const { handleSubmit } = approveForm;

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={type === "approve" ? t("approveRequest") : t("rejectRequest")}
      onSave={() => handleSubmit()}
    >
      <div className="">
        <InputTextForm
          form={approveForm}
          keyData="remark"
          className="mb-3"
          label={t("remark")}
          multiple
        />
      </div>
    </Drawer>
  );
};

export default RequestApprovalDrawer;
