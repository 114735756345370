import AppContext from "@helpers/context";
import { ITransferStock } from "@interfaces/transfer-stock";
import { transferStockDetail } from "@network/api/transfer-stock";
import { Progress, TabBar } from "d-react-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import TransferStockDetailActivity from "./TransferStockDetailActivity";
import TransferStockDetailDeliveries from "./TransferStockDetailDeliveries";
import TransferStockDetailGeneral from "./TransferStockDetailGeneral";
import TransferStockDetailHeader from "./TransferStockDetailHeader";
import TransferStockDetailInfo from "./TransferStockDetailInfo";
import TransferStockDetailProduct from "./TransferStockDetailProduct";
import TransferStockDetailStockRequest from "./TransferStockDetailStockRequest";

const TABS = [
  {
    id: "general",
    label: "general",
    component: <TransferStockDetailGeneral />,
  },

  {
    id: "itemList",
    label: "itemList",
    component: <TransferStockDetailProduct />,
  },
  {
    id: "delivery",
    label: "delivery",
    component: <TransferStockDetailDeliveries />,
  },
  // {
  //   id: "originConfirm",
  //   label: "originConfirm",
  //   component: <TransferStockDetailOriginConfirm />,
  // },
  // {
  //   id: "destinationConfirm",
  //   label: "destinationConfirm",
  //   component: <TransferStockDetailDestinationConfirm />,
  // },
  {
    id: "stockRequest",
    label: "stockRequest",
    component: <TransferStockDetailStockRequest />,
  },
  {
    id: "activity",
    label: "activity",
    component: <TransferStockDetailActivity />,
  },
];

const TransferStockDetail = () => {
  const [transferDetail, setTransferDetail] = useState<ITransferStock | null>(
    null
  );

  const [tab, setTab] = useState<any>(TABS[0]);
  const { transferId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    loadTransferDetail();
  }, []);

  const loadTransferDetail = () => {
    Progress.show(
      { method: transferStockDetail, params: [transferId] },
      (res: any) => {
        const transfer = res?.data?.data?.transfer;
        setTransferDetail(transfer);
      }
    );
  };

  return (
    <AppContext.Provider
      value={{
        transferDetail,
        loadTransferDetail,
      }}
    >
      <div className=" flex-column-container bg-trans">
        <TransferStockDetailHeader />
        <div className="p-3">
          <div className="grid mt-3 grid-cols-12 gap-4">
            <div className="col-span-4">{<TransferStockDetailInfo />}</div>
            <div className="col-span-8 px-3">
              <TabBar
                dataSource={TABS}
                onChange={setTab}
                value={tab}
                getLabel={(item: any) => t(item.label)}
              />
              <div className="mt-3">{tab.component}</div>
            </div>
          </div>
        </div>
      </div>
    </AppContext.Provider>
  );
};

export default TransferStockDetail;
