import {
  SERIAL_NUMBER_STATUS,
  SERIAL_NUMBER_STATUSES,
} from "@constants/serial-number";
import AppContext from "@helpers/context";
import SerialNumberAPI from "@network/api/serial-number";
import {
  Icon,
  Modal,
  Notifications,
  Progress,
  TimeUtils,
  ViewLabelStatus,
} from "d-react-components";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

const ACTIVATE = "ACTIVATE";
const CANCELLED = "CANCELLED";

const SerialNumberHeader = () => {
  const { serialDetail, loadSerialNumberDetail } = useContext(AppContext);
  const { id, info, product, serial_number } = serialDetail ?? {};
  const { status, by, created } = info ?? {};

  const [openConfirmModal, setOpenConfirmModal] = useState<any>({
    open: false,
    modalType: null,
  });
  const { t } = useTranslation();

  const { open, modalType } = openConfirmModal;

  const isPending = status === SERIAL_NUMBER_STATUS.PENDING;
  const isCancelled = status === SERIAL_NUMBER_STATUS.CANCELLED;

  const onActivateSerialNumber = () => {
    return Progress.show(
      { method: SerialNumberAPI.activate, params: [serial_number] },
      (res) => {
        Notifications.showSuccess(t("successfullyActivateSerialNumber"));
        loadSerialNumberDetail && loadSerialNumberDetail();
        setOpenConfirmModal({ open: false, modalType: null });
      }
    );
  };
  const onCancelSerialNumber = () => {
    return Progress.show(
      { method: SerialNumberAPI.cancelled, params: [id] },
      (res) => {
        Notifications.showSuccess(t("successfullyCancelSerialNumber"));
        loadSerialNumberDetail && loadSerialNumberDetail();
        setOpenConfirmModal({ open: false, modalType: null });
      }
    );
  };

  return (
    <div className="card-container p-3 flex-center justify-content-between">
      <div className="flex-column">
        <div className="d-flex">
          <h4>{serial_number}</h4>
          <ViewLabelStatus
            listStatus={SERIAL_NUMBER_STATUSES}
            status={status}
            className="ml-3"
            getLabel={(item) => t(item.label)}
          />
        </div>
        <div className="mt-3 d-flex align-items-center">
          {isPending && (
            <div
              className="flex-center-y cursor-pointer"
              onClick={() =>
                setOpenConfirmModal({ open: true, modalType: ACTIVATE })
              }
            >
              <Icon name="check" />
              <div className="ml-3"> {t("activate")}</div>
            </div>
          )}
          {/* <div
            className={`flex-center-y cursor-pointer ${ClassNames({
              "ml-4": isPending,
            })}`}
          >
            <Icon name="print" />
            <div className="ml-3"> {t("print")}</div>
          </div> */}
          {!isCancelled && (
            <div
              className="flex-center-y ml-4 cursor-pointer"
              onClick={() =>
                setOpenConfirmModal({ open: true, modalType: CANCELLED })
              }
            >
              <Icon name="cancel" />
              <div className="ml-3"> {t("cancel")}</div>
            </div>
          )}
        </div>
      </div>
      <div className="flex-column">
        <div className="d-flex">{`${t("by")} ${by?.name}`}</div>
        <small>{`${t("createdOn")} ${TimeUtils.toDateTime(created)}`}</small>
      </div>
      {open && modalType && (
        <Modal
          open={open && modalType}
          onClose={() => {
            setOpenConfirmModal({ open: false, type: null });
          }}
          onSave={() => {
            if (modalType === ACTIVATE) {
              return onActivateSerialNumber();
            }
            if (modalType === CANCELLED) {
              return onCancelSerialNumber();
            }
            return null;
          }}
          title={t("confirmation")}
          saveText={
            modalType === ACTIVATE ? t("activate") : t("cancelSerialNumber")
          }
          saveButtonProps={{
            color: modalType === CANCELLED ? "error" : "green",
          }}
        >
          <div className="text p-3">
            {modalType === ACTIVATE
              ? t("confirmActivateSerialNumber")
              : t("confirmCancelSerialNumber")}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default SerialNumberHeader;
