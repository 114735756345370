import { IFieldSelect } from "@interfaces/select";
import { getListInventoryControlEvent } from "@network/api/inventory-control-event";
import { ObjectUtils, Select } from "d-react-components";
import { debounce, isArray, isEmpty, map, uniqBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export interface IEventSelect<T> extends IFieldSelect<T> {
  [key: string]: any;
  warehouseIds?: string[];
  status?: string;
}

const InventoryEventSelect = ({
  value = [],
  multiple,
  className,
  onChange,
  warehouseIds,
  status = "in-use",
  ...selectProps
}: IEventSelect<any>) => {
  const [eventList, setEventList] = useState<any[]>(
    isArray(value) ? value : [value]
  );

  useEffect(() => {
    onLoadEvents();
  }, [JSON.stringify(warehouseIds)]);

  const { t } = useTranslation();

  const onChangeValue = (id: any) => {
    if (multiple) {
      onChange(map(id, (item) => ObjectUtils.findItemFromId(eventList, item)));
      return;
    }
    onChange(ObjectUtils.findItemFromId(eventList, id));
  };

  const eventValue = useMemo(() => {
    if (multiple) {
      return map(value, (item: any) => item.id);
    }
    return (value as any)?.id;
  }, [value]);

  const onLoadEvents = () => {
    const body = {
      // search: textSearch,
      status,
      warehouses: warehouseIds ?? "",
    };
    getListInventoryControlEvent(body, { pageIndex: 1, pageSize: 100 }).then(
      (res: any) => {
        const listEvent = res.data?.data?.control ?? [];

        const selectedValue = isArray(value) ? value : [value];
        const filterEvent = map([...selectedValue, ...listEvent], (item) => ({
          ...item,
          id: item.id,
          title: item.title,
        }));
        const uniqEvent = uniqBy(filterEvent, (item) => item.id);
        setEventList(uniqEvent);
      }
    );
  };

  return (
    <Select
      // onSearch={onChangeTextSearch}
      className={className}
      label={t("event")}
      dataSource={eventList}
      getLabel={(item) => `${item?.title} (ID: ${item?.code})`}
      value={eventValue}
      onChange={onChangeValue}
      multiple={multiple}
      placeholder={t("pleaseSelect")}
      showSearch
      // filterOption={false}
      {...selectProps}
    />
  );
};

export default InventoryEventSelect;
