import { Button, Drawer, Notifications } from "d-react-components";
import { t } from "i18next";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SignatureCanvas from "react-signature-canvas";

export const SignatureModal = ({
  open,
  onClose,
  canvasProps = {},
  onSave,
  description,
}: any) => {
  const signaturePadRef = useRef<any>();
  const { t } = useTranslation();
  const onClear = () => {
    signaturePadRef.current.clear();
  };

  const onSaveSignature = async () => {
    onSave(signaturePadRef.current.toDataURL());
    onClose();
    return Promise.resolve();
  };
  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("signature")}
      size="auto"
      width={"600"}
    >
      <div>
        <div className="signaturePadViewContainer ">
          <div className="flex justify-end">
            <Button className="buttonClearSignature" onClick={onClear}>
              {t("clear")}
            </Button>
          </div>
          <div className="border-dashed border-[1px] mt-3">
            <SignatureCanvas
              penColor="black"
              canvasProps={{
                width: 600,
                height: 300,
                className: "signaturePadView",
                ...canvasProps,
              }}
              ref={(ref: any) => {
                signaturePadRef.current = ref;
              }}
            />
          </div>
        </div>
        {description && <div className="subTitle2 mt-3">{description}</div>}
      </div>
      <div className="position-absolute bottom-0 w-100 end-0 start-0 flex-center-y justify-content-between py-3 px-3 bg-white">
        <Button
          onClick={() => {
            onClose && onClose({});
          }}
          variant="outline"
        >
          {t("cancel")}
        </Button>
        <Button
          onClick={() => {
            if (signaturePadRef.current.isEmpty()) {
              Notifications.showError(t("noLeaveSignEmpty"));
              return;
            }
            onSaveSignature();
          }}
        >
          {t("save")}
        </Button>
      </div>
    </Drawer>
  );
};

const SignatureField = ({ content, onSave, className = "" }: any) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className={className}>
      <Button
        className="w-100 text-xs"
        onClick={() => setOpenModal(true)}
        style={{ height: "40px" }}
      >
        {content || t("signature")}
      </Button>
      <SignatureModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onSave={onSave}
      />
    </div>
  );
};

export default SignatureField;
