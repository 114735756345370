import { PRODUCT_TYPE } from "@constants/product";
import { StringUtils } from "d-react-components";

export const getSizeDefault = (widthDefault = 0, lengthDefault = 0) => {
  return {
    width: widthDefault,
    length: lengthDefault,
    id: StringUtils.getUniqueID(),
  };
};

export const getListSizeDefault = (quantity: number) => {
  const listSize = [];
  for (let index = 0; index < quantity; index++) {
    listSize.push(getSizeDefault());
  }

  return listSize;
};

export const isGroupCustomSizeProduct = (product: any) => {
  const { product_type } = product?.product ?? {};
  return product_type === PRODUCT_TYPE.GROUP_CUSTOM_SIZE;
};

export const isCustomSizePro = (product: any) => {
  const { product_type } = product?.product ?? {};
  return product_type === PRODUCT_TYPE.CUSTOM_SIZE;
};

export const getBundlesFollowCustomSize = (
  freeGift: any,
  bundle: any,
  bundleList: any
) => {
  return bundleList?.map((bundleItem: any) => {
    const bundleResult = {
      ...bundleItem,
    };
    if (bundleList.length === 1) {
      bundleResult.quantity = bundle?.quantity ?? 0;
    }
    /**
     * transform product to map to data of custom size product
     */
    bundleResult.product = getCustomSizeProductsFromBundle(
      freeGift,
      bundleResult,
      bundleItem?.product ?? []
    );
    return bundleResult;
  });
};

export const getCustomSizeProductsFromBundle = (
  freeGift: any,
  bundleItem: any,
  productList = []
) => {
  return productList?.map((proItem: any) => ({
    ...proItem,
    // quantity: proItem.quantity * (bundleItem?.quantity ?? 1),
    quantity: proItem.quantity,
    isGift: true,
    bundleId: bundleItem.id,
    freeGiftId: freeGift.id,
    meta: getListSizeDefault(proItem.quantity * bundleItem.quantity),
  }));
};

export const getAttributeDefault = () => {
  return {
    id: null,
    value: null,
    rowId: StringUtils.getUniqueID(),
  };
};

export const calculateAllProductQuantity = (productList = []) => {
  return productList.reduce((sum, item: any) => {
    const isGroup = item?.groups?.length > 0;
    if (isGroup) {
      const totalSubPro = item.groups.reduce((subSum: any, subPro: any) => {
        return subSum + subPro?.quantity; // => if calculate all quantity of product in create order ,has to multiply with item.quantity
      }, 0);
      return sum + totalSubPro;
    }
    return sum + item?.quantity;
  }, 0);
};

export const isCustomSizeProduct = (product: any) => {
  return isCustomSizePro(product) || isGroupCustomSizeProduct(product);
};

export const getSubProductTotal = (subProduct: any[] = [], fatherQty: any) => {
  const total =
    subProduct.reduce(
      (sum, item) => sum + item?.sale_price * fatherQty * item?.quantity,
      0
    ) ?? 0;
  return total;
};

export const calculateNewSubProPrice = (
  subPro: any,
  listSubPro: any[] = [],
  mainPro: any,
  isRentPrice = false
) => {
  const mainQty = mainPro.quantity;
  const mainSalePrice = mainPro.sale_price;
  const subProductTotal = getSubProductTotal(listSubPro, mainQty);
  const newSalePrice =
    ((mainQty * mainSalePrice) / subProductTotal) *
    (isRentPrice ? subPro?.product?.sale_price_rent : subPro.sale_price);
  const newPrice = newSalePrice * mainQty * subPro.quantity;
  return [newSalePrice, newPrice];
};

export const transformerProduct = (proItem: any, isRentPrice = false) => {
  const isGroup = proItem?.product?.groups?.length > 0;
  const salePrice = isRentPrice
    ? proItem?.product?.sale_price_rent
    : proItem.sale_price;
  if (isGroup) {
    let children: any[] = [];
    const subProduct = proItem?.product?.groups?.map((subPro: any) => {
      const [newSalePrice, newPrice] = calculateNewSubProPrice(
        subPro,
        proItem.product.groups,
        proItem,
        isRentPrice
      );
      const quantity = subPro.quantity * proItem.quantity;
      return {
        ...subPro,
        isSubPro: true,
        price: newPrice,
        sale_price: newSalePrice,
        quantity,
      };
    });
    children = children.concat(subProduct);
    return {
      ...proItem,
      children,
      price: proItem.quantity * salePrice,
      id: proItem?.product?.id,
    };
  }
  return {
    ...proItem,
    price: proItem.quantity * salePrice,
    id: proItem?.product?.id,
  };
};

export const transferQuantityOfProduct = (product: any, quantity: number) => {
  let metaList = product?.meta ?? [];
  const metaLength = metaList?.length;

  if (quantity > metaLength) {
    for (let index = 0; index < quantity - metaLength; index++) {
      const theLastMeta = metaList?.[metaLength - 1];
      metaList.push(getSizeDefault(theLastMeta?.width, theLastMeta?.length));
    }
  } else {
    metaList = metaList.slice(0, quantity);
  }
  return {
    ...product,
    meta: metaList,
    quantity,
  };
};

export const calculateDefaultExpandedRowKeys = function (
  data = [],
  options: any
) {
  const rowKeys: any[] = [];
  if (!(data && data.length)) {
    return rowKeys;
  }

  const defaultOptions = {
    level: -1,
    key: "id",
    ...options,
  };

  const { level, key } = defaultOptions;

  if (!data[0][key]) {
    return rowKeys;
  }

  if (level === 0) {
    return rowKeys;
  }

  const mapRowKeys = function mapRowKeys(source = [], currentLevel = 1) {
    let keys: any[] = [];
    source.forEach(({ children, ...rest }: any) => {
      if (children !== null) {
        keys.push(rest[key]);
        if (level < 0 || (level > 0 && level > currentLevel)) {
          const childrenKeys = mapRowKeys(children, currentLevel + 1);
          keys = keys.concat(childrenKeys);
        }
      }
    });
    return keys;
  };

  return mapRowKeys(data);
};

export const isGroupProduct = (product: any) => {
  const { product_type } = product?.product ?? {};
  return product_type === PRODUCT_TYPE.GROUP;
};

export const isCustomProduct = (product: any) => {
  const { product_type } = product?.product ?? {};
  return product_type === PRODUCT_TYPE.CUSTOM;
};
