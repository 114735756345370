const DOC = {
  extension: ["docx", "doc", "pptx", "pps"],
  iconFile: "/images/files/doc.png",
};
const EXCEL = {
  extension: ["xls", "csv", "xlsx"],
  iconFile: "/images/files/sheets.png",
};
const PDF = { extension: ["pdf"], iconFile: "/images/files/pdf-file.png" };
export const IMAGE = {
  extension: [
    "jpeg",
    "bmp",
    "png",
    "jpg",
    "heic",
    "PNG",
    "HEIC",
    "JPG",
    "JPEG",
    "BMP",
    "webp",
  ],
  iconFile: "",
};
export const FILE_TYPE = [DOC, EXCEL, PDF];
export const IMAGE_FORMAT = "image/x-png,image/jpeg,image/heic,image/png";
export const FILE_FORMAT =
  "application/pdf,text/csv,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation";
export const FILE_FORMAT_PROMOTION =
  "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint";
