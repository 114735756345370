import BrandSelect from "@components/brand/components/BrandSelect";
import ProductCategorySelect from "@components/product-category/components/ProductCategorySelect";
import { YES_NO_OPTIONS } from "@constants/common";
import { getModelList, productSizeByCategory } from "@network/api/product";
import classNames from "classnames";
import { DateInput, InputText, ObjectUtils, Select } from "d-react-components";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface IProductInput {
  productForm: any;
  className?: any;
}

const ProductInputInfo = ({ productForm, className }: IProductInput) => {
  const formValues = productForm?.values;
  const { t } = useTranslation();
  const [modelList, setModelList] = useState([]);
  const [listSize, setListSize] = useState([]);

  const classNameField = "col-span-2";

  useEffect(() => {
    getListModel();
  }, [formValues.brand, formValues.category]);

  const getListModel = () => {
    if (!formValues.brand || !formValues.category) return;

    const body = {
      brand: [formValues?.brand?.id],
      category: [formValues?.category?.id],
    };

    getModelList(body).then((res) => {
      const listModelResult = res?.data?.data?.models ?? [];
      setModelList(listModelResult);
    });
  };

  useEffect(() => {
    getListSize();
  }, [formValues?.category]);

  const getListSize = () => {
    if (!formValues?.category) return;

    productSizeByCategory(formValues?.category?.id).then((res) => {
      const listSizeRes = res?.data?.data?.size ?? [];
      setListSize(listSizeRes);
    });
  };

  return (
    <div className={classNames("grid grid-cols-4 gap-4 ", className)}>
      <ProductCategorySelect
        className={classNameField}
        value={formValues?.category}
        onChange={(value) => productForm.setFieldValue("category", value)}
      />
      <BrandSelect
        className={classNameField}
        value={formValues?.brand}
        onChange={(value) => productForm.setFieldValue("brand", value)}
      />
      <Select
        className={classNameField}
        onChange={(id) => {
          const selectedModel = ObjectUtils.findItemFromId(modelList, id);
          productForm.setFieldValue("model", selectedModel);
        }}
        value={formValues?.model?.id}
        label={t("model")}
        dataSource={modelList}
        getLabel={(item) => item.name}
        showSearch
      />
      <Select
        className={classNameField}
        onChange={(id) => {
          const selectedSize = ObjectUtils.findItemFromId(listSize, id);
          productForm.setFieldValue("size", selectedSize);
        }}
        value={formValues?.size?.id}
        label={t("size")}
        dataSource={listSize}
        getLabel={(item) => item.name}
      />

      <DateInput
        className={classNameField}
        onChange={(value) => {
          productForm.setFieldValue("launchDate", value);
        }}
        value={formValues?.launchDate ? moment(formValues?.launchDate) : null}
        label={t("launchDate")}
      />
      <Select
        className={classNameField}
        onChange={(id) => {
          productForm.setFieldValue("visibility", id);
        }}
        value={formValues?.visibility}
        label={t("visibility")}
        getLabel={(item) => t(item.label)}
        dataSource={YES_NO_OPTIONS}
      />
      <Select
        className={classNameField}
        onChange={(id) => {
          productForm.setFieldValue("selling_out_of_stock", id);
        }}
        value={formValues?.selling_out_of_stock}
        label={t("continueSelling")}
        getLabel={(item) => t(item.label)}
        dataSource={YES_NO_OPTIONS}
      />
      <InputText
        className={"col-span-4"}
        label={`${t("remark")} (${t("optional")})`}
        value={formValues?.remark}
        onChange={(event) => {
          productForm.setFieldValue("remark", event.target.value);
        }}
        multiple
        rows={5}
      />
    </div>
  );
};

export default ProductInputInfo;
