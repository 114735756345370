import { OrderType, TYPE_OF_PLACE } from "@constants/common";
import { ORDER_STATUS, MARKETPLACE_TYPES } from "@constants/order";
import AppContext from "@helpers/context";
import { calculateAllProductQuantity } from "@helpers/product";
import { getFullName } from "@helpers/string";
import {
  deleteShippingFromOrder,
  updateOrderShipping,
} from "@network/api/address";
import { Spin } from "antd";
import { Icon, Notifications, Progress, TimeUtils } from "d-react-components";
import _, {
  every,
  filter,
  find,
  isEmpty,
  isNil,
  isUndefined,
  orderBy,
} from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ShippingAddressDrawer, {
  AddNewShippingProfileDrawer,
} from "../ShippingAddressDrawer";
import ShippingItemInfo from "./ShippingItemInfo";
import ImageIcon from "@common/Icon";
import { ContractStatus } from "@constants/contract";
import moment from "moment";
import { DELIVERY_STATUS } from "@constants/delivery";

const INIT_SHIPPING = {
  fullname: "",
  nickname: "",
  address: "",
  province: "",
  postcode: "",
  phone: "",
  place: "",
  firstname: "",
  lastname: "",
  city: "",
  note: "",
  expectedDateReceive: "",
};

//use only for contract to check for enable edit shipping or not
export const isEnableEditShipping = (deliveryList: any) => {
  const isAllDeliveryCancelled = every(deliveryList, (deliveryItem: any) => {
    return deliveryItem.status === DELIVERY_STATUS.CANCELLED;
  });
  return isAllDeliveryCancelled;
};

//use only for normal order to check for enable edit shipping or not
export const isDeliveryAllItems = (
  productList: any,
  deliveryList: any,
  pickupList: any
) => {
  const totalItems = calculateAllProductQuantity(productList);
  let itemsDelivery: any = [];
  deliveryList.forEach((deliveryItem: any) => {
    itemsDelivery = itemsDelivery.concat(deliveryItem?.items ?? []);
  });

  const totalDeliveryItems = _.reduce(
    itemsDelivery ?? [],
    (sum, item) => {
      return sum + item.stock;
    },
    0
  );

  const totalPickUpItems = _.reduce(
    pickupList,
    (sum, pickupItem) => {
      const subPickup = _.reduce(
        pickupItem.pickup,
        (subSum, subPickup) => {
          return subSum + subPickup?.stock;
        },
        0
      );
      return sum + subPickup;
    },
    0
  );

  return totalItems - totalDeliveryItems - totalPickUpItems === 0;
};

const ShippingAddress = ({
  orderType = OrderType.NORMAL,
}: {
  orderType?: OrderType;
}) => {
  const {
    shipping = [],
    order,
    customer,
    onReloadOrderData,
    isCreateOrder,
    onSaveShipping,
    delivery = [],
    products,
    pickup = [],
    editable,
    type,
    isPublic,
  } = useContext(AppContext);
  const [shippingInfo, setShippingInfo] = useState(
    initialShippingInfo(shipping)
  );
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openModalAddNew, setOpenModalAddNew] = useState(false);
  const [removeProgress, setRemoveProgress] = useState(false);
  const { t } = useTranslation();

  function initialShippingInfo(shipping: any[]) {
    if (!shipping) {
      return INIT_SHIPPING;
    }
    return shipping?.[0];
  }

  useEffect(() => {
    if (!isEmpty(shipping)) {
      setShippingInfo(initialShippingInfo(shipping));
    }
  }, [shipping]);

  const onClickRemoveShipping = (shippingItem: any) => {
    setRemoveProgress(true);
    if (isCreateOrder) {
      const clone = shipping?.filter(
        (item: any) => item?.id !== shippingItem?.id
      );
      onSaveShipping && onSaveShipping(clone);
      setRemoveProgress(false);
      return;
    }
    const idShippingDelivery = delivery?.map(
      (delivery: any) => delivery?.shipping?.id
    );
    if (idShippingDelivery?.includes(shippingItem?.id)) {
      Notifications.showError(
        t("notification:youcannotremovethisshippingaddress")
      );
      setRemoveProgress(false);
      return;
    }
    if (!_.isEmpty(shippingItem)) {
      deleteShippingFromOrder(shippingItem?.id, orderType)
        .then((respone) => {
          setRemoveProgress(false);
          onReloadOrderData();
          Notifications.showSuccess(
            t("notification:removeShippingAdressfromordersuccessfully")
          );
        })
        .catch((error) => {
          setRemoveProgress(false);
        });
    }
  };

  const getPlaceText = (place: any) => {
    return TYPE_OF_PLACE[place];
  };

  const isShowEditRemove = (shippingItem: any) => {
    if (!editable || isPublic) {
      return false;
    }
    if (isCreateOrder) {
      return true;
    }
    if (orderType === OrderType.NORMAL) {
      if (
        order &&
        (order.status === ORDER_STATUS.pending.value ||
          order.status === ORDER_STATUS.pendingPaymentConfirmation.value ||
          order.status === ContractStatus.SIGNED)
      ) {
        return true;
      }
      if (
        order &&
        (order.status === ORDER_STATUS.processing.value ||
          order.status === ORDER_STATUS.deliveryProcessing.value)
      ) {
        const createdDelivery =
          filter(
            delivery,
            (deliveryItem: any) =>
              deliveryItem?.shipping?.id === shippingItem?.id
          ) ?? [];
        const currentDelivery = orderBy(
          createdDelivery,
          "created",
          "desc"
        )?.[0];
        const isDeliveryAll = isDeliveryAllItems(products, delivery, pickup);

        if (currentDelivery) {
          return currentDelivery.status === "cancel";
        } else {
          if (!isDeliveryAll) {
            return true;
          }
        }
      }
    }
    if (orderType === OrderType.RENTAL) {
      return isEnableEditShipping(delivery);
    }
    return false;
  };

  const RenderContentView = ({ shippingItem, isLast }: any) => {
    const { latitude, longitude } = shippingItem;
    const canEdit =
      orderType === OrderType.NORMAL ? order.has_edit_shipping : true;
    const canRemove =
      orderType === OrderType.NORMAL ? order.has_delete_shipping : false;
    return (
      <div
        className={`shippingItemContainer mb-3 ${!isLast && "pb-3 border-b"}`}
      >
        <div className="flex">
          <div className="flex flex-column flex-1 min-w-0">
            <div className="mb-1 break-words">
              {getFullName(shippingItem) +
                (shippingItem?.nickname ? ` (${shippingItem.nickname})` : "")}
            </div>
            <div className="mb-1 break-words">{shippingItem?.phone}</div>
            {shippingItem?.alternative_phone && (
              <div className="mb-1 break-words">
                {shippingItem?.alternative_phone}
              </div>
            )}
            <div className="mb-1 break-words">{shippingItem?.address}</div>
            <div className="mb-1 break-words">
              {shippingItem?.subdistrict?.name ?? (
                <div className="text-error">
                  {t("unknown")} {t("subdistrict")}
                </div>
              )}
            </div>
            <div className="mb-1 break-words">
              {shippingItem?.city?.name ?? (
                <div className="text-error">
                  {t("unknown")} {t("district")}
                </div>
              )}
            </div>
            <div className="mb-1 break-words">
              {shippingItem?.province?.name ?? (
                <div className="text-error">
                  {t("unknown")} {t("province")}
                </div>
              )}
            </div>
            <div className="mb-1 break-words">{shippingItem?.postcode}</div>
            <div className="mb-1 break-words">
              {t(getPlaceText(shippingItem?.place)) || shippingItem?.place}
            </div>
            {latitude && longitude && (
              <a
                href={`https://maps.google.com/?q=${latitude},${longitude}`}
                target="_blank"
                className="mb-2"
                rel="noreferrer"
              >
                {t("openGoogleMaps")}
              </a>
            )}
            {!isEmpty(shippingItem?.third_party_address) && (
              <div className="bg-gray-100 p-2 mb-2">
                <label>{t("3rdPartyAddressRef")}</label>
                <div>{shippingItem.third_party_address}</div>
              </div>
            )}
            {shippingItem?.expectedDateReceive && (
              <div className="shippingItemNote break-words bg-blue-100 p-2 mb-2">
                <span>{`${t("expectedDateReceive")}: `}</span>
                {TimeUtils.convertMiliToDateTime(
                  shippingItem?.expectedDateReceive
                )}
              </div>
            )}
            {shippingItem?.note && (
              <div className="shippingItemNote relative break-words bg-blue-100 arrow-up p-2 mb-2">
                <span>{`${t("shippingNote")}: `}</span>
                {shippingItem?.note}
              </div>
            )}
          </div>
        </div>

        {shippingItem.scheduled_delivery_date && (
          <div className="bg-amber-100 p-2 text-amber-500 mt-2 mb-3">
            {t("scheduledDeliveryDate")} {t("at")}{" "}
            {TimeUtils.convertMiliToDate(shippingItem.scheduled_delivery_date)}
          </div>
        )}

        {isShowEditRemove(shippingItem) && (
          <div id="buttonGroup" className="flex items-center">
            {(isCreateOrder || canEdit) && (
              <div
                className="text-primary cursor-pointer mr-2"
                onClick={() => {
                  setShippingInfo(shippingItem);
                  setOpenEditModal(true);
                }}
              >
                <ImageIcon
                  className="w-[15px] cursor-pointer"
                  src="/images/icons/edit.svg"
                />
              </div>
            )}
            {(isCreateOrder || canRemove) && (
              <div
                className="text-red-500 cursor-pointer"
                onClick={() => {
                  onClickRemoveShipping(shippingItem);
                }}
              >
                {removeProgress ? (
                  <Spin size="default" />
                ) : (
                  <ImageIcon
                    className="w-[15px] cursor-pointer"
                    src="/images/icons/delete.svg"
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const isCustomerShowAdd = () => {
    if (isPublic) {
      return false;
    }
    if (isCreateOrder) {
      return !_.isEmpty(customer);
    }
    if (orderType === OrderType.NORMAL) {
      if (
        !isUndefined(order?.has_create_shipping) &&
        !order?.has_create_shipping
      ) {
        return false;
      }
      if (
        !isUndefined(order?.has_bulk_shipping) &&
        !order?.has_bulk_shipping &&
        shipping.length > 0
      ) {
        return false;
      }
      if (
        order &&
        (order.status === ORDER_STATUS.pending.value ||
          order.status === ORDER_STATUS.pendingPaymentConfirmation.value ||
          order.status === ContractStatus.SIGNED)
      ) {
        return true;
      }

      if (
        order &&
        (order.status === ORDER_STATUS.processing.value ||
          order.status === ORDER_STATUS.deliveryProcessing.value)
      ) {
        const isDeliveryAll = isDeliveryAllItems(products, delivery, pickup);

        if (!isDeliveryAll) {
          return true;
        }
      }
    }
    if (orderType === OrderType.RENTAL) {
      return isEnableEditShipping(delivery);
    }
    return false;
  };

  const onClickSave = async (shippingInfo: any) => {
    const { scheduled_delivery_date } = shippingInfo;
    const body = {
      ...shippingInfo,
      id: shippingInfo?.id,
      ...(shippingInfo?.expectedDateReceive
        ? { expected_to_receive: shippingInfo?.expectedDateReceive.valueOf() }
        : {}),

      ...(orderType === OrderType.NORMAL
        ? {
            orderid: order?.id,
            city: shippingInfo?.city?.id,
            province: shippingInfo?.province?.id,
            subdistrict: shippingInfo?.subdistrict?.id,
          }
        : {
            rent_id: order?.id,
            city_id: shippingInfo?.city?.id,
            province_id: shippingInfo?.province?.id,
            subdistrict_id: shippingInfo?.subdistrict?.id,
            code: shippingInfo?.postcode,
            ...(scheduled_delivery_date
              ? {
                  scheduled_delivery_date: moment(
                    scheduled_delivery_date
                  ).valueOf(),
                }
              : {}),
          }),
    };
    Progress.show(
      { method: updateOrderShipping, params: [body, orderType] },
      () => {
        onReloadOrderData && onReloadOrderData();
        setOpenEditModal(false);
        Notifications.showSuccess(
          t("notification:updateShippingAddressSuccess")
        );
      },
      (err: any) => {
        Notifications.showError(err?.response?.data?.message || err);
      }
    );
  };

  return (
    <ShippingItemInfo
      title={t("shippingAddress")}
      editText={<ImageIcon src="/images/icons/add.svg" />}
      onClickEdit={() => {
        setShippingInfo(INIT_SHIPPING);
        setOpenModalAddNew(true);
      }}
      isShowAdd={_.isEmpty(customer) ? false : true}
      isCustomerShowAdd={isCustomerShowAdd}
    >
      {!_.isEmpty(shipping) &&
        shipping?.map(
          (item: any, index: number) =>
            item && (
              <RenderContentView
                isLast={index + 1 === shipping?.length}
                shippingItem={item}
                key={index}
              />
            )
        )}

      {openModalAddNew && (
        <ShippingAddressDrawer
          open={openModalAddNew}
          onClose={() => setOpenModalAddNew(false)}
          orderType={orderType}
        />
      )}
      {openEditModal && (
        <AddNewShippingProfileDrawer
          openModalAddNew={openEditModal}
          setOpenModalAddNew={setOpenEditModal}
          shippingInfo={shippingInfo}
          onClickSaveAddNew={(values: any) => {
            onClickSave(values);
          }}
          showNote
          showExpectedReceiveTime={orderType === OrderType.NORMAL}
          showScheduledDeliveryDate={
            orderType === OrderType.RENTAL && shippingInfo.id
          }
          showSaveDefault={false}
          showMap
          size="auto"
          width="600px"
          order={order}
        />
      )}
    </ShippingItemInfo>
  );
};

export default ShippingAddress;
