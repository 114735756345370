import React from "react";

interface IconProps {
  src: string;
  className?: string;
  alt?: string;
  [key: string]: any;
}

const Icon = ({
  src,
  className = "w-[20px]",
  alt = "",
  ...props
}: IconProps) => {
  return <img src={src} className={className} alt={alt} {...props} />;
};

export default Icon;
