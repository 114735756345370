import Firebase from "firebase";

const config = {
    apiKey: "AIzaSyB2uLGgSTi04_RuzCoRemCTS02hQWZ1f6o",
    authDomain: "online-sales-order.firebaseapp.com",
    databaseURL: "https://online-sales-order.firebaseio.com",
    projectId: "online-sales-order",
    storageBucket: "online-sales-order.appspot.com",
    messagingSenderId: "407630784040",
    appId: "1:407630784040:web:75e4686f2fd59d9dfb771d"
};

Firebase.initializeApp(config);

export const firebaseKey = "BDCZxfEOecDg9bI6xTF6x18EHUwL30apk0dsHwOrHapUT4W4E4kVAjnfmaMB1UGYUJKEPwypLprSFxxditDRWRI";

export default Firebase;