/* eslint-disable jsx-a11y/alt-text */
import Icon from "@common/Icon";
import InputSelectForm from "@common/input/InputSelectForm";
import InputTextForm from "@common/input/InputTextForm";
import Drawer from "@components/shared/Drawer";
import AppContext from "@helpers/context";
import { updatePriceProduct } from "@network/api/product";
import RawMaterialAPI from "@network/api/raw-material";
import {
  IRowsKey,
  Notifications,
  Progress,
  StringUtils,
  ViewRowInterchange,
} from "d-react-components";
import { useFormik } from "formik";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

interface IProductDetailPricing {
  open: boolean;
  defaultValue?: any;
  onUpdated: any;
  onClose: () => void;
}

const DetailPricingDrawer = ({
  open,
  defaultValue,
  onUpdated,
  onClose,
}: IProductDetailPricing) => {
  const { rawMaterialId } = useParams();
  const { t } = useTranslation();
  const [unitList, setUnitList] = useState([]);

  useEffect(() => {
    loadMaterialUnits();
  }, []);

  const loadMaterialUnits = () => {
    RawMaterialAPI.unitList().then((res) => {
      const materialUnitList = res?.data?.data?.uom ?? [];
      setUnitList(materialUnitList);
    });
  };

  const pricingForm = useFormik<any>({
    initialValues: defaultValue as any,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: schema,
    onSubmit: (values: any) => {
      onUpdate(values);
    },
  });
  const formValues = pricingForm?.values;
  const formErrors = pricingForm?.errors;

  const onUpdate = (values: any) => {
    const { price, unit } = values;
    const body = {
      price: parseInt(price),
      uom_id: unit,
      id: rawMaterialId,
    };
    Progress.show(
      {
        method: RawMaterialAPI.update,
        params: [body],
      },
      (res: any) => {
        Notifications.showSuccess(t("updateSuccess"));
        onUpdated();
        onClose();
      }
    );
  };
  const classNameField = "col-span-4";

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("pricing")}
      size="auto"
      width="80vw"
      onSave={() => {
        pricingForm.handleSubmit();
      }}
    >
      <div className={"grid grid-cols-4 gap-4 "}>
        <InputSelectForm
          keyData="unit"
          dataSource={unitList}
          form={pricingForm}
          className={classNameField}
          getLabel={(item:any) => item.name}
        />
        <InputTextForm
          keyData="price"
          form={pricingForm}
          className={classNameField}
        />
      </div>
    </Drawer>
  );
};

const RawMaterialDetailPricing = () => {
  const { rawMaterialDetail, loadRawMaterialDetail } = useContext(AppContext);
  const [openEdit, setOpenEdit] = useState(false);
  const { t } = useTranslation();

  const DETAILS_KEY = useMemo<IRowsKey<any>[]>(
    () => [
      {
        id: "uom",
        label: t("UOM"),
        renderContent: ({ data, item }) => {
          return data?.name;
        },
      },
      {
        id: "price",
        label: t("price"),
        renderContent: ({ data, item }) => {
          return StringUtils.moneyThaiFormat(data);
        },
      },
    ],
    [t]
  );

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mt-3">
        <h5>{t("pricing")}</h5>
        <span onClick={() => setOpenEdit(true)}>
          <Icon
            className="w-[15px] cursor-pointer"
            src="/images/icons/edit.svg"
          />
        </span>
      </div>
      <div className="mt-3 border p-4 ">
        <ViewRowInterchange
          dataSource={rawMaterialDetail}
          keyList={DETAILS_KEY as any}
          variant="border"
          classNameLabel="text-hint"
          classNameContent="text-end "
        />
        {openEdit && (
          <DetailPricingDrawer
            open={openEdit}
            onClose={() => setOpenEdit(false)}
            defaultValue={rawMaterialDetail}
            onUpdated={loadRawMaterialDetail}
          />
        )}
      </div>
    </div>
  );
};

export default RawMaterialDetailPricing;
