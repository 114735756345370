import { ORDER_STATUS } from "@constants/order";
import AppContext from "@helpers/context";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import ImageIcon from "@common/Icon";

const ShippingItemInfo = ({
  title,
  onClickEdit,
  isShowAdd,
  isCustomerShowAdd,
  onClickAdd,
  editText,
  className,
  ...props
}: any) => {
  const { order, isCreateOrder, isCustomerDetail, editable } =
    useContext(AppContext);
  const { t } = useTranslation();

  const getEditShow = () => {
    if (!editable) {
      return false;
    }
    if (isCustomerShowAdd) {
      return isCustomerShowAdd();
    }
    if (!onClickEdit) {
      return false;
    }

    if (isCustomerDetail) {
      return true;
    }

    if (isCreateOrder) {
      return !!isShowAdd;
    }

    if (
      order &&
      (order.status === ORDER_STATUS.pending.value ||
        order.status === ORDER_STATUS.pendingPaymentConfirmation.value)
    ) {
      return true;
    }

    return false;
  };

  return (
    <div className={`shippingItemInfoContainer relative ${className}`}>
      <div id="shippingItemHeader relative">
        <label className="block text-sm">{title}</label>

        {getEditShow() && (
          <div
            className="text-primary cursor-pointer absolute top-0 right-0"
            onClick={() => onClickEdit && onClickEdit()}
          >
            {editText || (
              <ImageIcon
                className="w-[15px] cursor-pointer"
                src="/images/icons/edit.svg"
              />
            )}
          </div>
        )}
      </div>
      {props.children}
    </div>
  );
};

export default ShippingItemInfo;
