import OrderCreate, {
  OrderCreateType,
} from "@components/order/create/OrderDetail";
import React from "react";

const SubscriptionContractCreate = () => {
  return <OrderCreate type={OrderCreateType.RENTAL} />;
};

export default SubscriptionContractCreate;
