import _, { filter, map } from "lodash";
import React, { useContext } from "react";
import SelectSpecificAction from "./SelectSpecificAction";
import AppContext from "@helpers/context";
import { CART_RULE_ACTION } from "@constants/cart-rule";
import { useTranslation } from "react-i18next";
import {
  Button,
  Icon,
  InputText,
  ObjectUtils,
  Select,
} from "d-react-components";
import { YES_NO_OPTIONS } from "@constants/common";
import { PRODUCT_TYPE } from "@constants/product";
import { browseProduct } from "@network/api/product";
import ProductSearchButton from "@components/product/components/ProductSearchButton";
import ProductName from "@components/product/components/ProductName";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import RangeQuantityItem from "./RangeQuantityItem";
import BundleConditionProduct from "./BundleConditionProduct";
import BundleFreeProduct from "./BundleFreeProduct";

const BuyXGetXActions = ({
  cartRuleActions = [],
  handleSelectAction,
  actions,
  setChangeData,
  isShowBundleCondition,
  isShowFreeProduct,
}: any) => {
  const { cartRuleForm, disabledSpecificAction } = useContext(AppContext);
  const { t } = useTranslation();
  const formValues = cartRuleForm.values ?? {};
  const actionKey = actions?.specific ?? CART_RULE_ACTION.BUY_X_GET_X;

  const onClickAddRange = () => {
    const currentRange = getDiscountValue("range", []);
    const rangeDefault = {
      id: currentRange.length,
      min: 1,
      max: 4,
      free: 2,
    };
    const results = [...currentRange, rangeDefault];
    onChangeDiscountValue({ range: results });
  };

  const getDiscountValue = (key: string, defaultValue?: any) => {
    return actions?.[actionKey]?.[key] ?? defaultValue;
  };

  const onChangeDiscountValue = (newDiscount: any) => {
    const result = {
      ...actions,
      [actionKey]: {
        ...(actions?.[actionKey] ?? {}),
        ...newDiscount,
      },
    };
    setChangeData("actions", result);
  };

  const moveCard = (dragIndex: any, hoverIndex: any) => {
    const currentRange = getDiscountValue("range", []);
    const results = ObjectUtils.arrayMove(currentRange, dragIndex, hoverIndex);
    onChangeDiscountValue({ range: results });
    // forceUpdate();
  };

  const renderOptionsFiled = () => {
    return (
      <div className="grid grid-cols-2 gap-4">
        <SelectSpecificAction
          disabled={disabledSpecificAction}
          actions={actions}
          handleSelectAction={handleSelectAction}
          cartRuleActions={cartRuleActions}
          className="col-span-1"
        />

        <Select
          label={t("recursive")}
          dataSource={YES_NO_OPTIONS}
          value={getDiscountValue("recursive") as any}
          onChange={(value) => {
            onChangeDiscountValue({ recursive: value });
          }}
          className="col-span-1"
        />
      </div>
    );
  };
  const renderQuantityInputField = () => {
    return (
      <div className="grid grid-cols-2 gap-4 mt-2">
        <InputText
          className="col-span-1"
          label={t("buyQuantity")}
          onChange={(event) => {
            const rawValue = event.target.value;
            const value = _.isEmpty(rawValue) ? 0 : parseInt(rawValue);
            if (value < 1) {
              return;
            }
            onChangeDiscountValue({ buy: value });
          }}
          value={`${getDiscountValue("buy")}`}
          type="number"
        />
        <InputText
          className="col-span-1"
          label={t("getFreeQuantity")}
          onChange={(event) => {
            const rawValue = event.target.value;
            const value = _.isEmpty(rawValue) ? 0 : parseInt(rawValue);
            if (value < 1) {
              return;
            }
            onChangeDiscountValue({ free: value });
          }}
          value={`${getDiscountValue("free")}`}
          type="number"
        />
      </div>
    );
  };

  const onChangeRangeItem = (rangeItem: any) => {
    const currentRange = getDiscountValue("range", []);
    const results = map(currentRange, (item) => {
      if (item.id === rangeItem.id) {
        return rangeItem;
      }
      return item;
    });
    onChangeDiscountValue({ range: results });
  };

  const onDeleteRangeItem = (rangeItem: any) => {
    const currentRange = getDiscountValue("range", []);
    const results = filter(currentRange, (item) => item.id !== rangeItem.id);
    onChangeDiscountValue({ range: results });
  };

  const renderQuantityRangeConfig = () => {
    const currentRange = getDiscountValue("range", []);
    return (
      <div className="quantityRangeConfigContainer">
        <DndProvider backend={HTML5Backend}>
          {map(currentRange, (rangeItem, index) => (
            <RangeQuantityItem
              rangeItem={rangeItem}
              onChange={(rangeResult: any) => onChangeRangeItem(rangeResult)}
              onDelete={() => onDeleteRangeItem(rangeItem)}
              moveCard={moveCard}
              index={index}
            />
          ))}
        </DndProvider>

        <Button className="mt-2" iconName="add" onClick={onClickAddRange}>
          {t("addRange")}
        </Button>
      </div>
    );
  };
  const renderMainContent = () => {
    if (getDiscountValue("recursive")) {
      return renderQuantityInputField();
    }
    return renderQuantityRangeConfig();
  };

  const onDeleteFreeItemY = (itemDelete: any) => {
    const productsY = getDiscountValue("product");
    const results = filter(
      productsY,
      (item) => item?.product?.id !== itemDelete?.product?.id
    );
    onChangeDiscountValue({ product: results });
  };

  const sourceSearchProduct = (code: any, filterBody: any, paging: any) => {
    const body: any = {
      channel: formValues?.selectedChannel?.id,
      promotion: "name",
      code,
      product_type: [
        PRODUCT_TYPE.DEFAULT,
        PRODUCT_TYPE.GROUP,
        PRODUCT_TYPE.CUSTOM_SIZE,
      ],
    };

    const { brand, category } = filterBody;
    if (brand && brand.length > 0) {
      body.brand = map(brand, (item) => item.id);
    }

    if (category && category.length > 0) {
      body.category = map(category, (item) => item.id);
    }

    return browseProduct(body, paging);
  };

  const renderFreeItemsY = () => {
    if (actions?.specific === CART_RULE_ACTION.BUY_X_GET_X) {
      return <div />;
    }
    const productsY = getDiscountValue("product");
    return (
      <div className="freeItemYContainer">
        <div className="headerFreeItemY">
          <text className="h3">{t("freeItemsY")}</text>
          <ProductSearchButton
            onChange={(products) => {
              onChangeDiscountValue({ product: products });
            }}
            defaultValue={productsY}
            source={sourceSearchProduct}
            dataKey={"products"}
          />
        </div>
        <div className="freeItemList">
          {map(productsY, (productItem) => (
            <div className="freeItemContainer">
              <ProductName item={{ product: productItem.product }} />
              <Icon
                name="delete"
                onClick={() => onDeleteFreeItemY(productItem)}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderBundleGift = () => {
    return (
      <>
        {isShowBundleCondition && <BundleConditionProduct />}
        {isShowFreeProduct && <BundleFreeProduct />}
      </>
    );
  };
  return (
    <div className="w-100">
      {renderOptionsFiled()}
      {renderMainContent()}
      {renderBundleGift()}
    </div>
  );
};

export default BuyXGetXActions;
