export enum PRODUCT_TYPE {
  DEFAULT = "default_product",
  GROUP = "group_product",
  CUSTOM_SIZE = "product_custom_size",
  GROUP_CUSTOM_SIZE = "group_custom_size",
  CUSTOM = "custom_product",
}

export const PRODUCT_TYPES = [
  {
    id: PRODUCT_TYPE.DEFAULT,
    label: "defaultProduct",
  },
  {
    id: PRODUCT_TYPE.GROUP,
    label: "groupProduct",
  },

  {
    id: PRODUCT_TYPE.GROUP_CUSTOM_SIZE,
    label: "groupCustomSizeProduct",
  },
  {
    id: PRODUCT_TYPE.CUSTOM_SIZE,
    label: "customSizeProduct",
  },
];

export const FILTER_INVENT_STATUS_LIST = [
  { id: "out-of-stock", label: "outOfStock", value: [null, 0] },
  { id: "low-stock", label: "lowOnStock", value: [0, 10] },
  { id: "in-stock", label: "inStock", value: [1, null] },
];

export const INVENTORY_VISIBILITY = [
  { id: true, label: "enabled", value: true },
  { id: false, label: "disabled", value: false },
];

export const PRODUCT_LABEL_PRINT_TYPE = {
  TOPPER: "topper",
  BLANKET: "blanket",
  DEFAULT: "default",
};

export const PRODUCT_WARRANTY = {
  AVAILABLE: "AVAILABLE",
  NOT_AVAILABLE: "NOT_AVAILABLE",
};

export const PRODUCT_WARRANTIES = [
  {
    id: PRODUCT_WARRANTY.AVAILABLE,
    label: "available",
  },
  {
    id: PRODUCT_WARRANTY.NOT_AVAILABLE,
    label: "notAvailable",
  },
];

export const PRODUCT_VISIBILITIES = [
  {
    id: true,
    label: "Enable",
    color: "#33B950",
  },
  {
    id: false,
    label: "Disabled",
    color: "#ED6969",
  },
];

export const PRODUCT_SELL_WHEN_OUT_OF_STOCK = [
  {
    id: true,
    label: "Enable",
    color: "#33B950",
  },
  {
    id: false,
    label: "Disabled",
    color: "#ED6969",
  },
];

export const DIMENSION_UNIT = ["inch", "feet", "cm", "m"];

export const WEIGHT_UNIT = ["kg", "g", "lb", "oz"];

export const PRODUCT_SEARCH_TYPE_DEFAULT = "name";
export const PRODUCT_SEARCH_OPTIONS = [
  {
    id: "name",
    label: "name",
  },
  {
    id: "sku",
    label: "sku",
  },
  {
    id: "alias",
    label: "aliasSKU",
  },
];

export const PRODUCT_SUBSCRIPTION_STATUS = [
  {
    id: true,
    label: "Enable",
    color: "#33B950",
  },
  {
    id: false,
    label: "Disabled",
    color: "#ED6969",
  },
];

export const PRINT_INPUT_TYPE = {
  text: "text",
  select: "select",
};

export const IMPORT_PRODUCT_STEPS = [
  {
    id: "selectType",
    title: "selectProductType",
    desc: "selectProductTypeDesc",
  },
  {
    id: "selectImportMethod",
    title: "selectImportMethod",
    desc: "selectImportMethodDesc",
  },
  { id: "selectCSVFile", title: "selectCSVFile" },
  { id: "validation", title: "fileValidationAndImport" },
  { id: "import", title: "importResult" },
];

export const IMPORT_PRODUCT_METHODS = [
  { id: "update", label: "updateExistingProducts" },
  { id: "create", label: "addNewProducts" },
];

export const IMPORT_PRODUCT_ALIAS_STEPS = [
  {
    id: "selectMethod",
    title: "selectImportMethod",
    desc: "dependOnImportCorrectly",
  },
  { id: "selectCSVFile", title: "selectCSVFile" },
  { id: "validation", title: "fileValidationAndImport" },
  { id: "import", title: "importResult" },
];

export const PRODUCT_ALIAS_IMPORT_FIELDS = [
  {
    id: "sku",
  },
  {
    id: "sku_alias",
  },
];

export const PRODUCT_ALIAS_IMPORT_METHOD = [
  {
    id: "remove_all_existing",
    label: "removeExistingAndAddNew",
  },
  {
    id: "ignore_all_the_existing",
    label: "ignoreExistingAndAddNew",
  },
];

export const IMPORT_PRODUCT_LABEL_STEPS = [
  {
    id: "selectTemplate",
    title: "selectLabelTemplate",
    desc: "whichLabelTemplate",
  },
  { id: "selectCSVFile", title: "selectCSVFile" },
  { id: "validation", title: "fileValidationAndImport" },
  { id: "import", title: "importResult" },
];

export const PRODUCT_LABEL_PRINT_TYPES = [
  {
    id: PRODUCT_LABEL_PRINT_TYPE.TOPPER,
    label: "topper",
  },
  {
    id: PRODUCT_LABEL_PRINT_TYPE.BLANKET,
    label: "blanket",
  },
  {
    id: PRODUCT_LABEL_PRINT_TYPE.DEFAULT,
    label: "defaultText",
  },
];

export const CHECK_SKU_STATUS: any = {
  existed: {
    id: "existed",
    label: "existed",
    color: "#D30F0F",
  },
  notExisted: {
    id: "notExisted",
    label: "notExisted",
    color: "#0EAD27",
  },
  duplicated: {
    id: "duplicated",
    label: "duplicated",
    color: "#F6A80E",
  },
};
