/* eslint-disable jsx-a11y/alt-text */
import Icon from "@common/Icon";
import ProductName from "@components/product/components/ProductName";
import Drawer from "@components/shared/Drawer";
import AppContext from "@helpers/context";
import { uploadFile } from "@network/api/common";
import RawMaterialAPI from "@network/api/raw-material";
import {
  AwesomeTableComponent,
  Button,
  Icon as DIcon,
  Progress,
} from "d-react-components";
import { useFormik } from "formik";
import { filter, find, forEach, includes, isEmpty, map } from "lodash";
import { useContext, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

interface IDetailGalleryEditDrawer {
  open: boolean;
  defaultValue?: any;
  onUpdated: any;
  onClose: () => void;
}

const MediaItem = ({ media, onRemoveMedia }: any) => {
  const getSourceMedia = (media: any) => {
    if (media?.imageData) {
      return media.imageData;
    }

    if (!isEmpty(media?.url)) {
      return media?.url;
    }

    if (!isEmpty(media)) {
      return media;
    }

    return "/images/image-default.png";
  };
  return (
    <div className="mr-3 mt-3">
      <div className="position-relative width-fit-content">
        <img src={getSourceMedia(media)} className="image-reg-x-large" />
        <div
          onClick={() => onRemoveMedia(media)}
          className="position-absolute bg-danger p-1"
          style={{ right: 0, top: 0 }}
        >
          <DIcon name="delete" className="text-white" />
        </div>
      </div>
    </div>
  );
};

const DetailGalleryEditDrawer = ({
  open,
  defaultValue,
  onUpdated,
  onClose,
}: IDetailGalleryEditDrawer) => {
  const { t } = useTranslation();
  const rawMaterialForm = useFormik<any>({
    initialValues: defaultValue as any,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: schema,
    onSubmit: (values: any) => {
      onUpdate(values);
    },
  });
  const formValues = rawMaterialForm?.values;
  const formErrors = rawMaterialForm?.errors;
  const { imageurl, imageToUpload = [] } = formValues;

  const onUpdate = (values: any) => {
    const { imageurl, imageToUpload } = values;

    console.log("imageurl", imageurl);
    console.log("imageToUpload", imageToUpload);

    const body: any = {
      id: defaultValue?.id,
      add_image: map(imageToUpload, (item) => item.path),
      remove_image: filter(
        defaultValue?.imageurl,
        (url) => !includes(imageurl, url)
      ),
    };

    console.log("body", body);

    // Progress.show({ method: RawMaterialAPI.update, params: [body] }, (res: any) => {
    //   Notifications.showSuccess(t("updateSuccess"));
    //   onUpdated();
    //   onClose();
    // });
  };

  const onDrop = (files: any) => {
    const uploadBodyList: any[] = [];
    console.log("File upload ", files);
    forEach(files, (file, index: number) => {
      const reader = new FileReader();
      const url = reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        const body = new FormData();
        body.append("file", file);
        uploadBodyList.push({ method: uploadFile, params: [body] });
        if (index === files.length - 1) {
          Progress.show(uploadBodyList, (res: any) => {
            const result = map(res, (item) => item.data.data);

            rawMaterialForm.setFieldValue("imageToUpload", [
              ...imageToUpload,
              ...result,
            ]);
          });
        }
      };
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const onRemoveMediaUploading = (media: any) => {
    const result = filter(imageToUpload, (item) => item.id !== media.id);
    rawMaterialForm.setFieldValue("imageToUpload", result);
  };

  const onRemoveMediaUploaded = (media: any) => {
    const result = filter(imageurl, (item) => item !== media);
    rawMaterialForm.setFieldValue("imageurl", result);
  };

  console.log("imageurl", imageurl);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("gallery")}
      size="auto"
      width="80vw"
      onSave={() => {
        rawMaterialForm.handleSubmit();
      }}
    >
      <div>
        <div className="d-flex flex-wrap border p-3">
          {map(imageurl, (media, index: number) => (
            <MediaItem media={media} onRemoveMedia={onRemoveMediaUploaded} />
          ))}
          {map(imageToUpload, (media, index: number) => (
            <MediaItem media={media} onRemoveMedia={onRemoveMediaUploading} />
          ))}
          <div className="mr-3 mt-3">
            <div
              className="image-reg-x-large border-dashed flex-center flex-column p-3"
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <Button variant="trans" className="text-primary">
                {t("addFiles")}
              </Button>
              <small>{t("orDragDropHere")}</small>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

const RawMaterialDetailProductAssign = () => {
  const {
    rawMaterialDetail,
    loadRawMaterialDetail,
    materialUnitList = [],
  } = useContext(AppContext);
  const [openEdit, setOpenEdit] = useState(false);
  const { t } = useTranslation();
  const { rawMaterialId } = useParams();
  const [searchText, setSearchText] = useState("");

  const materialUnitObject = useMemo(() => {
    const foundMaterial = find(
      materialUnitList,
      (item) => item?.id === rawMaterialDetail?.materialUnit
    );
    return foundMaterial;
  }, [rawMaterialDetail]);

  const columns = [
    {
      title: t("itemNameTable"),
      dataIndex: "product",
      render: (product: any, item: any) => <ProductName item={{ product }} />,
    },
    {
      title: t("unitRMNeed"),
      dataIndex: "",
      render: (unit: any, item: any) => materialUnitObject?.name ?? "N/A",
    },
    {
      title: t("qtyRMNeed"),
      dataIndex: "quantity",
    },
  ];

  const source = (paging: any) => {
    const body = { raw_material_id: rawMaterialId, search: searchText };
    return RawMaterialAPI.productAssignList(body, paging);
  };

  const transformer = (res: any) => res?.data?.data?.products ?? [];

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mt-3">
        <h5>{t("product")}</h5>
        {/* <span onClick={() => setOpenEdit(true)}>
          <Icon
            className="w-[15px] cursor-pointer"
            src="/images/icons/edit.svg"
          />
        </span> */}
      </div>
      <div className="mt-3 border p-4 ">
        <AwesomeTableComponent
          //   ref={(ref) => (tableRef.current = ref)}
          source={(currentPage) => source(currentPage)}
          transformer={(res) => transformer(res)}
          columns={columns}
          isScroll={false}
        />
        {openEdit && (
          <DetailGalleryEditDrawer
            open={openEdit}
            onClose={() => setOpenEdit(false)}
            defaultValue={rawMaterialDetail}
            onUpdated={loadRawMaterialDetail}
          />
        )}
      </div>
    </div>
  );
};

export default RawMaterialDetailProductAssign;
