import AppContext from "@helpers/context";
import { Button } from "d-react-components";
import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  IMPORT_PRODUCTION_PRINTING_STEPS,
  INIT_IMPORT_FORM,
} from "./ProductionPrintingImportDrawer";
import { isEmpty } from "lodash";

const ProductionPrintingImportFooter = () => {
  const { stepIndex, setStepIndex, formImport, onImported, onClose } =
    useContext(AppContext);
  const stepNextValue = useMemo(
    () => IMPORT_PRODUCTION_PRINTING_STEPS[stepIndex + 1],
    [stepIndex]
  );
  const { t } = useTranslation();

  const classNameFooter =
    "d-flex justify-content-end align-items-center p-4 bg-gray-light";

  const getDisabledNextStep = () => {
    const { fileImport } = formImport.values;
    const { data, errors } = fileImport ?? {};

    switch (stepIndex) {
      case 0:
        return !data;
      case 1:
        return !isEmpty(errors);
      default:
        return false;
    }
  };

  const onClickResetDefault = () => {
    setStepIndex(0);
    formImport.setValues(INIT_IMPORT_FORM);
  };

  if (stepIndex === 2) {
    return (
      <div className={classNameFooter}>
        <Button onClick={onClickResetDefault}>{t("newImport")}</Button>
        <Button
          onClick={() => {
            onImported();
            onClose();
          }}
          className="ml-3"
        >
          {t("done")}
        </Button>
      </div>
    );
  }

  return (
    <div className={classNameFooter}>
      {stepIndex !== 0 && (
        <Button
          variant="outline"
          iconName="chevron_left"
          className="mr-3"
          onClick={() => setStepIndex(stepIndex - 1)}
        >
          {t("previousStep")}
        </Button>
      )}
      <Button
        disabled={getDisabledNextStep()}
        onClick={() => setStepIndex(stepIndex + 1)}
      >{`Step ${stepIndex + 2}: ${t(stepNextValue?.title)}`}</Button>
    </div>
  );
};

export default ProductionPrintingImportFooter;
