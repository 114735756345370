import AppContext from "@helpers/context";
import {
  IRowsKey,
  Notifications,
  Progress,
  TimeUtils,
  ViewLabelStatus,
  ViewRowInterchange,
} from "d-react-components";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import ImageIcon from "@common/Icon";
import Drawer from "@components/shared/Drawer";
import { CART_RULE_ACTIONS, CART_RULE_STATUS_LIST } from "@constants/cart-rule";
import { ICartRule, mapObjectServerToClient } from "@interfaces/cart-rule";
import CartRuleAPI from "@network/api/cart-rule";
import { useFormik } from "formik";
import { find, toUpper } from "lodash";
import { useParams } from "react-router-dom";
import CartRuleGeneralForm from "../component/CartRuleGeneralForm";
import CartRuleUsageStatus from "../component/CartRuleUsageStatus";

interface ICartRuleDetailGeneralUpdateDrawer {
  open: boolean;
  defaultValue: any;
  onUpdateSuccess: any;
  onClose: () => void;
}

const CartRuleDetailGeneralUpdateDrawer = ({
  open,
  defaultValue,
  onUpdateSuccess,
  onClose,
}: ICartRuleDetailGeneralUpdateDrawer) => {
  const { t } = useTranslation();
  const { cartRuleId } = useParams();
  const { cartRule, loadCartRuleDetail } = useContext(AppContext);

  const cartRuleForm = useFormik<any>({
    initialValues: defaultValue as any,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: schema,
    onSubmit: (values: any) => {
      const input = {
        id: cartRuleId,
        title: values?.title,
        description: values?.description,
        status: values?.status,
        rule: {
          channel: values?.selectedChannel?.id,
          period_from: values?.period_from,
          period_to: values?.period_to,
        },
      };
      onUpdate(input);
    },
  });
  const formValues = cartRuleForm?.values;
  const formErrors = cartRuleForm?.errors;

  const onUpdate = (input: any) => {
    Progress.show(
      { method: CartRuleAPI.update, params: [input] },
      (res: any) => {
        onClose();
        loadCartRuleDetail();
        Notifications.showSuccess(t("updateCartRuleSuccess"));
      }
    );
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("general")}
      onSave={() => cartRuleForm.handleSubmit()}
      size="large"
    >
      <CartRuleGeneralForm form={cartRuleForm} />
    </Drawer>
  );
};

const CartRuleDetailGeneral = () => {
  const { cartRule, loadCartRuleDetail } = useContext(AppContext);
  const [openUpdate, setOpenUpdate] = useState(false);
  const { t } = useTranslation();

  const { id } = cartRule;

  const DETAILS_KEY = useMemo<IRowsKey<ICartRule>[]>(
    () => [
      {
        id: "ruleid",
        label: t("id"),
      },
      {
        id: "title",
        label: t("name"),
      },
      {
        id: "actions",
        label: t("action"),
        renderContent: ({ data, item }) => {
          const cartRuleAction = find(
            CART_RULE_ACTIONS,
            (item) => item.id === data?.specific
          );
          return t(cartRuleAction?.label ?? "");
        },
      },
      {
        id: "rule",
        label: t("startAt"),
        renderContent: ({ data, item }) =>
          TimeUtils.toDateTime(data.period_from),
      },
      {
        id: "rule",
        label: t("endAt"),
        renderContent: ({ data, item }) => TimeUtils.toDateTime(data.period_to),
      },
      {
        id: "status",
        label: t("usageStatus"),
        renderContent: ({ data, item }) => {
          return (
            <div className="flex-row justify-content-end">
              <ViewLabelStatus
                status={data}
                listStatus={CART_RULE_STATUS_LIST}
                getLabel={(item) => toUpper(item.label)}
              />
            </div>
          );
        },
      },
      {
        id: "status",
        label: t("status"),
        renderContent: ({ data, item }) => {
          return (
            <div className="flex-row justify-content-end">
              <CartRuleUsageStatus cartRule={item} />
            </div>
          );
        },
      },
      {
        id: "created",
        label: t("createdAt"),
        renderContent: ({ data, item }) => TimeUtils.toDateTime(data),
      },
      {
        id: "updated",
        label: t("updatedAt"),
        renderContent: ({ data, item }) => TimeUtils.toDateTime(data),
      },
      {
        id: "description",
        label: t("description"),
      },
    ],
    [t]
  );

  return (
    <div className="border mt-3 p-4">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h5>{t("general")}</h5>
        <span onClick={() => setOpenUpdate(true)}>
          <ImageIcon
            className="w-[15px] cursor-pointer"
            src="/images/icons/edit.svg"
          />
        </span>
      </div>
      <ViewRowInterchange
        className="w-100"
        dataSource={cartRule}
        keyList={DETAILS_KEY as any}
        variant="border"
        classNameLabel="font-weight-bold "
        classNameContent="text-end "
      />
      {openUpdate && (
        <CartRuleDetailGeneralUpdateDrawer
          open={openUpdate}
          onClose={() => setOpenUpdate(false)}
          defaultValue={mapObjectServerToClient(cartRule)}
          onUpdateSuccess={loadCartRuleDetail}
        />
      )}
    </div>
  );
};

export default CartRuleDetailGeneral;
