import AppContext from "@helpers/context";
import { getVehicleDetail } from "@network/api/vehicle";
import { Progress, TabBar } from "d-react-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import VehicleDetailActivity from "./VehicleDetailActivity";
import VehicleDetailGallery from "./VehicleDetailGallery";
import VehicleDetailGeneral from "./VehicleDetailGeneral";

import VehicleDetailHeader from "./VehicleDetailHeader";
import VehicleDetailInfo from "./VehicleDetailInfo";

const TABS = [
  {
    id: "general",
    label: "general",
    component: <VehicleDetailGeneral />,
  },

  {
    id: "gallery",
    label: "gallery",
    component: <VehicleDetailGallery />,
  },
  {
    id: "activity",
    label: "activity",
    component: <VehicleDetailActivity />,
  },
];

const VehicleDetail = () => {
  const [vehicleDetail, setVehicleDetail] = useState(null);

  const [tab, setTab] = useState<any>(TABS[0]);
  const { vehicleId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    loadVehicleDetail();
  }, []);

  const loadVehicleDetail = () => {
    Progress.show(
      { method: getVehicleDetail, params: [vehicleId] },
      (res: any) => {
        const vehicle = res?.data?.data?.vehicle;
        setVehicleDetail(vehicle);
      }
    );
  };

  return (
    <AppContext.Provider
      value={{
        vehicleDetail,
        loadVehicleDetail,
      }}
    >
      <div className="flex-column-container bg-trans">
        <VehicleDetailHeader />
        <div className="grid p-3 grid-cols-12 gap-4">
          <div className="col-span-4">
            <VehicleDetailInfo />
          </div>
          <div className="col-span-8 px-3">
            <TabBar
              dataSource={TABS}
              onChange={setTab}
              value={tab}
              getLabel={(item: any) => t(item.label)}
            />
            {tab.component}
          </div>
        </div>
      </div>
    </AppContext.Provider>
  );
};

export default VehicleDetail;
