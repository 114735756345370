import { Modal } from "d-react-components";
import { useFormik } from "formik";
import { map } from "lodash";
import React, { useState } from "react";
import { Progress } from "antd";
import UserFilterForm from "./UserFilterForm";
import UserAPI from "@network/api/user";
import { exportToCSV } from "@helpers/file";
import { useTranslation } from "react-i18next";
import Drawer from "@components/shared/Drawer";

function UserTableExportModal({ open, onClose }: any) {
  const { t } = useTranslation();
  const [isVisibleProgress, setVisibleProgress] = useState(false);
  const [percentageProgress, setPercentageProgress] = useState(0);

  const exportForm = useFormik<any>({
    initialValues: {},
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      setVisibleProgress(true);
      setPercentageProgress(0);

      const { channel, storeType, warehouse, role, block, positionLabel } =
        values;

      const body = {
        channel,
        store_type: storeType,
        warehouse,
        role,
        block,
        position_label: positionLabel,
      };

      onExport(body);
    },
  });

  const onExport = async (body: any) => {
    let remainPage = true;
    let userListResult: any = [];
    let pageIndex = 1;
    while (remainPage) {
      const response = await UserAPI.list(body, {
        pageIndex,
        pageSize: 100,
      });
      const userList = response?.data?.data?.user ?? [];
      const paginationData = response?.data?.data?.pagination ?? {};
      const { currentPage, total } = paginationData;
      userListResult = userListResult.concat(userList);
      if (currentPage === total) {
        remainPage = false;
        setPercentageProgress(100);
      } else {
        pageIndex++;
        setPercentageProgress(Math.round((currentPage / total) * 100));
      }
    }

    const fileData = convertToExportData(userListResult);

    exportToCSV(fileData, "UserListExport");
    setVisibleProgress(false);
    onClose();
  };

  const convertToExportData = (userList = []) => {
    return map(userList, (item: any) => ({
      [t("userName")]: item?.typeLabel ?? "",
      [t("employeeId")]: item?.registrationNo ?? "",
      [t("nickName")]: item?.brand ?? "",
      [t("fullName")]: item?.model ?? "",
      [t("block")]: item?.yearText ?? "",
      [t("role")]: item?.color ?? "",
      [t("promotionStatus")]: item?.internalCode ?? "",
      [t("storeType")]: item?.vinNo ?? "",
      [t("positionLabel")]: item?.engineNo ?? "",
      [t("blockUser")]: item?.terminalSerial ?? "",
      [t("hasCallCenter")]: item?.vehicleDesc ?? "",
      [t("callCenterId")]: item?.status ? t("active") : t("inactive"),
      [t("callCenterEmail")]: item?.ownerShipLabel,
      [t("orderStatusManagement")]: item?.company?.name,
      [t("deliveryStatusManagement")]: item?.driver?.name,
      [t("authenticationStatus")]: item?.driver?.name,
      [t("authenticationSecretCode")]: item?.driver?.name,
    }));
  };

  const renderContent = () => {
    if (isVisibleProgress) {
      return (
        <div className="progressExportContainer">
          <Progress className="progressExport" percent={percentageProgress} />
        </div>
      );
    }
    return <UserFilterForm form={exportForm} />;
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("filter")}
      onSave={() => exportForm.handleSubmit()}
    >
      {renderContent()}
    </Drawer>
  );
}

export default UserTableExportModal;
