import { CART_RULE_CONDITION_OPTION } from "@constants/cart-rule";
import AppContext from "@helpers/context";
import {
  Button,
  DialogManager,
  Dropdown,
  ObjectUtils,
} from "d-react-components";
import { filter, map } from "lodash";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import CustomerCondition from "./CustomerCondition";
import StoreCondition from "./StoreCondition";
import SKUCondition from "./SKUCondition";
import CategoryCondition from "./CategoryCondition";
import BrandCondition from "./BrandCondition";
import SubtotalCondition from "./SubtotalCondition";
import CombineCondition from "./CombineCondition";

const CONDITIONS_TYPES = ObjectUtils.mapObjectToArray(
  CART_RULE_CONDITION_OPTION
);

const ConditionList = ({
  conditionOptions = CONDITIONS_TYPES,
  conditions = [],
  setChangeCondition,
  addConditionHandle,
  parentIndex,
  disabled,
}: any) => {
  const contextValues = ({} = useContext(AppContext));
  const { t } = useTranslation();

  const getIndex = (index: any) => {
    if (parentIndex) {
      return `${parentIndex}.${index + 1}`;
    }
    return index + 1;
  };

  const handleDeleteCondition = (condition: any) => {
    if (disabled) {
      return;
    }
    return DialogManager.showConfirm(
      t("confirm"),
      t("areyousurewanttodeletethiscondition"),
      () => handleClickOkDeleteCondition(condition)
    );
  };

  const handleClickOkDeleteCondition = (condition: any) => {
    const cloneList = filter(conditions, (item) => item?.id !== condition.id);
    setChangeCondition(cloneList);
  };

  return (
    <AppContext.Provider
      value={{ ...contextValues, cartRuleConditionDisabled: disabled }}
    >
      <div className="renderListCondition">
        {map(conditions, (condition, index) => {
          switch (condition.condition) {
            case "customer":
              return (
                <CustomerCondition
                  payload={condition}
                  key={`${index}${condition.key}`}
                  conditionList={conditions}
                  setChangeCondition={setChangeCondition}
                  indexText={getIndex(index)}
                  handleDeleteCondition={() => handleDeleteCondition(condition)}
                />
              );
            case "store":
              return (
                <StoreCondition
                  payload={condition}
                  key={`${index}${condition.key}`}
                  conditionList={conditions}
                  setChangeCondition={setChangeCondition}
                  indexText={getIndex(index)}
                  handleDeleteCondition={() => handleDeleteCondition(condition)}
                />
              );
            case "product":
              return (
                <SKUCondition
                  payload={condition}
                  key={`${index}${condition.key}`}
                  conditionList={conditions}
                  setChangeCondition={setChangeCondition}
                  indexText={getIndex(index)}
                  handleDeleteCondition={() => handleDeleteCondition(condition)}
                />
              );
            case "category":
              return (
                <CategoryCondition
                  indexText={getIndex(index)}
                  payload={{ ...condition, label: t("category") }}
                  key={`${index}${condition.key}`}
                  conditionList={conditions}
                  setChangeCondition={setChangeCondition}
                  handleDeleteCondition={() => handleDeleteCondition(condition)}
                />
              );
            case "brand":
              return (
                <BrandCondition
                  indexText={getIndex(index)}
                  payload={{ ...condition }}
                  key={`${index}${condition.key}`}
                  conditionList={conditions}
                  setChangeCondition={setChangeCondition}
                  handleDeleteCondition={() => handleDeleteCondition(condition)}
                />
              );
            case "total":
              return (
                <SubtotalCondition
                  indexText={getIndex(index)}
                  payload={condition}
                  key={`${index}${condition.key}`}
                  conditionList={conditions}
                  setChangeCondition={setChangeCondition}
                  handleDeleteCondition={() => handleDeleteCondition(condition)}
                />
              );
            case "subtotal":
              return (
                <SubtotalCondition
                  indexText={getIndex(index)}
                  payload={condition}
                  key={`${index}${condition.key}`}
                  conditionList={conditions}
                  setChangeCondition={setChangeCondition}
                  handleDeleteCondition={() => handleDeleteCondition(condition)}
                />
              );
            case "combination":
              return (
                <CombineCondition
                  index={index}
                  condition={condition}
                  conditionParentList={conditions}
                  setChangeCondition={setChangeCondition}
                  parentIndex={parentIndex}
                  handleDeleteCondition={() => handleDeleteCondition(condition)}
                  conditionOptions={conditionOptions}
                />
              );
            default:
              return <div />;
          }
        })}
      </div>
      {!disabled && (
        <Dropdown
          dataSource={map(conditionOptions, (item) => ({
            ...item,
            label: t(item.label),
          }))}
          onClick={addConditionHandle}
          className="mt-3"
        >
          <Button
            className="text-white inline-block px-2"
            iconName={"add"}
            content={t("addcondition")}
          />
        </Dropdown>
      )}
    </AppContext.Provider>
  );
};

export default ConditionList;
