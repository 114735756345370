import Drawer from "@components/shared/Drawer";
import { DELIVERY_STATUS } from "@constants/order";
import { exportToCSV } from "@helpers/file";
import { getDeliveryTrackingList } from "@network/api/delivery";
import { Spin } from "antd";
import { TimeUtils } from "d-react-components";
import { useFormik } from "formik";
import { map } from "lodash";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DeliveryTrackingFilterForm from "./DeliveryTrackingFilterForm";

interface IDrawerProps {
  open: boolean;
  onClose: () => void;
}
function DeliveryTrackingExportDrawer({ open, onClose }: IDrawerProps) {
  const { t } = useTranslation();
  const [loadingExport, setLoadingExport] = useState(false);
  const [percentageExport, setPercentageExport] = useState(0);

  const filterForm = useFormik<any>({
    initialValues: {} as any,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values: any) => {
      onExport(values);
    },
  });

  const onExport = async (filterBody: any) => {
    setLoadingExport(true);
    setPercentageExport(0);

    let sendData: any = {};

    const {
      channels,
      from,
      to,
      delivery,
      deliveryService,
      status,
      expectArrivalDate,
      warehouse,
      createdBy,
    } = filterBody;

    if (status && status.length > 0) {
      sendData.status = status;
    }
    sendData.channels = channels?.length && map(channels, (item) => item.id);
    sendData.from = from ? moment(from)?.valueOf() : null;
    sendData.to = to ? moment(to)?.valueOf() : null;
    sendData.delivery = delivery && delivery;
    sendData.service = deliveryService && deliveryService;
    sendData.expectedDateArrival = expectArrivalDate
      ? moment(expectArrivalDate)?.valueOf()
      : null;
    sendData.warehouseid =
      warehouse?.length && map(warehouse, (item) => item.id);
    sendData.createdBy = createdBy?.length && map(createdBy, (item) => item.id);

    try {
      await onLoadExport(sendData);
    } catch (err) {
      setLoadingExport(false);
    }
  };

  const onLoadExport = async (body: any) => {
    let remainPage = true;
    let deliveryList: any[] = [];
    let pageIndex = 1;
    while (remainPage) {
      const response = await getDeliveryTrackingList(body, { pageIndex });
      const deliveryListRes = response?.data?.data?.delivery ?? [];
      const paginationData = response?.data?.data?.pagination ?? {};
      const { currentPage, total } = paginationData;
      deliveryList = deliveryList.concat(deliveryListRes);
      if (currentPage === total) {
        remainPage = false;
        setPercentageExport(100);
      } else {
        pageIndex++;
        setPercentageExport(Math.round((currentPage / total) * 100));
      }
    }

    const fileData = convertToExportData(deliveryList);

    exportToCSV(fileData, "DeliveryTrackingExport");
    setLoadingExport(false);
    onClose();
  };

  const convertToExportData = (deliveryList: any[]) => {
    return map(deliveryList, (item) => ({
      [t("doNo")]: item?.ref ?? "",
      [t("orderNo")]: item?.order?.orderid ?? "",
      [t("fromWarehouse")]: item?.warehouse?.name ?? "",
      [t("logisticType")]: item?.courier_code ?? "",
      [t("itemQty")]: item?.item ?? "",
      [t("status")]: DELIVERY_STATUS[item?.status ?? ""],
      [t("customerPhone")]: item?.shipping?.phone ?? "",
      [t("provinceName")]: item?.shipping?.province?.name ?? "",
      [t("estArrival")]: TimeUtils.toDateTime(item?.estimated),
      [t("channel")]: item?.order?.channel?.name ?? "",
      [t("createdBy")]: item?.by?.fullname ?? "",
      [t("createdAt")]: TimeUtils.toDateTime(item?.created),
    }));
  };

  const renderContent = () => {
    if (loadingExport) {
      return (
        <div className="p-4 flex-center">
          <Spin />
        </div>
      );
    }
    return <DeliveryTrackingFilterForm form={filterForm} />;
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("export")}
      onSave={() => filterForm.handleSubmit()}
      size="auto"
      width={"80%"}
      saveText={t("export")}
    >
      {renderContent()}
    </Drawer>
  );
}

export default DeliveryTrackingExportDrawer;
