import { SelectInfinity } from "@components/shared/SelectInfinity";
import { IFieldSelect } from "@interfaces/select";
import { IVehicle } from "@interfaces/vehicle";

import { getVehicleList } from "@network/api/vehicle";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export interface IVehicleSelect<T> extends IFieldSelect<T> {
  [key: string]: any;
  channelId?: string | string[];
}

const VehicleSelect = ({
  value = [],
  multiple,
  className,
  onChange,
  channelId,
  disabled,
  ...selectProps
}: IVehicleSelect<IVehicle>) => {
  const { t } = useTranslation();

  const onChangeValue = (value: any) => {
    if (multiple) {
      onChange(value);
      return;
    }
    onChange(value?.[0]);
  };

  const vehicleValue = useMemo(() => {
    if (multiple) {
      return value;
    }
    return [value];
  }, [value]);

  return (
    <SelectInfinity
      fetchFn={getVehicleList}
      dataPath="vehicle"
      value={vehicleValue}
      label={t("vehicle")}
      customQuery={(search) => ({
        search,
      })}
      getLabel={(item) =>
        item?.name ? `${item?.name} (${item?.registration})` : ""
      }
      disabled={disabled}
      onChange={onChangeValue}
      mode={multiple ? "multiple" : undefined}
      placeholder={t("pleaseSelect")}
      className={className}
      {...selectProps}
    />
  );
};

export default VehicleSelect;
