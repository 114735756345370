import ProductName from "@components/product/components/ProductName";
import { STOCK_REQUEST_STATUS } from "@constants/stock-request";
import AppContext from "@helpers/context";
import { AwesomeTableComponent, Button } from "d-react-components";
import { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import StockRequestDetailProductsEditDrawer from "./StockRequestDetailProductsEditDrawer";

const StockRequestDetailProducts = () => {
  const { requestDetail } = useContext(AppContext);
  const { t } = useTranslation();
  const [openEdit, setOpenEdit] = useState<boolean>(false);

  const tableRef = useRef<any>();
  const columns = [
    {
      title: t("name"),
      dataIndex: "",
      render: (product: any) => <ProductName item={{ product }} />,
    },
    {
      title: t("quantity"),
      dataIndex: "quantity",
      width: 100,
    },
  ];

  return (
    <div className="card-container p-4 ">
      <div className="d-flex justify-content-between align-items-center">
        <label>{t("itemList")}</label>
        {/* <Button
          onClick={() => setOpenEdit(true)}
          variant="trans"
          hidden={requestDetail?.status !== STOCK_REQUEST_STATUS.PENDING}
        >
          {t("edit")}
        </Button> */}
      </div>
      <AwesomeTableComponent
        ref={(ref) => {
          tableRef.current = ref;
        }}
        columns={columns}
        dataSource={requestDetail?.products ?? []}
        className="mt-3 height-fit-content"
      />

      {openEdit && (
        <StockRequestDetailProductsEditDrawer
          open={openEdit}
          onClose={() => setOpenEdit(false)}
        />
      )}
    </div>
  );
};

export default StockRequestDetailProducts;
