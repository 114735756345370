import Icon from "@common/Icon";
import { OrderType } from "@constants/common";
import AppContext from "@helpers/context";
import { updateOrderNote } from "@network/api/order";
import { updateRentalNote } from "@network/api/rental";
import {
  InputText,
  Modal,
  Notifications,
  Button,
  Drawer,
  Progress,
} from "d-react-components";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

const OrderNote = ({
  className,
  orderType = OrderType.NORMAL,
}: {
  className?: string;
  orderType?: OrderType;
}) => {
  const { order, onReloadOrderData, editable, isPublic } =
    useContext(AppContext);
  const [openModal, setOpenModal] = useState(false);
  const [noteContent, setNoteContent] = useState(order?.note);
  const { t } = useTranslation();

  const onSaveNote = async () => {
    if (orderType === OrderType.RENTAL) {
      const body = {
        id: order.id,
        note: noteContent,
      };
      Progress.show({ method: updateRentalNote, params: [body] }, () => {
        Notifications.showSuccess(t("notification:editNoteSuccess"));
        setOpenModal(false);
        onReloadOrderData && onReloadOrderData();
      });
    } else {
      const body = {
        orderid: order.id,
        note: noteContent,
      };
      Progress.show({ method: updateOrderNote, params: [body] }, () => {
        Notifications.showSuccess(t("notification:editNoteSuccess"));
        setOpenModal(false);
        onReloadOrderData && onReloadOrderData();
      });
    }
  };

  return (
    <div
      className={`bg-amber-100 shadow-sm w-100 flex-column p-3 ${className}`}
    >
      <div className="flex items-start justify-content-center">
        <div className="flex-1 items-center">
          <label className="block mb-1 text-sm">{t("order:orderNote")}</label>
          <span className="text-gray-500 text-xs">
            {t("orderNoteInternalUse")}
          </span>
        </div>
        {editable && !isPublic && (
          <Icon
            onClick={() => setOpenModal(true)}
            className="w-[15px] cursor-pointer"
            src="/images/icons/edit.svg"
          />
        )}
      </div>
      {order?.note && <span className="orderNoteText mt-3">{order?.note}</span>}

      <Drawer
        title={t("order:orderNote")}
        open={openModal}
        onClose={() => setOpenModal(false)}
        size="auto"
        width="460"
      >
        {" "}
        <div className="bg-amber-100 px-3 py-2 mb-3">
          <span className="text-amber-500 text-xs">
            {t("orderNoteInternalUse")}
          </span>
        </div>
        <InputText
          label={t("order:noteName")}
          defaultValue={noteContent}
          onChange={(e) => setNoteContent(e.target.value)}
          multiple
          rows={5}
        />
        <div className="position-absolute bottom-0 w-100 end-0 start-0 flex-center-y justify-content-between py-3 px-3 bg-white">
          <Button
            onClick={() => {
              onSaveNote();
            }}
          >
            {t("submit")}
          </Button>
        </div>
      </Drawer>
    </div>
  );
};

export default OrderNote;
