import Drawer, { DrawerProps } from "@components/shared/Drawer";
import { SelectInfinity } from "@components/shared/SelectInfinity";
import WarehouseInfo from "@components/shared/WarehouseInfo";
import styled from "@emotion/styled";
import { DiscountType } from "@interfaces/common";
import { checkDiscountCode, createDiscountCode } from "@network/api/discount";
import { listUserStore } from "@network/api/store";
import UserAPI from "@network/api/user";
import { Button, InputText, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

export interface IssueDiscountOTPForm {
  warehouse?: any;
  staff?: any;
  discountType?: any;
  discountValue?: any;
}

const DiscountTypeSwitch = ({ discountType, onChange }: any) => {
  return (
    <div className="flex items-center">
      <Button
        size="fit-content"
        variant={
          discountType === DiscountType.Percentage ? "standard" : "outline"
        }
        className="mr-2"
        onClick={() => onChange(DiscountType.Percentage)}
      >
        %
      </Button>
      <Button
        size="fit-content"
        variant={
          discountType === DiscountType.FixAmount ? "standard" : "outline"
        }
        onClick={() => onChange(DiscountType.FixAmount)}
      >
        THB
      </Button>
    </div>
  );
};

const IssueDiscountOTPDrawer = ({ open, onClose, onSave }: DrawerProps) => {
  const { t } = useTranslation();
  const [discountLimit, setDiscountLimit] = useState({
    discount_percentage: 0,
    discount_fix_amount: 0,
  });
  const form = useFormik<IssueDiscountOTPForm>({
    initialValues: {
      discountType: DiscountType.Percentage,
    },
    validationSchema: Yup.object().shape({
      warehouse: Yup.mixed().required(t("fieldRequired")),
    }),
    onSubmit: () => {
      Progress.show(
        {
          method: createDiscountCode,
          params: [
            {
              warehouse_id: values?.warehouse?.[0]?.id,
              discount_type: values?.discountType,
              discount_value: values?.discountValue,
              ...(values?.staff && { issued_for_id: values?.staff?.[0]?.id }),
            },
          ],
        },
        (resp: any) => {
          Notifications.showSuccess(t("notification:issueDiscountOTPSuccess"));
          onSave && onSave();
        }
      );
    },
  });
  const { values, errors, setFieldValue, setValues, handleSubmit } = form;
  const { discountType, discountValue } = values;
  useEffect(() => {
    if (discountType === DiscountType.Percentage) {
      const maxDiscount = discountLimit.discount_percentage;
      if (values.discountValue > maxDiscount) {
        setFieldValue("discountValue", maxDiscount);
        return;
      }
    }
    if (discountType === DiscountType.FixAmount) {
      const maxDiscount = discountLimit.discount_fix_amount;
      if (values.discountValue > maxDiscount) {
        setFieldValue("discountValue", maxDiscount);
        return;
      }
    }
  }, [discountType, discountValue, discountLimit]);

  useEffect(() => {
    if (!isEmpty(values.warehouse)) {
      checkDiscountCode({
        warehouse_id: values?.warehouse?.[0]?.id,
      }).then((resp) => {
        setDiscountLimit(resp?.data?.data);
      });
    }
  }, [values.warehouse]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("discountAuthorizationCode")}
      onSave={() => handleSubmit()}
    >
      <SelectInfinity
        fetchFn={listUserStore}
        key="selectWarehouse"
        dataPath="stores"
        value={values?.warehouse}
        error={errors?.warehouse}
        label={t("warehouse")}
        customQuery={(search) => ({
          search,
        })}
        getLabel={(item) => item?.name}
        dropdownHeight={540}
        getLabelDropdownItem={(item) => <WarehouseInfo warehouse={item} />}
        onChange={(val) => {
          setFieldValue("warehouse", val);
        }}
        placeholder={t("pleaseSelect")}
        className="mb-3"
      />
      <SelectInfinity
        fetchFn={UserAPI.list}
        key="selectStaff"
        dataPath="user"
        value={values?.staff}
        error={errors?.staff}
        label={t("issuedFor")}
        customQuery={(search) => ({
          search,
        })}
        getLabel={(item) =>
          item ? `${item.fullname} (${item?.employee_id})` : null
        }
        getLabelDropdownItem={(item) => (
          <div className="flex items-center">
            {item.avatar && (
              <img
                src={item.avatar}
                className="mr-2 w-[20px] h-[20px]"
                alt="avatar"
              />
            )}
            {`${item.fullname} (${item?.employee_id})`}
          </div>
        )}
        onChange={(val) => {
          setFieldValue("staff", val);
        }}
        placeholder={t("pleaseSelect")}
        className="mb-3"
      />
      <StyledInput className="mb-3 relative">
        <label className="text-label">{t("discountValue")}</label>
        <div className="relative">
          <CurrencyInput
            placeholder={t("pleaseInput")}
            value={values.discountValue}
            className="text-x-small d-input-text__input w-full border outline-slate-400 text-xs !pr-[100px]"
            onValueChange={(v) => {
              if (!v) {
                setFieldValue("discountValue", 0);
                return;
              }
              if (values.discountType === DiscountType.Percentage) {
                if (parseFloat(v) > 100) {
                  setFieldValue("discountValue", 100);
                  return;
                }
              }
              setFieldValue("discountValue", v);
            }}
          />
          <div className="absolute top-2 right-2">
            <DiscountTypeSwitch
              discountType={values.discountType}
              onChange={(value: any) => setFieldValue("discountType", value)}
            />
          </div>
        </div>
      </StyledInput>
    </Drawer>
  );
};

const StyledInput = styled.div`
  .d-input-text__suffix-container,
  .d-input-text__prefix-container {
    background-color: transparent !important;
    border: none;
  }
  .d-input-text__prefix-container {
    white-space: nowrap;
    align-items: center;
    display: flex;
  }
  .d-button {
    min-width: 0;
    padding-left: 5px;
    padding-right: 5px;
  }
`;

export default IssueDiscountOTPDrawer;
