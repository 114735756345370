import DeliveryDetailConfirmedView from "@components/delivery-tstock/detail/DeliveryStockConfirmedView";
import { DELIVERY_STATUS } from "@constants/delivery";
import AppContext from "@helpers/context";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

const DeliverySODetailDestination = () => {
  const { deliveryDetail, loadDeliveryDetail } = useContext(AppContext);

  const { status, confirmationDesc } = deliveryDetail;
  const isConfirmed = status === DELIVERY_STATUS.COMPLETED;
  const isPending = status === DELIVERY_STATUS.PENDING;
  const isDeliveryCancelled = status === DELIVERY_STATUS.CANCELLED;
  const { t } = useTranslation();

  if (isPending || isDeliveryCancelled) {
    return <div />;
  }
  console.log("confirmationDesc", confirmationDesc);
  return (
    <div className="card-container p-3 mt-4">
      <div className="d-flex justify-content-between align-items-center">
        <div className="cardTitleText">{t("destinationPickupConfirm")}</div>
        {isConfirmed && (
          <div className="text-white bg-success px-3 py-2">
            {t("confirmed")}
          </div>
        )}
      </div>

      {!isConfirmed && <div className="mt-3">{t("waitingForPickupStaff")}</div>}
      {isConfirmed && (
        <DeliveryDetailConfirmedView confirmation={confirmationDesc} />
      )}
    </div>
  );
};
export default DeliverySODetailDestination;
