export interface LabelStatusProps {
  color?: string;
  content?: string;
  className?: string;
  listStatus?: any[];
  status?: any;
  getValue?: (item: any) => any;
  getLabel?: (item: any) => any;
}

const LabelStatus = ({ content, color }: LabelStatusProps) => {
  return (
    <div className="flex items-center">
      <div
        style={{ backgroundColor: color }}
        className="w-[8px] h-[8px] rounded-full inline-block mr-1"
      ></div>
      <span className="text-sm" style={{ color }}>
        {content}
      </span>
    </div>
  );
};

export default LabelStatus;
