import {
  FILTER_ORDER_PICKUP,
  ORDER_SEARCH_OPTIONS,
  ORDER_SEARCH_TYPE_DEFAULT,
  ORDER_STATUS_ARRAY,
} from "@constants/order";
import {
  AwesomeTableComponent,
  Button,
  HeaderTable,
  IColumnsProps,
  Icon,
  Modal,
  Select,
  StringUtils,
  TimeUtils,
  ViewLabelStatus,
} from "d-react-components";
import { find, isUndefined, join } from "lodash";
import React, {
  ElementRef,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import CurrencyFormat from "react-currency-format";
import { ORDER_TABLE_LAYOUT_KEY } from "@components/order/OrderTable";
import { listAllOrder } from "@network/api/order";
import axios from "axios";
import UserAvatarName from "@components/shared/UserAvatarName";
import { getFullName } from "@helpers/string";
import { Link } from "react-router-dom";
import AppContext from "@helpers/context";
import { searchOrder } from "@network/api/callcenter";
import { useLocalStorage } from "react-use";
import { CALL_CENTER_TOKEN_KEY } from "@constants";
import OrderDetailPopup from "./OrderDetailPopup";

export const RecentPurchasingTable = () => {
  const { t } = useTranslation();
  const [showSearchPopup, setShowSearchPopup] = useState(false);
  const [showOrderPopup, setShowOrderPopup] = useState();
  const { callerInfo, setCallerInfo } = useContext(AppContext);
  const tableRef = useRef<any>(null);
  const [callCenterToken] = useLocalStorage<any>(CALL_CENTER_TOKEN_KEY);

  const columns: IColumnsProps = useMemo(
    () => [
      {
        title: t("createdAt"),
        dataIndex: "created",
        render: (data) => {
          return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
        },
      },
      {
        title: t("orderId"),
        dataIndex: "orderid",
        render: (data, item) => (
          <a onClick={() => setShowOrderPopup(item.id)}>{data}</a>
        ),
      },
      {
        title: t("orderStatus"),
        dataIndex: "status",
        render: (status: string) => {
          const orderStatus = find(
            ORDER_STATUS_ARRAY,
            (item) => item.value === status
          );
          return (
            <ViewLabelStatus
              content={t(`order:${orderStatus?.content}`)}
              color={orderStatus?.color}
            />
          );
        },
      },
      {
        title: t("channel"),
        dataIndex: "channel",
        render: (data) => {
          return data?.name;
        },
      },
      {
        title: t("totalValue"),
        dataIndex: "invoice_amount",
        render: (data) => (
          <CurrencyFormat
            value={data || 0}
            displayType="text"
            thousandSeparator={true}
          />
        ),
      },
    ],
    [t]
  );

  const source = async ({ pageIndex, pageSize }: any) => {
    const token = callCenterToken;
    try {
      return searchOrder({
        search: callerInfo.phone,
        search_type: "customer_phone",
        token,
        page: pageIndex,
        limit: pageSize,
      }).then((res) => {
        if (!callerInfo.email && res?.data?.data?.order?.length) {
          const listOrder = res?.data?.data?.order;
          const contactInfo = listOrder?.[0]?.contact;
          setCallerInfo({
            ...callerInfo,
            ...contactInfo,
          });
        }
        return res;
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div className="flex mb-3">
        <div className="flex-1">
          <h4>{t("recentPurchasingHistory")}</h4>
        </div>
        <Button size="small" onClick={() => setShowSearchPopup(true)}>
          <Icon name="search" className="mr-2" /> {t("masterSearch")}
        </Button>
      </div>
      <AwesomeTableComponent
        source={source}
        transformer={(response) => {
          return response?.data?.data?.order ?? [];
        }}
        columns={columns}
        ref={tableRef}
      />
      {showSearchPopup && (
        <OrderMasterSearch
          open={showSearchPopup}
          onClose={() => setShowSearchPopup(false)}
        />
      )}
      {showOrderPopup && (
        <OrderDetailPopup
          orderId={showOrderPopup}
          open={!isUndefined(showOrderPopup)}
          onClose={() => setShowOrderPopup(undefined)}
        />
      )}
    </>
  );
};

const OrderMasterSearch = ({ open, onClose }: any) => {
  const [search, setSearch] = useState("");
  const [searchType, setSearchType] = useState(ORDER_SEARCH_TYPE_DEFAULT);
  const { t } = useTranslation();
  const orderTableRef = useRef<ElementRef<typeof AwesomeTableComponent>>(null);
  const refreshTable = () => {
    return orderTableRef.current && orderTableRef.current.refresh();
  };
  const source = (paging: any) => {
    const { CancelToken } = axios;
    let body: any = {
      search,
      search_type: searchType,
    };
    return listAllOrder(body, paging, {
      cancelToken: new CancelToken((c) => {}),
    });
  };
  const columns = useMemo(
    () => [
      {
        title: t("invoiceNo"),
        dataIndex: "orderid",
        ellipsis: true,
        width: 100,
        render: (orderid: string, item: any) => (
          <Link to={`/order/${item.id}`}>{orderid}</Link>
        ),
      },
      {
        title: t("createdDate"),
        dataIndex: "created",
        width: 70,
        render: (created: string) => TimeUtils.convertMiliToDateTime(created),
      },
      {
        title: t("channel"),
        dataIndex: "channel",
        width: 100,
        render: (channel: any) => channel?.name,
      },
      {
        title: t("storeWarehouse"),
        dataIndex: "store",
        width: 200,
        render: (store: any) => StringUtils.getFullNameStore(store),
      },
      {
        title: t("customer"),
        dataIndex: "customer",
        width: 75,
        render: (customer: any) => getFullName(customer),
      },
      {
        title: t("total"),
        dataIndex: "total",
        width: 75,
        render: (total: number) => StringUtils.moneyThaiFormat(total),
      },

      {
        title: t("totalQty"),
        dataIndex: "total_item",
        ellipsis: true,
        isDefault: false,
        width: 75,
        render: (total_item: string) =>
          total_item === null ? "N/A" : total_item,
      },
      {
        title: t("status"),
        dataIndex: "status",
        ellipsis: true,
        width: 125,
        render: (status: string) => {
          const orderStatus = find(
            ORDER_STATUS_ARRAY,
            (item) => item.value === status
          );
          return (
            <ViewLabelStatus
              content={t(`order:${orderStatus?.content}`)}
              color={orderStatus?.color}
            />
          );
        },
      },
      {
        title: t("pickUpStatus"),
        dataIndex: "status_pickup",
        ellipsis: true,
        width: 100,
        render: (status_pickup: string) => {
          const pickUpStatus = FILTER_ORDER_PICKUP.find(
            (item) => item.id === status_pickup
          );
          if (!pickUpStatus) return "N/A";
          const label = t(pickUpStatus?.label);
          return (
            <ViewLabelStatus content={label} color={pickUpStatus?.color} />
          );
        },
      },
      {
        title: t("createdBy"),
        dataIndex: "by",
        ellipsis: true,
        width: 150,
        render: (by: any) => <UserAvatarName user={by} />,
      },

      {
        title: t("pickupQty"),
        dataIndex: "total_pickup",
        ellipsis: true,
        width: 75,
        isDefault: false,
        render: (total_pickup: string) =>
          total_pickup === null ? "N/A" : total_pickup,
      },

      {
        title: t("expectedDateOfReceive"),
        dataIndex: "shipping",
        ellipsis: true,
        width: 100,
        isDefault: false,
        render: (shipping: any = []) => {
          const estimatedList = shipping.map((item: any) => {
            const { expected_to_receive } = item;
            return !expected_to_receive
              ? "N/A"
              : TimeUtils.convertMiliToDateTime(expected_to_receive);
          });
          const disPlayText = join(estimatedList, ", ");

          return disPlayText;
        },
      },
      {
        title: t("estimatedDateArrival"),
        dataIndex: "delivery",
        ellipsis: true,
        width: 100,
        isDefault: false,
        render: (delivery: any = []) => {
          const estimatedList = delivery.map((item: any) => {
            const { estimated } = item;
            return estimated === 0
              ? "N/A"
              : TimeUtils.convertMiliToDateTime(estimated);
          });
          const disPlayText = join(estimatedList, ", ");

          return disPlayText;
        },
      },
      {
        title: t("dateArrived"),
        dataIndex: "delivery",
        ellipsis: true,
        width: 100,
        isDefault: false,
        render: (delivery: any = []) => {
          const estimatedList = delivery.map((item: any) => {
            const { arrived } = item;
            return arrived ? TimeUtils.convertMiliToDateTime(arrived) : "N/A";
          });
          const disPlayText = join(estimatedList, ", ");

          return disPlayText;
        },
      },

      {
        title: t("deliveryType"),
        dataIndex: "delivery",
        ellipsis: true,
        width: 100,
        isDefault: false,
        render: (deliveries: any = []) => {
          const deliveryName = deliveries.map((item: any) => {
            const { delivery } = item;
            return delivery?.name;
          });
          const disPlayText = join(deliveryName, ", ");

          return disPlayText;
        },
      },

      {
        title: t("sourceOrder"),
        dataIndex: "source",
        width: 100,
        isDefault: false,
        render: (source: any) => source?.name,
      },

      {
        title: t("utmSource"),
        dataIndex: "tracking",
        width: 100,
        isDefault: false,
        render: (tracking: any) => tracking?.source ?? "N/A",
      },

      {
        title: t("utmMedium"),
        dataIndex: "tracking",
        width: 100,
        isDefault: false,

        render: (tracking: any) => tracking?.medium ?? "N/A",
      },
      {
        title: t("utmCampaign"),
        dataIndex: "tracking",
        width: 100,
        isDefault: false,

        render: (tracking: any) => tracking?.campaign ?? "N/A",
      },
    ],
    [t]
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      width="100vw"
      title={t("allOrdersMasterSearch")}
    >
      <HeaderTable
        onChangeText={(e: React.FormEvent<HTMLInputElement>) => {
          setSearch(e?.currentTarget.value);
        }}
        searchValue={search}
        placeholder={t("search")}
        onSubmitSearch={() => refreshTable()}
        customView={() => (
          <Select
            value={searchType}
            dataSource={ORDER_SEARCH_OPTIONS.map((item) => ({
              ...item,
              label: t(item.label),
            }))}
            onChange={(valueId) => setSearchType(valueId)}
            getLabel={(item) => item.label}
            classNameSelect="height-[37px]"
            placeholder={t("pleaseSelect")}
          />
        )}
      />
      <AwesomeTableComponent
        source={source}
        transformer={(response) => {
          return response?.data?.data?.orders ?? [];
        }}
        columns={columns}
        ref={orderTableRef}
        showSelectColumn
        keyTableLayout={ORDER_TABLE_LAYOUT_KEY}
        tableLayout="fixed"
        className="mt-3"
      />
    </Modal>
  );
};