import InputTextForm from "@common/input/InputTextForm";
import ButtonFileUpload from "@components/shared/ButtonFileUpload";
import Drawer from "@components/shared/Drawer";
import { DEPOSIT_STATUS_LIST } from "@constants/cash-deposit";
import { getBankFullInfo } from "@helpers/bank";
import AppContext from "@helpers/context";
import { isGrantURLPermission } from "@helpers/permissions";
import { API_CONFIRM_DEPOSIT, API_UPLOAD_CONFIRM_DEPOSIT } from "@network/URL";
import CashDepositAPI from "@network/api/cash-deposit";
import {
  Button,
  DateInput,
  Notifications,
  Progress,
  Select,
} from "d-react-components";
import { useFormik } from "formik";
import { map } from "lodash";
import moment from "moment";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

const ErrorDepositPaymentSchema = Yup.object().shape({
  actualBank: Yup.string().required("Required!"),
  actualDate: Yup.object().required("Required!"),
  remark: Yup.string().required("Required!"),
  attachments: Yup.array().min(1, "Required!").required("Required!"),
});
const PaymentConfirmDrawer = ({ open, onClose }: any) => {
  const {
    cashDepositDetail,
    loadCashDepositDetail,
    bankList = [],
  } = useContext(AppContext);

  const { t } = useTranslation();

  const confirmForm = useFormik({
    initialValues: {
      actualBank: undefined,
      actualDate: null,
      remark: "",
      attachments: [],
    },
    validationSchema: ErrorDepositPaymentSchema,

    onSubmit: (values) => {
      return onConfirmRejectPayment(values);
    },
  });

  const onConfirmRejectPayment = async (values: any) => {
    const { remark, actualDate, actualBank, attachments } = values;
    const body = {
      id: cashDepositDetail?.id,
      confirm_payment_note: remark,
      status: "confirm",
      confirm_actual_date_payment: moment(actualDate).valueOf(),
      confirm_actual_bank_payment: actualBank,
      attachment: map(attachments, (item) => item.key),
    };
    // const APIList = attachments.map((attach) => {
    //   const data = new FormData();
    //   data.append("file", attach.fileData);
    //   data.append("id", window.depositId);
    //   return { method: API.uploadConfirmDeposit, params: data };
    // });
    // const { remark, actualBank, actualDate } = formik.values;
    // Progress.show(APIList, (res) => {
    //   const body = {
    //     id: window.depositId,
    //     confirm_payment_note: remark,
    //     status: "confirm",
    //     confirm_actual_date_payment: actualDate,
    //     confirm_actual_bank_payment: actualBank,
    //   };
    CashDepositAPI.confirm(body).then((res) => {
      onClose && onClose();
      Notifications.showSuccess(t("confirmDepositSuccess"));
      loadCashDepositDetail();
    });
  };
  return (
    <Drawer
      open={open}
      onClose={onClose}
      onSave={async () => confirmForm.handleSubmit()}
      title={t("confirm")}
    >
      <div className="modalConfirmDepositContainer">
        <Select
          label={t("actualBankOfReceivingPayment")}
          dataSource={bankList}
          getLabel={getBankFullInfo}
          placeholder={t("selectBank")}
          onChange={(value) => confirmForm.setFieldValue("actualBank", value)}
          value={confirmForm.values.actualBank}
          error={confirmForm.errors.actualBank}
          className="mt-3"
        />
        <DateInput
          label={t("actualDateOfReceivingPayment")}
          onChange={(date) => {
            let value: any = date;
            if (!value) {
              value = new Date();
            }
            confirmForm.setFieldValue("actualDate", value);
          }}
          value={confirmForm.values.actualDate}
          name="actualDate"
          error={confirmForm.errors.actualDate}
          className="mt-3"
        />
        <div className={"mt-3"}>
          <label>{t("attachments")}</label>
          <ButtonFileUpload
            onChange={(values: any) =>
              confirmForm.setFieldValue("attachments", values)
            }
            maxFiles={5}
            //   ref={buttonUploadRef}
          />
        </div>
        <InputTextForm
          className="mt-3"
          label={t("remark")}
          form={confirmForm}
          keyData="remark"
          multiple
        />
      </div>
    </Drawer>
  );
};

const ErrorDepositRejectSchema = Yup.object().shape({
  remark: Yup.string().required("Required"),
});

const ModalRejectDepositPayment = ({ open, onClose }: any) => {
  const { onRefreshData, cashDepositDetail } = useContext(AppContext);
  const { t } = useTranslation();

  const rejectForm = useFormik({
    initialValues: {
      remark: "",
      attachments: [],
    },
    validationSchema: ErrorDepositRejectSchema,

    onSubmit: (values) => {
      return onConfirmRejectPayment(values);
    },
  });

  const onConfirmRejectPayment = async (values: any) => {
    // const APIList = attachments.map((attach) => {
    //   const data = new FormData();
    //   data.append("file", attach.fileData);
    //   data.append("id", window.depositId);
    //   return { method: API.uploadConfirmDeposit, params: data };
    // });
    // const { remark } = formik.values;
    const body = {
      id: cashDepositDetail?.id,
      confirm_payment_note: values?.remark,
      status: "reject",
      attachment: map(values?.attachments, (item) => item.key),
    };
    return CashDepositAPI.confirm(body).then((res) => {
      Notifications.showSuccess(t("paymentRejected"));
      onClose && onClose();
      onRefreshData && onRefreshData();
    });
  };
  return (
    <Drawer
      open={open}
      onClose={onClose}
      onSave={async () => rejectForm.handleSubmit()}
      title={t("confirmRejectPayment")}
      saveText={t("reject")}
    >
      <div className="flex-column">
        <text>{t("areCertainPaymentReject")}</text>
        <div className="mt-3">
          <ButtonFileUpload
            //   uploadedFiles={attachments}
            //   getFile={(files: any) => setAttachments(files)}
            //   uploadImagesOnly
            onChange={(values: any) =>
              rejectForm.setFieldValue("attachments", values)
            }
            maxFiles={5}
          />
        </div>

        <InputTextForm
          className="mt-3"
          label={t("remark")}
          form={rejectForm}
          keyData="remark"
        />
      </div>
    </Drawer>
  );
};
const pending = DEPOSIT_STATUS_LIST[0].id;
const pendingConfirm = DEPOSIT_STATUS_LIST[1].id;

const CashDepositPaymentConfirmButtons = () => {
  const { cashDepositDetail } = useContext(AppContext);
  const { status } = cashDepositDetail;
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const { t } = useTranslation();

  const isGrantPermission = useMemo(() => {
    const isGrandUploadPermission = isGrantURLPermission(
      API_UPLOAD_CONFIRM_DEPOSIT
    );
    const isGrandConfirmPermission = isGrantURLPermission(API_CONFIRM_DEPOSIT);
    return isGrandConfirmPermission && isGrandUploadPermission;
  }, []);

  const isPendingConfirmDeposit = useMemo(() => {
    return status === pendingConfirm;
  }, [status]);

  if (!isPendingConfirmDeposit) {
    return <div />;
  }

  if (!isGrantPermission) {
    return <div />;
  }

  return (
    <div className="flex-row">
      <Button onClick={() => setOpenConfirmModal(true)}>{t("confirm")}</Button>
      <Button
        onClick={() => setOpenRejectModal(true)}
        className="bg-error ml-2"
      >
        {t("reject")}
      </Button>

      {openConfirmModal && (
        <PaymentConfirmDrawer
          open={openConfirmModal}
          onClose={() => setOpenConfirmModal(false)}
        />
      )}

      <ModalRejectDepositPayment
        open={openRejectModal}
        onClose={() => setOpenRejectModal(false)}
      />
    </div>
  );
};

export default CashDepositPaymentConfirmButtons;
