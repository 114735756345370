import { Path } from "@components/layout/Path";
import UserAvatarName from "@components/shared/UserAvatarName";
import {
  STOCK_REQUEST_STATUS_LIST,
  STOCK_REQUEST_TYPES,
} from "@constants/stock-request";
import AppContext from "@helpers/context";
import { IStockRequest } from "@interfaces/stock-request";
import { IStore } from "@interfaces/store";
import { getStockRequestList } from "@network/api/stock-request";
import {
  AwesomeTableComponent,
  HeaderTable,
  ITabItem,
  TimeUtils,
  useFirstTime,
  ViewLabelStatus,
} from "d-react-components";
import { debounce, find, map } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import StockRequestFilterDrawer from "./StockRequestFilterDrawer";
import StockRequestTabs from "./StockRequestTabs";

const StockRequestTable = () => {
  const tableRef = useRef<any>();
  const searchRef = useRef<string>();
  const [tabSelected, setTabSelected] = useState<ITabItem>({
    id: "all",
    label: "all",
  });
  const [openFilter, setOpenFilter] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [filter = {}, setFilter] = useQueryParam<any>("filter", JsonParam);

  const isFirstTime = useFirstTime();

  useEffect(() => {
    if (isFirstTime) return;
    tableRef.current?.refresh();
  }, [filter, tabSelected]);

  const TABLE_ACTIONS = [
    { id: "edit", label: t("edit") },
    { id: "detail", label: t("detail") },
  ];

  const source = (paging: any) => {
    const body = {
      search: searchRef?.current ?? "",
      stores: map(filter?.store, (item) => item.id),
      status: tabSelected?.id === "all" ? null : [tabSelected?.id],
      channels: map(filter?.channel, (item) => item.id),
    };
    return getStockRequestList(body, paging);
  };

  const columns: any[] = [
    {
      title: t("requestId"),
      dataIndex: "ref",
      render: (ref: string, item: IStockRequest) => (
        <Link
          to={generatePath(Path.STOCK_REQUEST_DETAIL, { requestId: item.id })}
        >
          {ref}
        </Link>
      ),
    },
    {
      title: t("warehouseStore"),
      dataIndex: "store",
      render: (store: IStore) => store?.name,
    },
    // {
    //   title: t("storeCode"),
    //   dataIndex: "store",
    //   render: (store: IStore) => store?.code,
    // },

    {
      title: t("type"),
      dataIndex: "request_type",
      width: 150,
      render: (request_type: string) => {
        const type =
          find(STOCK_REQUEST_TYPES, (item) => item.id === request_type)
            ?.label ?? "";
        return t(type);
      },
    },
    {
      title: t("expectArrivalDate"),
      dataIndex: "expected_arrival_date",
      render: TimeUtils.toDate,
    },
    {
      title: t("quantity"),
      dataIndex: "total_quantity",
    },

    {
      title: t("status"),
      dataIndex: "status",
      render: (status: string) => {
        return (
          <ViewLabelStatus
            status={status}
            listStatus={STOCK_REQUEST_STATUS_LIST}
            getLabel={(item) => t(item.label)}
          />
        );
      },
    },

    {
      title: t("createdBy"),
      dataIndex: "by",
      width: 150,
      render: (by: any) => <UserAvatarName user={by} />,
    },

    {
      title: t("createdAt"),
      dataIndex: "created",
      render: TimeUtils.toDateTime,
    },
  ];

  const onChangeSearch = debounce((text) => {
    searchRef.current = text;
    tableRef.current.refresh();
  }, 300);

  const isFiltered = useMemo(() => Object.keys(filter).length > 0, [filter]);

  return (
    <AppContext.Provider value={{ tabSelected }}>
      <div className="bg-white">
        <HeaderTable
          label={t("allStockRequest")}
          onChangeText={(event: any) => onChangeSearch(event.target.value)}
          onClickNew={() => navigate(Path.STOCK_REQUEST_CREATE)}
          onClickFilter={() => setOpenFilter(true)}
          isFiltered={isFiltered}
          className="app-layout__page-header"
        />

        <div className="d-flex overflow-auto">
          <StockRequestTabs
            tabSelected={tabSelected}
            onChangeTab={setTabSelected}
          />
        </div>
        <AwesomeTableComponent
          source={source}
          transformer={(res) => res?.data?.data?.request ?? []}
          isScroll={false}
          columns={columns}
          ref={(ref) => (tableRef.current = ref)}
        />
        {openFilter && (
          <StockRequestFilterDrawer
            open={openFilter}
            onClose={() => setOpenFilter(false)}
            defaultValue={filter}
            onFilter={setFilter}
          />
        )}
      </div>
    </AppContext.Provider>
  );
};

export default StockRequestTable;
