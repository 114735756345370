import { BILLING_TYPE, TYPE_OF_PLACE } from "@constants/common";
import { PRODUCT_TYPE } from "@constants/product";
import AppContext from "@helpers/context";
import { join, map, isEmpty } from "lodash";
import React, { useContext } from "react";
import OrderDetailTable from "../content/OrderDetailTable";
import { TimeUtils } from "d-react-components";
import { getFullName } from "@helpers/string";
import InfoItemLangs from "@components/shared/InfoItemLangs";
import { ActivityItem } from "@components/order/components/activity/ActivitiesView";
import { useTranslation } from "react-i18next";
import { getBothLangsText } from "@helpers/locales";

const Barcode = require("react-barcode");

const getPlaceText = (place: string) => {
  return TYPE_OF_PLACE[place];
};

const ShippingItemLayout = ({ shipping, isBilling = false }: any) => {
  const { t } = useTranslation();
  if (!shipping) return <>N/A</>;

  return (
    <div style={{ textAlign: isBilling ? "end" : "start" }}>
      <div className="contentShippingBillingText" style={{ marginTop: "7px" }}>
        {`${getFullName(shipping)} (${shipping?.phone}${
          shipping?.alternative_phone ? ` / ${shipping?.alternative_phone}` : ""
        })`}
      </div>
      {shipping?.type === BILLING_TYPE.business && shipping?.taxid && (
        <div className="contentShippingBillingText">{`${t("taxId")}: ${
          shipping?.taxid
        }`}</div>
      )}
      {shipping?.type === BILLING_TYPE.personal && shipping?.citizenid && (
        <div className="contentShippingBillingText">{`${t("citizenId")}: ${
          shipping?.citizenid ?? "N/A"
        }`}</div>
      )}
      <div className="contentShippingBillingText">{shipping?.address}</div>
      <div className="contentShippingBillingText">
        {shipping?.subdistrict?.name}
      </div>
      <div className="contentShippingBillingText">{shipping?.city?.name}</div>
      <div className="contentShippingBillingText">{`${shipping?.province?.name}, ${shipping?.postcode}`}</div>
      <div className="contentShippingBillingText">
        {t(getPlaceText(shipping?.place))}
      </div>
      <div className="contentShippingBillingText">{shipping?.note}</div>
    </div>
  );
};

const BillingLayout = () => {
  const { order, shipping, billing, billingShippingSelect } =
    useContext(AppContext);
  if (!order.billing) {
    return <ShippingItemLayout shipping={billingShippingSelect} isBilling />;
  }
  return <ShippingItemLayout shipping={billing} isBilling />;
};

const OrderPrintActivityView = () => {
  const { t } = useTranslation();
  const { allOrderActivity } = useContext(AppContext);
  return (
    <div className="activityItemPrint">
      <span className="conditionTitle-printOrderReceipt">
        {t("activities")}
      </span>
      {allOrderActivity?.map((item: any) => (
        <ActivityItem comment={item} />
      ))}
    </div>
  );
};

const CompanyInfo = () => {
  const { channel_address, order } = useContext(AppContext);

  if (!channel_address) {
    return <div />;
  }
  return (
    <div className="company-container">
      <div>
        <img src={channel_address.print_logo} className="company-logo" alt="" />
      </div>
      <div className="companyNameText-ReceiptPrint">
        {channel_address.company}
      </div>

      <InfoItemLangs content={channel_address.address} />
      <InfoItemLangs content={channel_address.phone} />

      <InfoItemLangs content={channel_address.website} />
      <InfoItemLangs title="taxId" content={channel_address.tax} />
      <InfoItemLangs title="branch" content={order?.store?.branch_tax_id} />
    </div>
  );
};

const ReceiptInfo = ({ receiptText }: { receiptText: string }) => {
  const { order, contact, splitPayment = [], by } = useContext(AppContext);
  const { t } = useTranslation();

  const getPaymentMethodsText = () => {
    return join(
      map(splitPayment, (paymentItem) =>
        paymentItem?.method === "default" ? "Default" : t(paymentItem?.method)
      ),
      ","
    );
  };
  return (
    <div className="receiptInfo-receiptOrderPrint">
      <Barcode value={order?.orderid} width={1.5} height={55} fontSize={10} />
      <span className="receiptText-receptOrderPrint whitespace-pre">
        {receiptText}
      </span>
      <InfoItemLangs title="order" content={order.orderid} />
      <InfoItemLangs
        title="createdAt"
        content={TimeUtils.convertMiliToDateTime(order.created)}
      />
      <InfoItemLangs title="paymentMethod" content={getPaymentMethodsText()} />
      <InfoItemLangs title="storeCode" content={order?.store?.code} />
      <InfoItemLangs title="staffCode" content={by?.companyId} />
    </div>
  );
};

const PrintOrderReceipt = ({
  isShowSignature,
  isShowActivity,
  hideCustomProInfo,
  hideSelfCouponBy,
  receiptText = "ใบสั่งซื้อสินค้า (Purchase Order)",
}: any) => {
  const { order, shipping, billing, channel } = useContext(AppContext);
  const { t } = useTranslation();

  if (!order) {
    return <div />;
  }

  const renderConditionsReceipt = () => {
    return (
      <div className="print-order-condition-header-container">
        {/* <span className="conditionTitle-printOrderReceipt">
          {"หมายเหตุ / Terms and Conditions"}
        </span> */}
        <div
          className="conditionContent-printOrderReceipt"
          dangerouslySetInnerHTML={{ __html: channel?.terms }}
        />
        {/* <span className="conditionContent-printOrderReceipt">
          {CONDITION_TEXT}
        </span> */}
      </div>
    );
  };

  const renderSignature = () => {
    return (
      <div className="print-order-signature-header-container">
        <div>
          <InfoItemLangs
            containerClass="print-order-signature-paidByContainer"
            title="paidBy"
            content={
              <img src={order?.signature} className="paidSignatureImage" />
            }
          />
          <InfoItemLangs
            containerClass="print-order-signature-paidByContainer"
            title="date"
            content={TimeUtils.convertMiliToDate(order?.created)}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <InfoItemLangs
            containerClass="print-order-signature-paidByContainer"
            title="confirmedBy"
            content={<div className="signatureInput-print-order-signature" />}
          />
          <InfoItemLangs
            containerClass="print-order-signature-paidByContainer"
            title="date"
            content={<div className="signatureInput-print-order-signature" />}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="print-container print-order-receipt-container">
      <div className="print-order-receipt-header-container">
        <CompanyInfo />
        {order && <ReceiptInfo receiptText={receiptText} />}
      </div>
      <div className="print-order-receipt-header-container">
        <div className="print-shipping-container">
          <div className="titleShippingBillingText-printOrderReceipt">
            {getBothLangsText("shippingInformation")}
          </div>
          {shipping?.length === 0 && (
            <span
              className="contentShippingBillingText"
              style={{ marginTop: "7px" }}
            >
              N/A
            </span>
          )}
          {shipping?.map((shippingItem: any, index: number) => (
            <ShippingItemLayout shipping={shippingItem} key={index} />
          ))}
        </div>
        <div style={{ width: "30%" }} />
        <div className="print-billing-container">
          <div
            className="titleShippingBillingText-printOrderReceipt"
            style={{ textAlign: "right" }}
          >
            {getBothLangsText("billingInformation")}
          </div>

          <div
            style={{
              alignItems: "flex-end",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <BillingLayout />
          </div>
        </div>
      </div>
      <div className="print-order-receipt-header-container orderDetailTablePrintReceiptContainer">
        <OrderDetailTable
          isShowBothLangs
          hideCustomProInfo={hideCustomProInfo}
          hideSelfCouponBy={hideSelfCouponBy}
          isHideProductImage={(proItem: any) => {
            const productType = proItem?.product?.product_type;
            return (
              productType === PRODUCT_TYPE.GROUP ||
              productType === PRODUCT_TYPE.GROUP_CUSTOM_SIZE
            );
          }}
        />
      </div>
      {renderConditionsReceipt()}
      {isShowSignature && renderSignature()}
      {!isEmpty(order?.note) && isShowActivity && (
        <div className="print-order-receipt-header-container">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="conditionTitle-printOrderReceipt">{`${t(
              "orderNote"
            )}(${t("internal")})`}</div>
            <div className="contentShippingBillingText">{order?.note}</div>
          </div>
        </div>
      )}
      {isShowActivity && <OrderPrintActivityView />}
    </div>
  );
};

export default PrintOrderReceipt;
