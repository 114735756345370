import Drawer from "@components/shared/Drawer";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Checkbox, InputText } from "d-react-components";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "@helpers/context";

const AddAdditionalFee = ({ open, onClose }: any) => {
  const { t } = useTranslation();
  const { values: orderValues, setFieldValue: setOrderFieldValue } =
    useContext(AppContext);
  const additionalFeeForm = useFormik<any>({
    initialValues: {
      enableShippingFee: orderValues?.shippingFee >= 0,
      shippingFee: orderValues?.shippingFee,
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      enableShippingFee: Yup.boolean(),
      shippingFee: Yup.number(),
    }),
    onSubmit: (values) => {
      setOrderFieldValue(
        "shippingFee",
        values.enableShippingFee ? values.shippingFee : 0
      );
      onClose();
    },
  });

  const { values, errors, setFieldValue, handleSubmit } = additionalFeeForm;

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("addAdditionalFee")}
      destroyOnClose
      onSave={() => {
        handleSubmit();
      }}
    >
      <div className="flex items-center mb-2">
        <Checkbox
          className="mr-1"
          checked={values.enableShippingFee}
          onChange={() =>
            setFieldValue("enableShippingFee", !values.enableShippingFee)
          }
        />
        {t("shippingFee")}
      </div>
      <InputText
        value={values.shippingFee}
        label={t("amount")}
        onChange={(e) => setFieldValue("shippingFee", e.target.value)}
        suffix={"THB"}
        className="pl-4"
        error={errors?.shippingFee as string}
      />
    </Drawer>
  );
};

export default AddAdditionalFee;
