/* eslint-disable react-hooks/exhaustive-deps */
import InputSelectForm from "@common/input/InputSelectForm";
import InputTextForm from "@common/input/InputTextForm";
import TableItemDelivery from "@components/order/detail/content/TableItemDelivery";
import DriverSelect from "@components/user/components/DriverSelect";
import { STOCK_TRANSFER_DELIVERY_TYPES } from "@constants/transfer-stock";
import { transferStockListVehicle } from "@network/api/transfer-stock";
import { DateInput } from "d-react-components";
import { filter, isEmpty, reduce } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface IDeliveryForm {
  channelId: string;
  warehouseId: string;
  deliveryForm: any;
  deliveryItems: any[];
  delivery: any[];
  transferProduct: any[];
  isEdit?: boolean;
}

export const getDeliveredNumber = (deliverList: any[] = [], product: any) => {
  const filterList = deliverList.filter(
    (item: any) => item?.status !== "cancelled"
  );
  const deliverItemList = reduce(
    filterList,
    (list = [], item: any) => list.concat(item?.transfer_stock_product),
    []
  );
  const itemDeliver = getProductFromList(deliverItemList, product);
  return reduce(itemDeliver, (sum, item) => (sum += item.quantity), 0);
};

export const getProductFromList = (list: any = [], pro: any = {}) => {
  if (!(list?.length > 0)) {
    return [];
  }
  console.log("getProductFromList", pro);
  return filter(list, (item: any) => item?.productid === pro?.productid);
};

const DeliveryForm = ({
  channelId,
  warehouseId,
  deliveryItems,
  deliveryForm,
  delivery = [],
  transferProduct = [],
  isEdit = false,
}: IDeliveryForm) => {
  const formValues = deliveryForm?.values;
  const formErrors = deliveryForm?.errors;
  const inputClassName = "col-span-1 mt-3";
  const { t } = useTranslation();

  console.log("delivery", delivery);

  const [listVehicle, setListVehicle] = useState([]);

  useEffect(() => {
    if (!isEmpty(formValues?.deliveryType)) {
      loadListVehicle();
    }
  }, [formValues?.deliveryType]);

  const loadListVehicle = async () => {
    const body = {
      channel_id: channelId,
      warehouse_id: warehouseId,
      vehicle_ownership: formValues?.deliveryType,
    };
    const res = await transferStockListVehicle(body);
    const list = res?.data?.data?.vehicle ?? [];
    setListVehicle(list);
  };

  const renderTableProduct = () => {
    const transferDeliveryProducts = (listProducts: any[] = []) => {
      return listProducts.map((iPro: any) => {
        const proDeli = getProductFromList(deliveryItems, iPro);
        const productDelivery = proDeli?.[0];
        const { quantity = 0 } = iPro;
        const deliveredQty = getDeliveredNumber(delivery, iPro);
        const leftItemCount = quantity - deliveredQty;
        if (isEdit) {
          return {
            ...iPro,
            pickupQty: 0,
            deliveredQty,
            leftItemCount,
            product: iPro,
            stock: productDelivery?.quantity ?? 0,
          };
        }
        return {
          ...iPro,
          pickupQty: 0,
          deliveredQty,
          leftItemCount,
          product: iPro,
          stock: 0, // set quantity of current delivery
        };
      });
    };
    return (
      <div className="mb-3 mt-5 w-100 " style={{ overflowX: "scroll" }}>
        <TableItemDelivery
          products={transferDeliveryProducts(transferProduct)}
          onChange={(changePro: any) => {
            deliveryForm.setFieldValue("products", changePro);
          }}
          //   disabled={isView}
          transferDelivery
        />
      </div>
    );
  };

  return (
    <div className="grid grid-cols-1 gap-4">
      <InputSelectForm
        dataSource={STOCK_TRANSFER_DELIVERY_TYPES}
        keyData="deliveryType"
        form={deliveryForm}
        className={inputClassName}
      />
      <InputSelectForm
        keyData="vehicle"
        form={deliveryForm}
        className={inputClassName}
        dataSource={listVehicle}
        getLabel={(item: any) => item.registration}
      />
      <DriverSelect
        channelId={channelId}
        warehouseId={warehouseId}
        value={formValues?.driver}
        onChange={(value) => deliveryForm.setFieldValue("driver", value)}
      />
      <InputTextForm
        keyData="deliveryNo"
        form={deliveryForm}
        className={inputClassName}
      />
      <DateInput
        value={formValues?.expectedDateArrival}
        onChange={(value) =>
          deliveryForm.setFieldValue("expectedDateArrival", value)
        }
        label={t("expectedDateArrival")}
        className={inputClassName}
        error={formErrors?.expectedDateArrival as any}
        required
        format="DD/MM/YYYY"
      />
      {renderTableProduct()}
      <InputTextForm
        keyData="remark"
        form={deliveryForm}
        className={inputClassName}
        multiple
      />
    </div>
  );
};

export default DeliveryForm;
