import { DELIVERY_STATUS_LIST } from "@constants/delivery";
import styled from "@emotion/styled";
import { getDeliveryStockSummary } from "@network/api/delivery-stock";
import { Badge, ITabItem, ObjectUtils, TabBar } from "d-react-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface IDeliveryTabs {
  tabSelected: ITabItem;
  onChangeTab: (value: ITabItem) => void;
}

const TAB_LIST = [{ id: "all", label: "all" }, ...DELIVERY_STATUS_LIST];

const DeliverySOTabs = ({ tabSelected, onChangeTab }: IDeliveryTabs) => {
  const [summary, setSummary] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    loadSummary();
  }, []);

  const loadSummary = () => {
    getDeliveryStockSummary().then((res) => {
      const summaryRes = res?.data?.data?.request ?? [];
      const summary = ObjectUtils.mapArrayToObject(
        summaryRes,
        (item) => item.status
      );

      setSummary(summary);
    });
  };

  return (
    <TabBar
      dataSource={TAB_LIST}
      onChange={onChangeTab}
      value={tabSelected}
      getLabel={(item) => {
        const summaryNumber = (summary as any)?.[item.value]?.total ?? 0;
        const badgeNumber = summaryNumber > 999 ? "999+" : summaryNumber;
        return (
          <StyledBadge className="flex items-center">
            <span>{t(item.label as string) || "N/A"}</span>
            <Badge
              index={badgeNumber}
              variant="index"
              size="x-large"
              color="red"
            />
          </StyledBadge>
        );
      }}
      isScroll
      hideScrollBar
      minWidthItem={"100%"}
      classNameItem="justify-content-start text-gray-500 bg-white shrink-0 grow-0 border-right-0 border-left-0"
      variant="horizontal"
    />
  );
};

const StyledBadge = styled.div`
  .d-badge__badge-wrapper div {
    font-size: 8px !important;
  }
`;
export default DeliverySOTabs;
