import { PICKUP_TYPE } from "@constants/order";
import { getFullName } from "@helpers/string";
import { createPickup, getOrderDetail, signPickup } from "@network/api/order";
import { Button, Drawer, Icon, Notifications } from "d-react-components";
import { join, map } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PickupDrawer from "./PickupDrawer";
import PickupItem from "./PickupItem";
import { SignatureModal } from "./SignatureField";

const ModalConfirmPickup = ({ onClose, open, orderId, orderUrl }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const CONFIG_PICKUP_CONFIRM = useMemo(
    () => ({
      id: "confirm",
      title: t("storePickup"),
      okText: t("confirmPickup"),
      isShowAdd: true,
      saveText: "",
    }),
    [t]
  );

  const CONFIG_PICKUP_COMPLETE = useMemo(
    () => ({
      id: "complete",
      title: t("pickupCreated"),
      okText: t("completePickup"),
      isShowAdd: false,
      saveText: t("orderDetail"),
    }),
    [t]
  );

  const [CONFIG, setConfig] = useState(CONFIG_PICKUP_CONFIRM);

  const [pickupList, setPickupList] = useState<any[]>([]);
  const [orderDetail, setOrderDetail] = useState<any>();
  const [modalPickup, setModalPickup] = useState<any>({
    open: false,
    pickup: {},
  });
  const [modalSign, setModalSign] = useState(false);
  useEffect(() => {
    getOrderDetail(orderId).then((res) => {
      const resOrderInfo = res?.data?.data;
      setOrderDetail(resOrderInfo);
    });
  }, []);

  useEffect(() => {
    if (!orderDetail) {
      return;
    }

    const productResult = convertProductToPickupData(
      orderDetail?.products ?? []
    );
    const pickupDefault = {
      id: new Date().getTime(),
      store: orderDetail?.order?.store,
      pickupTime: new Date().getTime(),
      personName: getFullName(orderDetail?.customer),
      personPhone: orderDetail?.customer?.phone ?? "",
      products: productResult,
      note: "",
    };
    setPickupList([pickupDefault]);
  }, [orderDetail]);

  const isPickAll = () => {
    return true;
    // const isPickAll = _.every(orderDetail.products, (proItem) => {
    //   const picked = _.reduce(
    //     pickupList,
    //     (sum, pickupItem) => {
    //       const pickupPro = _.find(pickupItem.products, (proParam) => proParam?.id === proItem.id);
    //       return sum + (pickupPro?.stock ?? 0);
    //     },
    //     0
    //   );
    //   return picked === proItem.quantity;
    // });

    // if (!isPickAll) {
    //   Notifications.showError(Messages.allItemsAreNotPick);
    // }

    // return isPickAll;
  };

  const convertProductToPickupData = (productList: any) => {
    const pickup = productList
      .filter((item: any) => !(item?.groups?.length > 0))
      ?.map((pro: any) => {
        return {
          order_product_id: pro?.id,
          stock: pro.stock || pro.stock === 0 ? pro.stock : pro?.quantity ?? 0,
        };
      });

    productList
      .filter((item: any) => item?.groups?.length > 0)
      .forEach((pro: any) => {
        const { groups } = pro;
        groups.forEach((childPro: any) => {
          pickup.push({
            order_product_id: pro?.id,
            order_product_group_id: childPro?.id,
            stock:
              childPro.stock || childPro.stock === 0
                ? childPro.stock
                : childPro?.quantity ?? 0,
          });
        });
      });
    return pickup;
  };

  const onClickOk = async () => {
    switch (CONFIG.id) {
      case "confirm":
        return onClickConfirmPickup();
      default:
        return onClickCompletePickup();
    }
  };

  const onClickCompletePickup = () => {
    setModalSign(true);
  };

  const onClickConfirmPickup = () => {
    if (
      !orderDetail ||
      (orderDetail?.order?.pickup_type === PICKUP_TYPE.FULL && !isPickAll())
    ) {
      return Promise.reject();
    }
    const APIProgress = pickupList?.map((pickupItem) => {
      const { store, products, personName, personPhone, pickupTime, notes } =
        pickupItem;

      const body = {
        orderid: orderDetail?.order?.id,
        storeid: store?.id,
        pickup_time: pickupTime?.valueOf(),
        person_name: personName,
        person_phone: personPhone,
        pickup: products,
        notes,
      };
      return createPickup(body);
    });
    return Promise.all(APIProgress).then(
      (res) => {
        Notifications.showSuccess(t("notification:confirmPickupSuccess"));
        const pickupResList = map(
          res,
          (resItem) => resItem?.data?.data?.pickup
        );
        setPickupList(pickupResList);
        onSuccessConfirmPickup();
      },
      (err: any) => {
        Notifications.showError(err?.respone?.data?.message);
      }
    );
  };

  const onCompletePickup = (signature: any) => {
    if (!signature) {
      Notifications.showError(t("notification:signatureIsRequire"));
      return Promise.reject();
    }
    const APIProgress = pickupList?.map((pickup) => {
      const body = {
        pickupid: pickup.id,
        signature_pickup_order: signature,
      };

      return signPickup(body);
    });

    return Promise.all(APIProgress).then(
      (res) => {
        Notifications.showSuccess(t("notification:completePickupSuccess"));
        onNavigateOrderDetail();
      },
      (err: any) => {
        Notifications.showError(err?.respone?.data?.message);
      }
    );
  };

  const onNavigateOrderDetail = () => {
    navigate(`/order/${orderId}`);
    return Promise.resolve();
  };

  const onSuccessConfirmPickup = () => {
    if (pickupList.length > 1) {
      onNavigateOrderDetail();
    } else {
      setConfig(CONFIG_PICKUP_COMPLETE);
    }
  };

  const onSavePickup = (pickupData: any) => {
    if (modalPickup.pickup) {
      const results = pickupList?.map((item) => {
        if (item.id === pickupData.id) {
          const { products } = pickupData;
          const productResult = convertProductToPickupData(products ?? []);
          return { ...pickupData, products: productResult };
        }
        return item;
      });
      setPickupList(results);
    } else {
      const { products } = pickupData;
      const productResult = convertProductToPickupData(products ?? []);
      const pickupResult = { ...pickupData, products: productResult };
      setPickupList([...pickupList, pickupResult]);
    }

    setModalPickup({ open: false, pickup: null });
    return Promise.resolve();
  };

  const renderTitleModal = (
    <div className="headerModalConfirmPickup flex">
      <div className="cardTitleText flex-1">{t(CONFIG.title)}</div>
      {CONFIG.isShowAdd && (
        <Button
          className="subTitle1 addText"
          onClick={() => setModalPickup({ open: true, pickup: null })}
        >
          <Icon name="add" />
          {t("add")}
        </Button>
      )}
    </div>
  );

  const renderContent = () => {
    switch (CONFIG.id) {
      case "confirm":
        return renderPickupConfirmList();
      default:
        return renderPickupComplete();
    }
  };

  const renderPickupConfirmList = () => {
    return (
      <div className="modalConfirmPickupContainer">
        {pickupList?.map((pickupItem) => (
          <PickupItem
            pickup={pickupItem}
            onClickEdit={() =>
              setModalPickup({ open: true, pickup: pickupItem })
            }
          />
        ))}
      </div>
    );
  };

  const renderPickupComplete = () => {
    return (
      <div className="text-center">
        <img src="/images/icons/box.svg" className="w-12 h-12 inline-block" alt="icon" />
        <div className="subTitle1 mt-3">
          {t("pickupForOrder")}
          <span className="font-weight-bold mx-1">
            {orderDetail?.order?.orderid}
          </span>
          {t("wasCreatedSuccessfully")}
        </div>
        <div className="subTitle1 mt-3">
          {`${t("status")}: `}
          <span className="text-warning">Pending</span>
        </div>

        <div className="subTitle1 mt-3 font-weight-bold">
          {`${t("ref")}: `}
          <span className="font-weight-normal">
            {join(
              pickupList?.map((item) => item.code),
              ", "
            )}
          </span>
        </div>

        <SignatureModal
          open={modalSign}
          onSave={onCompletePickup}
          onClose={() => setModalSign(false)}
          description={t("byClickConfirmButtonBelow")}
        />
      </div>
    );
  };

  return (
    <Drawer
      title={renderTitleModal}
      open={open}
      onClose={onClose}
      // saveText={t("saveText")}
      // showSaveButton={!!t("saveText")}
      // style={{
      //   maxWidth: "80%",
      //   maxHeight: "80%",
      //   minWidth: "30%",
      //   overflowY: "scroll",
      // }}
      // showCancelButton={false}
      // onClickSave={onNavigateOrderDetail}
      // additionalText={CONFIG.okText}
      // onClickAddtional={onClickOk}
    >
      <div className="pb-10">{renderContent()}</div>
      <div className="position-absolute bottom-0 w-100 end-0 start-0 flex-center-y justify-content-end py-3 px-3 bg-white">
        <Button
          className="mr-2"
          onClick={() => {
            onClickOk();
          }}
        >
          {t(CONFIG.okText)}
        </Button>
        {!!CONFIG.saveText && (
          <Button
            onClick={() => {
              onNavigateOrderDetail();
            }}
            variant="outline"
          >
            {t(CONFIG.saveText)}
          </Button>
        )}
      </div>
      {modalPickup.open && (
        <PickupDrawer
          open={modalPickup.open}
          onClose={() => setModalPickup({ open: false, pickup: null })}
          pickup={modalPickup.pickup}
          productOrderList={orderDetail?.products ?? []}
          deliveryList={orderDetail?.delivery ?? []}
          pickupList={pickupList}
          store={orderDetail?.order?.store}
          customer={orderDetail?.customer}
          onSave={onSavePickup}
          disablePrint
        />
      )}
    </Drawer>
  );
};

export default ModalConfirmPickup;
