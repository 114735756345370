import AppContext from "@helpers/context";
import { getDetailGpEvent, getGPEventChannelList } from "@network/api/gp-event";
import { Progress, TabBar } from "d-react-components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import EventDetailInfo from "./EventDetailInfo";
import GPEventDetailActivity from "./GPEventDetailActivity";
import GPEventDetailChannel from "./GPEventDetailChannel";
import GPEventDetailConfig from "./GPEventDetailConfig";
import GPEventDetailGeneral from "./GPEventDetailGeneral";
import GPEventDetailHeader from "./GPEventDetailHeader";
import GPEventDetailWarehouse from "./GPEventDetailWarehouse";

const TABS = [
  {
    id: "dashboard",
    label: "dashboard",
    component: <GPEventDetailActivity />,
  },
  {
    id: "general",
    label: "general",
    component: <GPEventDetailGeneral />,
  },

  {
    id: "channel",
    label: "channel",
    component: <GPEventDetailChannel />,
  },
  {
    id: "warehouse",
    label: "warehouse",
    component: <GPEventDetailWarehouse />,
  },
  {
    id: "gpConfig",
    label: "gpConfig",
    component: <GPEventDetailConfig />,
  },
];

const GPEventDetail = () => {
  const [eventDetail, setEventDetail] = useState(null);
  const [eventChannelList, setEventChannelList] = useState([]);
  const [tab, setTab] = useState<any>(TABS[0]);
  const { eventId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    console.log("loadEventDetail");
    loadEventDetail();
    loadEventChannels();
  }, []);

  const loadEventDetail = () => {
    Progress.show(
      { method: getDetailGpEvent, params: [eventId] },
      (res: any) => {
        const event = res?.data?.data?.event;
        setEventDetail(event);
      }
    );
  };

  const loadEventChannels = () => {
    const body = {
      id: eventId,
      action: "in",
    };
    getGPEventChannelList(body).then((res) => {
      const channels = res?.data?.data?.channels ?? [];
      setEventChannelList(channels);
    });
  };

  // if (!eventDetail) return <div />;

  return (
    <AppContext.Provider
      value={{
        eventDetail,
        eventChannelList,
        loadEventChannels,
        loadEventDetail,
      }}
    >
      <div className=" flex-column-container bg-trans">
        <GPEventDetailHeader />
        <div className="grid mt-3 grid-cols-12 gap-4 p-3">
          <div className="col-span-4">
            <EventDetailInfo />
          </div>
          <div className="col-span-8 px-3">
            <TabBar
              dataSource={TABS}
              onChange={setTab}
              value={tab}
              getLabel={(item: any) => t(item.label)}
            />
            {tab.component}
          </div>
        </div>
      </div>
    </AppContext.Provider>
  );
};

export default GPEventDetail;
