import InputTextForm from "@common/input/InputTextForm";
import Drawer from "@components/shared/Drawer";
import {
  CART_RULE_CONFIGURATION_QUANTITY_TYPES,
  CART_RULE_TYPE,
  CART_RULE_TYPES,
} from "@constants/cart-rule";
import { YES_NO_OPTIONS } from "@constants/common";
import { exportToCSV } from "@helpers/file";
import CartRuleAPI from "@network/api/cart-rule";
import { Spin, Tooltip } from "antd";
import classNames from "classnames";
import {
  Button,
  Icon,
  InputText,
  Notifications,
  Select,
  StringUtils,
  useFirstTime,
} from "d-react-components";
import { useFormik } from "formik";
import { debounce, dropRight, filter, isEmpty, map, toUpper } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface ICartRuleConfigurationForm {
  form: any;
  formDisabled?: any;
}

const INIT_COUPON_CODE = {
  code: [],
  quantity: 0,
  auto: false,
  existingCode: [],
};

const INIT_DISPLAY_NOTIFY = {
  display: false,
  text: "",
  error: false,
};

const CouponCodeMultipleInput = ({
  form,
  onAddCode,
  codeList,
  onRemoveCode,
}: any) => {
  const [code, setCode] = useState<string>("");
  const inputClassName = classNames("mt-2 col-span-1");
  const formValues = form?.values ?? {};
  const { t } = useTranslation();

  const onValidateCode = () => {
    if (!formValues.period_to || !formValues.period_from) {
      Notifications.showError(t("youHaveToSetPeriodTimeForCoupon"));
      return;
    }
    if (isEmpty(code)) {
      return;
    }

    const body = {
      coupons: [code],
      period_to: formValues.period_to,
      period_from: formValues.period_from,
    };
    CartRuleAPI.validateCoupon(body).then(() => {
      onAddCode(code);
      setCode("");
    });
  };

  return (
    <div className="mt-2 col-span-2 bg-primary-10 p-3">
      <div className="grid grid-cols-2 gap-4">
        {map(codeList, (code) => (
          <div
            className={classNames(
              inputClassName,
              "border text-x-small d-input-text__input flex-row-between-center bg-white"
            )}
          >
            {code}
            <Icon
              name="delete"
              onClick={() => onRemoveCode(code)}
              className="text-error cursor-default"
            />
          </div>
        ))}
        <InputText
          className={inputClassName}
          placeholder="Please input"
          value={code}
          onChange={(event) => setCode(event.target.value)}
          onKeyDown={(e) => {
            if (e.code === "Enter") {
              onValidateCode();
            }
          }}
        />
      </div>
    </div>
  );
};

const CouponAutoGenerateCode = ({ onGenerate, cartRuleForm }: any) => {
  const { t } = useTranslation();
  const [codeList, setCodeList] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const autoGenForm = useFormik<any>({
    initialValues: {} as any,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: schema,
    onSubmit: (values: any) => {
      generateCoupon(values);
    },
  });
  const formValues = autoGenForm?.values;
  const formErrors = autoGenForm?.errors;
  const inputClassName = classNames("mt-2 col-span-1");

  const generateCoupon = (input: any) => {
    const listCode = [];
    for (let i = 0; i < input?.quantity; i++) {
      const code = `${input.prefix ?? ""}${toUpper(
        StringUtils.generateCode(12)
      )}${input.suffix ?? ""}`;
      listCode.push(code);
    }
    validateCoupon(listCode);
  };

  const validateCoupon = (codes: any) => {
    if (!cartRuleForm?.values.period_to || !cartRuleForm?.values.period_from) {
      Notifications.showError(t("youHaveToSetPeriodTimeForCoupon"));
      return;
    }
    if (isEmpty(codes)) {
      return;
    }

    const body = {
      coupons: codes,
      period_to: cartRuleForm?.values.period_to,
      period_from: cartRuleForm?.values.period_from,
    };
    CartRuleAPI.validateCoupon(body).then(() => {
      onGenerate(codes);
      autoGenForm.setValues({} as any);
      setCodeList(codes);
    });
  };

  return (
    <div className="col-span-2 bg-primary-10 p-3">
      <div className="grid grid-cols-3 gap-4">
        <InputTextForm
          keyData="prefix"
          className={inputClassName}
          placeholder="Please input"
          form={autoGenForm}
        />
        <InputTextForm
          keyData="suffix"
          className={inputClassName}
          placeholder="Please input"
          form={autoGenForm}
        />
        <InputTextForm
          keyData="quantity"
          className={inputClassName}
          placeholder="Please input"
          form={autoGenForm}
        />
      </div>
      <div className="text-small mt-2">{`${t("formatOfCode")}: ${
        formValues.prefix ?? ""
      }XXXXXXXXX${formValues.suffix ?? ""}`}</div>
      <div className="divider mt-2" />

      <div className="mt-3 flex-row">
        <Button
          onClick={() => autoGenForm.handleSubmit()}
          className=""
          disabled={!formValues.quantity}
        >
          {t("generate")}
        </Button>
        {!!codeList?.length && (
          <Button
            onClick={() => setOpenModal(true)}
            className="ml-2"
            variant="outline"
          >
            <div className="flex-row align-items-center">
              {t("generatedCoupon")}
              <div className="text-white bg-red rounded-3 p-1 text-tiny h-[20px] w-[20px] ml-1 flex-center">
                {codeList?.length}
              </div>
            </div>
          </Button>
        )}

        <Drawer
          title={t("couponCode")}
          open={openModal}
          onClose={() => setOpenModal(false)}
          // customSideButton={
          //   <div
          //     className="text-small cursor-pointer"
          //     onClick={onClickExportCode}
          //   >
          //     {t("export")}
          //   </div>
          // }
        >
          <div>
            <label>{t("couponCodes")}</label>
            {map(codeList, (cod, index) => (
              <div>
                {`${t("couponCode")} ${index + 1}:`}
                &nbsp; <span> {`${cod}`}</span>
              </div>
            ))}
          </div>
        </Drawer>
      </div>
    </div>
  );
};

const CartRuleConfigurationForm = ({
  form,
  formDisabled,
}: ICartRuleConfigurationForm) => {
  const [couponCode, setCouponCode] = useState<any>(INIT_COUPON_CODE);
  const [displayNotify, setDisplayNotify] = useState(INIT_DISPLAY_NOTIFY);
  const [openModal, setOpenModal] = useState(false);
  const [onProgress, setOnProgress] = useState(false);
  const [removableCode, setRemovableCode] = useState([]);
  const [unRemovableCode, setUnRemovableCode] = useState([]);
  const [couponCodeDisplay, setCouponCodeDisplay] = useState("");
  const isFirstTime = useFirstTime();
  const { t } = useTranslation();
  const formValues = form?.values ?? {};
  const inputClassName = classNames("mt-2 col-span-1");

  useEffect(() => {
    if (isFirstTime) {
      return;
    } // => not validate coupon in the first render
    if (formValues?.code && !isEmpty(formValues?.code)) {
      validateCoupon(formValues?.code);
    }
  }, [formValues?.period_from, formValues?.period_to]);

  useEffect(() => {
    if (formValues?.coupon === CART_RULE_TYPE.SPECIFIC_COUPON) {
      validateCoupon(couponCode?.code);
    }
  }, [couponCode?.code]);

  useEffect(() => {
    if (
      formValues?.coupon === CART_RULE_TYPE.SPECIFIC_COUPON &&
      isEmpty(couponCodeDisplay) &&
      !isEmpty(couponCode?.code)
    ) {
      setCouponCodeDisplay(couponCode?.code);
    }
  }, [couponCode?.code]);

  //update local code according to coupon code
  useEffect(() => {
    if (formValues?.coupon === CART_RULE_TYPE.SPECIFIC_COUPON) {
      setCouponCode({
        code: formValues.code,
        auto: false,
        quantity: 1,
        existingCode: formValues.existingCode,
      });
    } else if (formValues?.coupon === CART_RULE_TYPE.AUTO_GENERATE_COUPON) {
      setCouponCode({
        code: formValues.code,
        quantity: formValues.code.length + formValues?.existingCode.length,
        auto: true,
        existingCode: formValues.existingCode,
      });
    }
  }, [formValues?.code, formValues?.existingCode]);

  const onChangeCouponText = debounce((value: any) => {
    setDisplayNotify({ display: false, text: "", error: false });
    setCouponCode({ ...couponCode, code: [value] });
  }, 500);

  const generateCoupon = () => {
    const { code, existingCode } = formValues;
    const listCode = [];
    if (!couponCode.quantity) {
    } else if (couponCode.quantity === code.length + existingCode.length) {
    } else if (couponCode.quantity < existingCode.length) {
      // => case removing code
      if (existingCode.length - couponCode.quantity > removableCode.length) {
        setCouponCode({ ...couponCode, quantity: existingCode.length }); // if removing quantity > removable quantity => error
        return Notifications.showError(t("cantChangeThisQuantityOfCouponCode"));
      }
      const removedList = dropRight(
        removableCode,
        existingCode.length - couponCode.quantity
      );
      let result: any = [...removedList];
      if (unRemovableCode.length > 0) {
        result = result.concat(unRemovableCode);
      }
      const cloneExisting = filter(existingCode, (cod: any) =>
        result.includes(cod.code ?? "")
      );
      form.setFieldValue("existingCode", cloneExisting);
      form.setFieldValue("code", []);
    } else if (
      existingCode.length + code.length >
      couponCode.quantity
      // && couponCode.quantity >= existingCode.length
    ) {
      const clone = dropRight(
        code,
        code.length + existingCode.length - couponCode.quantity
      );
      form.setFieldValue("code", clone);
    } else if (couponCode.quantity > existingCode.length + code.length) {
      //case add more code
      for (
        let i = 0;
        i < couponCode.quantity - existingCode.length - code.length;
        i++
      ) {
        // const lengthRandom = StringUtils.getRandomNumber(4, 20);
        const code = toUpper(StringUtils.generateCode(12));
        listCode.push(code);
      }
      validateCoupon(listCode);
    }
  };

  const validateCoupon = (validateCode: string[]) => {
    if (!validateCode || isEmpty(validateCode)) {
      return;
    }
    if (!formValues.period_to || !formValues.period_from) {
      Notifications.showError(t("youHaveToSetPeriodTimeForCoupon"));
      return;
    }

    if (
      formValues.coupon === "specific_coupon" &&
      validateCode === formValues.code
    ) {
      return;
    }
    if (
      formValues.coupon === "specific_coupon" &&
      (validateCode[0]?.length > 20 || validateCode[0]?.length < 4)
    ) {
      setDisplayNotify({
        display: true,
        text: t("couponCodeNotRightLength"),
        error: true,
      });
      return;
    }
    setDisplayNotify(INIT_DISPLAY_NOTIFY);
    setOnProgress(true);
    const body = {
      coupons: validateCode,
      period_to: formValues.period_to,
      period_from: formValues.period_from,
    };
    CartRuleAPI.validateCoupon(body)
      .then((respone) => {
        if (formValues.coupon === "specific_coupon") {
          setDisplayNotify({
            display: true,
            text: t("couponCodeIsValid"),
            error: false,
          });
        }
        const result =
          formValues.coupon === "specific_coupon"
            ? validateCode
            : formValues.code.concat(validateCode);
        setOnProgress(false);
        form.setFieldValue("code", result);
      })
      .catch((err) => {
        Notifications.showError(err.message);
        setDisplayNotify({
          display: true,
          text: t("couponCodeIsInvalid"),
          error: true,
        });
        setOnProgress(false);
        if (formValues.coupon === "auto_generated_coupons") {
          generateCoupon();
        }
      });
  };

  const onClickExportCode = () => {
    const dataToExport = map(formValues.code, (item, index) => ({
      index,
      code: item,
    }));
    exportToCSV(dataToExport, "CouponCode");
  };

  const renderSingleCodeInput = () => {
    const classNameCode = classNames("flex-center-y mt-1", {
      "text-success": !displayNotify.error,
      "text-error": displayNotify.error,
    });
    const displayNotifyContent = () => {
      return (
        <div className={classNameCode}>
          {onProgress ? (
            <Spin />
          ) : (
            <>
              {!displayNotify.error && <Icon className="mr-1" name="check" />}
              <text>{displayNotify.text}</text>
            </>
          )}
        </div>
      );
    };

    return (
      <div
        className={inputClassName}
        id={
          formValues.coupon === "specific_coupon" ? "" : "cartRuleGridItemHide"
        }
      >
        <InputText
          label={t("couponCode")}
          onChange={(event) => {
            const { value } = event.target;
            if (!formValues.period_to || !formValues.period_from) {
              return Notifications.showError(
                t("pleaseInputStartingDateAndEndDateFirst")
              );
            }
            if (value?.length > 20) {
              Notifications.showError(t("couponCodeNotRightLength"));
              return;
            }
            onChangeCouponText(value);
            setCouponCodeDisplay(value);
          }}
          disabled={!formValues.period_to || !formValues.period_from}
          value={couponCodeDisplay}
        />

        {displayNotify.display &&
          formValues.coupon === "specific_coupon" &&
          displayNotifyContent()}
      </div>
    );
  };

  const renderQuantityCodeInput = () => {
    return (
      <div
        className={inputClassName}
        id={
          formValues.coupon === "auto_generated_coupons"
            ? ""
            : "cartRuleGridItemHide"
        }
      >
        <InputText
          label={
            <div>
              {t("couponQuantity")}
              {formValues.coupon === "auto_generated_coupons" &&
                couponCode.quantity > 0 && (
                  <span
                    className="text-primary small ml-3"
                    onClick={() => setOpenModal(true)}
                  >
                    {t("viewCoupons")}
                  </span>
                )}
            </div>
          }
          onChange={(event) => {
            const value = parseInt(event.target.value);
            if (value > 1000) {
              return;
            }
            setCouponCode({ ...couponCode, quantity: value });
          }}
          value={couponCode.quantity}
          type="number"
          onBlur={() => generateCoupon()}
        />
        <Drawer
          title={t("couponCode")}
          open={openModal}
          onClose={() => setOpenModal(false)}
          customSideButton={
            <div
              className="text-small cursor-pointer"
              onClick={onClickExportCode}
            >
              {t("export")}
            </div>
          }
        >
          <div className="subTitle1 listCode">
            {!isEmpty(formValues.existingCode) && (
              <div>
                {/* {existingCode && <div></div>} */}
                <div>{t("existingCode")}</div>
                {formValues.existingCode &&
                  map(formValues.existingCode, (cod, index) => {
                    return (
                      <div>
                        {`${t("couponCode")} ${index + 1}:`}
                        &nbsp; <span> {`${cod.code}`}</span>
                      </div>
                    );
                  })}
              </div>
            )}
            {!isEmpty(formValues.code) && (
              <div>
                <div>{t("newGenerateCode")}</div>
                {map(formValues.code, (cod, index) => {
                  return (
                    <div>
                      {`${t("couponCode")} ${index + 1}:`}
                      &nbsp; <span> {`${cod}`}</span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </Drawer>
      </div>
    );
  };

  const renderLabelTooltip = (content: any, tooltip: any) => (
    <div className="flex-center-y">
      {content}
      <Tooltip title={tooltip} overlayClassName="containerTooltip ">
        <Icon name="info" className="text-primary ml-3" />
      </Tooltip>
    </div>
  );

  const renderCouponCodeInput = () => {
    if (formValues.configQuantityType === "single") {
      return renderSingleCodeInput();
    }

    return (
      <CouponCodeMultipleInput
        form={form}
        onAddCode={(code: string) =>
          form.setFieldValue("code", [...(formValues.code ?? []), code])
        }
        onRemoveCode={(code: string) =>
          form.setFieldValue(
            "code",
            filter(formValues.code ?? [], (item) => item !== code)
          )
        }
        codeList={formValues.code ?? []}
      />
    );
  };

  return (
    <div className="grid grid-cols-2 gap-4">
      <Select
        className={inputClassName}
        label={t("type")}
        value={formValues.coupon}
        onChange={(value) => {
          if (formValues?.unRemovableCode?.length > 0) {
            return Notifications.showError(t("youCantChangeTypeOfCoupon"));
          }
          setDisplayNotify({ display: false, text: "", error: false });
          form.setFieldValue("code", []);
          setCouponCode({
            code: [],
            quantity: 0,
            auto: value === "auto_generated_coupons",
            existingCode: [],
          });
          form.setFieldValue("coupon", value);
          form.setFieldValue("existingCode", []);
        }}
        getLabel={(item) => t(item.label)}
        dataSource={CART_RULE_TYPES}
        disabled={formDisabled?.coupon}
      />
      {formValues.coupon === CART_RULE_TYPE.SPECIFIC_COUPON && (
        <Select
          className={inputClassName}
          label={t("quantity")}
          value={formValues.configQuantityType}
          dataSource={CART_RULE_CONFIGURATION_QUANTITY_TYPES}
          getLabel={(item) => t(item.label)}
          onChange={(value) => {
            form.setFieldValue("configQuantityType", value);
          }}
          disabled={formDisabled?.configQuantityType}
        />
      )}
      {formValues.coupon === CART_RULE_TYPE.SPECIFIC_COUPON &&
        renderCouponCodeInput()}
      {formValues.coupon === CART_RULE_TYPE.AUTO_GENERATE_COUPON && (
        <CouponAutoGenerateCode
          cartRuleForm={form}
          onGenerate={(codes: any) => form.setFieldValue("code", codes)}
        />
      )}
      <InputText
        className={inputClassName}
        label={renderLabelTooltip(t("usageLimit"), t("usageLimitTooltip"))}
        onChange={(event) => {
          form.setFieldValue("usage_limit", parseInt(event.target.value));
        }}
        value={formValues.usage_limit}
        type="number"
      />
      <InputText
        className={inputClassName}
        label={renderLabelTooltip(
          t("usageLimitPerUser"),
          t("usageLimitPerUserTooltip")
        )}
        onChange={(event) => {
          let value = parseInt(event.target.value);
          if (!value) {
            value = 0;
          }
          form.setFieldValue("usage_customer", value);
        }}
        value={formValues.usage_customer}
        type="number"
      />
      <Select
        className={inputClassName}
        label={
          renderLabelTooltip(
            t("conjunctionalUse"),
            t("conjunctionTooltip")
          ) as any
        }
        value={formValues.conjunctional}
        dataSource={YES_NO_OPTIONS}
        onChange={(value) => form.setFieldValue("conjunctional", value)}
      />
      <InputText
        className={inputClassName}
        label={renderLabelTooltip(t("priority"), t("priorityTooltip"))}
        onChange={(event) => {
          let value = parseInt(event.target.value);
          if (!value) {
            value = 0;
          }
          form.setFieldValue("priority", value);
        }}
        value={formValues.priority}
        type="number"
      />
    </div>
  );
};

export default CartRuleConfigurationForm;
