import InputSelectForm from "@common/input/InputSelectForm";
import ChannelSelect from "@components/channel/components/ChannelSelect";
import StoreSelect from "@components/store/components/StoreSelect";
import UserSelect from "@components/user/components/UserSelect";
import { DELIVERY_STATUS_LIST, SHIPPOP_TYPES } from "@constants/order";
import { getDeliveryTypeList } from "@network/api/delivery";
import { DateInput, ObjectUtils, TimeUtils } from "d-react-components";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface IDeliveryTrackingFilterForm {
  form: any;
}

const SHIPPOP_TYPES_ARRAY = ObjectUtils.mapObjectToArray(SHIPPOP_TYPES);

const DeliveryTrackingFilterForm = ({ form }: IDeliveryTrackingFilterForm) => {
  const formValues = form?.values ?? {};
  const formErrors = form?.errors ?? {};
  const [deliveryTypes, setDeliveryTypes] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    loadDeliveryTypes();
  }, []);

  const classNameInput = "col-span-4";

  const loadDeliveryTypes = () => {
    getDeliveryTypeList().then((res: any) => {
      const deliveryTypes = res?.data?.data?.delivery ?? [];
      setDeliveryTypes(deliveryTypes);
    });
  };

  return (
    <div className="grid grid-cols-4 gap-4">
      <ChannelSelect
        onChange={(values) => form.setFieldValue("channels", values)}
        value={formValues.channels}
        className={classNameInput}
        multiple
      />
      <StoreSelect
        onChange={(values) => form.setFieldValue("warehouse", values)}
        value={formValues.warehouse}
        className={classNameInput}
        multiple
      />
      <UserSelect
        multiple
        onChange={(values) => form.setFieldValue("createdBy", values)}
        value={formValues.createdBy}
        className={classNameInput}
        label={t("createdBy")}
      />
      <DateInput
        label={t("from")}
        onChange={(date) => {
          let result = date ? moment(date) : null;
          form.setFieldValue("from", result);
        }}
        value={formValues?.from ? moment(formValues?.from) : null}
        format={TimeUtils.DATE_FORMAT}
        className="col-span-2"
      />
      <DateInput
        label={t("to")}
        onChange={(date) => {
          let result = date ? moment(date) : null;
          form.setFieldValue("to", result);
        }}
        value={formValues?.to ? moment(formValues?.to) : null}
        format={TimeUtils.DATE_FORMAT}
        className="col-span-2"
      />
      <DateInput
        label={t("expectArrivalDate")}
        onChange={(date) => {
          let result = date ? moment(date) : null;
          form.setFieldValue("expectArrivalDate", result);
        }}
        value={
          formValues?.expectArrivalDate
            ? moment(formValues?.expectArrivalDate)
            : null
        }
        format={TimeUtils.DATE_FORMAT}
        className={classNameInput}
      />
      <InputSelectForm
        form={form}
        keyData="status"
        dataSource={DELIVERY_STATUS_LIST}
        className={classNameInput}
        multiple
      />
      <InputSelectForm
        form={form}
        keyData="deliveryType"
        dataSource={deliveryTypes}
        className={classNameInput}
        getLabel={(item: any) => item.name}
        multiple
      />
      <InputSelectForm
        form={form}
        keyData="deliveryService"
        dataSource={SHIPPOP_TYPES_ARRAY}
        className={classNameInput}
        multiple
      />
    </div>
  );
};

export default DeliveryTrackingFilterForm;
