import { useTranslation } from "react-i18next";
import { Dropdown } from "d-react-components";
import { LANGUAGES } from "@constants/locales";
import { ADMIN_LANG_KEY } from "@constants";
import { useLocalStorage } from "react-use";
import AppContext from "@helpers/context";
import { useContext } from "react";
import { changeBodyFont } from "@helpers";

export function DropdownLanguage() {
  const { t, i18n } = useTranslation();
  const { setCurrentLang } = useContext(AppContext);
  const [lang, setLang] = useLocalStorage<string>(ADMIN_LANG_KEY, "en");
  const languageSelecting = LANGUAGES.find((item) => item.locale === lang);

  const onChangeLocale = (value: any) => {
    const newLocale = value.locale;
    i18n.changeLanguage(newLocale);
    setLang(newLocale);
    setCurrentLang(newLocale);
    changeBodyFont(newLocale);
  };

  return (
    <Dropdown
      value={languageSelecting}
      dataSource={LANGUAGES}
      onClick={onChangeLocale}
      variant="view"
      position="left-edge"
      className="dropdown-language"
    />
  );
}
