import { INVENTORY_EVENT_STATUSES } from "@constants/inventory-control-event";
import AppContext from "@helpers/context";
import { getDetailGpEvent, getGPEventChannelList } from "@network/api/gp-event";
import {
  getInventoryControlEvent,
  getListInventoryControlEvent,
} from "@network/api/inventory-control-event";
import {
  IRowsKey,
  Progress,
  TabBar,
  TimeUtils,
  ViewLabelStatus,
  ViewRowInterchange,
} from "d-react-components";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import InventoryEventDetailActivity from "./InventoryEventDetailActivity";
import InventoryEventDetailControl from "./InventoryEventDetailControl";
import InventoryEventDetailGeneral from "./InventoryEventDetailGeneral";
import InventoryEventDetailHeader from "./InventoryEventDetailHeader";
import InventoryEventDetailInventory from "./InventoryEventDetailInventory";
import InventoryEventDetailGP from "./InventoryEventDetailGP";

const TABS = [
  {
    id: "general",
    label: "general",
    component: <InventoryEventDetailGeneral />,
  },

  {
    id: "control",
    label: "controlDetail",
    component: <InventoryEventDetailControl />,
  },
  {
    id: "inventory",
    label: "inventory",
    component: <InventoryEventDetailInventory />,
  },
  {
    id: "gpEvent",
    label: "gpEvent",
    component: <InventoryEventDetailGP />,
  },
  {
    id: "activity",
    label: "activity",
    component: <InventoryEventDetailActivity />,
  },
];

const EventDetailInfo = () => {
  const { eventDetail } = useContext(AppContext);
  const { t } = useTranslation();

  const DETAILS_KEY: IRowsKey<any>[] = useMemo(
    () => [
      {
        id: "chain",
        label: t("chain"),
        renderContent: ({ data, item }) => data?.name,
      },
      {
        id: "channel",
        label: t("channel"),
        renderContent: ({ data, item }) => data?.name,
      },
      {
        id: "warehouse",
        label: t("warehouse"),
        renderContent: ({ data, item }) => data?.name,
      },
      {
        id: "status",
        label: t("status"),
        renderContent: ({ data, item }) => (
          <div className="d-flex justify-content-end">
            <ViewLabelStatus
              listStatus={INVENTORY_EVENT_STATUSES}
              status={data}
              getLabel={(item) => t(item.label)}
            />
          </div>
        ),
      },
      {
        id: "code",
        label: t("code"),
      },
      {
        id: "from",
        label: t("from"),
        renderContent: ({ data, item }) => TimeUtils.toDateTime(data),
      },
      {
        id: "to",
        label: t("to"),
        renderContent: ({ data, item }) => TimeUtils.toDateTime(data),
      },
      {
        id: "visibility",
        label: t("visibility"),
        renderContent: ({ data, item }) =>
          data ? t("enabled") : t("disabled"),
      },
    ],
    [t]
  );

  return (
    <div className="">
      <div className="flex-center flex-column mb-4">
        <img src={"/images/icons/event.svg"} className="image-reg-large" />
        <h4 className="mt-3">{eventDetail?.title}</h4>
        <small>{eventDetail?.code}</small>
      </div>

      <ViewRowInterchange
        dataSource={eventDetail}
        keyList={DETAILS_KEY as any}
        variant="border"
        classNameLabel="font-weight-bold "
        classNameContent="text-end "
      />
    </div>
  );
};

const InventoryControlEventDetail = () => {
  const [eventDetail, setEventDetail] = useState(null);

  const [tab, setTab] = useState<any>(TABS[0]);
  const { eventId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    loadEventDetail();
  }, []);

  const loadEventDetail = () => {
    Progress.show(
      { method: getInventoryControlEvent, params: [eventId] },
      (res: any) => {
        const event = res?.data?.data?.control;
        setEventDetail(event);
      }
    );
  };

  if (!eventDetail) return <div />;

  return (
    <AppContext.Provider
      value={{
        eventDetail,
        loadEventDetail,
      }}
    >
      <div className="flex-column-container bg-trans">
        <InventoryEventDetailHeader />
        <div className="p-3 grid grid-cols-12 gap-4">
          <div className="col-span-4 bg-white p-3">
            <EventDetailInfo />
          </div>
          <div className="col-span-8 px-3">
            <TabBar
              dataSource={TABS}
              onChange={setTab}
              value={tab}
              getLabel={(item: any) => t(item.label)}
            />
            {tab.component}
          </div>
        </div>
      </div>
    </AppContext.Provider>
  );
};

export default InventoryControlEventDetail;
