/* eslint-disable jsx-a11y/alt-text */
import Icon from "@common/Icon";
import Drawer from "@components/shared/Drawer";
import AppContext from "@helpers/context";
import { IProduct } from "@interfaces/product";
import {
  IRowsKey,
  Notifications,
  Progress,
  ViewRowInterchange,
} from "d-react-components";
import { useFormik } from "formik";
import { join, map } from "lodash";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ProductInputRentalConfig from "../input/ProductInputRentalConfig";
import { editProduct } from "@network/api/product";
import { useParams } from "react-router-dom";
import { ProductRentalSchema } from "../create/ProductCreate";

interface IProductDetailRentalDrawer {
  open: boolean;
  defaultValue?: any;
  onUpdated: any;
  onClose: () => void;
}

const ProductDetailRentalDrawer = ({
  open,
  defaultValue = {},
  onUpdated,
  onClose,
}: IProductDetailRentalDrawer) => {
  const { t } = useTranslation();
  const { productId } = useParams();

  const productForm = useFormik<any>({
    initialValues: {
      ...defaultValue,
      rentalRegularPrice: defaultValue?.rentalRegularPrice
        ? defaultValue?.rentalRegularPrice
        : defaultValue.regularPrice,
      rentalSalePrice: defaultValue?.rentalSalePrice
        ? defaultValue?.rentalSalePrice
        : defaultValue.salePrice,
    } as any,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: ProductRentalSchema,
    onSubmit: (values: any) => {
      onUpdateProduct(values);
    },
  });
  const formValues = productForm?.values;
  const formErrors = productForm?.errors;

  const onUpdateProduct = (values: any) => {
    const {
      rentalStatus,
      rentalRegularPrice,
      rentalSalePrice,
      rentalOptions,
      rentalFreeGifts,
    } = values;
    const body = {
      productid: productId,

      is_rent: rentalStatus,
      regular_price_rent: rentalRegularPrice,
      sale_price_rent: rentalSalePrice,
      option_of_month_rent: map(rentalOptions, (item) => item.value),
      gift_rent: map(rentalFreeGifts, (item) => ({
        quantity: item.quantity,
        product_id: item?.product?.id,
      })),
    };

    Progress.show(
      {
        method: editProduct,
        params: [body],
      },
      (res: any) => {
        Notifications.showSuccess(t("updateSuccess"));
        onUpdated();
        onClose();
      }
    );
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("configuration")}
      size="auto"
      width="80vw"
      onSave={() => {
        productForm.handleSubmit();
      }}
    >
      <div>
        <ProductInputRentalConfig productForm={productForm} />
      </div>
    </Drawer>
  );
};

const ProductDetailRental = () => {
  const { productDetail, loadProductDetail } = useContext(AppContext);
  const [openEdit, setOpenEdit] = useState(false);
  const { t } = useTranslation();

  const DETAILS_KEY = useMemo<IRowsKey<IProduct>[]>(
    () => [
      {
        id: "is_rent",
        label: t("status"),
        renderContent: ({ data, item }) => {
          return data ? t("enable") : t("disable");
        },
      },
      {
        id: "option_of_month_rent",
        label: t("quantityOfMonth"),
        renderContent: ({ data, item }) => {
          return data
            ? join(
                map(data, (item) => item + " months"),
                " , "
              )
            : "N/A";
        },
      },
    ],
    [t]
  );

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mt-3">
        <h5>{t("subscription")}</h5>
        <span onClick={() => setOpenEdit(true)}>
          <Icon
            className="w-[15px] cursor-pointer"
            src="/images/icons/edit.svg"
          />
        </span>
      </div>
      <div className="mt-3 border p-4 ">
        <ViewRowInterchange
          dataSource={productDetail}
          keyList={DETAILS_KEY as any}
          variant="border"
          classNameLabel="text-hint"
          classNameContent="text-end "
        />
        {openEdit && (
          <ProductDetailRentalDrawer
            open={openEdit}
            onClose={() => setOpenEdit(false)}
            defaultValue={productDetail}
            onUpdated={loadProductDetail}
          />
        )}
      </div>
    </div>
  );
};

export default ProductDetailRental;
