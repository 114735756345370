import SelectFilter from "@common/dropdown/SelectFilter";
import InputSelectKey from "@common/input/InputSelectKey";
import { Path } from "@components/layout/Path";
import UserAvatarName from "@components/shared/UserAvatarName";
import {
  CART_RULE_ACTIONS,
  CART_RULE_STATUS_LIST,
  CART_RULE_TYPES,
  CART_RULE_USAGE_STATUS_FILTER_LIST,
} from "@constants/cart-rule";
import AppContext from "@helpers/context";
import { IChannel } from "@interfaces/channel";
import CartRuleAPI from "@network/api/cart-rule";
import { getListChannel } from "@network/api/channel";
import RoleAPI from "@network/api/role";
import { getWarehouseList } from "@network/api/store";
import {
  AwesomeTableComponent,
  Button,
  IColumnsProps,
  StringUtils,
  TimeUtils,
  ViewLabelStatus,
  useFirstTime,
} from "d-react-components";
import { debounce, find, map, now, toUpper } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, generatePath, useNavigate } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import CartRuleUsageStatus from "../component/CartRuleUsageStatus";
import moment from "moment";

const CartRuleTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const CART_RULE_SEARCH_KEYS = [
    { id: "title", label: t("cartRuleTitle") },
    { id: "id", label: t("cartRuleId") },
  ];

  const [openFilter, setOpenFilter] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [channelList, setChannelList] = useState<IChannel[]>([]);

  const [warehouseList, setWarehouseList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const isFirstTime = useFirstTime();
  // const [filterBody, setFilterBody] = useState({});
  const [
    filterBody = {
      searchType: CART_RULE_SEARCH_KEYS[0].id,
    },
    setFilterBody,
  ] = useQueryParam("filterBody", JsonParam);

  const tableRef = useRef<any>();
  const searchRef = useRef<any>();

  useEffect(() => {
    loadWarehouse();
    loadRoleList();
    loadChannelList();
  }, []);

  useEffect(() => {
    if (isFirstTime) return;
    tableRef.current?.refresh();
  }, [JSON.stringify(filterBody)]);

  const isFiltered = useMemo(
    () => Object.keys(filterBody).length > 0,
    [filterBody]
  );

  const loadChannelList = () => {
    getListChannel().then((res) => {
      const channelList = res?.data?.data?.channel ?? [];
      setChannelList(channelList);
    });
  };

  const loadWarehouse = () => {
    getWarehouseList().then((res) => {
      const warehouseList = res?.data?.data?.warehouse;
      setWarehouseList(warehouseList);
    });
  };

  const loadRoleList = () => {
    RoleAPI.list().then((res) => {
      const roleList = res?.data?.data?.roles ?? [];
      setRoleList(roleList);
    });
  };

  const columns: IColumnsProps = [
    {
      title: t("channel"),
      dataIndex: "rule",
      render: (rule) => rule?.channel?.name,
    },
    {
      title: t("ruleName"),
      dataIndex: "title",
      width: 400,
      render: (title, item) => (
        <div>
          <div className="flex-row align-items-center">
            <Link
              to={generatePath(Path.CART_RULE_DETAIL, { cartRuleId: item.id })}
            >
              {title}
            </Link>
            <ViewLabelStatus
              className="ml-2"
              status={item.status}
              listStatus={CART_RULE_STATUS_LIST}
              getLabel={(item) => toUpper(t(item.label))}
            />
          </div>
          <div className="text-gray">{t("id") + ": " + item?.ruleid}</div>
        </div>
      ),
    },

    // {
    //   title: t("couponCode"),
    //   dataIndex: "rule",
    //   render: (rule) => {
    //     const cartRuleType = find(
    //       CART_RULE_TYPES,
    //       (item) => item.id === rule.coupon
    //     );
    //     const code =
    //       rule.coupon === "specific_coupon"
    //         ? rule?.code?.[0]?.code?.toUpperCase()
    //         : t(cartRuleType?.label ?? "");

    //     return (
    //       <div
    //         style={{
    //           color: rule.coupon === "specific_coupon" ? "#FFBD59" : "black",
    //         }}
    //       >
    //         {code}
    //       </div>
    //     );
    //   },
    // },

    {
      title: t("ruleType"),
      dataIndex: "actions",
      render: (actions, cartRule) => {
        const cartRuleAction = find(
          CART_RULE_ACTIONS,
          (item) => item.id === actions?.specific
        );

        let value = "";
        if (actions?.discount_percentage) {
          value = `${actions?.discount_percentage?.percentage ?? ""}%`;
        }
        if (actions?.discount_fix_amount) {
          value = `${StringUtils.moneyThaiFormat(
            actions?.discount_fix_amount?.amount
          )}`;
        }
        if (actions?.discount_percentage_cart) {
          value = `${actions?.discount_percentage_cart?.percentage ?? ""}%`;
        }
        if (actions?.discount_fix_amount_cart) {
          value = `${StringUtils.moneyThaiFormat(
            actions?.discount_fix_amount_cart?.amount
          )}`;
        }
        if (actions?.free_gift?.gift) {
          value = `${actions?.free_gift?.gift?.length} pcs`;
        }
        if (actions?.buy_x_get_discount_percentage) {
          value = `${StringUtils.moneyThaiFormat(
            actions?.buy_x_get_discount_percentage?.amount
          )}`;
        }
        if (actions?.buy_x_get_discount_fix_amount) {
          value = `${StringUtils.moneyThaiFormat(
            actions?.buy_x_get_discount_fix_amount?.amount
          )}`;
        }

        const cartRuleType = find(
          CART_RULE_TYPES,
          (item) => item.id === cartRule?.rule.coupon
        );

        return (
          <div>
            {t(cartRuleAction?.label ?? "")}

            <div className="text-gray text-small flex-row align-items-center">
              {value}
              {value && (
                <div className="mx-2 rounded h-[5px] w-[5px] bg-gray" />
              )}
              {t(cartRuleType?.label ?? "")}
            </div>
          </div>
        );
      },
    },
    // {
    //   title: t("ruleValue"),
    //   dataIndex: "actions",
    //   ellipsis: true,
    //   render: (actions) => {
    //     let value = "N/A";
    //     if (actions?.discount_percentage) {
    //       value = `${actions?.discount_percentage?.percentage ?? ""}%`;
    //     }
    //     if (actions?.discount_fix_amount) {
    //       value = `${StringUtils.moneyThaiFormat(
    //         actions?.discount_fix_amount?.amount
    //       )}`;
    //     }
    //     if (actions?.discount_percentage_cart) {
    //       value = `${actions?.discount_percentage_cart?.percentage ?? ""}%`;
    //     }
    //     if (actions?.discount_fix_amount_cart) {
    //       value = `${StringUtils.moneyThaiFormat(
    //         actions?.discount_fix_amount_cart?.amount
    //       )}`;
    //     }
    //     if (actions?.free_gift?.gift) {
    //       value = `${actions?.free_gift?.gift?.length} pcs`;
    //     }
    //     if (actions?.buy_x_get_discount_percentage) {
    //       value = `${StringUtils.moneyThaiFormat(
    //         actions?.buy_x_get_discount_percentage?.amount
    //       )}`;
    //     }
    //     if (actions?.buy_x_get_discount_fix_amount) {
    //       value = `${StringUtils.moneyThaiFormat(
    //         actions?.buy_x_get_discount_fix_amount?.amount
    //       )}`;
    //     }
    //     return value;
    //   },
    // },
    // {
    //   title: t("visibility"),
    //   dataIndex: "status",
    //   width: 75,
    //   ellipsis: true,
    //   render: (status) => t(status),
    // },
    {
      title: t("status"),
      dataIndex: "",
      render: (cartRule) => <CartRuleUsageStatus cartRule={cartRule} />,
    },
    {
      title: t("period"),
      dataIndex: "rule",
      render: (rule) => {
        const isShowDayLeft =
          moment().valueOf() > moment(rule?.period_from).valueOf();
        return (
          <div>
            <div>
              {TimeUtils.toDate(rule?.period_from) +
                " - " +
                TimeUtils.toDate(rule?.period_to)}
            </div>
            <div className="text-gray text-small flex-row align-items-center">
              <div>{`${t("total")} ${TimeUtils.calculateDayDifferent(
                rule?.period_from,
                rule?.period_to
              )} days`}</div>
              {isShowDayLeft && (
                <div className="mx-2 rounded h-[5px] w-[5px] bg-gray" />
              )}
              {isShowDayLeft &&
                TimeUtils.calculateDayDifferent(
                  moment().valueOf(),
                  rule?.period_to
                ) + " days left"}
            </div>
          </div>
        );
      },
    },

    {
      title: t("createdAt"),
      dataIndex: "by",
      render: (by, item) => {
        return (
          <div>
            <div>{TimeUtils.toDateTime(item.created)}</div>
            <UserAvatarName user={by} className="mt-2" size="xx-small" />
          </div>
        );
      },
    },
  ];

  const source = (paging: any) => {
    let body = {};

    if (filterBody) {
      const { textSearch, status, searchType, channel } = filterBody;
      body = {
        ...(status && status !== "all" ? { status: [status] } : {}),
        channels: map(channel, (item) => ({ id: item })),
        search_type: searchType,
        search: textSearch,
      };
    }

    return CartRuleAPI.list(body, paging);
  };

  const transformer = (res: any) => {
    return res?.data?.data?.cartrule ?? [];
  };

  const onChangeFilterBody = (values: any) => {
    setFilterBody({ ...filterBody, ...values });
  };

  const renderHeader = useMemo(() => {
    return (
      <div className="w-full border px-3">
        <div className="flex-center-y py-3 border-b justify-between">
          <div className="flex-row align-items-center">
            <div className="flex-center-y gap-3">
              <h5 className="m-0">{t("status")}</h5>
              {map(CART_RULE_USAGE_STATUS_FILTER_LIST, (status) => {
                const isSelect =
                  filterBody?.status === status?.id ||
                  (status?.id === "all" && !filterBody?.status);
                return (
                  <Button
                    variant={isSelect ? "standard" : "outline"}
                    onClick={() => {
                      onChangeFilterBody({ status: status?.id });
                    }}
                  >
                    {t(status?.label)}
                  </Button>
                );
              })}
            </div>
          </div>
          <div className="flex-center-y">
            <Button
              iconName="add"
              onClick={() => {
                navigate(Path.CART_RULE_CREATE);
              }}
            >
              {t("new")}
            </Button>
          </div>
        </div>
        <div className="d-flex flex-center-y gap-3 w-100 py-3">
          {/* <ChannelSelect
            showLabel={false}
            onChange={(v) => {
              console.log("v", v);
              onChangeFilterBody({ channel: v });
            }}
            value={filterBody?.channel}
            style={{ width: "300px" }}
            placeholder={t("channel")}
            multiple
          /> */}
          <SelectFilter
            key="selectChannel"
            value={filterBody.channel}
            getLabel={(item) => item?.name}
            onChange={(val) => {
              onChangeFilterBody({ channel: val });
            }}
            placeholder={t("channel")}
            tagTitle={t("channel")}
            valueKey="name"
            dataSource={channelList}
            showSearch
            multiple
            style={{ width: "300px" }}
            onDropdownVisibleChange={(open) => {
              if (!open) {
                // onClickFilter();
              }
            }}
            // onClear={() => onClickFilter()}
          />
          <InputSelectKey
            className="w-100"
            placeholder="Search..."
            selectProps={{ placeholder: t("select") }}
            selectKey={filterBody.searchType}
            dataSource={CART_RULE_SEARCH_KEYS}
            getLabel={(i) => t(i?.label)}
            onChangeSelectKey={(v) => {
              onChangeFilterBody({ searchType: v });
            }}
            onSearch={(search: any) => {
              onChangeFilterBody({ textSearch: search });
            }}
            showSearch={false}
            inputProps={{}}
          />
        </div>
      </div>
    );
  }, [filterBody]);

  return (
    <AppContext.Provider value={{ warehouseList, roleList }}>
      <div className="bg-white">
        {/* <HeaderTable
          label={t("allCartRules")}
          onChangeText={(event: any) => onChangeSearch(event.target.value)}
          onClickNew={() => navigate(Path.CART_RULE_CREATE)}
          onClickFilter={() => setOpenFilter(true)}
          onClickExport={() => setOpenExport(true)}
          isFiltered={isFiltered}
          className="app-layout__page-header"
        /> */}
        {renderHeader}

        <AwesomeTableComponent
          ref={(ref) => {
            tableRef.current = ref;
          }}
          columns={columns}
          source={source}
          transformer={transformer}
        />
        {/* {openFilter && (
          <CartRuleTableFilterDrawer
            open={openFilter}
            onClose={() => setOpenFilter(false)}
            onFilter={onFilter}
            defaultValue={filterBody}
          />
        )} */}
      </div>
    </AppContext.Provider>
  );
};

export default CartRuleTable;
