import {
    Button,
    ButtonProps,
    Dropdown,
    DropdownProps,
} from "d-react-components";
import React from "react";
import { useTranslation } from "react-i18next";

export const ActionMenu = (props: DropdownProps) => {
    const { t } = useTranslation();
    return (
        <Dropdown
            buttonProps={{
                content: t("action"),
                size: "small",
                color: "primary",
                variant: "standard",
                suffixIcon: "expand_more",
                iconName: undefined,
                className: "text-white button-action",
            }}
            {...props}
        />
    );
};

export const ActionButton = (props: ButtonProps) => {
    return (
        <Button
            {...props}
            className={`${props.className} text-primary button-action h-[32px]`}
            size="small"
        >
            {props.children}
        </Button>
    );
};

export default ActionMenu;
