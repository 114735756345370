import AppContext from "@helpers/context";
import CashDepositAPI from "@network/api/cash-deposit";
import { Notifications } from "d-react-components";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CashDepositDetailHeader from "./CashDepositDetailHeader";
import CashDepositDetailTransaction from "./CashDepositDetailTransaction";
import CashDepositPayment from "./CashDepositPayment";
import CashDepositActivity from "./CashDepositActivity";
import CashDepositDetailInfo from "./CashDepositDetailInfo";
import { NoPrint, Print } from "react-easy-print";
import CashDepositPrintView from "./CashDepositPrintView";
import { map } from "lodash";
import { useTranslation } from "react-i18next";
import ChannelAPI from "@network/api/channel";
import CashDepositDetailDocuments from "./CashDepositDetailDocuments";

const CashDepositDetail = () => {
  const { cashDepositId } = useParams();
  const [cashDepositDetail, setCashDepositDetail] = useState({} as any);
  const [bankList, setBankList] = useState([]);
  const { t } = useTranslation();

  const loadCashDepositDetail = () => {
    if (!cashDepositId) {
      return;
    }

    CashDepositAPI.detail({ id: cashDepositId }).then(
      (res) => {
        setCashDepositDetail(res?.data?.data?.deposit);
      },
      (err) => {
        Notifications.showError(err);
      }
    );
  };

  useEffect(() => {
    loadCashDepositDetail();
  }, []);

  useEffect(() => {
    loadBankList(cashDepositDetail?.channel?.id);
  }, [cashDepositDetail?.channel?.id]);

  const loadBankList = (channelId: string) => {
    if (!channelId) {
      return;
    }

    const body = {
      channel: channelId,
      store_id: cashDepositDetail?.store?.id,
      bank_type: "bank-transfer",
    };
    ChannelAPI.bankList(body).then((res) => {
      setBankList(res?.data?.data?.bank ?? []);
    });
  };

  const onUpdateCashDeposit = (editedPayment: any) => {
    const {
      channel,
      store,
      transaction_closing_date,
      expected_deposit_date,
      remark,
      payments = [],
      bank_fee,
    } = cashDepositDetail;
    const oldPayments = map(payments, (item) => item?.id);
    const addPayments =
      map(editedPayment?.transactionSelected, (item) => item?.id) ?? [];
    const result = [...oldPayments, ...addPayments];
    const body = {
      id: cashDepositDetail?.id,
      channel: channel?.id,
      storeid: store?.id,
      transaction_closing_date,
      expected_deposit_date,
      remark,
      payments: result,
      bank_fee,
    };
    CashDepositAPI.update(body)
      .then((res) => {
        Notifications.showSuccess(t("editDepositInfoSuccess"));
        loadCashDepositDetail();
      })
      .catch((error) => console.error({ error }));
  };

  if (!cashDepositDetail) {
    return <div />;
  }

  return (
    <AppContext.Provider
      value={{
        bankList,
        cashDepositDetail,
        loadCashDepositDetail,
        onUpdateCashDeposit,
      }}
    >
      <Print name="Print cash deposit" printOnly>
        <CashDepositPrintView />
      </Print>
      <NoPrint>
        <CashDepositDetailHeader />

        <div className="grid grid-cols-3 gap-4 mt-3 px-3 pb-3 pt-0">
          <div className="col-span-3 md:col-span-2">
            <CashDepositDetailTransaction />
            {/* <CashDepositDetailDocuments /> */}
            <CashDepositPayment />
            <CashDepositActivity />
          </div>
          <div className="col-span-3 md:col-span-1">
            <CashDepositDetailInfo />
          </div>
        </div>
      </NoPrint>
    </AppContext.Provider>
  );
};

export default CashDepositDetail;
