import Drawer from "@components/shared/Drawer";
import WarehouseAPI from "@network/api/warehouse";
import { AwesomeTableComponent, InputTextSearch } from "d-react-components";
import { debounce, map } from "lodash";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

function WarehouseDetailChannelUpdateDrawer({
  open,
  onClose,
  title,
  warehouseId,
  type,
  onSave,
}: any) {
  const { t } = useTranslation();

  const tableRef = useRef<any>();
  const textSearch = useRef("");
  const [channelSelect, setChannelSelect] = useState<any>([]);

  const columns = [
    {
      title: t("name"),
      dataIndex: "name",
      render: (name: string, channel: any) => (
        <a href={channel?.editurl} target="_blank" rel="noreferrer">
          {name}
        </a>
      ),
    },
    {
      title: t("code"),
      dataIndex: "code",
    },
  ];

  const source = (paging: any) => {
    const body = { action: type, id: warehouseId, search: textSearch.current };
    return WarehouseAPI.channelList(body, paging);
  };

  const transformer = (res: any) => {
    return res?.data?.data?.channel ?? [];
  };

  const onChangeTextSearch = debounce((text) => {
    textSearch.current = text;
    tableRef.current.refresh();
  }, 300);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={title}
      onSave={() => onSave(channelSelect)}
      size="auto"
      width="50vw"
    >
      <InputTextSearch
        className="mt-3"
        placeholder={t("search")}
        onChange={(e: any) => onChangeTextSearch(e.target.value)}
      />
      <AwesomeTableComponent
        ref={(ref) => {
          tableRef.current = ref;
        }}
        columns={columns}
        source={source}
        transformer={transformer}
        className="mt-3"
        rowSelection={{
          selectedRowKeys: map(channelSelect, (item) => item.id),
          onChange: (selectedRowKeys, selectRows) => {
            setChannelSelect(selectRows);
          },
          preserveSelectedRowKeys: true,
        }}
      />
    </Drawer>
  );
}

export default WarehouseDetailChannelUpdateDrawer;
