import InputVerifier from "@common/input/InputVerifier";
import AppContext from "@helpers/context";
import { CustomerType, ICustomer } from "@interfaces/customer";
import {
  Avatar,
  IRowsKey,
  TimeUtils,
  ViewRowInterchange,
} from "d-react-components";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

export const CustomerInfoRow = () => {
  const { t } = useTranslation();
  const { customerDetail, setCustomerDetail } = useContext(AppContext);
  const DETAILS_KEY = useMemo<IRowsKey<ICustomer>[]>(
    () => [
      {
        id: "customerid",
        label: t("customerNo"),
      },
      {
        id: "customer_type",
        label: t("customerType"),
        renderContent: ({ data }) => t(data),
      },
      ...((customerDetail?.customer_type === CustomerType.INDIVIDUAL
        ? [
            {
              id: "firstname",
              label: t("firstName"),
            },
            {
              id: "lastname",
              label: t("lastName"),
            },
            {
              id: "nickname",
              label: t("nickName"),
            },
            {
              id: "citizen_id",
              label: t("citizenId"),
            },
            {
              id: "gender",
              label: t("gender"),
            },
            {
              id: "birthday",
              label: t("birthday"),
              renderContent: ({ data, item }) =>
                data ? TimeUtils.toDate(data) : "N/A",
            },
            {
              id: "email",
              label: t("email"),
              renderContent: ({ data, item: customer }) => (
                <div className="flex items-center">
                  <span className="mr-2">{data}</span>
                  <InputVerifier
                    type="email"
                    isVerified={customer?.is_verify_email}
                    value={customer?.email}
                    payload={{ channel_id: customer?.channel?.id }}
                    userId={customer?.id}
                    onVerified={(otp) => {
                      setCustomerDetail({
                        ...customerDetail,
                        is_verify_email: true,
                      });
                    }}
                  />
                </div>
              ),
            },
            {
              id: "phone",
              label: t("phone"),
              renderContent: ({ data, item: customer }) => (
                <div className="flex items-center">
                  <span className="mr-2">{data}</span>
                  <InputVerifier
                    type="phone"
                    isVerified={customer?.is_verify_phone}
                    value={customer?.phone}
                    payload={{ channel_id: customer?.channel?.id }}
                    userId={customer?.id}
                    onVerified={(otp) => {
                      setCustomerDetail({
                        ...customerDetail,
                        is_verify_phone: true,
                      });
                    }}
                  />
                </div>
              ),
            },
            {
              id: "alternative_phone",
              label: t("alternativePhone"),
            },
          ]
        : [
            {
              id: "company",
              label: t("companyName"),
            },
            {
              id: "tax_id",
              label: t("companyTax"),
            },
            {
              id: "email",
              label: t("companyEmail"),
              renderContent: ({ data, item: customer }) => (
                <div className="flex items-center">
                  <span className="mr-2">{data}</span>
                  <InputVerifier
                    type="email"
                    isVerified={customer?.is_verify_email}
                    value={customer?.email}
                    payload={{ channel_id: customer?.channel?.id }}
                    userId={customer?.id}
                    onVerified={(otp) => {
                      setCustomerDetail({
                        ...customerDetail,
                        is_verify_email: true,
                      });
                    }}
                  />
                </div>
              ),
            },
            {
              id: "phone",
              label: t("companyPhone"),
            },
            {
              id: "representative_first_name",
              label: t("representativeFirstname"),
            },
            {
              id: "representative_last_name",
              label: t("representativeLastname"),
            },
            {
              id: "representative_phone",
              label: t("representativePhone"),
            },
            {
              id: "representative_email",
              label: t("representativeEmail"),
            },
          ]) as IRowsKey<ICustomer>[]),

      {
        id: "created",
        label: t("createdAt"),
        renderContent: ({ data, item }) => TimeUtils.toDateTime(data),
      },
    ],
    [t, customerDetail]
  );
  return (
    <ViewRowInterchange
      className="w-100"
      dataSource={customerDetail}
      keyList={DETAILS_KEY as any}
      variant="border"
      classNameLabel="font-weight-bold "
      classNameContent="text-end flex justify-end"
    />
  );
};

const CustomerDetailInfo = () => {
  const { customerDetail } = useContext(AppContext);
  const { firstname, lastname, customerid, avatar } = customerDetail ?? {};

  return (
    <div className="">
      <div className="border p-4 p-4 flex-column flex-center ">
        <Avatar src={avatar ?? "/images/img-default.png"} />
        <h4 className="mt-3 text-center">{firstname + " " + lastname}</h4>
      </div>
      <div className="border p-4">
        <CustomerInfoRow />
      </div>
    </div>
  );
};

export default CustomerDetailInfo;
