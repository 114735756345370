import ButtonFileUpload from "@components/shared/ButtonFileUpload";
import Drawer from "@components/shared/Drawer";
import UploadFile from "@components/shared/UploadFile";
import { IMAGE_FORMAT } from "@constants/file";
import AppContext from "@helpers/context";
import { uploadRentalInvoiceProofPayment } from "@network/api/rental";
import {
  InputText,
  Notifications,
  Progress,
  Select,
  ViewTextError,
} from "d-react-components";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import {
  forwardRef,
  useContext,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

const UploadPaymentInfoRef = (
  {
    onSuccessUploadInfo,
    isView = false,
    defaultInfo = {},
    paymentId,
    paymentMethod,
  }: any,
  ref: any
) => {
  const { t } = useTranslation();
  const buttonUploadRef = useRef<any>(null);

  const UploadPaymentSchema = Yup.object().shape({
    remark: Yup.string().required(t("fieldRequired")),
    attachment: Yup.array()
      .required(t("fieldRequired"))
      .min(1, t("fieldRequired")),
  });

  const formik = useFormik({
    initialValues: {
      remark: "",
      attachment: [],
      ...defaultInfo,
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: UploadPaymentSchema,

    onSubmit: async (values) => {
      return onUploadProof();
    },
  });

  useImperativeHandle(ref, () => ({
    formik,
  }));

  const onUploadProof = async () => {
    const { remark, attachment } = formik.values;
    const payload = {
      id: paymentId,
      method: paymentMethod,
      attachment: attachment?.map((a: any) => a.key),
      remark,
    };
    const APIList: any[] = [
      { method: uploadRentalInvoiceProofPayment, params: payload },
    ];
    Progress.show(APIList, (res) => {
      Notifications.showSuccess(t("notification:uploadFileSuccess"));
      onSuccessUploadInfo();
    });

    // attachment.forEach((fileItem: any) => {
    //   const data = new FormData();
    //   data.append("method", paymentMethod);
    //   data.append("method", paymentMethod);
    //   data.append("remark", remark);
    //   APIList.push({ method: uploadRentalInvoiceProofPayment, params: data });
    // });
    // Progress.show(APIList, (res) => {
    //   Notifications.showSuccess(t("notification:uploadFileSuccess"));
    //   onSuccessUploadInfo();
    // });
  };

  return (
    <div>
      {!isView && (
        <div className="mt-2">
          <ButtonFileUpload
            onChange={(file: any) => formik.setFieldValue("attachment", file)}
            containerClassName="d-flex flex-column"
            ref={buttonUploadRef}
            inputParam={{ accept: IMAGE_FORMAT }}
            classNameItem="!w-24 !h-24"
          />
          <ViewTextError error={formik.errors.attachment} />
        </div>
      )}

      <InputText
        className="mt-3"
        label={t("remark")}
        multiple
        placeholder={t("pleaseInputTheNote")}
        name="remark"
        value={formik.values.remark}
        onChange={formik.handleChange}
        error={formik.errors.remark as string}
        hidden={isView && !formik.values.remark}
      />
    </div>
  );
};
export const UploadPaymentInfo = forwardRef(UploadPaymentInfoRef);

export default function UploadProofPaymentModal({
  open,
  onClose,
  payment,
  onUploaded,
}: any) {
  const { method, id } = payment;
  const paymentMethodDefault = method === "default" ? null : method;
  const { onReloadOrderData, banks, banks2C2P, paymentMethodsList } =
    useContext(AppContext);
  const uploadBankInfoRef = useRef<any>();
  const [paymentMethod, setPaymentMethod] = useState(paymentMethodDefault);
  const { t } = useTranslation();

  const renderInputContent = () => {
    if (isEmpty(paymentMethod)) {
      return <div />;
    }
    return (
      <UploadPaymentInfo
        ref={uploadBankInfoRef}
        banks={banks}
        merchantList={banks2C2P}
        onSuccessUploadInfo={onSuccessUploadInfo}
        paymentId={id}
        paymentMethod={paymentMethod}
        defaultInfo={{}}
      />
    );
  };

  const onClickSave = async () => {
    if (!paymentMethod) {
      return Promise.reject();
    }
    return uploadBankInfoRef.current.formik.handleSubmit();
  };

  const onSuccessUploadInfo = () => {
    onClose();
    onReloadOrderData && onReloadOrderData();
    onUploaded && onUploaded();
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      onSave={onClickSave}
      saveText={t("submit")}
      title={t("paymentInformation")}
      size="auto"
      width="500"
    >
      <Select
        disabled={!isEmpty(paymentMethodDefault)}
        label={`${t("paymentMethod")} *`}
        value={paymentMethod}
        onChange={setPaymentMethod}
        dataSource={paymentMethodsList}
      />
      {renderInputContent()}
    </Drawer>
  );
}
