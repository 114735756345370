import Drawer from "@components/shared/Drawer";
import UploadFile from "@components/shared/UploadFile";
import {
  NUMBER_INSTALMENT,
  PAYMENT_2C2P,
  PAYMENT_BANK_TRANSFER,
  PAYMENT_CREDIT_CARD_EDC,
  PAYMENT_MODERN_TRADE,
  PAYMENT_QR_PROMPT_PAY,
} from "@constants/payment";
import { getBankFullInfo } from "@helpers/bank";
import AppContext from "@helpers/context";
import { getFullName } from "@helpers/string";
import { uploadOrderSplitPayment } from "@network/api/order";
import { modernTradeBankList } from "@network/api/store";
import {
  DateInput,
  InputText,
  Notifications,
  Progress,
  Select,
  ViewTextError,
} from "d-react-components";
import { useFormik } from "formik";
import { filter, isEmpty } from "lodash";
import moment from "moment";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

const UploadPaymentInfoRef = (
  {
    banks = [],
    paymentId,
    onSuccessUploadInfo,
    isView = false,
    defaultInfo = {},
    paymentMethod,
    merchantList,
  }: any,
  ref: any
) => {
  const { t } = useTranslation();

  const bankOptions = useMemo(
    () => filter(banks, (item) => item.bank_type === paymentMethod),
    [banks]
  );

  const UploadPaymentSchema = Yup.object().shape({
    fullNameTransfer: Yup.string().required(t("fieldRequired")),
    dateTransfer: Yup.number().required(t("fieldRequired")),
    comment: Yup.string().required(t("fieldRequired")),
    attachments: Yup.array()
      .required(t("fieldRequired"))
      .min(1, t("fieldRequired")),
  });

  const UploadBankSchema = useMemo(
    () =>
      Yup.lazy(() => {
        return UploadPaymentSchema.concat(
          Yup.object().shape({
            bankTransfer: Yup.string()
              .nullable()
              .when("paymentMethod", {
                is: (val: string) =>
                  bankOptions?.length > 0 &&
                  [PAYMENT_QR_PROMPT_PAY, PAYMENT_CREDIT_CARD_EDC].includes(
                    val
                  ),
                then: Yup.string().required(t("fieldRequired")),
              }),
            cardLast4Digit: Yup.string()
              .nullable()
              .when("paymentMethod", {
                is: PAYMENT_CREDIT_CARD_EDC,
                then: Yup.string()
                  .required(t("fieldRequired"))
                  .test("len", t("mustbe4digit"), (val) =>
                    /^\d{4}$/.test(val as string)
                  ),
              }),
          })
        );
      }),
    [bankOptions]
  );

  const Upload2C2PSchema = Yup.lazy(() => {
    return UploadPaymentSchema.concat(
      Yup.object().shape({
        merchant: Yup.string().required(t("fieldRequired")),
        transactionId: Yup.string().required(t("fieldRequired")),
        paymentOption: Yup.mixed().required(t("fieldRequired")),
      })
    );
  });

  const { thaiQrPayment, paymentOptions, loadPaymentOptions } =
    useContext(AppContext);
  const isRequireBankField = bankOptions.length > 0;
  const [mtBankList, setMTBankList] = useState([]);

  useEffect(() => {
    if (
      ![PAYMENT_MODERN_TRADE, PAYMENT_QR_PROMPT_PAY].includes(paymentMethod)
    ) {
      return;
    }
    modernTradeBankList().then((res) => {
      const bankList = res?.data?.data?.brand_name ?? [];
      setMTBankList(bankList);
    });
  }, [paymentMethod]);

  useEffect(() => {
    if (!paymentOptions?.length) {
      loadPaymentOptions && loadPaymentOptions();
    }
  }, []);

  const validate = (values: any) => {
    const errors: any = {};

    if (paymentMethod === "bank-transfer" && !values.bankTransfer) {
      Notifications.showError("Require bank transfer");
      errors.bankTransfer = "Require bank transfer";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      paymentMethod,
      fullNameTransfer: "",
      bankTransfer: undefined,
      dateTransfer: undefined,
      comment: "",
      ...defaultInfo,
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema:
      paymentMethod === "2c2p" ? Upload2C2PSchema : UploadBankSchema,
    validate,

    onSubmit: async (values) => {
      return onUploadBankInfo();
    },
  });

  useImperativeHandle(ref, () => ({
    formik,
  }));

  const onUploadBankInfo = async () => {
    const {
      fullNameTransfer,
      bankTransfer,
      dateTransfer,
      comment,
      numberInstalment,
      merchant,
      transactionId,
      attachments,
      bankName,
      paymentOption,
      cardLast4Digit,
    } = formik.values;

    const APIList: any[] = [];

    attachments.forEach((fileItem: any) => {
      const data = new FormData();
      data.append("payment_method", paymentMethod);
      data.append("splitid", paymentId);
      data.append("file", fileItem.fileData);
      data.append("transfer_date", dateTransfer);
      data.append("transfer_name", fullNameTransfer);
      bankName && data.append("bank_name_id", bankName);
      cardLast4Digit && data.append("credit_card_number", cardLast4Digit);
      numberInstalment &&
        data.append("installment_loan_tenure", numberInstalment);
      merchant && data.append("bank_2c2p_id", merchant);
      transactionId && data.append("payment_transaction_id", transactionId);
      isRequireBankField && data.append("transfer_bank", bankTransfer);
      paymentMethod === "thai-qr-payment" &&
        data.append("transfer_bank", thaiQrPayment?.id);
      data.append("comment", comment);
      data.append("payment_option", paymentOption?.id);
      APIList.push({ method: uploadOrderSplitPayment, params: data });
    });
    Progress.show(APIList, (res) => {
      Notifications.showSuccess(t("notification:uploadFileSuccess"));
      onSuccessUploadInfo();
    });
  };

  return (
    <div>
      <Select
        disabled={isView || paymentMethod !== "default"}
        hidden={paymentMethod !== PAYMENT_2C2P}
        className="mt-3"
        label={t("paymentOptions")}
        dataSource={paymentOptions}
        value={formik.values.paymentOption}
        error={formik.errors.paymentOption}
        onChange={(value) => formik.setFieldValue("paymentOption", value)}
        placeholder={t("pleaseSelect")}
      />
      <Select
        disabled={!!defaultInfo.numberInstalment || isView}
        hidden={paymentMethod !== PAYMENT_MODERN_TRADE}
        className="mt-3"
        label={t("numberOfInstalment")}
        name="numberInstalment"
        dataSource={NUMBER_INSTALMENT}
        value={formik.values.numberInstalment}
        error={formik.errors.numberInstalment}
        onChange={(value) => formik.setFieldValue("numberInstalment", value)}
        placeholder={t("pleaseSelect")}
      />
      <Select
        disabled={!!defaultInfo.bankName || isView}
        hidden={![PAYMENT_MODERN_TRADE].includes(paymentMethod)}
        className="mt-3"
        label={t("bankName")}
        name="bankName"
        dataSource={mtBankList}
        value={formik.values.bankName}
        error={formik.errors.bankName}
        onChange={(value) => formik.setFieldValue("bankName", value)}
        getLabel={(item) => (
          <div className="flex items-center">
            {item.logo && (
              <img src={item.logo} className="w-4 h-4 mr-2" alt="" />
            )}
            {item.code}
          </div>
        )}
        placeholder={t("pleaseSelect")}
      />
      <InputText
        disabled={isView}
        className="mt-3"
        label={t("nameOfPayee")}
        name="fullNameTransfer"
        value={formik.values.fullNameTransfer}
        onChange={formik.handleChange}
        error={formik.errors.fullNameTransfer as string}
      />

      <Select
        hidden={
          ![
            PAYMENT_BANK_TRANSFER,
            PAYMENT_QR_PROMPT_PAY,
            PAYMENT_CREDIT_CARD_EDC,
          ].includes(paymentMethod) || !isRequireBankField
        }
        disabled={isView}
        className="mt-3"
        label={`${t("bankOfTransfer")} *`}
        name="bankTransfer"
        value={formik.values.bankTransfer}
        onChange={(value) => formik.setFieldValue("bankTransfer", value)}
        error={formik.errors.bankTransfer}
        dataSource={bankOptions}
        getLabel={(item) => getBankFullInfo(item)}
        placeholder={
          [PAYMENT_QR_PROMPT_PAY, PAYMENT_CREDIT_CARD_EDC].includes(
            paymentMethod
          )
            ? t("selectBankEDC")
            : t("pleaseSelect")
        }
      />

      <Select
        hidden={paymentMethod !== "2c2p"}
        disabled={isView}
        className="mt-3"
        label={`${t("merchantName")} *`}
        name="merchant"
        value={formik.values.merchant}
        onChange={(value) => formik.setFieldValue("merchant", value)}
        error={formik.errors.merchant}
        dataSource={merchantList}
        getLabel={(pro) => pro.name}
        placeholder={t("pleaseSelect")}
      />

      <InputText
        hidden={paymentMethod !== "2c2p"}
        disabled={isView}
        className="mt-3"
        label={t("C2PTransactionId")}
        name="transactionId"
        value={formik.values.transactionId}
        onChange={formik.handleChange}
        error={formik.errors.transactionId as string}
      />

      <DateInput
        disabled={isView}
        className="mt-3"
        label={t("dateOfPayment")}
        name="dateTransfer"
        value={
          formik.values.dateTransfer ? moment(formik.values.dateTransfer) : null
        }
        onChange={(date) => {
          formik.setFieldValue("dateTransfer", moment(date).valueOf());
        }}
        error={formik.errors.dateTransfer as string}
        format="DD/MM/YYYY HH:mm"
        showTime
      />

      <InputText
        hidden={paymentMethod !== PAYMENT_CREDIT_CARD_EDC}
        disabled={isView}
        className="mt-3"
        label={t("cardLast4Digit")}
        name="cardLast4Digit"
        value={formik.values.cardLast4Digit}
        onChange={formik.handleChange}
        error={formik.errors.cardLast4Digit as string}
      />

      {!isView && (
        <div className="mt-2">
          <UploadFile
            getFile={(file: any) => formik.setFieldValue("attachments", file)}
            uploadedFiles={formik?.values?.attachments}
            uploadImagesOnly
          />
          <ViewTextError error={formik.errors.attachments} />
        </div>
      )}

      <InputText
        className="mt-3"
        label={t("remark")}
        multiple
        placeholder={t("pleaseInputTheNote")}
        name="comment"
        value={formik.values.comment}
        onChange={formik.handleChange}
        error={formik.errors.comment as string}
        hidden={isView && !formik.values.comment}
      />
    </div>
  );
};
export const UploadPaymentInfo = forwardRef(UploadPaymentInfoRef);

export default function ModalUploadPaymentInfo({
  open,
  onClose,
  payment,
}: any) {
  const { method, installment_loan_tenure, id, bank_name, payment_option } =
    payment;
  const paymentMethodDefault = method === "default" ? null : method;
  const { onReloadOrderData, banks, banks2C2P, paymentMethodsList, customer } =
    useContext(AppContext);
  const uploadBankInfoRef = useRef<any>();
  const [paymentMethod, setPaymentMethod] = useState(paymentMethodDefault);
  const { t } = useTranslation();

  const renderInputContent = () => {
    if (isEmpty(paymentMethod)) {
      return <div />;
    }
    return (
      <UploadPaymentInfo
        ref={uploadBankInfoRef}
        banks={banks}
        merchantList={banks2C2P}
        onSuccessUploadInfo={onSuccessUploadInfo}
        paymentId={id}
        paymentMethod={paymentMethod}
        defaultInfo={{
          numberInstalment: installment_loan_tenure,
          bankName: bank_name?.id,
          paymentOption: payment_option,
          fullNameTransfer: getFullName(customer),
        }}
      />
    );
  };

  const onClickSave = async () => {
    if (!paymentMethod) {
      return Promise.reject();
    }
    return uploadBankInfoRef.current.formik.handleSubmit();
  };

  const onSuccessUploadInfo = () => {
    onClose();
    onReloadOrderData && onReloadOrderData();
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      onSave={onClickSave}
      saveText={t("submit")}
      title={t("paymentInformation")}
      size="auto"
      width={500}
    >
      <Select
        disabled={!isEmpty(paymentMethodDefault)}
        label={`${t("paymentMethod")} *`}
        value={paymentMethod}
        onChange={setPaymentMethod}
        dataSource={paymentMethodsList}
      />
      {renderInputContent()}
    </Drawer>
  );
}
