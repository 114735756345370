import AppContext from "@helpers/context";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import ChannelFormSetting from "../components/ChannelFormSetting";

const ChannelCreateSetting = () => {
  const { channelForm } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <div className="card-container p-4">
      <ChannelFormSetting form={channelForm} />
    </div>
  );
};

export default ChannelCreateSetting;
