import ImageIcon from "@common/Icon";
import Drawer from "@components/shared/Drawer";
import AppContext from "@helpers/context";
import { mapObjectServerToClient } from "@interfaces/cart-rule";
import { useFormik } from "formik";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import CartRuleConditionForm from "../component/CartRuleConditionForm";
import ConditionApplyType from "../component/condition/ConditionApplyType";
import ConditionList from "../component/condition/ConditionList";
import { useParams } from "react-router-dom";
import { Notifications, Progress } from "d-react-components";
import CartRuleAPI from "@network/api/cart-rule";
import { transferCondition } from "../create/CartRuleCreate";

interface ICartRuleDetailConditionUpdateDrawer {
  open: boolean;
  defaultValue: any;
  onUpdateSuccess: any;
  onClose: () => void;
}

const CartRuleDetailConditionUpdateDrawer = ({
  open,
  defaultValue,
  onUpdateSuccess,
  onClose,
}: ICartRuleDetailConditionUpdateDrawer) => {
  const { t } = useTranslation();
  const { cartRule, loadCartRuleDetail } = useContext(AppContext);
  const { cartRuleId } = useParams();

  const cartRuleForm = useFormik<any>({
    initialValues: defaultValue as any,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: schema,
    onSubmit: (values: any) => {
      const cloneConditions = transferCondition(values?.conditions);
      const input = {
        id: cartRuleId,
        conditions: cloneConditions,
      };
      onUpdate(input);
    },
  });
  const formValues = cartRuleForm?.values;
  const formErrors = cartRuleForm?.errors;

  const onUpdate = (input: any) => {
    Progress.show(
      { method: CartRuleAPI.update, params: [input] },
      (res: any) => {
        onClose();
        loadCartRuleDetail();
        Notifications.showSuccess(t("updateCartRuleSuccess"));
      }
    );
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("general")}
      onSave={() => cartRuleForm.handleSubmit()}
      size="auto"
      width="60vw"
    >
      <CartRuleConditionForm form={cartRuleForm} />
    </Drawer>
  );
};

const CartRuleDetailCondition = () => {
  const { cartRule, loadCartRuleDetail } = useContext(AppContext);
  const [openUpdate, setOpenUpdate] = useState(false);
  const { t } = useTranslation();

  const { id } = cartRule;

  return (
    <div className="border mt-3 p-4">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h5>{t("cartRuleCondition")}</h5>
        <span onClick={() => setOpenUpdate(true)}>
          <ImageIcon
            className="w-[15px] cursor-pointer"
            src="/images/icons/edit.svg"
          />
        </span>
      </div>
      <ConditionApplyType value={cartRule?.rule?.conditions} disabled />
      <ConditionList
        conditions={cartRule?.conditions}
        disabled
        // setChangeCondition={(value: any) =>
        //   cartRuleForm.setFieldValue("conditions", value)
        // }
        // addConditionHandle={addConditionHandle}
      />
      {openUpdate && (
        <CartRuleDetailConditionUpdateDrawer
          open={openUpdate}
          onClose={() => setOpenUpdate(false)}
          defaultValue={mapObjectServerToClient(cartRule)}
          onUpdateSuccess={loadCartRuleDetail}
        />
      )}
    </div>
  );
};

export default CartRuleDetailCondition;
