import Drawer from "@components/shared/Drawer";
import UserAPI from "@network/api/user";
import { AwesomeTableComponent, InputTextSearch, Modal, ObjectUtils } from "d-react-components";
import { debounce, map } from "lodash";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

function UserDetailRoleUpdateModal({ open, onClose, title, type, userId, onSave }: any) {
  const tableRef = useRef<any>();
  const { t } = useTranslation();
  const textSearch = useRef();
  const [roleSelecting, setRoleSelecting] = useState<any>([]);

  const columns = [
    {
      title: t("name"),
      dataIndex: "name",
    },
    {
      title: t("code"),
      dataIndex: "code",
    },
  ];

  const onChangeText = debounce((text) => {
    textSearch.current = text;
    tableRef.current.refresh();
  }, 500);

  const source = (paging: any) => {
    const body = { id: userId, action: type, search: textSearch.current };
    return UserAPI.roleList(body, paging);
  };

  const transformer = (res: any) => {
    return res?.data?.data?.roles ?? [];
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={title}
      onSave={() => onSave(roleSelecting)}
      size="auto"
      width="600px"
    >
      <div>
        <InputTextSearch
          onChange={(event: any) => onChangeText(event.target.value)}
        />
        <AwesomeTableComponent
          ref={(ref) => {
            tableRef.current = ref;
          }}
          columns={columns}
          source={source}
          transformer={transformer}
          className="mt-3"
          rowSelection={{
            onChange: (selectedRowKeys, selectedRows) => {
              setRoleSelecting(selectedRows);
            },
            preserveSelectedRowKeys: true,
          }}
        />
      </div>
    </Drawer>
  );
}

export default UserDetailRoleUpdateModal;
