import { ORDER_PICKUP_STATUS, ORDER_STATUS } from "@constants/order";
import AppContext from "@helpers/context";
import { createPickup, editPickup } from "@network/api/order";
import { Badge, Button, Notifications, Progress } from "d-react-components";
import _ from "lodash";
import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ImageIcon from "@common/Icon";
import PickupDrawer from "./PickupDrawer";
import PickupItem from "./PickupItem";

const OrderPickup = () => {
  const {
    pickup = [],
    products,
    delivery,
    store,
    customer,
    order,
    onReloadOrderData,
    onClickPickUpPrint,
    channel,
    manualProductsNotMap,
    editable,
    isPublic,
  } = useContext(AppContext);
  const { t } = useTranslation();
  const [showAllPickup, setShowAllPickup] = useState(false);

  const [modalPickup, setModalPickup] = useState<any>({
    open: false,
    pickup: null,
  });

  const isPendingOrder = useMemo(() => {
    return (
      order?.status === ORDER_STATUS.pending.value ||
      order?.status === ORDER_STATUS.pendingPaymentConfirmation.value ||
      order?.status === ORDER_STATUS.partiallyPaid.value
    );
  }, [order]);

  const isShowCreateEditPickup = useMemo(() => {
    if (isPublic) return false;
    if (manualProductsNotMap.length > 0) return false;
    return editable && isPendingOrder;
  }, [order]);

  const pickupList = useMemo(() => {
    return pickup?.map((pickupItem: any) => {
      const productResult = pickupItem.pickup?.map((pro: any) => {
        let proResult = {};
        // check is not prouduct in group
        if (_.isEmpty(pro.order_product_group_id)) {
          proResult = products.find(
            (item: any) => item.id === pro.order_product_id
          );
        } else {
          products.forEach((productItem: any) => {
            const { groups = [] } = productItem;
            proResult = groups.find(
              (item: any) =>
                item.id === pro.order_product_group_id &&
                pro.order_product_id === productItem.id
            );
          });
        }

        return {
          ...pro,
          ...proResult,
        };
      });
      return {
        ...pickupItem,
        personPhone: pickupItem.person_phone,
        personName: pickupItem.person_name,
        pickupTime: pickupItem.pickup_time,
        products: productResult,
      };
    });
  }, [pickup]);

  const getBodyAPI = (pickupData: any) => {
    const { store, products, personName, personPhone, pickupTime, notes } =
      pickupData;

    const pickup = products
      .filter((item: any) => !(item?.groups?.length > 0))
      ?.map((pro: any) => {
        return {
          order_product_id: pro?.id,
          stock: pro?.stock ?? 0,
        };
      });

    products
      .filter((item: any) => item?.groups?.length > 0)
      .forEach((pro: any) => {
        const { groups } = pro;
        groups.forEach((childPro: any) => {
          pickup.push({
            order_product_id: pro?.id,
            order_product_group_id: childPro?.id,
            stock: childPro?.stock ?? 0,
          });
        });
      });
    const body = {
      orderid: order?.id,
      storeid: store?.id,
      channelid: channel?.id,
      pickup_time: pickupTime?.valueOf(),
      person_name: personName,
      person_phone: personPhone,
      pickup,
      notes,
    };

    return body;
  };

  const onSaveOrderPickup = (pickupData: any) => {
    const bodyPickUp = getBodyAPI(pickupData);
    const checkStock = _.every(
      bodyPickUp?.pickup ?? [],
      (item) => item?.stock === 0
    );
    if (checkStock) {
      return Notifications.showError(
        t("notification:pleaseInputQuantityOfItemToPickUP")
      );
    }
    if (!bodyPickUp.storeid) {
      return Notifications.showError(t("notification:pleaseSelectStore"));
    }
    if (modalPickup.pickup) {
      const body = {
        ...bodyPickUp,
        pickupid: pickupData?.id,
      };
      Progress.show(
        { method: editPickup, params: [body] },
        () => {
          Notifications.showSuccess(t("notification:editPickupSuccess"));
          onReloadOrderData && onReloadOrderData();
          setModalPickup({ open: false, pickup: null });
        },
        (err: any) => {
          Notifications.showError(err?.respone?.data?.message);
        }
      );
    } else {
      Progress.show(
        { method: createPickup, params: [bodyPickUp] },
        () => {
          Notifications.showSuccess(t("notification:addPickupSuccess"));
          onReloadOrderData && onReloadOrderData();
          setModalPickup({ open: false, pickup: null });
        },
        (err: any) => {
          Notifications.showError(err?.respone?.data?.message);
        }
      );
    }
  };

  const pickupItemToDisplay = useMemo(() => {
    if (showAllPickup) {
      return pickupList;
    }
    if (!pickupList?.length) {
      return [];
    }
    return [pickupList?.[0] ?? {}];
  }, [showAllPickup, pickupList]);

  return (
    <div className="bg-white p-3 pt-4 mb-3 shadow-sm">
      <div className="headerContainer relative">
        <label className="block text-sm mb-3">{t("pickup")}</label>
        {isShowCreateEditPickup && (
          <span
            className="text-primary cursor-pointer top-0 right-0 absolute"
            onClick={() => {
              if (!order?.has_allowed_pickup) {
                Notifications.showError(t("notification:notAllowAddPickup"));
                return;
              }
              if (!order?.has_bulk_pickup && pickup.length > 0) {
                Notifications.showError(t("notification:notAllowAddMulPickup"));
                return;
              }

              setModalPickup({ open: true, pickup: null });
            }}
          >
            <ImageIcon
              className="w-[15px] cursor-pointer"
              src="/images/icons/add.svg"
            />
          </span>
        )}
      </div>

      {pickup.length === 0 && <span>{t("thereIsNoPickup")}</span>}

      {pickupItemToDisplay?.map((pickupItem: any) => (
        <PickupItem
          pickup={pickupItem}
          onClickEdit={(isReadOnly: boolean) =>
            setModalPickup({ open: true, pickup: pickupItem, isReadOnly })
          }
          isShowCreateEdit={isShowCreateEditPickup}
          onClickPrint={() => onClickPickUpPrint(pickupItem)}
          className={pickupList?.length === 1 ? "" : "border-b pb-2"}
        />
      ))}

      {pickupList.length > 1 && (
        <div className="flex justify-end border-t pt-3">
          <div className="relative">
            <span className="z-10 flex items-center justify-center px-[3px] py-0 text-[10px] bg-red-500 text-white rounded-full min-w-[20px] min-h-[20px] absolute -top-[5px] -right-[5px]">
              {pickupList.length}
            </span>
            <Button
              onClick={() => setShowAllPickup(!showAllPickup)}
              id="buttonTrack"
              color="primary"
              variant="outline"
              className="text-xs px-2"
              style={{ minWidth: 0, width: "40px" }}
            >
              <span className="text-xs text-white whitespace-nowrap text-ellipsis overflow-hidden inline-block flex items-center">
                <ImageIcon src="/images/icons/package.svg" />
              </span>
            </Button>
          </div>
        </div>
      )}

      {modalPickup.open && (
        <PickupDrawer
          open={modalPickup.open}
          isReadOnly={modalPickup.isReadOnly}
          onClose={() =>
            setModalPickup({ open: false, pickup: null, isReadOnly: false })
          }
          pickup={modalPickup.pickup}
          productOrderList={products}
          deliveryList={delivery}
          pickupList={pickupList}
          store={store}
          customer={customer}
          onSave={onSaveOrderPickup}
          showSaveButton={
            isPendingOrder &&
            (!modalPickup?.pickup ||
              modalPickup?.pickup?.status === ORDER_PICKUP_STATUS.PENDING)
          }
        />
      )}
    </div>
  );
};

export default OrderPickup;
