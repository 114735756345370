import { API } from "@network";
import {
  API_ADMIN_PROFILE,
  API_AUTHENTICATOR,
  API_GET_WORKINFINITY_LOGIN_URL,
  API_LINE_CONNECT_INFO,
  API_LINE_CONNECT_UNLINK,
  API_LINE_CONNECT_URL,
  API_LOGIN,
  API_LOGIN_WORKINFINITY_X,
  API_REFRESH_DISCOUNT_ID,
} from "@network/URL";

export const login = (body: any) => {
  return API.post(API_LOGIN, body);
};

export const validateAuth = (body: any) => {
  return API.post(API_AUTHENTICATOR, body);
};

export const getAdminProfile = (body?: any) => {
  return API.post(API_ADMIN_PROFILE, body);
};

export const refreshDiscount = () => {
  return API.post(API_REFRESH_DISCOUNT_ID, {});
};

export const getWorkinfinityXLoginURL = () => {
  return API.get(API_GET_WORKINFINITY_LOGIN_URL);
};

export const loginWorkinfinityX = (body: { code: string }) => {
  return API.post(API_LOGIN_WORKINFINITY_X, body);
};

export const getLineConnectURL = () => {
  return API.get(API_LINE_CONNECT_URL);
};

export const getLineConnectInfo = (body: any) => {
  return API.post(API_LINE_CONNECT_INFO, body);
};

export const unlinkLineConnect = (body: any) => {
  return API.post(API_LINE_CONNECT_UNLINK, body);
};