import { ADMIN_AUTH_KEY } from "@constants";
import { isEmpty } from "lodash";
import { Navigate, Route, RouteProps } from "react-router-dom";
import { useLocalStorage } from "react-use";
import MainContent from "./shared/MainContent";
import MenuBar from "./shared/MenuBar";
import NavBar from "./shared/NavBar";
// import Navbar from "./Layout/Navbar";

interface ProtectedRouteProps {
  permission?: string;
}

export function ProtectedRoute({
  children,
  permission,
}: ProtectedRouteProps & RouteProps) {
  const [isAuthenticated] = useLocalStorage(ADMIN_AUTH_KEY);
  const component = !isEmpty(isAuthenticated) ? (
    <>
      <MenuBar />
      <div className="active" id="app-layout__content">
        <NavBar />
        <MainContent>{children}</MainContent>
      </div>
    </>
  ) : (
    <></>
  );
  return component;
}

export default ProtectedRoute;
