import React from "react";
import { Avatar } from "d-react-components";
import classNames from "classnames";

const UserNameView = ({
  user,
  placeholder = "N/A",
  size = "x-small",
  className = "",
  variant = "rounded",
  text = "",
}: any) => {
  if (!user || !user?.id) return placeholder;
  return (
    <div className={classNames("flex items-center", className)}>
      {user.avatar ? (
        <Avatar src={user?.avatar} size={size} variant={variant} />
      ) : (
        <div className="bg-white w-[32px] h-[32px] text-primary flex items-center justify-center">
          JV
        </div>
      )}
      <div className="flex flex-col ml-2">
        <span>{user?.name}</span>
        <span>{user?.companyId}</span>
      </div>
    </div>
  );
};

export default UserNameView;
