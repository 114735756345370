import { Path } from "@components/layout/Path";
import UserAvatarName from "@components/shared/UserAvatarName";
import {
  DELIVERY_STATUS_LIST,
  DELIVERY_STOCK_STATUS_LIST,
} from "@constants/delivery";
import AppContext from "@helpers/context";

import {
  AwesomeTableComponent,
  HeaderTable,
  ITabItem,
  TimeUtils,
  useFirstTime,
  ViewLabelStatus,
} from "d-react-components";

import { getDeliveryStockList } from "@network/api/delivery-stock";
import { debounce, map } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import DeliveryStockFilterDrawer from "./DeliveryStockFilterDrawer";
import DeliveryTransferStockTabs from "./DeliveryStockTabs";

const DeliveryTransferStockTable = () => {
  const tableRef = useRef<any>();
  const searchRef = useRef<string>();
  const [tabSelected, setTabSelected] = useState<ITabItem>({
    id: "all",
    label: "all",
  });
  const [openFilter, setOpenFilter] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [filter = {}, setFilter] = useQueryParam<any>("filter", JsonParam);

  const isFirstTime = useFirstTime();

  useEffect(() => {
    if (isFirstTime) return;
    tableRef.current?.refresh();
  }, [JSON.stringify(filter), tabSelected]);

  const TABLE_ACTIONS = [
    { id: "edit", label: t("edit") },
    { id: "detail", label: t("detail") },
  ];

  const source = (paging: any) => {
    const body: any = {
      search: searchRef?.current,
      status:
        !tabSelected || tabSelected?.id === "all" ? null : [tabSelected?.id],
    };

    if (filter) {
      const {
        status,
        channels,
        warehouseOrigin,
        warehouseDestination,
        driver,
        vehicle,
        createdBy,
      } = filter;

      // body.status = status;

      body.channels = channels && map(channels, (item) => item.id);
      body.warehouse_origin =
        warehouseOrigin && map(warehouseOrigin, (item) => item.id);
      body.warehouse_destination =
        warehouseDestination && map(warehouseDestination, (item) => item.id);

      body.vehicle = vehicle && map(vehicle, (item) => item.id);
      body.created_by = createdBy && map(createdBy, (item) => item.id);
      body.driver = driver && map(driver, (item) => item.id);
    }
    return getDeliveryStockList(body, paging);
  };

  const columns: any[] = [
    {
      title: t("doNo"),
      dataIndex: "ref",
      ellipsis: true,
      render: (ref: string, delivery: any) => (
        <Link
          to={generatePath(Path.DELIVERY_STOCK_DETAIL, {
            deliveryId: delivery.id,
          })}
        >
          {ref}
        </Link>
      ),
    },
    {
      title: t("warehouseOrigin"),
      dataIndex: "transfer",
      render: (transfer: any) =>
        `${transfer?.transfer_origin?.name} - ${
          transfer?.transfer_origin?.code ?? ""
        }`,
    },
    {
      title: t("warehouseDestination"),
      dataIndex: "transfer",
      render: (transfer: any) =>
        `${transfer?.transfer_destination?.name} - ${
          transfer?.transfer_destination?.code ?? ""
        }`,
    },
    {
      title: t("vehicleNo"),
      dataIndex: "vehicle",
      render: (vehicle: any, item: any) =>
        vehicle ? vehicle?.registration : item?.vehicle_no,
    },
    {
      title: t("driver"),
      dataIndex: "driver",
      align: "center",
      render: (driver: any, item: any) =>
        driver ? <UserAvatarName user={driver} /> : item?.driver_name,
    },

    {
      title: t("itemQty"),
      dataIndex: "total_item",
    },

    {
      title: t("status"),
      dataIndex: "status",
      render: (status: string) => (
        <ViewLabelStatus
          status={status}
          listStatus={DELIVERY_STOCK_STATUS_LIST}
          getLabel={(item) => t(item.label)}
        />
      ),
    },
    {
      title: t("estArrival"),
      dataIndex: "estimated_arrival",
      render: TimeUtils.toDateTime,
    },

    // {
    //   title: t("createdBy"),
    //   dataIndex: "by",
    //   render: (by: any) => <UserAvatarName user={by} />,
    // },
    {
      title: t("transferRef"),
      dataIndex: "transfer",
      render: (transfer: any) => (
        <Link
          to={generatePath(Path.TRANSFER_STOCK_DETAIL, {
            transferId: transfer.id,
          })}
        >
          {transfer?.transfer_no}
        </Link>
      ),
    },
    {
      title: t("createAt"),
      dataIndex: "created",
      render: TimeUtils.toDateTime,
    },
  ];

  const onClickAction = (action: any, transfer: any) => {
    switch (action.id) {
      case "edit":
        break;

      case "detail":
        break;

      default:
        break;
    }
  };

  const onChangeSearch = debounce((text) => {
    searchRef.current = text;
    tableRef.current.refresh();
  }, 300);

  const isFiltered = useMemo(() => Object.keys(filter).length > 0, [filter]);

  return (
    <AppContext.Provider value={{ tabSelected }}>
      <div className="bg-white">
        <HeaderTable
          label={t("deliveryStock")}
          onChangeText={(event: any) => onChangeSearch(event.target.value)}
          onClickFilter={() => setOpenFilter(true)}
          isFiltered={isFiltered}
          className="app-layout__page-header"
        />

        <div className="d-flex overflow-auto">
          <DeliveryTransferStockTabs
            tabSelected={tabSelected}
            onChangeTab={setTabSelected}
          />
        </div>
        <AwesomeTableComponent
          source={source}
          transformer={(res) => res?.data?.data?.delivery ?? []}
          isScroll={false}
          columns={columns}
          ref={(ref) => (tableRef.current = ref)}
        />
        {openFilter && (
          <DeliveryStockFilterDrawer
            open={openFilter}
            onClose={() => setOpenFilter(false)}
            defaultValue={filter}
            onFilter={setFilter}
          />
        )}
      </div>
    </AppContext.Provider>
  );
};

export default DeliveryTransferStockTable;
