import { DIMENSION_UNIT, WEIGHT_UNIT } from "@constants/product";
import classNames from "classnames";
import { Icon, InputText, Select } from "d-react-components";
import { useTranslation } from "react-i18next";
import MaskedInput from "react-text-mask";

interface IProductInput {
  productForm: any;
  className?: any;
}

const DimensionInput = ({ ...inputProps }: any) => {
  return (
    <div className="d-input-text__input-container d-input-text__input-container-outline flex-row align-items-center pr-2">
      <input className=" d-input-text__input flex-grow-1" {...inputProps} />
      <text className="text-hint">cm</text>
    </div>
  );
};

const ProductInputShipping = ({ productForm, className }: IProductInput) => {
  const { weight_value, weight_option, dimension_value, dimension_option } =
    productForm?.values;
  const { t } = useTranslation();

  const classNameField = "col-span-2";

  return (
    <div className={classNames("grid grid-cols-4 gap-4 ", className)}>
      <InputText
        className={classNameField}
        label={t("weight")}
        value={weight_value}
        onChange={(event) =>
          productForm.setFieldValue("weight_value", event.target.value)
        }
        type="number"
      />
      <Select
        label={t("weightUnit")}
        dataSource={WEIGHT_UNIT}
        getLabel={(option) => option}
        value={weight_option}
        getValue={(item) => item}
        onChange={(value) => productForm.setFieldValue("weight_option", value)}
        className={classNameField}
      />
      <div className={classNameField}>
        <label>{t("dimension")}</label>
        {/* <div className="flex-row align-items-center">
          <DimensionInput
            placeholder="Width"
            value={dimensionValue?.width}
            onChange={(event: any) =>
              productForm.setFieldValue("dimensionValue", {
                ...dimensionValue,
                width: event.target.value,
              })
            }
          />
          <Icon name="clear" color="#a7a7a7" className="mx-3" />
          <DimensionInput
            placeholder="Length"
            value={dimensionValue?.length}
            onChange={(event: any) =>
              productForm.setFieldValue("dimensionValue", {
                length: event.target.value,
              })
            }
          />
          <Icon name="clear" color="#a7a7a7" className="mx-3" />
          <DimensionInput
            placeholder="Height"
            value={dimensionValue?.height}
            onChange={(event: any) =>
              productForm.setFieldValue("dimensionValue", {
                height: event.target.value,
              })
            }
          />
        </div> */}
        <MaskedInput
          mask={[
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            "x",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            "x",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]}
          placeholder={t("widthlengthheight")}
          placeholderChar="-"
          // label={t("dimension") as any}
          value={dimension_value ?? ""}
          onChange={(event) =>
            productForm.setFieldValue("dimension_value", event.target.value)
          }
          render={(ref, props) => (
            <div className="d-input-text__input-container d-input-text__input-container-outline">
              <input
                {...props}
                ref={ref as any}
                className="d-input-text__input"
              />
            </div>
          )}
        />
      </div>

      <Select
        className={classNameField}
        label={t("dimensionUnit")}
        dataSource={DIMENSION_UNIT}
        getLabel={(option) => option}
        value={dimension_option}
        getValue={(item) => item}
        onChange={(value) =>
          productForm.setFieldValue("dimension_option", value)
        }
      />
    </div>
  );
};

export default ProductInputShipping;
