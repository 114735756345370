import HeaderDetail from "@common/HeaderDetail";
import AppContext from "@helpers/context";
import { ICartRule, mapObjectServerToClient } from "@interfaces/cart-rule";
import CartRuleAPI from "@network/api/cart-rule";
import classNames from "classnames";
import { Button, DialogManager, Progress, TabBar } from "d-react-components";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import CartRuleDetailInfo from "./CartRuleDetailInfo";
import CartRuleDetailGeneral from "./CartRuleDetailGeneral";
import CartRuleDetailConfiguration from "./CartRuleDetailConfiguration";
import CartRuleDetailCondition from "./CartRuleDetailCondition";
import CartRuleDetailAction from "./CartRuleDetailAction";
import CartRuleDetailActivity from "./CartRuleDetailActivity";
import { Path } from "@components/layout/Path";
import CartRuleDetailSyncLog from "./CartRuleDetailSyncLog";
import { Dropdown } from "antd";

const TABS = [
  {
    id: "general",
    label: "general",
    component: <CartRuleDetailGeneral />,
  },

  {
    id: "configuration",
    label: "configuration",
    component: <CartRuleDetailConfiguration />,
  },
  {
    id: "condition",
    label: "cartRuleCondition",
    component: <CartRuleDetailCondition />,
  },
  {
    id: "action",
    label: "cartRuleAction",
    component: <CartRuleDetailAction />,
  },
  {
    id: "syncLog",
    label: "cartRuleSyncLog",
    component: <CartRuleDetailSyncLog />,
  },
  {
    id: "activity",
    label: "activity",
    component: <CartRuleDetailActivity />,
  },
];

const CartRuleDetail = () => {
  const [cartRule, setCartRule] = useState<ICartRule | null>(null);
  const [tab, setTab] = useState<any>(TABS[0]);
  const { cartRuleId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    loadCartRuleDetail();
  }, []);

  const OPTION = useMemo(() => {
    return [
      {
        label: t("endCartRule"),
        key: "endCartRule",
      },
      {
        label: t("duplicate"),
        key: "duplicate",
      },
    ];
  }, [t]);

  const loadCartRuleDetail = () => {
    const body = {
      id: cartRuleId,
    };
    Progress.show(
      { method: CartRuleAPI.detail, params: [body] },
      (res: any) => {
        const cartRule = res?.data?.data?.cartRule;
        setCartRule(cartRule);
      }
    );
  };

  const onClickDuplicate = () => {
    navigate(Path.CART_RULE_CREATE, {
      replace: true,
      state: {
        cartRuleDefault: {
          ...mapObjectServerToClient(cartRule),
          title: `${cartRule?.title}( ${t("duplicate")})`,
        },
      },
    });
  };

  const handleChooseAction = (item: any) => {
    if (item.key === "endCartRule") {
    }
    if (item.key === "duplicate") {
      onClickDuplicate();
    }
  };

  if (!cartRule) return <div />;

  return (
    <AppContext.Provider
      value={{
        cartRule,
        loadCartRuleDetail,
      }}
    >
      <div className="flex-column-container bg-trans">
        <HeaderDetail
          title={cartRule?.title}
          rightSide={
            // <div className="d-flex">
            //   <Button onClick={onClickDuplicate} size="small" className="ml-3">
            //     {t("duplicate")}
            //   </Button>
            // </div>
            <Dropdown
              trigger={["click"]}
              menu={{ items: OPTION, onClick: handleChooseAction }}
            >
              <Button
                variant="outline"
                content={t("action")}
                suffixIcon="arrow_drop_down"
                size="small"
                className="ml-2"
              />
            </Dropdown>
          }
        />
        <div className="p-3 bg-white">
          <div className="grid grid-cols-4 gap-4">
            <div className="col-span-4 md:col-span-1">
              <CartRuleDetailInfo />
            </div>
            <div className="col-span-4 md:col-span-3">
              <div className="">
                <TabBar
                  dataSource={TABS}
                  onChange={setTab}
                  value={tab}
                  getLabel={(item: any) => t(item.label)}
                  classNameItem={classNames(
                    "bg-white border-right-0 border-left-0 border-top-0"
                  )}
                />
                {tab.component}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppContext.Provider>
  );
};

export default CartRuleDetail;
