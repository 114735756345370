import AppContext from "@helpers/context";
import { uploadFile } from "@network/api/common";
import UserAPI from "@network/api/user";
import { Notifications, Progress, TimeUtils } from "d-react-components";
import React, { useContext } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

const UserDetailInfo = () => {
  const { t } = useTranslation();
  const { user, loadUserDetail } = useContext(AppContext);
  const {
    fullname,
    username,
    avatar,
    employee_id,
    authentication_status,
    name,
    created,
    os_model,
    last_login,
    block,
    updated,
    app_version,
  } = user;

  const renderRowInfo = (title: any, content: any) => (
    <div className="d-flex justify-content-between align-items-center border-bottom py-3 w-100">
      <text className="text-bold">{title}</text>
      <text>{content}</text>
    </div>
  );

  const onUploadFile = ([file]: any) => {
    const reader = new FileReader();
    const url = reader.readAsDataURL(file);
    reader.onloadend = function (e) {
      const body = new FormData();
      body.append("file", file);
      Progress.show({ method: uploadFile, params: [body] }, (res: any) => {
        const fileKey = res?.data?.data?.id;
        Progress.show(
          {
            method: UserAPI.update,
            params: [{ avatar: fileKey, id: user?.id }],
          },
          () => {
            Notifications.showSuccess(t("updateUserSuccess"));
            loadUserDetail();
          }
        );
      });
    };
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: onUploadFile });

  return (
    <div>
      <div className="border flex-center flex-column pt-5 w-100 bg-white p-4">
        <div {...getRootProps()}>
          <input {...getInputProps()} multiple={false} />
          <img
            src={avatar}
            className="image-circle-x-large object-fit-cover max-w-[100px]"
          />
        </div>

        <h4 className="mt-3">{fullname}</h4>
        <div className="flex-column mt-5 w-100 ">
          {renderRowInfo(t("username"), username)}
          {renderRowInfo(t("employeeId"), employee_id)}
          {renderRowInfo(t("nickName"), name)}
          {renderRowInfo(t("block"), block ? "Yes" : "No")}
          {renderRowInfo(
            t("authentication"),
            authentication_status ? "Yes" : "No"
          )}
          {renderRowInfo(
            t("createdAt"),
            TimeUtils.convertMiliToDateTime(created)
          )}
          {renderRowInfo(
            t("lastLogin"),
            TimeUtils.convertMiliToDateTime(last_login)
          )}
          {renderRowInfo(t("latestDevice"), os_model)}
          {renderRowInfo(t("appVersion"), app_version)}
          {renderRowInfo(
            t("updatedAt"),
            TimeUtils.convertMiliToDateTime(updated)
          )}
        </div>
      </div>
    </div>
  );
};

export default UserDetailInfo;
