import ImageIcon from "@common/Icon";
import Drawer from "@components/shared/Drawer";
import { CHANNEL_GENERAL_KEYS } from "@constants/channel";
import AppContext from "@helpers/context";
import { IChannel } from "@interfaces/channel";
import ChannelAPI from "@network/api/channel";
import {
  IRowsKey,
  Notifications,
  ObjectUtils,
  Progress,
  ViewRowInterchange,
} from "d-react-components";
import { useFormik } from "formik";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ChannelFormSms from "../components/ChannelFormSms";

function ChannelDetailSmsUpdateDrawer({
  open,
  onClose,
  channelId,
  defaultValue,
  onUpdateSuccess,
}: any) {
  const { t } = useTranslation();

  const generalForm = useFormik({
    initialValues: defaultValue ?? {},
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: ChannelGeneralSchema,
    onSubmit: (values) => {
      const formValue: any = ObjectUtils.mapFieldsLangsCTS(
        values,
        CHANNEL_GENERAL_KEYS as any
      );
      const body = { id: channelId, ...formValue };
      Progress.show({ method: ChannelAPI.update, params: [body] }, () => {
        Notifications.showSuccess(t("updateChannelSuccess"));
        onUpdateSuccess();
        onClose();
      });
    },
  });
  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("updateChannel")}
      onSave={() => generalForm.handleSubmit()}
      size="auto"
      width="30vw"
    >
      <ChannelFormSms form={generalForm} fieldClassName="col-12" />
    </Drawer>
  );
}

const ChannelDetailSMS = () => {
  const { channel, loadChannelDetail } = useContext(AppContext);
  const [openUpdate, setOpenUpdate] = useState(false);
  const { t } = useTranslation();

  const { id } = channel ?? {};

  const DETAILS_KEY = useMemo<IRowsKey<IChannel>[]>(
    () => [
      {
        id: "sender_name_sms",
        label: t("senderName"),
      },
      {
        id: "short_domain",
        label: t("shortDomain"),
      },
    ],
    [t]
  );

  return (
    <div className="border mt-3 p-4">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h5>{t("smsConfiguration")}</h5>
        <span onClick={() => setOpenUpdate(true)}>
          <ImageIcon
            className="w-[15px] cursor-pointer"
            src="/images/icons/edit.svg"
          />
        </span>
      </div>
      <ViewRowInterchange
        className="w-100"
        dataSource={channel}
        keyList={DETAILS_KEY as any}
        variant="border"
        classNameLabel="font-weight-bold "
        classNameContent="text-end "
      />
      {openUpdate && (
        <ChannelDetailSmsUpdateDrawer
          open={openUpdate}
          onClose={() => setOpenUpdate(false)}
          channelId={id}
          defaultValue={ObjectUtils.mapFieldsLangsSTC(
            channel ?? {},
            CHANNEL_GENERAL_KEYS as any
          )}
          onUpdateSuccess={loadChannelDetail}
        />
      )}
    </div>
  );
};

export default ChannelDetailSMS;
