import { IFieldSelect } from "@interfaces/select";
import { getCompanyList } from "@network/api/company";
import { ObjectUtils, Select } from "d-react-components";
import { isArray, map } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export interface ICompanySelect<T> extends IFieldSelect<T> {
  [key: string]: any;
}

const CompanySelect = ({
  value = [],
  multiple,
  className,
  onChange,
  ...selectProps
}: ICompanySelect<any>) => {
  const [companyList, setCompanyList] = useState<any[]>(
    isArray(value) ? value : [value]
  );

  const { t } = useTranslation();

  const onChangeValue = (id: any) => {
    if (multiple) {
      onChange(
        map(id, (item) => ObjectUtils.findItemFromId(companyList, item))
      );
      return;
    }
    onChange(ObjectUtils.findItemFromId(companyList, id));
  };

  const companyValue = useMemo(() => {
    if (multiple) {
      return map(value, (item: any) => item?.id);
    }
    return (value as any)?.id;
  }, [value]);

  useEffect(() => {
    loadCompanyList();
  }, []);

  const loadCompanyList = () => {
    getCompanyList().then((res) => {
      const companyList = res.data?.data?.company ?? [];
      setCompanyList(companyList);
    });
  };

  return (
    <Select
      className={className}
      label={t("company")}
      dataSource={companyList}
      getLabel={(item) => item?.name}
      value={companyValue}
      onChange={onChangeValue}
      multiple={multiple}
      placeholder={t("pleaseSelect")}
      showSearch
      {...selectProps}
    />
  );
};

export default CompanySelect;
