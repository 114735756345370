import Drawer from "@components/shared/Drawer";
import UploadFileItem from "@components/shared/UploadFileItem";
import { ORDER_STATUS } from "@constants/order";
import {
  PAYMENT_2C2P,
  PAYMENT_BANK_TRANSFER,
  PAYMENT_CASH,
  PAYMENT_CONFIRM_STATUS,
  PAYMENT_CREDIT_CARD_EDC,
  PAYMENT_QR_PROMPT_PAY,
} from "@constants/payment";
import { getBankFullInfo } from "@helpers/bank";
import AppContext from "@helpers/context";
import { isGrantURLPermission } from "@helpers/permissions";
import { getFullName } from "@helpers/string";
import {
  orderConfirmSplitPayment,
  uploadImageOrderConfirm,
} from "@network/api/order";
import { API_ORDER_CONFIRM_SPLIT_PAYMENT } from "@network/URL";
import {
  Button,
  DateInput,
  InputText,
  Notifications,
  Progress,
  Select,
  TimeUtils,
} from "d-react-components";
import { useFormik } from "formik";
import { map, isEmpty, filter } from "lodash";
import moment from "moment";
import React, { useContext, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

const AccountConfirmButton = ({ payload }: any) => {
  const { t } = useTranslation();
  const CONTENT_PAYMENT = {
    [PAYMENT_CONFIRM_STATUS.APPROVE]: {
      title: t("confirmFullPay"),
      description: t("areCertainPaymentPaid"),
      saveText: t("confirm"),
    },
    [PAYMENT_CONFIRM_STATUS.REJECTED]: {
      title: t("confirmRejectPayment"),
      description: t("areCertainPaymentReject"),
      saveText: t("reject"),
    },
  };

  const {
    order,
    onReloadOrderData,
    banks,
    isChainExternalSuspicious,
    hasCustomSizeProduct,
    orderHasCod,
    customer,
  } = useContext(AppContext);
  const [openModal, setOpenModal] = useState<any>({
    type: undefined,
    isVisible: false,
  });
  const [attachments, setAttachments] = useState<any[]>([]);
  // const remarkContent = useRef("");
  // const [actualDatePayment, setActualDatePayment] = useState<number>();
  // const [actualBankPayment, setActualBankPayment] = useState();
  const { status, id, method, payment_slip = [] } = payload;

  const isReject = status === PAYMENT_CONFIRM_STATUS.REJECTED;
  const isApprove = status === PAYMENT_CONFIRM_STATUS.APPROVE;

  const bankOptions = useMemo(
    () => filter(banks, (item) => item.bank_type === method),
    [banks]
  );

  const UploadSchema = useMemo(
    () =>
      Yup.lazy(() =>
        Yup.object().shape({
          actualDatePayment:
            openModal.type === PAYMENT_CONFIRM_STATUS.APPROVE
              ? Yup.string().required(t("fieldRequired"))
              : Yup.string().nullable(),
          actualBankPayment:
            openModal.type === PAYMENT_CONFIRM_STATUS.APPROVE &&
            [
              PAYMENT_QR_PROMPT_PAY,
              PAYMENT_CREDIT_CARD_EDC,
              PAYMENT_BANK_TRANSFER,
            ].includes(method)
              ? Yup.string().required(t("fieldRequired"))
              : Yup.string().nullable(),
          remarkContent: Yup.string().required(t("fieldRequired")),
          cardLast4Digit: Yup.string()
            .nullable()
            .when("method", {
              is: PAYMENT_CREDIT_CARD_EDC,
              then: Yup.string()
                .required(t("fieldRequired"))
                .test("len", t("mustbe4digit"), (val) =>
                  /^\d{4}$/.test(val as string)
                ),
            }),
          fullNameTransfer:
            openModal.type === PAYMENT_CONFIRM_STATUS.APPROVE &&
            [
              PAYMENT_QR_PROMPT_PAY,
              PAYMENT_CREDIT_CARD_EDC,
              PAYMENT_BANK_TRANSFER,
            ].includes(method)
              ? Yup.string().required(t("fieldRequired"))
              : Yup.string().nullable(),
          // attachments: Yup.array()
          //   .required(t("fieldRequired"))
          //   .min(1, t("fieldRequired")),
        })
      ),
    [openModal.type, method]
  );

  const onDrop = (fileUploads: any) => {
    const fileResult: any[] = [];
    fileUploads.forEach((file: any, index: number) => {
      const reader = new FileReader();
      const url = reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        fileResult.push({
          imageData: reader.result,
          fileData: file,
        });
        if (index === fileUploads.length - 1) {
          setAttachments([...attachments, ...fileResult]);
        }
      };
    });
  };

  const formik = useFormik<any>({
    initialValues: {
      method,
      fullNameTransfer: getFullName(customer),
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: UploadSchema,

    onSubmit: async (values) => {
      return onClickSave();
    },
  });

  const onClickRemoveImage = (removeFile: any) => {
    const clone = attachments?.filter(
      (item) => item?.imageData !== removeFile?.imageData
    );
    setAttachments([...clone]);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  if (
    method === PAYMENT_2C2P ||
    (method === PAYMENT_CASH && !order.is_chain_external)
  ) {
    return <></>;
  }
  if (isApprove || isReject) {
    return <></>;
  }

  if (
    order.status &&
    order.status !== ORDER_STATUS.pending.value &&
    order.status !== ORDER_STATUS.pendingPaymentConfirmation.value &&
    order.status !== ORDER_STATUS.partiallyPaid.value &&
    !(orderHasCod && order.status === ORDER_STATUS.delivered.value)
  ) {
    return <div />;
  }

  if (payment_slip.length === 0) {
    return <div />;
  }

  if (!isGrantURLPermission(API_ORDER_CONFIRM_SPLIT_PAYMENT)) {
    return <div />;
  }
  if (method === "2c2p") return <div />;

  const onClickConfirm = () =>
    setOpenModal({
      isVisible: true,
      type: PAYMENT_CONFIRM_STATUS.APPROVE,
    });

  const onClickReject = () => {
    if (isChainExternalSuspicious || hasCustomSizeProduct) {
      return;
    }
    setOpenModal({
      isVisible: true,
      type: PAYMENT_CONFIRM_STATUS.REJECTED,
    });
  };

  const { values, errors, handleSubmit, setFieldValue } = formik;
  const {
    actualDatePayment,
    actualBankPayment,
    remarkContent,
    cardLast4Digit,
    fullNameTransfer,
  } = values;

  const onClickSave = async () => {
    const APIList = attachments?.map((attach) => {
      const data = new FormData();
      data.append("file", attach.fileData);
      data.append("splitid", id);

      return { method: uploadImageOrderConfirm, params: data };
    });

    Progress.show(APIList, (res) => {
      let body: any = {
        splitid: id,
        note: remarkContent,
        status:
          openModal?.type === PAYMENT_CONFIRM_STATUS.APPROVE
            ? PAYMENT_CONFIRM_STATUS.APPROVE
            : PAYMENT_CONFIRM_STATUS.REJECTED,
        payment_method: method,
        ...(cardLast4Digit
          ? { confirm_credit_card_number: cardLast4Digit }
          : {}),
        ...(fullNameTransfer
          ? { confirm_name_of_payor: fullNameTransfer }
          : {}),
      };
      if (openModal.type === PAYMENT_CONFIRM_STATUS.APPROVE) {
        body = {
          ...body,
          actualDatePayment: moment(actualDatePayment).format("x"),
        };

        if (
          [
            PAYMENT_QR_PROMPT_PAY,
            PAYMENT_CREDIT_CARD_EDC,
            PAYMENT_BANK_TRANSFER,
          ].includes(method)
        ) {
          body = {
            ...body,
            actualBankPayment,
          };
        }
      }

      return orderConfirmSplitPayment(body).then(
        (res) => {
          openModal?.type === PAYMENT_CONFIRM_STATUS.APPROVE
            ? Notifications.showSuccess(t("notification:paymentConfirmed"))
            : Notifications.showSuccess(t("notification:paymentRejected"));
          setOpenModal({ type: null, isVisible: false });
          onReloadOrderData && onReloadOrderData();
        },
        (err) => {
          Notifications.showError(err);
        }
      );
    });
  };

  return (
    <div className="d-flex">
      <Button
        onClick={onClickConfirm}
        className="mr-3"
        disabled={isChainExternalSuspicious || hasCustomSizeProduct}
      >
        {t("confirm")}
      </Button>
      <Button
        onClick={onClickReject}
        color="error"
        disabled={isChainExternalSuspicious || hasCustomSizeProduct}
      >
        {t("reject")}
      </Button>

      <Drawer
        open={openModal.isVisible}
        onClose={() => setOpenModal({ isVisible: false })}
        onSave={handleSubmit}
        saveText={CONTENT_PAYMENT?.[openModal.type]?.saveText}
        title={CONTENT_PAYMENT?.[openModal.type]?.title}
        width={500}
        size="auto"
      >
        <div className="flex-column pb-12">
          <div className="text-x-small">
            {CONTENT_PAYMENT?.[openModal.type]?.description}
          </div>
          <Button iconName="cloud_upload" {...getRootProps()} className="mt-3">
            <div>
              <input
                {...getInputProps({
                  accept: "image/x-png,image/jpeg,image/heic",
                })}
              />
              {t("upload")}
            </div>
          </Button>
          <div className="mt-3 d-flex">
            {map(attachments, (item) => (
              <UploadFileItem
                source={item.imageData}
                onClickRemove={() => onClickRemoveImage(item)}
              />
            ))}
          </div>
          <div hidden={openModal?.type !== PAYMENT_CONFIRM_STATUS.APPROVE}>
            <DateInput
              className="mt-3"
              showTime
              format="DD/MM/YYYY HH:mm"
              label={t("actualDatePayment")}
              onChange={(date) => {
                setFieldValue("actualDatePayment", date);
              }}
              value={actualDatePayment}
              error={errors.actualDatePayment as string}
            />
            {[
              PAYMENT_QR_PROMPT_PAY,
              PAYMENT_CREDIT_CARD_EDC,
              PAYMENT_BANK_TRANSFER,
            ].includes(method) && (
              <Select
                className="mt-3"
                label={t("actualBankReceivePayment")}
                dataSource={bankOptions}
                value={actualBankPayment}
                onChange={(value) => setFieldValue("actualBankPayment", value)}
                getLabel={getBankFullInfo}
                placeholder={t("pleaseSelect")}
                error={errors.actualBankPayment as string}
              />
            )}
          </div>

          <InputText
            hidden={
              method !== PAYMENT_CREDIT_CARD_EDC ||
              openModal?.type !== PAYMENT_CONFIRM_STATUS.APPROVE
            }
            className="mt-3"
            label={t("cardLast4Digit")}
            name="cardLast4Digit"
            value={values.cardLast4Digit}
            onChange={(e) => setFieldValue("cardLast4Digit", e.target.value)}
            error={errors.cardLast4Digit as string}
          />

          <InputText
            hidden={
              openModal?.type !== PAYMENT_CONFIRM_STATUS.APPROVE ||
              ![
                PAYMENT_QR_PROMPT_PAY,
                PAYMENT_CREDIT_CARD_EDC,
                PAYMENT_BANK_TRANSFER,
              ].includes(method)
            }
            className="mt-3"
            label={t("nameOfPayee")}
            name="fullNameTransfer"
            value={formik.values.fullNameTransfer}
            onChange={formik.handleChange}
            error={formik.errors.fullNameTransfer as string}
          />

          <InputText
            className="mt-3"
            label={t("remark")}
            value={remarkContent}
            onChange={(event) =>
              setFieldValue("remarkContent", event.target.value)
            }
            multiple
            placeholder={t("pleaseInputTheNote")}
            error={errors.remarkContent as string}
          />
        </div>
      </Drawer>
    </div>
  );
};

export default AccountConfirmButton;
