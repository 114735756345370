import AppContext from "@helpers/context";
import { cancelOrder } from "@network/api/order";
import { Button, Drawer, Notifications, InputText } from "d-react-components";
import { isEmpty } from "lodash";
import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export default function CancelOrder({
  openModal,
  setOpenModal,
}: {
  openModal: boolean;
  setOpenModal: (status: boolean) => void;
}) {
  const { order, onReloadOrderData } = useContext(AppContext);
  const remarkRef = useRef<any>();
  const { t } = useTranslation();

  const onClickOKCancelOrderHandle = async () => {
    if (isEmpty(remarkRef.current)) {
      return Notifications.showError(
        t("notification:pleaseInputReasonToCancelThisOrder")
      );
    }
    const body = {
      orderid: order?.id,
      remark: remarkRef.current,
    };
    return cancelOrder(body)
      .then(() => {
        onReloadOrderData();
        setOpenModal(false);
        Notifications.showSuccess(t("notification:cancelOrderSuccessfully"));
      })
      .catch((error: any) => {
        Notifications.showError(error?.message);
      });
  };

  return (
    <>
      <Drawer
        open={openModal}
        onClose={() => setOpenModal(false)}
        title={t("cancelOrder")}
      >
        <div className="pb-12">
          <div className="mb-3">
            {t("notification:areYouSureWanttoCanelThisOder")}
          </div>
          <InputText
            label={t("remark")}
            multiple
            rows={5}
            placeholder={t("notification:pleaseInputTheNote")}
            onChange={(event: any) =>
              (remarkRef.current = event?.target?.value)
            }
          />
        </div>
        <div className="position-absolute bottom-0 w-100 end-0 start-0 flex-center-y justify-content-between py-3 px-3 bg-white">
          <Button
            onClick={() => {
              onClickOKCancelOrderHandle();
            }}
          >
            {t("save")}
          </Button>
        </div>
      </Drawer>
    </>
  );
}
