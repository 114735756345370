import { Path } from "@components/layout/Path";
import AppContext from "@helpers/context";
import { isGrantURLPermission } from "@helpers/permissions";
import { IStore } from "@interfaces/store";
import { API_MY_ASSETS_REQUEST_CREATE } from "@network/URL";
import MyAssetsAPI from "@network/api/my-assets";
import {
  AwesomeTableComponent,
  Button,
  InputTextSearch,
  TimeUtils,
} from "d-react-components";
import { find, uniqBy } from "lodash";
import React, { useContext, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";

const MyAssetsWarehouse = () => {
  const [searchValue, setSearchValue] = useState();
  const [listCheckWarehouse, setListCheckWarehouse] = useState<any[]>([]);
  const { summary } = useContext(AppContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tableRef = useRef<any>();

  const columns = useMemo(
    () => [
      {
        title: t("name"),
        dataIndex: "name",
      },
      {
        dataIndex: "warehouse_system_code",
        title: t("systemCode"),
        width: 130,
      },
      {
        dataIndex: "code",
        title: t("navArCode"),
        width: 130,
      },
      {
        dataIndex: "pickup_code",
        title: t("navPickupCode"),
        width: 130,
      },
      // {
      //   title: t("startingDate"),
      //   dataIndex: "start",
      //   width: 120,
      //   // render: (start: any, item: any) => {
      //   //   const currentItem = find(listCheckWarehouse, {
      //   //     warehouse_id: item.id,
      //   //   });
      //   //   return currentItem ? TimeUtils.toDate(currentItem?.start) : "";
      //   // },
      // },
    ],
    [t, listCheckWarehouse]
  );

  const source = (paging: any) => {
    const body = {
      search: searchValue,
      action: "in",
    };
    // return MyAssetsAPI.warehouse(paging, body);
    return MyAssetsAPI.warehouse(paging, body).then((resp) => {
      const listWareHouse = resp?.data?.data?.warehouse ?? [];
      MyAssetsAPI.checkAssetWarehouse({
        warehouse: listWareHouse?.map((w: IStore) => w.id),
      }).then((resp) => {
        setListCheckWarehouse(
          uniqBy(
            [...listCheckWarehouse, ...(resp?.data?.data?.asset ?? [])],
            "id"
          )
        );
      });
      return resp;
    });
  };

  const transformer = (res: any) => {
    return res?.data?.data?.warehouse ?? [];
  };

  const submitSearch = () => {
    tableRef?.current?.refresh();
  };

  return (
    <div className="bg-white p-3">
      <div className="flex items-center mb-3">
        <div className="flex flex-1 items-center">
          <h5 className="">{t("warehouse")}</h5>
          <span className="ml-1 bg-red-500 text-white min-w-[1rem] h-4 text-xs flex items-center justify-center">
            {summary?.warehouse}
          </span>
        </div>
        {isGrantURLPermission(API_MY_ASSETS_REQUEST_CREATE) && (
          <Button
            onClick={() =>
              navigate(generatePath(Path.MY_REQUEST_NEW, { type: "warehouse" }))
            }
          >
            {t("requestChange")}
          </Button>
        )}
      </div>
      <div className="mb-3">
        <InputTextSearch
          className="bg-white"
          placeholder={t("search")}
          value={searchValue}
          onChange={(e: any) => setSearchValue(e?.target?.value)}
          onSubmit={() => submitSearch()}
        />
      </div>
      <AwesomeTableComponent
        ref={(ref) => {
          tableRef.current = ref;
        }}
        transformer={transformer}
        source={source}
        columns={columns}
        className="mt-3"
      />
    </div>
  );
};

export default MyAssetsWarehouse;
