export enum PROMOTION_STATUS {
  IN_USE = "in-use",
  // CANCELLED = "canceled",
  // EXPIRED = "expired",
  ENDED = "ended",
  SCHEDULED = "scheduled",
}

export const PROMOTION_STATUSES = [
  { id: PROMOTION_STATUS.SCHEDULED, label: "scheduled", color: "#FAC256" },
  { id: PROMOTION_STATUS.IN_USE, label: "onGoing", color: "#33B950" },
  { id: PROMOTION_STATUS.ENDED, label: "ended", color: "#ED6969" },
  // { id: PROMOTION_STATUS.CANCELLED, label: "canceled", color: "#C4C4C4" },
  // { id: PROMOTION_STATUS.EXPIRED, label: "expired", color: "#ED6969" },
];

export const PROMOTION_FILTER_STATUSES = [
  { id: "all", label: "all", color: "#C4C4C4" },
  ...PROMOTION_STATUSES,
];

export enum PROMOTION_VISIBILITY {
  PUBLISH = "public",
  DRAFT = "draft",
  ENDED = "ended",
}

export const PROMOTION_VISIBILITIES = [
  { id: PROMOTION_VISIBILITY.DRAFT, label: "scheduled", color: "#C4C4C4" },
  { id: PROMOTION_VISIBILITY.PUBLISH, label: "publish", color: "#33B950" },
];
export const PROMOTION_FILTER_VISIBILITIES = [
  { id: "all", label: "all", color: "#C4C4C4" },
  ...PROMOTION_VISIBILITIES,
];

//use only for publish a promotion
export enum PromotionPublishStatus {
  DRAFT = "draft",
  PUBLISHED = "public",
}

export const PROMOTION_PUBLISH_STATUS = [
  {
    value: PromotionPublishStatus.DRAFT,
    label: "scheduled",
    color: "#FAC256",
  },
  {
    value: PromotionPublishStatus.PUBLISHED,
    label: "published",
    color: "#84C4FF",
  },
];
