import { FILE_TYPE, IMAGE } from "@constants/file";
import { Attachment } from "@interfaces/my-assets";
import { StringUtils } from "d-react-components";
import { find } from "lodash";
import { useTranslation } from "react-i18next";
import SimpleReactLightbox, {
  SRLWrapper,
  useLightbox,
} from "simple-react-lightbox";

interface AttachmentViewProps {
  attachments?: Attachment[];
}

const getFileNameFromUrl = (url: string) => {
  const splitted = url.split("/");
  return splitted[splitted.length - 1];
};

const getImageDataPreview = (attachment: Attachment) => {
  const fileName = getFileNameFromUrl(attachment.attachment);
  const extension = StringUtils.getExtensionFromFilename(fileName);
  const isImage = IMAGE.extension.includes(extension);
  if (isImage) return attachment?.attachment;
  const fileFormat = find(FILE_TYPE, (fileTypeItem) =>
    fileTypeItem.extension.includes(extension)
  );
  if (fileFormat) {
    return fileFormat.iconFile;
  }
  return "";
};

const AttachmentViewInner = ({ attachments }: AttachmentViewProps) => {
  const { openLightbox, closeLightbox } = useLightbox();
  const { t } = useTranslation();
  const imageToView = attachments?.map((a) => ({
    src: getImageDataPreview(a),
    caption: getFileNameFromUrl(a.attachment),
    download: a.attachment,
  }));

  return (
    <>
      <div className="my-3">
        <h5 className="flex items-center mb-3 text-xs">{t("document")}</h5>
        <div className="flex">
          {attachments &&
            attachments?.map((file: any, index: number) => (
              <img
                className="w-16 h-16 object-contain cursor-pointer mr-2"
                src={getImageDataPreview(file)}
                alt=""
                onClick={() => {
                  openLightbox(index);
                }}
              />
            ))}
        </div>
      </div>
      {/* @ts-ignore */}
      <SRLWrapper
        elements={imageToView}
        options={{ thumbnails: { showThumbnails: false } }}
      ></SRLWrapper>
    </>
  );
};

const AttachmentView = (props: AttachmentViewProps) => {
  return (
    <SimpleReactLightbox>
      <AttachmentViewInner {...props} />
    </SimpleReactLightbox>
  );
};

export default AttachmentView;
