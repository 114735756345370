import Drawer, { DrawerProps } from "@components/shared/Drawer";
import { useTranslation } from "react-i18next";
import { Icon, Notifications, TimeUtils } from "d-react-components";
import { DiscountType } from "@interfaces/common";

const DiscountOTPDrawer = ({
  open,
  onClose,
  code,
}: DrawerProps & { code: any }) => {
  const { t } = useTranslation();
  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("discountAuthorizationCode")}
      hideFooter={true}
    >
      <div className="text-center flex items-start justify-center mb-4">
        <span className="text-5xl text-primary">{code.code}</span>
        <span className="bg-primary px-2 py-1 ml-2 text-white">
          {code.discount_value}
          {code.discount_type === DiscountType.Percentage ? "%" : "THB"}
        </span>
      </div>
      <div className="flex flex-col items-center mb-4">
        <span className="text-gray-500 mb-1 block">
          {t("generatedAt")}: {TimeUtils.toDateTime(code.created)}
        </span>
        <span className="text-gray-500 mb-1 block">
          {t("validTill")}: {TimeUtils.toDateTime(code.expired_at)}
        </span>
      </div>
      <div className="flex items-center justify-center">
        <Icon
          name="content_copy"
          className="bg-primary text-white p-2 rounded cursor-pointer"
          onClick={() => {
            navigator.clipboard.writeText(code?.code ?? "");
            Notifications.showSuccess(t("notification:copySuccess"));
          }}
        />
      </div>
    </Drawer>
  );
};

export default DiscountOTPDrawer;
