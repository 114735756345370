import UserAvatarName from "@components/shared/UserAvatarName";
import {
  MY_REQUEST_EXECUTION_STATUS_LIST,
  MY_REQUEST_STATUS_LIST,
} from "@constants/my-assets";
import MyAssetsAPI from "@network/api/my-assets";
import {
  AwesomeTableComponent,
  TimeUtils,
  ViewLabelStatus,
} from "d-react-components";
import { find } from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useUpdateEffect } from "react-use";
import RequestAssetFilters from "../RequestAssetFilters";

const RequestApproval = () => {
  const tableRef = useRef<any>();
  const { status } = useParams();
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const [filterBody, setFilterBody] = useState<any>({});
  const [pagination, setPagination] = useState<any>();

  const columns = useMemo(
    () => [
      {
        title: t("requestId"),
        dataIndex: "request_no",
        render: (data: any, item: any) => (
          <Link to={`/my-assets/request-approval/detail/${item.id}`}>
            {data}
          </Link>
        ),
      },
      {
        title: t("requestedAt"),
        dataIndex: "created",
        render: (data: any) => {
          return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
        },
      },
      {
        title: t("requestedBy"),
        dataIndex: "request_by",
        render: (request_by: any) => {
          return (
            <UserAvatarName
              user={request_by}
              size="xx-small"
              variant="square"
            />
          );
        },
      },
      {
        title: t("requestedFor"),
        dataIndex: "request_for",
        render: (request_for: any) => {
          return (
            <UserAvatarName
              user={request_for}
              size="xx-small"
              variant="square"
            />
          );
        },
      },
      {
        title: t("typeOfChangeRequest"),
        dataIndex: "type_of_request",
        render: (data: any) => `${t("update")} ${data}`,
      },
      {
        title: t("status"),
        dataIndex: "status",
        render: (data: any) => {
          const status = find(MY_REQUEST_STATUS_LIST, { id: data });
          return (
            <ViewLabelStatus
              content={t(`${status?.label}`)}
              color={status?.color}
            />
          );
        },
      },
      {
        title: t("executionStatus"),
        dataIndex: "execution_status",
        render: (data: any) => {
          const status = find(MY_REQUEST_EXECUTION_STATUS_LIST, { id: data });
          return (
            <ViewLabelStatus
              content={t(`${status?.label}`)}
              color={status?.color}
            />
          );
        },
      },
    ],
    [t]
  );

  const source = (paging: any) => {
    const statusList = status === "approved" ? [status, "completed"] : [status];
    const { type_of_request, execution_status, request_by_id, admin_id } =
      filterBody;
    const body = {
      search: searchValue,
      ...(status === "all" ? {} : { status: statusList }),
      ...(type_of_request ? { type_of_request: [type_of_request] } : {}),
      ...(execution_status ? { execution_status: [execution_status] } : {}),
      ...(request_by_id?.length
        ? { request_by_id: request_by_id?.map((u: any) => u.id) }
        : {}),
      ...(admin_id?.length
        ? { admin_id: admin_id?.map((u: any) => u.id) }
        : {}),
    };
    return MyAssetsAPI.listRequestApproval(paging, body).then(
      (resp) => {
        setPagination(resp?.data?.data?.pagination);
        return resp;
      },
      (err) => {
        return err;
      }
    );
  };

  const transformer = (res: any) => {
    return res?.data?.data?.asset ?? [];
  };

  const onChangeFilter = (value: any) => {
    setFilterBody({
      ...filterBody,
      ...value,
    });
  };

  const refreshTable = () => {
    tableRef?.current?.refresh();
  };

  useUpdateEffect(() => {
    refreshTable();
  }, [status, filterBody?.type_of_request, filterBody?.execution_status]);

  // useUpdateEffect(() => {
  //   refreshTable();
  // }, [filterBody?.type_of_request, filterBody?.execution_status]);

  return (
    <div className="bg-white p-3">
      <div className="flex items-center mb-3">
        <h5 className="flex-1 flex items-center">
          {t(status as string)} {t("approval")}
          <span className="ml-1 bg-red-500 text-white min-w-[1rem] h-4 text-xs flex items-center justify-center">
            {pagination?.items}
          </span>
        </h5>
      </div>
      <RequestAssetFilters
        filterBody={filterBody}
        onChangeFilter={onChangeFilter}
        resetFilter={() => {
          setFilterBody({});
          setSearchValue("");
        }}
        refetch={refreshTable}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
      <AwesomeTableComponent
        ref={(ref) => {
          tableRef.current = ref;
        }}
        transformer={transformer}
        source={source}
        columns={columns}
        className="mt-3"
      />
    </div>
  );
};

export default RequestApproval;
