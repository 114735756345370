import { Select, SelectProps } from "d-react-components";
import React, { useState } from "react";

export interface SelectFilterProps {
  tagTitle: string;
}

const SelectFilter: React.FC<Partial<SelectProps> & SelectFilterProps> = (
  props
) => {
  const { value, tagTitle, onDropdownVisibleChange } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  return (
    <Select
      {...props}
      tagRender={(props) => {
        return props.value === value?.[0] && !dropdownOpen ? (
          <div className="flex items-center">
            {tagTitle}{" "}
            <span className="text-white bg-primary ml-1 w-4 h-4 rounded-full inline-block text-center text-xs">
              {value.length}
            </span>
          </div>
        ) : (
          <></>
        );
      }}
      onDropdownVisibleChange={(open) => {
        setDropdownOpen(open);
        onDropdownVisibleChange && onDropdownVisibleChange(open);
      }}
    />
  );
};

export default SelectFilter;
