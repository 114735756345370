import CallCenterMain from "@components/callcenter/CallCenterMain";
import { PUBLIC_PATHS } from "@components/layout/Path";
import FullScreenButton from "@components/shared/FullScreenButton";
import { ADMIN_AUTH_KEY, ADMIN_LANG_KEY } from "@constants";
import AppContext from "@helpers/context";
import { CallCenterInfo, IMenu } from "@interfaces/common";
import { getAdminProfile } from "@network/api/auth";
import { getListMenu, updateFirebaseToken } from "@network/api/common";
import { Notifications } from "d-react-components";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter } from "react-router-dom";
import { useLocalStorage } from "react-use";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { create } from "zustand";
import { Layout } from "./components/layout/Layout";
import Firebase, { firebaseKey } from "./firebase/firebase";
import InitComponent from "./InitComponent";
import "./styles/tailwind.css";
import "./styles/style.scss";
import UserInfoModal from "@components/shared/UserInfoModal";

export const useRootStore = create<{
  userModalId: string | undefined;
  setUserModalId: (id: string | undefined) => void;
}>((set) => ({
  userModalId: undefined,
  setUserModalId: (id) => set({ userModalId: id }),
}));

function App() {
  const [fullScreen, setFullScreen] = useState(false);
  const [admin, setAdminInfo] = useLocalStorage<any>(ADMIN_AUTH_KEY, {});
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [lang, setLang] = useLocalStorage<string>(ADMIN_LANG_KEY, "en");
  const [navBarHeight, setNavBarHeight] = useState(0);
  const [currentLang, setCurrentLang] = useState(lang);
  const [menus, setMenus] = useState<IMenu[]>([]);
  const [callCenterInfo, setCallCenterInfo] = useState<CallCenterInfo>();
  const [callCenterTokenReady, setCallCenterTokenReady] =
    useState<boolean>(false);

  const getAdminInfo = async () => {
    const adminProfileRes = await getAdminProfile();
    const adminProfile = adminProfileRes?.data?.data?.profile ?? {};
    if (!isEmpty(adminProfile)) {
      setIsAuthenticated(true);
      setAdminInfo(adminProfile);
      if (isEmpty(admin)) {
        setTimeout(() => {
          window.location.reload();
        }, 100);
      }
    }
  };

  const getMenu = async () => {
    const menuRes = await getListMenu();
    setMenus(menuRes?.data?.data?.menu ?? []);
  };

  const sendTokenToServer = (token: string) => {
    updateFirebaseToken(token).then((res) => {
      console.log("send token success", token);
    });
  };

  const onLoadMessagesFirebase = () => {
    if (!Firebase.messaging.isSupported()) {
      return;
    }
    const messaging = Firebase.messaging();
    messaging.usePublicVapidKey(firebaseKey);

    messaging
      .requestPermission()
      .then(() => {
        return messaging.getToken();
      })
      .then((currentToken: any) => {
        if (currentToken) {
          sendTokenToServer(currentToken);
        }
      });

    messaging.onTokenRefresh(() => {
      messaging.getToken().then((tokenRefresh: string) => {
        sendTokenToServer(tokenRefresh);
      });
    });

    messaging.onMessage((payload: any) => {
      const noti = payload?.notification ?? [];
      const url = payload?.notification?.click_action ?? "";
      if (!isEmpty(noti)) {
        Notifications.showInfo(payload?.notification?.body, () =>
          window.open(url, "_self")
        );
      }
    });
  };

  useEffect(() => {
    if (!PUBLIC_PATHS.includes(window.location.pathname)) {
      getAdminInfo();
      getMenu();
      onLoadMessagesFirebase();
    }
  }, []);

  useEffect(() => {
    if (currentLang === "en") {
      document.body.classList.add("body-en");
      document.body.classList.remove("body-th");
    } else {
      document.body.classList.add("body-th");
      document.body.classList.remove("body-en");
    }
  }, [currentLang]);

  return (
    <BrowserRouter>
      <Helmet>
        <script
          src={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_GOOGLE_KEY}&libraries=places`}
        ></script>
      </Helmet>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <AppContext.Provider
          value={{
            fullScreen,
            setFullScreen,
            navBarHeight,
            setNavBarHeight,
            currentLang,
            setCurrentLang,
            menus,
            callCenterInfo,
            setCallCenterInfo,
            callCenterTokenReady,
            setCallCenterTokenReady,
          }}
        >
          <div className={`bg-gray-100 min-h-screen`}>
            <InitComponent />
            <Layout />
          </div>
          <FullScreenButton
            {...{
              fullScreen,
              setFullScreen,
            }}
          />
          {isAuthenticated && <CallCenterMain />}
          <UserInfoModal />
        </AppContext.Provider>
      </QueryParamProvider>
    </BrowserRouter>
  );
}

export default App;
