import AppContext from "@helpers/context";
import { CallEvent } from "@interfaces/common";
import React, { useState, useCallback, useEffect, useContext } from "react";
import io from "socket.io-client";

const WEB_SOCKET_URL = "https://crm.lotusbeddinggroup.com";
const socket = io(WEB_SOCKET_URL);

const WebSocket = ({ onCallComing }: { onCallComing: any }) => {
  const { callCenterInfo } = useContext(AppContext);

  useEffect(() => {
    if (callCenterInfo?.call_center_token) {
      socket.io.opts.extraHeaders = {
        Authorization: `Bearer ${callCenterInfo?.call_center_token}`,
      };
      socket.disconnect().connect();
    }
  }, [callCenterInfo?.call_center_token]);

  useEffect(() => {
    socket.on("connect", () => {
      console.log("connected");
    });
    socket.on("call", (event: CallEvent) => {
      console.log("call", event);
      // event.destination
      onCallComing(event);
    });
    return () => {
      socket.disconnect();
    };
  }, []);
  return <></>;
};

export default WebSocket;
