import { Path } from "@components/layout/Path";
import AppContext from "@helpers/context";
import MyAssetsAPI from "@network/api/my-assets";
import {
  AwesomeTableComponent,
  Button,
  InputTextSearch,
} from "d-react-components";
import React, { useContext, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";

const MyAssetsRole = () => {
  const [searchValue, setSearchValue] = useState();
  const { summary } = useContext(AppContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tableRef = useRef<any>();

  const columns = useMemo(
    () => [
      {
        title: t("name"),
        dataIndex: "name",
      },
      {
        title: t("code"),
        dataIndex: "code",
      },
    ],
    [t]
  );

  const source = (paging: any) => {
    const body = {
      search: searchValue,
      action: "in",
    };
    return MyAssetsAPI.role(paging, body);
  };

  const transformer = (res: any) => {
    return res?.data?.data?.roles ?? [];
  };

  const submitSearch = () => {
    tableRef?.current?.refresh();
  };

  return (
    <div className="bg-white p-3">
      <div className="flex items-center mb-3 mt-2">
        <div className="flex flex-1 items-center">
          <h5 className="">{t("role")}</h5>
          <span className="ml-1 bg-red-500 text-white min-w-[1rem] h-4 text-xs flex items-center justify-center">
            {summary?.role}
          </span>
        </div>
      </div>
      <div className="mb-3">
        <InputTextSearch
          className="bg-white"
          placeholder={t("search")}
          value={searchValue}
          onChange={(e: any) => setSearchValue(e?.target?.value)}
          onSubmit={() => submitSearch()}
        />
      </div>
      <AwesomeTableComponent
        ref={(ref) => {
          tableRef.current = ref;
        }}
        transformer={transformer}
        source={source}
        columns={columns}
        className="mt-3"
      />
    </div>
  );
};

export default MyAssetsRole;
