import { getAttributeFromCategory } from "@network/api/order";
import { Button, Select, StringUtils, usePrevious } from "d-react-components";
import { filter, find, map, some } from "lodash";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

interface IProductInput {
  productForm: any;
  className?: any;
}

export const getAttributeDefault = () => {
  return {
    id: null,
    value: null,
    rowId: StringUtils.getUniqueID(),
  };
};

const AttributeInputItem = ({
  attribute,
  onRemove,
  onChange,
  attributeDataList,
  attributeList,
}: any) => {
  const valuesList = useMemo(() => {
    const attributeData = find(
      attributeDataList,
      (item) => item.id === attribute.id
    );
    return attributeData?.values ?? [];
  }, [attribute, attributeDataList]);

  const attributeSelectList = useMemo(() => {
    return filter(attributeDataList, (dataItem) => {
      if (dataItem.id === attribute.id) return true;
      const isExistInAttribute = some(
        attributeList,
        (item) => dataItem.id === item.id
      );
      return !isExistInAttribute;
    });
  }, [attributeDataList, attributeList]);

  return (
    <div className="row mt-3">
      <Select
        className="col-5"
        onChange={(id) =>
          onChange({
            ...attribute,
            id,
          })
        }
        value={attribute?.id}
        dataSource={attributeSelectList}
        getLabel={(item) => item.name}
      />
      <Select
        className="col-5"
        onChange={(id) => {
          onChange({ ...attribute, value: id });
        }}
        value={attribute?.value}
        dataSource={valuesList}
        getLabel={(item) => item.name}
      />
      <Button
        iconName="delete"
        variant="trans"
        onClick={() => onRemove(attribute)}
        className="col-2"
      />
    </div>
  );
};

const ProductInputAttribute = ({ productForm, className }: IProductInput) => {
  const { t } = useTranslation();

  const {
    attributeList = [],
    category,
    attributeDataList = [],
  } = productForm.values;
  const preCategory: any = usePrevious(category);

  useEffect(() => {
    if (
      preCategory &&
      preCategory.id !== "placeholder" &&
      preCategory.id !== category.id
    ) {
      productForm.setFieldValue("attributeList", []);
    }
    onLoadAttributes();
  }, [category]);

  const isShowAddMore = useMemo(() => {
    return attributeList.length !== attributeDataList.length;
  }, [attributeList, attributeDataList]);

  const onLoadAttributes = () => {
    if (!category || category.id === "placeholder") return;
    const body = { category: category?.id };
    getAttributeFromCategory(body).then((res) => {
      const attributeDataList = res?.data?.data?.attribute ?? [];
      productForm.setFieldValue("attributeDataList", attributeDataList);
    });
  };

  const onClickAddMore = () => {
    productForm.setFieldValue("attributeList", [
      ...attributeList,
      getAttributeDefault(),
    ]);
  };

  const onRemoveAttribute = (attributeItem: any) => {
    const resultList = filter(
      attributeList,
      (item) => item.rowId !== attributeItem.rowId
    );
    productForm.setFieldValue("attributeList", resultList);
  };

  const onChangeAttribute = (attribute: any) => {
    const resultList = map(attributeList, (item) => {
      if (item.rowId === attribute.rowId) {
        return attribute;
      }

      return item;
    });
    productForm.setFieldValue("attributeList", resultList);
  };

  return (
    <div className={className}>
      <div className="flex-column">
        {map(attributeList, (item) => (
          <AttributeInputItem
            attribute={item}
            onRemove={onRemoveAttribute}
            onChange={onChangeAttribute}
            attributeDataList={attributeDataList}
            attributeList={attributeList}
          />
        ))}
      </div>

      {isShowAddMore && (
        <div
          className="cursor-pointer mt-3 text-primary"
          onClick={onClickAddMore}
        >
          {t("addMore")}
        </div>
      )}
    </div>
  );
};

export default ProductInputAttribute;
