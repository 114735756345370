import ProvinceSelect from "@components/address/components/ProvinceSelect";
import ChannelSelect from "@components/channel/components/ChannelSelect";
import { YES_NO_OPTIONS } from "@constants/common";
import { STORE_TYPE_LIST } from "@constants/store";
import { WAREHOUSE_STATUSES } from "@constants/warehouse";
import { getListChain } from "@network/api/chain";
import { Select } from "d-react-components";
import { filter } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import WarehouseSelect from "../components/WarehouseSelect";
import NavSelect from "@components/delivery-so/components/NavSelect";
import { getAllChannel } from "@network/api/channel";

const WarehouseFilterForm = ({ form }: any) => {
  const [chainList, setChainList] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    loadChainList();
  }, []);

  const loadChainList = () => {
    getListChain().then((res) => {
      const chainList = res?.data?.data?.chains ?? [];
      setChainList(chainList);
    });
  };

  const formValue = form.values;

  return (
    <div className="flex-column">
      <Select
        dataSource={chainList}
        value={formValue.chain}
        onChange={(value) => form.setFieldValue("chain", value)}
        label={t("chain")}
        className="mt-3"
        getLabel={(item) => item.name}
        multiple
      />
      <ChannelSelect
        className="mt-3"
        value={formValue.channel}
        onChange={(valueId: any) => form.setFieldValue("channel", valueId)}
        label={t("channel")}
        multiple
        chainIds={formValue.chain}
      />

      <WarehouseSelect
        value={formValue.warehouseDelivery}
        onChange={(value) => form.setFieldValue("warehouseDelivery", value)}
        className="mt-3"
        label={t("warehouseDelivery")}
        multiple
      />
      <Select
        dataSource={filter(STORE_TYPE_LIST, (item) => item.id !== "all")}
        value={formValue.storeType}
        onChange={(value) => form.setFieldValue("storeType", value)}
        label={t("storeType")}
        className="mt-3"
        getLabel={(item) => t(item.label)}
      />
      <NavSelect
        className="mt-3"
        value={form?.values?.nav}
        onChange={(value) => {
          form.setFieldValue("nav", value);
        }}
        multiple
      />

      <Select
        dataSource={YES_NO_OPTIONS}
        value={formValue.stockAdjust}
        onChange={(value) => form.setFieldValue("stockAdjust", value)}
        label={t("stockAdjust")}
        className="mt-3"
      />

      <Select
        dataSource={WAREHOUSE_STATUSES}
        value={formValue.status}
        onChange={(value) => form.setFieldValue("status", value)}
        label={t("status")}
        className="mt-3"
        getLabel={(item) => t(item.label)}
      />

      <ProvinceSelect
        value={formValue.province}
        onChange={(value) => form.setFieldValue("province", value)}
        className="mt-3"
        multiple
      />
    </div>
  );
};

export default WarehouseFilterForm;
