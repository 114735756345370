import ImageIcon from "@common/Icon";
import Drawer from "@components/shared/Drawer";
import {
  CHANNEL_GENERAL_KEYS,
  CHANNEL_PDF_CONTRACT_KEYS,
  CHANNEL_SIGNATURE_KEYS,
} from "@constants/channel";
import AppContext from "@helpers/context";
import { IChannel } from "@interfaces/channel";
import ChannelAPI from "@network/api/channel";
import {
  IRowsKey,
  Notifications,
  ObjectUtils,
  Progress,
  ViewRowInterchange,
} from "d-react-components";
import { useFormik } from "formik";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ChannelFormPdfContract from "../components/ChannelFormPdfContract";
import ChannelFormSignature from "../components/ChannelFormSignature";
import { useParams } from "react-router-dom";
import ChannelFormTerm from "../components/ChannelFormTerm";

function ChannelDetailPdfContractUpdateDrawer({
  open,
  onClose,
  defaultValue,
  onUpdateSuccess,
}: any) {
  const { t } = useTranslation();
  const { channelId } = useParams();

  const generalForm = useFormik({
    initialValues: {
      pdfColorPrimary: defaultValue.pdfColorPrimary,
    },
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: ChannelGeneralSchema,
    onSubmit: (values) => {
      const formValue: any = ObjectUtils.mapFieldsLangsCTS(
        values,
        CHANNEL_PDF_CONTRACT_KEYS as any
      );

      const body = { id: channelId, ...formValue };
      Progress.show({ method: ChannelAPI.update, params: [body] }, () => {
        Notifications.showSuccess(t("updateChannelSuccess"));
        onUpdateSuccess();
        onClose();
      });
    },
  });
  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("updateChannel")}
      onSave={() => generalForm.handleSubmit()}
      size="auto"
      width="30vw"
    >
      <ChannelFormPdfContract
        defaultValue={defaultValue}
        form={generalForm}
        fieldClassName="col-12"
      />
    </Drawer>
  );
}
function ChannelDetailSignatureUpdateDrawer({
  open,
  onClose,
  defaultValue,
  onUpdateSuccess,
}: any) {
  const { t } = useTranslation();
  const { channelId } = useParams();

  const generalForm = useFormik({
    initialValues: {
      lessorName: defaultValue.lessorName,
      witnessName: defaultValue.witnessName,
    },
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: ChannelGeneralSchema,
    onSubmit: (values) => {
      const formValue: any = ObjectUtils.mapFieldsLangsCTS(
        values,
        CHANNEL_SIGNATURE_KEYS as any
      );

      const body = { id: channelId, ...formValue };
      Progress.show({ method: ChannelAPI.update, params: [body] }, () => {
        Notifications.showSuccess(t("updateChannelSuccess"));
        onUpdateSuccess();
        onClose();
      });
    },
  });
  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("updateChannel")}
      onSave={() => generalForm.handleSubmit()}
      size="auto"
      width="30vw"
    >
      <ChannelFormSignature
        defaultValue={defaultValue}
        form={generalForm}
        fieldClassName="col-12"
      />
    </Drawer>
  );
}

function ChannelDetailTermUpdateDrawer({
  open,
  onClose,
  channelId,
  defaultValue,
  onUpdateSuccess,
}: any) {
  const { t } = useTranslation();

  const generalForm = useFormik({
    initialValues: defaultValue ?? {},
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: ChannelGeneralSchema,
    onSubmit: (values) => {
      const formValue: any = ObjectUtils.mapFieldsLangsCTS(
        values,
        CHANNEL_GENERAL_KEYS as any
      );
      const body = { id: channelId, ...formValue };
      Progress.show({ method: ChannelAPI.update, params: [body] }, () => {
        Notifications.showSuccess(t("updateChannelSuccess"));
        onUpdateSuccess();
        onClose();
      });
    },
  });
  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("updateChannel")}
      onSave={() => generalForm.handleSubmit()}
      size="auto"
      width="60vw"
    >
      <ChannelFormTerm
        form={generalForm}
        fieldClassName="col-12"
        keyData={"terms_and_conditions_of_rent"}
      />
    </Drawer>
  );
}

const ChannelDetailSubscription = () => {
  const { channel, loadChannelDetail } = useContext(AppContext);
  const [openUpdateContract, setOpenUpdateContract] = useState(false);
  const [openUpdateSignature, setOpenUpdateSignature] = useState(false);
  const [openUpdateTerm, setOpenUpdateTerm] = useState(false);
  const { t } = useTranslation();

  const { id } = channel ?? {};

  const renderImage = (url: string) => {
    return (
      <div className="d-flex justify-content-end">
        <img
          className="image-reg-small"
          src={url ?? "/images/img-default.png"}
        />
      </div>
    );
  };

  const CONTRACT_KEY = useMemo<IRowsKey<IChannel>[]>(
    () => [
      {
        id: "color_pdf_of_rent",
        label: t("primaryColor"),
        renderContent: ({ data }) => {
          return (
            <div className="flex-row justify-content-end align-items-center">
              <div
                style={{
                  backgroundColor: data,
                  height: 15,
                  width: 15,
                  marginRight: 3,
                }}
              />
              {data}
            </div>
          );
        },
      },
      {
        id: "logo_pdf_of_rent",
        label: t("logo"),
        renderContent: ({ data }) => {
          return renderImage(data);
        },
      },
      {
        id: "icon_header_pdf_of_rent",
        label: t("iconHeader"),
        renderContent: ({ data }) => {
          return renderImage(data);
        },
      },
      {
        id: "icon_pdf_of_rent",
        label: t("footerIcon"),
        renderContent: ({ data }) => {
          return renderImage(data);
        },
      },
      {
        id: "background_header_pdf_of_rent",
        label: t("backgroundHeader"),
        renderContent: ({ data }) => {
          return renderImage(data);
        },
      },
    ],
    [t]
  );

  const SIGNATURE_KEY = useMemo<IRowsKey<IChannel>[]>(
    () => [
      {
        id: "signature_of_rent",
        label: t("lessorSignature"),
        renderContent: ({ data }) => {
          return renderImage(data);
        },
      },
      {
        id: "signature_name_of_rent",
        label: t("lessorName"),
      },
      {
        id: "witness_one_signature_of_rent",
        label: t("witnessSignature"),
        renderContent: ({ data }) => {
          return renderImage(data);
        },
      },
      {
        id: "witness_one_signature_name_of_rent",
        label: t("witnessName"),
      },
    ],
    [t]
  );

  return (
    <div>
      <div className="border mt-3 p-4">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <h5>{t("contractPDF")}</h5>
          <span onClick={() => setOpenUpdateContract(true)}>
            <ImageIcon
              className="w-[15px] cursor-pointer"
              src="/images/icons/edit.svg"
            />
          </span>
        </div>
        <ViewRowInterchange
          className="w-100"
          dataSource={channel}
          keyList={CONTRACT_KEY as any}
          variant="border"
          classNameLabel="font-weight-bold "
          classNameContent="text-end "
        />
        {openUpdateContract && (
          <ChannelDetailPdfContractUpdateDrawer
            open={openUpdateContract}
            onClose={() => setOpenUpdateContract(false)}
            defaultValue={ObjectUtils.mapFieldsLangsSTC(
              channel ?? {},
              CHANNEL_PDF_CONTRACT_KEYS as any
            )}
            onUpdateSuccess={loadChannelDetail}
          />
        )}
      </div>
      <div className="border mt-3 p-4">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <h5>{t("signature")}</h5>
          <span onClick={() => setOpenUpdateSignature(true)}>
            <ImageIcon
              className="w-[15px] cursor-pointer"
              src="/images/icons/edit.svg"
            />
          </span>
        </div>
        <ViewRowInterchange
          className="w-100"
          dataSource={channel}
          keyList={SIGNATURE_KEY as any}
          variant="border"
          classNameLabel="font-weight-bold "
          classNameContent="text-end "
        />
        {openUpdateSignature && (
          <ChannelDetailSignatureUpdateDrawer
            open={openUpdateSignature}
            onClose={() => setOpenUpdateSignature(false)}
            defaultValue={ObjectUtils.mapFieldsLangsSTC(
              channel ?? {},
              CHANNEL_SIGNATURE_KEYS as any
            )}
            onUpdateSuccess={loadChannelDetail}
          />
        )}
      </div>
      <div className="border mt-3 p-4">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <h5>{t("termAndCondition")}</h5>
          <span onClick={() => setOpenUpdateTerm(true)}>
            <ImageIcon
              className="w-[15px] cursor-pointer"
              src="/images/icons/edit.svg"
            />
          </span>
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: channel?.terms_and_conditions_of_rent,
          }}
        />
        {openUpdateTerm && (
          <ChannelDetailTermUpdateDrawer
            open={openUpdateTerm}
            onClose={() => setOpenUpdateTerm(false)}
            channelId={id}
            defaultValue={ObjectUtils.mapFieldsLangsSTC(
              channel ?? {},
              CHANNEL_GENERAL_KEYS as any
            )}
            onUpdateSuccess={loadChannelDetail}
          />
        )}
      </div>
    </div>
  );
};

export default ChannelDetailSubscription;
