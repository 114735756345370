import React from "react";
import { getFullName } from "@helpers/string";
import { TYPE_OF_PLACE } from "@constants/common";
import { useTranslation } from "react-i18next";

const getPlaceText = (place: string) => {
  return TYPE_OF_PLACE[place];
};
const ShippingInfoView = ({ shipping, containeClassName = "" }: any) => {
  const { t } = useTranslation();
  if (!shipping) {
    return <div />;
  }
  return (
    <>
      <div className={containeClassName}>
        <div className="subTitle1">
          {getFullName(shipping) +
            (shipping?.nickname ? ` (${shipping.nickname})` : "")}
        </div>
        <div className="subTitle1">{shipping?.phone}</div>
        <div className="subTitle1">{shipping?.address}</div>
        <div className="subTitle1">{shipping?.subdistrict?.name}</div>
        <div className="subTitle1">{shipping?.city?.name}</div>
        <div className="subTitle1">{shipping?.province?.name}</div>
        <div className="subTitle1">{shipping?.postcode}</div>
        <div className="subTitle1">{t(getPlaceText(shipping?.place))}</div>
      </div>
      {shipping?.note && (
        <div className="bg-blue-100 px-3 py-2 mt-3 arrow-up border-b-blue-100 relative">{`${shipping.note}`}</div>
      )}
    </>
  );
};

export default ShippingInfoView;
