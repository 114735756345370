import {
  AwesomeTableComponent,
  IColumnsProps,
  Icon,
  Modal,
  TimeUtils,
  ViewLabelStatus,
} from "d-react-components";
import { find } from "lodash";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CALL_STATUSES, CALL_TYPES, CALL_TYPES_OPTIONS } from "@constants/call";
import {
  getLogCalls,
  getOrderCallHistory,
  searchCalls,
} from "@network/api/callcenter";
import AppContext from "@helpers/context";
import moment from "moment";
import { CallLog } from "@interfaces/common";
import { Popover, Spin } from "antd";
import { StyledTable } from "../CallingPopup";
import Drawer from "@components/shared/Drawer";

export const RecentCalls = () => {
  const { t } = useTranslation();
  const tableRef = useRef<any>(null);
  const { callerInfo } = useContext(AppContext);
  const [showLogPopup, setShowLogPopup] = useState();

  const columns: IColumnsProps = useMemo(
    () => [
      {
        title: t("createdAt"),
        dataIndex: "created",
        render: (data) => {
          return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
        },
      },
      {
        title: t("id"),
        dataIndex: "callNo",
        render: (data, item) => {
          return <a onClick={() => setShowLogPopup(item)}>{data}</a>;
        },
      },
      {
        title: t("type"),
        dataIndex: "type",
        render: (data) => {
          const callType = find(CALL_TYPES_OPTIONS, { value: data });
          return callType ? t(callType.label) : "";
        },
      },
      {
        title: t("duration"),
        dataIndex: "duration",
        render: (data) => `${moment(data).format("m[m]s[s]")}`,
      },
    ],
    [t]
  );

  const source = async ({ pageSize, pageIndex }: any) => {
    return searchCalls({
      search: callerInfo?.phone,
      page: pageIndex,
      limit: pageSize,
    });
  };

  return (
    <>
      <div className="flex mb-3">
        <div className="flex-1">
          <h4>{t("recentCalls")}</h4>
        </div>
      </div>
      <AwesomeTableComponent
        source={source}
        transformer={(response) => {
          return response?.data?.data?.call ?? [];
        }}
        columns={columns}
        ref={tableRef}
      />
      {showLogPopup && (
        <CallLogPopup
          open={showLogPopup}
          onClose={() => setShowLogPopup(undefined)}
          call={showLogPopup}
        />
      )}
    </>
  );
};

export const CallLogPopup = ({
  open,
  onClose,
  call,
  orderId,
}: {
  call: CallLog;
  open: boolean;
  onClose: any;
  orderId?: string;
}) => {
  const { t } = useTranslation();
  const tableRef = useRef<any>(null);
  const [expandedRowKeys, setExpandedRowKeys] = useState<any[]>([call.id]);

  const columns: IColumnsProps = useMemo(() => {
    return [
      {
        title: t("ref"),
        dataIndex: "callNo",
      },
      {
        title: t("type"),
        dataIndex: "type",
        render: (data) => t(CALL_TYPES[data]?.content),
      },
      {
        title: t("startTime"),
        dataIndex: "startTime",
        render: (data) => {
          return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
        },
      },
      {
        title: t("to"),
        dataIndex: "destination",
      },
      {
        title: t("totalDuration"),
        dataIndex: "duration",
        render: (duration, item) => {
          return (
            <>
              {`${moment(moment().startOf("d").add(duration, "s")).format(
                "m[m]s[s]"
              )}`}
            </>
          );
        },
      },
    ];
  }, [t]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("callLog")}
      width="1200px"
      size="auto"
    >
      <AwesomeTableComponent
        dataSource={[call]}
        transformer={(response) => response}
        expandable={{
          expandedRowRender: (record) => (
            <ExpandedTable call={record} orderId={orderId} />
          ),
          rowExpandable: () => true,
          expandedRowKeys,
          onExpandedRowsChange: (items: any) => setExpandedRowKeys(items),
        }}
        columns={columns}
        ref={tableRef}
        rowKey={(item) => item.id}
      />
    </Drawer>
  );
};

const ExpandedTable = ({ call, orderId }: any) => {
  const { t } = useTranslation();
  const tableRef = useRef<any>(null);
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const columns: IColumnsProps = useMemo(() => {
    return [
      {
        title: t("loguuid"),
        dataIndex: "org_uuid",
      },
      {
        title: t("startTime"),
        dataIndex: "start",
        render: (data) => {
          return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
        },
      },
      {
        title: t("endTime"),
        dataIndex: "end",
        render: (data) => {
          return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
        },
      },
      {
        title: t("participant"),
        dataIndex: "from_label",
        render: (data) => <div className="flex items-center">{data}</div>,
      },
      {
        title: t("status"),
        dataIndex: "status",
        render: (data) => {
          const status = CALL_STATUSES[data];
          return (
            <ViewLabelStatus
              content={t(`${status?.content}`)}
              color={status?.color}
            />
          );
        },
      },
      {
        title: t("recording"),
        dataIndex: "url_recording",
        render: (data) => {
          return data ? (
            <div className="flex items-center">
              <Popover
                content={
                  <>
                    <audio controls>
                      <source src={data}></source>
                    </audio>
                  </>
                }
                trigger="click"
              >
                <a>
                  <Icon name="play_arrow" />
                </a>
              </Popover>
              <a href={data} target="_blank" rel="noreferrer">
                <Icon name="download" />
              </a>
            </div>
          ) : null;
        },
      },
    ];
  }, [t]);

  useEffect(() => {
    getOrderCallHistory({
      order_id: orderId,
      order_call_id: call.id,
    })
      .then((resp: any) => {
        setHistory(resp?.data?.data?.history ?? []);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return (
    <StyledTable>
      <div className="relative">
        {loading && (
          <div className="absolute flex items-center justify-content-center w-full z-10 pt-6">
            <Spin />
          </div>
        )}
        <AwesomeTableComponent
          dataSource={history}
          columns={columns}
          ref={tableRef}
          pagination={false}
        />
      </div>
    </StyledTable>
  );
};
