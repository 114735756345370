export const ORDER_STATUS_ARRAY = [
  {
    content: "pending",
    value: "pending",
    color: "#FAC256 ",
    label: "order:pending",
    id: "pending",
  },
  {
    content: "orderProcessing",
    value: "processing",
    color: "#84C4FF",
    label: "order:orderProcessing",
    id: "processing",
  },
  {
    value: "pending-payment-confirmation",
    content: "pendingPaymentConfirm",
    color: "#FAC256",
    label: "order:pendingPaymentConfirm",
    id: "pending-payment-confirmation",
  },
  {
    value: "partially-paid",
    content: "partiallyPaid",
    color: "#FAC256",
    label: "order:partiallyPaid",
    id: "partially-paid",
  },
  {
    content: "deliveryProcessing",
    value: "delivery-processing",
    color: "#29B3BC",
    label: "order:deliveryProcessing",
    id: "delivery-processing",
  },
  {
    content: "delivered",
    value: "delivered",
    color: "#33B950",
    label: "order:delivered",
    id: "delivered",
  },
  {
    content: "completed",
    value: "completed",
    color: "#33B950",
    label: "order:completed",
    id: "completed",
  },
  {
    content: "cancelled",
    value: "cancelled",
    color: "#C4C4C4",
    label: "order:cancelled",
    id: "cancelled",
  },
  {
    content: "refunded",
    value: "refunded",
    color: "#33B950",
    label: "order:refunded",
    id: "refunded",
  },
];

export const FILTER_ORDER_PICKUP = [
  {
    id: "no-pickup",
    value: "no-pickup",
    label: "order:noPickUp",
    color: "#8D8D8D",
  },
  {
    id: "full-pickup",
    value: "full-pickup",
    label: "order:fullPickUp",
    color: "#33B950",
  },
  {
    id: "partially-pickup",
    value: "partially-pickup",
    label: "order:partiallyPickUp",
    color: "#FFBD59",
  },
];

export const ORDER_TABLE_STATUS_TAB = {
  all: {
    label: "order:all",
    content: "all",
    value: "all",
    id: 7,
    color: "#FFBD59 ",
  },
  pending: {
    label: "order:pending",
    content: "pending",
    value: "pending",
    id: 1,
    color: "#FFBD59 ",
  },
  "pending-payment-confirmation": {
    value: "pending-payment-confirmation",
    label: "order:pendingPaymentConfirm",
    content: "pendingPaymentConfirm",
    color: " #FFBD59",
    id: 3,
  },
  "payment-rejected": {
    value: "payment-rejected",
    label: "order:paymentRejected",
    content: "paymentRejected",
    color: " #FFBD59",
    id: 6,
  },
  "partially-paid": {
    value: "partially-paid",
    label: "order:partiallyPaid",
    content: "partiallyPaid",
    color: " #FFBD59",
    id: 4,
  },
  processing: {
    label: "order:orderProcessing",
    content: "orderProcessing",
    value: "processing",
    color: "#FFBD59",
    id: 2,
  },
  "delivery-processing": {
    label: "order:deliveryProcessing",
    content: "deliveryProcessing",
    value: "delivery-processing",
    color: " #FFBD59",
    id: 5,
  },
};

export const ORDER_PAYMENT_STATUS: Record<string, any> = {
  pendingPayment: {
    id: "pending",
    label: "order:pendingPayment",
    color: "#FFBD59",
  },
  fullPaymentConfirm: {
    id: "full-payment-confirmed",
    label: "order:fullpaymentconfirm",
    color: "#27AE60",
  },
  fullPaymentReject: {
    id: "payment-fully-rejected",
    label: "order:fullpaymentreject",
    color: "#EB5757",
  },
  partiallyPaymentReject: {
    id: "payment-partially-rejected",
    label: "order:partiallypaymentreject",
    color: "#EB5757",
  },
  partiallyPaymentConfirm: {
    id: "payment-partially-confirmed",
    label: "order:partiallypaymentconfirm",
    color: "#FFBD59",
  },
};

export const ORDER_EXPORT_OPTION = {
  reportNAV: { id: "reportNAV", label: "order:reportNAV" },
  reportNAVModernTrade: {
    id: "reportNAVModernTrade",
    label: "order:reportNAVModernTrade",
  },
  reportByItem: { id: "reportByItem", label: "order:reportByItem" },
  reportByItemShopee: {
    id: "reportByItemShopee",
    label: "order:reportByItemShopee",
  },
  reportByItemLazada: {
    id: "reportByItemLazada",
    label: "order:reportByItemLazada",
  },
  reportByItemForMarketing: {
    id: "reportByItemMarketing",
    label: "order:reportByItemForMarketing",
  },
  reportByPrice: { id: "reportByPrice", label: "order:reportByPrice" },
  reportByPayment: { id: "reportByPayment", label: "order:reportByPayment" },
  reportByShipping: {
    id: "reportByShipping",
    label: "order:reportByShipping",
  },
  reportForPowerBI: { id: "reportForPowerBI", label: "order:reportPowerBI" },
};

export const ORDER_STATUS_STRING: any = {
  pending: "order:pending",
  processing: "order:orderProcessing",
  "pending-payment-confirmation": "order:pendingPaymentConfirm",
  "partially-paid": "order:partiallyPaid",
  "delivery-processing": "order:deliveryProcessing",
  delivered: "order:delivered",
  completed: "order:completed",
  cancelled: "order:cancelled",
  refunded: "order:refunded",
};

export const ORDER_FILTER_SUSPICIOUS_STATUS_LIST = [
  {
    id: "suspicious",
    label: "order:currentlySuspicious",
  },
  {
    id: "used_suspicious",
    label: "order:usedSuspicious",
  },
  {
    id: "no_suspicious",
    label: "order:noSuspicious",
  },
];

export const ORDER_FILTER_CUSTOM_SIZE = [
  {
    id: "custom_size",
    label: "order:pendingCustomSizeApproval",
  },
  {
    id: "used_custom_size",
    label: "order:customSizeApproved",
  },
  {
    id: "no_custom_size",
    label: "order:noCustomSizeDetected",
  },
];

export const PICKUP_TYPE = {
  PARTIALLY: "partially",
  FULL: "full",
  NONE: "none",
};

export const PICKUP_TYPES = [
  { id: "none", value: "none", label: "none", description: "" },
  {
    id: "full",
    value: "full",
    label: "fullPickUp",
    description: "pickUpAtOneOrMore",
  },
  {
    id: "partially",
    value: "partially",
    label: "partiallyPickUp",
    description: "pickUpAndDeliveryLater",
  },
];

export const MT_ORDER_TYPE = {
  NORMAL: "normal_sale",
  EVENT: "event_sale",
};

export const MT_ORDER_TYPES = [
  {
    id: MT_ORDER_TYPE.NORMAL,
    label: "normalSale",
  },
  {
    id: MT_ORDER_TYPE.EVENT,
    label: "eventSale",
  },
];

export const ORDER_STATUS: { [key: string]: any } = {
  pending: {
    content: "order:pending",
    value: "pending",
    color: "#FFBD59 ",
  },
  processing: {
    content: "order:orderProcessing",
    value: "processing",
    color: "#FFBD59",
  },
  pendingPaymentConfirmation: {
    value: "pending-payment-confirmation",
    content: "order:pendingPaymentConfirm",
    color: "#FFBD59",
  },
  partiallyPaid: {
    value: "partially-paid",
    content: "order:partiallyPaid",
    color: "#FFBD59",
  },
  deliveryProcessing: {
    content: "order:deliveryProcessing",
    value: "delivery-processing",
    color: "#FFBD59",
  },
  delivered: {
    content: "order:delivered",
    value: "delivered",
    color: "rgb(39, 174, 96)",
  },
  completed: {
    content: "order:completed",
    value: "completed",
    color: "rgb(34 197 94)",
  },
  cancelled: {
    content: "order:cancelled",
    value: "cancelled",
    color: "#8D8D8D",
  },
  refunded: {
    content: "order:refunded",
    value: "refunded",
    color: "#C51736",
  },
};

export const CART_RULE_ACTION_KEY = {
  freegift: { id: "free_gift", label: "order:buyBundleGetBundleFree" },
  buyXGetBundle: {
    id: "buy_x_get_bundle",
    label: "order:buyXGetBundleFree",
  },
  discountPercentage: {
    id: "discount_percentage",
    label: "order:applyDiscountByPercentage",
  },
  buyXGetX: { id: "buy_x_get_x", label: "orderbuyXGetX" },
  discountFix: {
    id: "discount_fix_amount",
    label: "order:applyDiscountByFixAmount",
  },
  discountPercentageCart: {
    id: "discount_percentage_cart",
    label: "order:discountPercentageCart",
  },
  discountFixAmountCart: {
    id: "discount_fix_amount_cart",
    label: "order:discountFixAmountCart",
  },

  buyNXGetDiscountPercentage: {
    id: "buy_x_get_discount_percentage",
    label: "order:buyNXGetDiscountPercentage",
  },
  buyNXGetDiscountFixAmount: {
    id: "buy_x_get_discount_fix_amount",
    label: "order:buyNXGetDiscountFixAmount",
  },
  buyNXGetDiscountPercentageOnY: {
    id: "buy_x_get_discount_percentage_y",
    label: "order:buyNXGetDiscountPercentageOnY",
  },
  buyNXGetDiscountFixAmountOnY: {
    id: "buy_x_get_discount_fix_amount_y",
    label: "order:buyNXGetDiscountFixAmountOnY",
  },
};

export const FREE_GIFT_ACTION_KEY = {
  discountPercentage: {
    id: "discount_percentage",
    label: "order:applyDiscountByPercentage",
  },
  discountFix: {
    id: "discount_fix_amount",
    label: "order:applyDiscountByFixAmount",
  },
  discountPercentageCart: {
    id: "discount_percentage_cart",
    label: "order:discountPercentageCart",
  },
  discountFixAmountCart: {
    id: "discount_fix_amount_cart",
    label: "order:discountFixAmountCart",
  },
  freegift: { id: "free_gift", label: "order:buyBundleGetBundleFree" },
  buyXGetBundle: {
    id: "buy_x_get_bundle",
    label: "order:buyXGetBundleFree",
  },
  buyXGetX: { id: "buy_x_get_x", label: "order:buyXGetX" },

  buyNXGetDiscountPercentage: {
    id: "buy_x_get_discount_percentage",
    label: "order:buyNXGetDiscountPercentage",
  },
  buyNXGetDiscountFixAmount: {
    id: "buy_x_get_discount_fix_amount",
    label: "order:buyNXGetDiscountFixAmount",
  },
  buyNXGetDiscountPercentageOnY: {
    id: "buy_x_get_discount_percentage_y",
    label: "order:buyNXGetDiscountPercentageOnY",
  },
  buyNXGetDiscountFixAmountOnY: {
    id: "buy_x_get_discount_fix_amount_y",
    label: "order:buyNXGetDiscountFixAmountOnY",
  },
};

export const ORDER_SOURES = [
  {
    id: "all",
    label: "all",
    value: "all",
  },
  {
    id: "online",
    label: "order:online",
    value: "online",
  },
  {
    id: "offline",
    label: "order:offline",
    value: "offline",
  },
  {
    id: "marketplace",
    label: "order:marketplace",
    value: "marketplace",
  },
  {
    id: "modern_trade",
    label: "order:modern_trade",
    value: "modern_trade",
  },
];

export const ORDER_PICKUP_STATUS_LIST = [
  {
    id: "pending",
    label: "pending",
    color: "#F2C94C",
  },
  {
    id: "completed",
    label: "completed",
    color: "#27AE60",
  },
];

export const ORDER_PICKUP_STATUS = {
  PENDING: "pending",
  COMPLETED: "completed",
};

export const PRINT_MODE = {
  REFUND: "refund",
  ORDER_DETAIL_INTERNAL: "printOrderInternal",
  ORDER_DETAIL_CUSTOMER: "printOrderCustomer",
  ORDER_RECEIPT: "printReceipt",
  ORDER_PICKUP: "printOrderPickUp",
  ORDER_CUSTOM_SIZE: "customSizeOrderPrint",
  ORDER_RECEIPT_CUSTOMER: "printReceiptCustomer",
};

export const ORDER_SUSPICIOUS_STATUS = {
  PENDING: "pending",
  APPROVED: "approved",
};

export const ORDER_CUSTOM_SIZE_STATUS = {
  PENDING: "pending",
  APPROVED: "approved",
};

export const ORDER_STATUS_MENU = [
  { key: "pending", content: "order:pending" },
  {
    key: "pending-payment-confirmation",
    content: "order:pendingPaymentConfirm",
  },
  { key: "partially-paid", content: "order:partiallyPaid" },
  { key: "processing", content: "order:orderProcessing" },
  { key: "delivery-processing", content: "order:deliveryProcessing" },
  { key: "delivered", content: "order:delivered" },
  { key: "completed", content: "order:completed" },
  { key: "refunded", content: "order:refunded" },
  { key: "cancelled", content: "order:cancelled" },
];

export const PRINT_ORDER_OPTION = [
  { id: "printOrderCustomer", label: "order:printOrderCustomer" },
  { id: "printOrderInternal", label: "order:printOrderInternal" },
  { id: "printReceipt", label: "order:printReceiptOffline" },
  { id: "printReceiptCustomer", label: "order:printReceiptCustomer" },
];

export const SHIPPOP_TYPES = {
  thaiEMS: {
    id: "THP",
    label: "order:thaiEMS",
  },
  flashExpress: {
    id: "FLE",
    label: "order:flashExpress",
  },
  dhl: {
    id: "DHL",
    label: "order:DHL",
  },
  fleb: {
    id: "FLEB",
    label: "order:fleb",
  },
};

export const DELIVERY_COURIERS = [
  {
    id: "lotus",
    courier: "lotus",
    name: "order:lotusTruck",
  },
  {
    id: "shippop",
    courier: "shippop",
    name: "order:shippop",
  },
];

export const DELIVERY_COURIER = {
  LOTUS_TRUCK: "lotus",
  SHIPPOP: "shippop",
  SHOPEE: "shopee",
};

export const DELIVERY_STATUS: { [key: string]: any } = {
  pending: "order:deliveryPending",
  booking: "order:deliveryBooked",
  "in-delivery": "order:inDelivery",
  completed: "order:deliveryCompleted",
  cancel: "order:deliveryCancelled",
  return: "order:deliveryReturned",
};

export const DELIVERY_STATUS_LIST = [
  {
    id: "pending",
    key: "pending",
    value: "pending",
    content: "order:deliveryPending",
    label: "order:deliveryPending",
    color: "#FAC256",
  },
  {
    id: "booking",
    key: "booking",
    value: "booking",
    content: "order:deliveryBooked",
    label: "order:deliveryBooked",
    color: "#84C4FF",
  },
  {
    id: "in-delivery",
    key: "in-delivery",
    value: "in-delivery",
    content: "order:inDelivery",
    label: "order:inDelivery",
    color: "#84C4FF",
  },
  {
    id: "completed",
    key: "completed",
    value: "completed",
    content: "order:deliveryCompleted",
    label: "order:deliveryCompleted",
    color: "#33B950",
  },
  {
    id: "cancelled",
    key: "cancel",
    value: "cancel",
    content: "order:deliveryCancelled",
    label: "order:deliveryCancelled",
    color: "#C4C4C4",
  },
  {
    id: "return",
    key: "return",
    value: "return",
    content: "order:deliveryReturned",
    label: "order:deliveryReturned",
    color: "#9B51E0",
  },
];

export const ORDER_SEARCH_TYPE_DEFAULT = "order_id";
export const ORDER_SEARCH_OPTIONS = [
  {
    id: "order_id",
    label: "orderId",
  },
  {
    id: "customer_name",
    label: "customerName",
  },
  {
    id: "customer_phone",
    label: "customerPhone",
  },
  {
    id: "customer_email",
    label: "customerEmail",
  },
  {
    id: "payment_code",
    label: "paymentCode",
  },
  {
    id: "shopee_id",
    label: "shopeeOrderId",
  },
  {
    id: "lazada_id",
    label: "lazadaOrderId",
  },
  {
    id: "woocommerce_id",
    label: "woocommerceOrderId",
  },
];

export const MARKETPLACE = {
  LAZADA: "lazada",
  SHOPEE: "shopee",
};

export const LOAD_ORDER_TYPE = {
  SINGLE: "single_order",
  BULK: "bulk_order",
};

export const MARKETPLACE_TYPES = [
  {
    label: "Lazada",
    value: MARKETPLACE.LAZADA,
  },
  {
    label: "Shopee",
    value: MARKETPLACE.SHOPEE,
  },
];

export const LOAD_ORDER_TYPES = [
  {
    label: "singleOrder",
    value: LOAD_ORDER_TYPE.SINGLE,
  },
  {
    label: "bulkOrder",
    value: LOAD_ORDER_TYPE.BULK,
  },
];

enum LogisticType {
  SHIPPOP = "shippop",
  OWN_FLEET = "ownFleet",
}

export const LOGISTIC_TYPES = [
  {
    label: "ownFleet",
    value: LogisticType.OWN_FLEET,
  },
  {
    label: "shippop",
    value: LogisticType.SHIPPOP,
  },
];

export const MAX_ORDER_VALUE_ALLOW_COD = 10000;
