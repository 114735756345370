import ProductName from "@components/product/components/ProductName";
import QuantityInputField from "@components/product/components/QuantityInputField";
import SearchGroupProducts from "@components/product/components/SearchGroupProducts";
import { PRODUCT_TYPE } from "@constants/product";
import { IProduct } from "@interfaces/product";
import { listProduct, searchProductInWarehouse } from "@network/api/product";
import {
  AwesomeTableComponent,
  IColumnsProps,
  Icon,
  ObjectUtils,
} from "d-react-components";
import { filter, map } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

interface IStockRequestProductForm {
  form: any;
  warehouseId: string;
  className?: any;
}

const StockAdjustmentCreateProduct = ({
  form,
  warehouseId,
  className,
}: IStockRequestProductForm) => {
  const { t } = useTranslation();
  const formValues = form?.values;

  const columns: IColumnsProps = [
    {
      title: t("item"),
      dataIndex: "",
      render: (product) => <ProductName item={{ product }} />,
    },
    {
      title: t("currentQty"),
      dataIndex: "stock",
    },
    {
      title: t("currentQty"),
      dataIndex: "adjustment",
      render: (quantity, item) => (
        <QuantityInputField
          quantity={quantity}
          onChangeQuantity={(quantity: number) => {
            if (quantity < 1) return;
            onChangeQuantity(item, quantity);
          }}
        />
      ),
    },
    {
      title: t("action"),
      dataIndex: "",
      render: (item) => (
        <Icon
          name="delete"
          className="cursor-default"
          onClick={() => onRemoveProduct(item.id)}
        />
      ),
    },
  ];

  const onChangeQuantity = (product: IProduct, quantity: number) => {
    const result = ObjectUtils.updateArrayById(formValues?.products, {
      ...product,
      adjustment: quantity,
    });
    form.setFieldValue("products", result);
  };

  const onRemoveProduct = (id: string) => {
    const result = filter(formValues?.products, (item) => item?.id !== id);
    form.setFieldValue("products", result);
  };

  const onChangeProduct = (list: any[] = []) => {
    const currentIdList = map(formValues?.products, (item) => item?.id);
    const addingList = map(list, (item) => {
      if (currentIdList?.includes(item?.id)) {
        return item;
      }
      return { ...item, adjustment: 1 };
    });

    form.setFieldValue("products", addingList);
  };

  const sourceSearchProduct = (
    pageIndex: number,
    search: any,
    bodyParam: any
  ) => {
    const body = {
      ...bodyParam,
      // warehouseid: warehouseId,
      search,
      total_stock: [1, null],
      product_type: [PRODUCT_TYPE.DEFAULT, PRODUCT_TYPE.CUSTOM_SIZE],
    };
    // return searchProductInWarehouse(body, { pageIndex, pageSize: 20 });
    return listProduct(body, { pageIndex, pageSize: 20 });
  };

  return (
    <div className={className}>
      <div className="flex-row-between-center mb-2">
        <label>{t("items")}</label>
        <SearchGroupProducts
          onChange={onChangeProduct}
          selectedProducts={formValues?.products ?? []}
          stockConsider={false}
          removeColumns={["stock_quantity", "regular_price", "sale_price"]}
          customSource={sourceSearchProduct}
        />
      </div>
      <AwesomeTableComponent
        pagination={false}
        columns={columns}
        dataSource={formValues?.products ?? []}
        className=""
      />
    </div>
  );
};

export default StockAdjustmentCreateProduct;
