import Drawer from "@components/shared/Drawer";
import UserAvatarName from "@components/shared/UserAvatarName";
import { ORDER_STATUS } from "@constants/order";
import {
  PAYMENTS_METHOD,
  PAYMENT_CASH,
  PAYMENT_CONFIRM_STATUS,
  PAYMENT_CONFIRM_STATUSES,
  PAYMENT_DEFAULT,
} from "@constants/payment";
import AppContext from "@helpers/context";
import { isGrantURLPermission } from "@helpers/permissions";
import { getLogsPaymentOrder } from "@network/api/order";
import { API_ORDER_PAYMENT_UPDATE_METHOD } from "@network/URL";
import {
  Button,
  Modal,
  Notifications,
  StringUtils,
  TimeUtils,
  AwesomeListComponent,
} from "d-react-components";
import _, { find, isEmpty, map } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import AccountConfirmButton from "./AccountingConfirmButtons";
import C2PConfirmButtons from "./C2PConfirmButtons";
import ModalChangePaymentMethod from "./ModalChangePaymentMethod";
import ModalUploadPaymentInfo, {
  UploadPaymentInfo,
} from "./ModalUploadPaymentInfo";
import PaymentContainer from "./PaymentContainer";
import UploadPaymentInfoView from "./UploadPaymentInfoView";
import CurrencyFormat from "react-currency-format";
import { getBankFullInfo } from "@helpers/bank";

export const PaymentBy2C2P = ({
  index,
  payload = {},
  onClickChangeMethod,
  isShowChangeMethod,
  onClickUpload,
}: any) => {
  const {
    banks2C2P,
    paymentOptions = [],
    loadPaymentOptions,
  } = useContext(AppContext);
  const { t } = useTranslation();
  const {
    id,
    total,
    status,
    confirm_actual_date_payment,
    payment_code,
    payment_slip = [],
    bank_statement = [],
    confirm_bank_2c2p,
    confirm_payment_transaction_id,
    method,
    isLog,
    operation_2c2p = [],
    payment_option,
    confirm_payment_at,
    confirm_payment_note: comment,
  } = payload;

  const allImages = [...payment_slip, ...bank_statement];
  const [openModalLogs, setOpenModalLogs] = useState(false);
  const isApprove = status === PAYMENT_CONFIRM_STATUS.APPROVE;

  const getTitleText = () => {
    return `${t("payment")} ${index + 1}`;
  };

  const getAmountText = () => {
    return `฿ ${total && total?.toLocaleString()}`;
  };

  const renderLogsItem = (logs: any) => (
    <div className="w-100 py-3">
      {map(_.split(logs, "<br/>"), (text) => (
        <div className="mt-1">{text}</div>
      ))}
      <div className="divider mt-3" />
    </div>
  );

  const source = (paging: any) => {
    return getLogsPaymentOrder(id, paging);
  };

  const renderTooltipFileUpload = (paymentInfo: any) => {
    if (!paymentInfo || !paymentInfo?.transfer_bank_2c2p) {
      return false;
    }
    const {
      transfer_name,
      transfer_date,
      payment_transaction_id,
      comment,
      credit_card_number,
    } = paymentInfo;
    const bankTransfer = paymentInfo?.transfer_bank_2c2p?.id;
    return (
      <UploadPaymentInfo
        isView
        merchantList={banks2C2P}
        paymentMethod={method}
        defaultInfo={{
          fullNameTransfer: transfer_name,
          merchant: bankTransfer,
          transactionId: payment_transaction_id,
          dateTransfer: transfer_date,
          comment,
          paymentOption: payment_option?.id,
          cardLast4Digit: credit_card_number,
        }}
      />
    );
  };

  const getInfoSuccessPayment = () => {
    if (status !== PAYMENT_CONFIRM_STATUS.APPROVE) {
      return <div />;
    }
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {confirm_bank_2c2p && (
          <span style={{ marginBottom: "1rem" }}>
            {`${t("c2pName")} ${t("merchantName")}: ${confirm_bank_2c2p?.name}`}
          </span>
        )}
        {confirm_payment_transaction_id && (
          <span style={{ marginBottom: "1rem" }}>
            {`${t("c2pName")} ${t(
              "transactionId"
            )}: ${confirm_payment_transaction_id}`}
          </span>
        )}
        {isLog && <span>{t("c2pApprovalCode") + payment_code}</span>}
        {confirm_actual_date_payment && (
          <div style={{ marginBottom: "1rem" }}>
            {`${t("actualDatePayment")}: ${TimeUtils.convertMiliToDateTime(
              confirm_actual_date_payment
            )}`}
          </div>
        )}
      </div>
    );
  };

  const renderOperationInfo = () => {
    if (isEmpty(operation_2c2p)) return <div />;
    const classNameText = "text-success text";

    const cardNumber = find(
      operation_2c2p,
      (item) => item.name === "masked_pan"
    );
    const ippAmount = find(operation_2c2p, (item) => item.name === "amount");
    const ippAgent = find(operation_2c2p, (item) => item.name === "paid_agent");
    const ippInterestRate = find(
      operation_2c2p,
      (item) => item.name === "ippInterestRate"
    );
    const ippPeriod = find(operation_2c2p, (item) => item.name === "ippPeriod");

    return (
      <div className="flex-column mt-3">
        <div hidden={!cardNumber?.value} className={classNameText}>{`${t(
          "cardNumber"
        )}: ${cardNumber?.value}`}</div>
        <div hidden={!ippAmount?.value} className={classNameText}>{`${t(
          "ippAmount"
        )}: ${StringUtils.moneyThaiFormat(ippAmount?.value)}`}</div>
        <div hidden={!ippAgent?.value} className={classNameText}>{`${t(
          "ippAgent"
        )}: ${ippAgent?.value}`}</div>
        <div hidden={!ippInterestRate?.value} className={classNameText}>{`${t(
          "ippInterestRate"
        )}: ${ippInterestRate?.value}%`}</div>
        <div hidden={!ippPeriod?.value} className={classNameText}>{`${t(
          "ippPeriod"
        )}: ${ippPeriod?.value} month`}</div>
      </div>
    );
  };

  useEffect(() => {
    if (!isEmpty(payment_option) && !paymentOptions.length) {
      loadPaymentOptions && loadPaymentOptions();
    }
  }, [payment_option]);

  const paymentOptionObj = useMemo(() => {
    return find(paymentOptions, { id: payment_option?.id });
  }, [payment_option, paymentOptions]);

  return (
    <PaymentContainer
      title={getTitleText()}
      amount={getAmountText()}
      method={t("debitBy2C2P")}
      subTitle1={
        <div className="my-1" hidden={!payment_option}>
          <span className="font-weight-bold">{`${t("paymentOptions")}: `}</span>
          {payment_option?.label || paymentOptionObj?.label}
        </div>
      }
      subTitle2={
        status === PAYMENT_CONFIRM_STATUS.APPROVE ? t("successBy2C2P") : null
      }
      subTitle3={getInfoSuccessPayment()}
      renderButtons={<C2PConfirmButtons payment={payload} />}
      onClickChangeMethod={onClickChangeMethod}
      isShowChangeMethod={isShowChangeMethod}
      numberOfInstalment={payload.installment_loan_tenure}
      payment={payload}
    >
      <UploadPaymentInfoView
        paymentInfoList={allImages}
        payment={payload}
        onClickUploadButton={onClickUpload}
        renderTooltip={renderTooltipFileUpload}
        imgClass="w-24 h-24"
      />

      {renderOperationInfo()}

      {isApprove && (
        <div className="text-success">
          {`${comment} ${t("at")} ${TimeUtils.convertMiliToDate(
            confirm_payment_at
          )} `}
        </div>
      )}

      {payload?.status !== "pending" && isLog && (
        <Button
          className="mt-3"
          variant="outline"
          size="x-small"
          onClick={() => setOpenModalLogs(true)}
        >
          {t("view2C2PLog").toLocaleUpperCase()}
        </Button>
      )}

      <Drawer
        open={openModalLogs}
        onClose={() => setOpenModalLogs(false)}
        onSave={() => setOpenModalLogs(false)}
        title={t("view2C2PLog")}
        saveText={t("close")}
      >
        <AwesomeListComponent
          source={(paging) => source(paging)}
          transformer={(respone) => {
            return respone?.data?.data?.logs ?? "";
          }}
          renderItem={(item) => renderLogsItem(item.logs)}
        />
      </Drawer>
    </PaymentContainer>
  );
};

export default function PaymentCustomerUpdated({ payload, index }: any) {
  const {
    method,
    total,
    payment_code,
    payment_slip = [],
    bank_statement = [],
    confirm_actual_date_payment = null,
    confirm_bank,
    status,
    confirm_payment_at,
    confirm_payment_by,
    confirm_payment_note: comment,
    confirm_name_of_payor,
    confirm_credit_card_number,
    bank_fee,
  } = payload;
  const { t } = useTranslation();
  const { onReloadOrderData, order, banks, isPublic } = useContext(AppContext);
  const [openChangeMethod, setOpenChangeMethod] = useState(false);
  const { paymentMethodsList } = useContext(AppContext);
  const isReject = status === PAYMENT_CONFIRM_STATUS.REJECTED;
  const isApprove = status === PAYMENT_CONFIRM_STATUS.APPROVE;

  const paymentMethod = useMemo(
    () =>
      find(paymentMethodsList, (item) => item.id === method) ?? PAYMENT_DEFAULT,
    [method, paymentMethodsList]
  );

  const allImages = [...payment_slip, ...bank_statement];

  const [openModalUpload, setOpenModalUpload] = useState(false);

  const renderTooltipFileUpload = (paymentInfo: any) => {
    if (!paymentInfo || _.isEmpty(paymentInfo?.transfer_name)) {
      return false;
    }
    const bankTransfer = paymentInfo?.transfer_bank?.id;
    const dateTransfer = paymentInfo?.transfer_date;
    const comment = paymentInfo?.comment;
    return (
      <UploadPaymentInfo
        isView
        banks={banks}
        paymentMethod={method}
        defaultInfo={{
          fullNameTransfer: paymentInfo?.transfer_name,
          bankTransfer,
          dateTransfer,
          comment,
          numberInstalment: payload?.installment_loan_tenure,
          cardLast4Digit: paymentInfo?.credit_card_number,
        }}
      />
    );
  };

  const isShowChangePaymentMethod = () => {
    const isReject = status === PAYMENT_CONFIRM_STATUS.REJECTED;
    const isApprove = status === PAYMENT_CONFIRM_STATUS.APPROVE;

    if (isApprove || isReject || isPublic) {
      return false;
    }

    if (
      order.status &&
      order.status !== ORDER_STATUS.pending.value &&
      order.status !== ORDER_STATUS.pendingPaymentConfirmation.value &&
      order.status !== ORDER_STATUS.partiallyPaid.value
    ) {
      return false;
    }

    if (!isGrantURLPermission(API_ORDER_PAYMENT_UPDATE_METHOD)) {
      return false;
    }

    return true;
  };

  const renderPaymentByBankTransfer = () => {
    const orderStatus = find(PAYMENT_CONFIRM_STATUSES, {
      value: payload?.status,
    });
    return (
      <PaymentContainer
        title={`${t("payment")} ${index ? index + 1 : ""}`}
        amount={`฿${total && total?.toLocaleString()}`}
        method={paymentMethod?.label}
        transactionId={payment_code ?? null}
        renderButtons={<AccountConfirmButton payload={payload} />}
        onClickChangeMethod={() => setOpenChangeMethod(true)}
        isShowChangeMethod={isShowChangePaymentMethod()}
        numberOfInstalment={payload.installment_loan_tenure}
        bankName={payload.bank_name}
        payment={payload}
      >
        <>
          <UploadPaymentInfoView
            paymentInfoList={payment_slip}
            payment={payload}
            onClickUploadButton={() => setOpenModalUpload(true)}
            renderTooltip={renderTooltipFileUpload}
            imgClass="w-24 h-24"
          />
          {(isApprove || isReject) && (
            <div className={`p-3 mt-2 bg-${orderStatus?.bgColor}-100 text-sm`}>
              <div className="border-b border-gray-500 mb-3 pb-2">
                <span className="text-bold">{t("financeConfirmation")}</span>
              </div>
              {isApprove && (
                <div className="">
                  <div className="flex items-center mb-1">
                    <span className="mr-2 font-weight-bold">
                      {t("status")}:
                    </span>{" "}
                    <span className="text-success">{t("approved")}</span>
                  </div>
                  <div className="flex items-center mb-1">
                    <span className="mr-2 font-weight-bold">
                      {t("confirmBy")}:
                    </span>{" "}
                    <UserAvatarName user={confirm_payment_by} size="xx-small" />
                  </div>
                  <div className="flex items-center mb-1">
                    <span className="mr-2 font-weight-bold">
                      {t("confirmAt")}:
                    </span>{" "}
                    {TimeUtils.convertMiliToDateTime(confirm_payment_at)}
                  </div>
                  {confirm_name_of_payor && (
                    <div className="flex items-center mb-1">
                      <span className="mr-2 font-weight-bold">
                        {t("nameOfPayee")}:
                      </span>{" "}
                      {confirm_name_of_payor}
                    </div>
                  )}
                  {confirm_credit_card_number && (
                    <div className="flex items-center mb-1">
                      <span className="mr-2 font-weight-bold">
                        {t("cardLast4Digit")}:
                      </span>{" "}
                      {confirm_credit_card_number}
                    </div>
                  )}

                  {method === PAYMENT_CASH && (
                    <>
                      <div className="my-1 flex items-center bank-fee">
                        <span className="font-weight-bold">{`${t(
                          "bankFee"
                        )}: `}</span>
                        <span className="ml-1">
                          <CurrencyFormat
                            value={bank_fee}
                            displayType="text"
                            thousandSeparator={true}
                            prefix="฿"
                          />
                        </span>
                      </div>
                      <div className="my-1 flex items-center bank-fee">
                        <span className="font-weight-bold">{`${t(
                          "receivingAmount"
                        )}: `}</span>
                        <span className="ml-1">
                          <CurrencyFormat
                            value={total - bank_fee}
                            displayType="text"
                            thousandSeparator={true}
                            prefix="฿"
                          />
                        </span>
                      </div>
                    </>
                  )}
                </div>
              )}

              {isReject && (
                <div className="">
                  <div className="flex items-center mb-1">
                    <span className="mr-2 font-weight-bold">
                      {t("status")}:
                    </span>{" "}
                    <span className="text-error">{t("rejected")}</span>
                  </div>
                  <div className="flex items-center mb-1">
                    <span className="mr-2 font-weight-bold">
                      {t("rejectBy")}:
                    </span>{" "}
                    <span>{`${confirm_payment_by?.companyId}_${confirm_payment_by?.name}`}</span>
                  </div>
                  <div className="flex items-center mb-1">
                    <span className="mr-2 font-weight-bold">
                      {t("rejectAt")}:
                    </span>{" "}
                    {TimeUtils.convertMiliToDate(confirm_payment_at)}
                  </div>
                </div>
              )}

              {confirm_actual_date_payment && (
                <div className="my-1 text-success">
                  {`${t(
                    "actualDatePayment"
                  )}: ${TimeUtils.convertMiliToDateTime(
                    confirm_actual_date_payment
                  )}`}
                </div>
              )}

              {confirm_bank && (
                <div className="my-1 text-success">{`${t(
                  "actualBankReceivePayment"
                )}: ${getBankFullInfo(confirm_bank)}`}</div>
              )}

              <UploadPaymentInfoView
                paymentInfoList={bank_statement}
                payment={payload}
                onClickUploadButton={() => setOpenModalUpload(true)}
                renderTooltip={renderTooltipFileUpload}
                imgClass="w-24 h-24"
              />

              {comment && (
                <div className="mt-2">
                  <div className="relative bg-white text-gray-800 p-2 mt-2 arrow-up-white">
                    {comment}
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      </PaymentContainer>
    );
  };
  return (
    <>
      {method === PAYMENTS_METHOD.C2P ? (
        <PaymentBy2C2P
          index={index}
          payload={payload}
          onClickChangeMethod={() => setOpenChangeMethod(true)}
          onClickUpload={() => setOpenModalUpload(true)}
          isShowChangeMethod={isShowChangePaymentMethod()}
        />
      ) : (
        renderPaymentByBankTransfer()
      )}
      {openChangeMethod && (
        <ModalChangePaymentMethod
          open={openChangeMethod}
          onClose={() => setOpenChangeMethod(false)}
          payment={payload}
          onUpdated={() => {
            setOpenChangeMethod(false);
            Notifications.showSuccess(
              t("notification:updatePaymentMethodSuccessfully")
            );
            onReloadOrderData();
          }}
        />
      )}

      {openModalUpload && (
        <ModalUploadPaymentInfo
          open={openModalUpload}
          onClose={() => setOpenModalUpload(false)}
          payment={payload}
        />
      )}
    </>
  );
}
