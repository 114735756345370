import { PRODUCT_TYPE } from "@constants/product";
import { listProduct } from "@network/api/product";
import { AwesomeTableComponent, Button, StringUtils } from "d-react-components";
import { filter, map } from "lodash";
import { useTranslation } from "react-i18next";
import ProductName from "../components/ProductName";
import SearchGroupProducts from "../components/SearchGroupProducts";

const ProductInputTypeGroupCustomSize = ({ visible, productForm }: any) => {
  const { group_custom_size } = productForm?.values;
  const { t } = useTranslation();

  const onAddProductToCart = (products: any) => {
    productForm.setFieldValue(
      "group_custom_size",
      map(products, (item) => {
        const result = { ...item };
        if (!result?.quantity) {
          result.quantity = 1;
        }
        return result;
      })
    );
  };

  const columns = [
    {
      title: t("itemName"),
      dataIndex: "",
      render: (product: any) => <ProductName product={product} />,
    },
    {
      title: t("regularPrice"),
      dataIndex: "regular_price",
      render: (price: any) => (
        <div className="subTitle1">{StringUtils.moneyThaiFormat(price)}</div>
      ),
    },
    {
      title: t("salePrice"),
      dataIndex: "sale_price",
      render: (price: any) => (
        <div className="subTitle1">{StringUtils.moneyThaiFormat(price)}</div>
      ),
    },

    {
      title: "",
      dataIndex: "",
      render: (product: any) => {
        return (
          <Button
            iconName="delete"
            variant="trans"
            onClick={() => onRemoveGroupProductItem(product)}
          />
        );
      },
    },
  ];

  const onRemoveGroupProductItem = (productRemove: any) => {
    const results = filter(
      group_custom_size,
      (item) => item.id !== productRemove.id
    );
    productForm.setFieldValue("group_custom_size", results);
  };

  const source = (currentPage: any, inputSearch: any, filterBodyParam: any) => {
    const body = {
      ...filterBodyParam,
      search: inputSearch,
      isCustomSize: true,
      product_type: [PRODUCT_TYPE.CUSTOM_SIZE],
    };
    return listProduct(body, {
      pageIndex: currentPage,
      pageSize: 20,
    });
  };

  if (!visible) {
    return <div />;
  }
  return (
    <div className="mt-3">
      <div className="flex-center justify-content-between">
        <label>{`${t("groupCustomSizeSku")} (${
          group_custom_size.length
        })`}</label>
        <SearchGroupProducts
          onChange={onAddProductToCart}
          selectedProducts={group_custom_size}
          stockConsider={false}
          customSource={source}
        />
      </div>

      <AwesomeTableComponent
        className="mt-3 height-fit-content"
        dataSource={group_custom_size}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};

export default ProductInputTypeGroupCustomSize;
