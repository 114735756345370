import AppContext from "@helpers/context";
import { getDeliveryStockDetail } from "@network/api/delivery-stock";
import { Button, Progress, TabBar, TimeUtils } from "d-react-components";
import { filter, map } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import DeliveryDetailActivity from "./DeliveryStockActivity";
import DeliveryDetailDestination from "./DeliveryStockDestination";
import DeliveryDetailDriverVehicle from "./DeliveryStockDriverVehicle";
import DeliveryStockGeneral from "./DeliveryStockGeneral";
import DeliveryTransferStockHeader from "./DeliveryStockHeader";
import DeliveryTransferStockInfo from "./DeliveryStockInfo";
import DeliveryDetailOrigin from "./DeliveryStockOrigin";
import DeliveryDetailProducts from "./DeliveryStockProducts";
import DeliveryDetailReturnDelivery from "./DeliveryDetailReturnDelivery";
import {
  DELIVERY_RETURN_STATUS,
  DELIVERY_STOCK_STATUS,
} from "@constants/delivery";
import { isGrantURLPermission } from "@helpers/permissions";
import { API_DELIVERY_STOCK_RETURN_CREATE } from "@network/URL";

const TABS = [
  {
    id: "general",
    label: "general",
    component: <DeliveryStockGeneral />,
  },
  {
    id: "driverVehicle",
    label: "driverVehicle",
    component: <DeliveryDetailDriverVehicle />,
  },
  {
    id: "packingList",
    label: "packingList",
    component: <DeliveryDetailProducts />,
  },
  {
    id: "origin",
    label: "origin",
    component: <DeliveryDetailOrigin />,
  },
  {
    id: "destination",
    label: "destination",
    component: <DeliveryDetailDestination />,
  },
  {
    id: "return",
    label: "returnDelivery",
    component: <DeliveryDetailReturnDelivery />,
  },

  {
    id: "activity",
    label: "activity",
    component: <DeliveryDetailActivity />,
  },
];

const getDeliveryDriverData = (detail: any = {}) => {
  const {
    signature_at_warehouse = {},
    signature_at_store = {},
    transfer_stock_product,
  } = detail;

  //confirmation pick up
  const {
    driver_signature_at_warehouse,
    driver_signature_by_at_warehouse,
    proof = [],
    remark_at_warehouse,
    supervisor_fullname_at_warehouse,
    supervisor_signature_at_warehouse,
    signed_at_warehouse,
    ...restConfirmOrigin
  } = signature_at_warehouse;
  const confirmationOrigin = {
    createdAt: TimeUtils.toDateTime(restConfirmOrigin.created),
    signAt: TimeUtils.toDateTime(signed_at_warehouse),
    signatureDriver: driver_signature_at_warehouse,
    signatureSupervisor: supervisor_signature_at_warehouse,
    driver: driver_signature_by_at_warehouse,
    attachments: map(proof, (item) => item.attachment),
    remark: remark_at_warehouse,
    supervisorName: supervisor_fullname_at_warehouse,
    ...restConfirmOrigin,
  };

  //confirmation received
  const {
    driver_signature_at_store,
    driver_signature_by_at_store,
    remark_at_store,
    supervisor_fullname_at_store,
    supervisor_signature_at_store,
    signed_at_store,
    ...restConfirmDes
  } = signature_at_store;
  const confirmationDesc = {
    createdAt: TimeUtils.toDateTime(restConfirmDes.created),
    signAt: TimeUtils.toDateTime(signed_at_store),
    signatureDriver: driver_signature_at_store,
    signatureSupervisor: supervisor_signature_at_store,
    driver: driver_signature_by_at_store,
    attachments: map(restConfirmDes?.proof, (item) => item.attachment),
    remark: remark_at_store,
    supervisorName: supervisor_fullname_at_store,
    ...restConfirmDes,
  };

  return {
    ...detail,
    products: filter(transfer_stock_product, (item) => !!item.quantity),
    confirmationOrigin,
    confirmationDesc,
  };
};

const DeliveryDetail = () => {
  const [deliveryDetail, setDeliveryDetail] = useState<any>(null);

  const [tab, setTab] = useState<any>(TABS[0]);
  const { deliveryId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    loadDeliveryDetail();
  }, []);

  const loadDeliveryDetail = () => {
    Progress.show(
      { method: getDeliveryStockDetail, params: [deliveryId] },
      (res: any) => {
        const delivery = res?.data?.data?.delivery;
        setDeliveryDetail(getDeliveryDriverData(delivery));
      }
    );
  };

  const renderDeliveryReturnDetected = () => {
    if (
      deliveryDetail?.return_delivery_status_current !==
        DELIVERY_RETURN_STATUS.REQUESTED ||
      tab?.id === "return"
    ) {
      return <div />;
    }
    return (
      <div className="bg-primary p-3">
        <div className="h4 text-white">{t("returnDeliveryDetected")}</div>
        <div className="text-white mt-2">{t("deliveryHasAReturnComplete")}</div>
        <Button
          className="bg-white text-primary mt-2"
          onClick={() => setTab(TABS[5])}
        >
          {t("checkIt")}
        </Button>
      </div>
    );
  };
  const renderDeliveryReturnRequired = () => {
    const { status, return_delivery_status_current } = deliveryDetail ?? {};
    if (tab?.id === "return") {
      return <div />;
    }

    if (
      !isGrantURLPermission(API_DELIVERY_STOCK_RETURN_CREATE) ||
      (status !== DELIVERY_STOCK_STATUS.FAILED &&
        status !== DELIVERY_STOCK_STATUS.PARTIALLY)
    ) {
      return <div />;
    }

    if (
      (status === DELIVERY_STOCK_STATUS.FAILED ||
        status === DELIVERY_STOCK_STATUS.PARTIALLY) &&
      (return_delivery_status_current === DELIVERY_RETURN_STATUS.COMPLETED ||
        return_delivery_status_current === DELIVERY_RETURN_STATUS.REQUESTED)
    ) {
      return <div />;
    }

    return (
      <div className="bg-error p-3">
        <div className="h4 text-white">{t("returnDeliveryRequired")}</div>
        <div className="text-white mt-2">{t("returnDeliveryNeedCreate")}</div>
        <Button
          className="bg-white text-error mt-2"
          onClick={() => setTab(TABS[5])}
        >
          {t("checkIt")}
        </Button>
      </div>
    );
  };

  return (
    <AppContext.Provider
      value={{
        deliveryDetail,
        loadDeliveryDetail,
      }}
    >
      <div className="flex-column-container bg-trans">
        <DeliveryTransferStockHeader />
        <div className="grid p-3 grid-cols-12 gap-4">
          <div className="col-span-4">{<DeliveryTransferStockInfo />}</div>
          <div className="col-span-8 px-3">
            {renderDeliveryReturnDetected()}
            {renderDeliveryReturnRequired()}
            <TabBar
              dataSource={TABS}
              onChange={setTab}
              value={tab}
              getLabel={(item: any) => t(item.label)}
            />
            <div className="mt-3">{tab.component}</div>
          </div>
        </div>
      </div>
    </AppContext.Provider>
  );
};

export default DeliveryDetail;
