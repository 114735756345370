import ProductName from "@components/product/components/ProductName";
import ProductSearchButton from "@components/product/components/ProductSearchButton";
import QuantityInputField from "@components/product/components/QuantityInputField";
import Drawer from "@components/shared/Drawer";
import { PRODUCT_TYPE } from "@constants/product";
import AppContext from "@helpers/context";
import { browseProduct } from "@network/api/product";
import { Table } from "antd";
import {
  AwesomeTableComponent,
  Button,
  Icon,
  InputText,
  InputTextSearch,
  Notifications,
} from "d-react-components";
import { filter, includes, map } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ModalEditBundleInfo = ({
  open,
  onClose,
  onSave,
  payload = {},
  hasPriority,
}: any) => {
  const [info, setInfo] = useState(payload);
  const { t } = useTranslation();

  return (
    <Drawer
      open={open}
      onClose={onClose}
      onSave={async () => {
        onSave && onSave(info);
      }}
      title={t("freeGiftBundle")}
    >
      <InputText
        className="mt-3"
        label={t("name")}
        value={info?.name}
        onChange={(event) => setInfo({ ...info, name: event?.target?.value })}
      />
      {hasPriority && (
        <InputText
          className="mt-3"
          label={t("priority")}
          type="number"
          value={`${info?.priority}`}
          onChange={(event) => {
            const priorityValue = parseInt(event?.target?.value);
            if (priorityValue <= 0) {
              return;
            }
            setInfo({ ...info, priority: priorityValue });
          }}
        />
      )}
    </Drawer>
  );
};

export default function GiftContainer({
  payload = {},
  setChangeBundle,
  deleteBundle,
  hasPriority,
  otherPriorityList,
  isMovePosition,
  onClickUp,
  onClickDown,
}: any) {
  const { product, name = "", id } = payload;
  const [displayTitle, setDisplayTitle] = useState("");
  const [modalEditInfo, setOpenModal] = useState(false);
  const { cartRuleForm } = useContext(AppContext);
  const { t } = useTranslation();
  const formValues = cartRuleForm.values ?? {};

  const columns = [
    {
      title: t("itemName"),
      dataIndex: "product",
      render: (product: any) => {
        return <ProductName item={{ product }} />;
      },
    },
    {
      title: t("quantity"),
      dataIndex: "quantity",
      render: (quantity: any, item: any) => {
        return (
          <QuantityInputField
            quantity={quantity}
            onChangeQuantity={(value: any) => {
              handleChangeQuantityItem(value, item);
            }}
          />
        );
      },
    },
    {
      title: t("action"),
      dataIndex: "",
      render: (product: any) => {
        return (
          <Icon
            name="delete"
            onClick={() => onClickDeleteProductHandle(product)}
          />
        );
      },
    },
  ];

  useEffect(() => {
    setDisplayTitle(name);
  }, [name]);

  //*********************************************************CONTROL UI ******************************* */

  const onBlurHandle = () => {
    setChangeBundle({ ...payload, name: displayTitle });
  };

  const onClickDeleteProductHandle = (item: any) => {
    const clone = filter(product, (pro) => pro?.productid !== item?.productid);
    const clonePayload = { ...payload, product: clone };
    setChangeBundle(clonePayload);
  };

  const setChangeSelectedProductHandle = (items: any) => {
    const cloneItem = map(items, (item) => {
      if (product.includes(item)) {
        return item;
      }
      return { ...item, quantity: 1 };
    });
    const clone = { ...payload, product: cloneItem };
    setChangeBundle(clone);
  };

  const handleChangeQuantityItem = (value: any, item: any) => {
    if (value < 1) {
      return;
    }
    const clone = [...product];
    clone.forEach((product) => {
      if (product?.productid === item?.productid) {
        product.quantity = value;
      }
    });
    const clonePayload = { ...payload, product: clone };
    setChangeBundle(clonePayload);
  };

  const onSaveInfo = (bundleInfo: any) => {
    const newBundle = { ...payload, ...bundleInfo };
    const isSamePriority = includes(otherPriorityList, bundleInfo?.priority);
    if (isSamePriority) {
      Notifications.showError(t("priorityMustNotCoincide"));
      return;
    }
    setChangeBundle(newBundle);
    setOpenModal(false);
  };

  //*********************************************************RENDER UI ******************************* */

  const renderMovePosition = () => {
    return (
      <div className="movePositionContainer">
        <Icon
          name="arrow_drop_up"
          className="arrowDropIcon"
          onClick={onClickUp}
        />
        <Icon
          name="arrow_drop_down"
          className="arrowDropIcon"
          onClick={onClickDown}
        />
      </div>
    );
  };

  const sourceSearchProduct = (code: any, filterBody: any, paging: any) => {
    const body: any = {
      channel: formValues?.selectedChannel?.id,
      promotion: "name",
      code,
      product_type: [
        PRODUCT_TYPE.DEFAULT,
        PRODUCT_TYPE.GROUP,
        PRODUCT_TYPE.CUSTOM_SIZE,
      ],
    };

    const { brand, category } = filterBody;
    if (brand && brand.length > 0) {
      body.brand = map(brand, (item) => item.id);
    }

    if (category && category.length > 0) {
      body.category = map(category, (item) => item.id);
    }

    return browseProduct(body, paging);
  };

  const renderHeader = () => {
    return (
      <div className="px-2 py-3 bg-primary flex-row">
        <div className="label text-white">
          {`${displayTitle.toUpperCase()}`}
          {hasPriority && `(${t("priority")} ${payload?.priority})`}
        </div>
        <Icon
          className="mx-2"
          onClick={() => setOpenModal(true)}
          name="edit"
          color="white"
        />
        <Icon
          className="mx-2"
          name="delete"
          onClick={() => deleteBundle(payload)}
          color="white"
        />
      </div>
    );
  };

  return (
    <div className="mt-2 border border-primary">
      {renderHeader()}
      <div className="p-3">
        <div className="flex-row">
          <InputTextSearch />
          <ProductSearchButton
            onChange={setChangeSelectedProductHandle}
            defaultValue={product}
            source={sourceSearchProduct}
            dataKey={"products"}
          />
        </div>
        <AwesomeTableComponent
          className="mt-2"
          dataSource={product && product}
          columns={columns}
          pagination={false}
        />
      </div>
      {modalEditInfo && (
        <ModalEditBundleInfo
          open={modalEditInfo}
          onClose={() => setOpenModal(false)}
          payload={payload}
          hasPriority={hasPriority}
          onSave={(bundleInfo: any) => onSaveInfo(bundleInfo)}
        />
      )}
    </div>
  );
}
