import ImageIcon from "@common/Icon";
import Drawer from "@components/shared/Drawer";
import { CHANNEL_GENERAL_KEYS } from "@constants/channel";
import AppContext from "@helpers/context";
import ChannelAPI from "@network/api/channel";
import { Notifications, ObjectUtils, Progress } from "d-react-components";
import { useFormik } from "formik";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import ChannelFormTerm from "../components/ChannelFormTerm";

function ChannelDetailTermUpdateDrawer({
  open,
  onClose,
  channelId,
  defaultValue,
  onUpdateSuccess,
}: any) {
  const { t } = useTranslation();

  const generalForm = useFormik({
    initialValues: defaultValue ?? {},
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: ChannelGeneralSchema,
    onSubmit: (values) => {
      const formValue: any = ObjectUtils.mapFieldsLangsCTS(
        values,
        CHANNEL_GENERAL_KEYS as any
      );
      const body = { id: channelId, ...formValue };
      Progress.show({ method: ChannelAPI.update, params: [body] }, () => {
        Notifications.showSuccess(t("updateChannelSuccess"));
        onUpdateSuccess();
        onClose();
      });
    },
  });
  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("updateChannel")}
      onSave={() => generalForm.handleSubmit()}
      size="auto"
      width="60vw"
    >
      <ChannelFormTerm form={generalForm} fieldClassName="col-12" />
    </Drawer>
  );
}

const ChannelDetailTermCondition = () => {
  const { channel, loadChannelDetail } = useContext(AppContext);
  const [openUpdate, setOpenUpdate] = useState(false);
  const { t } = useTranslation();

  const { id } = channel ?? {};

  return (
    <div className="border mt-3 p-4">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h5>{t("termAndCondition")}</h5>
        <span onClick={() => setOpenUpdate(true)}>
          <ImageIcon
            className="w-[15px] cursor-pointer"
            src="/images/icons/edit.svg"
          />
        </span>
      </div>
      <div dangerouslySetInnerHTML={{ __html: channel?.terms }} />
      {openUpdate && (
        <ChannelDetailTermUpdateDrawer
          open={openUpdate}
          onClose={() => setOpenUpdate(false)}
          channelId={id}
          defaultValue={ObjectUtils.mapFieldsLangsSTC(
            channel ?? {},
            CHANNEL_GENERAL_KEYS as any
          )}
          onUpdateSuccess={loadChannelDetail}
        />
      )}
    </div>
  );
};

export default ChannelDetailTermCondition;
