import ShippingItemInfo from "@components/order/components/shipping/ShippingItemInfo";
import Drawer from "@components/shared/Drawer";
import AppContext from "@helpers/context";
import { getSaleOrderSource, updateSourceSaleOrder } from "@network/api/order";
import { Notifications, RadioGroup } from "d-react-components";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const SourceSaleOrder = () => {
  const { order, onReloadOrderData, isPublic } = useContext(AppContext);
  const [openModal, setOpenModal] = useState(false);
  const [source, setSource] = useState<any>();
  const { t } = useTranslation();

  useEffect(() => {
    setSource(order?.source?.id);
  }, [order]);

  const onChangeSourceOfOrder = async () => {
    const body = {
      orderid: order?.id,
      sourceid: source,
    };
    return updateSourceSaleOrder(body).then((res) => {
      onReloadOrderData && onReloadOrderData();
      Notifications.showSuccess(t("notification:updateSourceOrderSuccess"));
      setOpenModal(false);
    });
  };

  return (
    <ShippingItemInfo
      title={t("sourceOrder")}
      onClickEdit={
        isPublic
          ? null
          : () => {
              setOpenModal(true);
            }
      }
    >
      {order && <div>{order?.source?.name}</div>}
      {openModal && (
        <SoureOrderDrawer
          open={openModal}
          onClose={() => setOpenModal(false)}
          source={source}
          setSource={setSource}
          onSave={onChangeSourceOfOrder}
        />
      )}
    </ShippingItemInfo>
  );
};

const SoureOrderDrawer = ({
  source,
  setSource,
  open,
  onClose,
  onSave,
}: any) => {
  const [sourceSaleOrderList, setSourceOrderList] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    getSaleOrderSource().then((res) => {
      const sourceSaleOrderList = res?.data?.data?.sources ?? [];
      setSourceOrderList(sourceSaleOrderList);
    });
  }, []);
  return (
    <Drawer
      title={t("sourceOrder")}
      open={open}
      onClose={onClose}
      onSave={onSave}
    >
      <RadioGroup
        value={source}
        onChange={(value) => setSource(value)}
        dataSource={sourceSaleOrderList}
        getLabel={(item) => item.name}
        getValue={(item) => item.id}
        numberOfColumns="1"
        classNameItem="mt-0 mb-3"
      />
    </Drawer>
  );
};

export default SourceSaleOrder;
