import ActivitiesView from "@components/order/components/activity/ActivitiesView";
import AppContext from "@helpers/context";
import CartRuleAPI from "@network/api/cart-rule";
import { Notifications } from "d-react-components";
import { map } from "lodash";
import { useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const CartRuleDetailActivity = () => {
  const { cartRule } = useContext(AppContext);
  const activityList = useRef(null);
  const { cartRuleId } = useParams();
  const { t } = useTranslation();

  const source = (paging: any) => {
    return CartRuleAPI.activities({ cart_rule_id: cartRuleId }, paging);
  };

  const transformer = (res: any) => {
    const activityList = res?.data?.data?.activities ?? [];
    return map(activityList, (item) => ({
      ...item,
      files: map(item.attachment, (attItem) => attItem?.attachment),
    }));
  };

  const onClickSendComment = (comment: any, attachment: any) => {
    const body = {
      cart_rule_id: cartRuleId,
      comment,
      attachment: map(attachment, (item) => item?.key),
    };
    return CartRuleAPI.activityAdd(body).then(() => {
      Notifications.showSuccess(t("addCommentSuccess"));
    });
  };

  return (
    <ActivitiesView
      hideType
      source={source}
      transformer={transformer}
      setRef={(listRefParams: any) => (activityList.current = listRefParams)}
      onClickSendComment={onClickSendComment}
      isInstantUploadAttach
    />
  );
};

export default CartRuleDetailActivity;
