import React, { useEffect, useMemo, useRef, useState } from "react";
import Drawer from "@components/shared/Drawer";
import { AwesomeTableComponent, IColumnsProps, TimeUtils } from "d-react-components";
import { useTranslation } from "react-i18next";
import MyAssetsAPI from "@network/api/my-assets";
import { IStore } from "@interfaces/store";
import { find, uniqBy } from "lodash";

const WarehouseList = ({ open, onClose, warehouseList, type: action }: any) => {
  const { t } = useTranslation();
  const tableRef = useRef<any>(null);
  const [listCheckWarehouse, setListCheckWarehouse] = useState<any[]>([]);

  useEffect(() => {
    MyAssetsAPI.checkAssetWarehouse({
      warehouse: warehouseList?.map((w: IStore) => w.id),
    }).then((resp) => {
      setListCheckWarehouse(
        uniqBy(
          [...listCheckWarehouse, ...(resp?.data?.data?.asset ?? [])],
          "id"
        )
      );
    });
  }, []);

  const columns: IColumnsProps = useMemo(
    () => [
      {
        title: t("name"),
        dataIndex: "name",
        render: (name: string) => name,
      },
      {
        dataIndex: "channel",
        title: t("channel"),
        render: (data: any) => data?.[0]?.name,
      },
      {
        dataIndex: "warehouse_system_code",
        title: t("systemCode"),
        width: 130,
      },
      {
        dataIndex: "code",
        title: t("navArCode"),
        width: 130,
      },
      {
        dataIndex: "pickup_code",
        title: t("navPickupCode"),
        width: 130,
      },
      // ...(action === "add"
      //   ? [
      //       {
      //         title: t("startingDate"),
      //         dataIndex: "start",
      //         width: 120,
      //         render: (start: any, item: any) => {
      //           return item?.start ? TimeUtils.toDate(item?.start) : "";
      //         },
      //       },
      //     ]
      //   : [
      //       {
      //         title: t("startingDate"),
      //         dataIndex: "start",
      //         width: 120,
      //         render: (start: any, item: any) => {
      //           const currentItem = find(listCheckWarehouse, {
      //             warehouse_id: item.id,
      //           });
      //           return currentItem?.start
      //             ? TimeUtils.toDate(currentItem?.start)
      //             : "";
      //         },
      //       },
      //       {
      //         title: t("lastWorkingDate"),
      //         dataIndex: "end",
      //         width: 150,
      //         render: (end: any, item: any) => {
      //           return item?.end ? TimeUtils.toDate(item?.end) : "";
      //         },
      //       },
      //     ]),
    ],
    [t, action, listCheckWarehouse]
  );

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("warehouses")}
      hideFooter={true}
      size="auto"
      width={1000}
    >
      <AwesomeTableComponent
        dataSource={warehouseList}
        columns={columns}
        ref={tableRef}
      />
    </Drawer>
  );
};

export default WarehouseList;
