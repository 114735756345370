import SourceSaleOrder from "@components/order/detail/content/SourceSaleOrder";
import AppContext from "@helpers/context";
import React, { useContext } from "react";
import BillingInformation from "./BillingInfo";
import CustomerNameInfo from "./CustomerNameInfo";
import OrderContact from "./OrderContact";
import ShippingAddress from "./ShippingAddress";

const ShippingInfo = () => {
  const { isCreateOrder, salesPerson } = useContext(AppContext);
  return (
    <>
      <div className="bg-white p-3 mb-3 shadow-sm">
        {!isCreateOrder && <SourceSaleOrder />}
      </div>
      <div className="bg-white p-3 mb-3 shadow-sm">
        <CustomerNameInfo />
      </div>
      {!isCreateOrder && (
        <div className="bg-white p-3 mb-3 shadow-sm">
          <OrderContact />
        </div>
      )}
      <div className="bg-white p-3 mb-3 shadow-sm">
        <ShippingAddress />
      </div>
      <div className="bg-white p-3 mb-3 shadow-sm">
        <BillingInformation />
      </div>
    </>
  );
};

export default ShippingInfo;
