import { STOCK_ADJUSTMENT_STATUS_LIST } from "@constants/stock-adjustment";
import { STOCK_REQUEST_STATUS_LIST } from "@constants/stock-request";
import AppContext from "@helpers/context";
import { IStockAdjustment } from "@interfaces/stock-adjustment";
import {
  IRowsKey,
  TimeUtils,
  ViewLabelStatus,
  ViewRowInterchange,
} from "d-react-components";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

const StockAdjustmentDetailInfo = () => {
  const { adjustmentDetail } = useContext(AppContext);
  const { ref, status } = adjustmentDetail ?? {};
  const { t } = useTranslation();

  const DETAILS_KEY = useMemo<IRowsKey<IStockAdjustment>[]>(
    () => [
      {
        id: "ref",
        label: t("id"),
      },
      {
        id: "channel",
        label: t("channel"),
        renderContent: ({ data, item }) => data?.name,
      },

      {
        id: "warehouse",
        label: t("branchWarehouse"),
        renderContent: ({ data, item }) => data?.name,
      },
      {
        id: "warehouse",
        label: t("branchWarehouseCode"),
        renderContent: ({ data, item }) => data?.code,
      },
      {
        id: "stock_inventory_control",
        label: t("eventCode"),
        renderContent: ({ data, item }) => data?.code,
      },
      {
        id: "created",
        label: t("createdAt"),
        renderContent: ({ data, item }) => TimeUtils.toDateTime(data),
      },
      {
        id: "updated",
        label: t("updatedAt"),
        renderContent: ({ data, item }) => TimeUtils.toDateTime(data),
      },
    ],
    [t]
  );
  console.log("status", status);
  return (
    <div className="card-container p-4 flex-column flex-center pt-5">
      <img src={"/images/icons/event.svg"} className="image-reg-large" />
      <h4 className="mt-3">{ref}</h4>
      <ViewLabelStatus
        status={status}
        listStatus={STOCK_ADJUSTMENT_STATUS_LIST}
        className="mt-2"
        getLabel={(item) => t(item.label)}
      />

      <ViewRowInterchange
        className="w-100"
        dataSource={adjustmentDetail}
        keyList={DETAILS_KEY as any}
        variant="border"
        classNameLabel="font-weight-bold "
        classNameContent="text-end "
      />
    </div>
  );
};

export default StockAdjustmentDetailInfo;
