import { PRODUCT_TYPE } from "@constants/product";
import { listProduct } from "@network/api/product";
import {
  AwesomeTableComponent,
  Button,
  InputText,
  StringUtils,
} from "d-react-components";
import _, { map } from "lodash";
import { useTranslation } from "react-i18next";
import ProductName from "../components/ProductName";
import SearchGroupProducts from "../components/SearchGroupProducts";

const CUSTOM_SIZE_INPUT = [
  { label: "widthMinCm", key: "min_width" },
  { label: "widthMaxCm", key: "max_width" },
  { label: "lengthMinCm", key: "min_length" },
  { label: "lengthMaxCm", key: "max_length" },
  { label: "priceCoefficient", key: "coefficient" },
];
const ProductInputTypeCustomSize = ({ visible, productForm }: any) => {
  const { product_custom_size } = productForm?.values;
  const { t } = useTranslation();

  const onAddProductToCart = (products: any) => {
    productForm.setFieldValue("product_custom_size", {
      product: { ...products?.[0] },
    });
  };

  const columns = [
    {
      title: t("itemName"),
      dataIndex: "",
      render: (product: any) => <ProductName item={{ product }} />,
    },
    {
      title: t("regularPrice"),
      dataIndex: "regular_price",
      render: (price: any) => (
        <div className="subTitle1">{StringUtils.moneyThaiFormat(price)}</div>
      ),
    },
    {
      title: t("salePrice"),
      dataIndex: "sale_price",
      render: (price: any) => (
        <div className="subTitle1">{StringUtils.moneyThaiFormat(price)}</div>
      ),
    },

    {
      title: "",
      dataIndex: "",
      render: (product: any) => {
        return (
          <Button
            iconName="delete"
            variant="trans"
            onClick={() => onRemoveGroupProductItem()}
          />
        );
      },
    },
  ];

  const onRemoveGroupProductItem = () => {
    productForm.setFieldValue("product_custom_size", null);
  };

  const source = (currentPage: any, inputSearch: any, filterBodyParam: any) => {
    const body = {
      ...filterBodyParam,
      search: inputSearch,
      product_type: [PRODUCT_TYPE.DEFAULT],
    };
    return listProduct(body, {
      pageIndex: currentPage,
      pageSize: 20,
    });
  };

  if (!visible) {
    return <div />;
  }

  const onChangeCustomSizeData = (key: string, text: any) => {
    const result = { ...product_custom_size, [key]: text };
    productForm.setFieldValue("product_custom_size", result);
  };

  const renderSelectProduct = () => {
    const listPro = product_custom_size?.product
      ? [product_custom_size?.product]
      : [];
    return (
      <div className="mt-3">
        <div className="flex-center justify-content-between">
          <label>{t("customSizeFromSku")}</label>
          <SearchGroupProducts
            onChange={onAddProductToCart}
            selectedProducts={listPro}
            stockConsider={false}
            customSource={source}
            isSingleSelect
          />
        </div>

        <AwesomeTableComponent
          dataSource={listPro}
          columns={columns}
          pagination={false}
          className="height-fit-content mt-3"
        />
      </div>
    );
  };
  const renderCustomSize = () => {
    if (_.isEmpty(product_custom_size?.product?.id)) {
      return <div />;
    }
    return (
      <div className="mt-3">
        <label>{t("customSizeFormula")}</label>
        <div className="mt-3 row">
          {map(CUSTOM_SIZE_INPUT, (input) => (
            <InputText
              className="col-12 col-sm-6 mt-3"
              label={t(input.label)}
              onChange={(event) =>
                onChangeCustomSizeData(input.key, event.target.value)
              }
              value={product_custom_size?.[input.key] ?? ""}
              type="number"
            />
          ))}
        </div>
      </div>
    );
  };

  return (
    <div>
      {renderSelectProduct()}
      {renderCustomSize()}
    </div>
  );
};

export default ProductInputTypeCustomSize;
