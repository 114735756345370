import {
  SplitPaymentInput,
  defaultPaymentList,
} from "@components/order/components/payment/PaymentInformation";
import Drawer, { DrawerProps } from "@components/shared/Drawer";
import AppContext from "@helpers/context";
import { getPaidFromPayments } from "@helpers/payment";
import { createRentalInvoice } from "@network/api/rental";
import { InputText, Notifications, Progress } from "d-react-components";
import { every, filter, isEmpty } from "lodash";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

export const generateSplitPaymentPayload = (
  listPayment: any,
  splitPayment: any,
  remainAmount: number,
  t: any
) => {
  const arrayTotal = listPayment?.map((item: any) => item?.total);
  if (arrayTotal.includes(0) || arrayTotal.includes("0")) {
    Notifications.showError(t("notification:pleaseFillAllThePaymentAmount"));
    return Promise.reject();
  }
  // if (remainAmount > 0) {
  //   Notifications.showError(t("notification:paymentIsNotEnough"));
  //   return Promise.reject();
  // }

  if (listPayment[0].method.id === "default") {
    Notifications.showError(
      t("notification:youNeedToChooseASpecificPaymentMethod")
    );
    return Promise.reject();
  }

  // const isValid2c2p = every(
  //   filter(listPayment, (item) => item.method.id === PAYMENT_2C2P),
  //   (item) => !!item.paymentOption
  // );
  // if (!isValid2c2p) {
  //   Notifications.showError(t("notification:needToFillPaymentOption"));
  //   return Promise.reject();
  // }
  let split: any = [];
  const splitPaymentID =
    splitPayment && splitPayment?.map((item: any) => item.id);
  listPayment.forEach((item: any) => {
    const paymentBody = {
      total: item.total,
      method: item.method.id,
    };
    if (splitPaymentID.includes(item.id) && item.status !== "approved") {
      split.push({ ...paymentBody, id: item.id });
    } else if (item.status !== "approved") {
      split.push(paymentBody);
    }
  });
  split = split.filter((item: any) => item.total !== 0 && item.total !== "0");
  return split;
};

const CreateInvoiceModal = (
  props: Partial<DrawerProps> & Pick<DrawerProps, "open"> & { onClose: any }
) => {
  const {
    order,
    onReloadOrderData,
    splitPayment = [],
    oldPayment = [],
  } = useContext(AppContext);
  const { open, onClose } = props;
  const { t } = useTranslation();
  const [remark, setRemark] = useState("");
  const [listPayment, setListPayment] = useState(defaultPaymentList);
  const [remainAmount, setRemainAmount] = useState(0);

  const getTotalRemainingOrder = () => {
    const totalAmount = order?.total ?? 0;
    const paid = getPaidFromPayments(oldPayment);
    return totalAmount - paid;
  };

  const createInvoice = () => {
    const split = generateSplitPaymentPayload(
      listPayment,
      splitPayment,
      remainAmount,
      t
    );
    if (!isEmpty(split)) {
      Progress.show(
        {
          method: createRentalInvoice,
          params: {
            rent_id: order.id,
            payments: split,
            description: remark,
          },
        },
        () => {
          onReloadOrderData && onReloadOrderData();
          onClose && onClose();
        }
      );
    }
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("createInvoice")}
      destroyOnClose
      onSave={createInvoice}
      size="auto"
      width="500"
    >
      <InputText
        value={remark}
        onChange={(e) => setRemark(e.target.value)}
        placeholder={t("pleaseInput")}
        label={t("description")}
      />
      <SplitPaymentInput
        total={getTotalRemainingOrder()}
        setRemainAmount={(value: any) => setRemainAmount(value)}
        listPayment={listPayment}
        setListPayment={setListPayment}
        order={order}
      />
    </Drawer>
  );
};

export default CreateInvoiceModal;
