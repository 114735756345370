export const CHANNEL_STATUSES = [
  { id: "ENABLED", label: "enabled", color: "#33B950" },
  { id: "DISABLED", label: "disabled", color: "#C4C4C4" },
];

export const CHANNEL_CONFIG_CREATED_ORDER_EMAIL = [
  { id: 1, label: "enabled" },
  { id: 0, label: "disabled" },
];

export const CHANNEL_GENERAL_KEYS = [
  { keyClient: "name", keyServer: "name" },
  { keyClient: "code", keyServer: "code" },
  {
    keyClient: "status",
    keyServer: "status",
  },
  {
    keyClient: "companyName",
    keyServer: "company",
  },
  { keyClient: "companyAddress", keyServer: "address" },
  { keyClient: "companyPhone", keyServer: "phone" },
  { keyClient: "companyWebsite", keyServer: "website" },
  {
    keyClient: "companyTaxId",
    keyServer: "tax",
  },

  {
    keyClient: "phoneSupport",
    keyServer: "phone_support_payment",
  },
  {
    keyClient: "line",
    keyServer: "line",
  },
  {
    keyClient: "messenger",
    keyServer: "facebook",
  },
  {
    keyClient: "emailSupport",
    keyServer: "email_support_payment",
  },
  {
    keyClient: "emailSender",
    keyServer: "email_sender",
  },
  {
    keyClient: "domainShareOrder",
    keyServer: "domain_order",
  },
  {
    keyClient: "color",
    keyServer: "color",
  },
  {
    keyClient: "isSendEmailCreatedOrder",
    keyServer: "is_send_mail_created_order_to_customer",
  },
  {
    keyClient: "isSendReportToLine",
    keyServer: "send_report_to_line",
  },
  {
    keyClient: "isThisChannelDealer",
    keyServer: "is_channel_dealer",
  },

  {
    keyClient: "channelOrderSource",
    keyServer: "channel_source",
  },

  {
    keyClient: "senderNameSms",
    keyServer: "sender_name_sms",
  },
  {
    keyClient: "shortDomain",
    keyServer: "short_domain",
  },
  {
    keyClient: "terms",
    keyServer: "terms",
  },
  {
    keyClient: "terms_and_conditions_of_rent",
    keyServer: "terms_and_conditions_of_rent",
  },
];

export const CHANNEL_PDF_CONTRACT_KEYS = [
  {
    keyClient: "pdfColorPrimary",
    keyServer: "color_pdf_of_rent",
  },
  {
    keyClient: "pdfLogo",
    keyServer: "logo_pdf_of_rent",
  },
  {
    keyClient: "pdfIconHeader",
    keyServer: "icon_header_pdf_of_rent",
  },
  {
    keyClient: "pdfIconFooter",
    keyServer: "icon_pdf_of_rent",
  },
  {
    keyClient: "pdfBackgroundHeader",
    keyServer: "background_header_pdf_of_rent",
  },
];

export const CHANNEL_SIGNATURE_KEYS = [
  {
    keyClient: "lessorSignature",
    keyServer: "signature_of_rent",
  },
  {
    keyClient: "lessorName",
    keyServer: "signature_name_of_rent",
  },
  {
    keyClient: "witnessSignature",
    keyServer: "witness_one_signature_of_rent",
  },
  {
    keyClient: "witnessName",
    keyServer: "witness_one_signature_name_of_rent",
  },
];
export const CHANNEL_REPORT_SOURCES = [
  { id: "online", label: "online" },
  { id: "standalone_store", label: "standaloneStore" },
  { id: "marketplace", label: "marketplace" },
  { id: "modern_trade", label: "modernTrade" },
];
