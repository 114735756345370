import { RenderPreviewFile } from "@components/shared/UploadFile";
import AppContext from "@helpers/context";
import { Spin } from "antd";
import { Button, Notifications, StringUtils } from "d-react-components";
import { isEmpty } from "lodash";
import React, { useContext } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

const CheckSKUUploadView = () => {
  const { t } = useTranslation();

  const {
    fileResults,
    setFileResults,
    uploadSkuData,
    setUploadSkuData,
    onProgress,
    handleOnClickCheckUploadData,
  } = useContext(AppContext);

  function onDrop(files: any[]) {
    const readerNew = new FileReader();
    readerNew.readAsText(files[0]);
    readerNew.onload = function (e) {
      const skuList = StringUtils.convertCsvJSON(readerNew.result);
      if (skuList?.length > 1000) {
        Notifications.showError(t("skuListIsNotMoreThan"));
        return;
      }
      if (!isEmpty(skuList)) {
        const result = skuList.filter((item) => item.sku);
        setUploadSkuData(result);
      }
    };

    const fileResults: any[] = [];
    files &&
      files.forEach((file, index) => {
        const reader = new FileReader();
        const url = reader.readAsDataURL(file);
        reader.onloadend = function (e) {
          fileResults.push({
            imageData: reader.result,
            fileData: file,
          });
          if (index === files.length - 1) {
            setFileResults(fileResults);
          }
        };
      });
  }
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <div>
      <div className="checkSKUPageContainer-copyPaste">
        <div className="my-2">
          <div>{t("checkByUploadingAllProduct")}</div>
          <div>
            <div style={{ fontWeight: "bold" }}>{t("remark")}:</div>
            <div style={{ marginLeft: "1rem" }}>- {t("skuFieldIsRequire")}</div>
            <div style={{ marginLeft: "1rem" }}>
              - {t("maximum1000SkusPerTime")}
            </div>
          </div>
        </div>
        <div className="dragContainerProductMedia" {...getRootProps()}>
          <div
            className="rounded border-dashed border-primary flex w-100 h-[160px] items-center justify-center flex-col"
            {...getRootProps()}
          >
            <img src="/images/icons/upload.svg" className="w-8" />
            <span className="text-bold">{t("dragAndDropYourCSVHere")}</span>
            <span className="text-gray-700 text-xs">
              {t("maximumFileSizeIs5MB")}
            </span>
            <a className="text-primary cursor-pointer text-xs">
              {t("orSelectFromYourComputer")}
            </a>
            <input {...getInputProps()} />
          </div>

          {fileResults?.length > 0 && (
            <div className="mt-3">
              {fileResults.map((item: any) => {
                const fileName = item?.fileData?.name;
                const fileExtension =
                  StringUtils.getExtensionFromFilename(fileName)?.toLowerCase();
                return (
                  <RenderPreviewFile
                    item={item}
                    extension={fileExtension}
                    name={fileName}
                  />
                );
              })}
            </div>
          )}
        </div>
        <div className="subTitle1" style={{ marginTop: "2rem" }}>
          {t("notSureHowToStart")}
        </div>
        <div className="subTitle1">- {t("downloadAsvFile")}</div>
        <div className="flex mb-3">
          - {t("download")}
          <a
            // style={{ color: AppColors.naviBlue, marginLeft: "0.5rem" }}
            onClick={() =>
              window.open(
                `https://onlinesale.lotusbeddinggroup.com/storage/exemple/csv-check-sku.csv?v=${new Date().getTime()}`
              )
            }
            className="cursor-pointer ml-1"
          >
            {t("blankCSVTemplate")}
          </a>
        </div>{" "}
        <Button
          onClick={handleOnClickCheckUploadData}
          disabled={isEmpty(uploadSkuData)}
        >
          {onProgress ? <Spin /> : t("check")}
        </Button>
      </div>
    </div>
  );
};

export default CheckSKUUploadView;
