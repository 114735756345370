import FilterButtonGroup from "@common/FilterButtonGroup";
import SalesPerson from "@common/SalesPerson";
import ProductName from "@components/product/components/ProductName";
import {
  CONTRACT_PAYMENT_PROOF_STATUS,
  CONTRACT_PAYMENT_STATUS,
  CONTRACT_STATUS,
  CONTRACT_STATUS_FOR_FILTER,
} from "@constants/contract";
import { DELIVERY_STATUS_LIST, LOGISTIC_TYPES } from "@constants/order";
import { listRental } from "@network/api/rental";
import axios from "axios";
import {
  AwesomeTableComponent,
  HeaderTable,
  Icon,
  TimeUtils,
} from "d-react-components";
import { pick, find, isUndefined } from "lodash";
import React, { ElementRef, useEffect, useMemo, useRef, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import styled from "@emotion/styled";
import classNames from "classnames";
import { Path } from "@components/layout/Path";
import moment from "moment";
import { TranslatedSelect } from "@common/TranslatedSelect";
import SelectFilter, {
  SelectInfinityFilterMultiple,
} from "@common/dropdown/SelectFilter";
import { listUserStore } from "@network/api/store";
import { getListChannel } from "@network/api/channel";
import { IChannel } from "@interfaces/channel";
import SelectWithInput from "@components/shared/SelectWithInput";
import LabelStatus from "@common/LabelStatus";
import { YES_NO_OPTIONS } from "@constants/common";

const SEARCH_TYPES = [
  { label: "contractNo", value: "code" },
  { label: "invoiceNo", value: "invoice" },
];

const SEARCH_CUSTOMER_TYPES = [
  { label: "customerPhoneNo", value: "customer_phone" },
  { label: "customerName", value: "customer_name" },
  { label: "customerEmail", value: "customer_email" },
];

const SEARCH_PRODUCT_TYPES = [{ label: "sku", value: "sku" }];

const SubscriptionContractTable = () => {
  const { CancelToken } = axios;
  const cancelSource = useRef<any>();
  const { t } = useTranslation();
  const [pageParam, setPageParam] = useQueryParam("pageParam", JsonParam);
  const [loading, setLoading] = useState(true);
  const orderTableRef = useRef<ElementRef<typeof AwesomeTableComponent>>(null);
  const [filters, setFilters] = useState<any>({
    search_type: SEARCH_TYPES[0].value,
    search_customer_type: SEARCH_CUSTOMER_TYPES[0].value,
    search_product_type: SEARCH_PRODUCT_TYPES[0].value,
  });

  const columns = useMemo(
    () => [
      {
        title: t("contractNo"),
        dataIndex: "code",
        ellipsis: true,
        width: 180,
        render: (contractId: string, item: any) => (
          <div className="flex flex-col text-gray-400">
            <Link to={`/subscription-contract/${item.id}`}>{contractId}</Link>
            <span className="text-gray-400 text-xs text-ellipsis overflow-hidden">
              {t("createdOn")} {TimeUtils.toDateTime(item.created)}
            </span>
          </div>
        ),
      },
      {
        title: t("pos"),
        dataIndex: "channel",
        width: 250,
        ellipsis: true,
        render: (channel: any, item: any) => {
          return (
            <>
              <div className="flex items-center mb-1">
                <span className="rounded border-[1px] border-primary p-1 text-primary text-xs">
                  {item?.warehouse?.code}
                </span>
                <span className="ml-1 text-ellipsis overflow-hidden">
                  {item?.warehouse?.name}
                </span>
              </div>
              <div className="text-gray-400 text-xs">{channel?.name}</div>
            </>
          );
        },
      },
      {
        title: t("product"),
        dataIndex: "product",
        ellipsis: true,
        width: 250,
        render: (product: any) => <ProductName item={{ product }} />,
      },
      {
        title: t("customer"),
        dataIndex: "customer",
        width: 160,
        ellipsis: true,
        render: (customer: any) => (
          <>
            <div className="text-ellipsis overflow-hidden">
              {customer?.company
                ? customer?.company
                : `${customer?.firstname} ${customer?.lastname}`}
            </div>
            <div>
              <span className="text-gray-400 text-xs text-ellipsis overflow-hidden">
                {customer?.phone}
              </span>
            </div>
          </>
        ),
      },
      {
        title: t("total"),
        dataIndex: "total",
        width: 120,
        ellipsis: true,
        render: (total: any, item: any) => (
          <>
            <CurrencyFormat
              value={total}
              displayType="text"
              thousandSeparator
            />
            <div className="text-gray-400 text-xs text-ellipsis overflow-hidden">
              {item.month} {t("months")}
            </div>
          </>
        ),
      },
      {
        title: t("contractStatus"),
        dataIndex: "status",
        width: 180,
        ellipsis: true,
        render: (status: any, item: any) => {
          const statusItem = find(CONTRACT_STATUS, {
            value: status,
          });
          return (
            <>
              <LabelStatus
                content={t(statusItem?.label as string)}
                color={statusItem?.color}
              />
              <div className="text-gray-400 text-xs">
                {TimeUtils.toDate(item?.created)} -{" "}
                {moment(item?.created)
                  .add(item?.month, "months")
                  .format("DD/MM/YYYY")}
              </div>
            </>
          );
        },
      },
      {
        title: t("paymentStatus"),
        dataIndex: "status_payment",
        width: 110,
        ellipsis: true,
        render: (status: any, item: any) => {
          const statusItem = find(CONTRACT_PAYMENT_STATUS, { value: status });
          return (
            <>
              <div>{t(statusItem?.label ?? "")}</div>
              <div className="flex items-center">
                <div className="text-gray-400 text-xs">
                  {item.count_month_payment} / {item.month}
                </div>
                {item.total_transaction_payment_failed > 0 && (
                  <span className="ml-2 cursor-pointer px-[3px] bg-red-500 text-white text-[10px] inline-block min-w-[18px] text-center h-[20px] rounded whitespace-nowrap">
                    {t("failed")}
                  </span>
                )}
              </div>
            </>
          );
        },
      },
      {
        title: t("paidToDate"),
        dataIndex: "total_payment",
        width: 180,
        ellipsis: true,
        render: (total: any, item: any) => (
          <>
            <CurrencyFormat
              value={total}
              displayType="text"
              thousandSeparator
            />
            <div className="text-gray-400 text-xs">
              <CurrencyFormat
                value={parseFloat(item.total) - parseFloat(item.total_payment)}
                displayType="text"
                thousandSeparator
              />{" "}
              {t("left")} - {item.month - item.count_month_payment}{" "}
              {t("months")} {t("left")}
            </div>
          </>
        ),
      },
      {
        title: t("salesPerson"),
        dataIndex: "salesPerson",
        ellipsis: true,
        width: 180,
        render: (by: any) => {
          return by ? <SalesPerson by={by} /> : null;
        },
      },
    ],
    [t]
  );

  const dataSource = (paging: any) => {
    const body = {
      ...(filters?.search
        ? {
            search_type: filters.search_type,
            search: filters.search,
          }
        : {}),
      ...(filters?.search_product
        ? {
            search_product_type: filters.search_product_type,
            search_product: filters.search_product,
          }
        : {}),
      ...(filters?.search_customer
        ? {
            search_customer_type: filters.search_customer_type,
            search_customer: filters.search_customer,
          }
        : {}),
      ...(!isUndefined(filters?.has_failed_payment) &&
      filters?.has_failed_payment !== "all"
        ? {
            has_failed_payment: filters.has_failed_payment,
          }
        : {}),
      ...pick(filters, [
        // "source",
        "status",
        // "status_sign",
        "status_payment",
        "delivery_status",
        "search_delivery",
        "channel",
        "warehouse",
      ]),
    };
    return listRental(body, paging, {
      cancelToken: new CancelToken((c) => {
        // An executor function receives a cancel function as a parameter
        cancelSource.current = c;
      }),
    }).then((resp) => {
      setLoading(false);
      return resp;
    });
  };

  const reloadList = () => {
    orderTableRef.current?.refresh();
  };

  useEffect(() => {
    reloadList();
  }, [
    filters.status,
    filters.status_payment,
    filters.delivery_status,
    filters.has_failed_payment,
  ]);

  return (
    <div className="">
      <SubscriptionContractFilter
        onClickFilter={() => reloadList()}
        filters={filters}
        setFilters={setFilters}
      />
      <StyledContractTable className="px-3">
        <AwesomeTableComponent
          classNameTable="order-table"
          source={dataSource}
          transformer={(response) => {
            return response?.data?.data?.rent ?? [];
          }}
          columns={columns}
          ref={orderTableRef}
          setCurrentPage={setPageParam}
          showSelectColumn
          keyTableLayout="contract-table"
          tableLayout="fixed"
          className="mt-3"
        />
      </StyledContractTable>
    </div>
  );
};

const SubscriptionContractFilter = ({
  onClickFilter,
  filters,
  setFilters,
}: {
  onClickFilter?: any;
  filters: any;
  setFilters: any;
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [channelList, setChannelList] = useState<IChannel[]>([]);

  useEffect(() => {
    loadChannelList();
  }, []);

  const loadChannelList = () => {
    getListChannel().then((res) => {
      const channelList = res?.data?.data?.channel ?? [];
      setChannelList(channelList);
    });
  };

  const changeFilter = (key: string, value: any) => {
    setFilters({
      ...filters,
      [key]: value,
    });
  };

  const customParams = useMemo(
    () => ({ channel: filters?.channel }),
    [filters?.channel]
  );

  return (
    <>
      <StyledHeaderTable
        label={t("subscriptionContract")}
        onClickNew={() => navigate(Path.SUBSCRIPTION_CONTRACT_CREATE)}
        className="app-layout__page-header bg-white"
        onChangeText={null}
        showFilter={false}
      />
      <div className="px-3 pt-3">
        <div className="bg-white p-3">
          {/* <div className="border-b mb-2 pb-2">
            <FilterButtonGroup
              label="source"
              options={CONTRACT_SOURCE}
              value={filters.source}
              onChange={(value) => changeFilter("source", value)}
            />
          </div> */}
          <div className="md:border-b mb-2 pb-[0px] md:pb-2">
            <FilterButtonGroup
              label="contractStatus"
              options={CONTRACT_STATUS_FOR_FILTER}
              value={filters.status}
              onChange={(value) => changeFilter("status", value)}
            />
          </div>
          <div className="flex md:border-b flex-col md:flex-row items-center md:pb-2 md:mb-2">
            <div className="w-full mb-[0.5rem] md:mb-0 flex-1">
              <FilterButtonGroup
                label="paymentStatus"
                options={CONTRACT_PAYMENT_STATUS}
                value={filters.status_payment}
                onChange={(value) => changeFilter("status_payment", value)}
                className="w-full"
              />
            </div>
            <div className="md:pl-4 w-full mb-[0.5rem] md:mb-0">
              <FilterButtonGroup
                label={t("failedPayment")}
                options={YES_NO_OPTIONS}
                value={filters?.has_failed_payment}
                onChange={(value) => {
                  changeFilter("has_failed_payment", value);
                }}
                className="w-full"
              />
            </div>
          </div>

          <div
            className={classNames("transition-all", {
              "max-h-0 overflow-hidden": !expanded,
              "max-h-[1000px] overflow-auto": expanded,
            })}
          >
            <div className="flex items-center md:border-b mb-2 pb-[0px] md:pb-2">
              <FilterButtonGroup
                label="documentStatus"
                options={CONTRACT_PAYMENT_PROOF_STATUS}
                value={filters.status_sign}
                onChange={(value) => changeFilter("status_sign", value)}
              />
              {/* <div className="ml-4">
                <FilterButtonGroup
                  label="logisticType"
                  options={LOGISTIC_TYPES}
                  value={filters.logisticType}
                  onChange={(value) => changeFilter("logisticType", value)}
                />
              </div> */}
            </div>

            <div className="md:border-b mb-2 pb-[0px] md:pb-2">
              <FilterButtonGroup
                label="deliveryStatus"
                options={DELIVERY_STATUS_LIST}
                value={filters.delivery_status}
                onChange={(value) => changeFilter("delivery_status", value)}
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
              <div className="col-span-1">
                <SelectWithInput
                  prefix={
                    <>
                      <TranslatedSelect
                        onChange={(value) => {
                          changeFilter("search_type", value);
                        }}
                        dataSource={SEARCH_TYPES}
                        value={filters.search_type}
                        getValue={(item) => item.value}
                        getKey={(item) => item.value}
                        allowClear={false}
                      />
                    </>
                  }
                  placeholder={t("search")}
                  suffix={<Icon name="search" />}
                  value={filters.search}
                  onChange={(e) => {
                    changeFilter("search", e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.code === "Enter") {
                      onClickFilter();
                    }
                  }}
                />
              </div>
              <div className="col-span-1">
                <SelectWithInput
                  prefix={
                    <>
                      <TranslatedSelect
                        onChange={(value) => {
                          changeFilter("search_customer_type", value);
                        }}
                        dataSource={SEARCH_CUSTOMER_TYPES}
                        value={filters.search_customer_type}
                        getValue={(item) => item.value}
                        getKey={(item) => item.value}
                        allowClear={false}
                      />
                    </>
                  }
                  placeholder={t("search")}
                  suffix={<Icon name="search" />}
                  value={filters.search_customer}
                  onChange={(e) => {
                    changeFilter("search_customer", e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.code === "Enter") {
                      onClickFilter();
                    }
                  }}
                />
              </div>
              <div className="col-span-1">
                <SelectWithInput
                  placeholder={t("deliveryNo")}
                  suffix={<Icon name="search" />}
                  value={filters.search_delivery}
                  onChange={(e) => {
                    changeFilter("search_delivery", e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.code === "Enter") {
                      onClickFilter();
                    }
                  }}
                />
              </div>
              <div className="col-span-1">
                <SelectWithInput
                  prefix={
                    <>
                      <TranslatedSelect
                        onChange={(value) => {
                          changeFilter("search_product_type", value);
                        }}
                        dataSource={SEARCH_PRODUCT_TYPES}
                        value={filters.search_product_type}
                        getValue={(item) => item.value}
                        getKey={(item) => item.value}
                        allowClear={false}
                      />
                    </>
                  }
                  placeholder={t("search")}
                  suffix={<Icon name="search" />}
                  value={filters.search_product}
                  onChange={(e) => {
                    changeFilter("search_product", e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.code === "Enter") {
                      onClickFilter();
                    }
                  }}
                />
              </div>
              <div className="col-span-1">
                <SelectFilter
                  key="selectChannel"
                  value={filters.channel}
                  getLabel={(item) => item?.name}
                  onChange={(val) => {
                    changeFilter("channel", val);
                  }}
                  placeholder={t("channel")}
                  tagTitle={t("channel")}
                  valueKey="name"
                  dataSource={channelList}
                  showSearch
                  multiple
                  onDropdownVisibleChange={(open) => {
                    if (!open) {
                      onClickFilter();
                    }
                  }}
                  onClear={() => onClickFilter()}
                />
              </div>
              <div className="col-span-1">
                <SelectInfinityFilterMultiple
                  fetchFn={listUserStore}
                  key="selectWarehouse"
                  dataPath="stores"
                  value={filters.warehouse}
                  customQuery={(search) => ({
                    // channel: filters.channel ?? [],
                    search,
                  })}
                  getLabel={(item) => item?.name}
                  dropdownHeight={300}
                  onChange={(val) => {
                    changeFilter("warehouse", val);
                  }}
                  placeholder={t("warehouse")}
                  // disabled={!filters.channel?.length}
                  multiple
                  mode="multiple"
                  // customParams={customParams}
                  valueKey="value"
                  tagTitle={t("warehouse")}
                  onDropdownVisibleChange={(open) => {
                    if (!open) {
                      onClickFilter();
                    }
                  }}
                  onClear={() => onClickFilter()}
                />
              </div>
            </div>
            {/* <div className="flex items-center justify-center mt-3">
              <Button
                iconName="tune"
                size="small"
                className="pl-1"
                style={{ borderRadius: "999px" }}
                onClick={onClickFilter}
              >
                {t("filter")}
              </Button>
            </div> */}
          </div>
          <div className="flex items-center justify-center mt-2">
            <span
              className="text-primary cursor-pointer flex items-center"
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? t("collapse") : t("expand")}
              <Icon name={expanded ? "expand_less" : "expand_more"} />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

const StyledHeaderTable = styled(HeaderTable)`
  .d-input-search {
    display: none;
  }
`;

const StyledContractTable = styled.div`
  .ant-table-thead {
    & > tr {
      & > th {
        padding: 10px 10px;
      }
    }
  }
  .ant-table-tbody {
    & > tr {
      & > td {
        padding: 10px 10px;
        @media screen and (max-width: 1000px) {
          padding-left: 40%;
        }
      }
    }
  }
`;

export default SubscriptionContractTable;
