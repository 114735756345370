import CustomerSelectDrawer from "@components/customer/component/CustomerSelectDrawer";
import { ONE_OF_CONDITION_TYPES } from "@constants/cart-rule";
import AppContext from "@helpers/context";
import { getFullName } from "@helpers/string";
import {
  AwesomeTableComponent,
  Button,
  Icon,
  InputTextSearch,
  ObjectUtils,
  Select,
} from "d-react-components";
import { filter, includes, isEmpty, map, toLower, uniqBy } from "lodash";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const CONDITION_TYPE = ObjectUtils.mapObjectToArray(ONE_OF_CONDITION_TYPES);
const CustomerCondition = ({
  payload = {},
  setChangeCondition,
  conditionList,
  indexText = "",
  handleDeleteCondition,
}: any) => {
  const { cartRuleForm, cartRuleConditionDisabled } = useContext(AppContext);
  const [textSearch, setTextSearch] = useState("");
  const formValues = cartRuleForm?.values ?? {};
  const { t } = useTranslation();
  const channelRef = useRef();
  const [openSelectCustomer, setOpenSelectCustomer] = useState(false);
  useEffect(
    () => (channelRef.current = formValues?.selectedChannel),
    [formValues?.selectedChannel]
  );

  const customerList = useMemo(() => {
    if (isEmpty(textSearch)) {
      return payload.customer;
    }
    return filter(payload.customer, (customer) =>
      includes(
        toLower(
          customer?.firstname +
            customer?.lastname +
            customer?.nickname +
            customer?.customerid
        ),
        toLower(textSearch)
      )
    );
  }, [textSearch, payload.customer]);

  const renderCustomerSelectedInfo = (customer: any) => {
    return (
      <div className="subTitle1 customerSelectInfo">
        {getFullName(customer)}
      </div>
    );
  };

  const onSelectCustomer = (customers: any) => {
    const { customer = [] } = payload;
    const customerResult = uniqBy(
      [...customer, ...customers],
      (item) => item.id
    );
    const clone = { ...payload, customer: customerResult };
    onChangeConditions(clone);
    setOpenSelectCustomer(false);
  };

  const onDeleteCustomer = (customerDelete: any) => {
    const { customer = [] } = payload;
    const results = filter(
      customer,
      (item) => item.customerid !== customerDelete.customerid
    );
    const clone = { ...payload, customer: results };
    onChangeConditions(clone);
  };

  const onChangeConditions = (clone: any) => {
    const cloneList = map(conditionList, (item) => {
      if (item.id === payload.id) {
        return clone;
      }
      return item;
    });
    setChangeCondition(cloneList);
  };

  const columns: any[] = [
    {
      title: t("id"),
      dataIndex: "customerid",
    },
    {
      title: t("customerName"),
      dataIndex: "",
      ellipsis: true,
      render: (customer: any) => renderCustomerSelectedInfo(customer),
    },

    {
      title: t("email"),
      dataIndex: "email",
    },

    {
      title: t("phone"),
      dataIndex: "phone",
    },
  ];

  if (!cartRuleConditionDisabled) {
    columns.push({
      title: t("action"),
      dataIndex: "",
      render: (customer: any) => {
        return (
          <Icon name="delete" onClick={() => onDeleteCustomer(customer)} />
        );
      },
    });
  }
  const handleChangeRuleCondition = (changedPayload: any) => {
    const cloneList = map(conditionList, (item) => {
      if (item.id === changedPayload.id) {
        return changedPayload;
      }
      return item;
    });
    setChangeCondition(cloneList);
  };

  return (
    <div className="mt-2 border border-primary">
      <div className="flex-row-between-center bg-primary px-2">
        <div className="flex-row align-items-center">
          <text className="font-weight-bold text-white">
            {`${t("condition")} ${indexText}: `}
          </text>
          <div className="ml-1 text-white">{t("customer")}</div>
          <Select
            className="ml-2 cart-rule__condition-select"
            dataSource={CONDITION_TYPE}
            disabled={cartRuleConditionDisabled}
            getLabel={(option) => t(option.label)}
            variant="standard"
            value={payload?.rule}
            onChange={(value) => {
              handleChangeRuleCondition({
                ...payload,
                rule: value,
              });
            }}
          />
        </div>
        {!cartRuleConditionDisabled && (
          <Icon
            name="remove_circle"
            color="white"
            onClick={handleDeleteCondition}
          />
        )}
      </div>
      <div className="p-3">
        <div className="flex-row-between-center">
          <InputTextSearch
            onChange={(event: any) => setTextSearch(event.target.value)}
          />
          {!cartRuleConditionDisabled && (
            <Button iconName="add" onClick={() => setOpenSelectCustomer(true)}>
              {t("add")}
            </Button>
          )}
          {openSelectCustomer && (
            <CustomerSelectDrawer
              open={openSelectCustomer}
              onClose={() => setOpenSelectCustomer(false)}
              onSave={onSelectCustomer}
              channelId={formValues?.selectedChannel?.id}
              defaultSelected={payload?.customer ?? []}
            />
          )}
        </div>
        <AwesomeTableComponent
          className="mt-2"
          dataSource={customerList}
          columns={columns}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default CustomerCondition;
