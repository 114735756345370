import { getBrandList, getCategoryList } from "@network/api/product";
import classNames from "classnames";
import { Button, CheckboxGroup, Drawer } from "d-react-components";
import { find, map } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const INIT_FILTER: any = {
  brand: [],
  category: [],
};
const ModalFilterSearchProduct = ({ open, onClose, onClickSave }: any) => {
  const [listBrand, setListBrand] = useState([]);
  const [listCategory, setListCategory] = useState([]);
  const [filterBody, setFilterBody] = useState<any>(INIT_FILTER);
  const { t } = useTranslation();

  useEffect(() => {
    getCategoryListData();
    getBrandListData();
  }, []);

  const getCategoryListData = () => {
    getCategoryList().then((response: any) => {
      const listCategory = response.data?.data?.category ?? [];
      setListCategory(listCategory);
    });
  };

  const getBrandListData = () => {
    getBrandList().then((response: any) => {
      const listBrand = response.data?.data?.brand ?? [];
      setListBrand(listBrand);
    });
  };

  return (
    <Drawer open={open} onClose={onClose} title={t("filter")}>
      <div className="pb-12">
        <CheckboxGroup
          dataSource={listBrand}
          getLabel={(item) => item.name}
          label={t("brand")}
          value={map(filterBody?.brand ?? [], (item) => item.id)}
          onChange={(values) => {
            const selected = map(values, (id) => find(listBrand, { id }));
            setFilterBody({ ...filterBody, brand: selected });
          }}
          numberOfColumns="2"
        />
        <CheckboxGroup
          dataSource={listCategory}
          label={t("category")}
          className="mt-3"
          value={map(filterBody?.category ?? [], (item) => item.id)}
          onChange={(values) => {
            const selectedCategory = map(values, (id) =>
              find(listCategory, { id })
            );
            setFilterBody({ ...filterBody, category: selectedCategory });
          }}
          getLabel={(item) => item.name}
          numberOfColumns="2"
        />
      </div>
      <div className="position-absolute bottom-0 w-100 end-0 start-0 flex-center-y justify-content-end py-3 px-3 bg-white">
        <Button
          className="mr-2"
          onClick={() => {
            onClickSave && onClickSave(filterBody);
          }}
        >
          {t("save")}
        </Button>
      </div>
    </Drawer>
  );
};

const ProductFilterInSearchButton = ({
  onSaveFilter,
  classNameButton = "ml-3",
}: any) => {
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  const onClickSaveFilter = async (filterBody: any) => {
    onSaveFilter && onSaveFilter(filterBody);
    setOpenModal(false);
    return Promise.resolve();
  };
  return (
    <div className={classNames(classNameButton)}>
      <Button iconName="filter_list" onClick={() => setOpenModal(true)}>
        {t("filter")}
      </Button>
      <ModalFilterSearchProduct
        open={openModal}
        onClose={() => setOpenModal(false)}
        onClickSave={onClickSaveFilter}
      />
    </div>
  );
};

export default ProductFilterInSearchButton;
