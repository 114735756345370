/* eslint-disable jsx-a11y/alt-text */
import Icon from "@common/Icon";
import Drawer from "@components/shared/Drawer";
import AppContext from "@helpers/context";
import { uploadFile } from "@network/api/common";
import { editProduct } from "@network/api/product";
import {
  Button,
  Icon as DIcon,
  Notifications,
  Progress,
} from "d-react-components";
import { useFormik } from "formik";
import { filter, forEach, includes, isEmpty, map } from "lodash";
import { useContext, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import Carousel, { Modal, ModalGateway } from "react-images";

interface IProductDetailGalleryEditDrawer {
  open: boolean;
  defaultValue?: any;
  onUpdated: any;
  onClose: () => void;
}

const MediaItem = ({ media, onRemoveMedia }: any) => {
  const getSourceMedia = (media: any) => {
    if (media?.imageData) {
      return media.imageData;
    }

    if (!isEmpty(media?.url)) {
      return media?.url;
    }

    if (!isEmpty(media)) {
      return media;
    }

    return "/images/image-default.png";
  };
  return (
    <div className="mr-3 mt-3">
      <div className="position-relative width-fit-content">
        <img src={getSourceMedia(media)} className="image-reg-x-large" />
        <div
          onClick={() => onRemoveMedia(media)}
          className="position-absolute bg-danger p-1"
          style={{ right: 0, top: 0 }}
        >
          <DIcon name="delete" className="text-white" />
        </div>
      </div>
    </div>
  );
};

const ProductDetailGalleryEditDrawer = ({
  open,
  defaultValue,
  onUpdated,
  onClose,
}: IProductDetailGalleryEditDrawer) => {
  const { t } = useTranslation();
  const productForm = useFormik<any>({
    initialValues: defaultValue as any,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: schema,
    onSubmit: (values: any) => {
      onUpdateProduct(values);
    },
  });
  const formValues = productForm?.values;
  const formErrors = productForm?.errors;
  const { imageurl, imageToUpload = [] } = formValues;

  const onUpdateProduct = (values: any) => {
    const { imageurl, imageToUpload } = values;

    console.log("imageurl", imageurl);
    console.log("imageToUpload", imageToUpload);

    const body: any = {
      productid: defaultValue?.id,
      add_image: map(imageToUpload, (item) => item.path),
      remove_image: filter(
        defaultValue?.imageurl,
        (url) => !includes(imageurl, url)
      ),
    };

    console.log("body", body);

    Progress.show({ method: editProduct, params: [body] }, (res: any) => {
      Notifications.showSuccess(t("updateSuccess"));
      onUpdated();
      onClose();
    });
  };

  const onDrop = (files: any) => {
    const uploadBodyList: any[] = [];
    console.log("File upload ", files);
    forEach(files, (file, index: number) => {
      const reader = new FileReader();
      const url = reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        const body = new FormData();
        body.append("file", file);
        uploadBodyList.push({ method: uploadFile, params: [body] });
        if (index === files.length - 1) {
          Progress.show(uploadBodyList, (res: any) => {
            const result = map(res, (item) => item.data.data);

            productForm.setFieldValue("imageToUpload", [
              ...imageToUpload,
              ...result,
            ]);
          });
        }
      };
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const onRemoveMediaUploading = (media: any) => {
    const result = filter(imageToUpload, (item) => item.id !== media.id);
    productForm.setFieldValue("imageToUpload", result);
  };

  const onRemoveMediaUploaded = (media: any) => {
    const result = filter(imageurl, (item) => item !== media);
    productForm.setFieldValue("imageurl", result);
  };

  console.log("imageurl", imageurl);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("gallery")}
      size="auto"
      width="80vw"
      onSave={() => {
        productForm.handleSubmit();
      }}
    >
      <div>
        <div className="d-flex flex-wrap border p-3">
          {map(imageurl, (media, index: number) => (
            <MediaItem media={media} onRemoveMedia={onRemoveMediaUploaded} />
          ))}
          {map(imageToUpload, (media, index: number) => (
            <MediaItem media={media} onRemoveMedia={onRemoveMediaUploading} />
          ))}
          <div className="mr-3 mt-3">
            <div
              className="image-reg-x-large border-dashed flex-center flex-column p-3"
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <Button variant="trans" className="text-primary">
                {t("addFiles")}
              </Button>
              <small>{t("orDragDropHere")}</small>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

const ProductDetailGallery = () => {
  const { productDetail, loadProductDetail } = useContext(AppContext);
  const [openEdit, setOpenEdit] = useState(false);
  const { t } = useTranslation();

  const [viewImageModal, setViewImageModal] = useState(false);
  const [imageIndex, setImageIndex] = useState<number>();

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mt-3">
        <h5>{t("gallery")}</h5>
        <span onClick={() => setOpenEdit(true)}>
          <Icon
            className="w-[15px] cursor-pointer"
            src="/images/icons/edit.svg"
          />
        </span>
      </div>
      <div className="mt-3 border p-4 ">
        <div className="flex-row flex-wrap">
          {map(productDetail?.imageurl, (media, index: number) => (
            <div className="mr-3 mt-3">
              <div className="position-relative width-fit-content">
                <img
                  src={media}
                  className="image-reg-x-large"
                  onClick={() => {
                    setImageIndex(index as any);
                    setViewImageModal(true);
                  }}
                />
              </div>
            </div>
          ))}
        </div>
        <ModalGateway>
          {viewImageModal ? (
            <Modal onClose={() => setViewImageModal(false)}>
              <Carousel
                currentIndex={imageIndex}
                views={
                  map(productDetail?.imageurl, (media) => {
                    return {
                      src: media,
                    };
                  }) as any
                }
              />
            </Modal>
          ) : null}
        </ModalGateway>
        {openEdit && (
          <ProductDetailGalleryEditDrawer
            open={openEdit}
            onClose={() => setOpenEdit(false)}
            defaultValue={productDetail}
            onUpdated={loadProductDetail}
          />
        )}
      </div>
    </div>
  );
};

export default ProductDetailGallery;
