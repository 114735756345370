import { PRODUCT_VISIBILITIES } from "@constants/product";
import AppContext from "@helpers/context";
import { IProduct } from "@interfaces/product";
import {
  Icon,
  IRowsKey,
  StringUtils,
  TimeUtils,
  ViewLabelStatus,
  ViewRowInterchange,
} from "d-react-components";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

const ProductDetailInfo = () => {
  const { productDetail } = useContext(AppContext);
  const { name, code } = productDetail ?? {};
  const { t } = useTranslation();

  const DETAILS_KEY = useMemo<IRowsKey<IProduct>[]>(
    () => [
      {
        id: "sku",
        label: t("sku"),
      },
      {
        id: "category",
        label: t("category"),
        renderContent: ({ data, item }) => {
          return data?.name ?? "N/A";
        },
      },
      {
        id: "brand",
        label: t("brand"),
        renderContent: ({ data, item }) => {
          return data?.name ?? "N/A";
        },
      },
      {
        id: "model",
        label: t("model"),
        renderContent: ({ data, item }) => {
          return data?.name ?? "N/A";
        },
      },
      {
        id: "warrantyPeriod",
        label: t("warranty"),
        renderContent: ({ data, item }) => {
          return data + " months";
        },
      },
      {
        id: "regularPrice",
        label: t("regularPrice"),
        renderContent: ({ data, item }) => StringUtils.moneyThaiFormat(data),
      },
      {
        id: "salePrice",
        label: t("salePrice"),
        renderContent: ({ data, item }) => StringUtils.moneyThaiFormat(data),
      },
      {
        id: "created",
        label: t("createdAt"),
        renderContent: ({ data, item }) =>
          data ? TimeUtils.toDateTime(data) : "N/A",
      },
    ],
    [t]
  );

  return (
    <div>
      <div className="border p-4 flex-column flex-center pt-5">
        <img
          src={productDetail?.imageurl?.[0] ?? "/images/img-default.png"}
          className="image-square-medium rounded-1"
        />
        <h4 className="mt-3 text-center text-primary">{name}</h4>
        <ViewLabelStatus
          listStatus={PRODUCT_VISIBILITIES}
          status={productDetail?.visibility}
          className="mt-3"
        />
      </div>
      <div className="border p-3">
        <ViewRowInterchange
          className="w-100"
          dataSource={productDetail}
          keyList={DETAILS_KEY as any}
          variant="border"
          classNameLabel="text-hint"
          classNameContent="text-end"
        />
      </div>
    </div>
  );
};

export default ProductDetailInfo;
