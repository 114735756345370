/* eslint-disable jsx-a11y/alt-text */
import Icon from "@common/Icon";
import AppContext from "@helpers/context";
import { IProduct } from "@interfaces/product";
import { getProductListStock } from "@network/api/product";
import { IRowsKey, ViewRowInterchange } from "d-react-components";
import { reduce } from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const ProductDetailInventory = () => {
  const { productDetail, loadProductDetail } = useContext(AppContext);
  const [openEdit, setOpenEdit] = useState(false);
  const { t } = useTranslation();
  const [stockList, setStockList] = useState([]);

  useEffect(() => {
    loadStockList();
  }, []);

  const loadStockList = () => {
    getProductListStock(productDetail?.id ?? "").then((res) => {
      setStockList(res?.data?.data?.stock ?? []);
    });
  };

  const totalStock = useMemo(
    () => reduce(stockList, (sum, stock: any) => sum + stock?.stock, 0),
    [stockList]
  );

  const DETAILS_KEY = useMemo<IRowsKey<IProduct>[]>(
    () => [
      {
        id: "inventory",
        label: t("quantity"),
        renderContent: ({ data, item }) => totalStock,
      },
    ],
    [t]
  );

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mt-3">
        <h5>{t("inventory")}</h5>
        {/* <span onClick={() => setOpenEdit(true)}>
          <Icon
            className="w-[15px] cursor-pointer"
            src="/images/icons/edit.svg"
          />
        </span> */}
      </div>
      <div className="mt-3 border p-4 ">
        <ViewRowInterchange
          dataSource={productDetail}
          keyList={DETAILS_KEY as any}
          variant="border"
          classNameLabel="text-hint"
          classNameContent="text-end "
        />
      </div>
    </div>
  );
};

export default ProductDetailInventory;
