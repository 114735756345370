import FilterDropdown from "@common/dropdown/FilterDropdown";
import ChannelDropdownSelect from "@components/channel/components/ChannelDropdownSelect";
import InventoryEventDropDownSelect from "@components/invenotry-control-event/components/InventoryEventDropDownSelect";
import StoreDropdownSelect from "@components/store/components/StoreDropdownSelect";
import { INVENTORY_STATUS_LIST } from "@constants/inventory";
import { PRODUCT_TYPES } from "@constants/product";
import { Select } from "d-react-components";
import { map } from "lodash";
import { useTranslation } from "react-i18next";

interface IInventoryTableQuickFilter {
  value: any;
  onChange: any;
}

const InventoryTableQuickFilter = ({
  value = {},
  onChange,
}: IInventoryTableQuickFilter) => {
  const { t } = useTranslation();

  const onChangeValue = (newValues = {}) => {
    const result = { ...value, ...newValues };

    onChange(result);
  };

  return (
    <div className="bg-primary-25 p-3 d-flex flex-wrap">
      <ChannelDropdownSelect
        className="w-min-content"
        value={value?.channels || []}
        onChange={(value) => {
          onChangeValue({ channels: value, warehouses: [], events: [] });
        }}
      />
      <StoreDropdownSelect
        channelIds={map(value?.channels, (item) => item.id)}
        className="w-min-content ml-3"
        value={value?.warehouses || []}
        onChange={(value) => {
          onChangeValue({ warehouses: value, events: [] });
        }}
      />

      <InventoryEventDropDownSelect
        storeIds={map(value?.warehouses, (item) => item.id)}
        className="w-min-content ml-3"
        value={value?.events || []}
        onChange={(value) => {
          onChangeValue({ events: value });
        }}
        hideWhenNoEvent
        status={null}
      />
      <Select
        className="w-48 ml-3"
        value={value?.status}
        onChange={(value) => {
          onChangeValue({ status: value });
        }}
        dataSource={INVENTORY_STATUS_LIST}
        placeholder="Inventory Status"
      />

      <FilterDropdown
        selectProps={{
          placeholder: t("search"),
          multiple: true,
          mode: "multiple",
          value: value?.productType,
          getLabel: (item) => t(item?.label) ?? "N/A",
          dataSource: PRODUCT_TYPES ?? [],
          onChange: (value) => {
            onChangeValue({ productType: value });
          },
          className: "mb-3",
          tagTitle: t("productType"),
          getValue: (item) => item.id,
          allowCreateNew: false,
        }}
        type="normal"
        className="w-min-content ml-3"
      />
    </div>
  );
};

export default InventoryTableQuickFilter;
