import AppContext from "@helpers/context";
import classNames from "classnames";
import { Button } from "d-react-components";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

const CustomerDetailHeader = () => {
  const { customerDetail } = useContext(AppContext);
  const { firstname, lastname } = customerDetail ?? {};
  const { t } = useTranslation();

  return (
    <div className="card-container p-3 flex-center justify-content-between border-bottom">
      <div className="flex-column">
        <div className="d-flex">
          <h4 className={classNames("text-[14px] text-primary")}>
            {firstname + " " + lastname}
          </h4>
        </div>
      </div>
      <div className="flex-row">
        <Button onClick={() => {}} className="">
          {t("remove")}
        </Button>
      </div>
    </div>
  );
};

export default CustomerDetailHeader;
