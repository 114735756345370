/* eslint-disable jsx-a11y/alt-text */
import Icon from "@common/Icon";
import Drawer from "@components/shared/Drawer";
import { PRODUCT_TYPES } from "@constants/product";
import AppContext from "@helpers/context";
import { IProduct } from "@interfaces/product";
import {
  IRowsKey,
  Notifications,
  Progress,
  ViewRowInterchange,
} from "d-react-components";
import { useFormik } from "formik";
import { find, map } from "lodash";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ProductInputAttribute from "../input/ProductInputAttribute";
import { editProduct } from "@network/api/product";
import { useParams } from "react-router-dom";

interface IProductDetailAttributeDrawer {
  open: boolean;
  defaultValue?: any;
  onUpdated: any;
  onClose: () => void;
}

const ProductDetailAttributeDrawer = ({
  open,
  defaultValue,
  onUpdated,
  onClose,
}: IProductDetailAttributeDrawer) => {
  const { t } = useTranslation();
  const { productId } = useParams();

  const productForm = useFormik<any>({
    initialValues: defaultValue as any,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: schema,
    onSubmit: (values: any) => {
      onUpdateProduct(values);
    },
  });
  const formValues = productForm?.values;
  const formErrors = productForm?.errors;

  const onUpdateProduct = (values: any) => {
    const { attributeList, warranty } = values;

    const body = {
      productid: productId,
      attribute_values: map(attributeList, (item) => item.value),
    };

    Progress.show(
      {
        method: editProduct,
        params: [body],
      },
      (res: any) => {
        Notifications.showSuccess(t("updateSuccess"));
        onUpdated();
        onClose();
      }
    );
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("configuration")}
      size="auto"
      width="80vw"
      onSave={() => {
        productForm.handleSubmit();
      }}
    >
      <div>
        <ProductInputAttribute productForm={productForm} />
      </div>
    </Drawer>
  );
};

const ProductDetailAttribute = () => {
  const { productDetail, loadProductDetail } = useContext(AppContext);
  const [openEdit, setOpenEdit] = useState(false);
  const { t } = useTranslation();

  const DETAILS_KEY = useMemo<IRowsKey<IProduct>[]>(
    () => [
      {
        id: "productType",
        label: t("type"),
        renderContent: ({ data, item }) => {
          const productType = find(PRODUCT_TYPES, (item) => item.id === data);
          return t(productType?.label as any);
        },
      },
    ],
    [t]
  );

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mt-3">
        <h5>{t("attribute")}</h5>
        <span onClick={() => setOpenEdit(true)}>
          <Icon
            className="w-[15px] cursor-pointer"
            src="/images/icons/edit.svg"
          />
        </span>
      </div>
      <div className="mt-3 border p-4 ">
        {map(productDetail.attributeList, (attr) => (
          <div className="border-bottom flex-row justify-content-between py-2">
            <div className="text-small w-100 font-weight-bold ">
              {attr.label}
            </div>
            <div className="w-100 text text-end ">{attr?.valueName?.name}</div>
          </div>
        ))}

        {openEdit && (
          <ProductDetailAttributeDrawer
            open={openEdit}
            onClose={() => setOpenEdit(false)}
            defaultValue={productDetail}
            onUpdated={loadProductDetail}
          />
        )}
      </div>
    </div>
  );
};

export default ProductDetailAttribute;
