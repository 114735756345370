import UserAvatarName from "@components/shared/UserAvatarName";
import AppContext from "@helpers/context";
import { IDelivery } from "@interfaces/delivery";
import { IRowsKey, TimeUtils, ViewRowInterchange } from "d-react-components";
import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

const DeliverySODetailVehicle = () => {
  const { deliveryDetail } = useContext(AppContext);
  const { t } = useTranslation();

  const DETAILS_KEY = useMemo<IRowsKey<IDelivery>[]>(
    () => [
      {
        id: "vehicle",
        label: t("vehicle"),
        renderContent: ({ data, item }) => data?.registration,
      },
      {
        id: "driver",
        label: t("driver"),
        renderContent: ({ data, item }) => <UserAvatarName user={data} />,
      },
      {
        id: "assigned_at",
        label: t("assignedOn"),
        renderContent: ({ data, item }) => TimeUtils.toDateTime(data),
      },
    ],
    [t]
  );

  return (
    <div className="card-container p-4 ">
      <div className="d-flex justify-content-between align-items-center">
        <label>{t("driverVehicle")}</label>
      </div>
      <ViewRowInterchange
        dataSource={deliveryDetail}
        keyList={DETAILS_KEY as any}
        variant="border"
        classNameLabel="font-weight-bold "
      />
    </div>
  );
};

export default DeliverySODetailVehicle;
