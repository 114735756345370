import {
  Header,
  Notifications,
  ObjectUtils,
  Progress,
  TabBar,
} from "d-react-components";
import { useFormik } from "formik";
import { forEach, map } from "lodash";
import React, { useEffect, useState } from "react";
import UserCreateAuthentication from "./UserCreateAuthentication";
import UserCreatePassword from "./UserCreatePassword";
import UserCreatePersonalInfo from "./UserCreatePersonalInfo";
import UserCreateSetting from "./UserCreateSetting";
import { useTranslation } from "react-i18next";
import { USER_KEYS, UserSchema } from "@constants/user";
import UserAPI from "@network/api/user";
import AppContext from "@helpers/context";
import { generatePath, useNavigate } from "react-router-dom";
import { Path } from "@components/layout/Path";

const UserCreate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const TAB_LIST = [
    {
      id: "personal",
      label: t("personalInfo"),
      component: <UserCreatePersonalInfo />,
    },
    {
      id: "password",
      label: t("password"),
      component: <UserCreatePassword />,
    },
    {
      id: "settings",
      label: t("settings"),
      component: <UserCreateSetting />,
    },
    {
      id: "authentication",
      label: t("authentication"),
      component: <UserCreateAuthentication />,
    },
  ];
  const [selectedTab, setSelectedTab] = useState<any>(TAB_LIST[0]);

  const userForm = useFormik({
    initialValues: {
      orderStatus: [],
      deliveryStatus: [],
      block: false,
      authStatus: false,
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: UserSchema,
    onSubmit: (values: any) => {
      if (values.password !== values.reTypePassword) {
        Notifications.showError(t("passwordNotMatch"));
        return;
      }
      const input = ObjectUtils.mapFieldsLangsCTS(values, USER_KEYS as any);
      Progress.show({ method: UserAPI.create, params: [input] }, (res: any) => {
        Notifications.showSuccess(t("createUserSuccessfully"));
        // window.open(res?.data?.data?.editurl, "_self");
        navigate(generatePath(Path.USER_DETAIL, { userId: res?.data?.data?.id}))
      });
    },
  });

  useEffect(() => {
    forEach(Object.keys(userForm.errors), (key) =>
      Notifications.showError(`${(userForm.errors as any)?.[key]}`)
    );
  }, [userForm.errors]);

  return (
    <AppContext.Provider value={{ userForm }}>
      <div className="p-4 bg-muted">
        <Header title={t("newUser")} onSave={() => userForm.handleSubmit()} />
        <div className="row mt-3">
          <div className="col-4 card-container">
            <TabBar
              dataSource={TAB_LIST}
              variant="vertical"
              onChange={(tab) => setSelectedTab(tab)}
              value={selectedTab}
            />
          </div>
          <div className="col-8">
            {map(TAB_LIST, (item) => (
              <div hidden={item.id !== selectedTab?.id}>{item?.component}</div>
            ))}
          </div>
        </div>
      </div>
    </AppContext.Provider>
  );
};

export default UserCreate;
