import { Path } from "@components/layout/Path";
import AppContext from "@helpers/context";
import { createCustomerShipping } from "@network/api/address";
import { createNewCustomer, uploadCustomerAvatar } from "@network/api/customer";
import { Spin } from "antd";
import { Button, Notifications } from "d-react-components";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import CustomerInputInfo from "../input/CustomerInputInfo";
import CustomerInputShipping from "../input/CustomerInputShipping";
import * as Yup from "yup";
import { CustomerType } from "@interfaces/customer";

export const ShippingSchema = Yup.object().shape({
  firstname: Yup.string().required("Field is required!"),
  lastname: Yup.string().required("Field is required!"),
  nickname: Yup.string().required("Field is required!"),
  phone: Yup.string().required("Field is required!"),
  province: Yup.object().required("Field is required!"),
  district: Yup.object().required("Field is required!"),
  subDistrict: Yup.object().required("Field is required!"),
  postCode: Yup.string().required("Field is required!"),
  typeOfPlace: Yup.string().required("Field is required!"),
  address: Yup.string().required("Field is required!"),
});

const CustomerCreate = (props: any) => {
  const FORM_INIT_VALUE = {
    firstname: "",
    lastname: "",
    nickname: "",
    company: "",
    gender: undefined,
    birthday: "",
    phone: "",
    email: "",
    note: "",
    customer_type: CustomerType.INDIVIDUAL,
    channel: undefined,
  };
  const SHIPPING_INIT_VALUE = {
    firstname: "",
    lastname: "",
    nickname: "",
    address: "",
    city: "",
    province: "",
    postcode: "",
    phone: "",
    place: "",
  };

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const CustomerSchema = Yup.lazy((value) => {
    let schema = Yup.object().shape({
      customer_type: Yup.string().required(t("fieldRequired")),
      channel: Yup.mixed().required(t("fieldRequired")),
      phone: Yup.number().required(t("fieldRequired")),
    });
    if (value.customer_type === CustomerType.BUSINESS) {
      schema = schema.concat(
        Yup.object().shape({
          representative_first_name: Yup.string().required(t("fieldRequired")),
          representative_last_name: Yup.string().required(t("fieldRequired")),
          email: Yup.string().required(t("fieldRequired")),
          company: Yup.string().required(t("fieldRequired")),
          tax_id: Yup.string().required(t("fieldRequired")),
        })
      );
    }
    if (value.customer_type === CustomerType.INDIVIDUAL) {
      schema = schema.concat(
        Yup.object().shape({
          firstname: Yup.string().required(t("fieldRequired")),
          lastname: Yup.string().required(t("fieldRequired")),
          nickname: Yup.string().required(t("fieldRequired")),
          citizen_id: Yup.string().test("len", t("citizenIdMustBe13"), (val) =>
            !isEmpty(val) ? val?.length === 13 : true
          ),
        })
      );
    }
    return schema;
  });

  const customerInfoForm = useFormik<any>({
    initialValues: FORM_INIT_VALUE as any,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: CustomerSchema,
    onSubmit: (values: any) => {
      onCreateCustomer(values);
    },
  });

  const shippingForm = useFormik<any>({
    initialValues: SHIPPING_INIT_VALUE as any,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: ShippingSchema,
    onSubmit: (values: any) => {},
  });

  const formValues = customerInfoForm?.values;
  const formErrors = customerInfoForm?.errors;

  const uploadAvatarCustomer = (respone: any) => {
    const { avatar } = formValues;
    const id = respone?.data?.data?.customers?.id ?? null;
    const data = new FormData();
    data.append("customerid", id);
    data.append("file", avatar);
    return uploadCustomerAvatar(data);
  };

  const createShippingAddress = (respone: any) => {
    const id = respone?.data?.data?.customers?.id ?? null;
    const url = respone?.data?.data?.editurl ?? "";
    const {
      firstname,
      lastname,
      nickname,
      province,
      district,
      postCode,
      address,
      subDistrict,
      phone,
      typeOfPlace,
    } = shippingForm.values;
    const bodyShipping = {
      firstname,
      lastname,
      nickname: isEmpty(nickname) ? firstname : nickname,
      phone,
      province: province?.id ?? "",
      city: district?.id ?? "",
      subdistrict: subDistrict?.id ?? "",
      postcode: postCode,
      address,
      customerid: id,
      default: true,
      place: typeOfPlace,
    };
    return createCustomerShipping(bodyShipping);
  };

  const onCreateCustomerSuccess = (customerId: string) => {
    Notifications.showSuccess(t("createCustomerSuccess"));
    setLoading(false);
    navigate(generatePath(Path.CUSTOMER_DETAIL, { customerId }));
  };

  const onCreateCustomer = async (values: any) => {
    await shippingForm.validateForm();
    const isValidShipping = !Object.keys(shippingForm.errors)?.length;
    if (!isValidShipping) {
      return;
    }
    const {
      firstname,
      lastname,
      nickname,
      phone,
      email,
      birthday,
      company,
      gender,
      customer_type,
      note,
      channel,
      avatar,
      alternative_phone,
      representative_first_name,
      representative_last_name,
      representative_email,
      representative_phone,
      tax_id,
      citizen_id,
      otp_phone_id,
      otp_email_id,
      is_verify_phone,
      is_verify_email,
    } = values;
    const { province, district, postCode, address, subDistrict } =
      shippingForm?.values;
    const body = {
      customer_type: customer_type,
      channel_id: channel?.id,
      province: province?.id ?? "",
      city: district?.id ?? "",
      subdistrict: subDistrict?.id ?? "",
      postcode: postCode,
      note,
      ...(values?.customer_type === CustomerType.INDIVIDUAL
        ? {
            firstname,
            lastname,
            phone,
            alternative_phone,
            email,
            nickname: isEmpty(nickname) ? firstname : nickname,
            gender,
            citizen_id,
            birthday: birthday ? birthday.valueOf() : null,
            ...(otp_phone_id && is_verify_phone ? { otp_phone_id } : {}),
            ...(otp_email_id && is_verify_email ? { otp_email_id } : {}),
          }
        : {
            company,
            tax_id,
            phone,
            email,
            representative_first_name,
            representative_last_name,
            representative_email,
            representative_phone,
            ...(otp_email_id && is_verify_email ? { otp_email_id } : {}),
          }),
    };
    try {
      const respone = await createNewCustomer(body);
      const customer = respone?.data?.data?.customers ?? {};
      const url = respone?.data?.data?.editurl ?? "";
      if (isEmpty(customer)) {
        const message = respone?.data?.message ?? "Error";
        Notifications.showError(message);
        setLoading(false);
        return;
      }
      if (avatar) {
        await uploadAvatarCustomer(respone);
      }

      await createShippingAddress(respone);
      onCreateCustomerSuccess(customer?.id);
    } catch (error) {
      setLoading(false);
      Notifications.showError(JSON.stringify(error));
    }
  };

  const renderPublicButton = () => {
    if (loading) {
      return (
        <Button className="ml-3">
          <Spin />
        </Button>
      );
    }
    return (
      <Button
        onClick={() => {
          customerInfoForm.handleSubmit();
        }}
        className="ml-3"
      >
        {t("create")}
      </Button>
    );
  };

  const renderHeader = () => {
    const { name } = formValues;
    return (
      <div className="p-3 bg-white flex-center justify-content-between border-bottom">
        <h4 className="text-primary">{name ? name : t("createCustomer")}</h4>
        {renderPublicButton()}
      </div>
    );
  };

  return (
    <AppContext.Provider
      value={{
        customerInfoForm,
        shippingForm,
      }}
    >
      {renderHeader()}
      <div className="p-3 bg-white">
        <div className="mt-3">
          <div className="label">{t("customerInformation")}</div>
          <CustomerInputInfo
            customerForm={customerInfoForm}
            className="border p-3 mt-3"
          />
        </div>
        <div className="mt-3">
          <div className="label">{t("customerShipping")}</div>
          <div className="border p-3 mt-3">
            <CustomerInputShipping customerForm={shippingForm} />
          </div>
        </div>
      </div>
    </AppContext.Provider>
  );
};

export default CustomerCreate;
