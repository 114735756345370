import { Button } from "d-react-components";
import React from "react";
import { useTranslation } from "react-i18next";

interface IAddressItemHeader {
  label: string;
  isDefault: boolean;
  onClickEdit: () => any;
  onClickSetDefault: () => any;
  onClickDelete: () => any;
  showAction?: boolean;
}

const AddressItemHeader = ({
  label,
  isDefault,
  onClickEdit,
  onClickSetDefault,
  onClickDelete,
  showAction = true,
}: IAddressItemHeader) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="flex-center ">
        <label className="mr-3">{label}</label>
        <div
          className="bg-primary px-3 py-1 text-white height-fit-content"
          hidden={!isDefault}
        >
          {t("defaultText")}
        </div>
      </div>
      {showAction && (
        <div className="d-flex">
          <Button variant="trans" onClick={onClickDelete}>
            {t("delete")}
          </Button>
          <Button variant="trans" onClick={onClickEdit}>
            {t("edit")}
          </Button>
          {!isDefault && (
            <Button variant="trans" onClick={onClickSetDefault}>
              {t("setAsDefault")}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default AddressItemHeader;
