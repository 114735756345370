import { Button } from "d-react-components";
import React, { useContext, useState } from "react";
import UserDetailPasswordUpdateModal from "./UserDetailPasswordUpdateModal";
import AppContext from "@helpers/context";
import { useTranslation } from "react-i18next";
import UserInfoRow from "@common/UserInfoRow";
import ImageIcon from "@common/Icon";

const UserDetailPassword = () => {
  const { user, loadUserDetail } = useContext(AppContext);
  const { t } = useTranslation();
  const [openUpdate, setOpenUpdate] = useState(false);

  return (
    <div className="border mt-3 p-4">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h5>{t("password")}</h5>
        <span onClick={() => setOpenUpdate(true)}>
          <ImageIcon
            className="w-[15px] cursor-pointer"
            src="/images/icons/edit.svg"
          />
        </span>
      </div>
      <div>
        <UserInfoRow label={t("password")} value="**********************" />
      </div>
      {openUpdate && (
        <UserDetailPasswordUpdateModal
          open={openUpdate}
          onClose={() => setOpenUpdate(false)}
          userId={user?.id}
          onUpdateSuccess={loadUserDetail}
        />
      )}
    </div>
  );
};

export default UserDetailPassword;
