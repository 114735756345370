import Drawer from "@components/shared/Drawer";
import { useTableSelectionOption } from "@helpers/useTable";
import UserAPI from "@network/api/user";
import { AwesomeTableComponent, InputTextSearch, Modal, Progress, TimeUtils } from "d-react-components";
import { ceil, debounce, map } from "lodash";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

function UserDetailSOCollaborationModal({ open, onClose, title, type, userId, onSave }: any) {
  const tableRef = useRef<any>();
  const textSearch = useRef<any>();
  const { t } = useTranslation();
  const [userSelecting, setUserSelecting] = useState<any>([]);

  const [tableSelectionOptions, tableMetaData, setTableMetaData] =
    useTableSelectionOption(
      (selectedRows: any) => setUserSelecting(selectedRows),
      () => onSelectAllOfAllPage()
    );

  const onSelectAllOfAllPage = async () => {
    const totalPages = ceil(tableMetaData?.totalDataLength / 100);

    Progress.show({ method: loadUserList, params: [totalPages] }, (userList) => {
      setTableMetaData({ currentPageData: userList, listSelected: userList });
      setUserSelecting(userList);
    });
  };

  const getFilterBody = () => {
    return { id: userId, action: type, search: textSearch.current };
  };

  const loadUserList = async (totalPages: any) => {
    const body = getFilterBody();
    let userList: any[] = [];

    for (let index = 1; index <= totalPages; index++) {
      const res = await UserAPI.soCollaborationList(body, {
        pageIndex: index,
        pageSize: 100,
      });
      userList = userList.concat(res?.data?.data?.admin ?? []);
    }

    return userList;
  };

  const columns = [
    {
      title: t("id"),
      dataIndex: "id",
      render: (id: any, user: any) => <a href={user.editurl}>{id ?? "N/A"}</a>,
    },
    {
      title: t("avatar"),
      dataIndex: "avatar",
      render: (avatar: any) => (
        <img src={avatar} className="w-[32px] h-[32px] object-cover" />
      ),
    },
    {
      title: t("fullName"),
      dataIndex: "fullname",
      render: (fullName: any, user: any) => (
        <a href={user.editurl}>{fullName ?? "N/A"}</a>
      ),
    },
    {
      title: t("companyId"),
      dataIndex: "employee_id",
    },
  ];

  const onChangeText = debounce((text) => {
    textSearch.current = text;
    tableRef.current.refresh();
  }, 500);

  const source = (paging: any) => {
    const body = { id: userId, action: type, search: textSearch.current };
    return UserAPI.soCollaborationList(body, paging);
  };

  const transformer = (res: any) => {
    const userList = res?.data?.data?.admin ?? [];
    setTableMetaData({
      currentPageData: userList,
      totalDataLength: res?.data?.data?.pagination?.items,
    });
    return userList;
  };

  const userIdSelected = map(userSelecting, (item) => item.id);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={title}
      size="auto"
      width={800}
      onSave={() => onSave(userSelecting)}
    >
      <div>
        <InputTextSearch
          onChange={(e: any) => onChangeText(e?.target?.value)}
        />
        <AwesomeTableComponent
          ref={(ref) => {
            tableRef.current = ref;
          }}
          columns={columns}
          source={source}
          transformer={transformer}
          className="mt-3"
          // rowSelection={{
          //   selectedRowKeys: userIdSelected,
          //   onChange: (selectedRowKeys, selectedRows) => {
          //     setUserSelecting(selectedRows);
          //   },
          //   preserveSelectedRowKeys: true,
          // }}
          rowSelection={{
            onChange: (selectedRowKeys, selectedRows) => {
              setTableMetaData({ listSelected: selectedRows });
              setUserSelecting(selectedRows);
            },
            preserveSelectedRowKeys: true,
            //add when table have selection options
            selectedRowKeys: userIdSelected,
            selections: tableSelectionOptions,
          }}
        />
      </div>
    </Drawer>
  );
}

export default UserDetailSOCollaborationModal;
