import { reduce, find, isEmpty, pick } from "lodash";
import {
  Button,
  Checkbox,
  Icon,
  Notifications,
  Select,
  StringUtils,
  usePrevious,
} from "d-react-components";
import * as Yup from "yup";
import React, { useContext, useEffect, useRef, useState } from "react";
import AppContext from "@helpers/context";
import {
  BILLING_TYPE,
  BILLING_TYPES,
  OrderType,
  TYPE_OF_PLACES,
} from "@constants/common";
import { getFullAddressFromShipping, getFullName } from "@helpers/string";
import {
  searchBillingCustomer,
  updateBillingAddress,
} from "@network/api/address";
import { calculateAllProductQuantity } from "@helpers/product";
import { useTranslation } from "react-i18next";
import AddressFieldInput, { CustomerAddressForm } from "./AddressFieldInput";
import { useFormik } from "formik";
import { createBillingCustomer } from "@network/api/order";
import Drawer from "@components/shared/Drawer";

const INIT_BILLING = {
  fullname: "",
  nickname: "",
  address: "",
  province: "",
  postcode: "",
  phone: "",
  place: "",
  firstname: "",
  lastname: "",
  type: BILLING_TYPE.personal,
};

const getIntialBillingInfo = (
  billing: any,
  orderType = OrderType.NORMAL,
  customer?: any
) => {
  const customerGeneralInfo = !isEmpty(customer)
    ? pick(customer, [
        "email",
        "firstname",
        "lastname",
        "phone",
        "alternative_phone",
      ])
    : {};
  if (!billing) {
    return { ...INIT_BILLING, ...customerGeneralInfo };
  }
  if (isEmpty(billing.type)) {
    return {
      ...INIT_BILLING,
      ...customerGeneralInfo,
      ...billing,
      type: BILLING_TYPE.personal,
      billing: BILLING_TYPE.personal,
    };
  }
  return {
    ...customerGeneralInfo,
    ...billing,
    ...(orderType === OrderType.RENTAL ? { type: billing.billing } : {}),
  };
};

const getPlaceText = (place: any) => {
  return TYPE_OF_PLACES[place];
};

export const BillingAddressDrawer = ({
  open,
  onClose,
  orderType = OrderType.NORMAL,
}: any) => {
  const {
    billingProfile: billing = {},
    order,
    onReloadOrderData,
    isCreateOrder,
    setBillingProfile: onSaveBilling,
    delivery = [],
    products,
    pickup = [],
    values,
    provinceList,
    loadProvinceList,
  } = useContext(AppContext);
  const { selectedCustomer } = values;
  const customer = selectedCustomer?.[0] ?? {};
  const [billingInfo, setBillingInfo] = useState(
    getIntialBillingInfo(billing, orderType, customer)
  );
  const [checkBilling, setCheckBilling] = useState(false);
  const [billingList, setBillingList] = useState<any[]>([]);
  const [openModalAddNew, setOpenModalAddNew] = useState(false);
  const [openModalAddToList, setOpenModalAddToList] = useState(false);
  const { t } = useTranslation();

  // function getInitCheckBilling() {
  //   if (isCreateOrder) {
  //     return !!billing?.status;
  //   }
  //   return !!order?.billing;
  // }

  useEffect(() => {
    if (isCreateOrder) {
      setCheckBilling(!!billing?.status);
    } else {
      setCheckBilling(!!order?.billing);
    }
  }, []);

  useEffect(() => {
    setBillingInfo(getIntialBillingInfo(billing, orderType, customer));
    if (isCreateOrder) {
      // setCheckBilling(getInitCheckBilling());
    }
  }, [billing, customer]);

  const onClickSave = async (billingInfo: any) => {
    if (isCreateOrder) {
      setOpenModalAddNew(false);
      if (!checkBilling) {
        onSaveBilling && onSaveBilling({});
        onClose && onClose();
        return;
      }
      onSaveBilling &&
        onSaveBilling({
          ...billingInfo,
          citizenid: StringUtils.removeAllSpace(billingInfo?.citizenid),
          taxid: StringUtils.removeAllSpace(billingInfo?.taxid),
          status: checkBilling,
        });
      onClose && onClose();
      return;
    }

    const body = {
      ...billingInfo,
      id: billingInfo?.id,
      expected_to_receive: billingInfo?.expectedDateReceive,
      status: checkBilling,
      ...(orderType === OrderType.NORMAL
        ? {
            orderid: order?.id,
            city: billingInfo?.city?.id,
            province: billingInfo?.province?.id,
            subdistrict: billingInfo?.subdistrict?.id,
          }
        : {
            rent_id: order?.id,
            city_id: billingInfo?.city?.id,
            province_id: billingInfo?.province?.id,
            subdistrict_id: billingInfo?.subdistrict?.id,
            code: billingInfo?.postcode,
            billing: billingInfo?.type,
          }),
    };
    return updateBillingAddress(body, orderType).then(
      () => {
        onReloadOrderData && onReloadOrderData();
        setOpenModalAddNew(false);
        onClose && onClose();
        Notifications.showSuccess(
          t("notification:updateBillingAddressSuccess")
        );
      },
      (err) => {
        Notifications.showError(err?.response?.data?.message || err);
      }
    );
  };

  const onClickSaveAddNew = async (billingInfoAddNew: any) => {
    if (billingInfoAddNew.id) {
      onClickSave(billingInfoAddNew);
      return;
    }
    const body = {
      customerid: customer.id,
      ...billingInfoAddNew,
      province: billingInfoAddNew?.province?.id,
      city: billingInfoAddNew?.city?.id,
      subdistrict: billingInfoAddNew?.subdistrict?.id,
      citizenid: StringUtils.removeAllSpace(billingInfoAddNew?.citizenid),
      taxid: StringUtils.removeAllSpace(billingInfoAddNew?.taxid),
    };

    return createBillingCustomer(body).then(
      () => {
        setOpenModalAddNew(false);
        Notifications.showSuccess(
          t("notification:createBillingInformationSuccess")
        );
        setBillingInfo(billingInfoAddNew);
      },
      (err) => {
        Notifications.showError(err?.response?.data?.message || err);
      }
    );
  };

  const onClickSaveAddToList = async (selectedBilling?: any) => {
    const billingData = selectedBilling || billingInfo;
    if (isCreateOrder) {
      setOpenModalAddToList(false);
      onSaveBilling &&
        onSaveBilling({
          ...billingData,
          citizenid: StringUtils.removeAllSpace(billingData?.citizenid),
          taxid: StringUtils.removeAllSpace(billingData?.taxid),
          status: checkBilling,
        });
      return Promise.reject();
    }

    const { id, ...rest } = billingData;
    const body = {
      ...rest,
      city: rest?.city?.id,
      province: rest?.province?.id,
      subdistrict: rest?.subdistrict?.id,
      orderid: order?.id,
    };
    setBillingInfo &&
      setBillingInfo({
        ...body,
        citizenid: StringUtils.removeAllSpace(billingData?.citizenid),
        taxid: StringUtils.removeAllSpace(billingData?.taxid),
        status: checkBilling,
      });
    setOpenModalAddToList(false);
    // return updateBillingAddress(body).then(
    //   () => {
    //     onReloadOrderData();
    //     setOpenModalAddToList(false);
    //     Notifications.showSuccess(
    //       t("notification:updateBillingAddressSuccess")
    //     );
    //   },
    //   (err) => {
    //     Notifications.showError(err?.respone?.data?.message);
    //   }
    // );
  };

  const isDeliveryAllItems = (
    productList: any,
    deliveryList: any,
    pickupList: any
  ) => {
    const totalItems = calculateAllProductQuantity(productList);
    let itemsDelivery: any[] = [];
    deliveryList.forEach((deliveryItem: any) => {
      itemsDelivery = itemsDelivery.concat(deliveryItem?.items ?? []);
    });

    const totalDeliveryItems = reduce(
      itemsDelivery ?? [],
      (sum, item) => {
        return sum + item.stock;
      },
      0
    );

    const totalPickUpItems = reduce(
      pickupList,
      (sum, pickupItem) => {
        const subPickup = reduce(
          pickupItem.pickup,
          (subSum, subPickup) => {
            return subSum + subPickup?.stock;
          },
          0
        );
        return sum + subPickup;
      },
      0
    );

    return totalItems - totalDeliveryItems - totalPickUpItems === 0;
  };

  const handleCheckChange = () => {
    setCheckBilling(!checkBilling);
  };

  useEffect(() => {
    if (checkBilling) {
      onSaveBilling(getIntialBillingInfo(billing, orderType, customer));
    }
  }, [checkBilling]);

  useEffect(() => {
    if (!provinceList?.length) {
      loadProvinceList && loadProvinceList();
    }
  }, []);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("orderBillingAddress")}
      destroyOnClose
      size="auto"
      width={500}
      onSave={() => onClickSave(billingInfo)}
    >
      <Checkbox
        checked={!checkBilling}
        onChange={handleCheckChange}
        name="checkedB"
        color="primary"
        label={t("useSameShipping")}
      />

      {checkBilling && (
        <BillingView
          {...{
            billing: billingInfo,
            setBillingInfo,
            isCreateOrder,
            order,
            setOpenModalAddNew,
          }}
        />
      )}

      {checkBilling && (
        <div className="border-t-1 py-2">
          <Button onClick={() => setOpenModalAddToList(true)}>
            <Icon name="add" /> {t("add")}
          </Button>
        </div>
      )}

      {openModalAddToList && (
        <AddBillingToOrderDrawer
          {...{
            openModalAddToList,
            setOpenModalAddToList,
            billingList,
            setBillingList,
            billingInfo,
            setBillingInfo,
            onClickSaveAddToList,
            onClose,
            setOpenModalAddNew,
            openModalAddNew,
          }}
        />
      )}
      {openModalAddNew && (
        <AddNewBillingProfileDrawer
          {...{
            openModalAddNew,
            setOpenModalAddNew,
            onClickSaveAddNew,
            billingInfo,
            customer,
          }}
        />
      )}
    </Drawer>
  );
};

const BillingView = ({
  isCreateOrder,
  order,
  billing,
  setBillingInfo,
}: any) => {
  const [openModalAddNew, setOpenModalAddNew] = useState(false);
  const { t } = useTranslation();

  const onClickSaveAddNew = (values: any) => {
    setOpenModalAddNew(false);
    setBillingInfo(values);
  };

  if (
    (!isCreateOrder && billing && billing.firstname) ||
    (isCreateOrder && billing?.status)
  ) {
    if (!billing) return <div />;
    const {
      address,
      postcode,
      phone,
      place,
      citizenid,
      nickname,
      taxid,
      type,
      email,
    } = billing;

    return (
      <>
        <div className="flex flex-column mt-3 mt-3 pb-2 border-b-1">
          <div className="font-bold">{getFullName(billing)}</div>
          <div>
            {t("type")}: {t(type)}
          </div>
          <div>{phone}</div>
          {type === BILLING_TYPE.personal && citizenid && (
            <div>{`${t("citizenId")}: ${citizenid ?? "N/A"}`}</div>
          )}
          <div>{email}</div>
          {type === BILLING_TYPE.business && taxid && (
            <div>{`${t("taxId")}: ${taxid}`}</div>
          )}
          <div>{address}</div>
          <div>
            {billing?.subdistrict?.name} {billing?.city?.name}{" "}
            {billing?.province?.name} {postcode}
          </div>
          <div>{getPlaceText(place)}</div>
        </div>
        <div id="buttonGroup" className="mb-3 mt-2">
          <div
            className="cursor-pointer bg-primary text-white p-1 w-fit inline-block leading-none mr-1"
            onClick={() => {
              setOpenModalAddNew(true);
            }}
          >
            <Icon name="edit" size="x-small" />
          </div>
        </div>
        {openModalAddNew && (
          <AddNewBillingProfileDrawer
            {...{
              openModalAddNew,
              setOpenModalAddNew,
              onClickSaveAddNew,
              billingInfo: billing,
            }}
          />
        )}
      </>
    );
  }

  return <></>;
};

const AddBillingToOrderDrawer = ({
  openModalAddToList,
  setOpenModalAddToList,
  billingList,
  setBillingList,
  billingInfo,
  setBillingInfo,
  onClickSaveAddToList,
  onClose,
  setOpenModalAddNew,
  openModalAddNew,
}: any) => {
  const { values } = useContext(AppContext);
  const { selectedCustomer } = values;
  const [selectedBilling, setSelectedBilling] = useState<any>(billingInfo);
  const customer = selectedCustomer?.[0] ?? {};
  const { t } = useTranslation();
  const prevState = usePrevious(openModalAddNew);

  useEffect(() => {
    searchBillingCustomer(customer?.id).then((resp) => {
      transformer(resp);
    });
  }, []);

  const transformer = (res: any) => {
    const billingList = res?.data?.data?.billing;
    setBillingList(billingList);
  };

  useEffect(() => {
    if (prevState === true && openModalAddNew === false) {
      searchBillingCustomer(customer?.id).then((resp) => {
        transformer(resp);
      });
    }
  }, [openModalAddNew]);

  const renderShippingProfileItem = (item: any, index: number) => {
    const shipping = {
      subdistrict: item?.subdistrict,
      address: item?.address,
      province: item?.province,
      city: item?.city,
      postcode: item?.postcode,
    };
    return (
      <div className="flex py-3 border-b" key={index}>
        <div className="flex-1">
          <div className="flex items-center text-bold mb-2">
            {getFullName(item)}
          </div>
          <div>{item?.phone}</div>
          <div>{getFullAddressFromShipping(shipping)}</div>
        </div>
        <div>
          <Checkbox
            variant="radio"
            checked={selectedBilling?.id === item?.id}
            onChange={(event) => {
              setSelectedBilling(item);
            }}
            value={item?.id}
          />
        </div>
      </div>
    );
  };

  return (
    <Drawer
      title={
        <div className="flex">
          <span className="flex-1">{t("addBillingToOrder")}</span>
          <Button onClick={() => setOpenModalAddNew(true)}>
            <Icon name="add" /> {t("add")}
          </Button>
        </div>
      }
      open={openModalAddToList}
      onClose={() => setOpenModalAddToList(false)}
      destroyOnClose
      onSave={() => {
        setBillingInfo(selectedBilling);
        onClickSaveAddToList(selectedBilling);
      }}
    >
      <div className="pb-10">
        {billingList?.map((item: any, index: number) =>
          renderShippingProfileItem(item, index)
        )}
      </div>
    </Drawer>
  );
};

export const AddNewBillingProfileDrawer = ({
  openModalAddNew,
  setOpenModalAddNew,
  onClickSaveAddNew,
  billingInfo,
}: any) => {
  const { t } = useTranslation();
  const CustomerAddressSchema = Yup.object().shape({
    firstname: Yup.string().required(t("fieldRequired")),
    lastname: Yup.string().required(t("fieldRequired")),
    place: Yup.string().required(t("fieldRequired")),
    phone: Yup.string().required(t("fieldRequired")),
    address: Yup.string().required(t("fieldRequired")),
    province: Yup.mixed().required(t("fieldRequired")),
    city: Yup.mixed().required(t("fieldRequired")),
    subdistrict: Yup.mixed().required(t("fieldRequired")),
    postcode: Yup.string().required(t("fieldRequired")),
    type: Yup.string().required(t("fieldRequired")),
    citizenid: Yup.string().when(["type"], {
      is: (type: string) => type === BILLING_TYPE.personal,
      then: Yup.string().test(
        "len",
        t("citizenIdMustBe13"),
        (val) => val?.length === 13
      ),
    }),
  });
  const customerForm = useFormik<CustomerAddressForm>({
    initialValues: !isEmpty(billingInfo)
      ? {
          ...billingInfo,
          citizenid: billingInfo?.citizenid ?? "",
          email: billingInfo?.email ?? "",
          type:
            billingInfo?.type || billingInfo?.billing || BILLING_TYPE.personal,
        }
      : {},
    onSubmit: (values) => {
      onClickSaveAddNew(values);
    },
    validationSchema: CustomerAddressSchema,
    validateOnChange: false,
  });

  const { handleSubmit, values, setFieldValue, errors } = customerForm;

  return (
    <Drawer
      title={
        <div className="flex">
          <span className="flex-1">
            {isEmpty(billingInfo) || !billingInfo?.id
              ? t("newAddress")
              : t("updateAddress")}
          </span>
        </div>
      }
      open={openModalAddNew}
      onClose={() => setOpenModalAddNew(false)}
      destroyOnClose
      onSave={() => {
        handleSubmit();
      }}
      size="auto"
      width="600px"
    >
      <div className="pb-12">
        <Select
          value={values?.type}
          dataSource={BILLING_TYPES}
          getLabel={(item: any) => t(item.label)}
          getValue={(item: any) => item.id}
          onChange={(val) => setFieldValue("type", val)}
          className="mb-3"
          label={t("type")}
          placeholder={t("pleaseSelect")}
        />
        <AddressFieldInput
          form={customerForm}
          isBilling={true}
          billingType={values.type}
        />
      </div>
    </Drawer>
  );
};

export default BillingAddressDrawer;
