/* eslint-disable jsx-a11y/alt-text */
import Icon from "@common/Icon";
import Drawer from "@components/shared/Drawer";
import UploadFile from "@components/shared/UploadFile";
import AppContext from "@helpers/context";
import { uploadFile } from "@network/api/common";
import { editProduct } from "@network/api/product";
import { Button, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import { forEach } from "lodash";
import { useContext, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import Carousel, { Modal, ModalGateway } from "react-images";

interface IProductDetailGalleryEditDrawer {
  open: boolean;
  defaultValue?: any;
  onUpdated: any;
  onClose: () => void;
}

const ProductDetailSpecImageEditDrawer = ({
  open,
  defaultValue,
  onUpdated,
  onClose,
}: IProductDetailGalleryEditDrawer) => {
  const { t } = useTranslation();
  const productForm = useFormik<any>({
    initialValues: defaultValue as any,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: schema,
    onSubmit: (values: any) => {
      onUpdateProduct(values);
    },
  });
  const formValues = productForm?.values;
  const formErrors = productForm?.errors;
  const { spec_image } = formValues;
  console.log("spec_image", spec_image);

  const onUpdateProduct = (values: any) => {
    const { spec_image } = values;

    const body: any = {
      productid: defaultValue?.id,
      spec_image,
    };

    Progress.show({ method: editProduct, params: [body] }, (res: any) => {
      Notifications.showSuccess(t("updateSuccess"));
      onUpdated();
      onClose();
    });
  };

  const onDrop = ([file]: any) => {
    const reader = new FileReader();
    const url = reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      const body = new FormData();
      body.append("file", file);
      uploadFile(body).then((res) => {
        const result = res?.data?.data?.url;
        console.log("result", result);
        productForm.setFieldValue("spec_image", result);
      });
    };
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const renderInput = () => {
    if (formValues.spec_image) {
      return (
        <div {...getRootProps()}>
          <img src={formValues.spec_image} className="image-reg-x-large" />
          <input {...getInputProps()} />
        </div>
      );
    }
    return (
      <div
        className="image-reg-x-large border-dashed flex-center flex-column p-3"
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Button variant="trans" className="text-primary">
          {t("addFiles")}
        </Button>
        <small>{t("orDragDropHere")}</small>
      </div>
    );
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("gallery")}
      size="auto"
      width="80vw"
      onSave={() => {
        productForm.handleSubmit();
      }}
    >
      <div>
        <div className="d-flex flex-wrap border p-3">
          <div className="mr-3 mt-3">{renderInput()}</div>
        </div>
      </div>
    </Drawer>
  );
};

const ProductDetailSpecImage = () => {
  const { productDetail, loadProductDetail } = useContext(AppContext);
  const [openEdit, setOpenEdit] = useState(false);
  const { t } = useTranslation();

  const [viewImageModal, setViewImageModal] = useState(false);

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mt-3">
        <h5>{t("specImage")}</h5>
        <span onClick={() => setOpenEdit(true)}>
          <Icon
            className="w-[15px] cursor-pointer"
            src="/images/icons/edit.svg"
          />
        </span>
      </div>
      <div className="mt-3 border p-4 " hidden={!productDetail?.spec_image}>
        <img
          src={productDetail?.spec_image}
          className="image-reg-x-large"
          onClick={() => {
            setViewImageModal(true);
          }}
        />
      </div>

      <ModalGateway>
        {viewImageModal ? (
          <Modal onClose={() => setViewImageModal(false)}>
            <Carousel
              currentIndex={0}
              views={[
                {
                  src: productDetail?.spec_image,
                } as any,
              ]}
            />
          </Modal>
        ) : null}
      </ModalGateway>
      {openEdit && (
        <ProductDetailSpecImageEditDrawer
          open={openEdit}
          onClose={() => setOpenEdit(false)}
          defaultValue={productDetail}
          onUpdated={loadProductDetail}
        />
      )}
    </div>
  );
};

export default ProductDetailSpecImage;
