import { PRODUCT_TYPE } from "@constants/product";
import { Switch } from "antd";
import { map } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ProductInputTypeCustomSize from "./ProductInputTypeCustomSize";
import ProductInputTypeGroup from "./ProductInputTypeGroup";
import ProductInputTypeGroupCustomSize from "./ProductInputTypeGroupCustomSize";

interface IProductInput {
  productForm: any;
  className?: any;
}

const ProductInputConfiguration = ({
  productForm,
  className,
}: IProductInput) => {
  const { t } = useTranslation();

  const PRODUCT_TYPE_SWITCH = [
    { id: PRODUCT_TYPE.GROUP, label: t("thisIsGroupSKU") },
    { id: PRODUCT_TYPE.GROUP_CUSTOM_SIZE, label: t("thisIsGroupCustomSize") },
    { id: PRODUCT_TYPE.CUSTOM_SIZE, label: t("thisIsCustomSizeProduct") },
  ];
  const { productType, sellingWhenOutStock } = productForm?.values;

  const isGroupProduct = useMemo(
    () => productType === PRODUCT_TYPE.GROUP,
    [productType]
  );

  const isGroupCustomSizeProduct = useMemo(
    () => productType === PRODUCT_TYPE.GROUP_CUSTOM_SIZE,
    [productType]
  );

  const isCustomSizeProduct = useMemo(
    () => productType === PRODUCT_TYPE.CUSTOM_SIZE,
    [productType]
  );

  const onChangeProductType = (typeItem: any) => {
    if (productType === typeItem.id) {
      productForm.setFieldValue("productType", PRODUCT_TYPE.DEFAULT);
    } else {
      productForm.setFieldValue("productType", typeItem.id);
    }
  };

  const renderProductTypeSwitch = (typeItem: any) => (
    <div className="d-flex mt-3">
      <Switch
        checked={productType === typeItem.id}
        onChange={() => onChangeProductType(typeItem)}
      />
      <div className="ml-3">{typeItem.label}</div>
    </div>
  );

  return (
    <div className={className}>
      <div className="d-flex mt-3">
        <Switch
          checked={sellingWhenOutStock}
          onChange={() =>
            productForm.setFieldValue(
              "sellingWhenOutStock",
              !sellingWhenOutStock
            )
          }
        />
        <div className="ml-3">{t("continueSelling")}</div>
      </div>
      {map(PRODUCT_TYPE_SWITCH, renderProductTypeSwitch)}
      <ProductInputTypeGroup
        visible={isGroupProduct}
        productForm={productForm}
      />
      <ProductInputTypeGroupCustomSize
        visible={isGroupCustomSizeProduct}
        productForm={productForm}
      />
      <ProductInputTypeCustomSize
        visible={isCustomSizeProduct}
        productForm={productForm}
      />
    </div>
  );
};

export default ProductInputConfiguration;
