import Drawer from "@components/shared/Drawer";
import AppContext from "@helpers/context";
import { getDeliveryLogs, rePushNav } from "@network/api/delivery";
import { Spin } from "antd";
import {
  Icon,
  Notifications,
  Button,
  DialogManager,
  TimeUtils,
  Modal,
} from "d-react-components";
import { isEmpty, orderBy, reduce } from "lodash";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

const NavLogButton = ({ deliveryTracking }: any) => {
  const { order, remainingItems } = useContext(AppContext);
  const [loadingData, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  const [logs, setLogs] = useState<any[]>([]);
  const [currentLog, setCurrentLog] = useState("");

  const onClickButton = () => {
    setLoading(true);
    const body = {
      deliveryid: deliveryTracking?.id,
      orderid: order?.id,
    };
    getDeliveryLogs(body)
      .then((res) => {
        setLoading(false);
        setOpenModal(true);
        setLogs(orderBy(res?.data?.data?.logs, "created", "desc") ?? []);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onClickViewLog = (logItem: any) => {
    // DialogManager.showInfo(t("navLog"), logItem.xml);
    setCurrentLog(logItem.xml);
  };

  const renderNavLogList = () => {
    if (!logs || logs.length === 0) {
      return <div className="subTitle2">{t("orderIsNotAvaiNavLog")}</div>;
    }
    return (
      <div className="logsContainerTracking">
        {logs?.map((logItem: any) => {
          return (
            <div className="border-b py-2">
              <p className="logText mb-0">
                {logItem.log}
                <Icon
                  onClick={() => onClickViewLog(logItem)}
                  name="visibility"
                  className="text-primary ml-2 cursor-pointer"
                />
              </p>
              <span className="text-gray-500 text-xs">
                {t("createdAt")}: {TimeUtils.toDateTime(logItem.created)}
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  const renderLogContent = () => {
    // if (!deliveryTracking) {
    //   return <div />;
    // }
    return (
      <div>
        {deliveryTracking?.nav?.map((navItem: any) => {
          return (
            <div>
              <div className="subTitle1 flex mb-2">
                {t("navSoNo")}: &nbsp;
                <div className="subTitle2">{navItem?.ref}</div>
              </div>
              <div className="subTitle1 flex mb-2 text-green-500">
                {t("successSyncOn")} &nbsp;
                <div className="subTitle2 text-green-500">
                  {" "}
                  {TimeUtils.convertMiliToDateTime(navItem?.created)}
                </div>
              </div>
            </div>
          );
        })}
        {renderNavLogList()}
      </div>
    );
  };
  const onClickRePushNav = async () => {
    if (remainingItems > 0) {
      Notifications.showError(t("notification:navSyncUnavailable"));
      return;
    }
    const body = {
      orderid: order?.id,
    };
    return rePushNav(body).then((res) => {
      setOpenModal(false);
      Notifications.showSuccess(t("notification:pushNavSuccessful"));
    });
  };

  const isNavFailStatus = () => {
    return order?.nav === false;
  };

  const onClickCopy = (currentLog: string) => {
    navigator.clipboard.writeText(currentLog);
    Notifications.showInfo(t("order:copytoclipboard"));
  };

  return (
    <div className="my-2">
      <Button
        onClick={onClickButton}
        id="buttonTrack"
        variant="outline"
        color="primary"
        className="w-100"
      >
        {loadingData ? (
          <Spin size="default" />
        ) : (
          <span className="text-ellipsis overflow-hidden truncate inline-block text-xs">
            {t("viewLog").toLocaleUpperCase()}
          </span>
        )}
      </Button>

      {openModal && (
        <Drawer
          open={openModal}
          onClose={() => setOpenModal(false)}
          title={t("navLog")}
          cancelText={t("close")}
          saveText={t("rePushNav")}
          disableSave={!isNavFailStatus()}
          onSave={onClickRePushNav}
        >
          {renderLogContent()}
        </Drawer>
      )}
      {!isEmpty(currentLog) && (
        <Modal
          open={!isEmpty(currentLog)}
          onClose={() => setCurrentLog("")}
          title={
            <div className="flex items-center justify-center">
              {t("navLog")}{" "}
              <Button
                onClick={() => onClickCopy(currentLog)}
                variant="trans"
                color="gray"
                className="ml-1"
              >
                <Icon name="content_copy" className="text-black" size="large" />
              </Button>
            </div>
          }
          showFooter={false}
          size="large"
        >
          {currentLog}
        </Modal>
      )}
    </div>
  );
};

export default NavLogButton;
