import { IProductCategory } from "@interfaces/product";
import { IFieldSelect } from "@interfaces/select";
import { getCategoryList } from "@network/api/product";
import { ObjectUtils, Select } from "d-react-components";
import { isArray, map } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export interface IProductCategorySelect<T> extends IFieldSelect<T> {
  [key: string]: any;
}

const ProductCategorySelect = ({
  value = [],
  multiple,
  className,
  onChange,
  ...selectProps
}: IProductCategorySelect<IProductCategory>) => {
  const [categoryList, setCategoryList] = useState<any[]>(
    isArray(value) ? value : [value]
  );

  const { t } = useTranslation();

  const onChangeValue = (id: any) => {
    if (multiple) {
      onChange(
        map(id, (item) => ObjectUtils.findItemFromId(categoryList, item))
      );
      return;
    }
    onChange(ObjectUtils.findItemFromId(categoryList, id));
  };

  const categoryValue = useMemo(() => {
    if (multiple) {
      return map(value, (item: any) => item.id);
    }
    return (value as any)?.id;
  }, [value]);

  useEffect(() => {
    loadCategoryList();
  }, []);

  const loadCategoryList = () => {
    getCategoryList().then((res) => {
      const listData = res.data?.data?.category ?? [];
      setCategoryList(listData);
    });
  };

  return (
    <Select
      className={className}
      label={t("category")}
      dataSource={categoryList}
      getLabel={(item) => item?.name}
      value={categoryValue}
      onChange={onChangeValue}
      multiple={multiple}
      placeholder={t("pleaseSelect")}
      showSearch
      {...selectProps}
    />
  );
};

export default ProductCategorySelect;
