import { API } from "@network";
import {
  API_DISCOUNT_REQUEST_HISTORY,
  API_STAFF_MANAGEMENT_DISCOUNT_ASSIGN,
  API_STAFF_MANAGEMENT_DISCOUNT_LIST,
} from "@network/URL";

const DiscountStaffAPI = {
  discountStaffList: (body: any, paging: any) => {
    return API.post(
      `${API_STAFF_MANAGEMENT_DISCOUNT_LIST}?page=${paging.pageIndex}&per_page=${paging.pageSize}`,
      body
    );
  },

  discountStaffAssign: (body: any) => {
    return API.post(API_STAFF_MANAGEMENT_DISCOUNT_ASSIGN, body);
  },

  discountStaffHistory: (body: any, currentPage: any) => {
    return API.post(
      `${API_DISCOUNT_REQUEST_HISTORY}?page=${currentPage}`,
      body
    );
  },
  
};

export default DiscountStaffAPI;
