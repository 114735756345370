import InputSelectForm from "@common/input/InputSelectForm";
import InputTextForm from "@common/input/InputTextForm";
import { CHANNEL_STATUSES } from "@constants/channel";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const ChannelFormGeneral = ({ form, fieldClassName = "", show }: any) => {
  const { t } = useTranslation();

  const classNameField = classNames("col-12 mt-3", fieldClassName);
  return (
    <div className="row">
      <InputTextForm
        keyData="name"
        form={form}
        required
        className={classNameField}
        label={t("channelName")}
      />
      {/* {show?.systemCode && ( */}
      <InputTextForm
        keyData="code"
        form={form}
        required
        className={classNameField}
        label={t("systemCode")}
      />
      {/* )} */}
      <InputTextForm
        keyData="companyName"
        form={form}
        required
        className={classNameField}
      />
      <InputTextForm
        keyData="companyAddress"
        required
        form={form}
        className={classNameField}
        label={t("companyAddressText")}
      />
      <InputTextForm
        keyData="companyTaxId"
        required
        form={form}
        className={classNameField}
      />
      <InputTextForm
        keyData="companyPhone"
        form={form}
        className={classNameField}
      />
      <InputTextForm
        keyData="companyWebsite"
        form={form}
        className={classNameField}
      />

      <InputSelectForm
        keyData="status"
        form={form}
        required
        className={classNameField}
        dataSource={CHANNEL_STATUSES}
      />
    </div>
  );
};

export default ChannelFormGeneral;
